import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { OpsGroupService } from 'src/app/users/service/ops-group/ops-group.service';
import { Subject, Subscription, of } from 'rxjs';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  finalize,
  map,
  mergeMap
} from 'rxjs/operators';

@Component({
  selector: 'ops-overview',
  templateUrl: './ops-overview.component.html',
  styleUrls: ['./ops-overview.component.scss'],
})
export class OpsOverviewComponent implements OnInit {
  @Output() action = new EventEmitter<any>();
  OpsList: Object;
  opsGroupData: any;
  opsGroupViewData: any;
  opsGroupId: any;
  opsGroupLoader: boolean = true;
  actionLoader: boolean;
  actionBtnIndex: any;
  actionText: any;
  public overviewSearchInput = new Subject<KeyboardEvent>();
  private OverviewSearchSubscription: Subscription;
  opsGroupDataTable:any={
    data: [],
    page: 1,
    total: 0,
    limit: 10,
    search:''
  }
  constructor(
    private opsGroupService: OpsGroupService,
    public toastService: ToastrService
  ) {
    this.OverviewSearchSubscription = this.overviewSearchInput.pipe(
      map((event) => {
        const target = event.target as HTMLTextAreaElement;
        return target.value;
      }),
      debounceTime(500),
      distinctUntilChanged(),
      mergeMap((search) => of(search).pipe(delay(500)))
    ).subscribe((input) => {
      this.opsGroupDataTable.page = 1;
      this.getOpsGroup();
    });
  }

  ngOnInit(): void {
    this.getOpsGroup();
  }

  exportOps(ops) {
    this.opsGroupService.viewOpsGruop(ops._id).subscribe((res) => {
      const opsExportPayload = res.data[0];
      this.opsGroupService
        .getExportOpsCSV(opsExportPayload)
        .subscribe((response:any) => {
          // console.log(csv);
          let csvElement = document.createElement('a');
          csvElement.href = 'data:attachment/csv,' +encodeURI(response.csv);
          csvElement.target = '_blank';
          csvElement.download = "grpExport.csv";
          document.body.appendChild(csvElement);
          csvElement.click();
          this.toastService.success(
            "success",
            "Ops group data exported succesfully"
          );
        });
    });
  }

  getOpsGroup() {
    this.OpsList = {
      privilage: true,
      search: this.opsGroupDataTable.search
    };

    const opsGroup = this.opsGroupService
      .getOpsGruop(this.OpsList)
      .pipe(
        finalize(() => {
          this.opsGroupLoader = false;
          opsGroup.unsubscribe();
        })
      )
      .subscribe((res) => {
        this.opsGroupDataTable.data = res.data || [];
        this.opsGroupDataTable.data= this.opsGroupDataTable.data?.sort((a, b) => (a.opsGroupName < b.opsGroupName ? -1 : 1));
        this.opsGroupDataTable.total=  this.opsGroupDataTable.data?.length || 0;
      });
  }

  getOpsDetail(item: any, index: String, action: String) {
    this.actionBtnIndex = index;
    this.actionLoader = true;
    this.actionText = action;
    const opsDetail = this.opsGroupService
      .viewOpsGruop(item._id)
      .pipe(
        finalize(() => {
          this.actionLoader = false;
          opsDetail.unsubscribe();
        })
      )
      .subscribe((res) => {
        if (res.success) {
          this.opsGroupViewData = res.data;
          this.opsGroupService.allPassedData.next(this.opsGroupViewData);
          action == 'edit'
            ? this.action.emit('EDIT')
            : this.action.emit('VIEW');
        }
      });
  }

  onDeleteOpsGroup(item) {
    this.opsGroupId = item._id;

    Swal.fire({
      title: 'Are you sure, you want to delete this OpsGroup?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        this.opsGroupService
          .deleteOpsGruop(this.opsGroupId)
          .subscribe((res) => {
            this.opsGroupDataTable.data = res;
            this.opsGroupDataTable.data= this.opsGroupDataTable.data?.sort((a, b) => (a.opsGroupName < b.opsGroupName ? -1 : 1));

            this.getOpsGroup();
            this.toastService.success(res['message']);
          });
      }
    });
  }

onPageChangeOpsGroupData(page: number) {
  this.opsGroupDataTable.page = page;
}
}
