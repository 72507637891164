<div class="card mb-3 w-100" *ngIf="notificatioNid && !isNotificationViewEnabled ">
  <div class="card-header flexi-header pt-4">
    <h3 style="display: inline-block;">NOTIFICATION</h3>
  </div>
  <div class="card-body">
    <div class="card table-card">
      <div class="card-body flexi-body">
        <div class="flex-column">
          <div class="d-flex justify-content-between mt-2 mb-3">
            <select class="custom-select" style="width: auto" name="pageSize" (change)="onLimitChangeUsers()"
              [(ngModel)]="usersPTable.limit">
              <option [ngValue]="10">10</option>
              <option [ngValue]="20">20</option>
              <option [ngValue]="30">30</option>
              <option [ngValue]="40">40</option>
              <option [ngValue]="50">50</option>
            </select>
            <div class="form-group form-inline mb-0">
              <span *ngIf="usersPTable.isLoading">Please wait...</span>
              <input class="form-control ml-2" type="search" name="searchTerm" (search)="loadUsers()"
                (keyup)="userssearchInput.next($event)" placeholder="Search here" [(ngModel)]="usersPTable.search" />
            </div>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" sortable="Tier 1" (sort)="onSortUsers($event, 'title')">
                    TITLE
                  </th>
                  <th scope="col" sortable="Tier 2" (sort)="onSortUsers($event, 'name')">
                    CATEGORY
                  </th>
                  <th scope="col" sortable="Tier 3" (sort)="onSortUsers($event, 'activeFrom')">
                    ACKNOWLEDGE
                  </th>

                  <th scope="col" sortable="Tier 4" (sort)="onSortUsers($event, 'updatedAt')">
                    ACTIVE FROM
                  </th>

                  <th scope="col">STATUS</th>
                  <th scope="col">ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of usersPTable.data">
                  <td>
                    {{ item.title }}
                  </td>

                  <td>{{ item?.subCategoryId?.categoryId?.name }}</td>

                  <td> <i class="fa fa-spinner" aria-hidden="true"></i> &ensp;{{ getAcknowledge(item) + '%'}}</td>
                  <td> {{
                    item?.activeFrom |date:'short'
                    }}
                  </td>
                  <td>
                    <span class="badge" [ngClass]="
                    getStatus(item) === 'Expired'
                          ? 'badge-danger' :  getStatus(item) === 'Active'? 'badge-success'
                          : 'badge-warning'">
                      {{ getStatus(item) }} </span>
                  </td>
                  <td>
                    <button class="btn btn-submit p-2 m-1" (click)="onViewNotification(item)">View</button>
                    <button class="btn btn-add p-2 m-1" (click)="onEditNotification(item)">Edit</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="d-flex justify-content-between">
          <p>Total Records {{ usersPTable.total }}</p>
          <ngb-pagination [collectionSize]="usersPTable.total" [(page)]="usersPTable.page" [maxSize]="5"
            (pageChange)="onPageChangeUsers($event)" [pageSize]="usersPTable.limit">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<app-view-notification *ngIf="isNotificationViewEnabled" [notificationViewData]="notificationViewData"
  (overViewNotification)="viewEnable($event)"></app-view-notification>
