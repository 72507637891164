import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { COMMON_MESSAGES } from 'src/app/core/utils/helper';
import { CompanySetupService } from 'src/app/users/service/company-setup/company-setup.service';
import { CustomFieldService } from 'src/app/users/service/custom-field/custom-field.service';
import { GamificationService } from 'src/app/users/service/gamification/gamification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  iconFileName: any;
  companySettingsId: string;
  baseUrl = environment.imageUrl;
  IconUrl: string;

  CustomFieldPTable: {
    sortBy: string;
    sortWith: string;
    data: any[];
    page: number;
    total: number;
    limit: number;
    search: string;
    statusFilter: string
  };
  isLoading: boolean = false
  isIconLoading: boolean = false
  isUpdateLoading: boolean = false
  usefullinkIndex: number;
  usefullinkEdit: boolean = false;
  setupForm: FormGroup;


  fieldNeedsToUpdate = [];
  formEmpty: boolean = true;

  CustomFieldDropDownLoading: boolean = false;
  userLinksubmitted: boolean = false
  constructor(
    private companyService: CompanySetupService,
    private formBuilder: FormBuilder,
    private tostService: ToastrService,
    private gamificationService: GamificationService,
    private customFieldService: CustomFieldService
  ) {


  }

  ngOnInit(): void {
    // this.config.notFoundText = 'Custom not found';
    this.CustomFieldPTable = {
      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,
      total: 0,
      limit: 10,
      search: '',
      statusFilter: 'active'
    };
    this.initForm();

    this.getCompanySettings();
    this.loadCustomField();
  }

  onSeachCustomField(value) {
    this.CustomFieldPTable.limit = 10;
    this.CustomFieldPTable.search = value.term;
    this.loadCustomField();
  }
  loadMoreCustomFieldItems(value) {

    this.CustomFieldPTable.limit = this.CustomFieldPTable.limit + 10;

    this.loadCustomField();
  }

  onClearCustomFieldDropdown(value) {
    if (value === undefined) {
      this.setupForm.patchValue({
        loginFields: [],
      });
      this.CustomFieldPTable.search = '';
      this.loadCustomField();

      this.fieldNeedsToUpdate = [];
      this.CustomFieldPTable.data = [];
    }
  }

  loadCustomField() {
    const { data, total, ...rest } = this.CustomFieldPTable
    const subscribe = this.customFieldService
      .getCustomField(rest)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          console.log(response.data.data)
          const { count, data } = response.data;
          this.CustomFieldPTable.data = data;
          this.fieldNeedsToUpdate = data;
          this.CustomFieldPTable.total = count;
        },
        (error) => {
          this.CustomFieldPTable.data = [];
          this.CustomFieldPTable.total = 0;
          this.tostService.error('No list found');
        }
      );
  }
  getCompanySettings() {
    this.companyService.getcompanySetting().subscribe((res) => {
      this.companySettingsId = res.data._id;
      // this.useFullLinkOrders.push(res.data.externalLinks.length + 1)
      this.setupForm.patchValue({
        isTaskViewIncluded: res.data.isTaskViewIncluded,
        isChallengeIncluded: res.data.isChallengeIncluded,
        isFormsIncluded: res.data.isFormsIncluded,
        isBoardsIncluded: res.data.isBoardsIncluded,
      });
      res.data.bannerImages.forEach((element) => {
        this.setupForm.patchValue({
          rotating_banners: element.rotating_banners
        })
        let previlege = this.formBuilder.group({
          rotating_banners: [
            element.rotating_banners,
            Validators.compose([Validators.required]),
          ],
          link: [element.link, Validators.compose([Validators.required])],
        });
        this.BannersControl.push(previlege);
      });
      res.data.loginFields.forEach((element) => {
        let field = this.formBuilder.group({
          string: [
            element.fieldName,
            Validators.compose([Validators.required]),
          ],
          _id: [element._id, Validators.compose([Validators.required])],
        });
        this.LoginFieldsControl.push(field);
      });
    },
      (error: HttpErrorResponse) => {

      });
  }

  initForm() {
    this.setupForm = this.formBuilder.group({
      bannerImages: this.formBuilder.array([]),
      loginFields: this.formBuilder.array([]),
      rotating_banners: [0, [Validators.required]],
      isTaskViewIncluded: [false, [Validators.required]],
      isChallengeIncluded: [false, [Validators.required]],
      isFormsIncluded: [false, [Validators.required]],
      isBoardsIncluded: [false, [Validators.required]],
    });


  }



  intiBannerArray() {
    return this.formBuilder.group({
      rotating_banners: [1, Validators.compose([Validators.required])],
      link: ['', Validators.compose([Validators.required])],
    });
  }
  /**
   *
   * @param event to get the file through the event
   * @description file upload is done here for the main element
   */
  BannerUpload(event) {
    this.isLoading = true;

    if (event.target.files && event.target.files.length) {
      if (event.target.files.length) {
        this.formEmpty = false;
      }
      let file: File = event.target.files[0];
      this.iconFileName = file.name;
        if (file && file.type.startsWith('image/')) {
          const fileUpload = this.gamificationService.uploadFile(file)
            .pipe(
              finalize(() => {
                fileUpload.unsubscribe();
              })
            )
            .subscribe(
              (data: any) => {
                this.addBannerImages(`${data?.data?.exactPath}`);
                this.isLoading = false
                this.tostService.success('Banner Image Uploaded Successfully')
              },
              (error: HttpErrorResponse) => {
                this.isLoading = false
                this.tostService.error(error?.error?.message, error?.statusText);
              }
            );
        } else {
          this.tostService.error(COMMON_MESSAGES.UPLOAD_ONLY_IMAGE);
          event.target.value = '';
          file = null
          this.iconFileName = '';
          this.isLoading = false;
        }
    }

  }

  get BannersControl(): FormArray {
    return this.setupForm.get('bannerImages') as FormArray;
  }
  get LoginFieldsControl(): FormArray {
    return this.setupForm.get('loginFields') as FormArray;
  }

  get externalLinksControl(): FormArray {
    return this.setupForm.get('externalLinks') as FormArray;
  }

  async addLoginFields(value) {
    if (value) {
      this.formEmpty = false;
    }
    else if (value === undefined) {
      this.formEmpty = true;
    }

    const Control = this.LoginFieldsControl;
    const index = await Control.value.findIndex((x) => x._id === value._id);

    if (index > -1) {
      this.ngSelectComponent.clearModel();
      this.tostService.error(`${value.fieldName} is already added`);
    } else {
      let field = this.formBuilder.group({
        string: [value.fieldName, Validators.compose([Validators.required])],
        _id: [value._id, Validators.compose([Validators.required])],
      });
      Control.push(field);
      this.ngSelectComponent.clearModel();
    }
  }

  deleteSelectedFields(index) {
    const Control = this.LoginFieldsControl;

    Control.removeAt(index);
  }


  async addBannerImages(link: string, count?: number) {
    const Control = this.BannersControl;
    console.log(link);

    let previlege = this.formBuilder.group({
      rotating_banners: [
        count ? count : 1,
        Validators.compose([Validators.required]),
      ],
      link: [link, Validators.compose([Validators.required])],
    });
    Control.push(previlege);
    console.log(Control);

  }
  /**
   *
   * @description update main element with the respective element id
   */

  onDeleteBannerImage(index) {
    const Control = this.BannersControl;
    Control.removeAt(index);
  }



  async onSubmitSetupForm() {
    this.isUpdateLoading = true
    const Control = this.LoginFieldsControl;
    const BannerControl = this.BannersControl;
    let fields = await Control.value.map((item) => item._id);
    let banners = await BannerControl.value.map((item) => {

      return {
        ...item,
        rotating_banners: this.setupForm.get('rotating_banners').value
      }
    });

    // const keys = []
    //     Control.value.forEach(element => {
    //       keys.push( ...Object.values(element))
    //     });
    //
    const { rotating_banners, ...rest } = this.setupForm.value


    if (rotating_banners < 0) {
      this.tostService.error('Rotating Banner should not be less than 0')
      this.isUpdateLoading = false
    }
    else {
      const data = {
        ...rest,
        bannerImages: banners,
        pageId: this.companySettingsId,
        loginFields: [...fields],
      };

      console.log("inside home page", data, this.companySettingsId);
      const sub = this.companyService
        .updateCompanySettings(data)
        .pipe(
          finalize(() => {
            sub.unsubscribe();
          })
        )
        .subscribe(
          (res) => {
            this.tostService.success('Updated SuccessFully');
            this.isUpdateLoading = false
            this.ngOnInit();
          },
          (error) => {
            this.isUpdateLoading = false
          }
        );
    }
  }

  validateURLImage(str) {
    var img_Path = str;
    if (img_Path.indexOf("https://") == 0) {
      var new_url = new URL(str);
     var pathName = (new_url.pathname.startsWith('//')) ? `/${new_url.pathname.split("//")[1]}` : new_url.pathname;
      return `${this.baseUrl}${pathName}`;
    }
    if (str.startsWith('/')) return this.baseUrl + str;
    return `${this.baseUrl}/${str}`;
   }

}
