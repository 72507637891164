import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StorageService } from 'src/app/core/service/storage.service';
import { queryParam } from 'src/app/core/utils/helper';
import { environment } from 'src/environments/environment';

const baseUrl2 = `${environment.baseUrl}/manage-notification`;

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private baseUrl: string;
  private get Headers(): HttpHeaders {
    return new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }
  constructor(
    private http: HttpClient,
  ) {
    this.baseUrl = environment.baseUrl;
  }
  getAllNotifications(data,id:string): Observable<any> {
    return this.http.post(`${this.baseUrl}/notification/read/?${queryParam(data)}`, {"businessUnitId": id }, {
      headers: this.Headers,
    })
    .pipe(map((res) => res as any))
  }
  getUnreadNofications(data): Observable<any> {
    return this.http.get(`${this.baseUrl}/notification/mynotifications/?${queryParam(data)}`, {
      headers: this.Headers,
    })
    .pipe(map((res) => res as any))
  }
  getAcknowledgedNofications(data): Observable<any> {
    return this.http.get(`${this.baseUrl}/notification/acknowledgedNotifications/?${queryParam(data)}`, {
      headers: this.Headers,
    })
    .pipe(map((res) => res as any))
  }
  getSingleNotification(id: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/notification/getSingleNotification/${id}`)
    .pipe(map((res) => res as any))
  }

  getModuleQuestions(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/common/questionModule/getModuleQuestions`, data, {
      headers: this.Headers,
    })
    .pipe(map((res) => res as any))
  }

  getManageQuestions(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/customForm/getManageQuestions`, data, {
      headers: this.Headers,
    })
    .pipe(map((res) => res as any))
  }
  getManageFormAnswers(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/customForm/getManageFormAnswers`, data, {
      headers: this.Headers,
    })
    .pipe(map((res) => res as any))
  }

  getAcknowledgeNotification(notificationId: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/notification/acknowledge`, {"notificationId": notificationId })
    .pipe(map((res) => res as any))
  }
  answerQuestions(data): Observable<any> {
    return this.http.post(`${this.baseUrl}//notification/answerQuestions/` ,data)
    .pipe(map((res) => res as any))
  }

  readAcknowledgedAndUnreadUser(id, data): Observable<any> {
    return this.http.get(`${this.baseUrl}/notification/readAcknowledgedAndUnreadUser/${id}?${queryParam(data)}`, {
      headers: this.Headers,
    })
    .pipe(map((res) => res as any))
  }

  // updateForm(data, id: string): Observable<any> {
  //   return this.http
  //     .put(`${this.baseUrl}/custom-form/${id}`, data, {
  //       headers: this.Headers,
  //     })
  //     .pipe(map((res) => res as any));
  // }
  createNotification(data): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/notification/create`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  saveDraft(): Observable<any> {
    return this.http.get(`${this.baseUrl}/centralBuilder/read?nonLinkedModules=true` ,{
      headers: this.Headers,
    })
    .pipe(map((res) => res as any));
  }
  exportNotification(data): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/notification/download`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  downloadUrl(url): Observable<any> {
    return this.http
      .get(`${this.baseUrl}/${url}`, {
        headers: this.Headers,
        observe: 'body', responseType: 'text' as 'json'
      })

      .pipe(map((res) => res as any));
  }

  getMimeType(data): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/scheme/mimetype`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  downloadAnyUrl(url): Observable<any> {
    return this.http
      .get(`${this.baseUrl}/${url}`, {
        headers: this.Headers,
        responseType: 'blob'
      })

      .pipe(map((res) => res as any));
  }

  downloadFile(signedUrl: string) {
    return this.http.get(signedUrl, { responseType: 'blob' });
  }

  ///category

  getAllCategory(data): Observable<any> {
    return this.http.get(`${this.baseUrl}/category/?${queryParam(data)}`, {
      headers: this.Headers,

    })
    .pipe(map((res) => res as any))
  }

  createCategory(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/category/` , data ,{
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }
  updateCategory(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/category/update` , data ,{
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }

  deleteCategory(id:string): Observable<any> {
    return this.http.post(`${this.baseUrl}/category/${id}`  ,{
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }



  ///subcategory


  getAllSubCategory(data, id:string): Observable<any> {
    return this.http.get(`${this.baseUrl}/subcategory/${id}/?${queryParam(data)}`, {
      headers: this.Headers,

    });
  }
  createSubCategory(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/subcategory/create` , data ,{
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }

  updateSubCategory(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/subcategory/update`, data ,{
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }

  deleteSubCategory(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/subcategory/delete`, data  ,{
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }
  getAllSchedule(data): Observable<any> {
    return this.http.post(baseUrl2 + '/schedule', data);
  }
  getAllPush(data): Observable<any> {
    return this.http.post(baseUrl2 + '/push', data);
  }
  export(data): Observable<any> {
    return this.http.post(baseUrl2 + '/export', data);
  }
  createNotificationManage(data): Observable<any> {
    return this.http.post(baseUrl2 + '/create', data);
  }
  updateNotification(data): Observable<any> {
    return this.http.post(baseUrl2 + '/update', data);
  }
  cancelNotification(data): Observable<any> {
    return this.http.post(baseUrl2 + '/cancel', data);
  }
  getSingleNotificationManage(notificationId): Observable<any> {
    return this.http.get(baseUrl2 + '/single/' + notificationId);
  }

  getScheduleNotification(data, queryData): Observable<any> {
    return this.http.post(baseUrl2 + '/scheduled/?'+queryParam(queryData), data);
  }

  getPushNotification(data, queryData): Observable<any> {
    return this.http.post(baseUrl2 + '/pushed/?'+queryParam(queryData), data);
  }

  getMyNotification(): Observable<any> {
    return this.http.get(`${this.baseUrl}/notification/mynotifications`)
    .pipe(map((res) => res as any))
    }

}
