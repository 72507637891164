import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  TemplateRef,
  Injectable,
  OnChanges,
} from '@angular/core';
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ShiftSchedulerService } from 'src/app/users/service/operations/shift-scheduler.service';
import { SetupService } from 'src/app/users/service/operations/setup.service';
import { finalize } from 'rxjs/operators';
import { getGmtOffSet } from 'src/app/core/utils/helper';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '-';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);

      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1].padStart(10, '0'), 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? date.day +
          this.DELIMITER +
          parseInt(date.month.toString().padStart(10, '0'), 10) +
          this.DELIMITER +
          date.year
      : '';
  }
}

@Component({
  selector: 'app-timesheet-lookup',
  templateUrl: './timesheet-lookup.component.html',
  styleUrls: ['./timesheet-lookup.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class TimesheetLookupComponent implements OnInit, OnChanges {
  @Input('businessUnitId') public businessUnitId: any;
  @Input() refresh: any;
  readonly DELIMITER = '-';
  public timeSheetData: any;
  public user: any;
  historyPayLoad: {};
  shiftStartTime: any;
  shiftEndTime: any;
  singleTimeSheet: any;
  singleData: any;
  approvalObj: any;
  checked: any;
  disabled: boolean;
  checkedArray = [];
  isShow = true;
  isApproveShowHisDet: string;
  breakTimeObj: {};
  shiftsForm: any;
  shiftsFormFilter: any;
  neitherShiftsForm: any;
  lockObj: any;
  approvalTimeIn: any;
  approvalTimeOut: any;
  dura = 0;
  dayHrs: any;
  uniqueIdClocked: any;
  uniqueIdShift: any;
  appovePath: any;
  neitTime: any;
  dateForNeither: any;
  min: number;
  break: any;
  empList: any;
  SelectedEmpDisplay: any;
  Selectedemp: any;
  filterObj: any;
  lastDate: any;
  checkShift: any;
  allShiftArray = {};
  allShift = [];
  exportObj: any;
  exportDate: any;
  isBreakShowHisDet: any;
  showBtn = true;
  neitherStartTime: any;
  enddateForNeither: any;
  remarkMessage: any;
  searchString: any = null;
  LockChecked: boolean;
  timeSheetDataLoader: boolean = true;
  gmtOffset: any;

  constructor(
    protected formBuilder: FormBuilder,
    public toastService: ToastrService,
    private timesheetService: ShiftSchedulerService,
    public modelService: NgbModal,
    private buService: SetupService
  ) {
    this.historyPayLoad = {
      endDate: moment().format('YYYY-MM-DD'),
      isDefault: false,
      startDate: moment().subtract(6, 'days').format('YYYY-MM-DD'),
    };
  }

  ngOnInit(): void {
    this.gmtOffset = getGmtOffSet();
  }

  ngOnChanges() {
    this.getTimesheetHistory();
    this.buService.getAdmin().subscribe((res) => {
      this.empList = res['data'];
    });
  }

  public getTimesheetHistory(): void {
    const startDate = moment(this.historyPayLoad['startDate']);
    const endDate = moment(this.historyPayLoad['endDate']);

    const startdate: NgbDateStruct = {
      year: startDate.year(),
      month: startDate.month() + 1, 
      day: startDate.date(),
    };
    
    const enddate: NgbDateStruct = {
      year: endDate.year(),
      month: endDate.month() + 1,
      day: endDate.date(),
    };

    this.lastDate = moment().format('DD-MM-YYYY');

    this.timesheetService
      .getTimesheetHistory(this.businessUnitId._id, this.historyPayLoad)
      .pipe(
        finalize(() => {
          this.timeSheetDataLoader = false;
        })
      )
      .subscribe((res: { data: any; message: any; status: any }) => {
        this.timeSheetData = res.data ? res.data : [];
      });

    this.shiftsFormFilter = this.formBuilder.group({
      startDateFilter: [startdate, [Validators.required]],
      endDateFilter: [enddate, [Validators.required]],
    });

    this.initForm();
  }

  @ViewChild('AddShiftHours', { static: true })
  AddShiftHours: TemplateRef<any>;

  createAddShiftHours(data) {
    this.singleTimeSheet = data;
    this.shiftStartTime = data.shiftDetails.startTime;
    this.shiftEndTime = data.shiftDetails.endTime;

    if (this.singleTimeSheet.attendance) {
      this.shiftsForm.patchValue({
        clockedStartTime: moment(data.attendance.clockInDateTime).format(
          'HH:mm'
        ),
        clockedEndTime: moment(data.attendance.clockOutDateTime).format(
          'HH:mm'
        ),
      });
    }
    this.shiftsForm.patchValue({
      startDate: moment(data.shiftDetails.startTime).format('MM-DD-YYYY'),
      endDate: moment(data.shiftDetails.endTime).format('MM-DD-YYYY'),
      startTime:
        data.shiftDetails.isExtendedShift &&
        data.shiftDetails.extendedStaff.length > 0
          ? moment(data.shiftDetails.extendedStaff[0].startDateTime).format(
              'HH:mm'
            )
          : moment(data.shiftDetails.startTime).format('HH:mm'),
      endTime:
        data.shiftDetails.isExtendedShift &&
        data.shiftDetails.extendedStaff.length > 0
          ? moment(data.shiftDetails.extendedStaff[0].endDateTime).format(
              'HH:mm'
            )
          : moment(data.shiftDetails.endTime).format('HH:mm'),
    });
    this.modelService.open(this.AddShiftHours, {
      windowClass: 'modal-ui-fix right-align',
    });
  }

  initForm() {
    this.shiftsForm = this.formBuilder.group({
      startDate: [false, [Validators.required]],
      endDate: [null, [Validators.required]],
      startTime: [null, [Validators.required]],
      endTime: [null, [Validators.required]],
      clockedStartTime: [null, [Validators.required]],
      clockedEndTime: [null, [Validators.required]],
      inputstartTime: [null, [Validators.required]],
      inputendTime: [null, [Validators.required]],
      neitherstartTime: [null, [Validators.required]],
      neitherendTime: [null, [Validators.required]],
      neitherRemarks: [null, [Validators.required]],
    });
  }

  handleApprove($event) {
    this.uniqueIdShift = $event;
  }
  handleExport($event) {
    this.exportDate = $event;
  }

  viewBtn() {
    this.timeSheetDataLoader = true;
    const startDate = this.shiftsFormFilter.get('startDateFilter').value;
    const endDate = this.shiftsFormFilter.get('endDateFilter').value;
    if (this.Selectedemp) {
      this.filterObj = {
        startDate: moment()
          .set({
            year: startDate.year,
            month: startDate.month - 1,
            date: startDate.day,
          })
          .format('YYYY-MM-DD'),
        isDefault: false,
        endDate: moment()
          .set({
            year: endDate.year,
            month: endDate.month - 1,
            date: endDate.day,
          })
          .format('YYYY-MM-DD'),
        userId: this.Selectedemp._id,
      };
    } else {
      this.filterObj = {
        startDate: moment()
          .set({
            year: startDate.year,
            month: startDate.month - 1,
            date: startDate.day,
          })
          .format('YYYY-MM-DD'),
        isDefault: false,
        endDate: moment()
          .set({
            year: endDate.year,
            month: endDate.month - 1,
            date: endDate.day,
          })
          .format('YYYY-MM-DD'),
      };
      this.historyPayLoad['startDate'] = this.filterObj.startDate;
      this.historyPayLoad['endDate'] = this.filterObj.endDate;
    }

    this.timesheetService
      .getTimesheetHistory(this.businessUnitId._id, this.filterObj)
      .subscribe((res: { data: any; message: any; status: any }) => {
        this.timeSheetData = res.data || [];
        this.timeSheetDataLoader = false;
      },
      (error: any) => {
        this.toastService.error("Something went wrong. Please try again.");
        this.timeSheetDataLoader = false;
      });
  }

  approvalBtn() {
    this.timeSheetDataLoader = true;
    this.dateForNeither = new Date(
      moment(this.shiftStartTime).format('YYYY-MM-DD ') +
        this.shiftsForm.get('neitherstartTime').value +
        ':00 ' + this.gmtOffset
    );
    this.enddateForNeither = new Date(
      moment(this.shiftEndTime).format('YYYY-MM-DD ') +
        this.shiftsForm.get('neitherendTime').value +
        ':00 ' + this.gmtOffset
    );
    this.remarkMessage = this.shiftsForm.get('neitherRemarks').value;

    if (this.singleTimeSheet.attendance) {
      this.break = this.singleTimeSheet.attendance.breakTime;
    } else if (this.singleTimeSheet.attendance) {
      this.break = [];
    }

    this.singleTimeSheet = this.singleTimeSheet;
    if (this.uniqueIdShift === 1) {
      if (this.singleTimeSheet.attendance) {
        this.approvalObj = {
          businessUnitId: this.singleTimeSheet.businessUnitId,
          clocked: false,
          neither: false,
          shift: true,
          breakTime: this.break,
          approveClockInTime:
            this.singleTimeSheet.shiftDetails.isExtendedShift &&
            this.singleTimeSheet.shiftDetails.extendedStaff.length > 0
              ? this.singleTimeSheet.shiftDetails.extendedStaff[0].startDateTime
              : this.singleTimeSheet.shiftDetails.startTime,
          approveClockOutTime:
            this.singleTimeSheet.shiftDetails.isExtendedShift &&
            this.singleTimeSheet.shiftDetails.extendedStaff.length > 0
              ? this.singleTimeSheet.shiftDetails.extendedStaff[0].endDateTime
              : this.singleTimeSheet.shiftDetails.endTime,
          shiftDetailId: this.singleTimeSheet.shiftDetails._id,
          shiftId: this.singleTimeSheet.shiftDetails.shiftId,
          userId: this.singleTimeSheet.userInfo._id,
          neitherMessage: this.remarkMessage,
          _id: this.singleTimeSheet.attendance._id,
        };
      } else if (!this.singleTimeSheet.attendance) {
        this.approvalObj = {
          businessUnitId: this.singleTimeSheet.businessUnitId,
          clocked: false,
          neither: false,
          shift: true,
          breakTime: this.break,
          neitherMessage: this.remarkMessage,
          approveClockInTime:
          this.singleTimeSheet.shiftDetails.isExtendedShift &&
          this.singleTimeSheet.shiftDetails.extendedStaff.length > 0
            ? this.singleTimeSheet.shiftDetails.extendedStaff[0].startDateTime
            : this.singleTimeSheet.shiftDetails.startTime,
        approveClockOutTime:
          this.singleTimeSheet.shiftDetails.isExtendedShift &&
          this.singleTimeSheet.shiftDetails.extendedStaff.length > 0
            ? this.singleTimeSheet.shiftDetails.extendedStaff[0].endDateTime
            : this.singleTimeSheet.shiftDetails.endTime,
          shiftDetailId: this.singleTimeSheet.shiftDetails._id,
          shiftId: this.singleTimeSheet.shiftDetails.shiftId,
          userId: this.singleTimeSheet.userInfo._id,
        };
      }
    } else if (this.uniqueIdShift === 2) {
      this.approvalObj = {
        businessUnitId: this.singleTimeSheet.businessUnitId,
        clocked: true,
        neither: false,
        shift: false,
        breakTime: this.break,
        approveClockInTime: this.singleTimeSheet.attendance.clockInDateTime,
        approveClockOutTime: this.singleTimeSheet.attendance.clockOutDateTime,
        shiftDetailId: this.singleTimeSheet.shiftDetails._id,
        shiftId: this.singleTimeSheet.shiftDetails.shiftId,
        userId: this.singleTimeSheet.userInfo._id,
        neitherMessage: this.remarkMessage,
        _id: this.singleTimeSheet.attendance._id,
      };
    } else if (this.uniqueIdShift === 3) {
      if (!this.shiftsForm.get('neitherRemarks').value) {
        this.toastService.error("Please enter remarks to approve neither hours.");
        this.timeSheetDataLoader = false;
        return;
      }
      this.approvalObj = {
        businessUnitId: this.singleTimeSheet.businessUnitId,
        clocked: false,
        neither: true,
        shift: false,
        breakTime: this.break,
        approveClockInTime: this.dateForNeither,
        approveClockOutTime: this.enddateForNeither,
        shiftDetailId: this.singleTimeSheet.shiftDetails._id,
        shiftId: this.singleTimeSheet.shiftDetails.shiftId,
        userId: this.singleTimeSheet.userInfo._id,
        neitherMessage: this.remarkMessage,
        _id: this.singleTimeSheet.attendance?._id,
      };
    }
    this.modelClose();
    this.timesheetService
      .approvalTimeSheet(this.approvalObj)
      .subscribe((res) => {
        this.timeSheetData = res['data'];
        this.getTimesheetHistory();
        this.timeSheetDataLoader = false;
        this.toastService.success('Shift approved successfully');
      },
      (error: any) => {
        this.toastService.error("Something went wrong. Please try again.");
        this.timeSheetDataLoader = false;
      });
  }

  checkAllShiftLock(i) {
    this.checkShift = i;
  }

  exportHistoryCSV() {
    const shiftFilter = this.shiftsFormFilter.value;
    const startdate = moment()
      .year(shiftFilter.startDateFilter.year)
      .month(shiftFilter.startDateFilter.month - 1)
      .date(shiftFilter.startDateFilter.day);

    const enddate = moment()
      .year(shiftFilter.endDateFilter.year)
      .month(shiftFilter.endDateFilter.month - 1)
      .date(shiftFilter.endDateFilter.day);

    const historyCsvPayload = {
      endDate: moment(enddate).format('DD-MM-YYYY'),
      startDate: moment(startdate).format('DD-MM-YYYY'),
    };

    this.timesheetService
      .exportHistoryTSData(this.businessUnitId._id, historyCsvPayload)
      .subscribe((res:any) => {
        var a = document.createElement('a');
        a.href = 'data:attachment/csv,' +encodeURI(res.csv);
        a.target = '_blank';
        a.download = "historical_timesheet.csv";
        document.body.appendChild(a);
        a.click();
       this.toastService.success(
          "success",
          "timesheet Details exported succesfully"
        );
      });
  }

  lockAllShiftBtn() {
    this.allShift = [];
    this.timeSheetData.forEach((val) => {
      if (Object.keys(val).includes('attendance')) {
        if (
          (val.attendance.approval.shift == true ||
            val.attendance.approval.clocked == true ||
            val.attendance.approval.neither == true ||
            val.attendance.isAutoApprove == true) &&
          val.attendance.IsLock == false
        ) {
          this.allShift.push(val.attendance._id);
        }
      }
    });

    this.allShiftArray = {
      ids: this.allShift,
    };

    this.timesheetService.lockShift(this.allShiftArray).subscribe((res) => {
      this.timeSheetData = res['data'];
      this.toastService.success(res['message']);
      this.LockChecked = false;
      this.getTimesheetHistory();
    });
  }

  breakTime() {
    this.singleTimeSheet = this.singleTimeSheet;

    this.breakTimeObj = {
      endTime:
        moment(this.singleTimeSheet.shiftDetails.startTime).format(
          'MM-DD-YYYY '
        ) +
        this.shiftsForm.get('inputendTime').value +
        ':00 ' + this.gmtOffset,

      shiftDetailId: this.singleTimeSheet.shiftDetails._id,
      startTime:
        moment(this.singleTimeSheet.shiftDetails.startTime).format(
          'MM-DD-YYYY '
        ) +
        this.shiftsForm.get('inputstartTime').value +
        ':00 ' + this.gmtOffset,
      userId: this.singleTimeSheet.userInfo._id,
    };

    this.timesheetService.breakTimeAdd(this.breakTimeObj).subscribe((res) => {
      this.timeSheetData = res['data'];
      this.toastService.success(res['message']);
      this.getTimesheetHistory();
    });
  }

  lockFunction(d) {
    this.singleData = d;

    this.lockObj = { _id: this.singleData.attendance._id };

    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'Back',
    }).then((result) => {
      if (result.isConfirmed) {
        this.timesheetService.lock(this.lockObj).subscribe((res) => {
          this.toastService.success(res['message']);
          this.getTimesheetHistory();
        });
      }
    });
  }

  isKeyAvailable = (item, key) => {
    if (item && key) {
      return Object.keys(item).includes(key);
    }
  };

  toggleDisplay() {
    this.isShow = !this.isShow;
  }

  getDuration(time) {
    if (time) {
      time = parseFloat(time);
      time = time * 1000 * 3600;
      const date = new Date(time);
      let hh = date.getUTCHours();
      let min = 0;
      if (date.getUTCMinutes() === 59) {
        min = 0;
        hh = hh + 1;
      } else {
        min = date.getUTCMinutes();
      }
      return '' + hh + 'h:' + min + 'min';
    } else {
      return '0';
    }
  }
  toggleApprovalDetails(data, i) {
    if (this.isApproveShowHisDet === i) {
      return (this.isApproveShowHisDet = '');
    }
    return (this.isApproveShowHisDet = i);
  }
  toggleBreakDetails(data, i) {
    if (this.isBreakShowHisDet === i) {
      this.showBtn = true;
      return (this.isBreakShowHisDet = '');
    }
    this.showBtn = false;
    return (this.isBreakShowHisDet = i);
  }
  modelClose() {
    this.getTimesheetHistory();
    this.modelService.dismissAll();
  }
}
