import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import {
  Component,
  EventEmitter,
  Injectable,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import {
  NgbDateParserFormatter,
  NgbDateStruct,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { LeavePlannerService } from 'src/app/users/service/human-resources/leave-planner.service';
import { UsersService } from 'src/app/users/service/user-service/users.service';
import { ToastrService } from 'ngx-toastr';
import { CompanySetupService } from 'src/app/users/service/company-setup/company-setup.service';
import { isThursday } from 'date-fns';
import { OpsGroupService } from 'src/app/users/service/ops-group/ops-group.service';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '-';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year
      : '';
  }
}

@Component({
  selector: 'create-ballot',
  templateUrl: './create-ballot.component.html',
  styleUrls: ['./create-ballot.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class CreateBallotComponent implements OnInit {
  @Input() ballot;
  @Input() roundBallot;
  @Input() autoBallot;
  @Output() back = new EventEmitter<any>();
  isLinear = false;
  active = 1;
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('table') table: MatTableModule;
  ballotdata: FormGroup;
  slotQuotaSetup: FormGroup;
  restrictLimit: FormGroup;
  admin: FormGroup;
  opsList: any;
  time = [];
  slotWeeks: any[];
  opsGrp: any;
  allOps: any[];
  slotinput = [];
  allinput = [];
  slot;
  CustomFieldPTable;
  fieldNeedsToUpdate: any;
  CustomFieldDropDownLoading: boolean = false;
  loggedUser: any;
  slotformat;
  resStaff = '';
  resGrp = [];
  allTeam = [];
  resTeam = [];
  allStaff = [];
  resStaffArr = [];
  resultReleaseDate;
  resultReleaseTime = null;
  comDetail: any;
  blockLeave: string;
  hidden = 'hidden';
  weekRange: any[] = [];
  autoBallotStaff: Object;
  noTeam: boolean;
  openingTimePassed: boolean;
  ballotdataSubmitted: boolean = false;
  slotQuotaSetupSubmitted: boolean = false;
  publishBallotLoader: boolean = false;
  saveAsDraftLoader: boolean = false;
  resultDateTimeCheck: boolean;
  loader: boolean;
  todayDate = new Date();
  addStaffFlag: boolean = false;
  futureMinDate: any = {
    year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate()
  };
  closingMinDate: any = {
    year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate()
  }
  constructor(
    private fb: FormBuilder,
    private leaveService: LeavePlannerService,
    private companyService: CompanySetupService,
    private userService: UsersService,
    public toastService: ToastrService,
    private _parserFormatter: NgbDateParserFormatter,
    private opsService: OpsGroupService
  ) {


    this.CustomFieldPTable = {
      sortBy: 'desc',
      sortWith: 'createdBy',

      data: [],
      page: 1,
      total: 0,
      limit: 8,

      filter: {
        status: ['active'],

        fields: ['name'],
      },
      search: '',
    };
  }

  ngOnInit(): void {
    this.loadCustomField();

    this.ballotdata = this.fb.group(
      {
        ballotName: ['', Validators.required],
        applicationOpenDateTime: '',
        applicationCloseDateTime: '',
        openDate: ['', Validators.required],
        closeDate: ['', Validators.required],
        openTime: [null, Validators.required],
        closeTime: [null, Validators.required],
        timeZone: moment().format('[GMT]ZZ'),
        ballotStartDate: ['', Validators.required],
        ballotEndDate: ['', Validators.required],
        leaveType: '1',
        leaveConfiguration: '1',
        resultRelease: ['2', Validators.required],
        resultReleaseDate: '',
        resultReleaseDateTime: '',
        resultReleaseTime: '',
        fixedBallotingLeaveType: false,
        totalQuota: 0,
      },
      { validators: this.fixedValidn }
    );
    this.slotQuotaSetup = this.fb.group({
      opsGroupId: ['', Validators.required],
      slotCreation: this.fb.array([]),
    });
    this.dateChange();
    this.restrictLimit = this.fb.group({
      isRestrict: 'true',
      maxSegment: this.fb.array([]),
      maxConsecutiveBallot: '',
      staffRestriction: this.fb.array([]),
    });

    this.admin = this.fb.group({
      adminId: this.fb.array([]),
    });

    this.getOpsLeave();
    this.selectTime();

    this.getuser();
    this.releaseSetupValidation();
    if (this.ballot) {

      if (this.roundBallot != null) {
        this.editBallot(this.roundBallot);
      } else {
        this.editBallot(this.ballot);
      }
    }

    if (this.roundBallot) {

      this.ballot = this.roundBallot;
      this.ballotdata.disable();
      this.ballotdata.controls.openDate.enable();
      this.ballotdata.controls.closeDate.enable();
      this.ballotdata.controls.openTime.enable();
      this.ballotdata.controls.closeTime.enable();

      this.ballotdata.patchValue({
        openDate: null,
        closeDate: null,
        openTime: null,
        closeTime: null,
      });

      this.restrictLimit.patchValue({
        isRestrict: this.roundBallot.isRestrict.toString(),
      });

      this.restrictLimit.disable();
    }

    if (this.autoBallot) {
      this.ballot = this.autoBallot.data;
      this.ballotdata.disable();
     this.restrictLimit.patchValue({
        isRestrict: this.autoBallot.data.isRestrict.toString(),
      });
      this.restrictLimit.disable();
      this.autoAssignBallot(this.autoBallot.data);
      this.leaveService
        .getAutoAssignStaff(this.ballot.parentBallot)
        .subscribe((res) => {
          this.autoBallotStaff = res;
        });

    }

    this.getLeaveConfig();
  }

  dateChange() {
    this.ballotdata.get('openDate').valueChanges.subscribe((res) => {
      let dataObj = new Date(res?.year, res?.month-1, res?.day)
      this.closingMinDate = { year: dataObj?.getFullYear(), month: dataObj?.getMonth(), day: dataObj?.getDate() }
    })

  }

  releaseSetupValidation() {
    this.ballotdata.get('resultRelease').valueChanges.subscribe((val) => {
      if (val == '1') {
        this.ballotdata.controls['resultReleaseDate'].setValidators([
          Validators.required,
        ]);
        this.ballotdata.controls['resultReleaseDateTime'].setValidators([
          Validators.required,
        ]);
        this.ballotdata.controls['resultReleaseTime'].setValidators([
          Validators.required,
        ]);
      } else {
        this.ballotdata.controls['resultReleaseDate'].clearValidators();
        this.ballotdata.controls['resultReleaseDateTime'].clearValidators();
        this.ballotdata.controls['resultReleaseTime'].clearValidators();
      }
      this.ballotdata.controls['resultReleaseDate'].updateValueAndValidity();
      this.ballotdata.controls['resultReleaseTime'].updateValueAndValidity();
    });
  }

  autoAssignBallot(i) {
    this.ballotdata.patchValue({
      ballotName: i.ballotName,
    });
  }

  getLeaveConfig() {
    this.companyService.getcompanySetting().subscribe((res) => {
      this.comDetail = res.data;

      switch (this.comDetail.opsGroup.blockLeaveConfiguration) {
        case 1:
          this.blockLeave = '5+2';
          this.ballotdata.patchValue({
            leaveConfiguration: '1',
          });
          break;
        case 2:
          this.blockLeave = '6+1';
          this.ballotdata.patchValue({
            leaveConfiguration: '2',
          });
          break;
        case 3:
          this.blockLeave = '7+0';
          this.ballotdata.patchValue({
            leaveConfiguration: '3',
          });
          break;
        default:
          this.blockLeave = '';
      }
    });
  }

  editBallot(i) {
    this.ballotdata.patchValue({
      ballotName: i.ballotName,
      applicationOpenDateTime: this.momToNgb(i.applicationOpenDateTime),
      applicationCloseDateTime: this.momToNgb(i.applicationCloseDateTime),
      openDate: this.momToNgb(i.applicationOpenDateTime),
      closeDate: this.momToNgb(i.applicationCloseDateTime),
      openTime: i.openTime,
      closeTime: i.closeTime,
      timeZone: moment().format('[GMT]ZZ'),
      ballotStartDate: this.momToNgb(i.ballotStartDate),
      ballotEndDate: this.momToNgb(i.ballotEndDate),
      leaveType: i.leaveType.toString(),
      leaveConfiguration: i.leaveConfiguration.toString(),
      resultRelease: i.resultRelease.toString(),
    });

    //prefill leaveType Day ballot fixed ballot checkbox and quota
    if (i.leaveType == 2) {
      this.ballotdata.patchValue({
        fixedBallotingLeaveType: i.fixedBallotingLeaveType,
        totalQuota: i.totalQuota,
      });
    }

    if (i.resultRelease == 1) {
      this.ballotdata.patchValue({
        resultReleaseDate: this.momToNgb(i.resultReleaseDate),
        resultReleaseDateTime: moment(i.resultReleaseDateTime).format(
          'MM-DD-YYYY [00:00:00 GMT]ZZ'
        ),
        resultReleaseTime: i.resultReleaseTime,
      });
    }

    this.restrictLimit.patchValue({
      isRestrict: i.isRestrict.toString(),
    });

    if (i.isRestrict) {
      i.maxSegment.forEach((val, i) => {
        let ar = this.fb.group({
          segmentNo: val.segmentNo,
          startDate: this.momToNgb(val.startDate),
          endDate: this.momToNgb(val.endDate),
          maxBallot: val.maxBallot,
        });
        this.maxSegment.push(ar);

        this.maxSegment.at(i).disable();
      });

      this.restrictLimit.patchValue({
        maxConsecutiveBallot: i.maxConsecutiveBallot,
      });
      i.staffRestriction?.forEach((staffObj, index) => {
        staffObj.startDate = moment(staffObj.startDate).format('MM-DD-YYYY HH:mm:ss [GMT]ZZ');
        staffObj.endDate = moment(staffObj.endDate).format('MM-DD-YYYY HH:mm:ss [GMT]ZZ');
        staffObj.showDate = staffObj.slot;
        const staffRes = i.staffRestriction[index]?.userList?.map(
          ({ label, _id, id }) => ({
            name: label,
            label: label,
            _id: _id,
            id: id
          })
        );

        i.staffRestriction[index]['userList'] = staffRes;
      });
      this.resStaffArr = i.staffRestriction;
    }
    this.slotQuotaSetup.controls.opsGroupId.disable();
  }

  momToNgb(d) {
    let md = moment(d);
    const dd = {
      year: md.year(),
      month: md.month() + 1,
      day: md.date(),
    };
    return dd;
  }

  ngbToMoment(d) {
    return moment().set({
      year: d.year,
      month: d.month - 1, //ngbdatepicker month starts from 1
      date: d.day,
    });
  }

  checkTime() {
    this.openingTimePassed = false;
    if (
      this.ballotdata.get('openDate').value &&
      this.ballotdata.get('openTime').value
    ) {
      const selectedOpenDate = this.ngbToMoment(
        this.ballotdata.get('openDate').value
      ).set({
        hour: this.ballotdata.get('openTime').value.slice(0, 2),
        minute: this.ballotdata.get('openTime').value.slice(-2),
      });

      if (!this.autoBallot) {
        if (moment().isAfter(selectedOpenDate)) {
          this.toastService.error(
            'Application Open Time should be more than current time',
            'Error',
            { timeOut: 5000, progressBar: true }
          );
          this.openingTimePassed = true;
        }
      }

    }
  }

  resultReleaseTimeCheck() {
    this.resultDateTimeCheck = false;
    let resultReleaseDate;
    let closeDate;
    let ballotStartDate;
    if (this.ballotdata.get('resultRelease').value == '1') {
      if (
        this.ballotdata.get('resultReleaseDate').value &&
        this.ballotdata.get('resultReleaseTime').value
      ) {
        resultReleaseDate = this.ngbToMoment(
          this.ballotdata.get('resultReleaseDate').value
        ).set({
          hour: this.ballotdata.get('resultReleaseTime').value.slice(0, 2),
          minute: this.ballotdata.get('resultReleaseTime').value.slice(-2),
        });

        if (
          this.ballotdata.get('closeDate').value &&
          this.ballotdata.get('closeTime').value
        ) {
          closeDate = this.ngbToMoment(
            this.ballotdata.get('closeDate').value
          ).set({
            hour: this.ballotdata.get('closeTime').value.slice(0, 2),
            minute: this.ballotdata.get('closeTime').value.slice(-2),
          });
        }

        if (this.ballotdata.get('ballotStartDate').value) {
          ballotStartDate = this.ngbToMoment(
            this.ballotdata.get('ballotStartDate').value
          ).set({
            hour: 0,
            minute: 0,
          });
        }

        if (!moment(resultReleaseDate).isBetween(closeDate, ballotStartDate)) {
          this.toastService.error(
            'Result Release Date and Time should be between Closing date and Ballot Start date',
            'Error',
            { timeOut: 5000, progressBar: true }
          );
          this.resultDateTimeCheck = true;
        }
      }
    }
  }

  publishBallot(isDraft?: boolean) {
    this.checkTime();
    this.resultReleaseTimeCheck();

    if (
      (this.openingTimePassed || this.resultDateTimeCheck) &&
      !this.autoBallot
    ) {
      return;
    }

    let wk = [];
    this.slotWeeks.forEach((val) => {
      if (this.ballotdata.controls.leaveType.value == '1') {
        wk.push({
          start: val[0].format('YYYY-MM-DD'),
          end: val[1].format('YYYY-MM-DD'),
        });
      } else {
        wk.push({
          start: val.format('YYYY-MM-DD'),
          end: val.format('YYYY-MM-DD'),
        });
      }
    });
    this.weekRange = wk;

    this.ballotdata.patchValue({
      applicationOpenDateTime: this.ngbToMoment(
        this.ballotdata.get('openDate').value
      )
        .set({
          hour: this.ballotdata.get('openTime').value.slice(0, 2),
          minute: this.ballotdata.get('openTime').value.slice(-2),
        })
        .format('MM-DD-YYYY HH:mm:ss [GMT]ZZ'),
      applicationCloseDateTime: this.ngbToMoment(
        this.ballotdata.get('closeDate').value
      )
        .set({
          hour: this.ballotdata.get('closeTime').value.slice(0, 2),
          minute: this.ballotdata.get('closeTime').value.slice(-2),
        })
        .format('MM-DD-YYYY HH:mm:ss [GMT]ZZ'),
      openDate: this.ngbToMoment(this.ballotdata.get('openDate').value).format(
        'MM-DD-YYYY'
      ),
      closeDate: this.ngbToMoment(
        this.ballotdata.get('closeDate').value
      ).format('MM-DD-YYYY'),
      ballotStartDate: this.ngbToMoment(
        this.ballotdata.get('ballotStartDate').value
      ).format('MM-DD-YYYY'),
      ballotEndDate: this.ngbToMoment(
        this.ballotdata.get('ballotEndDate').value
      ).format('MM-DD-YYYY'),
    });
   const opsG = [];
    this.slotQuotaSetup
      .get('opsGroupId')
      .value.forEach((el) => opsG.push(el._id));

    this.slotQuotaSetup.patchValue({
      opsGroupId: opsG,
    });

    const adminId = [];
    this.admin.get('adminId').value.forEach((el) => adminId.push(el._id));

    let ballotPayload = {};
    if (this.slotQuotaSetup.controls.slotCreation.value?.length == 0) {
      this.getSlotQuotaSetup();
    }

    ballotPayload = {
      ...this.ballotdata.getRawValue(),
      ...this.slotQuotaSetup.getRawValue(),
    };

    ballotPayload['weekRange'] = this.weekRange;
    ballotPayload['staffRestriction'] = this.resStaffArr;
    ballotPayload['adminId'] = adminId;
    ballotPayload['resultReleaseAdminId'] = adminId[0].name;
    ballotPayload['isDraft'] = isDraft ? true : false;
    ballotPayload['userFrom'] = '1';
    ballotPayload['isRestrict'] = this.restrictLimit.get('isRestrict').value;

    if (this.restrictLimit.get('isRestrict').value == 'true') {
      ballotPayload['maxConsecutiveBallot'] = this.restrictLimit.get(
        'maxConsecutiveBallot'
      ).value;

      let maxArr = [];
      this.restrictLimit.get('maxSegment').value.forEach((v, i) => {
        let max = {
          segmentNo: v.segmentNo,
          startDate: this.ngbToMoment(v.startDate).format(
            'MM-DD-YYYY [00:00:00 GMT]ZZ'
          ),
          endDate: this.ngbToMoment(v.endDate).format(
            'MM-DD-YYYY [00:00:00 GMT]ZZ'
          ),
          maxBallot: v.maxBallot,
        };

        maxArr.push(max);
      });
      ballotPayload['maxSegment'] = maxArr;
    }

    if (this.ballotdata.get('resultRelease').value == '1') {
      ballotPayload['resultReleaseDate'] = this.ngbToMoment(
        this.ballotdata.get('resultReleaseDate').value
      ).format('MM-DD-YYYY');

      ballotPayload['resultReleaseDateTime'] = this.ngbToMoment(
        this.ballotdata.get('resultReleaseDate').value
      ).format('MM-DD-YYYY [00:00:00 GMT]ZZ');
    } else {
      delete ballotPayload['resultReleaseDate'];
      delete ballotPayload['resultReleaseDateTime'];
      delete ballotPayload['resultReleaseTime'];
    }

    //leaveType Day ballot fixed ballot checkbox and quota
    if (this.ballotdata.get('leaveType').value == '1') {
      delete ballotPayload['fixedBallotingLeaveType'];
      delete ballotPayload['totalQuota'];
    }
    this.publishBallotLoader = isDraft ? false : true;
    this.saveAsDraftLoader = isDraft ? true : false;
    if (!this.ballot) {
      const addballot = this.leaveService
        .addBallot(ballotPayload)
        .pipe(
          finalize(() => {
            this.publishBallotLoader = false;
            this.saveAsDraftLoader = false;
            addballot.unsubscribe();
          })
        )
        .subscribe((res: { status }) => {
          if (res.status) {
            this.toastService.success(
              'Ballot created Successfully',
              'Successfull'
            );
            this.back.emit(1);
          } else {
            if (res['message']) {
              this.toastService.error(res['message'], 'Failed', {
                timeOut: 5000,
                progressBar: true,
              });
            } else {
              this.toastService.error('Ballot creation failed', 'Failed');
            }
          }
        });
    } else if (this.roundBallot) {
      ballotPayload['parentBallot'] = this.roundBallot.parentBallot;
      ballotPayload['ballotRound'] = this.roundBallot.ballotRound + 1;

      const roundBallot = this.leaveService
        .addBallot(ballotPayload)
        .pipe(
          finalize(() => {
            this.publishBallotLoader = false;
            this.saveAsDraftLoader = false;
            roundBallot.unsubscribe();
          })
        )
        .subscribe((res: { status }) => {
          if (res.status) {
            this.toastService.success(
              'Re-ballot created successfully',
              'Successfull'
            );
            this.back.emit(1);
          } else {
            if (res['message']) {
              this.toastService.error(res['message'], 'Failed');
            } else {
              this.toastService.error('Re-Ballot creation failed', 'Failed');
            }
          }
        });
    } else if (this.autoBallot) {
      this.loader = true;
      ballotPayload['isAutoAssign'] = true;
      ballotPayload['RATio'] = this.autoBallot.data.RATio;
      ballotPayload['TotBQ'] = this.autoBallot.data.TotBQ;
      ballotPayload['assignRatio'] = this.autoBallot.data.assignRatio;
      ballotPayload['totUN'] = this.autoBallot.data.totUN;
      ballotPayload['isCanceled'] = this.autoBallot.data.isCanceled;
      ballotPayload['isConduct'] = this.autoBallot.data.isConduct;
      ballotPayload['isDeleted'] = this.autoBallot.data.isDeleted;
      ballotPayload['isNotified'] = this.autoBallot.data.isNotified;
      ballotPayload['isPublish'] = this.autoBallot.data.isPublish;
      ballotPayload['ballotRound'] = this.autoBallot.data.ballotRound + 1;
      ballotPayload['parentBallot'] = this.autoBallot.data.parentBallot;
      ballotPayload['appliedStaff'] = this.autoBallot.data.appliedStaff;
      ballotPayload['childBallots'] = this.autoBallot.data.childBallots;
      ballotPayload['dayRange'] = this.autoBallot.data.dayRange;
      ballotPayload['deletedStaff'] = this.autoBallot.data.deletedStaff;
      ballotPayload['isResultRelease'] = this.autoBallot.data.isResultRelease;
      ballotPayload['eligibleStaffsForAuto'] = this.autoBallotStaff['data'];
      if(this.ballotdata.controls.leaveType.value == '2'){
        ballotPayload['leaveTypeId']=this.autoBallot.data.leaveTypeId;
      }



      const autoBallot = this.leaveService
        .addBallot(ballotPayload)
        .pipe(
          finalize(() => {
            this.publishBallotLoader = false;
            this.saveAsDraftLoader = false;
            autoBallot.unsubscribe();
          })
        )
        .subscribe((res) => {
          this.loader = false;
          this.toastService.success(
            'Auto-Assign Ballot created Successfully',
            'Successfull'
          );
          this.back.emit(1);
        });
    } else {
      ballotPayload['id'] = this.ballot._id;

      const updateBallot = this.leaveService
        .updataBallot(ballotPayload)
        .pipe(
          finalize(() => {
            this.publishBallotLoader = false;
            this.saveAsDraftLoader = false;
            updateBallot.unsubscribe();
          })
        )
        .subscribe((res) => {
          this.toastService.success(
            'Ballot Updated Successfully',
            'Successfull'
          );
          this.back.emit(1);
        });
    }
  }

  createPayloadForUser(staffArr) {
    let userArr = [];
    staffArr?.map(item => {
      let obj = {
        id: item._id,
        label: item.name,
        parentBussinessUnitId: item.parentBussinessUnitId?._id,
        name: item.name
      }
      userArr.push(obj);
    });
    return userArr;
  }

  addResStaff() {
    this.addStaffFlag = true;
    if (this.validateRequiredField()) {
      let tempRes;
      this.addStaffFlag = false;
      let slotIndex = this.slotWeeks?.map((val, index) => {
        if (this.slotformat === val) {
          return this.ballotdata.controls.leaveType.value == '1' ? 'Slot--' + (index + 1) + ' ' + val[0]?.format("DD-MMM") + '--' +
            val[1].format("DD-MMM") : 'Slot--' + (index + 1) + ' ' +val?.format("DD-MMM");
        }
      });
      slotIndex = slotIndex.filter(function (element) {
        return element !== undefined;
      });
      if (this.ballotdata.controls.leaveType.value == '1') {
        tempRes = {
          slot: slotIndex[0],
          showDate: slotIndex[0],
          startDate: this.slotformat[0].format('MM-DD-YYYY [00:00:00 GMT]ZZ'),
          endDate: this.slotformat[1].format('MM-DD-YYYY [00:00:00 GMT]ZZ'),
          userList: this.createPayloadForUser(this.resStaff),
        };
      } else {
        tempRes = {
          slot: slotIndex[0],
          showDate: slotIndex[0],
          startDate: this.slotformat.format('MM-DD-YYYY [00:00:00 GMT]ZZ'),
          endDate: this.slotformat.format('MM-DD-YYYY [00:00:00 GMT]ZZ'),
          userList: this.createPayloadForUser(this.resStaff),
        };
      }
      this.resStaffArr.push(tempRes);
      this.slotformat = null;
    }
  }

  resSlotChange() {
    this.allStaff = [];
    this.allTeam = [];
    this.resStaff = null;
    this.resGrp = [];
    this.resTeam = [];
    this.opsGrp?.map(item => {
      item.isSelected = false;
    })
  }

  getInt(i) {
    return parseInt(i);
  }

  removeStaff(i) {
    this.resStaffArr.splice(i, 1);
  }

  selectStaff(e, i, team) {
    if (e.target.checked) {
      this.resTeam.push(team);
    } else {
      const ii = this.resTeam.indexOf(team._id);
      this.resTeam.splice(ii, 1);
    }
    const payload = {
      opsGroupId: "5d65460b8a8c0642f2bbc98d",
      opsTeamId: this.resTeam?.map(item => { return item._id })
    }
    this.opsService.getStaffListBasedOnOpsGroup(payload).subscribe((res: any) => {
      this.allStaff = res?.data || [];
    });
    // } else {
    //   const tempStaff = this.allStaff;
    //   this.allStaff.forEach((v) => {
    //     this.allTeam[i].userId.forEach((vv) => {
    //       const indexx = tempStaff.findIndex((item) => item._id === vv._id);
    //       if (indexx > -1) {
    //         tempStaff.splice(indexx, 1);
    //       }
    //     });
    //   });

    // this.allStaff = tempStaff;
    this.allStaff.map((val) => {
      if (!val.hasOwnProperty('label')) {
        val['label'] = val.name;
      }
    });




  }

  selectedResGrp(e, i, ops) {
    const selectedOps = this.slotQuotaSetup.controls.opsGroupId.value;
    if (e.target.checked) {
      this.resGrp.push(ops);
      if (selectedOps[i].opsTeamId?.length) {
        selectedOps[i].opsTeamId.forEach((tt) => {
          tt.isSelected = false;
          this.allTeam.push(tt);
        });


      } else if (selectedOps[i].userId?.length) {
        this.noTeam = true;
      }
    } else {
      const ii = this.resGrp.indexOf(ops);
      this.resGrp.splice(ii, 1);
    }

    if (this.resGrp?.length > 0) {
      const payload = {
        isOpsGroup: true,
        opsGroupId: selectedOps?.map(item => {
          return item._id
        })
      }
      this.allStaff = [];
      this.leaveService.getBallotUser(payload).subscribe((res: any) => {
        this.allStaff = res?.data || [];
        this.allStaff?.map((val) => {
          if (!val.hasOwnProperty('label')) {
            val['label'] = val.name;
          }
        });
      })
    }


  }

  getAdmin() {
    this.userService.getUserData('');
  }

  loadMoreCustomFieldItems(value) {
    this.CustomFieldPTable.limit = this.CustomFieldPTable.limit + 10;
    this.loadCustomField();
  }

  onSeachCustomField(value) {
    this.CustomFieldPTable.limit = 10;
    this.CustomFieldPTable.search = value.term;
    this.loadCustomField();
  }

  onClearCustomFieldDropdown(value) {
    if (value === undefined) {
      this.admin.patchValue({
        loginFields: [],
      });
      this.CustomFieldPTable.search = '';
      // this.loadCustomField();

      // this.fieldNeedsToUpdate = [];
      // this.CustomFieldPTable.data = [];
    }
  }

  deleteSelectedFields(index) {
    const Control = this.LoginFieldsControl;
    Control.removeAt(index);
  }

  loadCustomField() {
    // const { data, loading, total, ...rest } = this.CustomFieldPTable;
    const subscribe = this.leaveService
      .getBallotAdminList()
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          // const { count, data } = response.data;
          this.CustomFieldPTable.data = response.data || [];
          this.fieldNeedsToUpdate = response.data || [];
          this.CustomFieldPTable.total = response.data?.length || 0;
        },
        (error) => {
          this.CustomFieldPTable.data = [];
          this.CustomFieldPTable.total = 0;
          // this.tostService.error('No list found');
        }
      );
  }

  get LoginFieldsControl(): FormArray {
    return this.admin.get('adminId') as FormArray;
  }


  getuser() {
    this.userService.getUserProfile().subscribe((res) => {
      this.loggedUser = res.data;

      const Control = this.LoginFieldsControl;
      this.clearFormArray(Control);

      if (!this.ballot) {
        let field = this.fb.group({
          string: [
            this.loggedUser.name,
            Validators.compose([Validators.required]),
          ],
          _id: [this.loggedUser._id, Validators.compose([Validators.required])],
        });
        Control.push(field);
      } else {
        this.ballot.adminId.forEach((v) => {
          let field = this.fb.group({
            string: [v.name, Validators.compose([Validators.required])],
            _id: [v._id, Validators.compose([Validators.required])],
          });
          Control.push(field);
        });
      }
    });
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray?.length !== 0) {
      formArray.removeAt(0);
    }
  };

  async addLoginFields(value) {
    const Control = this.LoginFieldsControl;
    const index = await Control.value.findIndex((x) => x._id === value?._id);


    if (index > -1) {
    } else {
      let field;

      field = this.fb.group({
        string: [value?.name],
        _id: [value?._id],
      });

      Control.push(field);
    }
  }

  getSlotQuotaSetup() {

    this.clearArray(this.slotCreation());

    this.opsGrp?.forEach((v, i) => {
      let grp = {
        opsId: v._id,
        value: v.opsGroupName,
        colspan: 3,
      };

      let ss = [];

      this.allOps.forEach((opsGrp) => {
        if (v._id == opsGrp.OpsGrpId) {
          ss.push(opsGrp.slots);
        }
      });

      let ww = {};
      ss.forEach((k, n) => {
        let o = [];
        o = Object.keys(k);

        o.forEach((ov, oi) => {
          if (n == 0) {
            //leave type 1 & 2 is week & day wise slot
            if (this.ballotdata.controls.leaveType.value == '1') {
              if (this.autoBallot) {
                ww[oi + 'A'] = {
                  value: parseInt(k[ov][0]),
                  startDate: this.slotWeeks[ov][0].format('YYYY-MM-DD'),
                  balanceToBeAssigned: parseInt(k[ov][0]),
                };
              } else {
                ww[oi + 'A'] = {
                  value: k[ov],
                  startDate: this.slotWeeks[ov][0].format('YYYY-MM-DD'),
                };
              }
            } else {
              if (this.autoBallot) {
                ww[oi + 'A'] = {
                  value: parseInt(k[ov][0]),
                  startDate: this.slotWeeks[ov].format('YYYY-MM-DD'),
                  balanceToBeAssigned: parseInt(k[ov][0]),
                };
              } else {
                ww[oi + 'A'] = {
                  value: k[ov],
                  startDate: this.slotWeeks[ov].format('YYYY-MM-DD'),
                };
              }
            }
          } else {
            if (this.ballotdata.controls.leaveType.value == '1') {
              if (this.autoBallot) {
                ww['OG' + oi + 'OT' + (n - 1).toString()] = {
                  value: parseInt(k[ov][0]),
                  startDate: this.slotWeeks[ov][0].format('YYYY-MM-DD'),
                  balanceToBeAssigned: parseInt(k[ov][0]),
                };
              } else {
                ww['OG' + oi + 'OT' + (n - 1).toString()] = {
                  value: k[ov],
                  startDate: this.slotWeeks[ov][0].format('YYYY-MM-DD'),
                };
              }
            } else {
              if (this.autoBallot) {
                ww['OG' + oi + 'OT' + (n - 1).toString()] = {
                  value: parseInt(k[ov][0]),
                  startDate: this.slotWeeks[ov].format('YYYY-MM-DD'),
                  balanceToBeAssigned: parseInt(k[ov][0]),
                };
              } else {
                ww['OG' + oi + 'OT' + (n - 1).toString()] = {
                  value: k[ov],
                  startDate: this.slotWeeks[ov].format('YYYY-MM-DD'),
                };
              }
            }
          }
        });
      });

      let c;
      if (!this.autoBallot) {
        c = this.fb.group({
          arr: this.fb.array([]),
          weekRangeSlot: ww,
          opsGroup: grp,
          opsTeam: this.fb.array([]),
        });
      } else {
        c = this.fb.group({
          arr: this.fb.array([]),
          weekRangeSlot: ww,
          opsGroup: grp,
          totalBallotBalance:
            this.autoBallot.data.slotCreation[i].totalBallotBalance,
          totalUnassignedIs:
            this.autoBallot.data.slotCreation[i].totalUnassignedIs,
          opsTeam: this.fb.array([]),
        });
      }

      this.slotCreation().push(c);
       v.opsTeamId.forEach((element, index) => {
        let ell = this.fb.group({
          _id: element._id,
          name: element.name

        });

        if(this.autoBallot){
          ell.controls['BallotBalance'] = new FormControl(this.autoBallot.data.slotCreation[i].opsTeam?.find(item=>item._id ===element._id )?.BallotBalance);
          ell.controls['ratioForBalnceQuota'] = new FormControl(this.autoBallot.data.slotCreation[i].opsTeam?.find(item=>item._id ===element._id )?.ratioForBalnceQuota);
          ell.controls['unassignBalanace'] = new FormControl(this.autoBallot.data.slotCreation[i].opsTeam?.find(item=>item._id ===element._id )?.unassignBalanace);
         }

        this.team(i).push(ell);
      });

      this.slotWeeks?.forEach((vv, ii) => {
        if (this.ballotdata.controls.leaveType.value == '1') {
          let el = this.fb.group({
            value: '',
            startDate: vv[0].format('MM-DD-YYYY [00:00:00 GMT]ZZ'),
          });
          this.arr(i).push(el);
        } else {
          let el = this.fb.group({
            value: '',
            startDate: vv.format('MM-DD-YYYY [00:00:00 GMT]ZZ'),
          });
          this.arr(i).push(el);
        }
      });
    });

  }

  slotCreation(): FormArray {
    return this.slotQuotaSetup.get('slotCreation') as FormArray;
  }

  newSlotCreation(): FormGroup {
    return this.fb.group({
      arr: this.fb.array([]),
      weekRangeSlot: this.fb.array([]),
      opsGroup: '',
      opsTeam: '',
    });
  }

  clearArray(arr) {
    while (arr?.length > 0) {
      arr.removeAt(0);
    }
  }

  arr(opsIndex: number): FormArray {
    return this.slotCreation().at(opsIndex).get('arr') as FormArray;
  }

  team(i: number): FormArray {
    return this.slotCreation().at(i).get('opsTeam') as FormArray;
  }

  newarr(): FormGroup {
    return this.fb.group({
      value: '',
      startDate: '',
    });
  }

  get maxSegment(): FormArray {
    return this.restrictLimit.get('maxSegment') as FormArray;
  }

  newMaxSegment(): FormGroup {
    return this.fb.group({
      segmentNo: null,
      startDate: '',
      endDate: '',
      maxBallot: null,
    });
  }

  removeMaxSegment(i: number) {
    this.maxSegment.removeAt(i);
  }

  addSegment() {
    this.maxSegment.push(this.newMaxSegment());
  }

  ngbMomentngb(d, weekday) {
    if (weekday == 's') {
      let tempSd = moment().set({
        year: d.year,
        month: d.month - 1,
        date: d.day,
      });

      const sd = moment(tempSd).startOf('isoWeek');
      const sdd = { year: sd.year(), month: sd.month() + 1, day: sd.date() };
      return sdd;
    } else {
      const tempEd = moment().set({
        year: d.year,
        month: d.month - 1,
        date: d.day,
      });

      const ed = moment(tempEd).endOf('isoWeek');
      const edd = { year: ed.year(), month: ed.month() + 1, day: ed.date() };
      return edd;
    }
  }

  plusSegment(i: number) {
    let sd: Object;
    let ed: Object;
    if (this.ballotdata.get('leaveType').value == '2') {
      sd = this.maxSegment.at(i).get('startDate').value;
      ed = this.maxSegment.at(i).get('endDate').value;
    } else {
      sd = this.ngbMomentngb(this.maxSegment.at(i).get('startDate').value, 's');
      ed = this.ngbMomentngb(this.maxSegment.at(i).get('endDate').value, 'e');
    }

    this.maxSegment.at(i).patchValue({
      segmentNo: i + 1,
      startDate: sd,
      endDate: ed,
    });
    this.maxSegment.at(i).disable();
  }

  editSegment(i: number) {
    this.maxSegment.at(i).enable();
  }

  checkAllSlots(e, i) {
    if (e.target.checked) {
      this.slotWeeks.forEach((v, ii) => {
        this.allOps[i].slots[ii] = this.slot[i];
      });
    }
  }

  getOpsGrp() {
    this.allOps = [];
    this.slot = [];
    this.clearArray(this.slotCreation());
    this.opsGrp = this.slotQuotaSetup.controls.opsGroupId.value;

    this.opsGrp.forEach((val, index) => {
      val.isSelected = false;
      let ballotSlot;
      let slotSortArr;
      if (this.ballot) {
        ballotSlot = this.ballot.slotCreation[index].weekRangeSlot;
        let keyy = Object.keys(ballotSlot);
        slotSortArr = [];
        keyy.forEach((v) => {
          slotSortArr.push([v, ballotSlot[v]]);
        });

      }

      let team = {
        OpsGrp: val.opsGroupName,
        OpsGrpId: val._id,
        OpsTeam: 'Tier 1',
        OpsTeamId: '',
        slots: {},
      };

      if (this.autoBallot) {
        team['BallotBalance'] =
          this.ballot.slotCreation[index].opsGroup.BallotBalance;
        team['unassignBalanace'] =
          this.ballot.slotCreation[index].opsGroup.unassignBalanace;
          team['ratioForBalnceQuota']=this.ballot.slotCreation[index].opsGroup.ratioForBalnceQuota;
      }

      if (this.ballot) {
        const slotGrpVal = [];
        let pk = [];
        slotSortArr.forEach((vv) => {
          if (!this.autoBallot && vv[0].endsWith('A')) {
            let ck = vv[0].slice(0, -1);
            pk.push([ck, vv[1].value]);
          } else if (this.autoBallot && vv[0].endsWith('A')) {
            let ck = vv[0].slice(0, -1);
            pk.push([ck, vv[1].value, vv[1].balanceToBeAssigned]);
          }
        });

        pk.sort((a, b) => {
          let aa = parseInt(a[0]);
          let bb = parseInt(b[0]);
          if (aa < bb) {
            return -1;
          }
          if (aa > bb) {
            return 1;
          }
          //if values are equal
          return 0;
        });
        if (!this.autoBallot) {
          pk.forEach((sv) => slotGrpVal.push(sv[1]));
        } else {
          pk.forEach((sv) => slotGrpVal.push([sv[1], sv[2]]));
        }
        team['slots'] = slotGrpVal;
      }

      this.allOps.push(team);


      if (val.opsTeamId) {

        val.opsTeamId.forEach((vv, opsTeamIndex) => {
          team = {
            OpsGrp: '',
            OpsGrpId: val._id,
            OpsTeam: vv.name,
            OpsTeamId: vv._id,
            slots: {},
          };

          if (this.autoBallot) {
            team['BallotBalance'] =
              this.ballot.slotCreation[index].opsTeam[
                opsTeamIndex
              ].BallotBalance;
            team['unassignBalanace'] =
              this.ballot.slotCreation[index].opsTeam[
                opsTeamIndex
              ].unassignBalanace;

              team['ratioForBalnceQuota']=this.ballot.slotCreation[index].opsGroup.ratioForBalnceQuota;

          }

          if (this.ballot) {
            const slotTeam = [];
            let pTeamm = [];
            this.slotWeeks.forEach((vs, slotsIndex) => {
              let matchKeyValue = slotSortArr.find((slotValue) => {
                if (
                  slotValue[0] ==
                  'OG' + slotsIndex + 'OT' + opsTeamIndex.toString()
                ) {
                  return true;
                }
              });
              pTeamm.push(matchKeyValue);
            });

            if (!this.autoBallot) {
              pTeamm?.map((sv) => {


                slotTeam.push(sv[1]?.value)
              });
            } else {
              pTeamm?.map((sv) =>
                slotTeam.push([sv[1]?.value, sv[1]?.balanceToBeAssigned])
              );
            }

            team['slots'] = slotTeam;
          }

          this.allOps.push(team);

        });
      }
    });

    this.allOps.forEach((val, i) => {
      this.slot[i] = '';
    });

  }

  getSlotWeeks() {
    if (
      this.ballotdata.get('ballotEndDate').value &&
      this.ballotdata.get('ballotStartDate').value
    ) {
      const startDate = this.ballotdata.controls.ballotStartDate.value;
      const endDate = this.ballotdata.controls.ballotEndDate.value;

      const ballotStart = moment()
        .set({
          year: startDate.year,
          month: startDate.month - 1,
          date: startDate.day,
        })
        .startOf('isoWeek');

      const ballotEnd = moment()
        .set({
          year: endDate.year,
          month: endDate.month - 1,
          date: endDate.day,
        })
        .endOf('isoWeek');

      const weekdiff = ballotEnd.diff(ballotStart, 'weeks');

      this.slotWeeks = [];
      for (var i = 0; i <= weekdiff; i++) {
        this.slotWeeks.push([
          moment(ballotStart).add(i, 'week'),
          moment(ballotStart).add(i, 'week').endOf('isoWeek'),
        ]);
      }
    }
  }

  checkOpenCloseTime() {
    if (this.ballotdata.get('openDate').value && this.ballotdata.get('openTime').value && this.ballotdata.get('closeDate').value && this.ballotdata.get('closeTime').value) {
      const selectedOpenDate = this.ngbToMoment(
        this.ballotdata.get('openDate').value
      ).set({
        hour: this.ballotdata.get('openTime').value.slice(0, 2),
        minute: this.ballotdata.get('openTime').value.slice(-2),
      });
      const selectedCloseDate = this.ngbToMoment(
        this.ballotdata.get('closeDate').value
      ).set({
        hour: this.ballotdata.get('closeTime').value.slice(0, 2),
        minute: this.ballotdata.get('closeTime').value.slice(-2),
      });
      if (!(moment(selectedCloseDate).isAfter(selectedOpenDate))) {
        this.toastService.error(
          'Application Close Time should be more than Open Time',
          'Error',
          { timeOut: 5000, progressBar: true }
        );
        return true;
      }

    }
    return false;
  }

  ballotDateValidation() {
    if (this.openingTimePassed) {
      this.toastService.error('Application Open Time should be more than current time', 'Error');
      return false
    } else if (new Date(this.ballotdata.get('closeDate').value.year, this.ballotdata.get('closeDate').value.month, this.ballotdata.get('closeDate').value.day).getTime() < new Date(this.ballotdata.get('openDate').value.year, this.ballotdata.get('openDate').value.month, this.ballotdata.get('openDate').value.day).getTime()) {
      // "Application start date should be less then end date
      this.toastService.error('Application start date should be less then end date', 'Error');
      return false
    } else if (this.checkOpenCloseTime()) {
      // Ballot start date should be less then end date
      // this.toastService.error('Ballot start date should be less then end date', 'Error');
      return false
    }
    else if (new Date(this.ballotdata.get('ballotEndDate').value.year, this.ballotdata.get('ballotEndDate').value.month, this.ballotdata.get('ballotEndDate').value.day).getTime() <= new Date(this.ballotdata.get('ballotStartDate').value.year, this.ballotdata.get('ballotStartDate').value.month, this.ballotdata.get('ballotStartDate').value.day).getTime()) {
      // Ballot start date should be less then end date
      this.toastService.error('Ballot start date should be less then end date', 'Error');
      return false
    } else if (new Date(this.ballotdata.get('openDate').value.year, this.ballotdata.get('openDate').value.month, this.ballotdata.get('openDate').value.day).getTime() >= new Date(this.ballotdata.get('ballotStartDate').value.year, this.ballotdata.get('ballotStartDate').value.month, this.ballotdata.get('ballotStartDate').value.day).getTime()) {
      // Application start date should be less then ballot start date
      this.toastService.error('Application start date should be less then ballot start date', 'Error');
      return false
    }
    else if (new Date(this.ballotdata.get('closeDate').value.year, this.ballotdata.get('closeDate').value.month, this.ballotdata.get('closeDate').value.day).getTime() >= new Date(this.ballotdata.get('ballotStartDate').value.year, this.ballotdata.get('ballotStartDate').value.month, this.ballotdata.get('ballotStartDate').value.day).getTime()) {
      // Application end date should be less then ballot start date
      this.toastService.error('Application end date should be less then ballot start date', 'Error');
      return false
    }
    else if (new Date(this.ballotdata.get('resultReleaseDate').value.year, this.ballotdata.get('resultReleaseDate').value.month, this.ballotdata.get('resultReleaseDate').value.day).getTime() >= new Date(this.ballotdata.get('ballotStartDate').value.year, this.ballotdata.get('ballotStartDate').value.month, this.ballotdata.get('ballotStartDate').value.day).getTime()) {
      //  Result release date should be less then ballot start date
      this.toastService.error('Result release date should be less then ballot start date', 'Error');

      return false
    } else if (new Date(this.ballotdata.get('resultReleaseDate').value.year, this.ballotdata.get('resultReleaseDate').value.month, this.ballotdata.get('resultReleaseDate').value.day).getTime() <= new Date(this.ballotdata.get('closeDate').value.year, this.ballotdata.get('closeDate').value.month, this.ballotdata.get('closeDate').value.day).getTime()) {
      //  "Result release date should be greater then Application end date
      this.toastService.error('Result release date should be greater then Application end date', 'Error');

      return false
    }

    return true
  }
  ballotdataNext() {
    this.ballotdataSubmitted = true;

    if (!this.ballotdata.invalid && this.ballotDateValidation()) {
      this.stepper.next();
      this.getSlots();
    }
  }

  slotQuotaSetupNext() {
    this.slotQuotaSetupSubmitted = true;

    if (this.slotQuotaSetup.valid) {
      this.getSlotQuotaSetup();

      //check if all slots value are filled
      const slotQuotaArr = this.slotQuotaSetup.value.slotCreation;
      let flag = false;
      for (let el of slotQuotaArr) {
        const slot = el.weekRangeSlot;
        for (let i in slot) {
          if (slot[i].value === '') {
            flag = true;
            break;
          }
        }
        if (flag) break;
      }

      if (flag) {
        this.toastService.error('Please fill all slots quota value', 'Error');
      } else {
        this.stepper.next();
      }
    }
  }

  getSlots() {
    if (this.ballotdata.get('leaveType').value == '2') {
      const startDate = this.ballotdata.controls.ballotStartDate.value;
      const endDate = this.ballotdata.controls.ballotEndDate.value;
      let ballotStart = moment().set({
        year: startDate.year,
        month: startDate.month - 1,
        date: startDate.day,
      });

      let ballotEnd = moment().set({
        year: endDate.year,
        month: endDate.month - 1,
        date: endDate.day,
      });

      ballotEnd.add(1, 'days');

      this.slotWeeks = [];

      do {
        this.slotWeeks.push(moment(ballotStart));
        ballotStart = moment(ballotStart).add(1, 'days');
      } while (ballotStart.format('DD-MM-YY') != ballotEnd.format('DD-MM-YY'));
    } else {
      this.getSlotWeeks();
    }
  }

  getOpsLeave() {
    this.leaveService.getOpsGrp().subscribe((res: { data: any }) => {
      this.opsList = res.data;

      if (this.ballot) {
        const editOps = [];
        this.ballot.opsGroupId.forEach((val) => {
          this.opsList.find((v) => {
            if (v._id == val._id) {
              editOps.push(v);
            }
          });
        });

        this.slotQuotaSetup.get('opsGroupId').patchValue(editOps);
        this.getSlots();
        this.getOpsGrp();
      }
    });
  }

  selectTime() {
    this.time = [];
    for (let hour = 0; hour < 24; hour++) {
      this.time.push(moment({ hour }).format('HH:mm'));
      this.time.push(moment({ hour, minute: 15 }).format('HH:mm'));
      this.time.push(moment({ hour, minute: 30 }).format('HH:mm'));
      this.time.push(moment({ hour, minute: 45 }).format('HH:mm'));
    }
  }

  fixedValidn(group: AbstractControl): { [key: string]: any } | null {
    const leaveType = group.get('leaveType');
    const fixedCheck = group.get('fixedBallotingLeaveType');
    const quota = group.get('totalQuota');
    if(leaveType.value == '2' && !fixedCheck.value){
      return {fixedNotChecked:true}
    }
    if (leaveType.value == '2' && fixedCheck.value == true) {
      if (quota.value == '') {
        return { quotaValueNull: true };
      } else {
        return null;
      }
    }
  }

  validateRequiredField() {
    if (!this.slotformat || !this.resStaff || this.checkOpsGrop()) {
      return false;
    }
    return true;
  }

  checkOpsGrop() {
    return !this.opsGrp?.find(x => x.isSelected == true);
  }
}
