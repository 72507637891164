<div class="d-flex flex-column">
  <ul
    ngbNav
    [(activeId)]="activeId"
    #companyNav="ngbNav"
    class="nav-tabs rounded mb-3"
  >
    <li [ngbNavItem]="1">
      <a ngbNavLink>HOME PAGE</a>
      <ng-template ngbNavContent>
        <app-home-page></app-home-page>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink>PASSWORD</a>
      <ng-template ngbNavContent>
        <password></password>
      </ng-template>
    </li>
    <li [ngbNavItem]="3">
      <a ngbNavLink>QUICK NAVIGATION</a>
      <ng-template ngbNavContent>
        <app-usefull-link></app-usefull-link>
      </ng-template>
    </li>
    <li [ngbNavItem]="4">
      <a ngbNavLink>USEFUL LINKS </a>
      <ng-template ngbNavContent>
        <quick-links></quick-links>
      </ng-template>
    </li>
    <li [ngbNavItem]="5">
      <a ngbNavLink>OTHERS</a>
      <ng-template ngbNavContent>
        <app-others></app-others>
      </ng-template>
    </li>
    <li [ngbNavItem]="6">
      <a ngbNavLink>POINT SYSTEM</a>
      <ng-template ngbNavContent>
        <app-point-system></app-point-system>
      </ng-template>
    </li>
    <li [ngbNavItem]="7">
      <a ngbNavLink>MY PAGE</a>
      <ng-template ngbNavContent>
        <app-my-page></app-my-page>
      </ng-template>
    </li>
    <li [ngbNavItem]="8">
      <a ngbNavLink>LEAVE MANAGEMENT AND SKILLSET SETUP</a>
      <ng-template ngbNavContent>
        <app-leavemng-skillset></app-leavemng-skillset>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="companyNav"></div>
</div>
