<ul
  ngbNav
  #navbr="ngbNav"
  (navChange)="navChanged1($event)"
  [(activeId)]="active"
  class="nav-tabs rounded mb-3"
>
  <li [ngbNavItem]="1">
    <a ngbNavLink>OVERVIEW</a>
    <ng-template ngbNavContent>
      <ops-overview (action)="onActionbtn($event)"></ops-overview>
    </ng-template>
  </li>
  <li [ngbNavItem]="2">
    <a ngbNavLink>{{ heading }}</a>
    <ng-template ngbNavContent>
      <app-ops-add-staff
        *ngIf="heading != 'EDIT'"
        [viewValue]="view"
        (ChangeTabId)="changeTab($event)"
      ></app-ops-add-staff>
      <app-ops-edit
        *ngIf="heading == 'EDIT'"
        [editValue]="edit"
        (ChangeTabId)="changeTab($event)"
      ></app-ops-edit>
    </ng-template>
  </li>
  <li
    [ngbNavItem]="3"
    class="d-flex justify-content-end flex-grow-1 nav-item pr-2"
  >
    <button
      class="align-self-center btn btn-warning ml-1"
      (click)="open(importQuota)"
    >
      Import Quota
    </button>
    <form
      [formGroup]="importStaffForm"
      class="align-self-center ml-1"
      (ngSubmit)="uploadImportStaff()"
    >
      <div class="bg-info header-buttons px-1 rounded">
        <input
          type="file"
          name="csv"
          class="btn-info rounded"
          accept=".csv"
          (change)="importStaff($event)"
        />
        <button class="btn btn-info ml-1" type="submit">
          <i class="fa fa-upload"></i> Import Staff
        </button>
      </div>
    </form>
    <!-- <button
      class="btn btn-success float-right m-1"
      style="font-size: 12px; padding: 5px"
      (click)="importStaff()"
    ></button> -->
    <button class="align-self-center btn btn-secondary ml-1">
      Import Staff Error Logs
    </button>
  </li>
</ul>
<div [ngbNavOutlet]="navbr"></div>

<ng-template #importQuota let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Import Leaves</h4>
    <div class="d-flex">
      <a
        type="button"
        href="./assets/leavesSAPFormat.csv"
        class="btn btn-secondary btn-sm"
      >
        <i class="fa fa-download"></i>
        Download Sample
      </a>
      <button
        ngbAutofocus
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <form
          [formGroup]="importQuotaForm"
          class="d-flex flex-wrap"
          (ngSubmit)="uploadImportQuota()"
        >
          <div class="bg-info header-buttons p-2 mb-2 rounded w-100">
            <input
              type="file"
              name="csv"
              class="btn-info rounded"
              accept=".csv"
              (change)="importQuotafile($event)"
            />
          </div>
          <button
            type="button"
            class="btn btn-success mx-auto"
            (click)="uploadImportQuota()"
          >
            Upload
          </button>
        </form>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.dismiss('Cross click')"
    >
      Close
    </button>
  </div>
</ng-template>
