<div class="card">
  <div class="card-header flexi-header p-4">
    <h3>CHALLENGES</h3>
  </div>
  <div class="card-body">
    <div class="card table-card">
      <div class="card-body flexi-body">
        <div class="flex-column">
          <div class="d-flex justify-content-between mt-2 mb-3">
            <select
              class="custom-select"
              style="width: auto"
              name="pageSize"
              (change)="onLimitChangeUsers()"
              [(ngModel)]="usersPTable.limit"
            >
              <option [ngValue]="10">10</option>
              <option [ngValue]="20">20</option>
              <option [ngValue]="30">30</option>
              <option [ngValue]="40">40</option>
              <option [ngValue]="50">50</option>
            </select>
            <div class="form-group form-inline mb-0">
              <span *ngIf="usersPTable.isLoading">Please wait...</span>
              <input
                class="form-control ml-2"
                type="search"
                name="searchTerm"
                (search)="loadUsers()"
                (keyup)="userssearchInput.next($event)"
                placeholder="Search here"
                [(ngModel)]="usersPTable.search"
              />
            </div>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th
                    scope="col"
                    sortable="0"
                    (sort)="onSortUsers($event, 'createdAt')"
                  >
                    DATE
                  </th>
                  <th
                    scope="col"
                    sortable="1"
                    (sort)="onSortUsers($event, 'title')"
                  >
                    CHALLENGE TITLE
                  </th>
                  <th
                    scope="col"
                    sortable="2"
                    (sort)="onSortUsers($event, 'publishEnd')"
                  >
                    EXPIRE DATE
                  </th>
                  <th
                    scope="col"
                  >
                    STATUS
                  </th>

                  <th scope="col">ACTION</th>
                </tr>
              </thead>
              <tbody *ngIf="!isTableLoading">
                <tr *ngFor="let item of usersPTable.data">
                  <td>
                    {{ item?.createdAt | date }}
                  </td>

                  <td>{{ item?.title }}</td>

                  <td>{{ item.publishEnd | date}}</td>
                  <td>
                    <span
                      class="badge badge-info"
                      *ngIf="
                        (item.publishStart
                          | eventPublishstatus: item.publishEnd) == '0'
                      "
                    >
                      Pending Publication
                    </span>
                    <span
                      class="badge badge-success"
                      *ngIf="
                        (item.publishStart
                          | eventPublishstatus: item.publishEnd) == '1'
                      "
                    >
                      Published
                    </span>
                    <span
                      class="badge badge-danger"
                      *ngIf="
                        (item.publishStart
                          | eventPublishstatus: item.publishEnd) == '2'
                      "
                    >
                      Expired
                    </span>
                  </td>
                  <td>
                    <div class="d-flex">
                      <button
                        class="btn btn-info btn-sm mr-1"
                        (click)="onViewUser(item)"
                      >
                        View
                      </button>
                      <button
                        class="btn btn-success btn-sm mr-1"
                        (click)="onEditUser(item)"
                      >
                        Edit
                      </button>
                      <button
                        class="btn btn-warning btn-sm"
                        (click)="
                          onManage(
                            item._id,
                            item.nonRewardPointSystemEnabled,
                            item.title,
                            item.isTeam
                          )
                        "
                      >
                        Manage
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <app-skliton-loading-table
            [numberOfCols]="5"
            [isTableLoading]="isTableLoading"
          ></app-skliton-loading-table>
        </div>
      </div>
      <div class="card-footer">
        <div class="d-flex justify-content-between">
          <p>Total records {{ usersPTable.total }}</p>
          <ngb-pagination
            [collectionSize]="usersPTable.total"
            [(page)]="usersPTable.page"
            [maxSize]="5"
            (pageChange)="onPageChangeUsers($event)"
            [pageSize]="usersPTable.limit"
          >
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
