import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { NewsEventsService } from 'src/app/users/service/manage-news & events/news-events.service';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-news-view',
  templateUrl: './news-view.component.html',
  styleUrls: ['./news-view.component.scss']
})
export class NewsViewComponent implements OnInit {
  @Input() newsId: string = null;
  @Input() newsData: any = null;
  contentTitle;
  eventTitle;
  eventLogsTitle;
  eventObject
  @Output() ViewEvent = new EventEmitter<string>();
  logPtable: { sortBy: string; sortWith: string; postId: string; data: any[]; page: number; limit: number; search: string; };
  baseUrl = environment.imageUrl;
  constructor( public newsService: NewsEventsService,) {
    this.newsId && this.getEventsview(this.newsId);
  }

  ngOnInit() {
    this.logPtable = {
      sortBy: 'desc',
      sortWith: 'createdBy',
      postId:this.newsId,
      data: [],
      page: 1,
      limit: 10,
      search: '',

    };
    this.newsId && this.getEventsview(this.newsData);
    this.newsId && this.getSinglePostLog(this.newsId)
  }

  getEventsview(newsObj: any) {
    this.eventObject = newsObj
this.contentTitle = this.eventObject.content.title
this.eventTitle = this.eventObject.content.content
    // const sub = this.newsService
    //   .getSingleNewsEvents(id)
    //   .pipe(
    //     finalize(() => {
    //       sub.unsubscribe();
    //     })
    //   )
    //   .subscribe(
    //     ({data}) => {
    //       this.eventObject = data
    //       this.contentTitle = this.eventObject.content.title
    //       this.eventTitle = this.eventObject.content.content
    //       // this.eventFormGroup.patchValue({
    //       //   ...event,
    //       // });
    //       // this.contentFormGroup.patchValue({
    //       //   ...publish,
    //       // });
    //       // this.publicationFormGroup.patchValue({
    //       //   ...event,
    //       // });
    //       // this.eventBoardFormGroup.patchValue({
    //       //   ...eventBoard,
    //       // });
    //       // this.sessionsForm.patchValue({
    //       //   ...session,
    //       // });
    //       // this.adminstrationFormGroup.patchValue({
    //       //   ...admin,
    //       // });
    //     },
    //     (error) => {}
    //   );
  }


  onCancelView(){
    this.ViewEvent.emit(null);
  }

  getSinglePostLog(id: string){
    const {data, ...rest}  = this.logPtable

    const sub = this.newsService
      .getSinglePostLog(rest)
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe(
        ({data}) => {
          this.logPtable.data = data.data;
        },
        (error) => {}
      );
  }

  checkNewsStatus(startDate: string, endDate: string) {
    if(moment() < moment(startDate)) return "Pending Publication";
    if(moment() > moment(endDate)) return "Expired";
    if(moment() < moment(endDate)) return "Published";
   }

   validateURLImage(str) {
    var img_Path = str;
    if (img_Path.indexOf("https://") == 0) {
      var new_url = new URL(str);
     var pathName = (new_url.pathname.startsWith('//')) ? `/${new_url.pathname.split("//")[1]}` : new_url.pathname;
      return `${this.baseUrl}${pathName}`;
    }
    if (str.startsWith('/')) return this.baseUrl + str;
    return `${this.baseUrl}/${str}`;
   }
}
