import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import { SetupService } from 'src/app/users/service/operations/setup.service';
import { finalize } from 'rxjs/operators';
import { CompanySetupService } from 'src/app/users/service/company-setup/company-setup.service';
import { UsersService } from 'src/app/users/service/user-service/users.service';
import { RoleService } from 'src/app/users/service/user-roles/role.service';
import { ShiftSchedulerService } from 'src/app/users/service/operations/shift-scheduler.service';
import { CustomFieldService } from 'src/app/users/service/custom-field/custom-field.service';
@Component({
  selector: 'app-user-shift-setup',
  templateUrl: './user-shift-setup.component.html',
  styleUrls: ['./user-shift-setup.component.scss'],
})
export class UserShiftSetupComponent implements OnInit {
  @Input() isEditUserBu: boolean;

  userEdit: any;
  buUserShiftSetUpForm: FormGroup;
  activeId: number = 1;
  buUserOption: any;
  skillLabels: any;
  busiUnit: any;
  parentUnit: any;
  buSkillSet: any;
  buSkillspeciality: any;
  planBusiness = [];
  viewBusiness = [];
  mainSkillset: any;
  userSkillset: any;
  mainSkillsetarray = [];
  userSkillsetarray = [];
  customFields = [];
  allCustomFieldsList = [];
  subSkillSets = [];
  userRole = [];
  userId: any;
  activateDisable: boolean;
  isVisible: boolean;
  isHeading: any;
  schemeTable: any;
  buShiftIdScheme: any;
  schemeLabel: any;
  schemeLabels: any;
  schemeArr: any;
  schemeLabelId: any;
  userShiftSetupLoader: boolean;
  tier: any;
  selectedRole:any=null;
  constructor(
    private buService: SetupService,
    private companyService: CompanySetupService,
    private formBuilder: FormBuilder,
    public toastService: ToastrService,
    private userService:RoleService,
    private shiftSchedularService:ShiftSchedulerService,
    private customFieldService: CustomFieldService,
  ) {}

  ngOnInit(): void {
    this.customFieldsList()
    this.initForm();
    this.displaySkilltable();
    this.getTier();
  }

  getTier() {
    this.shiftSchedularService.getPageSettingSkill().subscribe((res) => {
      this.tier = res['tierType'];

    });
  }

  customFieldsList(){
    this.customFieldService.getCustomFieldList().subscribe((res) => {
      this.allCustomFieldsList = res.data.fieldList
    })
  }

  initForm() {
    this.buUserShiftSetUpForm = this.formBuilder.group({
      parentBusiness: [null, [Validators.required]],
      planBusiness: [null, [Validators.required]],
      viewBusiness: [null, [Validators.required]],
      userName: [null, [Validators.required]],
      staffId: [null, [Validators.required]],
      appoinment: [null, [Validators.required]],
      contactNum: [null, [Validators.required]],
      userEmail: [null, [Validators.required]],
      userRoles: [null, [Validators.required]],
      shiftScheme: [null, [Validators.required]],
      skillSetTierType: [2, [Validators.required]],
      mainSkillSets: [null, [Validators.required]],
      subSkillSets: [null, [Validators.required]],
    });
  }

  displaySkilltable() {
    this.buService.getSkillSet().subscribe((res) => {
      this.buSkillSet = res['data']['skillsets'];
    });
  }

  onSkillSetChange($event) {
  /* const index = this.buSkillSet.find((val)=>{
   return val._id == $event._id
   });
   console.log(index);*/
   this.buSkillspeciality = $event.subSkillSets;
   //this.buUserShiftSetUpForm.patchValue({
    //subSkillSets: null,
  //});
    /*this.buService.getSkillSpeciality($event._id).subscribe((res) => {
      this.buSkillspeciality = res['data'];
      this.buUserShiftSetUpForm.patchValue({
        subSkillSets: null,
      });
    });*/
  }

  addSchemeButton() {
    this.schemeLabelId = this.buUserShiftSetUpForm.get('shiftScheme').value._id;
    this.schemeLabel =
      this.buUserShiftSetUpForm.get('shiftScheme').value.schemeName;
  }
  addRoles() {
    this.userEdit.role =  this.selectedRole;
    this.buUserShiftSetUpForm.patchValue({
      userRoles: this.selectedRole.name,
    });
   /* this.schemeLabelId = this.buUserShiftSetUpForm.get('shiftScheme').value._id;
    this.schemeLabel =
      this.buUserShiftSetUpForm.get('shiftScheme').value.schemeName;*/
  }
  clearRoles(){
    this.userEdit.role.name = '';
  }

  clearSchemeLabel(i) {
    this.schemeLabelId.splice(i);
    this.schemeLabel.splice(i);
  }

  addUserSkillbutton() {
    if (this.tier == 2) {
      if (
        this.userSkillsetarray.indexOf(
          this.buUserShiftSetUpForm.get('subSkillSets').value?._id
        ) > -1
      ) {
        this.toastService.warning('Already Exists', 'Info');
      } else {
      this.userSkillsetarray.push(
        this.buUserShiftSetUpForm.get('subSkillSets').value?._id
      );

      this.userSkillset.push([
        this.buUserShiftSetUpForm.get('subSkillSets').value?.skillSetId.name,
        this.buUserShiftSetUpForm.get('subSkillSets').value?.name,
      ]);
    }
    } else {
      if (
        this.userSkillsetarray.indexOf(
          this.buUserShiftSetUpForm.get('mainSkillSets').value._id
        ) > -1
      ) {
        this.toastService.warning('Already Exists', 'Info');
      } else {
      this.userSkillsetarray.push(
        this.buUserShiftSetUpForm.get('mainSkillSets').value._id
      );

      this.userSkillset.push([
        this.buUserShiftSetUpForm.get('mainSkillSets').value.name,
        '_',
      ]);
    }
    }
    this.buUserShiftSetUpForm.get('mainSkillSets').setValue(null);
    this.buUserShiftSetUpForm.get('subSkillSets').setValue(null);
  }

  clearLabel(i) {
    this.userSkillsetarray.splice(i, 1);
    this.userSkillset.splice(i, 1);
  }

  saveSetupDetails() {
    const updatedUserBu = {
      userId: this.userId,
      schemeId: this.schemeLabelId,
      skillSetTierType: this.tier,
      role: this.selectedRole?._id ? this.selectedRole?._id : this.userEdit.role._id,
    };

    if (this.tier == 2) {
      this.buUserShiftSetUpForm.patchValue({
        subSkillSets: this.userSkillsetarray,
      });

      updatedUserBu['subSkillSets'] = this.userSkillsetarray;
    } else {
      this.buUserShiftSetUpForm.patchValue({
        mainSkillSets: this.userSkillsetarray,
      });

      updatedUserBu['mainSkillSets'] = this.userSkillsetarray;
    }

    this.userShiftSetupLoader = true;
    this.buService
      .updateUserShift(updatedUserBu)
      .pipe(
        finalize(() => {
          this.userShiftSetupLoader = false;
        })
      )
      .subscribe((res) => {
        this.buUserShiftSetUpForm.reset();
        this.toastService.info('User Updated SuccessFully');
        this.activeId = 1;
      });
  }

  onViewBtn(event) {
    this.buUserShiftSetUpForm.reset();
    this.activateDisable = false;
    this.activeId = 2;
    this.isVisible = false;
    this.isHeading = 'VIEW USER SHIFT WORK SETUP';

    this.planBusiness = [];
    this.userSkillset = [];
    this.userSkillsetarray = [];
    this.userService.getUserRoles().subscribe((res)=>{
      this.userRole = res['data']['roles'];
  });
    this.buService.getUserShiftEdit(event._id).subscribe((res) => {
      this.userEdit = res['data']['data'];
      this.userId = event._id;
      this.parentUnit = res['data']['data']['parentBussinessUnitId']['orgName'];
      this.viewBusiness = res['data']['data']['viewBussinessUnitId']
      this.planBusiness = res['data']['data']['planBussinessUnitId']

      //this.buService.getBusinessUnit().subscribe((res) => {
        //this.busiUnit = res['data']['data'];

        if (this.tier == 2) {
          for (let k = 0; this.userEdit.subSkillSets.length > k; k++) {
            this.userSkillsetarray.push(this.userEdit.subSkillSets[k]._id);

            this.userSkillset.push([
              this.userEdit.subSkillSets[k].skillSetId.name,
              this.userEdit.subSkillSets[k].name,
            ]);
          }
        } else if (this.tier == 1) {
          for (let k = 0; this.userEdit.mainSkillSets.length > k; k++) {
            this.userSkillsetarray.push(this.userEdit.mainSkillSets[k]._id);

            this.userSkillset.push([this.userEdit.mainSkillSets[k].name, '']);
          }
        }

        if (this.userEdit.schemeId != null) {
          this.schemeLabel = this.userEdit.schemeId.schemeName;
        }

        /*for (let i = 0; this.busiUnit.length > i; i++) {
          if (this.busiUnit[i]._id == this.userEdit.parentBussinessUnitId._id) {
            this.parentUnit = this.busiUnit[i].businessUnit;
          }
        }

        for (let i = 0; this.userEdit.planBussinessUnitId.length > i; i++) {
          for (let j = 0; this.busiUnit.length > j; j++) {
            if (
              this.busiUnit[j]._id == this.userEdit.planBussinessUnitId[i]._id
            ) {
              this.planBusiness.push(this.busiUnit[j].businessUnit);
            }
          }

          this.viewBusiness = [];
          for (let i = 0; this.userEdit.viewBussinessUnitId.length > i; i++) {
            for (let j = 0; this.busiUnit.length > j; j++) {
              if (
                this.busiUnit[j]._id == this.userEdit.viewBussinessUnitId[i]._id
              ) {
                this.viewBusiness.push(this.busiUnit[j].businessUnit);
              }
            }
          }
        }*/
      //});

      this.buUserShiftSetUpForm.patchValue({
        userName: this.userEdit.name,
        staffId: this.userEdit.staffId,
        appoinment: this.userEdit.appointmentId.name,
        contactNum: this.userEdit.contactNumber,
        userEmail: this.userEdit.email,
        userRoles: this.userEdit.role.name,
      });

      this.buUserShiftSetUpForm.controls.mainSkillSets.disable();
      this.buUserShiftSetUpForm.controls.subSkillSets.disable();
      this.buUserShiftSetUpForm.controls.shiftScheme.disable();
      this.buUserShiftSetUpForm.controls.userRoles.disable();
      this.customFields  = res['data']['data'].otherFields
      this.customFields?.forEach(element => {
        const fieldName = this.allCustomFieldsList.find(id => id._id === element.fieldId)?.fieldName
        element['fieldLabel'] = fieldName
        element['formControl'] = fieldName?.replace(/\s/g, '')
        this.addFormControl(element.formControl, '');
        this.buUserShiftSetUpForm.controls[element.formControl].patchValue(element.value)
      });
    });
  }

  onEditBtn(event) {
    this.buUserShiftSetUpForm.reset();
    this.activateDisable = true;
    this.isVisible = true;
    this.isHeading = 'EDIT USER SHIFT WORK SETUP';
    this.activeId = 2;
    this.planBusiness = [];
    this.userSkillset = [];
    this.userSkillsetarray = [];
    this.userService.getUserRoles().subscribe((res)=>{
      this.userRole = res['data']['roles'];
  });
    this.buService.getUserShiftEdit(event._id).subscribe((res) => {
      this.userEdit = res['data']['data'];
      this.userId = event._id;
      this.buShiftIdScheme = res['data']['data']['parentBussinessUnitId']['_id'];
      this.parentUnit = res['data']['data']['parentBussinessUnitId']['orgName'];
      this.viewBusiness = res['data']['data']['viewBussinessUnitId']
      this.planBusiness = res['data']['data']['planBussinessUnitId']
      this.selectedRole = this.userEdit?.role ;
      if (this.userEdit.subSkillSets.length !== 0 && this.tier == 2) {
        for (let k = 0; this.userEdit.subSkillSets.length > k; k++) {
          this.userSkillsetarray.push(this.userEdit.subSkillSets[k]._id);

          this.userSkillset.push([
            this.userEdit.subSkillSets[k].skillSetId.name,
            this.userEdit.subSkillSets[k].name,
          ]);
        }
      } else if (this.userEdit.mainSkillSets.length !== 0 && this.tier == 1) {
        for (let k = 0; this.userEdit.mainSkillSets.length > k; k++) {
          this.userSkillsetarray.push(this.userEdit.mainSkillSets[k]?._id);
          this.userSkillset.push([this.userEdit.mainSkillSets[k]?.name, '']);
        }
      }

      if (this.userEdit.schemeId != null) {
        this.schemeLabel = this.userEdit.schemeId.schemeName;
      }

     /* this.buService.getBusinessUnit().subscribe((res) => {
        this.busiUnit = res['data']['data'];

        for (let i = 0; this.busiUnit.length > i; i++) {
          if (this.busiUnit[i]._id == this.userEdit.parentBussinessUnitId._id) {
            this.parentUnit = this.busiUnit[i].businessUnit;
          }
        }

        for (let i = 0; this.userEdit.planBussinessUnitId.length > i; i++) {
          for (let j = 0; this.busiUnit.length > j; j++) {
            if (
              this.busiUnit[j]._id == this.userEdit.planBussinessUnitId[i]._id
            ) {
              this.planBusiness.push(this.busiUnit[j].businessUnit);
            }
          }
        }

        this.viewBusiness = [];
        for (let i = 0; this.userEdit.viewBussinessUnitId.length > i; i++) {
          for (let j = 0; this.busiUnit.length > j; j++) {
            if (
              this.busiUnit[j]._id == this.userEdit.viewBussinessUnitId[i]._id
            ) {
              this.viewBusiness.push(this.busiUnit[j].businessUnit);
            }
          }
        }
      });*/

      this.buUserShiftSetUpForm.patchValue({
        userName: this.userEdit.name,
        staffId: this.userEdit.staffId,
        appoinment: this.userEdit.appointmentId.name,
        contactNum: this.userEdit.contactNumber,
        userEmail: this.userEdit.email,
      });

      const userID = localStorage.getItem("flexicompanyId");
      const result = userID.substring(1, userID.length-1)
       //id = userID
      this.buService.getScheme(userID, '').subscribe((res) => {
        this.schemeTable = res['data'];
      });
      this.customFields  = res['data']['data'].otherFields
      this.customFields?.forEach(element => {
        const fieldName = this.allCustomFieldsList.find(id => id._id === element.fieldId)?.fieldName
        element['fieldLabel'] = fieldName
        element['formControl'] = fieldName?.replace(/\s/g, '')
        this.addFormControl(element.formControl, '');
        this.buUserShiftSetUpForm.controls[element.formControl].patchValue(element.value)
      });
    });

    this.buUserShiftSetUpForm.controls.parentBusiness.disable();
    this.buUserShiftSetUpForm.controls.planBusiness.disable();
    this.buUserShiftSetUpForm.controls.viewBusiness.disable();
    this.buUserShiftSetUpForm.controls.appoinment.disable();
    //this.buUserShiftSetUpForm.controls.userRoles.disable();

    this.buUserShiftSetUpForm.controls.mainSkillSets.enable();
    this.buUserShiftSetUpForm.controls.subSkillSets.enable();
    this.buUserShiftSetUpForm.controls.shiftScheme.enable();
    this.buUserShiftSetUpForm.controls.userRoles.enable();
  }

  addFormControl(controlName: string, initialValue) {
    this.buUserShiftSetUpForm.addControl(controlName, this.formBuilder.control(initialValue));
  }

  navigateToOverview() {
    this.buUserShiftSetUpForm.reset();
    this.activeId = 1;
  }
}
