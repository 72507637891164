<form [formGroup]="addRepLoc">
  <div class="row" *ngIf="isMap || isDropdown">
    <div class="col-md-6">
      <div class="form-group row">
        <label class="col-sm-4">Enable Self Check-in</label>
        <div class="col-sm-8">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="checkin"
              [value]="true"
              id="yesCheckin"
              formControlName="isCheckInEnabled"
              name="isCheckInEnabled"
              (click)="changeCheckinEnable(true)"
            />
            <label class="form-check-label" for="inlineRadio1">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="checkin"
              [value]="false"
              id="noCheckin"
              formControlName="isCheckInEnabled"
              name="isCheckInEnabled"
              (click)="changeCheckinEnable(false)"
            />
            <label class="form-check-label" for="inlineRadio2">No</label>
          </div>
        </div>
        
      </div>
    </div>
  </div>

  <div class="row" *ngIf="this.addRepLoc.get('isCheckInEnabled').value">
    <div class="col-md-6">
      <div class="form-group row">
        <label class="col-sm-4">Enable proximity check </label>
        <div class="col-sm-8">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              [value]="true"
              name="isProximityEnabled"
              formControlName="isProximityEnabled"
              id="yes"
              (click)="changeProximityEnable(true)"
            />
            <label class="form-check-label" for="yes">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              [value]="false"
              name="isProximityEnabled"
              formControlName="isProximityEnabled"
              id="no"
              (click)="changeProximityEnable(false)"
            />
            <label class="form-check-label" for="no">No</label>
          </div>
        </div>
        
      </div>
    </div>
    <div
      class="col-md-4"
      *ngIf="this.addRepLoc.get('isProximityEnabled').value"
    >
      <div class="form-group">
        <label>Select Reporting Location Radius in mtrs </label>
        <ng-select
          [items]="reportingLocationRadius"
          class="custom"
          bindLabel="name"
          placeholder="Select Reporting Location Radius in mtrs"
          appendTo="body"
          id="repLoc"
          [multiple]="false"
          (change)="changeReportingLocationRadius($event)"
          formControlName="reportingLocationRadius"
          name="reportingLocationRadius"
        >
        </ng-select>
      </div>
    </div>
  </div>
  <div class="col-md-12" style="padding-left: 0" [hidden]="!isMap">
    <div class="form-group col-md-6" style="padding-left: 0">
      <label>Enter address</label>

      <input
        type="text"
        class="form-control"
        (keydown.enter)="$event.preventDefault()"
        placeholder="Search Nearest Location"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="off"
        type="text"
        #search
      />
    </div>
  </div>
  <div class="col-md-12" style="display: flex; padding-left: 0">
    <div
      class="col-md-6"
      *ngIf="isMap"
      style="margin-bottom: 1rem; height: 500px; padding-left: 0"
    >
      <agm-map
        [latitude]="latitude"
        [longitude]="longitude"
        [zoom]="zoom"
        style="height: 500px"
      >
        <agm-marker
          [latitude]="latitude"
          [longitude]="longitude"
          [markerDraggable]="true"
          (dragEnd)="markerDragEnd($event)"
        ></agm-marker>
      </agm-map>
    </div>

    <div class="col-md-2 buttons" *ngIf="isMap">
      <button class="btn btn-clear p-2 m-1">Clear</button>
      <button class="btn btn-add p-2 m-1" type="button" (click)="addAddress()">
        Add
      </button>
    </div>
    <div class="col-md-4">
      <div *ngIf="this.mapLocations != null && isMap">
        <div
          class="labels my-1"
          *ngFor="let item of mapLocations; let i = index"
        >
          <span class="text">
            {{ item }}
          </span>
          <span
            class="material-icons bg-danger rounded btn-close"
            (click)="clearMapLocationLabel(i)"
          >
            clear
          </span>
        </div>
      </div>
    </div>
  </div>
</form>
