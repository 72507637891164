import { Component, OnInit } from '@angular/core';

@Component({
  template: ``,
  styles: []
})
export class NetworkRocketComponent {
  constructor() { 
    window.top.location.href = 'https://corporate.net-roc.com';
  }
}
