import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, finalize } from 'rxjs/operators';
import { AuthService } from '../../service/auth/auth.service';

@Component({
  selector: 'app-design',
  templateUrl: './design.component.html',
  styleUrls: ['./design.component.scss']
})
export class DesignComponent implements OnInit, OnDestroy {
  activeUrl: string
  isToggle: boolean
  routeSubscribe: Subscription
  constructor(private router: Router, private AuthService: AuthService) {
    this.routeSubscribe = router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      switch (event.url) {
        case '/users/bu-unit':
          this.activeUrl = 'Business unit';
          break;
        case '/users/home':
          console.log("inside design componenet/user/home route");
          this.activeUrl = 'Home';
          break;
        case '/users/company-setup':
          this.activeUrl = 'Company Setup';
          break;
        case '/users/custom-fields':
          this.activeUrl = 'Custom Fields';
          break;
        case '/users/user-roles':
          this.activeUrl = 'User Roles';
          break;
        case '/users/view-users':
          this.activeUrl = 'View Users';
          break;
        case '/users/create-users':
          this.activeUrl = 'Create Users';
          break;
        case '/users/lock-account':
          this.activeUrl = 'Locked Account';
          break;
        case '/users/rest-password':
          this.activeUrl = 'Rest Password';
          break;
        case '/users/channel-setup':
          this.activeUrl = 'Chanel Setup';
          break;
        case '/users/logs':
          this.activeUrl = 'Communication / Logs';
          break;
        default:
          break;
      }
    });
  }
  ngOnDestroy(): void {

    this.routeSubscribe.unsubscribe();
  }

  ngOnInit(): void {

    this.isToggle = false;
  }
  toggleSideNav() {
    this.isToggle = !this.isToggle;
  }

}
