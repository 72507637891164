import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StorageService } from 'src/app/core/service/storage.service';
import { queryParam } from 'src/app/core/utils/helper';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChannelService {

  private baseUrl: string;
  private get Headers(): HttpHeaders {
    return new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }
  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {
    this.baseUrl = environment.baseUrl;
  }
  getChannels(data): Observable<any> {
    return this.http.get(`${this.baseUrl}/channel/?${queryParam(data)}`, {
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }
  getAllCategory(data): Observable<any> {
    return this.http.get(`${this.baseUrl}/category/?${queryParam(data)}`, {
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }

  exportChannels(channelId: string, data): Observable<any> {
    return this.http.post(`${this.baseUrl}/channel/export`, data, {
      headers: this.Headers,
    }).pipe(map((res) => res as any))
  }

  updateChannel(data, id:string): Observable<any> {
    return this.http.post(`${this.baseUrl}/channel/${id}` , data ,{
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }
  createChannels(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/channel/` , data ,{
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }
  createCategory(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/category/` , data ,{
      headers: this.Headers,
    }).pipe(map((res) => res as any))
  }
  updateCategory(data, id:string): Observable<any> {
    return this.http.post(`${this.baseUrl}/category` ,{...data, categoryId: id} ,{
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }

  deleteCategory(id:string): Observable<any> {
    return this.http.post(`${this.baseUrl}/category/${id}`  ,{
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }
  // getAllPrevileges(): Observable<any> {
  //   return this.http.get(`${this.baseUrl}/role/privilege/all`)
  // }

  getOneChannel(id: string):Observable<any> {
    return this.http.get(`${this.baseUrl}/channel/${id}`,{
      headers: this.Headers,

    })
    .pipe(map((res) => res as any))
  }
  // createUserRole(roleData: userRoleData): Observable<userRoleData> {
  //   return this.http
  //     .post(`${this.baseUrl}/role/`, roleData)
  //     .pipe(map((res) => res as userRoleData));
  // }
  // updateUserRole(roleData: userRoleData, roleId:string): Observable<userRoleData> {
  //   return this.http
  //     .put(`${this.baseUrl}/role/${roleId}`, roleData)
  //     .pipe(map((res) => res as userRoleData));
  // }
}
