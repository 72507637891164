<div class="card mt-3">
  <div
    class="
      align-items-center
      bg-theme
      card-header
      d-flex
      justify-content-between
    "
  >
    TEASER TITLE
    <button class="btn btn-info">Refresh</button>
  </div>
  <div class="card-body">
    <div class="d-flex justify-content-between mb-3">
      <div class="border h-25 p-2 rounded w-auto">
        <div>
          <h5 class="card-title">{{ sessionTitle }}</h5>
          <div class="d-flex">
            <div class="session-box">
              <p class="text-danger font-weight-bold">RSVP</p>
              <p class="sub-text">{{ sessionDetail?.length }}</p>
            </div>
            <div *ngFor="let item of attendanceCountArr" class="session-box session-border">
              <p class="text-danger font-weight-bold">Attendance {{item}}</p>
              <p class="sub-text">{{ getAttendeneceCount(item) }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="w-25">
        <label>Select Session</label>
        <ng-select
          (change)="getSessionDetail()"
          class="custom"
          [virtualScroll]="false"
          appendTo="body"
          [multiple]="false"
          [searchable]="true"
          [clearable]="false"
          [(ngModel)]="selectedSession"
        >
          <ng-option
            [value]="item"
            *ngFor="let item of sessionArr; let i = index"
          >
            Session {{ i + 1 }} @ {{ item.location }}
          </ng-option>
        </ng-select>
      </div>
    </div>

    <div class="card">
      <div class="bg-theme card-header">
        Event Name:
        <span class="eventTitle" *ngIf="sessionDetail" [innerHtml]="eventTitle | safeHtml"></span>
      </div>
      <div class="card-body p-0">
        <div class="table-responsive" *ngIf="sessionDetail">
          <table class="table">
            <thead>
              <tr>
                <th scope="col" sortable="Tier 1">USERNAME</th>
                <th scope="col" sortable="Tier 2">STAFF ID</th>
                <th scope="col">CONTACT NUMBER</th>
                <th scope="col">APPOINTMENT</th>
                <th scope="col">PARENT BUSINESS UNIT</th>
                <th scope="col">ATTENDANCE STATUS</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <div class="row m-0">
                    <span
                      class="col pl-0 text-theme"
                      *ngFor="let count of attendanceCountArr"
                      >Attendance {{ count }}</span
                    >
                  </div>
                </td>
              </tr>
              <tr *ngFor="let item of sessionDetail">
                <td>{{ item.staff?.name }}</td>
                <td>{{ item.staff?.staffId }}</td>
                <td>{{ item.staff?.contactNumber }}</td>
                <td>{{ item.staff?.appointmentId?.name }}</td>
                <td>{{ item.staff?.parentBussinessUnitId?.orgName }}</td>
                <td>
                  <div class="row m-0">
                    <span class="col pl-0 text-uppercase text-success" *ngFor="let count of attendanceCountArr; let i = index">
                      {{getAttendeneceStatus(item, count )}}
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer text-right">
    <button class="btn btn-warning" (click)="open(content)">
      Manual Attendance
    </button>
    <button class="btn btn-info ml-2" [disabled]="sessionDetail ? !sessionDetail.length: true " (click)="exportAttd()">
      Export Attendance List
    </button>
  </div>

  <ng-template #content let-modal>
    <div class="modal-header bg-theme">
      <h4 class="modal-title" id="modal-basic-title">Manual Attendance</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form [formGroup]="manualForm">
      <div class="modal-body">
        <div class="form-group">
          <label for="sessionNumber" class="session-header"
            >Session Number</label
          >
          <ng-select
            (change)="getSessionDetail()"
            class="custom"
            [virtualScroll]="false"
            appendTo="body"
            [multiple]="false"
            [searchable]="true"
            [clearable]="false"
            formControlName="session_id"
            required
          >
            <ng-option
              [value]="item"
              *ngFor="let item of sessionArr; let i = index"
            >
              Session {{ i + 1 }} @ {{ item.location }}
            </ng-option>
          </ng-select>
        </div>
        <div class="form-group">
          <label for="sessionNumber" class="session-header"
            >Attendance slot</label
          >
          <ng-select
            class="custom"
            [virtualScroll]="false"
            appendTo="body"
            [multiple]="false"
            [searchable]="true"
            [clearable]="true"
            formControlName="appointmentSlotNumber"
            required
          >
            <ng-option [value]="item" *ngFor="let item of attendanceCountArr; let i = index">
              Attendance {{ item }}
            </ng-option>
          </ng-select>
        </div>
        <div class="form-group">
          <label for="sessionNumber" class="session-header">Staff Id</label>
          <ng-select
            [items]="sessionDetail"
            class="custom"
            bindLabel="staff.name"
            bindValue="staff._id"
            [virtualScroll]="false"
            appendTo="body"
            [multiple]="true"
            [searchable]="true"
            [clearable]="true"
            formControlName="staff_ids"
            required
          >
          </ng-select>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-dark"
          (click)="modal.close('Save click')"
        >
          Close
        </button>
        <button
          type="submit"
          [disabled]="!manualForm.valid"
          class="btn btn-success"
          ngbAutoFocus
          (click)="submitManualAtt()"
        >
          Done
        </button>
      </div>
    </form>
  </ng-template>
</div>
