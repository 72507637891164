import { finalize } from 'rxjs/operators';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UsersService } from '../../service/user-service/users.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';
import {
  CustomValidators,
  MustMatch,
} from 'src/app/shared/custom-validator/custom-validator';
import { CompanySetupService } from '../../service/company-setup/company-setup.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../service/auth/auth.service';
import { GamificationService } from '../../service/gamification/gamification.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
  userData: any;
  activeId;
  passwordSettingId: string;
  passwordSetupForm: FormGroup;
  countryCode: ''
  mobileNumber: ''
  public isPasswordReset: boolean = false;
  public forgetPasswordForm: FormGroup;
  isEmail;
  data: {}
  public submitted1: boolean = false;
  public submitted3: boolean = false;
  isSubmittedRecovery = false;
  ID: string;
  validators = [];
  fileUploading = false;
  public passwordvisible: boolean = false;
  public confirmPasswordvisible: boolean = false;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  passwordIsInactive: string;
  recoveryForm: FormGroup;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  tostService: any;
  passwordRestForm: FormGroup;
  companyId: any;
  @ViewChild('changePasswordProfile', { static: true })
  changePasswordProfile: TemplateRef<any>;
  @ViewChild('changeMobileNumber', { static: true })
  changeMobileNumber: TemplateRef<any>;
  form: FormGroup;
  public fields: any[] = [];
  unsubcribe: any;
  isVerifyOTP=false;
  isGetProfileLoader=false;
  baseUrl = environment.imageUrl;
  constructor(
    private userService: UsersService,
    private auth: AuthService,
    public modelService: NgbModal,
    public toastService: ToastrService,
    private formBuilder: FormBuilder,
    private gamificationService: GamificationService,
    private passwordSettings: CompanySetupService,
    private companySetupService: CompanySetupService,
  ) {
    this.form = new FormGroup({
      fields: new FormControl(JSON.stringify(this.fields)),
    });
    this.unsubcribe = this.form.valueChanges.subscribe((update) => {

      this.fields = JSON.parse(update.fields);
    });
  }

  ngOnInit(): void {
    this.initForm();
    this.resetPasswordForm();
    this.loadProfileDetails();
    this.getPasswordSetupDetails()
  }
  resetPasswordForm() {
    this.passwordRestForm = this.formBuilder.group(
      {
        password: ['', [...this.validators, Validators.required]],
        confirmPassword: ['', Validators.required],
        // token: ['', [Validators.required]],
      },
      {
        validator: MustMatch('password', 'confirmPassword'),
      }
    );
  }
  private initForm() {
    this.forgetPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      profilePicture: ['', [Validators.required]],
      primaryMobileNumber: ['', [Validators.required]],
      contactNumber: ['']
    });
    this.recoveryForm = this.formBuilder.group({
      recoveryEmail: [''],
      recoveryPhoneNumber: [''],
      mobileOTP: [''],
      token: ['']

    });
  }
  get f() {
    return this.forgetPasswordForm.controls;
  }

  profileUpload(event) {
    if (event.target.files && event.target.files.length) {
      const file: File = event.target.files[0];
     this.fileUploading = true;
      const fileUpload = this.gamificationService
        .uploadFile(file)
        .pipe(
          finalize(() => {
            fileUpload.unsubscribe();
          })
        )
        .subscribe(
          (data: any) => {
            this.forgetPasswordForm.patchValue({
              profilePicture: `${environment.imageUrl}/${data?.data?.exactPath}`
            });
            this.fileUploading = false;
            this.userData.profilePicture = `${environment.imageUrl}/${data?.data?.exactPath}`;
            this.onSubmit();

          },
          (error) => {
            this.fileUploading = false;
          }
        );
    }
  }

  passwordVisible() {
    this.passwordvisible = !this.passwordvisible;
  }

  confirmPasswordVisible() {
    this.confirmPasswordvisible = !this.confirmPasswordvisible;
  }

  loadProfileDetails() {
    this.isGetProfileLoader=true;
    const subscribe = this.userService
      .getOneUser({ userId: localStorage.getItem('loggedInUserId') })
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          this.userData = response.data?.data || null;
          this.ID = response.data?.data._id
          this.forgetPasswordForm.patchValue({
            email: response.data?.data.email,
            primaryMobileNumber: response.data?.data.primaryMobileNumber,
            profilePicture: response.data?.data.profilePicture,
            contactNumber: response.data?.data.contactNumber
          });
          const modifiedData = response.data?.data.otherFields.map((rawProduct) => {
            return {
              disabled: rawProduct.editable ? false : true,
              ...rawProduct
            };
          });

          this.fields = modifiedData.filter((field: any) => field.status === 1)
          this.form = new FormGroup({});
          this.fields.forEach((x) => {
            if (x.type == 'checkbox') {
              this.form.addControl(x.fieldName, new FormGroup({}));
              x.options.forEach((o) => {
                (this.form.get(x.fieldName) as FormGroup).addControl(
                  o.key,
                  new FormControl(false)
                );
              });
            } else {
              this.form.addControl(
                x.fieldName,
                new FormControl(
                  x.value || '',
                  x.required ? Validators.required : null
                )
              );
            }
          });
          this.isGetProfileLoader=false;
        },
        (error) => {
          this.isGetProfileLoader=false;
        }
      );
  }
  getFields() {
    return this.fields;
  }

  getValidatorFnArray(setting?) {
    const validators = [];

    if (this.passwordIsInactive === 'inactive') {
      if (setting.charTypes.lowerCase) {
        validators.push(
          CustomValidators.patternValidator(null, {
            hasSmallCase: false,
          })
        );
      }
      if (setting.charTypes.specialChar) {
        validators.push(
          CustomValidators.patternValidator(
            null,
            {
              hasSpecialCharacters: false,
            }
          )
        );
      }

      if (setting.charTypes.upperCase) {
        validators.push(
          CustomValidators.patternValidator(null, {
            hasCapitalCase: false,
          })
        );
      }

      if (setting.charTypes.numbers) {
        validators.push(
          CustomValidators.patternValidator(null, {
            hasNumber: false,
          })
        );
      }

      if (setting.charTypes.numbers) {
        validators.push(
          CustomValidators.patternValidator(null, {
            minlength: false,
          })
        );
      }

      this.validators.push(...validators);
      // this.resetPasswordForm();
    }

    else {
      if (setting.charTypes.lowerCase) {
        validators.push(
          CustomValidators.patternValidator(/[a-z]/, {
            hasSmallCase: true,
          })
        );
      }
      if (setting.charTypes.specialChar) {
        validators.push(
          CustomValidators.patternValidator(
            /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
            {
              hasSpecialCharacters: true,
            }
          )
        );
      }

      if (setting.charTypes.upperCase) {
        validators.push(
          CustomValidators.patternValidator(/[A-Z]/, {
            hasCapitalCase: true,
          })
        );
      }

      if (setting.charTypes.numbers) {
        validators.push(
          CustomValidators.patternValidator(/\d/, {
            hasNumber: true,
          })
        );
      }

      if (setting.charTypes.numbers) {
        validators.push(
          CustomValidators.patternValidator(/^.{8,}$/, {
            minlength: true,
          })
        );
      }

      this.validators.push(...validators);
      // this.resetPasswordForm();
    }
  }

  changePasswordState(value) {
    // this.passwordRestForm?.reset();
    this.modelService.open(this.changePasswordProfile, {
      windowClass: 'modal-ui-fix right-align',
    });
    const sub = this.passwordSettings
      .getPasswordSetting()
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe(
        async (Res) => {
          const passwordSetting = await Res.data?.pageSettingData?.pwdSettings;
          this.getValidatorFnArray(passwordSetting);

          this.isPasswordReset = value;
        },
        (error) => {

        }
      );
  }
  changeRecoveryMobileNumber() {
    this.modelService.open(this.changeMobileNumber, {
      windowClass: 'modal-ui-fix right-align',
    });
    this.recoveryForm.reset();
    this.recoveryForm.updateValueAndValidity()
    if (this.isEmail == 1) {
      this.recoveryForm.get('recoveryEmail').setValidators([Validators.required, Validators.email]);
      this.recoveryForm.get('recoveryEmail').updateValueAndValidity();
    } else if (this.isEmail == 2) {
      this.recoveryForm.get('recoveryPhoneNumber').setValidators([Validators.required, Validators.email]);
      this.recoveryForm.get('recoveryPhoneNumber').updateValueAndValidity();
    }
  }
  closeChangePasswordPopUp() {
    this.modelService.dismissAll();
    this.submitted3 = false;
  }

  getPasswordSetupDetails() {
    const sub = this.companySetupService
      .getPasswordSetting()
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe((res) => {
        this.passwordIsInactive = res.data?.pageSettingData?.pwdSettings?.status == 1 ? 'active' : 'inactive';
       this.isEmail = res.data?.pageSettingData?.pwdSettings?.otpSentFor;
      });
  }

  onRestPassword() {
    if (this.passwordRestForm.invalid) {
      this.submitted3 = true;
      return;
    }

    this.passwordRestForm.patchValue({
      password: this.passwordRestForm.value.password,
    });
    this.onSubmit(true);

  }

  changePhoneNumber(isEmail) {
    if (isEmail === 1) {
      if (this.forgetPasswordForm.valid) {
        this.toastService.success('Email changed Succesfully', '', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
        this.onSubmit()
        this.modelService.dismissAll()
      }
      else {
        this.toastService.error('Please Enter Valid Email', '', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
      }
    }

    else if (isEmail === 2) {
      if (this.forgetPasswordForm.value.primaryMobileNumber !== null) {
        this.toastService.success('Mobile changed Succesfully', '', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
        this.onSubmit()
        this.modelService.dismissAll()
      }
      else {
        this.toastService.error('Please Enter a Mobile Number', '', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
      }
    }
  }

  onSubmit(passwordUpdate?: boolean) {
    const modifiedData = this.fields.map((rawProduct) => {
      return {
        fieldId: rawProduct._id,
        editable: rawProduct.editable,
      };
    });

    const values = Object.values(this.form.value);
    let newObject = modifiedData.map((item, i) =>
      Object.assign({}, item, { value: values[i] })
    );
    const { number } = this.forgetPasswordForm.get(
      'primaryMobileNumber'
    ).value;
    this.forgetPasswordForm.patchValue({
      primaryMobileNumber: number,
      profilePicture: this.forgetPasswordForm.value.profilePicture
    });
    this.userService.setProfileImage(this.forgetPasswordForm.value.profilePicture);
    // localStorage.setItem('userProfileImage',this.forgetPasswordForm.value.profilePicture);
    const payload = { ...this.forgetPasswordForm.value, otherFields: newObject, userId: localStorage.getItem('loggedInUserId') };
    if (passwordUpdate) {
      payload.password = this.passwordRestForm.get('password').value;
    }
    const sub = this.userService
      .updateUser(payload)
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe((res) => {
       this.toastService.success('Profile Updated Successfully');
        if (passwordUpdate) {
          this.closeChangePasswordPopUp();
        }
        this.loadProfileDetails()
      },
        (error) => {

        });
  }

  requestForOTP() {
    if (this.recoveryForm.invalid) {
      this.isSubmittedRecovery = true;
      return true;
    }
   const payload = {
      primaryMobileNumber: this.recoveryForm.get('recoveryPhoneNumber').value?.number,
      countryCode: this.recoveryForm.get('recoveryPhoneNumber').value?.dialCode,
      userId: localStorage.getItem('loggedInUserId'),
      pathName: "sats"
    }
    if (this.recoveryForm.valid) {
      this.auth.requestForgetPassword(payload).subscribe(response => {
        const data = response?.data;
        this.toastService.success(data?.message);
        this.recoveryForm.get('token').setValue(data.token);
        }, error => {
        this.isSubmittedRecovery=false;
         this.toastService.error(error);});
    }
  }

  verifyOTP(){
    this.isVerifyOTP=true;
    const payload = {
      otp: this.recoveryForm.get('mobileOTP').value,
      token: this.recoveryForm.get('token').value,
    }
    if (payload.otp && payload.token) {
      this.auth.verifyOTP(payload).subscribe(response => {
        const data = response?.data;
        this.toastService.success(data);
        this.loadProfileDetails();
       this.closeRecoveryPopUp();
      }, error => {
        this.isVerifyOTP=false;
        this.toastService.error(error);});
    }
  }

  closeRecoveryPopUp(){
    this.modelService.dismissAll();
    this.recoveryForm?.reset();
    this.isVerifyOTP=false;
    this.isSubmittedRecovery=false;
  }

  validateURLImage(str) {
    var img_Path = str;
    if (img_Path.indexOf("https://") == 0) {
      var new_url = new URL(str);
     var pathName = (new_url.pathname.startsWith('//')) ? `/${new_url.pathname.split("//")[1]}` : new_url.pathname;
      return `${this.baseUrl}${pathName}`;
    }
    if (str.startsWith('/')) return this.baseUrl + str;
    return `${this.baseUrl}/${str}`;
   }
}
