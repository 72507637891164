import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { StorageService } from 'src/app/core/service/storage.service';
import { AuthService } from '../service/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AutoLoginGuard implements CanActivate {
  public readonly JWT_TOKEN = 'usersToken';
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null
  );
  constructor(private authService: AuthService, private router: Router, private storageService:StorageService) {
    
   }

  canActivate(): Observable<boolean> {
    this.loadToken();
    return this.isAuthenticated.pipe(
      filter(val => val !== null), // Filter out initial Behaviour subject value
      take(1),
      // Otherwise the Observable doesn't complete!
      map(isAuthenticated => {
        if (isAuthenticated) {
          // Directly open inside area
          this.router.navigateByUrl('/users/home', { replaceUrl: true });
        } else {
          // Simply allow access to the login
          return true;
        }
      })
    );
  }

  loadToken() {
    const token = this.storageService.getByKey(this.JWT_TOKEN);
    if (token) {
      this.isAuthenticated.next(true);

    } else {
      this.isAuthenticated.next(false);
    }
  }

}
