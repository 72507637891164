import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { createQueryString } from 'src/app/core/utils/helper';
import { environment } from 'src/environments/environment';
import { queryParam } from '../../../core/utils/helper';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OpsGroupService {
  private baseUrl: string;
  private get Headers(): HttpHeaders {
    return new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }
  allPassedData: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }

  storePassedObject(passedData) {
    this.allPassedData.next(passedData);
  }

  createOpsForm(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/opsGroup`, data, {
      headers: this.Headers,
    });
    //.pipe(map((res) => res as any));
  }

  saveAsDraftOpsForm(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/opsgroup`, data, {
      headers: this.Headers,
    });
    //.pipe(map((res) => res as any));
  }

  staffList(data): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/opsGroup/unassign/stafflist `,
      data,
      {
        headers: this.Headers,
      }
    );
  }

  getOpsGruop(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/opsGroup/get`, data, {
      headers: this.Headers,
    });
  }

  viewOpsGruop(id): Observable<any> {
    return this.http.get(`${this.baseUrl}/opsgroup/detail/${id}`, {
      headers: this.Headers,
    });
  }

  getExportOpsCSV(data) {
    return this.http.post(`${this.baseUrl}/opsgroup/export`, data, {
      headers: this.Headers,
    });
  }

  getImportStaff(f) {
    const formData: FormData = new FormData();
    // formData.append('shift', JSON.stringify(d));
    formData.append('file', f);
    return this.http.post(`${this.baseUrl}/opsgroup/importstaff`, formData, {
      headers: this.Headers,
    });
  }

  getImportQuota(f) {
    const formData: FormData = new FormData();
    formData.append('ff', f);
    return this.http.post(`${this.baseUrl}/ballot/sapdata`, formData);
  }

  deleteOpsGruop(id): Observable<any> {
    return this.http.post(`${this.baseUrl}/opsGroup/${id}`, {
      headers: this.Headers,
    });
  }
  updateOpsGroup(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/opsgroup/update`, data, {
      headers: this.Headers,
    });
  }
  removeTeam(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/opsgroup/remove/staff`, data, {
      headers: this.Headers,
    });
  }
  removeBu(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/opsgroup/remove/bu`, data, {
      headers: this.Headers,
    });
  }

  getOpsGroupAdminList(): Observable<any>{
    return this.http.get(`${this.baseUrl}/opsgroup/bu/adminlist`, {
      headers: this.Headers,
    });
  }

  getStaffListBasedOnOpsGroup(data){
    return this.http.post(`${this.baseUrl}/opsgroup/team/stafflist`,data, {
      headers: this.Headers,
    });
  }
}
