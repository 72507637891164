import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { NewsEventsService } from 'src/app/users/service/manage-news & events/news-events.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-events-view',
  templateUrl: './events-view.component.html',
  styleUrls: ['./events-view.component.scss'],
})
export class EventsViewComponent implements OnInit {
  @Input() eventId: string = null;
  @Input() eventData: any = null;

  eventObject;
  contentTitle;
  eventTitle;
  @Output() ViewEvent = new EventEmitter<string>();
  logPtable: {
    sortBy: string;
    sortWith: string;
    postId: string;
    data: any[];
    page: number;
    limit: number;
    search: string;
  };
  baseUrl = environment.imageUrl;
  constructor(public newsService: NewsEventsService) {}

  ngOnInit() {
    this.logPtable = {
      sortBy: 'desc',
      sortWith: 'createdAt',
      postId: this.eventId,
      data: [],
      page: 1,
      limit: 10,
      search: '',
    };
    this.eventId && this.getEventsview(this.eventData);
    this.eventId && this.getSinglePostLog(this.eventId);
  }
  getEventsview(eventObj: any) {
          this.eventObject = eventObj;
          this.contentTitle = this.eventObject.content.title;
          this.eventTitle = this.eventObject.content.content;
    // const sub = this.newsService
    //   .getSingleNewsEvents(id)
    //   .pipe(
    //     finalize(() => {
    //       sub.unsubscribe();
    //     })
    //   )
    //   .subscribe(
    //     ({ data }) => {
    //       this.eventObject = data;
    //       this.contentTitle = this.eventObject.content.title;
    //       this.eventTitle = this.eventObject.content.content;
    //       // this.eventFormGroup.patchValue({
    //       //   ...event,
    //       // });
    //       // this.contentFormGroup.patchValue({
    //       //   ...publish,
    //       // });
    //       // this.publicationFormGroup.patchValue({
    //       //   ...event,
    //       // });
    //       // this.eventBoardFormGroup.patchValue({
    //       //   ...eventBoard,
    //       // });
    //       // this.sessionsForm.patchValue({
    //       //   ...session,
    //       // });
    //       // this.adminstrationFormGroup.patchValue({
    //       //   ...admin,
    //       // });
    //     },
    //     (error) => {}
    //   );


  }

  onCancelView() {
    this.ViewEvent.emit(null);
  }

  getSinglePostLog(id: string) {
    const { data, ...rest } = this.logPtable;

    const sub = this.newsService
      .getSinglePostLog(rest)
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe(
        ({ data }) => {
          this.logPtable.data = data.data;
        },
        (error) => {}
      );
  }

  validateURLImage(str) {
    var img_Path = str;
    if (img_Path.indexOf("https://") == 0) {
      var new_url = new URL(str);
     var pathName = (new_url.pathname.startsWith('//')) ? `/${new_url.pathname.split("//")[1]}` : new_url.pathname;
      return `${this.baseUrl}${pathName}`;
    }
    if (str.startsWith('/')) return this.baseUrl + str;
    return `${this.baseUrl}/${str}`;
   }
}
