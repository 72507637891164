import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subject, Subscription, of } from 'rxjs';
import { map, debounceTime, distinctUntilChanged, mergeMap, delay, finalize } from 'rxjs/operators';
import { SortableHeader, SortEvent } from 'src/app/shared/directive/sortable.directive';
import { UsersService } from 'src/app/users/service/user-service/users.service';
import { ToastrService } from 'ngx-toastr';
import { tableParam } from 'src/app/core/model/table-param.model';
import { TableService } from 'src/app/core/service/table.service';

@Component({
  selector: 'rest-password-overview',
  templateUrl: './rest-password-overview.component.html',
  styleUrls: ['./rest-password-overview.component.scss']
})
export class RestPasswordOverviewComponent implements OnInit {
  usersPTable;
  locAccTableColumn=['staffId.name','staffId.staffId','createdAt','resetUserId.staffId','resetUserId.name'];
  tableQueryParams=tableParam;
  // isBuUnitEdited:boolean = false
  // sectionId:string
  // subSectionId:string
  // bussinessUnitEditForm:FormGroup
  public userssearchInput = new Subject<KeyboardEvent>();
  isTableLoading: boolean = false;
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  // companyName: string;
  /// bussiness unit over view table config
  onSortUsers({ column, direction }: SortEvent, field) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.usersPTable.sort = { key: column, order: direction as any };
    this.usersPTable.sortBy = column;
    this.usersPTable.sortWith = direction;
    this.loadUsers();
  }

  onPageChangeUsers(page: number) {
    this.tableQueryParams.start = +page > 1 ? (page - 1) * this.usersPTable.limit : 0;
    this.loadUsers();
  }
  onLimitChangeUsers() {
    this.tableQueryParams.start=0;
    this.loadUsers();
  }

  private usersSearchSubscription: Subscription;

  constructor(
    private userService: UsersService,
    public toastService: ToastrService,
    private tableService:TableService

  ) {
    // this.config.notFoundText = 'Custom not found';
    this.usersPTable = {
      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,
      limit: 10,
      search: '',
    };
    // this.companyName = this.storageService.getByKey('companyId');

    this.usersSearchSubscription = this.userssearchInput
      .pipe(
        map((event) => {
          const target = event.target as HTMLTextAreaElement;
          return target.value;
        }),
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap((search) => of(search).pipe(delay(500)))
      )
      .subscribe((input) => {
        this.usersPTable.page = 1;
        this.tableQueryParams.search.value = input;
        this.loadUsers();
      });
  }

  ngOnInit(): void {
    this.tableQueryParams.draw = 0;
    this.tableQueryParams.start = 0;
    this.loadUsers();
  }

  loadUsers() {
    let tableCol:any = this.tableService.initializeTableParamsColums(this.locAccTableColumn, [0],[0]) || [];
    this.tableQueryParams.columns=tableCol;
    this.tableQueryParams.draw++;
    this.tableQueryParams.length=this.usersPTable.limit;
    this.isTableLoading=true;
    const subscribe = this.userService
      .getRestPassword(this.tableQueryParams)
      .pipe(
        finalize(() => {

          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          // const { count, data } = response.data;
          this.usersPTable.data = response.data;
          this.usersPTable.total = response.recordsTotal;
          this.isTableLoading = false;
        },
        (error) => {
          this.usersPTable.data = [];
          this.usersPTable.total = 0;
          this.isTableLoading = false;
          this.toastService.error('No list found');
        }
      );
  }

  ngOnDestroy(): void {
    this.usersSearchSubscription && this.usersSearchSubscription.unsubscribe();
  }
}
