import { Component, OnInit ,Input} from '@angular/core';
import { CustomFormService } from 'src/app/users/service/custom-form/custom-form.service';


@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {
  @Input() customFormId: string = null;
  @Input() formTitle: string = null;

  usersPTable;
  followUP;
  constructor(private customFormService: CustomFormService,) { }

  ngOnInit(): void {
    let finalData ={
      customFormId:this.customFormId
    }
    this.customFormService.getManageFormUser(finalData).subscribe((data)=>{
      this.assignValue(data.data)

     })
  }
  assignValue(value){
    value.data.map((val)=>{
      this.followUP = val?.showFormWorkflowStatus ? val?.showFormWorkflowStatus[0]?.fieldName : val?.showFormStatus[0]?.fieldName;
    })
    this.usersPTable = value.data;
    console.log(this.usersPTable);

  }

}
