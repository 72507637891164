<div class="card my-3 w-100">
  <div class="card-header flexi-header p-4">
    <h3>SOCIAL WALL GROUP DETAIL</h3>
    <button
      class="btn pull-right btn-outline-light"
      (click)="onCancelEdit()"
      type="button"
    >
      Back
    </button>
  </div>

  <div class="card-body">
    <form [formGroup]="wallCreateForm">
      <div class="row">
        <div class="col-md-6 mt-1">
          <div class="form-group">
            <label class="pb-2 font-weight-bold" for="exampleInputEmail1"
              >Social Wall Group Name (Limit to 100 characters)</label
            >
            <input
              type="text"
              disabled
              class="form-control"
              placeholder="wall name"
              formControlName="wallName"
              required
            />
          </div>
        </div>
      </div>
      <br/>
      <div class="row">
        <div class="col-md-6 mt-1">
          <div class="form-group pr-0">
            <input type="checkbox" disabled formControlName="quickNavEnabled" />
            Allow this form to be placed in quick navigation
          </div>
        </div>
      </div>
      <br/>
      <div class="row">
        <div class="col-md-6 mt-1">
          <div class="form-group">
            <label class="pb-2 font-weight-bold" for="exampleInputEmail1"
              >Social Icon</label
            >
            <div *ngIf="!wallCreateForm.get('bannerImage').value">
              <div class="custom-file mt-0 w-50 pt-4 pb-4" style="width: 67px">
                <input
                  type="file"
                  disabled
                  style="
                    position: absolute;
                    z-index: 9;
                    visibility: visible;
                    left: 0;
                    top: 0;
                    width: 100%;
                    opacity: 0;
                  "
                  (change)="iconUpload($event)"
                  required
                  id="b"
                />
                <label
                  class="font-weight-bold"
                  class="custom-file-label"
                  for="b"
                >
                  Upload Form Logo
                </label>
              </div>
            </div>
            <div *ngIf="wallCreateForm.get('bannerImage').value">
              <div class="image-with-delete-icon width-180">
                <img
                  [src]="validateURLImage(wallCreateForm.get('bannerImage').value)"
                  width="300px"
                  height="300px"
                  id="4"
                  alt="Image Preview"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--<div class="row">
        <div class="col-3 mt-1">
          <div class="form-group">
            <label for="" class="font-weight-bold">Listing Display Setup</label>
            <div class="d-flex justify-content-between p-3">
              <div class="radio radio-danger radio-inline p-1">
                <input
                  disabled
                  type="radio"
                  formControlName="displayType"
                  id="postSubReqTru"
                  [value]="1"
                />
                <label for="postSubReqTru"> Consolidated</label>
              </div>
              <div class="radio radio-danger radio-inline p-1">
                <input
                  disabled
                  type="radio"
                  id="postSubReqFals"
                  formControlName="displayType"
                  [value]="2"
                />
                <label for="postSubReqFals">Separate track</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-3 mt-1">
          <div class="form-group">
            <label for="" class="font-weight-bold">Post Type</label>
            <div class="d-flex justify-content-between p-3">
              <div class="radio radio-danger radio-inline p-1">
                <input
                  disabled
                  type="radio"
                  formControlName="postType"
                  id="postSubReqTr"
                  [value]="1"
                />
                <label for="postSubReqTr"> Long Wall Post</label>
              </div>
              <div class="radio radio-danger radio-inline p-1">
                <input
                  disabled
                  type="radio"
                  id="postSubReqFal"
                  formControlName="postType"
                  [value]="2"
                />
                <label for="postSubReqFal"> Tall Wall Post </label>
              </div>
            </div>
          </div>
        </div>
      </div>-->
      <div class="row">
        <div class="col-3 mt-1">
          <div class="form-group">
            <label for="" class="red-label">Listing Display Setup</label>
            <div class="d-flex justify-content-between p-3" style="width: 22vw">
              <div class="radio radio-danger radio-inline p-1">
                <input
                disabled
                  type="radio"
                  formControlName="displayType"
                  id="postSubReqTru"
                  [value]="0"
                />
                <label for="postSubReqTru"> Consolidated</label>
              </div>
              <div class="radio radio-danger radio-inline p-1">
                <input
                disabled
                  type="radio"
                  id="postSubReqFals"
                  formControlName="displayType"
                  [value]="1"
                />
                <label for="postSubReqFals">Separate track</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-3 mt-1">
          <div class="form-group">
            <label for="" class="red-label">Post Type</label>
            <div
            class="d-flex justify-content-between p-3"
            style="width: 21.5vw"
          >
            <div class="radio radio-danger radio-inline p-1">
              <input
              disabled
                type="radio"
                formControlName="postType"
                id="postSubReqTr"
                [value]="0"
              />
              <label for="postSubReqTr"> Long Wall Post</label>
            </div>
            <div class="radio radio-danger radio-inline p-1">
              <input
              disabled
                type="radio"
                id="postSubReqFal"
                formControlName="postType"
                [value]="1"
              />
              <label for="postSubReqFal"> Tall Wall Post</label>
            </div>
          </div>
          </div>
        </div>
        </div>
      <div class="row">
        <div class="col-3 mt-1">
          <div class="form-group">
            <label for="" class="font-weight-bold">Tasking</label>
            <div class="d-flex justify-content-between p-3">
              <div class="radio radio-danger radio-inline p-1">
                <input
                  disabled
                  type="radio"
                  formControlName="isTaskActive"
                  id="postSubReqT"
                  [value]="true"
                />
                <label for="postSubReqT">Yes</label>
              </div>
              <div class="radio radio-danger radio-inline p-1">
                <input
                  disabled
                  type="radio"
                  id="postSubReqF"
                  formControlName="isTaskActive"
                  [value]="false"
                />
                <label for="postSubReqF"> No</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-3 mt-1">
          <div class="form-group">
            <label for="" class="font-weight-bold">Nomination</label>
            <div class="d-flex justify-content-between p-3">
              <div class="radio radio-danger radio-inline p-1">
                <input
                  disabled
                  type="radio"
                  formControlName="isNomineeActive"
                  id="postSubReq"
                  [value]="true"
                />
                <label for="postSubReq"> Yes</label>
              </div>
              <div class="radio radio-danger radio-inline p-1">
                <input
                  disabled
                  type="radio"
                  id="postSubRe"
                  formControlName="isNomineeActive"
                  [value]="false"
                />
                <label for="postSubRe">No</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-3 mt-1">
          <div class="form-group">
            <label for="" class="red-label">Admin Response</label>
            <div class="d-flex justify-content-between p-3">
              <div class="radio radio-danger radio-inline p-1">
                <input
                  disabled
                  type="radio"
                  formControlName="adminResponse"
                  id="postadminresponsetrue"
                  [value]="true"
                />
                <label for="postadminresponsetrue"> Yes</label>
              </div>
              <div class="radio radio-danger radio-inline p-1">
                <input
                  disabled
                  type="radio"
                  formControlName="adminResponse"
                  id="postadminresponsefalse"
                  [value]="false"
                />
                <label for="postadminresponsefalse">No</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-3 mt-1">
          <div class="form-group">
            <label for="" class="red-label"
              >Allow posts to be created anonymously?</label
            >
            <div class="d-flex justify-content-between p-3">
              <div class="radio radio-danger radio-inline p-1">
                <input
                  disabled
                  type="radio"
                  formControlName="postAnonymously"
                  id="isAnonymouslyActivetrue"
                  [value]="true"
                />
                <label for="isAnonymouslyActivetrue"> Yes</label>
              </div>
              <div class="radio radio-danger radio-inline p-1">
                <input
                  disabled
                  type="radio"
                  formControlName="postAnonymously"
                  id="isAnonymouslyActivefalse"
                  [value]="false"
                />
                <label for="isAnonymouslyActivefalse">No</label>
              </div>
            </div>
          </div>
        </div>
      </div>
     <!---- <div *ngIf="isNominationOpen">
        <div class="form-check">
          <input
            disabled
            type="checkbox"
            class="form-check-input"
            formControlName="nominationOnlyByAdmin"
          />
          <label class="form-check-label mt-1 ml-2" for="exampleCheck1"
            >Allow nomination only by Administrator</label
          >
        </div>
        <div formGroupName="nominationPerUser">
          <label class="container mt-4 ml-0 red-label"
            >Limit max number of nomination that can be submitted per user
            <input
              disabled
              #el
              readonly
              (change)="toggleDisableNumberofNomination()"
              type="checkbox"
              formControlName="enabled"
            />
            <span class="checkmark"></span>
          </label>

          <div class="justify-content-SB col-8">
            <div class="form-group">
              <input
                disabled
                readonly
                type="text"
                class="form-control"
                aria-describedby="emailHelp"
                placeholder="Enter Max Submissions"
                [attr.disabled]="numberOfNomination ? '' : null"
                formControlName="submissionLimit"
              />
            </div>
            <div class="form-check">
              <input
                disabled
                class="form-check-input"
                type="radio"
                [value]="1"
                formControlName="submissionPeriod"
              />
              <label class="form-check-label ml-2 mt-1"> Monthly </label>
            </div>

            <div class="form-check">
              <input
                disabled
                class="form-check-input"
                type="radio"
                [value]="2"
                formControlName="submissionPeriod"
              />
              <label class="form-check-label ml-2 mt-1"> Quarterly </label>
            </div>

            <div class="form-check">
              <input
                disabled
                class="form-check-input"
                type="radio"
                [value]="3"
                formControlName="submissionPeriod"
              />
              <label class="form-check-label ml-2 mt-1"> Bi-annually </label>
            </div>

            <div class="form-check">
              <input
                disabled
                class="form-check-input"
                type="radio"
                [value]="4"
                formControlName="submissionPeriod"
              />
              <label class="form-check-label ml-2 mt-1"> Yearly </label>
            </div>
          </div>
        </div>
      </div>-->
      <div *ngIf="wallCreateForm.value.isNomineeActive">
        <div class="form-check">
          <input
          disabled
            type="checkbox"
            class="form-check-input"
            formControlName="nominationOnlyByAdmin"
          />
          <label class="form-check-label mt-1 ml-2" for="exampleCheck1"
            >Allow nomination only by Administrator</label
          >
        </div>
        <div formGroupName="nominationPerUser">
          <label class="container mt-4 ml-0 red-label"
            >Limit max number of nomination that can be submitted per user
            <input
              #el
              disabled
              (change)="toggleDisableNumberofNomination()"
              type="checkbox"
              formControlName="enabled"
            />
            <span class="checkmark"></span>
          </label>

          <div class="justify-content-SB col-8 ml-4">
            <div class="form-group">
              <input
              disabled
                type="text"
                class="form-control"
                aria-describedby="emailHelp"
                type="number"
                pattern="/^-?\d+\.?\d*$/"
                onKeyPress="if(this.value.length == 4) return false;"
                placeholder="Enter Max Submissions"
                [attr.disabled]="numberOfNomination ? null : ''"
                formControlName="submissionLimit"
              />
            </div>

            <div class="form-check">
              <input
              disabled
                class="form-check-input"
                type="radio"
                [value]="1"
                formControlName="submissionPeriod"
              />
              <label class="form-check-label ml-2 mt-1"> Monthly </label>
            </div>

            <div class="form-check">
              <input
              disabled
                class="form-check-input"
                type="radio"
                [value]="2"
                formControlName="submissionPeriod"
              />
              <label class="form-check-label ml-2 mt-1"> Quarterly </label>
            </div>

            <div class="form-check">
              <input
              disabled
                class="form-check-input"
                type="radio"
                [value]="3"
                formControlName="submissionPeriod"
              />
              <label class="form-check-label ml-2 mt-1"> Bi-annually </label>
            </div>

            <div class="form-check">
              <input
              disabled
                class="form-check-input"
                type="radio"
                [value]="4"
                formControlName="submissionPeriod"
              />
              <label class="form-check-label ml-2 mt-1"> Yearly </label>
            </div>
          </div>
        </div>
      </div>
     <!---- <div *ngIf="isNominationOpen" formGroupName="maxNomination">
        <label class="container mt-4 ml-0 red-label"
          >Limit how many times I can nominate each user
          <input
            disabled
            #el
            (change)="toggleDisableNominateEachUser()"
            type="checkbox"
            formControlName="enabled"
          />
          <span class="checkmark"></span>
        </label>

        <div class="justify-content-SB col-8">
          <div class="form-group">
            <input
              disabled
              readonly
              type="text"
              class="form-control"
              aria-describedby="emailHelp"
              placeholder="Enter Max Submissions"
              [attr.disabled]="limitNomination ? '' : null"
              formControlName="submissionLimit"
            />
          </div>

          <div class="form-check">
            <input
              disabled
              class="form-check-input"
              type="radio"
              value="1"
              formControlName="submissionPeriod"
            />
            <label class="form-check-label ml-2 mt-1"> Monthly </label>
          </div>

          <div class="form-check">
            <input
              disabled
              class="form-check-input"
              type="radio"
              value="2"
              formControlName="submissionPeriod"
            />
            <label class="form-check-label ml-2 mt-1"> Quarterly </label>
          </div>

          <div class="form-check">
            <input
              disabled
              class="form-check-input"
              type="radio"
              value="3"
              formControlName="submissionPeriod"
            />
            <label class="form-check-label ml-2 mt-1"> Bi-annually </label>
          </div>

          <div class="form-check">
            <input
              disabled
              class="form-check-input"
              type="radio"
              value="4"
              formControlName="submissionPeriod"
            />
            <label class="form-check-label ml-2 mt-1"> Yearly </label>
          </div>
        </div>
      </div>-->
      <div
        *ngIf="wallCreateForm.value.isNomineeActive"
        formGroupName="maxNomination"
      >
        <label class="container mt-4 ml-0 red-label"
          >Limit how many times I can nominate each user
          <input
          disabled
            #el
            (change)="toggleDisableNominateEachUser()"
            type="checkbox"
            formControlName="enabled"
          />
          <span class="checkmark"></span>
        </label>

        <div class="justify-content-SB col-8 ml-4">
          <div class="form-group">
            <input
            disabled
              type="number"
              pattern="/^-?\d+\.?\d*$/"
              onKeyPress="if(this.value.length == 4) return false;"
              class="form-control"
              aria-describedby="emailHelp"
              placeholder="Enter Max Submissions"
              [attr.disabled]="limitNomination ? null : ''"
              formControlName="submissionLimit"
            />
          </div>

          <div class="form-check">
            <input
            disabled
              class="form-check-input"
              type="radio"
              [value]="1"
              formControlName="submissionPeriod"
            />
            <label class="form-check-label ml-2 mt-1"> Monthly </label>
          </div>

          <div class="form-check">
            <input
            disabled
              class="form-check-input"
              type="radio"
              [value]="2"
              formControlName="submissionPeriod"
            />
            <label class="form-check-label ml-2 mt-1"> Quarterly </label>
          </div>

          <div class="form-check">
            <input
            disabled
              class="form-check-input"
              type="radio"
              [value]="3"
              formControlName="submissionPeriod"
            />
            <label class="form-check-label ml-2 mt-1"> Bi-annually </label>
          </div>

          <div class="form-check">
            <input
            disabled
              class="form-check-input"
              type="radio"
              [value]="4"
              formControlName="submissionPeriod"
            />
            <label class="form-check-label ml-2 mt-1"> Yearly </label>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="card my-3 w-100">
  <div class="card-header flexi-header">
    <h3>CATEGORY</h3>
  </div>

  <div class="card-body">
    <form [formGroup]="wallCreateForm">
      <div class="row">
        <div class="col-md-6">
          <label class="pb-2 font-weight-bold">
            Category
            <button class="btn p-1 ml-1" disabled></button>
          </label>
          <select disabled class="dropdown-select w-100 form-control"></select>
        </div>
        <div class="col-md-6">
          <div
            style="
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              margin-top: 25px;
            "
          >
            <div
              formArrayName="category"
              *ngFor="let field of CategorytControl.controls; let index = index"
            >
              <span class="text-items list-ui" [formGroupName]="index"
                >{{ field.get("categoryName").value }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="card my-3 w-100" *ngIf="userDetails.length != 0">
  <div class="card-header flexi-header">
    <h3>SELECTED INFORMATION</h3>
  </div>

  <div class="card-body">
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>BUSINESS UNIT</th>
            <th>APPOINTMENTS</th>
            <th>SELECTED USERS</th>
            <th>SELECTED ADMINISTRATOR</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of userDetails; let index = index">
            <td>
              <ul *ngIf="item.allBuToken == true">
                <li>All Business Units Selected ({{item.allBuTokenStaffId}})</li>
              </ul>
              <ul *ngFor="let bu of item.businessUnits">
                <li *ngIf="item?.allBuToken == false">{{ bu.orgName ? bu.orgName : bu.name }}</li>
                <!--<li *ngIf="item?.allBuToken == true">All Business Unit</li>-->
              </ul>
            </td>

            <td>
              <ul *ngFor="let item of item.appointments">
                <li>{{ item.name }}</li>
              </ul>
            </td>
            <td>
              <ul *ngFor="let item of item.user">
                <li>{{ item.name }}</li>
              </ul>
            </td>
            <td>
              <!--<ul *ngFor="let item of item.admin">
                <li>{{ item.name }}</li>
              </ul>-->
              <ul *ngFor="let current of item.admin">
                <li *ngIf="item.firstAdminAddedAsDefault == true">
                  <span *ngIf="current.staffId !== 'admin001'">{{current.name}}</span> 
                  </li>
                <li *ngIf="item?.firstAdminAddedAsDefault == false || !item?.firstAdminAddedAsDefault"> {{current.name}}</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
