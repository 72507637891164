import { Directive, EventEmitter, Input, Output, ViewContainerRef, TemplateRef } from '@angular/core';

@Directive({
  selector: '[tdloop]',

})
export class TableTdLoop {
constructor(private viewContainer:ViewContainerRef,
  private templateRef:TemplateRef<any>){

}
@Input('tdloop') set render(times:number){
  this.viewContainer.clear()

  for(let i =0; i< times; i++){
    this.viewContainer.createEmbeddedView(this.templateRef,{})
  }
}
}
