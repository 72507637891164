import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import * as moment from "moment";

@Component({
  selector: "app-booking",
  templateUrl: "./booking.component.html",
  styleUrls: ["./booking.component.scss"],
})
export class BookingComponent implements OnInit {
  @Input() shift;
  @Input() week;
  @Input() buId;
  @Input() page;
  @Output() headerData = new EventEmitter<any>();
  @Output() day = new EventEmitter<any>();

  cancel: boolean = false;

  dayArr = [
    { count: 0, day: "M" },
    { count: 1, day: "T" },
    { count: 2, day: "W" },
    { count: 3, day: "T" },
    { count: 4, day: "F" },
    { count: 5, day: "S" },
    { count: 6, day: "S" },
  ];

  weekDates = [];
  skillLabel = [];

  selectedDay: any;
  dayShifts: any;
  ShiftForm: FormGroup;
  listShift: any;
  selectedShift: any;
  plannedHr: number;
  plannedStaff: number;
  headerDataArr: any;
  hideBtn: boolean = true;
  PersonalData: FormGroup;
  username: any;
  usernameId: any;
  isSplitTime: any;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.selectedDay = this.dayArr[0];

    this.PersonalData = this.fb.group({
      phone: [""],
      email: [""],
    });

    this.ShiftForm = this.fb.group({
      buName: [""],
      date: [""],
      startTime: [""],
      endTime: [""],
      splitstartTime: [""],
      splitendTime: [""],
      reportLocationId: [""],
      staffNeedCount: [""],
      backUpStaffNeedCount: [""],
    });

    this.createDays();
    this.getSelectedWeek1(this.selectedDay);
    this.getPage();
  }

  cancelShift(val) {
    this.cancel = val;
  }

  getPage() {
    if (this.page == "view") {
      this.hideBtn = false;
    }
  }

  createDays() {
    for (var i = 0; i < 7; i++) {
      var j = moment(this.week);
      this.weekDates.push(j.add(i, "days").format("DD-MM-YYYY"));
    }
  }

  getSelectedWeek1(day) {
    this.day.emit(day);
    this.selectedDay = day;
    for (var i = 0; i < 7; i++) {
      if (this.selectedDay.count == i && this.shift.hasOwnProperty('data')) {
        if (this.shift.data.hasOwnProperty('list') && Object.keys(this.shift.data.list).length > 0) {
          if (Object.keys(this.shift.data.list).includes(this.weekDates[i])) {
            this.dayShifts = this.shift?.data.list[this.weekDates[i]];
            this.getListShift(this.dayShifts[0]);
          } else {
            this.dayShifts = [];
          }
        } else {
          this.dayShifts = [];
          this.skillLabel = [];
          this.ShiftForm.reset();
        }
      }
    }
  }

  getListShift(i) {
    this.selectedShift = i;
    this.skillLabel = [];
    this.listShift = i;
    this.username = i.confirmedStaffs.name;
    this.usernameId = i.confirmedStaffs.staffId;
    this.isSplitTime = i.isSplitShift;

    if (i.isSplitShift) {
      this.ShiftForm.patchValue({
        splitstartTime: moment(i.splitShiftStartTime).format("HH:mm"),
        splitendTime: moment(i.splitShiftEndTime).format("HH:mm"),
      });
    }

    this.PersonalData.patchValue({
      phone: i.confirmedStaffs.contactNumber,
      email: i.confirmedStaffs.email,
    });

    this.ShiftForm.patchValue({
      buName: this.buId.orgName,
      date: moment(i.date).format("DD MMM YYYY"),
      startTime: moment(i.startTime).format("HH:mm"),
      endTime: moment(i.endTime).format("HH:mm"),
      // reportLocationId: i.reportLocationId.name,
      staffNeedCount: i.staffNeedCount,
      backUpStaffNeedCount: i.backUpStaffNeedCount,
    });

    if (i.reportLocationId != null) {
      this.ShiftForm.patchValue({
        reportLocationId: i.reportLocationId?.name,
      });
    } else {
      this.ShiftForm.patchValue({
        reportLocationId: i.geoReportingLocation?.name,
      });
    }

    if(i.subSkillSets?.length){
      i.subSkillSets.forEach((element) => {
        this.skillLabel.push([element.skillSetId.name, element.name]);
      });
    } else if(i.mainSkillSets?.length) {
      i.mainSkillSets.forEach((element) => {
        this.skillLabel.push([element.name, '_']);
      });
    }
   
  }
}
