<div class="d-flex flex-column">
  <ul
    ngbNav
    [(activeId)]="activeId"
    #companyNav="ngbNav"
    class="nav-tabs rounded mb-3"
  >
    <li [ngbNavItem]="1">
      <a ngbNavLink>OVERVIEW</a>
      <ng-template ngbNavContent>
        <bussiness-unit-overview></bussiness-unit-overview>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink>ADD</a>
      <ng-template ngbNavContent>
        <div class="card">
          <div class="card-header flexi-header p-4">
            <h3>ADD BUSINESS UNIT</h3>
          </div>
          <div class="card-body mt-4">
            <form [formGroup]="createBussinessUnitForm">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-3">
                      <label class="pb-2" class="font-weight-bold"
                        >Tier 1
                      </label>
                      <input
                        style="margin-top: 8px; height: 35px"
                        type="text"
                        class="form-control tier-1-input"
                        placeholder=" Role Description"
                        [value]="companyName"
                        readonly
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="font-weight-bold">
                        Select / Add Tier 2
                        <button
                          class="btn p-1 ml-1"
                          style="color: #a51f38"
                          (click)="addDepartment()"
                        >
                          <i
                            class="fa fa-2x fa-plus-circle fa-lg"
                            aria-hidden="true"
                          ></i></button
                      ></label>
                      <ng-select
                        class="custom"
                        bindLabel="name"
                        placeholder="Select department"
                        appendTo="body"
                        [loading]="deparmentDropDownLoading"
                        [multiple]="false"
                        [searchable]="true"
                        [clearable]="true"
                        formControlName="departmentName"
                        (search)="onSeachDepartment($event)"
                        (scrollToEnd)="loadMoreDepartmentItems($event)"
                        (clear)="onClearDepartmentDropdown($event)"
                      >
                        <ng-option
                          class="option-select"
                          [value]="item"
                          *ngFor="let item of departmentDropDown"
                        >
                          <div class="options-color">{{ item.name }}</div>
                        </ng-option>
                      </ng-select>
                    </div>

                    <div class="col-md-3">
                      <label class="font-weight-bold">
                        Select/ Add Tier 3
                        <button
                          class="btn p-1 ml-1"
                          style="color: #a51f38"
                          (click)="addSection()"
                        >
                          <i
                            class="fa fa-2x fa-plus-circle fa-lg"
                            aria-hidden="true"
                          ></i></button
                      ></label>
                      <ng-select
                        class="custom"
                        bindLabel="name"
                        placeholder="Select section"
                        appendTo="body"
                        [multiple]="false"
                        [searchable]="true"
                        [clearable]="true"
                        formControlName="sectionName"
                        [searchable]="true"
                        [loading]="sectionDropDownLoading"
                        (search)="onSeachSection($event)"
                        (scrollToEnd)="loadMoreSectionItems($event)"
                        (clear)="onClearSectionDropdown($event)"
                      >
                        <ng-option
                          [value]="item"
                          *ngFor="let item of sectionDropDown"
                        >
                          {{ item.name }}
                        </ng-option>
                      </ng-select>
                    </div>

                    <div class="col-md-3">
                      <label class="font-weight-bold">
                        Select / Add Tier 4
                        <button
                          class="btn p-1 ml-1"
                          style="color: #a51f38"
                          (click)="addSubSection()"
                        >
                          <i
                            class="fa fa-2x fa-plus-circle fa-lg"
                            aria-hidden="true"
                          ></i></button
                      ></label>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="companyNav"></div>
</div>

<ng-template #AddDepartment let-c="close" let-d="dismiss">
  <div>
    <div class="modal-content">
      <div class="modal-body" style="padding: 10px; min-height: 100vh">
        <div>
          <div class="card my-3 w-100">
            <div class="card-header flexi-header">
              <h3 class="add-header">ADD DEPARTMENT</h3>
              <button
                type="button"
                class="btn"
                aria-label="Close"
                (click)="closeModal()"
              >
                <i class="fa fa-times-circle fa-2x" aria-hidden="true"></i>
              </button>
            </div>

            <div class="card-body">
              <form [formGroup]="createDepartmentForm">
                <div class="row">
                  <div class="col-md-6">
                    <label> Company </label>
                    <select disabled class="dropdown-select form-control">
                      <option selected disabled>{{ companyName }}</option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Department</label>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="name"
                        placeholder=""
                        maxlength="40"
                      />
                      <div
                        *ngIf="createDepartmentForm.get('name').errors"
                        class="invalid-feedback"
                      >
                        <small
                          *ngIf="
                            createDepartmentForm.get('name').errors.pattern
                          "
                        >
                          Please enter a valid department name
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  class="btn btn-add pull-right"
                  [disabled]="departmentLoading"
                  [disabled]="!createDepartmentForm.valid"
                  (click)="DepartmentCreate()"
                >
                  <i
                    class="fa"
                    [ngClass]="{
                      'fa-spin fa-spinner': departmentLoading,
                      '': !departmentLoading
                    }"
                  ></i>
                  {{ isDepartmentEdited ? "UPDATE" : " ADD" }}
                </button>
              </form>
            </div>
          </div>
          <div class="card my-3 w-100">
            <div class="card-header flexi-header">
              <h3>DEPARTMENT LIST</h3>
            </div>
            <div class="card-body">
              <div class="card table-card">
                <div class="card-body flexi-body">
                  <div class="flex-column">
                    <div class="d-flex justify-content-between mt-2 mb-3">
                      <select
                        class="custom-select"
                        style="width: auto"
                        name="pageSize"
                        (change)="onLimitChangeDepartment()"
                        [(ngModel)]="departmentPTable.limit"
                      >
                        <option [ngValue]="10">10</option>
                        <option [ngValue]="20">20</option>
                        <option [ngValue]="30">30</option>
                        <option [ngValue]="40">40</option>
                        <option [ngValue]="50">50</option>
                      </select>
                      <div class="form-group form-inline mb-0">
                        <span *ngIf="departmentPTable.isLoading"
                          >Please wait...</span
                        >
                        <input
                          class="form-control ml-2"
                          name="searchTerm"
                          (keyup)="DepartmentsearchInput.next($event)"
                          placeholder="Search here"
                          [(ngModel)]="departmentPTable.search"
                        />
                      </div>
                    </div>
                    <div class="table-responsive">
                      <table class="table table-body" *ngIf="!isTableLoading">
                        <thead>
                          <tr>
                            <th scope="col">S.NO</th>
                            <th>COMPANY</th>
                            <th
                              scope="col"
                              sortable="name"
                              (sort)="onSortDepartment($event)"
                            >
                              DEPARTMENT
                            </th>

                            <th scope="col">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let item of departmentPTable.data | slice: (departmentPTable.page-1) * departmentPTable.limit : departmentPTable.page * departmentPTable.limit;
                              let i = index
                            "
                          >
                            <td>{{ i + 1 }}</td>
                            <td>{{ item?.companyId?.name }}</td>

                            <td>{{ item.name }}</td>

                            <td>
                              <button
                                class="btn btn-add p-2 m-1"
                                (click)="onEditDepartment(item)"
                              >
                                Edit
                              </button>
                              <button
                                class="btn btn-clear p-2 m-1"
                                (click)="onDeleteDepartment(item._id)"
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <app-skliton-loading-table
                      [numberOfCols]="5"
                      [isTableLoading]="isTableLoading"
                    ></app-skliton-loading-table>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="d-flex justify-content-between">
                    <p>Total records {{ departmentPTable.total }}</p>
                    <ngb-pagination
                      [collectionSize]="departmentPTable.total"
                      [(page)]="departmentPTable.page"
                      [maxSize]="5"
                      (pageChange)="onPageChangeDepartment($event)"
                      [pageSize]="departmentPTable.limit"
                    >
                    </ngb-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #AddSection let-c="close" let-d="dismiss">
  <div>
    <div class="modal-content">
      <div class="modal-body" style="padding: 10px; min-height: 100vh">
        <div>
          <div class="card my-3 w-100">
            <div class="card-header flexi-header">
              <h3 class="add-header">ADD SECTION</h3>
              <button
                type="button"
                class="btn"
                aria-label="Close"
                (click)="c('Cross click')"
              >
                <i class="fa fa-times-circle fa-2x" aria-hidden="true"></i>
              </button>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <form [formGroup]="createBussinessUnitForm">
                    <label> Department </label>
                    <ng-select
                      class="custom"
                      bindLabel="name"
                      placeholder="Select Department"
                      appendTo="body"
                      [multiple]="false"
                      [searchable]="true"
                      [clearable]="true"
                      formControlName="departmentName"
                      [loading]="deparmentDropDownLoading"
                      (search)="onSeachDepartment($event)"
                      (scrollToEnd)="loadMoreDepartmentItems($event)"
                      (clear)="onClearDepartmentDropdown($event)"
                    >
                      <ng-option
                        [value]="item"
                        *ngFor="let item of departmentDropDown"
                      >
                        {{ item.name }}
                      </ng-option>
                    </ng-select>
                  </form>
                </div>
                <div class="col-md-6">
                  <form [formGroup]="createSectionForm">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Section</label>
                      <input
                        type="text"
                        style="height: 36px"
                        class="form-control"
                        formControlName="name"
                        placeholder=""
                        maxlength="40"
                      />
                      <div
                        *ngIf="createSectionForm.get('name').errors"
                        class="invalid-feedback"
                      >
                        <small
                          *ngIf="createSectionForm.get('name').errors.pattern"
                        >
                          Please enter a valid section name
                        </small>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <button
                class="btn btn-add pull-right"
                [disabled]="!createSectionForm.valid"
                (click)="SectionCreate()"
              >
                <i
                  class="fa"
                  [ngClass]="{
                    'fa-spin fa-spinner': sectionLoading,
                    '': !sectionLoading
                  }"
                ></i>
                {{ isSectionEdited ? "UPDATE" : " ADD" }}
              </button>
            </div>
          </div>
          <div class="card my-3 w-100">
            <div class="card-header flexi-header">
              <h3>SECTION LIST</h3>
            </div>
            <div class="card-body">
              <div class="card table-card">
                <div class="card-body flexi-body">
                  <div class="flex-column">
                    <div class="d-flex justify-content-between mt-2 mb-3">
                      <select
                        class="custom-select"
                        style="width: auto"
                        name="pageSize"
                        (change)="onLimitChangeSection()"
                        [(ngModel)]="sectionPTable.limit"
                      >
                        <option [ngValue]="10">10</option>
                        <option [ngValue]="20">20</option>
                        <option [ngValue]="30">30</option>
                        <option [ngValue]="40">40</option>
                        <option [ngValue]="50">50</option>
                      </select>
                      <div class="form-group form-inline mb-0">
                        <span *ngIf="sectionPTable.isLoading"
                          >Please wait...</span
                        >
                        <input
                          class="form-control ml-2"
                          type="search"
                          name="searchTerm"
                          (keyup)="SectionsearchInput.next($event)"
                          placeholder="Search here"
                          [(ngModel)]="sectionPTable.search"
                        />
                      </div>
                    </div>
                    <div class="table-responsive">
                      <table class="table" *ngIf="!isTableLoading">
                        <thead>
                          <tr>
                            <th scope="col">S.NO</th>

                            <th
                              scope="col"
                              sortable="name"
                              (sort)="onSortSection($event)"
                            >
                              SECTION
                            </th>

                            <th scope="col">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let item of sectionPTable.data | slice: (sectionPTable.page-1) * sectionPTable.limit : sectionPTable.page * sectionPTable.limit;
                              let i = index
                            "
                          >
                            <td>{{ i + 1 }}</td>

                            <td>{{ item.name }}</td>

                            <td>
                              <button
                                class="btn btn-add"
                                (click)="editSection(item)"
                              >
                                Edit
                              </button>
                              <button
                                class="btn btn-clear"
                                (click)="onDeleteSection(item._id)"
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <app-skliton-loading-table
                      [numberOfCols]="4"
                      [isTableLoading]="isTableLoading"
                    ></app-skliton-loading-table>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="d-flex justify-content-between">
                    <p>Total records {{ sectionPTable.total }}</p>
                    <ngb-pagination
                      [collectionSize]="sectionPTable.total"
                      [(page)]="sectionPTable.page"
                      [maxSize]="5"
                      (pageChange)="onPageChangeSection($event)"
                      [pageSize]="sectionPTable.limit"
                    >
                    </ngb-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #AddSubSection let-c="close" let-d="dismiss">
  <div>
    <div *ngIf="loader" class="loader">
      <div class="pageLoading" >
          <div class="innerDiv">
              <i class="fa fa-spinner fa-spin" style="float: none;"></i> Please wait, data is updating....
          </div>
      </div>
  </div>
    <div class="modal-content">
      <div class="modal-body" style="padding: 10px; min-height: 100vh">
        <div>
          <div class="card my-3 w-100">
            <div class="card-header flexi-header">
              <h3 class="add-header">ADD SUB SECTION</h3>
              <button
                type="button"
                class="btn"
                aria-label="Close"
                (click)="c('Cross click')"
              >
                <i class="fa fa-times-circle fa-2x" aria-hidden="true"></i>
              </button>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <form [formGroup]="createBussinessUnitForm">
                    <label> Section </label>
                    <ng-select
                      class="custom"
                      bindLabel="name"
                      placeholder="Select Section"
                      appendTo="body"
                      [multiple]="false"
                      [searchable]="true"
                      [clearable]="true"
                      formControlName="sectionName"
                      [loading]="sectionDropDownLoading"
                      (search)="onSeachSection($event)"
                      (scrollToEnd)="loadMoreSectionItems($event)"
                      (clear)="onClearSectionDropdown($event)"
                    >
                      <ng-option
                        [value]="item"
                        *ngFor="let item of sectionDropDown"
                      >
                        {{ item.name }}
                      </ng-option>
                    </ng-select>
                  </form>
                </div>
                <div class="col-md-6">
                  <form [formGroup]="createSubsectionForm">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Sub Section</label>
                      <input
                        type="text"
                        style="height: 36px"
                        class="form-control"
                        formControlName="name"
                        placeholder=""
                        maxlength="40"
                      />
                      <div
                        *ngIf="createSubsectionForm.get('name').errors"
                        class="invalid-feedback"
                      >
                        <small
                          *ngIf="
                            createSubsectionForm.get('name').errors.pattern
                          "
                        >
                          Please enter a valid sub section name
                        </small>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <button
                class="btn btn-add pull-right"
                [disabled]="subsectionLoading"
                [disabled]="!createSubsectionForm.valid"
                (click)="bussinessunitCreate()"
              >
                <i
                  class="fa"
                  [ngClass]="{
                    'fa-spin fa-spinner': subsectionLoading,
                    '': !subsectionLoading
                  }"
                ></i>
                {{ isSubSectionEdited ? "UPDATE" : " ADD" }}
              </button>
            </div>
          </div>
          <div class="card my-3 w-100">
            <div class="card-header flexi-header">
              <h3>SUB SECTION LIST</h3>
            </div>
            <div class="card-body">
              <div class="card table-card">
                <div class="card-body flexi-body">
                  <div class="flex-column">
                    <div class="d-flex justify-content-between mt-2 mb-3">
                      <select
                        class="custom-select"
                        style="width: auto"
                        name="pageSize"
                        (change)="onLimitChangeSubSection()"
                        [(ngModel)]="subSectionPTable.limit"
                      >
                        <option selected [ngValue]="5">5</option>
                        <option [ngValue]="10">10</option>
                        <option [ngValue]="20">20</option>
                        <option [ngValue]="30">30</option>
                        <option [ngValue]="40">40</option>
                        <option [ngValue]="50">50</option>
                      </select>
                      <div class="form-group form-inline mb-0">
                        <span *ngIf="subSectionPTable.isLoading"
                          >Please wait...</span
                        >
                        <input
                          class="form-control ml-2"
                          type="search"
                          name="searchTerm"
                          (keyup)="SubSectionsearchInput.next($event)"
                          placeholder="Search here"
                          [(ngModel)]="subSectionPTable.search"
                        />
                      </div>
                    </div>
                    <div class="table-responsive">
                      <table class="table" *ngIf="!isTableLoading">
                        <thead>
                          <tr>
                            <th scope="col">S.NO</th>
                            <th
                              scope="col"
                              sortable="name"
                              (sort)="onSortSubSection($event)"
                            >
                              SECTION
                            </th>
                            <th
                              scope="col"
                              sortable="name"
                              (sort)="onSortSubSection($event)"
                            >
                              SUBSECTION
                            </th>
                            <th scope="col">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let item of subSectionPTable.data | slice: (subSectionPTable.page-1) * subSectionPTable.limit : subSectionPTable.page * subSectionPTable.limit;
                              let index = index
                            "
                          >
                            <td>{{ index + 1 }}</td>
                            <td>{{ item.sectionId.name }}</td>
                            <td>
                              {{ item?.name }}
                            </td>
                            <!--<td>{{ item.status || ''| titlecase }}</td>-->

                            <td>
                              <button
                                class="btn btn-add p-2 m-1"
                                (click)="editSubSection(item)"
                              >
                                Edit
                              </button>
                              <button
                                class="btn btn-clear p -2 m-1"
                                (click)="onDeleteSubSection(item._id)"
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <app-skliton-loading-table
                      [numberOfCols]="4"
                      [isTableLoading]="isTableLoading"
                    ></app-skliton-loading-table>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="d-flex justify-content-between">
                    <p>Total records {{ subSectionPTable.total }}</p>
                    <ngb-pagination
                      [collectionSize]="subSectionPTable.total"
                      [(page)]="subSectionPTable.page"
                      [maxSize]="5"
                      (pageChange)="onPageChangeSubSection($event)"
                      [pageSize]="subSectionPTable.limit"
                    >
                    </ngb-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
