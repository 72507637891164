<div class="d-flex flex-column">
  <ul ngbNav #companyNav="ngbNav" class="nav-tabs rounded mb-3">
    <li [ngbNavItem]="1">
      <a ngbNavLink>MASTER DATA</a>
      <ng-template ngbNavContent>
        <div class="card">
          <h5 class="card-header bg-theme">LOGS</h5>
          <div class="card-body">
            <div class="flex-column">
              <div class="d-flex justify-content-between mt-2 mb-3">
                <select
                  class="custom-select"
                  style="width: auto"
                  name="pageSize"
                  (change)="onLimitChangeMaster()"
                  [(ngModel)]="mastersUnitPTable.limit"
                >
                  <option [ngValue]="10">10</option>
                  <option [ngValue]="20">20</option>
                  <option [ngValue]="30">30</option>
                  <option [ngValue]="40">40</option>
                  <option [ngValue]="50">50</option>
                </select>
                <div class="form-group form-inline mb-0">
                  <span *ngIf="mastersUnitPTable.isLoading"
                    >Please wait...</span
                  >
                  <input
                    class="form-control ml-2"
                    type="search"
                    name="searchTerm"
                    (search)="loadBussinessUnit()"
                    (keyup)="BussinessunitsearchInput.next($event)"
                    placeholder="Search here"
                    [(ngModel)]="mastersUnitPTable.search"
                  />
                </div>
              </div>

              <div class="table-responsive">
                <table class="table bg-white" *ngIf="!isTableLoading">
                  <thead>
                    <tr class="table-header">
                      <th scope="col" sortable="Tier 3">STATUS</th>

                      <th scope="col" sortable="Tier 4">NEW USERS</th>
                      <th scope="col" sortable="Status">UPDATED USERS</th>
                      <th scope="col">FAILED USERS</th>
                      <th scope="col">DATE</th>
                      <th scope="col">ERROR FILE PATH</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr *ngFor="let item of mastersUnitPTable.data">
                      <td>{{ item.status }}</td>
                      <td>{{ item.newTier2 }}</td>
                      <td>{{ item.newTier3 }}</td>
                      <td>{{ item.newTitle }}</td>
                      <td>{{ item.createdAt | date }}</td>
                      <td>{{ item.sourcePath }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <app-skliton-loading-table
                [numberOfCols]="6"
                [isTableLoading]="isTableLoading"
              ></app-skliton-loading-table>
            </div>
          </div>
          <div class="card-footer">
            <div class="d-flex justify-content-between">
              <p>Total records {{ mastersUnitPTable.total }}</p>
              <ngb-pagination
                [collectionSize]="mastersUnitPTable.total"
                [(page)]="mastersUnitPTable.page"
                [maxSize]="5"
                (pageChange)="onPageChangeMaster($event)"
                [pageSize]="mastersUnitPTable.limit"
              >
              </ngb-pagination>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink>USERS</a>
      <ng-template ngbNavContent>
        <div class="card">
          <h5 class="card-header bg-theme">LOGS</h5>
          <div class="card-body">
            <div class="flex-column">
              <div class="d-flex justify-content-between mt-2 mb-3">
                <select
                  class="custom-select"
                  style="width: auto"
                  name="pageSize"
                  (change)="onLimitChangeUsers()"
                  [(ngModel)]="usersUnitTable.limit"
                >
                  <option [ngValue]="10">10</option>
                  <option [ngValue]="20">20</option>
                  <option [ngValue]="30">30</option>
                  <option [ngValue]="40">40</option>
                  <option [ngValue]="50">50</option>
                </select>
                <div class="form-group form-inline mb-0">
                  <span *ngIf="usersUnitTable.isLoading">Please wait...</span>
                  <input
                    class="form-control ml-2"
                    type="search"
                    name="searchTerm"
                    (search)="loadUserLogs()"
                    (search)="UserssearchInput.next($event)"
                    (keyup)="UserssearchInput.next($event)"
                    placeholder="Search here"
                    [(ngModel)]="usersUnitTable.search"
                  />
                </div>
              </div>

              <div class="table-responsive">
                <table class="table bg-white" *ngIf="!isUserTableLoading" >
                  <thead>
                    <tr class="table-header">
                      <th scope="col" sortable="company">COMPANY</th>
                      <th scope="col" sortable="Tier 3">STATUS</th>
                      <th scope="col" sortable="Tier 4">NEW USERS</th>
                      <th scope="col" sortable="Status">UPDATED USERS</th>
                      <th scope="col">FAILED USERS</th>
                      <th scope="col">DATE</th>
                      <th scope="col">ERROR FILE PATH</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr *ngFor="let item of usersUnitTable.data">
                      <td>{{ item.company?.name }}</td>
                      <td>{{ item.status }}</td>
                      <td>{{ item.noOfNewUsers }}</td>
                      <td>{{ item.noOfUpdatedUsers }}</td>
                      <td>{{ item.faildUpdateUsers }}</td>
                      <td>{{ item.createdAt | date :'MMMM d, y h:mm a' }}</td>
                      <td>
                        <span *ngIf="item.status === 'Partially completed'" class="btn btn-link p-0" (click)="downloadFile(item.errorFilePath,item.errorFilePath)">Download</span>
                      </td>
                      </tr>
                  </tbody>
                </table>
                <ngx-skeleton-loader
                *ngIf="isUserTableLoading"
                count="7"
                [theme]="{
                  width: 'calc(100% - 20px)',
                  'border-radius': '6px',
                  height: '44px',
                  margin: '10px',
                  display: 'block'
                }"
              ></ngx-skeleton-loader>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="d-flex justify-content-between">
              <p>Total records {{ usersUnitTable.total }}</p>
              <ngb-pagination
                [collectionSize]="usersUnitTable.total"
                [(page)]="usersUnitTable.page"
                [maxSize]="5"
                (pageChange)="onPageChangeUsers($event)"
                [pageSize]="usersUnitTable.limit"
              >
              </ngb-pagination>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="3">
      <a ngbNavLink>LBSQUOTA</a>
      <ng-template ngbNavContent>
        <div class="card">
          <h5 class="card-header bg-theme">LOGS</h5>
          <div class="card-body">
            <div class="flex-column">
              <div class="d-flex justify-content-between mt-2 mb-3">
                <select
                  class="custom-select"
                  style="width: auto"
                  name="pageSize"
                  (change)="onLimitChangeUsers()"
                  [(ngModel)]="lbsQuotaUnitTable.limit"
                >
                  <option [ngValue]="10">10</option>
                  <option [ngValue]="20">20</option>
                  <option [ngValue]="30">30</option>
                  <option [ngValue]="40">40</option>
                  <option [ngValue]="50">50</option>
                </select>
                <div class="form-group form-inline mb-0">
                  <span *ngIf="lbsQuotaUnitTable.isLoading">Please wait...</span>
                  <input
                    class="form-control ml-2"
                    type="search"
                    name="searchTerm"
                    (search)="loadUserLogs()"
                    (search)="UserssearchInput.next($event)"
                    (keyup)="UserssearchInput.next($event)"
                    placeholder="Search here"
                    [(ngModel)]="lbsQuotaUnitTable.search"
                  />
                </div>
              </div>

              <div class="table-responsive">
                <table class="table bg-white" *ngIf="!lbsquotaTableLoading" >
                  <thead>
                    <tr class="table-header">
                      <th scope="col" sortable="company">COMPANY</th>
                      <th scope="col" sortable="Tier 3">STATUS</th>
                      <th scope="col" sortable="Tier 4">NEW USERS</th>
                      <th scope="col" sortable="Status">UPDATED USERS</th>
                      <th scope="col">FAILED USERS</th>
                      <th scope="col">DATE</th>
                      <th scope="col">ERROR FILE PATH</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr *ngFor="let item of lbsQuotaUnitTable.data">
                      <td>{{ item.company?.name }}</td>
                      <td>{{ item.status }}</td>
                      <td>{{ item.noOfNewUsers }}</td>
                      <td>{{ item.noOfUpdatedUsers }}</td>
                      <td>{{ item.faildUpdateUsers }}</td>
                      <td>{{ item.createdAt | date :'MMMM d, y h:mm a' }}</td>
                      <td>
                        <span *ngIf="item.status === 'Partially completed'" class="btn btn-link p-0" (click)="downloadFile(item.errorFilePath,item.errorFilePath)">Download</span>
                      </td>
                      </tr>
                  </tbody>
                </table>
                <ngx-skeleton-loader
                *ngIf="lbsquotaTableLoading"
                count="7"
                [theme]="{
                  width: 'calc(100% - 20px)',
                  'border-radius': '6px',
                  height: '44px',
                  margin: '10px',
                  display: 'block'
                }"
              ></ngx-skeleton-loader>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="d-flex justify-content-between">
              <p>Total records {{ lbsQuotaUnitTable.total }}</p>
              <ngb-pagination
                [collectionSize]="lbsQuotaUnitTable.total"
                [(page)]="lbsQuotaUnitTable.page"
                [maxSize]="5"
                (pageChange)="onPageChangeLbsQuota($event)"
                [pageSize]="lbsQuotaUnitTable.limit"
              >
              </ngb-pagination>
            </div>
          </div>
        </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="4">
        <a ngbNavLink>LBSAPPROVED</a>
        <ng-template ngbNavContent>
          <div class="card">
            <h5 class="card-header bg-theme">LOGS</h5>
            <div class="card-body">
              <div class="flex-column">
                <div class="d-flex justify-content-between mt-2 mb-3">
                  <select
                    class="custom-select"
                    style="width: auto"
                    name="pageSize"
                    (change)="onLimitLbsApprovedUsers()"
                    [(ngModel)]="lbsApprovedUnitTable.limit"
                  >
                    <option [ngValue]="10">10</option>
                    <option [ngValue]="20">20</option>
                    <option [ngValue]="30">30</option>
                    <option [ngValue]="40">40</option>
                    <option [ngValue]="50">50</option>
                  </select>
                  <div class="form-group form-inline mb-0">
                    <span *ngIf="lbsApprovedUnitTable.isLoading">Please wait...</span>
                    <input
                      class="form-control ml-2"
                      type="search"
                      name="searchTerm"
                      (search)="loadLbsApproved()"
                      (search)="lbsApprovedsearchInput.next($event)"
                      (keyup)="lbsApprovedsearchInput.next($event)"
                      placeholder="Search here"
                      [(ngModel)]="lbsApprovedUnitTable.search"
                    />
                  </div>
                </div>

                <div class="table-responsive">
                  <table class="table bg-white" *ngIf="!lbsApprovedTableLoading" >
                    <thead>
                      <tr class="table-header">
                        <th scope="col" sortable="Tier 3">COMPANY</th>
                        <th scope="col" sortable="Tier 3">STATUS</th>
                        <th scope="col" sortable="Tier 4">NEW USERS</th>
                        <th scope="col" sortable="Status">UPDATED USERS</th>
                        <th scope="col">FAILED USERS</th>
                        <th scope="col">DATE</th>
                        <th scope="col">ERROR FILE PATH</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr *ngFor="let item of lbsApprovedUnitTable.data">
                        <td>{{ item.company?.name }}</td>
                        <td>{{ item.status }}</td>
                        <td>{{ item.noOfNewUsers }}</td>
                        <td>{{ item.noOfUpdatedUsers }}</td>
                        <td>{{ item.faildUpdateUsers }}</td>
                        <td>{{ item.createdAt | date :'MMMM d, y h:mm a' }}</td>
                        <td>
                          <span *ngIf="item.status === 'Partially completed'" class="btn btn-link p-0" (click)="downloadFile(item.errorFilePath,item.errorFilePath)">Download</span>
                        </td>
                        </tr>
                    </tbody>
                  </table>
                  <ngx-skeleton-loader
                  *ngIf="lbsApprovedTableLoading"
                  count="7"
                  [theme]="{
                    width: 'calc(100% - 20px)',
                    'border-radius': '6px',
                    height: '44px',
                    margin: '10px',
                    display: 'block'
                  }"
                ></ngx-skeleton-loader>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="d-flex justify-content-between">
                <p>Total records {{ lbsApprovedUnitTable.total }}</p>
                <ngb-pagination
                  [collectionSize]="lbsApprovedUnitTable.total"
                  [(page)]="lbsApprovedUnitTable.page"
                  [maxSize]="5"
                  (pageChange)="onPageChangeLbsApproved($event)"
                  [pageSize]="lbsApprovedUnitTable.limit"
                >
                </ngb-pagination>
              </div>
            </div>
          </div>
          </ng-template>
        </li>
  </ul>
  <div [ngbNavOutlet]="companyNav"></div>
</div>
