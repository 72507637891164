<div class="row mb-3">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header bg-theme">LEAVE DETAILS FOR APPROVAL</div>
      <div class="card-body">
        <form>
          <div class="form-row">
            <div class="form-group col-md-4">
              <label class="font-weight-500">Select Year</label>
              <ng-select
                [items]="year"
                class="custom margin"
                bindLabel="name"
                placeholder="Select"
                appendTo="body"
                [multiple]="false"
                [searchable]="true"
                [clearable]="true"
                [closeOnSelect]="true"
                [(ngModel)]="selectedyear"
                [ngModelOptions]="{ standalone: true }"
              >
              </ng-select>
            </div>
            <div class="form-group col-md-4">
              <label class="font-weight-500">Status</label>
              <input
              type="text"
              class="form-control h-36"
              [value]="defaultSelectedStatus.name"
              readonly
            />
            </div>
            <div class="form-group col-md-4 align-self-end">
              <button
                type="submit"
                class="btn btn-success"
                (click)="filterData()"
              >
                Filter
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-8 pr-0">
    <div class="table-responsive border rounded">
      <ngx-skeleton-loader
        *ngIf="approveLoader"
        count="6"
        [theme]="{
          width: 'calc(100% - 20px)',
          'border-radius': '6px',
          height: '44px',
          margin: '10px',
          display: 'block'
        }"
      ></ngx-skeleton-loader>
      <table class="table table-hover m-0" *ngIf="!approveLoader">
        <thead class="bg-theme">
          <tr>
            <th scope="col">DATE SUBMITTED</th>
            <th scope="col">STAFF NAME</th>
            <th scope="col">STAFF ID</th>
            <th scope="col">LEAVE TYPE</th>
            <th scope="col">PERIOD</th>
            <th scope="col">STATUS</th>
            <th scope="col">ACTION</th>
          </tr>
        </thead>
        <tbody
          *ngFor="let data of filterDataResult; let i = index"
          [class.selected]="i === highlightIndex"
        >
          <tr>
            <td>{{ data.createdAt | date: "dd-MM-yyyy HH:MM" }}</td>
            <td>{{ data.userId.name }}</td>
            <td>{{ data.userId.staffId }}</td>
            <td>{{ data.leaveTypeId.name }}</td>
            <td>
              {{ data.startDate | date: "dd MMM yyyy" }} to
              {{ data.endDate | date: "dd MMM yyyy" }}
            </td>
            <td>
              {{
                data.status == 0
                  ? "Pending Approval"
                  : data.status == 1
                  ? "Approved"
                  : data.status == 2
                  ? "Rejected"
                  : data.status == 5
                  ? "Cancelled"
                  : data.status == 3
                  ? "-"
                  : "-"
              }}
            </td>
            <td>
              <button class="btn btn-info btn-sm" (click)="viewUser(data, i)">
                View
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="col-md-4">
    <div class="d-flex flex-column">
      <div class="card">
        <div class="card-header bg-theme text-center">
          USER DETAILS
        </div>
        <div class="card-body">
          <div class="d-flex flex-column">
            <div class="d-flex align-items-center">
              <div class="profile-img mr-3">
                <img
                  *ngIf="viewUserDet" crossorigin="anonymous"
                  src="{{
                    viewUserDet?.userId?.profilePicture
                      ? generateImageUrl(viewUserDet?.userId?.profilePicture)
                      : './assets/images/user1.png'
                  }}"
                  alt="{{
                    viewUserDet?.userId?.profilePicture
                      ? 'Profile Picture'
                      : 'No Profile Picture'
                  }}"
                  class="img-fluid rounded"
                />
              </div>
              <div class="flex-fill">
                <h5 class="card-title m-0">
                  {{ viewUserDet?.userId?.name }}
                </h5>
                <p class="card-text">
                  {{ viewUserDet?.userId?.staffId }}
                </p>
              </div>
            </div>
            <div class="mt-3">
              <ul class="list-group">
                <li
                  class="list-group-item d-flex justify-content-between align-items-center"
                >
                  <span class="font-weight-500">Phone</span>
                  <span class="ml-3 text-right">{{
                    viewUserDet?.userId?.contactNumber
                  }}</span>
                </li>
                <li
                  class="list-group-item d-flex justify-content-between align-items-center"
                >
                  <span class="font-weight-500">Email</span>
                  <span class="ml-3 text-right">{{
                    viewUserDet?.userId?.email
                  }}</span>
                </li>
                <li
                  class="list-group-item d-flex justify-content-between align-items-center"
                >
                  <span class="font-weight-500">Business Unit</span>
                  <span class="ml-3 text-right">{{
                    viewUserDet?.businessUnitId?.orgName
                  }}</span>
                </li>
                <li
                  class="list-group-item d-flex justify-content-between align-items-center"
                >
                  <span class="font-weight-500">Ops Group</span>
                  <span class="ml-3 text-right">
                    {{
                      viewUserDet?.opsGroup?.name +
                        " > " +
                        viewUserDet?.opsTeam
                    }}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="card mt-3">
        <div class="card-header bg-theme text-center">LEAVE DETAILS</div>
        <div class="card-body">
          <form [formGroup]="updateForm">
            <div class="form-group">
              <label class="font-weight-500"
                >Leave Submitted for Approval</label
              >
              <input
                type="text"
                class="form-control"
                value="{{ viewUserDet?.createdAt | date: 'dd-MM-yyyy HH:MM' }}"
              />
            </div>
            <div class="form-group">
              <label class="font-weight-500">Number of days applied for</label>
              <input
                type="text"
                class="form-control"
                value="{{ viewUserDet?.totalDay }}"
              />
            </div>
            <div class="form-group">
              <label class="font-weight-500">Leave Type</label>
              <input
                type="text"
                class="form-control"
                value="{{ viewUserDet?.leaveTypeId.name }}"
              />
            </div>
            <div class="form-group">
              <label class="font-weight-500">Leave Start Date</label>
              <input
                type="text"
                class="form-control"
                value="{{ viewUserDet?.startDate | date: 'dd MMM yyyy' }}"
              />
            </div>
            <div class="form-group">
              <label class="font-weight-500">Leave End Date</label>
              <input
                type="text"
                class="form-control"
                value="{{ viewUserDet?.endDate | date: 'dd MMM yyyy' }}"
              />
            </div>
            <div class="form-group">
              <label class="font-weight-500">Attachment : </label>
              <ng-container *ngIf="viewUserDet?.attachment">
                <a target='_blank' [href]="safeUrl(viewUserDet?.attachment)"  class="btn btn-link"
                download="getAttachmentFileName(viewUserDet?.attachment)">{{getAttachmentFileName(viewUserDet?.attachment)}}</a>
              </ng-container>
                </div>
            <div class="form-group">
              <label class="font-weight-500">Remarks</label>
              <input
                type="text"
                class="form-control"
                formControlName="forRemarks"
              />
            </div>
          </form>
        </div>
      </div>

      <div class="card mt-3" *ngIf="viewUserDet?.status == 0">
        <div class="card-header bg-theme text-center">LEAVE APPROVAL</div>
        <div class="card-body">
          <form [formGroup]="updateForm">
            <div class="form-group">
              <label class="font-weight-500">Approval For</label>
              <input
                type="text"
                class="form-control"
                formControlName="forApproval"
              />
            </div>
            <div class="form-group">
              <label class="font-weight-500">Remarks</label>
              <input
                type="text"
                class="form-control"
                formControlName="forNewRemarks"
              />
            </div>
            <div class="form-row">
              <div class="form-group col-md-3">
                <label class="font-weight-500">Deducted</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="forDeducted"
                />
              </div>
              <div
                class="align-self-end col-md-3 form-group"
                *ngIf="viewUserDet?.status == 0"
              >
                <button
                  class="bg-danger material-icons btn rounded-circle"
                  (click)="add(1)"
                >
                  remove</button
                ><button
                  class="bg-success material-icons btn ml-1 rounded-circle"
                  (click)="add(2)"
                >
                  add
                </button>
              </div>
              <div class="form-group col-md-3">
                <label class="font-weight-500">Off/Rest</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="forRest"
                />
              </div>
              <div class="form-group col-md-3">
                <label class="font-weight-500">Total</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="forTotal"
                />
              </div>
            </div>
            <div *ngIf="viewUserDet?.status == 0">
              <button
                type="submit"
                class="btn btn-success"
                (click)="approveLeave()"
              >
                Approve
              </button>
              <button
                type="submit"
                class="btn btn-danger ml-2"
                (click)="rejectLeave()"
              >
                Reject
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="card mt-3" *ngIf="viewUserDet?.status == 5">
        <div class="card-header bg-theme text-center">CANCELLED BY</div>
        <div class="card-body">
          <form [formGroup]="updateForm">
            <div class="form-group">
              <label class="font-weight-500">Date of Cancelled</label>
              <input
                type="text"
                class="form-control"
                formControlName="cancelledDate"
              />
            </div>
            <div class="form-group">
              <label class="font-weight-500">Staff Id</label>
              <input
                type="text"
                class="form-control"
                formControlName="staffId"
              />
            </div>
            <div class="form-group">
              <label class="font-weight-500">Name</label>
              <input
                type="text"
                class="form-control"
                formControlName="staffName"
              />
            </div>
          </form>
        </div>
      </div>
      <div
        class="card mt-3"
        *ngIf="viewUserDet?.status == 1 || viewUserDet?.status == 2"
      >
        <div class="card-header bg-theme text-center">APPROVAL HISTORY</div>
        <div class="card-body">
          <form [formGroup]="updateForm">
            <div class="form-group">
              <label class="font-weight-500">Date of Entry</label>
              <input
                type="text"
                class="form-control"
                formControlName="cancelledDate"
              />
            </div>
            <div class="form-group">
              <label class="font-weight-500">Approver Staff Id</label>
              <input
                type="text"
                class="form-control"
                formControlName="staffIdApproveHis"
              />
            </div>
            <div class="form-group">
              <label class="font-weight-500">Approver Name</label>
              <input
                type="text"
                class="form-control"
                formControlName="staffNameApproveHis"
              />
            </div>
            <div class="form-group">
              <label class="font-weight-500">Remarks</label>
              <input
                type="text"
                class="form-control"
                formControlName="forNewRemarks"
              />
            </div>
            <div class="form-group">
              <label class="font-weight-500">Status</label>
              <input
                type="text"
                class="form-control"
                formControlName="forStatus"
              />
            </div>
            <div class="form-row">
              <div class="form-group col-md-3">
                <label class="font-weight-500">Deducted</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="forDeducted"
                />
              </div>
              <div class="form-group col-md-3">
                <label class="font-weight-500">Off/Rest</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="forRest"
                />
              </div>
              <div class="form-group col-md-3">
                <label class="font-weight-500">Total</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="forTotal"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
