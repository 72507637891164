<div class="card mb-3">
  <h5 class="card-header bg-theme">HOME</h5>
  <div class="card-body">
    <div class="d-flex">
      <img
        class="user-image rounded" crossorigin="anonymous"
        src="{{
          profilePictureLink ? validateURLImage(profilePictureLink) : '../assets/images/user1.png'
        }}"
        onerror="this.src='./assets/images/user1.png';"
      />

      <div class="align-self-center ml-4">
        <h2 class="font-weight-bold m-0">{{ userData?.name }}</h2>
        <p class="font-weight-500 m-0 text-muted">{{ userData?.role?.name }}</p>
      </div>
    </div>
  </div>
</div>

<div class="card">
  <h5 class="card-header bg-theme">NOTIFICATION</h5>
  <div class="card-body table-responsive">
    <table class="table border m-0" *ngIf="notificationTable.data?.length">
      <thead>
        <tr>
          <th style="width: 25%">TITLE</th>
          <th style="width: 45%">SUBTITLE</th>
          <th>DATE & TIME</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let item of notificationTable.data | slice: (notificationTable.page-1) * notificationTable.limit : notificationTable.page * notificationTable.limit;">
          <td>{{ item.title }}</td>
          <td>{{ item.subTitle }}</td>
          <td>{{ item.activeFrom | date }} - {{ item.activeFrom | date:'hh:mm a' }}</td>
        </tr>
      </tbody>
    </table>
    <p class="h5 text-muted m-0" *ngIf="!notificationTable?.data?.length">
      No Notification found
    </p>
  </div>
  <div class="card-footer" *ngIf="notificationTable.total">
    <div class="d-flex justify-content-between">
      <p> Showing 1 to {{ notificationTable.total }} of {{ notificationTable.total }} entries</p>
      <ngb-pagination [collectionSize]="notificationTable.total" [(page)]="notificationTable.page"
      (pageChange)="onPageChangeballotData($event)" [pageSize]="notificationTable.limit">
    </ngb-pagination>
    </div>
  </div>
</div>
