import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  detectAllFaces,
  loadFaceLandmarkModel,
  loadFaceRecognitionModel,
  loadSsdMobilenetv1Model,
} from 'face-api.js';
import { ToastrService } from 'ngx-toastr';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { UsersService } from 'src/app/users/service/user-service/users.service';

@Component({
  selector: 'app-facial-edit',
  templateUrl: './facial-edit.component.html',
  styleUrls: ['./facial-edit.component.scss'],
})
export class FacialEditComponent implements OnInit {
  @Input() userFacial;
  @Output() changeTab = new EventEmitter<any>();
  showWebcam = false;
  actionbtn = false;
  private trigger: Subject<void> = new Subject<void>();
  public errors: WebcamInitError[] = [];
  userFacialImgUrl: string;
  @ViewChild('snapCam') snapCam: ElementRef;
  capturedImg: string;
  addPhotoIdLoader:boolean=false;
  constructor(
    private userService: UsersService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.userFacialImgUrl = this.userFacial.facialInfo;
    this.loadFaceModels();
  }

  navigateToOverview(){
    this.changeTab.emit('1');
  }

  toggleCamera() {
    this.capturedImg = null;
    this.showWebcam = !this.showWebcam;
    this.actionbtn = this.showWebcam;
  }

  public triggerSnapshot(): void {
    this.showWebcam = false;
    this.trigger.next();
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.capturedImg = webcamImage.imageAsDataUrl;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  async loadFaceModels() {
    const MODEL_URL = '/assets/models';

    await loadSsdMobilenetv1Model(MODEL_URL);
    await loadFaceLandmarkModel(MODEL_URL);
    await loadFaceRecognitionModel(MODEL_URL);
  }

  async addPhotoId() {
    const photoId = {
      userId: this.userFacial?.userId,
      facialInfo: this.capturedImg,
    };

    this.addPhotoIdLoader=true;
    let fullFaceDescriptions = await detectAllFaces(this.snapCam?.nativeElement)
      .withFaceLandmarks()
      .withFaceDescriptors();


    if (fullFaceDescriptions && fullFaceDescriptions.length > 0) {
      photoId['descriptor'] = [...fullFaceDescriptions[0].descriptor];

      this.userService.createFacialId(photoId).subscribe((res) => {
        if (res.message) {
          this.addPhotoIdLoader=false;
          this.toastrService.success('Facial Id updated successfully', 'Done', {
            timeOut: 3000,
          });
          // this.activeId = 1;
          this.changeTab.emit('1');
        } else {
          this.addPhotoIdLoader=false;
          this.toastrService.error('Something went wrong', 'Failed', {
            timeOut: 3000,
          });
        }
      });
    } else {
      this.addPhotoIdLoader=false;
      this.toastrService.error('Please capture proper image', 'Failed');
    }

  }
}
