import {
  Component,
  OnInit,
  Input,
  forwardRef,
  ViewChild,
  AfterViewInit,
  Injector,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  NgbTimeStruct,
  NgbDateStruct,
  NgbPopoverConfig,
  NgbPopover,
  NgbDatepicker,
} from '@ng-bootstrap/ng-bootstrap';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  NgControl,
} from '@angular/forms';
import { DatePipe } from '@angular/common';
import { DateTimeModel } from '../date-time.model';
import { noop } from 'rxjs';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faCalendar, faClock } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.html.component.html',
  styleUrls: ['./date-time-picker.html.component.scss'],
  providers: [
    DatePipe,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimePickerComponent),
      multi: true,
    },
  ],
})
export class DateTimePickerComponent
  implements ControlValueAccessor, OnInit
{
  @Input()
  dateString: string;

  @Input()
  inputDatetimeFormat = 'd/M/yyyy H:mm';
  @Input()
  hourStep = 1;
  @Input()
  minuteStep = 15;
  @Input()
  secondStep = 30;
  @Input()
  seconds = true;

  time = { hour: 0, minute: 0 };

  @Input() disabled = false;
  timePickerContent;
  public showTimePickerToggle = false;

  public datetime: DateTimeModel = new DateTimeModel();
  public firstTimeAssign = true;
  @Input() minDate: NgbDateStruct;
  @Input() maxDate: NgbDateStruct;
  // @ViewChild(NgbDatepicker, { static: true })
  // private dp: NgbDatepicker;
  @Output() sendDate = new EventEmitter<object>();
  @ViewChild(NgbPopover, { static: true })
  public popover: NgbPopover;

  public onTouched: () => void = noop;
  public onChange: (_: any) => void = noop;

  public ngControl: NgControl;

  constructor(
    private config: NgbPopoverConfig,
    private inj: Injector,
    library: FaIconLibrary
  ) {
    config.autoClose = 'outside';
    config.placement = 'auto';
    library.addIcons(faCalendar, faClock);
    const current = new Date();
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate(),
    };
    // this.maxDate = { year: current.getFullYear(), month: current.getMonth() + 3, day: current.getDate() }
  }

  ngOnInit(): void {
    this.ngControl = this.inj.get(NgControl);
  }

  writeValue(newModel: string) {
    if (newModel) {
      this.datetime = Object.assign(
        this.datetime,
        DateTimeModel.fromLocalString(newModel)
      );
      this.dateString = newModel;
      this.setDateStringModel();
    } else {
      this.datetime = new DateTimeModel();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  toggleDateTimeState($event) {
    this.showTimePickerToggle = !this.showTimePickerToggle;
    $event.stopPropagation();
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onInputChange($event: any) {
    const value = $event.target.value;
    const dt = DateTimeModel.fromLocalString(value);

    if (dt) {
      this.datetime = dt;
      this.setDateStringModel();
    } else if (value.trim() === '') {
      this.datetime = new DateTimeModel();
      this.dateString = '';
      this.onChange(this.dateString);
    } else {
      this.onChange(value);
    }
  }

  onDateChange($event: string | NgbDateStruct, dp: NgbDatepicker) {
    const date = new DateTimeModel($event);

    if (!date) {
      this.dateString = this.dateString;
      return;
    }

    if (!this.datetime) {
      this.datetime = date;
    }

    this.datetime.year = date.year;
    this.datetime.month = date.month;
    this.datetime.day = date.day;
    const data = {
      year: this.datetime.year,
      month: this.datetime.month,
      day: this.datetime.day,
    };
    this.sendDate.emit(data);
    const adjustedDate = new Date(this.datetime.toString());
    if (this.datetime.timeZoneOffset !== adjustedDate.getTimezoneOffset()) {
      this.datetime.timeZoneOffset = adjustedDate.getTimezoneOffset();
    }

    this.setDateStringModel();
  }

  onTimeChange(event: NgbTimeStruct) {
    this.datetime.hour = event.hour;
    this.datetime.minute = event.minute;
    this.datetime.second = event.second;

    this.setDateStringModel();
  }

  setDateStringModel() {
    this.dateString = this.datetime.toString();
    this.onChange(this.dateString);
    if (this.dateString !== null) {
      this.firstTimeAssign = false;
    }
    // if (!this.firstTimeAssign) {

    // } else {
    //   // Skip very first assignment to null done by Angular

    // }
  }

  inputBlur($event) {
    this.onTouched();
  }
}
