<div class="card">
  <div class="card-header flexi-header p-4">
    <h3>CHALLENGE</h3>
  </div>
  <div class="card-body" id="parita">
    <!-- <img
    src="'https://admin.netroc3.tk/uploads/challenge/75bafefd-ede8-4208-8749-17249168169a.png'"
    alt="Image Preview" srcset=""
  /> -->
    <mat-horizontal-stepper [linear]="isLinear" #stepper>
      <mat-step [stepControl]="discriptioGroup">
        <!-- <img width="90" height="90"
        src="https://admin.netroc3.tk/uploads/challenge/75bafefd-ede8-4208-8749-17249168169a.png"
        alt="Image Preview"
      /> -->
        <form [formGroup]="discriptioGroup">
          <ng-template matStepLabel>DESCRIPTION </ng-template>
          <div class="card my-3 w-100">
            <div class="card-header flexi-header">
              <h3>DESCRIPTION</h3>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 mt-1">
                  <div class="form-group">
                    <label class="pb-2 font-weight-bold" for="exampleInputEmail1">Title<span
                        class="field-mandatory">*</span></label>

                    <input type="text" maxlength="100" class="form-control" placeholder="Title" formControlName="title"
                      required />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mt-1">
                  <div class="form-group">
                    <label class="pb-2 font-weight-bold" for="exampleInputEmail1">Description<span
                      class="field-mandatory">*</span></label>

                    <textarea maxlength="1000" name="" class="form-control" id="" cols="5" rows="5"
                      formControlName="description"></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">

                  <div class="custom-file mt-0 pt-4 pb-4">
                    <input
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      #myInputVariable
                      class="custom-file-input"
                      (change)="iconUpload($event, 'teaser')"
                      id="c"
                    />
                    <label class="custom-file-label" for="i">
                      Choose file to upload <span class="field-mandatory">*</span>
                    </label>
                  </div>
                </div>
                <div class="col-md-3" *ngIf="discriptioGroup.get('icon').value">
                  <div class="image-with-delete-icon width-180">
                    <i class="fa fa-close" title="Delete" (click)="onDeleteIcon()"></i>
                    <img crossorigin="anonymous" [src]="validateURLImage(discriptioGroup.get('icon').value)" alt="Image Preview" />
                  </div>
                </div>
              </div>
              <div class="form-check m-3">
                <input class="form-check-input" type="checkbox" value="" formControlName="leaderBoard" [attr.disabled]="progressTypesValue == 2 ? true : null"
                  id="flexCheckChecked" />
                <label class="form-check-label ml-2 checkbox-label" for="flexCheckChecked">
                  Leader board required
                </label>
              </div>
              <div>
                <label class="pb-2 font-weight-bold" for="challengeParticipant">Challenge participants selection</label>
                <div class="criteria-radio col-6">
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="individual" [value]="false" (click)="selectIsTeamRequired(false)"
                      formControlName="isTeam" [attr.disabled]="challangesId ? true : null " />
                    <label for="individual">Individual challenge</label><br />
                  </div>
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="team" [value]="true" formControlName="isTeam" (click)="selectIsTeamRequired(true)" [attr.disabled]="challangesId ? true : null " />
                    <label for="team">Team challenge</label><br />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="card my-3 w-100">
                    <div class="card-header flexi-header">
                      <h3>PUBLISH PERIOD</h3>
                    </div>
                    <div class="card-body">
                      <div class="row mt-2">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="activeEndDate" class="pb-2 font-weight-bold">Start Date and Time<span
                                class="field-mandatory">*</span>
                            </label>
                            <app-date-time-picker [disabled]="challangesId ? true : false"
                              formControlName="publishStart">
                            </app-date-time-picker>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="activeEndDate" class="pb-2 font-weight-bold">End Date and Time<span
                                class="field-mandatory">*</span>
                            </label>
                            <app-date-time-picker formControlName="publishEnd"></app-date-time-picker>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card my-3 w-100">
                    <div class="card-header flexi-header">
                      <h3>CHALLENGE PERIOD</h3>
                    </div>
                    <div class="card-body">
                      <div class="row mt-2">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="activeEndDate" class="pb-2 font-weight-bold">Start Date and Time<span
                                class="field-mandatory">*</span>
                            </label>
                            <app-date-time-picker [disabled]="challangesId ? true : false"
                              formControlName="challengeStart"></app-date-time-picker>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="activeEndDate" class="pb-2 font-weight-bold">End Date and Time<span
                                class="field-mandatory">*</span>
                            </label>
                            <app-date-time-picker formControlName="challengeEnd"></app-date-time-picker>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button class="btn btn-success mt-2" (click)="validateDescription()">
                Next
              </button>
            </div>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="teamsGroup"  [attr.disabled]="true" *ngIf="discriptioGroup.get('isTeam').value === true">
        <form [formGroup]="teamsGroup">
          <ng-template matStepLabel>TEAMS</ng-template>
          <div class="card my-3 w-100">
            <div class="card-header flexi-header">
              <h3>TEAMS</h3>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-4 mt-1">
                  <div class="form-group">
                    <label class="pb-2 font-weight-bold" for="exampleInputEmail1">Team Name<span
                        class="field-mandatory">*</span></label>
                    <input type="text" maxlength="100" class="form-control" placeholder="Team Name"
                      formControlName="title" required />
                  </div>
                </div>
                <div class="col-md-3">
                  <label class="pb-2 font-weight-bold" for="exampleInputEmail1">Team Logo<span
                    class="field-mandatory">*</span></label>
                  <div class="custom-file mt-0 pt-4 pb-4">
                    <input accept="image/png, image/gif, image/jpeg" type="file" #myInputVariable
                      class="custom-file-input" (change)="iconUpload($event, 'teamLogo')" id="c" />
                    <label class="custom-file-label" for="i">
                      Choose file to upload <span class="field-mandatory">*</span>
                    </label>
                  </div>
                </div>
                <div class="col-md-3" *ngIf="teamsGroup.get('icon').value">
                  <div class="image-with-delete-icon logo-image-with-delete-icon width-50">
                    <i class="fa fa-close" title="Delete" (click)="onDeleteTeamIcon()"></i>
                    <img crossorigin="anonymous" style="height: 80px; width: 80px; border-radius: '50%';" [src]="validateURLImage(teamsGroup.get('icon').value)" alt="Image Preview" />
                  </div>
                </div>
              </div>
              <assign-users [userDetails]="teamUserDetails" [isAdminView]="false"
                (onSubmitAssignUsers)="updateTeamUserDetails($event)" [moduleName]="'challengesteam'"></assign-users>
              <div class="justify-content-center text-center align-items-center">
                <button class="btn btn-primary mt-4 mb-4" (click)="addTeamData()">
                 {{ isEditTeam ? 'Update Team'  : 'Add Team' }}
                </button>
              </div>
              <div class="card my-3 w-100" *ngIf="teamDetails.length !== 0">
                <div>
                  <div class="card-header flexi-header">
                    <h3>TEAM DETAILS </h3>
                  </div>
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th class="p-3">
                              TEAM NAME
                            </th>
                            <th class="p-3">
                              TEAM LOGO
                            </th>
                            <th class="p-3">
                              SELECTED USERS
                            </th>
                            <th class="p-3" scope="col">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of teamDetails; let index = index">
                            <td>
                              <div class="p-3">{{item.title}}</div>
                            </td>
                            <td>
                              <div class="p-3"><img crossorigin="anonymous" [src]="validateURLImage(item.icon)" style="height: 50px;
                                    width: 50px;
                                    border-radius: 50%;" /></div>
                            </td>
                            <td>
                              <div class="p-3"> {{item?.teamUserDetails.length || "-"}} items Added</div>
                            </td>
                            <td>
                              <div class="p-3">
                                <button class="btn btn-add p-2 m-1"
                                  (click)="editTeamDetail(item, index)">
                                  <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                </button>
                                <button class="btn btn-clear p-2 m-1"
                                  (click)="deleteTeamEntry(index)" [disabled]="isEditTeam">
                                  <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <button class="btn btn-warning mt-4" (click)="stepper.previous()">
              Prev
            </button>
            <button class="btn btn-danger ml-1 mt-4" (click)="validateTeamStepper()">
              Next
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="rulesGroup">
        <form [formGroup]="rulesGroup">
          <ng-template matStepLabel>RULES</ng-template>
          <div class="card my-3 w-100" *ngIf="!challangesId">
            <div class="card-header flexi-header p-4">
              <h3>RULES</h3>
            </div>
            <div class="card-body">
              <div>
                <p class="criteria-source">Criteria Source<span class="field-mandatory">*</span></p>
              </div>
              <div class="criteria-source-radio col-10">
                <div>
                  <input type="radio" formControlName="criteriaType" class="mt-1 ml-2" id="1" name="criteriaType"
                    [value]="1" (click)="onSelectChalange('Channel',1)" [attr.disabled]="discriptioGroup.get('isTeam').value == true ? true : null " />
                  <label for="channel">Channel</label>
                </div>
                <br />
                <div>
                  <input type="radio" formControlName="criteriaType" class="mt-1 ml-2" id="2" name="criteriaType"
                    [value]="2" (click)="onSelectChalange('Boards',2)"
                    [attr.disabled]="discriptioGroup.get('isTeam').value == true ? true : null "  />
                  <label for="boards">Boards</label>
                </div>
                <br />

                <div>
                  <input type="radio" formControlName="criteriaType" class="mt-1 ml-2" id="3" name="criteriaType"
                    [value]="3" (click)="onSelectChalange('System',3)" [attr.disabled]="discriptioGroup.get('isTeam').value == true ? true : null "/>
                  <label for="system">System</label>
                </div>
                <br />

                <div>
                  <input type="radio" formControlName="criteriaType" class="mt-1 ml-2" id="4" name="criteriaType"
                    [value]="4" (click)="onSelectChalange('Direct Rewards',4)" [attr.disabled]="discriptioGroup.get('isTeam').value == true ? true : null " />
                  <label for="direct-rewards">Direct Rewards</label>
                </div>
                <br />

                <div>
                  <input type="radio" formControlName="criteriaType" class="mt-1 ml-2" id="5" name="criteriaType"
                    [value]="5" (click)="onSelectChalange('CustomForm',5)" />
                  <label for="custom-form">Custom Form</label>
                </div>
                <br />

                <div>
                  <input type="radio" formControlName="criteriaType" class="mt-1 ml-2" id="6" name="criteriaType"
                    [value]="6" (click)="onSelectChalange('shiftWork',6)" [attr.disabled]="discriptioGroup.get('isTeam').value == true ? true : null " />
                  <label for="custom-form">Shift Work</label>
                </div>
                <br />
              </div>
              <hr />
              <div *ngIf="rulesGroup.get('criteriaType').value === 1">
                <div class="channels-section col-12 flex-direction mt-3">
                  <div class="col-md-4">
                    <ng-select (change)="addChannel($event)" class="custom" bindLabel="name"
                      placeholder="Select Channels" appendTo="body" [multiple]="false" [clearable]="true"
                      [(ngModel)]="channelSelect" [ngModelOptions]="{ standalone: true }">
                      <ng-option [value]="item" *ngFor="let item of channelData">
                        {{ item.name }}
                      </ng-option>
                    </ng-select>
                  </div>
                  <div class="col-md-4">
                    <ul *ngIf="rulesGroup.get('selectedChannel.name').value">
                      <li>
                        <span class="text-items list-ui selected-list">{{ rulesGroup.get("selectedChannel.name").value
                          }}
                          <img crossorigin="anonymous" (click)="deleteChannel()" class="ml-3"
                            *ngIf="rulesGroup.get('selectedChannel.name').value" src="./assets/images/cancel.png"
                            width="20px" height="20px" /></span>
                      </li>
                    </ul>
                  </div>
                </div>

                <p class="criteria-source mt-3">Criteria Selection<span class="field-mandatory">*</span></p>

                <div class="channels-section col-12 flex-direction">
                  <div class="col-md-4">
                    <ng-select (change)="addcriteriaSourceType($event, 'Channel')" class="custom" bindLabel="name"
                      placeholder="Select Criteria" [(ngModel)]="criteriaSelect" [ngModelOptions]="{ standalone: true }"
                      appendTo="body" [multiple]="false" [clearable]="true">
                      <ng-option [value]="item" *ngFor="let item of channelCriteria">
                        {{ item.key }}
                      </ng-option>
                    </ng-select>
                  </div>
                  <div class="col-md-4">
                    <ul *ngIf="creatiaView">
                      <li>
                        <span class="text-items list-ui selected-list">{{ creatiaView }}
                          <img crossorigin="anonymous" (click)="deleteChannelCreteria()" class="ml-3"
                            *ngIf="creatiaView" src="./assets/images/cancel.png" width="20px" height="20px" /></span>
                      </li>
                    </ul>
                  </div>
                </div>

                <p class="criteria-source mt-3">Criteria Counter</p>

                <div class="criteria-radio col-6">
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="single" [value]="1" formControlName="criteriaCountType"
                      (click)="resetCriteriaCounter()" />
                    <label for="channel">Single</label><br />

                    <div class="form-group ml-1">
                      <label class="criteria-subtext" for="criteria-count">Criteria Count</label>
                      <input type="number" min="1" [value]="1" [attr.disabled]="
                          rulesGroup.get('criteriaCountType').value === 1
                            ? true
                            : null
                        " class="form-control" placeholder="Criteria Count" formControlName="criteriaCount"
                        pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length == 10) return false;" />
                    </div>
                  </div>
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="bundled" [value]="2"
                      formControlName="criteriaCountType" />
                    <label for="boards">Bundled</label><br />

                    <div class="form-group ml-1">
                      <label class="criteria-subtext" for="reward-points">Reward Points<span
                          class="field-mandatory">*</span></label>
                      <input type="number" pattern="/^-?\d+\.?\d*$/"
                        onKeyPress="if(this.value.length==10) return false;" min="0" class="form-control"
                        placeholder="Reward Points" formControlName="rewardPoints" />
                    </div>
                  </div>
                </div>

                <p class="criteria-source mt-3">Non-reward Point System</p>
                <br />
                <p class="criteria-subtext mt-0">Do we need point system</p>
                <div class="criteria-radio col-3">
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="1" [value]="true"
                      formControlName="nonRewardPointSystemEnabled" />
                    <label for="yes">Yes</label><br />
                  </div>
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="2" [value]="false"
                      formControlName="nonRewardPointSystemEnabled" />
                    <label for="no">No</label><br />
                  </div>
                </div>

                <div class="row" *ngIf="rulesGroup.get('nonRewardPointSystemEnabled').value">
                  <div class="col-md-4">
                    <ng-select (change)="addPointSystem($event)" class="custom" bindLabel="name" placeholder=""
                      appendTo="body" [multiple]="false" [clearable]="true">
                      <ng-option [value]="item" *ngFor="let item of poinstSystemData">
                        {{ item.title }}
                      </ng-option>
                    </ng-select>
                  </div>
                  <div class="col-md-4">
                    <ul *ngIf="pointSytemView">
                      <li>
                        <span class="text-items list-ui mt-3">{{ pointSytemView }}
                          <img crossorigin="anonymous" class="ml-3" *ngIf="pointSytemView"
                            src="./assets/images/cancel.png" width="20px" height="20px" /></span>
                      </li>
                    </ul>
                  </div>
                </div>
                <button *ngIf="!rulesGroup.get('nonRewardPointSystemEnabled').value" class="reward-points-button">
                  Reward Points</button><br />

                <p class="criteria-source mt-0">Reward Counter</p>
                <p class="criteria-subtext mt-0">
                  Will rewards count stop after 1st achievement
                </p>
                <div class="criteria-radio col-3">
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="1" [value]="true"
                      formControlName="stopAfterAchievement" />
                    <label for="yes">Once-off</label><br />
                  </div>
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="2" [value]="false"
                      formControlName="stopAfterAchievement" />
                    <label for="no">Recurring</label><br />
                  </div>
                </div>

                <p class="criteria-subtext mt-0">
                  Do we need to limit the maximum rewards per staff
                </p>
                <div class="criteria-radio col-3">
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="1" [value]="true"
                      formControlName="setLimitToMaxRewards" />
                    <label for="yes">Yes</label><br />
                  </div>
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="2" [value]="false"
                      formControlName="setLimitToMaxRewards" />
                    <label for="no">No</label><br />
                  </div>
                </div>
                <div class="row" *ngIf="rulesGroup.get('setLimitToMaxRewards').value">
                  <div class="col-md-4">
                    <input type="number" pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==10) return false;"
                      min="0" class="form-control" placeholder="setLimitToMaxRewards" formControlName="maximumRewards"
                      required />
                  </div>
                </div>

                <p class="criteria-subtext mt-3">Badge Tiering required</p>
                <div class="criteria-radio col-3">
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="1" [value]="true" formControlName="badgeTiering" />
                    <label for="yes">Yes</label><br />
                  </div>
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="2" [value]="false" formControlName="badgeTiering" />
                    <label for="no">No</label><br />
                  </div>
                </div>
                <div class="row" *ngIf="rulesGroup.get('badgeTiering').value">
                  <div class="col-md-6">
                    <form [formGroup]="createRanks">
                      <table class="table borderless">
                        <tbody>
                          <tr>
                            <td>
                              <input type="text" class="form-control" placeholder="Badge" formControlName="name"
                                required pattern="/^-?\d+\.?\d*$/"
                                onKeyPress="if(this.value.length==50) return false;" />
                            </td>
                            <div class="ml-3">
                              <input type="number" min="0" class="form-control" placeholder="From"
                                formControlName="startRange" required pattern="/^-?\d+\.?\d*$/"
                                onKeyPress="if(this.value.length==10) return false;" />
                            </div>
                            <td class="columns">
                              <input type="number" min="0" class="form-control columns" placeholder="To"
                                formControlName="endRange" required pattern="/^-?\d+\.?\d*$/"
                                onKeyPress="if(this.value.length==10) return false;" />
                            </td>
                            <td>
                              <div style="
                                  width: 130px;
                                  height: 30px;
                                  margin-left: 10px;
                                "
                                class="custom-file"
                                *ngIf="!createRanks.get('icon').value"
                              >
                                <input
                                  type="file"
                                  style="
                                    position: absolute;
                                    z-index: 9;
                                    visibility: visible;
                                    left: 0;
                                    top: 0;
                                    width: 80%;
                                    opacity: 0;
                                  " (change)="badgeiconUpload($event)" required id="b" />
                                <label class="custom-file-label columns" for="b">
                                  Upload Image<span class="field-mandatory"> *</span>
                                </label>
                              </div>
                              <div *ngIf="createRanks.get('icon').value">
                                <div class="
                                    image-with-delete-icon
                                    ml-5 ml-0
                                    badge-image
                                  " style="width: 100px">
                                  <i class="fa fa-close" title="Delete" for="4" (click)="onDeleteBadgeImage()"></i>
                                  <img crossorigin="anonymous" [src]="validateURLImage(createRanks.get('icon').value)" id="4"
                                    alt="Image Preview" />
                                </div>
                              </div>
                            </td>
                            <div class="add-button columns">
                              <button class="btn btn-submit btn-lg ml-1 pl-3 pr-3" (click)="addRank()">
                                +
                              </button>
                            </div>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                  </div>
                </div>
                <div class="row">
                  <div class="col-8" *ngIf="badgeTieringArray.length != 0">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">TIER TITLE</th>
                            <th scope="col">FROM</th>

                            <th scope="col">TO</th>
                            <th scope="col">BADGE ICON</th>

                            <th scope="col">ACTION</th>

                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="
                              let field of badgeTieringArray;
                              let index = index
                            ">
                            <td>
                              <div class="mt-2">{{ field.name }}</div>
                            </td>

                            <td>
                              <div class="mt-2">{{ field.startRange }}</div>
                            </td>
                            <td>
                              <div class="mt-2">{{ field.endRange }}</div>
                            </td>

                            <td>
                              <img [src]="validateURLImage(field.icon)" width="50px" height="50px" class="mt-2" alt="icon Preview"
                                crossorigin="anonymous" />
                            </td>

                            <td>
                              <img class="ml-3 mt-2" (click)="deleteBadge(index)" src="./assets/images/cancel.png"
                                width="20px" height="20px" crossorigin="anonymous" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="rulesGroup.get('criteriaType').value === 2">
                <div class="channels-section col-12 flex-direction mt-3">
                  <div class="col-md-4">
                    <ng-select (change)="addBoard($event)" class="custom" bindLabel="name" [virtualScroll]="false"
                      placeholder="Select Board" appendTo="body" [clearable]="true" [(ngModel)]="channelSelect"
                      [ngModelOptions]="{ standalone: true }">
                      <ng-option [value]="item" *ngFor="let item of boardData">
                        {{ item.wallName }}
                      </ng-option>
                    </ng-select>
                  </div>
                  <div class="col-md-4">
                    <ul *ngIf="rulesGroup.get('selectedWall.name').value">
                      <li>
                        <span class="text-items list-ui selected-list">{{ rulesGroup.get("selectedWall.name").value }}
                          <img crossorigin="anonymous" (click)="deleteBoards()" class="ml-3"
                            *ngIf="rulesGroup.get('selectedWall.name').value" src="./assets/images/cancel.png"
                            width="20px" height="20px" /></span>
                      </li>
                    </ul>
                  </div>
                </div>

                <p class="criteria-source mt-3">Criteria Selection</p>

                <div class="channels-section col-12 flex-direction">
                  <div class="col-md-4">
                    <ng-select (change)="addcriteriaSourceType($event, 'Boards')" class="custom" bindLabel="name"
                      placeholder="Select Criteria" appendTo="body" [multiple]="false" [clearable]="true"
                      [(ngModel)]="criteriaSelect" [ngModelOptions]="{ standalone: true }">
                      <ng-option [value]="item" *ngFor="let item of boardCriteria">
                        {{ item.key }}
                      </ng-option>
                    </ng-select>
                  </div>
                  <div class="col-md-4">
                    <ul *ngIf="boardcreatiaView">
                      <li>
                        <span class="text-items list-ui selected-list">{{ boardcreatiaView }}
                          <img (click)="deleteBoardsCreteria()" class="ml-3" *ngIf="boardcreatiaView"
                            src="./assets/images/cancel.png" width="20px" height="20px"
                            crossorigin="anonymous" /></span>
                      </li>
                    </ul>
                  </div>
                </div>

                <p class="criteria-source mt-3">Criteria Counter</p>

                <div class="criteria-radio col-6">
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="single" [value]="1" formControlName="criteriaCountType"
                      (click)="resetCriteriaCounter()" />
                    <label for="channel">Single</label><br />

                    <div class="form-group ml-1">
                      <label class="criteria-subtext" for="criteria-count">Criteria Count</label>
                      <input type="number" min="1" [value]="1" [attr.disabled]="
                          rulesGroup.get('criteriaCountType').value === 1
                            ? true
                            : null
                        " class="form-control" placeholder="Criteria Count" formControlName="criteriaCount"
                        pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length == 10) return false;" />
                    </div>
                  </div>
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="bundled" [value]="2"
                      formControlName="criteriaCountType" />
                    <label for="boards">Bundled</label><br />

                    <div class="form-group ml-1">
                      <label class="criteria-subtext" for="reward-points">Reward Points<span
                          class="field-mandatory">*</span></label>
                      <input type="number" min="0" class="form-control" placeholder="Reward Points"
                        onKeyPress="if(this.value.length==10) return false;" formControlName="rewardPoints" />
                    </div>
                  </div>
                </div>

                <p class="criteria-source mt-3">Non-reward Point System</p>
                <br />
                <p class="criteria-subtext mt-0">Do we need point system</p>
                <div class="criteria-radio col-3">
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="1" [value]="true"
                      formControlName="nonRewardPointSystemEnabled" />
                    <label for="yes">Yes</label><br />
                  </div>
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="2" [value]="false"
                      formControlName="nonRewardPointSystemEnabled" />
                    <label for="no">No</label><br />
                  </div>
                </div>

                <div class="row" *ngIf="rulesGroup.get('nonRewardPointSystemEnabled').value">
                  <div class="col-md-4">
                    <ng-select (change)="addPointSystem($event)" class="custom" bindLabel="name" placeholder=""
                      appendTo="body" [multiple]="false" [clearable]="true">
                      <ng-option [value]="item" *ngFor="let item of poinstSystemData">
                        {{ item.title }}
                      </ng-option>
                    </ng-select>
                  </div>
                  <div class="col-md-4">
                    <ul *ngIf="pointSytemView">
                      <li>
                        <span class="text-items list-ui mt-3">{{ pointSytemView }}
                          <img class="ml-3" *ngIf="pointSytemView" src="./assets/images/cancel.png" width="20px"
                            height="20px" crossorigin="anonymous" /></span>
                      </li>
                    </ul>
                  </div>
                </div>
                <button *ngIf="!rulesGroup.get('nonRewardPointSystemEnabled').value" class="reward-points-button">
                  Reward Points</button><br />

                <p class="criteria-source mt-0">Reward Counter</p>
                <p class="criteria-subtext mt-0">
                  Will rewards count stop after 1st achievement
                </p>
                <div class="criteria-radio col-3">
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="1" [value]="true"
                      formControlName="stopAfterAchievement" />
                    <label for="yes">Once-off</label><br />
                  </div>
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="2" [value]="false"
                      formControlName="stopAfterAchievement" />
                    <label for="no">Recurring</label><br />
                  </div>
                </div>

                <p class="criteria-subtext mt-0">
                  Do we need to limit the maximum rewards per staff
                </p>
                <div class="criteria-radio col-3">
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="1" [value]="true"
                      formControlName="setLimitToMaxRewards" />
                    <label for="yes">Yes</label><br />
                  </div>
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="2" [value]="false"
                      formControlName="setLimitToMaxRewards" />
                    <label for="no">No</label><br />
                  </div>
                </div>
                <div class="row" *ngIf="rulesGroup.get('setLimitToMaxRewards').value">
                  <div class="col-md-4">
                    <input type="number" pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==10) return false;"
                      min="0" class="form-control" placeholder="setLimitToMaxRewards" formControlName="maximumRewards"
                      required />
                  </div>
                </div>

                <p class="criteria-subtext mt-0">Badge Tiering required</p>
                <div class="criteria-radio col-3">
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="1" [value]="true" formControlName="badgeTiering" />
                    <label for="yes">Yes</label><br />
                  </div>
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="2" [value]="false" formControlName="badgeTiering" />
                    <label for="no">No</label><br />
                  </div>
                </div>
                <div class="row" *ngIf="rulesGroup.get('badgeTiering').value">
                  <div class="col-md-6">
                    <form [formGroup]="createRanks">
                      <table class="table borderless">
                        <tbody>
                          <tr>
                            <td>
                              <input type="text" class="form-control" placeholder="Badge" formControlName="name"
                                required pattern="/^-?\d+\.?\d*$/"
                                onKeyPress="if(this.value.length==50) return false;" />
                            </td>
                            <div class="ml-3">
                              <input type="number" min="0" class="form-control" placeholder="From"
                                formControlName="startRange" required pattern="/^-?\d+\.?\d*$/"
                                onKeyPress="if(this.value.length==10) return false;" />
                            </div>
                            <td class="columns">
                              <input type="number" min="0" class="form-control columns" placeholder="To"
                                formControlName="endRange" required pattern="/^-?\d+\.?\d*$/"
                                onKeyPress="if(this.value.length==10) return false;" />
                            </td>
                            <td class="columns">
                              <div class="custom-file" style="
                                  width: 130px;
                                  height: 30px;
                                  margin-left: 10px;
                                " *ngIf="!createRanks.get('icon').value">
                                <input type="file" style="
                                    position: absolute;
                                    z-index: 9;
                                    visibility: visible;
                                    left: 0;
                                    top: 0;
                                    width: 80%;
                                    opacity: 0;
                                  " (change)="badgeiconUpload($event)" required id="b" />
                                <label class="custom-file-label" for="b">
                                  Upload Image
                                </label>
                              </div>
                              <div *ngIf="createRanks.get('icon').value">
                                <div class="
                                    image-with-delete-icon
                                    ml-5 ml-0
                                    badge-image
                                  " style="width: 100px">
                                  <i class="fa fa-close" title="Delete" for="4" (click)="onDeleteBadgeImage()"></i>
                                  <img [src]="validateURLImage(createRanks.get('icon').value)" id="4" alt="Image Preview"
                                    crossorigin="anonymous" />
                                </div>
                              </div>
                            </td>
                            <div class="add-button columns">
                              <button class="btn btn-submit btn-lg ml-1 pl-3 pr-3" (click)="addRank()">
                                +
                              </button>
                            </div>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                  </div>
                </div>
                <div class="row">
                  <div class="col-8" *ngIf="badgeTieringArray.length != 0">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">TIER TITLE</th>
                            <th scope="col">FROM</th>

                            <th scope="col">TO</th>
                            <th scope="col">BADGE ICON</th>

                            <th scope="col">ACTION</th>

                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="
                              let field of badgeTieringArray;
                              let index = index
                            ">
                            <td>
                              <div class="mt-2">{{ field.name }}</div>
                            </td>

                            <td>
                              <div class="mt-2">{{ field.startRange }}</div>
                            </td>
                            <td>
                              <div class="mt-2">{{ field.endRange }}</div>
                            </td>

                            <td>
                              <img [src]="validateURLImage(field.icon)" width="50px" height="50px" class="mt-2" alt="icon Preview"
                                crossorigin="anonymous" />
                            </td>

                            <td>
                              <img class="ml-3 mt-2" (click)="deleteBadge(index)" src="./assets/images/cancel.png"
                                width="20px" height="20px" crossorigin="anonymous" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="rulesGroup.get('criteriaType').value === 3">
                <div class="ml-3">
                  <p class="criteria-source">Criteria Selection</p>
                </div>
                <div class="channels-section col-12 flex-direction mt-3">
                  <div class="col-md-4 ml-1">
                    <ng-select (change)="addcriteriaSourceType($event, 'System')" class="custom" bindLabel="name"
                      placeholder="Select Criteria" appendTo="body" [multiple]="false" [clearable]="true"
                      [(ngModel)]="criteriaSelect" [ngModelOptions]="{ standalone: true }">
                      <ng-option [value]="item" *ngFor="let item of systemCriteria">
                        {{ item.key }}
                      </ng-option>
                    </ng-select>
                  </div>
                  <div class="col-md-4">
                    <ul *ngIf="systemCretiaView">
                      <li>
                        <span class="text-items list-ui selected-list">{{ systemCretiaView }}
                          <img (click)="deleteSystemCreteria()" class="ml-3" *ngIf="systemCretiaView"
                            src="./assets/images/cancel.png" width="20px" height="20px"
                            crossorigin="anonymous" /></span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-3 mt-2">
                  <label class="criteria-subtext" for="reward-points">Reward Points</label>
                  <input type="number" formControlName="rewardPoints" class="form-control" required
                    pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==10) return false;" />
                </div>
              </div>

              <div *ngIf="rulesGroup.get('criteriaType').value === 4">
                <assign-users [userDetails]="userDetails" [isAdminView]="false"
                  (onSubmitAssignUsers)="updatedUserDetails($event)" [moduleName]="'challenges'"></assign-users>
              </div>

              <div *ngIf="rulesGroup.get('criteriaType').value === 5">
                <div class="channels-section col-12 flex-direction mt-3">
                  <div class="col-md-4">
                    <ng-select (change)="addCustomForm($event)" class="custom" bindLabel="name" [virtualScroll]="false"
                      placeholder="Select Custom Form" appendTo="body" [clearable]="true" [(ngModel)]="channelSelect"
                      [ngModelOptions]="{ standalone: true }">
                      <ng-option [value]="item" *ngFor="let item of customfromData">
                        {{ item.title }}
                      </ng-option>
                    </ng-select>
                  </div>
                  <div class="col-md-4">
                    <ul *ngIf="rulesGroup.get('selectedCustomForm.title').value">
                      <li>
                        <span class="text-items list-ui selected-list">{{
                          rulesGroup.get("selectedCustomForm.title").value
                          }}
                          <img (click)="deleteCustomForm()" class="ml-3" *ngIf="
                              rulesGroup.get('selectedCustomForm.title').value
                            " src="./assets/images/cancel.png" width="20px" height="20px"
                            crossorigin="anonymous" /></span>
                      </li>
                    </ul>
                  </div>
                </div>

                <p class="criteria-source mt-3">Who to reward for challenge</p>
                <div class="channels-section col-12 flex-direction">
                  <div class="col-md-4">
                    <ng-select (change)="addcriteriaSourceType($event, 'Custom Form')" class="custom" bindLabel="name"
                      placeholder="Select Criteria" appendTo="body" [multiple]="false" [clearable]="true"
                      [(ngModel)]="criteriaSelect" [ngModelOptions]="{ standalone: true }">
                      <ng-option [value]="item" *ngFor="let item of customCriteria">
                        {{ item.key }}
                      </ng-option>
                    </ng-select>
                  </div>
                  <div class="col-md-4">
                    <ul *ngIf="customFormcreatiaView">
                      <li>
                        <span class="text-items list-ui selected-list">{{ customFormcreatiaView }}
                          <img class="ml-3" (click)="customFormcreatiaView = null" *ngIf="customFormcreatiaView"
                            src="./assets/images/cancel.png" width="20px" height="20px"
                            crossorigin="anonymous" /></span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div>
                  <div class="flex-direction">
                    <p class="criteria-source mt-3">Criteria Categories</p>
                    <label class="switch criteria-source mt-3 ml-4">
                      <input type="checkbox"
                        [checked]="rulesGroup.get('isCriteriaCategory').value === true ? 'checked' : ''"
                        formControlName="isCriteriaCategory" />
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <div class="channels-section col-12 flex-direction"
                    *ngIf="rulesGroup.get('isCriteriaCategory').value === true">
                    <div class="col-md-4">
                      <ng-select class="custom" bindLabel="name" (change)="addItemsInSelector($event, 'Criteria_Category')"
                        placeholder="Select Criteria Category" appendTo="body" [multiple]="false" [clearable]="true"
                        [(ngModel)]="criteriaCategorySelect" [ngModelOptions]="{ standalone: true }">
                        <ng-option [value]="item" *ngFor="let item of criteriaCategory">
                          {{ item.key }}
                        </ng-option>
                      </ng-select>
                    </div>
                    <div class="col-md-4">
                      <ul *ngIf="criteriaCategoryView">
                        <li>
                          <span class="text-items list-ui selected-list">{{ criteriaCategoryView }}
                            <img class="ml-3" (click)="criteriaCategoryView = null" *ngIf="criteriaCategoryView"
                              src="./assets/images/cancel.png" width="20px" height="20px"
                              crossorigin="anonymous" /></span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div *ngIf="shiftcriteriaSelectNumber === 7">
                  <p class="criteria-source mt-3">Select Nominate question</p>

                  <div class="channels-section col-12 flex-direction">
                    <div class="col-md-4">
                      <ng-select class="custom" bindLabel="name" placeholder="" appendTo="body" [multiple]="false"
                        [clearable]="true" (change)="addNomineeQuestion($event)" [(ngModel)]="nomineeQuestion">
                        <ng-option [value]="item" *ngFor="let item of nomineeQuestionData">
                          {{ item.question }}
                        </ng-option>
                      </ng-select>
                    </div>
                    <div class="col-md-4">
                      <ul *ngIf="nominateView">
                        <li>
                          <span class="text-items list-ui selected-list">{{ nominateView }}
                            <img class="ml-3" (click)="deleteNominateCreteria()" *ngIf="nominateView"
                              src="./assets/images/cancel.png" width="20px" height="20px"
                              crossorigin="anonymous" /></span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div *ngIf="workFlowStatus">
                  <p class="criteria-source mt-3">Workflow Status</p>

                  <div class="channels-section col-12 flex-direction">
                    <div class="col-md-4">
                      <ng-select (change)="addWorkFlow($event)" class="custom" bindLabel="fieldName" placeholder="Select workflow status"
                        appendTo="body" [multiple]="false" [clearable]="true" [(ngModel)]="workFlowDropdown"
                        [ngModelOptions]="{ standalone: true }">
                        <ng-option [value]="item" *ngFor="let item of workFlowData">
                          {{ item.fieldName }}
                        </ng-option>
                      </ng-select>
                    </div>
                    <div class="col-md-4" *ngIf="workFlowstatusData?.length > 0">
                      <ng-select (change)="addWorkflowStatus($event)" class="custom" bindLabel="fieldStatusValue"
                        placeholder="" appendTo="body" [multiple]="false" [clearable]="true"
                        [(ngModel)]="workFlowFieldValue" [ngModelOptions]="{ standalone: true }">
                        <ng-option [value]="item" *ngFor="let item of workFlowstatusData">
                          {{ item.fieldStatusValue }}
                        </ng-option>
                      </ng-select>
                    </div>
                    <div class="col-md-4">
                      <div formArrayName="fieldOptions" *ngFor="
                          let field of workFlowFieldControl.controls;
                          let index = index
                        ">
                        <span class="text-items list-ui mt-2" [formGroupName]="index">
                          {{ field.get("workflowValue").value }}
                          &lt; {{ field.get("worflowStatusValue").value }} <img class="ml-3"
                            (click)="deleteWorkFlowSttus(index)" src="./assets/images/cancel.png" width="20px"
                            height="20px" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <p class="criteria-source mt-3">Criteria Counter</p>

                <div class="criteria-radio col-6">
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="single" [value]="1" formControlName="criteriaCountType" />
                    <label for="channel">Single</label><br />

                    <div class="form-group ml-1">
                      <label class="criteria-subtext" for="criteria-count">Criteria Count</label>
                      <input type="number" min="1" [value]="1" [attr.disabled]="
                          rulesGroup.get('criteriaCountType').value === 1
                            ? true
                            : null
                        " class="form-control" placeholder="Criteria Count" formControlName="criteriaCount"
                        pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length == 10) return false;" />
                    </div>
                  </div>
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="bundled" [value]="2"
                      formControlName="criteriaCountType" />
                    <label for="boards">Bundled</label><br />
                  </div>
                </div>
                <div>
                  <div class="flex-direction">
                    <p class="criteria-source mt-3">Challenge Reward Type</p>
                    <label class="switch criteria-source mt-3 ml-4">
                      <input type="checkbox"
                        [checked]="rulesGroup.get('isRewardChallenge').value === true ? 'checked' : ''"
                        formControlName="isRewardChallenge" />
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <div *ngIf="rulesGroup.get('isRewardChallenge').value === true">
                    <div class="criteria-radio col-8">
                      <div>
                        <input type="radio" class="mt-1 ml-2" id="2" [value]="false"
                          formControlName="nonRewardPointSystemEnabled" />
                        <label for="no">Reward Point System</label><br />
                      </div>
                      <div>
                        <input type="radio" class="mt-1 ml-2" id="1" [value]="true"
                          formControlName="nonRewardPointSystemEnabled" />
                        <label for="yes">Non-Reward Point System</label><br />
                      </div>
                    </div>
                    <div class="row pt-4 pl-4 pb-2" *ngIf="rulesGroup.get('nonRewardPointSystemEnabled').value">
                      <div class="col-md-3">
                        <ng-select (change)="addPointSystem($event)" class="custom" bindLabel="name" placeholder=""
                          appendTo="body" [multiple]="false" [(ngModel)]="criteriaCategorySelect" [clearable]="true">
                          <ng-option [value]="item" *ngFor="let item of poinstSystemData">
                            {{ item.title }}
                          </ng-option>
                        </ng-select>
                      </div>
                      <div class="col-md-4">
                        <ul *ngIf="pointSytemView">
                          <li>
                            <span class="text-items list-ui">{{ pointSytemView }}
                              <img class="ml-3" (click)="pointSytemView = null" *ngIf="pointSytemView"
                                src="./assets/images/cancel.png" width="20px" height="20px"
                                crossorigin="anonymous" /></span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="form-group col-md-3">
                      <label class="criteria-subtext" for="reward-points">Reward Points</label>
                      <input type="number" min="0" class="form-control" placeholder="Reward Points"
                        onKeyPress="if(this.value.length==10) return false;" formControlName="rewardPoints" />
                    </div>
                  </div>
                </div>
                <p class="criteria-source mt-0">Points Counter</p>
                <p class="criteria-subtext mt-0">
                  Will points count stop after 1st achievement
                </p>
                <div class="criteria-radio col-3">
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="1" [value]="true"
                      formControlName="stopAfterAchievement" />
                    <label for="yes">Once-off</label><br />
                  </div>
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="2" [value]="false"
                      formControlName="stopAfterAchievement" />
                    <label for="no">Recurring</label><br />
                  </div>
                </div>

                <p class="criteria-subtext mt-0">
                  Do we need to limit the maximum points per staff
                </p>
                <div class="criteria-radio col-3">
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="1" [value]="true"
                      formControlName="setLimitToMaxRewards" />
                    <label for="yes">Yes</label><br />
                  </div>
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="2" [value]="false"
                      formControlName="setLimitToMaxRewards" />
                    <label for="no">No</label><br />
                  </div>
                </div>
                <div class="row" *ngIf="rulesGroup.get('setLimitToMaxRewards').value">
                  <div class="col-md-4">
                    <input type="number" pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==10) return false;"
                      min="0" class="form-control" placeholder="setLimitToMaxRewards" formControlName="maximumRewards"
                      required />
                  </div>
                </div>

                <p class="criteria-subtext mt-0">Badge Tiering required</p>
                <div class="criteria-radio col-3">
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="1" [value]="true" formControlName="badgeTiering" />
                    <label for="yes">Yes</label><br />
                  </div>
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="2" [value]="false" formControlName="badgeTiering" />
                    <label for="no">No</label><br />
                  </div>
                </div>
                <div class="row" *ngIf="rulesGroup.get('badgeTiering').value">
                  <div class="col-md-6">
                    <form [formGroup]="createRanks">
                      <table class="table borderless">
                        <tbody>
                          <tr>
                            <td>
                              <input type="text" class="form-control" placeholder="Badge" formControlName="name"
                                required pattern="/^-?\d+\.?\d*$/"
                                onKeyPress="if(this.value.length==50) return false;" />
                            </td>
                            <div class="ml-3">
                              <input type="number" min="0" class="form-control" placeholder="From"
                                formControlName="startRange" required pattern="/^-?\d+\.?\d*$/"
                                onKeyPress="if(this.value.length==10) return false;" />
                            </div>
                            <td class="columns">
                              <input type="number" min="0" class="form-control columns" placeholder="To"
                                formControlName="endRange" required pattern="/^-?\d+\.?\d*$/"
                                onKeyPress="if(this.value.length==10) return false;" />
                            </td>
                            <td class="columns">
                              <div class="custom-file" style="
                                  width: 130px;
                                  height: 30px;
                                  margin-left: 10px;
                                " *ngIf="!createRanks.get('icon').value">
                                <input type="file" style="
                                    position: absolute;
                                    z-index: 9;
                                    visibility: visible;
                                    left: 0;
                                    top: 0;
                                    width: 80%;
                                    opacity: 0;
                                  " (change)="badgeiconUpload($event)" required id="b" />
                                <label class="custom-file-label" for="b">
                                  Upload Image
                                </label>
                              </div>
                              <div *ngIf="createRanks.get('icon').value">
                                <div class="
                                    image-with-delete-icon
                                    ml-5 ml-0
                                    badge-image
                                  " style="width: 100px">
                                  <i class="fa fa-close" title="Delete" for="4" (click)="onDeleteBadgeImage()"></i>
                                  <img [src]="validateURLImage(createRanks.get('icon').value)" id="4" alt="Image Preview"
                                    crossorigin="anonymous" />
                                </div>
                              </div>
                            </td>
                            <div class="add-button columns">
                              <button class="btn btn-submit btn-lg ml-1 pl-3 pr-3" (click)="addRank()">
                                +
                              </button>
                            </div>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                  </div>
                </div>
                <div class="row">
                  <div class="col-8" *ngIf="badgeTieringArray.length != 0">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">TIER TITLE</th>
                            <th scope="col">FROM</th>

                            <th scope="col">TO</th>
                            <th scope="col">BADGE ICON</th>

                            <th scope="col">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="
                              let field of badgeTieringArray;
                              let index = index
                            ">
                            <td>
                              <div class="mt-2">{{ field.name }}</div>
                            </td>

                            <td>
                              <div class="mt-2">{{ field.startRange }}</div>
                            </td>
                            <td>
                              <div class="mt-2">{{ field.endRange }}</div>
                            </td>

                            <td>
                              <img [src]="validateURLImage(field.icon)" width="50px" height="50px" class="mt-2" alt="icon Preview"
                                crossorigin="anonymous" />
                            </td>

                            <td>
                              <img class="ml-3 mt-2" (click)="deleteBadge(index)" src="./assets/images/cancel.png"
                                width="20px" height="20px" crossorigin="anonymous" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="flex-direction">
                    <p class="criteria-source mt-3">Progress Status Setup</p>
                    <label class="switch criteria-source mt-3 ml-4">
                      <input type="checkbox"
                        [checked]="rulesGroup.get('isProgressSetup').value === true ? 'checked' : ''"
                        formControlName="isProgressSetup" />
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <div *ngIf="rulesGroup.get('isProgressSetup').value === true">
                    <div class="my-3">
                      <p class="criteria-subtext mt-0">Progress Type</p>
                      <div class="channels-section col-12 flex-direction">
                        <div class="col-md-4">
                          <ng-select class="custom" (change)="addItemsInSelector($event, 'Progress_Type')" [disabled]="discriptioGroup.get('leaderBoard').value == true ? true: null"
                            bindLabel="name" placeholder="Select Progress Type" appendTo="body" [multiple]="false"
                            [clearable]="true" [(ngModel)]="criteriaCategorySelect" [ngModelOptions]="{ standalone: true }">
                            <ng-option [value]="item" *ngFor="let item of progressTypes">
                              {{ item.key }}
                            </ng-option>
                          </ng-select>
                        </div>
                        <div class="col-md-4">
                          <ul *ngIf="progressTypesView">
                            <li>
                              <span class="text-items list-ui selected-list" [disabled]="discriptioGroup.get('leaderBoard').value == true ? true: null">{{ progressTypesView }}
                                <img class="ml-3" (click)="clearProgressTypeData()" *ngIf="progressTypesView"
                                  src="./assets/images/cancel.png" width="20px" height="20px"
                                  crossorigin="anonymous" /></span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="my-3">
                      <p class="criteria-subtext mt-0">Display Selection</p>
                      <div class="channels-section col-12 flex-direction">
                        <div class="col-md-4">
                          <ng-select class="custom" (change)="addItemsInSelector($event, 'Display_Selection')"
                            bindLabel="name" placeholder="Select Display Selection" appendTo="body" [multiple]="false"
                            [clearable]="true" [(ngModel)]="criteriaCategorySelect" [ngModelOptions]="{ standalone: true }">
                            <ng-option [value]="item" *ngFor="let item of displaySelectionData">
                              {{ item.key }}
                            </ng-option>
                          </ng-select>
                        </div>
                        <div class="col-md-4">
                          <ul *ngIf="displaySelectionView">
                            <li>
                              <span class="text-items list-ui selected-list">{{ displaySelectionView }}
                                <img class="ml-3" (click)="displaySelectionView = null" *ngIf="displaySelectionView"
                                  src="./assets/images/cancel.png" width="20px" height="20px"
                                  crossorigin="anonymous" /></span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="my-3">
                      <p class="criteria-subtext mt-0">Display Description</p>
                      <div class="channels-section col-12 flex-direction">
                        <div class="col-md-4">
                          <ng-select class="custom" (change)="addItemsInSelector($event, 'Display_Description')"
                            bindLabel="name" placeholder="Select Display Description" appendTo="body" [multiple]="false"
                            [clearable]="true" [(ngModel)]="criteriaCategorySelect" [ngModelOptions]="{ standalone: true }">
                            <ng-option [value]="item" *ngFor="let item of displayDescriptions">
                              {{ item.key }}
                            </ng-option>
                          </ng-select>
                        </div>
                        <div class="col-md-4">
                          <ul *ngIf="displayDescriptionsView">
                            <li>
                              <span class="text-items list-ui selected-list">{{ displayDescriptionsView }}
                                <img class="ml-3" (click)="displayDescriptionsView = null" *ngIf="displayDescriptionsView"
                                  src="./assets/images/cancel.png" width="20px" height="20px"
                                  crossorigin="anonymous" /></span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="my-3">
                      <p class="criteria-subtext mt-0">Display Fields</p>
                      <div class="channels-section col-12 flex-direction">
                        <div class="col-md-4">
                          <ng-select class="custom" (change)="addItemsInSelector($event, 'Display_Fields')"
                            bindLabel="name" placeholder="Select Display Fields" appendTo="body" [multiple]="false"
                            [clearable]="true" [(ngModel)]="criteriaCategorySelect" [ngModelOptions]="{ standalone: true }">
                            <ng-option [value]="item" *ngFor="let item of displayFieldsData">
                              {{ item.key }}
                            </ng-option>
                          </ng-select>
                        </div>
                        <div class="col-md-4">
                          <ul *ngIf="criteriaCategorySelectView">
                            <li>
                              <span class="text-items list-ui selected-list">{{ criteriaCategorySelectView }}
                                <img class="ml-3" (click)="criteriaCategorySelectView = null" *ngIf="criteriaCategorySelectView"
                                  src="./assets/images/cancel.png" width="20px" height="20px"
                                  crossorigin="anonymous" /></span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="flex-direction">
                        <p class="criteria-source mt-3">Target Criteria Display</p>
                        <label class="switch criteria-source mt-3 ml-4">
                          <input type="checkbox"
                            [checked]="rulesGroup.get('isTargetCriteria').value === true ? 'checked' : ''"
                            formControlName="isTargetCriteria" />
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <div *ngIf="rulesGroup.get('isTargetCriteria').value === true">
                        <div class="my-3">
                          <p class="criteria-subtext mt-0">Number of criteria count​</p>
                          <div class="channels-section col-12 flex-direction">
                            <div class="col-md-4">
                              <input type="number" min="0" class="form-control" placeholder="Criteria Count"
                                onKeyPress="if(this.value.length==10) return false;" formControlName="targetCount" />
                            </div>
                          </div>
                        </div>
                        <div class="my-3">
                          <p class="criteria-subtext mt-0">Target description​</p>
                          <div class="channels-section col-12 flex-direction">
                            <div class="col-md-4">
                              <input type="text" class="form-control" placeholder="Description" onKeyPress=""
                                formControlName="targetCountDescription" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="rulesGroup.get('criteriaType').value === 6">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="text-theme font-weight-500">Selected Scheme</label><br />
                      <ng-select [items]="schemeDataArr" class="custom" bindLabel="schemeName" [virtualScroll]="false"
                        placeholder="Select scheme" appendTo="body" [clearable]="true" [multiple]="true"
                        [closeOnSelect]="false" [searchable]="true" [(ngModel)]="schemeData"
                        [ngModelOptions]="{ standalone: true }">
                      </ng-select>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="text-theme font-weight-500">Criteria Selection</label><br />
                      <ng-select [items]="shiftCriteriaArr" class="custom" bindLabel="key" placeholder="Select Criteria"
                        appendTo="body" [multiple]="false" [clearable]="true" [(ngModel)]="shiftcriteriaSelect"
                        [ngModelOptions]="{ standalone: true }" (change)="addcriteriaSourceType($event, 'shiftWork')">
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <ul *ngIf="shiftcriteriaSelect">
                      <li>
                        <span class="text-items list-ui selected-list">{{ shiftcriteriaSelect }}
                          <img (click)="deleteShiftCriteria()" class="ml-3" *ngIf="shiftcriteriaSelect"
                            src="./assets/images/cancel.png" width="20px" height="20px"
                            crossorigin="anonymous" /></span>
                      </li>
                    </ul>
                  </div>
                </div>

                <p class="criteria-source mt-3">Criteria Counter</p>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <input type="radio" class="mt-1 ml-2" id="single" [value]="1"
                          formControlName="criteriaCountType" />
                        <label for="channel">Single</label><br />
                      </div>
                      <div class="form-group col-md-6">
                        <input type="radio" class="mt-1 ml-2" id="bundled" [value]="2"
                          formControlName="criteriaCountType" />
                        <label for="boards">Bundled</label><br />
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6"></div>

                  <div class="col-md-12" *ngIf="this.rulesGroup.get('criteriaCountType').value == 2">
                    <div class="form-row">
                      <div class="form-group col-md-4">
                        <label class="font-weight-bold">Select Day</label><br />
                        <button *ngFor="let i of dayArr" class="dayButton"
                          [class.selected]="this.addedDay.indexOf(i) != -1" (click)="addDay(i)">
                          {{ i.day }}
                        </button>
                      </div>
                      <div class="form-group col-md-4">
                        <div class="form-row">
                          <div class="form-group col-md-6">
                            <label>Count</label><br />
                            <input type="text" class="form-control" placeholder="" [(ngModel)]="bundleCount"
                              [ngModelOptions]="{ standalone: true }" />
                          </div>
                          <div class="form-group col-md-6 align-self-end">
                            <button class="btn btn-danger">Clear</button>
                            <button class="btn btn-primary" (click)="addBundle()">
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div *ngIf="this.bundleArr.length">
                          <div class="labels my-1" *ngFor="let item of bundleArr; let i = index">
                            <span class="text">{{ item.day }} ({{ item.count }})
                            </span>
                            <span class="material-icons bg-danger rounded btn-close" (click)="clearBundleLabel(i)">
                              clear
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label class="criteria-subtext" for="criteria-count">Criteria Count</label>
                        <input type="number" min="1" [value]="1" [attr.disabled]="
                            rulesGroup.get('criteriaCountType').value === 1
                              ? true
                              : null
                          " class="form-control" placeholder="Criteria Count" formControlName="criteriaCount"
                          pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length == 10) return false;" />
                      </div>
                      <div class="form-group col-md-6">
                        <label class="criteria-subtext" for="reward-points">Reward Points</label>
                        <input type="number" min="0" class="form-control" placeholder="Reward Points"
                          onKeyPress="if(this.value.length==10) return false;" formControlName="rewardPoints" />
                      </div>
                    </div>
                  </div>
                </div>

                <p class="criteria-source mt-3">Non-reward Point System</p>
                <br />
                <p class="criteria-subtext mt-0">Do we need point system</p>
                <div class="criteria-radio col-3">
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="1" [value]="true"
                      formControlName="nonRewardPointSystemEnabled" />
                    <label for="yes">Yes</label><br />
                  </div>
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="2" [value]="false"
                      formControlName="nonRewardPointSystemEnabled" />
                    <label for="no">No</label><br />
                  </div>
                </div>

                <div class="row" *ngIf="rulesGroup.get('nonRewardPointSystemEnabled').value">
                  <div class="col-md-4">
                    <ng-select (change)="addPointSystem($event)" class="custom" bindLabel="name" placeholder=""
                      appendTo="body" [multiple]="false" [clearable]="true">
                      <ng-option [value]="item" *ngFor="let item of poinstSystemData">
                        {{ item.title }}
                      </ng-option>
                    </ng-select>
                  </div>
                  <div class="col-md-4">
                    <ul *ngIf="pointSytemView">
                      <li>
                        <span class="text-items list-ui mt-3">{{ pointSytemView }}
                          <img class="ml-3" (click)="pointSytemView = null" *ngIf="pointSytemView"
                            src="./assets/images/cancel.png" width="20px" height="20px"
                            crossorigin="anonymous" /></span>
                      </li>
                    </ul>
                  </div>
                </div>
                <button *ngIf="!rulesGroup.get('nonRewardPointSystemEnabled').value" class="reward-points-button">
                  Reward Points</button><br />
                <p class="criteria-source mt-0">Reward Counter</p>
                <p class="criteria-subtext mt-0">
                  Will rewards count stop after 1st achievement
                </p>
                <div class="criteria-radio col-3">
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="1" [value]="true"
                      formControlName="stopAfterAchievement" />
                    <label for="yes">Once-off</label><br />
                  </div>
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="2" [value]="false"
                      formControlName="stopAfterAchievement" />
                    <label for="no">Recurring</label><br />
                  </div>
                </div>

                <p class="criteria-subtext mt-0">
                  Do we need to limit the maximum rewards per staff
                </p>
                <div class="criteria-radio col-3">
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="1" [value]="true"
                      formControlName="setLimitToMaxRewards" />
                    <label for="yes">Yes</label><br />
                  </div>
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="2" [value]="false"
                      formControlName="setLimitToMaxRewards" />
                    <label for="no">No</label><br />
                  </div>
                </div>
                <div class="row" *ngIf="rulesGroup.get('setLimitToMaxRewards').value">
                  <div class="col-md-4">
                    <input type="number" pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==10) return false;"
                      min="0" class="form-control" placeholder="setLimitToMaxRewards" formControlName="maximumRewards"
                      required />
                  </div>
                </div>

                <p class="criteria-subtext mt-0">Badge Tiering required</p>
                <div class="criteria-radio col-3">
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="1" [value]="true" formControlName="badgeTiering" />
                    <label for="yes">Yes</label><br />
                  </div>
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="2" [value]="false" formControlName="badgeTiering" />
                    <label for="no">No</label><br />
                  </div>
                </div>
                <div class="row" *ngIf="rulesGroup.get('badgeTiering').value">
                  <div class="col-md-6">
                    <form [formGroup]="createRanks">
                      <table class="table borderless">
                        <tbody>
                          <tr>
                            <td>
                              <input type="text" class="form-control" placeholder="Badge" formControlName="name"
                                required pattern="/^-?\d+\.?\d*$/"
                                onKeyPress="if(this.value.length==50) return false;" />
                            </td>
                            <div class="ml-3">
                              <input type="number" min="0" class="form-control" placeholder="From"
                                formControlName="startRange" required pattern="/^-?\d+\.?\d*$/"
                                onKeyPress="if(this.value.length==10) return false;" />
                            </div>
                            <td class="columns">
                              <input type="number" min="0" class="form-control columns" placeholder="To"
                                formControlName="endRange" required pattern="/^-?\d+\.?\d*$/"
                                onKeyPress="if(this.value.length==10) return false;" />
                            </td>
                            <td class="columns">
                              <div class="custom-file" style="
                                  width: 130px;
                                  height: 30px;
                                  margin-left: 10px;
                                " *ngIf="!createRanks.get('icon').value">
                                <input type="file" style="
                                    position: absolute;
                                    z-index: 9;
                                    visibility: visible;
                                    left: 0;
                                    top: 0;
                                    width: 80%;
                                    opacity: 0;
                                  " (change)="badgeiconUpload($event)" required id="b" />
                                <label class="custom-file-label" for="b">
                                  Upload Image
                                </label>
                              </div>
                              <div *ngIf="createRanks.get('icon').value">
                                <div class="
                                    image-with-delete-icon
                                    ml-5 ml-0
                                    badge-image
                                  " style="width: 100px">
                                  <i class="fa fa-close" title="Delete" for="4" (click)="onDeleteBadgeImage()"></i>
                                  <img [src]="validateURLImage(createRanks.get('icon').value)" id="4" alt="Image Preview"
                                    crossorigin="anonymous" />
                                </div>
                              </div>
                            </td>
                            <div class="add-button columns">
                              <button class="btn btn-submit btn-lg ml-1 pl-3 pr-3" (click)="addRank()">
                                +
                              </button>
                            </div>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                  </div>
                </div>
                <div class="row">
                  <div class="col-8" *ngIf="badgeTieringArray.length != 0">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">TIER TITLE</th>
                            <th scope="col">FROM</th>

                            <th scope="col">TO</th>
                            <th scope="col">BADGE ICON</th>

                            <th scope="col">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="
                              let field of badgeTieringArray;
                              let index = index
                            ">
                            <td>
                              <div class="mt-2">{{ field.name }}</div>
                            </td>

                            <td>
                              <div class="mt-2">{{ field.startRange }}</div>
                            </td>
                            <td>
                              <div class="mt-2">{{ field.endRange }}</div>
                            </td>

                            <td>
                              <img [src]="validateURLImage(field.icon)" width="50px" height="50px" class="mt-2" alt="icon Preview"
                                crossorigin="anonymous" />
                            </td>

                            <td>
                              <img class="ml-3 mt-2" (click)="deleteBadge(index)" src="./assets/images/cancel.png"
                                width="20px" height="20px" crossorigin="anonymous" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <button class="btn btn-warning mt-4" (click)="stepper.previous()">
                  Prev
                </button>
                <button class="btn btn-danger ml-1 mt-4" (click)="validateRulesStepper()">
                  Next
                </button>
              </div>
            </div>
          </div>

          <div class="card my-3 w-100" *ngIf="challangesId">
            <div class="card-header flexi-header">
              <h3>RULES</h3>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="text-theme font-weight-500">Criteria Source</label><br />
                    <div class="form-check form-check-inline">
                      <input type="radio" formControlName="criteriaType" class="form-check-input" id="1"
                        name="criteriaType" [value]="1" disabled />
                      <label for="channel" class="form-check-label">Channel</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input type="radio" formControlName="criteriaType" class="form-check-input" id="2"
                        name="criteriaType" [value]="2" disabled />
                      <label for="boards" class="form-check-label">Boards</label>
                    </div>

                    <div class="form-check form-check-inline">
                      <input type="radio" formControlName="criteriaType" class="form-check-input" id="3"
                        name="criteriaType" [value]="3" disabled />
                      <label for="system" class="form-check-label">System</label>
                    </div>

                    <div class="form-check form-check-inline">
                      <input type="radio" formControlName="criteriaType" class="form-check-input" id="4"
                        name="criteriaType" [value]="4" disabled />
                      <label for="direct-rewards" class="form-check-label">Direct Rewards</label>
                    </div>

                    <div class="form-check form-check-inline">
                      <input type="radio" formControlName="criteriaType" class="form-check-input" id="5"
                        name="criteriaType" [value]="5" disabled />
                      <label for="custom-form" class="form-check-label">Custom Form</label>
                    </div>

                    <div class="form-check form-check-inline">
                      <input type="radio" formControlName="criteriaType" class="form-check-input" id="6"
                        name="criteriaType" [value]="6" disabled />
                      <label class="form-check-label">Shift Work</label>
                    </div>
                  </div>
                </div>
              </div>
              <hr />

              <ng-container *ngIf="rulesGroup.get('criteriaType').value === 1">
                <div class="channels-section col-12 flex-direction mt-3">
                  <div class="col-md-4">
                    <select class="form-control" disabled>
                      <option></option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <ul *ngIf="rulesGroup.get('selectedChannel.name').value">
                      <li>
                        <span class="text-items list-ui selected-list">{{ rulesGroup.get("selectedChannel.name").value
                          }}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

                <p class="criteria-source mt-3">Criteria Selection</p>

                <div class="channels-section col-12 flex-direction">
                  <div class="col-md-4">
                    <select class="form-control" disabled>
                      <option></option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <ul *ngIf="creatiaView">
                      <li>
                        <span class="text-items list-ui selected-list">{{ creatiaView }}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

                <p class="criteria-source mt-3">Criteria Counter</p>
                <div class="criteria-radio col-6">
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="single" [value]="1" formControlName="criteriaCountType"
                      (click)="resetCriteriaCounter()" disabled />
                    <label for="channel">Single</label><br />

                    <div class="form-group ml-1">
                      <label class="criteria-subtext" for="criteria-count">Criteria Count</label>
                      <input type="number" min="1" [value]="1" disabled class="form-control"
                        placeholder="Criteria Count" formControlName="criteriaCount" pattern="/^-?\d+\.?\d*$/"
                        onKeyPress="if(this.value.length == 10) return false;" />
                    </div>
                  </div>
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="bundled" [value]="2" formControlName="criteriaCountType"
                      disabled />
                    <label for="boards">Bundled</label><br />

                    <div class="form-group ml-1">
                      <label class="criteria-subtext" for="reward-points">Reward Points</label>
                      <input type="number" pattern="/^-?\d+\.?\d*$/"
                        onKeyPress="if(this.value.length==10) return false;" min="0" class="form-control"
                        placeholder="Reward Points" formControlName="rewardPoints" disabled />
                    </div>
                  </div>
                </div>

                <p class="criteria-source mt-3">Non-reward Point System</p>
                <br />
                <p class="criteria-subtext mt-0">Do we need point system</p>
                <div class="criteria-radio col-3">
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="1" disabled [value]="true"
                      formControlName="nonRewardPointSystemEnabled" />
                    <label for="yes">Yes</label><br />
                  </div>
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="2" [value]="false" disabled
                      formControlName="nonRewardPointSystemEnabled" />
                    <label for="no">No</label><br />
                  </div>
                </div>

                <div class="row" *ngIf="rulesGroup.get('nonRewardPointSystemEnabled').value">
                  <div class="col-md-4">
                    <select class="form-control" disabled>
                      <option></option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <ul *ngIf="pointSytemView">
                      <li>
                        <span class="text-items list-ui mt-3">{{ pointSytemView }}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <button *ngIf="!rulesGroup.get('nonRewardPointSystemEnabled').value" class="reward-points-button">
                  Reward Points</button><br />

                <p class="criteria-source mt-0">Reward Counter</p>
                <p class="criteria-subtext mt-0">
                  Will rewards count stop after 1st achievement
                </p>
                <div class="criteria-radio col-3">
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="1" disabled [value]="true"
                      formControlName="stopAfterAchievement" />
                    <label for="yes">Once-off</label><br />
                  </div>
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="2" [value]="false" disabled
                      formControlName="stopAfterAchievement" />
                    <label for="no">Recurring</label><br />
                  </div>
                </div>

                <p class="criteria-subtext mt-0">
                  Do we need to limit the maximum rewards per staff
                </p>
                <div class="criteria-radio col-3">
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="1" [value]="true" disabled
                      formControlName="setLimitToMaxRewards" />
                    <label for="yes">Yes</label><br />
                  </div>
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="2" [value]="false" disabled
                      formControlName="setLimitToMaxRewards" />
                    <label for="no">No</label><br />
                  </div>
                </div>
                <div class="row" *ngIf="rulesGroup.get('setLimitToMaxRewards').value">
                  <div class="col-md-4">
                    <input type="number" pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==10) return false;"
                      min="0" class="form-control" placeholder="setLimitToMaxRewards" formControlName="maximumRewards"
                      required />
                  </div>
                </div>

                <p class="criteria-subtext mt-0">Badge Tiering required</p>
                <div class="criteria-radio col-3">
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="1" disabled [value]="true"
                      formControlName="badgeTiering" />
                    <label for="yes">Yes</label><br />
                  </div>
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="2" [value]="false" disabled
                      formControlName="badgeTiering" />
                    <label for="no">No</label><br />
                  </div>
                </div>
                <div class="row" *ngIf="rulesGroup.get('badgeTiering').value">
                  <div class="col-md-6">
                    <form [formGroup]="createRanks">
                      <table class="table borderless">
                        <tbody>
                          <tr>
                            <td>
                              <input type="text" class="form-control" placeholder="Badge" formControlName="name"
                                required pattern="/^-?\d+\.?\d*$/"
                                onKeyPress="if(this.value.length==50) return false;" />
                            </td>
                            <div class="ml-3">
                              <input type="number" min="0" class="form-control" placeholder="From"
                                formControlName="startRange" required pattern="/^-?\d+\.?\d*$/"
                                onKeyPress="if(this.value.length==10) return false;" />
                            </div>
                            <td class="columns">
                              <input type="number" min="0" class="form-control columns" placeholder="To"
                                formControlName="endRange" required pattern="/^-?\d+\.?\d*$/"
                                onKeyPress="if(this.value.length==10) return false;" />
                            </td>
                            <td class="columns">
                              <div class="custom-file" style="
                                  width: 130px;
                                  height: 30px;
                                  margin-left: 10px;
                                " *ngIf="!createRanks.get('icon').value">
                                <input type="file" disabled style="
                                    position: absolute;
                                    z-index: 9;
                                    visibility: visible;
                                    left: 0;
                                    top: 0;
                                    width: 80%;
                                    opacity: 0;
                                  " required id="b" />
                                <label class="custom-file-label" for="b">
                                  Upload Image
                                </label>
                              </div>
                              <div *ngIf="createRanks.get('icon').value">
                                <div class="
                                    image-with-delete-icon
                                    ml-5 ml-0
                                    badge-image
                                  " style="width: 100px">
                                  <i class="fa fa-close" title="Delete" for="4" (click)="onDeleteBadgeImage()"></i>
                                  <img [src]="validateURLImage(createRanks.get('icon').value)" id="4" alt="Image Preview"
                                    crossorigin="anonymous" />
                                </div>
                              </div>
                            </td>
                            <div class="add-button columns">
                              <button class="btn btn-submit btn-lg ml-1 pl-3 pr-3" (click)="addRank()">
                                +
                              </button>
                            </div>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                  </div>
                </div>
                <div class="row">
                  <div class="col-8" *ngIf="badgeTieringArray.length != 0">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">TIER TITLE</th>
                            <th scope="col">FROM</th>

                            <th scope="col">TO</th>
                            <th scope="col">BADGE ICON</th>

                            <th scope="col">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="
                              let field of badgeTieringArray;
                              let index = index
                            ">
                            <td>
                              <div class="mt-2">{{ field.name }}</div>
                            </td>

                            <td>
                              <div class="mt-2">{{ field.startRange }}</div>
                            </td>
                            <td>
                              <div class="mt-2">{{ field.endRange }}</div>
                            </td>

                            <td>
                              <img [src]="validateURLImage(field.icon)" width="50px" height="50px" class="mt-2" alt="icon Preview"
                                crossorigin="anonymous" />
                            </td>

                            <td>
                              <img class="ml-3 mt-2" (click)="deleteBadge(index)" src="./assets/images/cancel.png"
                                width="20px" height="20px" crossorigin="anonymous" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </ng-container>

              <div *ngIf="rulesGroup.get('criteriaType').value === 2">
                <div class="channels-section col-12 flex-direction mt-3">
                  <div class="col-md-4">
                    <select class="form-control" disabled>
                      <option></option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <ul *ngIf="rulesGroup.get('selectedWall.name').value">
                      <li>
                        <span class="text-items list-ui selected-list">{{ rulesGroup.get("selectedWall.name").value }}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

                <p class="criteria-source mt-3">Criteria Selection</p>

                <div class="channels-section col-12 flex-direction">
                  <div class="col-md-4">
                    <select class="form-control" disabled>
                      <option></option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <ul *ngIf="boardcreatiaView">
                      <li>
                        <span class="text-items list-ui selected-list">{{ boardcreatiaView }}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

                <p class="criteria-source mt-3">Criteria Counter</p>

                <div class="criteria-radio col-6">
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="single" [value]="1" disabled
                      formControlName="criteriaCountType" />
                    <label for="channel">Single</label><br />

                    <div class="form-group ml-1">
                      <label class="criteria-subtext" for="criteria-count">Criteria Count</label>
                      <input type="number" min="1" [value]="1" class="form-control" placeholder="Criteria Count"
                        formControlName="criteriaCount" pattern="/^-?\d+\.?\d*$/"
                        onKeyPress="if(this.value.length == 10) return false;" disabled />
                    </div>
                  </div>
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="bundled" [value]="2" disabled
                      formControlName="criteriaCountType" />
                    <label for="boards">Bundled</label><br />

                    <div class="form-group ml-1">
                      <label class="criteria-subtext" for="reward-points">Reward Points</label>
                      <input type="number" min="0" class="form-control" placeholder="Reward Points"
                        onKeyPress="if(this.value.length==10) return false;" formControlName="rewardPoints" disabled />
                    </div>
                  </div>
                </div>

                <p class="criteria-source mt-3">Non-reward Point System</p>
                <br />
                <p class="criteria-subtext mt-0">Do we need point system</p>
                <div class="criteria-radio col-3">
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="1" [value]="true" disabled
                      formControlName="nonRewardPointSystemEnabled" />
                    <label for="yes">Yes</label><br />
                  </div>
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="2" [value]="false" disabled
                      formControlName="nonRewardPointSystemEnabled" />
                    <label for="no">No</label><br />
                  </div>
                </div>

                <div class="row" *ngIf="rulesGroup.get('nonRewardPointSystemEnabled').value">
                  <div class="col-md-4">
                    <select name="cars" id="cars" disabled></select>
                  </div>
                  <div class="col-md-4">
                    <ul *ngIf="pointSytemView">
                      <li>
                        <span class="text-items list-ui mt-3">{{ pointSytemView }}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <button *ngIf="!rulesGroup.get('nonRewardPointSystemEnabled').value" class="reward-points-button">
                  Reward Points</button><br />

                <p class="criteria-source mt-0">Reward Counter</p>
                <p class="criteria-subtext mt-0">
                  Will rewards count stop after 1st achievement
                </p>
                <div class="criteria-radio col-3">
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="1" [value]="true" disabled
                      formControlName="stopAfterAchievement" />
                    <label for="yes">Once-off</label><br />
                  </div>
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="2" [value]="false" disabled
                      formControlName="stopAfterAchievement" />
                    <label for="no">Recurring</label><br />
                  </div>
                </div>

                <p class="criteria-subtext mt-0">
                  Do we need to limit the maximum rewards per staff
                </p>
                <div class="criteria-radio col-3">
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="1" [value]="true" disabled
                      formControlName="setLimitToMaxRewards" />
                    <label for="yes">Yes</label><br />
                  </div>
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="2" [value]="false" disabled
                      formControlName="setLimitToMaxRewards" />
                    <label for="no">No</label><br />
                  </div>
                </div>
                <div class="row" *ngIf="rulesGroup.get('setLimitToMaxRewards').value">
                  <div class="col-md-4">
                    <input type="number" pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==10) return false;"
                      min="0" class="form-control" placeholder="setLimitToMaxRewards" formControlName="maximumRewards"
                      required />
                  </div>
                </div>

                <p class="criteria-subtext mt-0">Badge Tiering required</p>
                <div class="criteria-radio col-3">
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="1" [value]="true" disabled
                      formControlName="badgeTiering" />
                    <label for="yes">Yes</label><br />
                  </div>
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="2" [value]="false" disabled
                      formControlName="badgeTiering" />
                    <label for="no">No</label><br />
                  </div>
                </div>
                <div class="row" *ngIf="rulesGroup.get('badgeTiering').value">
                  <div class="col-md-6">
                    <form [formGroup]="createRanks">
                      <table class="table borderless">
                        <tbody>
                          <tr>
                            <td>
                              <input type="text" class="form-control" placeholder="Badge" formControlName="name"
                                required pattern="/^-?\d+\.?\d*$/"
                                onKeyPress="if(this.value.length==50) return false;" />
                            </td>
                            <div class="ml-3">
                              <input type="number" min="0" class="form-control" placeholder="From"
                                formControlName="startRange" required pattern="/^-?\d+\.?\d*$/"
                                onKeyPress="if(this.value.length==10) return false;" />
                            </div>
                            <td class="columns">
                              <input type="number" min="0" class="form-control columns" placeholder="To"
                                formControlName="endRange" required pattern="/^-?\d+\.?\d*$/"
                                onKeyPress="if(this.value.length==10) return false;" />
                            </td>
                            <td class="columns">
                              <div class="custom-file" style="
                                  width: 130px;
                                  height: 30px;
                                  margin-left: 10px;
                                " *ngIf="!createRanks.get('icon').value">
                                <input type="file" disabled style="
                                    position: absolute;
                                    z-index: 9;
                                    visibility: visible;
                                    left: 0;
                                    top: 0;
                                    width: 80%;
                                    opacity: 0;
                                  " required id="b" />
                                <label class="custom-file-label" for="b">
                                  Upload Image
                                </label>
                              </div>
                              <div *ngIf="createRanks.get('icon').value">
                                <div class="
                                    image-with-delete-icon
                                    ml-5 ml-0
                                    badge-image
                                  " style="width: 100px">
                                  <i class="fa fa-close" title="Delete" for="4" (click)="onDeleteBadgeImage()"></i>
                                  <img [src]="validateURLImage(createRanks.get('icon').value)" id="4" alt="Image Preview"
                                    crossorigin="anonymous" />
                                </div>
                              </div>
                            </td>
                            <div class="add-button columns">
                              <button class="btn btn-submit btn-lg ml-1 pl-3 pr-3" (click)="addRank()">
                                +
                              </button>
                            </div>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                  </div>
                </div>
                <div class="row">
                  <div class="col-8" *ngIf="badgeTieringArray.length != 0">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">TIER TITLE</th>
                            <th scope="col">FROM</th>

                            <th scope="col">TO</th>
                            <th scope="col">BADGE ICON</th>

                            <th scope="col">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="
                              let field of badgeTieringArray;
                              let index = index
                            ">
                            <td>
                              <div class="mt-2">{{ field.name }}</div>
                            </td>

                            <td>
                              <div class="mt-2">{{ field.startRange }}</div>
                            </td>
                            <td>
                              <div class="mt-2">{{ field.endRange }}</div>
                            </td>

                            <td>
                              <img [src]="validateURLImage(field.icon)" width="50px" height="50px" class="mt-2" alt="icon Preview"
                                crossorigin="anonymous" />
                            </td>

                            <td>
                              <img class="ml-3 mt-2" (click)="deleteBadge(index)" src="./assets/images/cancel.png"
                                width="20px" height="20px" crossorigin="anonymous" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="rulesGroup.get('criteriaType').value === 3">
                <div class="ml-3">
                  <p class="criteria-source">Criteria Selection</p>
                </div>
                <div class="channels-section col-12 flex-direction mt-3">
                  <div class="col-3 ml-1">
                    <select class="form-control" disabled>
                      <option></option>
                    </select>
                  </div>
                  <div class="col-4">
                    <ul *ngIf="systemCretiaView">
                      <li>
                        <span class="text-items list-ui selected-list">{{ systemCretiaView }}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="row ml-0">
                  <div class="col-3 mt-2">
                    <label class="criteria-subtext" for="reward-points">Reward Points</label>
                    <input type="text" formControlName="rewardPoints" class="form-control" disabled required />
                  </div>
                  <div class="col-4" style="margin-top: 35px">
                    <ul *ngIf="rewardPoints">
                      <li>
                        <span class="text-items list-ui selected-list">{{ rewardPoints }}
                          <img class="ml-0" *ngIf="rewardPoints" src="./assets/images/cancel.png" width="20px"
                            height="20px" crossorigin="anonymous" /></span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div *ngIf="rulesGroup.get('criteriaType').value === 4">
                <div class="card my-3 w-100" *ngIf="userDetails.length != 0">
                  <div class="card-header flexi-header">
                    <h3>SELECTED INFORMATION</h3>
                  </div>

                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>BUSINESS UNIT</th>
                            <th>APPOINTMENTS</th>
                            <th>SELECTED USERS</th>

                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of userDetails; let index = index">
                            <td>
                              <ul *ngFor="let item of item.businessUnits">
                                <li>{{ item.name }}</li>
                              </ul>
                            </td>

                            <td>
                              <ul *ngFor="let item of item.appointments">
                                <li>{{ item.name }}</li>
                              </ul>
                            </td>
                            <td>
                              <ul *ngFor="let item of item.user">
                                <li>{{ item.name }}</li>
                              </ul>
                            </td>

                            <td>
                              <button class="btn btn-add p-2 m-1" [disabled]="isUserEdited"
                                (click)="editUserDetail(item, index)">
                                <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                              </button>
                              <button class="btn btn-clear p-2 m-1" [disabled]="isUserEdited"
                                (click)="deleteUserDetail(index)">
                                <i class="fa fa-trash" aria-hidden="true"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <button class="btn btn-clear" *ngIf="userDetails.length != 0" (click)="onClearAllFrom()">
                      Clear All
                    </button>
                  </div>
                </div>
              </div>

              <div *ngIf="rulesGroup.get('criteriaType').value === 5">
                <div class="channels-section col-12 flex-direction mt-3">
                  <div class="col-md-4">
                    <select name="cars" id="cars" disabled></select>
                  </div>
                  <div class="col-md-4">
                    <ul *ngIf="rulesGroup.get('selectedCustomForm.title').value">
                      <li>
                        <span class="text-items list-ui selected-list">{{
                          rulesGroup.get("selectedCustomForm.title").value
                          }}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

                <p class="criteria-source mt-3">Criteria Selection</p>

                <div class="channels-section col-12 flex-direction">
                  <div class="col-md-4">
                    <select name="cars" id="cars" disabled></select>
                  </div>
                  <div class="col-md-4">
                    <ul *ngIf="customFormcreatiaView">
                      <li>
                        <span class="text-items list-ui selected-list">{{ customFormcreatiaView }}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div>
                  <div class="flex-direction">
                    <p class="criteria-source mt-3">Criteria Categories</p>
                    <label class="switch criteria-source mt-3 ml-4">
                      <input type="checkbox"
                        [checked]="rulesGroup.get('isCriteriaCategory').value === true ? 'checked' : ''"
                        formControlName="isCriteriaCategory" disabled />
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <div class="channels-section col-12 flex-direction"
                    *ngIf="rulesGroup.get('isCriteriaCategory').value === true">
                    <div class="col-md-4">
                      <select name="cars" id="cars" disabled></select>
                    </div>
                    <div class="col-md-4">
                      <ul *ngIf="criteriaCategoryView">
                        <li>
                          <span class="text-items list-ui selected-list">{{ criteriaCategoryView }}
                            <img class="ml-3" *ngIf="criteriaCategoryView"
                              src="./assets/images/cancel.png" width="20px" height="20px"
                              crossorigin="anonymous" /></span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div *ngIf="rulesGroup.get('criteriaSourceType').value === 7">
                  <p class="criteria-source mt-3">Nominate question</p>
                  <div>
                    <span class="text-items list-ui mt-2">
                      {{nomineeQuestion}}
                    </span>

                  </div>
                </div>
                <p class="criteria-source mt-3">Select workflow status</p>
                <div>
                  <div formArrayName="fieldOptions" *ngFor="
                    let field of workFlowStatusInfo;
                    let index = index
                  ">
                    <span class="text-items list-ui mt-2" [formGroupName]="index">
                      {{field}}
                    </span>
                  </div>
                </div>

                <p class="criteria-source mt-3">Criteria Counter</p>

                <div class="criteria-radio col-6">
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="single" [value]="1" disabled
                      formControlName="criteriaCountType" />
                    <label for="channel">Single</label><br />

                    <div class="form-group ml-1">
                      <label class="criteria-subtext" for="criteria-count">Criteria Count</label>
                      <input type="number" min="1" [value]="1" disabled class="form-control"
                        placeholder="Criteria Count" formControlName="criteriaCount" pattern="/^-?\d+\.?\d*$/"
                        onKeyPress="if(this.value.length == 10) return false;" />
                    </div>
                  </div>
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="bundled" [value]="2" disabled
                      formControlName="criteriaCountType" />
                    <label for="boards">Bundled</label><br />
                  </div>
                </div>
                <div>
                  <div class="flex-direction">
                    <p class="criteria-source mt-3">Challenge Reward Type</p>
                    <label class="switch criteria-source mt-3 ml-4">
                      <input type="checkbox"
                        [checked]="rulesGroup.get('isRewardChallenge').value === true ? 'checked' : ''"
                        formControlName="isRewardChallenge"  disabled/>
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <div *ngIf="rulesGroup.get('isRewardChallenge').value === true">
                    <div class="criteria-radio col-8">
                      <div>
                        <input type="radio" class="mt-1 ml-2" id="2" [value]="false"
                          formControlName="nonRewardPointSystemEnabled" disabled />
                        <label for="no">Reward Point System</label><br />
                      </div>
                      <div>
                        <input type="radio" class="mt-1 ml-2" id="1" [value]="true"
                          formControlName="nonRewardPointSystemEnabled" disabled />
                        <label for="yes">Non-Reward Point System</label><br />
                      </div>
                    </div>
                    <div class="row pt-4 pl-4 pb-2" *ngIf="rulesGroup.get('nonRewardPointSystemEnabled').value">
                      <div class="col-md-3">
                        <select name="cars" id="cars" disabled></select>
                      </div>
                      <div class="col-md-4">
                        <ul *ngIf="pointSytemView">
                          <li>
                            <span class="text-items list-ui">{{ pointSytemView }}
                              <img class="ml-3" *ngIf="pointSytemView"
                                src="./assets/images/cancel.png" width="20px" height="20px"
                                crossorigin="anonymous" /></span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="form-group col-md-3">
                      <label class="criteria-subtext" for="reward-points">Reward Points</label>
                      <input type="number" min="0" class="form-control" placeholder="Reward Points"
                        onKeyPress="if(this.value.length==10) return false;" formControlName="rewardPoints" disabled />
                    </div>
                  </div>
                </div>

                <p class="criteria-source mt-0">Reward Counter</p>
                <p class="criteria-subtext mt-0">
                  Will rewards count stop after 1st achievement
                </p>
                <div class="criteria-radio col-3">
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="1" [value]="true" disabled
                      formControlName="stopAfterAchievement" />
                    <label for="yes">Once-off</label><br />
                  </div>
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="2" [value]="false" disabled
                      formControlName="stopAfterAchievement" />
                    <label for="no">Recurring</label><br />
                  </div>
                </div>

                <p class="criteria-subtext mt-0">
                  Do we need to limit the maximum rewards per staff
                </p>
                <div class="criteria-radio col-3">
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="1" [value]="true" disabled
                      formControlName="setLimitToMaxRewards" />
                    <label for="yes">Yes</label><br />
                  </div>
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="2" [value]="false" disabled
                      formControlName="setLimitToMaxRewards" />
                    <label for="no">No</label><br />
                  </div>
                </div>
                <div class="row" *ngIf="rulesGroup.get('setLimitToMaxRewards').value">
                  <div class="col-md-4">
                    <input type="number" pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==10) return false;"
                      min="0" class="form-control" placeholder="setLimitToMaxRewards" formControlName="maximumRewards"
                      required />
                  </div>
                </div>

                <p class="criteria-subtext mt-0">Badge Tiering required</p>
                <div class="criteria-radio col-3">
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="1" [value]="true" disabled
                      formControlName="badgeTiering" />
                    <label for="yes">Yes</label><br />
                  </div>
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="2" [value]="false" disabled
                      formControlName="badgeTiering" />
                    <label for="no">No</label><br />
                  </div>
                </div>
                <div class="row" *ngIf="rulesGroup.get('badgeTiering').value">
                  <div class="col-md-6">
                    <form [formGroup]="createRanks">
                      <table class="table borderless">
                        <tbody>
                          <tr>
                            <td>
                              <input type="text" class="form-control" placeholder="Badge" formControlName="name"
                                required pattern="/^-?\d+\.?\d*$/"
                                onKeyPress="if(this.value.length==50) return false;" />
                            </td>
                            <div class="ml-3">
                              <input type="number" min="0" class="form-control" placeholder="From"
                                formControlName="startRange" required pattern="/^-?\d+\.?\d*$/"
                                onKeyPress="if(this.value.length==10) return false;" />
                            </div>
                            <td class="columns">
                              <input type="number" min="0" class="form-control columns" placeholder="To"
                                formControlName="endRange" required pattern="/^-?\d+\.?\d*$/"
                                onKeyPress="if(this.value.length==10) return false;" />
                            </td>
                            <td>
                              <div class="custom-file" style="
                                  width: 130px;
                                  height: 30px;
                                  margin-left: 10px;
                                " *ngIf="!createRanks.get('icon').value">
                                <input type="file" disabled style="
                                    position: absolute;
                                    z-index: 9;
                                    visibility: visible;
                                    left: 0;
                                    top: 0;
                                    width: 80%;
                                    opacity: 0;
                                  " required id="b" />
                                <label class="custom-file-label" for="b">
                                  Upload Image
                                </label>
                              </div>
                              <div *ngIf="createRanks.get('icon').value">
                                <div class="
                                    image-with-delete-icon
                                    ml-5 ml-0
                                    badge-image
                                  " style="width: 100px">
                                  <i class="fa fa-close" title="Delete" for="4" (click)="onDeleteBadgeImage()"></i>
                                  <img [src]="validateURLImage(createRanks.get('icon').value)" id="4" alt="Image Preview"
                                    crossorigin="anonymous" />
                                </div>
                              </div>
                            </td>
                            <div class="add-button columns">
                              <button class="btn btn-submit btn-lg ml-1 pl-3 pr-3" (click)="addRank()">
                                +
                              </button>
                            </div>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                  </div>
                </div>
                <div class="row">
                  <div class="col-8" *ngIf="badgeTieringArray.length != 0">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">TIER TITLE</th>
                            <th scope="col">FROM</th>

                            <th scope="col">TO</th>
                            <th scope="col">BADGE ICON</th>

                            <th scope="col">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="
                              let field of badgeTieringArray;
                              let index = index
                            ">
                            <td>
                              <div class="mt-2">{{ field.name }}</div>
                            </td>

                            <td>
                              <div class="mt-2">{{ field.startRange }}</div>
                            </td>
                            <td>
                              <div class="mt-2">{{ field.endRange }}</div>
                            </td>

                            <td>
                              <img [src]="validateURLImage(field.icon)" width="50px" height="50px" class="mt-2" alt="icon Preview"
                                crossorigin="anonymous" />
                            </td>

                            <td>
                              <img class="ml-3 mt-2" (click)="deleteBadge(index)" src="./assets/images/cancel.png"
                                width="20px" height="20px" crossorigin="anonymous" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="flex-direction">
                    <p class="criteria-source mt-3">Progress Status Setup</p>
                    <label class="switch criteria-source mt-3 ml-4">
                      <input type="checkbox"
                        [checked]="rulesGroup.get('isProgressSetup').value === true ? 'checked' : ''"
                        formControlName="isProgressSetup" disabled />
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <div *ngIf="rulesGroup.get('isProgressSetup').value === true">
                    <div class="my-3">
                      <p class="criteria-subtext mt-0">Progress Type</p>
                      <div class="channels-section col-12 flex-direction">
                        <div class="col-md-4">
                          <select name="cars" id="cars" disabled></select>
                        </div>
                        <div class="col-md-4">
                          <ul *ngIf="progressTypesView">
                            <li>
                              <span class="text-items list-ui selected-list">{{ progressTypesView }}
                                <img class="ml-3" *ngIf="progressTypesView"
                                  src="./assets/images/cancel.png" width="20px" height="20px"
                                  crossorigin="anonymous" /></span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="my-3">
                      <p class="criteria-subtext mt-0">Display Selection</p>
                      <div class="channels-section col-12 flex-direction">
                        <div class="col-md-4">
                          <select name="cars" id="cars" disabled></select>
                        </div>
                        <div class="col-md-4">
                          <ul *ngIf="displaySelectionView">
                            <li>
                              <span class="text-items list-ui selected-list">{{ displaySelectionView }}
                                <img class="ml-3" *ngIf="displaySelectionView"
                                  src="./assets/images/cancel.png" width="20px" height="20px"
                                  crossorigin="anonymous" /></span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="my-3">
                      <p class="criteria-subtext mt-0">Display Description</p>
                      <div class="channels-section col-12 flex-direction">
                        <div class="col-md-4">
                          <select name="cars" id="cars" disabled></select>
                        </div>
                        <div class="col-md-4">
                          <ul *ngIf="displayDescriptionsView">
                            <li>
                              <span class="text-items list-ui selected-list">{{ displayDescriptionsView }}
                                <img class="ml-3" *ngIf="displayDescriptionsView"
                                  src="./assets/images/cancel.png" width="20px" height="20px"
                                  crossorigin="anonymous" /></span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="my-3">
                      <p class="criteria-subtext mt-0">Display Fields</p>
                      <div class="channels-section col-12 flex-direction">
                        <div class="col-md-4">
                          <select name="cars" id="cars" disabled></select>
                        </div>
                        <div class="col-md-4">
                          <ul *ngIf="criteriaCategorySelectView">
                            <li>
                              <span class="text-items list-ui selected-list">{{ criteriaCategorySelectView }}
                                <img class="ml-3" *ngIf="customFormcreatiaView"
                                  src="./assets/images/cancel.png" width="20px" height="20px"
                                  crossorigin="anonymous" /></span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="flex-direction">
                        <p class="criteria-source mt-3">Target Criteria Display</p>
                        <label class="switch criteria-source mt-3 ml-4">
                          <input type="checkbox"
                            [checked]="rulesGroup.get('isTargetCriteria').value === true ? 'checked' : ''"
                            formControlName="isTargetCriteria" disabled />
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <div *ngIf="rulesGroup.get('isTargetCriteria').value === true">
                        <div class="my-3">
                          <p class="criteria-subtext mt-0">Number of criteria count​</p>
                          <div class="channels-section col-12 flex-direction">
                            <div class="col-md-4">
                              <input type="number" min="0" class="form-control" placeholder="Criteria Count"
                                onKeyPress="if(this.value.length==10) return false;" formControlName="targetCount"  disabled/>
                            </div>
                          </div>
                        </div>
                        <div class="my-3">
                          <p class="criteria-subtext mt-0">Target description​</p>
                          <div class="channels-section col-12 flex-direction">
                            <div class="col-md-4">
                              <input type="text" class="form-control" placeholder="Description" onKeyPress=""
                                formControlName="targetCountDescription" disabled />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <ng-container *ngIf="rulesGroup.get('criteriaType').value === 6">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="text-theme font-weight-500">
                        Selected Scheme </label><br />
                      <ng-select [items]="schemeDataArr" class="custom" bindLabel="schemeName" [virtualScroll]="false"
                        appendTo="body" [clearable]="true" [multiple]="true" [closeOnSelect]="false" [searchable]="true"
                        [(ngModel)]="schemeData" [ngModelOptions]="{ standalone: true }" disabled>
                      </ng-select>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group" *ngIf="schemeData.length">
                      <label class="text-theme font-weight-500"> &nbsp; </label><br />
                      <div class="labels mb-1 mr-1" *ngFor="let item of schemeData; let i = index">
                        <span class="text">{{ item?.schemeName }} </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="text-theme font-weight-500">Criteria Selection</label><br />
                      <ng-select [items]="shiftCriteriaArr" class="custom" bindLabel="key" appendTo="body"
                        [(ngModel)]="shiftcriteriaSelect" [ngModelOptions]="{ standalone: true }" disabled>
                      </ng-select>

                    </div>
                  </div>
                  <!-- <div class="col-md-4">
                    <ul *ngIf="customFormcreatiaView">
                      <li>
                        <span class="text-items list-ui selected-list"
                          >{{ customFormcreatiaView }}
                        </span>
                      </li>
                    </ul>
                  </div> -->
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="" class="text-theme font-weight-bold">Criteria Counter</label><br />
                      <div class="form-check form-check-inline">
                        <input type="radio" class="form-check-input" id="single" [value]="1"
                          formControlName="criteriaCountType" disabled />
                        <label for="channel" class="form-check-label">Single</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input type="radio" class="form-check-input" id="bundled" [value]="2"
                          formControlName="criteriaCountType" disabled />
                        <label for="boards" class="form-check-label">Bundled</label><br />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12" *ngIf="this.rulesGroup.get('criteriaCountType').value == 2">
                    <div class="form-row">
                      <div class="form-group col-md-4">
                        <label class="font-weight-bold">Select Day</label><br />
                        <button *ngFor="let i of dayArr" class="dayButton"
                          [class.selected]="this.addedDay.indexOf(i) != -1" (click)="addDay(i)">
                          {{ i.day }}
                        </button>
                      </div>
                      <div class="form-group col-md-4">
                        <div class="form-row">
                          <div class="form-group col-md-6">
                            <label>Count</label><br />
                            <input type="text" class="form-control" placeholder="" [(ngModel)]="bundleCount"
                              [ngModelOptions]="{ standalone: true }" />
                          </div>
                          <div class="form-group col-md-6 align-self-end">
                            <button class="btn btn-danger">Clear</button>
                            <button class="btn btn-primary" (click)="addBundle()">
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div *ngIf="this.bundleArr.length">
                          <div class="labels my-1" *ngFor="let item of bundleArr; let i = index">
                            <span class="text">{{ item.day }} ({{ item.count }})
                            </span>
                            <span class="material-icons bg-danger rounded btn-close" (click)="clearBundleLabel(i)">
                              clear
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="" for="criteria-count">Criteria Count</label>
                      <input type="number" min="1" [value]="1" [attr.disabled]="
                          rulesGroup.get('criteriaCountType').value === 1
                            ? true
                            : false
                        " class="form-control" placeholder="Criteria Count" formControlName="criteriaCount"
                        pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length == 10) return false;" />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="" for="reward-points">Reward Points</label>
                      <input type="number" min="0" class="form-control" placeholder="Reward Points"
                        formControlName="rewardPoints" disabled />
                    </div>
                  </div>
                </div>

                <p class="criteria-source mt-3">Non-reward Point System</p>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="">Do we need point system</label><br />
                      <div class="form-check form-check-inline">
                        <input type="radio" class="form-check-input" id="1" [value]="true"
                          formControlName="nonRewardPointSystemEnabled" disabled />
                        <label for="yes" class="form-check-label">Yes</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input type="radio" class="form-check-input" id="2" [value]="false"
                          formControlName="nonRewardPointSystemEnabled" disabled />
                        <label for="no" class="form-check-label">No</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row" *ngIf="rulesGroup.get('nonRewardPointSystemEnabled').value">
                  <div class="col-md-4">
                    <select name="cars" id="cars" disabled></select>
                  </div>
                  <div class="col-md-4">
                    <ul *ngIf="pointSytemView">
                      <li>
                        <span class="text-items list-ui mt-3">{{ pointSytemView }}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <button *ngIf="!rulesGroup.get('nonRewardPointSystemEnabled').value" class="btn btn-danger">
                  Reward Points</button><br />

                <p class="criteria-source mt-3">Reward Counter</p>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="">Will rewards count stop after 1st achievement</label><br />
                      <div class="form-check form-check-inline">
                        <input type="radio" class="form-check-input" id="1" [value]="true"
                          formControlName="stopAfterAchievement" disabled />
                        <label for="yes" class="form-check-label">Once-off</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input type="radio" class="form-check-input" id="2" [value]="false"
                          formControlName="stopAfterAchievement" disabled />
                        <label for="no" class="form-check-label">Recurring</label><br />
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="">Do we need to limit the maximum rewards per
                        staff</label><br />
                      <div class="form-check form-check-inline">
                        <input type="radio" class="form-check-input" id="1" [value]="true"
                          formControlName="setLimitToMaxRewards" disabled />
                        <label for="yes" class="form-check-label">Yes</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input type="radio" class="form-check-input" id="2" [value]="false"
                          formControlName="setLimitToMaxRewards" disabled />
                        <label for="no" class="form-check-label">No</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4" *ngIf="rulesGroup.get('setLimitToMaxRewards').value">
                    <input type="number" pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==10) return false;"
                      min="0" class="form-control" placeholder="setLimitToMaxRewards" formControlName="maximumRewards"
                      required />
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="">Badge Tiering required</label><br />
                      <div class="form-check form-check-inline">
                        <input type="radio" class="form-check-input" id="1" [value]="true"
                          formControlName="badgeTiering" disabled />
                        <label for="yes" class="form-check-label">Yes</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input type="radio" class="form-check-input" id="2" [value]="false"
                          formControlName="badgeTiering" disabled />
                        <label for="no" class="form-check-label">No</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row" *ngIf="rulesGroup.get('badgeTiering').value">
                  <div class="col-md-6">
                    <form [formGroup]="createRanks">
                      <table class="table borderless">
                        <tbody>
                          <tr>
                            <td>
                              <input type="text" class="form-control" placeholder="Badge" formControlName="name"
                                required pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==50) return false;"
                                disabled />
                            </td>
                            <div class="ml-3">
                              <input type="number" min="0" class="form-control" placeholder="From"
                                formControlName="startRange" required pattern="/^-?\d+\.?\d*$/"
                                onKeyPress="if(this.value.length==10) return false;" />
                            </div>
                            <td class="columns">
                              <input type="number" min="0" class="form-control columns" placeholder="To"
                                formControlName="endRange" required pattern="/^-?\d+\.?\d*$/"
                                onKeyPress="if(this.value.length==10) return false;" />
                            </td>
                            <td>
                              <div class="custom-file" style="
                                  width: 130px;
                                  height: 30px;
                                  margin-left: 10px;
                                " *ngIf="!createRanks.get('icon').value">
                                <input type="file" disabled style="
                                    position: absolute;
                                    z-index: 9;
                                    visibility: visible;
                                    left: 0;
                                    top: 0;
                                    width: 80%;
                                    opacity: 0;
                                  " required id="b" />
                                <label class="custom-file-label" for="b">
                                  Upload Image
                                </label>
                                <span *ngIf="imageLoader" class="spinner-border spinner-border-sm ml-2" role="status"
                                  aria-hidden="true"></span>
                              </div>
                              <div *ngIf="createRanks.get('icon').value">
                                <div class="
                                    image-with-delete-icon
                                    ml-5 ml-0
                                    badge-image
                                  " style="width: 100px">
                                  <i class="fa fa-close" title="Delete" for="4" (click)="onDeleteBadgeImage()"></i>
                                  <img [src]="validateURLImage(createRanks.get('icon').value)" id="4" alt="Image Preview"
                                    crossorigin="anonymous" />
                                </div>
                              </div>
                            </td>
                            <div class="add-button columns">
                              <button class="btn btn-submit btn-lg ml-1 pl-3 pr-3" (click)="addRank()">
                                +
                              </button>
                            </div>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                  </div>
                </div>
                <div class="row">
                  <div class="col-8" *ngIf="badgeTieringArray.length != 0">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">TIER TITLE</th>
                            <th scope="col">FROM</th>

                            <th scope="col">TO</th>
                            <th scope="col">BADGE ICON</th>

                            <th scope="col">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="
                              let field of badgeTieringArray;
                              let index = index
                            ">
                            <td>
                              <div class="mt-2">{{ field.name }}</div>
                            </td>

                            <td>
                              <div class="mt-2">{{ field.startRange }}</div>
                            </td>
                            <td>
                              <div class="mt-2">{{ field.endRange }}</div>
                            </td>

                            <td>
                              <img [src]="validateURLImage(field.icon)" width="50px" height="50px" class="mt-2" alt="icon Preview"
                                crossorigin="anonymous" />
                            </td>

                            <td>
                              <img class="ml-3 mt-2" (click)="deleteBadge(index)" src="./assets/images/cancel.png"
                                width="20px" height="20px" crossorigin="anonymous" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </ng-container>

              <div>
                <button class="btn btn-warning mt-4" (click)="stepper.previous()">
                  Prev
                </button>
                <button class="btn btn-danger ml-1 mt-4" (click)="stepper.next()">
                  Next
                </button>
              </div>
            </div>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="adminGroup">
        <form [formGroup]="adminGroup">
          <ng-template matStepLabel>ADMINSTRATOR</ng-template>
          <div class="card my-3 w-100">
            <div class="card-header flexi-header">
              <h3>ADMINSTRATOR</h3>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="font-weight-bold">Admin </label><br />
                    <ng-select [items]="userPTable.data" (change)="addLoginFields($event)" class="custom"
                      bindLabel="name" placeholder="Admin" appendTo="body" [multiple]="false" [searchable]="true"
                      [clearable]="true" [loading]="CustomFieldDropDownLoading" (search)="onSeachAdmin($event)"
                      (scrollToEnd)="loadMoreAdminItems($event)" [(ngModel)]="adminSelect"
                      [ngModelOptions]="{ standalone: true }" [virtualScroll]="true"
                      (clear)="onClearAdminDropdown($event)">
                    </ng-select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>&nbsp;</label><br />
                    <div class="labels mb-1 mr-1" formArrayName="administrators" *ngFor="
                        let field of AdminFieldsControl.controls;
                        let index = index
                      ">
                      <span [formGroupName]="index" class="d-flex">{{ field.get("string").value }}

                        <span class="
                            material-icons
                            bg-danger
                            rounded
                            btn-close
                            ml-2
                          " (click)="deleteAdmin(index)">
                          clear
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex-direction mt-2">
                <div>
                  <button class="btn btn-danger" (click)="stepper.previous()">
                    Back
                  </button>
                </div>
                <div>
                  <button class="btn btn-success ml-2" [disabled]="isLoading" (click)="
                      onSubmitEvents(this.rulesGroup.get('criteriaType').value)
                    ">
                    {{ isUserEdited ? "Update" : "Submit" }}
                  </button>
                </div>
                <!-- <div>
                  <button class="btn btn-save ml-2" (click)="saveASDraft(this.rulesGroup.get('criteriaType').value)">
                    Save as Draft
                  </button>
                </div> -->
              </div>
            </div>
          </div>
          <div></div>
        </form>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div>
