<div class="d-flex flex-column">
  <ul
    ngbNav
    [(activeId)]="activeId"
    #companyNav="ngbNav"
    class="nav-tabs rounded mb-3"
  >
    <li [ngbNavItem]="1">
      <a ngbNavLink>UNREAD</a>
      <ng-template ngbNavContent>
        <app-unread></app-unread>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink> ACKNOWLEDGED</a>
      <ng-template ngbNavContent>
        <app-acknowledged></app-acknowledged>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="companyNav"></div>
</div>
