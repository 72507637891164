<div class="d-flex login-main flex-sm-row flex-column">
  <div class="flex-fill min-w50 d-sm-block d-none">
    <div class="mw-100 bg-banner"></div>
  </div>
  <div class="flex-fill bg">
    <div class="d-flex align-items-center h-100 min-h35">
      <div class="d-flex justify-content-center min-w100">
        <div class="login-body min-w50 card">
          <div class="card-body">
            <div class="d-flex justify-content-center companyLogo mb-5">
              <img [src]="companyLogo" class="w-50 img-fluid" crossorigin="anonymous" />
            </div>
            <form [formGroup]="userLoginForm" (keydown)="pressEnter($event)">
              <div class="form-group" *ngIf="companyName == 'xforce'">
                <label class="font-weight-500">Company Name</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="companyId"
                  placeholder="Enter Company Name"
                  [ngClass]="{ 'is-invalid': submitted && f.companyId.errors }"
                />
                <div
                  *ngIf="submitted && f.companyId.errors"
                  class="invalid-feedback"
                >
                  <small *ngIf="f.companyId.errors.required">
                    Company Name is required
                  </small>
                </div>
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1" class="font-weight-500"
                  >Staff ID</label
                >
                <input
                  type="text"
                  class="form-control"
                  formControlName="staffId"
                  aria-describedby="emailHelp"
                  placeholder="Enter Staff ID"
                  [ngClass]="{ 'is-invalid': submitted && f.staffId.errors }"
                />
                <div
                  *ngIf="submitted && f.staffId.errors"
                  class="invalid-feedback"
                >
                  <small *ngIf="f.staffId.errors.required">
                    Staff ID is required
                  </small>
                </div>
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1" class="font-weight-500"
                  >Password</label
                >

                <input
                  [type]="passwordvisible ? 'text' : 'password'"
                  class="form-control password-field"
                  placeholder="Password"
                  formControlName="password"
                  required
                  [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                />
                <div class="eye-slash" *ngIf="!passwordvisible">
                  <i
                    class="fa fa-eye-slash fa-lg"
                    aria-hidden="true"
                    (click)="passwordVisible()"
                  ></i>
                </div>
                <div class="eye-slash" *ngIf="passwordvisible">
                  <i
                    class="fa fa-eye fa-lg"
                    aria-hidden="true"
                    (click)="passwordVisible()"
                  ></i>
                </div>
                <div
                  *ngIf="submitted && f.password.errors"
                  class="invalid-feedback"
                >
                  <small *ngIf="f.password.errors.required">
                    Password is required
                  </small>
                </div>
                <a
                  (click)="navigateToForgetPassword()"
                  class="pull-right link mb-3 mt-1"
                  >Forgot password ?</a
                >
              </div>

              <button
                class="btn btn-block bg-theme mb-3"
                [disabled]="isLoading"
                (click)="onLogin()"
              >
                SIGN IN
                <span
                  *ngIf="isLoading"
                  class="spinner-border spinner-border-sm ml-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>

              <div class="d-flex justify-content-between">
                <a class="link" (click)="navigateToTerms()"
                  >Terms and Conditions*</a
                >
                <a class="link" (click)="navigateToSupport()">Support</a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
