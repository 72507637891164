import { HTTP_INTERCEPTORS, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';


import { AuthService } from '../../users/service/auth/auth.service';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  Company;
  constructor( private authService: AuthService,   private router: Router,) {
    this.Company = localStorage.getItem('flexicompanyName');
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
    let authReq = req;
    if (req.url.includes('amazonaws.com')) {
      return next.handle(req); // Skip adding auth token for file download requests
    }
    const token = localStorage.getItem('flexiusersToken');
    if (token != null && !this.router.url.includes('/admin')) {
      authReq = this.addTokenHeader(req, token);
    }
    return next.handle(authReq).pipe(
      catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 403 && !error.url?.includes('amazonaws.com')) {
        return this.handle403Error(authReq, next);
      }

      return throwError(error);
    }));
  }

  private handle403Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;

      this.refreshTokenSubject.next(null);
      const token = JSON.parse(localStorage.getItem('loggedInUserAllTokens'));
      const payload = {
        refreshToken: token.refreshToken?.split(" ")[1],
      };

      if (token)
        return this.authService.resetTokens(payload).pipe(
          switchMap((res: any) => {
            this.isRefreshing = false;

              const newTokens = {
                accessToken: res.data.accessToken,
                refreshToken: res.data.refreshToken,
              };
              localStorage.setItem('loggedInUserAllTokens', JSON.stringify(newTokens));
              localStorage.setItem('flexiusersToken', newTokens.accessToken);
            this.refreshTokenSubject.next(newTokens.accessToken);

            return next.handle(this.addTokenHeader(request, newTokens.accessToken));

          }),
          catchError((err) => {
            this.isRefreshing = false;

            this.logoutAndRedirect(err);
            return throwError(err);
          })
        );
    }

    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }


  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, token) });
  }

  private logoutAndRedirect(err): Observable<HttpEvent<any>> {
    this.authService.logout();
    this.router.navigate(['/users/login/', this.Company]);
    return throwError(err);
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];
