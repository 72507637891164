import { Component, EventEmitter, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, of } from 'rxjs';
import { map, debounceTime, distinctUntilChanged, mergeMap, delay, finalize } from 'rxjs/operators';
import { SortableHeader, SortEvent } from 'src/app/shared/directive/sortable.directive';
import { GamificationService } from 'src/app/users/service/gamification/gamification.service';
import * as moment from 'moment';
import { tableParam } from 'src/app/core/model/table-param.model';
import { TableService } from 'src/app/core/service/table.service';
@Component({
  selector: 'challenges-overview',
  templateUrl: './challenges-overview.component.html',
  styleUrls: ['./challenges-overview.component.scss']
})
export class ChallengesOverviewComponent implements OnInit {
  isUserEdited: boolean;
  userId: any = null
  usersPTable;
  todayDate: string;
  isTableLoading=false;
  locAccTableColumn=['createdAt','title','publishEnd','status','_id'];
  viewtableQueryParams={...tableParam, start: 0};
  public userssearchInput = new Subject<KeyboardEvent>();
  manageUsers: boolean = false;
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;

  onSortUsers({ column, direction }: SortEvent, field) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.usersPTable.sortBy = column;
    this.usersPTable.sortWith = direction;
    this.loadUsers();
  }

  getCurrentDate(){
    const currentDate = new Date();
    this.todayDate = moment(currentDate).utc().format();
  }

  onPageChangeUsers(page: number) {
    this.usersPTable.page = page;
    this.viewtableQueryParams.start = +page > 1 ? (page - 1) * this.usersPTable.limit : 0;
    this.loadUsers();
  }
  onLimitChangeUsers() {
    this.viewtableQueryParams.start = 0;
    this.loadUsers();
  }

  private usersSearchSubscription: Subscription;
  @Output() EditEvent = new EventEmitter<string>();
  @Output() ViewEvent = new EventEmitter<string>();
  @Output() isManage = new EventEmitter<object>();

  constructor(
    private gamificationService: GamificationService,
    public toastService: ToastrService,
    public tableService:TableService

  ) {
    this.usersPTable = {
      sortBy: 0,
      sortWith: 'desc',
      data: [],
      page: 1,
      limit: 10,
      search: '',

    };

    this.usersSearchSubscription = this.userssearchInput
      .pipe(
        map((event) => {
          const target = event.target as HTMLTextAreaElement;
          return target.value;
        }),
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap((search) => of(search).pipe(delay(500)))
      )
      .subscribe((input) => {
        this.usersPTable.page = 1;
        this.viewtableQueryParams.start = 0;
        this.viewtableQueryParams.search.value = input;
        this.loadUsers();
      });
  }

  ngOnInit(): void {
    this.loadUsers();
    this.getCurrentDate()
  }
  onEditUser(data: any) {
    this.EditEvent.emit(data);
  }
  onViewUser(data: any) {
    this.ViewEvent.emit(data);
  }

  onManage(id: string, pointSystem, title, isTeam) {
    this.isManage.emit({ id, pointSystem, title, isTeam })
  }

  loadUsers() {
    let tableCol:any = this.tableService.initializeTableParamsColums(this.locAccTableColumn, [0,1,2,3],[0,1,2,3,4]) || [];
    this.viewtableQueryParams.columns=tableCol;
    this.viewtableQueryParams.draw++;
    this.viewtableQueryParams.length=this.usersPTable.limit;
    this.isTableLoading = true;
    this.viewtableQueryParams.search.value = this.usersPTable.search;
    this.viewtableQueryParams.order = [{column: this.usersPTable.sortBy, dir:this.usersPTable.sortWith}]
    const { data, loading, total, ...rest } = this.usersPTable;
    const subscribe = this.gamificationService
      .getChallengesNew(this.viewtableQueryParams)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          this.isTableLoading = false;
          this.usersPTable.data = response?.data;
          this.usersPTable.total = response?.recordsTotal;
        },
        (error) => {
          this.isTableLoading = false;
          this.usersPTable.data = [];
          this.usersPTable.total = 0;
          this.toastService.error('No list found');
        }
      );
  }

  ngOnDestroy(): void {
    this.usersSearchSubscription && this.usersSearchSubscription.unsubscribe();
  }

  async addCategories(value) {

  }

  overViewEvent(value) {
    this.isUserEdited = false
    this.userId = null
  }

  onManageUser(id) {
    this.manageUsers = true;

  }
}
