import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';
import { take, tap, map, switchMap, finalize } from 'rxjs/operators';
import { AuthService } from '../../../service/auth/auth.service';
import { LoginForm } from '../../../../core/model/definition.model';
import { companyNameResponse } from '../../../../core/model/users.model';
import {
  CustomValidators,
  MustMatch,
} from '../../../../shared/custom-validator/custom-validator';
import { ToastrService } from 'ngx-toastr';
import { CompanySetupService } from 'src/app/users/service/company-setup/company-setup.service';
import { HttpErrorResponse } from '@angular/common/http';

import * as recheck from 'recheck';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
})
export class ForgetPasswordComponent implements OnInit {
  public login: LoginForm;
  public companyName;
  public isLoading$: boolean;
  public forgetPasswordForm: FormGroup;
  public otpForm: FormGroup;
  public otpVerifyForm: FormGroup;
  public passwordRestForm: FormGroup;
  public submitted1: boolean = false;
  public submitted2: boolean = false;
  public submitted3: boolean = false;
  public passwordvisible: boolean = false;
  public passwordvisibleAlt: boolean = false;
  public restPasswordState: string = 'initRest';
  validators = [];
  otpToken: string;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;

  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  isLoadingOnRequestRestPassword: Boolean = false;
  isLoadingOtpSubmit: Boolean = false;
  isLoadingResetPassword: Boolean = false;
  otpSentFor: number;
  xforceCompanyId: string;

  constructor(
    private router: Router,
    private auth: AuthService,
    public toastService: ToastrService,
    private companyService: CompanySetupService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,

  ) {}

  ngOnInit(): void {
    this.initForm();
    this.getPasswordSetting();

    this.route.params
      .pipe(
        take(1),
        tap((value) => (this.companyName = value.name)),
        map((v) => v.name),
        switchMap((name) => this.auth.fetchCompanyId(name))
      )
      .subscribe((res: companyNameResponse) => {
        this.forgetPasswordForm.patchValue({
          companyId: res.data._id,
        });
      });

    const urlParam = this.route.params
      .pipe(
        take(1),
        map((v) => v.name)
      )
      .subscribe((val) => {
        this.companyName = val;
        this.getCompanyId();
      });
  }

  getCompanyId() {
    if (this.companyName != 'xforce' && this.companyName != undefined) {
      const urlCompanyName = this.auth
        .fetchCompanyId(this.companyName)
        .pipe(
          finalize(() => {
            urlCompanyName.unsubscribe();
          })
        )
        .subscribe(
          (res: companyNameResponse) => {
            this.forgetPasswordForm.patchValue({
              companyId: res.data._id,
            });
          },
          (error: HttpErrorResponse) => {}
        );
    } else {
      if (this.f.companyId.value) {
        const urlxforce = this.auth
          .fetchCompanyId(this.forgetPasswordForm.controls.companyId.value)
          .pipe(
            finalize(() => {
              urlxforce.unsubscribe();
            })
          )
          .subscribe(
            (res: companyNameResponse) => {
              this.xforceCompanyId = res.data._id;
            },
            (error: HttpErrorResponse) => {}
          );
      }
    }
  }

  passwordVisible() {
    this.passwordvisible = !this.passwordvisible;
  }

  confirmPasswordVisible() {
    this.passwordvisibleAlt = !this.passwordvisibleAlt;
  }

  private initForm() {
    this.forgetPasswordForm = this.formBuilder.group({
      staffId: ['', [Validators.required]],
      email: ['', [Validators.required]],
      companyId: ['', [Validators.required]],
      mobile: [null, [Validators.required]],
    });
    this.otpForm = this.formBuilder.group({
      token: ['', [Validators.required]],
      companyId: ['', [Validators.required]],
      staffId: ['', [Validators.required]],
    });
   /* this.passwordRestForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      token: ['', Validators.required],
    });*/
    this.otpVerifyForm = this.formBuilder.group({
      token: ['', [Validators.required]],
      otp: ['', [Validators.required]],
    });
  }

  getPasswordSetting() {
    const sub = this.companyService
      .getPasswordSetting()
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe(
        (res) => {
          this.getValidatorFnArray(res.data?.pageSettingData?.pwdSettings);
          this.otpSentFor = res?.data?.pageSettingData?.pwdSettings?.otpSentFor;
         /* if (res.data.pwdSettings.otpSentFor === 2) {
            this.f.mobile.setValidators([Validators.required]);
            this.f.date.updateValueAndValidity();
          } else {
            this.f.email.setValidators([Validators.required]);

            this.f.time.updateValueAndValidity();
          }*/
        },
        (error) => {
          console.log(error);
        }
      );
  }
  resetPasswordForm() {
     this.passwordRestForm = this.formBuilder.group(
       {
         password: ['', [...this.validators, Validators.required]],
         confirmPassword: ['', Validators.required],
         token: ['', Validators.required],
       },
       {
         validator: MustMatch('password', 'confirmPassword'),
       }
     );
  }

  get phone() {
    return this.forgetPasswordForm.get('mobile');
  }

  get email() {
    return this.forgetPasswordForm.get('email');
  }

  get f() {
    return this.forgetPasswordForm.controls;
  }
  get otp() {
    return this.otpForm.controls;
  }
  get restPassword() {
    return this.passwordRestForm.controls;
  }

  async getValidatorFnArray(setting?) {
    const validators = [];

    if (setting?.charTypes?.lowerCase) {
      validators.push(
        CustomValidators.patternValidator(/[a-z]/, {
          hasSmallCase: true,
        })
      );
    }
    if (setting?.charTypes?.specialChar) {
      validators.push(
        CustomValidators.patternValidator(
          /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
          {
            hasSpecialCharacters: true,
          }
        )
      );
    }

    if (setting?.charTypes?.upperCase) {
      validators.push(
        CustomValidators.patternValidator(/[A-Z]/, {
          hasCapitalCase: true,
        })
      );
    }

    if (setting?.charTypes?.numbers) {
      validators.push(
        CustomValidators.patternValidator(/\d/, {
          hasNumber: true,
        })
      );
    }

    if (setting?.charLength) {
      const len = setting.charLength;
      let test = `^.{${len},22}`;
      let recheckResp = await recheck.check(test, "")
      if (recheckResp.status === 'safe') {
        let regex = new RegExp(test);
        validators.push(
          CustomValidators.patternValidator(
            regex,
            {
              minlength: true,
              length: setting.charLength,
            }
          )
        );
      }
      this.validators.push(...validators);
      this.resetPasswordForm();
    }
  }

  navigateToLogin() {
    this.router.navigate(['/users/login/', this.companyName]);
  }

  submitForgotPassword(){

  }
  onRequestRestPassword() {
    // if (!this.forgetPasswordForm.valid) {
    //   this.submitted1 = true;
    //   console.log('Hello')
    //   return;
    // }
    if (this.otpSentFor === 1) {
      const data = {
        // companyId: this.forgetPasswordForm.value.companyId,
        staffId: this.forgetPasswordForm.value.staffId,
        email: this.forgetPasswordForm.value.email,
        countryCode: '',
        // primaryMobileNumber: '',
        pathName:"sats"
      };
      const sub = this.auth
        .requestForgetPassword(data)
        .pipe(
          finalize(() => {
            sub.unsubscribe();
          })
        )
        .subscribe(
          (res) => {
            this.isLoadingOnRequestRestPassword = false;
            this.toastService.success(res.data.message);
            // this.forgetPasswordForm.reset();

            this.otpVerifyForm.patchValue({
              token: res?.data?.token,
            });
            this.otpToken=res?.data?.token;
            this.restPasswordState = 'otpRest';
          },
          (error) => {
            console.log(error)
            this.isLoadingOnRequestRestPassword = false;
          }
        );
    } else if (this.otpSentFor === 2) {
      this.isLoadingOnRequestRestPassword = true;
      const { number } = this.forgetPasswordForm.get('mobile').value;

      const data = {
        // companyId: this.forgetPasswordForm.value.companyId,
        staffId: this.forgetPasswordForm.value.staffId,
        // email: '',
        countryCode: this.forgetPasswordForm.value.mobile.dialCode,
        primaryMobileNumber: parseInt(
          number.replace(/[^0-9]/g, ''),
          10
        ).toString(),
        pathName:"sats"
      };

      const sub = this.auth
        .requestForgetPassword(data)
        .pipe(
          finalize(() => {
            sub.unsubscribe();
          })
        )
        .subscribe(
          (res) => {
            this.isLoadingOnRequestRestPassword = false;
            this.toastService.success(res.data.message);
            // this.forgetPasswordForm.reset();
            this.otpToken=res?.data?.token;
            this.otpVerifyForm.patchValue({
              token: res?.data?.token,
            });
            this.restPasswordState = 'otpRest';
          },
          (error) => {
            this.toastService.error(error?.error?.message)
            this.isLoadingOnRequestRestPassword = false;
          }
        );
    }
  }

  onSubmitOtp() {
    if (!this.otpVerifyForm.valid) {
      this.toastService.error('Please Enter the OTP');
      return;
    }
    this.isLoadingOtpSubmit = true;
    let data={token:this.otpVerifyForm.get('token').value, otp:this.otpVerifyForm.get('otp').value, forgotPassword:true}
    const sub = this.auth
      .verifyOTP(data)
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe(
        (res) => {
          this.isLoadingOtpSubmit = false;
          this.restPasswordState = 'passwordRest';
          // this.otpToken = res.data?.token;
          this.toastService.success('OTP verified successfully');
          // this.getValidatorFnArray(res.data.pwdSettings.pwdSettings);
        },
        (error) => {
          this.isLoadingOtpSubmit = false;
          this.toastService.error(error?.error?.message);
        }
      );
  }

  goBack() {
    this.restPasswordState = 'initRest';
  }
  onRestPassword() {
    this.passwordRestForm.patchValue({
      token: this.otpToken,
    });
    if (!this.passwordRestForm.valid) {
      this.submitted3 = true;
      return;
    }

    let data = {
      password: this.passwordRestForm.get('confirmPassword').value,
      token: this.passwordRestForm.value.token,
    };
    this.isLoadingResetPassword = true;
    const sub = this.auth
      .restPassword(data)
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe(
        (res) => {
          this.isLoadingResetPassword = false;
          this.toastService.success('Password Updated successfully');
          this.passwordRestForm.patchValue({
            token: res.data.token,
          });
          this.auth.logout();
        },
        (error) => {
          this.isLoadingResetPassword = false;
          this.toastService.error('Cannot use last 15 used passwords, please use a new password');
        }
      );
  }
}
