import { ToastrService } from 'ngx-toastr';
import { UsersService } from './../../../service/user-service/users.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  Component,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  finalize,
  map,
  max,
  mergeMap,
} from 'rxjs/operators';
import {
  SortableHeader,
  SortEvent,
} from 'src/app/shared/directive/sortable.directive';
import { PaginatedTable } from 'src/app/core/model/pagination.model';

import { Subscription, Subject, of } from 'rxjs';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BussinessUnitService } from 'src/app/users/service/bussiness-unit/bussiness-unit.service';
import { CustomFieldService } from 'src/app/users/service/custom-field/custom-field.service';
import { IMonthCalendarConfig } from 'ng2-date-picker';
import { ChannelService } from 'src/app/users/service/channel/channel.service';
import { ThrowStmt } from '@angular/compiler';
import { getAssignedUsers } from 'src/app/core/utils/helper';
import { NgSelectComponent } from '@ng-select/ng-select';
@Component({
  selector: 'app-channel-setup',
  templateUrl: './channel-setup.component.html',
  styleUrls: ['./channel-setup.component.scss'],
})
export class ChannelSetupComponent implements OnInit {
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  categoryDropDownLoading: boolean = false;
  planBuUnitDropDownLoading: boolean = false;
  apppoinmentDropDownLoading: boolean = false;
  CustomFieldDropDownLoading: boolean = false;
  businessUnit: boolean = true;
  categoryDropDown;
  bussinesUnitplanPTable: { sortWith: string; sortBy: string; data: any[]; page: number; limit: number; search: string; filter: { type: string; }; };
  UpdateduserDetails: any[] = [];
;
  activeId = 1;
  addedCustomfield;
  buDropDownToClear = null;
  apoinemntDropDownToClear = null;
  customFeildDropDownToClear = null;
  adminDropDownToClear = null;
  authorDropDownToClear = null;
  userDetailsEditIndex: number;
  isUserDetailsEdited: boolean = false;
  isChannelEdited: boolean = false;
  channelEditedId: string;
  timeElapsed = Date.now();
  today = new Date(this.timeElapsed);
  config: IMonthCalendarConfig = {
    format: 'MMM, YYYY',
    returnedValueType: 3,
    max: new Date(Date.now()).toUTCString(),
  };
  userDetails: any[] = [];
  userDropDown: any[] = [];

  categoryPTable;
  isUpdateLoading:boolean = false
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  private categorySearchSubscription: Subscription;
  public categorySearchInput = new Subject<KeyboardEvent>();
  @ViewChild('AddSubSection', { static: true })
  AddSubSection: TemplateRef<any>;
  channelCreateForm: FormGroup;
  userDetailsForm: FormGroup;
  createCategoryForm: FormGroup;
  editCategoryId: string;
  isCategoryEdited: boolean = false;
  planBuUnitDropDown: any[];
  appoinmentDropDown: any[];
  fieldNeedsToUpdate: any[];
  appoinmentPTable: { sortBy: string; sortWith: string; data: any[]; page: number; total: number; limit: number; search: string; };
  CustomFieldPTable: { sortBy: string; sortWith: string; data: any[]; page: number; total: number; limit: number; search: string; };
  userPTable: { sortBy: string; sortWith: string; data: any[]; page: number; limit: number; search: string; };
  authorLoading: boolean;
  adminPTable: { sortWith: string; sortBy: string; data: any[]; page: number; limit: number; search: string; };
  adminLoading: boolean;
  adminDropDown: any[];
  isAddButtonActive: boolean = false;
  onSortCategory({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.categoryPTable.sort = { sortWith: column, sortBy: direction as any };
    this.loadCategory();
  }

  onPageChangeCtegory(page: number) {
    this.categoryPTable.page = page;
    // this.loadCategory();
  }
  onLimitChangeCategory() {
    // this.loadCategory();
  }
  constructor(
    private modalService: NgbModal,
    private toastService: ToastrService,
    private userService: UsersService,
    private formBuilder: FormBuilder,
    private buUnit: BussinessUnitService,
    private customFieldService: CustomFieldService,
    private channelService: ChannelService
  ) {
    this.categoryPTable = {
      sortWith: 'createdAt',
      sortBy: 'desc',
      data: [],
      page: 1,
      limit: 10,

      search: '',
    };
    this.bussinesUnitplanPTable = {
      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,

      limit: 8,

      search: '',
      filter: {
        type: 'plan',
      },
    };
    this.appoinmentPTable = {

      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,
      total: 0,
      limit: 10,

      search: '',

    };
    this.CustomFieldPTable = {

      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,
      total: 0,
      limit: 10,

      search: '',

    };
    this.userPTable = {

      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,

      limit: 10,

      search: '',

    };
    this.adminPTable = {

      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,

      limit: 10,

      search: '',

    };
    this.categorySearchSubscription = this.categorySearchInput
      .pipe(
        map((event) => {
          const target = event.target as HTMLTextAreaElement;
          return target.value;
        }),
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap((search) => of(search).pipe(delay(500)))
      )
      .subscribe((input) => {
        this.categoryPTable.page = 1;
        this.loadCategory();
      });
  }

  hideBusinessUnit(value){
    this.businessUnit = value;
  }

  showCreateButton(){
    this.isAddButtonActive = true
  }

  hideCreateButton(){
    this.isAddButtonActive = false
  }

  initForm() {
    this.channelCreateForm = this.formBuilder.group({
      name: ['', Validators.required],
      category: this.formBuilder.array([], [Validators.required]),
      status: [1, Validators.required],
    });
  }

  clearForm(){
    this.channelCreateForm.patchValue({
      name: '',
      status: ''
    });
    this.UpdateduserDetails = []
    this.userDetails = []
    this.isAddButtonActive = false
  }

  categoryForm() {
    this.createCategoryForm = this.formBuilder.group({
      name: ['', Validators.required],
      status: [1, Validators.required],
    });
  }
  ngOnInit() {
    this.initForm();
    this.categoryForm();

    this.loadCategory();
  }

  onCancelEdit(){
    this.activeId = 1;
    this.isChannelEdited = false
    this.channelEditedId = null
    this.initForm()
  }
  ///  category
  addCategory(value) {
    this.categoryForm();
    this.loadCategory();
    this.modalService.open(this.AddSubSection, {
      windowClass: 'modal-ui-fix right-align',
    });

    // this.loadAppoinment();
  }

  get CategorytControl(): FormArray {
    return this.channelCreateForm.get('category') as FormArray;
  }

  async addCategories(value) {
    if (value) {

      const Control = this.CategorytControl;
      const index = await Control.value.findIndex((x) => x._id === value._id);

      if (index > -1) {
        this.toastService.error(`${value.name} is already added`);
        this.ngSelectComponent.clearModel();
      } else {
        let field = this.formBuilder.group({
          name: [value.name, Validators.compose([Validators.required])],
          _id: [value._id, Validators.compose([Validators.required])],
        });
        Control.push(field);
        this.ngSelectComponent.clearModel();
      }
    }
  }

  oneditCategory(category) {
    this.editCategoryId = category._id;
    this.isCategoryEdited = true;
    this.createCategoryForm.patchValue({
      name: category.name,
      status: category.status,
    });
  }

  deleteCategories(index) {
    const Control = this.CategorytControl;

    Control.removeAt(index);
  }
  UpdateCatagory() {
    const subscribe = this.channelService
      .updateCategory(this.createCategoryForm.value, this.editCategoryId)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          this.isCategoryEdited = false;
          this.categoryForm();
          this.loadCategory();
          this.toastService.success('Category Updated');
        },
        (error) => {
          this.toastService.error('Something Went Wrong');
        }
      );
  }
  createCategory() {
    const subscribe = this.channelService
      .createCategory(this.createCategoryForm.value)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          // this.loadCategory();
          this.categoryForm();
          this.toastService.success('Category Created', '', {
            timeOut: 3000,
            positionClass: 'toast-top-right'
          });
          this.modalService.dismissAll()
        }
      );
  }

  onDeleteCategory(id: string) {
    const subscribe = this.channelService
      .deleteCategory(id)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          this.loadCategory();
          this.toastService.success('Category Deleted');
        },
        (error) => {
          this.toastService.error('Something Went Wrong');
        }
      );
  }
  onSeachCategory(value) {
    this.categoryPTable.limit = 10;
    this.categoryPTable.search = value.term;

    this.loadCategory();
  }

  loadMoreCategoryItems(value) {
    this.categoryPTable.limit = this.categoryPTable.limit + 10;
    this.loadCategory();
  }

  onClearCategoryDropdown(value) {
    if (value === undefined) {
      // this.createUserForm.patchValue({
      //   appointmentId: null,
      // });
      this.categoryPTable.search = '';
      this.loadCategory();

      this.categoryDropDown = [];
      this.categoryPTable.data = [];
    }
  }

  loadCategory() {
    this.categoryDropDownLoading = true;
    const { data, total, ...rest } = this.categoryPTable;
    const subscribe = this.channelService
      .getAllCategory(rest)
      .pipe(
        finalize(() => {
          this.categoryDropDownLoading = false;
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const { count, data } = response.data;
          this.categoryPTable.data = data;
          this.categoryDropDown = data;
          this.categoryPTable.total = count;
        },
        (error) => {
          this.categoryPTable.data = [];
          this.categoryPTable.total = 0;
          this.toastService.error('No list found');
        }
      );
  }



  editChannel(id) {
    this.ngOnInit();
    if (id) {
      const sub = this.channelService
        .getOneChannel(id)
        .pipe(finalize(() => sub.unsubscribe()))
        .subscribe(
          (res) => {
            this.activeId = 2;
            this.userDetails = res.data.assignUsers;
            this.isChannelEdited = true;
            this.channelCreateForm.patchValue({
              name: res.data.name,
              status:res.data.status
            });
            const control = this.CategorytControl;
            res.data.category.forEach((element) => {
              let field = this.formBuilder.group({
                name: [element.name, Validators.compose([Validators.required])],
                _id: [element._id, Validators.compose([Validators.required])],
              });
              control.push(field);
            });
            this.channelEditedId = res.data._id;

            this.userDetails = res.data.assignUsers;
            this.UpdateduserDetails = getAssignedUsers(res.data.assignUsers)

          },
          (error) => {


          }
        );
    } else {
      this.isChannelEdited = false;
      this.activeId = 1;
    }
  }
  updateUserDetails(userDetails){
      this.UpdateduserDetails = userDetails
  }
  onSubmit() {
    if (!this.channelCreateForm.valid) {
      this.toastService.error('Please fill the form')
      return
    }
    this.isUpdateLoading = true


    const data = {
      ...this.channelCreateForm.value,
      assignUsers: this.UpdateduserDetails,
    };
    if (this.isChannelEdited) {
      const sub = this.channelService
        .updateChannel(data, this.channelEditedId)
        .pipe(finalize(() => sub.unsubscribe()))
        .subscribe(
          (res) => {
            this.toastService.success(`Channel Updated Successfully`);
            this.activeId = 1;
            // this.onClearAllFrom();
            this.isUpdateLoading = false
          },
          (error) => {
            this.isUpdateLoading = false
          }
        );
    } else {
      const sub = this.channelService
        .createChannels(data)
        .pipe(finalize(() => sub.unsubscribe()))
        .subscribe(
          (res) => {
            this.toastService.success(`Channel Created Successfully`);
            this.activeId = 1;
            // this.onClearAllFrom();
            this.isUpdateLoading = false
          },
          (error) => {
            this.isUpdateLoading = false
          }
        );
    }


  }

}
