import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
// import { ScheduleComponent } from './schedule/schedule.component';
// import { PushedComponent } from './pushed/pushed.component';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BussinessUnitService } from '../../service/bussiness-unit/bussiness-unit.service';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  notiData: any = null;
  selectedOption: string = 'create';
  navBar = 'CREATE';
  isNotificationEndabled: Boolean = false;
  viewNotification: FormGroup;
  bussinesUnitplanPTable: {
    sortBy: string;
    sortWith: string;
    data: any[];
    page: number;
    limit: number;
    search: string;
    filter: { type: string };
  };
  planBuUnitDropDown = [];

  planBuUnitDropDownLoading: boolean;
  isLoading: boolean = false;
  active = 1;
  @ViewChild('sidemenu') sidemenu: ElementRef;

  // @ViewChild(SidebarComponent) sideBar: SidebarComponent;
  buId: string;
  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private renderer: Renderer2,
    private buUnit: BussinessUnitService,
    private toastService: ToastrService
  ) {
    this.bussinesUnitplanPTable = {
      sortBy: 'createdAt',
      sortWith: 'desc',
      data: [],
      page: 1,

      limit: 8,

      search: '',
      filter: {
        type: 'plan',
      },
    };
    this.viewNotification = this.formBuilder.group({
      bussinessUnit: [null, [Validators.required]],
    });
  }

  ngOnInit(): void {
    // this.buId = this.route.snapshot.paramMap.get('buId');
    this.loadBussinessPlanUnit();
  }

  ngAfterViewInit() {
    if (!this.isNotificationEndabled) {
      setTimeout(() => {
        this.renderer.addClass(this.sidemenu.nativeElement, 'animate');
      }, 50);
    }
  }

  changeOption(option, from = 'other') {
    if (from === 'api') {
      if (option === 'schedule') {
        this.active = 2;
        // this.scheduleListChild.render();
      } else {
        this.active = 3;
        // this.pushListChild.render();
      }
      // this.pushListChild.getPushList();
    }
    if (option === 'schedule') {
      this.navBar = 'CREATE'
      this.active = 2;
    } else if (option === 'push') {
      this.active = 3;
      this.navBar = 'CREATE'
    } else {
      this.active = 1;
      this.notiData = null;
    }
    this.selectedOption = option;
  }
  viewSchedule(notificationData: any): void {
    if(notificationData.isView){
      this.navBar = 'VIEW'
    }else{
      this.navBar = 'EDIT'
    }
    this.selectedOption = 'create';
    this.active = 1;
    this.notiData = notificationData;
    // this.createNotification.populateDetails(
    //   notificationData.notificationId,
    //   notificationData.isView
    // );
  }
  addParentBussinentUnit(value) {
    this.viewNotification.patchValue({
      bussinessUnit: {
        string: value.businessUnit,
        _id: value._id,
      },
    });
  }
  deleteparentBussinesUnit() {
    this.viewNotification.patchValue({
      bussinessUnit: null,
    });
  }

  onSeachPlanBuUnit(value) {
    this.bussinesUnitplanPTable.limit = 10;
    this.bussinesUnitplanPTable.search = value.term;

    this.loadBussinessPlanUnit();
  }

  loadMorePlasnBuUnitItems(value) {
    this.bussinesUnitplanPTable.limit = this.bussinesUnitplanPTable.limit + 10;

    this.loadBussinessPlanUnit();
  }

  onClearPlanBuUnitDropdown(value) {
    if (value === undefined) {
      this.viewNotification.patchValue({
        bussinessUnit: null,
      });
      this.bussinesUnitplanPTable.search = '';
      this.loadBussinessPlanUnit();

      this.planBuUnitDropDown = [];
      this.bussinesUnitplanPTable.data = [];
    }
  }
  loadBussinessPlanUnit() {
    this.planBuUnitDropDownLoading = true;
    const { data, ...rest } = this.bussinesUnitplanPTable;
    const subscribe = this.buUnit
      .getBussinesUnit(rest, true)
      .pipe(
        finalize(() => {
          this.planBuUnitDropDownLoading = false;
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const { recordsTotal, businessUnitList } = response;
          this.bussinesUnitplanPTable.data = businessUnitList;
          this.planBuUnitDropDown = businessUnitList;
        },
        (error) => {
          this.bussinesUnitplanPTable.data = [];

          this.toastService.error('No list found');
        }
      );
  }
  overview() {
    this.buId = null;
    this.selectedOption = '';
    this.active = 1;
    this.viewNotification = this.formBuilder.group({
      bussinessUnit: [null, [Validators.required]],
    });
    this.isNotificationEndabled = false;
  }
  onClickView() {
    if (!this.viewNotification.valid) {
      this.toastService.error('No list found');
      return;
    }
    this.isLoading = true;
    const { _id } = this.viewNotification.get('bussinessUnit').value;
    this.buId = _id;
    this.selectedOption = 'create';
    this.active = 1;
    this.isNotificationEndabled = true;
    this.isLoading = false;
  }

  onCloseDrawer() {
    this.isNotificationEndabled = true;
  }
}
