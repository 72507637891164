<div class="card">
  <h5 class="card-header bg-theme">SHIFT SCHEME</h5>
  <div class="card-body table-responsive p-0">
    <div *ngIf="schemeShiftOverviewErr; then thenBlock; else elseBlock"></div>
    <ng-template #thenBlock>
      <div class="text-md text-black-50 p-4">Not Found!</div>
    </ng-template>
    <ng-template #elseBlock>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col" sortable="Tier 1">SCHEME NAME</th>
            <th scope="col" sortable="Tier 2">CREATION DATE</th>
            <th scope="col" sortable="Tier 3">SCHEME DESCRIPTION</th>
            <th scope="col" sortable="Tier 4">LAST MODIFIED AT</th>
            <th scope="col" sortable="Status">CREATED BY</th>
            <th scope="col">ACTION</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let item of paginateData">
            <td>
              <span class="font-weight-500">{{ item.schemeName }}</span>
            </td>
            <td>{{ item.createdAt | date }}</td>
            <td>{{ item.schemeDesc }}</td>
            <td>{{ item.updatedAt | date }}</td>
            <td>{{ item?.createdBy?.name }}</td>
            <td>
              <div class="d-flex">
                <button
                  class="btn btn-info btn-sm"
                  (click)="onViewSchemeShift(item)"
                >
                  View
                </button>
                <button
                  class="btn btn-success btn-sm ml-1"
                  (click)="onEditSchemeShift(item)"
                >
                  Edit
                </button>
                <button
                  class="btn btn-danger btn-sm ml-1"
                  (click)="setDeleteItem(item, true)"
                >
                  Delete
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-template>
    <ngx-skeleton-loader
      *ngIf="loader"
      count="5"
      [theme]="{
        width: 'calc(100% - 40px)',
        'border-radius': '6px',
        height: '44px',
        margin: '10px',
        display: 'block'
      }"
    ></ngx-skeleton-loader>
  </div>
  <div class="card-footer">
    <div class="d-flex justify-content-between">
      <p class="align-self-center m-0 text-muted">
        Total Records
        <b class="text-dark">{{ shiftSchemeTable.total }}</b>
      </p>
      <ngb-pagination
        [collectionSize]="shiftSchemeTable.total"
        [(page)]="shiftSchemeTable.page"
        [maxSize]="5"
        (pageChange)="onPageChangeShiftScheme($event)"
        [pageSize]="shiftSchemeTable.limit"
      >
      </ngb-pagination>
    </div>
  </div>
  <div [ngClass]="delete ? 'show-modal' : ''" class="modal" id="mmmModal">
    <div
      class="modal-dialog"
      style="margin-top: 180px; width: 311px; height: 200px"
    >
      <div class="modal-content modalContentBoxGrey">
        <div class="modal-body">
          <div>
            <h4 style="font-size: 17px">
              Are you sure, you want to delete this?
            </h4>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn footer-button btn-warning" (click)="setDelete()">
            Yes
          </button>
          <button
            class="btn footer-button btn-primary"
            (click)="this.delete = false"
            style="margin-right: 80px"
          >
            No
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
