import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject, Subscription, of } from 'rxjs';
import {
  map,
  debounceTime,
  distinctUntilChanged,
  mergeMap,
  delay,
  finalize,
} from 'rxjs/operators';
import {
  SortableHeader,
  SortEvent,
} from 'src/app/shared/directive/sortable.directive';
import { PaginatedTable } from 'src/app/core/model/pagination.model';
import { StorageService } from 'src/app/core/service/storage.service';

import { BussinessUnitService } from 'src/app/users/service/bussiness-unit/bussiness-unit.service';
import { UsersService } from 'src/app/users/service/user-service/users.service';
import { ToastrService } from 'ngx-toastr';
import { TableService } from 'src/app/core/service/table.service';
import { tableParam } from 'src/app/core/model/table-param.model';

@Component({
  selector: 'users-overview',
  templateUrl: './users-overview.component.html',
  styleUrls: ['./users-overview.component.scss'],
})
export class UsersOverviewComponent implements OnInit {
  usersPTable;
  // isBuUnitEdited:boolean = false
  // sectionId:string
  // subSectionId:string
  // bussinessUnitEditForm:FormGroup
  public userssearchInput = new Subject<KeyboardEvent>();
  isTableLoading: boolean = false;
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  userTableColumn = ['name', 'staffId', 'appointment.name', 'businessUnit', '_id'];
  tableQueryParams = tableParam;
  // companyName: string;
  /// bussiness unit over view table config
  onSortUsers({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.usersPTable.sort = { key: column, order: direction as any };
    this.loadUsers();
  }

  onPageChangeUsers(page: number) {
    this.usersPTable.page = page;
    this.tableQueryParams.start = +page > 1 ? (page - 1) * this.usersPTable.limit : 0;
    this.loadUsers();
  }
  onLimitChangeUsers() {
    this.usersPTable.page = 1;
    this.tableQueryParams.start = 0;
    this.loadUsers();
  }

  private usersSearchSubscription: Subscription;
  @Output() EditEvent = new EventEmitter<string>();
  constructor(
    private userService: UsersService,
    public toastService: ToastrService,
    public tableService: TableService
  ) {
    // this.config.notFoundText = 'Custom not found';
    this.usersPTable = {
      sortBy: 'createdBy',
      sortWith: 'desc',

      data: [],
      page: 1,
      limit: 10,
      search: '',
      filter: {
        status: ['active', 'inactive'],
        parentBussinessUnitId: [],
        // fields: ['email', 'staffId', 'appointmentId','parentBussinessUnitId'],
      },
    };
    // this.companyName = this.storageService.getByKey('companyId');

    this.usersSearchSubscription = this.userssearchInput
      .pipe(
        map((event) => {
          const target = event.target as HTMLTextAreaElement;
          return target.value;
        }),
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap((search) => of(search).pipe(delay(500)))
      )
      .subscribe((input) => {
        this.usersPTable.page = 1;
        this.tableQueryParams.start = 0;
        this.tableQueryParams.search.value = input;
        this.loadUsers();
      });
  }

  ngOnInit(): void {
    this.tableQueryParams.draw = 0;
     this.tableQueryParams.search.value = "";
    this.loadUsers();
  }
  onEditUser(id: string) {
    this.EditEvent.emit(id);
  }

  loadUsers() {
    // const { data, total, isLoading, ...rest } = this.usersPTable;
    let tableCol: any = this.tableService.initializeTableParamsColums(this.userTableColumn, [0, 1], [0, 1, 2, 3, 4]) || [];
    this.tableQueryParams.columns = tableCol;
    this.tableQueryParams.draw++;
    this.tableQueryParams.length = this.usersPTable.limit;
    this.isTableLoading = true;
    const subscribe = this.userService
      .getAllViewUsers(this.tableQueryParams)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          // const { count, data } = response.data;
          this.usersPTable.data = response.data;
          this.usersPTable.total = response?.recordsFiltered;
          this.isTableLoading = false;
        },
        (error) => {
          this.usersPTable.data = [];
          this.usersPTable.total = 0;
          this.toastService.error('No list found');
          this.isTableLoading = false;
        }
      );
  }

  ngOnDestroy(): void {
    this.usersSearchSubscription && this.usersSearchSubscription.unsubscribe();
  }
}
