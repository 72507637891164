import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { ShiftSchedulerService } from 'src/app/users/service/operations/shift-scheduler.service';
import { UsersService } from 'src/app/users/service/user-service/users.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-timesheet-overview',
  templateUrl: './timesheet-overview.component.html',
  styleUrls: ['./timesheet-overview.component.scss'],
})
export class TimesheetOverviewComponent implements OnInit, OnChanges {
  @Input('businessUnitId') public businessUnitId: any;
  @Input() refresh: any;
  @Output('onButtonClick') onButtonClick = new EventEmitter<number>();
  @Output('onUserData') onUserData = new EventEmitter<any>();
  @Input('dataAttendance') public dataAttendance: any;

  public isOverview: boolean = true;
  public showShiftDetails: boolean = false;
  public timesheetDetails: any=[];
  public todayTime: string;
  public startCamera: boolean;
  public showCameraAttendDiv: boolean;
  public isUpdated: boolean;
  public cameraDivShow: boolean;
  public qrscannerStarted: boolean;
  public myLoad1: boolean;
  public selectedUserFacialInfo: any;
  public scanner: any;
  public checkStatus: any;
  public dashboardData: any;
  public userDataObj: any;
  public image: any;
  public timeSheetData: any;
  public copyTimeSheetData: any;
  public tab: number;
  userData: any;
  attMode: boolean = true;
  timesheetDashboardLoader: boolean = true;

  constructor(
    private timesheetService: ShiftSchedulerService,
    private userService: UsersService,
    private toastService:ToastrService
  ) {}

  ngOnInit(): void {
    // this.readTimesheet();
    // this.getTimesheetData();

    // this.userService.getUserProfile().subscribe((res) => {
    //   this.userData = res['data'];
    // });
  }

  ngOnChanges() {
    this.readTimesheet();
    this.getTimesheetData();

    this.userService.getUserProfile().subscribe((res) => {
      this.userData = res['data'];
    });
  }

  public changeOverview(value): void {
    this.isOverview = value;
  }

  public changeShiftDetailsView(value): void {
    this.showShiftDetails = value;
  }

  public readTimesheet(): void {
    const payload = {
      timeZone: 330,
    };
    this.timesheetDetails=[];
    const timeDashboard = this.timesheetService
      .readTimesheet(this.businessUnitId._id, payload)
      .pipe(
        finalize(() => {
          this.timesheetDashboardLoader = false;
          timeDashboard.unsubscribe();
        })
      )
      .subscribe((res) => {
        res?.map((item,index)=>{
         /* if(item.shiftDetails?.isSplitShift){
            const shift1=JSON.parse(JSON.stringify(item));
            shift1.position = 1;
            this.timesheetDetails.push(shift1);
            const shift2=JSON.parse(JSON.stringify(item));
            shift2.position = 2;
            shift2.shiftDetails.date=shift1?.shiftDetails?.splitShiftObj?.date,
            shift2.shiftDetails.startTime=shift1?.shiftDetails?.splitShiftObj?.startTime,
            shift2.shiftDetails.endTime=shift1?.shiftDetails?.splitShiftObj?.endTime

            this.timesheetDetails.push(shift2);
          } else {*/
            // const shift=JSON.parse(JSON.stringify(item));
            this.timesheetDetails.push(item);
         // }

        });

        // this.timesheetDetails = res || [];
      });
  }

  manualCheckout(i) {
    const val = localStorage.getItem('loggedInUserId');
    var dataObj = {
      userId: i.userInfo._id,
      shiftId: i.shiftDetails.shiftId,
      shiftDetailId: i.shiftDetails._id,
      attendanceMode: 'Manual',
      status: 2,
      attandanceTakenBy: val,
      businessUnitId: i.businessUnitId,
    };
    Swal.fire({
      title: 'This staff has not updated any breaks',
      text: 'Ask staff if any breaks to update.',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Wait for break input',
      confirmButtonText: 'Continue Attendance',
    }).then((result) => {
      if (result.isConfirmed) {
        this.timesheetService.getAttendance(dataObj).subscribe((response:any) => {
          this.toastService.success(response?.message +' '+ 'successfully')
          this.readTimesheet();
        });
      }
    });
  }

  qrClockOut(data) {
    data.isFacial = false;
    this.onUserData.emit(data);
    this.onButtonClick.emit(2);
  }

  facialAttendance(data){
    this.onUserData.emit(data);
    this.onButtonClick.emit(2);
  }

  public markUserAttendance(userDataOld, isSplit) {
    this.onUserData.emit(userDataOld);
    this.onButtonClick.emit(2);
    var userData = Object.assign({}, userDataOld);
    if (isSplit === 'yes') {
      userData.shiftDetails = userDataOld.shiftDetails.splitShiftObj;
      if (userDataOld.splitAttendance) {
        userData.attendance = userDataOld.splitAttendance;
      } else {
        delete userData.attendance;
      }
    }
    this.todayTime = new Date(new Date().getTime()).toLocaleTimeString();
    this.startCamera = false;
    this.showCameraAttendDiv = false;
    this.selectedUserFacialInfo = userData;

    this.timesheetService
      .generateQr(
        this.selectedUserFacialInfo.userInfo?._id,
        this.selectedUserFacialInfo.shiftDetails?._id
      )
      .subscribe((response) => {});
    // this.timesheetService.getUserUmage(userData.userInfo._id).subscribe((response) => {
    //   if (response['data']) {

    //     this.selectedUserFacialInfo.userInfo.facialInfo = response['data'].userData.facialInfo;

    //   } else {
    //     // message('error', response['data'].message)
    //   }

    // })

    if (userData.hasOwnProperty('attendance')) {
      if (
        userData.attendance.attendanceMode === 'Facial Failed' &&
        userData.attendance.status == 4 &&
        (!userData.attendance.hasOwnProperty('clockInDateTime') ||
          userData.attendance.clockInDateTime == null)
      ) {
        this.tab = 2;
        this.isUpdated = false;
        this.showCameraAttendDiv = true;
        this.cameraDivShow = true;
        // this.openQr();//
      }
      if (
        userData.attendance.attendanceMode === 'Qr Failed' &&
        userData.attendance.status == 4 &&
        !userData.attendance.hasOwnProperty('clockInDateTime')
      ) {
        this.isUpdated = false;
        // this.takeManualAttendance();//
      }
      if (
        (userData.attendance.attendanceMode === 'Qr' ||
          userData.attendance.attendanceMode === 'Facial') &&
        userData.attendance.status == 5
      ) {
        if (userData.attendance.breakTime.length == 0) {
          if (!this.checkStatus) {
            // $('#BreakTimeModalstaff').modal();//
          } else {
            // $('#BreakTimeModal').modal();//
          }

          this.userDataObj = userData;
        } else {
          this.tab = 2;
          this.isUpdated = true;
          this.showCameraAttendDiv = true;
          this.cameraDivShow = true;
          // this.openWebCamera();//
        }
      }
      if (userData.attendance.status == 3 || userData.attendance.status == 2) {
        this.tab = 3;
        //this.getTimesheetData();
      }
      if (
        userData.attendance.status == 1 &&
        userData.attendance.attendanceMode == 'Facial'
      ) {
        if (userData.attendance.breakTime.length == 0) {
          if (!this.checkStatus) {
            // $('#BreakTimeModalstaff').modal();//
          } else {
            // $('#BreakTimeModal').modal();//
          }

          this.userDataObj = userData;
        } else {
          this.tab = 2;
          this.isUpdated = true;
          this.showCameraAttendDiv = true;
          this.cameraDivShow = true;
          // this.openWebCamera();//
        }
      }
      if (
        userData.attendance.status == 1 &&
        (userData.attendance.attendanceMode == 'Qr' ||
          userData.attendance.attendanceMode === 'Facial Failed')
      ) {
        if (userData.attendance.breakTime.length == 0) {
          if (!this.checkStatus) {
            // $('#BreakTimeModalstaff').modal();//
          } else {
            // $('#BreakTimeModal').modal();//
          }
          this.userDataObj = userData;
        } else {
          this.tab = 2;
          this.isUpdated = true;
          this.showCameraAttendDiv = true;
          this.cameraDivShow = true;
          // this.openQr();//
        }
      }
      if (
        userData.attendance.status == 1 &&
        (userData.attendance.attendanceMode == 'Manual' ||
          userData.attendance.attendanceMode === 'Qr Failed')
      ) {
        if (userData.attendance.breakTime.length == 0) {
          if (!this.checkStatus) {
            // $('#BreakTimeModalstaff').modal();//
          } else {
            // $('#BreakTimeModal').modal();//
          }
          this.userDataObj = userData;
        } else {
          this.isUpdated = true;
          // this.takeManualAttendance();//
        }
      }
    } else {
      this.isUpdated = false;
      if (userData.attendanceStatusMode === 'Facial') {
        this.tab = 2;
        this.cameraDivShow = true;
        this.showCameraAttendDiv = true;
        // this.openWebCamera();//
      }
      if (userData.attendanceStatusMode === 'Qr') {
        this.tab = 2;
        this.showCameraAttendDiv = true;
        // this.openQr();//
      }
      if (userData.attendanceStatusMode === 'Manual') {
        // this.takeManualAttendance();//
      }
    }
  }

  public getTimesheetData() {
    this.myLoad1 = true;
    this.timesheetService.getTimesheetData(this.businessUnitId._id).subscribe(
      (response) => {
        if (response['data']?.status == 1) {
          this.myLoad1 = false;
          this.timeSheetData = response['data'] ? response['data'].data : null;
          this.copyTimeSheetData = response['data']
            ? response['data'].data
            : null;
        }
      },
      function (error) {
        this.myLoad1 = false;
      }
    );
  }

  public setBtnStatus(userDetailsOld, index, from, isSplit) {
    var userDetails = Object.assign({}, userDetailsOld);
    //userDetails = JSON.parse(userDetails);
    if (isSplit === 'yes') {
      userDetails.shiftDetails = userDetails.shiftDetails.splitShiftObj;
      if (userDetailsOld.splitAttendance) {
        userDetails.attendance = userDetailsOld.splitAttendance;
      } else {
        delete userDetails.attendance;
      }
    }
    if (from === 'dashbaord') {
      if (!this.checkStatus) {
        if (
          userDetails.isFacial == true &&
          (!userDetails.hasOwnProperty('attendance') || !userDetails.attendance)
        ) {
          this.dashboardData[index].attendanceStatusMode = 'Facial';
          if (isSplit === 'yes') {
            this.dashboardData[index].btnClassSplit = 'redBtn hvr-pulse';
            //this.dashboardData[index].attendanceStatusMode = 'Facial';
          } else {
            this.dashboardData[index].btnClass = 'redBtn hvr-pulse';
          }
          this.dashboardData[index].attendanceStatusMode = 'Facial';
          return 'Facial Clockin';
        } else if (
          (userDetails.isFacial == false || !userDetails.isFacial) &&
          (!userDetails.hasOwnProperty('attendance') || !userDetails.attendance)
        ) {
          this.dashboardData[index].btnClass = '';
          this.dashboardData[index].btnClassSplit = '';
          //this.dashboardData[index].attendanceStatusMode = 'Facial';
          if (isSplit === 'yes') {
            this.dashboardData[index].isDisabledSplit = true;
          } else {
            this.dashboardData[index].isDisabled = true;
          }
          return 'Please Contact Supervisor';
        } else if (
          userDetails.attendance &&
          userDetails.attendance.IsLock === true &&
          userDetails.attendance.status === 3
        ) {
          this.dashboardData[index].attendanceStatusMode = '';

          if (isSplit === 'yes') {
            this.dashboardData[index].isDisabledSplit = true;
            this.dashboardData[index].btnClassSplit = 'greyBtn';
          } else {
            this.dashboardData[index].btnClass = 'greyBtn';
            this.dashboardData[index].isDisabled = true;
          }
          return 'Shift Completed';
        } else if (
          userDetails.attendance &&
          userDetails.attendance.IsLock === false &&
          userDetails.attendance.status === 3
        ) {
          this.dashboardData[index].attendanceStatusMode = '';
          if (isSplit === 'yes') {
            this.dashboardData[index].btnClassSplit = 'greyBtn';
            this.dashboardData[index].isDisabledSplit = true;
          } else {
            this.dashboardData[index].isDisabled = true;
            this.dashboardData[index].btnClass = 'greyBtn';
          }
          return 'Shift Completed';
        } else if (
          userDetails.attendance.attendanceMode === 'Facial' &&
          userDetails.attendance.status === 1
        ) {
          this.dashboardData[index].attendanceStatusMode = 'Facial';
          //this.dashboardData[index].btnClass = 'greenBtn hvr-pulse';
          if (isSplit === 'yes') {
            this.dashboardData[index].btnClassSplit = 'greenBtn hvr-pulse';
            //this.dashboardData[index].attendanceStatusMode = 'Facial';
          } else {
            this.dashboardData[index].btnClass = 'greenBtn hvr-pulse';
          }
          return 'Facial Clockout';
        } else if (
          userDetails.hasOwnProperty('attendance') &&
          (userDetails.attendance.status === 2 ||
            userDetails.attendance.status === 3)
        ) {
          this.dashboardData[index].attendanceStatusMode = '';
          this.dashboardData[index].btnClass = 'greyBtn';
          if (isSplit === 'yes') {
            this.dashboardData[index].isDisabledSplit = true;
            this.dashboardData[index].btnClassSplit = 'greyBtn';
          } else {
            this.dashboardData[index].isDisabled = true;
            this.dashboardData[index].btnClass = 'greyBtn';
          }
          return 'Shift Completed';
        } else {
          this.dashboardData[index].btnClass = '';
          this.dashboardData[index].btnClassSplit = '';
          if (isSplit === 'yes') {
            this.dashboardData[index].isDisabledSplit = true;
          } else {
            this.dashboardData[index].isDisabled = true;
          }
          return 'Please Contact Supervisor';
        }
      } else {
        if (
          userDetails.isFacial == true &&
          (!userDetails.hasOwnProperty('attendance') || !userDetails.attendance)
        ) {
          this.dashboardData[index].attendanceStatusMode = 'Facial';
          //this.dashboardData[index].btnClass = 'redBtn hvr-pulse';
          if (isSplit === 'yes') {
            this.dashboardData[index].btnClassSplit = 'redBtn hvr-pulse';
            //this.dashboardData[index].attendanceStatusMode = 'Facial';
          } else {
            this.dashboardData[index].btnClass = 'redBtn hvr-pulse';
          }
          return 'Facial Clockin';
        } else if (
          userDetails.attendance &&
          userDetails.attendance.IsLock === true &&
          userDetails.attendance.status === 3
        ) {
          this.dashboardData[index].attendanceStatusMode = '';
          this.dashboardData[index].btnClass = 'greyBtn';
          if (isSplit === 'yes') {
            this.dashboardData[index].isDisabledSplit = true;
            this.dashboardData[index].btnClassSplit = 'greyBtn';
          } else {
            this.dashboardData[index].isDisabled = true;
          }
          return 'Shift Completed';
        } else if (
          userDetails.attendance &&
          userDetails.attendance.IsLock === false &&
          userDetails.attendance.status === 3
        ) {
          this.dashboardData[index].attendanceStatusMode = '';
          if (isSplit === 'yes') {
            this.dashboardData[index].isDisabledSplit = true;
            this.dashboardData[index].btnClassSplit = 'redBtn';
          } else {
            this.dashboardData[index].isDisabled = true;
            this.dashboardData[index].btnClass = 'redBtn';
          }
          return 'Lock Timesheet';
        } else if (
          userDetails.attendance &&
          (userDetails.isFacial == false || userDetails.isFacial == true) &&
          userDetails.attendance.attendanceMode === 'Facial Failed' &&
          userDetails.attendance.status === 4
        ) {
          this.dashboardData[index].attendanceStatusMode = 'Qr';
          this.dashboardData[index].btnClass = 'redBtn hvr-pulse';
          if (isSplit === 'yes') {
            this.dashboardData[index].btnClassSplit = 'redBtn hvr-pulse';
          } else {
            this.dashboardData[index].btnClass = 'redBtn hvr-pulse';
          }
          return 'Qr Clockin';
        } else if (
          (userDetails.isFacial == false || !userDetails.isFacial) &&
          (!userDetails.hasOwnProperty('attendance') || !userDetails.attendance)
        ) {
          this.dashboardData[index].attendanceStatusMode = 'Qr';
          if (isSplit === 'yes') {
            this.dashboardData[index].btnClassSplit = 'redBtn hvr-pulse';
          } else {
            this.dashboardData[index].btnClass = 'redBtn hvr-pulse';
          }
          return 'Qr Clockin';
        } else if (
          userDetails.attendance.attendanceMode === 'Qr Failed' &&
          userDetails.attendance.status === 4
        ) {
          this.dashboardData[index].attendanceStatusMode = 'Manual';
          // this.dashboardData[index].btnClass = 'redBtn hvr-pulse';
          if (isSplit === 'yes') {
            this.dashboardData[index].btnClassSplit = 'redBtn hvr-pulse';
          } else {
            this.dashboardData[index].btnClass = 'redBtn hvr-pulse';
          }
          return 'Manual Clockin';
        } else if (
          userDetails.attendance.attendanceMode === 'Facial' &&
          userDetails.attendance.status === 1
        ) {
          this.dashboardData[index].attendanceStatusMode = 'Facial';
          //this.dashboardData[index].btnClass = 'greenBtn hvr-pulse';
          if (isSplit === 'yes') {
            this.dashboardData[index].btnClassSplit = 'greenBtn hvr-pulse';
          } else {
            this.dashboardData[index].btnClass = 'greenBtn hvr-pulse';
          }
          return 'Facial Clockout';
        } else if (
          (userDetails.attendance.attendanceMode === 'Qr' &&
            userDetails.attendance.status === 1) ||
          (userDetails.attendance.attendanceMode === 'Facial Failed' &&
            !userDetails.attendance.hasOwnProperty('clockOutDateTime'))
        ) {
          this.dashboardData[index].attendanceStatusMode = 'Qr';
          //  this.dashboardData[index].btnClass = 'greenBtn hvr-pulse';
          if (isSplit === 'yes') {
            this.dashboardData[index].btnClassSplit = 'greenBtn hvr-pulse';
          } else {
            this.dashboardData[index].btnClass = 'greenBtn hvr-pulse';
          }
          return 'Qr Clockout';
        } else if (
          ((userDetails.attendance.attendanceMode === 'Facial' ||
            userDetails.attendance.attendanceMode === 'Qr') &&
            userDetails.attendance.status === 5) ||
          (userDetails.attendance.attendanceMode === 'Facial Failed' &&
            !userDetails.attendance.hasOwnProperty('clockOutDateTime'))
        ) {
          this.dashboardData[index].attendanceStatusMode = 'Qr';
          // this.dashboardData[index].btnClass = 'greenBtn hvr-pulse';
          if (isSplit === 'yes') {
            this.dashboardData[index].btnClassSplit = 'greenBtn hvr-pulse';
          } else {
            this.dashboardData[index].btnClass = 'greenBtn hvr-pulse';
          }
          return 'Qr Clockout';
        } else if (
          (userDetails.attendance.attendanceMode === 'Manual' &&
            userDetails.attendance.status === 1) ||
          (userDetails.attendance.attendanceMode === 'Qr Failed' &&
            !userDetails.attendance.hasOwnProperty('clockOutDateTime'))
        ) {
          this.dashboardData[index].attendanceStatusMode = 'Manual';
          // this.dashboardData[index].btnClass = 'greenBtn hvr-pulse';
          if (isSplit === 'yes') {
            this.dashboardData[index].btnClassSplit = 'greenBtn hvr-pulse';
          } else {
            this.dashboardData[index].btnClass = 'greenBtn hvr-pulse';
          }
          return 'Manual Clockout';
        } else if (
          userDetails.attendance.IsLock === false &&
          (userDetails.attendance.status === 2 ||
            userDetails.attendance.status === 3) &&
          (userDetails.attendance.attendanceMode === 'Qr' ||
            userDetails.attendance.attendanceMode === 'Facial' ||
            userDetails.attendance.attendanceMode === 'Manual')
        ) {
          this.dashboardData[index].attendanceStatusMode = '';
          //this.dashboardData[index].btnClass = 'redBtn';
          if (isSplit === 'yes') {
            this.dashboardData[index].btnClassSplit = 'greyBtn';
          } else {
            this.dashboardData[index].btnClass = 'greyBtn';
          }
          return 'Timesheet';
        } else if (
          userDetails.attendance.IsLock === true &&
          userDetails.attendance.status === 3
        ) {
          this.dashboardData[index].attendanceStatusMode = '';

          if (isSplit === 'yes') {
            this.dashboardData[index].isDisabledSplit = true;
            this.dashboardData[index].btnClassSplit = 'greyBtn';
          } else {
            this.dashboardData[index].isDisabled = true;
            this.dashboardData[index].btnClass = 'greyBtn';
          }
          return 'Shift Completed';
        } else {
        }
      }
    }
  }

  getLogCsv() {
    const attendanceLogPayload = {
      businessUnitId: this.businessUnitId._id,
      timeZone: 330,
    };

    this.timesheetService
      .attendanceLog(attendanceLogPayload)
      .subscribe((res:any) => {
        var a = document.createElement('a');
        a.href = 'data:attachment/csv,' +encodeURI(res.csv);
        a.target = '_blank';
        a.download = "attendanceLog.csv";
        document.body.appendChild(a);
        a.click();
       this.toastService.success(
          "success",
          "timesheet Details exported succesfully"
        );
      });
  }
}
