import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { GamificationService } from 'src/app/users/service/gamification/gamification.service';
import { UsersService } from 'src/app/users/service/user-service/users.service';
import { BussinessUnitService } from 'src/app/users/service/bussiness-unit/bussiness-unit.service';
import { CustomFieldService } from 'src/app/users/service/custom-field/custom-field.service';
import { COMMON_MESSAGES, generateImageUrl, getAssignedUsers } from 'src/app/core/utils/helper';
import { SetupService } from 'src/app/users/service/operations/setup.service';
import { StorageService } from 'src/app/core/service/storage.service';
import { MatStepper } from '@angular/material/stepper';
@Component({
  selector: 'add-edit-challenges',
  templateUrl: './add-edit-challenges.component.html',
  styleUrls: ['./add-edit-challenges.component.scss'],
})
export class AddEditChallengesComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('myInputVariable')
  myInputVariable: ElementRef<any>;
  discriptioGroup: FormGroup;
  rulesGroup: FormGroup;
  teamsGroup: FormGroup;
  createRanks: FormGroup;
  @Input() challangesData = null;
  data: [
    {
      page: 1;
      limit: 1;
      search: 1;
      sortBy: 1;
      sortWith: 1;
    }
  ];
  dayArr = [
    { count: 0, day: 'M', dayName: 'Monday' },
    { count: 1, day: 'T', dayName: 'Tuesday' },
    { count: 2, day: 'W', dayName: 'Wednesday' },
    { count: 3, day: 'T', dayName: 'Thursday' },
    { count: 4, day: 'F', dayName: 'Friday' },
    { count: 5, day: 'S', dayName: 'Saturday' },
    { count: 6, day: 'S', dayName: 'Sunday' },
  ];
  selectedDay: any;
  editScheme = null;
  addedDay = [];
  adminGroup: FormGroup;
  isLinear;
  criteriaSelect = null;
  criteriaCategorySelect = null;
  adminSelect = [];
  channelSelect = null;
  workFlowDropdown = null;
  workFlowStatus: boolean = false;
  nominateQuestionStatus: boolean = false;
  workFlowData: any;
  nomineeQuestionData: any;
  clickedRadio: string = '';
  nomineeQuestion
  nomineeID: string = '';
  fieldNeedsToUpdate = [];
  badgeTieringArray: any[] = [];
  userDropDown: any[];
  isLoading: boolean;
  imageLoader: boolean;
  isEditTeam: boolean;
  editTeamIndex: number;
  assignUserDetailsForm: FormGroup;
  businessUnitList: any = [];
  workFlowFieldValue: any = [];
  imagePreview: any = null;
  appoinmentPTable: {
    sortWith: string;
    sortBy: string;
    data: any[];
    page: number;
    total: number;
    limit: number;
    search: string;
  };

  userDetails: any[] = [];
  teamUserDetails: any[] = [];

  teamDetails: any[] = [];
  createForm: any;
  rewardPoints;
  userPTable: {
    sortWith: string;
    sortBy: string;
    data: any[];
    page: number;
    limit: number;
    filter: { fields: string[] };
    search: string;
  };
  @Output() overViewEvent = new EventEmitter<string>();
  customfromData: any;
  channelData: any;
  boardData: any;
  creatiaView: any;
  /* criteria type 4*/
  buDropDownToClear = null;
  planBuUnitDropDownLoading: boolean = false;
  apppoinmentDropDownLoading: boolean = false;
  CustomFieldDropDownLoading: boolean = false;
  planBuUnitDropDown = [];
  shiftcriteriaSelectNumber: number;
  criteriaCategory = [];
  displayDescriptions = [];
  displayFieldsData = [];
  allCriteria = [
    {
      value: 1,
      key: 'Reading articles',
    },
    {
      value: 2,
      key: 'Questionnaire attempted',
    },
    {
      value: 3,
      key: ' Event attended',
    },
    {
      value: 4,
      key: 'Posting Done',
    },
    {
      value: 5,
      key: 'First Login',
    },
    {
      value: 6,
      key: 'Visible to submitted User',
    },
    {
      value: 7,
      key: 'Visible to Nominated User',
    },
    {
      value: 8,
      key: 'Nominated User',
    },
    {
      value: 9,
      key: 'Questionnaire attempted',
    },
    {
      value: 10,
      key: 'On-time report',
    },
    {
      value: 11,
      key: 'No. of Shift worked (By Hour)',
    },
    {
      value: 12,
      key: 'No. of Shift worked (By Shift Count)',
    },
  ];
  channelCriteria = [
    {
      value: 1,
      key: 'Reading articles',
    },
    {
      value: 2,
      key: 'Questionnaire attempted',
    },
    {
      value: 3,
      key: ' Event attended',
    },
  ];
  boardCriteria = [
    {
      value: 4,
      key: 'Posting Done',
    },
    {
      value: 9,
      key: 'Questionnaire attempted',
    },
    {
      value: 8,
      key: 'Nominated User',
    },
  ];
  systemCriteria = [
    {
      value: 5,
      key: 'First Login',
    },
  ];
  customCriteria = [
    {
      value: 6,
      key: 'Visible to submitted User',
    },
    {
      value: 7,
      key: 'Visible to Nominated User',
    },
  ];

  progressTypes = [
    {
      value: 1,
      key: 'Leader Board',
    },
    {
      value: 2,
      key: 'Digital Stamp',
    },
  ];

  rewardTypes = [
    {
      value: 1,
      key: 'Point System',
    },
    {
      value: 2,
      key: 'Non-Reward Point System',
    },
  ];



  shiftCriteriaArr = [
    { value: 10, key: 'On-time report' },
    { value: 11, key: 'No. of Shift worked (By Hour)' },
    { value: 12, key: 'No. of Shift worked (By Shift Count)' },
  ];
  displaySelectionData = [
    { value: 1, key: 'See own only' },
    { value: 2, key: 'See own or others' },
    { value: 3, key: 'See own + others' },
    { value: 4, key: 'See own + all combined' },
  ]

  poinstSystemData: any;
  pointSytemView: any;
  activeId: number = 1;
  customFormcreatiaView: any;
  criteriaCategoryView: any;
  progressTypesView: any;
  displaySelectionView: any;
  rewardTypesView: any;
  criteriaCategorySelectView: any;
  displayDescriptionsView: any;
  criteriaCategoryValue: any;
  progressTypesValue: any;
  displaySelectionValue: any;
  criteriaCategorySelectValue: any;
  displayDescriptionsValue: any;
  rewardTypesValue: any;
  boardcreatiaView: any;
  systemCretiaView: any;
  @Input() challangesId: string = null;
  @Input() isUserEdited: boolean = false;
  isChallengeEdited: boolean = false;
  UpdateduserDetails: any[] = [];
  UpdatedTeamUserDetails: any[] = [];
  workFlowstatusData: any[] = [];
  workFlowStatusInfo = [];
  workFlowId: any = null;
  nominateView: any;
  schemeData: any = null;
  shiftcriteriaSelect = null;
  schemeDataArr: any;
  bundleArr: any[] = [];
  bundleCount: any;
  loggedInUserId: any = null;
  allChannelAndBoards: {
    boards: any;
    channels: any;
    customForms: any;
    pointSystems: any;
    scheme: any;
  };
  baseUrl = environment.imageUrl;
    constructor(
    private formBuilder: FormBuilder,
    private toastService: ToastrService,
    private gamificationService: GamificationService,
    private userService: UsersService,
    private buUnit: BussinessUnitService,
    private customFieldService: CustomFieldService,
    private setupService: SetupService,
    private storageService: StorageService
  ) {
    this.loggedInUserId = localStorage.getItem('loggedInUserId');
    //this.loggedInUserId=JSON.parse(this.loggedInUserId);
    this.userPTable = {
      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,

      limit: 10,
      filter: {
        fields: ['name'],
      },
      search: '',
    };
  }
  @Output('toggleSideNav') toggleSideNav: EventEmitter<any> =
    new EventEmitter();
  ngOnInit() {
    !this.isUserEdited ? (this.challangesData = null) : '';
    this.initRulesFrom();
    this.initDiscriptionFrom();
    this.initTeamsFrom();
    this.initAdminFrom();
    this.createRank();
    this.loadAdminUsers();
    // this.getChallenges();
    this.getBoardsAndChannels();
    // this.getBusinessUnitList();
    this.initAssignuserDetailsFrom();
    this.getSchemeList();
    if (this.challangesData) {
      // this.getSingleChallenges(this.challangesId);
      this.getChallengeData();
    }
  }

  initDiscriptionFrom() {
    return (this.discriptioGroup = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      challengeStart: ['', Validators.required],
      challengeEnd: ['', Validators.required],
      publishStart: ['', Validators.required],
      publishEnd: ['', Validators.required],
      isTeam: [false, Validators.required],
      icon: ['', Validators.required],
      status: 1,
      leaderBoard: false,
    }));
  }

  initTeamsFrom() {
    return (this.teamsGroup = this.formBuilder.group({
      title: ['', Validators.required],
      icon: ['', Validators.required],
      status: 1,
      id:"",
      teamUserDetails: [[], Validators.required],
    }));
  }
  initAdminFrom() {
    return (this.adminGroup = this.formBuilder.group({
      administrators: this.formBuilder.array([]),
    }));
  }
  initRulesFrom() {
    return (this.rulesGroup = this.formBuilder.group({
      criteriaType: [null, Validators.required],
      criteriaSourceType: ['', Validators.required],
      criteriaCountType: ['1', Validators.required],
      maximumRewards: [0, Validators.required],
      criteriaCount: [null, Validators.required],
      selectedChannel: this.formBuilder.group({
        name: ['', Validators.compose([Validators.required])],
        _id: ['', Validators.compose([Validators.required])],
      }),
      selectedWall: this.formBuilder.group({
        name: [null, Validators.compose([Validators.required])],
        _id: [null, Validators.compose([Validators.required])],
      }),
      selectedCustomForm: this.formBuilder.group({
        title: ['', Validators.compose([Validators.required])],
        _id: ['', Validators.compose([Validators.required])],
      }),

      rewardPoints: ['', Validators.required],
      nonRewardPointSystem: ['', Validators.required],
      nonRewardPointSystemEnabled: [false, Validators.required],
      stopAfterAchievement: [false, Validators.required],
      setLimitToMaxRewards: [false, Validators.required],
      fieldOptions: this.formBuilder.array([]),

      nomineeQuestion: [null, Validators.required],
      badgeTiering: [false, Validators.required],
      ranks: this.formBuilder.array([]),
      isCriteriaCategory: false,
      isProgressSetup: false,
      isRewardChallenge: false,
      isTargetCriteria: false,
      targetCount: false,
      targetCountDescription: "",
    }));
  }
  getSchemeList() {
    let companyId = this.storageService.getByKey('companyId');
    this.gamificationService
      .getSchemeList(companyId)
      .subscribe((response: any) => {
        this.schemeDataArr = response?.data || [];
      });
  }
  get ranksControl(): FormArray {
    return this.rulesGroup.get('ranks') as FormArray;
  }
  get workFlowFieldControl(): FormArray {
    return this.rulesGroup.get('fieldOptions') as FormArray;
  }

  addRank(): void {
    if (this.createRanks?.value?.name !== '') {
      this.badgeTieringArray.push(this.createRanks.value);
      this.createRank();
    } else {
      this.toastService.error('Please enter the badge details');
    }
  }

  resetCriteriaCounter() {
    this.rulesGroup.patchValue({
      criteriaCount: 1,
    });
  }

  deleteBadge(index) {
    this.badgeTieringArray.splice(index, 1);
  }

  createRank(): FormGroup {
    return (this.createRanks = this.formBuilder.group({
      name: ['', Validators.required],
      startRange: [0, Validators.required],
      endRange: [0, Validators.required],
      icon: ['', Validators.required],
    }));
  }

  addChannel(value) {
    this.rulesGroup.patchValue({
      selectedChannel: {
        name: value.name,
        _id: value._id,
      },
    });
    this.channelSelect = [];
  }
  addBoard(value) {
    this.rulesGroup.patchValue({
      selectedWall: {
        name: value.wallName,
        _id: value._id,
      },
    });
    this.channelSelect = [];
  }

  deleteChannel() {
    this.rulesGroup.patchValue({
      selectedChannel: {
        name: null,
        _id: null,
      },
    });
  }

  deleteBoards() {
    this.rulesGroup.patchValue({
      selectedWall: {
        name: null,
        _id: null,
      },
    });
  }

  deleteCustomForm() {
    this.rulesGroup.patchValue({
      selectedCustomForm: {
        title: null,
        _id: null,
      },
    });
    this.displayDescriptionsView = "";
    this.displayDescriptionsValue = "";
    this.criteriaCategorySelectView = "";
    this.criteriaCategorySelectValue = "";
    this.criteriaCategoryView = "";
    this.criteriaCategoryValue = "";
    this.criteriaCategory = [];
    this.displayDescriptions = [];
    this.displayFieldsData = [];
  }

  deleteChannelCreteria() {
    this.creatiaView = null;
  }

  deleteBoardsCreteria() {
    this.boardcreatiaView = null;
  }

  deleteSystemCreteria() {
    this.systemCretiaView = null;
  }

  deleteShiftCriteria() {
    this.shiftcriteriaSelect = null;
  }
  deleteNominateCreteria() {
    this.nominateView = null;
  }

  deleteCustomFormCreteria() {
    this.customFormcreatiaView = null;
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  };
  addCustomForm(value) {
    const Control = this.workFlowFieldControl;

    this.nomineeID = value._id;
    this.workFlowStatus = true;
    this.clearFormArray(Control);
    this.workFlowstatusData = [];
    this.selectWorkflow();
    this.rulesGroup.patchValue({
      selectedCustomForm: {
        title: value.title,
        _id: value._id,
      },
    });
    this.getAllFieldsForCustomForm(value._id);
    this.channelSelect = [];
    this.displayDescriptionsView = "";
    this.displayDescriptionsValue = "";
    this.criteriaCategorySelectView = "";
    this.criteriaCategorySelectValue = "";
    this.criteriaCategoryView = "";
    this.criteriaCategoryValue = "";
    this.criteriaCategory = [];
    this.displayDescriptions = [];
    this.displayFieldsData = [];
    if((this.discriptioGroup.get('leaderBoard').value) != true) {
      this.progressTypesView = "";
      this.progressTypesValue = "";
    }
    this.displaySelectionView = "";
    this.displaySelectionValue = "";
    this.customFormcreatiaView = "";
  }
  addWorkFlow(workflow) {
    this.workFlowId = workflow;
    this.workFlowstatusData = workflow?.fieldStatus;
    // this.workFlowDropdown = [];
  }

  async addWorkflowStatus(value) {
    const Control = this.workFlowFieldControl;
    const index = await Control.value.findIndex(
      (x) => x.formStatusValue === value._id
    );

    if (index > -1) {
      this.toastService.error(`${value.fieldStatusValue} is already added`);
      // this.workFlowstatusData = [];
      this.workFlowFieldValue = [];
    } else {
      let field = this.formBuilder.group({
        fieldOptionValue: [
          this.workFlowId._id,
          Validators.compose([Validators.required]),
        ],
        formStatusValue: [value._id, Validators.compose([Validators.required])],
        workflowValue: [
          this.workFlowId.fieldName,
          Validators.compose([Validators.required]),
        ],
        worflowStatusValue: [
          value.fieldStatusValue,
          Validators.compose([Validators.required]),
        ],
      });
      Control.push(field);
      this.workFlowFieldValue = [];
      // this.workFlowstatusData = [];
    }
  }

  deleteWorkFlowSttus(index) {
    const Control = this.workFlowFieldControl;
    Control.removeAt(index);
  }
  selectWorkflow() {
    this.gamificationService
      .getNomineeQuestions(this.nomineeID)
      .subscribe((response: any) => {
        if (!!response.data.workflow && !!response.data.workflow?.length) {
          let workflow = response.data.workflow;
          workflow.forEach((wf) => {
            wf.fieldName = wf.title;
            wf.workflowStatus.forEach((wfs) => {
              wfs.fieldStatusValue = wfs.field;
            });
            wf.fieldStatus = wf.workflowStatus;
          });
          this.workFlowData = workflow;
        } else {
          this.workFlowData = response?.data?.formStatus;
        }
      });
  }

  clearteamsDataForm() {
    this.teamsGroup.patchValue({
      title: "",
      icon: "",
      id: "",
      teamUserDetails: [],
    });
    this.teamUserDetails = [];
    this.userDetails = [];
  }

  addTeamData() {

    if (!this.teamsGroup.valid) {
      this.toastService.error('Please fill the required team data')
      return
    }
    if (!!this.isEditTeam) {
      this.teamDetails[this.editTeamIndex] = { ...this.teamsGroup.value, teamUserDetails: this.teamUserDetails };
      this.isEditTeam = false;
    }
    else {
      this.teamDetails.push({ ...this.teamsGroup.value, teamUserDetails: this.teamUserDetails });
    }
    this.clearteamsDataForm();
  }

  selectNomineeQuestion() {
    this.gamificationService
      .getNomineeQuestions(this.nomineeID)
      .subscribe((response: any) => {
        this.nomineeQuestionData = response.data.questions;
        this.nomineeQuestionData.map((item) => {
          item.question = item.question.replace(/<[^>]*>/g, '');
        });
      });
  }

  addcriteriaSourceType(value, type) {
    this.shiftcriteriaSelectNumber = value.value;

    switch (type) {
      case 'Channel':
        this.creatiaView = value.key;
        // this.rulesGroup.patchValue({
        //   criteriaSourceType: 1,
        // });
        this.criteriaSelect = [];
        break;
      case 'Boards':
        this.boardcreatiaView = value.key;
        // this.rulesGroup.patchValue({
        //   criteriaSourceType: 2,
        // });
        this.criteriaSelect = [];
        break;
      case 'Custom Form':
        this.customFormcreatiaView = value.key;
        this.nominateQuestionStatus = true;
        this.selectNomineeQuestion();
        // this.rulesGroup.patchValue({
        //   criteriaSourceType: 5,
        // });
        this.criteriaSelect = [];
        break;
      case 'System':
        this.systemCretiaView = value.key;
        // this.rulesGroup.patchValue({
        //   criteriaSourceType: 3,
        // });
        this.criteriaSelect = [];
        break;
      case 'shiftWork':
        this.shiftcriteriaSelect = value.key;
        // this.rulesGroup.patchValue({
        //   criteriaSourceType: 3,
        // });
        this.criteriaSelect = [];
        break;
    }
  }

  addItemsInSelector(value, type) {
    switch (type) {
      case 'Criteria_Category':
        this.criteriaCategoryView = value.key;
        this.criteriaCategoryValue = value.value;
        this.criteriaCategorySelect = [];
        break;
      case 'Progress_Type':
        this.progressTypesView = value.key;
        this.progressTypesValue = value.value;
        this.criteriaCategorySelect = [];
        break;
      case 'Display_Selection':
        this.displaySelectionView = value.key;
        this.displaySelectionValue = value.value;
        this.criteriaCategorySelect = [];
        break;
      case 'Display_Fields':
        this.criteriaCategorySelectView = value.key;
        this.criteriaCategorySelectValue = value.value;
        this.criteriaCategorySelect = [];

        break;
      case 'Display_Description':
        this.displayDescriptionsView = value.key;
        this.displayDescriptionsValue = value.value;
        this.criteriaCategorySelect = [];
        break;
      case 'Reward_Type':
        this.rewardTypesView = value.key;
        this.rewardTypesValue = value.value;
        this.criteriaCategorySelect = [];
        break;
    }
  }

  populateCriteria({ value, key }) {
    if ([1, 2, 3].includes(value)) {
      this.creatiaView = key;
    }
    if ([4, 8, 9].includes(value)) {
      this.boardcreatiaView = key;
    }
    if ([6, 7].includes(value)) {
      this.customFormcreatiaView = key;
    }
    if ([5].includes(value)) {
      this.systemCretiaView = key;
    }
    if ([10, 11, 12].includes(value)) {
      this.shiftcriteriaSelect = key;
    }
  }
  addPointSystem(value) {
    this.pointSytemView = value.title;
    this.rulesGroup.patchValue({
      nonRewardPointSystem: value._id,
    });
  }
  addNomineeQuestion(value) {
    this.nominateView = value.question;
    this.rulesGroup.patchValue({
      nomineeQuestion: value._id,
    });
  }
  toggleMenu() {
    this.toggleSideNav.emit();
  }

  clearProgressTypeData() {
    if ((this.discriptioGroup.get('leaderBoard').value) == false) {
      this.progressTypesView = null;
      this.progressTypesValue = null;
    }

  }

  onSelectChalange(name, value) {
    const Control = this.workFlowFieldControl;
    if ((this.discriptioGroup.get('leaderBoard').value) == true) {
      this.progressTypesView = "Leader Board";
      this.progressTypesValue = 1;
    }
    else {
      this.progressTypesView = null;
      this.progressTypesValue = null;
    }
    this.creatiaView = '';
    this.boardcreatiaView = '';
    this.customFormcreatiaView = '';
    this.systemCretiaView = '';
    this.clearFormArray(Control);
    this.nominateView = '';
    this.badgeTieringArray = [];
    this.rulesGroup.patchValue({
      criteriaSourceType: value,
    });

    this.rulesGroup.patchValue({
      criteriaCountType: 1,
      criteriaCount: 1,
      isCriteriaCategory: false,
      isRewardChallenge: false,
      isProgressSetup: false,
      isTargetCriteria: false,
      selectedChannel: {
        name: '',
        _id: '',
      },
      selectedWall: {
        name: null,
        _id: '',
      },
      selectedCustomForm: {
        title: '',
        _id: '',
      },

      rewardPoints: 0,
      nonRewardPointSystemEnabled: false,
      stopAfterAchievement: false,
      setLimitToMaxRewards: false,

      badgeTiering: false,
      ranks: [],
    });
  }

  getSingleChallenges(id: string) {
    this.isChallengeEdited = true;
    const sub = this.gamificationService
      .getOneChal(id)
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe(
        ({ data }: any) => {
          const adminControl = this.AdminFieldsControl;
          const rankControl = this.ranksControl;
          data.ranks.forEach(({ name, startRange, endRange, icon }) => {
            let field = this.formBuilder.group({
              name,
              startRange,
              endRange,
              icon,
            });
            rankControl.push(field);
          });
          data.administrators.forEach((element) => {
            let field = this.formBuilder.group({
              string: [element.name, Validators.compose([Validators.required])],
              _id: [element._id, Validators.compose([Validators.required])],
            });
            adminControl.push(field);
          });
          const creteria = this.allCriteria.find(
            (cretia) => cretia.value === data.criteriaSourceType
          );
          this.populateCriteria(creteria);
          this.userDetails = data.assignUsers ? data.assignUsers : [];
          this.UpdateduserDetails = data.assignUsers
            ? getAssignedUsers(data.assignUsers)
            : [];
          this.discriptioGroup.patchValue({
            title: data.title,
            description: data.description,
            challengeStart: data.challengeStart,
            challengeEnd: data.challengeEnd,
            publishStart: data.publishStart,
            publishEnd: data.publishEnd,
            isTeam: data?.isTeam,
            icon: data.icon,
            status: 1,
            leaderBoard: data.leaderBoard,
          });
          this.rulesGroup.patchValue({
            criteriaType: data.criteriaType,
            criteriaSourceType: data.criteriaSourceType,
            criteriaCountType: data.criteriaCountType,
            maximumRewards: data.maximumRewards,
            criteriaCount: data.criteriaCount,
            selectedChannel: {
              name: data.selectedChannel ? data.selectedChannel.name : '',
              _id: data.selectedChannel ? data.selectedChannel._id : '',
            },
            selectedWall: {
              name: data.selectedWall ? data.selectedWall.wallName : null,
              _id: data.selectedWall ? data.selectedWall._id : '',
            },
            selectedCustomForm: {
              title: data.selectedCustomForm
                ? data.selectedCustomForm.title
                : '',
              _id: data.selectedCustomForm ? data.selectedCustomForm._id : '',
            },
            rewardPoints: data.rewardPoints,
            nonRewardPointSystem: data.nonRewardPointSystem,
            nonRewardPointSystemEnabled: data.nonRewardPointSystemEnabled,
            stopAfterAchievement: data.stopAfterAchievement,
            setLimitToMaxRewards: data.setLimitToMaxRewards,
            badgeTiering: data.badgeTiering,
          });
          this.rewardPoints = data.rewardPoints;
          this.schemeData = data.selectedScheme;
        },
        (error) => {
          this.toastService.error('No data found');
        }
      );
  }

  updatedUserDetails(userDetails) {
    this.UpdateduserDetails = userDetails;
  }

  updateTeamUserDetails(teamUserDetails) {
    this.UpdatedTeamUserDetails = teamUserDetails;
    this.teamsGroup.patchValue({
      teamUserDetails: teamUserDetails,
    });
  }

  getModifiedTeams() {
    const newArrayOfObj = this.teamDetails?.map(({
      title: name,
      icon: logo,
      teamUserDetails: assignUsers,
      id: _id,
    }) => ({
      name,
      logo,
      assignUsers,
      _id,
    }));

    newArrayOfObj.forEach((data, index) => {
      if(data._id == "") delete data._id;
      data?.assignUsers.forEach((item, index) => {
        if (item?.businessUnits[0]?._id == 1) {
          item.businessUnits = [];
        }
      });
    });
    return newArrayOfObj;
  }

  selectIsTeamRequired(isTeam: boolean) {
    if (isTeam) {
      this.onSelectChalange('CustomForm', 5);
      this.rulesGroup.patchValue({
        criteriaType: 5,
      });
    }
  }

  onSubmitEvents(value) {
    this.isLoading = true;
    const {
      selectedChannel,
      selectedWall,
      selectedCustomForm,
      criteriaSourceType,
      ...rest
    } = this.rulesGroup.value;

    if (criteriaSourceType && !this.shiftcriteriaSelectNumber) {
      this.shiftcriteriaSelectNumber = criteriaSourceType;
    }
    const descriptionValid = this.validateDescription('submit');
    if (!descriptionValid) {
      this.isLoading = false;
      return;
    }
    switch (value) {
      case 1:
        const data1 = {
          challenge: {
            ...this.discriptioGroup.value,
            ...rest,
            criteriaSourceType: this.shiftcriteriaSelectNumber,
            criteriaType: value,
            selectedChannel: this.rulesGroup.get('selectedChannel._id').value
              ? this.rulesGroup.get('selectedChannel._id').value
              : '',
            assignUsers:
              this.userDetails.length > 0 ? this.UpdateduserDetails : [],
            administrators: this.AdminFieldsControl.value.map(
              (item) => item._id
            ),
          },
        };
        this.challangesId
          ? this.gamificationService
            .updateChallenges(data1, this.challangesId)
            .subscribe(
              (data: any) => {
                this.toastService.success('Challenge updated ');
                this.isLoading = false;

                this.overViewEvent.emit(null);
              },
              (error: HttpErrorResponse) => {
                this.isLoading = false;
                // this.toastService.error(error.error.message);
              }
            )
          : this.gamificationService.createChallenges(data1).subscribe(
            (data: any) => {
              this.toastService.success('Challenge created ');
              this.isLoading = false;

              this.overViewEvent.emit(null);
            },
            (error: HttpErrorResponse) => {
              this.isLoading = false;
              // this.toastService.error(error.error.message);
            }
          );
        break;
      case 2:
        const data2 = {
          challenge: {
            ...this.discriptioGroup.value,
            ...rest,
            criteriaSourceType: this.shiftcriteriaSelectNumber,
            criteriaType: value,
            selectedWall: this.rulesGroup.get('selectedWall._id').value
              ? this.rulesGroup.get('selectedWall._id').value
              : null,
            assignUsers:
              this.userDetails.length > 0 ? this.UpdateduserDetails : [],
            administrators: this.AdminFieldsControl.value.map(
              (item) => item._id
            ),
          },
        };
        this.challangesId
          ? this.gamificationService
            .updateChallenges(data2, this.challangesId)
            .subscribe(
              (data: any) => {
                this.toastService.success('Challenge updated ');
                this.isLoading = false;

                this.overViewEvent.emit(null);
              },
              (error: HttpErrorResponse) => {
                this.isLoading = false;

                // this.toastService.error(error.error.message);
              }
            )
          : this.gamificationService.createChallenges(data2).subscribe(
            (data: any) => {
              this.toastService.success('Challenge created ');
              this.isLoading = false;
              this.overViewEvent.emit(null);
            },
            (error: HttpErrorResponse) => {
              this.isLoading = false;
              // this.toastService.error(error.error.message);
            }
          );
        break;
      case 3:
        const data3 = {
          challenge: {
            ...this.discriptioGroup.value,
            ...rest,
            criteriaSourceType: this.shiftcriteriaSelectNumber,
            criteriaType: value,
            assignUsers:
              this.userDetails.length > 0 ? this.UpdateduserDetails : [],
            administrators: this.AdminFieldsControl.value.map(
              (item) => item._id
            ),
          },
        };
        this.challangesId
          ? this.gamificationService
            .updateChallenges(data3, this.challangesId)
            .subscribe(
              (data: any) => {
                this.toastService.success('Challenge updated');
                this.isLoading = false;

                this.overViewEvent.emit(null);
              },
              (error: HttpErrorResponse) => {
                this.isLoading = false;
                // this.toastService.error(error.error.message);
              }
            )
          : this.gamificationService.createChallenges(data3).subscribe(
            (data: any) => {
              this.toastService.success('Challenge created');
              this.isLoading = false;
              this.overViewEvent.emit(null);
            },
            (error: HttpErrorResponse) => {
              this.isLoading = false;
              // this.toastService.error(error.error.message);
            }
          );
        break;
      case 4:
        const data4 = {
          challenge: {
            ...this.discriptioGroup.value,
            ...rest,
            selectedWall: this.rulesGroup.get('selectedWall._id').value
              ? this.rulesGroup.get('selectedWall._id').value
              : null,
            assignUsers:
              this.userDetails.length > 0 ? this.UpdateduserDetails : [],
            administrators: this.AdminFieldsControl.value.map(
              (item) => item._id
            ),
            directRewardCategory: false,
          },
        };

        this.challangesId
          ? this.gamificationService
            .updateChallenges(data4, this.challangesId)
            .subscribe(
              (data: any) => {
                this.toastService.success('Challenge updated ');
                this.isLoading = false;
                this.overViewEvent.emit(null);
              },
              (error: HttpErrorResponse) => {
                this.isLoading = false;
                // this.toastService.error(error.error.message);
              }
            )
          : this.gamificationService.createChallenges(data4).subscribe(
            (data: any) => {
              this.toastService.success('Challenge created ');
              this.isLoading = false;
              this.overViewEvent.emit(null);
            },
            (error: HttpErrorResponse) => {
              this.isLoading = false;
              // this.toastService.error(error.error.message);
            }
          );
        break;
      case 5:
        const data5 = {
          challenge: {
            ...this.discriptioGroup.value,
            ...rest,
            criteriaSourceType: this.shiftcriteriaSelectNumber,
            criteriaType: value,
            isTeam: (this.discriptioGroup.get('isTeam').value),
            teams: this.getModifiedTeams(),
            fieldOptions: !this.challangesId
              ? this.workFlowFieldControl.length
                ? this.workFlowFieldControl.value.map(
                  ({ fieldOptionValue, formStatusValue }) => ({
                    fieldOptionValue,
                    formStatusValue,
                  })
                )
                : []
              : this.challangesData.fieldOptions,
            selectedCustomForm: this.rulesGroup.get('selectedCustomForm._id')
              .value
              ? this.rulesGroup.get('selectedCustomForm._id').value
              : '',
            assignUsers:
              this.userDetails.length > 0 ? this.UpdateduserDetails : [],
            administrators: this.AdminFieldsControl.value.map(
              (item) => item._id
            ),
          },
        };
        if (data5.challenge.criteriaSourceType === 7 && this.challangesId) {
          data5.challenge.nomineeQuestion = this.challangesData.nomineeQuestion?._id;
        }
        if (this.discriptioGroup.get('isTeam').value) {
          data5.challenge.isCriteriaCategories = this.rulesGroup.get('isCriteriaCategory').value,
            data5.challenge.criteriaCategories = this.criteriaCategoryValue || null,
            data5.challenge.isNormalCount = true,
            data5.challenge.isRewardChallenge = this.rulesGroup.get('isRewardChallenge').value,
            data5.challenge.rewardType = this.rewardTypesValue,
            data5.challenge.isProgressStatusSetup = this.rulesGroup.get('isProgressSetup').value,
            data5.challenge.progressType = this.progressTypesValue || null,
            data5.challenge.displaySelection = this.displaySelectionValue || null,
            data5.challenge.displayDescription = this.displayDescriptionsValue || null,
            data5.challenge.displayField = this.criteriaCategorySelectValue || null,
            data5.challenge.isTargetCriteria = this.rulesGroup.get('isTargetCriteria').value,
            data5.challenge.targetCriteriaCount = this.rulesGroup.get('targetCount').value || null,
            data5.challenge.targetDescription = this.rulesGroup.get('targetCountDescription').value || null
        };

        this.challangesId
          ? this.gamificationService
            .updateChallenges(data5, this.challangesId)
            .subscribe(
              (data: any) => {
                this.toastService.success('Challenge updated ');
                this.isLoading = false;
                this.overViewEvent.emit(null);
              },
              (error: HttpErrorResponse) => {
                this.isLoading = false;
                this.toastService.error(error.error.message);
              }
            )
          : this.gamificationService.createChallenges(data5).subscribe(
            (data: any) => {
              this.toastService.success('Challenge created ');
              this.isLoading = false;
              this.overViewEvent.emit(null);
            },
            (error: HttpErrorResponse) => {
              this.isLoading = false;
              this.toastService.error(error.error.message);
            }
          );
        break;
      case 6:
        const data6 = {
          ...this.discriptioGroup.value,
          ...rest,
          criteriaSourceType: this.shiftcriteriaSelectNumber,
          criteriaType: value,
          selectedScheme: this.schemeData.map((v) => v._id),
          ranks: this.badgeTieringArray,
          fieldOptions: !this.challangesId
            ? this.workFlowFieldControl.length
              ? this.workFlowFieldControl.value.map(
                ({ fieldOptionValue, formStatusValue }) => ({
                  fieldOptionValue,
                  formStatusValue,
                })
              )
              : []
            : this.challangesData.fieldOptions,
          assignUsers:
            this.userDetails.length > 0 ? this.UpdateduserDetails : [],
          administrators: this.AdminFieldsControl.value.map((item) => item._id),
        };

        delete data6.nomineeQuestion;
        delete data6.nonRewardPointSystem;
        delete data6.nonRewardPointSystemEnabled;

        const challengePayload = {
          challenge: data6,
        };

        // return;
        this.challangesId
          ? this.gamificationService
            .updateChallenges(challengePayload, this.challangesId)
            .subscribe(
              (data: any) => {
                this.toastService.success('Challenge updated');
                this.isLoading = false;
                this.overViewEvent.emit(null);
              },
              (error: HttpErrorResponse) => {
                this.isLoading = false;
                // this.toastService.error(error.error.message);
              }
            )
          : this.gamificationService
            .createChallenges(challengePayload)
            .subscribe(
              (data: any) => {
                this.toastService.success('Challenge created ');
                this.isLoading = false;
                this.overViewEvent.emit(null);
              },
              (error: HttpErrorResponse) => {
                this.isLoading = false;
                // this.toastService.error(error.error.message);
              }
            );
        break;
    }
  }
  saveASDraft(value) {
    const {
      selectedChannel,
      selectedWall,
      selectedCustomForm,
      criteriaSourceType,
      ...rest
    } = this.rulesGroup.value;
    switch (value) {
      case 1:
        const data1 = {
          ...this.discriptioGroup.value,
          ...rest,
          status: 1,
          criteriaSourceType,
          selectedChannel: this.rulesGroup.get('selectedChannel._id').value
            ? this.rulesGroup.get('selectedChannel._id').value
            : '',
          assignUsers:
            this.userDetails.length > 0 ? this.UpdateduserDetails : [],
          administrators: this.AdminFieldsControl.value.map((item) => item._id),
        };
        this.challangesId
          ? this.gamificationService
            .updateChallenges(data1, this.challangesId)
            .subscribe(
              (data: any) => {
                this.toastService.success('Challange updated ');

                this.overViewEvent.emit(null);
              },
              (error: HttpErrorResponse) => {
                // this.toastService.error(error.error.message);
              }
            )
          : this.gamificationService.createChallenges(data1).subscribe(
            (data: any) => {
              this.toastService.success('Challange created ');

              this.overViewEvent.emit(null);
            },
            (error: HttpErrorResponse) => {
              // this.toastService.error(error.error.message);
            }
          );
        break;
      case 2:
        const data2 = {
          ...this.discriptioGroup.value,
          ...rest,
          status: 1,

          criteriaSourceType,
          selectedWall: this.rulesGroup.get('selectedWall._id').value
            ? this.rulesGroup.get('selectedWall._id').value
            : '',
          assignUsers:
            this.userDetails.length > 0 ? this.UpdateduserDetails : [],
          administrators: this.AdminFieldsControl.value.map((item) => item._id),
        };

        this.challangesId
          ? this.gamificationService
            .updateChallenges(data2, this.challangesId)
            .subscribe(
              (data: any) => {
                this.toastService.success('Challange updated ');

                this.overViewEvent.emit(null);
              },
              (error: HttpErrorResponse) => {
                this.toastService.error(error?.error?.message);
              }
            )
          : this.gamificationService.createChallenges(data2).subscribe(
            (data: any) => {
              this.toastService.success('Challange created ');

              this.overViewEvent.emit(null);
            },
            (error: HttpErrorResponse) => {
              this.toastService.error(error?.error?.message);
            }
          );
        break;
      case 3:
        const data3 = {
          ...this.discriptioGroup.value,
          ...rest,
          status: 1,

          criteriaSourceType,
          assignUsers:
            this.userDetails.length > 0 ? this.UpdateduserDetails : [],
          administrators: this.AdminFieldsControl.value.map((item) => item._id),
        };
        this.challangesId
          ? this.gamificationService
            .updateChallenges(data3, this.challangesId)
            .subscribe(
              (data: any) => {
                this.toastService.success('Challange updated ');

                this.overViewEvent.emit(null);
              },
              (error: HttpErrorResponse) => {
                // this.toastService.error(error.error.message);
              }
            )
          : this.gamificationService.createChallenges(data3).subscribe(
            (data: any) => {
              this.toastService.success('Challange created ');

              this.overViewEvent.emit(null);
            },
            (error: HttpErrorResponse) => {
              // this.toastService.error(error.error.message);
            }
          );
        break;
      case 4:
        const data4 = {
          ...this.discriptioGroup.value,
          ...rest,
          status: 1,

          assignUsers:
            this.userDetails.length > 0 ? this.UpdateduserDetails : [],
          administrators: this.AdminFieldsControl.value.map((item) => item._id),
        };
        this.challangesId
          ? this.gamificationService
            .updateChallenges(data4, this.challangesId)
            .subscribe(
              (data: any) => {
                this.toastService.success('Challange updated ');

                this.overViewEvent.emit(null);
              },
              (error: HttpErrorResponse) => {
                // this.toastService.error(error.error.message);
              }
            )
          : this.gamificationService.createChallenges(data4).subscribe(
            (data: any) => {
              this.toastService.success('Challange created ');

              this.overViewEvent.emit(null);
            },
            (error: HttpErrorResponse) => {
              // this.toastService.error(error.error.message);
            }
          );
        break;
      case 5:
        const data5 = {
          ...this.discriptioGroup.value,
          ...rest,
          status: 1,

          criteriaSourceType,
          fieldOptions: !this.challangesId
            ? this.workFlowFieldControl.length
              ? this.workFlowFieldControl.value.map(
                ({ fieldOptionValue, formStatusValue }) => ({
                  fieldOptionValue,
                  formStatusValue,
                })
              )
              : []
            : this.challangesData.fieldOptions,
          selectedCustomForm: this.rulesGroup.get('selectedCustomForm._id')
            .value
            ? this.rulesGroup.get('selectedCustomForm._id').value
            : '',
          assignUsers:
            this.userDetails.length > 0 ? this.UpdateduserDetails : [],
          administrators: this.AdminFieldsControl.value.map((item) => item._id),
        };
        this.challangesId
          ? this.gamificationService
            .updateChallenges(data5, this.challangesId)
            .subscribe(
              (data: any) => {
                this.toastService.success('Challange updated ');

                this.overViewEvent.emit(null);
              },
              (error: HttpErrorResponse) => {
                // this.toastService.error(error.error.message);
              }
            )
          : this.gamificationService.createChallenges(data5).subscribe(
            (data: any) => {
              this.toastService.success('Challange created ');

              this.overViewEvent.emit(null);
            },
            (error: HttpErrorResponse) => {
              // this.toastService.error(error.error.message);
            }
          );
        break;
    }
  }
  getChallenges() {
    this.gamificationService.getChallenges(this.data).subscribe(
      (data: any) => { },
      (error: HttpErrorResponse) => {
        // this.toastService.error(error.error.message);
      }
    );
  }

  validateTeamStepper(from = 'next') {
    let valid = true;
    if (!this.teamDetails.length) {
      valid = false;
      this.toastService.error('Please add atleast one team');
    }
    else if (from === 'next' && valid) {
      return this.stepper.next();
    }
    return valid;
  }

  validateRulesStepper(from = 'next') {
    let valid = true;
    if (!this.rulesGroup.value.criteriaSourceType) {
      valid = false;
      this.toastService.error('CriteriaSource is required');
    } else if (!!this.rulesGroup.value.isCriteriaCategory && !this.criteriaCategoryValue) {
      valid = false;
      this.toastService.error('Please Select Criteria Category');
    } else if (!!this.rulesGroup.value.isRewardChallenge && this.rulesGroup.value.rewardPoints == "") {
      valid = false;
      this.toastService.error('Please Add Reward Points');
    } else if (!!this.rulesGroup.value.isProgressSetup && !this.progressTypesValue) {
      valid = false;
      this.toastService.error('Please Add Progress Type');
    }
    else if ((this.progressTypesValue == 2) && !this.criteriaCategoryValue) {
      valid = false;
      this.toastService.error('Please Select Criteria Category');
    } else if (!!this.rulesGroup.value.isProgressSetup && !this.displaySelectionValue) {
      valid = false;
      this.toastService.error('Please Add Display Selection');
    } else if (!!this.rulesGroup.value.isProgressSetup && !this.displayDescriptionsValue) {
      valid = false;
      this.toastService.error('Please Add Display Description');
    }
    else if (!!this.rulesGroup.value.isProgressSetup && !this.criteriaCategorySelectValue) {
      valid = false;
      this.toastService.error('Please Add Display Field');
    }
    else if (!!this.rulesGroup.value.isTargetCriteria && this.rulesGroup.value.targetCount == "") {
      valid = false;
      this.toastService.error('Please Add Target Count');
    }
    else if (!!this.rulesGroup.value.isTargetCriteria && this.rulesGroup.value.targetCountDescription == "") {
      valid = false;
      this.toastService.error('Please Add Target Count Description');
    }
    else if (this.discriptioGroup.value.isTeam && !this.teamDetails.length) {
      valid = false;
      this.toastService.error('Please add atleast one team');
    }
    else if (from === 'next' && valid) {
      return this.stepper.next();
    }
    return valid;
  }

  validateDescription(from = 'next') {
    let valid = true;
    if (this.discriptioGroup.value.title.trim() === "") {
      valid = false;
      this.toastService.error('Title is required');
    } else if (this.discriptioGroup.value.description.trim() === "") {
      valid = false;
      this.toastService.error('Description is required');
    } else if (!this.discriptioGroup.value.icon) {
      valid = false;
      this.toastService.error('Icon is required');
    } else if (!this.discriptioGroup.value.publishStart) {
      valid = false;
      this.toastService.error('Publish start date is required');
    } else if (!this.discriptioGroup.value.publishEnd) {
      valid = false;
      this.toastService.error('Publish end date is required');
    } else if (!this.discriptioGroup.value.challengeStart) {
      valid = false;
      this.toastService.error('Challenge start date is required');
    } else if (!this.discriptioGroup.value.challengeEnd) {
      valid = false;
      this.toastService.error('Challenge end date is required');
    } else if (
      !this.challangesId &&
      new Date().getTime() >
      new Date(this.discriptioGroup.value.publishStart).getTime()
    ) {
      valid = false;
      this.toastService.error('Publish start time should be from future');
    } else if (
      new Date(this.discriptioGroup.value.publishEnd).getTime() <
      new Date(this.discriptioGroup.value.publishStart).getTime()
    ) {
      valid = false;
      this.toastService.error(
        'Publish end should be greater then publish start'
      );
    } else if (
      new Date(this.discriptioGroup.value.challengeStart).getTime() <
      new Date(this.discriptioGroup.value.publishStart).getTime()
    ) {
      valid = false;
      this.toastService.error(
        'challenge start should be greater then publish start'
      );
    } else if (
      new Date(this.discriptioGroup.value.publishEnd).getTime() <
      new Date(this.discriptioGroup.value.challengeEnd).getTime()
    ) {
      valid = false;
      this.toastService.error('challenge end should be less then publish end');
    } else if (
      new Date(this.discriptioGroup.value.challengeEnd).getTime() <
      new Date(this.discriptioGroup.value.challengeStart).getTime()
    ) {
      valid = false;
      this.toastService.error(
        'Challenge end should be greater then challenge start'
      );
    }
    if (from === 'next' && valid) {
      return this.stepper.next();
    }
    return valid;
  }
  getChallengeData() {
    const subscribe = this.gamificationService
      .getChallengesNewSingle(this.challangesData._id)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const data = response.data;
          this.challangesData = data;
          this.challangesId = this.challangesData._id;
          this.setEditData();
        },
        (error) => {
          this.toastService.error('Something went wrong');
        }
      );
  }

  getUpdatedTeams(data) {
    const newArrayOfObj = data?.map(({
      name: title,
      logo: icon,
      assignUsers: teamUserDetails,
      _id: id,
    }) => ({
      title,
      icon,
      teamUserDetails,
      id
    }));
    return newArrayOfObj;
  }

  setEditData() {
    let data = this.challangesData;
    this.isChallengeEdited = true;
    const adminControl = this.AdminFieldsControl;
    const rankControl = this.ranksControl;
    data.ranks?.forEach(({ name, startRange, endRange, icon }) => {
      let field = this.formBuilder.group({
        name,
        startRange,
        endRange,
        icon,
      });
      rankControl.push(field);
    });
    this.nomineeQuestion = data.nomineeQuestion?.question;
    data.administrators?.forEach((element) => {
      let field = this.formBuilder.group({
        string: [element.name, Validators.compose([Validators.required])],
        _id: [element._id, Validators.compose([Validators.required])],
      });
      adminControl.push(field);
    });
    const creteria = this.allCriteria?.find(
      (cretia) => cretia.value === data.criteriaSourceType
    );
    this.populateCriteria(creteria);

    const assignUsers1 = data.assignUsers?.map((item) => {
      if (item.allBuToken) {
        {
          item.businessUnits = [
            { _id: 'All Business Unit', name: 'All Business Unit' },
          ];
        }
      } else {
        item.businessUnits = item?.businessUnits?.map((bu) => {
          bu.name =
            bu?.sectionId.departmentId.companyId.name +
            ' > ' +
            bu?.sectionId.departmentId.name +
            ' > ' +
            bu?.sectionId.name +
            '> ' +
            bu?.name;
          bu._id = bu._id;
          delete bu.sectionId;
          delete bu.status;
          return bu;
        });
      }
      return item;
    });
    data.assignUsers = assignUsers1;
    this.userDetails = assignUsers1 ? assignUsers1 : [];
    this.workFlowStatusInfo = data.workFlowStatusInfo || [];

    this.UpdateduserDetails = data.assignUsers
      ? getAssignedUsers(data.assignUsers)
      : [];

    this.discriptioGroup.patchValue({
      title: data.title,
      description: data.description,
      challengeStart: data.challengeStart,
      challengeEnd: data.challengeEnd,
      publishStart: data.publishStart,
      publishEnd: data.publishEnd,
      icon: data.icon,
      status: 1,
      leaderBoard: data.leaderBoard,
      isTeam: data.isTeam,
    });

    if (data.nonRewardPointSystemEnabled) {
      this.pointSytemView = data.nonRewardPointSystem?.title;
    }
    this.rulesGroup.patchValue({
      criteriaType: data.criteriaType,
      criteriaSourceType: data.criteriaSourceType,
      criteriaCountType: data.criteriaCountType,
      maximumRewards: data.maximumRewards,
      criteriaCount: data.criteriaCount,
      selectedChannel: {
        name: data.selectedChannel ? data.selectedChannel.name : '',
        _id: data.selectedChannel ? data.selectedChannel._id : '',
      },
      selectedWall: {
        name: data.selectedWall ? data.selectedWall.wallName : null,
        _id: data.selectedWall ? data.selectedWall._id : '',
      },
      selectedCustomForm: {
        title: data.selectedCustomForm ? data.selectedCustomForm.title : '',
        _id: data.selectedCustomForm ? data.selectedCustomForm._id : '',
      },
      rewardPoints: data.rewardPoints,
      nonRewardPointSystem: data.nonRewardPointSystem,
      nonRewardPointSystemEnabled: data.nonRewardPointSystemEnabled
        ? data.nonRewardPointSystemEnabled
        : false,
      stopAfterAchievement: data.stopAfterAchievement,
      setLimitToMaxRewards: data.setLimitToMaxRewards,
      badgeTiering: data.badgeTiering,
      isCriteriaCategory: data.isCriteriaCategories,
      isRewardChallenge: data.isRewardChallenge,
      isProgressSetup: data.isProgressStatusSetup,
      isTargetCriteria: data.isTargetCriteria,
      targetCount: data.targetCriteriaCount,
      targetCountDescription: data.targetDescription,
    });
    this.criteriaCategoryView = data.criteriaCategories?.question,
      this.progressTypesView = this.getViewNameForProgressType(data.progressType),
      this.displaySelectionView = this.getViewNameForRewardType(data.displaySelection),
      this.displayDescriptionsView = data.displayDescription?.question,
      this.criteriaCategorySelectView = data.displayField?.question
    this.rewardPoints = data.rewardPoints || 0;
    this.schemeData = data?.selectedScheme;
    this.teamDetails = this.getUpdatedTeams(data.teams);
    this.criteriaCategoryValue = data.criteriaCategories?._id;
    this.progressTypesValue = data.progressType;
    this.displaySelectionValue = data.displaySelection;
    this.criteriaCategorySelectValue = data?.displayField?._id;
    this.displayDescriptionsValue = data?.displayDescription?._id;
    this.rewardTypesValue = data?.rewardType;
  }

  getViewNameForProgressType(key) {
    switch (key) {
      case 1:
        return 'Leader Board';
      case 2:
        return 'Digital Stamp';
    }
  }



  getViewNameForRewardType(key) {
    switch (key) {
      case 1:
        return 'See own only';
      case 2:
        return 'See own or other';
      case 3:
        return 'See own + others'
      case 4:
        return 'See own + all combined';
    }
  }

  getBoardsAndChannels() {
    this.gamificationService.getBoardsAndChannels().subscribe(
      ({ data: { boards, channels, customForms, pointSystems, scheme } }) => {
        this.allChannelAndBoards = {
          boards,
          channels,
          customForms,
          pointSystems,
          scheme,
        };
        const boardData = boards.map(({ _id, wallName }) => ({
          _id,
          wallName,
        }));
        const channelData = channels.map(({ _id, name }) => ({ _id, name }));
        const customFromData = customForms.map(({ _id, title }) => ({
          _id,
          title,
        }));
        this.boardData = boardData;
        this.channelData = channelData;
        this.customfromData = customFromData;
        this.poinstSystemData = pointSystems;
        // this.schemeDataArr = scheme;
      },
      (error: HttpErrorResponse) => {
        // this.toastService.error(error.error.message);
      }
    );
  }

  deleteTeamEntry(index) {
    this.teamDetails.splice(index, 1)
    this.toastService.success('Team removed successfully', '', {
      timeOut: 3000,
      positionClass: 'toast-top-right',
    });
  }

  getAllFieldsForCustomForm(id) {
    const subscribe = this.gamificationService
      .getFieldsfromCustomForm(id)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const data = response.data;
          this.criteriaCategory = this.getRequiredFieldData(response.data, 'category_criteria');
          this.displayDescriptions = this.getRequiredFieldData(response.data, 'display_desc');
          this.displayFieldsData = this.getRequiredFieldData(response.data, 'fields_data');

        },
        (error) => {
          this.toastService.error('Something went wrong');
        }
      );
  }

  getRequiredFieldData(items, key) {
    if (key == "category_criteria") {
      let filteredData = [];
      items.filter((value) => ((value.type == 11) || (value.type == 2))).map((data) => {
        let derivedData = {
          value: data?._id,
          key: data?.question,
        }
        filteredData.push(derivedData);
      });
      return filteredData;
    }
    if (key == "display_desc") {
      let filteredData = [];
      items.filter((value) => (value.type == 10)).map((data) => {
        let derivedData = {
          value: data?._id,
          key: data?.question,
        }
        filteredData.push(derivedData);
      });
      return filteredData;
    }
    let filteredData = [];
    items.filter((value) => ((value.type == 1) || (value.type == 8))).map((data) => {
      let derivedData = {
        value: data?._id,
        key: data?.question,
      }
      filteredData.push(derivedData);
    });
    return filteredData;
  }

  editTeamDetail(item, index) {
    this.isEditTeam = true;
    this.editTeamIndex = index;
    this.teamsGroup.patchValue({
      icon: item?.icon,
      title: item?.title,
      id: item?.id,
      teamUserDetails: item?.teamUserDetails,
    });

    this.teamUserDetails = item?.teamUserDetails;
  }

  iconUpload(event, key) {
    if (event.target.files && event.target.files.length) {
      let file: File = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        const fileUpload = this.gamificationService.uploadFile(file)
          .pipe(
            finalize(() => {
              fileUpload.unsubscribe();
            })
          )
          .subscribe(
            (data: any) => {
              if (key == "teamLogo") {
                this.teamsGroup.patchValue({
                  icon: data?.data?.exactPath,
                });
              }
              else {
                this.discriptioGroup.patchValue({
                  icon: data?.data?.exactPath,
                });
              }
            },
            (error: HttpErrorResponse) => {
              this.imageLoader = false;
              this.toastService.error(error?.error?.message);
            }
          );
      } else {
        this.toastService.error(COMMON_MESSAGES.UPLOAD_ONLY_IMAGE);
        event.target.value = '';
        file = null;
      }

    }
  }

  uploadImage(image) {
    const fileUpload = this.gamificationService
      .uploadImageChallange(image[image?.length - 1])
      .pipe(
        finalize(() => {
          fileUpload.unsubscribe();
        })
      )
      .subscribe(
        (data: any) => {
          this.imagePreview = data;
        },
        (error: HttpErrorResponse) => {
        }
      );
  }
  onDeleteIcon() {
    this.discriptioGroup.patchValue({
      icon: '',
    });
    this.myInputVariable?.nativeElement ? this.myInputVariable.nativeElement.value = '' : false ;
  }

  onDeleteTeamIcon() {
    this.teamsGroup.patchValue({
      icon: '',
    });
  }

  badgeiconUpload(event) {
    if (event.target.files && event.target.files.length) {
      let file: File = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        const fileUpload = this.gamificationService.uploadFile(file)
          .pipe(
            finalize(() => {
              fileUpload.unsubscribe();
            })
          )
          .subscribe(
            (data: any) => {
              this.createRanks.patchValue({
                icon: data?.link,
              });
            },
            (error: HttpErrorResponse) => {
              console.error(error);
            }
          );
      } else {
        this.toastService.error(COMMON_MESSAGES.UPLOAD_ONLY_IMAGE);
        event.target.value = '';
        file = null;
      }

    }
  }
  onDeleteBadgeImage() {
    this.createRanks.patchValue({
      icon: '',
    });
  }

  onSeachAdmin(value) {
    this.userPTable.limit = 10;
    this.userPTable.search = value.term;
    this.loadAdminUsers();
  }
  loadMoreAdminItems(value) {
    this.userPTable.limit = this.userPTable.limit + 10;
    this.loadAdminUsers();
  }

  onClearAdminDropdown(value) {
    if (value === undefined) {
      this.discriptioGroup.patchValue({
        admin: [],
      });
      this.userPTable.search = '';
      this.loadAdminUsers();

      this.fieldNeedsToUpdate = [];
      this.userPTable.data = [];
    }
  }

  get AdminFieldsControl(): FormArray {
    return this.adminGroup.get('administrators') as FormArray;
  }

  async addLoginFields(value) {
    const Control = this.AdminFieldsControl;
    const index = await Control.value.findIndex((x) => x._id === value._id);

    if (index > -1) {
      this.toastService.error(`${value.name} is already added`, '', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
      this.adminSelect = [];
    } else {
      let field = this.formBuilder.group({
        string: [value.name, Validators.compose([Validators.required])],
        _id: [value._id, Validators.compose([Validators.required])],
      });
      Control.push(field);
      this.adminSelect = [];
    }
  }

  deleteAdmin(index) {
    const Control = this.AdminFieldsControl;
    Control.removeAt(index);
  }

  loadAdminUsers() {
    // const { data, ...rest } = this.userPTable;
    const subscribe = this.setupService
      .getAdminWithfilter()
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          // const { count, data } = response.data;
          this.userPTable.data = response.data;
          if (this.userPTable.data && this.userPTable.data.length > 0) {
            const Control = this.AdminFieldsControl;

            const defaultSelectedData: any = this.userPTable.data?.find(
              (x) => x._id == this.loggedInUserId
            );
            let dataIndex = Control.value.findIndex(
              (item) => item._id == defaultSelectedData._id
            );
            if (dataIndex <= -1 && defaultSelectedData) {
              let field = this.formBuilder.group({
                string: [
                  defaultSelectedData?.name,
                  Validators.compose([Validators.required]),
                ],
                _id: [
                  defaultSelectedData?._id,
                  Validators.compose([Validators.required]),
                ],
              });
              Control.push(field);
            }
          }
        },
        (error) => {
          this.userPTable.data = [];

          // this.tostService.error('No list found');
        }
      );
  }

  getBusinessUnitList() {
    let allBUAccess: any = JSON.parse(localStorage.getItem('allBUAccess'));
    if (allBUAccess == 1) {
      this.planBuUnitDropDown.unshift({
        _id: 'all',
        fullName: 'All Business Units',
      });
    }
    this.planBuUnitDropDownLoading = true;
    const payload = { userId: this.loggedInUserId };
    this.buUnit.getBusinessUnit(payload).subscribe(
      (res) => {
        if (res?.data?.data?.planBussinessUnitId?.length > 0) {
          this.planBuUnitDropDown.push(...res?.data?.data?.planBussinessUnitId);
        }
        this.planBuUnitDropDownLoading = false;
      },
      (err) => {
        this.planBuUnitDropDownLoading = false;
      }
    );
  }

  addDay(d) {
    if (this.addedDay.indexOf(d) == -1) {
      this.addedDay.push(d);
    } else {
      this.addedDay.splice(this.addedDay.indexOf(d), 1);
    }
  }

  addBundle() {
    this.addedDay.forEach((val) => {
      const bundle = {
        day: val.dayName,
        count: this.bundleCount,
      };

      this.bundleArr.push(bundle);
    });

    this.addedDay = [];
  }

  clearBundleLabel() { }

  initAssignuserDetailsFrom() {
    return (this.assignUserDetailsForm = this.formBuilder.group({
      businessUnits: this.formBuilder.array([], [Validators.required]),
      buFilterType: [Validators.required],
      appointments: this.formBuilder.array([]),
      subSkillSets: this.formBuilder.array([]),
      user: this.formBuilder.array([]),
      admin: this.formBuilder.array([]),
      allBuToken: '',
      allBuTokenStaffId: '',
      customField: this.formBuilder.array([]),
    }));
  }
  onClearPlanBuUnitDropdown(event) {
    console.log(event);
  }

  validateURLImage(str) {
    console.log('ssss', str)
    const e=  generateImageUrl(str)
    console.log('eeee', e);
    return e;
  }
}
