import { async } from '@angular/core/testing';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from '../../../core/service/storage.service';
import {
  Company,
  LoginForm,
  StorageItem,
} from '../../../core/model/definition.model';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap, take } from 'rxjs/operators';
import { companyNameResponse } from 'src/app/core/model/users.model';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public readonly JWT_TOKEN = 'usersToken';
  private baseUrl: string;
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  isUserVerified: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private router: Router
  ) {
    this.baseUrl = environment.baseUrl;
    this.loadToken();
  }
  private get Headers(): HttpHeaders {
    return new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }
  async loadToken() {
    const token = await this.storageService.getByKey(this.JWT_TOKEN);

    if (token) {
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
      const company = await this.loadCompany();
      this.router.navigate(['/users/login/', company]);
    }
  }

  login(payload: LoginForm): Observable<any> {
    return this.http.post(`${this.baseUrl}/login`, payload).pipe(
      tap((data) => {
        const item: StorageItem = { key:this.JWT_TOKEN , value: data?.data?.token };
        const item2: StorageItem = { key:'staffId' , value: data?.data?.data?.staffId };
        this.storageService.addItem(item);
        this.storageService.addItem(item2);
        const tokens = {
          accessToken : data.data.token,
          refreshToken : data.data.refreshToken,
        }
        localStorage.setItem('loggedInUserAllTokens', JSON.stringify(tokens))
        this.isAuthenticated.next(true);
      })
    );
  }

  resetTokens(payload) {
    return this.http.post(`${this.baseUrl}/login/createAccessToken`, payload);
  }

  pwdChangeDuration() {
    return this.http.get(`${this.baseUrl}/login/pwdChangeDuration`).pipe(
      take(1),
      map((res) => res as any)
    );
  }

  requestForgetPassword(payload): Observable<any> {
    return this.http.post(`${this.baseUrl}/login/requestOtp`,payload, {
      headers: this.Headers,
    }).pipe(
      map(res => res as any)
    )
  }


  verifyOTP(payload): Observable<any>{
    return this.http
    .post(`${this.baseUrl}/login/verifyOtp`, payload)
    .pipe(map((res) => res as any));
  }

  profileInit() {
    return this.http.get(`${this.baseUrl}/user/profile-init/`).pipe(
      take(1),
      map((res) => res as any)
    );
    // .subscribe((res) => {
    //   this.isUserVerified.next(false);
    // });
  }

  updateProfileInit(type, data) {
    return this.http
      .post(`${this.baseUrl}/user/profile-init/${type}/`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }
  otpVerify(payload): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/user/verifyOtp/`, payload)
      .pipe(map((res) => res as any));
  }

  sendSupportQuery(data): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/user/sendFeedback/`, data)
      .pipe(map((res) => res as any));
  }

  restPassword(payload): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/login/resetPassword/`, payload)
      .pipe(map((res) => res as any));
  }

  resetPasswordFromProfile(data): Observable<any>{
    return this.http.post(`${this.baseUrl}/user/resetPasswordFromProfile/`, data)
      .pipe(map((res) => res as any));
  }


  fetchCompanyId(companyName: string) {
    return this.http
      .get<companyNameResponse>(
        `${this.baseUrl}/login/getCompany/${companyName}`
      )
      .pipe(
        tap((data) => {
          const item1: StorageItem = {
            key: 'companyName',
            value: data.data.name,
          };
          const item2: StorageItem = { key: 'companyId', value: data.data._id };
          this.storageService.addItem(item1);
          this.storageService.addItem(item2);
        }),

        map((res) => res as companyNameResponse)
      );
  }

  async loadCompany() {
    return await this.storageService.getByKey('urlCompanyName');
  }

  async logout(): Promise<void> {
    const company = await this.loadCompany();
    this.storageService.removeByKey(this.JWT_TOKEN);
    this.isAuthenticated.next(false);
    this.router.navigate(['/users/login/', company]);
  }
}
