<div class="card my-3 w-100">
  <div class="card-header flexi-header p-4">
    <h3>ADD EMOJI'S</h3>
  </div>
  <div class="card-body p-4">
    <div class="row">
      <div class="col-md-4">
        <h6>Icon / Emoji Image (50px by 50px)
        </h6>
        <div class="custom-file mt-0 pt-4 pb-4">
          <input
            type="file"
            class="custom-file-input"
            (change)="BannerUpload($event)"
            required
            id="b"
          />
          <label class="custom-file-label" for="b">
            <i class="fa " [ngClass]="{'fa-spin fa-spinner fa-2x': isLoading, ' fa-plus-circle fa-2x': !isLoading}" aria-hidden="true"></i>
          </label>
        </div>
      </div>
    </div>

    <form [formGroup]="createEmojisForm">
      <div class="row">

        <div
          class="p-3"
          formArrayName="bannerImages"
          *ngFor="let photo of BannersControl.controls; let index = index"
        >
          <div [formGroupName]="index">
            <div class="image-with-delete-icon">
              <i
                class="fa fa-close fa-lg delete-icon"
                title="Delete"
                (click)="onDeleteBannerImage(photo.get('_id').value,index)"
              ></i>
              <img
                [src]="photo.get('emoji').value"
                class="banner-image"
                alt="Image Preview"
              />
            </div>

          </div>
        </div>
      </div>
    </form>



  </div>
</div>
