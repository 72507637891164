<div class="card">
  <h5 class="card-header bg-theme">USERS</h5>
  <div class="card-body table-responsive p-0">
    <div class="d-flex justify-content-between m-2">
      <select
        class="custom-select"
        style="width: auto"
        name="pageSize"
        (change)="onLimitChangeUsers()"
        [(ngModel)]="usersPTable.limit"
      >
        <option [ngValue]="10">10</option>
        <option [ngValue]="20">20</option>
        <option [ngValue]="30">30</option>
        <option [ngValue]="40">40</option>
        <option [ngValue]="50">50</option>
      </select>
      <div class="form-group form-inline mb-0">
        <span *ngIf="usersPTable.isLoading">Please wait...</span>
        <input
          class="form-control ml-2"
          type="search"
          name="searchTerm"
          (search)="userssearchInput.next($event)"
          (keyup)="userssearchInput.next($event)"
          placeholder="Search here"
          [(ngModel)]="usersPTable.search"
        />
      </div>
    </div>
    <table class="table" *ngIf="!isTableLoading">
      <thead>
        <tr>
          <th scope="col" sortable="Tier 1" (sort)="onSortUsers($event)">
            USER NAME
          </th>
          <th scope="col" sortable="Tier 2" (sort)="onSortUsers($event)">
            STAFF ID
          </th>
          <th scope="col" sortable="Tier 3" (sort)="onSortUsers($event)">
            APPOINMENT
          </th>

          <th scope="col" sortable="Tier 4" (sort)="onSortUsers($event)">
            PARENT BUSSINESS UNIT
          </th>

          <th scope="col">ACTION</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of usersPTable.data">
          <td>
            <span class="font-weight-500"> {{ item?.name }}</span>
          </td>

          <td>{{ item?.staffId }}</td>

          <td>{{ item?.appointment?.name }}</td>
          <td>
            {{ item?.businessUnit }}
          </td>
          <td>
            <button
              class="btn btn-success btn-sm"
              (click)="onEditUser(item._id)"
            >
              Edit
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <ngx-skeleton-loader
      *ngIf="isTableLoading"
      count="6"
      [theme]="{
        width: 'calc(100% - 20px)',
        'border-radius': '6px',
        height: '44px',
        margin: '10px',
        display: 'block'
      }"
    ></ngx-skeleton-loader>
  </div>
  <div class="card-footer">
    <div class="d-flex justify-content-between">
      <p class="align-self-center m-0 text-muted">
        Total Records
        <b class="text-dark">{{ usersPTable.total }}</b>
      </p>
      <ngb-pagination
        [collectionSize]="usersPTable.total"
        [(page)]="usersPTable.page"
        [maxSize]="5"
        (pageChange)="onPageChangeUsers($event)"
        [pageSize]="usersPTable.limit"
      >
      </ngb-pagination>
    </div>
  </div>
</div>
