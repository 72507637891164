<div class="card">
  <div class="card-header align-items-center bg-theme">
    <h5 class="m-0">Business Unit : {{ bu.orgName }}</h5>
    <form [formGroup]="uploadForm" (ngSubmit)="uploadRoster()">
      <div class="header-buttons">
        <input
          type="file"
          name="csv"
          class="btn-info rounded"
          (change)="onFileSelect($event)"
        />
        <button class="btn btn-success btn-sm ml-1" type="submit">
          <i class="fa fa-upload"></i> Upload roster
        </button>
        <a
          class="btn btn-secondary btn-sm ml-1"
          href="../../../../../../../assets/assginshift.csv"
          download
        >
          <i class="fa fa-download"></i> Download sample
        </a>
      </div>
    </form>
  </div>
  <div class="card-body p-1">
    <div class="row">
      <div class="col-md-3 col-sm-12 my-1">
        <div class="d-flex" *ngIf="currWeek">
          <div
            class="weekbtn d-flex justify-content-center align-items-center rounded-left"
            (click)="changeWeek('Prev')"
            title="Previous Week"
          >
            <i class="material-icons"> chevron_left </i>
          </div>

          <div class="align-items-center d-flex weekInput">
            {{ currWeek }}
          </div>

          <div
            class="weekbtn d-flex justify-content-center align-items-center rounded-right"
            (click)="changeWeek('Nxt')"
            title="Next Week"
          >
            <i class="material-icons"> chevron_right </i>
          </div>
        </div>

        <ngx-skeleton-loader
          *ngIf="!currWeek"
          count="1"
          [theme]="{
            width: 'calc(80.4%)',
            'border-radius': '6px',
            height: '36px',
            margin: '10px',
            'margin-top': '0',
            'margin-left': '0',
            display: 'block'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div class="col d-flex my-1">
        <ng-select
          [items]="filterLocationArrayFinal"
          class="custom filters"
          bindLabel="name"
          placeholder="Location Filter"
          appendTo="body"
          [multiple]="true"
          [searchable]="true"
          [clearable]="true"
          [closeOnSelect]="true"
          [(ngModel)]="locationValue"
          (change)="locationFilter()"
        ></ng-select>

        <ng-select
          [items]="appointOption"
          class="custom px-1 filters"
          bindLabel="name"
          placeholder="Appointment Filter"
          appendTo="body"
          [multiple]="true"
          [searchable]="true"
          [clearable]="true"
          [closeOnSelect]="true"
          [(ngModel)]="appointValue"
          (change)="appointFitler()"
        ></ng-select>

        <ng-select
          [items]="mainSkillsetArray"
          class="custom px-1 filters"
          bindLabel="name"
          placeholder="Select Skillset"
          appendTo="body"
          [multiple]="false"
          [searchable]="true"
          [clearable]="true"
          [closeOnSelect]="true"
          [(ngModel)]="mainSkillsetValue"
          (change)="onMainSkillsetValue()"
        ></ng-select>

        <ng-select
          *ngIf="tierType == 2"
          [items]="skillsetArray"
          class="custom filters"
          bindLabel="name"
          placeholder="Select Speciality"
          appendTo="body"
          [multiple]="false"
          [searchable]="true"
          [clearable]="true"
          [closeOnSelect]="true"
          [(ngModel)]="subSkillFilter"
          (change)="onSubSkillFilter()"
        ></ng-select>
      </div>
      <div class="col text-right my-1">
        <button
          class="btn btn-success"
          [disabled]="disablePublishButton"
          (click)="shiftPublish()"
        >
          Publish
          <span
            *ngIf="loaderPublishShift"
            class="spinner-border spinner-border-sm ml-1"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
      </div>
    </div>
    <hr />
    <div style="overflow-x: scroll">
      <div class="mb-2 flexDisplay">
        <div
          class="align-items-center d-flex justify-content-center p-2 red-heading shiftCard"
        >
          Select Staff
        </div>
        <div
          class="align-items-center d-flex justify-content-center p-2 red-heading shiftCard"
        >
          Total Hours
        </div>
        <div class="red-heading shiftCard p-2">
          Monday<br />
          <span *ngIf="weekDateArr"> {{ weekDateArr[0] }} </span>
        </div>
        <div class="red-heading shiftCard p-2">
          Tuesday<br />
          <span *ngIf="weekDateArr"> {{ weekDateArr[1] }} </span>
        </div>
        <div class="red-heading shiftCard p-2">
          Wednesday<br />
          <span *ngIf="weekDateArr"> {{ weekDateArr[2] }} </span>
        </div>
        <div class="red-heading shiftCard p-2">
          Thursday<br />
          <span *ngIf="weekDateArr"> {{ weekDateArr[3] }} </span>
        </div>
        <div class="red-heading shiftCard p-2">
          Friday<br />
          <span *ngIf="weekDateArr"> {{ weekDateArr[4] }} </span>
        </div>
        <div class="red-heading shiftCard p-2">
          Saturday<br />
          <span *ngIf="weekDateArr"> {{ weekDateArr[5] }} </span>
        </div>
        <div class="red-heading shiftCard p-2">
          Sunday<br />
          <span *ngIf="weekDateArr"> {{ weekDateArr[6] }} </span>
        </div>
      </div>

      <!-- <div
        class="d-flex justify-content-center flex-column loadingBox"
        *ngIf="loader"
      >
        <div class="align-self-center justify-content-center loader"></div>
        <p class="h4 text-center text-dark mt-3 mb-0">Loading...</p>
        <p class="h6 text-center text-dark">Please wait, data is loading</p>
      </div> -->

      <ngx-skeleton-loader
        title="Please wait loading..."
        *ngIf="assignShiftsLoader"
        class="d-flex justify-content-between shiftSkeleton"
        count="9"
        [theme]="{
          'border-radius': '6px',
          height: '100px',
          width: '124px',
          margin: '0 2px 2px',
          border: '1px solid white'
        }"
      ></ngx-skeleton-loader>

      <div *ngIf="assignShifts">
        <div
          class="mb-2 flexDisplay"
          *ngFor="let i of this.assignShifts.Monday; let x = index"
        >
          <div class="shiftCard w3-white w3-card p-1 d-flex flex-column justify-content-between">
            <div class="flex-fill">
              <p class="font-weight-bold font m-0">
                {{ this.assignShifts?.Monday[x]?.staff_id?.name }}
              </p>
              <span class="text-muted">{{
                this.assignShifts?.Monday[x]?.staffId
              }}</span
              ><br />

              <span class="text-muted">{{
                this.assignShifts?.Monday[x]?.staffAppointmentId.name
              }}</span>
            </div>

            <div class="d-flex justify-content-end">
              <i
                *ngIf="this.assignShifts?.Monday[x]"
                class="material-icons text-danger click align-self-end"
                title="Delete"
                (click)="deleteShiftAll(x)"
              >
                delete
              </i>
            </div>
          </div>

          <div
            class="w3-white shiftCard w3-card align-items-center d-flex flex-column justify-content-center"
          >
            <span>{{ staffHrs[x] }} Hrs</span>
          </div>

          <!-- Monday -->
          <div
            class="shiftCard d-flex flex-column w3-card p-1"
            [class.w3-white]="
              this.assignShifts?.Monday[x]?.isEmpty == false &&
              this.assignShifts?.Monday[x]?.draftStatus == 1
            "
            [class.w3-skyblue]="
              this.assignShifts?.Monday[x]?.isEmpty == false &&
              this.assignShifts?.Monday[x]?.draftStatus == 0
            "
          >
            <i
              (click)="onCreateDay(this.assignShifts?.Monday[x])"
              class="fa fa-plus"
              *ngIf="
                this.assignShifts?.Monday[x]?.isEmpty == true &&
                filters &&
                filters
              "
            ></i>
            <div
              class="flex-fill"
              *ngIf="this.assignShifts?.Monday[x]?.isEmpty == false"
              (click)="onCreateDay(this.assignShifts?.Monday[x])"
            >
              <span *ngIf="this.assignShifts?.Monday[x]?.isOff == true"
                >OFF</span
              >
              <span *ngIf="this.assignShifts?.Monday[x]?.isRest == true"
                >REST</span
              >
              <div *ngIf="this.assignShifts?.Monday[x]?.endTime != null">
                <span>
                  {{ this.assignShifts?.Monday[x]?.startTime | date: "HH:mm" }}
                  -
                  {{ this.assignShifts?.Monday[x]?.endTime | date: "HH:mm" }}
                </span>
                <div *ngIf="this.assignShifts?.Monday[x]?.isSplitShift">
                  {{
                    this.assignShifts?.Monday[x]?.splitStartTime | date: "HH:mm"
                  }}
                  -
                  {{
                    this.assignShifts?.Monday[x]?.splitEndTime | date: "HH:mm"
                  }}
                </div>

                <div
                  *ngIf="
                    this.assignShifts?.Monday[x]?.subSkillSets.length &&
                    this.tier == 2
                  "
                >
                  <div
                    class="truncate"
                    *ngFor="let s of this.assignShifts?.Monday[x]?.subSkillSets"
                  >
                    {{ s.skillSetId.name }} > {{ s.name }}
                  </div>
                </div>
                <div
                  *ngIf="
                    this.assignShifts?.Monday[x]?.mainSkillSets &&
                    this.tier == 1
                  "
                >
                  <div
                    class="truncate"
                    *ngFor="
                      let s of this.assignShifts?.Monday[x]?.mainSkillSets
                    "
                  >
                    {{ s.name }}
                  </div>
                </div>

                <span>
                  {{
                    this.assignShifts?.Monday[x]?.reportLocationId != null
                      ? this.assignShifts?.Monday[x]?.reportLocationId?.name
                      : this.assignShifts?.Monday[x]?.geoReportingLocation?.name
                  }}
                </span>

                <div *ngIf="this.assignShifts?.Monday[x]?.isRecalled">
                  <div
                    [ngSwitch]="this.assignShifts?.Monday[x]?.isRecallAccepted"
                  >
                    <span class="text-warning" *ngSwitchCase="1"
                      >Recall - Pending</span
                    >
                    <span class="text-success" *ngSwitchCase="2"
                      >Recall - Accepted</span
                    >
                    <span class="text-danger" *ngSwitchCase="3"
                      >Recall - Rejected</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div
              class="d-flex justify-content-end"
              [class.justify-content-between]="
                this.assignShifts?.Monday[x]?.isLimit == true
              "
              *ngIf="this.assignShifts?.Monday[x]?.isEmpty == false"
            >
              <i
                class="material-icons text-danger click"
                title="Time exceeds"
                *ngIf="this.assignShifts?.Monday[x]?.isLimit == true"
                (click)="limit(this.assignShifts?.Monday[x])"
              >
                error_outline
              </i>
              <i
                class="material-icons text-info"
                title="Shift Seen"
                *ngIf="this.assignShifts?.Monday[x]?.shiftRead == 1"
              >
                done_all
              </i>
              <i
                class="material-icons text-success click"
                title="Read Msg"
                *ngIf="
                  this.assignShifts?.Monday[x]?.shiftChangeRequestMessage != ''
                "
                (click)="
                  showMsg(
                    this.assignShifts?.Monday[x]?.shiftChangeRequestMessage
                  )
                "
              >
                chat_bubble_outline
              </i>
              <i
                class="material-icons text-danger click align-self-end"
                title="Delete"
                (click)="deleteShift(this.assignShifts?.Monday[x])"
              >
                delete
              </i>
            </div>
          </div>
          <!-- Tuesday -->
          <div
            class="shiftCard d-flex flex-column w3-card p-1"
            [class.w3-white]="
              this.assignShifts?.Tuesday[x]?.isEmpty == false &&
              this.assignShifts?.Tuesday[x]?.draftStatus == 1
            "
            [class.w3-skyblue]="
              this.assignShifts?.Tuesday[x]?.isEmpty == false &&
              this.assignShifts?.Tuesday[x]?.draftStatus == 0
            "
          >
            <i
              (click)="onCreateDay(this.assignShifts?.Tuesday[x])"
              class="fa fa-plus"
              *ngIf="this.assignShifts?.Tuesday[x]?.isEmpty == true && filters"
            ></i>
            <div
              class="flex-fill"
              *ngIf="this.assignShifts?.Tuesday[x]?.isEmpty == false"
              (click)="onCreateDay(this.assignShifts?.Tuesday[x])"
            >
              <span *ngIf="this.assignShifts?.Tuesday[x]?.isOff == true"
                >OFF</span
              >
              <span *ngIf="this.assignShifts?.Tuesday[x]?.isRest == true"
                >REST</span
              >
              <div *ngIf="this.assignShifts?.Tuesday[x]?.endTime != null">
                <span>
                  {{ this.assignShifts?.Tuesday[x]?.startTime | date: "HH:mm" }}
                  -
                  {{ this.assignShifts?.Tuesday[x]?.endTime | date: "HH:mm" }}
                </span>
                <div *ngIf="this.assignShifts?.Tuesday[x]?.isSplitShift">
                  {{
                    this.assignShifts?.Tuesday[x]?.splitStartTime
                      | date: "HH:mm"
                  }}
                  -
                  {{
                    this.assignShifts?.Tuesday[x]?.splitEndTime | date: "HH:mm"
                  }}
                </div>

                <div
                  *ngIf="
                    this.assignShifts?.Tuesday[x]?.subSkillSets.length &&
                    this.tier == 2
                  "
                >
                  <div
                    class="truncate"
                    *ngFor="
                      let s of this.assignShifts?.Tuesday[x]?.subSkillSets
                    "
                  >
                    {{ s.skillSetId.name }} > {{ s.name }}
                  </div>
                </div>
                <div
                  *ngIf="
                    this.assignShifts?.Tuesday[x]?.mainSkillSets &&
                    this.tier == 1
                  "
                >
                  <div
                    class="truncate"
                    *ngFor="
                      let s of this.assignShifts?.Tuesday[x]?.mainSkillSets
                    "
                  >
                    {{ s.name }}
                  </div>
                </div>

                <span>
                  {{
                    this.assignShifts?.Tuesday[x]?.reportLocationId != null
                      ? this.assignShifts?.Tuesday[x]?.reportLocationId?.name
                      : this.assignShifts?.Tuesday[x]?.geoReportingLocation
                          ?.name
                  }}
                </span>

                <div *ngIf="this.assignShifts?.Tuesday[x]?.isRecalled">
                  <div
                    [ngSwitch]="this.assignShifts?.Tuesday[x]?.isRecallAccepted"
                  >
                    <span class="text-warning" *ngSwitchCase="1"
                      >Recall - Pending</span
                    >
                    <span class="text-success" *ngSwitchCase="2"
                      >Recall - Accepted</span
                    >
                    <span class="text-danger" *ngSwitchCase="3"
                      >Recall - Rejected</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <div
              class="d-flex justify-content-end"
              [class.justify-content-between]="
                this.assignShifts?.Tuesday[x]?.isLimit == true
              "
              *ngIf="this.assignShifts?.Tuesday[x]?.isEmpty == false"
            >
              <i
                class="material-icons text-danger click"
                title="Time exceeds"
                *ngIf="this.assignShifts?.Tuesday[x]?.isLimit == true"
                (click)="limit(this.assignShifts?.Tuesday[x])"
              >
                error_outline
              </i>
              <i
                class="material-icons text-info"
                title="Shift Seen"
                *ngIf="this.assignShifts?.Tuesday[x]?.shiftRead == 1"
              >
                done_all
              </i>
              <i
                class="material-icons text-success click"
                title="Read Msg"
                *ngIf="
                  this.assignShifts?.Tuesday[x]?.shiftChangeRequestMessage != ''
                "
                (click)="
                  showMsg(
                    this.assignShifts?.Tuesday[x]?.shiftChangeRequestMessage
                  )
                "
              >
                chat_bubble_outline
              </i>
              <i
                class="material-icons text-danger click align-self-end"
                title="Delete"
                (click)="deleteShift(this.assignShifts?.Tuesday[x])"
              >
                delete
              </i>
            </div>
          </div>
          <!-- Wednesday -->
          <div
            class="shiftCard d-flex flex-column w3-card p-1"
            [class.w3-white]="
              this.assignShifts?.Wednesday[x]?.isEmpty == false &&
              this.assignShifts?.Wednesday[x]?.draftStatus == 1
            "
            [class.w3-skyblue]="
              this.assignShifts?.Wednesday[x]?.isEmpty == false &&
              this.assignShifts?.Wednesday[x]?.draftStatus == 0
            "
          >
            <i
              (click)="onCreateDay(this.assignShifts?.Wednesday[x])"
              class="fa fa-plus"
              *ngIf="
                this.assignShifts?.Wednesday[x]?.isEmpty == true && filters
              "
            ></i>
            <div
              class="flex-fill"
              *ngIf="this.assignShifts?.Wednesday[x]?.isEmpty == false"
              (click)="onCreateDay(this.assignShifts?.Wednesday[x])"
            >
              <span *ngIf="this.assignShifts?.Wednesday[x]?.isOff == true"
                >OFF</span
              >
              <span *ngIf="this.assignShifts?.Wednesday[x]?.isRest == true"
                >REST</span
              >
              <div *ngIf="this.assignShifts?.Wednesday[x]?.endTime != null">
                <span>
                  {{
                    this.assignShifts?.Wednesday[x]?.startTime | date: "HH:mm"
                  }}
                  -
                  {{ this.assignShifts?.Wednesday[x]?.endTime | date: "HH:mm" }}
                </span>

                <div *ngIf="this.assignShifts?.Wednesday[x]?.isSplitShift">
                  {{
                    this.assignShifts?.Wednesday[x]?.splitStartTime
                      | date: "HH:mm"
                  }}
                  -
                  {{
                    this.assignShifts?.Wednesday[x]?.splitEndTime
                      | date: "HH:mm"
                  }}
                </div>

                <div
                  *ngIf="this.assignShifts?.Wednesday[x]?.subSkillSets.length"
                >
                  <div
                    class="truncate"
                    *ngFor="
                      let s of this.assignShifts?.Wednesday[x]?.subSkillSets
                    "
                  >
                    {{ s.skillSetId.name }} > {{ s.name }}
                  </div>
                </div>
                <div
                  *ngIf="
                    this.assignShifts?.Wednesday[x]?.mainSkillSets &&
                    this.tier == 1
                  "
                >
                  <div
                    class="truncate"
                    *ngFor="
                      let s of this.assignShifts?.Wednesday[x]?.mainSkillSets
                    "
                  >
                    {{ s.name }}
                  </div>
                </div>

                <span>
                  {{
                    this.assignShifts?.Wednesday[x]?.reportLocationId != null
                      ? this.assignShifts?.Wednesday[x]?.reportLocationId?.name
                      : this.assignShifts?.Wednesday[x]?.geoReportingLocation
                          ?.name
                  }}
                </span>

                <div *ngIf="this.assignShifts?.Wednesday[x]?.isRecalled">
                  <div
                    [ngSwitch]="
                      this.assignShifts?.Wednesday[x]?.isRecallAccepted
                    "
                  >
                    <span class="text-warning" *ngSwitchCase="1"
                      >Recall - Pending</span
                    >
                    <span class="text-success" *ngSwitchCase="2"
                      >Recall - Accepted</span
                    >
                    <span class="text-danger" *ngSwitchCase="3"
                      >Recall - Rejected</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <div
              class="d-flex justify-content-end"
              [class.justify-content-between]="
                this.assignShifts?.Wednesday[x]?.isLimit == true
              "
              *ngIf="this.assignShifts?.Wednesday[x]?.isEmpty == false"
            >
              <i
                class="material-icons text-danger click"
                title="Time exceeds"
                *ngIf="this.assignShifts?.Wednesday[x]?.isLimit == true"
                (click)="limit(this.assignShifts?.Wednesday[x])"
              >
                error_outline
              </i>
              <i
                class="material-icons text-info"
                title="Shift Seen"
                *ngIf="this.assignShifts?.Wednesday[x]?.shiftRead == 1"
              >
                done_all
              </i>
              <i
                class="material-icons text-success click"
                title="Read Msg"
                *ngIf="
                  this.assignShifts?.Wednesday[x]?.shiftChangeRequestMessage !=
                  ''
                "
                (click)="
                  showMsg(
                    this.assignShifts?.Wednesday[x]?.shiftChangeRequestMessage
                  )
                "
              >
                chat_bubble_outline
              </i>
              <i
                class="material-icons text-danger click align-self-end"
                title="Delete"
                (click)="deleteShift(this.assignShifts?.Wednesday[x])"
              >
                delete
              </i>
            </div>
          </div>
          <!-- Thursday -->
          <div
            class="shiftCard d-flex flex-column w3-card p-1"
            [class.w3-white]="
              this.assignShifts?.Thursday[x]?.isEmpty == false &&
              this.assignShifts?.Thursday[x]?.draftStatus == 1
            "
            [class.w3-skyblue]="
              this.assignShifts?.Thursday[x]?.isEmpty == false &&
              this.assignShifts?.Thursday[x]?.draftStatus == 0
            "
          >
            <i
              (click)="onCreateDay(this.assignShifts?.Thursday[x])"
              class="fa fa-plus"
              *ngIf="this.assignShifts?.Thursday[x]?.isEmpty == true && filters"
            ></i>
            <div
              class="flex-fill"
              *ngIf="this.assignShifts?.Thursday[x]?.isEmpty == false"
              (click)="onCreateDay(this.assignShifts?.Thursday[x])"
            >
              <span *ngIf="this.assignShifts?.Thursday[x]?.isOff == true"
                >OFF</span
              >
              <span *ngIf="this.assignShifts?.Thursday[x]?.isRest == true"
                >REST</span
              >
              <div *ngIf="this.assignShifts?.Thursday[x]?.endTime != null">
                <div>
                  {{
                    this.assignShifts?.Thursday[x]?.startTime | date: "HH:mm"
                  }}
                  -
                  {{ this.assignShifts?.Thursday[x]?.endTime | date: "HH:mm" }}
                </div>
                <div *ngIf="this.assignShifts?.Thursday[x]?.isSplitShift">
                  {{
                    this.assignShifts?.Thursday[x]?.splitStartTime
                      | date: "HH:mm"
                  }}
                  -
                  {{
                    this.assignShifts?.Thursday[x]?.splitEndTime | date: "HH:mm"
                  }}
                </div>

                <div
                  *ngIf="this.assignShifts?.Thursday[x]?.subSkillSets.length"
                >
                  <div
                    class="truncate"
                    *ngFor="
                      let s of this.assignShifts?.Thursday[x]?.subSkillSets
                    "
                  >
                    {{ s.skillSetId.name }} > {{ s.name }}
                  </div>
                </div>
                <div
                  *ngIf="
                    this.assignShifts?.Thursday[x]?.mainSkillSets &&
                    this.tier == 1
                  "
                >
                  <div
                    class="truncate"
                    *ngFor="
                      let s of this.assignShifts?.Thursday[x]?.mainSkillSets
                    "
                  >
                    {{ s.name }}
                  </div>
                </div>

                <span>
                  {{
                    this.assignShifts?.Thursday[x]?.reportLocationId != null
                      ? this.assignShifts?.Thursday[x]?.reportLocationId?.name
                      : this.assignShifts?.Thursday[x]?.geoReportingLocation
                          ?.name
                  }}
                </span>

                <div *ngIf="this.assignShifts?.Thursday[x]?.isRecalled">
                  <div
                    [ngSwitch]="
                      this.assignShifts?.Thursday[x]?.isRecallAccepted
                    "
                  >
                    <span class="text-warning" *ngSwitchCase="1"
                      >Recall - Pending</span
                    >
                    <span class="text-success" *ngSwitchCase="2"
                      >Recall - Accepted</span
                    >
                    <span class="text-danger" *ngSwitchCase="3"
                      >Recall - Rejected</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <div
              class="d-flex justify-content-end"
              [class.justify-content-between]="
                this.assignShifts?.Thursday[x]?.isLimit == true
              "
              *ngIf="this.assignShifts?.Thursday[x]?.isEmpty == false"
            >
              <i
                class="material-icons text-danger click"
                title="Time exceeds"
                *ngIf="this.assignShifts?.Thursday[x]?.isLimit == true"
                (click)="limit(this.assignShifts?.Thursday[x])"
              >
                error_outline
              </i>
              <i
                class="material-icons text-info"
                title="Shift Seen"
                *ngIf="this.assignShifts?.Thursday[x]?.shiftRead == 1"
              >
                done_all
              </i>
              <i
                class="material-icons text-success click"
                title="Read Msg"
                *ngIf="
                  this.assignShifts?.Thursday[x]?.shiftChangeRequestMessage !=
                  ''
                "
                (click)="
                  showMsg(
                    this.assignShifts?.Thursday[x]?.shiftChangeRequestMessage
                  )
                "
              >
                chat_bubble_outline
              </i>
              <i
                class="material-icons text-danger click align-self-end"
                title="Delete"
                (click)="deleteShift(this.assignShifts?.Thursday[x])"
              >
                delete
              </i>
            </div>
          </div>
          <!-- Friday -->
          <div
            class="shiftCard d-flex flex-column w3-card p-1"
            [class.w3-white]="
              this.assignShifts?.Friday[x]?.isEmpty == false &&
              this.assignShifts?.Friday[x]?.draftStatus == 1
            "
            [class.w3-skyblue]="
              this.assignShifts?.Friday[x]?.isEmpty == false &&
              this.assignShifts?.Friday[x]?.draftStatus == 0
            "
          >
            <i
              (click)="onCreateDay(this.assignShifts?.Friday[x])"
              class="fa fa-plus"
              *ngIf="this.assignShifts?.Friday[x]?.isEmpty == true && filters"
            ></i>
            <div
              class="flex-fill"
              *ngIf="this.assignShifts?.Friday[x]?.isEmpty == false"
              (click)="onCreateDay(this.assignShifts?.Friday[x])"
            >
              <span *ngIf="this.assignShifts?.Friday[x]?.isOff == true"
                >OFF</span
              >
              <span *ngIf="this.assignShifts?.Friday[x]?.isRest == true"
                >REST</span
              >
              <div *ngIf="this.assignShifts?.Friday[x]?.endTime != null">
                <span>
                  {{ this.assignShifts?.Friday[x]?.startTime | date: "HH:mm" }}
                  -
                  {{ this.assignShifts?.Friday[x]?.endTime | date: "HH:mm" }}
                </span>
                <div *ngIf="this.assignShifts?.Friday[x]?.isSplitShift">
                  {{
                    this.assignShifts?.Friday[x]?.splitStartTime | date: "HH:mm"
                  }}
                  -
                  {{
                    this.assignShifts?.Friday[x]?.splitEndTime | date: "HH:mm"
                  }}
                </div>

                <div *ngIf="this.assignShifts?.Friday[x]?.subSkillSets.length">
                  <div
                    class="truncate"
                    *ngFor="let s of this.assignShifts?.Friday[x]?.subSkillSets"
                  >
                    {{ s.skillSetId.name }} > {{ s.name }}
                  </div>
                </div>
                <div
                  *ngIf="
                    this.assignShifts?.Friday[x]?.mainSkillSets &&
                    this.tier == 1
                  "
                >
                  <div
                    class="truncate"
                    *ngFor="
                      let s of this.assignShifts?.Friday[x]?.mainSkillSets
                    "
                  >
                    {{ s.name }}
                  </div>
                </div>

                <span>
                  {{
                    this.assignShifts?.Friday[x]?.reportLocationId != null
                      ? this.assignShifts?.Friday[x]?.reportLocationId?.name
                      : this.assignShifts?.Friday[x]?.geoReportingLocation?.name
                  }}
                </span>

                <div *ngIf="this.assignShifts?.Friday[x]?.isRecalled">
                  <div
                    [ngSwitch]="this.assignShifts?.Friday[x]?.isRecallAccepted"
                  >
                    <span class="text-warning" *ngSwitchCase="1"
                      >Recall - Pending</span
                    >
                    <span class="text-success" *ngSwitchCase="2"
                      >Recall - Accepted</span
                    >
                    <span class="text-danger" *ngSwitchCase="3"
                      >Recall - Rejected</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <div
              class="d-flex justify-content-end"
              [class.justify-content-between]="
                this.assignShifts?.Friday[x]?.isLimit == true
              "
              *ngIf="this.assignShifts?.Friday[x]?.isEmpty == false"
            >
              <i
                class="material-icons text-danger click"
                title="Time exceeds"
                *ngIf="this.assignShifts?.Friday[x]?.isLimit == true"
                (click)="limit(this.assignShifts?.Friday[x])"
              >
                error_outline
              </i>
              <i
                class="material-icons text-info"
                title="Shift Seen"
                *ngIf="this.assignShifts?.Friday[x]?.shiftRead == 1"
              >
                done_all
              </i>
              <i
                class="material-icons text-success click"
                title="Read Msg"
                *ngIf="
                  this.assignShifts?.Friday[x]?.shiftChangeRequestMessage != ''
                "
                (click)="
                  showMsg(
                    this.assignShifts?.Friday[x]?.shiftChangeRequestMessage
                  )
                "
              >
                chat_bubble_outline
              </i>
              <i
                class="material-icons text-danger click align-self-end"
                title="Delete"
                (click)="deleteShift(this.assignShifts?.Friday[x])"
              >
                delete
              </i>
            </div>
          </div>
          <!-- Saturday -->
          <div
            class="shiftCard d-flex flex-column w3-card p-1"
            [class.w3-white]="
              this.assignShifts?.Saturday[x]?.isEmpty == false &&
              this.assignShifts?.Saturday[x]?.draftStatus == 1
            "
            [class.w3-skyblue]="
              this.assignShifts?.Saturday[x]?.isEmpty == false &&
              this.assignShifts?.Saturday[x]?.draftStatus == 0
            "
          >
            <i
              (click)="onCreateDay(this.assignShifts?.Saturday[x])"
              class="fa fa-plus"
              *ngIf="this.assignShifts?.Saturday[x]?.isEmpty == true && filters"
            ></i>
            <div
              class="flex-fill"
              *ngIf="this.assignShifts?.Saturday[x]?.isEmpty == false"
              (click)="onCreateDay(this.assignShifts?.Saturday[x])"
            >
              <span *ngIf="this.assignShifts?.Saturday[x]?.isOff == true"
                >OFF</span
              >
              <span *ngIf="this.assignShifts?.Saturday[x]?.isRest == true"
                >REST</span
              >
              <div *ngIf="this.assignShifts?.Saturday[x]?.endTime != null">
                <span>
                  {{
                    this.assignShifts?.Saturday[x]?.startTime | date: "HH:mm"
                  }}
                  -
                  {{ this.assignShifts?.Saturday[x]?.endTime | date: "HH:mm" }}
                </span>
                <div *ngIf="this.assignShifts?.Saturday[x]?.isSplitShift">
                  {{
                    this.assignShifts?.Saturday[x]?.splitStartTime
                      | date: "HH:mm"
                  }}
                  -
                  {{
                    this.assignShifts?.Saturday[x]?.splitEndTime | date: "HH:mm"
                  }}
                </div>

                <div
                  *ngIf="
                    this.assignShifts?.Saturday[x]?.subSkillSets.length &&
                    this.tier == 2
                  "
                >
                  <div
                    class="truncate"
                    *ngFor="
                      let s of this.assignShifts?.Saturday[x]?.subSkillSets
                    "
                  >
                    {{ s.skillSetId.name }} > {{ s.name }}
                  </div>
                </div>
                <div
                  *ngIf="
                    this.assignShifts?.Saturday[x]?.mainSkillSets &&
                    this.tier == 1
                  "
                >
                  <div
                    class="truncate"
                    *ngFor="
                      let s of this.assignShifts?.Saturday[x]?.mainSkillSets
                    "
                  >
                    {{ s.name }}
                  </div>
                </div>

                <span>
                  {{
                    this.assignShifts?.Saturday[x]?.reportLocationId != null
                      ? this.assignShifts?.Saturday[x]?.reportLocationId?.name
                      : this.assignShifts?.Saturday[x]?.geoReportingLocation
                          ?.name
                  }}
                </span>

                <div *ngIf="this.assignShifts?.Saturday[x]?.isRecalled">
                  <div
                    [ngSwitch]="
                      this.assignShifts?.Saturday[x]?.isRecallAccepted
                    "
                  >
                    <span class="text-warning" *ngSwitchCase="1"
                      >Recall - Pending</span
                    >
                    <span class="text-success" *ngSwitchCase="2"
                      >Recall - Accepted</span
                    >
                    <span class="text-danger" *ngSwitchCase="3"
                      >Recall - Rejected</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <div
              class="d-flex justify-content-end"
              [class.justify-content-between]="
                this.assignShifts?.Saturday[x]?.isLimit == true
              "
              *ngIf="this.assignShifts?.Saturday[x]?.isEmpty == false"
            >
              <i
                class="material-icons text-danger click"
                title="Time exceeds"
                *ngIf="this.assignShifts?.Saturday[x]?.isLimit == true"
                (click)="limit(this.assignShifts?.Saturday[x])"
              >
                error_outline
              </i>

              <i
                class="material-icons text-info"
                title="Shift Seen"
                *ngIf="this.assignShifts?.Saturday[x]?.shiftRead == 1"
              >
                done_all
              </i>
              <i
                class="material-icons text-success click"
                title="Read Msg"
                *ngIf="
                  this.assignShifts?.Saturday[x]?.shiftChangeRequestMessage !=
                  ''
                "
                (click)="
                  showMsg(
                    this.assignShifts?.Saturday[x]?.shiftChangeRequestMessage
                  )
                "
              >
                chat_bubble_outline
              </i>
              <i
                class="material-icons text-danger click align-self-end"
                title="Delete"
                (click)="deleteShift(this.assignShifts?.Saturday[x])"
              >
                delete
              </i>
            </div>
          </div>
          <!-- Sunday -->
          <div
            class="shiftCard d-flex flex-column w3-card p-1"
            [class.w3-white]="
              this.assignShifts?.Sunday[x]?.isEmpty == false &&
              this.assignShifts?.Sunday[x]?.draftStatus == 1
            "
            [class.w3-skyblue]="
              this.assignShifts?.Sunday[x]?.isEmpty == false &&
              this.assignShifts?.Sunday[x]?.draftStatus == 0
            "
          >
            <i
              (click)="onCreateDay(this.assignShifts?.Sunday[x])"
              class="fa fa-plus"
              *ngIf="this.assignShifts?.Sunday[x]?.isEmpty == true && filters"
            ></i>

            <div
              class="flex-fill"
              *ngIf="this.assignShifts?.Sunday[x]?.isEmpty == false"
              (click)="onCreateDay(this.assignShifts?.Sunday[x])"
            >
              <span *ngIf="this.assignShifts?.Sunday[x]?.isOff == true"
                >OFF</span
              >
              <span *ngIf="this.assignShifts?.Sunday[x]?.isRest == true"
                >REST</span
              >
              <div *ngIf="this.assignShifts?.Sunday[x]?.endTime != null">
                <span>
                  {{ this.assignShifts?.Sunday[x]?.startTime | date: "HH:mm" }}
                  -
                  {{ this.assignShifts?.Sunday[x]?.endTime | date: "HH:mm" }}
                </span>

                <div *ngIf="this.assignShifts?.Sunday[x]?.isSplitShift">
                  {{
                    this.assignShifts?.Sunday[x]?.splitStartTime | date: "HH:mm"
                  }}
                  -
                  {{
                    this.assignShifts?.Sunday[x]?.splitEndTime | date: "HH:mm"
                  }}
                </div>

                <div
                  *ngIf="
                    this.assignShifts?.Sunday[x]?.subSkillSets.length &&
                    this.tier == 2
                  "
                >
                  <div
                    class="truncate"
                    *ngFor="let s of this.assignShifts?.Sunday[x]?.subSkillSets"
                  >
                    {{ s.skillSetId.name }} > {{ s.name }}
                  </div>
                </div>
                <div
                  *ngIf="
                    this.assignShifts?.Sunday[x]?.mainSkillSets &&
                    this.tier == 1
                  "
                >
                  <div
                    class="truncate"
                    *ngFor="
                      let s of this.assignShifts?.Sunday[x]?.mainSkillSets
                    "
                  >
                    {{ s.name }}
                  </div>
                </div>

                <span>
                  {{
                    this.assignShifts?.Sunday[x]?.reportLocationId != null
                      ? this.assignShifts?.Sunday[x]?.reportLocationId?.name
                      : this.assignShifts?.Sunday[x]?.geoReportingLocation?.name
                  }}
                </span>

                <div *ngIf="this.assignShifts?.Sunday[x]?.isRecalled">
                  <div
                    [ngSwitch]="this.assignShifts?.Sunday[x]?.isRecallAccepted"
                  >
                    <span class="text-warning" *ngSwitchCase="1"
                      >Recall - Pending</span
                    >
                    <span class="text-success" *ngSwitchCase="2"
                      >Recall - Accepted</span
                    >
                    <span class="text-danger" *ngSwitchCase="3"
                      >Recall - Rejected</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <div
              class="d-flex justify-content-end"
              [class.justify-content-between]="
                this.assignShifts?.Sunday[x]?.isLimit == true
              "
              *ngIf="this.assignShifts?.Sunday[x]?.isEmpty == false"
            >
              <i
                class="material-icons text-danger click"
                title="Time exceeds"
                *ngIf="this.assignShifts?.Sunday[x]?.isLimit == true"
                (click)="limit(this.assignShifts?.Sunday[x])"
              >
                error_outline
              </i>
              <i
                class="material-icons text-info"
                title="Shift Seen"
                *ngIf="this.assignShifts?.Sunday[x]?.shiftRead == 1"
              >
                done_all
              </i>
              <i
                class="material-icons text-success click"
                title="Read Msg"
                *ngIf="
                  this.assignShifts?.Sunday[x]?.shiftChangeRequestMessage != ''
                "
                (click)="
                  showMsg(
                    this.assignShifts?.Sunday[x]?.shiftChangeRequestMessage
                  )
                "
              >
                chat_bubble_outline
              </i>
              <i
                class="material-icons text-danger click align-self-end"
                title="Delete"
                (click)="deleteShift(this.assignShifts?.Sunday[x])"
              >
                delete
              </i>
            </div>
          </div>
        </div>
      </div>

      <div
        class="shiftCard w3-white w3-card p-2 align-items-center d-flex flex-column justify-content-center"
      >
        <i (click)="open1(content1)" class="fa fa-plus"></i>
      </div>

      <div class="my-1 flexDisplay" *ngIf="this.assignShifts">
        <div class="shiftCard"></div>
        <div
          class="w3-gray w3-gray-card shiftCard"
          *ngIf="this.totalHrStr != ''"
        >
          {{ totalHrStr }} Hrs
        </div>
        <div
          class="shiftCard w3-gray w3-gray-card"
          *ngFor="let j of this.dayHrs"
        >
          <div style="text-align: center" *ngIf="assignShifts.length != 0">
            {{ assignShifts?.Monday?.length }} Staff
          </div>
          <div style="text-align: center">{{ j }} Hrs</div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" [ngClass]="addStaff ? 'show-modal' : ''" id="mmmModal">
  <div
    class="modal-dialog"
    style="margin-top: 180px; width: 500px; height: 500px"
  >
    <div class="modal-content modalContentBoxGrey">
      <div class="modal-body">
        <div>
          <div class="mb- col-md-12 row"><b>Select Staff</b></div>
          <ng-select
            [items]="empList"
            class="custom"
            bindLabel="name"
            [placeholder]="placeHolderText"
            appendTo="body"
            [multiple]="false"
            [searchable]="true"
            [clearable]="true"
            [closeOnSelect]="true"
            (search)="staffSearch($event)"
            [(ngModel)]="Selectedemp"
            (scrollToEnd)="loadMorePlasnBuUnitItems($event)"
            [loading]='isSelectStaffLoading'
            loadingText="Loading more result"
          ></ng-select>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn footer-button btn-clear" (click)="onAddStaff(false)">
          Close
        </button>
        <button class="btn footer-button btn-primary" (click)="onEmpSelected()">
          Submit
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #content1 let-modal>
  <div class="modal-header bg-theme">
    <h5 class="modal-title" id="modal-basic-title">Staff Selection</h5>
    <button
      type="button"
      class="close text-light"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>Select Staff</label>
      <ng-select
        ngbAutofocus
        [items]="empList"
        class="custom"
        bindLabel="nameAndStaffIdCombination"
        [placeholder]="placeHolderText"
        appendTo="body"
        [multiple]="false"
        [searchable]="true"
        (search)="staffSearch($event)"
        [clearable]="true"
        [closeOnSelect]="true"
        [(ngModel)]="Selectedemp"
        [loading]="isSelectStaffLoading"
        loadingText='Loading more result'
        (scrollToEnd)="loadMorePlasnBuUnitItems($event)"
      ></ng-select>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-dark"
      (click)="modal.dismiss('cancel click')"
    >
      Close
    </button>
    <button
      type="button"
      class="btn btn-success"
      (click)="onEmpSelected(); modal.close('Save click')"
    >
      Submit
    </button>
  </div>
</ng-template>

<ng-template #AddCreateDay let-c="close" let-d="dismiss">
  <div class="modal-content">
    <div class="modal-body" style="padding: 0; min-height: 100vh">
      <div>
        <div class="card w-100">
          <div class="card-header flexi-header h6 text-white">
            {{ StaffName }}
          </div>
          <div class="card-body">
            <form [formGroup]="adShift">
              <div class="row">
                <div class="col-md-4">
                  <input
                    type="checkbox"
                    name="isOff"
                    value="1"
                    [checked]="isCheck"
                    formControlName="isOff"
                    (change)="dayOff($event)"
                  />
                  <label
                    for="off"
                    style="padding-left: 0.2rem; vertical-align: middle"
                    ><b>OFF</b></label
                  >
                </div>
                <div class="col-md-4">
                  <input
                    type="checkbox"
                    name="isRest"
                    value="2"
                    [checked]="isCheckRest"
                    formControlName="isRest"
                    (change)="dayOff($event)"
                  />
                  <label
                    for="rest"
                    style="padding-left: 0.2rem; vertical-align: middle"
                    ><b>REST</b></label
                  >
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="font-weight-500">Select Day</label><br />
                    <button
                      *ngFor="let i of dayArr"
                      class="dayButton"
                      [class.selected]="this.addedDay.indexOf(i) != -1"
                      (click)="addDay(i)"
                    >
                      {{ i.day }}
                    </button>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="font-weight-500">Set Timing</label>
                    <ng-select
                      [items]="timeDropDown"
                      class="custom"
                      appendTo="body"
                      placeholder="Select StartTime"
                      [multiple]="false"
                      [searchable]="true"
                      [clearable]="true"
                      [closeOnSelect]="true"
                      formControlName="StartTime"
                    >
                    </ng-select>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label class="font-weight-500">&nbsp;</label>
                    <ng-select
                      [items]="timeDropDown"
                      class="custom"
                      appendTo="body"
                      placeholder="Select EndTime"
                      [multiple]="false"
                      [searchable]="true"
                      [clearable]="true"
                      [closeOnSelect]="true"
                      formControlName="EndTime"
                    >
                    </ng-select>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-check form-check-inline float-right">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="splitTiming"
                      value="1"
                      [checked]="isSplit"
                      formControlName="isSplit"
                      (change)="setSplitShift($event)"
                      name="splitTiming"
                    />
                    <label
                      class="form-check-label font-weight-500"
                      for="inlineCheckbox1"
                      >Split Shift</label
                    >
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="isSplit">
                <div class="col-md-4">
                  <div class="form-group">
                    <ng-select
                      [items]="timeDropDown"
                      class="custom"
                      appendTo="body"
                      placeholder="Select StartTime"
                      [multiple]="false"
                      [searchable]="true"
                      [clearable]="true"
                      [closeOnSelect]="true"
                      formControlName="splitStartTime"
                    >
                    </ng-select>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ng-select
                      [items]="timeDropDown"
                      class="custom"
                      appendTo="body"
                      placeholder="Select EndTime"
                      [multiple]="false"
                      [searchable]="true"
                      [clearable]="true"
                      [closeOnSelect]="true"
                      formControlName="splitEndTime"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>

              <div class="card mb-3">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="form-group">
                        <label class="font-weight-500">Report to</label>
                        <ng-select
                          [items]="reportLocation"
                          class="custom"
                          bindLabel="name"
                          placeholder="Select Location"
                          appendTo="body"
                          [multiple]="false"
                          [searchable]="true"
                          [clearable]="true"
                          [closeOnSelect]="true"
                          formControlName="reportLocation"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="align-self-end col-sm-4 font-weight-500"
                      >Enable Check-in</label
                    >
                    <div class="col-sm-8">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          [value]="true"
                          id="yesCheckin"
                          formControlName="isCheckInEnabled"
                          name="isCheckInEnabled"
                        />
                        <label class="form-check-label">Yes</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          [value]="false"
                          id="noCheckin"
                          formControlName="isCheckInEnabled"
                          name="isCheckInEnabled"
                        />
                        <label class="form-check-label">No</label>
                      </div>
                    </div>
                  </div>

                  <div
                    class="form-group row"
                    *ngIf="this.adShift.get('isCheckInEnabled').value"
                  >
                    <label class="align-self-end col-sm-4 font-weight-500"
                      >Proximity Enabled</label
                    >
                    <div class="col-sm-8">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          [value]="true"
                          id="yesProx"
                          formControlName="isProximityEnabled"
                          name="isProximityEnabled"
                        />
                        <label class="form-check-label">Yes</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          [value]="false"
                          id="noProx"
                          formControlName="isProximityEnabled"
                          name="isProximityEnabled"
                        />
                        <label class="form-check-label">No</label>
                      </div>
                    </div>
                  </div>

                  <div
                    class="form-group row mb-0"
                    *ngIf="
                      this.adShift.get('isProximityEnabled').value &&
                      this.adShift.get('isCheckInEnabled').value
                    "
                  >
                    <label class="align-self-end col-sm-4 font-weight-500"
                      >Proximity</label
                    >
                    <div class="col-sm-8">
                      <ng-select
                        [items]="reportingLocationRadius"
                        class="custom"
                        bindLabel="name"
                        formControlName="proximity"
                        name="proximity"
                        appendTo="body"
                        [multiple]="false"
                        [searchable]="true"
                        [clearable]="true"
                        [closeOnSelect]="true"
                      >
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-5">
                <div class="col-md-4">
                  <div class="form-group m-0">
                    <label class="font-weight-500">Select Skillsets </label>
                    <ng-select
                      [items]="skillArr"
                      class="custom"
                      bindLabel="name"
                      placeholder="Skillsets"
                      appendTo="body"
                      [multiple]="false"
                      [searchable]="true"
                      [clearable]="true"
                      [closeOnSelect]="true"
                      formControlName="SkillSets"
                      (change)="
                        setSubSkill(this.adShift.controls.SkillSets.value)
                      "
                    >
                    </ng-select>
                  </div>
                </div>

                <div class="col-md-4" *ngIf="this.tier == 2">
                  <div class="form-group m-0">
                    <label class="font-weight-500"> Select Speciality </label>
                    <ng-select
                      [items]="subSkill"
                      class="custom"
                      bindLabel="name"
                      placeholder="Speciality"
                      appendTo="body"
                      [multiple]="false"
                      [searchable]="true"
                      [closeOnSelect]="true"
                      [clearable]="true"
                      formControlName="subSkillSets"
                    >
                    </ng-select>
                  </div>
                </div>

                <div class="col-md-4 align-self-end">
                  <button class="btn btn-clear p-2" (click)="clearSkill()">
                    Clear
                  </button>
                  <button class="btn btn-add p-2" (click)="addSkills()">
                    Add
                  </button>

                  <div *ngIf="this.skillsLabel != null">
                    <div
                      class="labels"
                      *ngFor="let item of skillsLabel; let i = index"
                    >
                      <span class="text"
                        >{{ item[0] }} >
                        <ng-container *ngIf="this.tier == 2">{{
                          item[1]
                        }}</ng-container>
                      </span>
                      <span
                        class="material-icons bg-danger rounded btn-close"
                        (click)="clearLabel(i)"
                      >
                        clear
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  (click)="modelClose()"
                >
                  Close
                </button>
                <button
                  type="button"
                  class="btn btn-info"
                  (click)="createShift()"
                >
                  {{ submitBtn }}
                  <span
                    *ngIf="loaderCreateShift"
                    class="spinner-border spinner-border-sm ml-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
