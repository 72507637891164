import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { CompanyService } from 'src/app/admin/service/companyservice/company.service';
import { deleteAlert } from 'src/app/shared/sweet-alert/sweet-alert';
import { CentralBuilderService } from 'src/app/users/service/central-builder/central-builder.service';
import { environment } from 'src/environments/environment';
import { QuestionModalComponent } from '../question-modal/question-modal.component';
import { COMMON_MESSAGES } from 'src/app/core/utils/helper';

@Component({
  selector: 'create-edit-module',
  templateUrl: './create-edit-module.component.html',
  styleUrls: ['./create-edit-module.component.scss'],
})
export class CreateEditModuleComponent implements OnInit {
  toogleView = false;
  dropDownIndex = [];
  selectedModule;
  sequenceType: boolean = true;
  questionsLength: number;
  moduleUpdateForm: FormGroup;
  @Input() moduleId: string;
  isScoring: boolean = false;
  @ViewChild('createQuestion', { static: true })
  createQuestion: TemplateRef<any>;
  @ViewChild('updateModule', { static: true })
  updateModule: TemplateRef<any>;

  questionTypes = [
    {
      name: 'Multiple Choices',
      type: 2,
    },
    {
      name: 'Check Box',
      type: 5,
    },
    {
      name: 'Polling',
      type: 4,
    },
    {
      name: 'True or False Options',
      type: 3,
    },
    {
      name: 'Signature',
      type: 6,
    },
    {
      name: 'Profile Fields',
      type: 7,
    },
    {
      name: 'Free Text(Long)',
      type: 1,
    },
    {
      name: 'Free Text(Short)',
      type: 8,
    },
    {
      name: 'Numeric',
      type: 9,
    },
    {
      name: 'Date & Time',
      type: 10,
    },
    {
      name: 'Drop Down',
      type: 11,
    },
    {
      name: 'Attachment',
      type: 12,
    },
    {
      name: 'Star Rating',
      type: 13,
    },
    {
      name: 'Nominate User',
      type: 14,
    },
    {
      name: 'Sequence Type',
      type: 15,
    },
    {
      name: 'Photopick',
      type: 16,
    },
  ];
  scores = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  finalSubmissionQuestions = [];
  validators = [];
  @Output() overViewEvent = new EventEmitter<string>();
  constructor(

    private modalService: NgbModal,
    private config: NgbRatingConfig,
    private centralBuilderService: CentralBuilderService,
    private formBuilder: FormBuilder,
    private companyService: CompanyService,
    private toastService: ToastrService
  ) {
    // config.max = 5;
    config.readonly = true;
  }
  onClickDropDown(Dropindex) {
    if (this.dropDownIndex.includes(Dropindex)) {
      const index = this.dropDownIndex.indexOf(Dropindex);
      if (index > -1) {
        this.dropDownIndex.splice(index, 1);
      }
    } else {
      this.dropDownIndex.push(Dropindex);
    }
  }
  ngOnInit(): void {
    this.getQuestions();
  }

  initForm() {
    this.moduleUpdateForm = this.formBuilder.group({
      moduleName: this.selectedModule?.moduleName,
      questions: this.formBuilder.array([]),
      postSubmissionResponse: this.formBuilder.array([]),
      postSubmissionRequired: [false, Validators.required],
      postSubmissionMessage: [''],
      postSubmissionImage: [''],
      closingMessage: ['', Validators.required],
      randomOrder: true,
      scorePerQuestion: 0,
      scoringEnabled: false,
      status: '',
      viewCount: [0, [Validators.required, Validators.pattern('^[1-9]\d*$'), Validators.pattern(`^[1-${this.questionsLength}]`)]],
      welComeAttachement: ['', Validators.required],
      welComeMessage: ['', Validators.required],
    });
  }

  get f() {
    return this.moduleUpdateForm.controls;
  }

  enableScoring(){
    this.isScoring = !this.isScoring
  }

  welcomeBannerUpload(event) {
    if (event.target.files && event.target.files.length) {
      let file: File = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
      const fileUpload = this.companyService
        .uploadWallFile(file)
        .pipe(
          finalize(() => {
            fileUpload.unsubscribe();
          })
        )
        .subscribe(
          (data: any) => {
            this.moduleUpdateForm.patchValue({
              welComeAttachement: `${environment.imageUrl}/${data.data.filePath}`,
            });
          },
          (error: HttpErrorResponse) => {
            this.toastService.error(error.error.message);
          }
        );
      } else{
        this.toastService.error(COMMON_MESSAGES.UPLOAD_ONLY_IMAGE);
        event.target.value = '';
        file = null;
      }
    }
  }
  postSubmissionImageUpload(event) {
    if (event.target.files && event.target.files.length) {
      let file: File = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
      const fileUpload = this.companyService
        .uploadWallFile(file)
        .pipe(
          finalize(() => {
            fileUpload.unsubscribe();
          })
        )
        .subscribe(
          (data: any) => {
            this.moduleUpdateForm.patchValue({
              postSubmissionImage: `${environment.imageUrl}/${data?.data?.filePath}`,
            });
          },
          (error: HttpErrorResponse) => {
            this.toastService.error(error.error.message);
          }
        );
      } else{
        this.toastService.error(COMMON_MESSAGES.UPLOAD_ONLY_IMAGE);
        event.target.value = '';
        file = null;
      }
    }
  }
  onDeleteWelcomeImage() {
    this.moduleUpdateForm.patchValue({
      welComeAttachement: '',
    });
  }
  onDeletePostSumissionImage() {
    this.moduleUpdateForm.patchValue({
      postSubmissionImage: '',
    });
  }

  moduleUpdate() {
    const Control = this.postSumissionArray;
    if (this.moduleUpdateForm.controls.postSubmissionRequired.value === false) {
      delete this.moduleUpdateForm.value.postSubmissionResponse;
      delete this.moduleUpdateForm.value.postSubmissionRequired;
      delete this.moduleUpdateForm.value.postSubmissionMessage;
      delete this.moduleUpdateForm.value.postSubmissionImage;
    } else {
      if (this.moduleUpdateForm.value.postSubmissionResponse && this.moduleUpdateForm.value.postSubmissionResponse.length > 0) {
        this.moduleUpdateForm.value.postSubmissionResponse = this.moduleUpdateForm.value.postSubmissionResponse.map(id => id._id)
      }
    }
    const data = {
      ...this.moduleUpdateForm.value,
      status: 1,
      // postSubmissionResponse:Control.value.map((item) => item._id)
    }

    data._id = this.moduleId;
    const sub = this.centralBuilderService.updateModule(data, this.moduleId)
    .pipe(
      finalize(() => {
        sub.unsubscribe();
      })
    ).subscribe((res)=> {

      this.overViewEvent.emit(null)
      this.modalService.dismissAll()
      this.toastService.success('Published Successfully')
    })
  }
  saveAsDraft() {
    delete this.moduleUpdateForm.value.postSubmissionResponse;
    delete this.moduleUpdateForm.value.postSubmissionRequired;
    delete this.moduleUpdateForm.value.postSubmissionMessage;
    delete this.moduleUpdateForm.value.postSubmissionImage;

    const data = {
      ...this.moduleUpdateForm.value,
      status: 0
    }
    data._id = this.moduleId;
    const sub = this.centralBuilderService.updateModule(data, this.moduleId)
    .pipe(
      finalize(() => {
        sub.unsubscribe();
      })
    ).subscribe((res)=> {

      this.toastService.success('Saved as draft successfully')
      this.overViewEvent.emit(null)
    })
  }

  openPublishPopup() {
    this.modalService.open(this.updateModule);
  }
  getQuestions() {

    this.centralBuilderService
      .getSingleQuestions(this.moduleId)
      .subscribe((res) => {

        let selectedQuestions = res.data.data.questions.filter( i =>  i );


        this.selectedModule = res.data.data;
        this.questionsLength = res.data.data.questions.length;
        this.initForm();
        const newData = res.data.data.questions.map(
          ({ _id, question }) => ({ _id, question })
        );
        const modifiedData = newData.map((rawProduct) => {
          return { ...rawProduct, flag: false };
        });
        this.finalSubmissionQuestions = modifiedData;
        const Control = this.madatoryQuestionsArray;
        selectedQuestions.forEach(element => {
          let field = this.formBuilder.group({
            _id: [element._id, Validators.compose([Validators.required])],
            required: element.required,
          });
          Control.push(field);
        });
        this.moduleUpdateForm.patchValue({
          postSubmissionRequired: this.selectedModule.postSubmissionRequired,
          postSubmissionMessage: this.selectedModule.postSubmissionMessage,
          postSubmissionImage: this.selectedModule.postSubmissionImage,
          closingMessage: this.selectedModule.closingMessage,
          randomOrder: this.selectedModule.randomOrder,
          scorePerQuestion: this.selectedModule.scorePerQuestion,
          scoringEnabled: this.selectedModule.scoringEnabled ,
          status:1 ,
          viewCount: this.selectedModule.viewCount,
          welComeAttachement:this.selectedModule.welComeAttachement ,
          welComeMessage:this.selectedModule.welComeMessage ,
          questions: selectedQuestions,
        })
        this.sequenceType = this.selectedModule.randomOrder
        this.onSequenceChange(this.sequenceType);
        this.selectedModule?.postSubmissionResponse.length && this.selectedModule?.postSubmissionResponse.forEach(element => {
          const questionData = this.selectedModule?.questions.find(id => id._id === element)
          let field = this.formBuilder.group({
            name: [questionData.question, Validators.compose([Validators.required])],
            _id: [questionData._id, Validators.compose([Validators.required])],
          });
          this.postSumissionArray.push(field);
        });
      });
  }
  get postSumissionArray(): FormArray {
    return this.moduleUpdateForm.get('postSubmissionResponse') as FormArray;
  }

  async getSubmissionQuestions(value) {
    if (value) {
      const Control = this.postSumissionArray;
      const index = await Control.value.findIndex((x) => x._id === value._id);

      if (index > -1) {
        this.toastService.error(`${value.name} is already added`);
      } else {
        let field = this.formBuilder.group({
          name: [value.question, Validators.compose([Validators.required])],
          _id: [value._id, Validators.compose([Validators.required])],
        });
        Control.push(field);
      }
    }
  }

  deletePostSubmission(index) {
    const Control = this.postSumissionArray;

    Control.removeAt(index);
  }

  get madatoryQuestionsArray(): FormArray {
    return this.moduleUpdateForm.get('questions') as FormArray;
  }

  async addQuestionToMandatory(event) {
    const value = event.target.value;
    if (value) {
      const Control = this.madatoryQuestionsArray;
      const index = await Control.value.findIndex((x) => x._id === value);
      if (index > -1) {
        Control.at(index).patchValue({ _id: value ,required: event.target.checked})
        // let qusData=Control.value[index];
        // qusData.status=value.status;
      } else {
        let field = this.formBuilder.group({
          _id: [value, Validators.compose([Validators.required])],
          required: event.target.checked,
        });
        Control.push(field);

      }

     /* if (index == -1) {
        Control.removeAt(index);
      } else {
        let field = this.formBuilder.group({
          _id: [value, Validators.compose([Validators.required])],
          required: true,
        });
        Control.push(field);
      }*/
    }
  }

  getValidatorFnArray(setting?) {
    const validators = [];

    if (setting.charTypes.lowerCase) {
      validators.push(Validators.minLength(0));
    }
    if (setting.charTypes.specialChar) {
      validators.push(Validators.maxLength(this.questionsLength));
    }

    this.validators.push(...validators);
  }

  onDeleteQuestion(questionId, moduleId) {
    deleteAlert().then((result) => {
      this.toastService.success("Question Deleted Successfully");
      if (result.value) {
        const mobileData = {
          moduleId,
          questionId,
        };
        this.centralBuilderService.deleteQuestion(mobileData).subscribe(
          (data: any) => {
            this.getQuestions();
          },
          (error: HttpErrorResponse) => {
            this.toastService.error(error.message, error.statusText);
          }
        );
      }
    });
  }

  onPostSubmistionChange(value) {

    if (value) {
      this.f.postSubmissionMessage.setValidators([Validators.required]);
      // this.f.postSubmissionImage.setValidators([Validators.required]);
      this.f.closingMessage.setValidators([Validators.required]);
    } else {
      this.f.postSubmissionMessage.clearValidators();
      this.f.postSubmissionMessage.updateValueAndValidity();
      // this.f.postSubmissionImage.clearValidators();
      // this.f.postSubmissionImage.updateValueAndValidity();
      this.f.closingMessage.clearValidators();
      this.f.closingMessage.updateValueAndValidity();
    }
  }
  onSequenceChange(value) {
    this.sequenceType = value;
    if (value) {
      this.f.viewCount.setValidators([
        Validators.pattern(`^([1-9]\d?|${this.questionsLength})`),
      ]);
      this.f.viewCount.updateValueAndValidity();
    } else {
      this.f.viewCount.clearValidators();
      this.f.viewCount.updateValueAndValidity();
    }
  }

  toogleButton() {}

  openPopup(item) {
    const questions = this.questionsLength;
    const data = {
      ...item,
      moduleId: this.moduleId,
      questionslength: questions,
    };
    const modalRef = this.modalService.open(QuestionModalComponent, {
      size: 'lg',
      windowClass: 'modal-ui-fix right-align',
    });
    modalRef.componentInstance.value = data;
    modalRef.componentInstance.isEdit = false;
    modalRef.dismissed.subscribe((result) => {
      this.getQuestions();
    });
  }

  onEditPopup(item) {
    const questions = this.questionsLength;
    const data = {
      ...item,
      questionslength: questions,
      isEdited: true,
    };
    const modalRef = this.modalService.open(QuestionModalComponent, {
      size: 'lg',
      windowClass: 'modal-ui-fix right-align',
    });
    modalRef.componentInstance.value = data;
    modalRef.componentInstance.isEdit = true;
    modalRef.dismissed.subscribe((result) => {
      this.getQuestions();
    });
  }
}
