<div
  #sidemenu
  id="sidemenu"
  class="p-3 d-none d-sm-block pt-5 sidemenu"
  *ngIf="!isView"
>
  <aside>
    <div class="form-group">
      <label class="d-block text-center">BUSINESS UNIT</label>
      <ng-select
        [items]="buForShift"
        [loading]="buLoader"
        loadingText="Please wait data is loading..."
        class="custom"
        bindLabel="orgName"
        placeholder="Select/Search Business unit"
        appendTo="body"
        [searchable]="true"
        [clearable]="true"
        [closeOnSelect]="true"
        [(ngModel)]="planBuId"
      >
      </ng-select>
    </div>

    <div class="row">
      <div class="col pr-md-3">
        <button class="btn btn-danger btn-block">Cancel</button>
      </div>
      <div class="col pl-md-0">
        <button
          class="btn btn-info btn-block"
          (click)="viewClicked()"
        >
          View
        </button>
      </div>
    </div>
  </aside>
</div>

<div class="d-flex flex-column" *ngIf="isView">
  <ul
    ngbNav
    #companyNav="ngbNav"
    [(activeId)]="activeId"
    class="nav-tabs rounded mb-3"
  >
    <li [ngbNavItem]="1">
      <a ngbNavLink>DASHBOARD</a>
      <ng-template ngbNavContent>
        <app-timesheet-overview
          [refresh]="refreshNum"
          [businessUnitId]="businessUnitId"
          [dataAttendance]="dataAttendance"
          (onButtonClick)="onButtonClick($event)"
          (onUserData)="qrColin($event)"
        ></app-timesheet-overview>
      </ng-template>
    </li>
    <li [ngbNavItem]="2" [disabled]="true">
      <a ngbNavLink>ATTENDANCE</a>
      <ng-template ngbNavContent>
        <app-attendance
          [businessUnitId]="businessUnitId"
          [dataAttendance]="dataAttendance"
          (ChangeTabId)="changeActiveTab($event)"
          (onButtonClick)="onButtonClick($event)"
          (onUserData)="qrColin($event)"
        ></app-attendance>
      </ng-template>
    </li>
    <li [ngbNavItem]="3">
      <a ngbNavLink>TIMESHEET</a>
      <ng-template ngbNavContent>
        <app-timesheet-data
          [refresh]="refreshNum"
          [businessUnitId]="businessUnitId"
        ></app-timesheet-data>
      </ng-template>
    </li>
    <li [ngbNavItem]="4">
      <a ngbNavLink>HISTORICAL TIMESHEET LOOKUP</a>
      <ng-template ngbNavContent>
        <app-timesheet-lookup
          [refresh]="refreshNum"
          [businessUnitId]="businessUnitId"
        ></app-timesheet-lookup>
      </ng-template>
    </li>

    <li class="align-self-center flex-grow-1 pr-2">
      <button
        class="btn btn-primary pull-right"
        (click)="refreshPage(activeId)"
      >
        REFRESH
      </button>
    </li>
  </ul>
  <div [ngbNavOutlet]="companyNav"></div>
</div>
