import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subject, Subscription, of } from 'rxjs';
import { map, debounceTime, distinctUntilChanged, mergeMap, delay, finalize } from 'rxjs/operators';
import { SortableHeader, SortEvent } from 'src/app/shared/directive/sortable.directive';
import { StorageService } from 'src/app/core/service/storage.service';

import { BoardSetupService } from 'src/app/users/service/board-setup/board-setup.service';
import { UsersService } from 'src/app/users/service/user-service/users.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reported-posts',
  templateUrl: './reported-posts.component.html',
  styleUrls: ['./reported-posts.component.scss']
})
export class ReportedPostsComponent implements OnInit {
  isTableLoading: boolean = true;
  usersPTable
  // isBuUnitEdited:boolean = false
  // sectionId:string
  // subSectionId:string
  // bussinessUnitEditForm:FormGroup
  public userssearchInput = new Subject<KeyboardEvent>();

  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  // companyName: string;
  /// bussiness unit over view table config
  onSortUsers({ column, direction }: SortEvent, field) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.usersPTable.sortWith = field;
    this.usersPTable.sortBy = direction;
    this.loadUsers();
  }

  onPageChangeUsers(page: number) {
    this.usersPTable.page = page;
    this.loadUsers();
  }
  onLimitChangeUsers() {
    this.loadUsers();
  }

  private usersSearchSubscription: Subscription;

  constructor(
    private boardService: BoardSetupService,
    public toastService: ToastrService,
    private storageService: StorageService,
    private formBuilder: FormBuilder
  ) {
    // this.config.notFoundText = 'Custom not found';
    this.usersPTable = {
      sortBy: 'desc',
      sortWith: 'createdAt',

      data: [],
      page: 1,
      limit: 10,

    };
    // this.companyName = this.storageService.getByKey('companyId');

    this.usersSearchSubscription = this.userssearchInput
      .pipe(
        map((event) => {
          const target = event.target as HTMLTextAreaElement;
          return target.value;
        }),
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap((search) => of(search).pipe(delay(500)))
      )
      .subscribe((input) => {
        this.usersPTable.page = 1;
        this.loadUsers();
      });
  }

  ngOnInit(): void {
    this.loadUsers();
  }

  loadUsers() {
    const {data, loading,total ,...rest}  = this.usersPTable
    const subscribe = this.boardService
      .getAllReportedPosts(rest)
      .pipe(
        finalize(() => {

          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const { recordsTotal, data } = response;
          this.usersPTable.data = data;
          this.usersPTable.total = recordsTotal;
          this.isTableLoading = false;
        },
        (error) => {
          this.usersPTable.data = [];
          this.usersPTable.total = 0;
          this.toastService.error('No list found');
        }
      );
  }
  changePostStatus(id:string, status:number){
    const data =  status == 1 ? 2 : 1;
      const subscribe = this.boardService
      .updateReportedPost(id,data)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          this.toastService.success('Data updated Successfully');
          this.loadUsers()
        },
        (error) => {
          this.toastService.error('Error Found');

        }
      );
    }

  ngOnDestroy(): void {
    this.usersSearchSubscription && this.usersSearchSubscription.unsubscribe();
  }

}
