import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { CompanyService } from 'src/app/admin/service/companyservice/company.service';
import { UsersService } from 'src/app/users/service/user-service/users.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent implements OnInit {
  activeId;
  form: FormGroup;
  public fields: any[] = [];
  IconUrl: string = null;
  unsubcribe: any;
  @Input() userId: string = null;
  @Output() overViewEvent = new EventEmitter<string>();
  public forgetPasswordForm: FormGroup;
  userData: any;
  viewProfileData:any=null;
  constructor(
    private toastService: ToastrService,
    private userService: UsersService,
    private companyService: CompanyService,

  ) {
    this.form = new FormGroup({
      fields: new FormControl(JSON.stringify(this.fields)),
    });
    this.unsubcribe = this.form.valueChanges.subscribe((update) => {

      this.fields = JSON.parse(update.fields);
    });
  }

  ngOnInit(): void {
    this.userId && this.getSingleUserEdit(this.userId);

  }

  goBack() {
    this.overViewEvent.emit(null);
  }
  getFields() {
    return this.fields;
  }

  getSingleUserEdit(userId) {
    const data={userId:this.userId}
    const subscribe = this.userService
    .getOneUser(data)
    .pipe(
      finalize(() => {
         subscribe.unsubscribe();
      })
    )
    .subscribe(
      (response: any) => {
        this.userData=response?.data?.data;
        const modifiedData = this.userData.otherFields.map((rawProduct) => {
                  return {
                    disabled: true,
                    ...rawProduct,
                  };
                });

                this.fields = modifiedData.filter(
                  (field) => field.status === 1
                );
                this.form = new FormGroup({});
                this.fields.forEach((x) => {
                  if (x.type == 'checkbox') {
                    this.form.addControl(x.fieldName, new FormGroup({}));
                    x.options.forEach((o) => {
                      (this.form.get(x.fieldName) as FormGroup).addControl(
                        o.key,
                        new FormControl(false)
                      );
                    });
                  } else {
                    this.form.addControl(
                      x.fieldName,
                      new FormControl(
                        x.value || '',
                        x.required ? Validators.required : null
                      )
                    );
                  }
                });
              },



      (error) => {
        this.userData=null;
      }
    );
     }
}
