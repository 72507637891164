import { finalize } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { CentralBuilderService } from '../users/service/central-builder/central-builder.service';
import { NotificationService } from '../users/service/notification/notification.service';
import { ToastrService } from 'ngx-toastr';
import { SignatureQuestionsComponent } from '../users/pages/central-builder/my-forms/signature-questions/signature-questions.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { CustomFormService } from '../users/service/custom-form/custom-form.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { UsersService } from '../users/service/user-service/users.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-external-link',
  templateUrl: './external-link.component.html',
  styleUrls: ['./external-link.component.scss']
})
export class ExternalLinkComponent implements OnInit {

  selectedModule: any;
  formDetails: any;
  questions: any;
  viewQuestions: any;
  images = [];
  typeFourteenQuestion;
  questionIDFourteen;
  signatureImg: string;
  isDisable = false;
  hasParent: any = question => 0 === question?.conditionalQuestions?.length;
  constructor(
    private centralBuilderService: CentralBuilderService,
    private toastService: ToastrService,
    private dialogRef: MatDialog,
    private customFormService: CustomFormService,
    private userService: UsersService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.getExternalQestion()
  }

  getExternalQestion() {
    let payload = {
      formName: this.activatedRoute.snapshot.params.id
    }

    this.userService.getQuestionDetails(payload).subscribe((res) => {
      this.selectedModule = res?.data.data || null;
      this.questions = this.selectedModule.questions.filter(v => 7 !== v.type) || [];
      this.viewQuestions = this.questions.filter(x => 0 === x.conditionalQuestions.length) || [];
      let typeFourteen;
      this.viewQuestions?.map(item => {
        item.answer = '';
        if (item.type == 10) {
          item.answer = { data: '', time: '' }
        }
        if (item.type === 14) {
          typeFourteen = item._id;
        }
        if (item.type === 15) {
          item?.options.map((final: any, index) => {
            this.images[index] = { "src": final.imageSrc, "value": final.value, "option": final._id };
          })
        }
      })
      this.getNominateUserType(typeFourteen);
    })

  }

  getNominateUserType(questionId) {
    let finalData = {
      questionId: questionId
    }
    this.centralBuilderService.getNominateUser(finalData).subscribe((data) => {
      this.setDropdown(data.data, questionId);
    });
  }

  setDropdown(data, questionId) {
    this.typeFourteenQuestion = data?.items || [];
    this.questionIDFourteen = questionId;
  }

  submit() {
    const answers = this.viewQuestions.reduce((prev, question, i) => {
      let answer = null,
        _id = question._id,
        type = question.type;
      switch (question.type) {
        case 1:
        case 2:
        case 3:
        case 6:
        case 8:
        case 9:
        case 11:
        case 14:
        case 12:
        case 13:
          answer = question.answer;
          break;
        case 10:
          if (-1 !== question.dateTime.indexOf(1) && !!question.answer && !!question.answer.date) {
            answer = answer || {};
            answer["date"] = question.answer.date;
          }

          if (
            -1 !== question.dateTime.indexOf(2) &&
            !!question.answer &&
            !!question.answer.time

          ) {
            answer = answer || {};
            if (question?.answer.time?.hour && question?.answer.time?.minute) {
              answer["time"] = this.convert24hourTo12HourFormat(question.answer.time?.hour + ':' + question.answer.time?.minute)
            }
          }
          if ((-1 !== question.dateTime.indexOf(1) && -1 !== question.dateTime.indexOf(2)) &&
            !(!!question.answer && !!question.answer.date && !!question.answer.time?.hour && !!question.answer.time?.minute)) {
            answer = false;
          }
          break;
        case 5:
        case 4:
          answer = question.options?.filter(v => v.answer);
          if (!answer.length) {
            answer = null;
          }
          break;
        case 16:
          break;
        default:
          answer = false;
          break;
      }
      return prev.concat({ _id, answer, type });
    }, []);
    if (answers?.some(v => v.type === 9 && !(!!v.answer))) {
      this.toastService.error('Please enter valid numeric values');
      return;
    }
    this.viewQuestions.filter(q => q.type === 15).forEach(sq => {
      let inans = answers.find(answer => answer._id.toString() === sq._id.toString());
      inans.answer = sq.options;
    });
    if (answers.some(v => !!!v.answer)) {
      this.toastService.error("Answer all questions");
      return;
    }
    let _questions = this.questions.map(v => v._id);
    const payload = {
      answers: answers,
      customFormId: this.selectedModule?.customFormId,
      questions: _questions
    }
    answers.length > 0 ? this.centralBuilderService.submitExternalAnswers(payload).subscribe((data) => {
      if (data.data) {
        this.toastService.success("Form Submitted Successfully")
        this.isDisable = true;
      }
    }) : '';
  };

  convert24hourTo12HourFormat(time) {
    const time_part_array = time.split(":");
    let ampm = 'am';
    if (time_part_array[0] >= 12) {
      ampm = 'pm';
    }
    if (time_part_array[0] > 12) {
      time_part_array[0] = time_part_array[0] - 12;
    }
    const formatted_time = time_part_array[0] + '-' + time_part_array[1] + '-' + ampm;
    return formatted_time;
  }


  setChild(question, option, index?: number) {
    if (question.type == 11) {
      this.selectedModule.questions[index].answer = option;
      this.selectedModule.questions[index].isVisibleList = !this.selectedModule.questions[index].isVisibleList;
    }
    this.checkQuestions(question, option._id);
  };

  checkQuestions(question, a) {

    let q = question._id;
    const filteredQuestions = this.questions
      ?.filter(
        v =>
          !this.hasParent(v) &&
          v?.conditionalQuestions?.some(
            c => c.questionId === q && c.optionId === a
          )
      )
      ?.map(c => {
        c["cQuestion"] = q;
        c["cOption"] = a;
        return JSON.parse(JSON.stringify(c));
      });
    if (question.type == 5) {
      setTimeout(() => {
        if (!question.options.filter(option => option._id === a)[0].answer) {
          let allQuestionsadded = filteredQuestions.map(v => v._id);

          let questionsNeedToDelete = this.viewQuestions?.reduce((prev, curr, i) => {
            return allQuestionsadded.includes(curr._id) ? prev.concat(i) : prev;
          }, []);
          questionsNeedToDelete.sort((a, b) => b - a);
          questionsNeedToDelete.forEach(v => {
            this.viewQuestions.splice(v, 1);
          })
        } else {
          const index = this.viewQuestions?.findIndex(ques => ques._id == q) + 1;
          this.viewQuestions.splice(index, 0, ...filteredQuestions);
        }

        this.viewQuestions = this.viewQuestions?.reduce((prev, curr, i) => {
          const index = prev.findIndex(v => v._id === curr._id);
          return -1 === index ? prev.concat(curr) : prev;
        }, []);
      }, 100);
    } else {
      setTimeout(() => {

        this.viewQuestions = this.viewQuestions?.filter(ques =>
          "cQuestion" in ques ? ques.cQuestion !== q : true
        );
        const index = this.viewQuestions.findIndex(ques => ques._id == q) + 1;
        this.viewQuestions.splice(index, 0, ...filteredQuestions);

        this.viewQuestions = this.viewQuestions.reduce((prev, curr, i) => {
          const index = prev.findIndex(v => v._id === curr._id);
          return -1 === index ? prev.concat(curr) : prev;
        }, []);

      })

    }
  };

  selectPolling(questionId, option) {
    let question = this.questions.find(q => q._id.toString() === questionId.toString());
    if (!question.pollingSelectionCount || (1 === question.pollingSelectionCount)) {
      question.options.forEach(opt => opt.answer = (option._id.toString() === opt._id.toString()));
    } else if ((question.options.filter(op => op.answer).length === question.pollingSelectionCount) && !option.answer) {
      this.toastService.error('maxinum options reach for this question')
    } else {
      question.options.find(op => op._id.toString() === option._id.toString()).answer =
        !question.options?.find(op => op._id.toString() === option._id.toString()).answer;
    }
  }

  openDialog(questionid) {
    const modalRef = this.dialogRef.open(SignatureQuestionsComponent, {
      height: '500px',
      width: '600px',
    });
    modalRef.componentInstance.takeSignature.subscribe((signURL: any) => {
      this.signatureImg = signURL;
      questionid.answer = signURL;
      this.close(modalRef);
    });
  }


  close(modalRef) {
    modalRef.close();
  }

  fileUpload(event, question) {
    let typeList = question.options?.map(data => { return data.value.toLowerCase() }) || [];
    if (event.target.files && event.target.files.length) {
      const file: File = event.target.files[0];
      let fileType = file.name.split('.')
      let fileExe = fileType?.length ? fileType[fileType.length - 1].toLowerCase() : null;
      if (fileExe && typeList?.includes(fileExe)) {
        var useraddData = new FormData();
        useraddData.append("file", file);
        const fileUpload = this.customFormService
          .uploadFiles(useraddData)
          .pipe(
            finalize(() => {
              fileUpload.unsubscribe();
            })
          )
          .subscribe(
            (data: any) => {
              question.answer = { url: data?.filePath || null, name: file.name }
            },
            (error: HttpErrorResponse) => {
              this.toastService.error(error?.message || 'File upload error');
            }
          );
      }
      else {
        this.toastService.error("Only this format are accecpted", typeList?.toString());
      }
      // }
    } else {
      this.toastService.error("Only this format are accecpted", typeList?.toString());
    }
  }

  multiSelect(value, question) {
    question.answer = value?.map(x => {
      if (this.typeFourteenQuestion?.find(qus => qus._id == x)) {
        return this.typeFourteenQuestion?.find(qus => qus._id == x)
      }
    });
  }

  drop(event: CdkDragDrop<string[]>, question: any) {
    moveItemInArray(this.images, event.previousIndex, event.currentIndex);
  }

}
