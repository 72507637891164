import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss']
})
export class FormsComponent implements OnInit {
  isFormEdited: boolean = false
  isFormManaged: boolean = false
  isFormViewed: boolean = false
  formDetails:any=null;
  activeId = 1
  formID;
  manageId: string;
  overViewData:any;
  constructor() { }

  ngOnInit(): void {
  }


  manageForm(data: any) {
    this.isFormManaged = true;
    this.overViewData = data;
    this.manageId = data._id
    this.formDetails=data;
    this.activeId = 2
    if(this.activeId !== 4)
    {
      this.isFormViewed = false;
    }
  }
  formEdit(data: any) {
    this.activeId = 2
    this.isFormEdited = true;
    this.formID = data.id;
    this.formDetails=data;
    if(this.activeId !== 4)
    {
      this.isFormViewed = false;
    }
  }
  formView(data: any) {
    this.activeId = 4
    this.isFormViewed = true
    this.formID = data.moduleId;
    this.formDetails=data;
  }
  overViewEvent(value) {
    // this.isEdited = false
    this.activeId = 1
    this.formID = null
    this.manageId = null
    this.isFormEdited = false;
    this.formDetails=null;
    if(this.activeId !== 4)
    {
      this.isFormViewed = false;
    }
    setTimeout(() => {                           // <<<---using ()=> syntax
      this.isFormManaged = false;
    }, 200);
  }

}
