import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileDownloadService {

  constructor(private http: HttpClient) { }

  downloadFile(url: string, fileName: string): void {
    const headers = new HttpHeaders();
    headers.append('Accept', 'application/octet-stream');

    this.http.get(`${environment.imageUrl}${url}`, { responseType: 'blob', headers: headers }).subscribe((data: Blob) => {
      saveAs(data, fileName);
    }, error => {
      console.error('Failed to download file:', error);
    });
  }
}
