<div class="card">
    <div class="card-header flexi-header">
        <h3>POINT SYSTEM</h3>
    </div>
    <div class="card-body">
        <div class="justify-content-SB">
            <div class="col">
                <div class="form-group">
                    <label class="font-weight-bold red-heading" for="exampleInputEmail1">Title</label>
                    <input type="email" class="form-control" placeholder="" maxlength="50" [(ngModel)]="pointObj.title" [disabled]="pointObj.status == true? true : false"/>
                </div>
            </div>
            <div class="col mb-2">
                <div class="form-group">
                    <label class="font-weight-bold red-heading" for="exampleInputEmail1">Description</label>
                    <input type="title" class="form-control" placeholder="" maxlength="50" [(ngModel)]="pointObj.description" [disabled]="pointObj.status == true ? true : false"/>
                </div>
            </div>
            <div class="col mb-2" >
                <div class="form-group">
                    <label class="font-weight-bold red-heading" for="exampleInputEmail1">Icon</label>
                    <br>
                    <div style="display: flex;">
                      <img src="{{'./assets/images/c-icons/'+pointObj.iconURL+'.png'}}" alt="" height="99px">
                      <!-- expand icon -->
                      <span (click)="expandIcons=!expandIcons" class="ddStyle">
                          <img src="{{'./assets/images/c-icons/1.png'}}" alt="" height="30px" style="vertical-align: top;">
                      </span>
                      <!-- icons div -->
                      <div id="iconsContainer" *ngIf="expandIcons" class="col-xs-12 col-sm-12 col-md-6 col-lg-4" (click)="expandIcons=false">
                          <div *ngFor="let icon of iconsList" class="iconitem" (click)="selectIcon(icon)">
                              <img src="{{'./assets/images/c-icons/'+icon+'.png'}}" alt="img" height="45px">
                          </div>
                      </div>
                  </div>
                </div>
            </div>

            <div>
                <button class="btn btn-red-back ml-3" (click)="cancelData()">Cancel</button>
                <ng-container *ngIf="!pointObj.isFromEdit">
                  <button  class="btn btn-add ml-3" [disabled]="!pointObj.iconURL || !pointObj.description || !pointObj.title" (click)="activateRecord(pointObj)">Activate</button>
                </ng-container>
                <ng-container *ngIf="pointObj.isFromEdit">
                  <button  class="btn btn-add ml-3" (click)="updateRecord(pointObj)">Update</button>
                </ng-container>

            </div>
        </div>

        <div class="row mt-3">
            <div class="col">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th scope="col">TITLE</th>
                                <th scope="col">DESCRIPTION</th>

                                <th scope="col">ICON</th>

                                <th scope="col">STATUS</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of pointSystems; let i = index">
                                <ng-container>
                                    <td>
                                        <label class="container">
                                            <input type="checkbox"  id="checkBox{{i}}" name="checkName{{i}}" [(ngModel)]="item.isCheckedBox" (change)="onCheckBoxChanges(item, i, $event)" >
                                            <span class="checkmark" ></span>
                                        </label>
                                    </td>
                                    <td>{{item.title}}</td>
                                    <td>{{item.description}}</td>
                                    <td>
                                        <img class="table-image"
                                            src="{{'./assets/images/c-icons/'+item.icon+'.png'}}"
                                            alt="table-img">
                                    </td>
                                    <td>

                                        <span class="badge badge-success" [style.background-color]="item.isEnabled ? '#28a745' : '#f0ad4e'">{{item.isEnabled ? 'Active':'Inactive'}}</span>
                                    </td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div *ngIf="selectedRecord"> <button class="btn btn-add ml-2" (click)="editRecord()">Edit</button></div>
    </div>

    <div class="card-footer">
        <button class="btn btn-red-back">Clear</button>
        <button class="btn btn-add ml-2" (click)="updatePointSettings()">Update</button>
    </div>
</div>
