import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, Subscription, of } from 'rxjs';
import {
  map,
  debounceTime,
  distinctUntilChanged,
  mergeMap,
  delay,
  finalize,
} from 'rxjs/operators';
import {
  SortableHeader,
  SortEvent,
} from 'src/app/shared/directive/sortable.directive';
import { PaginatedTable } from 'src/app/core/model/pagination.model';
import { RoleTable } from 'src/app/core/model/users.model';
import { StorageService } from 'src/app/core/service/storage.service';

import { RoleService } from '../../../service/user-roles/role.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.scss'],
})
export class UserRolesComponent implements OnInit {
  privilegeFlags: any;
  Status = ['active', 'inactive'];
  userRolesFormGroup: FormGroup;
  submitted: boolean = false;
  isEditing: boolean = false;
  isLoading: boolean = false;
  activeId: number = 1;
  isTableLoading: boolean = true;
  editRoleId: string;
  public searchInput = new Subject<KeyboardEvent>();
  public role: RoleTable;
  private searchSubscription: Subscription;
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  paginateData: any = [];
  public privilegeData = [];
  pTable: {
    sortBy: string;
    sortWith: string;
    total: number;
    data: any[];
    page: number;
    limit: number;
    search: string;
  };
  privilegeMix: any[];
  categaoryDetails: any[];
  userRolesIdsUpdate: any = [];
  constructor(
    private router: Router,
    private roleService: RoleService,
    public toastService: ToastrService,
    private storageService: StorageService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.initForm();
    // this.config.notFoundText = 'Custom not found';
    this.pTable = {
      sortBy: 'desc',
      sortWith: 'createdBy',
      total: 0,
      data: [],
      page: 1,
      limit: 10,
      search: '',
    };

    this.clearFields();

    this.searchSubscription = this.searchInput
      .pipe(
        map((event) => {
          const target = event.target as HTMLTextAreaElement;
          return target.value;
        }),
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap((search) => of(search).pipe(delay(500)))
      )
      .subscribe((input) => {
        this.pTable.page = 1;
        this.loadRoles();
      });
  }
  clearFields() { }
  onLimitChange() {
    this.loadRoles();
  }
  ngOnDestroy(): void {
    this.searchSubscription && this.searchSubscription.unsubscribe();
  }

  searchInputValue(event) {
    this.paginateData = [];
    if (event.target.value !== '') {
      this.paginateData = this.pTable.data.filter((element: any) => {
        return Object.keys(element).some((key) => {
          if (element[key] !== null)
            return element[key].toString().toLowerCase().includes(event.target.value.toLowerCase());
        });
      });
      this.pTable.total = this.paginateData.length
    } else {
      this.getPaginateData()
    }
  }

  loadUserPrivilege() {
    const subscribe = this.roleService
      .getUserPrivilege()
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          this.privilegeFlags = response.data;
          },
        (error) => {
          console.error("error",error);

          // this.pTable.data = [];
          // this.pTable.total = 0;
          // this.toastService.error('No list found');
        }
      );
  }

  loadRoles() {
    const { data, total, ...rest } = this.pTable;
    const subscribe = this.roleService
      .getUserRoles()
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          // const { count, data } = response.data;
          this.isTableLoading = false;
          this.pTable.data = response?.data?.roles || [];
          this.pTable.total = response?.data?.roles?.length || 0;
          this.getPaginateData();
        },
        (error) => {
          this.pTable.data = [];
          this.pTable.total = 0;
          this.toastService.error('No list found');
        }
      );
  }

  getPaginateData() {

    this.paginateData = this.pTable.data
      .slice((this.pTable.page - 1) * this.pTable.limit, (this.pTable.page - 1) * this.pTable.limit + this.pTable.limit);
  }
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.pTable.sortBy = direction;
    this.pTable.sortWith = column;
    direction && this.loadRoles();
  }

  onPageChange(page: number) {
    this.pTable.page = page;
    this.loadRoles();
  }

  ngOnInit(): void {
    this.getPrivilege();
    this.loadRoles();
    this.loadUserPrivilege();
  }

  get PrivilegeControl(): FormArray {
    return this.userRolesFormGroup.get('privileges') as FormArray;
  }

  getControls() {
    return (<FormArray>this.userRolesFormGroup.get('privileges')).controls;
  }

  getEditRoleData(data: any) {
    this.editRoleId = data._id;
    // const privilegeControl = this.PrivilegeControl;

    // this.roleService.getOneRole(id).subscribe((res: any) => {
    this.activeId = 2;

    this.isEditing = true;
    const newArray = [];

    // res.data.privileges.forEach((val) => {
    //   let previlege = this.formBuilder.group({
    //     privilegeId: val ? val.privilegeId : '',
    //     flag: val.flag,
    //   });
    //   privilegeControl.push(previlege);
    // });

    this.userRolesFormGroup.patchValue({
      name: data.name,
      description: data.description,
      isFlexiStaff: data.isFlexiStaff == 1 ? true : false,
      status: data.status == 1 ? 'active' : 'inactive',
    });

    // this.privilegeData.forEach((eachItm) => {
    //   eachItm.privileges.map((val) => {
    //     res.data.privileges.forEach((res) => {
    //       if (val._id === res.privilegeId) {
    //         val.flag = res.flag;
    //       }
    //     });
    //   });
    // });

    // this.privilegeMix.map((val) => {
    //   res.data.privileges.forEach((val1) => {
    //     if (val1.privilegeId == val._id) {
    //       val['flag'] = val1.flag;
    //     }
    //   });
    // });

    //add flag false for missing privileges
    // const tempprivilegeMix = [...this.privilegeMix];
    // tempprivilegeMix.forEach((val, index) => {
    //   if (!this.privilegeMix[index].hasOwnProperty('flag')) {
    //     this.privilegeMix[index]['flag'] = false;
    //   }
    // });

    // this.privilegeMix.sort((a, b) =>
    //   a.CategoryName.toLowerCase() > b.CategoryName.toLowerCase() ? -1 : 1
    // );

    // this.userRolesIdsUpdate = data.privileges ? data.privileges : [];


    this.categaoryDetails.map(item => {
      item?.privileges?.map((preData: any) => {

        if (data.privileges?.indexOf(preData._id) < 0) {
          preData.isSelected = false;
        } else {
          this.userRolesIdsUpdate.push(preData._id);
          preData.isSelected = true;
        }
      })
    })

    // });
  }

  getPrivilege() {
    this.roleService.getAllPrevileges().subscribe((res) => {
      let categoryData = res.data?.privilegeCategory || [];
      // categoryData?.map(item => {
      //   if (item.privileges && item.privileges.length > 0) {
      //     item.privileges.map(pre => {
      //       pre.isSelected = false;
      //     });
      //   }
      // })
      this.categaoryDetails = categoryData;

      // const modifiedData = res.data.privilegeCategory.map((rawProduct) => {
      //   return { ...rawProduct, privileges: [] };
      // });
      // //

      // const privilages = res.data.privileges.map((rawProduct) => {
      //   return { ...rawProduct, flag: false };
      // });
      // //
      // modifiedData.map((catagory) => {
      //   privilages.forEach((element) => {
      //     if (element.privilegeCategoryId == catagory._id) {
      //       return catagory.privileges.push(element);
      //     }
      //   });
      // });

      // this.privilegeData = modifiedData;
      // const privilegesAll = res.data.privileges;

      // privilegesAll.map((val) => {
      //   res.data.privilegeCategories.forEach((val1) => {
      //     if (val.privilegeCategoryId == val1._id) {
      //       val['CategoryName'] = val1.name ?? '';
      //     }
      //   });
      // });

      // this.privilegeMix = [...privilegesAll];

    });
  }

  initForm() {
    this.userRolesFormGroup = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required]],
      isFlexiStaff: [false, [Validators.required]],
      privileges: this.formBuilder.control([]),
      status: ['active', [Validators.required]],
    });
  }

  addPrivileges(id: any, isSelected: any) {

    if(isSelected) {
      this.userRolesIdsUpdate.push(id)
    } else {
      var index = this.userRolesIdsUpdate.indexOf(id)
      this.userRolesIdsUpdate.splice(index, 1);
    }

    /*const control = this.getControls();
     const privilegeControl = this.PrivilegeControl;

     const ind =  privilegeControl.value.findIndex(
      (x) => {
        x === id}
     );*/
   /* if (ind > -1) {
       privilegeControl.removeAt(index);
     } else {
      let previlege = this.formBuilder.group(
         [id],
       );
       privilegeControl.push(previlege);
     }*/
  }

  get f() {
    return this.userRolesFormGroup.controls;
  }
  onChangeStatus(value) {
    if (value === 'active') {
      this.userRolesFormGroup.patchValue({
        status: 'active',
      });
    } else {
      this.userRolesFormGroup.patchValue({
        status: 'inactive',
      });
    }
  }
  onCancelEdit() {
    this.activeId = 1;
    this.clearForm();
    this.userRolesIdsUpdate=[];
    this.loadRoles();
  }

  clearForm() {
    this.userRolesFormGroup.reset();
    this.clearFormArray(this.PrivilegeControl);
    this.isEditing = false;
    this.getPrivilege();
    this.userRolesIdsUpdate = [];
    this.editRoleId = null;
  }
  clearFormArray = (formArray: FormArray) => {
    if(formArray.length > 0)
    {
      while (formArray.length !== 0) {
        formArray.removeAt(0);
      }
    }
   

    //create company form

    // onSubmit(company: Company) {
    //   const sub = this.comapnyService.createCompany(company).pipe(finalize(() => {
    //     sub.unsubscribe();
    //   })).subscribe(data => {
    //     this.toastService.success('Successfully created');
    //     this.loadCompanies();
    //     this.clearFields();
    //   }, error => {
    //     this.toastService.error('Somthing went wrong');
    //   });
    // }
  };

  onUserRoleFormSubmit() {
    this.isLoading = true;
    if (this.isEditing) {

      let roleCreateData = {
        "roleId": this.editRoleId,
        "name": this.f.name.value,
        "description": this.f.description.value,
        "isFlexiStaff": this.f.isFlexiStaff.value,
        "privileges": this.userRolesIdsUpdate,
        "status": this.f.status.value == 'active' ? 1:0
    }

      const subscribe = this.roleService
        .updateUserRole(roleCreateData)
        .pipe(
          finalize(() => {
            this.isLoading = false;
            subscribe.unsubscribe();
          })
        )
        .subscribe((res) => {
          this.activeId = 1;
          this.onCancelEdit();
          this.loadRoles();
            this.toastService.success(
              'Privileges Successfully updated',
              'Updated'
            );

        });
    } else {
      let roleCreateData = {
        "name": this.userRolesFormGroup.value.name,
        "description": this.userRolesFormGroup.value.description,
        "isFlexiStaff": this.userRolesFormGroup.value.isFlexiStaff,
        "privileges": this.userRolesIdsUpdate,
        "status": this.userRolesFormGroup.value.status == 'active' ? 1:0
    }
      const subscribe = this.roleService
        .createUserRole(roleCreateData)
        .pipe(
          finalize(() => {
            this.isLoading = false;
            subscribe.unsubscribe();
          })
        )
        .subscribe(
          (res) => {
            this.activeId = 1;
            this.onCancelEdit();
            this.loadRoles();
            this.toastService.success(
              'Role Privileges Successfully created',
              'Success'
            );
          },
          (error) => {
            this.toastService.error('Something went wrong', 'Failed');
          }
        );
    }
  }
}
