import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { OpsGroupService } from 'src/app/users/service/ops-group/ops-group.service';
import { GamificationService } from 'src/app/users/service/gamification/gamification.service';
import { finalize } from 'rxjs/operators';
import { COMMON_MESSAGES } from 'src/app/core/utils/helper';

@Component({
  selector: 'app-ops-group',
  templateUrl: './ops-group.component.html',
  styleUrls: ['./ops-group.component.scss'],
})
export class OpsGroupComponent implements OnInit {
  @Output() changeActiveTab = new EventEmitter<any>();
  active = 1;
  tabId: number;
  OpsList: {};
  heading: string;
  importStaffForm: FormGroup;
  importQuotaForm: FormGroup;
  edit: any;
  view: boolean;
  closeResult = '';
  fileToUpload: File | null = null;
  constructor(
    private opsGroupService: OpsGroupService,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private fb: FormBuilder,
    private gamificationService: GamificationService

  ) {}

  ngOnInit(): void {
    this.heading = 'CREATE';
    this.importStaffForm = this.fb.group({
      csv: [''],
    });

    this.importQuotaForm = this.fb.group({
      csv: [''],
    });
  }

  changeTab($event) {
    this.active = $event;
    if ($event === 1) {
      this.heading = 'CREATE';
      this.view = false;
      this.edit = false;
    }
  }

  onActionbtn(event) {
    this.heading = event;
    this.active = 2;
    if (event == 'EDIT') {
      this.edit = true;
    } else if (event == 'VIEW') {
      this.view = true;
    }
  }

  navChanged1(event) {
    if (event.nextId === 1) {
      this.view = false;
      this.edit = false;
      this.heading = 'CREATE';
    }
  }

  importStaff(event) {
    let file = event.target.files[0];
    if (file && file.type === 'text/csv') {
        let fileUpload = this.gamificationService.uploadFile(file)
        .pipe(
          finalize(() => {
            fileUpload.unsubscribe();
          })
        )
        .subscribe(
          (data: any) => {
            this.importStaffForm.get('csv').setValue(file);
          },
          (error: HttpErrorResponse) => {
            this.toastrService.error(error?.error?.message, error?.statusText,{
              timeOut: 3000,
            });
            event.target.value = '';
          });
    }
    else {
      this.toastrService.error(COMMON_MESSAGES.UPLOAD_ONLY_CSV);
      event.target.value = '';
      file = null;
      event.target.value = '';
    }
  }

  uploadImportStaff() {
    this.opsGroupService
      .getImportStaff(this.importStaffForm.get('csv').value)
      .subscribe((res) => {
        console.log('import', res);
      });
  }

  importQuotafile(event) {
    let file = event.target.files[0]
    if(file && file.type === 'text/csv'){
      let fileUpload = this.gamificationService.uploadFile(file)
      .pipe(
        finalize(() => {
          fileUpload.unsubscribe();
        })
      )
      .subscribe(
        (data: any) => {
          this.fileToUpload = file;
        },
        (error: HttpErrorResponse) => {
          this.toastrService.error(error?.error?.message, error?.statusText,{
            timeOut: 3000,
          });
          event.target.value = '';
          this.fileToUpload = null;
        });
    }
    else {
      this.toastrService.error(COMMON_MESSAGES.UPLOAD_ONLY_CSV);
      event.target.value = '';
      file = null;
      event.target.value = '';
    }
  }

  uploadImportQuota() {
    this.opsGroupService
      .getImportQuota(this.fileToUpload)
      .subscribe((res: { message }) => {
        this.toastrService.info(res.message);
        this.modalService.dismissAll();
      });
  }

  open(importQuota) {
    this.modalService
      .open(importQuota, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
