<div class="card my-3 w-100">
  <div class="card-header flexi-header p-4">
    <h3>SOCIAL WALL GROUP DETAIL</h3>
    <button
      *ngIf="isBoardEdited"
      class="btn pull-right btn-outline-light"
      (click)="onCancelEdit()"
      type="button"
    >
      Back
    </button>
  </div>

  <div class="card-body">
    <form [formGroup]="wallCreateForm">
      <div class="row">
        <div class="col-md-6 mt-1">
          <div class="form-group">
            <label class="pb-2 red-label" for="exampleInputEmail1"
              >Social Wall Group Name (Limit to 100 characters)</label
            >

            <input
              type="text"
              class="form-control"
              placeholder=""
              formControlName="wallName"
              maxlength="100"
            />
          </div>
        </div>
      </div>
      <br/>
      <div class="row">
        <div class="col-md-6 mt-1">
          <div class="form-group pr-0">
            <input type="checkbox" formControlName="quickNavEnabled" />
            <span class="ml-2"
              >Allow this board to be placed in quick navigation</span
            >
          </div>
        </div>
      </div>
      <br/>
      <div class="row">
        <div class="col-md-6 mt-1">
          <div class="form-group">
            <label class="pb-2 red-label" for="exampleInputEmail1"
              >Social Icon</label
            >
            <div *ngIf="!wallCreateForm.get('bannerImage').value">
              <div class="custom-file mt-0 w-50 pt-4 pb-4" style="width: 67px">
                <input
                  type="file"
                  style="
                    position: absolute;
                    z-index: 9;
                    visibility: visible;
                    left: 0;
                    top: 0;
                    width: 100%;
                    opacity: 0;
                  "
                  (change)="iconUpload($event)"
                  required
                  id="b"
                />
                <label
                  class="font-weight-bold"
                  class="custom-file-label"
                  for="b"
                >
                  Upload Form Logo
                </label>
              </div>
            </div>
            <div *ngIf="wallCreateForm.get('bannerImage').value">
              <div class="image-with-delete-icon" style="width: 300px">
                <i
                  class="fa fa-close"
                  title="Delete"
                  for="4"
                  (click)="ondeleteImage()"
                ></i>
                <img
                  [src]="validateURLImage(wallCreateForm.get('bannerImage').value)"
                  width="300px"
                  height="300px"
                  id="4"
                  alt="Image Preview"
                  crossorigin="anonymous"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-3 mt-1">
          <div class="form-group">
            <label for="" class="red-label">Listing Display Setup</label>
            <div class="d-flex justify-content-between p-3" style="width: 22vw">
              <div class="radio radio-danger radio-inline p-1">
                <input
                  type="radio"
                  formControlName="displayType"
                  id="postSubReqTru"
                  [value]="0"
                />
                <label for="postSubReqTru"> Consolidated</label>
              </div>
              <div class="radio radio-danger radio-inline p-1">
                <input
                  type="radio"
                  id="postSubReqFals"
                  formControlName="displayType"
                  [value]="1"
                />
                <label for="postSubReqFals">Separate track</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-3 mt-1">
          <div class="form-group">
            <label for="" class="red-label">Post Type</label>
            <div
            class="d-flex justify-content-between p-3"
            style="width: 21.5vw"
          >
            <div class="radio radio-danger radio-inline p-1">
              <input
                type="radio"
                formControlName="postType"
                id="postSubReqTr"
                [value]="0"
              />
              <label for="postSubReqTr"> Long Wall Post</label>
            </div>
            <div class="radio radio-danger radio-inline p-1">
              <input
                type="radio"
                id="postSubReqFal"
                formControlName="postType"
                [value]="1"
              />
              <label for="postSubReqFal"> Tall Wall Post</label>
            </div>
          </div>
          </div>
        </div>
        </div>
      <div class="row">
        <div class="col-3 mt-1">
          <div class="form-group">
            <label for="" class="red-label">Tasking</label>
            <div class="d-flex justify-content-between p-3">
              <div class="radio radio-danger radio-inline p-1">
                <input
                  type="radio"
                  formControlName="isTaskActive"
                  id="postSubReqT"
                  [value]="true"
                />
                <label for="postSubReqT">Yes</label>
              </div>
              <div class="radio radio-danger radio-inline p-1">
                <input
                  type="radio"
                  id="postSubReqF"
                  formControlName="isTaskActive"
                  [value]="false"
                />
                <label for="postSubReqF"> No</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-3 mt-1">
          <div class="form-group">
            <label for="" class="red-label">Nomination</label>
            <div class="d-flex justify-content-between p-3">
              <div class="radio radio-danger radio-inline p-1">
                <input
                  type="radio"
                  formControlName="isNomineeActive"
                  id="postSubReq"
                  [value]="true"
                  (click)="nominationMenuTrue()"
                />
                <label for="postSubReq"> Yes</label>
              </div>
              <div class="radio radio-danger radio-inline p-1">
                <input
                  type="radio"
                  id="postSubRe"
                  formControlName="isNomineeActive"
                  [value]="false"
                  (click)="nominationMenuFalse()"
                />
                <label for="postSubRe">No</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="wallCreateForm.value.isNomineeActive">
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            formControlName="nominationOnlyByAdmin"
          />
          <label class="form-check-label mt-1 ml-2" for="exampleCheck1"
            >Allow nomination only by Administrator</label
          >
        </div>
        <div formGroupName="nominationPerUser">
          <label class="container mt-4 ml-0 red-label"
            >Limit max number of nomination that can be submitted per user
            <input
              #el
              (change)="toggleDisableNumberofNomination()"
              type="checkbox"
              formControlName="enabled"
            />
            <span class="checkmark"></span>
          </label>

          <div class="justify-content-SB col-8 ml-4">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                aria-describedby="emailHelp"
                type="number"
                pattern="/^-?\d+\.?\d*$/"
                onKeyPress="if(this.value.length == 4) return false;"
                placeholder="Enter Max Submissions"
                [attr.disabled]="wallCreateForm.value.nominationPerUser.enabled ? null : ''"
                formControlName="submissionLimit"
              />
            </div>

            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                [value]="1"
                formControlName="submissionPeriod"
              />
              <label class="form-check-label ml-2 mt-1"> Monthly </label>
            </div>

            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                [value]="2"
                formControlName="submissionPeriod"
              />
              <label class="form-check-label ml-2 mt-1"> Quarterly </label>
            </div>

            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                [value]="3"
                formControlName="submissionPeriod"
              />
              <label class="form-check-label ml-2 mt-1"> Bi-annually </label>
            </div>

            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                [value]="4"
                formControlName="submissionPeriod"
              />
              <label class="form-check-label ml-2 mt-1"> Yearly </label>
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="wallCreateForm.value.isNomineeActive"
        formGroupName="maxNomination"
      >
        <label class="container mt-4 ml-0 red-label"
          >Limit how many times I can nominate each user
          <input
            #el
            (change)="toggleDisableNominateEachUser()"
            type="checkbox"
            formControlName="enabled"
          />
          <span class="checkmark"></span>
        </label>

        <div class="justify-content-SB col-8 ml-4">
          <div class="form-group">
            <input
              type="number"
              pattern="/^-?\d+\.?\d*$/"
              onKeyPress="if(this.value.length == 4) return false;"
              class="form-control"
              aria-describedby="emailHelp"
              placeholder="Enter Max Submissions"
              [attr.disabled]="wallCreateForm.value.maxNomination.enabled ? null : ''"
              formControlName="submissionLimit"
            />
          </div>

          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              [value]="1"
              formControlName="submissionPeriod"
            />
            <label class="form-check-label ml-2 mt-1"> Monthly </label>
          </div>

          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              [value]="2"
              formControlName="submissionPeriod"
            />
            <label class="form-check-label ml-2 mt-1"> Quarterly </label>
          </div>

          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              [value]="3"
              formControlName="submissionPeriod"
            />
            <label class="form-check-label ml-2 mt-1"> Bi-annually </label>
          </div>

          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              [value]="4"
              formControlName="submissionPeriod"
            />
            <label class="form-check-label ml-2 mt-1"> Yearly </label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-3 mt-1">
          <div class="form-group">
            <label for="" class="red-label">Admin Response</label>
            <div class="d-flex justify-content-between p-3">
              <div class="radio radio-danger radio-inline p-1">
                <input
                  type="radio"
                  formControlName="adminResponse"
                  id="postadminresponsetrue"
                  [value]="true"
                />
                <label for="postadminresponsetrue"> Yes</label>
              </div>
              <div class="radio radio-danger radio-inline p-1">
                <input
                  type="radio"
                  formControlName="adminResponse"
                  id="postadminresponsefalse"
                  [value]="false"
                />
                <label for="postadminresponsefalse">No</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-3 mt-1">
          <div class="form-group">
            <label for="" class="red-label"
              >Allow posts to be created anonymously?</label
            >
            <div class="d-flex justify-content-between p-3">
              <div class="radio radio-danger radio-inline p-1">
                <input
                  type="radio"
                  formControlName="postAnonymously"
                  id="isAnonymouslyActivetrue"
                  [value]="true"
                />
                <label for="isAnonymouslyActivetrue"> Yes</label>
              </div>
              <div class="radio radio-danger radio-inline p-1">
                <input
                  type="radio"
                  formControlName="postAnonymously"
                  id="isAnonymouslyActivefalse"
                  [value]="false"
                />
                <label for="isAnonymouslyActivefalse">No</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="card my-3 w-100">
  <div class="card-header flexi-header">
    <h3>CATEGORY</h3>
  </div>
  <div class="card-body">
    <form [formGroup]="wallCreateForm">
      <div class="row">
        <div class="col-md-4">
          <label class="pb-2 red-label">
            Category
            <button
              class="btn p-1 ml-1"
              style="color: #a51f38"
              (click)="addCategory()"
            >
              <i class="fa fa-plus-circle fa-lg" aria-hidden="true"></i>
            </button>
          </label>
          <ng-select
            (change)="addCategories($event)"
            class="custom"
            bindLabel="name"
            placeholder="Select Category"
            appendTo="body"
            [multiple]="false"
            [searchable]="true"
            [clearable]="true"
            [(ngModel)]="categories"
            [ngModelOptions]="{ standalone: true }"
            [loading]="categoryDropDownLoading"
            (search)="onSeachCategory($event)"
            (scrollToEnd)="loadMoreCategoryItems($event)"
            (clear)="onClearCategoryDropdown($event)"
          >
            <ng-option [value]="item" *ngFor="let item of categoryDropDown">
              {{ item.name }}
            </ng-option>
          </ng-select>
        </div>
        <div class="col-md-6">
          <div
            style="
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              margin-top: 33px;
            "
          >
            <div
              formArrayName="category"
              *ngFor="let field of CategorytControl.controls; let index = index"
            >
              <span class="text-items list-ui" [formGroupName]="index"
                >{{ field.get("categoryName").value }}
                <img
                  class="ml-3"
                  (click)="deleteCategories(index)"
                  src="./assets/images/cancel.png"
                  width="25 px"
                  height="25px"
              /></span>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<assign-users
  [userDetails]="userDetails"
  (showCreateButton)="showCreateButton()"
  (hideCreateButton)="hideCreateButton()"
  (onSubmitAssignUsers)="updateUserDetails($event)"
  toastMessage="Board Updated"
>
</assign-users>

<div class="row">
  <div class="col-md-4">
    <button
      *ngIf="isAddButtonActive"
      class="btn btn-submit update-button"
      [disabled]="isLoading"
      (click)="createBoard()"
    >
      <i
        class="fa"
        [ngClass]="{ 'fa-spin fa-spinner': isLoading, '': !isLoading }"
      ></i
      >{{ boardId ? "Update" : "Create" }}
    </button>
  </div>
</div>

<ng-template #AddSubSection let-c="close" let-d="dismiss">
  <div>
    <div class="modal-content">
      <div class="modal-body" style="padding: 10px; min-height: 100vh">
        <div>
          <div class="card my-3 w-100">
            <div class="card-header flexi-header">
              <h3>ADD CATEGORY</h3>
              <button
                type="button"
                class="btn close-button"
                aria-label="Close"
                (click)="c('Cross click')"
              >
                <i class="fa fa-times-circle fa-2x" aria-hidden="true"></i>
              </button>
            </div>
            <div class="card-body">
              <form [formGroup]="createCategoryForm">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="exampleInputEmail1">CATEGORY</label>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="name"
                        placeholder="Category"
                      />
                    </div>
                  </div>
                </div>
                <button
                  class="btn btn-add pull-right"
                  *ngIf="!isCategoryEdited"
                  [disabled]="!createCategoryForm.valid"
                  (click)="createCategory()"
                >
                  Add
                </button>
                <button
                  class="btn btn-add pull-right"
                  *ngIf="isCategoryEdited"
                  [disabled]="!createCategoryForm.valid"
                  (click)="UpdateCatagory()"
                >
                  UPDATE
                </button>
              </form>
            </div>
          </div>
          <div class="card my-3 w-100">
            <div class="card-header flexi-header">
              <h3>CATEGORY LIST</h3>
            </div>
            <div class="card-body">
              <div class="card table-card">
                <div class="card-body flexi-body">
                  <div class="flex-column">
                    <div class="d-flex justify-content-between mt-2 mb-3">
                      <select
                        class="custom-select"
                        style="width: auto"
                        name="pageSize"
                        (change)="onLimitChangeCategory()"
                        [(ngModel)]="categoryPTable.limit"
                      >
                        <option [ngValue]="10">10</option>
                        <option [ngValue]="20">20</option>
                        <option [ngValue]="30">30</option>
                        <option [ngValue]="40">40</option>
                        <option [ngValue]="50">50</option>
                      </select>
                      <div class="form-group form-inline mb-0">
                        <span *ngIf="categoryPTable.isLoading"
                          >Please wait...</span
                        >
                        <input
                          class="form-control ml-2"
                          type="search"
                          name="searchTerm"
                          (keyup)="categorySearchInput.next($event)"
                          placeholder="Search here"
                          [(ngModel)]="categoryPTable.search"
                        />
                      </div>
                    </div>
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              sortable="Tier 1"
                              (sort)="onSortCategory($event)"
                            >
                              S.NO
                            </th>
                            <th
                              scope="col"
                              sortable="Tier 2"
                              (sort)="onSortCategory($event)"
                            >
                              CATEGORY
                            </th>

                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let item of categoryDropDown;
                              let index = index
                            "
                          >
                            <td>{{ index + 1 }}</td>

                            <td>{{ item.name }}</td>

                            <td>
                              <button
                                class="btn btn-add p-2 m-1"
                                (click)="oneditCategory(item)"
                              >
                                Edit
                              </button>
                              <button
                                class="btn btn-clear p -2 m-1"
                                (click)="onDeleteCategory(item._id)"
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="d-flex justify-content-between">
                    <p>Total Records {{ categoryPTable.total }}</p>
                    <ngb-pagination
                      [collectionSize]="categoryPTable.total"
                      [(page)]="categoryPTable.page"
                      [maxSize]="5"
                      (pageChange)="onPageChangeCtegory($event)"
                      [pageSize]="categoryPTable.limit"
                    >
                    </ngb-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
