<!--<div
  #sidemenu
  id="sidemenu"
  class="p-3 d-none d-sm-block pt-5 sidemenu"
  *ngIf="!isView"
>
  <aside>
    <div class="form-group">
      <label class="d-block text-center">BUSINESS UNIT</label>
      <ng-select
        class="custom"
        [virtualScroll]="false"
        [loading]="buLoader"
        bindLabel="businessUnit"
        placeholder="Select/Search Business unit"
        appendTo="body"
        [searchable]="true"
        [clearable]="true"
        [closeOnSelect]="true"
        (scrollToEnd)="loadMoreItem($event)"
        [(ngModel)]="schemeBuView"
      >
        >
        <ng-option [value]="item" *ngFor="let item of buParameters.data">
          <span title="{{ item.businessUnit }}">{{ item.businessUnit }}</span>
        </ng-option>
      </ng-select>
    </div>

    <div class="row">
      <div class="col pr-md-3">
        <button class="btn btn-danger btn-block">Cancel</button>
      </div>
      <div class="col pl-md-0">
        <button
          class="btn btn-info btn-block"
          (click)="viewClicked(schemeBuView._id)"
        >
          View
        </button>
      </div>
    </div>
  </aside>
</div>-->

<div class="d-flex flex-column" *ngIf="isView">
  <ul
    ngbNav
    #companyNav="ngbNav"
    (navChange)="onNavChange($event)"
    [(activeId)]="activeId"
    class="nav-tabs rounded mb-3"
  >
    <li [ngbNavItem]="1">
      <a ngbNavLink>OVERVIEW</a>
      <ng-template ngbNavContent>
        <app-shift-scheme-overview
          [buShiftID]="buShiftSelectedID"
          [schemeShiftID]="schemeSingleID"
          (changeActiveScheme)="activeViewTab($event)"
          (changeActiveViewScheme)="viewOrEdit($event)"
        ></app-shift-scheme-overview>
      </ng-template>
    </li>
    <li [ngbNavItem]="2" class="not-allowed">
      <a ngbNavLink class="no-click">{{
        schemeView === "view"
          ? "VIEW"
          : schemeView === "edit"
          ? "EDIT"
          : "CREATE"
      }}</a>
      <ng-template ngbNavContent>
        <app-shift-scheme-edit
          (ChangeTabId)="changeActiveTab($event)"
          [buShiftID]="buShiftSelectedID"
          [schemeRecieveData]="schemeRecieveData"
          [isView]="schemeView"
          [resetTab]="changeCreateForm"
        ></app-shift-scheme-edit>
      </ng-template>
    </li>
    <li [ngbNavItem]="3" class="not-allowed">
      <a ngbNavLink class="no-click">LOGS</a>
      <ng-template ngbNavContent>
        <app-shift-scheme-logs
          [BusinessUnit]="buShiftSelectedID"
        ></app-shift-scheme-logs>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="companyNav"></div>
</div>
