import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { BoardSetupService } from 'src/app/users/service/board-setup/board-setup.service';

@Component({
  selector: 'view-board',
  templateUrl: './view-board.component.html',
  styleUrls: ['./view-board.component.scss']
})
export class ViewBoardComponent implements OnInit {
  userDetails: any[] = [];
  wallCreateForm: FormGroup;
  @Input() boardId: any = null
  @Output() overViewEvent = new EventEmitter<any>();
  isNominationOpen: boolean = true;
  baseUrl = environment.imageUrl;
  constructor(private formBuilder: FormBuilder,
    private boardService: BoardSetupService,
    private toastService: ToastrService) { }

  ngOnInit() {
    this.initForm()
    this.boardId && this.getFormForEdit(this.boardId);
  }
  initForm() {
    this.wallCreateForm = this.formBuilder.group({
      wallName: ['', Validators.required],
      displayType: [0, Validators.required],
      postType: [0, Validators.required],
      isNomineeActive: [true, Validators.required],
      adminResponse:[true, Validators.required],
      postAnonymously:[true, Validators.required],
      isTaskActive: [true, Validators.required],
      quickNavEnabled: [false, Validators.required],
      bannerImage: ['', Validators.required],
      category: this.formBuilder.array([]),
      nominationOnlyByAdmin: [true],
      nominationPerUser: this.formBuilder.group({
        enabled: [true],
        submissionLimit:null,
        submissionPeriod: null,
      }),

      maxNomination: this.formBuilder.group({
        enabled: [true],
        submissionLimit: [''],
        submissionPeriod: null,
      }),
    });
  }

  get CategorytControl(): FormArray {
    return this.wallCreateForm.get('category') as FormArray;
  }
  onCancelEdit() {
    this.overViewEvent.emit(null);
  }
  getFormForEdit(data: any) {
    this.userDetails = data.assignUsers
    const control = this.CategorytControl;
    data.category.forEach((element) => {
      let field = this.formBuilder.group({
        categoryName: [element.categoryName, Validators.compose([Validators.required])],
        _id: [element._id, Validators.compose([Validators.required])],
      });
      control.push(field);
    });
    this.wallCreateForm.patchValue({
      wallName: data.wallName,
      displayType: data.displayType,
      postType: data.postType,
      isNomineeActive: data.isNomineeActive,
      nominationOnlyByAdmin: data.nominationOnlyByAdmin,
      nominationPerUser: {
        ...data.nominationPerUser
      },
      maxNomination: {
        ...data.maxNomination
      },
      adminResponse: data.adminResponse,
      postAnonymously: data.postAnonymously,
      isTaskActive: data.isTaskActive,
      quickNavEnabled: data.quickNavEnabled,
      bannerImage: data.bannerImage,
    });

  }

  nominationMenuTrue() {
    this.isNominationOpen = true;
  }

  nominationMenuFalse() {
    this.isNominationOpen = false;
  }

  validateURLImage(str) {
    var img_Path = str;
    if (img_Path.indexOf("https://") == 0) {
      var new_url = new URL(str);
     var pathName = (new_url.pathname.startsWith('//')) ? `/${new_url.pathname.split("//")[1]}` : new_url.pathname;
      return `${this.baseUrl}${pathName}`;
    }
    if (str.startsWith('/')) return this.baseUrl + str;
    return `${this.baseUrl}/${str}`;
   }
}
