import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subject, Subscription, of } from 'rxjs';
import { map, debounceTime, distinctUntilChanged, mergeMap, delay, finalize } from 'rxjs/operators';
import { SortableHeader, SortEvent } from 'src/app/shared/directive/sortable.directive';
import { StorageService } from 'src/app/core/service/storage.service';

import { BoardSetupService } from 'src/app/users/service/board-setup/board-setup.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/users/service/notification/notification.service';
import * as moment from 'moment';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent implements OnInit {
  isTableLoading: boolean = true;
  usersPTable
  // isBuUnitEdited:boolean = false
  // sectionId:string
  // subSectionId:string
  // bussinessUnitEditForm:FormGroup
  public userssearchInput = new Subject<KeyboardEvent>();

  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  // companyName: string;
  /// bussiness unit over view table config
  onSortUsers({ column, direction }: SortEvent, field) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.usersPTable.sortWith = field;
    this.usersPTable.sortBy = direction;
    this.loadUsers();
  }

  onPageChangeUsers(page: number) {
    this.usersPTable.page = page;
    this.loadUsers();
  }
  onLimitChangeUsers() {
    this.loadUsers();
  }

  private usersSearchSubscription: Subscription;

  constructor(
    private boardService: BoardSetupService,
    public toastService: ToastrService,
    private storageService: StorageService,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
  ) {
    // this.config.notFoundText = 'Custom not found';
    this.usersPTable = {
      sortBy: 'desc',
      sortWith: 'createdAt',

      data: [],
      page: 1,
      limit: 10,
      search: '',

    };
    // this.companyName = this.storageService.getByKey('companyId');

    this.usersSearchSubscription = this.userssearchInput
      .pipe(
        map((event) => {
          const target = event.target as HTMLTextAreaElement;
          return target.value;
        }),
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap((search) => of(search).pipe(delay(500)))
      )
      .subscribe((input) => {
        this.usersPTable.page = 1;
        this.loadUsers();
      });
  }

  ngOnInit(): void {
    this.loadUsers();
  }

  loadUsers() {
    const {data, loading,total ,...rest} = this.usersPTable
    const subscribe = this.boardService
      .getAllReportedQuestionarie(rest)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const { recordsTotal, data } = response;
          this.usersPTable.data = data;
          this.usersPTable.total = recordsTotal;
          this.isTableLoading = false;
        },
        (error) => {
          this.usersPTable.data = [];
          this.usersPTable.total = 0;
          this.toastService.error('No list found');
        }
      );
  }

  exportData(id){
    const subscribe = this.boardService
      .exportQuestionnire(id)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        ({data}) => {
          this.notificationService.downloadUrl(data.csvLink).subscribe((content) => {
            var a = document.createElement('a');
            a.href = 'data:text/csv,' + content;
            a.target = '_blank';
            a.download = "questionnaire_" + moment(new Date()).format("DD-MM-yyyy") + ".csv";
            document.body.appendChild(a);
            a.click()
          });
          this.toastService.success('Data exported successfully');
        },
        (error) => {
          this.toastService.error('No data found');
        }
      );
  }

  ngOnDestroy(): void {
    this.usersSearchSubscription && this.usersSearchSubscription.unsubscribe();
  }

}
