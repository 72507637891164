import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manage-events',
  templateUrl: './manage-events.component.html',
  styleUrls: ['./manage-events.component.scss']
})
export class ManageEventsComponent implements OnInit {
  isBoardEdited: boolean = false
  isManageEdited: boolean = false

  activeId = 1

  boardId: string
  constructor() { }

  ngOnInit() {
  }
  overViewEvent(value) {
    this.isBoardEdited = false
    this.boardId = null
    this.activeId = 1;
    this.isManageEdited = false;
  }
  
  formEdit(id: string) {
    this.activeId = 2
    this.isBoardEdited = true
    this.boardId = id
  }

  formManage(id: string) {    
    this.activeId = 2
    this.isManageEdited = true
    this.boardId = id
  }
}
