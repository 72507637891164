import {
  COMMON_MESSAGES,
  getAssignedUsers,
} from 'src/app/core/utils/helper';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl, ValidationErrors } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IMonthCalendarConfig } from 'ng2-date-picker';
import { Subscription, Subject, of } from 'rxjs';
import {
  map,
  debounceTime,
  distinctUntilChanged,
  mergeMap,
  delay,
  finalize,
  startWith,
  pairwise,
} from 'rxjs/operators';
import {
  SortableHeader,
  SortEvent,
} from 'src/app/shared/directive/sortable.directive';
import { PaginatedTable } from 'src/app/core/model/pagination.model';

import { BussinessUnitService } from 'src/app/users/service/bussiness-unit/bussiness-unit.service';
import { CentralBuilderService } from 'src/app/users/service/central-builder/central-builder.service';
import { ChannelService } from 'src/app/users/service/channel/channel.service';
import { CompanyService } from 'src/app/users/service/companyservice/company.service';
import { CustomFieldService } from 'src/app/users/service/custom-field/custom-field.service';
import { NotificationService } from 'src/app/users/service/notification/notification.service';
import { UsersService } from 'src/app/users/service/user-service/users.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { editFormatDate } from 'src/app/core/utils/helper';
import * as moment from 'moment';

@Component({
  selector: 'app-create-edit-notification',
  templateUrl: './create-edit-notification.component.html',
  styleUrls: ['./create-edit-notification.component.scss'],
})
export class CreateEditNotificationComponent implements OnInit {
  categoryDropDownLoading: boolean = false;
  subCategoryDropDownLoading: boolean = false;
  moduleDropDownLoading: boolean = false;
  planBuUnitDropDownLoading: boolean = false;
  apppoinmentDropDownLoading: boolean = false;
  CustomFieldDropDownLoading: boolean = false;
  categoryDropDown;
  mainCategory;
  subCategory;
  subCategoryDropDown;
  isAddButtonActive: boolean = true;
  activeId = 1;
  addedCustomfield;
  buDropDownToClear = null;
  apoinemntDropDownToClear = null;
  customFeildDropDownToClear = null;
  adminDropDownToClear = null;
  authorDropDownToClear = null;
  moduleList = null;
  userDetailsEditIndex: number;
  noSpacesRegex: any = /.*\S.*/;
  isUserDetailsEdited: boolean = false;
  isNotificationEdited: boolean = false;
  @Input() notificationEditedId = null;
  notificationData = null;
  timeElapsed = Date.now();
  today = new Date(this.timeElapsed);
  config: IMonthCalendarConfig = {
    format: 'MMM, YYYY',
    returnedValueType: 3,
    max: new Date(Date.now()).toUTCString(),
  };
  userDetails: any[] = [];
  userDropDown: any[] = [];
  moduleDropDown: any[] = [];

  categoryPTable;
  subCategoryPTable;
  modulePTable;
  @Output() overViewEvent = new EventEmitter<string>();
  @Output() editEvent = new EventEmitter<string>();
  @Input() notificatioNid: string = null;
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  private categorySearchSubscription: Subscription;
  private defaultImage = "";
  private subcategorySearchSubscription: Subscription;
  public categorySearchInput = new Subject<KeyboardEvent>();
  public subcategorySearchInput = new Subject<KeyboardEvent>();
  @ViewChild('AddCategory', { static: true })
  AddCategory: TemplateRef<any>;
  @ViewChild('AddSubCategory', { static: true })
  AddSubCategory: TemplateRef<any>;
  notificationCreateForm: FormGroup;
  userDetailsForm: FormGroup;
  createCategoryForm: FormGroup;
  createSubCategoryForm: FormGroup;
  editCategoryId: string;
  isCategoryEdited: boolean = false;
  editSubCategoryId: string;
  isSubCategoryEdited: boolean = false;
  planBuUnitDropDown: any[];
  appoinmentDropDown: any[];
  fieldNeedsToUpdate: any[];
  maxDate: { year: number; month: number; day: number };
  efectiveMinDate: { year: number; month: number; day: number };
  IconUrl: string;
  @ViewChild('myInputVariable')
  myInputVariable: ElementRef<any>;
  // @ViewChild('myInputVariable') el:ElementRef;
  isLoading: boolean;
  file: File;
  categoryId: any;
  userPTable: {
    sortWith: string;
    sortBy: string;
    data: any[];
    page: number;
    limit: number;
    search: string;
  };
  bussinesUnitplanPTable: {
    sortWith: string;
    sortBy: string;
    data: any[];
    page: number;
    limit: number;
    search: string;
    filter: { type: string };
  };
  appoinmentPTable: {
    sortWith: string;
    sortBy: string;
    data: any[];
    page: number;
    total: number;
    limit: number;
    search: string;
  };
  CustomFieldPTable: {
    sortWith: string;
    sortBy: string;
    data: any[];
    page: number;
    total: number;
    limit: number;
    search: string;
  };
  authorLoading: boolean;
  UpdateduserDetails: any[] = [];
  notificationAttachmentFilePath: string;
  attachmentFilePath: string;

  onSortCategory({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.categoryPTable.sort = { key: column, order: direction as any };
    this.loadCategory();
  }

  onPageChangeCtegory(page: number) {
    this.categoryPTable.page = page;
    this.loadCategory();
  }
  onLimitChangeCategory() {
    this.loadCategory();
  }
  onSortSubCategory({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.subCategoryPTable.sort = { key: column, order: direction as any };
    this.loadSubCategory();
  }

  onPageChangeSubCtegory(page: number) {
    this.subCategoryPTable.page = page;
    this.loadSubCategory();
  }
  onLimitChangeSubCategory() {
    this.loadSubCategory();
  }

  getDate(value) {
    this.efectiveMinDate = value;
    //     let date = new Date(+value);
    // let year = date.getFullYear();
    // let month = date.getMonth()+1;
    // let day = date.getDate();
    // return {year,month,day}
  }

 spaceValidator(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
        control.setValue('');
        return { required: true }
    }
    else {
        return null;
    }
    }

  constructor(
    private modalService: NgbModal,
    private toastService: ToastrService,
    private userService: UsersService,
    private formBuilder: FormBuilder,
    private buUnit: BussinessUnitService,
    private customFieldService: CustomFieldService,
    private centralBuilderService: CentralBuilderService,
    private notificationService: NotificationService,
    private companyService: CompanyService
  ) {
    this.categoryPTable = {
      sortWith: 'createdAt',
      sortBy: 'desc',
      data: [],
      page: 1,
      limit: 10,

      search: '',
    };
    this.modulePTable = {
      sortWith: 'createdAt',
      sortBy: 'desc',
      data: [],
      page: 1,
      limit: 10,

      search: '',
    };
    this.subCategoryPTable = {
      sortWith: 'createdAt',
      sortBy: 'desc',
      data: [],
      page: 1,
      limit: 8,

      search: '',
    };
    this.userPTable = {
      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,

      limit: 10,

      search: '',
    };
    this.bussinesUnitplanPTable = {
      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,

      limit: 10,

      search: '',
      filter: {
        type: 'plan',
      },
    };
    this.appoinmentPTable = {
      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,
      total: 0,
      limit: 10,

      search: '',
    };
    this.CustomFieldPTable = {
      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,
      total: 0,
      limit: 10,

      search: '',
    };

    this.categorySearchSubscription = this.categorySearchInput
      .pipe(
        map((event) => {
          const target = event.target as HTMLTextAreaElement;
          return target.value;
        }),
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap((search) => of(search).pipe(delay(500)))
      )
      .subscribe((input) => {
        this.categoryPTable.page = 1;
        this.loadCategory();
      });
    this.subcategorySearchSubscription = this.subcategorySearchInput
      .pipe(
        map((event) => {
          const target = event.target as HTMLTextAreaElement;
          return target.value;
        }),
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap((search) => of(search).pipe(delay(500)))
      )
      .subscribe((input) => {
        this.categoryPTable.page = 1;
        this.loadSubCategory();
      });
  }

  initForm() {
    this.notificationCreateForm = this.formBuilder.group({
      title: ['', Validators.required,],
      notificationId: [''],
      subTitle: ['', Validators.required, ],
      description: ['', Validators.required,],
      effectiveFrom: [],
      effectiveTo: [],
      activeFrom: ['', Validators.required],
      activeTo: ['', Validators.required],
      mainCategory: this.formBuilder.group({
        string: [''],
        _id: [''],
      }),
      businessUnitId: [this.notificatioNid, Validators.required],

      CategoryId: this.formBuilder.group({
        string: [''],
        _id: [''],
      }),
      subCategoryId: this.formBuilder.group({
        string: ['', Validators.required],
        _id: ['', Validators.required],
      }),
      notificationAttachment: [null, Validators.required],
      isDynamic: ['0', Validators.required],

      viewOnly: [false],
      moduleIncluded: [false],
      moduleId: this.formBuilder.group({
        string: [''],
        _id: [''],
      }),
      status: 1,
    });
  }

  categoryForm() {
    this.createCategoryForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9_]+.*$')]],
      status: [1, Validators.required],
    });
  }
  SubcategoryForm() {
    this.createSubCategoryForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9_]+.*$')]],
      status: 1,
    });
  }

  onDateSelect(event, value) {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;
    let day = event.day <= 9 ? '0' + event.day : event.day;
    let finalDate = year + '-' + month + '-' + day;

    let date = moment(finalDate).utc().format();
    if (value === 'Effective to') {
      this.notificationCreateForm.patchValue({
        effectiveTo: {
          ...this.notificationCreateForm.get('effectiveTo').value,
          date,
        },
      });
    }
    if (value === 'Effective from') {
      this.notificationCreateForm.patchValue({
        effectiveFrom: {
          ...this.notificationCreateForm.get('effectiveFrom').value,
          date,
        },
      });
    }
  }
  ngOnInit() {
    this.initForm();

    this.getActiveDate();
    this.maxDate = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate(),
    };

    this.categoryForm();
    this.SubcategoryForm();
    this.loadModule();
    this.getSubCategory();

    this.notificationData = this.notificationEditedId;
    this.notificationEditedId = this.notificationData
      ? this.notificationData._id
      : null;


    this.loadCategory();

    this.notificationEditedId && this.getNotificationDetails(this.notificationData);
  }

  getActiveDate() {
    this.notificationCreateForm
      .get('activeFrom')
      .valueChanges.pipe(startWith(null), pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        if (next != null) {
        }
      });
  }
  getNotificationDetails(data) {

      this.isNotificationEdited = true;
      this.userDetails = data?.assignUsers;
      this.UpdateduserDetails =  getAssignedUsers(data?.assignUsers);
      this.notificationCreateForm.patchValue({
        title: data?.title,
        subTitle: data?.subTitle,
        description: data?.description,
        effectiveFrom: editFormatDate(data?.effectiveFrom),
        effectiveTo: editFormatDate(data?.effectiveTo),
        activeFrom: data?.activeFrom,
        activeTo: data?.activeTo,
        isDynamic: data?.isDynamic,
        businessUnitId: data?.businessUnitId,
        CategoryId: {
          string: data?.subCategoryId?.categoryId?.name,
          _id: data?.subCategoryId?.categoryId?._id,
        },
        mainCategory: {
          string: data?.subCategoryId?.categoryId?.name,
          _id: data?.subCategoryId?.categoryId?._id,
        },
        subCategoryId: {
          string: data?.subCategoryId?.name || "",
          _id: data?.subCategoryId?._id || "",
        },
        notificationAttachment: `${environment.imageUrl}/${data?.notificationAttachment}`,

        viewOnly: data.viewOnly,
        moduleIncluded: data?.moduleId ? true : false,
        moduleId: {
          string: data.moduleId ? data?.moduleId.moduleName : '',
          _id: data.moduleId ? data?.moduleId._id : '',
        },
        status: 1,
      });
      this.attachmentFilePath = data?.notificationAttachment;
  }

  onCancelEdit() {
    this.initForm();
    this.isNotificationEdited = false;
    this.notificationEditedId = null;

    this.userDetails.length = 0;
    this.overViewEvent.next(null);
  }

  //add category

  addSeletedSubCategory(value) {
    this.notificationCreateForm.patchValue({
      subCategoryId: {
        string: value.name,
        _id: value._id,
      },
    });
    this.mainCategory = [];
    this.subCategory = [];
  }
  addSeletedModule(value) {
    this.notificationCreateForm.patchValue({
      moduleId: {
        string: value.moduleName,
        _id: value._id,
      },
    });
    this.moduleList = [];
  }

  deleteSeletedCategory() {
    this.notificationCreateForm.patchValue({
      CategoryId: {
        string: null,
        _id: null,
      },
    });
  }

  addMainCategory(value) {
    this.notificationCreateForm.patchValue({
      mainCategory: {
        string: value.name,
        _id: value._id,
      },
    });
    this.mainCategory = [];
  }

  addSeletedCategory(value) {
    this.notificationCreateForm.patchValue({
      CategoryId: {
        string: value.name,
        _id: value._id,
      },
    });
    this.mainCategory = [];
  }

  deleteMainCategory() {
    this.notificationCreateForm.patchValue({
      CategoryId: {
        string: null,
        _id: null,
      },
    });
  }

  deleteSelectedSubCategory() {
    this.notificationCreateForm.patchValue({
      subCategoryId: {
        string: null,
        _id: null,
      },
    });
  }
  deleteSelectedModule() {
    this.notificationCreateForm.patchValue({
      moduleId: {
        string: null,
        _id: null,
      },
    });
  }

  addCategory() {
    this.categoryForm();
    this.loadCategory();
    this.modalService.open(this.AddCategory, {
      windowClass: 'modal-ui-fix right-align',
    });
  }

  addSubCategory() {
    this.categoryForm();
    this.loadCategory();
    this.modalService.open(this.AddSubCategory, {
      windowClass: 'modal-ui-fix right-align',
    });

    // this.loadAppoinment();
  }

  oneditCategory(category) {
    this.editCategoryId = category._id;
    this.isCategoryEdited = true;
    this.createCategoryForm.patchValue({
      name: category.name,
      status: category.status,
    });
  }
  oneditSubCategory(category) {
    this.editSubCategoryId = category._id;
    this.isSubCategoryEdited = true;
    this.createSubCategoryForm.patchValue({
      name: category.name,
      status: category.status,
    });
  }

  // deleteCategories(index) {
  //   const Control = this.CategorytControl;

  //   Control.removeAt(index);
  // }
  UpdateCatagory() {
    const payload = {
      name: this.createCategoryForm.value.name,
      categoryId: this.editCategoryId,
      status: "1",
    }
    const subscribe = this.notificationService
      .updateCategory(payload)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe((response: any) => {
        this.isCategoryEdited = false;
        this.categoryForm();
        this.loadCategory();
        this.toastService.success('Category Updated', '', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
      });
  }
  UpdateSubCatagory() {
    const payload = {
      name: this.createSubCategoryForm.value.name,
      categoryId: this.notificationCreateForm.value.CategoryId._id,
      status: "1",
      subCategoryId: this.editSubCategoryId
    }
    const subscribe = this.notificationService
      .updateSubCategory(payload)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe((response: any) => {
        this.isSubCategoryEdited = false;
        this.editSubCategoryId = null;
        this.categoryForm();
        this.loadSubCategory();
        this.toastService.success('Subcategory Updated', '', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
      });
  }
  createCategory() {
    const subscribe = this.notificationService
      .createCategory(this.createCategoryForm.value)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe((response: any) => {
        this.loadCategory();
        this.categoryForm();
        this.toastService.success('Category Created', '', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
      });
  }
  createSubCategory() {
    const payload = {
      name: this.createSubCategoryForm.value.name,
      categoryId: this.notificationCreateForm.value.CategoryId._id,
      status: "1"
    }
    const subscribe = this.notificationService
      .createSubCategory(payload)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe((response: any) => {
        this.loadSubCategory();
        this.SubcategoryForm();
        this.toastService.success('Subcategory Created', '', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
      });
  }

  onDeleteCategory(id: string) {
    const subscribe = this.notificationService
      .deleteCategory(id)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe((response: any) => {
        this.loadCategory();
        this.toastService.success('Category Deleted', '', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
      });
  }
  onDeleteSubCategory(id: string) {
    const payload = {
      subCategoryId: id,
    }
    const subscribe = this.notificationService
      .deleteSubCategory(payload)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe((response: any) => {
        this.loadCategory();
        this.loadSubCategory();
        this.toastService.success('Subcategory Deleted', '', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
      });
  }
  onSeachCategory(value) {
    this.categoryPTable.limit = 10;
    this.categoryPTable.search = value.term;
    this.loadCategory();
  }

  loadMoreCategoryItems(value) {
    this.categoryPTable.limit = this.categoryPTable.limit + 10;
    this.loadCategory();
  }

  showCreateButton() {
    this.isAddButtonActive = true;
  }

  hideCreateButton() {
    this.isAddButtonActive = false;
  }

  closeModal() {
    this.modalService.dismissAll();
    this.notificationCreateForm.patchValue({
      CategoryId: {
        string: '',
        _id: '',
      },
    });
    this.createSubCategoryForm.patchValue({
      name: '',
    });
  }

  onClearCategoryDropdown(value) {
    if (value === undefined) {
      // this.createUserForm.patchValue({
      //   appointmentId: null,
      // });
      this.categoryPTable.search = '';
      this.loadCategory();

      this.categoryDropDown = [];
      this.categoryPTable.data = [];
    }
  }

  loadCategory() {
    this.categoryDropDownLoading = true;
    const { data, total, ...rest } = this.categoryPTable;
    const subscribe = this.notificationService
      .getAllCategory(rest)
      .pipe(
        finalize(() => {
          this.categoryDropDownLoading = false;
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const { count, data } = response.data;
          this.categoryPTable.data = data;
          this.categoryDropDown = data;
          this.categoryPTable.total = count;
        },
        (error) => {
          this.categoryPTable.data = [];
          this.categoryPTable.total = 0;
        }
      );
  }

  onSeachSubCategory(value) {
    this.subCategoryPTable.limit = 10;
    this.subCategoryPTable.search = value.term;

    this.loadSubCategory();
  }

  loadMoreSubCategoryItems(value) {
    this.subCategoryPTable.limit = this.subCategoryPTable.limit + 10;
    this.loadSubCategory();
  }

  onClearSubCategoryDropdown(value) {
    if (value === undefined) {
      // this.createUserForm.patchValue({
      //   appointmentId: null,
      // });
      this.subCategoryPTable.search = '';
      this.loadSubCategory();

      this.subCategoryDropDown = [];
      this.subCategoryPTable.data = [];
    }
  }

  loadSubCategory() {
    this.subCategoryDropDownLoading = true;
    const { data, total, ...rest } = this.subCategoryPTable;
    const subscribe = this.notificationService
      .getAllSubCategory(
        rest,
        this.notificationCreateForm.get('CategoryId._id').value
      )
      .pipe(
        finalize(() => {
          this.subCategoryDropDownLoading = false;
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const { count, data } = response.data;
          this.subCategoryPTable.data = data;
          this.subCategoryDropDown = data;
          this.subCategoryPTable.total = count;
        },
        (error) => {
          this.subCategoryPTable.data = [];
          this.subCategoryPTable.total = 0;
        }
      );
  }
  onSeachModule(value) {
    this.subCategoryPTable.limit = 10;
    this.subCategoryPTable.search = value.term;

    this.loadSubCategory();
  }

  loadMoreModuleItems(value) {
    this.subCategoryPTable.limit = this.subCategoryPTable.limit + 10;
    this.loadSubCategory();
  }

  onClearModuleDropdown(value) {
    if (value === undefined) {
      // this.createUserForm.patchValue({
      //   appointmentId: null,
      // });
      this.subCategoryPTable.search = '';
      this.loadSubCategory();

      this.subCategoryDropDown = [];
      this.subCategoryPTable.data = [];
    }
  }

  loadModule() {
    this.moduleDropDownLoading = true;
    const { data, total, ...rest } = this.modulePTable;
    const subscribe = this.centralBuilderService
      .getAllNonLinedModules(true)
      .pipe(
        finalize(() => {
          this.moduleDropDownLoading = false;
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          // const { count, data } = response.data;
          this.modulePTable.data = response.data.moduleList;
          this.moduleDropDown = response.data.moduleList;
          // this.modulePTable.total = count;
        },
        (error) => {
          this.modulePTable.data = [];
          this.modulePTable.total = 0;
          this.toastService.error('No list found');
        }
      );
  }

  getSubCategory() {
    this.notificationCreateForm
      .get('CategoryId._id')
      .valueChanges.pipe(startWith(null), pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        if (next != null) {
          this.notificationCreateForm.patchValue({
            subCategoryId: {
              string: null,
              _id: null,
            },
          });
          //
          const { _id } = next;
          this.subCategoryDropDownLoading = true;
          this.subCategoryDropDown = [];
          // this.categoryId = _id;

          this.loadSubCategory();
          //
        }
      });
  }
  showImageTag(fileName){
    const formats = ['jpeg', 'gif', 'png', 'apng', 'svg', 'bmp', 'bmp ico', 'png ico'];
    return formats.includes(fileName.split('.').pop())
  }
  /// file upload
  iconUpload(event) {
    if (event.target.files && event.target.files.length) {
      let file: File = event.target.files[0];
      if (file) {
        const fileUpload = this.companyService.uploadFile(file,true)
        .pipe(
          finalize(() => {
            fileUpload.unsubscribe();
          })
        )
        .subscribe(
          (data: any) => {
            this.notificationAttachmentFilePath = data.filePath;


            this.attachmentFilePath = data?.filePath.split("notificationAttachment")[1] || "";
            this.notificationCreateForm.patchValue({
              notificationAttachment: `${environment.imageUrl}/${data.filePath}`,
            });
          },
          (error: HttpErrorResponse) => {
            this.toastService.error(error.error.message);
          }
        );
      } else {
        this.toastService.error(COMMON_MESSAGES.UPLOAD_ONLY_IMAGE);
        event.target.value = '';
        file = null;
      }

    }
  }

  onDeleteIcon() {
    this.notificationAttachmentFilePath = null;
    this.attachmentFilePath = null;
    this.notificationCreateForm.patchValue({
      notificationAttachment: null,
    });
    this.myInputVariable?.nativeElement ? this.myInputVariable.nativeElement.value = '' : false ;
  }

  updateUserDetails(userDetails) {
    this.UpdateduserDetails = userDetails;
  }

  showFieldValidationAlerts(formGroup: FormGroup) {
    // TODO for test purpose we need this console on server
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (this.notificationCreateForm.value.title.trim() === "") {
        this.toastService.error('Title is required');
      }
     else if (this.notificationCreateForm.value.subTitle.trim() === "") {
        this.toastService.error('SubTitle is required');
      }
      else if (this.notificationCreateForm.value.description.trim() === "") {
        this.toastService.error('Description is required');
      }
     else if (control.invalid) {
        this.toastService.error(`Validation failed for field: ${field}`);
      }
      else if (control instanceof FormGroup) {
        this.showFieldValidationAlerts(control);
      }
    });
  }


  onCreateNotification() {
    if (!this.notificationCreateForm.valid) {
      this.showFieldValidationAlerts(this.notificationCreateForm);
      return;
    }
    this.isLoading = true;
    const { effectiveFrom, effectiveTo, isDynamic, businessUnitId, notificationAttachment } = this.notificationCreateForm.value;
    const data = {
      ...this.notificationCreateForm.value,
      effectiveFrom: effectiveFrom.date,
      effectiveTo: effectiveTo.date,
      moduleId: this.notificationCreateForm.get('moduleId._id').value
        ? this.notificationCreateForm.get('moduleId._id').value
        : null,
      subCategoryId: this.notificationCreateForm.get('subCategoryId._id').value,
      CategoryId: this.notificationCreateForm.get('CategoryId._id').value,
      assignUsers: this.UpdateduserDetails,
      isDynamic: ((isDynamic === '0') || !isDynamic )? false : true,
    };
    data.moduleIncluded = data.moduleId ? true : false;
    data.notificationAttachment = this.notificationAttachmentFilePath || notificationAttachment?.replace(/^.*\/\/[^\/]+/, '');
    data.effectiveFrom = `${moment(data.effectiveFrom).format(
      'MM-DD-YYYY'
    )} ${moment(data.effectiveFrom).format('HH:mm:ss')} GMT${moment().format(
      'ZZ'
    )}`;
    data.effectiveTo = `${moment(data.effectiveTo).format(
      'MM-DD-YYYY'
    )} ${moment(data.effectiveTo).format('HH:mm:ss')} GMT${moment().format(
      'ZZ'
    )}`;
    data.activeFrom = `${moment(data.activeFrom).format('MM-DD-YYYY')} ${moment(
      data.activeFrom
    ).format('HH:mm:ss')} GMT${moment().format('ZZ')}`;
    data.activeTo = `${moment(data.activeTo).format('MM-DD-YYYY')} ${moment(
      data.activeTo
    ).format('HH:mm:ss')} GMT${moment().format('ZZ')}`;
    let isInvalid = false;
     if (
      !this.notificationEditedId &&
      new Date().getTime() >
        new Date(data.activeFrom).getTime()
    ) {
      this.toastService.error('Active From time should be from future');
      isInvalid =  true;
    } else if (
      new Date(data.activeTo).getTime() <
      new Date(data.activeFrom).getTime()
    ) {
      this.toastService.error(
        'Notification active to should be greater then active from'
      )
      isInvalid =  true;
    }else if(data.assignUsers.length ===0){
      this.toastService.error(
        'Add Assign user details'
      )
      isInvalid =  true;
    }
  if(isInvalid){
    return;
  }
    if (this.isNotificationEdited) {
      Object.assign(data, { notificationId: this.notificationEditedId });
      data.businessUnitId = data?.businessUnitId?._id;
    }

    const { CategoryId, ...rest } = data;
    delete rest.mainCategory;
    const sub = this.notificationService
      .createNotification(rest)
      .pipe(finalize(() => sub.unsubscribe()))
      .subscribe(
        (res) => {
          this.toastService.success(
            `${
              this.isNotificationEdited
                ? 'Notification Updated Successfully'
                : 'Notification Created Successfully'
            }`
          );
          this.overViewEvent.emit('true');
          // this.onClearAllFrom();
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          this.toastService.error(error?.error?.message);
        }
      );
  }
  saveToDraftNotification() {
    if (!this.notificationCreateForm.valid) {
      this.toastService.error('Please fill all fields');
      return;
    }
    this.isLoading = true;
    const { effectiveFrom, effectiveTo } = this.notificationCreateForm.value;
    const data = {
      ...this.notificationCreateForm.value,

      effectiveFrom: effectiveFrom.finalDate,
      effectiveTo: effectiveTo.finalDate,

      moduleId: this.notificationCreateForm.get('moduleId._id').value
        ? this.notificationCreateForm.get('moduleId._id').value
        : null,
      subCategoryId: this.notificationCreateForm.get('subCategoryId._id').value,
      CategoryId: this.notificationCreateForm.get('CategoryId._id').value,
      assignUsers: this.UpdateduserDetails,
    };

    if (this.isNotificationEdited) {
      Object.assign(data, { notificationId: this.notificationEditedId });
    }

    const { CategoryId, ...rest } = data;
    const sub = this.notificationService
      .createNotification(rest)
      .pipe(finalize(() => sub.unsubscribe()))
      .subscribe(
        (res) => {
          this.toastService.success(
            `${
              this.isNotificationEdited
                ? 'Notification Updated Successfully'
                : 'Notification Created Successfully'
            }`
          );
          this.overViewEvent.emit(null);
          // this.onClearAllFrom();
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
  }
}
