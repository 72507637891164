import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import {
  detectSingleFace,
  FaceMatcher,
  loadFaceLandmarkModel,
  loadFaceRecognitionModel,
  loadSsdMobilenetv1Model,
  loadTinyFaceDetectorModel,
  TinyFaceDetectorOptions,
} from 'face-api.js';
import { UsersService } from 'src/app/users/service/user-service/users.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { ShiftSchedulerService } from 'src/app/users/service/operations/shift-scheduler.service';
import { ToastrService } from 'ngx-toastr';
// import { FaceMatch, detectAllFaces } from 'face-api.js';

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.scss'],
})
export class AttendanceComponent implements OnInit {
  public selectedStaff: any;
  public companyuseList: any;
  public startCamera: boolean;
  public cameraDivShow: boolean;
  public selectedUserFacialInfo: any;
  public selectedStaffId: any;
  public showCameraAttendDiv: boolean;
  public qrscannerStarted: boolean;
  public isUpdated: any;
  @Output('onButtonClick') onButtonClick = new EventEmitter<number>();
  @Output() ChangeTabId = new EventEmitter<any>();
  @Input('businessUnitId') public businessUnitId: any;
  @Input('dataAttendance') public dataAttendance: any;
  @Output() getPicture = new EventEmitter<WebcamImage>();
  @ViewChild('snapImg') snapImg: ElementRef;
  @ViewChild('originalImg') originalImg: ElementRef;
  showWebcam = true;
  isCameraExist = false;
  errors: WebcamInitError[] = [];

  private trigger: Subject<void> = new Subject<void>();
  private nextWebcam: Subject<boolean | string> = new Subject<
    boolean | string
  >();
  userData: any;
  systemTime: any;
  displayTime: any;
  qrText: string;
  clockInCam: string;
  userImage: any;
  webcamImage: WebcamImage;
  originalImgDescriptor: any;
  facialActionBtn: boolean;
  loadingText: string = 'Verify Photo';

  constructor(
    private timesheetService: ShiftSchedulerService,
    private userService: UsersService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.showCameraAttendDiv = false;
    this.startCamera = false;
    this.cameraDivShow = true;
    this.qrscannerStarted = false;
    // this.getCompanyData();

    WebcamUtil.getAvailableVideoInputs().then(
      (mediaDevices: MediaDeviceInfo[]) => {
        this.isCameraExist = mediaDevices && mediaDevices.length > 0;
      }
    );
   /* this.userService.getUserProfile().subscribe((res) => {
      this.userData = res['data'];
    },
    (err)=>{
      console.log(err);
    });*/

    if (this.dataAttendance?.isFacial) {
      this.clockInCam = 'facial';
      this.facialActionBtn = true;
    } else {
      this.clockInCam = 'QR';
      this.facialActionBtn = false;
    }

    this.getUserImage();
    this.loadFaceModels();
  }

  async loadFaceModels() {
    const MODEL_URL = '/assets/models';

    await loadSsdMobilenetv1Model(MODEL_URL);
    await loadTinyFaceDetectorModel(MODEL_URL);
    await loadFaceLandmarkModel(MODEL_URL);
    await loadFaceRecognitionModel(MODEL_URL);
  }

  async verifyPhoto() {
    this.loadingText = 'Please wait';
    // this.checkCapturedImage();
    const fullFaceDescription = await detectSingleFace(
      this.snapImg?.nativeElement, new TinyFaceDetectorOptions()
    )
      .withFaceLandmarks()
      .withFaceDescriptor();

    if (fullFaceDescription) {
      const faceMatcher = new FaceMatcher(fullFaceDescription.descriptor, 0.6);
      const results = (this.originalImgDescriptor &&  faceMatcher) ? faceMatcher.matchDescriptor(this.originalImgDescriptor):null;
      if (results?.distance < 0.6) {
        const dataObj = {
          userId: this.dataAttendance.userInfo._id,
          shiftId: this.dataAttendance.shiftDetails.shiftId,
          shiftDetailId: this.dataAttendance.shiftDetails._id,
          attendanceMode: 'Facial',
          attandanceTakenBy: localStorage.getItem('loggedInUserId'),
          businessUnitId: this.dataAttendance.businessUnitId,
        };

        if (this.dataAttendance.hasOwnProperty('attendance')) {
          dataObj['status'] = 2;
        } else {
          dataObj['status'] = 1;
        }


        this.timesheetService.getAttendance(dataObj).subscribe((res) => {
          this.toastrService.success('Attendance done', 'Success', {
            timeOut: 3000,
          });
          this.redirectToOverview();
        });
      } else {
        this.toastrService.error(
          'Face did not match Please try again',
          'Failed',
          {
            timeOut: 3000,
          }
        );
        this.clockInCam = 'facial';
        this.webcamImage = null;
      }
    } else {
      this.toastrService.error('No face Detected Please try again', 'Failed', {
        timeOut: 3000,
      });
      this.clockInCam = 'facial';
      this.webcamImage = null;
    }

    this.loadingText = 'Verify Photo';
  }

  checkCapturedImage() {
    if (this.snapImg.nativeElement) {
    } else {
      return;
    }
  }

  getUserImage() {
    this.userService
      .getUserImage(this.dataAttendance?.userInfo?._id)
      .subscribe((res) => {
        this.userImage = res?.userData?.facialInfo;
        this.originalImgDescriptor = res?.userData?.descriptor;
      });
  }

  takeSnapshot(): void {
    this.trigger.next();
  }

  scanQR() {
    this.isCameraExist = true;
  }

  scanSuccessHandler(e) {
    if (!this.qrText) {
      this.qrText = e;

      if (Object.keys(this.dataAttendance).includes('attendance')) {
        const qrClockOutPayload = {
          userId: this.dataAttendance.userInfo._id,
          shiftId: this.dataAttendance.shiftDetails.shiftId,
          shiftDetailId: this.dataAttendance.shiftDetails._id,
          attendanceMode: 'Qr',
          attandanceTakenBy: localStorage.getItem('loggedInUserId'),
          businessUnitId: this.dataAttendance.businessUnitId,
          status: 2,
        };

        this.timesheetService.attendance(qrClockOutPayload).subscribe((res) => {
          Swal.fire({
            title: 'QR Code Verified',
            icon: 'info',
            html:
              '<p>Attendance Clocked-out</p><button class="btn btn-info">System Time ' +
              moment().format('h:mm:ss a') +
              '</button>',
            confirmButtonText: `Ok`,
          }).then((result) => {
            this.ChangeTabId.emit(1);
          });
        });
      } else {
        const attPayload = {
          userId: this.dataAttendance.userInfo._id,
          shiftId: this.dataAttendance.shiftDetails.shiftId,
          shiftDetailId: this.dataAttendance.shiftDetails._id,
          attendanceMode: 'Qr',
          attandanceTakenBy: localStorage.getItem('loggedInUserId'),
          businessUnitId: this.dataAttendance.businessUnitId,
          status: 1,
        };

        this.timesheetService.attendance(attPayload).subscribe((res) => {
          Swal.fire({
            title: 'QR Code Verified',
            icon: 'success',
            html:
              '<p>Attendance Clocked-in</p><button class="btn btn-info">System Time ' +
              moment().format('h:mm:ss a') +
              '</button>',
            confirmButtonText: `Ok`,
          }).then((result) => {
            this.ChangeTabId.emit(1);
          });
        });
      }
    }
  }

  redirectToOverview() {
    this.ChangeTabId.emit(1);
  }

  onOffWebCame() {
    this.showWebcam = !this.showWebcam;
  }

  handleInitError(error: WebcamInitError) {
    this.errors.push(error);
  }

  changeWebCame(directionOrDeviceId: boolean | string) {
    this.nextWebcam.next(directionOrDeviceId);
  }

  handleImage(webcamImage: WebcamImage) {
    this.getPicture.emit(webcamImage);
    this.webcamImage = webcamImage;
  }

  get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  public triggerSnapshot(): void {
    this.clockInCam = null;
    this.trigger.next();
  }

  qrScan() {
    this.clockInCam = 'QR';
    if(this.dataAttendance?.isFacial)
    {
      this.timesheetService.generateQr(this.dataAttendance?.userInfo?._id, this.dataAttendance?.shiftDetails?._id).subscribe((response)=>{

      })
    }
  }

  camerasNotFound(e: Event) {
    // Display an alert modal here
  }

  cameraFound(e: Event) {
    // Log to see if the camera was found
  }

  onScanSuccess(result: string) {
  }

  public okFunction(): void {
    this.onButtonClick.emit(1);
  }

  // public getCompanyData(): void {
  //   let payload = {
  //     businessUnitId: this.businessUnitId,
  //   };
  //   this.timesheetService
  //     .companyuseReadWithData(payload)
  //     .subscribe((response) => {
  //       // this.companyuseList = response.data.data.data;
  //     });
  // }

  public selectedUserId(): void {
    this.selectedStaff = null;
    this.companyuseList.forEach((value) => {
      if (this.selectedStaffId === value.staffId) {
        this.selectedStaff = value;
      }
    });

    this.startCamera = false;
    this.selectedUserFacialInfo = null;
    this.getFacialShiftData();
  }

  public getFacialShiftData(): void {}

  public takeManualAttendance(): void {
    this.systemTime = new Date();
    this.displayTime = moment(this.systemTime).format('HH:mm:ss');
    var dataObj = {
      userId: this.dataAttendance.userInfo._id,
      shiftId: this.dataAttendance.shiftDetails.shiftId,
      shiftDetailId: this.dataAttendance.shiftDetails._id,
      attendanceMode: 'Manual',
      status: 1,
      attandanceTakenBy: localStorage.getItem('loggedInUserId'),
      businessUnitId: this.dataAttendance.businessUnitId,
    };
    Swal.fire({
      title: 'Manual Attendance Clocked',
      icon: 'success',
      html: ' System Time : ' + this.displayTime,
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'OK',
    }).then((result) => {
      if (result.isConfirmed) {
        this.ChangeTabId.emit(1);
      }
    });

    // if (this.isUpdated) {
    //   dataObj.attendanceId = this.selectedUserFacialInfo.attendance._id;
    //   dataObj.status = 2;
    // } else {
    //   dataObj.status = 1;
    // }
    this.timesheetService.getAttendance(dataObj).subscribe((response) => {
      // if (response.data.status == 1) {
      // $("#manualModal").modal();
      // this.getTimesheetData();
      // this.getDashboardData();
      // } else {
      // message('error', response.data.message)
      // }
    });
  }

  public cancelAttendance(): void {}
}
