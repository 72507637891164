import { CompanySetupService } from '../../../../service/company-setup/company-setup.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { StorageService } from 'src/app/core/service/storage.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})
export class PasswordComponent implements OnInit {
  passwordSetupForm: FormGroup;
  submitted: boolean;
  passwordSettingId: string;
  isUpdateLoading: boolean = false
  constructor(
    private formbuilder: FormBuilder,
    private companySetupService: CompanySetupService,
    private toastService: ToastrService,
    private storageService:StorageService
  ) {


  }

  ngOnInit(): void {
    this.submitted = true;
    this.initForm();
    this.getPasswordSetupDetails();
  }

  getPasswordSetupDetails() {
    const sub = this.companySetupService
      .getcompanySetting()
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe((res) => {

        this.passwordSettingId = res.data._id;
        this.passwordSetupForm.patchValue({
          pwdSettings: {
            defaultPassword: res.data.pwdSettings.defaultPassword?  res.data.pwdSettings.defaultPassword : '',
            status: res.data.pwdSettings.status,
            charTypes: {
              specialChar: res.data.pwdSettings.charTypes.specialChar,
              numbers: res.data.pwdSettings.charTypes.numbers,
              upperCase: res.data.pwdSettings.charTypes.upperCase,
              lowerCase: res.data.pwdSettings.charTypes.lowerCase,
            },
            pwdReUse: res.data.pwdSettings.pwdReUse,
            maxLoginAttempt: res.data.pwdSettings.maxLoginAttempt,
            charLength: res.data.pwdSettings.charLength,
            pwdDuration: res.data.pwdSettings.pwdDuration,
            otpSentFor: res.data.pwdSettings.otpSentFor,
            passwordType: res.data.pwdSettings.passwordType,
          },
        });
        console.log(this.passwordSetupForm);
      },   (error) => {

      });
  }
  initForm() {
    this.passwordSetupForm = this.formbuilder.group({
      pwdSettings: this.formbuilder.group({
        defaultPassword: [''],
        status: [1],
        charTypes: this.formbuilder.group({
          specialChar: [false, Validators.required],
          numbers: [false, Validators.required],
          upperCase: [false, Validators.required],
          lowerCase: [false, Validators.required]
        }),
        pwdReUse: [1, [Validators.required, Validators.pattern('^[1-9][0-9]?$|^100$')]],
        maxLoginAttempt: [
          1,
          [Validators.required, Validators.pattern('^[1-9][0-9]?$|^100$')],
        ],
        charLength: [null, [Validators.required, Validators.pattern('^[4-9][0-9]?$|^100$')]],
        pwdDuration: [1, [Validators.required, Validators.pattern('^[1-9][0-9]?$|^100$')]],
        otpSentFor: [1, [Validators.required,]],
        passwordType: [
          1,
          [Validators.required],
        ],
      }),
    });
  }

  // get pwdSettingsControl(): FormGroup {
  //   return this.passwordSetupForm.controls.pwdSettings ;
  // }

  onSubmitPasswordSetupForm() {
    if (!this.passwordSetupForm.valid) {
      this.submitted = true;
      return;
    }
    this.isUpdateLoading = true
    let data=this.passwordSetupForm.value;
    data.pageId=this.passwordSettingId;
    this.companySetupService
      .updatePasswordSettings(
      data
      )
      .subscribe((res) => {
        this.toastService.success('Updated SuccessFully');
        this.isUpdateLoading = false
        this.ngOnInit()
      },
       (error) => {
       this.isUpdateLoading = false
      });
  }
}
