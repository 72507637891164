<div class="d-flex flex-column">
    <ul ngbNav [(activeId)]="activeId" #companyNav="ngbNav" class="nav-tabs rounded mb-3">
      <li [ngbNavItem]="1">
        <a ngbNavLink (click)="overViewEvent($event)">OVERVIEW</a>
        <ng-template ngbNavContent>
          <events-overview (EditEvent)="formEdit($event)" (ManageEvent)="formManage($event)"></events-overview>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink> {{isBoardEdited? 'EDIT' : isManageEdited? 'MANAGE' : 'CREATE'}}</a>
        <ng-template ngbNavContent>
          <app-create-edit-events [eventId]="boardId" *ngIf="!isManageEdited" (overViewEvent)="overViewEvent($event)"></app-create-edit-events>
          <app-events-manage *ngIf="isManageEdited" [manageId]="boardId"></app-events-manage>
        </ng-template>
      </li>

      <li [ngbNavItem]="3">
        <a ngbNavLink>REPORTED POSTS</a>
        <ng-template ngbNavContent> 
          <events-reported-post></events-reported-post>
        </ng-template>
      </li>
      <li [ngbNavItem]="4">
        <a ngbNavLink>REPORTED COMMENTS</a>
        <ng-template ngbNavContent>
          <events-reported-comments></events-reported-comments>
        </ng-template>
      </li>

    </ul>
    <div [ngbNavOutlet]="companyNav"></div>
</div>