import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../service/auth/auth.service';
import { StorageService } from 'src/app/core/service/storage.service';
import { UsersService } from '../../service/user-service/users.service';
import { NotificationService } from 'src/app/users/service/notification/notification.service';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CompanyService } from 'src/app/admin/service/companyservice/company.service';

@Component({
  selector: 'admin-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  userData;
  profilePicture;
  companyLogo:any;
  baseUrl = environment.imageUrl;
    constructor(
    private storageService: StorageService,
    private router: Router,
    private toastService: ToastrService,
    private notificationService: NotificationService,
    private authservice: AuthService,
    private userService: UsersService,
    private companyService: CompanyService
  ) {
    this.userData = localStorage.getItem('flexistaffId');

    this.usersPTable = {
      sortBy: 'desc',
      sortWith: 'createdBy',

      data: [],
      page: 1,
      limit: 10,
      search: '',
    };
  }
  usersPTable;
  @Input() activeUrl: string;
  @Output('toggleSideNav') toggleSideNav: EventEmitter<any> =
    new EventEmitter();
  subscription: Subscription;
  notificationCount: number;

  ngOnInit(): void {
    const companyDetails = JSON.parse(localStorage.getItem('companyDetails') || '{}');
    this.companyLogo = this.companyService.checkImagePath(companyDetails?.logo);
    this.loadProfileDetails();
    this.loadUsers();
    this.subscription = this.userService.userProfileImage$.subscribe(message => {
      this.profilePicture = message;
    });
  }

  loadUsers() {
    const { data, total, ...rest } = this.usersPTable;
    const subscribe = this.notificationService
      .getUnreadNofications(rest)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          this.notificationCount = response.data.count;
        },
        (error) => {
          this.toastService.error('No list found');
        }
      );
  }

  loadProfileDetails() {
    const subscribe = this.userService
      .getUserProfile()
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe((response: any) => {
          this.userService.setProfileImage(response.data.profilePicture);
      });
  }

  toggleMenu() {
    this.toggleSideNav.emit();
  }

  logout() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to logout?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Logout',
    }).then((result) => {
      if (result.isConfirmed) {
        // this.toastService.success('Logged out sucessfully.');
        return this.authservice.logout();
      }
    });
  }

  profile() {
    this.userService.getPrivilege().subscribe((res) => {
      localStorage.setItem("privilegeFlags", JSON.stringify(res.privileges) );
      const userData = JSON.parse(localStorage.getItem("privilegeFlags"));
      if (userData.userProfile) {
        this.router.navigate(['/users/profile'])
      }
    }, (error) => {
      if (error.status === 402) {
        this.toastService.error(error.error);
        localStorage.removeItem('privilegeFlags')
        this.authservice.logout();
      }
    })
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  validateURLImage(str) {
    var img_Path = str;
    if (img_Path.indexOf("https://") == 0) {
      var new_url = new URL(str);
     var pathName = (new_url.pathname.startsWith('//')) ? `/${new_url.pathname.split("//")[1]}` : new_url.pathname;
      return `${this.baseUrl}${pathName}`;
    }
    if (str.startsWith('/')) return this.baseUrl + str;
    return `${this.baseUrl}/${str}`;
   }
}
