<table cellpadding="0" cellspacing="0" width="100%" class="table_loader">
  <tbody *ngIf="isTableLoading">
    <tr>
      <td *ngFor='let in of counter(numberOfCols); let i = index'>
        <span></span>
      </td>
    </tr>
    <tr>
      <td *ngFor='let in of counter(numberOfCols); let i = index'>
        <span></span>
      </td>
    </tr>
    <tr>
      <td *ngFor='let in of counter(numberOfCols); let i = index'>
        <span></span>
      </td>
    </tr>
    <tr>
      <td *ngFor='let in of counter(numberOfCols); let i = index'>
        <span></span>
      </td>
    </tr>
  </tbody>
</table>