<div class="d-flex flex-column">
  <ul
    ngbNav
    #listNav="ngbNav"
    [(activeId)]="activeId"
    class="nav-tabs rounded mb-3"
  >
    <li [ngbNavItem]="1">
      <a ngbNavLink>LIST OF BOOKING</a>
      <ng-template ngbNavContent>
        <div class="card">
          <h6 class="card-header bg-theme">SELECT BUSINESS UNIT AND DATE</h6>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-md-10 d-flex align-items-end">
                    <div class="form-group w-100">
                      <label class="font-weight-500">Business Unit<span class="field-mandatory">*</span></label>
                      <ng-select
                        [items]="allBuShift"
                        [loading]="buLoader"
                        class="custom"
                        bindLabel="orgName"
                        placeholder="Select Business unit"
                        appendTo="body"
                        [multiple]="false"
                        [searchable]="true"
                        [clearable]="true"
                        [closeOnSelect]="true"
                        [(ngModel)]="bu"
                      >
                      </ng-select>
                      <div *ngIf="buLoader" class="text-danger">
                        Please wait data is loading...
                      </div>
                    </div>
                    <button class="btn btn-danger ml-2" (click)="clearAllBu()">Clear</button>
                    <button class="btn btn-success ml-2" (click)="addBu()">
                      Add
                    </button>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12">
                    <div *ngIf="this.buLabelArr != null">
                      <div class="labels" *ngFor="let item of buLabelArr; let i = index">
                        <span class="text">{{ item }}</span>
                        <span class="material-icons bg-danger rounded btn-close" (click)="clearLabel(i)">
                          clear
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label class="font-weight-500">Start Date<span class="field-mandatory">*</span></label>
                      <div class="input-group">
                        <input
                          class="form-control"
                          (click)="d1.toggle()"
                          placeholder="Select date"
                          ngbDatepicker
                          #d1="ngbDatepicker"
                          [(ngModel)]="startdate"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label class="font-weight-500">End Date<span class="field-mandatory">*</span></label>
                      <div class="input-group">
                        <input
                          class="form-control"
                          (click)="d2.toggle()"
                          placeholder="Select date"
                          ngbDatepicker
                          #d2="ngbDatepicker"
                          [(ngModel)]="enddate"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button class="btn btn-warning" (click)="downloadCsv()" [disabled]="!(startdate && enddate && buArr.length > 0)">
              Click to download File
            </button>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="listNav"></div>
</div>

<div>
  <div *ngIf="isLoader" class="loader">
    <div class="pageLoading">
      <div class="innerDiv">
        <i class="fa fa-spinner fa-spin" style="float: none;"></i> {{ loadingMessage }}
      </div>
    </div>
  </div>