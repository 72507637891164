<div class="d-flex flex-column">
    <ul ngbNav [(activeId)]="activeId" #companyNav="ngbNav" class="nav-tabs rounded mb-3">
      <li [ngbNavItem]="1">
        <a ngbNavLink (click)="overViewEvent($event)">OVERVIEW</a>
        <ng-template ngbNavContent>
          <app-forms-overview (EditEvent)="formEdit($event)" (isManage)="manageForm($event)" (ViewEvent)="formView($event)">
          </app-forms-overview>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>{{isFormEdited? 'EDIT' : isFormManaged? 'MANAGE' : 'CREATE'}}</a>
        <ng-template ngbNavContent>
          <app-create-edit-forms  *ngIf="!isFormManaged" [formId]="formID" [editFormData]="formDetails" (overViewEvent)="overViewEvent($event)"></app-create-edit-forms>
          <app-form-manage *ngIf="isFormManaged" [manageId]="manageId" [overViewData]="overViewData" [editFormData]="formDetails"></app-form-manage>
        </ng-template>
      </li>
      <li [ngbNavItem]="4" *ngIf = "isFormViewed && !isFormEdited && !isFormManaged">
        <a ngbNavLink>View</a>
        <ng-template ngbNavContent>
          <app-view-forms [formId]="formID" (overViewEvent)="overViewEvent($event)" [editFormData]="formDetails"></app-view-forms>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink>LOGS</a>
        <ng-template ngbNavContent>
          <app-logs-overview></app-logs-overview>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="companyNav"></div>
</div>
