
<div (mouseleave)="showDropDown = false" >
    <div >

        <button class="drop-toggle btn flat w-100" (click)="showDropDown=!showDropDown"  >
            <span *ngIf="!currentSelected">Select</span>
            <span *ngIf="currentSelected">{{currentSelected}}</span>
            <i class="fa fa-angle-down"></i>
        </button>
        <div class="drop-show w-80" *ngIf="showDropDown">
          <input type = "search" [(ngModel)]="searchvalue" class="search">
            <div class="topList" *ngFor="let a of options|filter:{ value: searchvalue }">


                <div class="list"  (click)="getSelectedValue(a.value)">{{a.value}}</div>
            </div>
        </div>
    </div>
</div>
