<div
  #sidemenu
  id="sidemenu"
  class="p-3 d-none d-sm-block pt-5 sidemenu"
  *ngIf="!isView"
>
  <aside>
    <div class="form-group">
      <label class="d-block text-center">BUSINESS UNIT</label>
      <ng-select
        [items]="buForShift"
        [loading]="buLoader"
        loadingText="Please wait data is loading..."
        class="custom"
        bindLabel="orgName"
        placeholder="Select/Search Business unit"
        appendTo="body"
        [searchable]="true"
        [clearable]="true"
        [closeOnSelect]="true"
        [(ngModel)]="buId"
        (change)="onBuChange()"
      >
      </ng-select>
    </div>

    <div class="form-group">
      <label class="d-block text-center">WORK WEEK</label>
      <ng-select
        class="custom"
        [virtualScroll]="true"
        [loading]="weeksLoader"
        placeholder="Select Week"
        appendTo="body"
        [multiple]="false"
        [searchable]="true"
        [clearable]="true"
        [closeOnSelect]="true"
        [(ngModel)]="selectedWeek"
      >
      <ng-container *ngFor="let item of weeksArr">
        <ng-option  [value]="item.label">{{item.label}}</ng-option>
      </ng-container>

      </ng-select>
    </div>

    <div class="row">
      <div class="col pr-md-3">
        <button class="btn btn-danger btn-block">Cancel</button>
      </div>
      <div class="col pl-md-0">
        <button class="btn btn-info btn-block" (click)="viewClicked()">
          View
        </button>
      </div>
    </div>
  </aside>
</div>

<div class="d-flex flex-column" *ngIf="isView">
  <ul
    ngbNav
    #companyNav="ngbNav"
    [(activeId)]="activeId"
    class="nav-tabs rounded mb-3"
  >
    <li [ngbNavItem]="1">
      <a ngbNavLink (click)="refreshHeader()">OVERVIEW</a>

      <div *ngIf="isLoader" class="loader">
        <div class="pageLoading" >
            <div class="innerDiv">
                <i class="fa fa-spinner fa-spin" style="float: none;"></i> Please wait, data is loading....
            </div>
        </div>
    </div>

      <ng-template ngbNavContent>
        <div class="top-header rounded bg-white mb-3">
          <div>
            <div>Planned Flexi Hrs</div>
            <div class="hrs-text red-text">
              {{
                headerData.one == 0
                  ? "0.00"
                  : (headerData.one | number: "1.2-2")
              }}
            </div>
          </div>
          <div>
            <div>Planned Flexi Staff</div>
            <div class="hrs-text red-text">
              {{
                headerData.two == 0
                  ? "0.00"
                  : (headerData.two | number: "1.2-2")
              }}
            </div>
          </div>
          <div>
            <div class="red-text">Booked Flexi Hrs</div>
            <div class="hrs-text red-text">
              {{
                headerData.three == 0
                  ? "0.00"
                  : (headerData.three | number: "1.2-2")
              }}
            </div>
          </div>
          <div>
            <div class="red-text">Booked Flexi staff</div>
            <div class="hrs-text red-text">
              {{
                headerData.four == 0
                  ? "0.00"
                  : (headerData.four | number: "1.2-2")
              }}
            </div>
          </div>
        </div>

        <app-view-booking-overview *ngIf="shiftReadRes"
          [bu]="buId"
          [startDate]="startDateLocal"
          [graphData]="shiftReadRes"
        ></app-view-booking-overview>
      </ng-template>
    </li>
    <li [ngbNavItem]="2" [disabled]="!shiftReadRes">
      <a ngbNavLink (click)="switchToBooking()">BOOKING</a>
      <ng-template ngbNavContent>
        <div class="top-header rounded bg-white mb-3">
          <div>
            <div>Planned Flexi Hrs</div>
            <div class="hrs-text red-text">
              {{
                this.topData == undefined
                  ? "0.00"
                  : (this.topData.totalHours.need | number: "1.2-2")
              }}
            </div>
          </div>
          <div>
            <div>Planned Flexi Staff</div>
            <div class="hrs-text red-text">
              {{
                this.topData == undefined
                  ? "0.00"
                  : (this.topData.numberOfShifts.need | number: "1.2-2")
              }}
            </div>
          </div>
          <div>
            <div class="red-text">Booked Flexi Hrs</div>
            <div class="hrs-text red-text">
              {{
                this.topData == undefined
                  ? "0.00"
                  : (this.topData.totalHours.booked | number: "1.2-2")
              }}
            </div>
          </div>
          <div>
            <div class="red-text">Booked Flexi staff</div>
            <div class="hrs-text red-text">
              {{
                this.topData == undefined
                  ? "0.00"
                  : (this.topData.numberOfShifts.booked | number: "1.2-2")
              }}
            </div>
          </div>
        </div>
        <app-booking *ngIf="receivedPlanShift"
          [shift]="receivedPlanShift"
          [week]="planShift.startDate"
          [buId]="buId"
          (headerData)="getheaderData($event)"
          (day)="headerHrsStaff($event)"
        ></app-booking>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="companyNav"></div>
</div>
