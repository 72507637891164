import { Component, OnInit, SecurityContext} from '@angular/core';
import { LeavePlannerService } from 'src/app/users/service/human-resources/leave-planner.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { generateImageUrl } from 'src/app/core/utils/helper';

@Component({
  selector: 'app-approve',
  templateUrl: './approve.component.html',
  styleUrls: ['./approve.component.scss'],
})
export class ApproveComponent implements OnInit {
  selectedyear;
  appliedLeaveData: any;
  year = [
    parseInt(moment().subtract(1, 'year').format('YYYY')),
    parseInt(moment().format('YYYY')),
    parseInt(moment().add(1, 'year').format('YYYY')),
  ];
  defaultSelectedStatus:any={ status: 0, name: 'Pending Approval' };
  filterDataResult: any;
  viewUserDet: any;
  approveObj: {};
  updateForm: FormGroup;
  submitFormType: any;
  submitTypeValue: any;
  increment: any;
  highlightIndex: any;
  selectedUserId: any;
  approveLoader: boolean = true;
  baseUrl = environment.imageUrl;
  generateImageUrl = generateImageUrl
  constructor(
    private leaveService: LeavePlannerService,
    protected formBuilder: FormBuilder,
    public toastService: ToastrService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.approveDataList();
    this.initForm();
  }

  initForm() {
    this.updateForm = this.formBuilder.group({
      forApproval: ['', [Validators.required]],
      forRemarks: ['', [Validators.required]],
      forNewRemarks: ['', [Validators.required]],
      forDeducted: ['', [Validators.required]],
      forRest: ['', [Validators.required]],
      forTotal: ['', [Validators.required]],
      cancelledDate: ['', [Validators.required]],
      staffId: ['', [Validators.required]],
      staffIdApproveHis: ['', [Validators.required]],
      staffNameApproveHis: ['', [Validators.required]],
      staffName: ['', [Validators.required]],
      forStatus: ['', [Validators.required]],
    });
  }

  approveDataList() {
    this.leaveService
      .approveAppliedLeave()
      .pipe(
        finalize(() => {
          this.approveLoader = false;
        })
      )
      .subscribe((res: { data: any }) => {
        this.appliedLeaveData = res.data;
        this.filterDataResult = this.appliedLeaveData;
        this.viewUser(this.filterDataResult[0], 0);
      });
  }

  yearFilter(yearLeaveData) {
    if (this.selectedyear) {
      return yearLeaveData.filter((element) => {
        const year = new Date(element.startDate).getFullYear();
        if (this.selectedyear === year) {
          return element;
        }
        return;
      });
    }
    return yearLeaveData;
  }

  statusFilter(statusLeaveData) {
    if (this.defaultSelectedStatus) {
      return statusLeaveData.filter((element) => {
        if (this.defaultSelectedStatus.status === element.status) {
          return element;
        }
        return;
      });
    }
    return statusLeaveData;
  }

  filterData() {
    const dataFilteredByYear = this.yearFilter(this.appliedLeaveData);
    const dataFilteredByStatus = this.statusFilter(dataFilteredByYear);

    this.filterDataResult = dataFilteredByStatus;
  }

  getAttachmentFileName(fileUrl){
    let fileArray:any;
    let fileName:any;
    if(fileUrl){
      fileArray=fileUrl.split('/');
      fileName=fileArray[fileArray?.length-1]
    }
    return fileName ? fileName :null;
  }

  viewUser(history, index) {
    this.viewUserDet = history;
    this.selectedUserId = history._id;
    this.highlightIndex = index;
    this.updateForm.patchValue({
      forApproval:
        this.viewUserDet?.submittedFrom == 1
          ? 'Apply Leave'
          : this.viewUserDet?.submittedFrom == 2
          ? 'Leave Request'
          : 'Apply from My Leave Plans',
      forRemarks: this.viewUserDet.remark,
      forApproveRemarks: this.viewUserDet.approvalHistory?.approvalRemark,
      forDeducted: this.viewUserDet.totalDeducated,
      forRest: this.viewUserDet.totalRestOff,
      forTotal: this.viewUserDet.totalDay,
      cancelledDate: moment(this.viewUserDet.cancelledDateTime).format(
        'DD-MMM-YYYY hh:mm'
      ),
      staffId: this.viewUserDet?.cancelledBy?.staffId,
      staffIdApproveHis:
        this.viewUserDet.approvalHistory[0]?.approvalBy?.staffId,
      staffNameApproveHis:
        this.viewUserDet.approvalHistory[0]?.approvalBy?.name,
      staffName: this.viewUserDet?.cancelledBy?.name,
      forStatus:
        this.viewUserDet.status == 0
          ? 'Pending Approval'
          : this.viewUserDet.status == 1
          ? 'Approved'
          : this.viewUserDet.status == 2
          ? 'Rejected'
          : this.viewUserDet.status == 5
          ? 'Cancelled'
          : this.viewUserDet.status == 3
          ? '-'
          : '-',
    });
  }

  approveLeave() {
    this.submitFormType = this.updateForm.get('forApproval').value;
    if (this.submitFormType == 'Apply Leave') {
      this.submitTypeValue = 1;
    } else if (this.submitFormType == 'Leave Request') {
      this.submitTypeValue = 2;
    } else {
      this.submitTypeValue = 3;
    }
    this.approveObj = {
      appliedLeaveId: this.viewUserDet._id,
      isApprove: true,
      approvalRemark: this.updateForm.get('forNewRemarks').value,
      approvalFrom: this.submitTypeValue,
      totalDeducated: parseInt(this.updateForm.get('forDeducted').value),
      totalRestOff: parseInt(this.updateForm.get('forRest').value),
    };

    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to approve the leave?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        this.leaveService
          .leaveApproval(this.approveObj)
          .subscribe((res: { data: any }) => {
            this.approveDataList();
            this.toastService.success('Leave Approved successfully');
            // this.appliedLeaveData = res.data;
            // this.filterDataResult = this.appliedLeaveData;
          });
      }
    });
  }

  rejectLeave() {
    this.submitFormType = this.updateForm.get('forApproval').value;
    if (this.submitFormType == 'Apply Leave') {
      this.submitTypeValue = 1;
    } else if (this.submitFormType == 'Leave Request') {
      this.submitTypeValue = 2;
    } else {
      this.submitTypeValue = 3;
    }
    this.approveObj = {
      appliedLeaveId: this.viewUserDet._id,
      isApprove: false,
      approvalRemark: this.updateForm.get('forNewRemarks').value,
      approvalFrom: this.submitTypeValue,
      totalDeducated: parseInt(this.updateForm.get('forDeducted').value),
      totalRestOff: parseInt(this.updateForm.get('forRest').value),
    };

    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to reject the leave?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        this.leaveService
          .leaveApproval(this.approveObj)
          .subscribe((res: { data: any }) => {
            this.approveDataList();
            this.toastService.success('Leave Rejected successfully');

            // this.appliedLeaveData = res.data;
            // this.filterDataResult = this.appliedLeaveData;
          });
      }
    });
  }

  add(val) {
    let increment = this.updateForm.get('forDeducted').value;
    let decrement = this.updateForm.get('forRest').value;

    if (val == 1) {
      increment = increment + 1;
      this.updateForm.patchValue({
        forDeducted: increment,
      });
      decrement = decrement - 1;
      this.updateForm.patchValue({
        forRest: decrement,
      });
    }
    if (val == 2) {
      increment = increment - 1;
      this.updateForm.patchValue({
        forDeducted: increment,
      });
      decrement = decrement + 1;
      this.updateForm.patchValue({
        forRest: decrement,
      });
    }
    //  this.increment++;
  }

  safeUrl(url: string): SafeUrl {
    const safeUrl: SafeUrl = this.sanitizer.sanitize(SecurityContext.URL, this.baseUrl + '/' + url);
    return safeUrl ? this.sanitizer.bypassSecurityTrustUrl(safeUrl.toString()) : null;
  }

}
