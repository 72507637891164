
<div class="tab-content">
    <div class="row dashboardPanelCon">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="panel panel-default">
          <div class="panel-heading">Scheduled Push Notification List</div>
          <div class="panel-body lg-padding">
            <div class="table-responsive">
              <div class="card-body">
                <div class="card table-card">
                  <div class="card-body flexi-body">
                    <div class="flex-column">
                      <div class="d-flex justify-content-between mt-2 mb-3">
                        <select class="custom-select" style="width: auto" name="pageSize" (change)="onLimitChangeSchedule()"
                          [(ngModel)]="schedulePTable.limit">
                          <option [ngValue]="10">10</option>
                          <option [ngValue]="20">20</option>
                          <option [ngValue]="30">30</option>
                          <option [ngValue]="40">40</option>
                          <option [ngValue]="50">50</option>
                        </select>
                        <div class="form-group form-inline mb-0">
                          <span *ngIf="schedulePTable.isLoading">Please wait...</span>
                          <input class="form-control ml-2" type="search" name="searchTerm"
                            (keyup)="scheduleSearchInput.next($event)" placeholder="Search here"
                            [(ngModel)]="schedulePTable.search" />
                        </div>
                      </div>
                      <div class="table-responsive">
                        <table class="table" *ngIf="!isTableLoading">
                          <thead>
                            <tr>
                              <th scope="col" sortable="title" (sort)="onSortSchedule($event)">
                                Title
                              </th>
                              <th scope="col" sortable="scheduleType" (sort)="onSortSchedule($event)">
                                Schedule
                              </th>
                              <th scope="col" sortable="activeFrom" (sort)="onSortSchedule($event)">
                                Active From
                              </th>
                              <th scope="col" sortable="activeTo" (sort)="onSortSchedule($event)">
                                Active To
                              </th>
                              <th scope="col" sortable="createdBy" (sort)="onSortSchedule($event)">
                                Created By
                              </th>
                              <th scope="col" sortable="status" (sort)="onSortSchedule($event)">
                                Status
                              </th>
                              <th scope="col">
                                ACTION
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of schedulePTable.data">
                              <td>
                                {{ item.title }}
                              </td>
                              <td>
                                {{ item.scheduleType }}
                              </td>
                              <td>
                                {{ item.activeFrom }}
                              </td>
                              <td>
                                {{ item.activeTo }}
                              </td>
                              <td>
                                {{ item.createdBy }}
                              </td>
                              <td>
                                {{ item.notificationStatus }}
                              </td>

                              <td>
                                <button class="btn btn-submit p-2 m-1 pl-3 pr-3" (click)="onViewSchedule(item._id)">
                                  View
                                </button>
                                <button *ngIf="item.notificationStatus !== 'Expired' && item.notificationStatus !== 'Inactive'" class="btn btn-add p-2 m-1 pl-3 pr-3" (click)="onEditSchedule(item._id)">
                                  Edit
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <app-skliton-loading-table [numberOfCols]="8" [isTableLoading]="isTableLoading"></app-skliton-loading-table>
                    </div>
                  </div>
                  <div class="card-footer">
                    <div class="d-flex justify-content-between">
                      <p>Total records {{ schedulePTable.total }}</p>
                      <ngb-pagination [collectionSize]="schedulePTable.total" [(page)]="schedulePTable.page" [maxSize]="5"
                        (pageChange)="onPageChangeSchedule($event)" [pageSize]="schedulePTable.limit">
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <table datatable [dtOptions]="dtOptions" class="table table-hover" id="notificationListSchedule">
                <thead>
                  <tr>
                    <th class="centerAlign">Title</th>
                    <th class="centerAlign">Schedule</th>
                    <th class="centerAlign">Active From</th>
                    <th class="centerAlign">Active To</th>
                    <th class="centerAlign">Created By</th>
                    <th class="centerAlign">Status</th>
                    <th class="centerAlign">Action</th>
                  </tr>
                </thead>
              </table> -->
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>


