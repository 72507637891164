<div class="d-flex flex-column">
  <ul
    ngbNav
    #listNav="ngbNav"
    [(activeId)]="activeId"
    class="nav-tabs rounded mb-3"
  >
    <li [ngbNavItem]="1">
      <a ngbNavLink>LIST OF USERS</a>
      <ng-template ngbNavContent>
        <div class="card">
          <h6 class="card-header bg-theme">SELECT BUSINESS UNIT AND DATE</h6>
          <div class="card-body">
            <div class="row">
              <div class="col-md-8">
                <div class="form-group">
                  <label class="font-weight-500">Business Unit<span class="field-mandatory">*</span></label>
                  <ng-select
                    [items]="allBuShift"
                    [loading]="buLoader"
                    class="custom large-dropdown"
                    bindLabel="orgName"
                    placeholder="Select Business unit"
                    appendTo="body"
                    [multiple]="false"
                    [searchable]="true"
                    [clearable]="true"
                    [closeOnSelect]="true"
                    [(ngModel)]="bu"
                  >
                  </ng-select>
                  <div *ngIf="buLoader" class="text-danger">
                    Please wait data is loading...
                  </div>
                </div>
              </div>
              <div class="col flex-grow-0 noWrap">
                <label class="font-weight-500">&nbsp; </label><br />
                <button class="btn btn-danger" (click)="clearAllBu()">Clear</button>
                <button class="btn btn-success ml-2" (click)="addBu()">
                  Add
                </button>
              </div>
              <div class="col-md-12">
                <div *ngIf="this.buLabelArr">
                  <div
                    class="labels"
                    *ngFor="let item of buLabelArr; let i = index"
                  >
                    <span class="text">{{ item }}</span>
                    <span
                      class="material-icons bg-danger rounded btn-close"
                      (click)="clearLabel(i)"
                    >
                      clear
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label class="font-weight-500">Select Skillsets</label>
                  <ng-select
                    class="custom"
                    bindLabel="name"
                    placeholder="Select Skill Sets"
                    appendTo="body"
                    [multiple]="false"
                    [searchable]="true"
                    [clearable]="true"
                    (change)="onSkillSetChange($event)"
                    [(ngModel)]="skill"
                  >
                    <ng-option [value]="i" *ngFor="let i of buSkillSet">
                      {{ i.name }}
                    </ng-option>
                  </ng-select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="font-weight-500">Select speciality</label>
                  <ng-select
                    class="custom"
                    bindLabel="name"
                    placeholder="Select Speciality"
                    appendTo="body"
                    [multiple]="false"
                    [searchable]="true"
                    [clearable]="true"
                    [(ngModel)]="speciality"
                  >
                    <ng-option
                      [value]="item"
                      *ngFor="let item of buSkillspeciality"
                      >{{ item.name }}</ng-option
                    >
                  </ng-select>
                </div>
              </div>
              <div class="col flex-grow-0 noWrap">
                <label class="font-weight-500">&nbsp; </label><br />
                <button class="btn btn-danger">Clear</button>
                <button
                  class="btn btn-success ml-2"
                  (click)="addSkillbutton(skill, speciality)"
                >
                  Add
                </button>
              </div>
              <div class="col-md-6">
                <div *ngIf="this.skillsSet != null">
                  <div
                    class="labels"
                    *ngFor="let item of skillLabels; let i = index"
                  >
                    <span class="text">{{ item[0] }} > {{ item[1] }}</span>
                    <span
                      class="material-icons bg-danger rounded btn-close"
                      (click)="clearSkillLabel(i)"
                    >
                      clear
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="font-weight-500">Appointment </label>
                  <ng-select
                    [items]="appoinmentPTable.data"
                    bindLabel="name"
                    [searchable]="true"
                    [closeOnSelect]="true"
                    [clearable]="true"
                    [multiple]="false"
                    placeholder="Select appoinment"
                    [loading]="apppoinmentDropDownLoading"
                    (search)="onSeachAppoinment($event)"
                    (scrollToEnd)="loadMoreAppoinmentItems($event)"
                    [(ngModel)]="appoint"
                  >
                  </ng-select>
                </div>
              </div>
              <div class="col flex-grow-0 noWrap">
                <label class="font-weight-500">&nbsp; </label><br />
                <button class="btn btn-danger">Clear</button>
                <button class="btn btn-success ml-2" (click)="addAppoint()">
                  Add
                </button>
              </div>
              <div class="col-md-6">
                <label class="font-weight-500">&nbsp; </label><br />
                <div *ngIf="this.userappointlabels != null">
                  <div
                    class="labels"
                    *ngFor="let item of userappointlabels; let i = index"
                  >
                    <span class="text">{{ item }}</span>
                    <span
                      class="material-icons bg-danger rounded btn-close"
                      (click)="clearappointLabel(i)"
                    >
                      clear
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="font-weight-500">User Status</label>
                  <ng-select
                    [items]="status"
                    class="custom"
                    bindLabel="status"
                    placeholder="Select role"
                    appendTo="body"
                    [multiple]="false"
                    [searchable]="true"
                    [clearable]="true"
                    [(ngModel)]="statusId"
                  >
                  </ng-select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="font-weight-500">Role</label>
                  <ng-select
                    [items]="roleDropDown"
                    class="custom"
                    bindLabel="name"
                    placeholder="Select role"
                    appendTo="body"
                    [multiple]="false"
                    [searchable]="true"
                    [clearable]="true"
                    [loading]="roleDropDownLoading"
                    (search)="onSeachRole($event)"
                    (scrollToEnd)="loadMoreRoleItems($event)"
                    (clear)="onClearRoleDropdown($event)"
                    [(ngModel)]="rolelabel"
                  >
                  </ng-select>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button class="btn btn-warning" [disabled]="downloadFileLoader" (click)="downloadCsv()" [disabled]="!(buArr.length > 0)">
              Click to download File
              <span
                *ngIf="downloadFileLoader"
                class="spinner-border spinner-border-sm ml-2"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="listNav"></div>
</div>

<div>
  <div *ngIf="downloadFileLoader" class="loader">
    <div class="pageLoading">
      <div class="innerDiv">
        <i class="fa fa-spinner fa-spin" style="float: none;"></i> {{ loadingMessage }}
      </div>
    </div>
  </div>