<div class="tab-content">
  <div class="panel">
    <div class="panel-heading">
      <div class="bg-theme card-header d-flex flex-column">
        <div class="d-flex justify-content-between w-100">
          <div class="mb-2">
            <h5 class="m-0">TIMESHEET HISTORY</h5>
            <p class="m-0">Selected Business Unit : {{ businessUnitId.orgName }}</p>
          </div>
          <div>
            <button class="btn btn-info btn-sm" (click)="exportHistoryCSV()">
              Export Timesheet
            </button>
            <button class="btn btn-outline-light btn-sm ml-1">Back</button>
          </div>
        </div>
        <form [formGroup]="shiftsFormFilter">
          <div class="row">
            <div class="col-md-3 align-self-end">
              <label class="">SELECT START DATE</label>
              <input
                class="form-control"
                (click)="d1.toggle()"
                formControlName="startDateFilter"
                ngbDatepicker
                #d1="ngbDatepicker"
                (change)="handleExport(1)"
              />
            </div>
            <div class="col-md-3 align-self-end">
              <label class="">SELECT END DATE</label>
              <input
                class="form-control"
                (click)="d2.toggle()"
                placeholder="{{ this.lastDate }}"
                formControlName="endDateFilter"
                ngbDatepicker
                #d2="ngbDatepicker"
                (change)="handleExport(1)"
              />
              <!-- [(ngModel)]="model5" -->
            </div>

            <div class="col-md-2 align-self-end">
              <button class="btn btn-success" (click)="viewBtn()">View</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="panel-body">
      <div class="row">
        <div class="col-md-12">
          <input
            type="text"
            class="form-control my-2"
            id="f.userInfo.name"
            placeholder="Search by User Name or Staff ID"
            autocomplete="off"
            [(ngModel)]="searchString"
          />
        </div>
        <div class="col-md-12">
          <div class="table-responsive">
            <div *ngIf="timeSheetDataLoader" class="loader">
              <div class="pageLoading">
                  <div class="innerDiv">
                      <i class="fa fa-spinner fa-spin" style="float: none;"></i> Please wait, Data is Refreshing ......
                  </div>
              </div>
          </div>
            <table class="table bg-white" *ngIf="!timeSheetDataLoader">
              <tr>
                <th
                  class="headTh"
                  rowspan="2"
                  style="text-align: center; background: #ddd; width: 10%"
                  data-orderable="true"
                >
                  User Name
                </th>
                <th
                  class="headTh"
                  rowspan="2"
                  style="text-align: center; background: #ddd"
                >
                  Staff ID
                </th>
                <th
                  class="headTh"
                  rowspan="2"
                  style="
                    text-align: center;
                    background: #ddd;
                    padding-left: 20px;
                    padding-right: 25px;
                  "
                >
                  Date
                </th>
                <th
                  class="headTh"
                  colspan="2"
                  style="text-align: center; background: #ddd"
                >
                  Shift Hours
                </th>
                <th
                  class="headTh"
                  colspan="2"
                  style="text-align: center; background: #ddd"
                >
                  Clocked Hours
                </th>
                <th
                  class="headTh"
                  rowspan="2"
                  style="text-align: center; background: #ddd; width: 5%"
                >
                  Total Break Duration
                </th>
                <th
                  class="headTh"
                  colspan="3"
                  style="text-align: center; background: #ddd"
                >
                  Approve
                </th>
                <th
                  class="headTh"
                  rowspan="2"
                  style="text-align: center; background: #ddd; width: 5%"
                >
                  Auto Approved Shifts
                </th>
                <th class="headTh w-75" rowspan="2" style="background: #ddd">
                  <div class="d-flex flex-column">
                    <span class="text-center">
                      Approved Hours <br />
                      (2 sets if split shift)
                    </span>

                    <input
                      class="align-self-center shiftInput m-1"
                      type="checkbox"
                      [checked]="LockChecked"
                      (change)="checkAllShiftLock(true);lockAllShiftBtn()"
                    />
                    <button
                      type="button"
                      style="font-size: 10px; background-color: #a42138"
                      class="align-self-center btn btn-info w-50"
                      [disabled]="true"
                    >
                      Lock All Approved Shifts
                    </button>
                  </div>
                </th>
                <th
                  class="headTh"
                  rowspan="2"
                  style="text-align: center; background: #ddd"
                >
                  Aggregated hours <br />
                  if Split Shift
                </th>
              </tr>
              <tr>
                <td
                  class="headTd"
                  style="
                    text-align: center;
                    color: green;
                    font-weight: 700;
                    background: #ddd;
                  "
                >
                  Start
                </td>
                <td
                  class="headTd"
                  style="
                    text-align: center;
                    color: #a42138;
                    font-weight: 700;
                    background: #ddd;
                  "
                >
                  End
                </td>
                <td
                  class="headTd"
                  style="
                    text-align: center;
                    color: green;
                    font-weight: 700;
                    background: #ddd;
                  "
                >
                  Start
                </td>
                <td
                  class="headTd"
                  style="
                    text-align: center;
                    color: #a42138;
                    font-weight: 700;
                    background: #ddd;
                  "
                >
                  End
                </td>

                <td
                  class="headTd"
                  style="
                    text-align: center;
                    color: green;
                    font-weight: 700;
                    background: #ddd;
                  "
                >
                  Shift
                  <br />Hours
                </td>
                <td
                  class="headTd"
                  style="
                    text-align: center;
                    color: #a42138;
                    font-weight: 700;
                    background: #ddd;
                  "
                >
                  Clocked Hours
                </td>
                <td
                  class="headTd"
                  style="text-align: center; font-weight: 700; background: #ddd"
                >
                  Neither
                </td>
              </tr>
              <tbody>
                <tr
                  *ngFor="
                    let d of timeSheetData | filter: searchString;
                    let i = index
                  "
                >
                  <td class="headTd">
                    {{ d.userInfo.name }}
                    <p
                      class="m-0 font-weight-normal text-primary"
                      *ngIf="d.shiftDetails.isAssignShift == true"
                    >
                      Assigned Shift
                    </p>
                  </td>
                  <td class="headTd">{{ d.userInfo.staffId }}</td>
                  <td class="headTd p-0 text-center">
                    {{ d.shiftDetails.startTime | date: "dd-MM-yyyy" }}
                  </td>
                  <td class="headTd">
                    <div *ngIf="d.shiftDetails.isExtendedShift && d.shiftDetails.extendedStaff.length > 0; else startTimeBlock">
                      <p>
                        Booked Shift {{ d.shiftDetails.startTime | date: "HH:mm" }}
                        Extended Shift {{ d.shiftDetails.extendedStaff[0].startDateTime | date: "HH:mm" }}
                      </p>
                    </div>
                    <ng-template #startTimeBlock>
                      {{ d.shiftDetails.startTime | date: "HH:mm" }}
                    </ng-template>
                  </td>
                  <td class="headTd">
                    <div *ngIf="d.shiftDetails.isExtendedShift && d.shiftDetails.extendedStaff.length > 0; else endTimeBlock">
                      <p>
                        Booked Shift {{ d.shiftDetails.endTime | date: "HH:mm" }}
                        Extended Shift {{ d.shiftDetails.extendedStaff[0].endDateTime | date: "HH:mm" }}
                      </p>
                    </div>
                    <ng-template #endTimeBlock>
                      {{ d.shiftDetails.endTime | date: "HH:mm" }}
                    </ng-template>
                  </td>
                  <td class="headTd">
                    <p *ngIf="d.attendance" class="m-0">
                      {{ d.attendance.clockInDateTime | date: "HH:mm " }}
                    </p>
                  </td>
                  <td class="headTd">
                    <p
                      class="m-0"
                      *ngIf="d.attendance && d.attendance.clockOutDateTime"
                    >
                      {{ d.attendance.clockOutDateTime | date: "HH:mm " }}
                    </p>
                  </td>
                  <td class="headTd">
                    <div *ngIf="d?.attendance?.breakTime">
                      <div
                        *ngFor="let breakduration of d.attendance.breakTime"
                        class="d-flex"
                      >
                        <p class="m-0">
                          {{ breakduration.duration }}
                        </p>
                        <button
                          *ngIf="this.showBtn"
                          class="btn addBreakMultiple"
                          (click)="toggleBreakDetails(data, i)"
                        >
                          +
                        </button>
                        <button
                          *ngIf="!this.showBtn"
                          class="btn subBreakMultiple"
                          (click)="toggleBreakDetails(data, i)"
                        >
                          -
                        </button>
                      </div>
                      <div>
                        <div *ngIf="isBreakShowHisDet === i">
                          <table>
                            <tbody>
                              <tr>
                                <th class="p-1"></th>
                                <th class="p-1">From</th>
                                <th class="p-1">To</th>
                              </tr>
                              <tr
                                *ngFor="
                                  let breakData of d?.attendance?.breakTime;
                                  let i = index
                                "
                              >
                                <td class="p-1">Break{{ i + 1 }}:</td>
                                <td class="p-1">
                                  {{ breakData.startTime | date: "HH:mm" }}
                                </td>
                                <td class="p-1">
                                  {{ breakData.endTime | date: "HH:mm" }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="headTd">
                    <!-- (click)="()" -->
                    <input
                      class="shiftInput"
                      style="margin: 5px; height: 17px; width: 15px"
                      type="checkbox"
                      [checked]="
                        isKeyAvailable(d.attendance, 'approval') &&
                        d.attendance.approval.shift === true
                      "
                      (click)="createAddShiftHours(d)"
                      (change)="handleApprove(1)"
                    />
                  </td>
                  <td class="headTd">
                    <input
                      [disabled]="
                        !isKeyAvailable(d.attendance, 'clockOutDateTime')
                      "
                      class="shiftInput ml-3"
                      style="margin: 5px; height: 17px; width: 15px"
                      type="checkbox"
                      [checked]="
                        isKeyAvailable(d.attendance, 'approval') &&
                        d.attendance.approval.clocked === true
                      "
                      (click)="createAddShiftHours(d)"
                      (change)="handleApprove(2)"
                    />
                  </td>
                  <td class="headTd">
                    <input
                      class="shiftInput"
                      style="margin: 5px; height: 17px; width: 15px"
                      type="checkbox"
                      [checked]="
                        isKeyAvailable(d.attendance, 'approval') &&
                        d.attendance.approval.neither === true
                      "
                      (click)="createAddShiftHours(d)"
                      (change)="handleApprove(3)"
                    />
                  </td>
                  <td class="headTd text-center">
                    <span
                      class="material-icons text-success"
                      *ngIf="d.attendance?.isAutoApprove"
                    >
                      done
                    </span>
                  </td>
                  <td>
                    <div
                      *ngIf="
                        isKeyAvailable(d.attendance, 'approval') &&
                        d.attendance.approval.approveClockInTime
                      "
                    >
                      <div class="d-flex flex-column justify-content-center">
                        <button
                          class="btn btn-sm apprDet"
                          (click)="toggleApprovalDetails(data, i)"
                        >
                          VIEW APPROVED DETAILS
                        </button>
                        <div *ngIf="isApproveShowHisDet === i">
                          <table class="table">
                            <tbody>
                              <tr>
                                <th class="p-1"></th>
                                <th class="p-1">From</th>
                                <th class="p-1">To</th>
                              </tr>
                              <tr>
                                <td class="p-1">Worked Time</td>
                                <td class="p-1">
                                  {{
                                    d.attendance.approval.approveClockInTime
                                      | date: "HH:mm"
                                  }}
                                </td>
                                <td class="p-1">
                                  {{
                                    d.attendance.approval.approveClockOutTime
                                      | date: "HH:mm"
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td class="p-1">Normal</td>
                                <td class="p-1">
                                  {{
                                    getDuration(d.attendance.approval.duration)
                                  }}
                                </td>
                                <td class="p-1"></td>
                              </tr>
                              <tr>
                                <td class="p-1">Total Work</td>
                                <td class="p-1">
                                  {{
                                    getDuration(d.attendance.approval.duration)
                                  }}
                                </td>
                                <td class="p-1"></td>
                              </tr>
                              <tr>
                                <td class="p-1">Total Break Duration</td>
                                <td class="p-1">
                                  {{ d.attendance.totalBreakDuration }}
                                  mins
                                </td>
                                <td class="p-1"></td>
                              </tr>
                              <tr>
                                <td class="p-1">Remarks</td>
                                <td class="p-1">
                                  {{ d.attendance.approval.neitherMessage }}
                                </td>
                                <td class="p-1"></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <!-- <div> -->

                      <!-- formControlName="startTime" -->
                      <!-- </div> -->
                      <div
                        class="align-items-center d-flex justify-content-between"
                      >
                        <div
                          class="form-check"
                          *ngIf="
                            this.checkShift && d.attendance.IsLock == false
                          "
                        >
                          <input
                            class="form-check-input position-static checkBox-lg"
                            type="checkbox"
                            [checked]="this.checkShift"
                          />
                        </div>

                        <div *ngIf="d.attendance.IsLock === true">
                          <div
                            class="align-items-center mt-3 d-flex justify-content-start"
                          >
                            <span class="fa fa-lock fa-2x"></span>
                            <button class="btn bg-theme ml-2">
                              Shift Locked
                            </button>
                          </div>
                          <p class="font-weight-lighter m-0">
                            * You Have Edit Privilege by Ticking Checkboxes
                          </p>
                        </div>
                        <div
                          class="align-items-center d-flex justify-content-center mt-1"
                          *ngIf="!d.attendance.IsLock === true"
                        >
                          <span class="fa fa-unlock fa-2x"></span>
                          <button
                            class="btn lockBtn ml-1"
                            (click)="lockFunction(d)"
                          >
                            Lock
                          </button>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="headTd"></td>
                </tr>
              </tbody>
            </table>
            <div class="bg-white text-center p-3" *ngIf="timeSheetData?.length === 0">
              No Data Available
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #AddShiftHours let-c="close" let-d="dismiss">
  <div>
    <div class="modal-content">
      <div class="modal-body">
        <h4 class="modal-title m-3" *ngIf="this.uniqueIdShift === 1">
          SHIFT HOURS :
        </h4>
        <h4 class="modal-title m-3" *ngIf="this.uniqueIdShift === 2">
          CLOCKED HOURS :
        </h4>
        <h4 class="modal-title m-3" *ngIf="this.uniqueIdShift === 3">
          EDIT WORKED HOURS:
        </h4>
        <div>
          <form [formGroup]="shiftsForm">
            <div class="row" *ngIf="this.uniqueIdShift === 1">
              <div class="col-md-6">
                <label class="font-weight-bold">Start :</label>
                <input
                  disabled
                  type="text"
                  class="form-control disabled"
                  formControlName="startTime"
                />
              </div>
              <div class="col-md-6">
                <label class="font-weight-bold">End :</label>
                <input
                  disabled
                  type="text"
                  class="form-control disabled"
                  formControlName="endTime"
                />
              </div>
            </div>
            <div class="row" *ngIf="this.uniqueIdShift === 2">
              <div class="col-md-6">
                <label class="font-weight-bold">Start :</label>
                <input
                  disabled
                  type="text"
                  class="form-control disabled"
                  formControlName="clockedStartTime"
                />
              </div>
              <div class="col-md-6">
                <label class="font-weight-bold">End :</label>
                <input
                  disabled
                  type="text"
                  class="form-control disabled"
                  formControlName="clockedEndTime"
                />
              </div>
            </div>
            <div class="row" *ngIf="this.uniqueIdShift === 3">
              <div class="col-md-6">
                <label class="font-weight-bold">Start :</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="neitherstartTime"
                />
              </div>
              <div class="col-md-6">
                <label class="font-weight-bold">End :</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="neitherendTime"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 mt-4 d-flex align-items-center">
                <label class="font-weight-bold breakText">BREAKS</label>
                <label class="font-weight-bold">- NotTaken</label>
              </div>
            </div>

            <div class="row" *ngIf="isShow">
              <div class="col-md-12 d-flex">
                <label class="font-weight-bold">Add Break Time </label>
                <button class="addBreak ml-2" (click)="toggleDisplay()">
                  +
                </button>
              </div>
            </div>

            <div *ngIf="!isShow">
              <div class="row">
                <h4 class="modal-title ml-3">ADD BREAK TIME: (Optional)</h4>
              </div>

              <table class="breakTimeTable">
                <tr>
                  <th></th>
                  <th></th>
                  <th>Start:</th>
                  <th></th>
                  <th>End:</th>
                  <th></th>
                </tr>
                <tr>
                  <td><strong>BREAKS:</strong></td>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="startDate"
                    />
                  </td>
                  <td class="tdSize">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="inputstartTime"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="endDate"
                    />
                  </td>
                  <td class="tdSize">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="inputendTime"
                    />
                  </td>
                  <td>
                    <button
                      type="button"
                      class="btn btn-success"
                      (click)="breakTime()"
                    >
                      ADD
                    </button>
                  </td>
                </tr>
              </table>
            </div>
            <div class="row m-3">
              <div class="col-md-12 d-flex">
                <label class="font-weight-bold mr-3">REMARKS<span *ngIf="this.uniqueIdShift === 3" style="font-size: 1.2em; color: red;">*</span></label>
                <input
                  type="text"
                  formControlName="neitherRemarks"
                  class="form-control not-allowed"
                />
              </div>
            </div>
          </form>
        </div>
        <div class="row d-flex justify-content-end m-3">
          <button type="button" class="btn btn-success" (click)="approvalBtn()">
            Submit
          </button>
          <button
            type="button"
            class="btn btn-danger ml-3"
            (click)="modelClose()"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
