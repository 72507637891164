<div class="card">
  <div class="card-header flexi-header">
    <h3>ALL POSTS</h3>
  </div>
  <div class="card-body">
    <div class="card table-card">
      <div class="card-body flexi-body">
        <div class="flex-column">
          <div class="d-flex justify-content-between mt-2 mb-3">
            <select class="custom-select" style="width: auto" name="pageSize">
              <option [ngValue]="10">10</option>
              <option [ngValue]="20">20</option>
              <option [ngValue]="30">30</option>
              <option [ngValue]="40">40</option>
              <option [ngValue]="50">50</option>
            </select>
            <div class="form-group form-inline mb-0">
              <input
                class="form-control ml-2"
                type="search"
                name="searchTerm"
                placeholder="Search here"
              />
            </div>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" sortable="Tier 1">USER</th>
                  <th scope="col" sortable="Tier 2">DATE AND TIME</th>
                  <th scope="col" sortable="Tier 3">DESCRIPTION</th>

                  <th scope="col" sortable="Tier 4">POST TYPE</th>

                  <th scope="col">ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>

                  <td></td>

                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="d-flex justify-content-between">
          <p>Total records</p>
          <ngb-pagination [maxSize]="5"> </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
