<div class="card" *ngIf="!isBuUnitEdited">
  <div class="card-header flexi-header p-4">
    <h3>BUSINESS UNIT</h3>
  </div>
  <div class="card-body">
    <div class="card table-card">
      <div class="card-body flexi-body">
        <div class="flex-column">
          <div class="d-flex justify-content-between mt-2 mb-3">
            <select class="custom-select" style="width: auto" name="pageSize" (change)="onLimitChangeBussinessUnit()"
              [(ngModel)]="bussinesUnitPTable.limit">
              <option [ngValue]="10">10</option>
              <option [ngValue]="20">20</option>
              <option [ngValue]="30">30</option>
              <option [ngValue]="40">40</option>
              <option [ngValue]="50">50</option>
            </select>
            <div class="form-group form-inline mb-0">
              <input class="form-control ml-2" type="search" name="searchTerm" (search)="loadBussinessUnit()"
                (keyup)="BussinessunitsearchInput.next($event)" placeholder="Search here"
                [(ngModel)]="bussinesUnitPTable.search" />
            </div>
          </div>

          <div class="table-responsive">
            <table class="table" *ngIf="!isTableLoading">
              <thead>
                <tr class="table-header">
                  <th scope="col" sortable="Tier 1" (sort)="onSortBussinessUnit($event)">
                    TIER 1
                  </th>
                  <th scope="col" sortable="Tier 2" (sort)="onSortBussinessUnit($event)">
                    TIER 2
                  </th>
                  <th scope="col" sortable="Tier 3" (sort)="onSortBussinessUnit($event)">
                    TIER 3
                  </th>

                  <th scope="col" sortable="Tier 4" (sort)="onSortBussinessUnit($event)">
                    TIER 4
                  </th>
                  <th scope="col" sortable="Status" (sort)="onSortBussinessUnit($event)">
                    STATUS
                  </th>
                  <th scope="col">ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of bussinesUnitPTable.data">
                  <td>
                    {{ item.sectionId?.departmentId?.companyId?.name }}
                  </td>

                  <td>{{ item?.sectionId?.departmentId?.name }}</td>

                  <td>{{ item.sectionId?.name }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.status == 1 ? 'Active' :'InActive' }}</td>
                  <td>
                    <button class="btn btn-add p-2 m-1" (click)="onEditBuUnit(item)">Edit</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <app-skliton-loading-table [numberOfCols]="6" [isTableLoading]="isTableLoading"></app-skliton-loading-table>
        </div>
      </div>
      <div class="card-footer">
        <div class="d-flex justify-content-between">
          <p>Total records {{ bussinesUnitPTable.total }}</p>
          <ngb-pagination [collectionSize]="bussinesUnitPTable.total" [(page)]="bussinesUnitPTable.page" [maxSize]="5"
            (pageChange)="onPageChangeBussinessUnit($event)" [pageSize]="bussinesUnitPTable.limit">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="card" *ngIf="isBuUnitEdited">
  <div class="card-header flexi-header">
    <h3>EDIT BUSINESS UNIT DETAILS</h3>
  </div>
  <div class="card-body">
    <form [formGroup]="bussinessUnitEditForm">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <label class="font-weight-bold col-md-3 red-heading"> Change Status</label>
          </div>
          <div class="row">
            <div class="form-group col-md-2 pr-0">
              <input type="radio" [value]="1"  id="active" formControlName="status" (change)="changeRadio($event)" />
              <label for="std-row" class="radio-inline ml-2" >Active</label>
            </div>
            <div class="form-group col-md-2 pr-0">
              <input type="radio" [value]="2" id="inactive"
                formControlName="status" />
              <label for="custom-row" class="radio-inline ml-2">Inactive</label>
            </div>
          </div>
        </div>

      </div>
      <div class="row">
        <div class="col-md-2 mt-3">
          <label class="font-weight-bold red-heading"> Business unit</label>
        </div>
        <div class="red-container">
          {{bussinessUnitEditForm.get('buUnit').value}}

        </div>

      </div>
      <div class="row bu-container">
        <div class="col">
          <button class="btn btn-add p-2 m-1" [disabled]="!bussinessUnitEditForm.valid" (click)="onUpdateBuUnit()">
            Update business unit
          </button>

          <button class="btn btn-clear p-2 m-1" (click)="onClearBuUnitEdit()">Back</button>
        </div>
      </div>
    </form>

  </div>
</div>
