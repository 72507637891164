import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { HttpClient } from '@angular/common/http';
import { Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { element } from 'protractor';
import { debounceTime, finalize } from 'rxjs/operators';
import { NewsEventsService } from 'src/app/users/service/manage-news & events/news-events.service';
import { UsersService } from 'src/app/users/service/user-service/users.service';

import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-edit-events',
  templateUrl: './create-edit-events.component.html',
  styleUrls: ['./create-edit-events.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
})
export class CreateEditEventsComponent implements OnInit {
  closeResult = '';
  listingType = [
    { name: 'No Photo', value: 1 },
    { name: 'Small Photo', value: 2 },
    { name: 'Large Photo', value: 3 }
  ];
  isIconLoading: boolean = false;
  IconUrl: string;
  departmentDropDown;

  @ViewChild('AddDepartment', { static: true })
  AddDepartment: TemplateRef<any>;
  @ViewChild('AddImageCropper', { static: true })
  AddImageCropper: TemplateRef<any>;
  @ViewChild('stepper') stepper: MatStepper;
  imageCategory: string;
  sessionsForm: FormGroup;
  adminstrationFormGroup: FormGroup;
  eventBoardFormGroup: FormGroup;
  publicationFormGroup: FormGroup;
  contentFormGroup: FormGroup;
  eventFormGroup: FormGroup;
  CustomFieldDropDownLoading: boolean = false;
  fieldNeedsToUpdate = [];
  @Input() eventId: string = null;
  isEditable = false;
  isLinear = false;
  isParticipentsEnabled = false;
  public invoiceForm: FormGroup;

  channelArray: any;
  categoriesArray: any;
  @Output() overViewEvent = new EventEmitter<string>();
  CustomFieldPTable;
  channel: any;
  category: any;
  userName: string;
  sessionRowsLen: any;
  editEvent: any;
  sessionChange: any;
  sessionchangeArr = {};
  eventFormGroupErr: boolean;
  contentFormGroupErr: boolean;
  publicationFormGroupErr: boolean;
  sessionsFormErr: boolean;
  eventBoardFormGroupErr: boolean;
  adminstrationFormGroupErr: boolean;
  loggedUser: any;
  eventLoader: boolean;
  isSessionDisabled = false;
  baseUrl = environment.imageUrl;

  constructor(
    public http: HttpClient,
    public modelService: NgbModal,
    private _formBuilder: FormBuilder,
    private userService: UsersService,
    public newsService: NewsEventsService,
    public tostService: ToastrService
  ) {
    this.CustomFieldPTable = {
      sortBy: 'desc',
      sortWith: 'createdAt',

      data: [],
      page: 1,
      total: 0,
      limit: 8,

      filter: {
        status: [1],

        fields: ['name'],
      },
      search: '',
    };
    this.userName = localStorage.getItem('flexistaffId');
  }

  public options: Object = {
    key: environment.froalaEditorKey,
    videoResponsive: true,
    videoInsertButtons: ['videoBack', '|', 'videoByURL'],
    listAdvancedTypes: true,
    toolbarButtons: [
      'bold',
      'italic',
      'underline',
      'outdent',
      'indent',
      'insertHorizontalRule',
    ],
  };
  public otheroptions: Object = {
    key: environment.froalaEditorKey,
    videoResponsive: true,
    listAdvancedTypes: true,
    toolbarButtons: [
      'undo',
      'redo',
      'bold',
      'italic',
      'underline',
      'strikeThrough',
      'subscript',
      'superscript',
      'fontFamily',
      'fontSize',
      'color',
      'formatBlock',
      'blockStyle',
      'inlineStyle',
      'align',
      'insertOrderedList',
      'insertUnorderedList',
      'outdent',
      'indent',
      'selectAll',
      'createLink',
      'insertImage',
      'insertVideo',
      'table',
      'undo',
      'redo',
      'html',
      'save',
      'insertHorizontalRule',
      'uploadFile',
      'removeFormat',
      'fullscreen',
    ],
    videoUploadURL: `${environment.baseUrl}/notification/uploadContentFiles/`,
    requestHeaders: {
      Authorization: localStorage.getItem('flexiusersToken'),
    },
    requestWithCORS: false,
    // Set the image upload URL.
    imageUploadURL: `${environment.baseUrl}/post/uploadContentFiles`,

    imageUploadParams: { crossorigin: 'anonymous' },

    // Set request type.
    imageUploadMethod: 'POST',

    // Allow to upload PNG and JPG.
    imageAllowedTypes: ['jpeg', 'jpg', 'png'],
    // Set request type.
    videoUploadMethod: 'POST',

    // Set max video size to 50MB.
    videoMaxSize: 50 * 1024 * 1024,

    // Allow to upload MP4, WEBM and OGG
    videoAllowedTypes: ['webm', 'jpg', 'ogg', 'mp4', 'mov', 'MKV'],
    events: {
      'video.beforeUpload': function (videos) {
        // Return false if you want to stop the video upload.
      },
      'video.uploaded': function (response) {
        // Video was uploaded to the server.
      },
      'video.inserted': function ($img, response) {
        // Video was inserted in the editor.
      },
      'video.replaced': function ($img, response) {
        // Video was replaced in the editor.
      },
      'video.error': function (error, response) { },
      'image.beforeUpload': function (images) {
        // Return false if you want to stop the image upload.
      },
      'image.uploaded': function (response) {
        // Image was uploaded to the server.
      },
      'image.inserted': function ($img, response) {
        // Image was inserted in the editor.
      },
      'image.replaced': function ($img, response) {
        // Image was replaced in the editor.
      },
    },
  };

  title = 'my-image-cropper';
  @ViewChild('myInput') myInputVariable!: ElementRef;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  croppedFilePath: any = "";
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.croppedFilePath = event;
  }

  imageLoaded() {
    this.showCropper = true;
  }
  cropperReady(sourceImageDimensions: Dimensions) {

  }

  loadImageFailed() { }

  clear(isStatus) {
    this.modelService.dismissAll();
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
    this.croppedImage = '';
    this.imageChangedEvent = '';
   if (!isStatus) this.modelService.open(this.AddImageCropper, {
      windowClass: 'modal-ui-fix.center-align.xlModal', size: 'lg',
    });
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV,
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation,
    };
  }

  onDeleteIcon(key) {
    this.IconUrl = null;
    key === 'teaser' ? (this.IconUrl = null) : (this.IconUrl = null);
   if (key === 'contentTeaser') {
       this.contentFormGroup.get('image').patchValue('')
      }
     else if (key === 'eventTeaser') {
       this.eventFormGroup.get('image').patchValue('');
      }
      else {
        this.eventBoardFormGroup.get('eventWallLogoImage').patchValue('');
      }
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  iconUpload(type) {

    const file = this.dataURLtoFile(this.croppedImage, "croppedImg.png");

    const fileUpload = this.newsService
      .uploadFile(file)
      .pipe(
        finalize(() => {
          fileUpload.unsubscribe();
        })
      )
      .subscribe(
        (data: any) => {
          switch (type) {
            case 'eventTeaser':
              this.eventFormGroup.patchValue({
                image: `${environment.imageUrl}${data.data.path}`,
              });
              this.tostService.success('Image Uploaded Successfully');
              break;
            case 'contentTeaser':
              this.contentFormGroup.patchValue({
                image: `${environment.imageUrl}${data.data.path}`,
              });
              this.tostService.success('Image Uploaded Successfully');
              break;
            case 'eventBoard':
              this.eventBoardFormGroup.patchValue({
                eventWallLogoImage: `${environment.imageUrl}${data.data.path}`,
              });
              this.tostService.success('Image Uploaded Successfully');
              break;
          }
          this.modelService.dismissAll();
          this.clear(true);
        },
        (error) => {
          this.isIconLoading = false;
        }
      );
  }

  addDepartment() {
    this.editEvent = {
      data: {
        content: {
          ...this.contentFormGroup.value,
        },
        teaser: {
          ...this.eventFormGroup.value,
        },
        publish: {
          ...this.publicationFormGroup.value,
        },
        eventBoard: {
          ...this.eventBoardFormGroup.value,
        },
        session: {
          ...this.sessionsForm.value,
        },
      },
    };

    this.modelService.open(this.AddDepartment, {
      windowClass: 'modal-ui-fix.center-align',
    });
  }

  ngOnInit() {
    this.loadCustomField();
    this.getchannels();
    this.eventFormGroup = this._formBuilder.group({
      title: ['', Validators.required],
      image: [''],
      listingType: [0, Validators.required],
    });
    this.contentFormGroup = this._formBuilder.group({
      title: ['', Validators.required],
      image: '',
      isTeaserImage: null,
      content: ['', Validators.required],
      organizerName: ['', Validators.required],
      eventType: ['multi', Validators.required],
      address: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: [''],
    });
    this.publicationFormGroup = this._formBuilder.group({
      channelId: [null, Validators.required],
      categoryId: [null, Validators.required],
      isRSVPRequired: ['false', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
    });
    this.eventBoardFormGroup = this._formBuilder.group({
      isEventWallRequired: false,
      eventWallLogoImage: '',
      wallName: '',
      startDate: '',
      endDate: '',
    });
    this.sessionsForm = this._formBuilder.group({
      isLimitRequired: [false, Validators.required],
      isLimitRsvp: [false, Validators.required],
      totalAttendanceTaking: false,
      isAttendanceRequired: [false],
      maxNoRSVP: 1,
      Rows: this._formBuilder.array([this.initRows()]),
    });
    this.adminstrationFormGroup = this._formBuilder.group({
      admin: this._formBuilder.array([]),
    });
    this.eventId && this.getEventsEdit(this.eventId);
    this.eventId && this.isLinear != this.isLinear;

    this.validateEventForm();

    this.getuser();
  }

  getuser() {
    if (!this.eventId) {
      this.userService.getUserProfile().subscribe((res) => {
        this.loggedUser = res.data;

        this.publicationFormGroup.controls.isRSVPRequired.valueChanges.subscribe(
          (res) => {
            if (
              this.publicationFormGroup.controls.isRSVPRequired.value == 'true'
            ) {
              const Control = this.LoginFieldsControl;
              this.clearFormArray(Control);

              let field = this._formBuilder.group({
                string: [
                  this.loggedUser.name,
                  Validators.compose([Validators.required]),
                ],
                _id: [
                  this.loggedUser._id,
                  Validators.compose([Validators.required]),
                ],
              });
              Control.push(field);
            } else {
              const Control = this.LoginFieldsControl;
              this.clearFormArray(Control);
            }
          }
        );
      });
    }
  }

  sameTeaserImg() {
    if (this.contentFormGroup.controls.isTeaserImage.value) {
      this.contentFormGroup.patchValue({
        image: this.eventFormGroup.get('image').value,
      });
    } else if (this.contentFormGroup.controls.isTeaserImage.value == false) {
      this.contentFormGroup.patchValue({
        image: '',
      });
    }
  }

  getformdata() {
    if (this.contentFormGroup.controls.isTeaserImage.value == true) {
      this.contentFormGroup.patchValue({
        isTeaserImage: true,
        image: this.eventBoardFormGroup.controls.eventWallLogoImage.value,
      });
    } else {
      this.contentFormGroup.patchValue({
        isTeaserImage: false,
      });
    }
  }

  validateMaxRsvp(e) {
    this.sessionRowsLen = this.sessionsForm.controls.Rows.value.length;

    if (this.sessionsForm.get('isLimitRsvp').value == false) {
      this.sessionsForm.patchValue({
        maxNoRSVP: 9999,
      });
    } else if (this.sessionsForm.get('isLimitRsvp').value == true) {
      this.setValid();
    }
  }

  setValid() {
    this.sessionRowsLen = this.sessionsForm.controls.Rows.value.length;
    if (
      (this.sessionsForm.get('maxNoRSVP').value < this.sessionRowsLen ||
        this.sessionsForm.get('maxNoRSVP').value == this.sessionRowsLen) &&
      this.sessionsForm.get('maxNoRSVP').value != 0
    ) {
      this.sessionsForm.get('maxNoRSVP').setErrors(null);
    } else {
      this.sessionsForm.get('maxNoRSVP').setErrors({ incorrect: true });
      this.tostService.error(
        'The value has to be min 1 but should not exceed the total number of sessions',
        '',
        { timeOut: 5000 }
      );
    }
  }

  move() {
    if (!this.eventId && !this.minPublicationTimeValidator()) {
      return;
    }
    if (this.publicationFormGroup.controls.isRSVPRequired.value == 'false') {
      this.stepper.selectedIndex = 4;
      this.sessionsForm.patchValue({
        isLimitRequired: false,
        isLimitRsvp: false,
        totalAttendanceTaking: 0,
        isAttendanceRequired: false,
        maxNoRSVP: 0,
        Rows: [],
      });
    } else {
      this.stepper.next();
    }
  }

  moveBackEvent() {
    if (this.publicationFormGroup.controls.isRSVPRequired.value == 'false') {
      this.stepper.selectedIndex = 2;
      this.sessionsForm.patchValue({
        isLimitRequired: false,
        isLimitRsvp: false,
        totalAttendanceTaking: 0,
        isAttendanceRequired: false,
        maxNoRSVP: 0,
        Rows: [],
      });
    } else {
      this.stepper.previous();
    }
  }
  validateEventForm() {
    this.eventBoardFormGroup
      .get('isEventWallRequired')
      .valueChanges.subscribe((value) => {
        if (value == true) {
          this.eventBoardFormGroup
            .get('wallName')
            .setValidators(Validators.required);
          this.eventBoardFormGroup
            .get('startDate')
            .setValidators(Validators.required);
          this.eventBoardFormGroup
            .get('endDate')
            .setValidators(Validators.required);
        } else if (value == false) {
          this.eventBoardFormGroup.get('wallName').clearValidators();
          this.eventBoardFormGroup.get('startDate').clearValidators();
          this.eventBoardFormGroup.get('endDate').clearValidators();
          this.eventBoardFormGroup.patchValue({
            // isEventWallRequired: false,
            wallName: '',
            startDate: '',
            endDate: '',
          });
        }
      });
  }

  eventFormGroupError() {
    this.eventFormGroup.valueChanges.subscribe((res) => {
      if (!this.eventFormGroup.valid) {
        this.eventFormGroupErr = true;
      } else {
        this.eventFormGroupErr = false;
      }
    });
    if (!this.eventFormGroup.valid) {
      this.eventFormGroupErr = true;
    } else {
      this.eventFormGroupErr = false;
    }
  }
  contentFormGroupError() {
    this.contentFormGroup.valueChanges.subscribe((res) => {
      if (!this.contentFormGroup.valid) {
        this.contentFormGroupErr = true;
      } else {
        this.contentFormGroupErr = false;
      }
    });
    if (!this.contentFormGroup.valid) {
      this.contentFormGroupErr = true;
    } else {
      this.contentFormGroupErr = false;
    }
  }
  publicationFormGroupError() {
    this.publicationFormGroup.valueChanges.subscribe((res) => {
      if (!this.publicationFormGroup.valid) {
        this.publicationFormGroupErr = true;
      } else {
        this.publicationFormGroupErr = false;
      }
    });
    if (!this.publicationFormGroup.valid) {
      this.publicationFormGroupErr = true;
    } else {
      this.publicationFormGroupErr = false;
    }
  }
  sessionsFormError() {
    this.sessionsForm.valueChanges.subscribe((res) => {
      if (!this.sessionsForm.valid) {
        this.sessionsFormErr = true;
      } else {
        this.sessionsFormErr = false;
      }
    });
    if (!this.sessionsForm.valid) {
      this.sessionsFormErr = true;
    } else {
      this.sessionsFormErr = false;
    }
  }
  eventBoardFormGroupError() {
    this.eventBoardFormGroup.valueChanges.subscribe((res) => {
      if (
        this.eventBoardFormGroup.controls.isEventWallRequired.value ===
        'true' &&
        !this.eventBoardFormGroup.valid
      ) {
        this.eventBoardFormGroupErr = true;
      } else {
        this.eventBoardFormGroupErr = false;
      }
    });
    if (
      this.eventBoardFormGroup.controls.isEventWallRequired.value === 'true' &&
      !this.eventBoardFormGroup.valid
    ) {
      this.eventBoardFormGroupErr = true;
    } else {
      this.eventBoardFormGroupErr = false;
    }
  }
  adminstrationFormGroupError() {
    this.adminstrationFormGroup.valueChanges.subscribe((res) => {
      if (!this.adminstrationFormGroup.valid) {
        this.adminstrationFormGroupErr = true;
      } else {
        this.adminstrationFormGroupErr = false;
      }
    });
    if (!this.adminstrationFormGroup.valid) {
      this.adminstrationFormGroupErr = true;
    } else {
      this.adminstrationFormGroupErr = false;
    }
  }

  listingTypeChange() {
    if (this.eventFormGroup.get('listingType').value == 1) {
      this.eventFormGroup.get('image').setValue(null);
      this.eventFormGroup.get('image').clearValidators();
    } else {
      this.eventFormGroup.get('image').setValidators(Validators.required);
    }
    this.eventFormGroup.get('image').updateValueAndValidity();
  }

  getAttNo() {
    this.sessionsForm
      .get('isAttendanceRequired')
      .valueChanges.subscribe((val) => {
        if (val == true) {
          this.sessionsForm
            .get('isAttendanceRequired')
            .setValidators(Validators.required);
        } else if (val == false) {
          this.sessionsForm.get('isAttendanceRequired').clearValidators();
        }
      });
  }

  getchannels() {
    const sub = this.newsService
      .getAllChannels()

      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe((res: any) => {
        this.channelArray = res.data;
      });
  }
  getCategories(channel) {
    this.channel = channel;

    this.publicationFormGroup.patchValue({
      categoryId: null,
    });
    if (channel._id) {
      const sub = this.newsService
        .getAllCategories(channel._id)

        .pipe(
          finalize(() => {
            sub.unsubscribe();
          })
        )
        .subscribe((res: any) => {
          this.categoriesArray = res.data;
        });
    }
  }
  setCategories(channel) {
    this.category = channel;
  }

  getEventsEdit(id: string) {
    const sub = this.newsService
      .getSingleNewsEvents(id)
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe(
        ({
          data: { content, publish, eventBoard, session, teaser, admin },
        }) => {
          this.editEvent = {
            data: { content, publish, eventBoard, session, teaser, admin },
          };

          this.getCategories(publish.channelId);
          this.channel = publish.channelId;
          this.category = publish.categoryId;
          this.isSessionDisabled = publish.isRSVPRequired === false ? true : false;
          this.eventFormGroup.patchValue({
            title: teaser.title,
            image: teaser.image,
            listingType: teaser.listingType,
          });
          this.contentFormGroup.patchValue({
            title: content.title,
            image: content.image,
            isTeaserImage: content.isTeaserImage,
            content: content.content,
            organizerName: content.organizerName,
            eventType: content.eventType,
            address: content.address,
            startDate: content.startDate,
            endDate: content.endDate,
          });
          this.publicationFormGroup.patchValue({
            channelId: publish.channelId._id,
            categoryId: publish.categoryId._id,
            isRSVPRequired: publish.isRSVPRequired.toString(),
            startDate: publish.startDate,
            endDate: publish.endDate,
          });
          this.eventBoardFormGroup.patchValue({
            isEventWallRequired: eventBoard.isEventWallRequired,
            eventWallLogoImage: eventBoard.eventWallLogoImage,
            wallName: eventBoard.wallName,
            startDate: eventBoard.startDate,
            endDate: eventBoard.endDate,
          });
          this.sessionsForm.patchValue({
            isLimitRequired: session.isLimitRequired,
            isLimitRsvp: session.isLimitRsvp,
            totalAttendanceTaking: session.totalAttendanceTaking,
            isAttendanceRequired: session.isAttendanceRequired,
            maxNoRSVP: session.maxNoRSVP,
            Rows: session.Rows,
          });
          const SessionControl = this.formArr;
          this.clearFormArray(SessionControl);
          session.Rows.forEach((element) => {
            let field = this._formBuilder.group({
              startDate: [element.startDate, Validators.required],
              endDate: [element.endDate, Validators.required],
              totalParticipantPerSession: [element.totalParticipantPerSession],
              location: [element.location, Validators.required],
            });
            SessionControl.push(field);
          });
          const Control = this.LoginFieldsControl;

          this.clearFormArray(Control);
          admin.forEach((element) => {
            let field = this._formBuilder.group({
              string: [element.name, Validators.compose([Validators.required])],
              _id: [element._id, Validators.compose([Validators.required])],
            });
            Control.push(field);
          });

          this.getAttNo();

          if (!eventBoard.wallName) {
            this.eventBoardFormGroup.patchValue({
              isEventWallRequired: false,
            });
          }
          // else {
          //   this.eventBoardFormGroup.patchValue({
          //     isEventWallRequired: true,
          //   });
          // }

          if (session.maxNoRSVP > 0 && session.maxNoRSVP != 9999) {
            this.sessionsForm.patchValue({
              isLimitRsvp: true,
            });
          } else {
            this.sessionsForm.patchValue({
              isLimitRsvp: false,
            });
          }

          this.sessionChange = this.sessionsForm
            .get('Rows')
            .valueChanges.pipe(debounceTime(500))
            .subscribe((val) => {
              this.sessionchangeArr = {};
              const ss = this.sessionsForm.controls.Rows as FormArray;

              ss.controls.forEach((val, indx) => {
                let vs = val as FormGroup;

                if (!vs.pristine) {
                  this.sessionchangeArr[indx] = session.Rows[indx];
                }
              });
            });
        },
        (error) => { }
      );
  }

  onSeachCustomField(value) {
    this.CustomFieldPTable.limit = 10;
    this.CustomFieldPTable.search = value.term;
    this.loadCustomField();
  }
  loadMoreCustomFieldItems(value) {
    this.CustomFieldPTable.limit = this.CustomFieldPTable.limit + 10;

    this.loadCustomField();
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  };
  onClearCustomFieldDropdown(value) {
    if (value === undefined) {
      this.adminstrationFormGroup.patchValue({
        loginFields: [],
      });
      this.CustomFieldPTable.search = '';
      this.loadCustomField();

      this.fieldNeedsToUpdate = [];
      this.CustomFieldPTable.data = [];
    }
  }

  loadCustomField() {
    const { data, loading, total, ...rest } = this.CustomFieldPTable;
    const subscribe = this.userService
      .getAllUsers(rest)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const { count, data } = response.data;
          this.CustomFieldPTable.data = data;
          this.fieldNeedsToUpdate = data;
          this.CustomFieldPTable.total = count;
        },
        (error) => {
          this.CustomFieldPTable.data = [];
          this.CustomFieldPTable.total = 0;
          // this.tostService.error('No list found');
        }
      );
  }

  get formArr(): FormArray {
    return this.sessionsForm.get('Rows') as FormArray;
  }

  get LoginFieldsControl(): FormArray {
    return this.adminstrationFormGroup.get('admin') as FormArray;
  }

  initRows() {
    return this._formBuilder.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      totalParticipantPerSession: [''],
      location: ['', Validators.required],
    });
  }

  async addLoginFields(value) {
    const Control = this.LoginFieldsControl;
    const index = await Control.value.findIndex((x) => x._id === value._id);

    if (index > -1) {
      // this.tostService.error(`${value.fieldName} is already added`);
    } else {
      let field;
      if (this.publicationFormGroup.controls.isRSVPRequired.value == 'false') {
        field = this._formBuilder.group({
          string: [value.name],
          _id: [value._id],
        });
      } else {
        field = this._formBuilder.group({
          string: [value.name, [Validators.required]],
          _id: [value._id, [Validators.required]],
        });
      }
      // let field = this._formBuilder.group({
      //   string: [value.name, [Validators.required]],
      //   _id: [value._id, [Validators.required]],
      // });
      Control.push(field);
    }
  }

  deleteSelectedFields(index) {
    const Control = this.LoginFieldsControl;
    Control.removeAt(index);
  }

  addNewRow() {
    this.formArr.push(this.initRows());
  }

  addMoreFelds() {
    const control = this.formArr;
    control.valid && control.push(this.initRows());
  }

  addMoreFelds1() {
    this.isParticipentsEnabled = !this.isParticipentsEnabled;
  }

  async deleteRow(index: number) {
    const id = this.editEvent.data.session.Rows[index]?._id;
    await Swal.fire({
      title: 'Cancel Session!',
      text: 'Are you sure, you want to cancel this session??',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: `Yes`,
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.newsService
          .cancelSessions(id)
          .pipe(
            finalize(() => {
              this.eventLoader = false;
            })
          )
          .subscribe(
            (response: any) => {
              this.tostService.success('Session Cancelled Successfully');
            },
            (error) => {
              this.tostService.error('Error found');
            }
          );
        this.formArr.removeAt(index);
      }
    });
  }

  async onSubmitEvents(i) {
    if (!this.adminstrationFormGroup.valid) {
      this.adminstrationFormGroupErr = true;
      return;
    }
    if (!this.eventId && !this.minPublicationTimeValidator()) {
      return;
    }

    if (this.sessionChange && Object.keys(this.sessionchangeArr).length) {
      this.sessionChange.unsubscribe();

      const sessformArr = this.sessionsForm.controls.Rows as FormArray;
      let sessCount = sessformArr.length;

      sessformArr.controls.forEach((el, index) => {
        if (Object.keys(this.sessionchangeArr).includes(index.toString())) {
          if (this.sessionchangeArr[index] != undefined) {
            let form: FormGroup = el as FormGroup;
            form.addControl(
              '_id',
              new FormControl(this.sessionchangeArr[index]._id)
            );
          } else {
            let form: FormGroup = el as FormGroup;
            form.addControl('_id', new FormControl(''));
          }
        }
      });
      for (var j = sessCount; j >= Math.abs(sessCount); j--) {
        sessCount = sessCount - 1;
        if (!sessformArr.controls[j]?.value?.hasOwnProperty('_id')) {
          this.formArr.removeAt(j);
        }
      }

      const sessformArr2 = this.sessionsForm.controls.Rows as FormArray;
      sessCount = sessformArr.length;

      sessformArr2.controls.forEach((el, index) => {
        let form: FormGroup = el as FormGroup;
        if (el.get('_id').value == '') {
          form.removeControl('_id');
        }
      });
    }

    if (!Object.keys(this.sessionchangeArr).length && this.eventId) {
      this.formArr.clear();
    }

    const Control = this.LoginFieldsControl;
    const { channelId, categoryId } = this.publicationFormGroup.value;
    const newData = {
      channelId: channelId,
      categoryId: categoryId,
      eventCreation: !!this.eventBoardFormGroup.value.isEventWallRequired ? 1 : 0,
      postType: 'event',
      teaser: {
        title: this.eventFormGroup.value.title,
        listingType: this.eventFormGroup.value.listingType,
      },
      content: {
        title: this.contentFormGroup.value.title,
        isTeaserImage: this.contentFormGroup.value.isTeaserImage,
        content: this.contentFormGroup.value.content,
        eventWallStartDate: this.eventBoardFormGroup.value.startDate,
        eventWallEndDate: this.eventBoardFormGroup.value.endDate,
      },
      wallTitle: {
        title: this.eventBoardFormGroup.value.wallName,
        /*isTeaserImageForWall:
          this.eventBoardFormGroup.value.isEventWallRequired,*/
      },
      eventDetails: {
        organizerName: this.contentFormGroup.value.organizerName,
        address: this.contentFormGroup.value.address,
        eventType: this.contentFormGroup.value.eventType,
        startDate: this.contentFormGroup.value.startDate,
        endDate: this.contentFormGroup.value.endDate,
        isRSVPRequired: this.publicationFormGroup.value.isRSVPRequired,
        isLimitRSVP: this.sessionsForm.value.isLimitRsvp,
        maxNoRSVP: this.sessionsForm.value.maxNoRSVP,
        isLimitRequired: this.sessionsForm.value.isLimitRequired,
        isAttendanceRequired: this.sessionsForm.value.isAttendanceRequired,
        totalAttendanceTaking: this.sessionsForm.value.totalAttendanceTaking,
        isEventWallRequired: this.eventBoardFormGroup.value.isEventWallRequired,
      },
      publishing: {
        startDate: this.publicationFormGroup.value.startDate,
        endDate: this.publicationFormGroup.value.endDate,
        NumberOfSessions: 1,
      },
      userOptions: {
        like: false,
        comment: false,
        share: false,
        attendance: false,
      },
      status: 1,
      teaserImage: this.eventFormGroup.value?.image,
      mainImage: this.contentFormGroup.value?.image,
      eventWallLogoImage: this.eventBoardFormGroup.get('eventWallLogoImage').value,
    };
    if (this.eventId) newData['postId'] = this.eventId;

    if (this.eventId) {
      await Swal.fire({
        title: 'Send Notification!',
        text: 'Do you wish to send a notification for this update?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: `Yes`,
        cancelButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          newData['isNotifi'] = 'true';
        } else {
          newData['isNotifi'] = 'false';
        }
      });
    }

    this.eventLoader = true;
    const subscribe = this.eventId
      ? this.newsService
        .updateNewsEvents(newData)
        .pipe(
          finalize(() => {
            this.eventLoader = false;
            subscribe.unsubscribe();
          })
        )
        .subscribe(
          (response: any) => {
            const getSessionList = [];
            this.sessionsForm.value.Rows.length > 0 && !!this.sessionsForm.value.Rows[0].startDate &&
              this.sessionsForm.value?.Rows?.map((val, ind) => {
                const sessionVO = {
                  indexno: ind + 1,
                  startDate: val?.startDate,
                  startTime: val?.startDate,
                  endDate: val?.endDate,
                  endTime: val?.endDate,
                  location: val?.location,
                  totalParticipantPerSession:
                    this.sessionsForm.value?.isLimitRequired ? +val?.totalParticipantPerSession : 9999,
                  adminIds: Control.value.map((item) => item._id),
                  checked: false,
                  _id: val._id,
                  post: response.data._id,
                  attendaceRequiredCount: 1,
                };
                getSessionList.push(sessionVO);
              });
            const filtered = this.editEvent.data.session.Rows.filter((data, index) => {
              const indexFound = getSessionList.findIndex((element) => { return element?._id == data?._id })
              return indexFound < 0
            })
            let updatedata = []
            if (!this.editEvent.data.session.isLimitRequired) {
              updatedata = filtered.map(val => val?.totalParticipantPerSession == 0 || val?.totalParticipantPerSession == 9999 && { ...val, totalParticipantPerSession: 9999 });

            }
            else {
              updatedata = filtered
            }
            const sessionPayload = {
              event_id: response.data._id,
              sessions: [
                ...getSessionList,
                ...updatedata,
              ],
              deletedSessions: [],
            };
            this.newsService
              .updateEventsSessions(sessionPayload)
              .pipe(
                finalize(() => {
                  this.eventLoader = false;
                  subscribe.unsubscribe();
                })
              )
              .subscribe(
                (response: any) => {
                  this.overViewEvent.emit(null);
                  this.tostService.success('Event Updated');
                },
                (error) => {
                  this.tostService.error('Error found');
                }
              );
          },
          (error) => { }
        )
      : this.newsService
        .createNewsEvents(newData)
        .pipe(
          finalize(() => {
            this.eventLoader = false;
            subscribe.unsubscribe();
          })
        )
        .subscribe(
          (response: any) => {
            this.sessionApiCall(response)

          });
  }

  openImageCropUpload(value) {
    this.imageCategory = value;
    this.modelService.open(this.AddImageCropper, {
      windowClass: 'modal-ui-fix.center-align.xlModal', size: 'lg',
    });
  }

  saveAsDraft() {
    if (!this.eventId && !this.minPublicationTimeValidator()) {
      return;
    }
    const { channelId, categoryId } = this.publicationFormGroup.value;
    const newData = {
      channelId: channelId,
      categoryId: categoryId,
      eventCreation: 0,
      postType: 'event',
      teaser: {
        title: this.eventFormGroup.value.title,
        listingType: this.eventFormGroup.value.listingType,
      },
      content: {
        title: this.contentFormGroup.value.title,
        isTeaserImage: this.contentFormGroup.value.isTeaserImage,
        content: this.contentFormGroup.value.content,
        eventWallStartDate: this.eventBoardFormGroup.value.startDate,
        eventWallEndDate: this.eventBoardFormGroup.value.endDate,
      },
      wallTitle: {
        title: this.eventBoardFormGroup.value.wallName,
        isTeaserImageForWall: this.eventBoardFormGroup.value.isEventWallRequired
      },
      eventDetails: {
        organizerName: this.contentFormGroup.value.organizerName,
        address: this.contentFormGroup.value.address,
        eventType: this.contentFormGroup.value.eventType,
        startDate: this.contentFormGroup.value.startDate,
        endDate: this.contentFormGroup.value.endDate,
        isRSVPRequired: this.publicationFormGroup.value.isRSVPRequired,
        isLimitRSVP: this.sessionsForm.value.isLimitRsvp,
        maxNoRSVP: this.sessionsForm.value.maxNoRSVP,
        isLimitRequired: this.sessionsForm.value.isLimitRequired,
        isAttendanceRequired: this.sessionsForm.value.isAttendanceRequired,
        totalAttendanceTaking: this.sessionsForm.value.totalAttendanceTaking,
        isEventWallRequired: this.eventBoardFormGroup.value.isEventWallRequired,
      },
      publishing: {
        startDate: this.publicationFormGroup.value.startDate,
        endDate: this.publicationFormGroup.value.endDate,
        NumberOfSessions: 1,
      },
      userOptions: {
        like: false,
        comment: false,
        share: false,
        attendance: false,
      },
      status: 2,
      teaserImage: this.eventFormGroup.value?.image
    };

    const subscribe = this.newsService
      .createNewsEvents(newData)
      .pipe(
        finalize(() => {
          this.eventLoader = false;
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          this.sessionApiCall(response);

        },
        (error) => {
          this.tostService.error('Error Found');
        }
      );
  }

  //method to call session api
  sessionApiCall(response) {
    const getSessionList = [];
    const Control = this.LoginFieldsControl;
    this.sessionsForm.value.Rows.length > 0 &&
      this.sessionsForm.value?.Rows?.map((val, ind) => {
        const sessionVO = {
          indexno: ind + 1,
          startDate: val?.startDate,
          startTime: val?.startDate,
          endDate: val?.endDate,
          endTime: val?.endDate,
          location: val?.location,
          totalParticipantPerSession:
            this.sessionsForm.value?.isLimitRequired ? +val?.totalParticipantPerSession : 9999,
          adminIds: Control.value.map((item) => item._id),
          checked: false,
        };
        getSessionList.push(sessionVO);
      });
    const sessionPayload = {
      event_id: response.data._id,
      sessions: getSessionList,
    };

    const subscribe = this.newsService
      .createEventsSessions(sessionPayload)
      .pipe(
        finalize(() => {
          this.eventLoader = false;
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          this.overViewEvent.emit(null);
          this.tostService.success('Event Created Successfully');
        },
        (error) => {
          this.tostService.error('Error found');
        }
      );
  }

  //method to validate publication time
  minPublicationTimeValidator() {
    const startDateTime = new Date(this.publicationFormGroup.get('startDate').value);
    const endDateTime = new Date(this.publicationFormGroup.get('endDate').value);
    const currentDateTime = new Date();
    if (startDateTime <= currentDateTime) {
      this.tostService.error('Start time should be greater than current time.');
      return false;
    } else if (endDateTime <= startDateTime) {
      this.tostService.error('End time should be greater than start time.');
      return false;
    }
    return true;
  }

  validateURLImage(str) {
    var img_Path = str;
    if (img_Path.indexOf("https://") == 0) {
      var new_url = new URL(str);
     var pathName = (new_url.pathname.startsWith('//')) ? `/${new_url.pathname.split("//")[1]}` : new_url.pathname;
      return `${this.baseUrl}${pathName}`;
    }
    if (str.startsWith('/')) return this.baseUrl + str;
    return `${this.baseUrl}/${str}`;
   }
}
