import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  ModalDismissReasons,
  NgbModal,
  NgbModalConfig,
} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { SetupService } from 'src/app/users/service/operations/setup.service';
import { ShiftSchedulerService } from 'src/app/users/service/operations/shift-scheduler.service';
import { UsersService } from 'src/app/users/service/user-service/users.service';
import Swal from 'sweetalert2';
import { GamificationService } from 'src/app/users/service/gamification/gamification.service';
import { COMMON_MESSAGES } from 'src/app/core/utils/helper';
import { getGmtOffSet } from 'src/app/core/utils/helper';

@Component({
  selector: 'app-assign-shift',
  templateUrl: './assign-shift.component.html',
  styleUrls: ['./assign-shift.component.scss'],
  providers: [NgbModalConfig, NgbModal],
})
export class AssignShiftComponent implements OnInit {
  @Input() bu;
  @Input() tier;
  businessUnit:any;
  locationValue;
  appointValue;
  mainSkillsetValue;
  addStaff: boolean = false;
  createDay: boolean = false;
  isSplit: boolean = false;
  lastday: any;
  firstday: any;
  empList: any;
  Selectedemp: any;
  SelectedEmpDisplay: any;
  startDay: string;
  currWeek: any;
  weekDateArr: any[];
  isSelectStaffLoading:boolean = false;
  buWeekArr = [];
  currentWeekCount :any;
  weekNumber: any;
  weekCount = 0;
  stafflisting: any;
  endDay: string;
  emptyShiftPara: {};
  userListPage=1;
  dayArr = [
    { count: 0, day: 'M', dayName: 'Monday' },
    { count: 1, day: 'T', dayName: 'Tuesday' },
    { count: 2, day: 'W', dayName: 'Wednesday' },
    { count: 3, day: 'T', dayName: 'Thursday' },
    { count: 4, day: 'F', dayName: 'Friday' },
    { count: 5, day: 'S', dayName: 'Saturday' },
    { count: 6, day: 'S', dayName: 'Sunday' },
  ];
  selectedDay: any;
  addedDay = [];
  adShift: FormGroup;
  reportLocation: any;
  isCheck: boolean = false;
  isCheckRest: boolean = false;
  restOffPara: { assignShiftId: string; isOff: boolean; isRest: boolean };
  daywiseData: any;
  assignShiftReadParam: any;
  assignShifts: any;
  skillSet = [];
  createUserShift = [];
  subSkillSetsId: any;
  selectedEmptyShift: any;
  createStaffShifts: { user: any[] };
  subSkill = [];
  skillsLabel = [];
  skillsId = [];
  closeResult: string;
  assignShiftsId = [];
  dayKey: string;
  dur = 0;
  durH = 0;
  publishParam: any;
  draftShifts = [];
  singleShift: any;
  StaffName: any;
  placeHolderText:string = 'select staff';
  deleteShiftIds = [];
  dayHrs = [];
  staffHrs = [];
  totalHrs = 0;
  totalHrStr: string;
  uploadParam: FormGroup;
  fileToUpload: File = null;
  uploadForm: FormGroup;
  user: any;
  isSplitShift: boolean = false;
  splitArr: any[];
  submitBtn = 'Create';
  skillName: string;
  timeDropDown: any[];
  filterLocationData = [];
  assignShiftsArray = [];
  filterDaysObj = [];
  filtersOptions: any;
  filterLocationArray: any[];
  filterLocationArrayFinal:any[];
  noOfStaff: number;
  appointmentArr = [];
  appointOption: any[];
  skillsetFilter = [];
  skillsetArray: any[];
  mainSkillsetFilter = [];
  mainSkillsetOptions = [];
  mainSkillsetArray: any[];
  tempAssignShift: any;
  tierType: any;
  filters: boolean = true;
  subSkillFilter: any;
  skillArr: any[];
  locations: any;
  checkInEnabledFlag:boolean;
  radius: any;
  isCheckInEnabled: any;
  isProximityEnabled: any;
  reportingLocationRadius = [
    { name: 50, id: 50 },
    { name: 100, id: 100 },
    { name: 150, id: 150 },
    { name: 200, id: 200 },
    { name: 250, id: 250 },
    { name: 300, id: 300 },
    { name: 350, id: 350 },
    { name: 400, id: 400 },
    { name: 450, id: 450 },
    { name: 500, id: 500 },
  ];
  assignShiftsLoader: boolean = true;
  loaderPublishShift: boolean = false;
  loaderCreateShift: boolean = false;
  disablePublishButton:boolean = true;
  gmtOffset: any;

  constructor(
    private modalService: NgbModal,
    config: NgbModalConfig,
    private buService: SetupService,
    private shiftService: ShiftSchedulerService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private userService: UsersService,
    private gamificationService: GamificationService
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  @ViewChild('AddCreateDay', { static: true })
  AddCreateDay: TemplateRef<any>;

  ngOnInit(): void {
    this.gmtOffset = getGmtOffSet();
    this.getWeeks();
    this.selectedDay = this.dayArr[0];
    this.initAddShift();
    this.readPageSettingSkill();
    this.getLocation();
    this.roaterForm();

    /*this.userService.getUserProfile().subscribe((res) => {
      this.user = res['data'];
    });*/

    this.selectTime();
    this.getLocationSetting();
  }

  checkDraftStatus(){
    Object.entries(this.assignShifts).map((days:any)=>{
    const finalVal =  days[1].filter((data:any)=>{
       return !data?.isEmpty && data?.draftStatus === 0
      });
      if(finalVal.length >0)
      this.disablePublishButton = false;
    })
  }
  selectTime() {
    this.timeDropDown = [];
    for (let hour = 0; hour < 24; hour++) {
      this.timeDropDown.push(moment({ hour }).format('HH:mm'));
      this.timeDropDown.push(moment({ hour, minute: 15 }).format('HH:mm'));
      this.timeDropDown.push(moment({ hour, minute: 30 }).format('HH:mm'));
      this.timeDropDown.push(moment({ hour, minute: 45 }).format('HH:mm'));
    }
  }

  getLocationSetting() {
    this.buService.getBuShiftGeoLocation(this.bu._id).subscribe((res) => {
      const data = res['result'];
      this.radius = data.proximity;
      this.isCheckInEnabled = data.isCheckInEnabled;
      this.isProximityEnabled = data.isProximityEnabled;
      this.adShift.patchValue({
        proximity: this.radius,
        isCheckInEnabled: this.isCheckInEnabled,
        isProximityEnabled: this.isProximityEnabled,
      });
    });
  }

  initAddShift() {
    this.adShift = this.fb.group({
      isOff: null,
      isRest: null,
      StartTime: null,
      EndTime: null,
      splitStartTime: null,
      splitEndTime: null,
      SkillSets: null,
      subSkillSets: null,
      reportLocation: null,
      isCheckInEnabled: this.isCheckInEnabled,
      isProximityEnabled: this.isProximityEnabled,
      proximity: this.radius,
      isSplit: null,
    });
  }

  dayOff(c) {
    if (c.target.name == 'isOff') {
      this.isCheckRest = false;
      if (c.target.checked == true) {
        this.isCheck = true;
        this.isCheckRest = false;
      } else {
        this.isCheck = false;
      }
    } else if (c.target.name == 'isRest') {
      if (c.target.checked == true) {
        this.isCheckRest = true;
        this.isCheck = false;
      } else {
        this.isCheckRest = false;
      }
    } else {
      this.isCheckRest = false;
      this.isCheck = false;
      this.adShift.patchValue({
        isOff: false,
        isRest: false,
      });
    }
  }

  getLocation() {
    this.buService.getRepoLocations(this.bu._id).subscribe((res) => {
      this.reportLocation = res['data'].reportingLocation;

      this.buService.getGeoRepoLocations(this.bu._id).subscribe((res) => {
        this.locations = res['result'].geoReportingLocation;
        this.reportLocation = this.reportLocation.concat(this.locations);
        this.checkInEnabledFlag = res['result']['isCheckInEnabled'];
      });
    });
  }

  addSplitTime() {
  }
  areTimingsOnOtherDay(startTime, endTime) {
    const startDateTime = startTime.split(':');
    const endDateTime = endTime.split(':');

    const startHour = parseInt(startDateTime[0]);
    const endHour = parseInt(endDateTime[0]);

    return startHour > endHour
  }
  createShift() {
    const pastTrue = [];
    this.createUserShift = [];
    if (this.isSplit) {
      const firstSplitEndTime = this.adShift.controls.EndTime.value;
      const secondSplitStartTime = this.adShift.controls.splitStartTime.value;

      var startTime = moment(firstSplitEndTime, 'HH:mm');
      var endTime = moment(secondSplitStartTime, 'HH:mm');
      var duration = endTime.diff(startTime);
      if (duration < 0) {
        this.toastr.error(
          'Second split timing is overlapping with the first split timing',
          'Failed',
          { timeOut: 3000 }
        );
        return;
      }
    }

    if (this.isCheck == true || this.isCheckRest == true) {
      this.loaderCreateShift = true;

      this.restOffPara = {
        assignShiftId: this.assignShiftsId[0]._id,
        isOff: this.isCheck,
        isRest: this.isCheckRest,
      };
      const restOffShift = this.shiftService
        .updateShiftRestOff(this.restOffPara)
        .pipe(
          finalize(() => {
            this.loaderCreateShift = false;
            restOffShift.unsubscribe();
          })
        )
        .subscribe((res) => {
          this.modelClose();
          this.readShift();
          this.restOffPara = {
            assignShiftId: '',
            isOff: null,
            isRest: null,
          };
          this.toastr.success('Shift Created', 'Successfully');
        });
    } else {
      this.addedDay.forEach((item, index) => {
        const otherDayFlag = this.areTimingsOnOtherDay(this.adShift.controls.StartTime.value, this.adShift.controls.EndTime.value);
        const nextDay = moment(this.startDay).add(item.count + 1, 'days').format('DD-MM-YYYY');
        let splitStartDate = moment(this.startDay).add(item.count, 'days').format('DD-MM-YYYY');
        let splitEndDate = moment(this.startDay).add(item.count, 'days').format('DD-MM-YYYY');
        let endDate = moment(this.startDay).add(item.count, 'days').format('DD-MM-YYYY');

        if (this.isSplit) {
          if (this.areTimingsOnOtherDay(this.adShift.controls.StartTime.value, this.adShift.controls.splitEndTime.value)) {
            splitEndDate = nextDay;
          }
          if (this.areTimingsOnOtherDay(this.adShift.controls.StartTime.value, this.adShift.controls.splitStartTime.value)) {
            splitStartDate = nextDay;
          }
        }
        if (otherDayFlag) {
          endDate = nextDay
        }
        const createShift = {
          assignShiftId: this.assignShiftsId[index]._id,
          Date: moment(this.startDay)
            .add(item.count, 'days')
            .format('DD-MM-YYYY'),
          StartDate: moment(this.startDay)
            .add(item.count, 'days')
            .format('DD-MM-YYYY'),
          StartTime: this.adShift.controls.StartTime.value + ':00',
          EndDate:endDate,
          EndTime: this.adShift.controls.EndTime.value + ':00',
          timeFormat: this.gmtOffset,
          isOff: false,
          isRest: false,
          splitStartTime: this.adShift.controls.splitStartTime.value + ':00',
          splitEndTime: this.adShift.controls.splitEndTime.value + ':00',
          splitStartDate: splitStartDate,
          spiltEndDate: splitEndDate,
          isSplitShift: this.isSplit,
          isCheckInEnabled: this.adShift.controls.isCheckInEnabled.value,
          isProximityEnabled: this.adShift.controls.isProximityEnabled.value,
          proximity: this.adShift.controls.proximity.value?.id,
        };
        const indexVal =   this.locations.find((data)=>{
          return data._id == this.adShift.controls.reportLocation.value._id
         });
        if (!indexVal) {
          createShift['geoReportingLocation'] = null;
          createShift['reportLocationId'] =
            this.adShift.controls.reportLocation.value._id;
        } else {
          createShift['reportLocationId'] = null;
          createShift['geoReportingLocation'] =
            this.adShift.controls.reportLocation.value._id;
        }

        if (this.tier == 2) {
          createShift['subSkillSets'] = this.skillsId;
        } else {
          createShift['mainSkillSets'] = this.skillsId;
        }

        this.createUserShift.push(createShift);
      });

      if (this.isSplit) {
        const splitst = this.adShift.controls.splitStartTime.value;
        const splitet = this.adShift.controls.splitEndTime.value;

        for (var s = 0; s < this.createUserShift.length; s++) {
          this.createUserShift[s].splitStartTime = splitst + ':00';
          this.createUserShift[s].splitEndTime = splitet + ':00';
        }
      }

      this.createStaffShifts = {
        user: this.createUserShift,
      };

      // Past Date and Time not allowed validation
      const now = moment();
      for (var t = 0; t < this.createUserShift.length; t++) {
        let timecheck = moment(
          this.createUserShift[t].StartDate,
          'DD-MM-YYYY'
        ).set({
          hour: this.createUserShift[t].StartTime.slice(0, 2),
          minute: this.createUserShift[t].StartTime.slice(3, 5),
        });

        if (timecheck.isAfter(now)) {
          pastTrue.push('true');
        } else {
          pastTrue.push('false');
        }
      }

      if (pastTrue.includes('false')) {
        this.toastr.error(
          'This Date or Time has already passed. Can Update only future dates',
          'Failed',
          {
            timeOut: 3000,
          }
        );
        pastTrue.length = 0;
      } else {
        this.loaderCreateShift = true;
        const createShift = this.shiftService
          .updateShift(this.createStaffShifts)
          .pipe(
            finalize(() => {
              this.loaderCreateShift = false;
              createShift.unsubscribe();
            })
          )
          .subscribe(
            (res) => {
              if (res['success'] == true) {
                this.modelClose();
                this.readShift();
                this.toastr.success('Shift Created', 'Successfully');
                this.addedDay = [];
                this.createUserShift = [];
                this.skillsId = [];
                this.skillsLabel = [];
                this.adShift.reset();
                this.isSplit = false;
                this.isSplitShift = false;
              } else if (res['success'] == false) {
                this.toastr.error(res['message'], 'Failed', {
                  timeOut: 3000,
                });
              }
            },
            (error) => {
              this.toastr.error(error, 'Failed', {
                timeOut: 3000,
              });
            },
            () => {
              pastTrue.length = 0;
            }
          );
      }
    }
  }

  getWeeks() {
    const buWeeks = this.shiftService
      .getWeeks(this.bu._id)
      .pipe(
        finalize(() => {
          buWeeks.unsubscribe();
        })
      )
      .subscribe((res: { data }) => {
        //this.buWeekArr = res['data'];
        const weekCount = res['data'];
        //this.selectedWeek
        const number = res['data'].noOfWeek;
        this.weekNumber = number;
        let getDate = moment().startOf('isoWeek').format("MM-DD-YYYY");
        if (number > 0) {
          for (var i = -number; i <= number; i++) {
            let dummyData = this.getWeekStartEndDate(getDate, 7 * (i))
           this.buWeekArr.push(dummyData);
            //localStorageService.set("WorkWeekList",  $scope.myWeekRange);
          }
          /*this.planOpenshiftForm.patchValue({
            selectWeek: this.weekArr1[res['data'].noOfWeek],
          });*/
          this.currentWeekCount = this.buWeekArr[number];
        }
        this.changeWeek('');
      });
  }

  getWeekStartEndDate = function (date, count) {
    let datesToReturn = {};
    let label = moment().startOf('isoWeek').add(count, 'days').format("MMM DD") + ' to ' + moment().startOf('isoWeek').add(count + 6, 'days').format("MMM DD")
    let value = moment().startOf('isoWeek').add(count, 'days').format("MM-DD-YYYY");

    datesToReturn = { label: label, value: value, key: count };
   /* if (count == 0 && $scope.workWeeksDate == undefined) {

      $scope.workWeeksDate = value;

    }*/
    return label;
  }
  changeWeek(str) {
    this.locationValue = [];
    this.weekDateArr = [];
    if (str == 'Nxt') {
      this.weekCount =
        this.weekCount < this.weekNumber
          ? this.weekCount + 1
          : this.weekNumber;
    } else if (str == 'Prev') {
      this.weekCount =
        this.weekCount == -Math.abs(this.weekNumber)
          ? this.weekCount
          : this.weekCount - 1;
    } else {
      this.weekCount = 0;
    }

    this.currWeek =
      this.buWeekArr[this.weekNumber + this.weekCount];
    var startDaystr = this.currWeek.slice(0, 6);
    var date = moment(startDaystr).year(moment().year()).format('MM-DD-YYYY');
    this.startDay = date + ` 00:00:00 ${this.gmtOffset}`;

    var endDayString = moment(startDaystr)
      .year(moment().year())
      .add(6, 'days')
      .format('MM-DD-YYYY');
    this.endDay = endDayString + ` 00:00:00 ${this.gmtOffset}`;

    for (var w = 0; w < 7; w++) {
      this.weekDateArr.push(
        moment(date, 'MM-DD-YYYY').add(w, 'days').format('DD MMM')
      );
    }
    this.readShift();
  }

  onAddStaff(value) {
    const payload = {
      page: this.userListPage,
      "parentBussinessUnitId[]": this.bu._id
    }
    this.addStaff = value;
    this.isSelectStaffLoading = true;
    this.placeHolderText = 'Loading..........'
    this.shiftService.getUserbyPlanBU(payload).subscribe((res) => {
      const response = res['data']['items'];
      this.empList = [...this.empList,...response];
      this.empList?.map(id => {
        id['nameAndStaffIdCombination'] = `${id.name} (${id.staffId})`
      })
      this.placeHolderText = 'select staff'
      this.isSelectStaffLoading = false;
    });
  }
  /*className() {
    return this.isSelectStaffLoading ? 'class1' : 'class2';
  }*/
  onScrollToEnd() {
    const payload = {
      page: this.userListPage,
      "parentBussinessUnitId[]": this.bu._id
    }
    this.isSelectStaffLoading = true;
    this.placeHolderText = 'Loading..........'
    this.shiftService.getUserbyPlanBU(payload).subscribe((res) => {
      const response = res['data']['items'];
      this.empList = [...this.empList,...response];
      this.empList?.map(id => {
        id['nameAndStaffIdCombination'] = `${id.name} (${id.staffId})`
      })
      this.placeHolderText = 'select staff'
      this.isSelectStaffLoading = false;
    });
  }
  staffSearch(term){

    const payload = {
      q: term.term,
      "parentBussinessUnitId[]": this.businessUnit
    }
    this.isSelectStaffLoading = true;
    this.placeHolderText = 'Loading..........'
this.shiftService.getUserbyPlanBU(payload).subscribe((res) => {
  const response = res['data']['items'];
  this.placeHolderText = 'select staff'
  this.isSelectStaffLoading = false;
  this.empList = response;
  this.empList?.map(id => {
    id['nameAndStaffIdCombination'] = `${id.name} (${id.staffId})`
  })
});
  }
  anotherFun(){
  }
  loadMorePlasnBuUnitItems(data){
    this.userListPage = this.userListPage+1;
    this.onScrollToEnd();
  }
  readShift() {
    this.assignShifts = null;
    this.assignShiftsArray = [];
    this.filterDaysObj = [];
    this.disablePublishButton = true;
    this.assignShiftsLoader = true;
    this.assignShiftReadParam = {
      businessUnitId: this.bu._id,
      weekRangeStartsAt: this.startDay,
      weekRangeEndsAt: this.endDay,
      timeZone: 330,
    };

    this.dayHrs = [];
    this.totalHrs = 0;
    this.totalHrStr = '';

    this.shiftService.readAssignShift(this.assignShiftReadParam).subscribe(
      (res) => {
        this.assignShifts = res['shifts'];
        this.checkDraftStatus();
        this.tempAssignShift = Object.assign({}, res['shifts']);
        // this.noOfStaff = this.assignShifts.Monday;
        for (var k in this.assignShifts) {
          for (var j in this.assignShifts[k]) {
            this.dur = this.dur + this.assignShifts[k][j].duration;
          }
          var m = Math.trunc(this.dur);
          var n = (this.dur - m) * 60;
          this.dayHrs.push(m + ':' + n);

          this.totalHrs = this.totalHrs + this.dur;
          var e = Math.trunc(this.totalHrs);
          var f = (this.totalHrs - e) * 60;
          this.totalHrStr = e + ':' + f;

          this.dur = 0;
        }

        this.totalColumnHrs();
        this.assignShiftsArray.push(this.assignShifts);
        this.assignShiftsArray.forEach((element) => {
          this.filterDaysObj.push(
            element['Monday'],
            element['Tuesday'],
            element['Wednesday'],
            element['Thursday'],
            element['Friday'],
            element['Saturday'],
            element['Sunday']
          );
          this.filterDaysObj.forEach((item) => {
            item?.forEach((data1) => {
              this.appointmentArr.push(data1.staffAppointmentId.name);
              this.appointOption = this.appliedLeaveCount(this.appointmentArr);
              if (data1.startTime) {
               data1?.geoReportingLocation?.name ? this.filterLocationData.push(data1.geoReportingLocation?.name) : this.filterLocationData.push(data1.reportLocationId?.name) ;
                this.filtersOptions = this.filterLocationData;
                this.filterLocationArray = this.appliedLeaveCount(
                  this.filtersOptions
                );
              }
              if (data1?.subSkillSets.length > 0) {
                this.mainSkillsetFilter.push(data1?.subSkillSets);
                this.mainSkillsetFilter.forEach((element) => {
                  this.mainSkillsetOptions.push(element[0].skillSetId.name);
                });

                this.mainSkillsetArray = this.appointCount(
                  this.mainSkillsetOptions
                );
              } else if (
                data1?.subSkillSets.length === 0 &&
                data1?.mainSkillSets > 0
              ) {
                // this.mainSkillsetFilter.push(data1?.mainSkillSets)
              }
            });
          });
        });
      this.filterLocationArrayFinal =  this.filterLocationArray && this.filterLocationArray.filter((data)=>{
          return data?.name !== undefined
        })
      },
      () => {},
      () => {
        this.assignShiftsLoader = false;
      }
    );
  }

  totalColumnHrs() {
    this.staffHrs = [];
    if (this.assignShifts.length != 0) {
      for (var p = 0; p < this.assignShifts['Monday'].length; p++) {
        for (var l in this.assignShifts) {
          if (this.assignShifts[l][p].isEmpty != true) {
            this.durH = this.durH + this.assignShifts[l][p].duration;
          }
        }

        var q = Math.trunc(this.durH);
        var r = (this.durH - q) * 60;

        this.staffHrs.push(q + ':' + r);
        this.durH = 0;
      }

    }
  }

  appointCount(arr) {
    let objArr = [
      ...arr.map((item) => {
        return item;
      }),
    ];
    const uniqueArr = [...new Set(objArr)];
    const arrObj3 = [];
    for (let index = 0; index < uniqueArr.length; index++) {
      const count = this.getOccurence(objArr, uniqueArr[index]);
      arrObj3.push(uniqueArr[index]);
    }
    return arrObj3;
  }

  appliedLeaveCount(arr) {
    let objArr = [
      ...arr.map((item) => {
        return item;
      }),
    ];
    const uniqueArr = [...new Set(objArr)];
    const arrObj3 = [];
    for (let index = 0; index < uniqueArr.length; index++) {
      const count = this.getOccurence(objArr, uniqueArr[index]);
      arrObj3.push({ name: uniqueArr[index], total: count });
    }
    return arrObj3;
  }

  getOccurence(arr, val) {
    return arr.filter((value) => value === val).length;
  }

  // onSelectLocation(){

  //   let daysObj = {}
  //   Object.keys(this.assignShifts).forEach((item, i) => {
  //    // Object.values(this.assignShifts).forEach(element => {
  //       //console.log('aarr', this.assignShifts[item])
  //       this.assignShifts[item].forEach(ele => {
  //         if(ele.reportLocationId && ele.reportLocationId?.name === this.locationValue.name){
  //           daysObj[item] = this.assignShifts[item]
  //           this.noOfStaff = this.assignShifts[item]
  //         }else{
  //           daysObj[item] = []
  //         }
  //       })
  //     //})
  //   })

  //   this.assignShifts = daysObj;

  // }

  deleteShift(i) {
    const currTime = moment();
    const startTime = moment(i.startTime);
    const diffTime = currTime.diff(startTime);


    if (diffTime > 0) {
      this.toastr.error(
        "Can't delete this. Either shift started or attendance has been taken.",
        'Failed',
        {
          timeOut: 3000,
        }
      );
    } else {
      const shiftDeletePayload = {
        assignShiftId: [i._id],
        userId: i.staff_id._id,
      };

      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to delete this?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          this.shiftService
            .deleteSingleShift(shiftDeletePayload)
            .subscribe((res) => {
             // Swal.fire('Deleted!', 'Shift has been deleted.', 'success');
             this.toastr.success('Shift has been deleted.', 'Successfully');
              this.readShift();
            });
        }
      });
    }
  }

  deleteShiftAll(i) {
    const currTime = moment();
    const times = moment(this.assignShifts['Monday'][i].date);
    const diffTime = currTime.diff(times);

    if (diffTime > 0) {
      this.toastr.error(
        "Can't delete this. Either shift started or attendance has been taken.",
        'Failed',
        {
          timeOut: 3000,
        }
      );
    } else {
      for (let j in this.assignShifts) {
        this.deleteShiftIds.push(this.assignShifts[j][i]._id);
      }

      this.singleShift = {
        assignShiftId: this.deleteShiftIds,
        userId: this.assignShifts['Monday'][i].staff_id._id,
      };

      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to delete All Shift for this Staff?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          this.shiftService
            .deleteSingleShift(this.singleShift)
            .subscribe((res) => {
             // Swal.fire('Deleted!', 'Shift has been deleted.', 'success');
             this.toastr.success('Shift has been deleted.', 'Successfully');
              this.readShift();
            });
        }
      });
    }
  }

  limit(i) {
    if (i.schemeDetails.alert == true) {
      Swal.fire({
        title: i.alertMessage,
        text: 'Do you want to continue?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          var d = {
            assignShiftId: i._id,
            from: 'yes',
          };
          this.shiftService.alertAction(d).subscribe((res) => {
            this.toastr.success('Changed', 'You have Selected to Proceed');
            this.readShift();
          });
          // Swal.fire("Deleted!", "Your file has been deleted.", "success");
        }
      });
    } else {
      Swal.fire(i.alertMessage);
    }

    if (i.schemeDetails.disallow == true) {
      Swal.fire(i.alertMessage);
    }
  }

  showMsg(i) {
    Swal.fire(i);
  }

  readPageSettingSkill() {
    this.shiftService.getPageSettingSkill().subscribe((res) => {
      this.tierType = res['tierType'];
    });
  }

  onEmpSelected() {
    this.SelectedEmpDisplay = this.Selectedemp;

    this.stafflisting = {
      businessUnitId: this.bu._id,
      plannedBy: localStorage.getItem('loggedInUserId'),
      timeFormat: this.gmtOffset,
      userId: this.SelectedEmpDisplay._id,
      weekNumber: moment(this.startDay).week(),
      weekRangeEndsAt: this.endDay,
      weekRangeStartsAt: this.startDay,
    };

    this.shiftService
      .getStaffIdShift(this.SelectedEmpDisplay.staffId)
      .subscribe(() => {});

    this.shiftService.createEmptyShift(this.stafflisting).subscribe((res) => {
      this.daywiseData = res['dayWiseData'];
      this.readShift();
    });
  }

  onChangeSplit(value) {
    this.isSplit = value;
  }

  onCreateDay(i) {
    this.isCheck = false;
    this.isCheckRest = false;

    this.modalService.open(this.AddCreateDay, {
      windowClass: 'modal-ui-fix right-align',
    });

    this.StaffName = i.staff_id.name;

    this.selectedEmptyShift = i;
    this.getStaffSkill(i.staff_id);
    this.assignShiftsId.push(i);

    for (var k in this.assignShifts[i.dayName]) {
      if (this.assignShifts[i.dayName][k] == i) {
        this.dayKey = k;
      }
    }

    if (i.isEmpty == false) {
      this.submitBtn = 'Update';
    } else {
      this.submitBtn = 'Create';
    }

    if (i.isEmpty == false && i.isOff == false && i.isRest == false) {
      //not getting createdAt in assignshift read api hence adding it to handle geolocation
      let locationId;
      if (i.reportLocationId) {
        locationId = this.reportLocation.find(
          (el) => el._id == i.reportLocationId._id
        );
      } else {
        locationId = this.locations.find(
          (el) => el._id == i.geoReportingLocation._id
        );
      }
      this.adShift.patchValue({
        StartTime: moment(i.startTime).format('HH:mm'),
        EndTime: moment(i.endTime).format('HH:mm'),
        reportLocation: locationId,
        isCheckInEnabled: i.isCheckInEnabled != undefined ? i.isCheckInEnabled : this.checkInEnabledFlag,
        isProximityEnabled:
          i.isProximityEnabled != undefined ? i.isProximityEnabled : this.checkInEnabledFlag,
        proximity: i.proximity != undefined ? i.proximity : null,
        isSplit : i.isSplitShift,
        splitStartTime:moment(i?.splitStartTime).format('HH:mm'),
        splitEndTime:moment(i?.splitEndTime).format('HH:mm'),
      });
        this.isSplit = i.isSplitShift;
      if (this.tier == 2 && i.subSkillSets.length) {
        i.subSkillSets.forEach((item) => {
          this.skillsLabel.push([item.skillSetId.name, item.name]);
          this.skillsId.push(item._id);
        });
      } else if (this.tier == 1 && i.mainSkillSets.length) {
        i.mainSkillSets.forEach((item) => {
          this.skillsLabel.push([item.name, '']);
          this.skillsId.push(item._id);
        });
      }
    } else if (i.isOff == true && i.isEmpty == false) {
      this.isCheck = i.isOff;
      this.isCheckRest = i.isRest;
    } else if (i.isRest == true && i.isEmpty == false) {
      this.isCheck = i.isOff;
      this.isCheckRest = i.isRest;
    }

    this.assignShiftsId.forEach((item) => {
      switch (item.dayName) {
        case 'Monday':
          this.addedDay.push(this.dayArr[0]);
          break;
        case 'Tuesday':
          this.addedDay.push(this.dayArr[1]);
          break;
        case 'Wednesday':
          this.addedDay.push(this.dayArr[2]);
          break;
        case 'Thursday':
          this.addedDay.push(this.dayArr[3]);
          break;
        case 'Friday':
          this.addedDay.push(this.dayArr[4]);
          break;
        case 'Saturday':
          this.addedDay.push(this.dayArr[5]);
          break;
        case 'Sunday':
          this.addedDay.push(this.dayArr[6]);
          break;
      }
    });

    this.isCheck = false;
    this.isCheckRest = false;
  }

  modelClose() {
    this.adShift.patchValue({
      isOff: false,
      isRest: false,
      SkillSets: null,
      subSkillSets: null,
      StartTime: null,
      EndTime: null,
      reportLocation: null,
      proximity: this.radius,
      isCheckInEnabled: this.isCheckInEnabled,
      isProximityEnabled: this.isProximityEnabled,
    });
    this.isCheck = false;
    this.isCheckRest = false;

    this.modalService.dismissAll();
    this.addedDay = [];
    this.assignShiftsId = [];
    this.skillsId = [];
    this.skillsLabel = [];
  }
  displaySkilltable() {
    this.buService.getSkillset(this.bu._id).subscribe((res) => {
      if (this.tier == 2) {
      this.skillSet = res['data'].skillSets;
      }
    });
  }
  getStaffSkill(s) {
    this.shiftService.getStaffIdShift(s.staffId).subscribe((res) => {
      if (this.tier == 2) {
        this.skillSet = res['user'].subSkillSets;
        if (this.skillSet.length) {
          const skillArrTemp = [];
          this.skillSet.forEach((val) => {
            skillArrTemp.push(val.skillSetId);
          }); 

          this.skillArr = skillArrTemp.filter(
            (v, i, a) => a.findIndex((t) => t._id === v._id) === i
          );

        }
      } else {
        this.skillArr = res['user'].mainSkillSets;
      }
    });
  }

  setSubSkill(selectedSkill) {
    this.adShift.patchValue({
      subSkillSets: null,
    });
    this.subSkill = this.skillSet.filter(
      (v, i, a) => v.skillSetId._id == selectedSkill._id
    );
  }

  addSkills() {
    if (this.tier == 2) {
      this.skillsLabel.push([
        this.adShift.controls.SkillSets.value.name,
        this.adShift.controls.subSkillSets.value.name,
      ]);
      this.skillsId.push(this.adShift.controls.subSkillSets.value._id);
    } else {
      this.skillsLabel.push([this.adShift.controls.SkillSets.value.name, '_']);
      this.skillsId.push(this.adShift.controls.SkillSets.value._id);
    }
  }

  clearLabel(i) {
    this.skillsLabel.splice(i, 1);
    this.skillsId.splice(i, 1);
  }

  clearSkill() {
    this.skillsLabel = [];
    this.skillsId = [];
  }

  addDay(d) {
    if (this.addedDay.indexOf(d) == -1) {
      this.addedDay.push(d);

      this.assignShiftsId.push(this.assignShifts[d.dayName][this.dayKey]);
    } else {
      this.addedDay.splice(this.addedDay.indexOf(d), 1);
      this.assignShiftsId.splice(
        this.assignShiftsId.indexOf(this.assignShifts[d.dayName][this.dayKey]),
        1
      );
    }
  }

  shiftPublish() {
    for (var a in this.assignShifts) {
      for (var b in this.assignShifts[a]) {
        if (
          this.assignShifts[a][b].draftStatus == 0 &&
          this.assignShifts[a][b].isEmpty == false &&
          (this.assignShifts[a][b].isLimit == false ||
            this.assignShifts[a][b].isLimit == null)
        ) {
          this.draftShifts.push(this.assignShifts[a][b]._id);
        }
      }
    }
    this.publishParam = {
      businessUnitId: this.bu._id,
      weekRangeStartsAt: this.startDay,
      weekRangeEndsAt: this.endDay,
      assignShiftIds: [...new Set(this.draftShifts)],
    };
    if (this.draftShifts.length != 0) {
      this.loaderPublishShift = true;
      const publishShift = this.shiftService
        .assignShiftPublish(this.publishParam)
        .pipe(
          finalize(() => {
            this.loaderPublishShift = false;
            publishShift.unsubscribe();
          })
        )
        .subscribe((res) => {
          this.draftShifts = [];
          this.readShift();
          this.toastr.success('Shift Published', 'Successfully');
        });
    } else {
      this.toastr.info('Nothing to Publish', 'Unsuccessful');
    }
  }

  onFileSelect(event) {
    if (event.target.files && event.target.files.length) {
      let file: File = event.target.files[0];
      if (file && file.type === 'text/csv') {
        const fileUpload = this.gamificationService.uploadFile(file)
        .pipe(
          finalize(() => {
            fileUpload.unsubscribe();
          })
        )
        .subscribe(
          (data: any) => {
            this.uploadForm.get('csv').setValue(file);
          },
          (error: HttpErrorResponse) => {
            this.toastr.error(error?.error?.message, error?.statusText,{
              timeOut: 3000,
            });
            event.target.value = '';
            file = null;          }
        );

      } else {
        this.toastr.error(COMMON_MESSAGES.UPLOAD_ONLY_CSV);
        event.target.value = '';
        file = null;
        this.uploadForm.get('csv').reset()
      }
    }
  }

  roaterForm() {
    this.uploadForm = this.fb.group({
      csv: [''],
    });
  }

  uploadRoster() {
    this.uploadParam = this.fb.group({
      businessUnitId: this.bu._id,
      weekRangeStartsAt: this.startDay,
      weekRangeEndsAt: this.endDay,
      weekNumber: moment(this.startDay).week(),
      plannedBy: localStorage.getItem("loggedInUserId"),
      timeFormat: this.gmtOffset,
    });

    this.shiftService
      .uploadRoster(this.uploadParam.value, this.uploadForm.get('csv').value)
      .subscribe((res : any ) => {
        this.readShift();
        if (res && res.status === true) {
          this.toastr.success('Roster Uploaded', 'Successfully');
        } else {
          this.toastr.error('There is some issue with the data in the csv file, please check');
        }
      }, (error) => {
        this.toastr.error('Something wrong went, please try again');
      });
  }

  setSplitShift(e) {
    if (e.target.checked) {
      this.isSplit = true;
      this.isSplitShift = true;
    } else {
      this.isSplit = false;
      this.isSplitShift = false;
    }
  }

  open1(content1) {
    const payload = {
      page: this.userListPage,
      "parentBussinessUnitId[]": this.bu._id
    }
    this.businessUnit = this.bu._id;
    this.isSelectStaffLoading = true;
    this.placeHolderText = 'Loading..........'
    this.shiftService.getUserbyPlanBU(payload).subscribe((res) => {
      this.empList = res['data']['items'];
      this.empList?.map(id => {
        id['nameAndStaffIdCombination'] = `${id.name} (${id.staffId})`
      })
      this.placeHolderText = 'select staff'
      this.isSelectStaffLoading = false;
    });

    this.Selectedemp = null;

    this.modalService
      .open(content1, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  locationFilter() {
    const initAssignShift = this.assignShifts;
    if (this.locationValue.length != 0) {
      this.filters = false;
      for (const prop in initAssignShift) {
        for (const inprop of initAssignShift[prop]) {
          this.locationValue.forEach((val) => {
            if (val.name != inprop.reportLocationId?.name) {
              inprop['isEmpty'] = true;
            }
          });

          this.locationValue.forEach((val) => {
            if (val.name == inprop.reportLocationId?.name) {
              inprop['isEmpty'] = false;
            }
          });
        }
      }
      this.totalColumnHrs();
    } else {
      this.filters = true;
      this.readShift();
    }
  }

  onMainSkillsetValue() {
    this.assignShifts = Object.assign({}, this.tempAssignShift);
    this.skillsetFilter = [];
    this.filterDaysObj.forEach((item) => {
      item.forEach((data1) => {
        if (
          data1?.subSkillSets.length > 0 &&
          this.mainSkillsetValue == data1?.subSkillSets[0].skillSetId.name
        ) {
          this.skillsetFilter.push(data1?.subSkillSets[0].name);
          this.skillsetArray = this.appointCount(this.skillsetFilter);
        }
      });
    });

    const initAssignShift = this.assignShifts;
    if (this.mainSkillsetValue) {
      this.filters = false;
      for (const prop in initAssignShift) {
        for (const inprop of initAssignShift[prop]) {
          if (
            this.mainSkillsetValue != inprop.subSkillSets[0]?.skillSetId?.name
          ) {
            inprop['isEmpty'] = true;
          } else {
            inprop['isEmpty'] = false;
          }
        }
      }
    } else {
      this.filters = true;
      this.subSkillFilter = null;
      this.readShift();
    }
  }

  onSubSkillFilter() {
    const initAssignShift = this.assignShifts;
    if (this.subSkillFilter) {
      this.filters = false;
      for (const prop in initAssignShift) {
        for (const inprop of initAssignShift[prop]) {
          if (this.subSkillFilter != inprop.subSkillSets[0]?.name) {
            inprop['isEmpty'] = true;
          } else {
            inprop['isEmpty'] = false;
          }
        }
      }
    } else {
      this.filters = true;
      // this.assignShifts = Object.assign({}, this.tempAssignShift);
      this.onMainSkillsetValue();
    }
  }

  appointFitler() {
    this.assignShifts = Object.assign({}, this.tempAssignShift);

    if (this.appointValue.length != 0) {
      this.filters = false;
      for (const prop in this.assignShifts) {
        let temp = [];
        this.appointValue.forEach((v) => {
          this.assignShifts[prop].forEach((vv) => {
            if (v.name == vv.staffAppointmentId.name) {
              temp.push(vv);
            }
          });
        });
        this.assignShifts[prop] = [...temp];
      }
    } else {
      this.filters = true;
      this.assignShifts = Object.assign({}, this.tempAssignShift);
    }
  }
}
