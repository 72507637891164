import { Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, of } from 'rxjs';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  finalize,
  map,
  mergeMap,
  pairwise,
  startWith,
} from 'rxjs/operators';
import {
  SortableHeader,
  SortEvent,
} from 'src/app/shared/directive/sortable.directive';
import { PaginatedTable } from 'src/app/core/model/pagination.model';
import { StorageService } from 'src/app/core/service/storage.service';

import { CustomFieldService } from '../../../service/custom-field/custom-field.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-custom-fields',
  templateUrl: './custom-fields.component.html',
  styleUrls: ['./custom-fields.component.scss'],
})
export class CustomFieldsComponent implements OnInit {
  order = [];
  type = ['text', 'date', 'dropdown'];
  hideDefaultFields: boolean = true;
  dropDownIndex: number;
  inputValue: string;
  isDropDownEdited: boolean = false;
  isCustomFieldEdited: boolean = false;
  isLoading: boolean = false;
  // customFieldID:string
  customFieldForm: FormGroup;
  dropDownForm: FormGroup;
  isTableLoading: boolean = false;
  CustomFieldPTable: { sortWith: string; sortBy: string; data: any[]; page: number; total: number; limit: number; search: string; };
  bussinessUnitEditForm: FormGroup;
  orderList = [];
  public CustomFieldsearchInput = new Subject<KeyboardEvent>();


  public form: FormGroup;
  unsubcribe: any
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  companyName: string;
  /// bussiness unit over view table config
  onSortCustomField({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.CustomFieldPTable.sortBy = column;
    this.CustomFieldPTable.sortWith = direction;
    this.loadCustomField();
  }

  onPageChangeCustomField(page: number) {
    this.CustomFieldPTable.page = page;
    if (this.CustomFieldPTable.page === 1) {
      this.hideDefaultFields = true;
    }
    else {
      this.hideDefaultFields = false;
    }
    this.loadCustomField();
  }
  onLimitChangeCustomFieldt() {
    this.loadCustomField();
  }
  @ViewChild('AddDepartment', { static: true })
  AddDepartment: TemplateRef<any>;

  private CustomFieldSearchSubscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private customFieldService: CustomFieldService,
    public toastService: ToastrService,
    private storageService: StorageService,
    public modelService: NgbModal,
  ) {
    this.CustomFieldPTable = {
      sortWith: 'indexNum',
      sortBy: 'asc',
      data: [],
      page: 1,
      total: 0,
      limit: 10,
      search: '',
    };
    this.companyName = this.storageService.getByKey('companyId');

    this.CustomFieldSearchSubscription = this.CustomFieldsearchInput.pipe(
      map((event) => {
        const target = event.target as HTMLTextAreaElement;
        return target.value;
      }),
      debounceTime(500),
      distinctUntilChanged(),
      mergeMap((search) => of(search).pipe(delay(500)))
    ).subscribe((input) => {
      this.CustomFieldPTable.page = 1;
      if (input === '') {
        this.hideDefaultFields = true;
      }
      else {
        this.hideDefaultFields = false;
      }
      this.loadCustomField();
    });
  }

  searchInputChange(value) {
    console.log(value)
    // this.hideDefaultFields = false;
  }

  ngOnInit(): void {
    this.form = new FormGroup({});
    this.initForm();
    this.loadCustomField();
  }

  initForm() {
    this.customFieldForm = this.formBuilder.group({
      fieldName: ['', [Validators.required]],
      type: ['', [Validators.required]],
      options: this.formBuilder.array([]),
      required: [true],
      editable: [false],
      indexNum: [null],
      status: ['active'],
      order: [null],
      fieldId: [null]
    });
    this.dropDownForm = this.formBuilder.group({

      value: ['', Validators.required],
    });
  }

  get customFieldFormValue() {
    return this.customFieldForm.controls;
  }

  addDepartment() {
    this.initForm()

    this.modelService.open(this.AddDepartment, {
      windowClass: 'modal-ui-fix right-align',
    });
  }

  loadCustomField() {
    // const {data,total ,...rest}  = this.CustomFieldPTable
    this.isTableLoading = true;
    const subscribe = this.customFieldService.getCustomFieldList().pipe(
      finalize(() => {
        subscribe.unsubscribe();
      })
    )
      .subscribe(
        (response: any) => {
          const { total, fieldList } = response?.data;
          this.isTableLoading = false;
          this.CustomFieldPTable.data = fieldList || [];
          this.CustomFieldPTable.data.forEach((e, i) => {
            this.order.push(i + 1)
          })
          this.order.push(this.CustomFieldPTable.data.length + 1);
          this.CustomFieldPTable.total = total;
        },
        (error) => {
          this.CustomFieldPTable.data = [];
          this.CustomFieldPTable.total = 0;
          this.toastService.error('No list found');
          this.isTableLoading = false;
        }
      );
  }

  get OptionieldsControl(): FormArray {
    return this.customFieldForm.get('options') as FormArray;
  }

  async addOptions() {
    const { value } = this.dropDownForm.value;
    const Control = this.OptionieldsControl;
    if (!this.isDropDownEdited) {
      const index = await Control.value.findIndex((x) => x.value === value);

      if (index > -1) {
        return;
      } else {
        let field = this.formBuilder.group({
          id:[null],
          value: [value, Validators.compose([Validators.required])],
        });
        Control.push(field);
        this.dropDownForm.reset();

      }
    } else {
      Control.at(this.dropDownIndex).patchValue({
        id:[null],
        value: value,
      });
      this.dropDownIndex = null;
      this.isDropDownEdited = false;
      this.dropDownForm.reset();

    }
  }

  onEditDropDown(index, { value }) {

    this.isDropDownEdited = true;
    this.dropDownIndex = index;


    this.dropDownForm.patchValue({
      value: value,
    });
  }
  deleteOption(index) {
    const Control = this.OptionieldsControl;

    Control.removeAt(index);
    this.toastService.error('Field deleted');
  }




  editCustomField(value, index) {
    this.order.pop()
    const control = this.OptionieldsControl;
    this.getOrderList(this.CustomFieldPTable.data?.length);
    this.clearFormArray(control)
    this.isCustomFieldEdited = true
    // this.customFieldID = value._id
    this.customFieldForm.patchValue({
      fieldName: value.fieldName,
      type: value.type,

      required: value.required,
      editable: value.editable,
      indexNum: value.indexNum,
      status: value.status,
      order: parseInt(index + 5),
      fieldId: value._id
    })
    value.options.forEach(element => {

      let field = this.formBuilder.group({
         _id: [null],
        value: [element, Validators.compose([Validators.required])],
      });
      control.push(field);
    });

    this.modelService.open(this.AddDepartment, {
      windowClass: 'modal-ui-fix right-align',
    }).result.then((result) => {
      this.isCustomFieldEdited = false
      this.order = []
      this.CustomFieldPTable.data.forEach((e, i) => {
        this.order.push(i + 1)
      })
      this.order.push(this.CustomFieldPTable.data.length + 1)
    }, (reason) => {
      this.isCustomFieldEdited = false
      this.order = []
      this.CustomFieldPTable.data.forEach((e, i) => {
        this.order.push(i + 1)
      })
      this.order.push(this.CustomFieldPTable.data.length + 1)
    });


  }

  getOrderList(dataLength) {
    this.orderList = []
    for (let i = 5; i < (dataLength + 5); i++) {
      this.orderList.push(i)
    }
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }


  deleteCustomField(id) {
    Swal.fire({
      title: 'Are you sure, you want to delete this Custom field?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        this.customFieldService.deleteCustomField(id).subscribe((res) => {
          this.toastService.success(res?.data);
          this.order = []
          this.loadCustomField()
        })
      }})
  }

  getDropDownOptionValue(valArr) {
    let returnArr = [];
    if (valArr && valArr?.length > 0) {
      valArr.map(item => {
        returnArr.push(item.value);
        return returnArr;
      })
    }
    return returnArr;

  }

  onSubmitCustomField() {
    this.order.push(this.CustomFieldPTable.data.length + 1)
    if (!this.customFieldForm.valid) {
      return;
    }
    this.isLoading = true;
    let payload: any = {
      fieldName: this.customFieldFormValue.fieldName.value,
      type: this.customFieldFormValue.type.value,
      options: this.getDropDownOptionValue(this.customFieldFormValue.options.value),
      indexNum: this.isCustomFieldEdited ? (Number(this.customFieldFormValue.order.value) - 5) : this.CustomFieldPTable.data?.length,
      editable: this.customFieldFormValue.editable.value,
    }

    if (!this.isCustomFieldEdited) {
      this.customFieldService
        .createCustomField(payload)
        .subscribe((res) => {
          // this.customFieldForm.reset()
          this.toastService.success(res?.message);
          this.initForm();
          const control = this.OptionieldsControl;
          this.order = []
          this.clearFormArray(control)
          this.loadCustomField()
          this.isLoading = false;
          this.modelService.dismissAll()
        }, (error) => {
          this.isLoading = false
          this.toastService.success(error?.message || 'Something went wrong');
        });
    } else {
      payload.fieldId = this.customFieldFormValue.fieldId.value;
      this.customFieldService.updateCustomField(payload).subscribe((res) => {
        this.isCustomFieldEdited = false
        // this.customFieldForm.reset()
        this.initForm()
        const control = this.OptionieldsControl;
        this.order = []
        this.clearFormArray(control)
        this.loadCustomField()
        this.toastService.success(res?.message);

        this.isLoading = false
        this.modelService.dismissAll()
      }, (error) => {
        this.isLoading = false
        this.toastService.success(error?.message || 'Something went wrong');
      })
    }
  }

  getValues(values) {

  }
  ngOnDestroy(): void {
    this.CustomFieldSearchSubscription &&
      this.CustomFieldSearchSubscription.unsubscribe();

  }
}
