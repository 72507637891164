<div class="d-flex flex-column">
  <ul
    ngbNav
    [(activeId)]="activeId"
    #companyNav="ngbNav"
    class="nav-tabs rounded mb-3"
  >
    <li [ngbNavItem]="1">
      <a ngbNavLink>OVERVIEW</a>
      <ng-template ngbNavContent>
        <rest-password-overview></rest-password-overview>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink>RESET PASSWORD</a>
      <ng-template ngbNavContent>
        <div class="card" *ngIf="!isUserDetected">
          <div class="card-header flexi-header p-4">
            <h3>RESET PASSWORD</h3>
          </div>

          <div class="card-body">
            <form [formGroup]="getUserForm">
              <div class="row">
                <div class="col-md-4">
                  <div class="row mb-2">
                    <div class="col-12 mt-1">
                      <div class="form-group">
                        <label
                          class="pb-2 form-bg font-weight-bold"
                          for="exampleInputEmail1"
                        >
                          Staff ID</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          style="height: 40px; width: 200px"
                          formControlName="staffId"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                class="btn btn-add p-1 m-1 pl-3 pr-3"
                [disabled]="!getUserForm.valid"
                (click)="getUserBYStaffId()"
              >
                Submit
              </button>
              <button
                class="btn btn-clear p-1 m-1 pl-3 pr-3"
                (click)="onClear()"
              >
                Clear
              </button>
            </form>
          </div>
        </div>
        <div class="card" *ngIf="isUserDetected">
          <div class="card-header flexi-header">
            <h3>RESET PASSWORD</h3>
          </div>
          <div class="card-body">
            <form [formGroup]="resetPasswordForm">
              <div class="row">
                <div class="col-md-6">
                  <div class="row mb-2">
                    <div class="col-12 mt-1">
                      <div class="form-group">
                        <label class="pb-2 form-bg" for="exampleInputEmail1"
                          >Name</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          placeholder=" Name"
                          disabled
                          formControlName="name"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-12 mt-1">
                      <div class="form-group">
                        <label class="pb-2 form-bg" for="exampleInputEmail1"
                          >Selected staff ID</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          placeholder=" Staff ID"
                          disabled
                          formControlName="staffId"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-12 mt-1">
                      <div class="form-group">
                        <label class="pb-2 form-bg" for="exampleInputEmail1"
                          >Business Unit
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          formControlName="businessUnit"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row mb-2">
                    <div class="col-12 mt-1">
                      <div class="form-group">
                        <label class="pb-2 form-bg" for="exampleInputEmail1"
                          >Password
                        </label>
                        <input
                          [type]="passwordvisible ? 'text' : 'password'"
                          class="form-control"
                          placeholder=" Password"
                          [ngClass]="{
                            'is-invalid':
                              submitted &&
                              resetPasswordForm.get('password').errors
                          }"
                          formControlName="password"
                        />
                        <div class="eye-slash">
                          <i [class]="passwordvisible  ? 'fa fa-eye fa-lg'  : 'fa fa-eye-slash fa-lg'" aria-hidden="true"  (click)="passwordVisible()"
                            *ngIf="passwordvisible  ? 'passwordvisible' : '!passwordvisible'  "

                          ></i>
                        </div>
                        <div *ngIf="resetPasswordForm.get('password').errors"  class="invalid-feedback d-block">
                          <small  *ngIf="resetPasswordForm.get('password').errors.required"  >
                            Password is required
                          </small>
                          <br  *ngIf="resetPasswordForm.get('password').errors.required " />
                          <small *ngIf="resetPasswordForm.get('password').errors.hasNumber" >
                            Atleast one numeric letter is required
                          </small>
                          <br *ngIf="resetPasswordForm.get('password').errors.hasNumber"  />

                          <small  *ngIf="resetPasswordForm.get('password').errors.hasSpecialCharacters" >
                            Atleast one special character is required
                          </small>
                          <br *ngIf="resetPasswordForm.get('password').errors.hasSpecialCharacters" />




                          <small   *ngIf="resetPasswordForm.get('password').errors.hasSmallCase"  >
                            Atleast one small character is required
                          </small>
                          <br *ngIf="resetPasswordForm.get('password').errors.hasSmallCase"  />
                          <small *ngIf="resetPasswordForm.get('password').errors.hasCapitalCase" >
                          Atleast one capital character is required
                        </small>
                        <br  *ngIf="resetPasswordForm.get('password').errors.hasCapitalCase"  />
                          <small   *ngIf=" resetPasswordForm.get('password').errors.minlength" >
                            Minimum length should be {{resetPasswordForm.get('password').errors.length}} characters
                          </small>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-12 mt-1">
                      <div class="form-group">
                        <label class="pb-2 form-bg" for="exampleInputEmail1"
                          >Confirm password
                        </label>

                        <input
                          class="form-control"
                          [type]="confirmPasswordvisible ? 'text' : 'password'"
                          placeholder=" Confirm Password"
                          [ngClass]="{
                            'is-invalid':
                              submitted &&
                              resetPasswordForm.get('confirmPassword').errors
                          }"
                          formControlName="confirmPassword"
                        />
                        <div class="eye-slash">
                          <i
                            [class]="
                              confirmPasswordvisible
                                ? 'fa fa-eye fa-lg'
                                : 'fa fa-eye-slash fa-lg'
                            "
                            aria-hidden="true"
                            (click)="confirmPasswordVisible()"
                            *ngIf="
                              confirmPasswordvisible
                                ? 'confirmPasswordvisible'
                                : '!confirmPasswordvisible'
                            "
                          ></i>
                        </div>

                        <div *ngIf="resetPasswordForm.get('confirmPassword').errors" class="invalid-feedback d-block" >
                          <small *ngIf="resetPasswordForm.get('confirmPassword').errors?.required">
                            Confirm password is required
                          </small>
                        </div>
                        <div class="invalid-feedback d-block" *ngIf="!resetPasswordForm.get('confirmPassword').errors && resetPasswordForm.hasError('mismatch')">
                          <small>
                          Password doesn't Match
                        </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                class="btn btn-add p-1 m-1 pl-3 pr-3" [disabled]="!resetPasswordForm.valid"
                (click)="onPasswordRest()"
              >
                Reset password
              </button>
              <button class="btn btn-clear p-1 m-1 pl-3 pr-3" type="reset">
                Clear
              </button>
              <button
                class="btn btn-add p-1 m-1 pl-3 pr-3"
                (click)="overview()"
              >
                Back
              </button>
            </form>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="companyNav"></div>
</div>
