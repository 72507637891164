<div class="top-header rounded bg-white mb-3">
  <div>
    <div>Planned Flexi Hrs</div>
    <div class="hrs-text red-text">
      {{ data == null ? "0.00" : (data.one | number: "1.2-2") }}
    </div>
  </div>
  <div>
    <div>Planned Flexi Staff</div>
    <div class="hrs-text red-text">
      {{ data == null ? "0.00" : (data.two | number: "1.2-2") }}
    </div>
  </div>
  <div>
    <div class="red-text">Booked Flexi Hrs</div>
    <div class="hrs-text red-text">
      {{ data == null ? "0.00" : (data.three | number: "1.2-2") }}
    </div>
  </div>
  <div>
    <div class="red-text">Booked Flexi staff</div>
    <div class="hrs-text red-text">
      {{ data == null ? "0.00" : (data.four | number: "1.2-2") }}
    </div>
  </div>
  <div>
    <div class="red-text">Planned Assigned Hrs</div>
    <div class="hrs-text red-text">
      {{ data == null ? "0.00" : (data.five | number: "1.2-2") }}
    </div>
  </div>
  <div>
    <div class="red-text">Planned Assigned Staff</div>
    <div class="hrs-text red-text">
      {{ data == null ? "0.00" : (data.six | number: "1.2-2") }}
    </div>
  </div>
</div>
