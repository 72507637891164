import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StorageService } from 'src/app/core/service/storage.service';
import { queryParam } from 'src/app/core/utils/helper';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BoardSetupService {

  private baseUrl: string;
  private get Headers(): HttpHeaders {
    return new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }
  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {
    this.baseUrl = environment.baseUrl;
  }
  
  getAllBoardsOverview(data): Observable<any> {
    return this.http.get(`${this.baseUrl}/wall/read?${queryParam(data)}`, {
      headers: this.Headers,
    }).pipe(map((res) => res as any))
  }

  getAllReportedPosts(data): Observable<any> {
    return this.http.get(`${this.baseUrl}/wall/reportedPosts/?${queryParam(data)}`, {
      headers: this.Headers,
    }).pipe(map((res) => res as any))
  }

  getAllReportedComments(data): Observable<any> {
    return this.http.get(`${this.baseUrl}/wall/reportedComments/?${queryParam(data)}`, {
      headers: this.Headers,
    }).pipe(map((res) => res as any))
  }

  getAllReportedQuestionarie(data): Observable<any> {
    return this.http.get(`${this.baseUrl}/wall/getWallPostDetails?${queryParam(data)}`, {
      headers: this.Headers,
    }).pipe(map((res) => res as any))
  }

  // getAllReportedQuestionarie(data): Observable<any> {
  //   return this.http.get(`${this.baseUrl}/wall/​?${queryParam(data)}`, {
  //     headers: this.Headers,
  //   });
  // }

  createWall(data): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/wall/add`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  updateWall(data, id): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/wall/update`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  exportQuestionnire(id: string): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/wall/exportWallPost`, {_id: id}, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  exportWall(id: string): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/post/exportWall`, {_id: id})
      .pipe(map((res) => res as any));
  }

  getOneWall(id: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/wall/${id}`);
  }

  changeReportedPostStatus(id: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/reportPost/reportComment/${id}`);
  }

  changeReportedCommentStatus(id: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/reportPost/reportComment/${id}`);
  }

  deleteEmojies(id: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/common/emoji/remove`, {emojiId : id})
    .pipe(map((res) => res as any));
  }

  getAllEmojies(): Observable<any> {
    return this.http.get(`${this.baseUrl}/common/emoji/getEmojis`);
  }

  uploadEmojiFile(fileToUpload) {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return this.http.post(`${this.baseUrl}/common/emoji/uploadEmoji`, formData)
      .pipe(map((res) => res as any))
  }

  updateReportedPost(id: string, data) {
    return this.http.post(`${this.baseUrl}/wall/reviewPost`, {postId: id, status: data}, {
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }

  updateReportedComment( data) {
    return this.http.post(`${this.baseUrl}/wall/updateStatus`, data, {
      headers: this.Headers,
    }).pipe(map((res) => res as any))
  }
}
