import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationService } from 'src/app/users/service/notification/notification.service';
import * as fileSaver from 'file-saver';
import { environment } from 'src/environments/environment';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { stringify } from 'querystring';

@Component({
  selector: 'app-view-notification',
  templateUrl: './view-notification.component.html',
  styleUrls: ['./view-notification.component.scss'],
})
export class ViewNotificationComponent implements OnInit {
  AudienceCollapsed: boolean = false;
  StatusCollapsed: boolean = false;
  @Input() notificationViewData;
  acknowledgedUserList = [];
  unreadUserList = [];
  active = 1;
  @Output() overViewNotification = new EventEmitter<boolean>();
  public toastService: ToastrService;
  acknowledgeTable: {
    page: number;
    limit: number;
    from: string;
  };
  constructor(private notificationService: NotificationService) {

    this.acknowledgeTable = {
      page: 1,
      limit: 25,
      from: 'acknowledged',
    };
  }

  ngOnInit() {
    this.getAcknowledgedAndUnreadUserData(
      this.notificationViewData?._id,
      'acknowledged'
    );
    this.getAcknowledgedAndUnreadUserData(this.notificationViewData?._id, "unreadUsers");
  }

  overview() {
    this.overViewNotification.next(false);
  }

  getAcknowledge(notification) {
    let count = isNaN(
      notification.notifyAcknowledgedUsers.length /
        notification.notifyOverAllUsers.length
    )
      ? 0
      : notification.notifyAcknowledgedUsers.length /
        notification.notifyOverAllUsers.length;
    return count * 100;
  }
  getNotificationUsres(notificationViewData) {
    return 100 - this.getAcknowledge(notificationViewData);
  }
  onExport() {
    const data = {
      date: new Date(new Date().setHours(0, 0, 0, 0)).toString(),
      notificationId: this.notificationViewData._id,
    };
    this.notificationService.exportNotification(data).subscribe((data) => {
      if (data?.data?.csvLink) {
        this.notificationService
          .downloadUrl(data.data.csvLink)
          .subscribe((content) => {
            var a = document.createElement('a');
            a.href = 'data:text/csv,' + content;
            a.target = '_blank';
            a.download = 'notifications_' + new Date() + '.csv';
            document.body.appendChild(a);
            a.click();
          });
      }
      // window.open(`${environment.imageUrl}/${data.data.csvLink}`)
    });
  }

  downloadAttachmentOfNOtification(attachment) {
    console.log(JSON,stringify(attachment));
    if(!attachment) {
      this.toastService.error('No attachment found');
      return;
    }

    var lastFileName = attachment.split('/');
    lastFileName = lastFileName[lastFileName.length - 1];
    this.notificationService
      .getMimeType({
        fileName: lastFileName,
      })
      .subscribe((data) => {
        // $scope.message('success', 'please wait, file is downloading.')
        this.notificationService
          .downloadAnyUrl(attachment)
          .subscribe((content) => {
            fileSaver.saveAs(
              new Blob([content], { type: data.type }),
              attachment
            );
          },(error) => {
            if(error.status === 403 && error.url?.includes('amazonaws.com')){
              this.notificationService.downloadFile(error.url).subscribe(
                (content) => {
                  fileSaver.saveAs(new Blob([content], { type: data.type }), attachment);
                },
                (error) => {
                  console.error('Error downloading file:', error);
                }
              );
            }
            console.error('Error downloading file:', error);
          })
      });
  }
  // get acknowledged and unread user data
  getAcknowledgedAndUnreadUserData(id, type) {
    // if(type === 'unreadUsers' && this.unreadUserList.length>0){
    //   return;
    // }else if(this.acknowledgedUserList.length>0){
    //   return;
    // }
    this.acknowledgeTable.from = type
    const subscribe = this.notificationService
      .readAcknowledgedAndUnreadUser(id, this.acknowledgeTable)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const { data } = response;
          if (type === 'unreadUsers') {
            this.unreadUserList = data;
            return;
          }
          this.acknowledgedUserList = data;
        },
        (error) => {
          this.toastService.error('No list found');
        }
      );
  }

}
