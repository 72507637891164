import { Component, Injectable, OnInit } from '@angular/core';
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ReportService } from 'src/app/users/service/operations/report.service';
import { SetupService } from 'src/app/users/service/operations/setup.service';
import * as fileSaver from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { finalize, map } from 'rxjs/operators';
import { LOADING_MESSAGE } from 'src/app/constants';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '-';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);

      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1].padStart(10, '0'), 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? date.day +
          this.DELIMITER +
          parseInt(date.month.toString().padStart(10, '0'), 10) +
          this.DELIMITER +
          date.year
      : '';
  }
}

@Component({
  selector: 'app-list-cancellation',
  templateUrl: './list-cancellation.component.html',
  styleUrls: ['./list-cancellation.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class ListCancellationComponent implements OnInit {
  buArr = [];
  buLabelArr = [];
  bu = null;
  cancelArr = [];
  cancelHours = null;
  startdate: NgbDateStruct;
  enddate: NgbDateStruct;
  buLoader: boolean = true;
  allBuShift: any[];
  activeId = 1;
  isLoader = false;
  loadingMessage = LOADING_MESSAGE;

  constructor(
    private setupService: SetupService,
    private reportService: ReportService,
    private toastService: ToastrService
  ) {}

  ngOnInit() {
    this.getAllBu();
    for (var i = 1; i < 25; i++) {
      this.cancelArr.push(i.toString());
    }
  }

  getAllBu() {
    this.buLoader = true;
    const buSubscribe = this.setupService
      .getAllBuShiftModule()
      .pipe(
        finalize(() => {
          this.buLoader = false;
          buSubscribe.unsubscribe();
        }),
        map((val) => {
          // Adding 'all' option to Select All Bu
          val['data']['businessUnitList'].unshift({
            _id: 'all',
            orgName: 'Select All Business Units',
          });
          return val;
        })
      )
      .subscribe(
        (res: any) => {
          if (res['data']['businessUnitList']) {
            this.allBuShift = res['data']['businessUnitList'];
          } else {
            this.allBuShift = [];
          }
        },
        (error) => {
          this.allBuShift = [];
        }
      );
  }

  addBu() {
    if (this.bu._id === 'all') {
      //removing selected bu before selection of All bu
      this.buArr = [];
      this.buLabelArr = [];
      //Adding all bu except 1st Select All option
      this.allBuShift.forEach((el, i) => {
        if (i > 0) {
          this.buArr.push(el._id);
        }
      });
      this.buLabelArr.push('All Business Units Selected');
    } else if (this.buArr.includes(this.bu._id)) {
      this.toastService.info(
        'Business unit already selected',
        'Already Selected'
      );
    } else {
      this.buArr.push(this.bu._id);
      this.buLabelArr.push(this.bu.orgName);
      this.bu = null;
    }
  }

  clearLabel(i) {
    if (this.buLabelArr.includes('All Business Units Selected')) {
      this.buArr = [];
      this.buLabelArr = [];
    } else {
      this.buArr.splice(i, 1);
      this.buLabelArr.splice(i, 1);
    }
  }

  downloadCsv() {
    this.isLoader = true;
    const filePayload = {
      date: moment().format('MM-DD-YYYY') + ' 00:00:00 GMT+0800',
      businessUnitId: this.buArr,
      startDate:
        moment()
          .set({
            y: this.startdate.year,
            M: this.startdate.month - 1,
            D: this.startdate.day,
          })
          .format('MM-DD-YYYY') + ' 00:00:00 GMT+0800',
      endDate:
        moment()
          .set({
            y: this.enddate.year,
            M: this.enddate.month - 1,
            D: this.enddate.day,
          })
          .format('MM-DD-YYYY') + ' 00:00:00 GMT+0800',
      cancelHours: this.cancelHours,
    };

    this.reportService
      .listOfCancellation(filePayload)
      .pipe(
        finalize(() => {
          this.isLoader = false; // Stop loader when complete
        })
      )
      .subscribe(
        (res) => {
          const filename = res['data']['csvLink']
            .split('\\')
            .pop()
            .split('/')
            .pop();
          if (filename != null) {
            this.downloadFile(filename);
          }
        },
        (error) => {
          this.toastService.error(`Something went wrong!`);
        }
      );
  }

  downloadFile(n) {
    this.reportService
      .downloadFile(n)
      .pipe(
        finalize(() => {
          this.bu = null;
          this.buArr = [];
          this.buLabelArr = [];
          this.cancelHours = null;
          this.startdate = null;
          this.enddate = null;
        })
      )
      .subscribe(
        (res) => {
          if (res) {
            fileSaver.saveAs(new Blob([res], { type: 'text/csv' }), n);
          }
        }
      );
  }

  clearAllBu() {
    this.buArr = [];
    this.buLabelArr = [];
  }
}
