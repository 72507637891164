<form [formGroup]="setupForm">
  <div class="card mb-3">
    <div class="card-header flexi-header p-4">
      <h3>QUICK NAVIGATION</h3>
    </div>
    <div class="card-body p-5">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 " *ngFor="let check of useFullLinksArray">
          <label class="container">{{check.title}}
            <input #el (change)="onCheckBoxChanges(el, check.title)" type="checkbox" [checked]="check.isChecked">
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <button class="btn btn-submit update-button" [disabled]="isUpdateLoading " (click)="onSubmitSetupForm()"><i
          class="fa" [ngClass]="{'fa-spin fa-spinner': isUpdateLoading, '': !isUpdateLoading}"></i> Update</button>
    </div>
  </div>
</form>