import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, switchMap, take, tap } from 'rxjs/operators';
import { LoginForm, StorageItem } from 'src/app/core/model/definition.model';
import { StorageService } from 'src/app/core/service/storage.service';

import { AuthService } from 'src/app/users/service/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { UsersService } from 'src/app/users/service/user-service/users.service';
import { generateImageUrl } from 'src/app/core/utils/helper';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public login: LoginForm;
  public userLoginForm: FormGroup;
  public submitted: boolean = false;
  public passwordvisible: boolean = false;
  public favicon: string = localStorage.getItem("favicon") || "";
  isLoading = false;
  public companyName;
  companyLogo: string;
  loginCredentials: LoginForm;
  favIcon: HTMLLinkElement = document.querySelector('#favIcon');
  isRegistered:boolean;
  constructor(
    private router: Router,
    private auth: AuthService,
    public toastService: ToastrService,
    private storageService: StorageService,
    private formBuilder: FormBuilder,
    public titleService: Title,
    private route: ActivatedRoute,
    private userService: UsersService,
  ) {
    router.events
      .pipe(
        take(1),
        switchMap(() => this.route.params)
      )
      .subscribe((val) => {
       this.companyName = val.name;
        const urlCompany: StorageItem = {
          key: 'urlCompanyName',
          value: val.name,
        };
        this.storageService.addItem(urlCompany);
        this.titleService.setTitle(this.companyName);
        this.initForm();
        this.userLoginForm.reset();

        if (this.companyName != 'xforce' && this.companyName != undefined) {
          this.getLogo(this.companyName);
        } else {
          this.companyLogo = './assets/images/xforce.png';
       
          this.favIcon.href =  !!this.favicon? this.favicon : 'favicon.ico';
          this.userLoginForm
            .get('companyId')
            .setValidators(Validators.required);
        }
      });
  }

  ngOnInit(): void {
    localStorage.removeItem("favicon")
  }

  passwordVisible() {
    this.passwordvisible = !this.passwordvisible;
  }

  private initForm() {
    this.userLoginForm = this.formBuilder.group({
      staffId: ['', [Validators.required]],
      password: ['', [Validators.required]],
      companyId: [''],
    });
  }

  get f() {
    return this.userLoginForm.controls;
  }

  navigateToForgetPassword() {
    this.router.navigate(['/users/forget-password/', this.companyName]);
  }
  navigateToPasswordExpired(){
    this.router.navigate(['/users/password-expired/', this.companyName]);
  }

  navigateToWelcomeScreen(){
    this.router.navigate(['/users/WelcomeScreen/', this.companyName]);
  }

  navigateToTerms() {
    this.router.navigate(['/users/terms-conditions/', this.companyName]);
  }

  navigateToSupport() {
    this.router.navigate(['/users/support/', this.companyName]);
  }

  pressEnter(event: any) {
    if (event.keyCode == 13) {
      this.onLogin();
    }
  }

  getLogo(companyName) {
    const logo = this.auth
      .fetchCompanyId(companyName)
      .pipe(
        finalize(() => {
          logo.unsubscribe();
        })
      )
      .subscribe(
        (res: any) => {
          if (res.data) {
            const logo = generateImageUrl(environment.imageUrl +'/'+ res.data?.logo);
            this.companyLogo = logo
            localStorage.setItem("favicon", logo)
            this.favIcon.href = logo;
          } else {
            this.companyLogo = './assets/images/xforce.png';
            this.favIcon.href =  !!this.favicon? this.favicon : 'favicon.ico';
          }
        },
        () => {
          this.toastService.error(
            'Unable to fetch Company Details',
            'Something went wrong'
          );
          this.companyLogo = './assets/images/xforce.png';
          this.favIcon.href =  !!this.favicon? this.favicon : 'favicon.ico';
        }
      );
  }

  getCompany(urlName) {
    this.isLoading = true;
      this.loginCredentials = <LoginForm>this.userLoginForm.value;

      this.auth.fetchCompanyId(urlName).subscribe(
        (res) => {
          if (res?.data) {
            const companyID = res.data._id;
            this.loginCredentials['companyId'] = companyID;
            return this.auth.login(this.loginCredentials)
            .subscribe(
              (res) => {
                if(res?.data?.data){
                  this.auth.pwdChangeDuration().subscribe((response)=>{
                    this.isRegistered = response?.data?.registration;
                    localStorage.setItem("privilegeFlags", JSON.stringify(res['data']['data']['privilegeFlags']));
                    localStorage.setItem("loggedInUserName", JSON.stringify(res['data']['data']['name']));
                    localStorage.setItem("loggedInUserId", res['data']['data']['userId']);
                    localStorage.setItem("companyDetails",JSON.stringify( res['data']['data']['companyId'] || null));
                    if(this.isRegistered)
                    {
                      localStorage.setItem("otpToken", (response?.data?.token));
                      this.navigateToWelcomeScreen()
                    }
                    else{
               this.toastService.success('Logged in successfully');
               this.userService.setUserProfileData(res);
               this.router.navigateByUrl('/users/home');
                    }
                  })
      
                }
                else if(res?.data?.passwordExpired){
                  this.toastService.error(res?.data?.message, 'Failed', {
                    timeOut: 3000,
                    positionClass: 'toast-top-right',
                  });
                  this.navigateToPasswordExpired();
                }
                else{
                  this.toastService.error(res?.data?.message, 'Failed', {
                    timeOut: 3000,
                    positionClass: 'toast-top-right',
                  });
                }
                this.isLoading = false;
      
              },
              (error) => {
                this.isLoading = false;
                let errorMessage = error?.error?.message;
               this.toastService.error(errorMessage, 'Failed', {
                  timeOut: 3000,
                  positionClass: 'toast-top-right',
                });
              }
            );
          } else {
            this.toastService.error('This company name does not exist, please enter correct name', 'Failed', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
            });
          }
        },
        (error) => {
          this.toastService.error(
            "This company name does not exist, please enter correct name",
            'Failed',
            {
              timeOut: 3000,
              positionClass: 'toast-top-right',
            }
          );
          this.isLoading = false;
        }
      );
  }

  onLogin() {
   if (!this.userLoginForm.valid) {
      this.submitted = true;
      return;
    }

    if (this.companyName != 'xforce' && this.companyName != undefined) {
     this.getCompany(this.companyName);
    } else {
      this.getCompany(this.userLoginForm.controls.companyId.value);
    }
  }
}
