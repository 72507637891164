import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-forms',
  templateUrl: './my-forms.component.html',
  styleUrls: ['./my-forms.component.scss']
})
export class MyFormsComponent implements OnInit {
  activeId = 1;
  questionId;
  customFormId;
  formName;
  formTitle;
  viewStatus = false;
  constructor() { }

  ngOnInit(): void {
  }
  question(id:string){
    this.activeId = 2;
    this.questionId = id;
  }
  customForm(id:string){
    this.activeId = 2;
    this.customFormId = id;
  }
  overview(){
    this.activeId = 1;
  }
  formType(e){
    this.formName = e;
  }
  title(e){
    //this.viewStatus = true;
    this.formTitle = e;
  }
  viewPage(e){
    this.customFormId = e;
  }
  formTitleviewPage(e){
    this.formTitle = e;
    this.formName ='viewQuestion';
    this.activeId = 2;
  }
}
