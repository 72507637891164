<div class="row">
  <div class="col-md-6 col-sm-6">
    <div class="card my-3 w-100">
      <div class="card-header flexi-header">
        <h3 style="display: inline-block">NOTIFICATION DETAIL</h3>
        <button class="btn pull-right btn-outline-light" (click)="overview()">
          <i class="fa fa-chevron-left" aria-hidden="true"></i> Back
        </button>
      </div>

      <div class="card-body">
        <div class="panel-body">
          <div class="row m-0">
            <div class="col-md-6">
              <div class="form-group">
                <label> {{ notificationViewData.title }}</label>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>{{ notificationViewData.activeFrom | date }}</label>
              </div>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-md-6">
              <div class="form-group">
                <label> {{ notificationViewData.subTitle }}</label>
              </div>
            </div>
            <!-- <div class="col-md-6">
              <div class="form-group">
                <label>{{ notificationViewData.activeFrom | date }}</label>
              </div>
            </div> -->
          </div>
          <hr />
          <div class="row m-0">
            <div class="col-md-6">
              <div class="form-group">
                <label>Effective From</label>
                <div class="form-text-conteol ng-binding">
                  {{ notificationViewData?.effectiveFrom | date}}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Effective Till</label>
                <div class="form-text-conteol ng-binding">
                  {{ notificationViewData?.effectiveTo | date}}
                </div>
              </div>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-md-6">
              <div class="form-group">
                <label>Main Category</label>
                <div class="form-text-conteol ng-binding">
                  {{ notificationViewData?.subCategoryId?.categoryId?.name }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Sub Category </label>
                <div class="form-text-conteol ng-binding">
                  {{ notificationViewData?.subCategoryId?.name }}
                </div>
              </div>
            </div>
          </div>

          <div class="row m-0">
            <div class="col-md-6">
              <button class="btn btn-add" (click)="downloadAttachmentOfNOtification(notificationViewData.notificationAttachment)">Click to download attachment</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 col-sm-6">
    <div class="card my-3 w-100">
      <div class="card-header flexi-header">
        <h3>Acknowledgement Status</h3>
      </div>
      <div class="card-body p-3">
        <div class="card my-3 w-100">
          <div class="card-header flexi-header" (click)="collapse1.toggle()" [attr.aria-expanded]="!AudienceCollapsed">
            <h3>
              Intended Audience
              {{ notificationViewData?.notifyOverAllUsers?.length }} Staff
            </h3>
            <i class="fa pull-right fa-lg mt-1" style="color:white" [ngClass]="
                  AudienceCollapsed
                    ? 'fa-angle-double-down'
                    : 'fa-angle-double-up'
                " aria-hidden="true"></i>
          </div>
          <div class="card-body" #collapse1="ngbCollapse" [(ngbCollapse)]="AudienceCollapsed">
            <table class="table table-borderless">
              <tbody>
                <tr>
                  <td class="text-left">
                    Acknowledged
                    <i class="fa fa-spinner" aria-hidden="true"></i>
                    {{ getAcknowledge(notificationViewData) + "%" }} :
                  </td>
                  <td>
                    {{ notificationViewData?.notifyAcknowledgedUsers.length }}
                  </td>
                </tr>
                <tr>
                  <td class="text-left">
                    Unread <i class="fa fa-spinner" aria-hidden="true"></i>
                    {{ getNotificationUsres(notificationViewData) + "%" }} :
                  </td>
                  <td>{{ notificationViewData?.notifyUnreadUsers.length }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card my-3 w-100">
          <div class="card-header flexi-header" (click)="collapse2.toggle()" [attr.aria-expanded]="!StatusCollapsed">
            <h3>
              Notification Status
            </h3>
            <i class="fa pull-right fa-lg mt-1" style="color:white" [ngClass]="
                  StatusCollapsed
                    ? 'fa-angle-double-down'
                    : 'fa-angle-double-up'
                "aria-hidden="true"></i>
          </div>
          <div class="card-body" #collapse2="ngbCollapse" [(ngbCollapse)]="StatusCollapsed">
            <table class="table table-borderless">
              <tbody>
                <tr>
                  <td class="text-left">Active From :</td>
                  <td>
                    {{ notificationViewData?.activeFrom | date}}
                  </td>
                </tr>
                <tr>
                  <td class="text-left">Active To :</td>
                  <td>{{ notificationViewData?.activeTo | date }}</td>
                </tr>
                <hr />
                <tr>
                  <td class="text-left">Business Unit :</td>
                  <td>{{ notificationViewData?.businessUnitId.orgName }}</td>
                </tr>
                <tr>
                  <td class="text-left">Appointments :</td>
                  <td>{{notificationViewData?.assignUsers?.length && notificationViewData?.assignUsers[0]?.appointments[0]?.name }}</td>
                </tr>
                <tr>
                  <td class="text-left">Select Users :</td>
                  <td>{{notificationViewData?.assignUsers?.length && notificationViewData?.assignUsers[0]?.user[0]?.name }}</td>
                </tr>
                <tr>
                  <td class="text-left">Method :</td>
                  <td>
                    {{
                    notificationViewData?.isDynamic === 0
                    ? "Static"
                    : "Dynamic"
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="d-flex flex-column p-3">
            <div class="my-3">
              <ul ngbNav [(activeId)]="active" #companyNav="ngbNav" class="nav-tabs rounded mb-3">
                <li [ngbNavItem]="1">
                  <a  (click)="getAcknowledgedAndUnreadUserData(notificationViewData._id, 'acknowledged')" ngbNavLink>Acknowledged</a>
                  <ng-template ngbNavContent>
                    <table class="table">
                      <tbody>
                        <tr *ngFor="
                            let item of acknowledgedUserList
                          ">
                          <td>
                            {{ item.name }}
                          </td>

                          <td>{{ item?.staffId }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </ng-template>
                </li>
                <li [ngbNavItem]="2">
                  <a ngbNavLink> Unread</a>
                  <ng-template ngbNavContent>
                    <table class="table">
                      <tbody>
                        <tr *ngFor="
                            let item of unreadUserList
                          ">
                          <td>
                            {{ item.name }}
                          </td>

                          <td>{{ item?.staffId }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </ng-template>
                </li>
                <li [ngbNavItem]="3">
                  <a ngbNavLink (click)="onExport()"> Export</a>
                  <ng-template ngbNavContent> </ng-template>
                </li>
              </ul>
              <div [ngbNavOutlet]="companyNav"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
