import { Pipe, PipeTransform } from '@angular/core';
import { isNumber } from '@ng-bootstrap/ng-bootstrap/util/util';

@Pipe({
  name: 'filter',
  pure: false,
})
export class FilterPipe implements PipeTransform {
  transform(value: any, searchTerm: any): any {
    if (searchTerm == null) {
      return value;
    }

    return value.filter((search) => {
      return (
        search.userInfo.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >
          -1 ||
        search.userInfo.staffId
          .toLowerCase()
          .indexOf(searchTerm.toLowerCase()) > -1
      );
    });
  }
}
