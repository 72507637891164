<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs rounded mb-3">
  <li [ngbNavItem]="1">
    <a ngbNavLink>Overview</a>
    <ng-template ngbNavContent>
      <div
        *ngIf="getLeaveTypeList.length !== 0; then thenBlock; else noData"
      ></div>
      <ng-template #thenBlock>
        <div class="card">
          <div class="card-body table-responsive p-0">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">LEAVE TYPE NAME</th>
                  <th scope="col">EXCEED</th>
                  <th scope="col">CREATED BY AND DATE</th>
                  <th scope="col">LAST MODIFIED BY AND DATE</th>
                  <th scope="col" *ngIf="leavetypeAccess">ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let leave of getLeaveTypeList">
                  <td>
                    <span class="font-weight-500">{{ leave.name }}</span>
                  </td>
                  <td>
                    {{
                      leave.isQuotaExceed
                        ? "Allowed to exceed quota"
                        : "Cannot exceed quota"
                    }}
                  </td>
                  <td>{{ leave.createdBy.name }}, {{ leave.createdAt }}</td>
                  <td>{{ leave.updatedBy.name }}, {{ leave.updatedAt }}</td>
                  <td *ngIf="leavetypeAccess">
                    <div class="d-flex">
                      <button
                        class="btn btn-warning btn-sm"
                        (click)="switchToEditDeletePage()"
                      >
                        Edit
                      </button>
                      <button
                        class="btn btn-danger btn-sm ml-2"
                        *ngIf="!predefineLeaveType.includes(leave.name)"
                        (click)="switchToEditDeletePage()"
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ng-template>
      <ng-template #noData>
        <div *ngIf="isLeaveTypeAvailable === 'no'">
          No Leave Types created yet!
        </div>
      </ng-template>
      <div class="card" *ngIf="leaveTypeListLoader">
        <div class="card-body p-0">
          <ngx-skeleton-loader
            count="6"
            [theme]="{
              width: 'calc(100% - 20px)',
              'border-radius': '6px',
              height: '44px',
              margin: '10px',
              display: 'block'
            }"
          ></ngx-skeleton-loader>
        </div>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="2" *ngIf="leavetypeAccess">
    <a ngbNavLink>Create/Edit</a>
    <ng-template ngbNavContent>
      <div class="card w-100">
        <div class="card-header bg-theme addNewLeave p-3">
          <h5 class="m-0">SETUP LEAVE GROUPINGS</h5>
          <button type="button" class="btn btn-info" (click)="open(leaveType)">
            Add New Leave Type
          </button>
        </div>
        <div class="card-body p-0">
          <div
            *ngIf="
              getLeaveTypeList && getLeaveTypeList.length !== 0;
              else staticList
            "
          >
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Leave Types</th>
                  <th scope="col">Can Exceed Quota ?</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let leave of getLeaveTypeList">
                  <td>
                    <span class="font-weight-500">{{ leave.name }}</span>
                  </td>
                  <td>{{ leave.isQuotaExceed ? "YES" : "NO" }}</td>
                  <td>
                    <div class="d-flex">
                      <button
                        class="btn btn-warning btn-sm mr-2"
                        (click)="editLeaveType(true, leave, editleaveType)"
                      >
                        Edit
                      </button>
                      <button
                        class="btn btn-danger btn-sm"
                        *ngIf="!predefineLeaveType.includes(leave.name)"
                        (click)="
                          this.deleteLeaveTypeId = leave._id;
                          openDeletemodal(leave.name)
                        "
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <ng-template #staticList>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Leave Types</th>
                  <th scope="col">Can Exceed Quota ?</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let leave of predefineLeaveTypeList; let i = index">
                  <td>
                    <span class="font-weight-500">{{ leave.name }}</span>
                  </td>
                  <td>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        [(ngModel)]="leave.isQuotaExceed"
                        value="true"
                        name="{{ leave.name }}"
                        id="{{ 'exceedcheck' + i }}"
                      />
                      <label
                        class="form-check-label"
                        for="{{ 'exceedcheck' + i }}"
                      >
                        Yes
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        [(ngModel)]="leave.isQuotaExceed"
                        value="false"
                        name="{{ leave.name }}"
                        id="{{ 'exceedcheck' + i }}"
                      />
                      <label
                        class="form-check-label"
                        for="{{ 'exceedcheck' + i }}"
                      >
                        No
                      </label>
                    </div>
                  </td>
                  <td>
                    <button
                      type="button"
                      class="btn btn-info btn-sm"
                      (click)="createLeave()"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="card-footer">
              <button
                type="button"
                class="btn btn-info"
                (click)="createLeave()"
              >
                Create
              </button>
            </div>
          </ng-template>
        </div>
      </div>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav"></div>

<!-- Add Leave type Modal -->
<ng-template #leaveType let-modal>
  <div class="modal-header bg-theme">
    <h5 class="modal-title" id="modal-basic-title">Add Leave Type</h5>
    <button
      type="button"
      class="close text-light"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="font-weight-500">Leave Type Name</label>
          <input
            class="form-control"
            name="name"
            [(ngModel)]="addNewLeaveType.name"
            required
          />
        </div>
        <div class="form-group">
          <label class="font-weight-500">Can Exceed Quota?</label><br />
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              id="inlineRadio1"
              value="true"
              name="leave"
              [(ngModel)]="addNewLeaveType.isQuotaExceed"
            />
            <label class="form-check-label" for="inlineRadio1">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              id="inlineRadio2"
              value="false"
              name="leave"
              [(ngModel)]="addNewLeaveType.isQuotaExceed"
            />
            <label class="form-check-label" for="inlineRadio2">No</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('Save click')"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-success"
      (click)="submitAddNewLeaveType()"
      [disabled]="loader"
    >
      Submit
      <span
        *ngIf="loader"
        class="spinner-border spinner-border-sm ml-2"
        role="status"
        aria-hidden="true"
      ></span>
    </button>
  </div>
</ng-template>

<!-- Edit Leave type Modal -->
<ng-template #editleaveType let-modal>
  <div class="modal-header bg-theme">
    <h5 class="modal-title" id="modal-basic-title">Edit Leave Type</h5>
    <button
      type="button"
      class="close text-light"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="font-weight-500">Leave Type Name</label>
          <input
            class="form-control"
            name="name"
            [(ngModel)]="editLeaveTypeDetail.name"
            [disabled]="predefineLeaveType.includes(editLeaveTypeDetail.name)"
          />
        </div>
        <div class="form-group">
          <label class="font-weight-500">Can Exceed Quota?</label><br />
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              id="inlineRadio1"
              [value]="true"
              [(ngModel)]="editLeaveTypeDetail.isQuotaExceed"
              name="{{ editLeaveTypeDetail.name }}"
            />
            <label class="form-check-label" for="inlineRadio1">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              id="inlineRadio2"
              [value]="false"
              [(ngModel)]="editLeaveTypeDetail.isQuotaExceed"
              name="{{ editLeaveTypeDetail.name }}"
            />
            <label class="form-check-label" for="inlineRadio2">No</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('Save click')"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-success"
      (click)="submitEditLeaveType(); modal.close('Save click')"
    >
      Submit
    </button>
  </div>
</ng-template>
