<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs rounded mb-3">
  <li [ngbNavItem]="1">
    <a ngbNavLink>DASHBOARD</a>
    <ng-template ngbNavContent>
      <app-dashboard></app-dashboard>
    </ng-template>
  </li>
  <li [ngbNavItem]="2" *ngIf="approveAccess">
    <a ngbNavLink>APPROVE</a>
    <ng-template ngbNavContent>
      <app-approve></app-approve>
    </ng-template>
  </li>
  <li [ngbNavItem]="3">
    <a ngbNavLink>SWAP RESTRICTIONS</a>
    <ng-template ngbNavContent>
      <swap-restrictions></swap-restrictions>
    </ng-template>
  </li>
  <li [ngbNavItem]="4" *ngIf="opsLeaveQuotaAccess">
    <a ngbNavLink>OPS LEAVE QUOTA</a>
    <ng-template ngbNavContent>
      <ops-leave-quota></ops-leave-quota>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav"></div>
