import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IMonthCalendarConfig } from 'ng2-date-picker';
import { Subscription, Subject, of } from 'rxjs';
import {
  map,
  debounceTime,
  distinctUntilChanged,
  mergeMap,
  delay,
  finalize,
} from 'rxjs/operators';
import { CompanyService } from 'src/app/admin/service/companyservice/company.service';
import {
  SortableHeader,
  SortEvent,
} from 'src/app/shared/directive/sortable.directive';
import { PaginatedTable } from 'src/app/core/model/pagination.model';

import { BoardSetupService } from 'src/app/users/service/board-setup/board-setup.service';
import { BussinessUnitService } from 'src/app/users/service/bussiness-unit/bussiness-unit.service';
import { ChannelService } from 'src/app/users/service/channel/channel.service';
import { CustomFieldService } from 'src/app/users/service/custom-field/custom-field.service';
import { UsersService } from 'src/app/users/service/user-service/users.service';
import { environment } from 'src/environments/environment';
import { COMMON_MESSAGES, getAssignedUsers } from 'src/app/core/utils/helper';

@Component({
  selector: 'app-create-edit-boards',
  templateUrl: './create-edit-boards.component.html',
  styleUrls: ['./create-edit-boards.component.scss'],
})
export class CreateEditBoardsComponent implements OnInit {
  isChannelEdited: boolean = true;
  categoryDropDownLoading: boolean = false;
  planBuUnitDropDownLoading: boolean = false;
  apppoinmentDropDownLoading: boolean = false;
  CustomFieldDropDownLoading: boolean = false;
  isLoading: boolean = false;
  isNominationOpen: boolean = false;
  categoryDropDown = [];

  activeId = 1;
  addedCustomfield;
  buDropDownToClear = null;
  apoinemntDropDownToClear = null;
  customFeildDropDownToClear = null;
  adminDropDownToClear = null;
  authorDropDownToClear = null;
  numberOfNomination: boolean = false;
  limitNomination: boolean = false;
  userDetailsEditIndex: number;
  isUserDetailsEdited: boolean = false;
  isBoardEdited: boolean = false;
  channelEditedId: string;
  timeElapsed = Date.now();
  today = new Date(this.timeElapsed);
  config: IMonthCalendarConfig = {
    format: 'MMM, YYYY',
    returnedValueType: 3,
    max: new Date(Date.now()).toUTCString(),
  };
  userDetails: any[] = [];
  userDropDown: any[] = [];

  categoryPTable;
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  private categorySearchSubscription: Subscription;
  public categorySearchInput = new Subject<KeyboardEvent>();
  @ViewChild('AddSubSection', { static: true })
  AddSubSection: TemplateRef<any>;
  wallCreateForm: FormGroup;
  userDetailsForm: FormGroup;
  createCategoryForm: FormGroup;
  nominationForm: FormGroup;
  channelCreateForm: FormGroup;
  editCategoryId: string;
  isCategoryEdited: boolean = false;
  planBuUnitDropDown: any[];
  appoinmentDropDown: any[];
  fieldNeedsToUpdate: any[];
  isAddButtonActive: boolean = false;
  categories = null;
  @Input() boardId: any = null;
  @Output() overViewEvent = new EventEmitter<any>();
  bussinesUnitplanPTable: {
    sortWith: string;
    sortBy: string;
    data: any[];
    page: number;
    limit: number;
    search: string;
    filter: { type: string };
  };
  appoinmentPTable: {
    sortWith: string;
    sortBy: string;
    data: any[];
    page: number;
    total: number;
    limit: number;
    search: string;
  };
  CustomFieldPTable: {
    sortWith: string;
    sortBy: string;
    data: any[];
    page: number;
    total: number;
    limit: number;
    search: string;
  };
  userPTable: {
    sortWith: string;
    sortBy: string;
    data: any[];
    page: number;
    limit: number;
    search: string;
  };
  adminPTable: {
    sortWith: string;
    sortBy: string;
    data: any[];
    page: number;
    limit: number;
    search: string;
  };
  adminDropDown: any;
  adminLoading: boolean;
  authorLoading: boolean;
  UpdateduserDetails: any[] = [];
  baseUrl = environment.imageUrl;
  onSortCategory({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.categoryPTable.sort = { key: column, order: direction as any };
    this.loadCategory();
  }

  onPageChangeCtegory(page: number) {
    this.categoryPTable.page = page;
    this.loadCategory();
  }

  onLimitChangeCategory() {
    this.loadCategory();
  }
  constructor(
    private modalService: NgbModal,
    private toastService: ToastrService,
    private userService: UsersService,
    private companyService: CompanyService,
    private formBuilder: FormBuilder,
    private buUnit: BussinessUnitService,
    private customFieldService: CustomFieldService,
    private channelService: ChannelService,
    private boardService: BoardSetupService
  ) {
    this.categoryPTable = {
      sortWith: 'createdAt',
      sortBy: 'desc',
      data: [],
      page: 1,
      limit: 10,
      total: 0,
      search: '',
    };
    this.bussinesUnitplanPTable = {
      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,

      limit: 8,

      search: '',
      filter: {
        type: 'plan',
      },
    };
    this.appoinmentPTable = {
      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,
      total: 0,
      limit: 10,

      search: '',
    };
    this.CustomFieldPTable = {
      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,
      total: 0,
      limit: 10,

      search: '',
    };
    this.userPTable = {
      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,

      limit: 10,

      search: '',
    };
    this.adminPTable = {
      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,

      limit: 10,

      search: '',
    };
    this.categorySearchSubscription = this.categorySearchInput
      .pipe(
        map((event) => {
          const target = event.target as HTMLTextAreaElement;
          return target.value;
        }),
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap((search) => of(search).pipe(delay(500)))
      )
      .subscribe((input) => {
        this.categoryPTable.page = 1;
        this.loadCategory();
      });
  }

  initForm() {
    this.wallCreateForm = this.formBuilder.group({
      wallName: ['', Validators.required],
      displayType: [null, Validators.required],
      postType: [null, Validators.required],
      isNomineeActive: [null, Validators.required],
      adminResponse: [null, Validators.required],
      postAnonymously: [null, Validators.required],
      isTaskActive: [null, Validators.required],
      nominationOnlyByAdmin: [false],


      quickNavEnabled: [false],
      bannerImage: ['', Validators.required],
      nominationPerUser: this.formBuilder.group({
        enabled: false,
        submissionLimit: '',
        submissionPeriod: '',
      }),


      maxNomination: this.formBuilder.group({
        enabled: false,
        submissionLimit: '',
        submissionPeriod: '',
      }),
      category: this.formBuilder.array([]),
    });
    this.channelCreateForm = this.formBuilder.group({
      name: ['', Validators.required],
      category: this.formBuilder.array([], [Validators.required]),
      status: [1, Validators.required],
    });
  }


  clearForm() {
    this.channelCreateForm.patchValue({
      name: '',
      status: ''
    });
    this.UpdateduserDetails = []
    this.userDetails = []
    this.isAddButtonActive = false
  }
  inituserDetailsFrom() {
    return (this.userDetailsForm = this.formBuilder.group({
      businessUnits: this.formBuilder.array([], [Validators.required]),
      buFilterType: [1, Validators.required],
      appointments: this.formBuilder.array([]),
      subSkillSets: this.formBuilder.array([]),
      user: this.formBuilder.array([]),
      admin: this.formBuilder.array([]),
      allBuToken: '',
      allBuTokenStaffId: '',
      customField: this.formBuilder.array([]),
    }));
  }
  categoryForm() {
    this.createCategoryForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9_]+.*$')]],
      status: [1, Validators.required],
    });
  }
  ngOnInit() {
    this.inituserDetailsFrom();
    this.initForm();

    this.categoryForm();

    this.loadCategory();
    this.boardId && this.getFormForEdit(this.boardId);
  }

  onCancelEdit() {

    this.initForm();
    this.isBoardEdited = false;
    this.boardId = null;

    this.userDetails.length = 0;
    this.overViewEvent.emit(null);
  }
  getFormForEdit(data: any) {
    this.isBoardEdited = true;
    this.userDetails = data.assignUsers;
    this.showCreateButton();
    this.UpdateduserDetails = getAssignedUsers(data?.assignUsers)

    const control = this.CategorytControl;
    data?.category.forEach((element) => {
      let field = this.formBuilder.group({
        categoryName: [
          element.categoryName,
          Validators.compose([Validators.required]),
        ],
      });
      control.push(field);
    });
    this.wallCreateForm.patchValue({
      wallName: data.wallName,
      displayType: data.displayType,
      postType: data.postType,
      isNomineeActive: data.isNomineeActive,
      nominationOnlyByAdmin: data.nominationOnlyByAdmin,
      nominationPerUser: {
        ...data.nominationPerUser
      },
      maxNomination: {
        ...data.maxNomination
      },
      adminResponse: data.adminResponse,
      postAnonymously: data.postAnonymously,
      isTaskActive: data.isTaskActive,
      quickNavEnabled: data.quickNavEnabled,
      bannerImage: data.bannerImage,
    });
  }

  showCreateButton() {
    this.isAddButtonActive = true
  }

  hideCreateButton() {
    this.isAddButtonActive = false
  }

  updateUserDetails(userDetails) {
    this.UpdateduserDetails = userDetails
  }

  ondeleteImage() {
    this.wallCreateForm.patchValue({
      bannerImage: '',
    });
  }

  iconUpload(event) {
    if (event.target.files && event.target.files.length) {
      let file: File = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        const fileUpload = this.companyService
          .uploadWallFile(file)
          .pipe(
            finalize(() => {
              fileUpload.unsubscribe();
            })
          )
          .subscribe(
            (data: any) => {
              this.toastService.success("Image uploaded successfully");
              this.wallCreateForm.patchValue({
                bannerImage: `${environment.imageUrl}/${data.data.filePath}`,
              });
            },
            (error: HttpErrorResponse) => {
              this.toastService.error(error.error.message);
            }
          );
      } else {
        this.toastService.error(COMMON_MESSAGES.UPLOAD_ONLY_IMAGE);
        event.target.value = '';
        file = null;
      }
    }
  }

  ///  category
  addCategory() {
    this.categoryForm();
    this.loadCategory();
    this.modalService.open(this.AddSubSection, {
      windowClass: 'modal-ui-fix right-align',
    });

    // this.loadAppoinment();
  }

  get CategorytControl(): FormArray {
    return this.wallCreateForm.get('category') as FormArray;
  }

  async addCategories(value) {
    if (value) {
      const Control = this.CategorytControl;
      const index = await Control.value.findIndex(
        (x) => x.categoryName === value.name
      );
      this.categories = []

      if (index > -1) {
        this.toastService.error(`${value.name} is already added`, '', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
      } else {
        let field = this.formBuilder.group({
          categoryName: [value.name, Validators.compose([Validators.required])],
        });
        Control.push(field);
        this.categories = []
      }
    }
  }

  oneditCategory(category) {
    this.editCategoryId = category._id;
    this.isCategoryEdited = true;
    this.createCategoryForm.patchValue({
      name: category.name,
      status: category.status,
    });
  }

  deleteCategories(index) {
    const Control = this.CategorytControl;

    Control.removeAt(index);
  }
  UpdateCatagory() {
    const subscribe = this.channelService
      .updateCategory(this.createCategoryForm.value, this.editCategoryId)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          this.isCategoryEdited = false;
          this.categoryForm();
          this.loadCategory();
          this.toastService.success('Category Updated', '', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        }
      );
  }
  createCategory() {

    // this.categoryDropDown.push(this.createCategoryForm.value)
    // this.toastService.success('Category Created', '', {
    //   timeOut: 3000,
    //   positionClass: 'toast-top-right',
    // });
    // this.createCategoryForm.patchValue({
    //   name: '',
    // });
    const subscribe = this.channelService
      .createCategory(this.createCategoryForm.value)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          this.loadCategory();
          this.categoryForm();
          this.toastService.success('Category Created', '', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
          this.createCategoryForm.patchValue({
            name: '',
         });
        }
      );
  }

  onDeleteCategory(id: string) {
    const subscribe = this.channelService
      .deleteCategory(id)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          this.loadCategory();
          this.toastService.error('Category Deleted', '', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        },(error) => {
          this.toastService.error('Something Went Wrong');
        }
      );
  }
  onSeachCategory(value) {
    this.categoryPTable.limit = 10;
    this.categoryPTable.search = value.term;

    this.loadCategory();
  }

  loadMoreCategoryItems(value) {
    this.categoryPTable.limit = this.categoryPTable.limit + 10;

    this.loadCategory();
  }

  onClearCategoryDropdown(value) {
    if (value === undefined) {
      // this.createUserForm.patchValue({
      //   appointmentId: null,
      // });
      this.categoryPTable.search = '';
      this.loadCategory();

      this.categoryDropDown = [];
      this.categoryPTable.data = [];
    }
  }

  loadCategory() {
    this.categoryDropDownLoading = true;
    const { data, total, ...rest } = this.categoryPTable;
    const subscribe = this.channelService
      .getAllCategory(rest)
      .pipe(
        finalize(() => {
          this.categoryDropDownLoading = false;
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const { count, data } = response.data;
          this.categoryPTable.data = data;
          this.categoryDropDown = data;
          this.categoryPTable.total = count;
        },
        (error) => {
          this.categoryPTable.data = [];
          this.categoryPTable.total = 0;
        }
      );
  }

  createBoard() {
    // if (!this.wallCreateForm.valid) {
    //   this.toastService.error('Please fill all fields');
    //   return;
    // }

    this.isLoading = true;
    if (this.wallCreateForm.controls.displayType.invalid) {
      this.toastService.error('Please select listing display setup');
      this.isLoading = false;
      return
    } else if(this.wallCreateForm.controls.postType.invalid){
      this.toastService.error('Please select post type');
      this.isLoading = false;
      return
    } else if(this.wallCreateForm.value.nominationPerUser.enabled &&
      !this.wallCreateForm.value.nominationPerUser.submissionLimit &&
      !this.wallCreateForm.value.nominationPerUser.submissionPeriod ){
      this.toastService.error('Please enter max number of nomination');
      this.isLoading = false;
      return
    } else if(this.wallCreateForm.value.maxNomination.enabled &&
      !this.wallCreateForm.value.maxNomination.submissionLimit &&
      !this.wallCreateForm.value.maxNomination.submissionPeriod
    ){
      this.toastService.error('Please enter max number of nomination per user');
      this.isLoading = false;
      return
    }

    this.wallCreateForm.patchValue({
      isNomineeActive : this.wallCreateForm.value.isNomineeActive ? this.wallCreateForm.value.isNomineeActive : false,
      adminResponse : this.wallCreateForm.value.adminResponse ? this.wallCreateForm.value.adminResponse : false,
      postAnonymously : this.wallCreateForm.value.postAnonymously ? this.wallCreateForm.value.postAnonymously : false,
      isTaskActive : this.wallCreateForm.value.isTaskActive ? this.wallCreateForm.value.isTaskActive : false
    })

    const data = {
      ...this.wallCreateForm.value,
      assignUsers: this.UpdateduserDetails,
      status: this.createCategoryForm.value.status,
    };

    data.maxNomination.enabled && data.maxNomination.submissionPeriod ? data.maxNomination.submissionPeriod = Number(data.maxNomination.submissionPeriod) : data.maxNomination.submissionPeriod = 0
    data.maxNomination.enabled && data.maxNomination.submissionLimit ? data.maxNomination.submissionLimit = Number(data.maxNomination.submissionLimit) : data.maxNomination.submissionLimit = 0
    data.nominationPerUser.enabled && data.nominationPerUser.submissionPeriod ? data.nominationPerUser.submissionPeriod = Number(data.nominationPerUser.submissionPeriod) : data.nominationPerUser.submissionPeriod = 0
    data.nominationPerUser.enabled && data.nominationPerUser.submissionLimit ? data.nominationPerUser.submissionLimit = Number(data.nominationPerUser.submissionLimit) : data.nominationPerUser.submissionLimit = 0;

    if(this.boardId) data.wallId = { _id : this.boardId._id }
    const sub1 = this.boardId
      ? this.boardService
        .updateWall(data, this.boardId._id)

        .pipe(finalize(() => sub1.unsubscribe()))
        .subscribe(
          (res) => {
            this.isLoading = false;
            this.overViewEvent.emit(null);
            this.toastService.success('Wall Updated Successfully');
          },
          (error) => {
            this.isLoading = false;
            this.toastService.error('Please fill all the fields');
          }
        )
      : this.boardService
        .createWall(data)
        .pipe(finalize(() => sub1.unsubscribe()))
        .subscribe(
          (res) => {
            this.isLoading = false;
            this.overViewEvent.emit(null);
            this.toastService.success(`Wall Created Successfully`);
          },
          (error) => {
            this.isLoading = false;
            this.toastService.error('Please fill all the fields');
          }
        );
  }

  nominationMenuTrue() {
    this.isNominationOpen = true;
  }

  nominationMenuFalse() {
    this.isNominationOpen = false;
  }

  toggleDisableNumberofNomination() {
  }

  toggleDisableNominateEachUser() {
  }

  validateURLImage(str) {
    var img_Path = str;
    if (img_Path.indexOf("https://") == 0) {
      var new_url = new URL(str);
     var pathName = (new_url.pathname.startsWith('//')) ? `/${new_url.pathname.split("//")[1]}` : new_url.pathname;
      return `${this.baseUrl}${pathName}`;
    }
    if (str.startsWith('/')) return this.baseUrl + str;
    return `${this.baseUrl}/${str}`;
   }
}
