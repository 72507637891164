<div class="p-4 card-container">
    <div class="card card-main">
        <div class="card-body">
            <h3>Post your query</h3>
        </div>

        <div class="form-container">
            <form [formGroup]="supportForm">
                <div class="form-group">
                    <label class="form-label">Name*</label>
                    <input type="text" class="form-control form-input" placeholder="Enter your name here"
                        formControlName="name">
                    <div class="error-container" *ngIf="name.invalid && name.touched">
                        <span *ngIf="name.errors.required">Name is Required</span>
                        <span *ngIf="name.errors.pattern">Please Enter a Valid Name</span>
                    </div>
                </div>
                <div class="form-group">
                    <label for="exampleInputPassword1" class="form-label">Email*</label>
                    <input type="email" class="form-control form-input" id="exampleInputPassword1"
                        formControlName="emailId" placeholder="Enter your Email here">
                        <div class="error-container" *ngIf="emailId.invalid && emailId.touched">
                            <span *ngIf="emailId.errors.required">Email is Required</span>
                            <span *ngIf="emailId.errors.email">Please Enter a Valid Email</span>
                        </div>
                </div>
                <div class="form-group">
                    <label for="exampleInputPassword1" class="form-label">Phone*</label>
                    <input type="tel" class="form-control form-input" id="exampleInputPassword1"
                        formControlName="phone" placeholder="Enter your Phone here">
                        <div class="error-container" *ngIf="phone.invalid && phone.touched">
                            <span *ngIf="phone.errors.required">Phone is Required</span>
                            <span *ngIf="phone.errors.pattern">Please Enter a Valid Phone Number</span>
                        </div>
                </div>
                <div class="form-group">
                    <label for="exampleInputPassword1" class="form-label">Feedback*</label>
                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="4" formControlName="feedback"
                        placeholder="Enter your Feedback here"></textarea>
                    <div class="error-container">
                        <span *ngIf="feedback.invalid && feedback.touched">Feedback is Required</span>
                    </div>
                </div>

                <div class="justify-content-SB">
                    <div>
                        <a class="back-button" (click)="backToLogin()">Back to Login</a>
                    </div>
                    <div>
                        <button (click)="submitForm()" class="btn btn-success" [disabled]="!supportForm.valid">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>