import { environment } from 'src/environments/environment';

const imageURl = environment.imageUrl

export const isEmail = email => /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
// message constant
export const COMMON_MESSAGES = {
  UPLOAD_ONLY_IMAGE: 'Please upload only .png, .jpg and .jpeg files',
  UPLOAD_ONLY_EXCEL: 'Please upload only .xlsx, .csv and .xls files',
  UPLOAD_ONLY_CSV: 'Please upload only .csv files',
};


export const createQueryString = (data) => {
  return Object.keys(data).map(key => {
    let val = data[key]
    if (typeof val === 'object') val = createQueryString(val)
    return `${key}=${encodeURIComponent(`${val}`.replace(/\s/g, '_'))}`
  }).join('&')
}

// export const  queryParam = (input, parentKey?, output = []) => {
//   debugger
//   const prepareQuery = (key, value) => {
//     console.log(key,value);

//       output.push(`${key}=${value}`);
//   }
//   const workOnArray = (arr=[], key=parentKey) => {
//       // if(arr.length === 0){
//       //   prepareQuery(`${key}[]`, []);
//       // }else {
//       //   arr.forEach(curr=> {
//       //     if (Array.isArray(curr)) {
//       //         workOnArray(curr, key);
//       //     } else if (typeof curr === 'object') {
//       //         workOnObject(curr, key);
//       //     } else {
//       //         prepareQuery(`${key}[]`, curr);
//       //     }
//       // });
//       // }
//       arr.forEach((curr, idx)=> {
//             if (Array.isArray(curr)) {
//                 workOnArray(curr, key);
//             } else if (typeof curr === 'object') {
//                 workOnObject(curr, key, idx);
//             } else {
//                 prepareQuery(`${key}[]`, curr);
//             }
//         });

//   }
//   const workOnObject = (input, parentKey?:any, index?:any) => {
//       for (const key in input) {
//           if (input.hasOwnProperty(key)) {
//               const element = input[key];
//               console.log(input);
//               console.log(index);

//               const consKey = parentKey ? index!=undefined ? `${parentKey}[${index}][${key}]` : `${parentKey}[${key}]`: key;
//               if (Array.isArray(element)) {
//                   workOnArray(element, consKey);
//               } else if (typeof element === 'object') {
//                   workOnObject(element, key, index);
//               } else {
//                   prepareQuery(consKey, element);
//               }
//           }
//       }
//   }
//   workOnObject(input);
//   return output.join('&');
// }


export const formatDate =(date) => {
  // tslint:disable-next-line:one-variable-per-declaration
  if (date == undefined || date == '') {
    return '';
  } else {
    // tslint:disable-next-line:one-variable-per-declaration
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    // return { year: year, month: month, day: day }
    return   [year, month, day].join('-') ;
  }

}

export const  editFormatDate =(date) => {
  // tslint:disable-next-line:one-variable-per-declaration
  if (date == undefined || date == '') {
    return '';
  } else {
    // tslint:disable-next-line:one-variable-per-declaration
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    // return { year: year, month: month, day: day }


    return  {year:year,month:Number(month), day:Number(day)


   };
  }
}
export const getCleanObj =(obj) => {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === '' || obj[propName] === []) {
      delete obj[propName];
    }
  }
  return obj
}

export const getAssignedUsers =(userDetails)=> {
  const Output = [];
  userDetails.forEach((element) => {
    const userAllBuIndex = element.businessUnits.findIndex(
      (x) => x._id === '1'  || x._id === 'All Business Unit'
    );
    let businessUnits =
      userAllBuIndex > -1
        ? []
        : element.businessUnits.map((item) => item._id);
    let buFilterType = element.buFilterType;
    let appointments = element.appointments.map((item) => item._id);
    let subSkillSets = element.subSkillSets.map((item) => item._id);
    let user = element.user.map((item) => item._id);
    let admin = !!element.admin ? element.admin.map((item) => item._id): [];
    let allBuToken = userAllBuIndex > -1  || element.allBuToken? true : false;
    let allBuTokenStaffId =
      userAllBuIndex > -1 ? localStorage.getItem('flexistaffId') :element?.allBuTokenStaffId ?element?.allBuTokenStaffId:  '';
    let customField = element.customField;
    let obj = {
      businessUnits,
      buFilterType,
      appointments,
      subSkillSets,
      user,
      admin,
      allBuToken,
      allBuTokenStaffId,
      customField,
    };

    Output.push(obj);
  });

  return Output


}

const isObj = function(a) {
  if ((!!a) && (a.constructor === Object)) {
    return true;
  }
  return false;
};
const _st = function(z, g) {
  return "" + (g != "" ? "[" : "") + z + (g != "" ? "]" : "");
};
export const queryParam = function(params, skipobjects?, prefix?) {
  if (skipobjects === void 0) {
    skipobjects = false;
  }
  if (prefix === void 0) {
    prefix = "";
  }
  var result = "";
  if (typeof(params) != "object") {
    return encodeURIComponent(prefix) + "=" + encodeURIComponent(params) + "&";
  }
  for (var param in params) {
    var c = "" + prefix + _st(param, prefix);
    if (isObj(params[param]) && !skipobjects) {
      result += queryParam(params[param], false, "" + c);
    } else if (Array.isArray(params[param]) && !skipobjects) {
      params[param].forEach(function(item, ind) {
        result += queryParam(item, false, c + "[" + ind + "]");
      });
    } else {
      result += encodeURIComponent(c) + "=" + encodeURIComponent(params[param]) + "&";
    }
  }
  return result;
};

export const getGmtOffSet = () => {
  const offsetInMinutes = -new Date().getTimezoneOffset();
  const hours = Math.floor(offsetInMinutes / 60);
  const minutes = offsetInMinutes % 60;
  const sign = offsetInMinutes >= 0 ? '+' : '-';
  return `GMT${sign}${Math.abs(hours)
    .toString()
    .padStart(2, '0')}${Math.abs(minutes).toString().padStart(2, '0')}`;
}

export const URL_REGEX_PATTERN: string =
  '((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)';


export const generateImageUrl = (url)=>{
  if(!url){
    return url
  }
  if(url.startsWith('upload')){
    return `${imageURl}/${url}`
  }
  if(url.startsWith('/upload')){
    return `${imageURl}${url}`
  }
  if(url.startsWith(imageURl)){
    if(url.includes('//upload')){
      const tempUrl = url.replace('//upload', '/upload')
      return tempUrl
    }

    return url
  }

  if(url.startsWith('http')){
    const tempUrls = url.split('/uploads')
    if(tempUrls[1]){
    return `${imageURl}/uploads${tempUrls[1]}`
    }
  }
  return url;
}
