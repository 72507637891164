import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { SetupService } from 'src/app/users/service/operations/setup.service';
import { OpsGroupService } from 'src/app/users/service/ops-group/ops-group.service';
import { UsersService } from 'src/app/users/service/user-service/users.service';
import { finalize, map } from 'rxjs/operators';
@Component({
  selector: 'app-ops-add-staff',
  templateUrl: './ops-add-staff.component.html',
  styleUrls: ['./ops-add-staff.component.scss'],
})
export class OpsAddStaffComponent implements OnInit {
  @Output() ChangeTabId = new EventEmitter<any>();
  @Input() viewValue;
  @Input() viewOpsGroup;
  activeSubId: number = 1;
  opsGroupForm: FormGroup;
  buOption: any;
  isView: boolean = true;
  isViewtab2: boolean = true;
  isViewtab3: boolean = true;
  selectedAdmin = [];
  checkedBuShift: [];
  selectedBuShift = [];
  groupBuShiftId = [];
  groupAdmintId: any = [];
  staffUserDet: any = [];
  staffDetail = [];
  teamNo = [];
  shiftSchemeAdmin: any=[];
  createGroupObj: any;
  groupName: any;
  groupAdmin: any;
  groupBuShift: any;
  groupTeamNo: any;
  teamCountNo: any = 0;
  teamName: any;
  checkedBu: any;
  forUserBuId = [];
  staffUserId = [];
  staffObj: { buId: any[] };
  staffUser: any;
  showRow: boolean = false;
  showteamCount: number;
  teamCountInt: any=null;
  teamNameArray = [];
  viewOps: any;
  teamOpsObj: {};
  teamOpsobjArr = [];
  staffAllUsers=[];
  selectProducts: any;
  subscription: Subscription;
  heading: string;
  headingtabchange: boolean = false;
  editBtn: any;
  disable: boolean = false;
  addFromBu: boolean;
  selectedTeamIndex: number = 0;
  addFromSource: any;
  buShiftNewArr = [];
  buIdArry = [];
  selectedViewTeam: number = 1;
  allTeam: any[];
  teamUsersObj: any;
  draftOpsGrpLoader:boolean=false;
  // userData: {};
  loginAdminId: any = [];
  selectedTransferTeamIndex: any;
  sourceTeamId: any;
  destinationTeamId: any;
  ifNoTeam: boolean = true;
  removedTeam = [];
  createOpsGrpLoader: boolean;
  buLoader: boolean = true;
  administrationLoader=false;
  dropdownSettingsSourceBU:any={};
  dropdownSettingsAdmin:any = {};
  buParameters: {
    sortWith: string;
    sortBy: string;
    data: any[];
    page: number;
    limit: number;
    search: string;
  };

  constructor(
    private fb: FormBuilder,
    private buService: SetupService,
    private opsGroupService: OpsGroupService,
    public toastService: ToastrService,
    private userService: UsersService
  ) {
    this.buParameters = {
      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,
      limit: 10,
      search: '',
    };

    this.dropdownSettingsSourceBU = {
      singleSelection: false,
      text: 'Enter/Search Business Unit',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'myclass custom-class',
      labelKey: 'orgName',
      primaryKey: '_id',
      badgeShowLimit: 1,
      // showCheckbox:true,
      lazyLoading: true,
      noDataLabel: 'No Data Available',
      maxHeight: 250

    };

    this.dropdownSettingsAdmin = {
      singleSelection: false,
      text: 'Search Administration',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'myclass custom-class',
      labelKey: 'name',
      primaryKey: '_id',
      badgeShowLimit: 1,
      // showCheckbox:true,
      lazyLoading: true,
      noDataLabel: 'No Data Available',
      maxHeight: 250
    }
  }

  ngOnInit(): void {
    this.activeSubId = 1;
    this.headingtabchange = true;
    this.opsGroupForm = this.fb.group({
      groupname: ['', [Validators.required]],
      buShift: ['', [Validators.required]],
      teamName: [[], [Validators.required]],
      opsTeamName: ['', [Validators.required]],
      numberteam: ['0', [Validators.required]],
      swap: ['0', [Validators.required]],
      opsGroupAction: ['', [Validators.required]],
      removeOpsGroup: ['', [Validators.required]],
      editable: ['', [Validators.required]],
   });
    this.getAdministrationList();
    this.getSourceBuList();
    this.opsGroupForm.get('removeOpsGroup').disable();
    if (this.viewValue == true) {
      this.opsGroupForm.get('groupname').disable();
      this.opsGroupForm.get('teamName').disable();
      this.opsGroupForm.get('numberteam').disable();
      this.opsGroupForm.get('swap').disable();
      this.selectedBuShift = [];
      this.disable = true;
      this.headingtabchange = false;
      this.activeSubId = 3;
      this.isView = false;
      this.isViewtab2 = false;
      this.isViewtab3 = true;
      this.opsGroupService.allPassedData.subscribe((allPassedData) => {
        this.selectProducts = allPassedData;

        this.selectProducts.forEach((element) => {
          if (element.hasOwnProperty('removeOpsTeamId')) {
            this.removedTeam = element.removeOpsTeamId;
          }

          this.opsGroupForm.patchValue({
            groupname: element.opsGroupName,
            swap: element.swopSetup,
            numberteam: element.noOfTeam,
          });
          this.teamCountNo=parseInt(element?.noOfTeam);
          this.teamCountInt = parseInt(element?.noOfTeam);
          let BuArr=[]
          element?.buId?.map(item=>{
            let obj={_id:item._id, orgName: item?.sectionId.departmentId.companyId.name + ' > ' +
            item?.sectionId.departmentId.name +
            ' > ' +
            item?.sectionId.name +
            '> ' +
            item?.name}
            BuArr.push(obj);

          })
          this.selectedBuShift = BuArr;
          this.selectedAdmin = element?.adminId;

          if (element?.noOfTeam > 0) {
            this.ifNoTeam = false;
            this.teamNameArray = element?.opsTeamId.map((team) => {
              return {
                id: team._id,
                teamName: team.name,
                staffUser: team.userId,
              };
            });
          } else {
            this.ifNoTeam = true;
            this.teamNameArray = element?.userId.map((team) => {
              return {
                id: null,
                teamName: null,
                staffUser: [{ ...team }],
              };
            });
          }
          element?.opsTeamId.map((team) => {
            this.opsGroupForm.patchValue({
              opsTeamName: team.name,
            });
          });
          this.selectedteam(this.selectedTeamIndex, 'o', null);
        });
      });
    }
    for (let i = 0; i <= 40; i++) {
      this.teamNo.push(i.toString());
    }
  }

  getAdministrationList(){
    this.administrationLoader=true;
    this.opsGroupService.getOpsGroupAdminList().subscribe((res) => {
      this.administrationLoader=false;
      this.shiftSchemeAdmin = res['data'];
      if(!this.viewValue){
        this.selectedAdmin = this.shiftSchemeAdmin?.find(item => item.name == JSON.parse(localStorage.getItem("loggedInUserName")))? [this.shiftSchemeAdmin?.find(item=>item.name == JSON.parse(localStorage.getItem("loggedInUserName")))]:[];
      }


    },(err)=>{
      this.administrationLoader=false;
    });
  }

  viewOpsData() {
    this.viewOps = this.viewOpsGroup;
  }

  changeTabIdNextBTn() {
    this.activeSubId = 3;
    this.checkedBu = this.checkedBuShift;
    this.opsGroupForm.get('groupname').disable();
    this.opsGroupForm.get('teamName').disable();
    this.opsGroupForm.get('numberteam').disable();
    this.opsGroupForm.get('swap').disable();
  }

  changeTabId() {
    let  count=0;
    if (this.teamCountInt) {

      this.teamNameArray.map((item) => {
          if (item.teamName) {
              count++;
          }
      });
  }
    if (this.opsGroupForm.get('groupname').invalid) {
      this.toastService.warning('Please enter OPS Group name');
    } else if (this.opsGroupForm.get('buShift').invalid) {
      this.toastService.warning("Please Select Source Bu's");
    }else if (this.teamCountInt && +count != +this.teamCountInt) {
      this.toastService.warning("Please click on 'Add Team' Button");
    } else {
      this.activeSubId = 2;
      this.addFromSource = 1;
      this.ifNoTeam = false;
    }

    this.groupName = this.opsGroupForm.get('groupname').value;

    this.groupTeamNo = this.teamCountNo;
    this.teamName = this.opsGroupForm.get('teamName').value;

    this.buShiftNewArr = this.selectedBuShift.map((ele) => {
      return {
        id: ele._id,
        name: ele.orgName,
        isChecked: false,
      };
    });

    this.teamNameArray = this.teamNameArray.map((ele) => {
      return {
        ...ele,
        sourceBus: this.buShiftNewArr,
      };
    });

    this.selectedBuShift.forEach((element) => {
      this.groupBuShiftId.push(element._id);
    });

    this.handleApprove();
  }
  changeTabIdPrevious() {
    this.groupBuShiftId = [];
    this.groupAdmintId = [];
    this.activeSubId = 1;
  }
  changeTabIdPreviouBtn() {
    this.activeSubId = 2;
  }

  intializeTeamArr(i) {
    let teamList = [];
    for (let j = 0; j < i; j++) {
      const obj = {
        teamName: '',
        isAdded: false,
        addFromSource: false,
        sourceBus: [],
        staffUser: []
      };
      teamList.push(obj);
    }
    this.teamNameArray = teamList;
  }

  handleApprove() {
    this.addFromBu = true;

    const bu = [];
    this.buShiftNewArr.forEach((val) => {
      bu.push(val.id);
    });

    this.staffObj = {
      buId: bu,
    };

    this.opsGroupService.staffList(this.staffObj).subscribe((res) => {
      this.staffUser = res.data;
      this.staffAllUsers=JSON.parse(JSON.stringify(this.staffUser));
    });
  }

  teamNoCount() {
    this.showRow = true;
    this.teamCountInt = parseInt(this.teamCountNo);
    this.intializeTeamArr(this.teamCountInt);
  }

  selectedteam(i, type, id) {
    this.selectedTeamIndex = i;
    this.selectedViewTeam = i + 1;
    if (type === 'o') {
      this.sourceTeamId = id;
      this.selectedTeamIndex = i;
    }
    if (type === 't') {
      this.destinationTeamId = id;
      this.selectedTransferTeamIndex = i;
    }
    this.addFromSource =
      this.teamNameArray[this.selectedTeamIndex].addFromSource;
    this.staffDetail = this.teamNameArray[this.selectedTeamIndex].staffUser;
    if (this.teamCountInt === 0) {
      this.staffUserDet = this.teamNameArray.reduce(
        (accumulator, currentObject) => {
          const staffUsers = currentObject.staffUser;
          accumulator.push(...staffUsers);
          return accumulator;
        },
        []
      );
    } else {
      this.staffUserDet = this.teamNameArray[this.selectedTeamIndex].staffUser;
    }
    this.buShiftNewArr = this.teamNameArray[this.selectedTeamIndex].sourceBus;
  }

  getBuShift(event, i, id) {
    if (this.teamNameArray.length !== 0) {
      this.teamNameArray[this.selectedTeamIndex].sourceBus[i].isChecked =
        event.target.checked;
    }

    if (event.target.checked) {
      this.buIdArry.push(id);
    } else {
      this.buIdArry.splice(this.buIdArry.indexOf(id), 1);
    }

    this.staffObj = {
      buId: this.buIdArry,
    };

    this.opsGroupService.staffList(this.staffObj).subscribe((res) => {
      this.staffUser = res.data;
    });
  }
  removeBuId(buitem) {
    this.selectedBuShift = this.selectedBuShift.filter(
      (item) => item._id !== buitem._id
    );
  }
  removeAdminId(AdminId) {
    this.selectedAdmin = this.selectedAdmin.filter(
      (adminitem) => adminitem._id !== AdminId._id
    );
  }
  removeStaff(index){
    let selectedUser =this.staffAllUsers?.filter(x=>x._id == this.staffUserDet[index]?._id);
    if(selectedUser?.length){
      this.staffUser.push(selectedUser[0]);
    }
    this.staffUser = [...this.staffUser];
    this.staffUserDet?.splice(index,1);
  }
  addStaffUser() {
    this.staffUserId = [];

    if (this.staffDetail.length) {
      this.staffDetail.forEach((val) => {
        this.staffUserDet.push(val);
        const teIn = this.staffUser.findIndex((vv) => {
          if (vv._id == val._id) {
            return true;
          }
        });

        if (teIn != -1) {
          this.staffUser.splice(teIn, 1);
        }
      });

      this.staffUser = [...this.staffUser];
    }

    this.staffUserDet.forEach((element) => {
      this.staffUserId.push(element._id);
    });

    if (this.teamNameArray.length !== 0) {
      this.teamOpsobjArr = this.teamNameArray.map((ele) => {
        return {
          admin: [],
          name: ele.teamName,
          userId: this.staffUserId,
        };
      });
    }

    if (this.teamNameArray.length !== 0) {
      this.teamOpsObj = {
        admin: [],
        name: this.teamName,
        userId: this.staffUserId,
      };
      this.teamNameArray[this.selectedTeamIndex].staffUser = this.staffDetail;
    }
    this.teamOpsobjArr.push(this.teamOpsObj);
    this.staffDetail = [];
  }

  addTeambutton(i) {
    this.editBtn = true;
    this.teamNameArray[i].teamName = (<HTMLInputElement>(
      document.getElementById('teamIdName' + i)
    )).value;
    this.teamNameArray[i].isAdded = true;
  }

  editTeam(i){
    this.teamNameArray[i].isAdded = false;
  }

  removeTeam(i){
    this.teamNameArray.splice(i,1);
    this.teamCountNo = this.teamCountNo - 1;
    this.teamCountInt = parseInt(this.teamCountNo);
  }
  backToOverview() {
    this.ChangeTabId.emit(1);
  }

  clearLocationLabel() {
    this.teamName = '';
  }
  onCreateForm() {
    let createGroupObjPayload =this.createPayloadForSave(false);
    this.createOpsGrpLoader = true;
    const createOpsGrp = this.opsGroupService
      .createOpsForm(createGroupObjPayload)
      .pipe(
        finalize(() => {
          createOpsGrp.unsubscribe();
          this.createOpsGrpLoader = false;
        })
      )
      .subscribe((res) => {
        if (res['success']) {
          this.toastService.success(res['message'], 'Successful');
          this.ChangeTabId.emit(1);
        } else {
          this.toastService.error(res['message'], 'Failed', {
            timeOut: 5000,
            progressBar: true,
          });
        }
      });
  }

  getSourceBuList() {
    this.buLoader = true;
    // const { data, ...rest } = this.buParameters;
    const buSubscribe = this.buService
      .getBusinessUnitBUShift().subscribe(
        (res: any) => {
          // if (res.success) {
            this.buLoader = false;
            this.buParameters.data = res?.data?.planBussinessUnitId || [];


          // } else {
          //   this.buParameters.data = [];
          // }
        },(error) => {
          this.buLoader = false;
          this.buParameters.data = [];
        }
      );
  }

  loadMoreItem() {
    this.buParameters.limit = this.buParameters.limit + 10;
    this.getSourceBuList();
  }

  saveAsDraft(){
    let createGroupObjPayload =this.createPayloadForSave(true);
      this.draftOpsGrpLoader = true;
    const createOpsGrp = this.opsGroupService
      .saveAsDraftOpsForm(createGroupObjPayload)
      .pipe(
        finalize(() => {
          createOpsGrp.unsubscribe();
          this.draftOpsGrpLoader = false;
        })
      )
      .subscribe((res) => {
        if (res['success']) {
          this.toastService.success(res['message'], 'Successful');
          this.ChangeTabId.emit(1);
        } else {
          this.toastService.error(res['message'], 'Failed', {
            timeOut: 5000,
            progressBar: true,
          });
        }
      });
  }

  createPayloadForSave(isDraft?:boolean){
    this.teamUsersObj = [];
    this.groupAdmin = this.selectedAdmin;
    this.groupAdmin.forEach((admin) => {
      this.groupAdmintId.push(admin._id);
    });
    let opsArr = [];
    if (this.teamNameArray.length !== 0) {
      this.teamNameArray.forEach((val) => {
        const userId = [];
        val.staffUser.forEach((v) => {
          userId.push(v._id);
          opsArr.push(v._id);
        });

        this.teamUsersObj.push({ admin: [], name: val.teamName,  userId: userId });
      });
    } else {
      opsArr = [...this.staffUserId];
    }
    return {
      adminId: this.groupAdmintId,
      buId: this.groupBuShiftId,
      isDraft: isDraft ? isDraft:false,
      noOfTeam: this.teamCountNo,
      opsGroupName: this.groupName,
      opsTeam: this.teamUsersObj,
      swopSetup: this.opsGroupForm.get('swap').value,
      userId: opsArr,
    };

  }

  async deleteSourceBU(id) {
    const buUnitIndex = await this.selectedBuShift?.findIndex((x) => x._id === id);
    this.selectedBuShift?.splice(buUnitIndex, 1)
  }

  async deleteAdmin(id) {
    const adminIndex = await this.selectedAdmin?.findIndex((x) => x._id === id);
    this.selectedAdmin?.splice(adminIndex, 1)
  }




}
