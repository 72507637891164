import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import {
  detectAllFaces,
  loadFaceLandmarkModel,
  loadFaceRecognitionModel,
  loadSsdMobilenetv1Model,
} from 'face-api.js';
import { Observable, Subject } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { SetupService } from 'src/app/users/service/operations/setup.service';
import { UsersService } from 'src/app/users/service/user-service/users.service';
import { BussinessUnitService } from 'src/app/users/service/bussiness-unit/bussiness-unit.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-facial-id',
  templateUrl: './facial-id.component.html',
  styleUrls: ['./facial-id.component.scss'],
})
export class FacialIdComponent implements OnInit {
  @ViewChild('sidemenu') sidemenu: ElementRef;
  activeId: number = 1;
  isView: boolean;
  buOption: any;
  shiftSchemeAdmin: any;
  usersList: any;
  userPTable: {
    sortWith: string;
    sortBy: string;
    data: any[];
    page: number;
    limit: number;
    filter: { fields: string[] };
    search: string;
  };
  userSelect = null;
  adminSelect = null;
  private trigger: Subject<void> = new Subject<void>();
  public errors: WebcamInitError[] = [];
  showWebcam = false;
  staffName: any;
  staffPhone: any;
  staffDetails: any;
  tabView: any;
  userFacialDetail: any;
  @ViewChild('snap') snapCam: ElementRef;
  capturedImg: string;
  actionbtn: boolean = false;
  userFacialImgUrl: any;
  buLoader: boolean = true;
  buId: any;
  routeSub;
  addPhotoIdLoader:boolean=false;
  constructor(
    private buService: SetupService,
    private userService: UsersService,
    private toastrService: ToastrService,
    private renderer: Renderer2,
    private businessunitService: BussinessUnitService,
    private router: Router,
    private route: ActivatedRoute,

  ) {
    this.userPTable = {
      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,

      limit: 10,
      filter: {
        fields: ['name'],
      },
      search: '',
    };

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.onSameUrlNavigation = 'reload';
  }

  ngOnInit() {
    this.getBu();

    this.buService.getAdmin().subscribe((res) => {
      this.shiftSchemeAdmin = res['data'];
    });

   // this.loadUsers();
    this.loadFaceModels();
  }

  getBu() {
    this.buLoader = true;
    const buSubscribe = this.buService
      .getAllBuShiftModule()
      .pipe(
        finalize(() => {
          this.buLoader = false;
          buSubscribe.unsubscribe();
        })
      )
      .subscribe(
        (res: any) => {
         // if (res.success) {
            this.buOption = res?.data?.businessUnitList;
          /*} else {
            this.buOption = [];
          }*/
        },
        (error) => {
          this.buOption = [];
        }
      );
  }

  ngAfterViewInit() {
    if (!this.isView) {
      setTimeout(() => {
        this.renderer.addClass(this.sidemenu.nativeElement, 'animate');
      }, 50);
    }
  }



  viewClicked() {
    if (this.buId) {
      this.isView = true;
      this.loadUsers();
    } else {
      this.toastrService.info('Please Selct Business Unit', 'Info')
    }
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    if (changeEvent.nextId === 1) {
      this.tabView = null;
      this.adminSelect = null;
    }
  }

  loadUsers() {
    const { data, ...rest } = this.userPTable;
    const payload = {businessUnitId : this.buId._id}
    const subscribe = this.businessunitService
      .getBusinessUnit(payload)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const data = response.data.data;
          this.userPTable.data = data;
        },
        (error) => {
          this.userPTable.data = [];

          // this.tostService.error('No list found');
        }
      );
  }

  toggleCamera() {
    this.capturedImg = null;
    this.showWebcam = !this.showWebcam;
    this.actionbtn = this.showWebcam;
  }

  public triggerSnapshot(): void {
    this.showWebcam = false;
    this.trigger.next();
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.capturedImg = webcamImage.imageAsDataUrl;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  loadMoreAdminItems(value) {
    this.userPTable.limit = this.userPTable.limit + 10;
    this.loadUsers();
  }

  onSeachAdmin(value) {
    this.userPTable.limit = 10;
    this.userPTable.search = value.term;
    this.loadUsers();
  }

  selectedStaff(data) {
    this.staffPhone = this.adminSelect.contactNumber;
    this.staffName = this.adminSelect.name;
    this.staffDetails = this.adminSelect;
  }

  navigateToOverview() {
    this.activeId = 1;
    this.tabView = null;
    this.resetAdd();
  }

  async loadFaceModels() {
    const MODEL_URL = '/assets/models';

    await loadSsdMobilenetv1Model(MODEL_URL);
    await loadFaceLandmarkModel(MODEL_URL);
    await loadFaceRecognitionModel(MODEL_URL);
  }

  async addPhotoId() {
    const photoId = {
      userId: this.staffDetails._id,
      facialInfo: this.capturedImg,
    };
    this.addPhotoIdLoader=true;
    let fullFaceDescriptions = await detectAllFaces(this.snapCam.nativeElement)
      .withFaceLandmarks()
      .withFaceDescriptors();


    if (fullFaceDescriptions && fullFaceDescriptions.length > 0) {
      photoId['descriptor'] = [...fullFaceDescriptions[0].descriptor];
      this.userService.createFacialId(photoId).subscribe((res) => {
        if (res.message) {
          this.addPhotoIdLoader=false;
          this.toastrService.success('Facial Id updated successfully', 'Done', {
            timeOut: 3000,
          });
          this.activeId = 1;
          this.tabView = null;
          this.resetAdd();

        } else {
          this.addPhotoIdLoader=false;
          this.toastrService.error('Something went wrong', 'Failed', {
            timeOut: 3000,
          });
        }
      });
    } else {
      this.addPhotoIdLoader=false;
      this.toastrService.error('Please capture proper image', 'Failed');
    }

  }

  resetAdd() {
    this.capturedImg = null;
    this.showWebcam = false;
    this.adminSelect = null;
    this.actionbtn = false;
  }

  editFacialId(e) {
    this.activeId = 2;
    this.tabView = 'edit';
    this.userFacialDetail = e;
  }
}
