<div class="card">
  <h5 class="card-header bg-theme">POSTS</h5>
  <div class="card-body">
    <div class="card table-card">
      <div class="card-body flexi-body">
        <div class="flex-column">
          <div class="d-flex justify-content-between mt-2 mb-3">
            <select
              class="custom-select"
              style="width: auto"
              name="pageSize"
              (change)="onLimitChangeUsers()"
              [(ngModel)]="usersPTable.limit"
            >
              <option [ngValue]="10">10</option>
              <option [ngValue]="20">20</option>
              <option [ngValue]="30">30</option>
              <option [ngValue]="40">40</option>
              <option [ngValue]="50">50</option>
            </select>
            <div class="form-group form-inline mb-0">
              <span *ngIf="usersPTable.isLoading">Please wait...</span>
              <input
                class="form-control ml-2"
                type="search"
                name="searchTerm"
                (search)="loadUsers()"
                (keyup)="userssearchInput.next($event)"
                placeholder="Search here"
                [(ngModel)]="usersPTable.search"
              />
            </div>
          </div>
          <div class="table-responsive">
            <table class="table" *ngIf="!isTableLoading">
              <thead>
                <tr>
                  <th
                    scope="col"
                    sortable="Tier 2"
                    (sort)="onSortUsers($event, 'title')"
                  >
                    Post Title
                  </th>
                  <th
                    scope="col"
                    sortable="Tier 3"
                    (sort)="onSortUsers($event, 'name')"
                  >
                    Channel
                  </th>

                  <th
                    scope="col"
                    sortable="Tier 4"
                    (sort)="onSortUsers($event, 'updatedAt')"
                  >
                    Reported Date / Time
                  </th>
                  <th
                    scope="col"
                    sortable="Tier 4"
                    (sort)="onSortUsers($event, 'name')"
                  >
                    USER NAME
                  </th>

                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of usersPTable.data">
                  <td>
                    <p [innerHtml]="item?.teaser?.title | safeHtml"></p>
                  </td>

                  <td>{{ item?.channelId?.name }}</td>

                  <td>{{ item?.updatedAt | date }}</td>

                  <td>
                    <ul *ngFor="let user of item.userList">
                      <li>{{ user?.userId?.name }}</li>
                    </ul>
                  </td>
                  <td>
                    <label class="switch">
                      <input
                        type="checkbox"
                        (click)="changePostStatus(item._id, item.status)"
                        [checked]="item.status === 1 ? 'checked' : ''"
                      />
                      <span class="slider round"></span>
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <app-skliton-loading-table
            [numberOfCols]="5"
            [isTableLoading]="isTableLoading"
          ></app-skliton-loading-table>
        </div>
      </div>
      <div class="card-footer">
        <div class="d-flex justify-content-between">
          <p>Total records {{ usersPTable.total }}</p>
          <ngb-pagination
            [collectionSize]="usersPTable.total"
            [(page)]="usersPTable.page"
            [maxSize]="5"
            (pageChange)="onPageChangeUsers($event)"
            [pageSize]="usersPTable.limit"
          >
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
