import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';

import { BussinessUnitService } from 'src/app/users/service/bussiness-unit/bussiness-unit.service';

@Component({
  selector: 'app-manage-notification',
  templateUrl: './manage-notification.component.html',
  styleUrls: ['./manage-notification.component.scss']
})
export class ManageNotificationComponent implements OnInit {
  isNotificationEdited: boolean = false

  activeId = 1
  viewNotification: FormGroup
  isNotificationViewEnabled = false;
  bussinesUnitplanPTable: { sortBy: string; sortWith: string; data: any[]; page: number; limit: number; search: string; filter: { type: string; }; };
  planBuUnitDropDown = []
  isOverView: string;
  planBuUnitDropDownLoading: boolean;
  notificatioNid: string;
  notificatioEditid: string;
  isNotificationEndabled: boolean = false
  isLoading: boolean = false
  constructor(
    private formBuilder: FormBuilder,
    private buUnit: BussinessUnitService,
    private toastService: ToastrService,
  ) {
    this.bussinesUnitplanPTable = {
      sortBy: 'createdAt',
      sortWith: 'desc',
      data: [],
      page: 1,

      limit: 8,

      search: '',
      filter: {
        type: 'plan',
      },
    };
  }

  ngOnInit() {
    this.viewNotification = this.formBuilder.group({
      bussinessUnit: [null, [Validators.required]],
    })
    this.loadBussinessPlanUnit()
  }

  addParentBussinentUnit(value) {

    this.viewNotification.patchValue({
      bussinessUnit: {
        string: value.businessUnit,
        _id: value._id,
      },
    });
  }
  deleteparentBussinesUnit() {
    this.viewNotification.patchValue({
      bussinessUnit: null
    });
  }

  onSeachPlanBuUnit(value) {
    this.bussinesUnitplanPTable.limit = 10;
    this.bussinesUnitplanPTable.search = value.term;

    this.loadBussinessPlanUnit();
  }

  loadMorePlasnBuUnitItems(value) {
    this.bussinesUnitplanPTable.limit = this.bussinesUnitplanPTable.limit + 10;
    this.loadBussinessPlanUnit();
  }

  onClearPlanBuUnitDropdown(value) {


    if (value === undefined) {
      this.viewNotification.patchValue({
        bussinessUnit: null
      });
      this.bussinesUnitplanPTable.search = '';
      this.loadBussinessPlanUnit();

      this.planBuUnitDropDown = [];
      this.bussinesUnitplanPTable.data = [];
    }
  }
  loadBussinessPlanUnit() {
    this.planBuUnitDropDownLoading = true;
    const { data, ...rest } = this.bussinesUnitplanPTable;
    const subscribe = this.buUnit
      .getBussinesUnit(rest, true)
      .pipe(
        finalize(() => {
          this.planBuUnitDropDownLoading = false;
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const { recordsTotal, businessUnitList } = response;
          this.bussinesUnitplanPTable.data = businessUnitList;
          this.planBuUnitDropDown = businessUnitList;
        },
        (error) => {
          this.bussinesUnitplanPTable.data = [];

          this.toastService.error('No list found');
        }
      );
  }
  onOverView() {
    if(!this.isNotificationViewEnabled) {
    this.activeId = 1
    this.notificatioEditid = null
    this.isNotificationEdited = false
    this.isNotificationViewEnabled = false;
    }
  }

  onCreateTab() {
    this.isNotificationViewEnabled = false;
  }

  notificationEdit(id: string) {
    this.activeId = 2
    this.isNotificationEdited = !this.isNotificationEdited
    this.notificatioEditid = id
    this.isNotificationViewEnabled = false;
  }

  viewNotificationClick($event){
    console.log('viewNotificationClick', $event)
    this.isNotificationViewEnabled = $event;
  }

  onClickView() {
    if (!this.viewNotification.valid) {
      this.toastService.error('Please Select a Business Unit');
      return
    }
    this.isLoading = true

    setTimeout(() => {
      this.isNotificationEndabled = true
      const { _id } = this.viewNotification.get('bussinessUnit').value
      this.notificatioNid = _id
      this.ngOnInit()
      this.isLoading = false
    }, 1500);

  }

  viewEnable(value) {
    this.isNotificationEdited = false;
  }

  overViewEvent(value) {
    this.isOverView = value;

    this.isNotificationEndabled = true;

    this.isNotificationEdited = false
    // this.isEdited = false
    this.activeId = 1
    this.isNotificationViewEnabled = false;
  }
}
