import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { ShiftSchedulerService } from 'src/app/users/service/operations/shift-scheduler.service';
import { getGmtOffSet } from 'src/app/core/utils/helper';

@Component({
  selector: 'app-manage-overview',
  templateUrl: './manage-overview.component.html',
  styleUrls: ['./manage-overview.component.scss'],
})
export class ManageOverviewComponent implements OnInit, OnChanges {
  isHour: boolean = true;
  @Input() bu;
  @Input() startDate;
  @Input() shiftGraph;
  @Input() refresh: any;

  startDay: string;
  currWeek: any;
  buWeekArr: Object;
  weekCount = 0;
  stafflisting: any;
  endDay: string;
  shiftReadReq: any;
  shiftRead: Object;
  dashboardData: any;
  graphData: any;
  rawGraph = [];

  graphNeed = [];
  graphNeedAssign = [];
  graphBooked = [];

  graphHrNeedAssign = [];
  graphHrNeed = [];
  graphHrBooked = [];

  hrAndShiftChartData: any;
  graphLoader: boolean = true;
  time = [];

  bookShiftArr = [];
  openShiftArr = [];
  assignShiftArr = [];
  selectedDay: any;
  index: any = 2;
  gmtOffset: any = getGmtOffSet();

  dayArr = [
    { count: 0, d: 'M', day: 'monday' },
    { count: 1, d: 'T', day: 'tuesday' },
    { count: 2, d: 'W', day: 'wednesday' },
    { count: 3, d: 'T', day: 'thursday' },
    { count: 4, d: 'F', day: 'friday' },
    { count: 5, d: 'S', day: 'saturday' },
    { count: 6, d: 'S', day: 'sunday' },
  ];
  yAxesLabel: any;
  constructor(private planShift: ShiftSchedulerService) {}

  ngOnInit(): void {
    
  }

  ngOnChanges() {
    // if (this.refresh != 0) {
    //   this.getShiftRead();
    //   this.selectTime();
    // }
    this.shiftRead = this.shiftGraph;
    this.getGraphData();
    this.dayViewGraph(this.dayArr[0]);
    this.selectTime();
    this.graphLoader = false;
  }

  getGraphData() {
    this.graphNeed = [];
    this.graphNeedAssign = [];
    this.graphBooked = [];

    this.graphHrNeed = [];
    this.graphHrNeedAssign = [];
    this.graphHrBooked = [];
    this.rawGraph = this.shiftRead['graphDataWeb'];

    const weekdays = [];
    moment.updateLocale('en', {
      week: {
        dow: 1, // Monday is the first day of the week.
      },
    });
    for (var i = 0; i < 7; i++) {
      weekdays.push(moment(this.startDate).weekday(i).format('DD-MM-YYYY'));
    }

    const graphRawData = Object.keys(this.rawGraph).reverse();
    for (let prop in weekdays) {
      if (graphRawData.includes(weekdays[prop])) {
        this.graphNeed.push(
          this.rawGraph[weekdays[prop]]['numberOfShifts'].need
        );
        this.graphNeedAssign.push(
          this.rawGraph[weekdays[prop]]['numberOfShifts'].needAssign
        );
        this.graphBooked.push(
          this.rawGraph[weekdays[prop]]['numberOfShifts'].booked
        );

        this.graphHrNeed.push(this.rawGraph[weekdays[prop]]['totalHours'].need);
        this.graphHrNeedAssign.push(
          this.rawGraph[weekdays[prop]]['totalHours'].needAssign
        );
        this.graphHrBooked.push(
          this.rawGraph[weekdays[prop]]['totalHours'].booked
        );
      } else {
        this.graphNeed.push(0);
        this.graphNeedAssign.push(0);
        this.graphBooked.push(0);

        this.graphHrNeed.push(0);
        this.graphHrNeedAssign.push(0);
        this.graphHrBooked.push(0);
      }
    }

    this.changeHrAndNumber(true);
  }

  changeHrAndNumber(value) {
    this.isHour = value;
    if (value === true) {
      this.hrAndShiftChartData = [
        {
          data: this.graphHrNeedAssign,
        },
        {
          data: this.graphHrNeed,
        },
        {
          data: this.graphHrBooked,
        },
      ];
      this.index = 2;
    } else {
      this.hrAndShiftChartData = [
        {
          data: this.graphNeedAssign,
        },
        {
          data: this.graphNeed,
        },
        {
          data: this.graphBooked,
        },
      ];
      this.index = 1;
    }
    this.dayViewGraph(this.dayArr[0]);
  }

  selectTime() {
    this.time = [];
    for (let hour = 0; hour < 24; hour++) {
      this.time.push(moment({ hour }).format('HH:mm'));
      this.time.push(moment({ hour, minute: 30 }).format('HH:mm'));
    }
  }

  hrAndShiftChartoptions = {
    responsive: true, // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
    scales: {
      xAxes: [
        {
          maxBarThickness: 40,
          gridLines: {
            display: false,
          },
        },
      ],
    },
  };

  hrAndShiftChartLabels = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  // CHART COLOR.
  hrAndShiftChartColors = [
    { backgroundColor: '#0000FF' },
    { backgroundColor: '#a42138' },
    { backgroundColor: '#6BB11E' },
  ];

  // Day View Chart
  dayViewGraph(i) {
    this.bookShiftArr = [];
    this.openShiftArr = [];
    this.assignShiftArr = [];

    this.yAxesLabel = [];

    this.selectedDay = i;
    const weeklyStaffGraphData = this.shiftRead['weeklyStaffGraphData'];

    const bookShift = weeklyStaffGraphData.staffAppliedData[i.day];
    for (let i in bookShift) {
      this.bookShiftArr.push(bookShift[i][this.index]);
      this.yAxesLabel.push(moment(bookShift[i][0]).format('HH:mm'));
    }

    const openShift = weeklyStaffGraphData.staffNeedData[i.day];
    for (let i in openShift) {
      this.openShiftArr.push(openShift[i][this.index]);
    }

    const assignShift = weeklyStaffGraphData.staffNeedDataAssing[i.day];
    for (let i in assignShift) {
      this.assignShiftArr.push(assignShift[i][this.index]);
    }

    this.dayChartData = [
      {
        label: 'Booked Open Shift',
        data: this.bookShiftArr,
        fill: false,
        borderWidth: 3,
        lineTension: 0,
        pointStyle: 'star',
      },
      {
        label: 'Planned Open Shift',
        data: this.openShiftArr,
        fill: false,
        borderWidth: 3,
        lineTension: 0,
        pointStyle: 'rect',
      },
      {
        label: 'Assign Shift',
        data: this.assignShiftArr,
        fill: false,
        borderWidth: 3,
        lineTension: 0,
        pointStyle: 'circle',
      },
    ];
  }

  dayChartData = [
    {
      label: 'Booked Open Shift',
      data: this.bookShiftArr,
      fill: false,
      borderWidth: 3,
      lineTension: 0,
      pointStyle: 'star',
    },
    {
      label: 'Planned Open Shift',
      data: this.openShiftArr,
      fill: false,
      borderWidth: 3,
      lineTension: 0,
      pointStyle: 'rect',
    },
    {
      label: 'Assign Shift',
      data: this.assignShiftArr,
      fill: false,
      borderWidth: 3,
      lineTension: 0,
      pointStyle: 'circle',
    },
  ];

  dayChartOptions = {
    responsive: true,
    borderColor: 'blue',
    scales: {
      xAxes: [
        {
          ticks: {
            callback: function (value, index, values) {
              return index % 4 == 0 ? value : '';
            },
            color: 'red',
            maxRotation: 0,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            borderDash: [4, 4],
          },
        },
      ],
    },
  };

  dayChartColorOption = [
    { borderColor: '#6BB11E', backgroundColor: '#6BB11E' },
    { borderColor: '#a42138', backgroundColor: '#a42138' },
    { borderColor: '#0000FF', backgroundColor: '#0000FF' },
  ];
}
