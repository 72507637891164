import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { companyNameResponse } from 'src/app/core/model/users.model';
import { finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/users/service/auth/auth.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    public toastService: ToastrService,
  ) { }
  public companyName;
  public forgetPasswordForm: FormGroup;

  supportForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.pattern('^[A-Za-z ]{3,26}$')]),
    emailId: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', [Validators.required, Validators.pattern('^[6-9][0-9]{9}$')]),
    feedback: new FormControl('', Validators.required)
  })

  get name(){
    return this.supportForm.get('name')
  }
  get emailId(){
    return this.supportForm.get('emailId')
  }
  get phone(){
    return this.supportForm.get('phone')
  }
  get feedback(){
    return this.supportForm.get('feedback')
  }
  
  ngOnInit(): void {
    this.route.params
      .pipe(
        take(1),
        tap((value) => this.companyName = value.name),
        map((v) => v.name),
        switchMap((name) => this.auth.fetchCompanyId(name))
      )
      .subscribe((res: companyNameResponse) => {
        this.forgetPasswordForm.patchValue({
          companyId: res.data._id,
        });
      });
  }

  backToLogin() {
    this.router.navigate(['/users/login/', this.companyName]);
  }

  submitForm(){
    const sub = this.auth
      .sendSupportQuery(this.supportForm.value)
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe(
        (res) => {
          this.toastService.success('Message Sent Successfully');
          this.router.navigate(['/users/login/', this.companyName]);
        },
        (error) => {
        }
      );
  }
}
