<div class="card mb-3 w-100" *ngIf="!editClicked">
  <div class="card-header flexi-header">
    <h3>WORKFLOW STATUS</h3>
  </div>
  <div class="card-body" *ngIf="editFormData?.workflow && editFormData?.workflow.length > 0">
    <div class="d-flex forms-manage " style="padding-right: 1rem">
      <div class="box-item d-flex flex-column mr-2" *ngFor="let item of editFormData?.workflow; let i = index">
        <h5 *ngIf="editFormData?.workflow && editFormData?.workflow.length > 0">{{item?.title}}</h5>
        <div class="d-flex box-item-status">
          <div class="d-flex flex-column flex-fill align-items-center status" *ngFor="let data of editFormData?.workflow[i]?.workflowStatus">
            <h6>{{data?.field}}</h6>
            <h3>{{getWorkflowStatusCount(data?._id)}}</h3>
          </div>
          <!--<div class="d-flex flex-column flex-fill align-items-center status">
            <h6>Approve</h6>
            <h3>{{getWorkflowStatusCount('Approve')}}</h3>
          </div>
          <div class="d-flex flex-column flex-fill align-items-center status">
            <h6>Reject</h6>
            <h3>{{getWorkflowStatusCount('Reject')}}</h3>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</div>
<div class="card mb-3 w-100">
  <div class="card-header flexi-header" *ngIf="!editClicked">
    <h3>FILTER</h3>
  </div>
  <div class="card-body" *ngIf="!editClicked">
    <form [formGroup]="readManageForm">
      <div class="row">

        <div class="col-2 d-flex flex-column justify-content-between">
          <!-- Here comes your content -->
          <button style="margin: 2px;" class="btn btn-add  btn-block" (click)="statusOpenPopup()">STATUS FILTER&emsp; <i class="fa fa-caret-down"
            aria-hidden="true"></i>
        </button>
        </div>
        <div class="col-2 d-flex flex-column justify-content-between">
          <div class="form-group">
            <div class="input-group">
              <input class="form-control" formControlName="fromDate" placeholder="dd-mm-yyyy" style="max-width: 240px;"
                name="dp" [maxDate]="maxDate" ngbDatepicker #d="ngbDatepicker">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                  <i class="fa fa-calendar" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 d-flex flex-column justify-content-between">

          <div class="form-group">
            <div class="input-group">
              <input class="form-control"  formControlName="toDate" placeholder="dd-mm-yyyy" style="max-width: 240px;"
                name="dp" [maxDate]="maxDate" ngbDatepicker  #c="ngbDatepicker">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="c.toggle()" type="button">
                  <i class="fa fa-calendar" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>


        </div>

        <div class="col-3 d-flex flex-column justify-content-between ">
          <ng-select class="custom" bindLabel="name" [placeholder]="businessunitPlaceholder" appendTo="body"
            [multiple]="false" [searchable]="true" [clearable]="false" [closeOnSelect]="true"
            formControlName="parentBussinessUnitId" [loading]="parentBuUnitDropDownLoading"
            (search)="onSearchParentBuUnit($event)" (scrollToEnd)="loadMoreParentBuUnitItems($event)"
            (clear)="onClearParentBuUnitDropdown($event)" >
            <!-- <ng-option value="" disabled selected>Select Business unit</ng-option> -->
            <ng-option [value]="item._id" *ngFor="let item of parentBuUnitDropDown">
              {{ item.orgName }}
            </ng-option>
          </ng-select>
        </div>

        <div class="col-3 d-flex flex-column justify-content-between">
          <div class="d-flex justify-content-between">
            <button class="btn btn-submit p-2 m-1  btn-block" [disabled]="!readManageForm.valid" (click)="onViewBasedOnBussinessUnit()">View</button>

            <button class="btn btn-primary p-2 m-1  btn-block" [disabled]="!readManageForm.valid" (click)="onExportForm()">Export</button>
            <button class="btn btn-warning p-2 m-1  btn-block" [disabled]="!readManageForm.valid" (click)="onclearBussinessUnit()">Clear</button>

          </div>

        </div>
      </div>

    </form>
  </div>

  <div class="card my-3 w-100" *ngIf="!editClicked">
    <div class="card-header flexi-header">
      <h3>FORMS</h3>
    </div>
    <div class="card-body">
      <div class="card table-card">
        <div class="card-body flexi-body">
          <div class="flex-column">
            <div class="d-flex justify-content-between mt-2 mb-3">

              <div class="form-group">
                <label for="" class="font-weight-bold">Form Status</label>
                <div class="d-flex">
                    <div class="radio radio-danger radio-inline">
                        <input type="radio" id="active" [value]="1" name="formStatus" [(ngModel)]="formStatusValue" (change)="selectFormStatus($event)">
                        <label class="font-weight-bold" for="active">
                            Active
                        </label>
                    </div>
                    <div class="radio radio-danger radio-inline ml-2">
                        <input type="radio" id="inactive" [value]="2" name="formStatus" [(ngModel)]="formStatusValue" (change)="selectFormStatus($event)">
                        <label class="font-weight-bold" for="inactive">
                            Inactive
                        </label>
                    </div>
                </div>
            </div>

              <div class="d-flex flex-column justify-content-between">
                <button class="btn btn-submit  btn-block" (click)="settingsOpenPopup()">COLUMN VISIBILITY &emsp; <i
                    class="fa fa-columns" aria-hidden="true"></i>
                </button>
                <!--<button style="margin: 2px;" class="btn btn-add  btn-block" (click)="statusOpenPopup()">STATUS &emsp; <i class="fa fa-caret-down"
                    aria-hidden="true"></i>
                </button>-->
              </div>
            </div>
            <div class="table-responsive">
              <table class="table">
                <tr>
                  <th *ngFor="let col of columns; let i = index">
                     <ng-container *ngIf="(col=='createdAt' || col=='formId' || col=='formName' || col=='user');else qusColList">
                      <p [innerHTML]="setHeader(col) |uppercase | safeHtml"></p>
                    </ng-container>
                    <ng-template #qusColList>
                      <ng-container *ngIf="isColVisible(col)">
                        <p [innerHTML]="setHeader(col) |uppercase | safeHtml"></p>
                      </ng-container>
                      <!-- <ng-container *ngIf="" -->


                    </ng-template>
                    <!-- <ng-container *ngIf="isColVisible(col)">
                      <p [innerHTML]="setHeader(col) |uppercase | safeHtml"></p>
                    </ng-container> -->

                  </th>
                  <th> ACTION</th>
                </tr>
                <tr *ngFor="let item of tableData">

                  <td *ngFor="let column of columns">
                    <ng-container *ngIf="(column=='createdAt' || column=='formId' || column=='formName' || column=='user');else qusList">
                      {{setData(item[column], column)}}
                    </ng-container>
                    <ng-template #qusList>
                      <ng-container>
                        <ng-container *ngIf="isColVisible(column)">
                           <p [innerHTML]="setAnswer(item,column) | safeHtml"></p>

                        </ng-container>
                      </ng-container>


                      <!-- <ng-container *ngIf="" -->


                    </ng-template>
                  </td>
                  <td>

                    <div class="d-flex justify-content-between">
                      <button class="btn btn-clear p-2 m-1 btn-block" [disabled]="!item.viewOnly" (click)="onEdit(item._id)">Edit</button>

                      <button class="btn btn-submit p-2 m-1 btn-block" (click)="onPrintWorkFlowPdf(item._id)">Print</button>
                      <button class="btn btn-add p-2 m-1 btn-block" (click)="WorkflowstatusOpenPopup(item,item._id)">WorkFlow</button>

                    </div>

                  </td>
                </tr>
              </table>

            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="d-flex justify-content-between">
            <p>Total records {{ tableDataObj.total }}</p>
            <ngb-pagination [collectionSize]="tableDataObj.total" [(page)]="tableDataObj.page" [maxSize]="5"
              (pageChange)="onPageChangeUsers($event)" [pageSize]="tableDataObj.limit">
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>

  </div>


  <ng-template #updateSettings let-c="close" let-d="dismiss">
    <div>
      <div class="modal-content">
        <div class="modal-body" style="padding: 0px; min-height: 100vh">
          <div>
            <div class="card w-100">
              <div class="card-header flexi-header">
                <h3>COLUMN VISIBILITY SETTINGS</h3>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <!-- <form> -->
                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col" sortable="Tier 1">QUESTIONS</th>
                              <th scope="col" sortable="Tier 2">
                                ADDED
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr *ngFor="let ques of questions">
                              <td style="word-break: break-all">
                                <p [innerHTML]="ques?.question | safeHtml"></p>
                              </td>

                              <td>
                                <input type="checkbox" [checked]="ques?.status === true"  [(ngModel)]="ques.status" (ngModelChange)="
                                addFieldStatus(ques)
                              " />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col" sortable="Tier 1">WORKFLOW STATUS</th>
                              <th scope="col" sortable="Tier 2">
                                ADDED
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of workflow">
                              <td style="word-break: break-all">
                                {{item?.title}}
                              </td>

                              <td>
                                <input type="checkbox" [checked]="item?.status  === true" [(ngModel)]="item.status" (ngModelChange)="
                                addFieldStatus(item)
                              " />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="d-flex justify-content-between">
                            <button class="btn btn-clear btn-block mr-1" (click)="onCloseColumnForm()">
                              Close
                            </button>
                            <button style="margin: 2px;" class="btn btn-add btn-block"
                              (click)="onSubmitStatusFilter('workflow')">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    <!-- </form> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #updateStatusSettings let-c="close" let-d="dismiss">
    <div>
      <div class="modal-content">
        <div class="modal-body" style="padding: 0px; min-height: 100vh">
          <div>
            <div class="card w-100">
              <div class="card-header flexi-header">
                <h3>CHANGE WORKFLOW STATUS</h3>
              </div>
              <div *ngIf="!showWorkFlowContent && workflow.length > 0">
                <div *ngFor="let item of workflow; let i=index">
                  <button style="margin: 2px; margin-top: 20px; margin-right: 4px;" class="btn-block btn btn-success btn-lg"
                  (click)="onShowWorkFlowContent(item)">
                  <span *ngIf="workflow !== undefined && workflow !== null">{{item?.title}}</span>
                  </button>
                </div>

              </div>
              <div class="card-body" *ngIf="showWorkFlowContent">
                <div class="row">
                  <div class="col-md-12">
                    <!--<form [formGroup]="workFlowLogForm">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Name</label>
                      <input type="text" class="form-control" name="name" aria-describedby="nameHelp"
                        placeholder="Enter company name" formControlName="name" maxlength="26"
                         />
                    </div>
                    </form>-->
                    <!-- <form> -->
                      <div class="row" style="margin-left: 4px;">
                        <label for="exampleInputEmail1" class="font-weight-bold">Form Name</label>
                        <input type="text" class="form-control" name="name" aria-describedby="nameHelp"
                          placeholder="Enter company name" value="{{editFormData?.title}}" maxlength="20"
                           disabled/>                      </div>
                      <div class="row" style="margin-top: 5px; margin-left: 4px;">
                        <label for="exampleInputEmail1" class="font-weight-bold">Submitted User</label>
                        <input type="text" class="form-control" name="name" aria-describedby="nameHelp"
                          value="{{selectedWorkFlowData?.user}}" maxlength="20"
                            disabled/>                      </div>
                      <div class="row" style="margin-top: 5px; margin-left: 4px;">
                        <label for="exampleInputEmail1" class="font-weight-bold">Submitted At</label>
                        <input type="text" class="form-control" name="name" aria-describedby="nameHelp"
                          value="{{selectedWorkFlowData?.createdAt |date:'medium'}}"  maxlength="20"
                           disabled/>  </div>

                           <div class="row" style="margin-top: 5px; margin-left: 4px;">
                            <label for="exampleInputEmail1" class="font-weight-bold">WorkFlow Name</label>
                            <input type="text" class="form-control" name="name" aria-describedby="nameHelp"
                              value="{{clickedWorkFlowTitle?.title}}"  maxlength="20"
                               disabled/>  </div>

                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col" sortable="Tier 1">STATUS</th>
                              <th scope="col" sortable="Tier 2">
                                ADDED
                              </th>
                            </tr>
                          </thead>
                          <tbody>

                            <tr *ngFor="let ques of defaultStatusArray; let index = index">
                              <td  >
                                <span
                                class="text-items status-workflow-class"
                                [style.background]="ques.color"
                                > {{ques?.field}}</span
                              >

                              </td>

                              <td>
                                <input [disabled]="!isAdminTrue" type="checkbox" [checked]="ques?.status == true" [ngModel]="selectedCheckBoxIndex === index"
                               (change)="isAllSelected($event,ques,index)" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="d-flex justify-content-between">
                            <button class="btn btn-clear btn-block mr-1" (click)="onCloseStatusFrom()">
                              Close
                            </button>
                            <button [disabled]="!isAdminTrue" style="margin: 2px;" class="btn btn-add btn-block"
                              (click)="onSubmitColumnSettings('status')">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="card-header flexi-header w-100">
                          <h3>FORM STATUS LOG</h3>
                        </div>
                        <div class="d-flex justify-content-between mt-2 mb-3">
                          <select class="custom-select" style="width: auto" name="pageSize" (change)="onLimitChangeWorkFlow()"
                            [(ngModel)]="workFlowLogTableData.limit">
                            <option [ngValue]="10">10</option>
                            <option [ngValue]="20">20</option>
                            <option [ngValue]="30">30</option>
                            <option [ngValue]="40">40</option>
                            <option [ngValue]="50">50</option>
                          </select>
                          <!--<div class="form-group form-inline mb-0">
                            <span *ngIf="usersPTable.isLoading">Please wait...</span>
                            <input class="form-control ml-2" type="search" name="searchTerm" (search)="userssearchInput.next($event)"
                              (keyup)="userssearchInput.next($event)" placeholder="Search here" [(ngModel)]="usersPTable.search" />
                          </div>-->
                        </div>
                        <div class="table-responsive">
                          <table class="table">
                            <thead>
                              <tr>
                                <th scope="col" sortable="Tier 1">STATUS</th>
                                <th scope="col" sortable="Tier 2">
                                  STAFF ID
                                </th>
                                <th scope="col" sortable="Tier 2">
                                  UPDATED BY
                                </th>
                                <th scope="col" sortable="Tier 2">
                                  UPDATED AT
                                </th>
                              </tr>
                            </thead>
                            <tbody *ngIf="workFlowLogTableData?.data && workFlowLogTableData.data.length >0">

                              <tr *ngFor="let ques of workFlowLogTableData.data">
                                <td  >
                                  <span
                                  class="text-items"
                                  style="word-break: break-all"

                                  [style.background]="ques.color"
                                  > {{ques?.changeMessage}}</span
                                >
                                </td>
                                <td>
                                  {{ques?.user?.staffId}}
                                </td>
                                <td>
                                  {{ques?.user?.name}}
                                </td>
                                <td>
                                  {{ques?.createdAt |date:"medium"}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <br>
                      <div class="card-footer">
                        <div class="d-flex justify-content-between">
                          <p>Total records {{ workFlowLogTableData.total }}</p>
                          <ngb-pagination [collectionSize]="workFlowLogTableData.total" [(page)]="workFlowLogTableData.page" [maxSize]="5"
                            (pageChange)="onPageChangeWorkFlow($event)" [pageSize]="workFlowLogTableData.limit">
                          </ngb-pagination>
                        </div>
                      </div>
                      <br>

                    <!-- </form> -->
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #updateStatusFilter let-c="close" let-d="dismiss">
    <div>
      <div class="modal-content">
        <div class="modal-body" style="padding: 10px; min-height: 100vh">
          <div>
            <div class="card my-3 w-100">
              <div class="card-header flexi-header">
                <h3>WORKFLOW STATUS SETTINGS
                </h3>
              </div>
              <div class="card-body">

                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col" sortable="Tier 1">STATUS</th>
                        <th scope="col" sortable="Tier 2">
                          ADDED
                        </th>
                      </tr>
                    </thead>
                    <tbody>

                      <tr *ngFor="let ques of defaultStatusFilterArray">
                        <td  >
                          <span
                          class="text-items"
                          style="word-break: break-all"

                          [style.background]="ques.color"
                          > {{ques.field}}</span
                        >

                        </td>

                        <td>
                          <input type="checkbox" [(ngModel)]="ques.status"
                         (change)="onChangeFilterCheckBox($event,ques)" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br>
                <div class="row">
                  <div class="col-md-6">
                    <div class="d-flex justify-content-between">
                      <button class="btn btn-clear btn-block mr-1" (click)="onCloseStatusFrom()">
                        Close
                      </button>
                      <button style="margin: 2px;" class="btn btn-add btn-block"
                        (click)="onSubmitStatusFilter('status')">
                        Submit
                      </button>


                    </div>

                  </div>
                </div>
              </div>
            </div>
            <!--<div class="card my-3 w-100">
              <div class="card-header flexi-header">
                <h3>FORM STATUS LOG
                </h3>
              </div>
              <div class="card-body">
                <div class="card table-card">
                  <div class="card-body flexi-body">
                    <div class="flex-column">
                      <div class="d-flex justify-content-between mt-2 mb-3">
                        <select
                          class="custom-select"
                          style="width: auto"
                          name="pageSize"
                          (change)="onLimitChangeManageFormLogs()"
                          [(ngModel)]="manageFormLogTable.limit"
                        >
                          <option [ngValue]="10">10</option>
                          <option [ngValue]="20">20</option>
                          <option [ngValue]="30">30</option>
                          <option [ngValue]="40">40</option>
                          <option [ngValue]="50">50</option>
                        </select>
                        <div class="form-group form-inline mb-0">
                          <span *ngIf="manageFormLogTable.isLoading"
                            >Please wait...</span
                          >
                          <input
                            class="form-control ml-2"
                            type="search"
                            name="searchTerm"
                            (keyup)="manageFromLogSearchInput.next($event)"
                            placeholder="Search here"
                            [(ngModel)]="manageFormLogTable.search"
                          />
                        </div>
                      </div>
                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                sortable="Tier 1"
                                (sort)="onSortUsers($event)"
                              >
                                S.NO
                              </th>
                              <th
                                scope="col"
                                sortable="Tier 2"
                                (sort)="onSortUsers($event)"
                              >
                                CATEGORY
                              </th>

                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="
                                let item of manageFormLogTable.data;
                                let index = index
                              "
                            >
                              <td>{{ index + 1 }}</td>

                              <td>{{ item.name }}</td>


                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <div class="d-flex justify-content-between">
                      <p>Total Records {{ manageFormLogTable.total }}</p>
                      <ngb-pagination
                        [collectionSize]="manageFormLogTable.total"
                        [(page)]="manageFormLogTable.page"
                        [maxSize]="5"
                        (pageChange)="onPageChangeManageFromLogs($event)"
                        [pageSize]="manageFormLogTable.limit"
                      >
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>-->
          </div>
        </div>
      </div>
    </div>
  </ng-template>


<div *ngIf="editClicked">
  <app-form-manage-edit [questionId]="questionId" [customFormId]="customFormId" (takeQuestion)="backToManage($event)" [selectedWorkFlowID]="selectedWorkFlowID"></app-form-manage-edit>
</div>

<div *ngIf="adminQuestionare">
  <app-admin-question-form [workflow]="workflow"  (formSubmitted)="adminFormSubmitted($event)" (adminModalClosed)="adminModalClosed($event)" [workflowId]="workflowId" [manageFormId]="manageFormId" [questionId]="questionId" [customFormId]="manageId" (takeQuestion)="backToManage($event)" [selectedWorkFlowID]="selectedWorkFlowID"></app-admin-question-form>
</div>
<ng-template #adminQuestion let-c="close" let-d="dismiss">
  <div>
    <div class="modal-content">
      <div class="modal-body" style="padding: 0px; min-height: 100vh">
        <div>
          <div class="card w-100">
            <div class="card-header flexi-header">
              <h3>CHANGE WORKFLOW STATUS</h3>
            </div>
            <div class="card-body" style="word-break: break-word; padding: 10px; height:max-content;">
              <!-- [formGroup]="answerForm" -->
              <!-- <form  (ngSubmit) ="submit()"> -->
              <div class="box d-flex flex-column" *ngFor="let item of additionalModuleIdData; let i = index">
                <div class="topHead d-flex">
                  <strong class="mr-1">{{ i + 1 }} </strong>
                  <div class="box-heading" style="word-break: break-word">
                    &emsp; <p [innerHTML]="item?.question | safeHtml"></p>
                  </div>
                </div>
                <div class="box-body" *ngIf="item.type === 1">
                    <textarea matInput rows="5" cols="100"  [(ngModel)]="item.answer"></textarea>
                    <br/>
                </div>
               <div  class="box-body" *ngIf="item.type === 2">
                    <div class="box" *ngFor="let option of item.options">
                      <!-- <div> -->
                    <div class="card-body" *ngIf="!!option.imageSrc">
                      <img [src]="option?.imageSrc" crossorigin="anonymous" width="250px" height="320px"  id="4" alt="Image Preview" />
                    </div>
                    <!-- <form [formGroup]="answerQuestionForm"> -->
                      <input type="radio" id="{{option._id}}" name="{{item._id}}" [value]="option?.value"  [(ngModel)]="item.answer"   (change)="setChild(item, option)" class="card-header-flexi"/>
                      <label for="consolidated">{{ option.value }}</label>


                    <!-- </form> -->
                    <!-- </div> -->
                  </div>
                  <br/>
                </div>


                <div class="box-body" *ngIf="item.type === 3" >
                 <div class="boxx" *ngFor="let option of item.options">
                   <div class="card-body" *ngIf="!!option.imageSrc">
                    <img [src]="option?.imageSrc" crossorigin="anonymous" width="250px" height="320px"   alt="Image Preview" />
                  </div>
                    <input type="radio" id="{{option._id}}" name="{{item._id}}" [value]="option?.value"  [(ngModel)]="item.answer"   (change)="setChild(item, option)" class="card-header-flexi"/>
                    <label for="{{option._id}}">{{ option.value }}</label>
                  </div>
                  <br/>
                </div>


                <div class="card-body" *ngIf="item.type === 4">
                    <div class="d-flex" style="overflow: auto;">
                      <div class="question-radio-inline" *ngFor="let option of item.options;let i=index">
                            <label for="{{option._id}}" class="card" style="border-radius: 10px;width: min-content;">
                                  <img *ngIf="!!option.imageSrc" crossorigin="anonymous" src="{{option.imageSrc}}" alt=""  style="width: 270px;height: 201px;border-radius: 10px 10px 0px 0px;" (click)="item.selected=i">
                                  <div class="card-container" [ngStyle]="{'width': option.imageSrc ? '270px' : '150px' }" style="background-color: #6eb96e;display: flex;justify-content: space-between;border-radius: 0px 0px 10px 10px;padding:5px;" (click)="selectPolling(item._id, option)">
                                      <h4><b>Select</b></h4>
                                      <img crossorigin="anonymous" style="width: 12%;min-width: 30px;height: 1%;margin: 3px;" src="{{'./assets/images/'+(option.answer?'like1':'like0')+'.png'}}" alt="" height="10px">
                                  </div>
                              </label>
                              <h4 style="text-align: center;" [ngClass]="{'pollingSelect':item.selected===i}" (click)="item.selected=i"><b>{{option.value}}</b></h4>
                          <span  class="text-success" *ngIf="!!option.percentage">{{option.percentage}} %</span>
                      </div>
                  </div>
                    <br/>
                  </div>


                <div class="box-body" *ngIf="item.type === 5">
                  <div class=" form-group d-flex flex-column answers-options">
                    <div class="box" *ngFor="let option of item.options; let index= index">
                      <div *ngIf="option.imageSrc">
                        <div class="card-body">
                          <img [src]="option.imageSrc" width="250px" height="320px" id="4" alt="Image Preview" />
                        </div>
                      </div>
                      <input type="checkbox" [(ngModel)]="item.answer[index]['value']" name="{{option._id}}" id="{{option._id}}"(change)="setChild(item,option)"/>
                        <label for="consolidated">{{ option.value }}</label>
                      </div>
                    </div>
                    <br/>
                  </div>


                <div class="box-body" *ngIf="item.type === 6">
                  <div>
                    <div *ngIf = "item.answer" class="signature-div">
                      <img [src]='item.answer' class="signature-img"/>
                    </div>
                    <button mat-button (click)="openDialog(item)">Signature</button>
                    <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="home"></mat-icon>

                  </div>
                  <br/>
                </div>
                <div class="box-body" *ngIf="item.type === 8">
                  <div class="d-flex flex-wrap answers-options">
                    <div class="box form-group flex-fill" style="margin: 1rem 1rem; width: 44%">
                      <textarea class="form-control" rows="5" [(ngModel)]="item.answer"  maxlength="{{!!item?.maxlength?item?.maxlength:30}}" ></textarea>
                    </div>
                  </div>
                  <br/>
                </div>


                <div class="box-body" *ngIf="item.type === 9">
                  <div class="d-flex flex-wrap answers-options">
                    <div class=" box form-group flex-fill" style="margin: 1rem 1rem; width: 44%">
                      <input type="number" class="form-control" [(ngModel)]="item.answer"/>
                    </div>
                  </div>
                  <br/>
                </div>


                <div class="box-body" *ngIf="item.type === 10">
                  <div *ngFor="let option of item.dateTime">
                    <div *ngIf="item.dateTime?.length === 2 && option === 2" class="box">
                        <div class="d-flex flex-wrap answers-options">
                          <div class="form-group flex-fill" style="margin: 1rem">
                            <label for="my-input">Date</label>
                            <input id="my-input" class="form-control" type="date" #myDate [value]="item?.answer?.date | date:'yyyy-MM-dd'"  />
                          </div>
                      </div>
                      <div style="margin-left: 10px;"class="box">
                        <label>Time</label>
                        <ngb-timepicker [(ngModel)]="item.answer['time']" [meridian]="true"
                        [hourStep]="hourStep" [minuteStep]="minuteStep"  ></ngb-timepicker>
                      </div>
                    </div>
                    <div class="box form-group" *ngIf="item.dateTime?.length === 1 && option === 1">
                      <div class="d-flex flex-wrap answers-options" *ngIf="option === 1">
                        <div class="form-group flex-fill" style="margin: 1rem">
                          <label for="my-input">Date</label>
                          <input id="my-input" class="form-control" type="date" #myDate [value]="item?.answer?.date | date:'yyyy-MM-dd'" />
                        </div>
                      </div>
                    </div>
                    <div style="margin-left: 10px;"class="box" *ngIf="option === 2 && item.dateTime?.length === 1">
                      <div style="margin-left: 10px;"class="box">
                        <label>Time</label>
                        <ngb-timepicker [(ngModel)]="item.answer['time']" [meridian]="true"
                        [hourStep]="hourStep" [minuteStep]="minuteStep"  ></ngb-timepicker>
                      </div>
                    </div>
                  </div>
                  <br/>
                </div>


                <div class="box-body" *ngIf="item.type === 11">
                  <div class="box">
                  <select class="form-control" #mySelect [(ngModel)]="item.answer" (change)="setChild(item, mySelect.value,i)" >
                    <option [ngValue]="selected" selected  disabled>Select Options</option>
                    <option  *ngFor="let choice of item.options" [value]="choice.value"> <img src="{{choice.imageSrc}}" crossorigin="anonymous" class="dropdown-img" *ngIf="choice.imageSrc" />{{choice.value}}</option>
                  </select>
                  </div>
                  <br/>
                </div>


                <div class="box-body" *ngIf="item.type === 12">
                  <div class="box form-group">
                    <input id="my-input" class="form-control-file"  type="file" (change)="fileUpload($event,item)" />
                  </div>
                  Preffered formats are -
                  <span *ngFor="let fileType of item?.options;let index=index">
                    <button (click)="downloadAttachMent(item.answer)"> Download Attachment</button>
                    {{ fileType.value }}<span *ngIf="item.options?.length != index+1">, </span>
                  </span>
                  <br/>
                </div>


                <div class="box-body" *ngIf="item.type === 13">
                  <div class="d-flex">
                    <ngb-rating [max]="item.value" [(rate)]="item.answer" ></ngb-rating>
                  </div>
                  <br/>
                </div>


                <div class="box-body" *ngIf="item.type === 14">
                  <div class="row">
                    <div class="col-md-6">
                      <ng-select  class="custom" bindLabel="name"
                      (change)="multiSelect($event,item)"
                      placeholder="Select Users"  [multiple]="true" [searchable]="true" [(ngModel)]="user"
                      [clearable]="true"  >
                      <ng-option [value]="choice._id" *ngFor="let choice of typeFourteenQuestion">
                        {{ choice.name }}
                      </ng-option>
                    </ng-select>
                      </div>
                  </div>
                  <br/>
                </div>


                <div class="box-body" *ngIf="item.type === 15">
                    <div cdkDropList (cdkDropListDropped)="drop($event,item)">
                      <div *ngFor="let imagObj of item.options" cdkDrag>
                        <div *ngIf ="imagObj.imageSrc">
                        <img [src]="imagObj.imageSrc" width="250px" height="320px"  id="4" alt="Image Preview" />
                        </div>
                        <p>{{imagObj.value}}</p>
                        <br/>
                      </div>
                    </div>
                    <br/>
                </div>>


                <div *ngIf="additionalModuleIdData.length == 0" style="text-align: center">
                  No questions to show
                </div>
              </div>
              <button type="submit" class="btn-submit" (click)="submit()" >submit</button>
            <!-- </form> -->
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
