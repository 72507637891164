import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skliton-loading-table',
  templateUrl: './skliton-loading-table.component.html',
  styleUrls: ['./skliton-loading-table.component.scss']
})
export class SklitonLoadingTableComponent implements OnInit {
  @Input() numberOfCols = [];
  @Input() isTableLoading;
  width = (Math.floor(Math.random() * 51) + 50);
  constructor() { }
  
  ngOnInit(): void {
  }

  counter(numbers: number) { 
    return new Array(numbers)
  }
}
