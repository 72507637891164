import { CustomFieldService } from 'src/app/users/service/custom-field/custom-field.service';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  FormArray,
  AbstractControl,
  ValidatorFn,
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription, Subject, of, Observable } from 'rxjs';
import {
  map,
  debounceTime,
  distinctUntilChanged,
  mergeMap,
  delay,
  finalize,
  take,
  switchMap,
} from 'rxjs/operators';
import { CompanyService } from 'src/app/admin/service/companyservice/company.service';
import { PaginatedTable } from 'src/app/core/model/pagination.model';
import { StorageService } from 'src/app/core/service/storage.service';
import {
  SortableHeader,
  SortEvent,
} from 'src/app/shared/directive/sortable.directive';
import { BussinessUnitService } from 'src/app/users/service/bussiness-unit/bussiness-unit.service';
import { CompanySetupService } from 'src/app/users/service/company-setup/company-setup.service';
import { RoleService } from 'src/app/users/service/user-roles/role.service';
import { UsersService } from 'src/app/users/service/user-service/users.service';
import { AngularMultiSelect } from 'angular2-multiselect-dropdown';
import { EMAIL_PATTERN } from 'src/app/constants';

@Component({
  selector: 'app-add-edit-users',
  templateUrl: './add-edit-users.component.html',
  styleUrls: ['./add-edit-users.component.scss'],
})
export class AddEditUsersComponent implements OnInit {
  @Input() userId: string = null;
  onAppoinmentEditID: string;
  isAppoinmentEdited: boolean = false;
  parentBuUnitDropDown = [];
  planBuUnitDropDown = [];
  viewBuUnitDropDown = [];
  appoinmentDropDown = [];
  roleDropDown = [];
  selectedRole: any = [];
  selectedLeaveGroup: any = [];
  submitted: boolean;
  viewBuUnit: any = null;
  planBuUnit: any = null;
  parentBuUnit: any = null;
  parentBuUnitDropDownLoading: boolean = false;
  dropdownSettingsBplan: any = {};
  dropdownSettingsBview: any = {};
  @ViewChild('dropdownBplanRef', { static: false }) dropdownBplanRef: AngularMultiSelect;
  buUnitDropDownLoading: boolean = false;
  planBuUnitDropDownLoading: boolean = false;
  viewBuUnitDropDownLoading: boolean = false;
  apppoinmentDropDownLoading: boolean = false;
  roleDropDownLoading: boolean = false;
  createUserForm: FormGroup;
  createApoinmentForm: FormGroup;
  @ViewChild('myInput')
  myInputVariable: ElementRef;
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  IconUrl: string;
  model;
  isLoading: boolean;
  file: File;
  unsubcribe: any;
  form: FormGroup;
  fields: any;
  isUserEdited: boolean = false;
  parentbussinesUnitPTable: {
    sortWith: string;
    sortBy: string;
    data: any[];
    page: number;
    limit: number;
    search: string;
    filter: { type: string };
  };
  appoinmentPTable: {
    sortWith: string;
    sortBy: string;
    data: any[];
    page: number;
    total: number;
    limit: number;
    search: string;
  };
  CustomFieldPTable: {
    sortWith: string;
    sortBy: string;
    data: any[];
    page: number;
    total: number;
    limit: number;
    search: string;
  };
  // rolePTable: {
  //   sortWith: string;
  //   sortBy: string;
  //   data: any[];
  //   page: number;
  //   total: number;
  //   limit: number;
  //   search: string;
  // };
  bussinesUnitviewPTable: {
    sortWith: string;
    sortBy: string;
    data: any[];
    page: number;
    limit: number;
    search: string;
    filter: { type: string };
  };
  bussinesUnitplanPTable: {
    sortWith: string;
    sortBy: string;
    data: any[];
    page: number;
    limit: number;
    search: string;
    filter: { type: string };
  };
  assignData: any;

  onSortApoinment({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.appoinmentPTable.sortBy = direction;
    this.appoinmentPTable.sortWith = column;
    direction && this.loadAppoinment();
  }

  onPageChangeApoinment(page: number) {
    this.appoinmentPTable.page = page;
    this.loadAppoinment();
  }
  onLimitChangeApoinment() {
    this.loadAppoinment();
  }
  @ViewChild('AddSubSection', { static: true })
  AddSubSection: TemplateRef<any>;
  isUpdateLoading: boolean = false;
  @Output() overViewEvent = new EventEmitter<string>();
  private apoinmentSearchSubscription: Subscription;
  public apoinmentSearchInput = new Subject<KeyboardEvent>();
  constructor(
    private formBuilder: FormBuilder,
    private buUnit: BussinessUnitService,
    private toastService: ToastrService,
    private userService: UsersService,
    public modelService: NgbModal,
    private companyService: CompanyService,
    private roleService: RoleService,
    private storageService: StorageService,
    private CustomFieldService: CustomFieldService,
  ) {
    this.form = new FormGroup({
      fields: new FormControl(JSON.stringify(this.fields)),
    });
    this.unsubcribe = this.form.valueChanges.subscribe((update) => {
      this.fields = JSON.parse(update.fields);
    });
    this.initForm();
    this.parentbussinesUnitPTable = {
      sortBy: 'createdAt',
      sortWith: 'desc',
      data: [],
      page: 1,

      limit: 10,

      search: '',
      filter: {
        type: 'plan',
      },
    };
    this.dropdownSettingsBplan = {
      singleSelection: false,
      text: 'Bussiness unit (Plan)',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'myclass custom-class',
      labelKey: 'orgName',
      primaryKey: '_id',
      badgeShowLimit: 1,
      // showCheckbox:true,
      lazyLoading: true,
      noDataLabel: 'No Data Available',
      maxHeight: 250

    };
    this.dropdownSettingsBview = {
      singleSelection: false,
      text: 'Business unit (view)',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'myclass custom-class',
      labelKey: 'orgName',
      primaryKey: '_id',
      badgeShowLimit: 1,
      // showCheckbox:true,
      lazyLoading: true,
      noDataLabel: 'No Data Available',
      maxHeight: 250
    }
    // this.rolePTable = {
    //   sortWith: 'createdBy',
    //   sortBy: 'desc',
    //   data: [],
    //   page: 1,
    //   total: 0,
    //   limit: 10,
    //   search: '',
    // };
    this.bussinesUnitviewPTable = {
      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,

      limit: 10,

      search: '',
      filter: {
        type: 'view',
      },
    };
    this.bussinesUnitplanPTable = {
      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,

      limit: 10,

      search: '',
      filter: {
        type: 'plan',
      },
    };
    this.appoinmentPTable = {
      sortWith: 'createdAt',
      sortBy: 'desc',
      data: [],
      page: 1,
      total: 0,
      limit: 10,
      search: '',
    };
    this.CustomFieldPTable = {
      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,
      total: 0,
      limit: 10,

      search: '',
    };

    this.apoinmentSearchSubscription = this.apoinmentSearchInput
      .pipe(
        map((event) => {
          const target = event.target as HTMLTextAreaElement;
          return target.value;
        }),
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap((search) => of(search).pipe(delay(500)))
      )
      .subscribe((input) => {
        this.appoinmentPTable.page = 1;
        this.loadAppoinment();
      });
  }

  ngOnInit(): void {
    this.submitted = true;
    this.loadAppoinment();
    this.loadAllBusinessUnit();
    this.loadRole();
    this.displayAssignData();
    !this.userId && this.getLoginFields();
    this.userId && this.getSingleUserEdit(this.userId);
  }

  loadAllBusinessUnit() {
    this.buUnitDropDownLoading = true;
    this.buUnit.getBusinessUnitData().subscribe((res) => {
      this.buUnitDropDownLoading = false;
      this.parentBuUnitDropDown = res?.data?.planBussinessUnitId || [];
      this.planBuUnitDropDown = res?.data?.planBussinessUnitId || [];
      this.viewBuUnitDropDown = res?.data?.planBussinessUnitId || [];
    }, (error) => {
      this.buUnitDropDownLoading = false;
    });

  }

  displayAssignData() {
    this.userService.getAssignLeave().subscribe((res) => {
      this.assignData = res['data'];
    });
  }

  getFields() {
    return this.fields;
  }

  getSingleUserEdit(userId) {
    const payload = { "userId": userId };
    this.isUserEdited = true;
    const sub = this.userService
      .getOneUser(payload)
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe(
        ({ data: {
          data: {
            name,
            staffId,
            email,
            appointmentId,
            contactNumber,
            allBUAccess,
            status,
            role,
            leaveGroupId,
            parentBussinessUnitId,
            planBussinessUnitId,
            viewBussinessUnitId,
            otherFields
          }
        },

        }: any) => {
          const fileds = otherFields?.sort((a, b) => a.indexNum - b.indexNum);
          this.fields = fileds;
          this.form = new FormGroup({});
          this.fields?.forEach((x) => {
            if (x.type == 'checkbox') {
              this.form.addControl(x.fieldName, new FormGroup({}));
              x.options.forEach((o) => {
                (this.form.get(x.fieldName) as FormGroup).addControl(
                  o.key,
                  new FormControl(false)
                );
              });
            } else {
              this.form.addControl(
                x.fieldName,
                new FormControl(
                  x.value || '',
                  x.required ? Validators.required : null
                )
              );
            }
          });
          const PControl = this.PlanBuUnitFieldsControl;
          const VControl = this.ViewBuUnitControl;
          this.planBuUnit = planBussinessUnitId;
          this.viewBuUnit = viewBussinessUnitId;
          planBussinessUnitId?.forEach((element) => {
            let field = this.formBuilder.group({
              string: [
                element.orgName,
                Validators.compose([Validators.required]),
              ],
              _id: [element._id, Validators.compose([Validators.required])],
            });
            PControl.push(field);
          });
          viewBussinessUnitId?.forEach((element) => {
            let field = this.formBuilder.group({
              string: [
                element.orgName,
                Validators.compose([Validators.required]),
              ],
              _id: [element._id, Validators.compose([Validators.required])],
            });
            VControl.push(field);
          });
          if (appointmentId) {
            this.appoinmentDropDown = [
              ...this.appoinmentDropDown,
              { _id: appointmentId._id, name: appointmentId.name },
            ];
          }
          this.createUserForm.patchValue({
            name: name,
            staffId: staffId,
            email: email,
            appointmentId: appointmentId?._id,
            contactNumber: contactNumber,
            allBUAccess: allBUAccess,
            status: status,
            role: {
              string: role?.name,
              _id: role?._id,
            },

            leaveGroupId: {
              string: leaveGroupId?.name,
              _id: leaveGroupId?._id,
            },

            parentBussinessUnitId: {
              string: parentBussinessUnitId?.orgName,
              _id: parentBussinessUnitId?._id,
            },
          });
          this.isUserEdited = false;
        },
        (error) => {
          this.toastService.error('No Data found');
          this.isUserEdited = false;
        }
      );
  }

  overview() {
    this.overViewEvent.next(null);
  }

  getLoginFields() {
    const { data, total, ...rest } = this.CustomFieldPTable;
    const sub = this.CustomFieldService.getCustomField(rest)
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe(
        ({ data }) => {
          const fileds = data.data.sort((a, b) => a.indexNum - b.indexNum);
          this.fields = fileds || [];
          this.form = new FormGroup({});
          this.fields.forEach((x) => {
            if (x.type == 'checkbox') {
              this.form.addControl(x.fieldName, new FormGroup({}));
              x.options.forEach((o) => {
                (this.form.get(x.fieldName) as FormGroup).addControl(
                  o.key,
                  new FormControl(false)
                );
              });
            } else {
              this.form.addControl(
                x.fieldName,
                new FormControl(
                  x.value || '',
                  x.required ? Validators.required : null
                )
              );
            }
          });
        },
        (error) => { }
      );
  }

  addApoinment() {
    this.loadAppoinment;
    this.modelService.open(this.AddSubSection, {
      windowClass: 'modal-ui-fix right-align',
    });
  }
  /// apoinment

  onSeachAppoinment(value) {
    this.appoinmentPTable.limit = 10;
    this.appoinmentPTable.search = value.term;

    this.loadAppoinment();
  }

  loadMoreAppoinmentItems(value) {
    this.appoinmentPTable.limit = this.appoinmentPTable.limit + 10;

    this.loadAppoinment();
  }

  onClearAppoinmentDropdown(value) {
    if (value === undefined) {
      this.createUserForm.patchValue({
        appointmentId: null,
      });
      this.appoinmentPTable.search = '';
      this.loadAppoinment();

      this.appoinmentDropDown = [];
      this.appoinmentPTable.data = [];
    }
  }

  /// plan buunit
  onSeachPlanBuUnit(value) {
    this.bussinesUnitplanPTable.limit = 10;
    this.bussinesUnitplanPTable.search = value.term;

    this.loadBussinessPlanUnit();
  }

  loadMorePlasnBuUnitItems(value) {
    this.bussinesUnitplanPTable.limit = this.bussinesUnitplanPTable.limit + 10;

    this.loadBussinessPlanUnit();
  }

  onClearPlanBuUnitDropdown(value) {
    if (value === undefined) {
      this.createUserForm.patchValue({
        parentBussinessUnitId: {
          string: null,
          _id: null,
        },
      });
      this.bussinesUnitplanPTable.search = '';
      this.loadBussinessPlanUnit();

      this.planBuUnitDropDown = [];
      this.bussinesUnitplanPTable.data = [];
    }
  }

  // view bu unit
  onSeachViewBuUnit(value) {
    this.bussinesUnitviewPTable.limit = 10;
    this.bussinesUnitviewPTable.search = value.term;

    this.loadBussinessViewUnit();
  }

  loadMoreViewBuUnitItems(value) {
    this.bussinesUnitviewPTable.limit = this.bussinesUnitviewPTable.limit + 10;

    this.loadBussinessViewUnit();
  }

  onClearViewBuUnitDropdown(value) {
    if (value === undefined) {
      this.createUserForm.patchValue({
        parentBussinessUnitId: {
          string: null,
          _id: null,
        },
      });
      this.bussinesUnitviewPTable.search = '';
      this.loadBussinessViewUnit();

      this.viewBuUnitDropDown = [];
      this.bussinesUnitviewPTable.data = [];
    }
  }
  ///parent bu unit
  onSearchParentBuUnit(value) {
    this.parentbussinesUnitPTable.limit = 10;
    this.parentbussinesUnitPTable.search = value.term;

    this.loadParentBussinessUnit();
  }

  loadMoreParentBuUnitItems(value) {
    this.parentbussinesUnitPTable.limit =
      this.parentbussinesUnitPTable.limit + 10;

    this.loadParentBussinessUnit();
  }

  onClearParentBuUnitDropdown(value) {
    if (value === undefined) {
      // this.viewBussinessUnitForm.patchValue({
      //   bussinessUnit: null,
      // });
      // this.parentbussinesUnitPTable.search = '';
      // this.loadParentBussinessUnit();

      // this.parentBuUnitDropDown = [];
      // this.parentbussinesUnitPTable.data = [];
    }
  }
  loadRole() {
    this.roleDropDownLoading = true;
    // const { data, total, ...rest } = this.rolePTable;
    const subscribe = this.roleService
      .getUserRoles()
      .pipe(
        finalize(() => {
          this.roleDropDownLoading = false;
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const res = response?.data;
          // this.rolePTable.data = res?.roles;;
          this.roleDropDown = res?.roles || [];
          // this.rolePTable.total = res?.roles?.length;
        },
        (error) => {
          // this.rolePTable.data = [];
          // this.rolePTable.total = 0;
          // this.toastService.error('No list found');
        }
      );
  }
  loadAppoinment() {
    this.apppoinmentDropDownLoading = true;
    const { data, total, ...rest } = this.appoinmentPTable;
    const subscribe = this.userService
      .getAppoinments(rest)
      .pipe(
        finalize(() => {
          this.apppoinmentDropDownLoading = false;
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const { count, data } = response.data;
          this.appoinmentPTable.data = data;

          this.appoinmentDropDown = data;
          this.appoinmentPTable.total = count;
        },
        (error) => {
          this.appoinmentPTable.data = [];
          this.appoinmentPTable.total = 0;
          this.toastService.error('No list found');
        }
      );
  }

  loadParentBussinessUnit() {
    this.parentBuUnitDropDownLoading = true;
    const { data, ...rest } = this.parentbussinesUnitPTable;
    const subscribe = this.buUnit
      .getBussinesUnit(rest)
      .pipe(
        finalize(() => {
          this.parentBuUnitDropDownLoading = false;
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const { count, data } = response.data;
          this.parentBuUnitDropDown = data;
        },
        (error) => {
          this.bussinesUnitplanPTable.data = [];
          this.toastService.error('No list found');
        }
      );
  }
  loadBussinessViewUnit() {
    this.viewBuUnitDropDownLoading = true;
    const { data, ...rest } = this.bussinesUnitviewPTable;
    const subscribe = this.buUnit
      .getBussinesUnit(rest)
      .pipe(
        finalize(() => {
          this.viewBuUnitDropDownLoading = false;
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const { count, data } = response.data;

          this.viewBuUnitDropDown = data;
        },
        (error) => {
          this.bussinesUnitviewPTable.data = [];

          this.toastService.error('No list found');
        }
      );
  }
  loadBussinessPlanUnit() {
    this.planBuUnitDropDownLoading = true;
    const { data, ...rest } = this.bussinesUnitplanPTable;
    const subscribe = this.buUnit
      .getBussinesUnit(rest)
      .pipe(
        finalize(() => {
          this.planBuUnitDropDownLoading = false;
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const { count, data } = response.data;

          this.planBuUnitDropDown = data;
        },
        (error) => {
          this.toastService.error('No list found');
        }
      );
  }
  get PlanBuUnitFieldsControl(): FormArray {
    return this.createUserForm.get('planBussinessUnitId') as FormArray;
  }
  get ViewBuUnitControl(): FormArray {
    return this.createUserForm.get('viewBussinessUnitId') as FormArray;
  }

  // get AppoinmentsControl(): FormArray {
  //   return this.createUserForm.get('appointmentId') as FormArray;
  // }

  get roleControl(): FormArray {
    return this.createUserForm.get('role') as FormArray;
  }

  deleteparentBussinesUnit() {
    this.createUserForm.patchValue({
      parentBussinessUnitId: {
        string: null,
        _id: null,
      },
    });
  }
  async deselectBussinesUnitPlan(value) {
    if (value) {
      const Control = this.PlanBuUnitFieldsControl;
      const index = await Control.value.findIndex((x) => x._id === value._id);
      if (index > -1) {
        Control?.removeAt(index);
        // this.toastService.error(`Business unit (Plan) is already removed`);
      }

    }
  }
  deselectAllBussinesUnitPlan(value) {
    const Control = this.PlanBuUnitFieldsControl;
    Control?.clear();
  }

  addAllBussinesUnitPlan(value) {
    if (value && value?.length > 0) {
      const Control = this.PlanBuUnitFieldsControl;
      Control?.clear();
      value?.map(x => {
        let field = this.formBuilder.group({
          string: [
            x.orgName,
            Validators.compose([Validators.required]),
          ],
          _id: [x._id, Validators.compose([Validators.required])],
        });
        Control.push(field);
      });

      //         // this.planBuUnit = [];
      //         // this.dropdownBplanRef.closeDropdown();
    }
  }

  async addBussinesUnitPlan(value) {
    if (value) {
      const Control = this.PlanBuUnitFieldsControl;
      const index = await Control.value.findIndex((x) => x._id === value._id);
      if (index > -1) {
        this.toastService.error(`Business unit (Plan) is already added`);
      } else {
        let field = this.formBuilder.group({
          string: [
            value.orgName,
            Validators.compose([Validators.required]),
          ],
          _id: [value._id, Validators.compose([Validators.required])],
        });
        Control.push(field);

      }
    }
  }

  async deselectAllBussinesUnitView(value) {
    const Control = this.ViewBuUnitControl;
    Control?.clear();
  }

  async deselectBussinesUnitView(value) {
    if (value) {
      const Control = this.ViewBuUnitControl;
      const index = await Control.value.findIndex((x) => x._id === value._id);
      if (index > -1) {
        Control?.removeAt(index);
        // this.toastService.error(`Business unit (Plan) is already removed`);
      }

    }
  }

  addAllBussinesUnitView(value) {
    if (value && value?.length > 0) {
      const Control = this.ViewBuUnitControl;
      Control?.clear();
      value?.map(x => {
        let field = this.formBuilder.group({
          string: [
            x.orgName,
            Validators.compose([Validators.required]),
          ],
          _id: [x._id, Validators.compose([Validators.required])],
        });
        Control.push(field);
      });
    }
  }

  async addBussinesUnitView(value) {
    if (value) {
      const Control = this.ViewBuUnitControl;
      const index = await Control.value.findIndex((x) => x._id === value._id);

      if (index > -1) {
        this.toastService.error(`${value.name} is already added`);
      } else {
        let field = this.formBuilder.group({
          string: [
            value.orgName,
            Validators.compose([Validators.required]),
          ],
          _id: [value._id, Validators.compose([Validators.required])],
        });
        Control.push(field);
      }
    }
  }

  addParentBussinentUnit(value) {
    if (value) {
      this.createUserForm.patchValue({
        parentBussinessUnitId: {
          string: value.orgName,
          _id: value._id,
        },
      });
      this.parentBuUnit = [];
    }

  }

  async deletePlanBuUnitFields(index, id) {
    const Control = this.PlanBuUnitFieldsControl;
    Control.removeAt(index);
    const buUnitIndex = await this.planBuUnit.findIndex((x) => x._id === id);
    this.planBuUnit?.splice(buUnitIndex, 1)

  }
  addRole(value) {
    if (value) {
      this.createUserForm.patchValue({
        role: {
          string: value.name,
          _id: value._id,
        },
      });
      this.selectedRole = [];
    }

  }

  addAssignLeave(value) {
    if (value) {
      this.createUserForm.patchValue({
        leaveGroupId: {
          string: value.name,
          _id: value._id,
        },
      });
      this.selectedLeaveGroup = [];
    }
  }

  deleteroleFields(index) {
    this.createUserForm.patchValue({
      role: {
        string: null,
        _id: null,
      },
    });
  }

  deleteAssignFields(index) {
    this.createUserForm.patchValue({
      leaveGroupId: {
        string: null,
        _id: null,
      },
    });
  }

  async deleteViewBuUnitFields(index, id) {
    const Control = this.ViewBuUnitControl;
    Control.removeAt(index);
    const buUnitIndex = await this.viewBuUnit.findIndex((x) => x._id === id);
    this.viewBuUnit?.splice(buUnitIndex, 1)
  }

  isCheckModel($event) {
    this.createUserForm.patchValue({});
  }
  appoinmentCreate() {
    if (this.createApoinmentForm.valid) {
      if (!this.isAppoinmentEdited) {
        this.userService
          .createAppoinment(this.createApoinmentForm.value)
          .subscribe(
            (res) => {
              this.appoinemtnForm();
              this.loadAppoinment();
              this.toastService.success('Appoinment Created Succesfully');
            },
            (error) => {
              this.toastService.error('This appointment name already exists');
            }
          );
      } else {
        const payload = {
          appointmentId: this.onAppoinmentEditID,
          name: this.createApoinmentForm.get('name').value,
          status: this.createApoinmentForm.get('status').value
        }
        this.userService
          .updateAppoinment(
            payload
          )
          .subscribe(
            (res) => {
              this.appoinemtnForm();
              this.loadAppoinment();
              this.isAppoinmentEdited = false;
              this.toastService.success('Appoinment Updated Succesfully');
            },
            (error) => {
              this.toastService.error('This appointment name already exists');
            }
          );
      }
    }
  }

  onEditAppoinment(item) {
    this.isAppoinmentEdited = true;
    this.onAppoinmentEditID = item._id;
    this.createApoinmentForm.patchValue({
      name: item.name,
    });
  }

  async onCreateUser() {
    if (!this.createUserForm.valid) {
      return this.toastService.error('Please fill up all mandatory fields');
    }

    this.isUpdateLoading = true;
    const PControl = this.PlanBuUnitFieldsControl;
    const VControl = this.ViewBuUnitControl;
    // const appointmentsControl = this.AppoinmentsControl
    let viewBuUnit = await VControl.value.map((item) => item._id);
    let planBuUnit = await PControl.value.map((item) => item._id);
    // let appointmentUnit = await appointmentsControl.value.map((item) => item._id);
    const role = Object.values(this.createUserForm.get('role').value);
    const leaveGroupId = Object.values(
      this.createUserForm.get('leaveGroupId').value
    );
    const parentUnit = Object.values(
      this.createUserForm.get('parentBussinessUnitId').value
    );
    const modifiedData = this.fields.map((rawProduct) => {
      return {
        fieldId: rawProduct._id,
        editable: rawProduct.editable,
      };
    });

    const values = Object.values(this.form.value);

    let newOjbect = modifiedData.map((item, i) =>
      Object.assign({}, item, { value: values[i] })
    );

    const { status, ...rest } = this.createUserForm.value;
    const data = {
      ...rest,
      status,
      role: role[1],
      leaveGroupId: leaveGroupId[1] || null,
      parentBussinessUnitId: parentUnit[1],
      planBussinessUnitId: [...planBuUnit],
      viewBussinessUnitId: [...viewBuUnit],

      otherFields: newOjbect,
      allBUAccess: this.createUserForm.get('allBUAccess').value ? 1 : 0,
    };

    if (this.userId) {
      data['userId'] = this.userId;
      this.userService.updateUser(data).subscribe(
        (res) => {
          this.submitted = false;
          this.initForm();
          this.form.reset();
          // this.activeId = 1;
          this.isUpdateLoading = false;
          this.toastService.success(`User Updated`);
          this.overViewEvent.emit(null);
        },
        (error) => {
          this.isUpdateLoading = false;
        }
      )
    } else {
      data['isFlexiStaff'] = 0;
      this.userService.createUser(data).subscribe(
        (res) => {
          this.initForm();
          this.form.reset();
          // this.activeId = 1;
          this.isUpdateLoading = false;
          this.toastService.success(`User Created`);
          this.overViewEvent.emit(null);
        },
        (error) => {
          this.toastService.error(error?.error?.message);
          this.isUpdateLoading = false;
        }
      );
    }

  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  checkLimit(min: number, max: number): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (c.value && (isNaN(c.value) || c.value < min || c.value > max)) {
        return { range: true };
      }
      return null;
    };
  }

  initForm() {
    this.createUserForm = this.formBuilder.group({
      name: ['', [Validators.required, this.noWhitespaceValidator]],
      staffId: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(EMAIL_PATTERN)] ],
      appointmentId: [null, Validators.required],
      status: [1, Validators.required],
      // isFlexiStaff:[0],
      staffPassExpiryDate: [""],
      contactNumber: [null, [Validators.maxLength(10)]],
      allBUAccess: [0, Validators.required],
      role: this.formBuilder.group({
        string: ['', Validators.compose([Validators.required])],
        _id: ['', Validators.compose([Validators.required])],
      }),
      leaveGroupId: this.formBuilder.group({
        string: [''],
        _id: [''],
      }),
      parentBussinessUnitId: this.formBuilder.group({
        string: ['', Validators.compose([Validators.required])],
        _id: ['', Validators.compose([Validators.required])],
      }),
      planBussinessUnitId: this.formBuilder.array([]),
      viewBussinessUnitId: this.formBuilder.array([]),
      otherFields: this.formBuilder.array([]),
    });
    this.appoinemtnForm();
  }
  get f() {
    return this.createUserForm.controls;
  }

  appoinemtnForm() {
    this.createApoinmentForm = this.formBuilder.group({
      name: ['', [Validators.required, this.noWhitespaceValidator]],
      status: [1],
    });
  }

  onDeleteAppoinment(id) {
    const appointId = { appointmentId: id }
    const subscribe = this.userService
      .deleteAppoinment(appointId)
      .pipe(
        finalize(() => {
          this.apppoinmentDropDownLoading = false;
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          this.toastService.success(`Appointment Deleted`);
          this.loadAppoinment();
        },
        (error) => { }
      );
  }
}
