<mat-horizontal-stepper [linear]="isLinear" #stepper>
  <mat-step [stepControl]="firstFormGroup">
    <form [formGroup]="createNewsForm">
      <ng-template matStepLabel> NEWS CONTENT </ng-template>
      <div class="card">
        <h5 class="card-header bg-theme">NEWS CONTENT</h5>
        <div class="card-body" formGroupName="teaser">
          <div class="row">
            <div class="col-md-4">
              <label class="font-weight-bold">Listing type<span class="field-mandatory">*</span></label>
              <select class="dropdown-select w-100 form-control" formControlName="listingType">
                <option *ngFor="let cnt of listingType" [ngValue]="cnt.value">
                  {{ cnt.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-12">
              <div class="form-group">
                <label class="font-weight-bold" for="exampleInputEmail1">Teaser Title (Limit to 150 Character)<span
                    class="field-mandatory">*</span>
                </label>
                <div [froalaEditor]="options" formControlName="title"></div>
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-md-4">
              <h6>Teaser Image<span class="field-mandatory">*</span></h6>
              <div class="custom-file mt-0 pt-4 pb-4">
                <input class="btn custom-file-input cursor-pointer"
                  [disabled]="isTeaserLoading" (click)="openImageCropUpload('teaser')" id="c" />

                <label class="custom-file-label" for="i">
                  <i class="fa" [ngClass]="{
                      'fa-spin fa-spinner fa-2x': isTeaserLoading,
                      ' fa-plus-circle fa-2x': !isTeaserLoading
                    }" aria-hidden="true"></i>
                </label>
              </div>
            </div>

            <div class="col-md-4" *ngIf="createNewsForm.get('teaser').get('image').value">
              <div class="image-with-delete-icon" style="width: 250px">
                <i class="fa fa-close" title="Delete" (click)="onDeleteIcon('teaser')"></i>
                <img [src]="validateURLImage(createNewsForm.get('teaser').get('image').value)" crossorigin="anonymous"
                  alt="Image Preview" />
              </div>
            </div>
          </div>
          <div class="col-md-12 p-0" *ngIf="isSubmitted && createNewsForm.controls.teaser.status === 'INVALID' ">
            <p class="text-danger font-weight-bold">
              Please fill up all the fields
            </p>
          </div>
          <button class="btn btn-success mt-2" (click)="nextStep(1)">
            Next
          </button>
        </div>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="secondFormGroup">
    <form [formGroup]="createNewsForm">
      <ng-template matStepLabel>CONTENT</ng-template>

      <div class="card">
        <h5 class="card-header bg-theme">CONTENT</h5>
        <div class="card-body" formGroupName="content">
          <div class="row mt-4">
            <div class="col-md-12">
              <div class="form-group">
                <label class="font-weight-bold" for="exampleInputEmail1">Title (Limit to 150 Character)<span
                    class="field-mandatory">*</span>
                </label>
                <div [froalaEditor]="options" formControlName="title"></div>
              </div>
            </div>
          </div>
          <div class="row mt-0">
            <div class="col-md-12">
              <div class="row p-3">
                <div class="form-group pr-0">
                  <input type="checkbox" formControlName="isTeaserImage" />
                  &emsp;Same as teaser photo<span class="field-mandatory">*</span>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-4" *ngIf="!createNewsForm.get('content').get('isTeaserImage').value">
            <div class="col-md-4">
              <h6>Content Image<span class="field-mandatory">*</span></h6>
              <div class="custom-file mt-0 pt-4 pb-4">
                <input class="btn custom-file-input cursor-pointer" [disabled]="isIconLoading"
                  (click)="openImageCropUpload('mainImage')" id="c" />
                <label class="custom-file-label" for="i">
                  <i class="fa" [ngClass]="{
                      'fa-spin fa-spinner fa-2x': isIconLoading,
                      ' fa-plus-circle fa-2x': !isIconLoading
                    }" aria-hidden="true"></i>
                </label>
              </div>
            </div>
            <div class="col-md-4" *ngIf="createNewsForm.get('content').get('image').value">
              <div class="image-with-delete-icon" style="width: 250px">
                <i class="fa fa-close" title="Delete" (click)="onDeleteIcon('mainImage')"></i>
                <img [src]="validateURLImage(createNewsForm.get('content').get('image').value)" crossorigin="anonymous"
                  alt="Image Preview" />
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-md-12">
              <div class="form-group">
                <label class="font-weight-bold" for="exampleInputEmail1">News content<span
                    class="field-mandatory">*</span>
                </label>
                <div [froalaEditor]="otheroptions" formControlName="content"></div>
              </div>
            </div>
          </div>
          <div class="col-md-12 p-0" *ngIf="isSubmitted && createNewsForm.controls.content.status === 'INVALID' ">
            <p class="text-danger font-weight-bold">
              Please fill up all the fields
            </p>
          </div>
          <div class="ml-1">
            <button class="btn btn-warning mt-4" (click)="stepper.previous()">
              Prev
            </button>
            <button class="btn btn-danger ml-1 mt-4" (click)="nextStep(2)">Next</button>
          </div>
        </div>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="thirdFormGroup">
    <form [formGroup]="createNewsForm">
      <ng-template matStepLabel>PUBLICATION</ng-template>
      <div class="card">
        <h5 class="card-header bg-theme">PUBLICATION</h5>
        <div class="card-body">
          <div class="row">
            <div class="col-md-4" formGroupName="publish">
              <label class="font-weight-bold">Channel<span class="field-mandatory">*</span></label>
              <ng-select (change)="getCategories($event)" [items]="channelArray" bindLabel="name" bindValue="_id"
                [searchable]="true" placeholder="Channel" formControlName="channelId">
              </ng-select>
            </div>
          </div>
          <div class="row mt-4" formGroupName="publish">
            <div class="col-md-4">
              <label class="font-weight-bold">Category <span class="field-mandatory">*</span></label>
              <ng-select (change)="setCategories($event)" [items]="categoriesArray" bindLabel="name" bindValue="_id"
                [searchable]="true" placeholder="Category" formControlName="categoryId">
              </ng-select>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-4" formGroupName="publish">
              <label class="font-weight-bold">Module</label>
              <ng-select [items]="modulesArray" bindLabel="moduleName" bindValue="_id" [searchable]="true"
                placeholder="Module" formControlName="moduleId"
                [clearable]="createNewsForm.get('publish').get('moduleId').value ? true:false"
                (change)="addModuleData($event)">
              </ng-select>

            </div>
            <div class="col-md-6" *ngIf="createNewsForm.get('publish').get('moduleData').value?.moduleName">
              <div class="form-group">
                <label>&nbsp;</label><br />
                <div class="labels mb-1 mr-1">
                  <span class="d-flex">
                    {{createNewsForm.get('publish').get('moduleData').value?.moduleName}}
                    <span class="
                    material-icons
                    bg-danger
                    rounded
                    btn-close
                    ml-2
                  " (click)="deleteModuleData()">
                      clear
                    </span>
                  </span>
                </div>


              </div>
            </div>


          </div>
          <div formGroupName="publish">
            <div class="row mt-4">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="activeEndDate" class="pb-2 font-weight-bold">Publish Date and time<span
                      class="field-mandatory">*</span></label>
                  <app-date-time-picker [minDate]="minDate" (sendDate)="getDate($event)"
                    formControlName="startDate"></app-date-time-picker>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="activeEndDate" class="pb-2 font-weight-bold">Expire Date and Time<span
                      class="field-mandatory">*</span>
                  </label>
                  <app-date-time-picker [minDate]="efectiveMinDate" formControlName="endDate"></app-date-time-picker>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-direction mt-2">
            <button class="btn btn-sm btn-danger ml-3" (click)="stepper.previous()">
              Back
            </button>

            <button class="btn btn-sm btn-success" (click)="onCreateNews()" [disabled]="!createNewsForm.valid">
              {{ newsId ? "Update News" : " Create News" }}
            </button>

            <button class="btn btn-sm btn-save" [disabled]="!!newsId" (click)="saveAsDraft()">
              Save as Draft
            </button>

            <button class="btn preview-button" (click)="preview()">
              Preview
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </form>
  </mat-step>
</mat-horizontal-stepper>

<ng-template #AddDepartment let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Article Preview 3.75x6.66(inch)
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="smartphone">
      <div class="content container-scroll">
        <div class="pl-2 pr-2 content-container mt-3">
          <img
                src="{{ createNewsForm.get('content').get('image').value || teaserUrl}}"
                alt="News Image"
                crossorigin="anonymous"
                class="img-fluid rounded"
              />
          <div [froalaView]="editorContent" [innerHTML]="contentTitle | safeHtml"></div>
        </div>

        <div class="row m-0 mb-15">
          <div class="col-xs-12 ml-4 mt-4">
            <p class="text-muted channel-name">
              {{ channelName }} / {{ categoryName }}
            </p>
          </div>
        </div>

        <div class="row m-0">
          <div class="col-md-12">
            <span class="d-block ng-binding">Authored By <br />{{ userName }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #AddImageCropper let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Upload Image
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span style="font-size: 30px;" aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="container">
        <div class="row" style="margin-top: 5%;">
          <div class="row">
            <div class="text-center col-md-12">
              <input type="file" #myInput id="myInput" class="text-primary" accept="image/png, image/gif, image/jpeg" (change)="fileChangeEvent($event)" />
              <button class="btn btn-info" (click)="clear()">Clear</button>
            </div>
          </div>
        </div>
        <hr class="horizontal-line" />
        <div *ngIf="!!imageChangedEvent">
          <div class="row text-center p-2 m-2 border-1 bg-wrapper">
            <div class="col-md-2">
              <button (click)="toggleContainWithinAspectRatio()" mat-mini-fab color="warning">
                <mat-icon>{{containWithinAspectRatio?'aspect_ratio':'image_aspect_ratio'}}</mat-icon>
              </button>
            </div>
            <div class="col-md-2">
              <button (click)="resetImage()" mat-mini-fab color="warning">
                <mat-icon>refresh</mat-icon>
              </button>
            </div>
            <div class="col-md-2">
              <button (click)="flipHorizontal()" mat-mini-fab color="success">
                <mat-icon>swap_horiz</mat-icon>
              </button>
            </div>
            <div class="col-md-2">
              <button (click)="flipVertical()" mat-mini-fab color="success">
                <mat-icon>swap_vert</mat-icon>
              </button>
            </div>
            <div class="col-md-2">
              <button (click)="rotateLeft()" mat-mini-fab color="success">
                <mat-icon>rotate_left</mat-icon>
              </button>
            </div>
            <div class="col-md-2">
              <button (click)="rotateRight()" mat-mini-fab color="success">
                <mat-icon>rotate_right</mat-icon>
              </button>
            </div>
          </div>
          <div class="row" style="margin-top: 5%;">
            <div class="text-center col-5">
              <h5>Crop Image</h5>
              <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="4 / 3"
                [cropperMinWidth]="128" [roundCropper]="false" [canvasRotation]="canvasRotation" [transform]="transform"
                [alignImage]="'left'" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                (cropperReady)="cropperReady($event)" (loadImageFailed)="loadImageFailed()">
              </image-cropper>
            </div>
            <div class="text-center col-7">
              <h5>Preview</h5>
              <img [src]="croppedImage" />
            </div>
          </div>
          <div class="row text-center p-2 m-2  border-1 bg-wrapper">
            <div class="col-md-6">
              <button (click)="zoomOut()" mat-mini-fab color="success">
                <mat-icon>zoom_out</mat-icon>
              </button>
            </div>
            <div class="col-md-6">
              <button (click)="zoomIn()" mat-mini-fab color="success">
                <mat-icon>zoom_in</mat-icon>
              </button>
            </div>
          </div>
          <div class="row text-center justify-content-center pt-4">
            <button class="btn btn-lg btn-success" (click)="Upload(imageCategory)">
              Upload
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>