<div class="card mb-3">
  <div class="card-header flexi-header p-4">
    <h3>PASSWORD MANAGEMENT</h3>
  </div>

  <div class="card-body" style="padding: 30px">
    <form [formGroup]="passwordSetupForm">
      <div formGroupName="pwdSettings">

        <div class="row">
          <label class="red-heading col-md-3"> Status</label>
        </div>
        <div class="row">
          <div class="form-group col-md-2 pr-0">
            <input type="radio" id="active" [value]="1" formControlName="status" />
            <label for="active" class="radio-inline ml-2">Active</label>
          </div>
          <div class="form-group col-md-2 pr-0">
            <input type="radio" id="inactive" [value]="2" formControlName="status" />
            <label for="inactive" class="radio-inline ml-2">Inactive</label>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="red-heading" for="exampleInputEmail1"
                >Number of password character</label
              >

              <input
                type="tel"
                class="form-control"
                placeholder="Number of password character"
                formControlName="charLength"
              />
              <!-- <div
                *ngIf="passwordSetupForm.get('pwdSettings.charLength').errors"
                class="invalid-feedback"
              >
                <small
                  *ngIf="
                    passwordSetupForm.get('pwdSettings.charLength').errors
                      .required
                  "
                >
                  Number of password character is required
                </small>
                <small
                  *ngIf="
                    passwordSetupForm.get('pwdSettings.charLength').errors
                      .pattern
                  "
                >
                  Number of password should be an Integer add greater than 4
                </small>
              </div> -->
            </div>
          </div>
        </div>

        <div formGroupName="charTypes">
          <div class="row">
            <div class="col">
              <label class="red-heading"
                >Types of character required in password</label
              >
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <input type="checkbox" id="54" formControlName="lowerCase" />
              <label for="54" class="radio-inline ml-2">  Show
                Lowercase characters (a through)</label>

            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <input type="checkbox" id="55" formControlName="upperCase" />
              <label for="55" class="radio-inline ml-2">  Show Uppercase
                characters (A through Z)</label>

            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <input type="checkbox" id="56" formControlName="numbers" />
              <label for="56" class="radio-inline ml-2"> Show Forms
                Numbers (0 through 9)</label>

            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <input type="checkbox" id="57" formControlName="specialChar" />
              <label for="57" class="radio-inline ml-2">Show Special
                characters (e.g. !,@,#,$,%)</label>

            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="red-heading" for="exampleInputEmail1">
                Password change</label
              >

              <input
                type="tel"
                class="form-control"
                placeholder=" Password Change"
                formControlName="pwdDuration"
                [ngClass]="{
                  'is-invalid':
                    submitted &&
                    passwordSetupForm.get('pwdSettings.pwdDuration').errors
                }"
              />
              <div
                *ngIf="passwordSetupForm.get('pwdSettings.pwdDuration').errors"
                class="invalid-feedback"
              >
                <small
                  *ngIf="
                    passwordSetupForm.get('pwdSettings.pwdDuration').errors
                      .required
                  "
                >
                  Password change is required
                </small>
                <small
                  *ngIf="
                    passwordSetupForm.get('pwdSettings.pwdDuration').errors
                      .pattern
                  "
                >
                  Change password should be between 1-100
                </small>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="red-heading" for="exampleInputEmail1">
                Password reuse</label
              >

              <input
                type="tel"
                class="form-control"
                placeholder=" Password Reuse"
                formControlName="pwdReUse"
                [ngClass]="{
                  'is-invalid':
                    submitted &&
                    passwordSetupForm.get('pwdSettings.pwdReUse').errors
                }"
              />
              <div
                *ngIf="passwordSetupForm.get('pwdSettings.pwdReUse').errors"
                class="invalid-feedback"
              >
                <small
                  *ngIf="
                    passwordSetupForm.get('pwdSettings.pwdReUse').errors
                      .required
                  "
                >
                  Password Reuse is required
                </small>
                <small
                  *ngIf="
                    passwordSetupForm.get('pwdSettings.pwdReUse').errors.pattern
                  "
                >
                  Password Reuse should be should be between 1-100
                </small>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="red-heading" for="exampleInputEmail1"
                >Password retries</label
              >

              <input
                type="tel"
                class="form-control"
                placeholder="Password Retries"
                formControlName="maxLoginAttempt"
                [ngClass]="{
                  'is-invalid':
                    submitted &&
                    passwordSetupForm.get('pwdSettings.maxLoginAttempt').errors
                }"
              />
              <div
                *ngIf="
                  passwordSetupForm.get('pwdSettings.maxLoginAttempt').errors
                "
                class="invalid-feedback"
              >
                <small
                  *ngIf="
                    passwordSetupForm.get('pwdSettings.maxLoginAttempt').errors
                      .required
                  "
                >
                  Password Retries is required
                </small>
                <small
                  *ngIf="
                    passwordSetupForm.get('pwdSettings.maxLoginAttempt').errors
                      .pattern
                  "
                >
                  Password Retries should be should be between 1-100
                </small>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <label class="red-heading col-md-3"> OTP sent for</label>
        </div>
        <div class="row">
          <div class="form-group col-md-2 pr-0">
            <input type="radio" id="otp2" [value]="1" formControlName="otpSentFor" />
            <label for="otp2" class="radio-inline ml-2">Email</label>
          </div>
          <div class="form-group col-md-2 pr-0">
            <input type="radio" id="otp1" [value]="2" formControlName="otpSentFor" />
            <label for="otp1" class="radio-inline ml-2">Mobile</label>
          </div>
        </div>
        <div class="row">
          <label class="red-heading col-md-3"> Password type</label>
        </div>
        <div class="row">
          <div class="form-group col-md-3 pr-0">
            <input type="radio" id="ptype" [value]="1" formControlName="passwordType" />
            <label for="ptype" class="radio-inline ml-2"
              >System generated password</label
            >
          </div>
          <div class="form-group col-md-3 pr-0">
            <input type="radio" id="ptype1" [value]="2" formControlName="passwordType" />
            <label for="ptype1" class="radio-inline ml-2"
              >Default password</label
            >
          </div>
        </div>
        <div
          *ngIf="
            passwordSetupForm.get('pwdSettings').get('passwordType').value == 2
          "
        >
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="red-heading" for="exampleInputEmail1"
                  >Default password</label
                >

                <input
                  type="title"
                  class="form-control"
                  placeholder="Default Password"
                  formControlName="defaultPassword"
                  [ngClass]="{
                    'is-invalid':
                      submitted &&
                      passwordSetupForm.get('pwdSettings.defaultPassword').errors
                  }"
                />
                <div
                  *ngIf="
                    passwordSetupForm.get('pwdSettings.defaultPassword').errors
                  "
                  class="invalid-feedback"
                >
                  <small
                    *ngIf="
                      passwordSetupForm.get('pwdSettings.defaultPassword')
                        .errors.required
                    "
                  >
                    Default password is required
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

</div>
<div class="row">
  <div class="col-md-4">
    <button class="btn btn-submit update-button" [disabled]="isUpdateLoading " (click)="onSubmitPasswordSetupForm()"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isUpdateLoading, '': !isUpdateLoading}"></i>  Update</button>
  </div>
</div>
