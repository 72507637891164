<div class="d-flex flex-column">
  <ul ngbNav [(activeId)]="activeId" #companyNav="ngbNav" class="nav-tabs rounded">
    <li [ngbNavItem]="1">
      <a ngbNavLink (click)="overViewEvent($event)">OVERVIEW</a>
      <ng-template ngbNavContent>
        <boards-overview *ngIf="!isBoardView" (ViewEvent)="boardView($event)" (EditEvent)="boardEdit($event)">
        </boards-overview>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink *ngIf="isBoardView">VIEW</a>
      <ng-template ngbNavContent>
        <boards-overview *ngIf="!isBoardView" (ViewEvent)="boardView($event)" (EditEvent)="boardEdit($event)">
        </boards-overview>
        <view-board *ngIf="isBoardView" [boardId]="boardViewId" (overViewEvent)="overViewEvent($event)"></view-board>
      </ng-template>
    </li>
    <li [ngbNavItem]="3">
      <a ngbNavLink>{{isBoardEdited? 'EDIT' : 'CREATE'}}</a>
      <ng-template ngbNavContent>
        <app-create-edit-boards [boardId]="boardId" (overViewEvent)="overViewEvent($event)"></app-create-edit-boards>
      </ng-template>
    </li>
    <li [ngbNavItem]="4">
      <a ngbNavLink (click)="hideViewTab()">EMOJI'S</a>
      <ng-template ngbNavContent>
        <app-emoji-component></app-emoji-component>
      </ng-template>
    </li>
    <li [ngbNavItem]="5">
      <a ngbNavLink (click)="hideViewTab()">REPORTED POSTS</a>
      <ng-template ngbNavContent>
        <app-reported-posts></app-reported-posts>
      </ng-template>
    </li>
    <li [ngbNavItem]="6">
      <a ngbNavLink (click)="hideViewTab()">REPORTED COMMENTS</a>
      <ng-template ngbNavContent>
        <app-reported-comments></app-reported-comments>
      </ng-template>
    </li>
    <li [ngbNavItem]="7">
      <a ngbNavLink (click)="hideViewTab()">QUESTIONNAIRE</a>
      <ng-template ngbNavContent>
        <app-questionnaire></app-questionnaire>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="companyNav"></div>
</div>