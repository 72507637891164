<div class="card">
    <div class="card-header flexi-header">
        <h3>MY PAGE</h3>
    </div>
    <div class="card-body">
        <div class="row col-10">
            <label class="font-weight-bold ml-3 mt-2">Compliments </label>
            <div class="flex-direction col-sm-4">
                <div class="w-100">
                    <ng-select (change)="addCompliments($event)" class="custom" bindLabel="name" [virtualScroll]="false"
                        placeholder="" appendTo="body" [multiple]="false" [searchable]="true" [clearable]="true" [hideSelected]="true">
                        <ng-option [value]="item" *ngFor="let item of wallDropDowns">
                            {{ item.wallName }}
                        </ng-option>
                    </ng-select>
                </div>
            </div>
            <div *ngFor="let item of complementsArray; let index = index">
                <span class="text-items list-ui mt-1">{{ item.wallName }}
                    <img class="ml-3 close-icon" (click)="deleteCompliments(index)" src="./assets/images/cancel.png"
                        width="20px" height="20px" /></span>
            </div>
        </div>

        <div class="row col-10 suggestions-container">
            <label class="font-weight-bold ml-3 mt-2">Suggestions </label>
            <div class="flex-direction col-sm-4 ml-2">
                <div class="w-100">
                    <ng-select (change)="addSuggestions($event)" [(ngModel)]="suggestions" class="custom" bindLabel="name" [virtualScroll]="false"
                        placeholder="" appendTo="body" [multiple]="false" [searchable]="true" [clearable]="true" [hideSelected]="true">
                        <ng-option [value]="item" *ngFor="let item of wallDropDowns">
                            {{ item.wallName }}
                        </ng-option>
                    </ng-select>
                </div>
            </div>
            <div *ngFor="let item of suggestionsArray; let index = index">
                <span class="text-items list-ui mt-1">{{ item.wallName }}
                    <img class="ml-3 close-icon" (click)="deleteSuggestionsArray(index)"
                        src="./assets/images/cancel.png" width="20px" height="20px" /></span>
            </div>
        </div>
        <div class="ml-2" style="margin-top: 30px;">
            <button class="btn btn-add" (click)="updateCompanySettings()">Update</button>
        </div>
    </div>
</div>