import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { SetupService } from 'src/app/users/service/operations/setup.service';
import { OpsGroupService } from 'src/app/users/service/ops-group/ops-group.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ops-edit',
  templateUrl: './ops-edit.component.html',
  styleUrls: ['./ops-edit.component.scss'],
})
export class OpsEditComponent implements OnInit {
  @Output() ChangeTabId = new EventEmitter<any>();
  @Input() editValue;
  activeId: number = 1;
  opsGroupForm: FormGroup;
  opsCreateGroupForm: FormGroup;
  buOption: any=[];
  checkedBu: any;
  buLoader = false;
  administrationLoader = false;
  teamNo = [];
  selectedAdmin:any = [];
  checkedBuShift: [];
  selectedBuShift:any = [];
  groupBuShiftId = [];
  groupAdmintId = [];
  teamNameArray = [];
  allUserList = [];
  shiftSchemeAdmin: any=[];
  showRow: boolean = false;
  groupName: any;
  groupAdmin: any;
  groupBuShift: any;
  groupTeamNo: any;
  teamCountNo: any;
  teamName: any;
  forUserBuId = [];
  staffUserId = [];
  staffObj: { buId: any[] };
  teamCountInt: any;
  staffUser: any;
  staffUserDet: any;
  teamOpsObj: {};
  teamOpsobjArr = [];
  createGroupObj: any;
  staffDetail : any;
  selectProducts: any;
  teamOpsEditObj: {};
  opsNewArray: any = [];
  userIdOld: any = [];
  selectedOpsId: any;
  editBtn: any;
  deletedTeam: any;
  addFromBu: boolean = false;
  removeFromBu: boolean = false;
  transferFromBu: boolean = false;
  updateBtn: boolean = false;
  removeStaffObj: any;
  removeTeamObj: {};
  removeTeamUser: any = [];
  deletedStaff: any;
  removeStaffUser: any = [];
  removeBuObj: {};
  opsGroupId: any;
  opsGroupdata: any;
  userIdForBuRemove: any = [];
  disableInput: boolean = true;
  selectedTeamIndex: number = 0;
  transferTeamArr: any[];
  userIdList: void[];
  selectedTransferTeamIndex: any;
  sourceTeamId: any;
  destinationTeamId: any;
  ifNoTeam: boolean = true;
  renShow: boolean = true;
  tempstaffUserDet: any;
  removedStaff: any = [];
  updateOpsGrpLoader: boolean;
  dropdownSettingsSourceBU:any={};
  dropdownSettingsAdmin:any={};
  staffUserArray = []

  constructor(
    private fb: FormBuilder,
    private buService: SetupService,
    private opsGroupService: OpsGroupService,
    public toastService: ToastrService
  ) {
    this.dropdownSettingsSourceBU = {
      singleSelection: false,
      text: 'Enter/Search Business Unit',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'myclass custom-class',
      labelKey: 'orgName',
      primaryKey: '_id',
      badgeShowLimit: 1,
      // showCheckbox:true,
      lazyLoading: true,
      noDataLabel: 'No Data Available',
      maxHeight: 250

    };
    this.dropdownSettingsAdmin = {
      singleSelection: false,
      text: 'Search Administration',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'myclass custom-class',
      labelKey: 'name',
      primaryKey: '_id',
      badgeShowLimit: 1,
      // showCheckbox:true,
      lazyLoading: true,
      noDataLabel: 'No Data Available',
      maxHeight: 250
    }
  }

  ngOnInit() {
    this.opsGroupForm = this.fb.group({
      groupname: ['', [Validators.required]],
      teamName: [[], [Validators.required]],
      opsTeamName: ['', [Validators.required]],
      opsTeamTransfer: ['', [Validators.required]],
      numberteam: ['', [Validators.required]],
      swap: ['0', [Validators.required]],
      opsAction: ['', [Validators.required]],
      editable: ['', [Validators.required]],
    });
    this.getSourceBuList();
    this.getAdministrationList();
    this.editData();
    for (let i = 0; i <= 40; i++) {
      this.teamNo.push(i.toString());
    }

  }

  getAdministrationList() {
    this.administrationLoader = true;
    this.opsGroupService.getOpsGroupAdminList().subscribe((res) => {
      this.administrationLoader = false;
      this.shiftSchemeAdmin = res['data'];
    }, (err) => {
      this.administrationLoader = false;
    });
  }

  getSourceBuList() {
    this.buLoader = true;
    // const { data, ...rest } = this.buParameters;
    const buSubscribe = this.buService
      .getBusinessUnitBUShift().subscribe(
        (res: any) => {
          // if (res.success) {
          this.buLoader = false;
          this.buOption = res?.data?.planBussinessUnitId || [];


          // } else {
          //   this.buParameters.data = [];
          // }
        }, (error) => {
          this.buLoader = false;
          this.buOption = [];
        }
      );
  }
  editData() {
    this.opsGroupService.allPassedData.subscribe((allPassedData) => {
      this.selectProducts = allPassedData;
      this.selectProducts.forEach((element) => {
        this.opsGroupForm.patchValue({
          groupname: element.opsGroupName,
          swap: element.swopSetup,
          numberteam: element.noOfTeam,
        });
        this.teamCountInt = element?.noOfTeam;

        const transformedBu = element.buId.map((item: any) => {
          return {
            _id: item._id,
            orgName: item?.sectionId.departmentId.companyId.name + ' > ' +
              item?.sectionId.departmentId.name +
              ' > ' +
              item?.sectionId.name +
              '> ' +
              item?.name,
          }
        });
        this.selectedBuShift = transformedBu;

        this.selectedOpsId = element?._id;
        this.selectedAdmin = element?.adminId;

        this.teamNameArray = [];

        if (element?.noOfTeam > 0) {
          this.allUserList = [];
          this.ifNoTeam = false;
          this.teamNameArray = element?.opsTeamId.map((item) => {
            return {
              ...item,
              isInputDisabled: true,
            };
          });
        } else {
          this.ifNoTeam = true;
          this.allUserList = element?.userId;
          this.teamNameArray = element?.userId.map((item) => {
            return {
              userId: [{ ...item }],
              isInputDisabled: true,
            };
          });
        }
        this.selectedteam(this.selectedTeamIndex, 'o', null);
      });
    });

  }

  removeBuId(buitem) {
    this.opsGroupdata = this.selectProducts;
    this.selectedBuShift = this.selectedBuShift.filter(
      (item) => item._id !== buitem._id
    );

    this.selectProducts.forEach((item) => {
      this.opsGroupId = item._id;
      item.userId.forEach((element) => {
        if (element.parentBussinessUnitId._id === buitem._id) {
          this.userIdForBuRemove.push(element._id);
        }
      });
    });

    this.removeBuObj = {
      buId: buitem._id,
      opsGroupId: this.opsGroupId,
      userId: this.userIdForBuRemove,
    };
    this.opsGroupService.removeBu(this.removeBuObj).subscribe((res) => { });
  }
  removeAdminId(AdminId) {
    this.selectedAdmin = this.selectedAdmin.filter(
      (adminitem) => adminitem._id !== AdminId._id
    );
  }

  changeTabIdNextBTn() {
    this.activeId = 3;
    this.checkedBu = this.checkedBuShift;

    this.tempstaffUserDet = this.staffUserDet;
    const temStaf = this.staffUserDet.filter((val) => {
      if (!val.removed) {
        return true;
      }
    });

    this.staffUserDet = [...temStaf];
  }
  changeTabId() {
    this.activeId = 2;
    this.groupName = this.opsGroupForm.get('groupname').value;
    this.groupAdmin = this.selectedAdmin;
    this.groupBuShift = this.selectedBuShift;
    this.teamName = this.opsGroupForm.get('teamName').value;

    this.selectedBuShift.forEach((element) => {
      this.groupBuShiftId.push(element._id);
    });

    this.selectedAdmin.forEach((element) => {
      this.groupAdmintId.push(element._id);
    });

    this.opsGroupForm.patchValue({
      opsAction: 1,
    });
    this.handleApprove(1);
  }

  changeTabIdPrevious() {
    this.activeId = 1;
    this.groupBuShiftId = [];
    this.groupAdmintId = [];
    this.staffUser = this.staffUserArray
  }

  changeTabIdPreviouBtn() {
    this.activeId = 2;
  }

  intializeTeamArr(i) {
    const defaultObj = {
      name: '',
      admin: [],
      userId: [],
      isInputDisabled: false,
    };
    for (let j = 0; j < i; j++) {
      this.teamNameArray.push(defaultObj);
    }
  }

  enableInput(i) {
    this.teamNameArray[i].isInputDisabled = false;
  }

  addAnotherTeam() {
    this.ifNoTeam = false;
    this.intializeTeamArr(1);
    this.teamCountInt = this.teamNameArray.length;
  }

  selectedteam(i, type, id) {
    this.staffUserDet = this.teamCountInt === 0 ? this.allUserList : this.teamNameArray[this.selectedTeamIndex]?.userId;
    if (type === 'o') {
      this.sourceTeamId = id;
      this.selectedTeamIndex = i;
    }
    if (type === 't') {
      this.destinationTeamId = id;
      this.selectedTransferTeamIndex = i;
    }
    this.transferTeamList();
  }

  getBuShift(obj) {
    this.forUserBuId.push(obj._id);
    this.staffObj = {
      buId: this.forUserBuId,
    };

    this.opsGroupService.staffList(this.staffObj).subscribe((res) => {
      this.staffUser = res.data;
    });
  }

  handleApprove(i) {
    if (i == 1) {
      this.addFromBu = true;
      this.removeFromBu = false;
      this.transferFromBu = false;

      const bu = [];
      this.groupBuShift.forEach((val) => {
        bu.push(val._id);
      });

      this.staffObj = {
        buId: bu,
      };
      this.staffUserArray = this.staffUser
      this.opsGroupService.staffList(this.staffObj).subscribe((res) => {
        this.staffUser = res.data;
        if (this.staffUserArray?.length > 0 ) {
          this.staffUser = this.staffUserArray
        }
      });
    } else if (i == 2) {
      this.addFromBu = false;
      this.removeFromBu = true;
      this.transferFromBu = false;
    } else if (i == 3) {
      this.addFromBu = true;
      this.removeFromBu = false;
      this.transferFromBu = true;
      this.transferTeamList();
    }
  }

  transferTeamList() {
    this.transferTeamArr = this.teamNameArray.filter((team) => {
      if (team.name !== this.teamNameArray[this.selectedTeamIndex].name) {
        return team;
      }
    });
  }

  transferUsers(user) {
    let userIndex;
    const userList = this.teamNameArray[this.selectedTeamIndex].userId;
    userList.forEach((ele, i) => {
      if (ele._id === user._id) {
        userIndex = i;
      }
    });
    userList.splice(userIndex, 1);
    this.teamNameArray.forEach((team, i) => {
      if (team._id === this.destinationTeamId) {
        this.teamNameArray[i].userId.push(user);
      }
    });
  }

  addStaffUser() {
    if (!this.staffDetail || this.staffDetail === '' ) {
      return
    }
    this.staffUserId = [];
    this.staffUserDet.forEach((element) => {
      this.staffUserId.push(element._id);
    });
    this.teamOpsObj = {
      admin: [],
      name: this.opsGroupForm.get('opsTeamName').value,
      userId: this.staffUserId,
    };
    this.opsNewArray.push(this.teamOpsObj);
    if (this.teamCountInt==0){
      let lastTeamObjIndex = this.teamNameArray.length-1
      this.teamNameArray.push({
        userId: [this.staffDetail],
        isInputDisabled: true,
      })
      this.staffUserDet.push(this.staffDetail)
    } else {
      this.teamNameArray[this.selectedTeamIndex].userId.push(this.staffDetail);
    }
    const index = this.staffUser.findIndex(id => id._id === this.staffDetail['_id']);
    if (index > -1) {
      this.staffUser.splice(index, 1);
      this.staffDetail = '';
      this.staffUserArray = this.staffUser
    }
  }

  addTeambutton(i) {
    const teamName = (<HTMLInputElement>(
      document.getElementById('teamIdName' + i)
    )).value;
    this.teamNameArray[i].name = teamName;
    this.teamNameArray[i].isInputDisabled = true;
  }

  removeTeambutton(team) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to remove this team?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: `Yes`,
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.teamNameArray.forEach((t, i) => {
          if (t._id === team._id) {
            this.teamNameArray.splice(i, 1);
          }
        });

        this.deletedTeam = team;

        this.deletedTeam?.userId.forEach((item) => {
          this.removeTeamUser.push(item._id);
        });

        this.removeTeamObj = {
          userId: this.removeTeamUser,
          teamId: this.deletedTeam._id,
        };

        this.opsGroupService.removeTeam(this.removeTeamObj).subscribe((res) => {
          console.log('Removed Team', res);
        });

        this.teamCountInt = this.teamNameArray.length;
        this.opsGroupForm.controls.opsTeamName.setValue(0)
        this.selectedTeamIndex = 0;
        team.userId.forEach( ele => this.staffUser.push(ele) ) 
        this.selectedteam(this.selectedTeamIndex, 'o', this.teamNameArray[0]._id);
      }
    });
  }

  removeStaffbutton(item, index) {
    this.deletedStaff = item;
    this.removeStaffObj = {
      userId: [this.deletedStaff._id],
    };
    this.opsGroupService.removeTeam(this.removeStaffObj).subscribe((res) => {
      this.staffUser.push(item)
      this.staffUserDet[index]['removed'] = true;
      const teRem = this.staffUserDet[index];
      this.staffUserDet.splice(index, 1, this.staffUserDet[index]);
      this.staffUserDet = [...this.staffUserDet];
      this.removedStaff.push(item._id);
    });
  }

  clearLocationLabel() {
    this.teamName = '';
  }

  onEditForm() {
    this.userIdOld = [];
    this.opsNewArray = this.teamNameArray.map((team) => {
      return {
        ...team,
        userId: [...team.userId.map((user) => user._id)],
      };
    });

    this.teamNameArray.forEach((team) => {
      team.userId.forEach((user) => {
        this.userIdOld.push(user._id);
      });
    });

    this.createGroupObj = {
      adminId: this.groupAdmintId,
      buId: this.groupBuShiftId,
      isDraft: false,
      noOfTeam: this.teamCountInt,
      opsGroupName: this.groupName,
      opsTeam: this.opsNewArray,
      swopSetup: this.opsGroupForm.get('swap').value,
      userId: this.userIdOld,
      _id: this.selectedOpsId,
    };

    if (this.removedStaff.length) {
      let userArr = this.createGroupObj.userId;
      this.removedStaff.forEach((val) => {
        const ind = userArr.indexOf(val);
        if (ind != -1) {
          userArr.splice(ind, 1);
        }

        this.createGroupObj.opsTeam.forEach((tv) => {
          const uvIn = tv.userId?.indexOf(val);
          if (uvIn != -1) {
            tv.userId.splice(uvIn, 1);
          }
        });
      });

      this.createGroupObj['userId'] = userArr;

      let teamArr = this.createGroupObj.opsTeam;
    }

    this.updateOpsGrpLoader = true;
    const updateOpsGrp = this.opsGroupService
      .updateOpsGroup(this.createGroupObj)
      .pipe(
        finalize(() => {
          updateOpsGrp.unsubscribe();
          this.updateOpsGrpLoader = false;
        })
      )
      .subscribe((res) => {
        if (res['success']) {
          this.toastService.success(res['message'], 'Successful');
          this.ChangeTabId.emit(1);
        } else {
          this.toastService.error(res['message']);
        }
      });
  }

  async deleteSourceBU(id) {
    const buUnitIndex = await this.selectedBuShift?.findIndex((x) => x._id === id);
    this.selectedBuShift?.splice(buUnitIndex, 1)
  }

  async deleteAdmin(id) {
    const adminIndex = await this.selectedAdmin?.findIndex((x) => x._id === id);
    this.selectedAdmin?.splice(adminIndex, 1)
  }
}
