<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs rounded mb-3">
  <li [ngbNavItem]="1">
    <a ngbNavLink>Overview</a>
    <ng-template ngbNavContent>
      <div class="card table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">LEAVE GROUP NAME</th>
              <th scope="col">CREATED BY AND DATE</th>
              <th scope="col">LAST MODIFIED BY AND DATE</th>
              <th scope="col">ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let leaveGroup of leaveGroupList">
              <td>{{ leaveGroup.name }}</td>
              <td>
                {{ leaveGroup.createdBy.name }}, {{ leaveGroup.createdAt }}
              </td>
              <td>
                {{ leaveGroup.updatedBy.name }}, {{ leaveGroup.updatedAt }}
              </td>
              <td>
                <div class="d-flex">
                  <button
                    class="btn btn-info btn-sm"
                    (click)="onlyView = true; editLeaveGroup(leaveGroup, true)"
                  >
                    View
                  </button>
                  <button
                    class="btn btn-success btn-sm ml-2"
                    (click)="
                      onlyView = false; editLeaveGroup(leaveGroup, false)
                    "
                  >
                    Edit
                  </button>
                  <button
                    class="btn btn-danger btn-sm ml-2"
                    (click)="onlyView = false; editLeaveGroup(leaveGroup, true)"
                  >
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="2">
    <a ngbNavLink>{{ onlyView ? "View" : "Create" }}</a>
    <ng-template ngbNavContent>
      <div class="card">
        <h5 class="card-header bg-theme">SETUP LEAVE GROUPINGS</h5>
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label class="font-weight-500">Enter Leave Grouping Name</label>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="groupName"
                  [disabled]="onlyView"
                />
              </div>
            </div>

            <div class="col-md-12 mb-3">
              <div class="table-responsive rounded border">
                <table class="table table-bordered customTable1 m-0">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col" *ngFor="let leave of leaveTypeList">
                        {{ leave["name"] }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ staticLeaveGroupName[0] }}</td>
                      <td *ngFor="let leaveGroup of leaveTypeList">
                        <input
                          type="checkbox"
                          [(ngModel)]="leaveGroup.displayQuota"
                          [disabled]="onlyView"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ staticLeaveGroupName[1] }}
                      </td>
                      <td *ngFor="let leaveGroup of leaveTypeList">
                        <ng-container *ngIf="leaveGroup.displayQuota">
                          <input
                            class="form-control w-50"
                            type="text"
                            [(ngModel)]="leaveGroup.quota"
                            [disabled]="onlyView"
                          />
                        </ng-container>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ staticLeaveGroupName[2] }}</td>
                      <td *ngFor="let leaveGroup of leaveTypeList">
                        <ng-container *ngIf="leaveGroup.displayQuota">
                          <input
                            type="checkbox"
                            [(ngModel)]="leaveGroup.displayInMyLeave"
                            [disabled]="onlyView"
                          />
                        </ng-container>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ staticLeaveGroupName[3] }}</td>
                      <td *ngFor="let leaveGroup of leaveTypeList">
                        <ng-container *ngIf="leaveGroup.displayQuota">
                          <div
                            *ngIf="
                              leaveGroup && leaveGroup.proRate.length !== 0;
                              else empty
                            "
                          >
                            <li
                              *ngFor="let pt of leaveGroup.proRate"
                              style="
                                font-size: 10px;
                                text-align: inherit;
                                text-transform: capitalize;
                              "
                              (click)="proRateModal(leaveGroup)"
                            >
                              <span *ngIf="pt.toMonth !== 0">
                                {{ pt.fromMonth }} to {{ pt.toMonth }} months
                                completed : {{ pt.quota }} days
                              </span>
                              <span *ngIf="pt.toMonth === 0">
                                {{ pt.fromMonth }} months above :
                                {{ pt.quota }} days
                              </span>
                            </li>
                          </div>
                          <ng-template #empty>
                            <input
                              type="checkbox"
                              [(ngModel)]="leaveGroup.proRateDisplay"
                              (click)="proRateModal(leaveGroup)"
                              [disabled]="onlyView"
                            />
                            {{ leaveGroup.proRate }}
                          </ng-template>
                        </ng-container>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ staticLeaveGroupName[4] }}</td>
                      <td *ngFor="let leaveGroup of leaveTypeList">
                        <ng-container *ngIf="leaveGroup.displayQuota">
                          <div
                            *ngIf="
                              leaveGroup && leaveGroup.seniority.length !== 0;
                              else empty
                            "
                          >
                            <li
                              *ngFor="let pt of leaveGroup.seniority"
                              style="
                                font-size: 10px;
                                text-align: inherit;
                                text-transform: capitalize;
                              "
                              (click)="seniorityModal(leaveGroup)"
                            >
                              {{ pt.year }} years completed :
                              {{ pt.quota }} days
                            </li>
                          </div>
                          <ng-template #empty>
                            <input
                              type="checkbox"
                              [(ngModel)]="leaveGroup.seniorityDisplay"
                              (click)="seniorityModal(leaveGroup)"
                              [disabled]="onlyView"
                            />
                          </ng-template>
                        </ng-container>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ staticLeaveGroupName[5] }}</td>
                      <td
                        *ngFor="let leaveGroup of leaveTypeList; let i = index"
                      >
                        <ng-container *ngIf="leaveGroup.displayQuota">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              [(ngModel)]="leaveGroup.isLeaveRequest"
                              [disabled]="onlyView"
                              id="{{ 'leavecheck' + i }}"
                            />
                            <label
                              class="form-check-label"
                              for="{{ 'leavecheck' + i }}"
                            >
                              Leave Request
                            </label>
                          </div>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              [(ngModel)]="leaveGroup.isAdminAllocate"
                              [disabled]="onlyView"
                              id="{{ 'allocatecheck' + i }}"
                            />
                            <label
                              class="form-check-label"
                              for="{{ 'allocatecheck' + i }}"
                            >
                              Admin Allocate
                            </label>
                          </div>
                        </ng-container>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ staticLeaveGroupName[6] }}</td>
                      <td
                        *ngFor="let leaveGroup of leaveTypeList; let i = index"
                      >
                        <ng-container *ngIf="leaveGroup.displayQuota">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              [(ngModel)]="leaveGroup.isApplyLeavePlan"
                              [disabled]="onlyView"
                              id="{{ 'plancheck' + i }}"
                            />
                            <label
                              class="form-check-label"
                              for="{{ 'plancheck' + i }}"
                            >
                              Apply Leave Plan
                            </label>
                          </div>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              [(ngModel)]="leaveGroup.isApplyLeave"
                              [disabled]="onlyView"
                              id="{{ 'applycheck' + i }}"
                            />
                            <label
                              class="form-check-label"
                              for="{{ 'applycheck' + i }}"
                            >
                              Apply Leave
                            </label>
                          </div>
                        </ng-container>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group m-0">
                <label class="font-weight-500">Add admin</label>
                <ng-select
                  [items]="adminList"
                  [multiple]="true"
                  bindLabel="name"
                  [(ngModel)]="selectedAdmin"
                  groupBy="selectedAllGroup"
                  [searchable]="true"
                  [selectableGroup]="true"
                  [selectableGroupAsModel]="true"
                  [closeOnSelect]="false"
                  [ngModelOptions]="{ standalone: true }"
                  placeholder="Select Admin"
                  [selectableGroupAsModel]="true"
                  [disabled]="onlyView"
                >
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    {{ item.name }}
                  </ng-template>
                </ng-select>
              </div>
            </div>

            <div class="col-md-4 align-self-end">
              <div class="labels mr-1 mb-1" *ngFor="let item of selectedAdmin">
                <span class="text"> {{ item.name }}</span>
                <span
                  class="material-icons bg-danger rounded btn-close"
                  (click)="removeAdminId(item)"
                  >clear</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div *ngIf="!onlyView">
            <div *ngIf="isEdit; else create">
              <button
                type="button"
                class="btn btn-info"
                (click)="updateLeaveGroup()"
              >
                Update
              </button>
              <ng-container *ngIf="isDelete">
                <button
                  type="button"
                  class="btn btn-danger ml-2"
                  (click)="deleteLeaveGroup()"
                >
                  Delete
                </button>
              </ng-container>
            </div>
            <ng-template #create>
              <button
                type="button"
                class="btn btn-info"
                (click)="createLeaveGroup()"
              >
                Create
              </button>
            </ng-template>
          </div>
        </div>
      </div>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav"></div>

<div
  class="modal"
  [ngClass]="isProrateModelOpen ? 'show-modal' : ''"
  id="mmmModal"
>
  <div class="modal-backdrop">
    <div class="modal-dialog" style="width: 50%; height: 200px">
      <div class="modal-content modalContentBoxGrey">
        <div class="modal-header red-container">
          <div style="text-align: right; color: yellow">Annual Leave</div>
          <div style="text-align: center">PRO RATE TABLE</div>
          <div style="text-align: left; color: yellow">Quota - {{ quota }}</div>
        </div>
        <div class="modal-body">
          <table class="table col-md-12 table-bordered">
            <thead>
              <tr>
                <th
                  scope="col"
                  style="text-align: center; width: 10%; font-size: 12px"
                ></th>
                <th scope="col" style="text-align: center; font-size: 12px">
                  <label class="color-maroon">Slab No</label>
                </th>
                <th scope="col" style="text-align: center; font-size: 12px">
                  <label class="color-maroon">From (in months)</label>
                </th>
                <th scope="col" style="text-align: center; font-size: 12px">
                  <label class="color-maroon">To (in months)</label>
                </th>
                <th scope="col" style="text-align: center; font-size: 12px">
                  <label class="color-maroon">Pro Rate Quota</label>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let int of proRateSlab; let index = index">
                <td>
                  <div style="line-height: 2; text-align: center">
                    <i
                      class="fa fa-trash"
                      style="line-height: 2"
                      (click)="removeproRateSlab(index)"
                    ></i>
                  </div>
                </td>
                <td>
                  <div style="line-height: 2; text-align: center">
                    {{ index + 1 }}
                  </div>
                </td>
                <td>
                  <select
                    id="fromThis"
                    [(ngModel)]="int.fromMonth"
                    class="custom-select form-control"
                  >
                    <option *ngFor="let id of proList">
                      {{ id.val }}
                    </option>
                  </select>
                </td>
                <td>
                  <select
                    id="toThis"
                    [(ngModel)]="int.toMonth"
                    class="custom-select form-control"
                  >
                    <option *ngFor="let id of proList">
                      {{ id.val }}
                    </option>
                  </select>
                </td>
                <td style="text-align: center">
                  <input
                    type="text"
                    [(ngModel)]="int.quota"
                    style="width: 50px; text-align: center; margin-top: 10px"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button
            class="btn footer-button btn-success"
            (click)="addNewProSlab()"
            style="float: left"
          >
            Add Another Slab
          </button>
          <button
            class="btn footer-button btn-success"
            (click)="submitProRate(proRateSlab)"
          >
            Submit
          </button>
          <button
            class="btn footer-button btn-danger"
            (click)="isProrateModelOpen = false"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal"
  [ngClass]="isSeniorityModelOpen ? 'show-modal' : ''"
  id="mmmModal"
>
  <div class="modal-backdrop">
    <div class="modal-dialog" style="width: 50%; height: 200px">
      <div class="modal-content modalContentBoxGrey">
        <div class="modal-header red-container">
          <div style="text-align: right; color: yellow">Annual Leave</div>
          <div style="text-align: center">SENIORITY TABLE</div>
          <div style="text-align: left; color: yellow">Quota - {{ quota }}</div>
        </div>
        <div class="modal-body">
          <table class="table col-md-12 table-bordered">
            <thead>
              <tr>
                <th
                  scope="col"
                  style="text-align: center; width: 10%; font-size: 12px"
                ></th>
                <th scope="col" style="text-align: center; font-size: 12px">
                  <label class="color-maroon">Slab No</label>
                </th>
                <th scope="col" style="text-align: center; font-size: 12px">
                  <label class="color-maroon">Years (Completed)</label>
                </th>
                <th scope="col" style="text-align: center; font-size: 12px">
                  <label class="color-maroon">Quota</label>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let int of senioritySlab; let index = index">
                <td>
                  <div style="line-height: 2; text-align: center">
                    <i
                      class="fa fa-trash"
                      style="line-height: 2"
                      (click)="removesenioritySlab(index)"
                    ></i>
                  </div>
                </td>
                <td>
                  <div style="line-height: 2; text-align: center">
                    {{ index + 1 }}
                  </div>
                </td>
                <td>
                  <select
                    id="fromThis"
                    [(ngModel)]="int.year"
                    class="custom-select form-control"
                  >
                    <option *ngFor="let id of proList">
                      {{ id.val }}
                    </option>
                  </select>
                </td>
                <td style="text-align: center">
                  <input
                    type="text"
                    [(ngModel)]="int.quota"
                    style="width: 50px; text-align: center; margin-top: 10px"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button
            class="btn footer-button btn-success"
            (click)="addNewSeniority()"
            style="float: left"
          >
            Add Another Slab
          </button>
          <button
            class="btn footer-button btn-success"
            (click)="submitseniority(senioritySlab)"
          >
            Submit
          </button>
          <button
            class="btn footer-button btn-danger"
            (click)="isSeniorityModelOpen = false"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
