<div class="d-flex flex-column">
  <ul
    ngbNav
    #companyNav="ngbNav"
    [(activeId)]="activeId"
    class="nav-tabs rounded mb-3"
  >
    <li [ngbNavItem]="1">
      <a ngbNavLink>OVERVIEW</a>
      <ng-template ngbNavContent>
        <app-user-shift-setup-overview
          (changeActiveUserBu)="onEditBtn($event)"
          (changeActiveViewBu)="onViewBtn($event)"
        ></app-user-shift-setup-overview>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink class="no-click"> USER SHIFT SETUP</a>
      <ng-template ngbNavContent>
        <div
          class="d-flex justify-content-center flex-column loadingBox"
          *ngIf="userShiftSetupLoader"
        >
          <div class="align-self-center justify-content-center loader"></div>
          <p class="h4 text-center text-dark mt-3 mb-0">Loading...</p>
          <p class="h6 text-center text-dark">Please wait, data is loading</p>
        </div>
        <div class="card">
          <h5 class="card-header bg-theme">
            {{ this.isHeading }}
          </h5>

          <div class="card-body">
            <form [formGroup]="buUserShiftSetUpForm">
              <div
                class="custom p-3 mb-3"
                [ngClass]="{ disabled: activateDisable }"
              >
                <div class="row mb-2">
                  <label class="font-weight-bold red-heading col-md-3"
                    >BUSINESS UNIT</label
                  >
                </div>
                <div class="row mb-2">
                  <div class="col-md-3">
                    <label>Parent Business unit </label>
                    <ng-select
                      [attr.disabled]="true"
                      class="custom disabled"
                      bindLabel="name"
                      formControlName="parentBusiness"
                      placeholder="Parent Business unit"
                      appendTo="body"
                      [multiple]="false"
                      [searchable]="true"
                      [clearable]="true"
                      [closeOnSelect]="false"
                    >
                      <!-- <ng-option [value]="item" [disabled]="item.disabled">
                        </ng-option> -->
                    </ng-select>
                    <table class="table table-borderless mt-3">
                      <tbody>
                        <tr>
                          <div class="list-ui">
                            <div class="form-text-conteol">
                              {{ parentUnit }}
                              <!-- <span>X</span> -->
                              <!-- (click)="clearLabel(i)" -->
                            </div>
                          </div>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-md-3">
                    <label> Business unit (Plan) </label>
                    <ng-select
                      class="custom disabled"
                      bindLabel="name"
                      formControlName="planBusiness"
                      placeholder="Business unit (Plan)"
                      appendTo="body"
                      [multiple]="false"
                      [searchable]="true"
                      [closeOnSelect]="false"
                      [clearable]="true"
                    >
                      <!-- <ng-option
        [value]="item"
        *ngFor="let item of planBuUnitDropDown"
        >
        {{ item.businessUnit }}
        </ng-option> -->
                    </ng-select>
                    <table class="table table-borderless mt-3">
                      <tbody>
                        <tr>
                          <div
                            class="list-ui"
                            *ngFor="let item of planBusiness"
                          >
                            <div class="form-text-conteol">{{ item.orgName }}</div>
                          </div>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-md-3">
                    <label> Business unit (view) </label>
                    <ng-select
                      class="custom disabled"
                      bindLabel="name"
                      placeholder="Business unit (view)"
                      appendTo="body"
                      formControlName="viewBusiness"
                      [multiple]="false"
                      [searchable]="true"
                      [closeOnSelect]="false"
                      [clearable]="true"
                    >
                    </ng-select>
                    <table class="table table-borderless mt-3">
                      <tbody>
                        <tr>
                          <div
                            class="list-ui"
                            *ngFor="let item of viewBusiness"
                          >
                            <div class="form-text-conteol">
                              {{ item.orgName }}
                            </div>
                          </div>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row mb-2">
                  <label class="font-weight-bold red-heading col-md-3"
                    >USER INFORMATION</label
                  >
                </div>
                <div class="row mb-2">
                  <div class="col-md-3 mt-1">
                    <div class="form-group">
                      <label class="pb-2 form-bg" for="exampleInputEmail1"
                        >Name</label
                      >

                      <input
                        disabled
                        type="text"
                        formControlName="userName"
                        class="form-control disabled"
                        placeholder=" Name"
                        required
                      />{{ name }}
                    </div>
                  </div>
                  <div class="col-md-3 mt-1">
                    <div class="form-group">
                      <label class="pb-2 form-bg" for="exampleInputEmail1"
                        >Staff ID</label
                      >

                      <input
                        disabled
                        type="text"
                        formControlName="staffId"
                        class="form-control disabled"
                        placeholder="Staff ID"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <label> Appoinment </label>
                    <ng-select
                      class="custom disabled"
                      bindLabel="name"
                      formControlName="appoinment"
                      placeholder="Select Section"
                      appendTo="body"
                      [disabled]="true"
                      [multiple]="false"
                      [searchable]="false"
                      [clearable]="false"
                      [closeOnSelect]="false"
                    >
                    </ng-select>
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label class="form-bg" for="exampleInputEmail1"
                        >Contact Number</label
                      >

                      <input
                        disabled
                        type="text"
                        formControlName="contactNum"
                        class="form-control disabled"
                        placeholder="Contact Number"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label class="form-bg" for="exampleInputEmail1"
                        >Email</label
                      >

                      <input
                        type="text"
                        formControlName="userEmail"
                        disabled
                        class="form-control disabled"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                </div>

                <div class="row mb-2">
                  <label class="font-weight-bold red-heading col-md-3"
                    >CUSTOM FIELD</label
                  >
                </div>
                <div class="row mb-2">
                  <div class="col-md-3" *ngFor="let item of customFields">
                    <div class="form-group">
                      <label class="form-bg" >{{item.fieldLabel}}</label>
                      <input
                        type="text"
                        [formControlName]="item.formControl"
                        disabled
                        class="form-control disabled"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row p-3">
                <label class="font-weight-bold col-md-3 red-heading"
                  >ADDITIONAL USER INFORMATION</label
                >
              </div>
              <!-- </form> -->
              <div class="custom p-3 mb-3">
                <div class="row mb-2">
                  <div class="col-md-3">
                    <label>Roles</label>
                    <ng-select
                    class="custom"
                      bindLabel="name"
                      appendTo="body"
                      [multiple]="false"
                      [searchable]="false"
                      [clearable]="false"
                      formControlName="userRoles"
                      [(ngModel)]="selectedRole"
                      [ngModelOptions]="{standalone: true}"
                      placeholder="Please select Role"
                    >
                    <ng-option
                    [value]="item"
                    *ngFor="let item of userRole"
                  >
                    <!--*ngFor="let item of sectionDropDown"-->
                    {{ item.name }}
                  </ng-option>
                    </ng-select>
                  </div>
                  <div class="col-md-2 buttons mt-4" *ngIf="isVisible">
                    <button class="btn btn-clear p-2 m-1" (click)="clearRoles()">Clear</button>
                    <button
                      class="btn btn-add p-2 m-1"
                      (click)="addRoles()"
                    >
                      Add
                    </button>
                  </div>
                  <div class="col-md-6">
                    <tr>
                      <p class="mb-1">Roles</p>
                      <div class="text-item list-ui" *ngIf="userEdit">
                        {{ this.userEdit.role.name }}
                      </div>
                    </tr>
                  </div>
                </div>
                <!-- <form class="paddingDiv"> -->
                <div class="row mb-2">
                  <div class="col-md-3">
                    <label>SHIFT SCHEME</label>
                    <!-- [ngClass]="getClassForEdit()" -->
                    <!-- [attr.disabled]="true" -->
                    <ng-select
                      class="custom"
                      bindLabel="name"
                      formControlName="shiftScheme"
                      placeholder="Please select Shift Scheme"
                      appendTo="body"
                      [multiple]="false"
                      [searchable]="true"
                      [clearable]="true"
                      [closeOnSelect]="false"
                    >
                      <ng-option
                        [value]="item"
                        *ngFor="let item of schemeTable"
                      >
                        <!--*ngFor="let item of sectionDropDown"-->
                        {{ item.schemeName }}
                      </ng-option>
                    </ng-select>
                  </div>
                  <div class="col-md-2 buttons mt-4" *ngIf="isVisible">
                    <button class="btn btn-clear p-2 m-1">Clear</button>
                    <button
                      class="btn btn-add p-2 m-1"
                      (click)="addSchemeButton()"
                    >
                      Add
                    </button>
                  </div>
                  <div class="col-md-4">
                    <p class="mb-1">Shift Schemes</p>
                    <div class="closeAdd" *ngIf="schemeLabel">
                      <div class="text-item list-ui">
                        {{ schemeLabel }}
                        <!-- <span (click)="clearSchemeLabel(i)">X</span> -->
                      </div>
                    </div>
                  </div>
                </div>
                <!-- </form>
              <form class="paddingDiv"> -->
                <div class="row mb-2">
                  <div class="col-md-3">
                    <label>Select Skillsets </label>
                    <!-- [ngClass]="getClassForEdit()" -->
                    <ng-select
                      [attr.disabled]="true"
                      class="custom"
                      bindLabel="name"
                      formControlName="mainSkillSets"
                      placeholder="Please select Skillsets"
                      appendTo="body"
                      [multiple]="false"
                      [searchable]="true"
                      [clearable]="true"
                      [closeOnSelect]="true"
                      (change)="onSkillSetChange($event)"
                    >
                      <ng-option [value]="i" *ngFor="let i of buSkillSet">
                        {{ i.name }}
                      </ng-option>
                    </ng-select>
                  </div>
                  <div class="col-md-3" *ngIf="tier == 2">
                    <label> Select Speciality </label>
                    <ng-select
                      class="custom"
                      bindLabel="name"
                      formControlName="subSkillSets"
                      placeholder="Please select Speciality"
                      appendTo="body"
                      [multiple]="false"
                      [searchable]="true"
                      [closeOnSelect]="true"
                      [clearable]="true"
                    >
                      <ng-option
                        [value]="item"
                        *ngFor="let item of buSkillspeciality"
                        >{{ item.name }}</ng-option
                      >
                    </ng-select>
                  </div>
                  <div class="col-md-2 buttons mt-4" *ngIf="isVisible">
                    <button class="btn btn-clear p-2 m-1">Clear</button>
                    <button
                      class="btn btn-add p-2 m-1"
                      (click)="addUserSkillbutton()"
                    >
                      Add
                    </button>
                  </div>
                  <div class="col-md-4">
                    <p class="mb-1">Skillsets & Specialities</p>
                    <div *ngIf="this.userSkillset != null">
                      <div
                        class="text-item list-ui"
                        *ngFor="let item of userSkillset; let i = index"
                      >
                        {{ item[0] }} >
                        <ng-container *ngIf="tier == 2">
                          {{ item[1] }}
                        </ng-container>

                        <span *ngIf="isVisible">
                          <img
                            class="ml-3"
                            (click)="clearLabel(i)"
                            src="./assets/images/cancel.png"
                            width="20px"
                            height="20px"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="card-footer">
            <div class="d-flex">
              <button
                type="button"
                class="align-items-center btn btn-outline-dark d-flex"
                (click)="navigateToOverview()"
              >
                <span class="material-icons mr-1"> arrow_back </span>
                <span>Back</span>
              </button>
              <button
                class="btn btn-lg btn-success ml-2"
                *ngIf="isVisible"
                type="submit"
                (click)="saveSetupDetails()"
                [disabled]="userShiftSetupLoader"
              >
                Update Shift Setup Details
                <span
                  *ngIf="userShiftSetupLoader"
                  class="spinner-border ml-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="companyNav"></div>
</div>
