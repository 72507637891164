<div class="card my-3 w-100"  >
  <div class="card-header flexi-header p-4">
    <h3>QUESTIONNAIRE</h3>
  </div>
  <div class="card-body">
    <div class="card table-card">
      <div class="card-body flexi-body">
        <div class="flex-column">
          <div class="d-flex justify-content-between mt-2 mb-3">
            <select
              class="custom-select"
              style="width: auto"
              name="pageSize"

              (change)="onLimitChangeUsers()"
              [(ngModel)]="usersPTable.limit"
            >
              <option [ngValue]="10">10</option>
              <option [ngValue]="20">20</option>
              <option [ngValue]="30">30</option>
              <option [ngValue]="40">40</option>
              <option [ngValue]="50">50</option>
            </select>
            <div class="form-group form-inline mb-0">
              <span *ngIf="usersPTable.isLoading">Please wait...</span>
              <input
                class="form-control ml-2"
                type="search"
                name="searchTerm"
                (search)="loadUsers()"
                (keyup)="userssearchInput.next($event)"
                placeholder="Search here"
                [(ngModel)]="usersPTable.search"
              />
            </div>
          </div>
          <div class="table-responsive">
            <table class="table" *ngIf="!isTableLoading">
              <thead>
                <tr>
                  <th
                    scope="col"
                    sortable="Tier 1"
                    (sort)="onSortUsers($event, 'title')"
                  >
                  Post Title
                  </th>
                  <th
                    scope="col"
                    sortable="Tier 2"
                    (sort)="onSortUsers($event, 'wallId.wallName')"
                  >
                  Social Wall
                  </th>
                  <th
                    scope="col"
                    sortable="Tier 3"
                    (sort)="onSortUsers($event, 'createdAt')"
                  >
                  Created Date/Time
                  </th>

                  <th
                    scope="col"
                    sortable="Tier 4"
                    (sort)="onSortUsers($event, 'author.name')"
                  >
                  User Name
                  </th>

                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of usersPTable.data">
                  <td>
                    {{ item.title }}
                  </td>

                  <td>{{ item.wallId.wallName }}</td>

                  <td>{{ item.createdAt | date }}</td>
                  <td>{{ item.author.name }}</td>
                  <td>
                    <button class="btn btn-clear p-2 m-1" (click)="exportData(item._id)">Export</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <app-skliton-loading-table [numberOfCols]="5" [isTableLoading]="isTableLoading"></app-skliton-loading-table>
        </div>
      </div>
      <div class="card-footer">
        <div class="d-flex justify-content-between">
          <p>Total records {{ usersPTable.total }}</p>
          <ngb-pagination
            [collectionSize]="usersPTable.total"
            [(page)]="usersPTable.page"
            [maxSize]="5"
            (pageChange)="onPageChangeUsers($event)"
            [pageSize]="usersPTable.limit"
          >
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
