<div class="card mb-3">
    <h5 class="card-header bg-theme">FORMS</h5>
    <div class="card-body">
      <div class="card table-card">
        <div class="card-body flexi-body">
          <div class="flex-column">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      sortable="Tier 1"
                    >
                     DATE  </th>
                    <th
                      scope="col"
                      sortable="Tier 2"
                    >
                    FORM TITLE
                    </th>
                    <th
                      scope="col"
                      sortable="Tier 3"
                    >
                    {{followUP | uppercase}}
                    </th>
                  </tr>
                </thead>
                <tbody>
                    <div *ngIf="usersPTable?.length === 0 ">
                        <h5 style="text-align: center; margin-left: 200px;">No Records Found</h5>
                    </div>
                  <tr *ngFor="let item of usersPTable">
                    <td>
                      {{ item.updatedAt | date: "mediumDate" }}
                    </td>
                    <td >
                        {{formTitle}}
                      </td>
                      <td   *ngIf="item?.showFormWorkflowStatus">
                        <ng-container *ngFor="let workflow of item.showFormWorkflowStatus">
                          {{ workflow.status}}
                        </ng-container>

                      </td>
                      <td   *ngIf="item?.showFormStatus">
                        <ng-container *ngFor="let workflow of item.showFormStatus">
                          {{ workflow.status}}
                        </ng-container>
                      </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!--<div class="card-footer">
          <div class="d-flex justify-content-between">
            <p>Total Records {{ usersPTable.total }}</p>
          </div>
        </div>-->
      </div>
    </div>
  </div>
