import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-bootstrap-alert',
  templateUrl: './bootstrap-alert.component.html',
  styleUrls: ['./bootstrap-alert.component.scss']
})
export class BootstrapAlertComponent implements OnInit {
  @Input() departmentCustomMessage;
  @Input() sectionCustomMessage;
  @Input() alertType;
  
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert;
  constructor() { }

  ngOnInit(): void {
    setTimeout(() => this.selfClosingAlert.close(), 3000);
  }
}
