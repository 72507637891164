import { Component, OnInit ,ElementRef, ViewChild, Output, EventEmitter} from '@angular/core';
import SignaturePad from 'signature_pad';
import { MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-signature-questions',
  templateUrl: './signature-questions.component.html',
  styleUrls: ['./signature-questions.component.scss']
})
export class SignatureQuestionsComponent implements OnInit {
  title = 'signatureJS';
  signaturePad: SignaturePad;
  @ViewChild('canvasel') canvasEl: ElementRef;
  @Output() takeSignature = new EventEmitter<string>();

  signatureImg: string;
  constructor(public modalRef:MatDialogRef<SignatureQuestionsComponent>) { }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
  }

  startDrawing(event: Event) {

  }

  moved(event: Event) {
    // works in device not in browser
  }

  clearPad() {
    this.signaturePad.clear();
  }

  savePad() {
    const base64Data = this.signaturePad.toDataURL();
    this.signatureImg = base64Data;
    this.takeSignature.emit(this.signatureImg);

  }
  close(){
    this.modalRef.close();
  }

}
