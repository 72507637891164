import { Component, OnInit, Input } from "@angular/core";
import * as moment from "moment";
import { ShiftSchedulerService } from "src/app/users/service/operations/shift-scheduler.service";
@Component({
  selector: "app-status",
  templateUrl: "./status.component.html",
  styleUrls: ["./status.component.scss"],
})
export class StatusComponent implements OnInit {
  @Input() buId;
  @Input() week;
  @Input() refreshStatus;

  shiftDetails:any=null;
  existingShiftDetails:any=null;
  shiftLog: any;
  status: any;
  date = [];
  time: any;
  colDate: any;
  colTime: any;
  isOverview = true;
  logDetail: any;
  showShiftDetails: any;
  shiftDetail = false;
  BUname: any;

  constructor(private planShiftService: ShiftSchedulerService) {}

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.statusLogShift();
  }

  statusLogShift() {
    this.shiftLog = {
      businessUnitId: this.buId._id,
      startDate: this.week,
      status: 5,
    };

    this.planShiftService
      .getShiftLog(this.shiftLog)
      .subscribe((res: { data: { orgName; result } }) => {
        this.status = res.data;
       // this.BUname = res.data.orgName
      });
    this.colDate = moment.utc(this.date).format("DD-MM-YYYY");
    this.colTime = moment.utc(this.date).format("HH:MM");
  }



  changeOverview(value, item) {
    this.isOverview = value;
    this.logDetail = item;
    if (item) {
      this.shiftDetails = item?.adjustedShift 
        ? JSON.parse(item.adjustedShift)
        : item?.existingShift
        ? JSON.parse(item.existingShift)
        : item?.shiftDetails
        ? JSON.parse(item.shiftDetails)
        : null;
    } else {
      this.shiftDetails = null;
    }
  }

  changeShiftDetailsView(value, item) {
    this.showShiftDetails = value;
    this.shiftDetail = item;
  }
}
