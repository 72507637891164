import { Component, OnInit ,Input,EventEmitter,Output} from '@angular/core';
import { CentralBuilderService } from 'src/app/users/service/central-builder/central-builder.service';

@Component({
  selector: 'app-view-forms',
  templateUrl: './view-forms.component.html',
  styleUrls: ['./view-forms.component.scss']
})
export class ViewFormsComponent implements OnInit {
  public isModuleView: boolean = false;
  selectedModule:any=null;
  @Input() formId: string = null;
  @Input() editFormData:any=null;
  @Output() overViewEvent = new EventEmitter<string>();





  constructor(private centralBuilderService: CentralBuilderService) { }

  ngOnInit(): void {
    // this.formId && this.getQuestions(this.formId);


  }
  ngOnChanges(){
    if(this.editFormData){
      this.selectedModule=this.editFormData?.moduleId || null;
      this.isModuleView = true;
      }
  }
  back (){
    this.overViewEvent.emit(null);
  }

  // getQuestions(moduleId: string) {
  //   this.centralBuilderService.getSingleQuestions(moduleId).subscribe((res) => {
  //     this.isModuleView = true;
  //     this.selectedModule = res.data.moduleData;
  //   });
  // }

}
