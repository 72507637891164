import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { queryParam } from 'src/app/core/utils/helper';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomFieldService {
  private baseUrl: string;
  private get Headers(): HttpHeaders {
    return new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }
  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }
  getCustomField(data): Observable<any> {
    return this.http.get(`${this.baseUrl}/custom-field/?${queryParam(data)}`, {
      headers: this.Headers,

    }).pipe(
      map((res) => res as any)
    )
  }


  createCustomField(data){
    return this.http.post(`${this.baseUrl}/userField/create`, data, {
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }

  updateCustomField(data){
    return this.http.post(`${this.baseUrl}/userField/update`, data, {
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }
  DeleteCustomField(Id){
    return this.http.delete(`${this.baseUrl}/custom-field/${Id}`, {
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }

  getCustomFieldList(){
    return this.http.get(`${this.baseUrl}/userField/read`, {
      headers: this.Headers,
    }).pipe(
      map((res) => res as any)
    )
  }

  deleteCustomField(Id){
    return this.http.get(`${this.baseUrl}/userField/remove/${Id}`, {
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }
}
