import { Injectable } from '@angular/core';
import { StorageItem } from '../model/definition.model';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }
  addItem(item: StorageItem): void{
    localStorage.setItem(`flexi${item.key}`, item.value);
  }
  getByKey(key:string): any{
    return localStorage.getItem(`flexi${key}`);
  }
  removeByKey(key:string): void{
    localStorage.removeItem(`flexi${key}`);
  }
  clearAll():void{
    for (const key in localStorage) {
      if (localStorage.hasOwnProperty(key)) {
        const element = localStorage[key];
        if (key.startsWith('flexi')) {
          localStorage.removeItem(key);
        }
      }
    }
  }
}
