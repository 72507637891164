import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { COMMON_MESSAGES } from 'src/app/core/utils/helper';
import { BoardSetupService } from 'src/app/users/service/board-setup/board-setup.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-emoji-component',
  templateUrl: './emoji-component.component.html',
  styleUrls: ['./emoji-component.component.scss']
})
export class EmojiComponentComponent implements OnInit {
  createEmojisForm: FormGroup
  isLoading: boolean;
  constructor(private formBuilder: FormBuilder,
    private boardService: BoardSetupService,
    private tostService: ToastrService,) { }

  ngOnInit() {
    this.initForm()
    this.loadEmojes()
  }
  initForm() {
    this.createEmojisForm = this.formBuilder.group({
      bannerImages: this.formBuilder.array([]),

    });
  }
  get BannersControl(): FormArray {
    return this.createEmojisForm.get('bannerImages') as FormArray;
  }
  /**
    *
    * @param event to get the file through the event
    * @description file upload is done here for the main element
    */
  BannerUpload(event) {
    this.isLoading = true;
    if (event.target.files && event.target.files.length) {
      let file: File = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        const fileUpload = this.boardService
          .uploadEmojiFile(file)
          .pipe(
            finalize(() => {
              fileUpload.unsubscribe();
            })
          )
          .subscribe(
            (data: any) => {
              this.isLoading = false
              this.tostService.success('Emoji Added');
              this.loadEmojes()
            },
            (error: HttpErrorResponse) => {
              this.isLoading = false
              // this.tostService.error(error.error.message, error.statusText);
            }
          );
      } else {
        this.tostService.error(COMMON_MESSAGES.UPLOAD_ONLY_IMAGE);
        event.target.value = '';
        file = null;
        this.isLoading = false;
      }
    }
  }
  /**
   *
   * @description update main element with the respective element id
   */

  onDeleteBannerImage(id: string, index) {
    const subscribe = this.boardService
      .deleteEmojies(id)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      ).subscribe((res) => {
        const Control = this.BannersControl;
        Control.removeAt(index);
        this.tostService.success('Emoji Deleted');
        this.loadEmojes()
      })
  }

  async addBannerImages(link: string) {
    const Control = this.BannersControl;
    let previlege = this.formBuilder.group({
      link: [link, Validators.compose([Validators.required])],
    });
    Control.push(previlege);
  }

  loadEmojes() {
    const Control = this.BannersControl;
    this.clearFormArray(Control)
    const subscribe = this.boardService
      .getAllEmojies()
      .pipe(
        finalize(() => {

          subscribe.unsubscribe();
        })
      )
      .subscribe(
        ({ data }) => {
          // const newData = data.map(e => {
          //   e.logo = `${environment.imageUrl}${e.emoji}`
          //   return e;
          // });

          data.forEach(({ emoji, name, _id }) => {
            let previlege = this.formBuilder.group({

              emoji: [`${environment.imageUrl}/${emoji}`, Validators.compose([Validators.required])],
              name: [name, Validators.compose([Validators.required])],
              _id: [_id, Validators.compose([Validators.required])],
            });
            Control.push(previlege);
          });
        },
        (error) => {

          this.tostService.error('No list found');
        }
      );
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  };
}
