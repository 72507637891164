import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { UsersService } from 'src/app/users/service/user-service/users.service';
import { ToastrService } from 'ngx-toastr';
import { GamificationService } from 'src/app/users/service/gamification/gamification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { COMMON_MESSAGES } from 'src/app/core/utils/helper';

@Component({
  selector: 'app-create-users',
  templateUrl: './create-users.component.html',
  styleUrls: ['./create-users.component.scss'],
})
export class CreateUsersComponent implements OnInit {
  activeId: number = 1;
  isUserEdited: boolean;
  userId: any = null;
  isLoading: boolean = false
  file: File;
  IconUrl: any;
  constructor(
    private toastService: ToastrService,
    private userService: UsersService,
    private gamificationService: GamificationService
  ) {}

  ngOnInit(): void {}

  userEdit(id: string) {
    this.activeId = 2;
    this.isUserEdited = true;
    this.userId = id;
  }
  overViewEvent(value) {
    this.isUserEdited = false;
    this.userId = null;
    this.activeId = 1;
  }

  iconUpload(event) {
    this.isLoading = true;
    if (event.target.files && event.target.files.length) {
      this.file = event.target.files[0];
      if (this.file && !this.file.type.startsWith('image/')) {
        const fileUpload = this.gamificationService.uploadFile(this.file)
          .pipe(
            finalize(() => {
              fileUpload.unsubscribe();
            })
          )
          .subscribe(
            (data: any) => {
              this.IconUrl = this.file.name;
              this.isLoading = false
            },
            (error: HttpErrorResponse) => {
              this.isLoading = false
              this.toastService.error(error?.error?.message, error?.statusText,{
                timeOut: 3000,
              });
            }
          );
      }
      else {
        this.toastService.error(COMMON_MESSAGES.UPLOAD_ONLY_EXCEL);
        event.target.value = '';
        this.file = null
        this.isLoading = false;
      }
    }
    else {
      this.isLoading = false;
    }
  }

  createBulkUsers() {
    const fileUpload = this.userService
      .createBulkUser(this.file)
      .pipe(
        finalize(() => {
          fileUpload.unsubscribe();
        })
      )
      .subscribe(
        (data: any) => {
          this.file = null;
          this.IconUrl = null;
          this.toastService.success('File Uploaded Succesfully');
        },
        (error) => {}
      );
  }
}
