import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { StorageService } from 'src/app/core/service/storage.service';
import { queryParam } from 'src/app/core/utils/helper';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BussinessUnitService {
  private baseUrl: string;
  private get Headers(): HttpHeaders {
    return new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }
  tableParams={
    draw: 0,
    columns: [],
    order: [
      {
        column: 0,
        dir: "desc"
      }
    ],
    start: 0,
    length: 10,
    search: {
      value: "",
      regex: false
    }
  }
  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }
  getBussinesUnit(data, isNew=false): Observable<any> {
    if(isNew){
      return this.http
      .get(`${this.baseUrl}/businessunit/v2/readWithPn?${queryParam(data)}`, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
    }
    return this.http
      .get(`${this.baseUrl}/businessunit/readWithPn?${queryParam(data)}`, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }
  getDepartments(data): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/department/readWithPn`, data,{
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }
  getSection(data): Observable<any> {
    return this.http
      .post(
        `${this.baseUrl}/section/read`, data,
        {
          headers: this.Headers,
        }
      )
      .pipe(map((res) => res as any));
  }
  getSubsection(data): Observable<any> {
    return this.http
      .post(
        `${this.baseUrl}/subsection/read`, data,
        {
          headers: this.Headers,
        }
      )
      .pipe(map((res) => res as any));
  }

  getBusinessUnitData() {
    return this.http
      .get( `${this.baseUrl}/opsgroup/planbu`,
        {
          headers: this.Headers,
        }
      ).pipe(map((res) => res as any));
  }

  createDepartment(department) {
    return this.http
      .post(`${this.baseUrl}/department/create`, department, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }
  updateDepartment(department) {
    return this.http
      .post(`${this.baseUrl}/department/update`, department, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }
  deleteDepartment(data) {
    return this.http
      .post(`${this.baseUrl}/department/delete`,data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }
  createSection(data) {
    return this.http
      .post(`${this.baseUrl}/section/create`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }
  updateSection(data) {
    return this.http
      .post(
        `${this.baseUrl}/section/update`,
        data,
        {
          headers: this.Headers,
        }
      )
      .pipe(map((res) => res as any));
  }
  deleteSection(data) {
    return this.http
      .post(`${this.baseUrl}/section/delete`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }
  createSubsection(data) {
    return this.http
      .post(
        `${this.baseUrl}/subsection/create`,
        data,
        {
          headers: this.Headers,
        }
      )
      .pipe(map((res) => res as any));
  }

  updateBusinessUnitDetails(data: any) {
    return this.http
      .post(
        `${this.baseUrl}/businessunit/updateskillsetandlocation`,
        data,
        {
          headers: this.Headers,
        }
      )
      .pipe(map((res) => res as any));
  }

  updateSubsection(data) {

    return this.http
      .post(
        `${this.baseUrl}/subsection/update`,
        data,
        {
          headers: this.Headers,
        }
      )
      .pipe(map((res) => res as any));
  }
  deleteSubsection(data) {
    return this.http
      .post(
        `${this.baseUrl}/subsection/delete`, data,
        {
          headers: this.Headers,
        }
      )
      .pipe(map((res) => res as any));
  }

  getBusinessUnit(data) {
    return this.http
      .post(
        `${this.baseUrl}/companyuser/read`,
        data,
        {
          headers: this.Headers,
        }
      )
      .pipe(map((res) => res as any));
  }
}
