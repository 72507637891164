<div class="card">
  <h5 class="card-header bg-theme">COMMENTS</h5>
  <div class="card-body">
    <div class="card table-card">
      <div class="card-body flexi-body">
        <div class="flex-column">
          <div class="d-flex justify-content-between mt-2 mb-3">
            <select
              class="custom-select"
              style="width: auto"
              name="pageSize"
              (change)="onLimitChangeUsers()"
              [(ngModel)]="usersPTable.limit"
            >
              <option [ngValue]="10">10</option>
              <option [ngValue]="20">20</option>
              <option [ngValue]="30">30</option>
              <option [ngValue]="40">40</option>
              <option [ngValue]="50">50</option>
            </select>
            <div class="form-group form-inline mb-0">
              <span *ngIf="usersPTable.isLoading">Please wait...</span>
              <input
                class="form-control ml-2"
                type="search"
                (search)="loadUsers()"
                name="searchTerm"
                (keyup)="userssearchInput.next($event)"
                placeholder="Search here"
                [(ngModel)]="usersPTable.search"
              />
            </div>
          </div>
          <div class="table-responsive">
            <table class="table" *ngIf="!isTableLoading">
              <thead>
                <tr>
                  <th
                    scope="col"
                    sortable="Tier 1"
                    (sort)="onSortUsers($event, 'comment')"
                  >
                    Comments
                  </th>
                  <th
                    scope="col"
                    sortable="Tier 2"
                    (sort)="onSortUsers($event, 'postTitle')"
                  >
                    Post Title
                  </th>
                  <th
                    scope="col"
                    sortable="Tier 3"
                    (sort)="onSortUsers($event, 'channelName')"
                  >
                    Social wall/Channel
                  </th>

                  <th
                    scope="col"
                    sortable="Tier 4"
                    (sort)="onSortUsers($event, 'reportedAt')"
                  >
                    Reported Date / Time
                  </th>
                  <th
                    scope="col"
                    sortable="Tier 4"
                    (sort)="onSortUsers($event, 'name')"
                  >
                    USER NAME
                  </th>

                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of usersPTable.data">
                  <td>
                    {{ item?.comment }}
                  </td>
                  <td>
                    {{ item?.postTitle }}
                  </td>

                  <td>{{ item?.channelName }}</td>

                  <td>{{ item?.reportList[0].reportedAt | date: "medium" }}</td>

                  <td>
                    <ul *ngFor="let user of item.reportList">
                      <li>{{ user?.name }}</li>
                    </ul>
                  </td>
                  <td>
                    <label class="switch">
                      <input
                        type="checkbox"
                        (click)="changeCommentStatus(item._id, item.status)"
                        [checked]="item.status === 1 ? 'checked' : ''"
                      />
                      <span class="slider round"></span>
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <app-skliton-loading-table
            [numberOfCols]="6"
            [isTableLoading]="isTableLoading"
          ></app-skliton-loading-table>
        </div>
      </div>
      <div class="card-footer">
        <div class="d-flex justify-content-between">
          <p>Total records {{ usersPTable.total }}</p>
          <ngb-pagination
            [collectionSize]="usersPTable.total"
            [(page)]="usersPTable.page"
            [maxSize]="5"
            (pageChange)="onPageChangeUsers($event)"
            [pageSize]="usersPTable.limit"
          >
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
