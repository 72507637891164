import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-board-setup',
  templateUrl: './board-setup.component.html',
  styleUrls: ['./board-setup.component.scss']
})
export class BoardSetupComponent implements OnInit {
  isBoardEdited: boolean = false
  isBoardView: boolean = false
  activeId = 1
  boardId: string
  boardViewId: string
  constructor() { }

  ngOnInit() {
  }
  overViewEvent(value) {
    this.isBoardEdited = false
    this.isBoardView = false
    this.boardId = null
    this.activeId = 1
  }

  hideViewTab() {
    this.isBoardView = false;
  }

  boardEdit(id: string) {
    this.activeId = 3
    this.isBoardEdited = true
    this.boardId = id
  }
  boardView(id: string) {
    this.activeId = 2
    this.isBoardView = true
    this.boardViewId = id
  }
}
