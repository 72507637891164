import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { SetupService } from 'src/app/users/service/operations/setup.service';

@Component({
  selector: 'app-timesheet',
  templateUrl: './timesheet.component.html',
  styleUrls: ['./timesheet.component.scss'],
})
export class TimesheetComponent implements OnInit {
  @ViewChild('sidemenu') sidemenu: ElementRef;
  public buShiftSelectedID: any;
  public buOption: any;
  public isView: boolean;
  public routeSub: any;
  public businessUnitId: string;
  public onUserData: any;
  public activeId: any;
  public timeSheetData: any;
  historyPayLoad: {};
  dataAttendance: any;
  refreshNum: number = 0;
  buLoader: boolean = true;
  buParameters: {
    sortWith: string;
    sortBy: string;
    data: any[];
    page: number;
    limit: number;
    search: string;
  };
  buForShift: any[];
  planBuId: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    private setupService: SetupService,
    private toastr: ToastrService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.onSameUrlNavigation = 'reload';
    this.routeSub = this.route.data.subscribe((val) => {
      this.isView = val.isView;
    });

    this.buParameters = {
      sortWith: 'createdAt',
      sortBy: 'desc',
      data: [],
      page: 1,
      limit: 10,
      search: '',
    };
  }

  ngOnInit(): void {
    this.getAllBuForShift();
    this.getTimesheetHist();
  }

  getAllBuForShift() {
    this.buLoader = true;
    const buSubscribe = this.setupService
      .getAllBuShiftModule()
      .pipe(
        finalize(() => {
          this.buLoader = false;
          buSubscribe.unsubscribe();
        })
      )
      .subscribe(
        (res: any) => {
         // if (res.success) {
            this.buForShift = res.data.businessUnitList;
        /*  } else {
            this.buForShift = [];
          }*/
        },
        (error) => {
          this.buForShift = [];
        }
      );
  }

  ngAfterViewInit() {
    if (!this.isView) {
      setTimeout(() => {
        this.renderer.addClass(this.sidemenu.nativeElement, 'animate');
      }, 50);
    }
  }

  changeActiveTab($event) {
    this.activeId = $event;
  }

  refreshPage(id) {
    this.activeId = id;
    this.refreshNum = this.refreshNum + 1;
    this.toastr.success('Data Refreshed Successfully', 'Refreshed');
  }

  viewClicked(): void {
    if (this.planBuId) {
      this.buShiftSelectedID = this.planBuId._id;
      this.businessUnitId = this.planBuId;
      this.isView = true;
    } else {
      this.toastr.warning('Please select Business Unit', 'Warning');
    }
  }

  public getTimesheetHist(): void {
    this.buShiftSelectedID = this.buShiftSelectedID;
    var curr = new Date(); // get current date
    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6

    var firstday = new Date(curr.setDate(first)).toUTCString();
    var lastday = new Date(curr.setDate(last)).toUTCString();

    this.historyPayLoad = {
      endDate: moment(lastday).format('YYYY-MM-DD'),
      isDefault: false,
      startDate: moment(firstday).format('YYYY-MM-DD'),
    };
  }

  public qrColin(e) {
    this.dataAttendance = e;
  }

  public onButtonClick(e) {
    this.activeId = e;
  }
}
