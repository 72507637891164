import { Router } from '@angular/router';
import { BussinessUnitService } from '../../../service/bussiness-unit/bussiness-unit.service';
import {
  Component,
  OnInit,
  OnDestroy,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
  debounceTime,
  delay,
  distinctUntilChanged,
  finalize,
  map,
  mergeMap,

} from 'rxjs/operators';

import { UsersService } from '../../../service/user-service/users.service';

import { Subject, Subscription, of } from 'rxjs';
import {
  SortableHeader,
  SortEvent,
} from 'src/app/shared/directive/sortable.directive';
import { ToastrService } from 'ngx-toastr';
import { tableParam } from 'src/app/core/model/table-param.model';
import { TableService } from 'src/app/core/service/table.service';

@Component({
  selector: 'app-view-users',
  templateUrl: './view-users.component.html',
  styleUrls: ['./view-users.component.scss'],
})
export class ViewUsersComponent implements OnInit, OnDestroy {
  sectionDropDown = [];
  isUserViewed:boolean = false
  viewBussinessUnitForm: FormGroup;
  viewEnabled: boolean = false;
  isTableLoading: boolean = false;
  sectionDropDownLoading: boolean = false;
  public UserssearchInput = new Subject<KeyboardEvent>();
  bussinesUnitPTable;
  usersPTable;
  userId: string = null
  private UsersSearchSubscription: Subscription;
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  userTableColumn=['name','staffId','appointment.name','businessUnit','_id'];
  tableQueryParams=tableParam;
  viewProfileData:any=null;

  onSortViewUsers({ column, direction }: SortEvent) {

    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.usersPTable.sort = { key: column, order: direction as any };
    this.loadUsers();
  }

  onPageChangeUsers(page: number) {
    this.usersPTable.page = page;
    this.tableQueryParams.start= +page > 1 ? (page-1)* this.usersPTable.limit:0;
    this.loadUsers();
  }
  onLimitChangeUsers() {
    this.usersPTable.page = 1;
    this.tableQueryParams.start=0;
    this.loadUsers();
  }

  constructor(
    private formBuilder: FormBuilder,
    private buUnit: BussinessUnitService,
    private userService: UsersService,
    private toastService: ToastrService,
    private router:Router,
    private tableService:TableService
  ) {
    this.bussinesUnitPTable = {

      sortBy:  'createdAt',
      sortWith: 'desc' ,
      data: [],
      page: 1,

      limit: 10,

      search: '',
      filter: {
        type: 'view',

      },
    };
    this.usersPTable = {
      sortBy: 'createdBy',
      sortWith: 'desc',
      data: [],
      page: 1,
      limit: 10,
      search: '',
      filter: {
        status: ['active', 'inactive'],
        parentBussinessUnitId: [],
       },
    };
    this.UsersSearchSubscription = this.UserssearchInput.pipe(
      map((event) => {
        const target = event.target as HTMLTextAreaElement;
        return target.value;
      }),
      debounceTime(500),
      distinctUntilChanged(),
      mergeMap((search) => of(search).pipe(delay(500)))
    ).subscribe((input) => {
      this.usersPTable.page = 1;
      this.tableQueryParams.start=0;
      this.tableQueryParams.search.value=input;
      this.loadUsers();
    });

    this.initForm();

  }

  ngOnInit(): void {
    this.tableQueryParams.draw = 0;
     this.loadUsers();
  }


  viewUser(id:string){
    this.isUserViewed = true
    this.userId = id;
  }

  getUserViewDetails(){


  }
  overViewEvent(value){

    this.isUserViewed = false

    this.userId = null


}
  onSeachSection(value) {

    this.bussinesUnitPTable.limit = 10;
    this.bussinesUnitPTable.search = value.term;

    this.loadBussinessUnit();
  }

  loadMoreSectionItems(value) {
    this.bussinesUnitPTable.limit = this.bussinesUnitPTable.limit + 10;

    this.loadBussinessUnit();
  }

  onClearSectionDropdown(value) {


    if (value === undefined) {
      this.viewBussinessUnitForm.patchValue({
        bussinessUnit: null,
      });
      this.bussinesUnitPTable.search = '';
      this.loadBussinessUnit();

      this.sectionDropDown = [];
      this.bussinesUnitPTable.data = [];
    }

  }
  loadBussinessUnit() {
    this.sectionDropDownLoading = true;
    const {data, loading,total ,...rest}  = this.bussinesUnitPTable
    const subscribe = this.buUnit
      .getBussinesUnit(rest)
      .pipe(
        finalize(() => {
          this.sectionDropDownLoading = false;
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const { count, data } = response.data;
          this.bussinesUnitPTable.data = data;
          this.sectionDropDown = data;
          this.bussinesUnitPTable.total = count;
        },
        (error) => {
          this.bussinesUnitPTable.data = [];
          this.bussinesUnitPTable.total = 0;
          this.toastService.error('No list found');
        }
      );
  }
  bussinessunitView() {
    if (!this.viewBussinessUnitForm.valid) {
      return;
    }
    const { bussinessUnit } = this.viewBussinessUnitForm.value;

    this.usersPTable.filter.parentBussinessUnitId = [bussinessUnit];

    this.loadUsers();
  }

  loadUsers() {
    // const {data, loading,total ,...rest}  = this.usersPTable
    this.tableQueryParams['onlyViewBU']=true;
    let tableCol:any = this.tableService.initializeTableParamsColums(this.userTableColumn, [0,1],[0,1,2,3,4]) || [];
    this.tableQueryParams.columns=tableCol;
    this.tableQueryParams.draw++;
    this.tableQueryParams.length=this.usersPTable.limit;
    this.isTableLoading=true;
    const subscribe = this.userService
      .getAllViewUsers(this.tableQueryParams)
      .pipe(
        finalize(() => {
          // this.isTableLoading = false;
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          // const { count, data } = response.data;
          this.isTableLoading = false;
          this.usersPTable.data = response.data;
          this.usersPTable.total = response?.recordsFiltered;
          this.viewEnabled = true;
          this.viewBussinessUnitForm.reset();
        },
        (error) => {
          this.usersPTable.data = [];
          this.usersPTable.total = 0;
          this.toastService.error('No list found');
          this.isTableLoading = false;
        }
      );
  }

  goBack() {
    this.router.navigateByUrl('/users/home')
  }
  initForm() {
    this.viewBussinessUnitForm = this.formBuilder.group({
      bussinessUnit: ['', [Validators.required]],
    });
  }
  ngOnDestroy() {

  }
}
