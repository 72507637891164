<!--Start of adding design changes for create  by ME-->
<div>

    <div class="dashboardPanelCon">
        <div class="panel panel-default">
            <form [formGroup]="notificationCreateForm">
                <div>
                    <div class="panel-heading">Basic Information</div>
                    <div class="col-12 p-3">
                        <div>
                            <div class="d-block redTitle">Push Notification Type<span class="field-mandatory">*</span></div>
                            <div class="chkBoxSection">
                                <div class="form-check">
                                    <input [attr.disabled]="isView?'':null" formControlName="notificationType"
                                        class="form-control form-check-input inputCheck form-control-input" name="notificationType"
                                        [value]="1" type="radio" id="notificationTypeAdHoc" (change)="notificationTypeChange()">
                                    <label class="form-check-label form-control-label red font-weight-bold" for="check1" style="padding-top: 15px;">
                                        Ad-Hoc Push Notification (1-time)
                                    </label>
                                </div>

                                <div class="mt-3" *ngIf="notificationCreateForm.value.notificationType==1">
                                    <div class="row mt-3">
                                        <div class="col-md-3 col-lg-3 col-xl-3 col-12 marginFor" >
                                            Push Notification Date & Time
                                        </div>

                                        <div class="col-md-4 col-lg-4 col-xl-4 col-12">
                                            <div class="form-check-inline" style="display: flex;">
                                            <label style="padding-right: 10px; width: 90%; margin-bottom: 0px;">Push Notification Date<span class="field-mandatory">*</span></label>
                                            <input *ngIf="!isView" formControlName="activeFrom" style="font-size: 12px; height: 38px;"
                                            class="form-control"   [minDate]="minDate" placeholder="Select Date" ngbDatepicker #datepicker="ngbDatepicker" (click)="datepicker.open()">
                                            <input *ngIf="isView" formControlName="activeFrom" class="form-control" style="font-size: 12px; height: 38px;" placeholder="DD/MM/YYYY" [attr.disabled]="isView?'':null">
                                            </div>
                                        </div>

                                        <div class="col-md-4 col-lg-4 col-xl-4 col-12">
                                            <div class="form-check-inline" style="display: flex;">
                                                <label style="padding-right: 10px;  width: 90%; margin-bottom: 0px">Push Notification Time<span class="field-mandatory">*</span></label>
                                                <select formControlName="notificationTime" class="form-control" style="font-size: 12px; height: 38px;"
                                                    [attr.disabled]="isView?'':null">
                                                    <option [value]="-1">
                                                        Select Time
                                                    </option>
                                                    <option *ngFor="let time of timeArr" [value]="time">
                                                        {{time}}
                                                    </option>
                                                </select>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                                <div class="form-check">
                                    <input [attr.disabled]="isView?'':null" type="radio"
                                        formControlName="notificationType" (change)="notificationTypeChange()"
                                        class="form-control form-check-input inputCheck form-control-input" [value]="2"
                                        id="notificationTypeSchedule" name="notificationType">
                                    <label class="form-check-label form-control-label red font-weight-bold" for="check2" style="padding-top: 15px;">
                                        Scheduled Push Notification
                                    </label>
                                </div>
                                <div>
                                    <div class="mt-3" *ngIf="notificationCreateForm.value.notificationType==2">
                                        <div class="row mt-3">
                                            <div class="col-md-3 col-lg-3 col-xl-3 col-12 marginFor" >
                                                Push Notification Date<span class="field-mandatory">*</span>
                                            </div>

                                            <div class="col-md-4 col-lg-4 col-xl-4 col-12" >
                                                <div class="form-check-inline">
                                                <label class="activeLabel" style="margin-bottom: 0px;">Push Notification Period</label>
                                                <input *ngIf="!isView" (ngModelChange)="setMinDate2()"  [minDate]="minDate" formControlName="activeFrom" style="font-size: 12px; height: 38px;" class="form-control" placeholder="Select Date" ngbDatepicker #datepickerFrom="ngbDatepicker" (click)="datepickerFrom.open()">
                                                <input *ngIf="isView" [attr.disabled]="isView?'':null" formControlName="activeFrom" class="form-control" style="font-size: 12px; height: 38px;" placeholder="Select Date">
                                              </div>
                                            </div>
                                            <div class="col-md-4 col-lg-4 col-xl-4 col-12">
                                                <div class="form-check-inline">
                                                <label class="activeLabel"  style="margin-bottom: 0px;">Push Notification Till</label>
                                                <input *ngIf="!isView" formControlName="activeTo"  [minDate]="minDate2" class="form-control" style="font-size: 12px; height: 38px;" placeholder="Select Date" ngbDatepicker #datepickerTo="ngbDatepicker" (click)="datepickerTo.open()">
                                                <input *ngIf="isView" [attr.disabled]="isView?'':null" formControlName="activeTo" class="form-control" style="font-size: 12px; height: 38px;" placeholder="Select Date">
                                              </div>
                                            </div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col-md-3 col-lg-3 col-xl-3 col-12 marginFor" style="margin-top:20px;">
                                                Schedule Push<span class="field-mandatory">*</span>
                                            </div>

                                            <div class="col-md-8 col-lg-8 col-xl-8 col-12">
                                                <div>
                                                    <div class="form-check form-check-inline">
                                                        <input
                                                            [attr.disabled]="notificationCreateForm.value.notificationType==1 || isView ?'':null"
                                                            formControlName="notificationSchedule" (change)="scheduleChange()"
                                                            class="form-control form-check-input inputSize red-x" type="radio"
                                                            name="notificationSchedule" id="inlineRadio1" [value]="2">
                                                        <label class="form-check-label" for="inlineRadio1">Daily</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input
                                                            [attr.disabled]="notificationCreateForm.value.notificationType==1 || isView ?'':null"
                                                            formControlName="notificationSchedule"
                                                            class="form-control form-check-input inputSize red-x" type="radio"
                                                            name="notificationSchedule" id="inlineRadio2" [value]="3">
                                                        <label class="form-check-label"
                                                            for="inlineRadio2">Weekly</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input
                                                            [attr.disabled]="notificationCreateForm.value.notificationType==1 || isView ?'':null"
                                                            formControlName="notificationSchedule"
                                                            class="form-control form-check-input inputSize red" type="radio"
                                                            name="notificationSchedule" id="inlineRadio3" [value]="4">
                                                        <label class="form-check-label"
                                                            for="inlineRadio3">Monthly</label>
                                                    </div>
                                                </div>

                                                <div class="row" style="margin-top: 15px;
                                                margin-bottom: 15px;">
                                                    <div class="col-md-6 col-lg-6 col-xl-6 col-12">
                                                    <div class="form-check-inline" style="display: flex;">
                                                        <label style="padding-right: 10px; width: 90%; margin-bottom: 0px;">Push Notification Day<span class="field-mandatory">*</span></label>
                                                        <select formControlName="day" class="form-control" style="font-size: 12px; height: 38px;"
                                                            [attr.disabled]="notificationCreateForm.value.notificationType==1 || notificationCreateForm.value.notificationSchedule==2 || isView?'':null">
                                                            <option *ngFor="let day of weekDay" [value]="day.value">
                                                                {{day.viewValue}}
                                                            </option>
                                                        </select>
                                                        <!-- <label class="form-check-label" for="inlineRadio1">Day</label>
                              <input [attr.disabled]="notificationType==1 || notificationSchedule==2" type="text" placeholder="Day Name"> -->
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-6 col-xl-6 col-12">
                                                    <div class="form-check-inline" style="display: flex;">
                                                        <label style="padding-right: 10px;  width: 90%; margin-bottom: 0px;">Push Notification Time<span class="field-mandatory">*</span></label>
                                                        <select formControlName="notificationTime" class="form-control" style="font-size: 12px; height: 38px;"
                                                            [attr.disabled]="isView?'':null">
                                                            <option [value]="-1">
                                                                Select Time
                                                            </option>
                                                            <option *ngFor="let time of timeArr" [value]="time">
                                                                {{time}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-md-3 col-lg-3 col-xl-3 col-12 redTitle marginFor">
                                            Push Notification Title<span class="field-mandatory">*</span>
                                        </div>

                                        <div class="col-md-8 col-lg-8 col-xl-8 col-12">
                                            <input formControlName="title" class="form-control"
                                                [attr.disabled]="isView?'':null" type="text" class="w-100" rows="2"
                                                style="height: 40px;" />
                                        </div>

                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-md-3 col-lg-3 col-xl-3 col-12 redTitle marginFor">
                                            Push Notification Content<span class="field-mandatory">*</span>
                                        </div>

                                        <div class="col-md-8 col-lg-8 col-xl-8 col-12">
                                            <textarea formControlName="description" class="form-control"
                                                [attr.disabled]="isView?'':null" type="text" class="w-100"
                                                style="height: 80px; padding: 4px;"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div style="clear: both;"></div>

                    <!-- <div style="clear: both;"></div> -->
                </div>
            </form>
            <div style="clear: both;"></div>

        </div>
    </div>
</div>
   <div>
                <assign-users [userDetails]="userDetails" [isAdminView]="false"
                  (onSubmitAssignUsers)="updatedUserDetails($event)"></assign-users>
  </div>
<div style="margin: 10px; float: right;">
  <button [attr.disabled]="isView?'':null" class="btn btn-sm btn-primary" (click)="createUpdate(1)"
      style="margin-right: 10px; background-color: '#a42138'; font-size: 9px;border-color: #a42138;">{{buttonText}}</button>
  <button [attr.disabled]="isView || isPublish?'':null" class="btn btn-sm btn-warning" (click)="createUpdate(0)"
      style="margin-right: 10px;">Draft</button>
  <button [hidden]="!notficationId" class="btn btn-sm btn-danger" style="margin-right: 10px;"
      (click)="cancelUpdate()">Cancel</button>
      <button  [attr.disabled]="isView?'':null" [hidden]="(!notficationId && !isView) || notificationCreateForm.value.notificationType==1" class="btn btn-sm btn-danger" style="background-color: chocolate;"
      (click)="inActive()">Inactive</button>
</div>
<ng-template #content let-modal>
  <div class="modal-header" style="border:none">
    <button type="button" class="close" (click)="modal.dismiss(0)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p style="font-size: 16px; color:'#a42138'; text-align: center; font-weight: 'bold';">Do you want to inactive this notification?</p>
  </div>
  <div class="modal-footer" style="border:none">
    <button type="button" class="btn btn-sm btn-success" (click)="modal.close(1)">Yes</button>
    <button type="button" class="btn btn-sm btn-danger" (click)="modal.dismiss(0)">No</button>
  </div>
</ng-template>
<ng-template #sendNotification let-modal>
  <div class="modal-header" style="border:none">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss(0)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p style="font-size: 16px; color:'#a42138'; text-align: center; font-weight: 'bold';">Do you want to send notification immediately?</p>
  </div>
  <div class="modal-footer" style="border:none">
    <button type="button" class="btn btn-sm btn-success" (click)="modal.close(1)">Yes</button>
    <button type="button" class="btn btn-sm btn-danger" (click)="modal.close(0)">No</button>
  </div>
</ng-template>



