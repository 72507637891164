 <div class="loading-class" *ngIf="isGetProfileLoader">
    <img src="./assets/images/loading.gif" />
    <div class="text-center">Please wait, data is loading...</div>
  </div>
<div class="row">
  <div class="col-md-4">
    <div class="card">
      <div class="card-body">
        <div class="profile-details text-left">
          <div class="profile-userpic">
            <input type="file" (change)="profileUpload($event)" accept="image/*" />
            <img crossorigin="anonymous" [src]="
                userData?.profilePicture
                  ? validateURLImage(userData?.profilePicture)
                  : '../assets/images/user1.png'
              " class="img-responsive" alt="" />
            <div class="editProfile"><ng-container *ngIf="fileUploading;else editTab">Uploading...</ng-container>
              <ng-template #editTab>Edit</ng-template>
            </div>
          </div>

          <div class="profile-usertitle">
            <div class="profile-usertitle-name">
              {{ userData?.name }}
            </div>
            <div class="profile-usertitle-job">
              {{ userData?.role?.name }}
            </div>
          </div>

          <ul class="list-group text-dark">
            <li class="list-group-item d-flex justify-content-between align-items-center">
              <div class="text-muted"><i class="fa fa-user"></i> Staff ID</div>
              <span class="text-right">{{ userData?.staffId }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              <div class="text-muted"><i class="fa fa-phone"></i> Phone</div>
              <span class="text-right">{{
                userData?.primaryMobileNumber
                }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              <div class="text-muted"><i class="fa fa-envelope"></i> Email</div>
              <span class="text-right">{{ userData?.email }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              <div class="text-muted">
                <i class="fa fa-flag"></i> Parent Business Unit
              </div>
              <span class="text-right">{{
                userData?.parentBussinessUnitId?.orgName
                }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              <div class="text-muted">
                <i class="fa fa-users"></i> Ops Group Name
              </div>
              <span class="text-right">{{ userData?.opsGroupName }}</span>
            </li>
          </ul>
        </div>
        <hr />
      </div>
    </div>
  </div>
  <div class="col-md-8 pl-md-0">
    <div class="d-flex flex-column">
      <div>
        <ul ngbNav [(activeId)]="activeId" #companyNav="ngbNav" class="nav-tabs rounded mb-3">
          <li [ngbNavItem]="1">
            <a ngbNavLink>GENERAL INFO</a>
            <ng-template ngbNavContent>
              <div class="card">
                <div class="card-body">
                  <div class="col-md-6 col-sm-12">
                    <form [formGroup]="forgetPasswordForm">
                      <div class="form-group">
                        <label for="exampleInputEmail1">User Name</label>
                        <input type="text" class="form-control" [value]="userData?.name" aria-describedby="emailHelp"
                          placeholder="Enter User Name" readonly [ngClass]="{
                            'is-invalid': submitted1 && f.staffId.errors
                          }" />
                        <div *ngIf="submitted1 && f.staffId.errors" class="invalid-feedback d-block">
                          <small *ngIf="f.staffId.errors.required">
                            StaffId is required
                          </small>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="exampleInputEmail1">Staff Id</label>
                        <input type="text" class="form-control" [value]="userData?.staffId" aria-describedby="emailHelp"
                          placeholder="Enter Staff Id" readonly [ngClass]="{
                            'is-invalid': submitted1 && f.staffId.errors
                          }" />
                        <div *ngIf="submitted1 && f.staffId.errors" class="invalid-feedback d-block">
                          <small *ngIf="f.staffId.errors.required">
                            StaffId is required
                          </small>
                        </div>
                      </div>

                      <div class="form-group">
                        <label class="pb-2 form-bg" for="exampleInputEmail1">Password</label>
                        <div class="input-group">
                          <input class="form-control" value="000000" type="password" readonly />
                          <div class="input-group-append">
                            <button type="button" class="btn btn-outline-secondary btn-sm"
                              (click)="changePasswordState()">
                              <i class="fa fa-edit" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="pb-2 form-bg" for="exampleInputEmail1">{{
                          isEmail === 2
                          ? "Recovery Mobile Number"
                          : "Recovery Email"
                          }}</label>
                        <div class="input-group">
                          <input class="form-control" [value]="
                              isEmail === 1
                                ? userData?.email
                                : userData?.primaryMobileNumber
                            " type="{{ isEmail === 2 ? 'email' : 'tel' }}" readonly />
                          <div class="input-group-append" *ngIf="isEmail === 2">
                            <button type="button" class="btn btn-outline-secondary btn-sm"
                              (click)="changeRecoveryMobileNumber()">
                              <i class="fa fa-edit" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="exampleInputEmail1">Appoinment</label>
                        <input type="text" class="form-control" [value]="userData?.appointmentId?.name"
                          aria-describedby="emailHelp" placeholder="Enter Apoinment" readonly [ngClass]="{
                            'is-invalid': submitted1 && f.staffId.errors
                          }" />
                        <div *ngIf="submitted1 && f.staffId.errors" class="invalid-feedback d-block">
                          <small *ngIf="f.staffId.errors.required">
                            StaffId is required
                          </small>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink>CONTACT INFO</a>
            <ng-template ngbNavContent>
              <div class="card">
                <div class="card-body">
                  <div class="col-md-6">
                    <form [formGroup]="forgetPasswordForm">
                      <div class="form-group">
                        <label for="exampleInputContact1">Contact Number</label>
                        <input type="text" class="form-control" formControlName="contactNumber"
                          aria-describedby="emailHelp" placeholder="Enter Contact Number" [ngClass]="{
                            'is-invalid': submitted1 && f.staffId.errors
                          }" />
                        <div *ngIf="submitted1 && f.staffId.errors" class="invalid-feedback d-block">
                          <small *ngIf="f.staffId.errors.required">
                            StaffId is required
                          </small>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="exampleInputPassword1">Email</label>
                        <input type="email" class="form-control" placeholder="Enter Email" formControlName="email"
                          required [ngClass]="{
                            'is-invalid': submitted1 && f.email.errors
                          }" />
                        <div *ngIf="submitted1 && f.email.errors" class="invalid-feedback d-block">
                          <small *ngIf="f.email.errors.required">
                            Email is required
                          </small>
                        </div>
                      </div>

                      <div class="form-group">
                        <button type="submit" (click)="onSubmit()" class="btn btn-sm btn-submit float-right">
                          Update
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="3">
            <a ngbNavLink>OTHER INFO</a>
            <ng-template ngbNavContent>
              <div class="card">
                <div class="card-body">
                  <div class="col-md-6">
                    <form [formGroup]="forgetPasswordForm">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Roles</label>
                        <input type="text" class="form-control" [value]="userData.role?.name"
                          aria-describedby="emailHelp" placeholder="Enter Staff Id" readonly [ngClass]="{
                            'is-invalid': submitted1 && f.staffId.errors
                          }" />
                      </div>
                      <div class="form-group">
                        <label for="exampleInputPassword1">Bussiness Unit</label>
                        <input type="email" class="form-control" placeholder="Enter BussinnesUnit" readonly
                          [value]="userData.parentBussinessUnitId?.orgName" [ngClass]="{
                            'is-invalid': submitted1 && f.email.errors
                          }" />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="4">
            <a ngbNavLink>CUSTOM FIELDS</a>
            <ng-template ngbNavContent>
              <div class="card">
                <div class="card-body">
                  <form [formGroup]="form">
                    <dynamic-form-builder [gridView]="'col-xs-12 col-sm-12 col-md-4 col-lg-4 '" [formGroup]="form"
                      [fields]="getFields()"></dynamic-form-builder>
                  </form>
                </div>
                <div class="form-group">
                  <button type="submit" (click)="onSubmit()" class="btn btn-sm btn-submit float-right">
                    Update
                  </button>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="5">
            <a ngbNavLink>ASSIGNED BUSSINESS UNIT</a>
            <ng-template ngbNavContent>
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Business Unit (Plan)</label>
                        <ng-container *ngFor="let item of userData?.planBussinessUnitId">
                          <input type="text" class="form-control mb-2" [value]="item.orgName"
                            aria-describedby="emailHelp" placeholder="Enter Staff Id" readonly />
                        </ng-container>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Business Unit (View)
                        </label>
                        <ng-container *ngFor="let item of userData?.viewBussinessUnitId">
                          <input type="text" class="form-control mb-2" [value]="item.orgName"
                            aria-describedby="emailHelp" placeholder="Enter Staff Id" readonly />
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="companyNav"></div>
      </div>
    </div>
  </div>
</div>

<ng-template #changePasswordProfile let-c="close" let-d="dismiss">
  <div>
    <div class="modal-content">
      <div class="modal-body" style="padding: 10px; min-height: 0vh">
        <div>
          <div class="card my-3 w-100">
            <div class="card-header flexi-header">
              <h3>CHANGE PASSWORD</h3>
              <button type="button" class="btn close-icon" aria-label="Close" (click)="closeChangePasswordPopUp()">
                <i class="fa fa-times-circle fa-2x" aria-hidden="true"></i>
              </button>
            </div>
            <div class="card-body">
              <form [formGroup]="passwordRestForm">
                <div class="form-group">
                  <label class="pb-2 form-bg" for="exampleInputEmail1">Password
                  </label>
                  <input [type]="passwordvisible ? 'text' : 'password'" class="form-control" placeholder="Password"
                    [ngClass]="{
                      'is-invalid':
                        submitted3 && passwordRestForm.get('password')?.errors
                    }" formControlName="password" />
                  <div class="eye-slash">
                    <i [class]="
                        passwordvisible
                          ? 'fa fa-eye fa-lg'
                          : 'fa fa-eye-slash fa-lg'
                      " aria-hidden="true" (click)="passwordVisible()" *ngIf="passwordvisible ? 'passwordvisible' : '!passwordvisible'
                      "></i>
                  </div>
                  <div *ngIf="submitted3 && passwordRestForm.get('password')?.errors" class="invalid-feedback d-block">
                    <div class="mt-2" *ngIf="passwordIsInactive === 'active'">
                      <small *ngIf="passwordRestForm.get('password')?.errors?.required">
                        Password is required
                      </small>
                    </div>
                    <div *ngIf="passwordIsInactive === 'active'">
                      <small *ngIf="
                          passwordRestForm.get('password')?.errors?.hasNumber
                        ">
                        Atleast one numeric letter is required
                      </small>
                    </div>
                    <div *ngIf="passwordIsInactive === 'active'">
                      <small *ngIf="
                          passwordRestForm.get('password')?.errors
                            ?.hasSpecialCharacters
                        ">
                        Atleast one special character is required
                      </small>
                    </div>
                    <div *ngIf="passwordIsInactive === 'active'">
                      <small *ngIf="
                          passwordRestForm.get('password').errors?.hasCapitalCase
                        ">
                        Atleast one Capital character is required
                      </small>
                    </div>
                    <div *ngIf="passwordIsInactive === 'active'">
                      <small *ngIf="
                          passwordRestForm.get('password').errors?.hasSmallCase
                        ">
                        Atleast one Small character is required
                      </small>
                    </div>
                    <div *ngIf="passwordIsInactive === 'active'">
                      <small *ngIf="
                          passwordRestForm.get('password').errors?.minlength
                        ">
                        Min Length Should 8 Charecter
                      </small>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="form-bg" for="exampleInputEmail1">Confirm Password
                  </label>

                  <input [type]="confirmPasswordvisible ? 'text' : 'password'" class="form-control"
                    placeholder=" Confirm Password" [ngClass]="{
                      'is-invalid':
                        submitted3 &&
                        passwordRestForm.get('confirmPassword')?.errors
                    }" formControlName="confirmPassword" />
                  <div class="eye-slash">
                    <i [class]="
                        confirmPasswordvisible
                          ? 'fa fa-eye fa-lg'
                          : 'fa fa-eye-slash fa-lg'
                      " aria-hidden="true" (click)="confirmPasswordVisible()" *ngIf="
                        confirmPasswordvisible
                          ? 'confirmPasswordvisible'
                          : '!confirmPasswordvisible'
                      "></i>
                  </div>
                  <div *ngIf="submitted3 && passwordRestForm.get('confirmPassword')?.errors"
                    class="invalid-feedback d-block">
                    <div *ngIf="passwordIsInactive === 'active'">
                      <small *ngIf="
                          passwordRestForm.get('confirmPassword')?.errors
                            ?.required
                        ">
                        Confirm Password is required
                      </small>
                    </div>
                    <div *ngIf="passwordIsInactive === 'active'">
                      <small *ngIf="
                          passwordRestForm.get('confirmPassword')?.errors
                            ?.mustMatch
                        ">
                        Password doesn't Match
                      </small>
                    </div>
                  </div>
                </div>
              </form>
              <div class="form-group">
                <button type="submit" (click)="onRestPassword()" class="btn btn-sm btn-submit float-right m-1 p-1">
                  Reset Password
                </button>
                <button type="submit" (click)="closeChangePasswordPopUp()"
                  class="btn btn-sm btn-clear float-right m-1 p-1">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #changeMobileNumber let-c="close" let-d="dismiss">
  <div class="modal-content">
    <div class="modal-body" style="padding: 10px; min-height: 50vh">
      <div>
        <div class="card my-3 w-100">
          <div class="card-header flexi-header">
            <h3>
              {{ isEmail === 2 ? "CHANGE MOBILE NUMBER" : "CHANGE EMAIL" }}
            </h3>
            <button type="button" class="btn close-icon" aria-label="Close" (click)="closeRecoveryPopUp()">
              <i class="fa fa-times-circle fa-2x" aria-hidden="true"></i>
            </button>
          </div>
          <div class="card-body">
            <form [formGroup]="recoveryForm">
              <div class="form-group">
                <label for="exampleInputPassword1">{{
                  isEmail === 2 ? "Mobile Number" : "Email"
                  }}</label>
                <div *ngIf="isEmail === 2">

                  <div class="d-flex">
                    <div>
                      <ngx-intl-tel-input [preferredCountries]="preferredCountries" [cssClass]="{
                        'form-control': 1,
                        'is-invalid': submitted1 && recoveryForm.get('recoveryPhoneNumber').errors
                      }" [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                        [searchCountryField]="[
                        SearchCountryField?.Iso2,
                        SearchCountryField?.Name
                      ]" [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.India" [maxLength]="15"
                        [tooltipField]="TooltipLabel?.Name" [phoneValidation]="false" [separateDialCode]="false"
                        [numberFormat]="PhoneNumberFormat.National" formControlName="recoveryPhoneNumber">
                      </ngx-intl-tel-input>
                      <div *ngIf="isSubmittedRecovery && recoveryForm.get('recoveryPhoneNumber').errors"
                        class="invalid-feedback d-block">
                        <small *ngIf="recoveryForm.get('recoveryPhoneNumber').errors?.required">
                          Mobile Number is required
                        </small>
                      </div>
                    </div>

                    <div class="text-center">
                      <button class="btn btn-sm btn-primary ml-2" (click)="requestForOTP()">Send OTP</button>
                    </div>
                  </div>
                  <div *ngIf="recoveryForm.get('token').value">
                    <input type="text" class="form-control col-sm-2" formControlName="mobileOTP"
                      placeholder="Enter OTP">
                    <div *ngIf="isVerifyOTP && !recoveryForm.get('mobileOTP').value" class="invalid-feedback d-block">
                      <small>
                        OTP is required
                      </small>
                    </div>
                  </div>
                </div>


                <div *ngIf="isEmail === 1">
                  <input class="form-control" type="email" placeholder="Enter your Email"
                    formControlName="recoveryEmail" />

                  <div *ngIf="isSubmittedRecovery && recoveryForm.get('recoveryEmail').errors"
                    class="invalid-feedback d-block">
                    <small *ngIf="recoveryForm.get('recoveryEmail').errors.required">
                      Email is required
                    </small>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <button type="submit" [disabled]="this.forgetPasswordForm.value.email === ''"
                  (click)="verifyOTP()" class="btn btn-sm btn-submit float-right m-1 p-1">
                  Update
                </button>
                <button type="submit" (click)="closeRecoveryPopUp()"
                  class="btn btn-sm btn-clear float-right m-1 p-1">
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
