<div class="card">
  <div class="card-header align-items-center bg-theme">
    <h5 class="m-0">VIEW BOOKING</h5>
    <p class="m-0">Selected Business Unit : {{ buId.orgName }}</p>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <div class="form-group">
              <label for="" class="font-weight-500">Select Day</label><br />
              <button
                *ngFor="let i of dayArr"
                class="dayButton"
                [class.selected]="i === selectedDay"
                (click)="getSelectedWeek1(i)"
              >
                {{ i.day }}
              </button>
            </div>
            <div
              *ngIf="dayShifts?.length > 0; then thenBlock; else elseBlock"
            ></div>
            <ng-template #thenBlock>
              <div
                class="shiftTab my-2 rounded"
                *ngFor="let i of dayShifts"
                [class.selectedShift]="i === selectedShift"
                (click)="getListShift(i)"
              >
                <h6 class="m-0">
                  {{ i.confirmedStaffs.name }}
                </h6>
                <div class="d-flex justify-content-between">
                  <div class="text-secondary" *ngFor="let j of i.subSkillSets">
                    {{ j.skillSetId.name | titlecase }} >
                    {{ j.name | titlecase }}
                  </div>
                  <div class="text-secondary">
                    <p class="m-0">
                      {{ i.startTime | date: "HH:mm" }} to
                      {{ i.endTime | date: "HH:mm" }}
                    </p>
                    <p class="m-0" *ngIf="i.isSplitShift">
                      {{ i.splitShiftStartTime | date: "HH:mm" }} to
                      {{ i.splitShiftEndTime | date: "HH:mm" }}
                    </p>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template #elseBlock>
              <h5 class="text-muted">No items found</h5>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card" *ngIf="this.dayShifts?.length > 0">
          <div class="card-body">
            <form [formGroup]="PersonalData">
              <div class="d-flex mb-3">
                <img
                  class="user-image rounded"
                  [src]="
                    this.selectedShift.confirmedStaffs?.profilePicture != ''
                      ? this.selectedShift.confirmedStaffs.profilePicture
                      : '../assets/images/user1.png'
                  "
                  onerror="this.src='./assets/images/user1.png';"
                />

                <div class="align-self-center ml-4">
                  <h4 class="font-weight-bold m-0">{{ username }}</h4>
                  <p class="font-weight-500 m-0 text-muted">{{ usernameId }}</p>
                </div>
              </div>
              <div class="form-input no-padding mb-2">
                Skillsets & Speciality
              </div>
              <div class="bg-theme d-inline-block mb-2 mr-2 p-2 rounded" *ngFor="let i of skillLabel">
                {{ i[0] }} > {{ i[1] }}
              </div>
              <div class="row mb-3 mt-2 col-md-12">
                <div class="form-input mb-2">Phone</div>
                <input class="form-control" formControlName="phone" readonly/>
              </div>
              <div class="row mb-3 col-md-12">
                <div class="form-input mb-2">Email</div>
                <input class="form-control" formControlName="email" readonly/>
              </div>
            </form>

            <div class="card">
              <div class="card-header bg-theme">
                <h5 class="m-0">Shift Details</h5>
              </div>
              <div class="card-body">
                <form [formGroup]="ShiftForm">
                  <div class="row mb-3 col-md-12">
                    <div class="form-input mb-2">Business Unit</div>
                    <input class="form-control" formControlName="buName" readonly/>
                  </div>
                  <div class="row mb-3 col-md-12">
                    <div class="form-input mb-2">Date</div>
                    <input class="form-control" formControlName="date" readonly/>
                  </div>
                  <div class="row mb-3 col-md-12">
                    <div class="form-input mb-2">Time</div>
                    <div class="col-md-12 no-padding flexDisplay mb-1">
                      <input
                        class="form-control mr-2"
                        formControlName="startTime"
                        readonly
                      />
                      <input
                        class="form-control ml-2"
                        formControlName="endTime"
                        readonly
                      />
                    </div>
                    <div class="col-md-12 no-padding flexDisplay" *ngIf="isSplitTime">
                      <input
                        class="form-control mr-2"
                        formControlName="splitstartTime"
                        readonly
                      />
                      <input
                        class="form-control ml-2"
                        formControlName="splitendTime"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="row mb-3 col-md-12">
                    <div class="form-input col-md-12 no-padding mb-2">
                      Skillsets & Speciality
                    </div>
                    <div class="redButton mr-2" *ngFor="let i of skillLabel">
                      {{ i[0] }} > {{ i[1] }}
                    </div>
                  </div>
                  <div class="row mb-3 col-md-12">
                    <div class="form-input mb-2">Reports To</div>
                    <input
                      class="form-control"
                      formControlName="reportLocationId"
                      readonly
                    />
                  </div>
                  <!-- <div class="row mb-3 col-md-12">
                <div class="form-input mb-2">Planned Staff Count</div>
                <div class="col-md-12 no-padding flexDisplay">
                  <div>
                    <input
                      class="form-control pr-2"
                      formControlName="staffNeedCount"
                    />
                    <div class="pr-2">Confirmed</div>
                  </div>
                  <div>
                    <input
                      class="form-control pl-2"
                      formControlName="backUpStaffNeedCount"
                    />
                    <div class="pl-2">Standby</div>
                  </div>
                </div>
              </div>
              <div class="row mb-3 col-md-12">
                <div class="redButton col-md-12 no-padding mb-2">
                  <b>Staff Booked</b>
                </div>
                <div class="col-md-12 no-padding flexDisplay">
                  <div>
                    <input class="form-control pr-2" />
                    <div class="pr-2">Confirmed</div>
                  </div>
                  <div>
                    <input class="form-control pl-2" />
                    <div class="pl-2">Standby</div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 row mb-3" *ngIf="hideBtn">
                <button
                  type="button"
                  class="redButton col-md-12 collapsed"
                  data-toggle="collapse"
                  data-target="#adjustShift"
                >
                  Adjust Shift
                </button>
    
                <div class="card col-md-12" id="adjustShift">
                  <div class="card-body">
                    <div class="col-md-12 mb-3 no-padding flexDisplay">
                      <div>
                        <div class="pr-2">Planned staff</div>
                        <input class="form-control pr-2" />
                      </div>
                      <div>
                        <div class="pl-2">Adjust to</div>
                        <input class="form-control pl-2" />
                      </div>
                    </div>
                    <div class="col-md-12 row mb-3 pr-0">
                      <button class="col-md-12 greenButton">
                        Submit changed shift
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 row mb-3" *ngIf="hideBtn">
                <button
                  type="button"
                  class="redButton col-md-12"
                  (click)="cancelShift(true)"
                >
                  Cancel Shift
                </button>
              </div>
              <div
                [ngClass]="cancel ? 'show-modal' : ''"
                class="modal"
                id="mmmModal"
              >
                <div class="modal-dialog" style="width: 50%; height: 200px">
                  <div class="modal-content modalContentBoxGrey">
                    <div class="modal-header"><b>Cancel Confirmation</b></div>
                    <div class="modal-body">
                      <div>
                        <h4 style="font-size: 17px">
                          Are you sure, you want to cancel this shift?
                        </h4>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        class="btn footer-button btn-danger"
                        style="font-size: 11px"
                      >
                        Yes
                      </button>
                      <button
                        class="btn footer-button btn-primary"
                        type="button"
                        (click)="cancelShift(false)"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div> -->
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
