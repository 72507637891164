import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ShiftSchedulerService {
  private baseUrl: string;
  private get Headers(): HttpHeaders {
    return new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }

  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }

  getWeeks(buId) {
    return this.http.get(`${this.baseUrl}/businessunit/weekNo/` + buId, {
      headers: this.Headers,
    });
  }

  getUserbyPlanBU(data) {
    /*const payload = {
      "parentBussinessUnitId[]": id
    }*/
    return this.http.post(
      `${this.baseUrl}/companyUser/readUserByPlanBU/assignShift`,data,{
        headers: this.Headers,
      });
  }

  getStaffIdShift(id) {
    return this.http.get(`${this.baseUrl}/assginshift/stafflist/` + id, {
      headers: this.Headers,
    });
  }

  getBuShiftName({ page, limit, search, sortWith, sortBy }) {
    return this.http.get(`${this.baseUrl}/businessunit/planBU/addedShiftSetup`, {
      headers: this.Headers,
      params: { page, limit, search, sortWith, sortBy }
    });
  }

  getPageSettingSkill() {
    return this.http.get(`${this.baseUrl}/pageSetting/skillset`, {
      headers: this.Headers,
    });
  }

  getStaffSkill(staffId) {
    return this.http.get(`${this.baseUrl}/assginshift/stafflist` + staffId, {
      headers: this.Headers,
    });
  }

  readAssignShift(data) {
    return this.http.post(`${this.baseUrl}/assginshift/read`, data, {
      headers: this.Headers,
    });
  }

  shiftLogRead(data) {
    return this.http.post(`${this.baseUrl}/shiftLog/read`, data, {
      headers: this.Headers,
    });
  }

  createShift(data) {
    return this.http.post(`${this.baseUrl}/shift/create`, data, {
      headers: this.Headers,
    });
  }

  createEmptyShift(data) {
    return this.http.post(`${this.baseUrl}/assginshift/stafflisting`, data, {
      headers: this.Headers,
    });
  }

  deleteSingleShift(data) {
    return this.http.post(`${this.baseUrl}/assginshift/staff/delete`, data, {
      headers: this.Headers,
    });
  }

  alertAction(data) {
    return this.http.post(`${this.baseUrl}/assginshift/alertaction`, data, {
      headers: this.Headers,
    });
  }

  updateShift(data) {
    return this.http.post(`${this.baseUrl}/assginshift/staff/update`, data, {
      headers: this.Headers,
    });
  }

  updateShiftRestOff(data) {
    return this.http.post(
      `${this.baseUrl}/assginshift/staff/update/restoff`,
      data,
      {
        headers: this.Headers,
      }
    );
  }

  assignShiftPublish(data) {
    return this.http.post(`${this.baseUrl}/assginshift/publishAll`, data, {
      headers: this.Headers,
    });
  }

  uploadRoster(d: any, f) {
    const formData: FormData = new FormData();
    formData.append('shift', JSON.stringify(d));
    formData.append('ff', f);
    return this.http.post(`${this.baseUrl}/assginshift/create`, formData);
  }

  shiftExtension(data) {
    return this.http.post(`${this.baseUrl}/shift/checklimit`, data, {
      headers: this.Headers,
    });
  }

  stopShiftExtension(data) {
    return this.http.post(`${this.baseUrl}/shift/shiftExtension/stop`, data, {
      headers: this.Headers,
    });
  }

  againShiftExtension(data) {
    return this.http.post(`${this.baseUrl}/shift/shiftExtension/again`, data, {
      headers: this.Headers,
    });
  }

  confirmedShift(data) {
    return this.http.post(`${this.baseUrl}/shift/shiftExtension`, data, {
      headers: this.Headers,
    });
  }

  saveAdjustShift(data) {
    return this.http.post(`${this.baseUrl}/shift/adjust`, data, {
      headers: this.Headers,
    });
  }

  getViewBooking(data) {
    return this.http.post(`${this.baseUrl}/shift/viewbookings`, data, {
      headers: this.Headers,
    });
  }

  savePlanShift(data) {
    return this.http.post(`${this.baseUrl}/template/create`, data, {
      headers: this.Headers,
    });
  }

  getBusinessUnitSetting(businessUnitId) {
    return this.http.get(`${this.baseUrl}/bu-shift/setting/${businessUnitId}`, {
      headers: this.Headers,
    });
  }

  getGeoReportingLocationSetting(businessUnitId) {
    return this.http.get(`${this.baseUrl}/bu-shift/setting/${businessUnitId}`, {
      headers: this.Headers,
    });
  }

  getTemplate(data) {
    return this.http.post(`${this.baseUrl}/template/read`, data, {
      headers: this.Headers,
    });
  }

  deleteTemplate(data) {
    return this.http.post(`${this.baseUrl}/template/remove`, data, {
      headers: this.Headers,
    });
  }

  deleteShiftTemplate(data) {
    return this.http.post(`${this.baseUrl}/template/deleteShiftInTemplate`, data, {
      headers: this.Headers,
    });
  }
  getPlanShift(data) {
    return this.http.post(`${this.baseUrl}/shift/read`, data, {
      headers: this.Headers,
    });
  }

  getShiftGraph(data) {
    return this.http.post(`${this.baseUrl}/shift/graphData`, data, {
      headers: this.Headers,
    });
  }

  createRecall(data) {
    return this.http.post(`${this.baseUrl}/shift/create/restoff`, data, {
      headers: this.Headers,
    });
  }

  getShiftLog(data) {
    return this.http.post(`${this.baseUrl}/shiftLog/read`, data, {
      headers: this.Headers,
    });
  }
  requestChange(data) {
    return this.http.post(`${this.baseUrl}/shift/requestChange`, data, {
      headers: this.Headers,
    });
  }
  stopRequestChange(data) {
    return this.http.post(`${this.baseUrl}/shift/stopRequesting`, data, {
      headers: this.Headers,
    });
  }
  cancelShiftNew(data) {
    const result = this.http.post(`${this.baseUrl}/shift/cancel`, data, {
      headers: this.Headers,
    });
    return result;
  }
  cancelBookedShift(data) {
    const result = this.http.post(`${this.baseUrl}/shift/cancelIndividualShift`, data, {
      headers: this.Headers,
    });
    return result;
  }

  //TimeSheet Services
  public readTimesheet(id, payload) {
    return this.http
      .post(`${this.baseUrl}/timesheet/read/${id}`, payload, {
        headers: this.Headers,
      })
      .pipe(
        map((val: { data }) => {
          const filterdata = [];

          val.data?.forEach((res) => {
            if (Object.keys(res).includes('attendance')) {
              if (
                Object.keys(res.attendance).includes('clockOutDateTime') ==
                false
              ) {
                filterdata.push(res);
              }
            } else {
              filterdata.push(res);
            }
          });
          return filterdata;
        })
      );
  }

  public getTimesheetData(id) {
    return this.http.get(`${this.baseUrl}/timesheet/${id}`, {
      headers: this.Headers,
    });
  }

  public generateQr(userId, shiftDetailId) {
    return this.http.get(
      `${this.baseUrl}/timesheet/qrcode/${userId}/${shiftDetailId}`,
      {
        headers: this.Headers,
      }
    );
  }

  public getTimesheetHistory(id, data) {
    return this.http.post(`${this.baseUrl}/timesheet/history/${id}`, data, {
      headers: this.Headers,
    });
  }

  public lockShift(data) {
    return this.http.post(`${this.baseUrl}/timesheet/lockall`, data, {
      headers: this.Headers,
    });
  }

  public getAttendance(data) {
    return this.http.post(`${this.baseUrl}/attendance/add`, data, {
      headers: this.Headers,
    });
  }

  public approvalTimeSheet(data) {
    return this.http.post(`${this.baseUrl}/timesheet/approval`, data, {
      headers: this.Headers,
    });
  }

  public breakTimeAdd(payload) {
    return this.http.post(`${this.baseUrl}/attendance/breakTime`, payload, {
      headers: this.Headers,
    });
  }

  public lock(payload) {
    return this.http.post(`${this.baseUrl}/timesheet/lock`, payload, {
      headers: this.Headers,
    });
  }

  public exportTimesheetData(id) {
    return this.http.get(`${this.baseUrl}/timesheet/export/${id}`, {
      headers: this.Headers,
    });
  }

  public exportHistoryTSData(id, data) {
    return this.http.post(
      `${this.baseUrl}/timesheet/history/export/${id}`,
      data,
      {
        headers: this.Headers,
      }
    );
  }

  attendance(data) {
    return this.http.post(`${this.baseUrl}/attendance/add`, data, {
      headers: this.Headers,
    });
  }

  attendanceLog(data) {
    return this.http.post(`${this.baseUrl}/attendance/logs`, data, {
      headers: this.Headers,
    });
  }

  //Log Assign Services
  getLogAssign(payload) {
    return this.http.post(`${this.baseUrl}/assginshift/log`, payload, {
      headers: this.Headers,
    });
  }
}
