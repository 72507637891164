<div
  class="card my-3"
  style="width: 33.5em; margin: 0 auto"
  *ngIf="!isNotificationEndabled"
>
  <div class="card-header flexi-header text-center p-4">
    <h3>NOTIFICATION</h3>
  </div>
  <div class="card-body">
    <form action="" [formGroup]="viewNotification">
      <div class="row mb-2">
        <div class="col-md-12">
          <label> Bussiness unit (Plan) </label>
          <ng-select
            class="custom"
            bindLabel="businessUnit"
            placeholder="Bussiness unit (Plan)"
            appendTo="body"
            [multiple]="false"
            [searchable]="true"
            [closeOnSelect]="true"
            [clearable]="true"
            formControlName="bussinessUnit"
            [loading]="planBuUnitDropDownLoading"
            (search)="onSeachPlanBuUnit($event)"
            (scrollToEnd)="loadMorePlasnBuUnitItems($event)"
            (clear)="onClearPlanBuUnitDropdown($event)"
          >
            <ng-option [value]="item" *ngFor="let item of planBuUnitDropDown">
              {{ item.orgName }}
            </ng-option>
          </ng-select>
        </div>
      </div>
    </form>
  </div>
  <div class="card-footer">
    <button
      class="btn btn-submit btn-block"
      [disabled]="isLoading"
      (click)="onClickView()"
    >
      <i
        class="fa"
        [ngClass]="{ 'fa-spin fa-spinner': isLoading, '': !isLoading }"
      ></i>
      Next
    </button>
  </div>
</div>
<div class="d-flex flex-column" *ngIf="isNotificationEndabled">
  <ul ngbNav [(activeId)]="activeId" #companyNav="ngbNav" class="nav-tabs rounded mb-3">
    <li [ngbNavItem]="1">
      <a ngbNavLink (click)="onOverView()" [disabled]="isNotificationViewEnabled">{{isNotificationViewEnabled?"VIEW" : "OVERVIEW"}}</a>
      <ng-template ngbNavContent>
        <app-notification-overview
          (overViewEvent)="overViewEvent($event)"
          (overViewNotification)="viewNotificationClick($event)"
          (editEvent)="notificationEdit($event)"
          [notificatioNid]="notificatioNid"
        >
        </app-notification-overview>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink (click)="onCreateTab()">{{ isNotificationEdited ? "EDIT" : "CREATE" }}</a>
      <ng-template ngbNavContent>
        <app-create-edit-notification
          (overViewEvent)="overViewEvent($event)"
          (editEvent)="notificationEdit($event)"
          [notificationEditedId]="notificatioEditid"
          [notificatioNid]="notificatioNid"
        >
        </app-create-edit-notification>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="companyNav"></div>
</div>
