import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subject, Subscription, of } from 'rxjs';
import { map, debounceTime, distinctUntilChanged, mergeMap, delay, finalize } from 'rxjs/operators';
import { SortableHeader, SortEvent } from 'src/app/shared/directive/sortable.directive';
import { StorageService } from 'src/app/core/service/storage.service';

import { NewsEventsService } from 'src/app/users/service/manage-news & events/news-events.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'events-reported-post',
  templateUrl: './events-reported-post.component.html',
  styleUrls: ['./events-reported-post.component.scss']
})
export class EventsReportedPostComponent implements OnInit {

  usersPTable
  // isBuUnitEdited:boolean = false
  // sectionId:string
  // subSectionId:string
  // bussinessUnitEditForm:FormGroup
  public userssearchInput = new Subject<KeyboardEvent>();
  isTableLoading: boolean = true;
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  // companyName: string;
  /// bussiness unit over view table config
  onSortUsers({ column, direction }: SortEvent, field) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.usersPTable.sortWith = !!direction ? field : 'createdAt';
    this.usersPTable.sortBy = !!direction ? direction : 'desc';
    this.loadUsers();
  }

  onPageChangeUsers(page: number) {
    this.usersPTable.page = page;
    this.loadUsers();
  }
  onLimitChangeUsers() {
    this.loadUsers();
  }

  private usersSearchSubscription: Subscription;

  constructor(
    private boardService: NewsEventsService,
    public toastService: ToastrService,
    private storageService: StorageService,
    private formBuilder: FormBuilder
  ) {
    // this.config.notFoundText = 'Custom not found';
    this.usersPTable = {
      sortBy: 'desc',
      sortWith: 'createdAt',

      data: [],
      page: 1,
      limit: 10,
      search: '',

    };
    // this.companyName = this.storageService.getByKey('companyId');

    this.usersSearchSubscription = this.userssearchInput
      .pipe(
        map((event) => {
          const target = event.target as HTMLTextAreaElement;
          return target.value;
        }),
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap((search) => of(search).pipe(delay(500)))
      )
      .subscribe((input) => {
        this.usersPTable.page = 1;
        this.loadUsers();
      });
  }

  ngOnInit(): void {
    this.loadUsers();
  }


  changePostStatus(id:string, status){
    const data = {
      status:status == 1 ? 2 : 1,
      postId:id
    }
      const subscribe = this.boardService
      .updateReportedPost(id,data)
      .pipe(
        finalize(() => {

          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          this.toastService.success(response?.data);
         this.loadUsers();
        },
        (error) => {

        }
      );
    }


  loadUsers() {
const {data, loading,total ,...rest}  = this.usersPTable
    const subscribe = this.boardService
      .getAllReportedPosts(rest, 'event')
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          this.usersPTable.data = response.data;
          this.isTableLoading = false;
          this.usersPTable.total = response.recordsTotal;
        },
        (error) => {

        }
      );
  }

  ngOnDestroy(): void {
    this.usersSearchSubscription && this.usersSearchSubscription.unsubscribe();
  }


}
