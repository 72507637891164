import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { queryParam } from "src/app/core/utils/helper";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ReportService {
  private baseUrl: string;

  private get Headers(): HttpHeaders {
    return new HttpHeaders({
      Accept: "application/json",
      "Content-Type": "application/json",
    });
  }

  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }

  listOfShiftCsv(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/reports/listofshifts`, data, {
      headers: this.Headers,
    });
  }
  listOfUserCsv(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/reports/users`, data, {
      headers: this.Headers,
    });
  }

  reportBooking(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/reports/bookings`, data, {
      headers: this.Headers,
    });
  }

  listOfCancellation(data) {
    return this.http.post(`${this.baseUrl}/reports/listofcancellations`, data, {
      headers: this.Headers,
    });
  }

  downloadFile(x: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/uploads/reportsDownloads/` + x, {
      responseType: "blob",
    });
  }
}
