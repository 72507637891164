import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-challenges',
  templateUrl: './challenges.component.html',
  styleUrls: ['./challenges.component.scss']
})
export class ChallengesComponent implements OnInit {
  activeId: number = 1
  isUserEdited: boolean;
  isUserView: boolean;
  userId: any = null
  manageId: string;
  isFormManaged: boolean;
  viewType:string ='';
  userViewData:any=null;
  userEditData:any=null;
  constructor() { }

  ngOnInit() {
  }
  userEdit(data: any) {
    this.activeId = 2
    this.isUserEdited = true
    this.viewType = 'edit'
    this.userId = data._id;
    this.userEditData=data;
  }
  userView(data: any) {
    this.activeId = 2
    this.viewType ='view'
    this.isUserView = true
    this.userId = data._id;
    this.userViewData=data;
  }

  manageForm(data){
    this.viewType = 'manage'
    this.isFormManaged = true;
    this.manageId = data
    this.activeId = 2
  }
  overViewLogEvent(value){
    this.manageId = null

    setTimeout(()=>{                           // <<<---using ()=> syntax
      this.isFormManaged = false;
  }, 200);
    this.isUserEdited = false
    this.isUserView = false
    this.viewType = ''
    this.userId = null
  }
  overViewEvent(value) {

    this.activeId = 1
    this.isFormManaged = false;
    this.manageId = null
    this.isUserEdited = false
    this.isUserView = false
    this.userId = null
    this.viewType = ''
  }
}
