import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { RoleService } from 'src/app/users/service/user-roles/role.service';

@Component({
  selector: 'app-leave-planner',
  templateUrl: './leave-planner.component.html',
  styleUrls: ['./leave-planner.component.scss'],
})
export class LeavePlannerComponent implements OnInit {
  active = 1;
  approveAccess: boolean;
  opsLeaveQuotaAccess: boolean;
  constructor(private roleService: RoleService) {}

  ngOnInit(): void {
    const rolePrivileges = this.roleService
      .getNewUserPrivilege()
      .pipe(
        finalize(() => {
          rolePrivileges.unsubscribe();
        })
      )
      .subscribe((res) => {
        this.approveAccess = res.privileges.hasOwnProperty('leavePlannerApprover')
          ? res.privileges.leavePlannerApprover
          : false;
          
        this.opsLeaveQuotaAccess = res.privileges.hasOwnProperty(
          'leavePlannerOpsLeaveQuota'
        )
          ? res.privileges.leavePlannerOpsLeaveQuota
          : false;
      });
  }
}
