import { finalize } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadAdapter } from 'src/app/shared/ckEditor-ImageUpload/ckeditor-imageUpload';
import { NewsEventsService } from 'src/app/users/service/manage-news & events/news-events.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { MatHorizontalStepper } from '@angular/material/stepper';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-create-edit-managenews',
  templateUrl: './create-edit-managenews.component.html',
  styleUrls: ['./create-edit-managenews.component.scss'],
})
export class CreateEditManagenewsComponent implements OnInit {
  listingType = [
    { name: 'No Photo', value: 1 },
    { name: 'Small Photo', value: 2 },
    { name: 'Large Photo', value: 3 }
  ];
  channelArray: any[] = [];
  isIconLoading: boolean = false;
  title = 'my-image-cropper';
  @ViewChild('myInput') myInputVariable: ElementRef<HTMLInputElement>;

  createNewsForm: FormGroup;
  @ViewChild('AddDepartment', { static: true })
  AddDepartment: TemplateRef<any>;
  @ViewChild('AddImageCropper', { static: true })
  AddImageCropper: TemplateRef<any>;
  isTeaserLoading: boolean;
  mainImageLoading: boolean;
  mainImageUrl: string;
  teaserUrl: string;
  categoriesArray: any;
  modulesArray: any;
  channel;
  channelName;
  categoryName;
  category;
  contentTitle;
  imageCategory: string;
  baseUrl = environment.imageUrl;
  minDate= {

  }
  @Input() newsId: any; // getting news data and later swapping it
  @Output() overViewEvent = new EventEmitter<string>();
  userName: string;
  newsData: any = null;
  efectiveMinDate: any;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  isLinear = false;
  constructor(
    public http: HttpClient,
    public newsService: NewsEventsService,
    public modelService: NgbModal,
    public formBuilder: FormBuilder,
    public tostService: ToastrService
  ) {
    this.userName = localStorage.getItem('flexistaffId');
  }

  setTopLabel: 'NEWS CONTENT'
  public options: Object = {
    key: environment.froalaEditorKey,
    videoResponsive: true,
    charCounterMax: 150,
    videoInsertButtons: ['videoBack', '|', 'videoByURL'],
    listAdvancedTypes: true,
    toolbarButtons: ['bold', 'italic', 'underline', 'insertLink', 'insertHR', 'insertTable', 'emoticons', 'textColor', 'backgroundColor', 'quote', 'paragraphStyle', 'paragraphFormat', 'inlineStyle', 'outdent', 'indent',  'insertHorizontalRule','formatOL', 'formatUL', 'align', 'clearFormatting'],
    quickInsertEnabled: false,
    attribution: false,
  }
  public otheroptions: Object = {
    key: environment.froalaEditorKey,
    videoResponsive: true,
    quickInsertEnabled: false,
    videoInsertButtons: ['videoBack', '|', 'videoByURL', 'textColor', 'backgroundColor', 'videoEmbed', 'videoUpload'],
    listAdvancedTypes: true,
    toolbarButtons: ['undo', 'redo' ,'bold', 'italic', 'underline','insertLink', 'insertHR', 'insertTable', 'emoticons', 'paragraphStyle','quote','paragraphFormat', 'strikeThrough','inlineStyle','subscript', 'superscript', 'fontFamily', 'fontSize', 'color', 'formatBlock', 'blockStyle', 'inlineStyle', 'align', 'insertOrderedList', 'insertUnorderedList', 'outdent', 'indent', 'selectAll', 'createLink', 'insertImage', 'insertVideo', 'table', 'undo', 'redo', 'html', 'save', 'insertHorizontalRule', 'uploadFile', 'removeFormat', 'fullscreen', 'formatOL', 'formatUL', 'clearFormatting' ],
    videoUploadURL: `${environment.baseUrl}/notification/uploadContentFiles/`,
    requestHeaders: {
      Authorization: localStorage.getItem("flexiusersToken")
    },
    imageUploadParams: {crossorigin: "anonymous"},

    requestWithCORS: false,
   // Set the image upload URL.
    imageUploadURL: `${environment.baseUrl}/post/uploadContentFiles`,
    attribution: false,
    // Set request type.
    imageUploadMethod: 'POST',

        // Allow to upload PNG and JPG.
    imageAllowedTypes: ['jpeg', 'jpg', 'png'],
    // Set request type.
    videoUploadMethod: 'POST',

    // Set max video size to 50MB.
    videoMaxSize: 50 * 1024 * 1024,

    // Allow to upload MP4, WEBM and OGG
    videoAllowedTypes: ['webm', 'jpg', 'ogg', 'mp4', 'mov', 'MKV'],
    events: {
      'video.beforeUpload': function (videos) {
        // Return false if you want to stop the video upload.
      },
      'video.uploaded': function (response) {
        // Video was uploaded to the server.
      },
      'video.inserted': function ($img, response) {
        // Video was inserted in the editor.
      },
      'video.replaced': function ($img, response) {
        // Video was replaced in the editor.
      },
      'video.error': function (error, response) {

      },
      'image.beforeUpload': function (images) {
        // Return false if you want to stop the image upload.
      },
      'image.uploaded': function (response) {
        // Image was uploaded to the server.
      },
      'image.inserted': function ($img, response) {
        // Image was inserted in the editor.
      },
      'image.replaced': function ($img, response) {
        // Image was replaced in the editor.
      },

    }
  }
  @ViewChild(MatHorizontalStepper, { static: false }) stepper: MatHorizontalStepper;
  isSubmitted = false;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  croppedFilePath: any = "";
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.croppedFilePath = event;
  }
  imageLoaded() {
    this.showCropper = true;
  }
  cropperReady(sourceImageDimensions: Dimensions) {

  }

  loadImageFailed() {}

  clear(isStatus) {
    this.modelService.dismissAll();
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
    this.croppedImage = '';
    this.imageChangedEvent = '';
   if (!isStatus) this.modelService.open(this.AddImageCropper, {
      windowClass: 'modal-ui-fix.center-align.xlModal', size: 'lg',
    });
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV,
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation,
    };
  }

  ngOnInit() {
    const current = new Date();

        this.minDate = { year: current.getFullYear(), month: current.getMonth() + 1, day: current.getDate() };

    this.firstFormGroup = this.formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this.formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
    this.thirdFormGroup = this.formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
    this.initForm();
    this.getModules();
    this.getchannels();
    this.newsData = this.newsId;
    this.newsId = this.newsData? this.newsData._id: null;
    this.newsId && this.getNewsEdit(this.newsData);
  }

  getNewsEdit(newsObj: any) {
    const {teaser,content,publishing,status,postType, channelId} = newsObj;
      this.getCategories(newsObj.channelId)
      // this.channel = publish.channelId,
      // this.category = publish.categoryId,
      this.channelName = newsObj.channelId.name;
      this.categoryName = newsObj.categoryId.name
      this.createNewsForm.patchValue({
        postType: postType,
        teaser: {
          title: teaser.title,
          listingType: teaser.listingType,
          image: teaser.image,
        },
        content: {
          title: content.title,
          image: content.image,
          isTeaserImage: content.isTeaserImage,
          content: content.content,
        },

        publish: {
          channelId: newsObj.channelId._id,
          categoryId: newsObj.categoryId._id,
          startDate: publishing.startDate,
          endDate: publishing.endDate,
          moduleId: newsObj?.moduleId?._id || null,
          moduleData:{moduleName:newsObj?.moduleId?.moduleName || null, _id:newsObj?.moduleId?._id || null}
        },
        status:status,
       });

    // const sub = this.newsService
    //   .getSingleNewsEvents(id)
    //   .pipe(
    //     finalize(() => {
    //       sub.unsubscribe();
    //     })
    //   )
    //   .subscribe(
    //     ({ data:{teaser,content,publish,status,postType} }: any) => {
    //       this.getCategories(publish.channelId)
    //       // this.channel = publish.channelId,
    //       // this.category = publish.categoryId,
    //       this.channelName = publish.channelId.name;

    //       this.categoryName = publish.categoryId.name
    //       this.createNewsForm.patchValue({
    //         postType: postType,
    //         teaser: {
    //           title: teaser.title,
    //           listingType: teaser.listingType,
    //           image: teaser.image,
    //         },
    //         content: {
    //           title: content.title,
    //           image: content.image,
    //           isTeaserImage: content.isTeaserImage,
    //           content: content.content,
    //         },

    //         publish: {
    //           channelId: publish.channelId._id,
    //           categoryId: publish.categoryId._id,
    //           startDate: publish.startDate,
    //           endDate: publish.endDate,
    //           moduleId: publish.moduleId ? publish.moduleId._id :null,
    //         },
    //         status:status,
    //       });
    //     },
    //     (error) => {}
    //   );
  }

  getDate(value){
    this.efectiveMinDate = value;
  }

  deleteModuleData(){
    this.createNewsForm.patchValue({
      publish: {
        moduleData:{
          moduleName: null,
           _id: null
          }
      },
    });
    this.createNewsForm.get('publish').get('moduleId').setValue(null);
  }

  addModuleData(event){
    this.createNewsForm.patchValue({
      publish: {
        moduleData:{
          moduleName: event?.moduleName || null,
           _id: event?._id || null
          }
      },
    });
    this.createNewsForm.get('publish').get('moduleId').setValue(null);
  }
  getchannels() {
    const sub = this.newsService
      .getAllChannels()

      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe((res: any) => {
        this.channelArray = res.data;
      });
  }

  getCategories(channel) {
    // this.channel = channel;
    this.channelName = channel.name;

    // this.categoryName = this.channel.categoryList[0].name;
    this.createNewsForm.patchValue({
      publish:{
      categoryId:null
      }
    })
    if (channel._id) {
      const sub = this.newsService
        .getAllCategories(channel._id)

        .pipe(
          finalize(() => {
            sub.unsubscribe();
          })
        )
        .subscribe((res: any) => {
          this.categoriesArray = res.data;
        });
    }
  }
  setCategories( channel) {

    this.categoryName = channel.name
  }
  getModules() {
    const sub = this.newsService
      .getnonLinkModules()

      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe(({ data }) => {
        this.modulesArray = data.moduleList;
      });
  }

  initForm() {
    return (this.createNewsForm = this.formBuilder.group({
      postType: 'news',
      teaser: this.formBuilder.group({
        title:['',Validators.required],
        listingType: 2,
        image: ['',Validators.required],
      }),
      content: this.formBuilder.group({
        title: ['',Validators.required],
        image: '',
        isTeaserImage: false,
        content: ['',Validators.required],
      }),

      publish: this.formBuilder.group({
        channelId: [null,Validators.required],
        categoryId: [null,Validators.required],
        startDate: ['',Validators.required],
        moduleId:null,
        endDate: ['',Validators.required],
        moduleData:[{moduleName:null,_id:null}]
      }),

      status: 1,
    }));
  }
  onDeleteIcon(key) {
    key === 'teaser' ? (this.teaserUrl = null) : (this.mainImageUrl = null);
    key === 'teaser'
      ? this.createNewsForm.get('teaser').patchValue({
          image: '',
        })
      : this.createNewsForm.get('content').patchValue({
          image: '',
        });
  }
   dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}

  Upload(key) {
    const file = this.dataURLtoFile(this.croppedImage, "croppedImg.png");
      const fileUpload = this.newsService
        .uploadFile(file)
        .pipe(
          finalize(() => {
            fileUpload.unsubscribe();
          })
        )
        .subscribe(
          (data: any) => {
            key === 'teaser'
              ? (this.teaserUrl = `${environment.imageUrl}${data.data.path}`)
              : (this.mainImageUrl = `${environment.imageUrl}${data.data.path}`);

            key === 'teaser'
              ? this.createNewsForm.get('teaser').patchValue({
                  image: `${environment.imageUrl}${data.data.path}`,
                })
              : this.createNewsForm.get('content').patchValue({
                  image: `${environment.imageUrl}${data.data.path}`,
                });

            key === 'teaser'
              ? (this.isTeaserLoading = false)
              : (this.mainImageLoading = false);
            this.tostService.success('Icon Uploaded Successfully')

            this.modelService.dismissAll();
            this.clear(true);
          },
          (error) => {
            key === 'teaser'
              ? (this.isTeaserLoading = false)
              : (this.mainImageLoading = false);
          }
        );
    // }
  }
  preview() {
    const {
      publish: { channelId, categoryId },
      content: { content },
    } = this.createNewsForm.value;
    this.contentTitle = content;
    (this.channel = channelId), (this.category = categoryId);
    this.modelService.open(this.AddDepartment, {
      windowClass: 'modal-ui-fix.center-align',
    });
  }

  openImageCropUpload(value){
    this.imageCategory = value;
    this.modelService.open(this.AddImageCropper, {
      windowClass: 'modal-ui-fix.center-align.xlModal', size: 'lg',
    });
  }


  saveAsDraft() {
    if (!this.newsId && !this.minPublicationTimeValidator()) {
      return;
    }
      const {
        publish: { channelId, categoryId },
      } = this.createNewsForm.value;
      const data = {
        ...this.createNewsForm.value,
        // status: 'inactive',
        userOptions: {
          like: false,
          comment: false,
          share: false,
          attendance: false
      },
      channelId: channelId,
      categoryId: categoryId,
      moduleId:this.createNewsForm.get('publish').get('moduleData').value?._id,
      publishing:{
        startDate:this.createNewsForm.get('publish').get('startDate').value,
        endDate:this.createNewsForm.get('publish').get('endDate').value,
      },
      isNotifi:true,
      eventDetails:{},
      status:2
      };
        this.newsService.createNewsEvents(data).
      subscribe((res) => {
        this.overViewEvent.emit(null);
        this.tostService.success('News Created');
      },
      (error) => {
        this.tostService.error('Please Fill all the fields');
      });
  }

  async onCreateNews() {
    if(!this.createNewsForm.valid){
      this.tostService.error('Please Fill the form');
      return
    }
    if(!this.newsId && !this.minPublicationTimeValidator()){
      return
    }
    const {
      publish: { channelId, categoryId },
    } = this.createNewsForm.value;
    const data = {
      ...this.createNewsForm.value,
      userOptions: {
        like: false,
        comment: false,
        share: false,
        attendance: false
    },
    eventDetails: {},
      status: 1,
      channelId: channelId,
        categoryId: categoryId,
        moduleId:this.createNewsForm.get('publish').get('moduleData').value?._id,
        publishing: {
        ...this.createNewsForm.get('publish').value,

      },
    };
    data.publish.moduleId=this.createNewsForm.get('publish').get('moduleData').value?._id;
    delete data.publishing.categoryId
    delete data.publishing.channelId
    delete data.publishing.moduleId
    delete data.publishing.moduleData
    delete data.publish.moduleData
    if (this.newsId) {
      await Swal.fire({
          title: 'Send Notification!',
          text: 'Do you wish to send a notification for this update?',
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: `Yes`,
          cancelButtonText: `No`,
        }).then((result) => {
          if (result.isConfirmed) {
            data['isNotifi'] = 'true';
          } else {
            data['isNotifi'] = 'false';
          }
        });
    }
    this.newsId ?  this.newsService.updateNewsEvents({... data, postId: this.newsId}).subscribe((res) => {
      this.overViewEvent.emit(null);
      this.tostService.success('News updated');
    }) :
    this.newsService.createNewsEvents( data).subscribe((res) => {
      this.overViewEvent.emit(null);
      this.tostService.success('News Created');
    });
  }

  nextStep(stepIndex): void {
    this.isSubmitted = true;
    if (stepIndex == 1 ) {
      if (this.createNewsForm.controls.teaser.status === 'VALID') {
        this.stepper.next();
        this.isSubmitted = false
      }
    } else if (stepIndex == 2) {
      if (this.createNewsForm.controls.content.status === 'VALID') {
        this.stepper.next();
        this.isSubmitted = false
      }
    }
  }

  //method to validate publication time
  minPublicationTimeValidator() {
    const startDateTime = new Date(this.createNewsForm.get('publish.startDate').value);
    const endDateTime = new Date(this.createNewsForm.get('publish.endDate').value);
    const currentDateTime = new Date();
    if (startDateTime <= currentDateTime) {
      this.tostService.error('Start time should be greater than current time.');
      return false;
    } else if (endDateTime <= startDateTime) {
      this.tostService.error('End time should be greater than start time.');
      return false;
    }
    return true;
  }

  validateURLImage(str) {
    var img_Path = str;
    if (img_Path.indexOf("https://") == 0) {
      var new_url = new URL(str);
     var pathName = (new_url.pathname.startsWith('//')) ? `/${new_url.pathname.split("//")[1]}` : new_url.pathname;
      return `${this.baseUrl}${pathName}`;
    }
    if (str.startsWith('/')) return this.baseUrl + str;
    return `${this.baseUrl}/${str}`;
   }
}
