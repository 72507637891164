<ul
  ngbNav
  #nav="ngbNav"
  [(activeId)]="active"
  class="nav-tabs mb-3 rounded"
  (navChange)="navChanged1($event)"
>
  <li [ngbNavItem]="1">
    <a ngbNavLink class="text-uppercase" (click)="backTo(1)">Overview</a>
    <ng-template ngbNavContent>
      <div class="card">
        <h5 class="card-header bg-theme">Leave Balloting</h5>
        <div class="card-body table-responsive p-0">
          <ngx-skeleton-loader
            *ngIf="ballotLoader"
            count="6"
            [theme]="{
              width: 'calc(100% - 20px)',
              'border-radius': '6px',
              height: '44px',
              margin: '10px',
              display: 'block'
            }"
          ></ngx-skeleton-loader>
          <table class="table table-hover" *ngIf="!ballotLoader">
            <thead>
              <tr>
                <th scope="col">BALLOT TITLE</th>
                <th scope="col">DATE CREATED</th>
                <th scope="col">BALLOT PERIOD</th>
                <th scope="col">OPENING DATE FOR APPLICATION</th>
                <th scope="col">CLOSING DATE FOR APPLICATION</th>
                <th scope="col">STATUS</th>
                <th scope="col">ACTIONS</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of ballotDataTable.data; let i = index">
                <td>
                  {{
                    item.ballotRound != 0
                      ? item.ballotName + " - Round " + (item.ballotRound + 1)
                      : item.ballotName
                  }}
                </td>
                <td>
                  <span class="noWrap">
                    {{ item.createdAt | date: "mediumDate" }}
                  </span>
                </td>
                <td>
                  <span class="noWrap">
                    {{ item.ballotStartDate | date: "mediumDate" }} to
                    {{ item.ballotEndDate | date: "mediumDate" }}
                  </span>
                </td>
                <td>
                  <span class="noWrap">
                    {{ item.applicationOpenDateTime | date: "mediumDate" }}
                  </span>
                </td>
                <td>
                  <span class="noWrap">
                    {{ item.applicationCloseDateTime | date: "mediumDate" }}
                  </span>
                </td>
                <td>
                  <ng-container *ngIf="!item.isAutoAssign">
                    <span *ngIf="item.isDraft">Draft<span *ngIf="item.isCanceled">, Cancelled</span></span>
                    <span *ngIf="!item.isDraft && statusByTime(item.applicationCloseDateTime)">{{
                      item.isPublish
                        ? item.isCanceled
                          ? "Cancelled"
                          : "Open"
                        : item.isCanceled
                        ? "Cancelled"
                        : "Pending Publication"
                    }}</span>
                    <span *ngIf="!item.isDraft && !statusByTime(item.applicationCloseDateTime)">
                      {{ item.isCanceled ? "Cancelled" : "Closed" }}
                    </span>
                    <span *ngIf="item.isConduct">, Computed </span>
                    <span *ngIf="item.isResultRelease"
                      >, Results Released
                    </span>
                  </ng-container>
                  <ng-container *ngIf="item.isAutoAssign">
                    <span *ngIf="!item.isConduct">To be Auto-Assigned</span>
                    <span *ngIf="item.isConduct"
                      >{{ item.isCanceled ? "Cancelled" : "Closed" }}, Computed
                    </span>
                    <span *ngIf="item.isConduct && item.isResultRelease"
                      >{{ item.isCanceled ? "Cancelled" : "Closed" }}, Computed,
                      Results Released
                    </span>
                  </ng-container>
                </td>
                <td>
                  <div class="d-flex">
                    <button class="btn btn-info btn-sm" (click)="viewBallot(i)">
                      View
                    </button>
                    <button
                      class="btn btn-warning btn-sm ml-2"
                      (click)="editBallot(i)"
                      [disabled]="item.isPublish || item.isAutoAssign"
                    >
                      Edit
                    </button>
                    <button
                      *ngIf="!onlyViewAccess"
                      class="btn btn-success btn-sm ml-2"
                      (click)="manageBallot(i)"
                    >
                      Manage
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer">
          <div class="d-flex justify-content-between">
            <p>Total records {{ ballotDataTable.total }}</p>
            <ngb-pagination [collectionSize]="ballotDataTable.total" [(page)]="ballotDataTable.page" [maxSize]="5"
              (pageChange)="onPageChangeballotData($event)" [pageSize]="ballotDataTable.limit">
            </ngb-pagination>
          </div>
        </div>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="2" *ngIf="!onlyViewAccesstemp">
    <a ngbNavLink class="text-uppercase"
      >{{
        view ? "View" : edit ? "Edit" : auto ? "Auto-Assign" : "Create"
      }}
      Ballot</a
    >
    <ng-template ngbNavContent>
      <create-ballot
        *ngIf="!this.view"
        [ballot]="ballotData"
        [autoBallot]="autoBallotData"
        [roundBallot]="roundBallot"
        (back)="backTo($event)"
      ></create-ballot>
      <view-ballot
        *ngIf="this.view"
        [ballot]="ballotData"
        (back)="backTo($event)"
      ></view-ballot>
    </ng-template>
  </li>
  <li [ngbNavItem]="3" [disabled]="manage" *ngIf="!onlyViewAccess">
    <a ngbNavLink class="text-uppercase">Manage Ballot</a>
    <ng-template ngbNavContent>
      <manage-ballot
        [ballot]="ballotData"
        (ballotRound)="addBallotRound($event)"
        (ballotAuto)="autoBallot($event)"
        (redirectToOverview)="fromManageToOverview()"
        (back)="backTo($event)"
      ></manage-ballot>
    </ng-template>
  </li>
  <li [ngbNavItem]="4" class="align-self-center ml-auto nav-item pr-2">
    <button class="btn btn-primary" (click)="getBallot()">Refresh</button>
  </li>
</ul>

<div [ngbNavOutlet]="nav"></div>
