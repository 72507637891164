import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-notification',
  templateUrl: './my-notification.component.html',
  styleUrls: ['./my-notification.component.scss']
})
export class MyNotificationComponent implements OnInit {
  activeId =1
  constructor() { }

  ngOnInit() {
  }

}
