<div class="d-flex flex-column">
  <ul ngbNav #companyNav="ngbNav" class="nav-tabs rounded mb-3">
    <li [ngbNavItem]="1">
      <ng-template ngbNavContent>
        <app-post-logs></app-post-logs>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="companyNav"></div>
</div>
