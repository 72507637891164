<div class="tab-content">
  <div class="row dashboardPanelCon">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="panel panel-default">
        <div class="panel-heading">Pushed Notification List</div>
        <div class="panel-body lg-padding">
          <div class="table-responsive">
            <div class="card-body">
              <div class="card table-card">
                <div class="card-body flexi-body">
                  <div class="flex-column">
                    <div class="d-flex justify-content-between mt-2 mb-3">
                      <select class="custom-select" style="width: auto" name="pageSize"
                        (change)="onLimitChangeSchedule()" [(ngModel)]="schedulePTable.limit">
                        <option [ngValue]="10">10</option>
                        <option [ngValue]="20">20</option>
                        <option [ngValue]="30">30</option>
                        <option [ngValue]="40">40</option>
                        <option [ngValue]="50">50</option>
                      </select>
                      <div class="form-group form-inline mb-0">
                        <span *ngIf="schedulePTable.isLoading">Please wait...</span>
                        <input class="form-control ml-2" type="search" name="searchTerm"
                          (keyup)="scheduleSearchInput.next($event)" placeholder="Search here"
                          [(ngModel)]="schedulePTable.search" />
                      </div>
                    </div>
                    <div class="table-responsive">
                      <table class="table" *ngIf="!isTableLoading">
                        <thead>
                          <tr>
                            <th scope="col" sortable="data.title" (sort)="onSortSchedule($event)">
                              Title
                            </th>
                            <th scope="col" sortable="data.type" (sort)="onSortSchedule($event)">
                              Type
                            </th>
                            <th scope="col" sortable="lastFinishedAt" (sort)="onSortSchedule($event)">
                              Pushed Date
                            </th>
                            <th scope="col" sortable="lastFinishedAt" (sort)="onSortSchedule($event)">
                              Pushed Time
                            </th>
                            <th scope="col" sortable="totalSent" (sort)="onSortSchedule($event)">
                              Notification Count
                            </th>
                            <th scope="col" sortable="data.createdBy" (sort)="onSortSchedule($event)">
                              Created By
                            </th>
                            <th scope="col" sortable="data.status" (sort)="onSortSchedule($event)">
                              Status
                            </th>
                            <th scope="col">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of schedulePTable.data">
                            <td>
                              {{ item.title }}
                            </td>
                            <td>
                              {{ item.type }}
                            </td>
                            <td>
                              {{ item.pushedDate }}
                            </td>
                            <td>
                              {{ item.pushTime }}
                            </td>
                            <td>
                              {{ item.notificationCount }}
                            </td>
                            <td>
                              {{ item.createdBy }}
                            </td>
                            <td class="active-status">{{ item.status }}</td>

                            <td>
                              <button class="btn btn-submit p-2 m-1 pl-3 pr-3" (click)="onViewSchedule(item._id)">
                                View
                              </button>
                              <button *ngIf="item.status !== 'Completed' && item.status !== 'Cancelled'"  class="btn btn-add p-2 m-1 pl-3 pr-3" (click)="onEditSchedule(item._id, item.status, 'edit')">
                                Edit
                              </button>
                              <button *ngIf="item.status == 'Pending' || item.status == 'Draft'" class="btn btn-warning" (click)="onEditSchedule(item._id, item.status, 'cancel')">
                                Cancel
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <app-skliton-loading-table [numberOfCols]="8" [isTableLoading]="isTableLoading">
                    </app-skliton-loading-table>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="d-flex justify-content-between">
                    <p>Total records {{ schedulePTable.total }}</p>
                    <ngb-pagination [collectionSize]="schedulePTable.total" [(page)]="schedulePTable.page" [maxSize]="5"
                      (pageChange)="onPageChangeSchedule($event)" [pageSize]="schedulePTable.limit">
                    </ngb-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="table-responsive">
              <table datatable [dtOptions]="dtOptions" class="table table-hover"  id="notificationList">
                <thead>
                  <tr>
                    <th class="centerAlign">Title</th>
                    <th class="centerAlign">Type</th>
                    <th class="centerAlign">Pushed Date</th>
                    <th class="centerAlign">Pushed Time</th>
                    <th class="centerAlign">Notification Count</th>
                    <th class="centerAlign">Created By</th>
                    <th class="centerAlign">Status</th>
                    <th class="centerAlign">Action</th>

                  </tr>
                </thead>
              </table>
            </div> -->
        </div>
      </div>


    </div>
  </div>
</div>
<ng-template #content let-modal>
  <div class="modal-header" style="border:none">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss(0)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p style="font-size: 16px; color:'#a42138'; text-align: center; font-weight: 'bold';">Do you want to cancel this
      notification?</p>
  </div>
  <div class="modal-footer" style="border:none">
    <button type="button" class="btn btn-sm btn-success" (click)="modal.close(1)">Yes</button>
    <button type="button" class="btn btn-sm btn-danger" (click)="modal.dismiss(0)">No</button>
  </div>
</ng-template>
