import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-locked-account',
  templateUrl: './locked-account.component.html',
  styleUrls: ['./locked-account.component.scss']
})
export class LockedAccountComponent implements OnInit {
  activeId
  constructor() { }

  ngOnInit(): void {
  }

}
