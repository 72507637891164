<div class="card" *ngIf="isOverview">
  <label class="font-weight-500 m-3" for="">Select Week</label>
  <div class="d-flex ml-3 mb-3">
    <div class="weekbtn d-flex justify-content-center align-items-center rounded-left" (click)="changeWeek('Prev')"
      title="Previous Week">
      <i class="material-icons"> chevron_left </i>
    </div>

    <div class="weekInput d-flex align-items-center">
      {{ currWeek }}
    </div>

    <div class="weekbtn d-flex justify-content-center align-items-center rounded-right" (click)="changeWeek('Nxt')"
      title="Next Week">
      <i class="material-icons"> chevron_right </i>
    </div>
  </div>
  <div class="card-header align-items-center bg-theme">
    <h5 class="m-0">Log Open Shifts</h5>
    <p class="m-0">Selected Business Unit : {{ bu.orgName }}</p>
  </div>
  <div class="card-body table-responsive p-0">
    <ngx-skeleton-loader
      *ngIf="openLogLoader"
      count="6"
      [theme]="{
        width: 'calc(100% - 20px)',
        'border-radius': '6px',
        height: '44px',
        margin: '10px',
        display: 'block'
      }"></ngx-skeleton-loader>
    <table class="table m-0" *ngIf="!openLogLoader">
      <thead>
        <tr>
          <th scope="col" sortable="Tier 1">DATE</th>
          <!-- (sort)="onSortBuShiftSetup($event) -->
          <th scope="col" sortable="Tier 2">TIME</th>
          <th scope="col" sortable="Tier 3">DESCRIPTION</th>

          <th scope="col" sortable="Tier 4">USER</th>
          <th scope="col" sortable="Status">STATUS</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let i of shiftLogData">
          <td>{{ i.createdAt | date: "dd/MM/yyyy" }}</td>
          <td>{{ i.createdAt | date: "HH:mm" }}</td>
          <td>{{ i.description }}</td>
          <td>{{ i.userId.name }}</td>
          <td>
            <button class="btn btn-success" (click)="changeOverview(false, i)">
              View
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="bg-white text-center p-3" *ngIf="!openLogLoader && shiftLogData?.length === 0">
      No Data Available
    </div>
  </div>
</div>

<div class="card" *ngIf="!isOverview">
  <div
    class="card-header d-flex align-items-center justify-content-between bg-theme"
  >
    <h5 class="m-0">LOG DETAILS</h5>
    <button class="btn btn-outline-light btn-sm" (click)="changeOverview(true)">
      Back
    </button>
  </div>
  <div class="card-body">
    <form>
      <div class="card mb-3">
        <div class="card-header">
          <h5 class="m-0">General</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label for="">Date</label>
                <input
                  #date
                  class="form-control"
                  [disabled]="true"
                  [value]="this.logDetail.createdAt | date: 'dd/MM/yyyy'"
                  placeholder="date"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="">Time</label>
                <input
                  class="form-control"
                  [disabled]="true"
                  [value]="this.logDetail.createdAt | date: 'shortTime'"
                  placeholder="time"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="">User</label>
                <input
                  class="form-control"
                  [disabled]="true"
                  [value]="this.logDetail.userId.name"
                  placeholder="user"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="">Description</label>
                <input
                  class="form-control"
                  [disabled]="true"
                  [value]="this.logDetail.description"
                  placeholder="Description"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <h5 class="m-0">Shift Listing</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div
                class="shiftTab d-flex align-items-center mb-2"
                [ngClass]="shiftDetail == j ? 'selected' : ''"
                (click)="changeShiftDetailsView(true, j)"
                *ngFor="let j of logDetail.shiftDetails.shiftDetails"
              >
                <div class="d-flex justify-content-between w-100">
                  <div>
                    Shift Published Successfully
                    <ng-container *ngIf="j.subSkillSets?.length">
                      <p class="m-0 red" *ngFor="let s of j.subSkillSets" style="color: #c12e2e;">
                        {{ s.skillSetId.name | titlecase }} >
                        {{ s.name | titlecase }}
                      </p>
                    </ng-container>
                  </div>
                  <div *ngIf="j.isOff == ''">
                    {{ j.startTime | date: "HH:mm" }}-{{
                      j.endTime | date: "HH:mm"
                    }}
                    <div *ngIf="j.isSplitShift">
                      {{ j.splitShiftStartTime | date: "HH:mm" }}-{{
                      j.splitShiftEndTime | date: "HH:mm"
                      }}
                    </div>
                    <div class="d-flex justify-content-end mt-1">
                      <div
                        class="badge badge-pill bg-theme"
                      >
                        {{ j?.staffNeedCount }}/{{
                          j?.backUpStaffNeedCount
                        }}
                      </div>
                    </div>
                  </div>
                  <div *ngIf="j.isOff != ''">
                    {{ j.isOff }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6" *ngIf="showShiftDetails">
              <div class="card">
                <div class="card-header">
                  <h6 class="m-0">Details of Shift</h6>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="">Business Unit</label>
                        <input
                          class="form-control"
                          placeholder="Business Unit"
                          [disabled]="true"
                          value="{{ bu.orgName }}"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="">Date</label>
                        <input
                          class="form-control"
                          placeholder="Date"
                          [disabled]="true"
                          value="{{ shiftDetail.date | date: 'dd/MM/yyyy'}}"
                        />
                      </div>
                    </div>
                    <div class="col-md-12" *ngIf="!shiftDetail.isOff || !shiftDetail.isRest">
                      <div class="form-group row">
                        <label for="" class="col-md-12">Timing</label>
                        <div class="col-md-6">
                          <input
                            class="form-control col-md-5"
                            placeholder="Timing"
                            [disabled]="true"
                            value="{{ shiftDetail.startTime | date: 'HH:mm' }}"
                          />
                        </div>
                        <div class="col-md-6">
                          <input
                            class="form-control col-md-5"
                            placeholder="Timimg"
                            [disabled]="true"
                            value="{{ shiftDetail.endTime | date: 'HH:mm' }}"
                          />
                        </div>
                      </div>
                      <div class="form-group row" *ngIf="shiftDetail.isSplitShift">
                        <label for="" class="col-md-12">Split Timing</label>
                        <div class="col-md-6">
                          <input
                            class="form-control col-md-5"
                            placeholder="Timing"
                            [disabled]="true"
                            value="{{ shiftDetail.splitShiftStartTime | date: 'HH:mm' }}"
                          />
                        </div>
                        <div class="col-md-6">
                          <input
                            class="form-control col-md-5"
                            placeholder="Timimg"
                            [disabled]="true"
                            value="{{ shiftDetail.splitShiftEndTime | date: 'HH:mm' }}"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="">Publish Message</label>
                        <input
                          class="form-control"
                          [disabled]="true"
                          value="Shift Publish Successfully"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
