import { queryParam } from 'src/app/core/utils/helper';
import { map } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Company } from 'src/app/core/model/definition.model';
import { userRoleData } from 'src/app/core/model/users.model';
import { StorageService } from 'src/app/core/service/storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  private baseUrl: string;
  private get Headers(): HttpHeaders {
    return new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }
  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {
    this.baseUrl = environment.baseUrl;
  }
  getRoles(data): Observable<any> {
    return this.http.get(`${this.baseUrl}/role/?${queryParam(data)}`, {
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }
  getUserRoles(): Observable<any> {
    return this.http.post(`${this.baseUrl}/role/read`, {
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }

  getUserPrivilege(): Observable<any>{
    return this.http.get(`${this.baseUrl}/companyUser/getUserPrivilege`)
    .pipe(map((res) => res as any))
  }

  getNewUserPrivilege(): Observable<any>{
    const data = {
      getPriv:true
    }
    return this.http.get(`${this.baseUrl}/companyuser/checkWithRole?${queryParam(data)}`)
    .pipe(map((res) => res as any))
  }

  getAllPrevileges(): Observable<any> {
    return this.http.post(`${this.baseUrl}/privilegecategory/read`,{
      headers: this.Headers,

    })
    .pipe(map((res) => res as any))
  }

  getOneRole(roleId: string):Observable<any> {
    return this.http.get(`${this.baseUrl}/role/${roleId}`)
    .pipe(map((res) => res as any))
  }
  createUserRole(roleData): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/role/create`, roleData)
      .pipe(map((res) => res as any));
  }
  updateUserRole(roleData: userRoleData): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/role/update`, roleData)
      .pipe(map((res) => res as any));
  }

}
