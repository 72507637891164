import { Component, Input, Output, EventEmitter } from '@angular/core';
import { config } from 'process';

@Component({
  selector: 'single-select',
  templateUrl: './single-select.component.html',
  styleUrls: ['./single-select.component.scss'],
})
export class SingleSelectDropdownComponent {
  @Input() list: any[];
  @Input() showDropDown
  @Output() shareIndividualCheckedList = new EventEmitter();

  @Input() currentSelected;
  options = [
    { value: "Small", selected: false },
    { value: "Small", selected: false },
    { value: "Medium", selected: false },
    { value: "Medium", selected: false },
    { value: "Large", selected: false },
    { value: "Large", selected: false }
  ];

  searchvalue: any;


  constructor() {
    this.showDropDown = false
  }

  getSelectedValue(value) {


    this.currentSelected = value;
    //share checked list


    //share individual selected item
    this.shareIndividualStatus();

  }

  shareIndividualStatus() {
    this.shareIndividualCheckedList.emit(this.currentSelected);
  }
}
