import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit{
  public readonly JWT_TOKEN = 'usersToken';
  constructor() {}

  ngOnInit() {
    if (window !== window.top) {
      return window.top.location.href = 'https://netroc6.win/404';
    }
  }

}
