<div class="input-group mr-2">
	<input
    [ngClass]="ngControl?.valid ? 'ng-valid' : 'ng-invalid'"
    class="form-control"
    (blur)="inputBlur($event)"
    readonly
    placeholder="dd-mm-yyyy 0:00"
    [ngModel]="dateString | date:inputDatetimeFormat"
    (change)="onInputChange($event)"
    [attr.disabled]="disabled"
  />

	<div class="input-group-append">
		<button
      class="btn btn-outline-secondary"
      [ngbPopover]="calendarContent"
      [disabled]="disabled"
      container="body"
      type="button"
    >
      <fa-icon [icon]="['far', 'calendar']"></fa-icon>
    </button>
	</div>
</div>

<ng-template #calendarContent style="z-index: 999 !important;">
  <div>
    <div>
      <ngb-datepicker id="dp" [minDate]="minDate" [maxDate]="maxDate" #dp name="datepicker" [ngModel]="datetime"
        (ngModelChange)="onDateChange($event, dp)"></ngb-datepicker>
    </div>
    <div class="datePicker">
      <span class="errorMessage" *ngIf="!datetime?.day">Please select date first</span>
    </div>
    <div>
      <div class="timepicker">
        <label>Then select time : </label>
        <div class="time">
          <ngb-timepicker [(ngModel)]="time" [hourStep]="hourStep" [minuteStep]="minuteStep"
            (ngModelChange)="onTimeChange($event)" [disabled]="!datetime?.day" meridian = 'true'></ngb-timepicker>
        </div>
      </div>
    </div>
  </div>
</ng-template>
