<div class="card">
  <div class="card-header flexi-header p-4">
    <h3>USEFUL LINKS</h3>
  </div>
  <div class="card-body">
    <form [formGroup]="userFullLinksForm" class="p-2">

      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label class="font-weight-bold" for="exampleInputEmail1">Link title (Limit To 50 characters)</label>

            <input type="title" class="form-control" placeholder="Link Title" formControlName="title" maxlength="50" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label class="font-weight-bold" for="exampleInputEmail1">External link</label>

            <input type="url" class="form-control" placeholder=" External Link" formControlName="link"
              [ngClass]="{ 'is-invalid': userLinksubmitted && userLinkControl.link.errors }" />
            <div *ngIf="userLinksubmitted && userLinkControl.link.errors" class="invalid-feedback">
              <small *ngIf="userLinkControl.link.errors.pattern">
                Please enter a valid link
              </small>
            </div>

          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 mb-2">
          <label class="font-weight-bold">Order</label>
          <select class="dropdown-select w-100 form-control" formControlName="indexNum">
            <option [value]="null" selected>Select Order</option>
            <option *ngFor="let cnt of useFullLinkOrders" [ngValue]="cnt">
              {{ cnt }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 mt-2">
          <label class="font-weight-bold">Upload icon</label>
          <div class="custom-file mt-0 pt-4 pb-4">
            <input type="file" class="custom-file-input" [disabled]="isIconLoading"
              (change)="iconUpload($event)" id="c" />
            <label class="custom-file-label" for="i">
              <i class="fa"
                [ngClass]="{'fa-spin fa-spinner fa-2x': isIconLoading, ' fa-plus-circle fa-2x': !isIconLoading}"
                aria-hidden="true"></i>
            </label>
          </div>
        </div>
        <div class="col-md-4" *ngIf="userFullLinksForm.get('icon').value">
          <div class="image-with-delete-icon " style="width: 150px;">
            <i class="fa fa-close" title="Delete" (click)="onDeleteIcon()"></i>
            <img crossorigin="anonymous" [src]="validateURLImage(userFullLinksForm.get('icon').value)" width="100px" height="80px" alt="Image Preview" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <button *ngIf="!usefullinkEdit" class="btn btn-add p-2 m-1"
            (click)="addUserLinks(this.userFullLinksForm.value)">
            Add
          </button>
          <button *ngIf="usefullinkEdit" class="btn btn-add p-2 m-1" (click)="updateUserLinks()">
            Update
          </button>
          <button class="btn btn-clear p-2 m-1" (click)="onClearUserLink() ">Clear</button>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">TITLE</th>
                <th scope="col">EXTERNAL LINK</th>

                <th scope="col">ICON IMAGE</th>

                <th scope="col">ACTION</th>

              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let link of useFullLinksArray; let index = index">
                <ng-container>
                  <td>
                    {{ link.title }}
                  </td>
                  <td>
                    <a [href]="link.link">{{link.link}}</a>

                  </td>

                  <td>
                    <img crossorigin="anonymous" [src]="validateURLImage(link.icon)" width="50px" height="50px" alt="icon Preview" />
                  </td>

                  <td>
                    <button class="btn btn-add p-2" style="width: 40px;" [disabled]="usefullinkEdit"
                      (click)="editUsefullLinks(link, index)">
                      <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-clear p-2" style="width: 40px;" [disabled]="usefullinkEdit"
                      (click)="deteleUseFullLinnk(index)">
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </button>
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <button class="btn btn-submit update-button pl-3 pr-3" [disabled]="isUpdateLoading "
      (click)="onSubmitSetupForm()"><i class="fa"
        [ngClass]="{'fa-spin fa-spinner': isUpdateLoading, '': !isUpdateLoading}"></i>Update</button>
  </div>
</div>