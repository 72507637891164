<div class="card my-3 w-100">
  <div class="card-header flexi-header">
    <h3>ASSIGN USERS</h3>
  </div>

  <div class="card-body">
    <form [formGroup]="userDetailsForm">
      <div class="row mb-2">
        <div class="col-md-4">
          <label class="red-label"> Business unit (Plan) <span class="field-mandatory">*</span></label>
          <ng-container *ngIf="moduleName=='challenges';else otherModule">
            <ng-select (change)="addbussinesUnitPlan($event)" class="custom hightlighted-dropdown" bindLabel="name"
            placeholder="Business unit (Plan)" appendTo="body" [multiple]="false" [searchable]="true"
            [clearable]="true" [(ngModel)]="buDropDownToClear" [ngModelOptions]="{standalone: true}"  [virtualScroll]="true"
            [loading]="planBuUnitDropDownLoading"
            >
            <ng-option [value]="'All Business Unit'">
              Select All Business Unit
            </ng-option>
            <ng-option [value]="item" *ngFor="let item of planBuUnitDropDown">
              {{ item.orgName }}
            </ng-option>
          </ng-select>
          </ng-container>
          <!--[loading]="planBuUnitDropDownLoading" (search)="onSeachPlanBuUnit($event)"
            (scrollToEnd)="loadMorePlasnBuUnitItems($event)" (clear)="onClearPlanBuUnitDropdown($event)"-->
          <ng-template #otherModule>
            <ng-select (change)="addbussinesUnitPlan($event)" class="custom hightlighted-dropdown" bindLabel="name"
            placeholder="Bussiness unit (Plan)" appendTo="body" [multiple]="false" [searchable]="true"
            [clearable]="true" [(ngModel)]="buDropDownToClear" [ngModelOptions]="{standalone: true}"
            >
            <ng-option [value]="'All Business Unit'">
              Select All Business Unit
            </ng-option>
            <ng-option [value]="item" *ngFor="let item of planBuUnitDropDown">
              {{ item.orgName }}
            </ng-option>
          </ng-select>
          </ng-template>

        </div>
        <div class="col-md-6">
          <div style="display: flex;flex-direction: row; flex-wrap: wrap; margin-top: 20px;">
            <div formArrayName="businessUnits" *ngFor="
              let field of BussinessUnitControl.controls;
              let index = index
            ">
              <span class="text-items list-ui" [formGroupName]="index">
                {{
                field.get("orgName").value? field.get("orgName").value : field.get("orgName").value }}
                <img class="ml-3" (click)="deletePlanBuUnitFields(index,field.get('_id').value)" src="./assets/images/cancel.png" width="20px"
                  height="20px" /></span>
            </div>
          </div>
        </div>
      </div>
      <hr />
    <ng-container class="row mb-2">
      <label class="red-label"> Select Anyone Option from here<span class="field-mandatory">*</span></label>
    </ng-container>

    <div class="row mb-2 ">
        <div class="col-md-4">
          <div class="radio radio-danger radio-inline">
            <input type="radio" formControlName="buFilterType" [value]="1" (change)="selectBusinessUnit('all')"/>
            <label for="iosu" class="iosu">
              All Users in Selected Business Unit</label>
          </div>
        </div>
        <div class="col-md-4">
          <div class="radio radio-danger radio-inline">
            <input type="radio" formControlName="buFilterType" [value]="2" (change)="selectBusinessUnit('includeSelected')"/>
            <label for="iosu" class="iosu">
              Include only Selected Users from Business Unit</label>
          </div>
        </div>
        <div class="col-md-4">
          <div class="radio radio-danger radio-inline">
            <input type="radio" formControlName="buFilterType" [value]="3" (change)="selectBusinessUnit('excludeSelected')"/>
            <label for="iosu" class="iosu">
              Exclude selected Users from Business Unit</label>
          </div>
        </div>
    </div>
      <hr />
      <div>
        <div class="row mb-2">
          <div class="col-md-4">
            <label class="red-label"> Appointment </label>
            <ng-select (change)="addAppointment($event)" [virtualScroll]="false" class="custom" bindLabel="name" placeholder="Select appointment"
              appendTo="body" [multiple]="false" [searchable]="true" [(ngModel)]="apoinemntDropDownToClear"
              [ngModelOptions]="{standalone: true}" [clearable]="true" [loading]="apppoinmentDropDownLoading"
               [hideSelected]="true" [disabled]="isDisabledFormFields">
              <ng-option [value]="item" *ngFor="let item of appoinmentDropDown">
                {{ item.name }}
              </ng-option>
            </ng-select>
          </div>
          <div class="col-md-6">
            <div style="display: flex;flex-direction: row; flex-wrap: wrap; margin-top: 20px;">
              <div formArrayName="appointments" *ngFor="
                let field of AppoinemtControl.controls;
                let index = index
              ">
                <span class="text-items list-ui" [formGroupName]="index">{{
                  field.get("name").value }}
                  <img class="ml-3" (click)="deleteAppoinemnt(index)" src="./assets/images/cancel.png" width="20px"
                    height="20px" /></span>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="row mb-2">
          <div class="col-md-4">
            <label class="red-label">Custom Fields
            </label>
            <ng-select (change)="addCustomFields($event)" class="custom" bindLabel="name" [virtualScroll]="false"
              placeholder="Select custom fields" appendTo="body" [multiple]="false" [searchable]="true" [clearable]="true"
              [(ngModel)]="customFeildDropDownToClear" [ngModelOptions]="{standalone: true}"
              [loading]="CustomFieldDropDownLoading" (search)="onSeachCustomField($event)"
              (scrollToEnd)="loadMoreCustomFieldItems($event)" (clear)="onClearCustomFieldDropdown($event)" [disabled]="isDisabledFormFields">
              <ng-option [value]="item" *ngFor="let item of fieldNeedsToUpdate">
                {{ item.fieldName }}
              </ng-option>
            </ng-select>
          </div>


          <div class="col-md-4" *ngIf="addedCustomfield">
            <div class="form-group" *ngIf="addedCustomfield?.type === 'text'">
              <label class="font-weight-bold">{{
                addedCustomfield.fieldName
                }}</label>

              <input type="text" class="form-control" placeholder="enter  value" #value />
              <div>
                <button class="btn btn-add" (click)="
                    customFieldADD(
                      value.value,
                      addedCustomfield.fieldName,
                      addedCustomfield._id
                    )
                  ">
                  Add
                </button>
                <button class="btn btn-clear" (click)="addedCustomfield = null">
                  Clear
                </button>
              </div>
            </div>
            <div class="form-group" *ngIf="addedCustomfield?.type === 'dropdown'">
              <label class="font-weight-bold">{{
                addedCustomfield.fieldName
                }}</label>

              <select class="form-control" #value>
                <option *ngFor="let item of addedCustomfield.options">{{item.value}}</option>

              </select>
              <div>
                <button class="btn btn-add" (click)="
                    customFieldADD(
                      value.value,
                      addedCustomfield.fieldName,
                      addedCustomfield._id
                    )
                  ">
                  Add
                </button>
                <button class="btn btn-clear" (click)="addedCustomfield = null">
                  Clear
                </button>
              </div>
            </div>
            <div class="form-group" *ngIf="addedCustomfield?.type === 'date'">
              <label class="font-weight-bold">{{
                addedCustomfield.fieldName
                }}</label>

              <input type="date" class="form-control" placeholder="enter  value" #value />
              <div>
                <button class="btn btn-add" (click)="
                    customFieldADD(
                      value.value,
                      addedCustomfield.fieldName,
                      addedCustomfield._id
                    )
                  ">
                  Add
                </button>
                <button class="btn btn-clear" (click)="addedCustomfield = null">
                  Clear
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-4">

            <div style="display: flex;flex-direction: row; flex-wrap: wrap; margin-top: 20px;">
              <div formArrayName="customField" *ngFor="
                let field of  CustomFiledControl.controls;
                let index = index
              ">
                <span class="text-items list-ui" [formGroupName]="index">{{
                  field.get("name").value }}
                  <img class="ml-3" (click)="deleteCustomField(index)" src="./assets/images/cancel.png" width="20px"
                    height="20px" /></span>
              </div>
            </div>

          </div>
        </div>
        <hr />
        <div class="row mb-2">
          <div class="col-md-3">
            <label class="red-label"> Select Skill Set </label>
            <ng-select (change)="addSkillSet($event)" [virtualScroll]="false" class="custom" bindLabel="name" placeholder="Select skill set"
              appendTo="body" [multiple]="false" [searchable]="true" [(ngModel)]="skillSetDropDownToClear"
              [ngModelOptions]="{standalone: true}" [clearable]="true" [loading]="skillSetDropDownLoading"
              (search)="onSearchSkillSet($event)" (scrollToEnd)="loadMoreSkillSetItems($event)"
              (clear)="onClearSkillSetDropdown($event)" [hideSelected]="true" [disabled]="isDisabledFormFields">
              <ng-option [value]="item" *ngFor="let item of skillSetDropDown">
                {{ item.name }}
              </ng-option>
            </ng-select>
          </div>
          <div class="col-md-3">
            <div *ngIf="showSubSkillSet">
            <label class="red-label"> Select SubSkill Set </label>
            <ng-select (change)="addSubSkillSet($event)" [virtualScroll]="false" class="custom" bindLabel="name" placeholder="Select subskill set"
              appendTo="body" [multiple]="false" [searchable]="false" [(ngModel)]="subSkillSetDropDownToClear"
              [ngModelOptions]="{standalone: true}" [clearable]="true"
              [hideSelected]="true" [disabled]="isDisabledFormFields">
              <ng-option [value]="item" *ngFor="let item of subSkillSetDropDown">
                {{ item.name }}
              </ng-option>
            </ng-select>
          </div>
          </div>
          <div class="col-md-6">
            <div style="display: flex;flex-direction: row; flex-wrap: wrap; margin-top: 20px;">
              <div formArrayName="subSkillSets" *ngFor="
                let field of SkillSetControl.controls;
                let index = index
              ">
                <span class="text-items list-ui" [formGroupName]="index">{{
                  field.get("name").value }}
                  <img class="ml-3" (click)="deleteSkillSet(index)" src="./assets/images/cancel.png" width="20px"
                    height="20px" /></span>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!isAssignUser">
          <hr/>
          <div class="row">
            <div class="col-md-4">
              <label class="red-label"> Assign Users </label>
              <ng-select (change)="addAuthor($event)" class="custom" bindLabel="name" placeholder="Select users"
                appendTo="body" [multiple]="false" [searchable]="true" [clearable]="true" (clear)="onClearAuthor($event)" (close)="loadAssignedUser()"
                [(ngModel)]="authorDropDownToClear" (scrollToEnd)="loadMoreAssignUsers($event)"  (search)="onSearchAssignUser($event)" [ngModelOptions]="{standalone: true}" [disabled]="isDisabledFormFields">
                <ng-option [value]="item" *ngFor="let item of userDropDown">
                  {{ item.name }}
                </ng-option>
              </ng-select>
            </div>
            <div class="col-md-6">
              <div style="display: flex;flex-direction: row; flex-wrap: wrap; margin-top: 20px;">
                <div formArrayName="user" *ngFor="
                let field of authorControl.controls;
                let index = index
              ">
                  <span class="text-items list-ui" [formGroupName]="index">{{
                    field.get("name").value }}
                    <img class="ml-3" (click)="deleteAuthor(index)" src="./assets/images/cancel.png" width="20px"
                      height="20px" /></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="row mb-2" *ngIf="isAdminView">
        <div class="col-md-4">
          <label class="red-label">Administrator </label>
          <ng-select (change)="addAdmin($event)" class="custom" bindLabel="name" [placeholder]="adminDropDownPlaceholderText" appendTo="body"
            [multiple]="false" [searchable]="true" [clearable]="true" [(ngModel)]="adminDropDownToClear" (clear)="onClearAdmin()" (close)="loadAdminData()" (search)="onSeachAdmin($event)"
            [ngModelOptions]="{standalone: true}" [virtualScroll]="true" (scrollToEnd)="loadMoreAdmin($event)"
            [loading]="adminLoading">
            <ng-option [value]="item" *ngFor="let item of adminDropDown">
              {{ item.name }}
            </ng-option>
          </ng-select>
        </div>
        <div class="col-md-6">

          <div style="display: flex;flex-direction: row; flex-wrap: wrap; margin-top: 20px;">
            <div formArrayName="admin" *ngFor="
              let field of adminControl.controls;
              let index = index
            ">
              <span class="text-items list-ui" [formGroupName]="index">{{
                field.get("name").value }}
                <img class="ml-3" (click)="deleteAdmin(index)" src="./assets/images/cancel.png" width="20px"
                  height="20px" /></span>
            </div>
          </div>

        </div>
      </div>
      <hr />
    </form>
    <button class="btn btn-add" *ngIf="!isUserDetailsEdited" (click)="addUserDetails()">Add</button>
    <button class="btn btn-add" *ngIf="isUserDetailsEdited" (click)="updateUserDetails()">Update</button>
    <button class="btn btn-clear" (click)="ClearUserDetailForm()">Clear</button>

  </div>
</div>
<div class="card my-3 w-100" *ngIf="userDetails?.length != 0">
  <div class="card-header flexi-header">
    <h3>SELECTED INFORMATION </h3>
  </div>

  <div class="card-body">
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>
              BUSINESS UNIT
            </th>
            <th>
              APPOINTMENTS
            </th>
            <th>
              SELECTED USERS
            </th>
            <th *ngIf="isAdminView">
              SELECTED ADMINISTRATOR
            </th>
            <th scope="col">ACTION</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of userDetails; let index = index">
            <td>
              <ul *ngFor="let buUnit of item.businessUnits">
                <li *ngIf ="item.allBuToken == ''">{{buUnit.orgName}}</li>
              </ul>
              <ul *ngIf="item.allBuToken == true">
                <li>All Business Units Selected ({{item.allBuTokenStaffId}})</li>
              </ul>
              <ul *ngFor="let buUnit of item.businessUnits; let len = count">
                <li *ngIf="item.allBuToken == false">{{buUnit.name}}</li>
              </ul>
            </td>

            <td>
              <ul *ngFor="let item of item.appointments">
                <li> {{item.name}}</li>
              </ul>
            </td>
            <td>
              <ul *ngFor="let item of item.user">
                <li> {{item.name}}</li>
              </ul>
            </td>
            <td *ngIf="isAdminView">
              <ul *ngFor="let current of item.admin">
                <li *ngIf="item.firstAdminAddedAsDefault == true">
                  <span *ngIf="current.staffId !== 'admin001'">{{current.name}}</span>
                  </li>
                <li *ngIf="item?.firstAdminAddedAsDefault == false || !item?.firstAdminAddedAsDefault"> {{current.name}}</li>
              </ul>
            </td>

            <td>
              <button class="btn btn-add p-2 m-1" [disabled]="isUserDetailsEdited"
                (click)="editUserDetail(item, index)"> <i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>
              <button class="btn btn-clear p-2 m-1" (click)="deleteUserDetail(index)" [disabled]="isUserDetailsEdited">
                <i class="fa fa-trash" aria-hidden="true"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
