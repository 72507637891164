import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription, of } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  map,
  debounceTime,
  distinctUntilChanged,
  mergeMap,
  delay,
  finalize,
} from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import {
  SortableHeader,
  SortEvent,
} from 'src/app/shared/directive/sortable.directive';
import { NotificationService } from 'src/app/users/service/notification/notification.service';

@Component({
  selector: 'app-pushed',
  templateUrl: './pushed.component.html',
  styleUrls: ['./pushed.component.scss'],
})
export class PushedComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  ajax: DataTables.AjaxMethods;
  dtTrigger: Subject<any> = new Subject();

  @Output() pushView = new EventEmitter<any>();

  @ViewChild('content', { read: TemplateRef }) content: TemplateRef<any>;

  schedulePTable;
  isLoading: boolean = false;
  isTableLoading: boolean = true;
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  public scheduleSearchInput = new Subject<KeyboardEvent>();
  @Output() scheduleView = new EventEmitter<any>();

  @Input() buId: string = null;
  private scheduleSearchSubscription: Subscription;

  constructor(
    private notificationService: NotificationService,
    private modalService: NgbModal,
    private toastService: ToastrService
  ) {
    this.schedulePTable = {
      sortWith: 'createdBy',
      sortBy: 'desc',

      data: [],
      page: 1,
      limit: 10,
      search: '',

    };
    // this.companyName = this.storageService.getByKey('companyId');

    this.scheduleSearchSubscription = this.scheduleSearchInput
      .pipe(
        map((event) => {
          const target = event.target as HTMLTextAreaElement;
          return target.value;
        }),
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap((search) => of(search).pipe(delay(500)))
      )
      .subscribe((input) => {
        this.schedulePTable.page = 1;
        this.loadSchedule();
      });
  // this.route.params.subscribe(params => {
  //   this.ngOnInit(); // reset and set based on new parameter this time
  // });
  }

  onSortSchedule({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.schedulePTable.sortWith = !!direction ? column : 'lastFinishedAt';
    this.schedulePTable.sortBy = !!direction ? direction : 'desc';
    this.loadSchedule();
  }

  ngOnInit(): void {
    this.loadSchedule();
  }

  ngOnDestroy(): void {
    this.scheduleSearchSubscription && this.scheduleSearchSubscription.unsubscribe();
  }

  onPageChangeSchedule(page: number) {
    this.schedulePTable.page = page;
    this.loadSchedule();
  }

  loadSchedule() {
    const { data, loading, total, ...rest } = this.schedulePTable;
    const body = {
      timeZone: 'GMT+0530',
      buId: this.buId,
    };
    const subscribe = this.notificationService
      .getPushNotification(body, rest)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const { totalRecords, data } = response;
          this.schedulePTable.data = data;
          this.schedulePTable.total = totalRecords;
          this.isTableLoading = false;
        },
        (error) => {
          this.schedulePTable.data = [];
          this.schedulePTable.total = 0;
          this.toastService.error('No list found');
        }
      );
  }

  onLimitChangeSchedule() {
    this.loadSchedule();
  }

  onViewSchedule(notificationId) {
    this.pushView.emit({ notificationId, isView: true });
  }

  onEditSchedule(notificationId, status, from) {
    if (from === 'edit') {
      this.pushView.emit({ notificationId, isView: false });
    } else {
      this.modalService
        .open(this.content, { ariaLabelledBy: 'modal-basic-title' })
        .result.then(
          (result) => {
            if (result === 1) {
              const data = { id: notificationId };
              this.notificationService.cancelNotification(data).subscribe(
                (response) => {
                  if (response.success) {
                    this.toastService.success(response.msg);
                  } else {
                    this.toastService.error(response.msg);
                  }
                  this.loadSchedule();
                },
                (error) => {
                  if (error.error) {
                    this.toastService.error(error.error.msg);
                  } else {
                    this.toastService.error('Something went wrong');
                  }
                }
              );
            }
          },
          (reason) => {}
        );
    }
  }
}
