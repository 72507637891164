<div class="row">
  <div *ngIf="addPhotoIdLoader" style="position: fixed;top: 0;right: 0;bottom: 0;left: 0;z-index: 1050;background-color: rgba(0, 0, 0, 0.5);overflow: auto;">
    <div class="pageLoading" style="text-align: center;color: white;font-size: 450%;">
        <div class="innerDiv">
            <i class="fa fa-spinner fa-spin" style="float: none;"></i> Please wait, data is updating....
        </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="form-group">
      <label class="font-weight-500"> Select Staff ID/Name</label>
      <input
        type="text"
        class="form-control"
        [value]="userFacial.userInfo.name"
        readonly
      />
    </div>
  </div>
</div>
<hr />
<div class="row">
  <div class="col-md-4">
    <h5 class="font-weight-500 m-0">
      {{ userFacial.userInfo?.name }}
    </h5>
    <p class="text-muted">{{ userFacial?.userInfo?.staffId }}</p>
  </div>
  <div class="col-md-8">
    <button class="btn btn-info btn-lg" (click)="toggleCamera()">
      {{ showWebcam ? "Switch OFF Camera" : "Switch ON Camera" }}
    </button>
  </div>
  <div class="col-md-4">
    <img
      [src]="userFacialImgUrl"
      class="img-fluid border rounded mb-2 img-shot"
      alt=""
    />
    <div class="form-group">
      <label class="font-weight-500">Staff ID</label>
      <input
        type="text"
        class="form-control"
        [value]="userFacial?.userInfo?.staffId"
        readonly
      />
    </div>
    <div class="form-group">
      <label class="font-weight-500">Phone no.</label>
      <input
        type="text"
        class="form-control"
        value="{{
          userFacial?.userInfo?.contactNumber
            ? userFacial.userInfo.contactNumber
            : '-'
        }}"
        readonly
      />
    </div>
  </div>
  <div class="col-md-8">
    <div class="align-items-center d-flex flex-column border rounded p-3 h-100">
      <webcam
        [trigger]="triggerObservable"
        (imageCapture)="handleImage($event)"
        [allowCameraSwitch]="allowCameraSwitch"
        *ngIf="showWebcam"
        [imageQuality]="0.9"
        (initError)="handleInitError($event)"
        [width]="500"
        [height]="340"
      >
      </webcam>
      <img
        #snapCam
        *ngIf="!showWebcam"
        [src]="capturedImg"
        class="img-fluid border rounded mb-2"
        alt=""
        width="500"
        height="320"
      />
      <div class="d-flex justify-content-center" *ngIf="actionbtn">
        <button class="btn btn-info" (click)="triggerSnapshot()">
          Capture
        </button>
        <button class="btn btn-success ml-2" (click)="addPhotoId()">
          Add Photo ID
        </button>
        <button class="btn btn-danger ml-2" (click)="navigateToOverview()">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
