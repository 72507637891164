import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-company-setup',
  templateUrl: './company-setup.component.html',
  styleUrls: ['./company-setup.component.scss']
})
export class CompanySetupComponent implements OnInit {
  activeId: number = 1
  
  constructor() { }

  ngOnInit(): void {

    if (this.activeId == 1) {

    } else {

    }
  }

}
