import { Component, OnInit, QueryList, ViewChildren, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { of, Subject, Subscription } from 'rxjs';
import { finalize ,  debounceTime,
  delay,
  distinctUntilChanged,
  map,
  mergeMap, } from 'rxjs/operators';
import { tableParam } from 'src/app/core/model/table-param.model';
import { TableService } from 'src/app/core/service/table.service';
import { SortableHeader, SortEvent } from 'src/app/shared/directive/sortable.directive';
import { LogsService } from 'src/app/users/service/all-logs/logs.service';

@Component({
  selector: 'app-integration-logs',
  templateUrl: './integration-logs.component.html',
  styleUrls: ['./integration-logs.component.scss']
})
export class IntegrationLogsComponent implements OnInit {
  mastersUnitPTable: { sortWith: string; sortBy: string; data: any[]; page: number; total: number; limit: number; search: string; };
  usersUnitTable: { sortWith: string; sortBy: string; data: any[]; page: number; total: number; limit: number; search: string; };
  lbsQuotaUnitTable:{ sortWith: string; sortBy: string; data: any[]; page: number; total: number; limit: number; search: string; };
  lbsApprovedUnitTable:{ sortWith: string; sortBy: string; data: any[]; page: number; total: number; limit: number; search: string; };
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  userTableColumn=['company.name','status','noOfNewUsers','noOfUpdatedUsers','faildUpdateUsers','createdAt','errorFilePath'];
  lbsQuotaTableColumn=['company.name','status','noOfNewUsers','noOfUpdatedUsers','faildUpdateUsers','createdAt','errorFilePath'];
  lbsApprovedTableColumn=['company.name','status','noOfNewUsers','noOfUpdatedUsers','faildUpdateUsers','createdAt','errorFilePath'];
  userTableQueryParams=JSON.parse(JSON.stringify(tableParam));
  lbsQuotaTableQueryParams=JSON.parse(JSON.stringify(tableParam));
  lbsApprovedTableQueryParams=JSON.parse(JSON.stringify(tableParam));
  isUserTableLoading:boolean=false;
  lbsquotaTableLoading:boolean=false;
  lbsApprovedTableLoading:boolean=false;
  public UserssearchInput = new Subject<KeyboardEvent>();
  private UsersSearchSubscription: Subscription;
  public lbsQuotasearchInput = new Subject<KeyboardEvent>();
  private lbsQuotaSubscription: Subscription;
  public lbsApprovedsearchInput = new Subject<KeyboardEvent>();
  private lbsApprovedSubscription: Subscription;
  constructor(
    private logsService: LogsService,
    public toastService: ToastrService,
    public tableService:TableService,
    private sanitizer: DomSanitizer

  ) {
    this.UsersSearchSubscription = this.UserssearchInput.pipe(map((event) => {
        const target = event.target as HTMLTextAreaElement;
        return target.value;
      }),
      debounceTime(500),
      distinctUntilChanged(),
      mergeMap((search) => of(search).pipe(delay(500)))
    ).subscribe((input) => {
      this.usersUnitTable.page = 1;
      this.userTableQueryParams.start=0;
      this.userTableQueryParams.search.value=input;
      this.loadUserLogs();
    });
    this.lbsQuotaSubscription = this.lbsQuotasearchInput.pipe(map((event) => {
      const target = event.target as HTMLTextAreaElement;
      return target.value;
    }),
    debounceTime(500),
    distinctUntilChanged(),
    mergeMap((search) => of(search).pipe(delay(500)))
  ).subscribe((input) => {
    this.lbsQuotaUnitTable.page = 1;
    this.lbsQuotaTableQueryParams.start=0;
    this.lbsQuotaTableQueryParams.search.value=input;
    this.loadLbsQuota();
  });
  this.lbsApprovedSubscription = this.lbsApprovedsearchInput.pipe(map((event) => {
    const target = event.target as HTMLTextAreaElement;
    return target.value;
  }),
  debounceTime(500),
  distinctUntilChanged(),
  mergeMap((search) => of(search).pipe(delay(500)))
).subscribe((input) => {
  this.lbsApprovedUnitTable.page = 1;
  this.lbsApprovedTableQueryParams.start=0;
  this.lbsApprovedTableQueryParams.search.value=input;
  this.loadLbsApproved();
});
  }
  isTableLoading: boolean = true;
  ngOnInit(): void {
    this.mastersUnitPTable = {
      sortWith: 'createdAt',
      sortBy: 'desc',
      data: [],
      page: 1,
      total: 0,
      limit: 10,
      search: '',
    };
    this.usersUnitTable = {
      sortWith: 'createdAt',
      sortBy: 'desc',
      data: [],
      page: 1,
      total: 0,
      limit: 10,
      search: '',
    };
    this.lbsQuotaUnitTable={
      sortWith: 'createdAt',
      sortBy: 'desc',
      data: [],
      page: 1,
      total: 0,
      limit: 10,
      search: '',
    }
    this.lbsApprovedUnitTable={
      sortWith: 'createdAt',
      sortBy: 'desc',
      data: [],
      page: 1,
      total: 0,
      limit: 10,
      search: '',
    }
    this.loadMasterLogs();
    this.loadUserLogs();
    this.loadLbsQuota();
    this.loadLbsApproved();
  }
  onSortMaster({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.mastersUnitPTable.sortBy =  direction  ;
    this.mastersUnitPTable.sortWith = column  ;
   direction && this.loadMasterLogs();
  }

  onPageChangeMaster(page: number) {
    this.mastersUnitPTable.page = page;
    this.loadMasterLogs();
  }
  onLimitChangeMaster() {
    this.loadMasterLogs();
  }
  onSortUsers({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.usersUnitTable.sortBy =  direction  ;
    this.usersUnitTable.sortWith = column  ;
   direction && this.loadUserLogs();


  }

  onPageChangeUsers(page: number) {
    this.usersUnitTable.page = page;
    this.userTableQueryParams.start= +page > 1 ? (page-1)* this.usersUnitTable.limit:0;
    this.loadUserLogs();
  }
  onLimitChangeUsers() {
    this.usersUnitTable.page = 1;
    this.userTableQueryParams.start=0;
    this.loadUserLogs();
  }

  onLimitLbsQuotaUsers() {
    this.lbsQuotaUnitTable.page = 1;
    this.lbsQuotaTableQueryParams.start=0;
    this.loadLbsQuota();
  }

  onLimitLbsApprovedUsers() {
    this.lbsApprovedUnitTable.page = 1;
    this.lbsApprovedTableQueryParams.start=0;
    this.loadLbsApproved();
  }
  onPageChangeLbsApproved(page: number) {
    this.lbsApprovedUnitTable.page = page;
    this.lbsApprovedTableQueryParams.start= +page > 1 ? (page-1)* this.lbsApprovedUnitTable.limit:0;
    this.loadLbsApproved();
  }
  onPageChangeLbsQuota(page: number) {
    this.lbsQuotaUnitTable.page = page;
    this.lbsQuotaTableQueryParams.start= +page > 1 ? (page-1)* this.lbsQuotaUnitTable.limit:0;
    this.loadLbsQuota();
  }

  loadMasterLogs() {
    // this.bussinesUnitPTable.isLoading = true;
    const { data, total, ...rest } = this.mastersUnitPTable;

    const subscribe = this.logsService
      .getMasterIntegratinLogs(rest)
      .pipe(
        finalize(() => {
          // this.bussinesUnitPTable.isLoading = false;
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const { count, data } = response.data;
          this.mastersUnitPTable.data = data;
          this.isTableLoading = false;
          this.mastersUnitPTable.total = count;
        },
        (error) => {
          this.mastersUnitPTable.data = [];
          this.mastersUnitPTable.total = 0;
          this.toastService.error('No list found');
        }
      );
  }

  loadUserLogs() {
    let tableCol:any = this.tableService.initializeTableParamsColums(this.userTableColumn, [0,1,2,3,4,5,6],[0,1,2,3,4,5,6]) || [];
    this.userTableQueryParams.columns=tableCol;
    this.userTableQueryParams.draw++;
    this.userTableQueryParams.length=this.usersUnitTable.limit;
    this.isUserTableLoading=true;
    const subscribe = this.logsService
      .getUsersIntegratinLogs(this.userTableQueryParams)
      .pipe(
        finalize(() => {
          // this.bussinesUnitPTable.isLoading = false;
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          //
          const { recordsFiltered, data } = response;
          this.usersUnitTable.data = data;
          this.isUserTableLoading=false;
          this.usersUnitTable.total = recordsFiltered;
        },
        (error) => {
          this.usersUnitTable.data = [];
          this.usersUnitTable.total = 0;
          this.isUserTableLoading=false;
          this.toastService.error('No list found');
        }
      );
  }

  loadLbsQuota(){
    let tableCol:any = this.tableService.initializeTableParamsColums(this.lbsQuotaTableColumn, [0,1,2,3,4,5,6],[0,1,2,3,4,5,6]) || [];
    this.lbsQuotaTableQueryParams.columns=tableCol;
    this.lbsQuotaTableQueryParams.draw++;
    this.lbsQuotaTableQueryParams.length=this.lbsQuotaUnitTable.limit;
    this.lbsquotaTableLoading=true;
    const subscribe = this.logsService
      .getLbsQuotaIntegratinLogs(this.lbsQuotaTableQueryParams)
      .pipe(
        finalize(() => {
          // this.bussinesUnitPTable.isLoading = false;
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          //
          const { recordsFiltered, data } = response;
          this.lbsQuotaUnitTable.data = data;
          this.lbsquotaTableLoading=false;
          this.lbsQuotaUnitTable.total = recordsFiltered;
        },
        (error) => {
          this.lbsQuotaUnitTable.data = [];
          this.lbsQuotaUnitTable.total = 0;
          this.lbsquotaTableLoading=false;
          this.toastService.error('No list found');
        }
      );
  }

  loadLbsApproved(){
    let tableCol:any = this.tableService.initializeTableParamsColums(this.lbsApprovedTableColumn, [0,1,2,3,4,5,6],[0,1,2,3,4,5,6]) || [];
    this.lbsApprovedTableQueryParams.columns=tableCol;
    this.lbsApprovedTableQueryParams.draw++;
    this.lbsApprovedTableQueryParams.length=this.lbsApprovedUnitTable.limit;
    this.lbsApprovedTableLoading=true;
    const subscribe = this.logsService
      .getLbsApprovedIntegratinLogs(this.lbsApprovedTableQueryParams)
      .pipe(
        finalize(() => {
          // this.bussinesUnitPTable.isLoading = false;
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          //
          const { recordsFiltered, data } = response;
          this.lbsApprovedUnitTable.data = data;
          this.lbsApprovedTableLoading=false;
          this.lbsApprovedUnitTable.total = recordsFiltered;
        },
        (error) => {
          this.lbsApprovedUnitTable.data = [];
          this.lbsApprovedUnitTable.total = 0;
          this.lbsApprovedTableLoading=false;
          this.toastService.error('No list found');
        }
      );

  }

  downloadFile(url:any, fileName:any) {
    if(!url){
      this.toastService.error('Failed to download the file. Please try again.');
      return;
    }
    this.logsService.downloadFile(url).subscribe((response: Blob) => {
      const blobUrl = window.URL.createObjectURL(response);
      const link = document.createElement('a');
      link.href = blobUrl;
      const finalFileName = fileName.split('/').pop();
      link.download = finalFileName;
      link.click();
      window.URL.revokeObjectURL(blobUrl);
      this.toastService.success('File downloaded successfully!');
    }, 
    (error) => {
      this.toastService.error('Failed to download the file. Please try again.')
    });
  }

  safeUrl(url: string): SafeUrl {
    const safeUrl: SafeUrl = this.sanitizer.sanitize(SecurityContext.URL, url);
    return safeUrl ? this.sanitizer.bypassSecurityTrustUrl(safeUrl?.toString()) : null;
  }
}
