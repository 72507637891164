import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subject, Subscription, of } from 'rxjs';
import { map, debounceTime, distinctUntilChanged, mergeMap, delay, finalize } from 'rxjs/operators';
import { SortableHeader, SortEvent } from 'src/app/shared/directive/sortable.directive';
import { StorageService } from 'src/app/core/service/storage.service';

import { NotificationService } from 'src/app/users/service/notification/notification.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-acknowledged',
  templateUrl: './acknowledged.component.html',
  styleUrls: ['./acknowledged.component.scss']
})
export class AcknowledgedComponent implements OnInit {
  usersPTable
  // isBuUnitEdited:boolean = false
  // sectionId:string
  // subSectionId:string
  // bussinessUnitEditForm:FormGroup
  isTableLoading: boolean = true;
  notificationViewData
  public userssearchInput = new Subject<KeyboardEvent>();
  selectedIndex: number;
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  // companyName: string;
  /// bussiness unit over view table config
  onSortUsers({ column, direction }: SortEvent, field) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    // this.usersPTable.sort = { key: column, order: direction as any };
    this.usersPTable.sortWith = field;
    this.usersPTable.sortBy = direction;
    this.loadUsers();
  }

  onPageChangeUsers(page: number) {
    this.usersPTable.page = page;
    this.loadUsers();
  }
  onLimitChangeUsers() {
    this.loadUsers();
  }

  private usersSearchSubscription: Subscription;

  constructor(
    private notificationService: NotificationService,
    public toastService: ToastrService,
    private storageService: StorageService,
    private formBuilder: FormBuilder
  ) {
    // this.config.notFoundText = 'Custom not found';
    this.usersPTable = {
      sortBy: 'desc',
      sortWith: 'activeFrom',

      data: [],
      page: 1,
      limit: 10,
      search: '',

    };
    // this.companyName = this.storageService.getByKey('companyId');

    this.usersSearchSubscription = this.userssearchInput
      .pipe(
        map((event) => {
          const target = event.target as HTMLTextAreaElement;
          return target.value;
        }),
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap((search) => of(search).pipe(delay(500)))
      )
      .subscribe((input) => {
        this.usersPTable.page = 1;
        this.loadUsers();
      });
  }

  ngOnInit(): void {
    this.loadUsers();
  }

  onSelectNotification(item, index) {
    this.notificationViewData = item
    this.selectedIndex = index;
  }

  loadUsers() {
    const { data, total, ...rest } = this.usersPTable
    const subscribe = this.notificationService
      .getAcknowledgedNofications(rest)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const { count, data } = response.data;
          this.usersPTable.data = data;
          this.usersPTable.total = count;
          this.onSelectNotification(data[0], 0)
          this.isTableLoading = false;
        },
        (error) => {
          this.usersPTable.data = [];
          this.usersPTable.total = 0;
          this.toastService.error('No list found');
        }
      );
  }



  ngOnDestroy(): void {
    this.usersSearchSubscription && this.usersSearchSubscription.unsubscribe();
  }


}
