import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-post-logs',
  templateUrl: './post-logs.component.html',
  styleUrls: ['./post-logs.component.scss']
})
export class PostLogsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
