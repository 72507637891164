import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StorageService } from 'src/app/core/service/storage.service';
import { queryParam } from 'src/app/core/utils/helper';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GamificationService {

  private baseUrl: string;
  private get Headers(): HttpHeaders {
    return new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }
  constructor(
    private http: HttpClient,

  ) {
    this.baseUrl = environment.baseUrl;
  }

  getChallenges(data): Observable<any> {
    return this.http.get(`${this.baseUrl}/common/challenge/read?${queryParam(data)}`, {
      headers: this.Headers,

    })
    .pipe(map((res) => res as any))
  }

  getChallengesNew(data): Observable<any> {
    return this.http.get(`${this.baseUrl}/common/challenge/read/new?${queryParam(data)}`, {
      headers: this.Headers,

    })
    .pipe(map((res) => res as any))
  }

  getChallengesNewSingle(data): Observable<any> {
    return this.http.get(`${this.baseUrl}/common/challenge/read/new/${data}`, {
      headers: this.Headers,

    })
    .pipe(map((res) => res as any))
  }

  getLogs(data): Observable<any> {
    return this.http.get(`${this.baseUrl}/common/challenge/getChallengesLog?${queryParam(data)}`, {
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }
  getRedemptionList(businessUnitId, pageNumber, searchData): Observable<any> {
    return this.http.get(`${this.baseUrl}/common/redemption/rewardsHistory?businessUnit=${businessUnitId}&start=${pageNumber}&search=${searchData?.search}&sortWith=${searchData?.sortWith}&sortBy=${searchData?.sortBy}`, {
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }
  updateChallenges(data, id:string): Observable<any> {
    data.challenge._id=id;
    return this.http.post(`${this.baseUrl}/common/challenge/update` , data ,{
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }
  createChallenges(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/common/challenge/create` , data ,{
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }

  uploadFile(fileToUpload) {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return this.http.post(`${this.baseUrl}/pageSetting/uploadFiles`, formData);
  }
  uploadBadgeFile(fileToUpload) {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return this.http.post(`${this.baseUrl}/challenges/uploadBadge/`, formData);
  }

  uploadImageChallange(imageFile){
    return this.http.get(`${this.baseUrl}/uploads/challenge/${imageFile}`)
    .pipe(map((res) => res as any))
  }

  getRedemptionExport(bussinessUnit, timeZone){
    return this.http.get(`${this.baseUrl}/common/redemption/rewardsHistoryExport/?businessUnit=${bussinessUnit}&timeZone=${timeZone}`)
    .pipe(map((res) => res as any))
  }

  getOneChal(id: string):Observable<any> {
    return this.http.get(`${this.baseUrl}/challenges/${id}`)
    .pipe(map((res) => res as any))
  }
  getChallangesUsers(data):Observable<any> {
    return this.http.post(`${this.baseUrl}/common/challenge/getChallengeUsers`,data)
    .pipe(map((res) => res as any))
  }
  getBoardsAndChannels():Observable<any> {
    return this.http.get(`${this.baseUrl}/common/challenge/getChannelsAndBoards`)
    .pipe(map((res) => res as any))
  }

  getCountOfChallenges(data): Observable<any> {
    return this.http.get(`${this.baseUrl}/common/challenge/getCountOfChallenges?${queryParam(data)}`, {
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }
  getDisqualifierUserData(data): Observable<any> {
    return this.http.get(`${this.baseUrl}/common/challenge/readDisqualifier?${queryParam(data)}`, {
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }
  getNomineeQuestions(id): Observable<any> {
    return this.http.get(`${this.baseUrl}/common/challenge/getNomineeQuestions?customFormId=${id}`, {
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }
  getFieldsfromCustomForm(id): Observable<any> {
    return this.http.get(`${this.baseUrl}/common/challenge/customForm/fields/${id}?type=`, {
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }
  getManageChallenge(data): Observable<any> {
    return this.http.get(`${this.baseUrl}/common/challenge/manageChallenge?${queryParam(data)}`, {
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }
  getManageChallengeUsers(data): Observable<any> {
    return this.http.get(`${this.baseUrl}/challenges/getChallengesAndUsers/?${queryParam(data)}`, {
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }
  exportManageChallenge(data): Observable<any> {
    return this.http.get(`${this.baseUrl}/common/challenge/exportManageChallenge?${queryParam(data)}`, {
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }
  deleteDisqualifier(id): Observable<any> {
    return this.http.get(`${this.baseUrl}/common/challenge/deleteDisqualifier?_id=${id}`, {
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }
  readDisqualifier(data): Observable<any> {
    return this.http.get(`${this.baseUrl}/challenges/readDisqualifier/?${queryParam(data)}`, {
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }
  createDisqualifyUser(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/common/challenge/disqualifyUser` , data ,{
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }
  createDirectRewards(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/common/challenge/directRewards` , data ,{
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }

  downloadRedemptionFile(x:string): Observable<any> {
    return this.http.get(`${this.baseUrl}/uploads/reward/` + x, {
      responseType: "blob",
    });
  }

  bulkUpdateDirectReward(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/common/challenge/bulkUpdateDirectReward` , data ,{
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }

  getSchemeList(id): Observable<any> {
     return this.http.get(`${this.baseUrl}/scheme/` + id, {
      headers: this.Headers,

    }).pipe(map((res) => res as any))
  }
  importLogsPresent(id): Observable<any> {
      return this.http.get(`${this.baseUrl}/common/challenge/isRewardErrorLogExist/${id}`, {
       headers: this.Headers,

     }).pipe(map((res) => res as any))
  }

  getLogData(id): Observable<any> {
      return this.http.get(`${this.baseUrl}/common/challenge/getRewardErrorLog/${id}`, {
       headers: this.Headers,

     }).pipe(map((res) => res as any))
  }


}
