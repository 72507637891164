<div class="d-flex flex-column">
    <ul ngbNav [(activeId)]="activeId" #companyNav="ngbNav" class="nav-tabs rounded mb-3">
      <li [ngbNavItem]="1">
        <a ngbNavLink>OVERVIEW</a>
        <ng-template ngbNavContent>
          <app-question-builder-overview (overViewEvent)="overViewEvent($event)" (editModuleEvent)="EditModule($event)">
          </app-question-builder-overview>
        </ng-template>
      </li>
      <li [ngbNavItem]="2" (click)="!isEdited && createModulePopup()" *ngIf="!isEdited">
        <a ngbNavLink>CREATE</a>
        <ng-template ngbNavContent *ngIf="isEdited">
          <create-edit-module [moduleId]="moduleID" (overViewEvent)="overViewEvent($event)"></create-edit-module>
        </ng-template>
      </li>
      <li [ngbNavItem]="3" *ngIf="isEdited">
        <a ngbNavLink>EDIT</a>
        <ng-template ngbNavContent>
          <create-edit-module [moduleId]="moduleID" (overViewEvent)="overViewEvent($event)"></create-edit-module>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="companyNav"></div>
</div>

<ng-template #createModule let-c="close" let-d="dismiss">
  <div>
    <div class="modal-content">
      <div class="modal-body" style="padding: 0px;">
        <div class="card w-100">
          <div class="card-header flexi-header p-4">
            <h3>CREATE MODULE</h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6 col-lg-6">
                <form [formGroup]="createModuleForm">
                  <div class="form-group">
                    <label class="font-weight-bold" for="exampleInputEmail1">Module Name</label>
                    <input type="text" class="form-control" placeholder="Module Name" formControlName="moduleName" maxlength="100"/>
                  </div>
                </form>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="pull-right">
                  <button class="btn btn-add m-1" [disabled]="!createModuleForm.valid" (click)="onCreateModule()">
                    Add
                  </button>
                  <button class="btn btn-clear m-1" (click)="closePopup()">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>