import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { HeaderComponent } from './layout/header/header.component';
import { DesignComponent } from './layout/design/design.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { HomeComponent } from './pages/home/home.component';
import { BussinessUnitComponent } from './pages/system-settings/bussiness-unit/bussiness-unit.component';
import { AuthGuard } from './guards/auth.guard';
import { AuthService } from './service/auth/auth.service';
import { UserRolesComponent } from './pages/system-settings/user-roles/user-roles.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BussinessUnitOverviewComponent } from './pages/system-settings/bussiness-unit/bussiness-unit-overview/bussiness-unit-overview.component';
import { ImageCropperModule } from 'ngx-image-cropper';

import { PasswordComponent } from './pages/system-settings/company-setup/password/password.component';
import { CompanySetupComponent } from './pages/system-settings/company-setup/company-setup.component';
import { CustomFieldsComponent } from './pages/system-settings/custom-fields/custom-fields.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

import { DpDatePickerModule } from 'ng2-date-picker';
import { ForgetPasswordComponent } from './pages/auth/forget-password/forget-password.component';
import { VerifyUserComponent } from './pages/auth/verify-user/verify-user.component';
import { UsersOverviewComponent } from './pages/users/create-users/users-overview/users-overview.component';

import { UserDetailsComponent } from './pages/users/view-users/user-details/user-details.component';
import { ViewUsersComponent } from './pages/users/view-users/view-users.component';
import { CreateUsersComponent } from './pages/users/create-users/create-users.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { ChannelSetupComponent } from './pages/communication/channel-setup/channel-setup.component';
import { ChannelOverviewComponent } from './pages/communication/channel-setup/channel-overview/channel-overview.component';
import { OverviewComponent } from './pages/central-builder/question-builder/overview/overview.component';
import { MyFormsComponent } from './pages/central-builder/my-forms/my-forms.component';
import { FormsComponent } from './pages/central-builder/forms/forms.component';
import { QuestionModalComponent } from './pages/central-builder/question-builder/question-modal/question-modal.component';
import { CreateEditModuleComponent } from './pages/central-builder/question-builder/create-edit-module/create-edit-module.component';
import { FormsOverviewComponent } from './pages/central-builder/forms/forms-overview/forms-overview.component';
import { LogsOverviewComponent } from './pages/central-builder/forms/logs-overview/logs-overview.component';
import { CreateEditFormsComponent } from './pages/central-builder/forms/create-edit-forms/create-edit-forms.component';
import { HomePageComponent } from './pages/system-settings/company-setup/home-page/home-page.component';
import { UsefullLinkComponent } from './pages/system-settings/company-setup/usefull-link/usefull-link.component';
import { FormManageComponent } from './pages/central-builder/forms/form-manage/form-manage.component';
import { MyFormsOverviewComponent } from './pages/central-builder/my-forms/my-forms-overview/my-forms-overview.component';
import { MyNotificationComponent } from './pages/communication/notification/my-notification/my-notification.component';
import { ManageNotificationComponent } from './pages/communication/notification/manage-notification/manage-notification.component';
import { CreateEditNotificationComponent } from './pages/communication/notification/manage-notification/create-edit-notification/create-edit-notification.component';
import { NotificationOverviewComponent } from './pages/communication/notification/manage-notification/notification-overview/notification-overview.component';
import { QuestionBuilderComponent } from './pages/central-builder/question-builder/question-builder.component';
import { UnreadComponent } from './pages/communication/notification/my-notification/unread/unread.component';
import { AcknowledgedComponent } from './pages/communication/notification/my-notification/acknowledged/acknowledged.component';
import { ViewNotificationComponent } from './pages/communication/notification/manage-notification/view-notification/view-notification.component';
import { BoardSetupComponent } from './pages/communication/board-setup/board-setup.component';
import { BoardsOverviewComponent } from './pages/communication/board-setup/boards-overview/boards-overview.component';
import { QuestionnaireComponent } from './pages/communication/board-setup/questionnaire/questionnaire.component';
import { ReportedCommentsComponent } from './pages/communication/board-setup/reported-comments/reported-comments.component';
import { ReportedPostsComponent } from './pages/communication/board-setup/reported-posts/reported-posts.component';
import { CreateEditBoardsComponent } from './pages/communication/board-setup/create-edit-boards/create-edit-boards.component';
import { EmojiComponentComponent } from './pages/communication/board-setup/emoji-component/emoji-component.component';
import { ManageNewsComponent } from './pages/communication/manage-news/manage-news.component';
import { NewsOverviewComponent } from './pages/communication/manage-news/news-overview/news-overview.component';
import { CreateEditManagenewsComponent } from './pages/communication/manage-news/create-edit-managenews/create-edit-managenews.component';
import { NewsReportedCommentsComponent } from './pages/communication/manage-news/news-reported-comments/news-reported-comments.component';
import { NewsReportedPostsComponent } from './pages/communication/manage-news/news-reported-posts/news-reported-posts.component';
import { ViewBoardComponent } from './pages/communication/board-setup/boards-overview/view-board/view-board.component';
import { ManageEventsComponent } from './pages/communication/manage-events/manage-events.component';
import { CreateEditEventsComponent } from './pages/communication/manage-events/create-edit-events/create-edit-events.component';
import { EventsOverviewComponent } from './pages/communication/manage-events/events-overview/events-overview.component';
import { EventsReportedCommentsComponent } from './pages/communication/manage-events/events-reported-comments/events-reported-comments.component';
import { EventsReportedPostComponent } from './pages/communication/manage-events/events-reported-post/events-reported-post.component';
import { NewsViewComponent } from './pages/communication/manage-news/news-view/news-view.component';
import { EventsViewComponent } from './pages/communication/manage-events/events-view/events-view.component';
import { ChallengesComponent } from './pages/gamification/challenges/challenges.component';
import { AddEditChallengesComponent } from './pages/gamification/challenges/add-edit-challenges/add-edit-challenges.component';
import { MyNewsAndEventsComponent } from './pages/communication/my-news-and-events/my-news-and-events.component';
import { QuickLinksComponent } from './pages/system-settings/company-setup/quick-links/quick-links.component';
import { ManageChallengesComponent } from './pages/gamification/challenges/challenges-overview/manage-challenges/manage-challenges.component';
import { ChallengesLogComponent } from './pages/gamification/challenges/challenges-log/challenges-log.component';
import { ChallengesOverviewComponent } from './pages/gamification/challenges/challenges-overview/challenges-overview.component';
import { RedemptionListComponent } from './pages/gamification/Redemption-List/Redemption-List.component';
import { AddEditUsersComponent } from './pages/users/create-users/add-edit-users/add-edit-users.component';
import { UserRoutingModule } from './users-routing.module';

import { ViewChallangesComponent } from './pages/gamification/challenges/challenges-overview/view-challanges/view-challanges.component';
import 'froala-editor/js/froala_editor.min.js';
import 'froala-editor/js/plugins.pkgd.min.js';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { FacialIdComponent } from './pages/admin/users/facial-id/facial-id.component';
import { OpsAddStaffComponent } from './pages/admin/users/ops-group/ops-add-staff/ops-add-staff.component';
import { OpsGroupComponent } from './pages/admin/users/ops-group/ops-group.component';
import { OpsOverviewComponent } from './pages/admin/users/ops-group/ops-overview/ops-overview.component';
import { CreateBallotComponent } from './pages/human-resource/leave-balloting/create-ballot/create-ballot.component';
import { LeaveBallotingComponent } from './pages/human-resource/leave-balloting/leave-balloting.component';
import { ManageBallotComponent } from './pages/human-resource/leave-balloting/manage-ballot/manage-ballot.component';
import { ApproveComponent } from './pages/human-resource/leave-planner/approve/approve.component';
import { DashboardComponent } from './pages/human-resource/leave-planner/dashboard/dashboard.component';
import { LeavePlannerComponent } from './pages/human-resource/leave-planner/leave-planner.component';
import { OpsLeaveQuotaComponent } from './pages/human-resource/leave-planner/ops-leave-quota/ops-leave-quota.component';
import { SwapRestrictionsComponent } from './pages/human-resource/leave-planner/swap-restrictions/swap-restrictions.component';
import { LeaveGrpOverviewComponent } from './pages/human-resource/leave-scheme/leave-grp-overview/leave-grp-overview.component';
import { LeaveOverviewComponent } from './pages/human-resource/leave-scheme/leave-overview/leave-overview.component';
import { LeaveSchemeComponent } from './pages/human-resource/leave-scheme/leave-scheme.component';
import { ListBookingComponent } from './pages/operations/report/list-booking/list-booking.component';
import { ListCancellationComponent } from './pages/operations/report/list-cancellation/list-cancellation.component';
import { ListShiftComponent } from './pages/operations/report/list-shift/list-shift.component';
import { ListUserComponent } from './pages/operations/report/list-user/list-user.component';
import { BuShiftSetupOverviewComponent } from './pages/operations/setup/bu-shift-setup/bu-shift-setup-overview/bu-shift-setup-overview.component';
import { BuShiftSetupComponent } from './pages/operations/setup/bu-shift-setup/bu-shift-setup.component';
import { ShiftSchemeEditComponent } from './pages/operations/setup/shift-scheme/shift-scheme-edit/shift-scheme-edit.component';
import { ShiftSchemeLogsComponent } from './pages/operations/setup/shift-scheme/shift-scheme-logs/shift-scheme-logs.component';
import { ShiftSchemeOverviewComponent } from './pages/operations/setup/shift-scheme/shift-scheme-overview/shift-scheme-overview.component';
import { ShiftSchemeComponent } from './pages/operations/setup/shift-scheme/shift-scheme.component';
import { UserShiftSetupOverviewComponent } from './pages/operations/setup/user-shift-setup/user-shift-setup-overview/user-shift-setup-overview.component';
import { UserShiftSetupComponent } from './pages/operations/setup/user-shift-setup/user-shift-setup.component';
import { ManageShiftComponent } from './pages/operations/shift-scheduler/manage-shift/manage-shift.component';
import { ManageComponent } from './pages/operations/shift-scheduler/manage-shift/manage/manage.component';
import { StatusComponent } from './pages/operations/shift-scheduler/manage-shift/status/status.component';
import { AssignShiftComponent } from './pages/operations/shift-scheduler/plan-shift/assign-shift/assign-shift.component';
import { LogAssignComponent } from './pages/operations/shift-scheduler/plan-shift/log-assign/log-assign.component';
import { LogOpenComponent } from './pages/operations/shift-scheduler/plan-shift/log-open/log-open.component';
import { OpenShiftComponent } from './pages/operations/shift-scheduler/plan-shift/open-shift/open-shift.component';
import { PlanOverviewComponent } from './pages/operations/shift-scheduler/plan-shift/plan-overview/plan-overview.component';
import { PlanShiftHeaderComponent } from './pages/operations/shift-scheduler/plan-shift/plan-shift-header/plan-shift-header.component';
import { PlanShiftComponent } from './pages/operations/shift-scheduler/plan-shift/plan-shift.component';
import { StaffingComponent } from './pages/operations/shift-scheduler/plan-shift/staffing/staffing.component';
import { AttendanceComponent } from './pages/operations/shift-scheduler/timesheet/attendance/attendance.component';
import { TimesheetDataComponent } from './pages/operations/shift-scheduler/timesheet/timesheet-data/timesheet-data.component';
import { TimesheetLookupComponent } from './pages/operations/shift-scheduler/timesheet/timesheet-lookup/timesheet-lookup.component';
import { TimesheetOverviewComponent } from './pages/operations/shift-scheduler/timesheet/timesheet-overview/timesheet-overview.component';
import { TimesheetComponent } from './pages/operations/shift-scheduler/timesheet/timesheet.component';
import { BookingComponent } from './pages/operations/shift-scheduler/view-booking/booking/booking.component';
import { ViewBookingOverviewComponent } from './pages/operations/shift-scheduler/view-booking/view-booking-overview/view-booking-overview.component';
import { ViewBookingComponent } from './pages/operations/shift-scheduler/view-booking/view-booking.component';
import { ViewShiftComponent } from './pages/operations/shift-scheduler/view-shift/view-shift.component';
import { LockAccountOverviewComponent } from './pages/users/locked-account/lock-account-overview/lock-account-overview.component';
import { LockedAccountComponent } from './pages/users/locked-account/locked-account.component';
import { ResetPasswordComponent } from './pages/users/reset-password/reset-password.component';
import { RestPasswordOverviewComponent } from './pages/users/reset-password/rest-password-overview/rest-password-overview.component';
import { IntegrationLogsComponent } from './pages/system-settings/integration-logs/integration-logs.component';
import { LogsComponent } from './pages/communication/news&events-logs/logs.component';
import { PostLogsComponent } from './pages/communication/news&events-logs/post-logs/post-logs.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ChartsModule } from 'ng2-charts';
import { ManageOverviewComponent } from './pages/operations/shift-scheduler/manage-shift/manage-overview/manage-overview.component';
import { TermsAndConditionsComponent } from './pages/auth/terms-and-conditions/terms-and-conditions.component';
import { SupportComponent } from './pages/auth/support/support.component';
import { MyPageComponent } from './pages/system-settings/company-setup/my-page/my-page.component';
import { PointSystemComponent } from './pages/system-settings/company-setup/point-system/point-system.component';
import { OthersComponent } from './pages/system-settings/company-setup/others/others.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { OpsEditComponent } from './pages/admin/users/ops-group/ops-edit/ops-edit.component';
import { EventsManageComponent } from './pages/communication/manage-events/events-manage/events-manage.component';
import { LeavemngSkillsetComponent } from './pages/system-settings/company-setup/leavemng-skillset/leavemng-skillset.component';

import { NotificationComponent } from './pages/notification/notification.component';
import { ScheduleComponent } from './pages/notification/schedule/schedule.component';
import { PushedComponent } from './pages/notification/pushed/pushed.component';
import { CreateComponent } from './pages/notification/create/create.component';
import { DataTablesModule } from 'angular-datatables';

import { ViewBallotComponent } from './pages/human-resource/leave-balloting/view-ballot/view-ballot.component';
import { AddMapSetupComponent } from './pages/operations/setup/bu-shift-setup/add-map-setup/add-map-setup.component';
import { AgmCoreModule } from '@agm/core';
import { FacialOverviewComponent } from './pages/admin/users/facial-id/facial-overview/facial-overview.component';
import { WebcamModule } from 'ngx-webcam';
import { FacialEditComponent } from './pages/admin/users/facial-id/facial-edit/facial-edit.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ViewFormsComponent } from './pages/central-builder/forms/view-forms/view-forms.component';
import { MyFormsViewComponent } from './pages/central-builder/my-forms/my-forms-view/my-forms-view.component';
import {SignatureQuestionsComponent} from './pages/central-builder/my-forms/signature-questions/signature-questions.component';
import { ViewComponent } from './pages/central-builder/my-forms/view/view.component';
import { PasswordExpiredComponent } from './pages/auth/password-expired/password-expired.component';
import { FormManageEditComponent } from './pages/central-builder/forms/form-manage/form-manage-edit/form-manage-edit.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { AdminQuestionFormComponent } from './pages/central-builder/forms/form-manage/admin-question-form/admin-question-form.component';
import { WelcomepageComponent } from './pages/auth/welcomepage/welcomepage.component';
import { authInterceptorProviders } from '../shared/token-interceptor/auth-interceptor.service';

@NgModule({
  declarations: [
    LoginComponent,
    SidebarComponent,
    HeaderComponent,
    HomeComponent,
    DesignComponent,
    BussinessUnitComponent,
    UserRolesComponent,
    BussinessUnitOverviewComponent,
    PasswordComponent,
    CompanySetupComponent,
    CustomFieldsComponent,
    ViewUsersComponent,
    CreateUsersComponent,
    UsersOverviewComponent,
    LockAccountOverviewComponent,
    LockedAccountComponent,
    RestPasswordOverviewComponent,
    ResetPasswordComponent,
    ForgetPasswordComponent,
    UserDetailsComponent,
    VerifyUserComponent,
    UserProfileComponent,
    ChannelSetupComponent,
    ChannelOverviewComponent,
    QuestionBuilderComponent,
    AddEditUsersComponent,
    OverviewComponent,
    MyFormsComponent,
    FormsComponent,
    QuestionModalComponent,
    CreateEditModuleComponent,
    FormsOverviewComponent,
    LogsOverviewComponent,
    CreateEditFormsComponent,
    HomePageComponent,
    QuickLinksComponent,
    UsefullLinkComponent,
    FormManageComponent,
    MyFormsOverviewComponent,
    MyNotificationComponent,
    ManageNotificationComponent,
    CreateEditNotificationComponent,
    NotificationOverviewComponent,
    UnreadComponent,
    AcknowledgedComponent,
    ViewNotificationComponent,
    BoardSetupComponent,
    BoardsOverviewComponent,
    QuestionnaireComponent,
    ReportedCommentsComponent,
    ReportedPostsComponent,
    CreateEditBoardsComponent,
    EmojiComponentComponent,
    ManageNewsComponent,
    NewsOverviewComponent,
    CreateEditManagenewsComponent,
    NewsReportedCommentsComponent,
    NewsReportedPostsComponent,
    ViewBoardComponent,
    ManageEventsComponent,
    CreateEditEventsComponent,
    EventsOverviewComponent,
    EventsReportedCommentsComponent,
    EventsReportedPostComponent,
    NewsViewComponent,
    EventsViewComponent,
    EventsManageComponent,
    ChallengesComponent,
    RedemptionListComponent,
    AddEditChallengesComponent,
    ChallengesOverviewComponent,
    ChallengesLogComponent,
    MyNewsAndEventsComponent,
    ManageChallengesComponent,
    LogsComponent,
    PostLogsComponent,
    ViewChallangesComponent,
    BuShiftSetupComponent,
    BuShiftSetupOverviewComponent,
    ShiftSchemeOverviewComponent,
    ShiftSchemeLogsComponent,
    ShiftSchemeEditComponent,
    ShiftSchemeComponent,
    UserShiftSetupComponent,
    UserShiftSetupOverviewComponent,
    PlanShiftComponent,
    OpenShiftComponent,
    PlanShiftHeaderComponent,
    LogAssignComponent,
    LogOpenComponent,
    AssignShiftComponent,
    StaffingComponent,
    PlanOverviewComponent,
    ManageShiftComponent,
    ManageComponent,
    StatusComponent,
    ViewShiftComponent,
    ViewBookingComponent,
    BookingComponent,
    ViewBookingOverviewComponent,
    TimesheetComponent,
    AttendanceComponent,
    TimesheetDataComponent,
    TimesheetLookupComponent,
    TimesheetOverviewComponent,
    ListShiftComponent,
    ListBookingComponent,
    ListCancellationComponent,
    ListUserComponent,
    LeaveSchemeComponent,
    LeaveOverviewComponent,
    LeaveGrpOverviewComponent,
    LeaveBallotingComponent,
    LeavePlannerComponent,
    CreateBallotComponent,
    ManageBallotComponent,
    DashboardComponent,
    ApproveComponent,
    SwapRestrictionsComponent,
    OpsLeaveQuotaComponent,
    FacialOverviewComponent,
    FacialIdComponent,
    OpsGroupComponent,
    OpsEditComponent,
    OpsOverviewComponent,
    OpsAddStaffComponent,
    IntegrationLogsComponent,
    ManageOverviewComponent,
    TermsAndConditionsComponent,
    SupportComponent,
    PointSystemComponent,
    MyPageComponent,
    OthersComponent,
    LeavemngSkillsetComponent,
    NotificationComponent,
    ScheduleComponent,
    PushedComponent,
    CreateComponent,
    ViewBallotComponent,
    AddMapSetupComponent,
    FacialEditComponent,
    ViewFormsComponent,
    MyFormsViewComponent,
    SignatureQuestionsComponent,
    ViewComponent,
    PasswordExpiredComponent,
    FormManageEditComponent,
    AdminQuestionFormComponent,
    WelcomepageComponent

  ],
  imports: [
    CommonModule,
    UserRoutingModule,
    NgbModule,
    FormsModule,
    SharedModule,
    NgSelectModule,
    DpDatePickerModule,
    ImageCropperModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    ChartsModule,
    ZXingScannerModule,
    WebcamModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB2_gxfxDXf2CC7Zeq9w3JpzTkydW_bn4Y',
      libraries: ['places'],
    }),
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    DataTablesModule,
    NgxSkeletonLoaderModule,
    FormsModule,
    ReactiveFormsModule,
    NgxQRCodeModule
  ],
  providers: [
    AuthService,
    AuthGuard,
    DatePipe,
    authInterceptorProviders
  ],
})
export class UsersModule { }
