import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StorageService } from 'src/app/core/service/storage.service';
import { queryParam } from 'src/app/core/utils/helper';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class NewsEventsService {
  private baseUrl: string;
  private get Headers(): HttpHeaders {
    return new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }
  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }

  uploadFile(fileToUpload) {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return this.http
      .post(`${this.baseUrl}/post/uploadFile/`, formData)
      .pipe(map((res) => res as any));
  }
  getAllNewsEventsOld(query): Observable<any> {
    return this.http
      .get(`${this.baseUrl}/common-post/myNewsAndEvents/?${queryParam(query)}`)
      .pipe(map((res) => res as any));
  }

  getAllNewsEvents(query): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/common/post/read`, query)
      .pipe(map((res) => res as any));
  }

  getAllNewsEventsOverview(companyId,data): Observable<any> {
    return this.http
      .get(`${this.baseUrl}/post/${companyId}?${queryParam(data)}`, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }
  getAllModules(): Observable<any> {
    return this.http
      .get(`${this.baseUrl}/central-builder/module/`, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  getnonLinkModules(): Observable<any> {
    return this.http
      .get(`${this.baseUrl}/centralBuilder/read?nonLinkedModules=true`, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }
  getAllReportedPosts(data, type): Observable<any> {
    return this.http
      .get(`${this.baseUrl}/post/reportedPosts/${type}?${queryParam(data)}`, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }
  getAllReportedComments(data, type): Observable<any> {
    return this.http
      .get(
        `${this.baseUrl}/post/reportedComments/${type}?${queryParam(data)}`,
        {
          headers: this.Headers,
        }
      )
      .pipe(map((res) => res as any));
  }
  getAllChannels(): Observable<any> {
    return this.http
      .get(`${this.baseUrl}/channel/getChannelsForAdmin/`, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }
  getAllCategories(id: string): Observable<any> {
    return this.http
      .get(`${this.baseUrl}/category/getChannelCategories/${id}`, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }
  getSingleNewsEvents(id: string): Observable<any> {
    return this.http
      .get(`${this.baseUrl}/post/one/${id}`, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  getSinglePostLog(data): Observable<any> {
    return this.http
      .get(`${this.baseUrl}/postLog/?${queryParam(data)}`, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  updateReportedPost(id: string, data) {
    return this.http
      .post(`${this.baseUrl}/post/updatereviewPost`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }
  updateReportedComment(data) {
    return this.http
      .post(`${this.baseUrl}/post/updateCommentStatus`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  updateNewsEvents(data, id: string= null): Observable<any> {
    if(id){
      return this.http
      .put(`${this.baseUrl}/post/one/${id}`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
    }
    return this.http
      .post(`${this.baseUrl}/post/update`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }
  updateReviewPost(data, id: string): Observable<any> {
    return this.http
      .put(`${this.baseUrl}/post/updatereviewPost/${id}`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }
  updateReviewComment(data, id: string): Observable<any> {
    return this.http
      .put(`${this.baseUrl}/post/updateCommentStatus/${id}`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }
  createNewsEvents(data): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/post/create`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  createEventsSessions(data): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/event/session/create`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  updateEventsSessions(data): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/event/session/editMultipleSessions`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  cancelSessions(id): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/event/session/cancelSession`, {_id: id}, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  getChannelForNewsAndEvents(): Observable<any> {
    return this.http
      .get(`${this.baseUrl}/common/post/getUserChannels/`, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  getCategoriesForNewsAndEvents(id: string): Observable<any> {
    return this.http
      .get(`${this.baseUrl}/common-post/getChannelCategories/${id}`, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  getReportedComments(): Observable<any> {
    return this.http.get(`${this.baseUrl}/post/reportedComments/event`, {
      headers: this.Headers,
    });
  }
  //

  getSessionDetail(data) {
    return this.http.post(
      `${this.baseUrl}/event/session/get/`,
      data,
      {
        headers: this.Headers,
      }
    );
  }

  getRSVPAttendanceStatus(data) {
    return this.http.post(
      `${this.baseUrl}/event/rsvp/list-attendees`,
      data,
      {
        headers: this.Headers,
      }
    );
  }

  exportAttendance(data) {
    return this.http.post(
      `${this.baseUrl}/event/attendance/export-attendees`,
      data,
      {
        headers: this.Headers,
      }
    );
  }

  downloadFile(x: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/uploads/Postexport/` + x, {
      responseType: "blob",
    });
  }

  manualStaffAttendance(data) {
    return this.http.post(
      `${this.baseUrl}/event/attendance/manual`,
      data,
      {
        headers: this.Headers,
      }
    );
  }

  getSessions(data) {
    return this.http.post(
      `${this.baseUrl}/event/session/getSessionsByPost`,
      data,
      {
        headers: this.Headers,
      }
    );
  }
}
