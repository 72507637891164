<div
  #sidemenu
  id="sidemenu"
  class="p-3 d-none d-sm-block pt-5 sidemenu"
  *ngIf="!isView"
>
  <aside>
    <div class="form-group">
      <label class="d-block text-center">BUSINESS UNIT</label>
      <ng-select
        [items]="buForShift"
        [loading]="buLoader"
        loadingText="Please wait data is loading..."
        class="custom"
        bindLabel="orgName"
        placeholder="Select/Search Business unit"
        appendTo="body"
        [searchable]="true"
        [clearable]="true"
        [closeOnSelect]="true"
        [(ngModel)]="buId"
        (change)="onBuChange()"
      >
      </ng-select>
    </div>

    <div class="form-group">
      <label class="d-block text-center">WORK WEEK</label>
      <ng-select
        class="custom"
        [virtualScroll]="true"
        [loading]="weeksLoader"
        placeholder="Select Week"
        appendTo="body"
        [multiple]="false"
        [searchable]="true"
        [clearable]="true"
        [closeOnSelect]="true"
        [(ngModel)]="selectedWeek"
      >
      <ng-container *ngFor="let item of weeksArr">
        <ng-option  [value]="item.label">{{item.label}}</ng-option>
      </ng-container>

      </ng-select>
    </div>
    <div class="row">
      <div class="col pr-md-3">
        <button class="btn btn-danger btn-block">Cancel</button>
      </div>
      <div class="col pl-md-0">
        <button class="btn btn-info btn-block" (click)="viewClicked()">
          View
        </button>
      </div>
    </div>
  </aside>
</div>

<div class="d-flex flex-column" *ngIf="isView">
  <ul
    ngbNav
    #companyNav="ngbNav"
    [(activeId)]="activeId"
    class="nav-tabs rounded mb-3"
  >
    <li [ngbNavItem]="1" (click)="refreshHeaderData()">
      <a ngbNavLink (click)="viewClicked()">OVERVIEW</a>
      <ng-template ngbNavContent>
        <app-plan-shift-header [data]="headerData"></app-plan-shift-header>
        <app-manage-overview
          [bu]="buId"
          [startDate]="startDateLocal"
          [shiftGraph]="receivedPlanShift"
        ></app-manage-overview>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink (click)="switchToShift()">SHIFTS</a>
      <ng-template ngbNavContent>
        <div *ngIf="isShiftsPageActive" class="top-header bg-white rounded mb-3">
          <div>
            <div>Planned Flexi Hrs</div>
            <div class="hrs-text red-text">
              {{
                this.topData == undefined
                  ? "0.00"
                  : (this.topData.totalHours.need | number: "1.2-2")
              }}
            </div>
          </div>
          <div>
            <div>Planned Flexi Staff</div>
            <div class="hrs-text red-text">
              {{
                this.topData == undefined
                  ? "0.00"
                  : (this.topData.numberOfShifts.need | number: "1.2-2")
              }}
            </div>
          </div>
          <div>
            <div class="red-text">Booked Flexi Hrs</div>
            <div class="hrs-text red-text">
              {{
                this.topData == undefined
                  ? "0.00"
                  : (this.topData.totalHours.booked | number: "1.2-2")
              }}
            </div>
          </div>
          <div>
            <div class="red-text">Booked Flexi staff</div>
            <div class="hrs-text red-text">
              {{
                this.topData == undefined
                  ? "0.00"
                  : (this.topData.numberOfShifts.booked | number: "1.2-2")
              }}
            </div>
          </div>
          <div>
            <div class="red-text">Assigned Hrs</div>
            <div class="hrs-text red-text">
              {{
                this.topData == undefined
                  ? "0.00"
                  : (this.topData.totalHours.needAssign | number: "1.2-2")
              }}
            </div>
          </div>
          <div>
            <div class="red-text">Assigned Staff</div>
            <div class="hrs-text red-text">
              {{
                this.topData == undefined
                  ? "0.00"
                  : (this.topData.numberOfShifts.needAssign | number: "1.2-2")
              }}
            </div>
          </div>
        </div>
        <app-manage *ngIf="isShiftsPageActive"
          [shift]="shiftsPageData"
          [page]="pagefrom"
          [week]="planShift.startDate"
          [buId]="buId"
          (headerData)="getheaderData($event)"
          (day)="headerHrsStaff($event)"
        ></app-manage>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="companyNav"></div>
</div>
