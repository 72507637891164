import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';

import { UsersService } from '../../../../service/user-service/users.service';
import { RoleService } from 'src/app/users/service/user-roles/role.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'leave-overview',
  templateUrl: './leave-overview.component.html',
  styleUrls: ['./leave-overview.component.scss'],
})
export class LeaveOverviewComponent implements OnInit {
  active = 1;
  predefineLeaveTypeList = [
    {
      name: 'Annual Leave',
      isQuotaExceed: false,
      type: 'default',
    },
    {
      name: 'No Pay Leave',
      isQuotaExceed: false,
      type: 'default',
    },
    {
      name: 'Off-in-Lieu',
      isQuotaExceed: false,
      type: 'default',
    },
  ];

  editLeaveTypeDetail = {
    _id: '',
    name: '',
    isQuotaExceed: false,
    type: 'other',
  };
  addNewLeaveType = { name: '', isQuotaExceed: false, type: 'other' };
  predefineLeaveType = ['Annual Leave', 'No Pay Leave', 'Off-in-Lieu'];
  getLeaveTypeList = [];
  deleteLeaveTypeId = '';
  leaveTypeName: string = '';
  isLeaveTypeAvailable: string = '';
  isAddLeaveModalOpen: boolean = false;
  isDeleteModalOpen: boolean = false;
  isEditModalOpen: boolean = false;
  leaveTypeListLoader: boolean = true;
  loader: boolean = false;
  leavetypeAccess: boolean = false;

  constructor(
    private _usersService: UsersService,
    public toastService: ToastrService,
    private roleService: RoleService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.getLeaveTypeData();

    const rolePrivileges = this.roleService
      .getNewUserPrivilege()
      .pipe(
        finalize(() => {
          rolePrivileges.unsubscribe();
        })
      )
      .subscribe((res) => {
        this.leavetypeAccess = res.privileges.hasOwnProperty('leaveSchemeLeaveType')
          ? res.privileges.leaveSchemeLeaveType
          : false;
      });
  }

  getLeaveTypeData() {
    const subscribe = this._usersService
      .getLeaveType()
      .pipe(
        finalize(() => {
          this.leaveTypeListLoader = false;
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.getLeaveTypeList = response.data;
            this.getLeaveTypeList.forEach((leave) => {
              leave.createdAt = moment(leave.createdAt).format('DD MMM YYYY');
              leave.updatedAt = moment(leave.updatedAt).format('DD MMM YYYY');
            });
            this.isLeaveTypeAvailable =
              this.getLeaveTypeList.length !== 0 ? 'yes' : 'no';
          }
        },
        (error) => {
          this.toastService.error('No list found');
        }
      );
  }

  createLeave() {
    this.loader = true;
    this._usersService.createLeaveType(this.predefineLeaveTypeList).subscribe(
      (response) => {
        if (response.success) {
          this.toastService.success(response.message);
          this.active = 1;
          this.getLeaveTypeData();
        } else {
          this.toastService.error(response.message);
        }
        this.loader = false;
      },
      (error) => {
        this.loader = false;
        this.toastService.error(error.message);
      }
    );
  }

  updateLeavea() {
    this.isAddLeaveModalOpen = true;
  }

  openAddLeaveModal(isModalOpen) {
    this.isAddLeaveModalOpen = isModalOpen;
  }

  open(content) {
    this.modalService.open(content);
  }

  callLeaveUpdateService(requestPayload) {
    this.loader = true;
    this._usersService.updateLeaveType(requestPayload).subscribe(
      (response) => {
        if (response.success) {
          this.toastService.success(response.message);
          this.active = 1;
          this.modalService.dismissAll();
          this.resetEditModal();
          this.getLeaveTypeData();
        } else {
          this.resetEditModal();
          this.toastService.error(response.message);
        }
        this.loader = false;
      },
      (error) => {
        this.loader = false;
        this.resetEditModal();
        this.toastService.error(error.message);
      }
    );
  }

  submitAddNewLeaveType() {
    if (this.addNewLeaveType.name.length === 0) {
      this.isAddLeaveModalOpen = false;
      return this.toastService.error('Leave name cannot be empty');
    } else {
      //check if leave name already exists
      const nameExist = this.getLeaveTypeList.findIndex((x) => {
        //making case-insensitive
        return x.name.toLowerCase() === this.addNewLeaveType.name.toLowerCase();
      });

      if (nameExist !== -1) {
        this.toastService.error('Leave name already exists', 'Failed');
        this.resetEditModal();
      } else {
        this.callLeaveUpdateService({
          name: this.addNewLeaveType.name,
          isQuotaExceed: this.addNewLeaveType.isQuotaExceed,
          type: 'any',
        });
      }
    }
  }

  submitEditLeaveType() {
    if (this.editLeaveTypeDetail.name.length === 0) {
      this.isEditModalOpen = false;
      return this.toastService.error('Leave name cannot be empty');
    }
    this.callLeaveUpdateService({
      leaveTypeId: this.editLeaveTypeDetail._id,
      name: this.editLeaveTypeDetail.name,
      isQuotaExceed: this.editLeaveTypeDetail.isQuotaExceed,
      type: 'any',
    });
  }

  deleteLeaveType() {
    this._usersService
      .deleteLeaveType({ leaveTypeId: this.deleteLeaveTypeId })
      .subscribe(
        (response) => {
          this.isDeleteModalOpen = false;
          if (response.success) {
            this.toastService.success(response.message);
            this.active = 1;
            this.getLeaveTypeData();
          } else {
            this.toastService.error(response.message);
          }
        },
        (error) => {
          this.toastService.error(error.message);
        }
      );
  }

  openDeletemodal(leaveName) {
    this.leaveTypeName = leaveName;
    // this.isDeleteModalOpen = !this.isDeleteModalOpen;
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete this ' + this.leaveTypeName + ' ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteLeaveType();
      }
    });
  }

  switchToEditDeletePage() {
    this.active = 2;
  }

  editLeaveType(isOpen, editLeaveData, model) {
    this.modalService.open(model);
    this.editLeaveTypeDetail = { ...editLeaveData };
  }

  resetEditModal() {
    this.isAddLeaveModalOpen = false;
    this.isEditModalOpen = false;
    this.addNewLeaveType.name = '';
    this.addNewLeaveType.isQuotaExceed = false;
    this.editLeaveTypeDetail.name = '';
    this.editLeaveTypeDetail._id = '';
    this.editLeaveTypeDetail.isQuotaExceed = false;
  }
}
