<div class="d-flex flex-column">
  <ul
    ngbNav
    [(activeId)]="activeId"
    #companyNav="ngbNav"
    class="nav-tabs rounded mb-3"
  >
    <li [ngbNavItem]="1">
      <a ngbNavLink>PASSWORD LOCK</a>
      <ng-template ngbNavContent>
        <lock-account-overview></lock-account-overview>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="companyNav"></div>
</div>
