<div class="card" *ngIf="isOverview">
  <div class="card-header align-items-center bg-theme">
    <h5 class="m-0">STATUS</h5>
    <p class="m-0">Selected Business Unit : {{ buId?.orgName }}</p>
  </div>
  <div class="card-body p-0">
    <!-- <app-skliton-loading-table></app-skliton-loading-table> -->
    <div class="table-responsive">
      <table class="table table-striped m-0">
        <thead>
          <tr>
            <th scope="col" sortable="Tier 1">DATE</th>
            <th scope="col" sortable="Tier 2">TIME</th>
            <th scope="col" sortable="Tier 3">DESCRIPTION</th>
            <th scope="col" sortable="Tier 4">USER</th>
            <th scope="col" sortable="Status">STATUS</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let s of this.status">
            <td>{{ s.createdAt | date }}</td>
            <td>{{ s.createdAt | date: "HH:mm " }}</td>
            <td>{{ s.description }}</td>
            <td>{{ s.userId.name }}</td>
            <td>
              <button
                class="btn btn-success btn-sm"
                (click)="changeOverview(false, s)"
              >
                View
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="card" *ngIf="!isOverview">
  <div class="card-header flexi-header">
    <h3>LOG DETAILS</h3>
  </div>
  <div class="card-body">
    <form>
      <div class="row mb-2 col-md-12 flexDisplay">
        <div class="col-md-2 red-heading"><b>General</b></div>
        <div class="red-heading" (click)="changeOverview(true)">
          <b>Back</b>
        </div>
      </div>
      <div class="col-md-12 row mb-2 titleHeader flexDisplay">
        <div class="col-md-3 no-padding"><b>Date</b></div>
        <div class="col-md-3 no-padding"><b>Time</b></div>
        <div class="col-md-3 no-padding"><b>User</b></div>
      </div>
      <div class="row mb-2 col-md-12 flexDisplay">
        <input
          class="col-md-3 form-control"
          [disabled]="true"
          value="{{ logDetail.createdAt | date: 'dd-MM-yyyy' }}"
        />
        <input
          class="col-md-3 form-control"
          [disabled]="true"
          value="{{ logDetail.createdAt | date: 'HH:mm' }}"
        />
        <input
          class="col-md-3 form-control"
          [disabled]="true"
          value="{{ logDetail.userId.name }}"
        />
      </div>
      <div class="col-md-12 row mb-2"><b>Description</b></div>
      <div class="col-md-12 row mb-2">
        <input
          class="col-md-12 form-control"
          [disabled]="true"
          value="{{ logDetail.description }}"
        />
      </div>
      <div class="row mb-2 col-md-12 mt-4">
        <div class="col-md-2 red-heading"><b>Shift Details</b></div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="shiftTab selected">
            <div class="d-flex justify-content-between">
              <div>
                <ng-container *ngIf="shiftDetails?.subSkillSets?.length">
                  <p class="m-0 red" *ngFor="let s of shiftDetails?.subSkillSets">
                    {{ s.skillSetId.name | titlecase }} >
                    {{ s.name | titlecase }}
                  </p>
                </ng-container>
              </div>
              <div>
                {{   shiftDetails?.startTime | date: 'HH:mm' }} -
                {{   shiftDetails?.endTime | date: 'HH:mm' }}
                <div *ngIf="shiftDetails?.isSplitShift">
                  {{ shiftDetails.splitShiftStartTime | date: "HH:mm" }} - {{
                    shiftDetails.splitShiftEndTime | date: "HH:mm"
                  }}
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end mt-1">
              <div
                class="badge badge-pill bg-theme"

              >
                {{ shiftDetails?.staffNeedCount }}/{{
                  shiftDetails?.backUpStaffNeedCount
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card border-0">
            <div class="card-header bg-theme">
              <h6 class="m-0">Details of Shift</h6>
            </div>
            <div class="card-body border-theme">
              <div class="col-md-12 row mb-2"><b>Business Unit</b></div>
              <div class="col-md-12 row mb-2">
                <input
                  class="form-control"
                  placeholder="Business Unit"
                  [disabled]="true"
                  value="{{ buId.orgName }}"
                />
              </div>
              <div class="col-md-12 row mb-2"><b>Date</b></div>
              <div class="col-md-12 row mb-2">
                <input

                  class="form-control"
                  placeholder="Date"
                  [disabled]="true"
                  value="{{ shiftDetails?.date | date }}"
                />
              </div>

              <div class="col-md-12 row mb-2"><b>Timing</b></div>

              <div
                class="col-md-12 row mb-2 flexDisplay"

              >
                <input
                  class="form-control col-md-5"
                  placeholder="Timing"
                  [disabled]="true"
                  value="{{
                    shiftDetails?.startTime | date: 'HH:mm'
                  }}"
                />
                <input
                  class="form-control col-md-5"
                  placeholder="Timimg"
                  [disabled]="true"
                  value="{{
                    shiftDetails?.endTime | date: 'HH:mm'
                  }}"
                />
              </div>
              <div *ngIf="shiftDetails?.isSplitShift">
              <div class="col-md-12 row mb-2"><b>Split Timing</b></div>

              <div class="col-md-12 row mb-2 flexDisplay">
                <input
                  class="form-control col-md-5"
                  placeholder="Timing"
                  [disabled]="true"
                  value="{{
                    shiftDetails?.splitShiftStartTime | date: 'HH:mm'
                  }}"
                />
                <input
                  class="form-control col-md-5"
                  placeholder="Timimg"
                  [disabled]="true"
                  value="{{
                    shiftDetails?.splitShiftEndTime | date: 'HH:mm'
                  }}"
                />
              </div>
              </div>
              <div *ngIf="logDetail.status !==11 &&  logDetail.status !==5 && logDetail.status !==18 && logDetail.status !==19 && logDetail.status !==20 && logDetail.status !==21 && logDetail.status !==22">
                <div class="col-md-12 row mb-2"><b>New Timing</b></div>
                <div class="col-md-12 row mb-2 flexDisplay">
                  <input
                    class="form-control col-md-5"
                    placeholder="Timing"
                    [disabled]="true"
                    value="{{ logDetail?.newTiming?.start | date: 'HH:mm' }}"
                  />
                  <input
                    class="form-control col-md-5"
                    placeholder="Timimg"
                    [disabled]="true"
                    value="{{ logDetail?.newTiming?.end | date: 'HH:mm' }}"
                  />
                </div>
              </div>
              <div class="col-md-12 my-2">
                <!-- <div *ngIf="this.buLabelArr != null">
                  <div
                    class="labels"
                    *ngFor="let item of buLabelArr; let i = index"
                  >
                    <span class="text">{{ item }}</span>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
