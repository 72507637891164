
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { UsersModule } from './users/users.module';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from './shared/shared.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DecimalPipe } from '@angular/common';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { ExternalLinkComponent } from './external-link/external-link.component';

@NgModule({
  declarations: [AppComponent, ExternalLinkComponent],
  imports: [
    BrowserModule,
    NgSelectModule,
    FormsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-left',
      preventDuplicates: true,
      closeButton: true,
      progressBar: false,
    }),
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    UsersModule,
    SharedModule,
    DragDropModule,
    AngularMultiSelectModule

  ],
  providers: [Title,DecimalPipe],
  bootstrap: [AppComponent],
})
export class AppModule { }
