import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { ShiftSchedulerService } from 'src/app/users/service/operations/shift-scheduler.service';
import { getGmtOffSet } from 'src/app/core/utils/helper';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-log-open',
  templateUrl: './log-open.component.html',
  styleUrls: ['./log-open.component.scss'],
})
export class LogOpenComponent implements OnInit {
  @Input() bu;
  @Input() shift;
  isOverview: boolean = true;
  showShiftDetails: boolean = false;
  shiftreadPara: any;
  shiftLog: any;
  currWeek: any;
  startDay: any;
  startDaystr: any;
  shiftLogData: Object;
  logDetail: any;
  weekArr2:any;
  weekArr1 = [];
  openLogLoader: boolean = true;
  weekCount = 0;
  currentWeekCount: any;
  shiftDetail: any;
  gmtOffset: any;
  constructor(private shiftService: ShiftSchedulerService, 
    private toastService: ToastrService ) {}

  ngOnInit(): void {
    this.gmtOffset = getGmtOffSet();
    this.getShiftRead();
    this.getWeeks();
  }

  getShiftLog() {
    this.openLogLoader = true;
    this.shiftLog = {
      status: 1,
      businessUnitId: this.bu._id,
      startDate: this.startDay,
    };

    const openshiftSub = this.shiftService
      .getShiftLog(this.shiftLog)
      .pipe(
        finalize(() => {
          this.openLogLoader = false;
          openshiftSub.unsubscribe();
        })
      )
      .subscribe((res) => {
        this.shiftLogData = res['data'];
        this
      });
  }

  getShiftRead() {
    if (this.shift != null) {
      this.shiftreadPara = {
        businessUnitId: this.bu._id,
        startDate: this.shift.weekRangeStartsAt,
        date: this.shift.weekRangeStartsAt,
        from: 'plan',
      };
      this.shiftService.getPlanShift(this.shiftreadPara).subscribe((res) => {
        console.log('ShiftRead', res);
      });
    }
  }

  getWeeks() {
    this.shiftService.getWeeks(this.bu._id).subscribe((res) => {
      this.weekArr2 = res['data']['noOfWeek'];
      this.currentWeekCount = this.weekArr2;
      let getDate = moment().startOf('isoWeek').format("MM-DD-YYYY");
      if (this.weekArr2 > 0) {
        for (var i = -this.weekArr2; i <= this.weekArr2; i++) {
          let dummyData = this.getWeekStartEndDate(getDate, 7 * (i))
          console.log(dummyData);
         this.weekArr1.push(dummyData);
          //localStorageService.set("WorkWeekList",  $scope.myWeekRange);
        }
      }
      console.log(this.weekArr1,this.weekArr2)
      this.currWeek = this.weekArr1[this.weekArr2];
      let startDaystr = this.currWeek.slice(0, 6);
      let date = moment(startDaystr).year(moment().year()).format();
      let dd = moment(date).date().toString();
      let mm = moment(date).month();
      let mm1 = mm + 1;
      let yyyy = moment(date).year();
      this.startDay =
        mm1.toString().padStart(2, '0') +
        '-' +
        dd.padStart(2, '0') +
        '-' +
        yyyy +
        ` 00:00:00 ${this.gmtOffset}`;
      this.getShiftLog();
    });
  }


  getWeekStartEndDate = function (date, count) {
    let datesToReturn = {};
    let label = moment().startOf('isoWeek').add(count, 'days').format("MMM DD") + ' to ' + moment().startOf('isoWeek').add(count + 6, 'days').format("MMM DD")
    let value = moment().startOf('isoWeek').add(count, 'days').format("MM-DD-YYYY");

    datesToReturn = { label: label, value: value, key: count };
   /* if (count == 0 && $scope.workWeeksDate == undefined) {

      $scope.workWeeksDate = value;

    }*/
    return label;
  }

  changeOverview(value, i) {
    this.isOverview = value;
    this.logDetail = i;
    if(i && i.shiftDetails && typeof i.shiftDetails === 'string'){
      this.logDetail.shiftDetails = JSON.parse(i.shiftDetails);
    } else if (i && i.shiftId && typeof i.shiftId === 'string') {
      this.logDetail.shiftDetails = JSON.parse(i.shiftId);
    }
    this.showShiftDetails = false;
  }

  changeShiftDetailsView(value,item) {
    this.showShiftDetails = value;
    this.shiftDetail = item;
  }

  changeWeek(str) {
    if (str == 'Nxt') {
      this.weekCount =
        this.weekCount < this.currentWeekCount
          ? this.weekCount + 1
          : this.currentWeekCount;
    } else if (str == 'Prev') {
      this.weekCount =
        this.weekCount == -Math.abs(this.currentWeekCount)
          ? this.weekCount
          : this.weekCount - 1;
    } else {
      this.weekCount = 0;
    }
  
    this.currWeek = this.weekArr1[this.currentWeekCount + this.weekCount];
    let startDaystr = this.currWeek.slice(0, 6);
    let date = moment(startDaystr).year(moment().year()).format('MM-DD-YYYY');
    this.startDay = date + ` 00:00:00 ${this.gmtOffset}`;
    this.getShiftLog();
  }
}
