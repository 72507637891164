<div class="card">
  <div class="card-header bg-theme">
    <h5 class="m-0">FACIAL ID</h5>
    <p class="m-0">Selected Business Unit : {{ bu.orgName }}</p>
  </div>
  <div class="card-body table-responsive p-0">
    <ngx-skeleton-loader
      *ngIf="facialIdLoader"
      count="6"
      [theme]="{
        width: 'calc(100% - 20px)',
        'border-radius': '6px',
        height: '44px',
        margin: '10px',
        display: 'block'
      }"
    ></ngx-skeleton-loader>
    <table class="table table-hover" *ngIf="!facialIdLoader">
      <thead>
        <tr>
          <th scope="col">PHOTO ID</th>
          <th scope="col">USER NAME</th>
          <th scope="col">STAFF ID</th>
          <th scope="col">CONTACT NUMBER</th>
          <th scope="col">APPOINTMENT</th>
          <th scope="col">ACTION</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of facialList">
          <td>
            <img [src]="item.facialInfo" alt="" class="img-rounded" />
          </td>
          <td>
            <span class="font-weight-500">{{ item.userInfo?.name }}</span>
          </td>
          <td>{{ item.userInfo?.staffId }}</td>
          <td>{{ item.contactNumber ? item.contactNumber : "-" }}</td>
          <td>{{ item.appointmentInfo?.name }}</td>
          <td>
            <button class="btn btn-success btn-sm" (click)="editFacialId(item)">
              Edit Facial ID
            </button>
          </td>
        </tr>
        <ng-container *ngIf="facialList?.length == 0">
          <td colspan="6">No data available</td>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
