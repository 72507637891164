import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subject, Subscription, of } from 'rxjs';
import {
  map,
  debounceTime,
  distinctUntilChanged,
  mergeMap,
  delay,
  finalize,
} from 'rxjs/operators';
import {
  SortableHeader,
  SortEvent,
} from 'src/app/shared/directive/sortable.directive';
import { StorageService } from 'src/app/core/service/storage.service';

import { NotificationService } from 'src/app/users/service/notification/notification.service';
import { UsersService } from 'src/app/users/service/user-service/users.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'app-unread',
  templateUrl: './unread.component.html',
  styleUrls: ['./unread.component.scss'],
})
export class UnreadComponent implements OnInit {
  isTableLoading: boolean = true;
  usersPTable;
  // isBuUnitEdited:boolean = false
  // sectionId:string
  // subSectionId:string
  // bussinessUnitEditForm:FormGroup
  public userssearchInput = new Subject<KeyboardEvent>();
  notificationViewData;
  selectedIndex: number;
  moduleDetail;
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  // companyName: string;
  /// bussiness unit over view table config
  onSortUsers({ column, direction }: SortEvent, field) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    // this.usersPTable.sort = { key: column, order: direction as any };
    this.usersPTable.sortWith = field;
    this.usersPTable.sortBy = direction;
    this.loadUsers();
  }

  onPageChangeUsers(page: number) {
    this.usersPTable.page = page;
    this.loadUsers();
  }
  onLimitChangeUsers() {
    this.loadUsers();
  }

  private usersSearchSubscription: Subscription;

  constructor(
    private notificationService: NotificationService,
    public toastService: ToastrService,
    private storageService: StorageService,
    private formBuilder: FormBuilder
  ) {
    // this.config.notFoundText = 'Custom not found';
    this.usersPTable = {
      sortBy: 'desc',
      sortWith: 'activeFrom',

      data: [],
      page: 1,
      limit: 10,
      search: '',
    };
    // this.companyName = this.storageService.getByKey('companyId');

    this.usersSearchSubscription = this.userssearchInput
      .pipe(
        map((event) => {
          const target = event.target as HTMLTextAreaElement;
          return target.value;
        }),
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap((search) => of(search).pipe(delay(500)))
      )
      .subscribe((input) => {
        this.usersPTable.page = 1;
        this.loadUsers();
      });
  }

  ngOnInit(): void {
    this.loadUsers();
  }

  onSelectNotification(item, index) {
    this.selectedIndex = index;
    this.notificationViewData = item;
    if(item?.moduleId) this.getModuleQuestions(item);
  }

  onAcknowlege(id: string) {
    const sub = this.notificationService
      .getAcknowledgeNotification(id)
      .pipe(finalize(() => sub.unsubscribe()))
      .subscribe((res) => {
        this.loadUsers();
        this.toastService.success(res?.data || res?.message, '', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
      },
      (err) => {
        this.toastService.error("Already acknowledged to this notification", '', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
      },
      );
  }

  getModuleQuestions(item: any) {

    const payloadData = {
          moduleType:"notification",
          notificationId:item._id,
          moduleId:item.moduleId,
          trackQns:true
    }
    const sub = this.notificationService
      .getModuleQuestions(payloadData)
      .pipe(finalize(() => sub.unsubscribe()))
      .subscribe((res) => {
        this.moduleDetail = res.data.data;
      },
      (err) => {
        this.moduleDetail = null;
      },
      );
  }

  loadUsers() {
    const { data, total, ...rest } = this.usersPTable;
    const subscribe = this.notificationService
      .getUnreadNofications(rest)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const { data,count } = response.data;
          const unread = response.data.filter((data)=>{
            return !data?.isAcknowledged
          })
          this.usersPTable.data = unread;
          this.usersPTable.total = unread.length;
          this.isTableLoading = false;
          this.onSelectNotification(unread[0], 0)
        },
        (error) => {
          this.usersPTable.data = [];
          this.usersPTable.total = 0;
          this.toastService.error('No list found');
        }
      );
  }

  onexportNotification(id) {
    const event = new Date();

    const data = {
      date: event.toString(),
      notificationId: id,
    };
    const sub = this.notificationService
      .exportNotification(data)
      .pipe(finalize(() => sub.unsubscribe()))
      .subscribe(({data}) => {
        window.open(`${environment.imageUrl}/${data.csvLink}`);
        // this.loadUsers();
      });
  }

  downloadAttachmentOfNOtification(attachment)  {
    var lastFileName = attachment.split('/');
    lastFileName = lastFileName[lastFileName.length - 1];
    this.notificationService.getMimeType({
      fileName: lastFileName
    }).subscribe((data) => {
      this.notificationService.downloadUrl(attachment).subscribe((content) => {
        fileSaver.saveAs(new Blob([content], { type: data.type}), attachment);
      });

    });
  }

  ngOnDestroy(): void {
    this.usersSearchSubscription && this.usersSearchSubscription.unsubscribe();
  }
}
