import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { LeavePlannerService } from 'src/app/users/service/human-resources/leave-planner.service';
import { UsersService } from 'src/app/users/service/user-service/users.service';

@Component({
  selector: 'swap-restrictions',
  templateUrl: './swap-restrictions.component.html',
  styleUrls: ['./swap-restrictions.component.scss'],
})
export class SwapRestrictionsComponent implements OnInit {
  opsGrpSelect;
  opsTeamSelect;
  loggedInUser: any;
  leaveData: any;
  opsList: any;
  opsTeam: any;
  opsTeamUserId: any;
  opsTeamUser = [];
  swapCheck = [];
  swapListData: any;

  constructor(
    private usersService: UsersService,
    private leaveService: LeavePlannerService,
    public toastService: ToastrService
  ) {}

  ngOnInit(): void {
    this.swapOpsGroup();
  }

  swapList() {
    let swap = {};
    if (this.opsGrpSelect && !this.opsTeamSelect) {
      swap = {
        opsGroupId: this.opsGrpSelect.id,
      };
    } else if (this.opsGrpSelect && this.opsTeamSelect) {
      swap = {
        opsGroupId: this.opsGrpSelect.id,
        opsTeamId: this.opsTeamSelect.id,
      };
    }

    this.leaveService.getSwapList(swap).subscribe((res) => {
      this.swapListData = res['data'];
      this.swapCheck = [];
      this.swapListData.userId?.forEach((val) => {
        this.swapCheck.push(val.isLeaveSwapAllowed);
      });
    });

  }

  swapChange(item) {

    const swapList = this.leaveService
      .swapRestrict(item._id)
      .pipe(
        finalize(() => {
          swapList.unsubscribe();
        })
      )
      .subscribe((res: { status; message }) => {
        if (res.status) {
          this.toastService.success(
            res.message ? res.message : 'Successfully updated',
            'Successfull'
          );
          this.swapList();
        } else {
          this.toastService.error(
            res.message ? res.message : 'Update Failed',
            'Failed'
          );
        }
      });
  }

  swapOpsGroup() {
    const profile = this.usersService
      .getUserProfile()
      .pipe(
        finalize(() => {
          profile.unsubscribe();
        })
      )
      .subscribe((res) => {
        this.loggedInUser = res.data;

        const grp = this.leaveService
          .getSwapOpsGroup(this.loggedInUser._id)
          .pipe(
            finalize(() => {
              grp.unsubscribe();
            })
          )
          .subscribe((res: { data: any }) => {
            this.leaveData = res.data.leavedata;
            this.opsList = res.data.opsids;
          });
      });
  }

  getOpsTeamUser() {
    this.opsTeamUserId = this.opsTeamSelect.id;
    this.leaveData.forEach((element) => {
      element.users.forEach((item) => {
        if (item.teamId === this.opsTeamUserId) {
          this.opsTeamUser.push(item);
        }
      });
    });
  }

  getOpsTeam() {
    this.opsTeam = this.opsGrpSelect.team;
  }
}
