import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { SetupService } from 'src/app/users/service/operations/setup.service';
@Component({
  selector: 'app-shift-scheme-overview',
  templateUrl: './shift-scheme-overview.component.html',
  styleUrls: ['./shift-scheme-overview.component.scss'],
})
export class ShiftSchemeOverviewComponent implements OnInit {
  @Output() changeActiveScheme = new EventEmitter<any>();
  @Output() changeActiveViewScheme = new EventEmitter<any>();
  schemeData = '';
  isEdit: boolean;
  delete = false;
  @Input() buShiftID: any;

  schemeTable: any;
  schemeEdit: any;
  schemeId: any;
  deleteSchemeId: any;
  shiftSchemeTable;
  loader: boolean = true;
  paginateData:any=[];
  schemeShiftOverviewErr: boolean = false;
  constructor(
    private buService: SetupService,
    public toastService: ToastrService
  ) {
    this.shiftSchemeTable = {
      sortBy: 'desc',
      sortWith: 'createdAt',
      data: [],
      page: 1,
      limit: 10,
      search: '',
    };
  }

  ngOnInit(): void {
    //this.schemeOverview();
    this.loadShiftScheme();
  }

  // schemeOverview() {
  //   this.buService.getScheme(this.buShiftID, "").subscribe((res) => {
  //     this.schemeTable = res["data"];
  //     console.log("schemeTable", this.schemeTable);
  //   });
  // }

  loadShiftScheme() {
    this.loader = true;
    this.buShiftID = localStorage.getItem('flexicompanyId');
    const { data, total, ...rest } = this.shiftSchemeTable;    
    const subscribe = this.buService
      .getScheme(this.buShiftID, rest)
      .pipe(
        finalize(() => {
          this.loader = false;
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const { status, data ,msg} = response;
          this.shiftSchemeTable.data = data;
          this.shiftSchemeTable.total = data.length;
          this.getPaginateData();
        },
        (error) => {
          this.shiftSchemeTable.data = [];
          this.shiftSchemeTable.total = 0;
          this.toastService.error('No list found');
          this.schemeShiftOverviewErr = true;
        }
      );
  }
  getPaginateData(){

    this.paginateData =  this.shiftSchemeTable.data
     .slice((this.shiftSchemeTable.page - 1) * this.shiftSchemeTable.limit, (this.shiftSchemeTable.page - 1) * this.shiftSchemeTable.limit + this.shiftSchemeTable.limit);

   }
  onPageChangeShiftScheme(page: number) {
    this.shiftSchemeTable.page = page;
    this.getPaginateData();
  }

  onViewSchemeShift(value) {
    this.changeActiveScheme.emit(value);
    this.changeActiveViewScheme.emit('view');
  }

  onEditSchemeShift(value) {
    this.changeActiveScheme.emit(value);
    this.changeActiveViewScheme.emit('edit');
  }

  // onViewOrEditClick(value) {
  //   this.isEdit = value;
  //   this.changeActive.emit(this.isEdit);
  // }

  setDeleteItem(i, d) {
    this.delete = d;
    this.schemeId = i._id;
  }

  setDelete() {
    this.deleteSchemeId = { _id: this.schemeId ,status: false};

    // this.schemeId = {schemeId: schemeTable}
    this.buService.updateScheme(this.deleteSchemeId).subscribe((res) => {
      this.delete = false;
      // this.schemeOverview();
      this.loadShiftScheme();
      this.toastService.info('deleted successfully');
    });
  }
}
