import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { companyNameResponse } from 'src/app/core/model/users.model';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/users/service/auth/auth.service';
import { FormGroup } from '@angular/forms';
@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
  ) { }
  public companyName;
  public forgetPasswordForm: FormGroup;

  ngOnInit(): void {
    this.route.params
      .pipe(
        take(1),
        tap((value) => this.companyName = value.name),
        map((v) => v.name),
        switchMap((name) => this.auth.fetchCompanyId(name))
      )
      .subscribe((res: companyNameResponse) => {
        this.forgetPasswordForm.patchValue({
          companyId: res.data._id,
        });
      });
  }

  backToLogin(){
    this.router.navigate(['/users/login/', this.companyName]);
  }
}
