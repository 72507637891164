<div class="card">
  <div class="card-header bg-theme">
    <h5 class="m-0">BU WITH SHIFT SETUP</h5>
  </div>
  <div class="card-body table-responsive p-0">
    <div class="d-flex justify-content-between m-2">
      <select
        (change)="onLimitChangeBuShiftSetUp()"
        [(ngModel)]="bussinesUnitPTable.limit"
        class="custom-select"
        name="pageSize"
        style="width: auto"
      >
        <option [ngValue]="10">10</option>
        <option [ngValue]="20">20</option>
        <option [ngValue]="30">30</option>
        <option [ngValue]="40">40</option>
        <option [ngValue]="50">50</option>
      </select>
      <div class="form-group form-inline mb-0">
        <!-- <span *ngIf="bussinesUnitPTable.isLoading">Please wait...</span> -->
        <input
          (keyup)="buShiftSetUpSearchInput.next($event)"
          [(ngModel)]="bussinesUnitPTable.search"
          class="form-control ml-2"
          name="searchTerm"
          placeholder="Search here"
          type="search"
        />
      </div>
    </div>

    <div *ngIf="buShiftOverviewErr; then thenBlock7; else elseBlock7"></div>
    <ng-template #thenBlock7>
      <div class="text-md text-black-50 p-4">Not Found!</div>
    </ng-template>
    <ng-template #elseBlock7>
      <table class="table table-striped">
        <thead>
          <tr>
            <th
              (sort)="onSortBuShiftSetup($event)"
              scope="col"
              sortable="Tier 2"
            >
              Tier 1
            </th>
            <th
              (sort)="onSortBuShiftSetup($event)"
              scope="col"
              sortable="Tier 2"
            >
              Tier 2
            </th>
            <th
              (sort)="onSortBuShiftSetup($event)"
              scope="col"
              sortable="Tier 3"
            >
              Tier 3
            </th>

            <th
              (sort)="onSortBuShiftSetup($event)"
              scope="col"
              sortable="Tier 4"
            >
              Tier 4
            </th>
            <th
              (sort)="onSortBuShiftSetup($event)"
              scope="col"
              sortable="Status"
            >
              Status
            </th>
            <th scope="col">Action</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let item of paginateData">
            <td> {{item.sectionId.departmentId.companyId.name}} </td>
            <td>
              {{ item.sectionId.departmentId.name }}
            </td>

            <td>{{ item.sectionId.name }}</td>

            <td>{{ item.name }}</td>
            <td>
              <span *ngIf="item.status == 1">Active</span>
              <span *ngIf="item.status == 2">In Active</span>
            </td>
            <td>
              <button
                (click)="onEditBuShiftSetup(item)"
                class="btn btn-success btn-sm"
              >
                Edit
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-template>
    <ngx-skeleton-loader
      *ngIf="loader"
      count="6"
      [theme]="{
        width: 'calc(100% - 20px)',
        'border-radius': '6px',
        height: '44px',
        margin: '10px',
        display: 'block'
      }"
    ></ngx-skeleton-loader>
  </div>
  <div class="card-footer">
    <div class="d-flex justify-content-between">
      <p class="align-self-center m-0 text-muted">
        Total Records
        <b class="text-dark">{{ bussinesUnitPTable.total }}</b>
      </p>
      <ngb-pagination
        (pageChange)="onPageChangeBuShiftSetUp($event)"
        [(page)]="bussinesUnitPTable.page"
        [collectionSize]="bussinesUnitPTable.total"
        [maxSize]="5"
        [pageSize]="bussinesUnitPTable.limit"
      >
      </ngb-pagination>
    </div>
  </div>
</div>
