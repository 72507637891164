<div class="card">
  <h5 class="card-header bg-theme">OPS GROUP</h5>
  <div class="card-body table-responsive p-0">
    <ngx-skeleton-loader
      title="Please wait..."
      *ngIf="opsGroupLoader"
      count="6"
      [theme]="{
        width: 'calc(100% - 20px)',
        'border-radius': '6px',
        height: '44px',
        margin: '10px',
        display: 'block'
      }"
    ></ngx-skeleton-loader>
    <div class="d-flex justify-content-between m-2">
      <div class="form-group form-inline mb-0 ml-auto">
        <input
        class="form-control ml-2"
        type="search"
        name="searchTerm"
        placeholder="Search here"
        (search)="overviewSearchInput.next($event)"
        (keyup)="overviewSearchInput.next($event)"
        [(ngModel)]="opsGroupDataTable.search"
      />
      </div>
    </div>

    <table class="table table-hover m-0" *ngIf="!opsGroupLoader">
      <thead>
        <tr>
          <th scope="col">OPS GROUP NAME</th>
          <th scope="col">CREATION DATE</th>
          <th scope="col">CREATED BY</th>
          <th scope="col">LAST MODIFIED DATE</th>
          <th scope="col">USERS COUNT</th>
          <th scope="col">BU'S Count</th>
          <th scope="col">No OF OPS TEAMS</th>
          <th scope="col">STATUS</th>
          <th scope="col">ACTIONS</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let opsData of opsGroupDataTable?.data| slice: (opsGroupDataTable.page-1) * opsGroupDataTable.limit : opsGroupDataTable.page * opsGroupDataTable.limit; let i = index">
          <td>
            <span class="font-weight-500">{{ opsData.opsGroupName }}</span>
          </td>
          <td>{{ opsData.createdAt | date: "dd/MM/yyyy" }}</td>
          <td>{{ opsData.createdBy?.name }}</td>
          <td>{{ opsData.updatedAt | date: "dd/MM/yyyy" }}</td>
          <td>{{ opsData.userId?.length }}</td>
          <td>{{ opsData.buId?.length }}</td>
          <td>{{ opsData.noOfTeam }}</td>
          <td>{{opsData.isDraft ? 'Draft' :'Active'}}</td>
          <td>
            <div class="d-flex">
              <button
                class="btn bg-primary text-white btn-sm"
                (click)="getOpsDetail(opsData, i, 'view')"
              >
                View
                <span
                  *ngIf="
                    actionLoader && actionBtnIndex == i && actionText == 'view'
                  "
                  class="spinner-border spinner-border-sm ml-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
              <button
                class="btn btn-warning btn-sm noWrap ml-1"
                (click)="getOpsDetail(opsData, i, 'edit')"
              >
                Edit
                <span
                  *ngIf="
                    actionLoader && actionBtnIndex == i && actionText == 'edit'
                  "
                  class="spinner-border spinner-border-sm ml-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>

              <button
                class="btn btn-danger btn-sm ml-1"
                (click)="onDeleteOpsGroup(opsData)"
              >
                Delete
              </button>
              <button
                class="btn btn-success btn-sm ml-1"
                (click)="exportOps(opsData)"
              >
                Export
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="card-footer">
    <div class="d-flex justify-content-between">
      <p>Total records {{ opsGroupDataTable.total }}</p>
      <ngb-pagination [collectionSize]="opsGroupDataTable.total" [(page)]="opsGroupDataTable.page"
        (pageChange)="onPageChangeOpsGroupData($event)" [pageSize]="opsGroupDataTable.limit">
      </ngb-pagination>
    </div>
  </div>
</div>
