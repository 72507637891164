import { Component, OnInit } from '@angular/core';
import { SetupService } from 'src/app/users/service/operations/setup.service';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from 'src/app/users/service/user-service/users.service';
import { finalize, map } from 'rxjs/operators';
import * as fileSaver from 'file-saver';
import * as moment from 'moment';
import { Subscription, Subject, of } from 'rxjs';
import { LOADING_MESSAGE } from 'src/app/constants';

import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  FormArray,
  AbstractControl,
  ValidatorFn,
} from '@angular/forms';
import { RoleService } from 'src/app/users/service/user-roles/role.service';
import { ReportService } from 'src/app/users/service/operations/report.service';
@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.scss'],
})
export class ListUserComponent implements OnInit {
  buArr = [];
  buLabelArr = [];
  bu = null;
  skill = null;
  speciality = null;
  appoint = null;
  role = null;
  allBusinessUnitSelected: boolean = false;
  buSkillSet: any;
  buSkillspeciality: any;
  buShiftSetUpForm: any;
  roleDropDown = [];
  mainSkillLabel: any;
  subSkillLabel: any;
  skillsSet = [];
  skillLabels = [];
  appointlabel = [];
  userappointlabels = [];
  appointArr = [];
  apppoinmentDropDownLoading: boolean = false;
  roleDropDownLoading: boolean = false;
  loadingMessage = LOADING_MESSAGE;

  appoinmentPTable: {
    sortWith: string;
    sortBy: string;
    data: any[];
    page: number;
    total: number;
    limit: number;
    search: string;
  };
  rolePTable: {
    sortWith: string;
    sortBy: string;
    data: any[];
    page: number;
    total: number;
    limit: number;
    search: string;
  };
  listUserForm: FormGroup;
  public apoinmentSearchInput = new Subject<KeyboardEvent>();
  status = [
    { id: 'active', status: 'active' },
    { id: '2', status: 'inactive' },
    { id: '3', status: 'both' },
  ];
  rolelabel: any;
  statusId: any;
  currentDate: Date;
  buLoader: boolean = true;
  allBuShift: any;
  activeId = 1;
  downloadFileLoader: boolean;

  constructor(
    private fb: FormBuilder,
    private setupService: SetupService,
    private userService: UsersService,
    private roleService: RoleService,
    private reportService: ReportService,
    private toastService: ToastrService
  ) {
    this.appoinmentPTable = {
      sortWith: 'createdAt',
      sortBy: 'desc',
      data: [],
      page: 1,
      total: 0,
      limit: 10,
      search: '',
    };
    this.rolePTable = {
      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,
      total: 0,
      limit: 10,
      search: '',
    };
  }

  ngOnInit() {
    this.displaySkilltable();
    this.loadAppoinment();
    this.loadRole();
    this.getAllBu();
  }

  getAllBu() {
    this.buLoader = true;
    const buSubscribe = this.setupService
      .getBusinessUnitBUShift()
      .pipe(
        finalize(() => {
          this.buLoader = false;
          buSubscribe.unsubscribe();
        }),
        map((val) => {
          // Adding 'all' option to Select All Bu
          val['data']['planBussinessUnitId'].unshift({
            _id: 'all',
            orgName: 'Select All Business Units',
          });
          return val;
        })
      )
      .subscribe(
        (res: any) => {
          if (res['data']) {
            this.allBuShift = res['data']['planBussinessUnitId'];
          } else {
            this.allBuShift = [];
          }
        },
        (error) => {
          this.allBuShift = [];
        }
      );
  }

  addBu() {
    if (this.bu._id === 'all') {
      //removing selected bu before selection of All bu
      this.buArr = [];
      this.buLabelArr = [];
      //Adding all bu except 1st Select All option
      this.allBuShift.forEach((el, i) => {
        if (i > 0) {
          this.buArr.push(el._id);
        }
      });
      this.buLabelArr.push('All Business Units Selected');
    } else if (this.buArr.includes(this.bu._id)) {
      this.toastService.info(
        'Business unit already selected',
        'Already Selected'
      );
    } else {
      this.buArr.push(this.bu._id);
      this.buLabelArr.push(this.bu.orgName);
      this.bu = null;
    }
  }

  clearLabel(i) {
    if (this.buLabelArr.includes('All Business Units Selected')) {
      this.buArr = [];
      this.buLabelArr = [];
    } else {
      this.buArr.splice(i, 1);
      this.buLabelArr.splice(i, 1);
    }
  }

  displaySkilltable() {
    this.setupService.getSkillSet().subscribe((res) => {
      this.buSkillSet = res['data'].skillsets;
    });
  }

  onSkillSetChange($event) {
    this.buSkillspeciality = $event.subSkillSets
  }

  addSkillbutton(e, d) {
    this.subSkillLabel = this.speciality._id;
    if (this.skillsSet.indexOf(this.speciality._id) > -1) {
      this.toastService.warning('Already Exists');
    } else {
      this.skillLabels.push([this.skill.name, this.speciality.name]);
      this.skillsSet.push(this.subSkillLabel);
    }
    this.skill = null;
    this.speciality = null;
  }

  clearSkillLabel(i) {
    this.skillsSet.splice(i, 1);
    this.skillLabels.splice(i, 1);
  }

  loadAppoinment() {
    this.apppoinmentDropDownLoading = true;
    const { data, total, ...rest } = this.appoinmentPTable;
    const subscribe = this.setupService
      .getAppointment()
      .pipe(
        finalize(() => {
          this.apppoinmentDropDownLoading = false;
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
         // const { count, data } = response.data;
         const res = response.data.appointments;
          this.appoinmentPTable.data = res;
          this.appoinmentPTable.total = res.length;
        },
        (error) => {
          this.appoinmentPTable.data = [];
          this.appoinmentPTable.total = 0;
          this.toastService.error('No list found');
        }
      );
  }

  onSeachAppoinment(value) {
    this.appoinmentPTable.limit = 10;
    this.appoinmentPTable.search = value.term;
    this.loadAppoinment();
  }

  loadMoreAppoinmentItems(value) {
    this.appoinmentPTable.limit = this.appoinmentPTable.limit + 10;
    this.loadAppoinment();
  }

  onClearAppoinmentDropdown(value) {
    if (value === undefined) {
      this.appoinmentPTable.search = '';
      this.loadAppoinment();
      this.appoinmentPTable.data = [];
    }
  }

  addAppoint() {
    if (this.appointArr.indexOf(this.appoint._id) > -1) {
      this.toastService.warning(
        'This Appointment already exists',
        'Already Exist'
      );
    } else {
      this.userappointlabels.push(this.appoint.name);
      this.appointArr.push(this.appoint._id);
    }
    this.appoint = null;
  }

  clearappointLabel(i) {
    this.appointArr.splice(i, 1);
    this.userappointlabels.splice(i, 1);
  }

  loadRole() {
    this.roleDropDownLoading = true;
    const { data, total, ...rest } = this.rolePTable;
    const subscribe = this.roleService
      .getUserRoles()
      .pipe(
        finalize(() => {
          this.roleDropDownLoading = false;
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const res = response.data;
          this.rolePTable.data = res.roles;
          this.roleDropDown = res.roles;
          this.rolePTable.total = res.length;
        },
        (error) => {
          this.rolePTable.data = [];
          this.rolePTable.total = 0;
          this.toastService.error('No list found');
        }
      );
  }

  onSeachRole(value) {
    this.rolePTable.limit = 10;
    this.rolePTable.search = value.term;

    this.loadRole();
  }

  loadMoreRoleItems(value) {
    this.rolePTable.limit = this.rolePTable.limit + 10;
    this.loadRole();
  }

  onClearRoleDropdown(value) {
    if (value === undefined) {
      this.rolePTable.search = '';
      this.loadRole();
      this.roleDropDown = [];
      this.rolePTable.data = [];
    }
  }

  downloadCsv() {
    this.currentDate = new Date();
    const filePayload = {
      date: moment().format('MM-DD-YYYY') + ' 00:00:00 GMT+0530',
      status: !this.statusId?.id? 1:  this.statusId?.id == "active" ? 1 : this.statusId?.id == "2" ? 2 : 3,
      appointmentId: this.appointArr,
      skillsets: this.skillsSet,
      businessUnitId: this.buArr,
      role: this.rolelabel?._id,
      doj: {},
      airportPassExpiryDate: {},
    };

    this.downloadFileLoader = true;
    this.reportService.listOfUserCsv(filePayload).subscribe(
      (res) => {
        const filename = res['data']['csvLink']
          .split('\\')
          .pop()
          .split('/')
          .pop();
        this.downloadFile(filename);
      },
      () => {
        this.downloadFileLoader = false;
      },
      () => {
        this.downloadFileLoader = false;
      }
    );
  }

  downloadFile(n) {
    this.reportService.downloadFile(n).subscribe((res) => {
      if (res) {
        fileSaver.saveAs(new Blob([res], { type: 'text/csv' }), n);
      }
      this.reset();
    });
  }

  reset() {
    this.bu = null;
    this.skill = null;
    this.speciality = null;
    this.statusId = null;
    this.rolelabel = null;
    this.appoint = null;
    this.buArr = [];
    this.buLabelArr = [];
    this.userappointlabels = [];
    this.skillsSet = [];
    this.skillLabels = [];
  }

  clearAllBu() {
    this.buArr = [];
    this.buLabelArr = [];
  }
}
