<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <form [formGroup]="opsLeaveQuota">
          <div class="form-row">
            <div class="form-group col-md-6">
              <label>Select Ops Group</label>
              <ng-select
                [items]="opsList"
                class="custom margin"
                bindLabel="opsGroupName"
                placeholder="Select Ops Group"
                appendTo="body"
                [multiple]="false"
                [searchable]="true"
                [clearable]="true"
                [closeOnSelect]="true"
                formControlName="opsGrpSelect"
                (change)="getOpsTeam()"
              >
              </ng-select>
            </div>
            <div class="form-group col-md-4">
              <label for="inputPassword4">Select Ops Team</label>
              <ng-select
                [items]="opsTeam"
                class="custom margin"
                bindLabel="name"
                placeholder="Select Ops Team"
                appendTo="body"
                [multiple]="false"
                [searchable]="true"
                [clearable]="true"
                [closeOnSelect]="true"
                formControlName="opsTeamSelect"
              >
              </ng-select>
            </div>
          </div>

          <fieldset class="form-group">
            <div class="row">
              <legend class="col-form-label col-sm-2 pt-0">Select Year</legend>
              <div class="col-sm-4">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    [value]="yearArr[0]"
                    formControlName="year"
                  />
                  <label class="form-check-label" for="gridRadios1">
                    {{yearArr[0]}}
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    [value]="yearArr[1]"
                    formControlName="year"
                  />
                  <label class="form-check-label" for="gridRadios2">
                    {{yearArr[1]}}
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    [value]="yearArr[2]"
                    formControlName="year"
                  />
                  <label class="form-check-label" for="gridRadios2">
                    {{yearArr[2]}}
                  </label>
                </div>
              </div>
            </div>
          </fieldset>

          <div class="form-row">
            <div class="form-group col-md-2">
              <label for="inputEmail4">From Month</label>
              <ng-select
                [items]="monthArr"
                class="custom margin"
                placeholder="Select Month"
                appendTo="body"
                [multiple]="false"
                [searchable]="false"
                [clearable]="false"
                [closeOnSelect]="true"
                formControlName="fromMonth"
              >
              </ng-select>
            </div>
            <div class="form-group col-md-2">
              <label for="inputPassword4">To Month</label>
              <ng-select
                [items]="monthArr"
                class="custom margin"
                placeholder="Select Month"
                appendTo="body"
                [multiple]="false"
                [searchable]="false"
                [clearable]="false"
                [closeOnSelect]="true"
                formControlName="toMonth"
              >
              </ng-select>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-sm-10">
              <button
                type="button"
                class="btn btn-info"
                [disabled]="!opsLeaveQuota.valid"
                (click)="viewQuota()"
              >
                View
              </button>
              <button
                type="button"
                class="btn btn-success ml-2"
                [disabled]="!opsLeaveQuota.valid"
                (click)="saveLeaveQuota()"
              >
                Save Leave Quota
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="col-md-12 mt-3" *ngIf="viewLeaveQuota">
    <div class="card">
      <div class="card-body">
        <div class="d-flex">
          <div class="border d-flex flex-column">
            <div class="d-flex justify-content-between p-1 bg-theme">
              <span>Tier</span><span *ngIf="teamLeave">Team</span>
            </div>
            <div
              class="
                align-items-center
                bg-theme
                d-flex
                justify-content-between
                p-1
              "
            >
              <input
                type="checkbox"
                (change)="tierCheck($event, leaveQuotaInputSun.value, 'Sun')"
              />
              <input
                type="text"
                class="form-control w-25 text-center"
                #leaveQuotaInputSun
              />
              <input
                type="checkbox"
                *ngIf="teamLeave"
                (change)="teamCheck($event, leaveQuotaInputSun.value, 'Sun')"
              />
            </div>
          </div>
          <div class="border d-flex flex-column">
            <div class="d-flex justify-content-between p-1 bg-theme">
              <span>Tier</span><span *ngIf="teamLeave">Team</span>
            </div>
            <div
              class="
                align-items-center
                bg-theme
                d-flex
                justify-content-between
                p-1
              "
            >
              <input
                type="checkbox"
                (change)="tierCheck($event, leaveQuotaInputMon.value, 'Mon')"
              />
              <input
                type="text"
                class="form-control w-25 text-center"
                #leaveQuotaInputMon
              />
              <input
                type="checkbox"
                *ngIf="teamLeave"
                (change)="teamCheck($event, leaveQuotaInputMon.value, 'Mon')"
              />
            </div>
          </div>
          <div class="border d-flex flex-column">
            <div class="d-flex justify-content-between p-1 bg-theme">
              <span>Tier</span><span *ngIf="teamLeave">Team</span>
            </div>
            <div
              class="
                align-items-center
                bg-theme
                d-flex
                justify-content-between
                p-1
              "
            >
              <input
                type="checkbox"
                (change)="tierCheck($event, leaveQuotaInputTue.value, 'Tue')"
              />
              <input
                type="text"
                class="form-control w-25 text-center"
                #leaveQuotaInputTue
              />
              <input
                type="checkbox"
                *ngIf="teamLeave"
                (change)="teamCheck($event, leaveQuotaInputTue.value, 'Tue')"
              />
            </div>
          </div>
          <div class="border d-flex flex-column">
            <div class="d-flex justify-content-between p-1 bg-theme">
              <span>Tier</span><span *ngIf="teamLeave">Team</span>
            </div>
            <div
              class="
                align-items-center
                bg-theme
                d-flex
                justify-content-between
                p-1
              "
            >
              <input
                type="checkbox"
                (change)="tierCheck($event, leaveQuotaInputWed.value, 'Wed')"
              />
              <input
                type="text"
                class="form-control w-25 text-center"
                #leaveQuotaInputWed
              />
              <input
                type="checkbox"
                *ngIf="teamLeave"
                (change)="teamCheck($event, leaveQuotaInputWed.value, 'Wed')"
              />
            </div>
          </div>
          <div class="border d-flex flex-column">
            <div class="d-flex justify-content-between p-1 bg-theme">
              <span>Tier</span><span *ngIf="teamLeave">Team</span>
            </div>
            <div
              class="
                align-items-center
                bg-theme
                d-flex
                justify-content-between
                p-1
              "
            >
              <input
                type="checkbox"
                (change)="tierCheck($event, leaveQuotaInputThu.value, 'Thu')"
              />
              <input
                type="text"
                class="form-control w-25 text-center"
                #leaveQuotaInputThu
              />
              <input
                type="checkbox"
                *ngIf="teamLeave"
                (change)="teamCheck($event, leaveQuotaInputThu.value, 'Thu')"
              />
            </div>
          </div>
          <div class="border d-flex flex-column">
            <div class="d-flex justify-content-between p-1 bg-theme">
              <span>Tier</span><span *ngIf="teamLeave">Team</span>
            </div>
            <div
              class="
                align-items-center
                bg-theme
                d-flex
                justify-content-between
                p-1
              "
            >
              <input
                type="checkbox"
                (change)="tierCheck($event, leaveQuotaInputFri.value, 'Fri')"
              />
              <input
                type="text"
                class="form-control w-25 text-center"
                #leaveQuotaInputFri
              />
              <input
                type="checkbox"
                *ngIf="teamLeave"
                (change)="teamCheck($event, leaveQuotaInputFri.value, 'Fri')"
              />
            </div>
          </div>
          <div class="border d-flex flex-column">
            <div class="d-flex justify-content-between p-1 bg-theme">
              <span>Tier</span><span *ngIf="teamLeave">Team</span>
            </div>
            <div
              class="
                align-items-center
                bg-theme
                d-flex
                justify-content-between
                p-1
              "
            >
              <input
                type="checkbox"
                (change)="tierCheck($event, leaveQuotaInputSat.value, 'Sat')"
              />
              <input
                type="text"
                class="form-control w-25 text-center"
                #leaveQuotaInputSat
              />
              <input
                type="checkbox"
                *ngIf="teamLeave"
                (change)="teamCheck($event, leaveQuotaInputSat.value, 'Sat')"
              />
            </div>
          </div>
        </div>
        <ng-template #customCellTemplate let-day="day" let-locale="locale">
          <div class="cal-cell-top text-center">
            <p class="cal-day-number font-weight-bold mb-0">
              {{ day.date | calendarDate: "monthViewDayNumber":locale }}
            </p>
          </div>
          <div
            class="d-flex justify-content-between w-100 p-1"
            *ngIf="day.inMonth"
          >
            <ng-template [ngIf]="day.day == 0">
              <input
                #tierInput
                class="form-control w-25 text-center"
                type="text"
                id="tier{{ day.date }}"
                [value]="tierLeaveQuotaSun"
              />
              <input
                #teamInput
                class="form-control w-25 text-center"
                type="text"
                id="team{{ day.date }}"
                [value]="teamLeaveQuotaSun"
                *ngIf="teamLeave"
              />
            </ng-template>
            <ng-template [ngIf]="day.day == 1">
              <input
                #tierInput
                class="form-control w-25 text-center"
                type="text"
                id="tier{{ day.date }}"
                [value]="tierLeaveQuotaMon"
              />
              <input
                #teamInput
                class="form-control w-25 text-center"
                type="text"
                id="team{{ day.date }}"
                [value]="teamLeaveQuotaMon"
                *ngIf="teamLeave"
              />
            </ng-template>
            <ng-template [ngIf]="day.day == 2">
              <input
                #tierInput
                class="form-control w-25 text-center"
                type="text"
                id="tier{{ day.date }}"
                [value]="tierLeaveQuotaTue"
              />
              <input
                #teamInput
                class="form-control w-25 text-center"
                type="text"
                id="team{{ day.date }}"
                [value]="teamLeaveQuotaTue"
                *ngIf="teamLeave"
              />
            </ng-template>
            <ng-template [ngIf]="day.day == 3">
              <input
                #tierInput
                class="form-control w-25 text-center"
                type="text"
                id="tier{{ day.date }}"
                [value]="tierLeaveQuotaWed"
              />
              <input
                #teamInput
                class="form-control w-25 text-center"
                type="text"
                id="team{{ day.date }}"
                [value]="teamLeaveQuotaWed"
                *ngIf="teamLeave"
              />
            </ng-template>
            <ng-template [ngIf]="day.day == 4">
              <input
                #tierInput
                class="form-control w-25 text-center"
                type="text"
                id="tier{{ day.date }}"
                [value]="tierLeaveQuotaThu"
              />
              <input
                #teamInput
                class="form-control w-25 text-center"
                type="text"
                id="team{{ day.date }}"
                [value]="teamLeaveQuotaThu"
                *ngIf="teamLeave"
              />
            </ng-template>
            <ng-template [ngIf]="day.day == 5">
              <input
                #tierInput
                class="form-control w-25 text-center"
                type="text"
                id="tier{{ day.date }}"
                [value]="tierLeaveQuotaFri"
              />
              <input
                #teamInput
                class="form-control w-25 text-center"
                type="text"
                id="team{{ day.date }}"
                [value]="teamLeaveQuotaFri"
                *ngIf="teamLeave"
              />
            </ng-template>
            <ng-template [ngIf]="day.day == 6">
              <input
                #tierInput
                class="form-control w-25 text-center"
                type="text"
                id="tier{{ day.date }}"
                [value]="tierLeaveQuotaSat"
              />
              <input
                #teamInput
                class="form-control w-25 text-center"
                type="text"
                id="team{{ day.date }}"
                [value]="teamLeaveQuotaSat"
                *ngIf="teamLeave"
              />
            </ng-template>
          </div>
        </ng-template>

        <div *ngFor="let date of selectMonthArr">
          <h5 class="bg-theme mt-4 p-2 text-center">
            {{ date | calendarDate: view + "ViewTitle":locale }}
          </h5>
          <mwl-calendar-month-view
            [viewDate]="date"
            [events]="events"
            [cellTemplate]="customCellTemplate"
          >
          </mwl-calendar-month-view>
        </div>
      </div>
    </div>
  </div>
</div>
