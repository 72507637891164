<div class="mb-3">
  <div class="card-header flexi-header">
    <h3>FILTER</h3>
  </div>
  <form>
    <div>
      <div class="card table-card">
        <div class="card-body flexi-body">
          <div class="manage-filter col-9">
            <div class="form-group w-100">
              <label for="startDate" class="pb-2 font-weight-bold"
                >Start Date</label
              >
              <div class="input-group">
                <input
                  class="form-control"
                  style="height: 35px"
                  placeholder="yyyy-mm-dd"
                  name="dp"
                  ngbDatepicker
                  #d="ngbDatepicker"
                  (dateSelect)="onDateSelect($event, 'from')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="d.toggle()"
                    type="button"
                  >
                    <i class="fa fa-calendar" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="form-group w-100 ml-4">
              <label for="endDate" class="pb-2 font-weight-bold"
                >End Date</label
              >
              <div class="input-group">
                <input
                  class="form-control"
                  style="height: 35px"
                  placeholder="yyyy-mm-dd"
                  name="dp"
                  ngbDatepicker
                  #c="ngbDatepicker"
                  (dateSelect)="onDateSelect($event, 'to')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="c.toggle()"
                    type="button"
                  >
                    <i class="fa fa-calendar" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <label class="pb-2 font-weight-bold">Business Unit</label>
              <ng-select
                class="custom"
                (change)="addBussinessUnit($event)"
                bindLabel="orgName"
                placeholder="Select Bussiness Unit"
                appendTo="body"
                [multiple]="false"
                [searchable]="true"
                [clearable]="true"
              >
                <ng-option
                  [value]="item._id"
                  *ngFor="let item of parentBuUnitDropDown"
                >
                  {{ item.orgName }}
                </ng-option>
              </ng-select>
            </div>

            <div class="filter-buttons">
              <button class="btn btn-add" (click)="onView()">View</button>
            </div>
            <div class="filter-buttons ml-2">
              <button class="btn preview-button" (click)="onExport()">
                Export
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="mb-3">
  <div class="card-header flexi-header p-3">
    <h3>CHALLENGE RESULT</h3>
    <div class="disqualifer-button">
      <button class="btn btn-warning mt-2" (click)="openDisqualier()">
        Disqualifier
      </button>
    </div>
    <div class="indivisual-checkbox">
      <div class="form-check">
        <label class="form-check-label checkbox-label" for="exampleCheck1"
          >INDIVIDUAL ACHIVEMENTS</label
        >
        <input
          type="checkbox"
          (change)="onClickCheckBox('Individual', $event)"
          [(ngModel)]="managePTable.individual"
          [checked]="managePTable.individual"
          class="form-check-input"
          id="exampleCheck1"
        />
      </div>
    </div>
    <div class="indivisual-checkbox" style="margin-right: 35px;" *ngIf="manageId?.isTeam == true">
      <div class="form-check">
        <label class="form-check-label checkbox-label" for="teamCheck"
          >TEAMS ACHIVEMENTS</label
        >
        <input
          type="checkbox"
          (change)="onClickCheckBox('Team', $event)"
          [(ngModel)]="managePTable.isTeam"
          [checked]="managePTable.isTeam"
          class="form-check-input"
          id="teamCheck"
        />
      </div>
    </div>
  </div>
  <form>
    <div>
      <div class="card table-card manage-filter p-3 challenges-card">
        <div class="card-body flexi-body w-50">
          <div class="card-header flexi-header p-3">
            <h3>SUMMARY</h3>
          </div>
          <form>
            <div>
              <div class="card table-card summary-card">
                <div class="card-body flexi-body">
                  <div class="challenges-body-points">
                    <div class="ml-3">Total points granted</div>
                    <div class="mr-3">{{ totalAmount }}</div>
                  </div>
                  <div class="challenges-body-staff">
                    <div class="ml-3">No of staff with points</div>
                    <div class="mr-3">{{ totalUsers }}</div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="card-body flexi-body w-50">
          <div class="card-header flexi-header p-3">
            <h3>DIRECT REWARDS</h3>
            <span>
              <button
                class="align-self-center btn btn-warning"
                (click)="openModal(importLogsReward, 'errorLog')"
                [disabled]='!errorLogPresent'
              >
                Import Error Logs
              </button>
              <button
                class="align-self-center btn btn-success mx-2"
                (click)="openModal(importReward, 'normal')"
              >
                Import Rewards
              </button>
            </span>
          </div>
          <form [formGroup]="directRewardCreateForm">
            <div>
              <div class="card table-card">
                <div
                  class="card-body flexi-body manage-filter"
                  style="height: 20vh"
                >
                  <div class="col-4">
                    <label class="font-weight-bold">Select User</label>
                    <ng-select
                      [(ngModel)]="directRewardSelecteduser"
                      [ngModelOptions]="{ standalone: true }"
                      class="custom"
                      bindLabel="name"
                      placeholder="Select Section"
                      appendTo="body"
                      [multiple]="false"
                      [searchable]="true"
                      [clearable]="directRewardSelecteduser ? true : false"
                      (search)="onSeachUsers($event)"
                      (scrollToEnd)="loadMoreUsers($event)"
                      (clear)="onClearUsers($event)"
                      [loading]="userDropDownLoading"
                    >
                      <ng-option
                        [value]="item"
                        *ngFor="let item of challangeUsers"
                      >
                        {{ item.name }}
                      </ng-option>
                    </ng-select>
                  </div>
                  <div class="form-group w-100">
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      placeholder="Enter comments here"
                      formControlName="comment"
                      rows="3"
                    ></textarea>
                  </div>
                </div>

                <div class="w-100 row rewards-input">
                  <div class="col-8">
                    <input
                      type="number"
                      class="form-control"
                      id="exampleFormControlInput1"
                      formControlName="points"
                      placeholder="Enter reward points"
                    />
                  </div>
                  <div class="col-4">
                    <button
                      type="submit"
                      class="btn btn-success"
                      (click)="addReward()"
                    >
                      Add rewards
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="card table-card table-body">
      <div class="card-body flexi-body">
        <div class="flex-column">
          <div class="d-flex justify-content-between mt-2 mb-3">
            <select
              class="custom-select"
              style="width: auto"
              name="pageSize"
              (change)="onLimitChangeUsers()"
              [(ngModel)]="managePTable.limit"
            >
              <option [ngValue]="10">10</option>
              <option [ngValue]="20">20</option>
              <option [ngValue]="30">30</option>
              <option [ngValue]="40">40</option>
              <option [ngValue]="50">50</option>
            </select>
            <div class="form-group form-inline mb-0">
              <input
                class="form-control ml-2"
                type="search"
                name="searchTerm"
                placeholder="Search"
                (search)="userssearchInput.next($event)"
                (keyup)="userssearchInput.next($event)"
                [(ngModel)]="managePTable.search"
              />
            </div>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col"
                  (sort)="onSortData($event, 'createdAt')" sortable="0">CHALLENGE</th>
                  <th scope="col"  (sort)="onSortData($event, 'name')" sortable="1">STAFF NAME</th>
                  <th scope="col"  (sort)="onSortData($event, 'staffId')" sortable="2">STAFF ID</th>
                  <th scope="col">TEAMS</th>
                  <th scope="col">PARENT BUSSINESS UNIT</th>
                  <th scope="col">POINTS EARNED</th>
                  <th scope="col" (sort)="onSortData($event, 'createdAt')" sortable="5">REWARDED ON</th>
                  <th scope="col">DIRECTLY REWARDED BY</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let challenge of manageChallengesTable">
                  <td>{{ challengeName }}</td>
                  <td>{{ challenge.name }}</td>
                  <td>{{ challenge.staffId }}</td>
                  <td>{{ challenge?.teamInfo?.name || "-"}}</td>
                  <td>{{ challenge.businessUnit }}</td>
                  <td class="text-center">{{challenge?.rewardPoints || ""}}
                    <span *ngIf="!!challenge?.rewardPoints"> ({{ challenge?.count}})</span>
                    <span *ngIf="!challenge?.rewardPoints"> {{ challenge?.count}}</span>
                  </td>
                  <td>{{ challenge.createdAt | date }}</td>
                  <td>{{ challenge.directRewardBy?.name }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="d-flex justify-content-between">
          <p class="align-self-center m-0 text-muted">
            Total Records
            <b class="text-dark">{{ managePTable.total }}</b>
          </p>
          <ngb-pagination
            [collectionSize]="managePTable.total"
            [(page)]="managePTable.page"
            [maxSize]="5"
            (pageChange)="onPageChangeUsers($event)"
            [pageSize]="managePTable.limit"
          >
          </ngb-pagination>
        </div>
      </div>
    </div>
  </form>
</div>

<ng-template #OpenQualifier>
  <div>
    <div class="modal-content">
      <div class="modal-body" style="padding: 10px; min-height: 100vh">
        <div class="card my-3 w-100">
          <div class="card-header flexi-header">
            <h3>DISQUALIFIER</h3>
            <button
              type="button"
              class="btn"
              aria-label="Close"
              (click)="closeModal()"
            >
              <i class="fa fa-times-circle fa-2x" aria-hidden="true"></i>
            </button>
          </div>
          <div>
            <div class="card table-card">
              <form [formGroup]="disqualifierCreateForm">
                <div class="col-md-6 pb-2">
                  <label class="pb-2 mt-2 font-weight-bold">Select User</label>
                  <ng-select
                    class="custom"
                    appendTo="body"
                    bindLabel="name"
                    (change)="addDisqualifier($event)"
                    placeholder="Select Section"
                    [multiple]="false"
                    [searchable]="true"
                    [loading]="userDropDownLoading"
                    [clearable]="disqualifySelectUser ? true : false"
                    (search)="onSeachUsers($event)"
                    (scrollToEnd)="loadMoreUsers($event)"
                    (clear)="onClearUsers($event)"
                    [(ngModel)]="disqualifySelectUser"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <ng-option
                      [value]="item"
                      *ngFor="let item of challangeUsers"
                    >
                      {{ item.name }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="manage-filter">
                  <div class="form-group w-100 p-3">
                    <div class="form-group date-container">
                      <div>
                        <label
                          class="pb-2 font-weight-bold"
                          for="exampleInputEmail1"
                          >From Date</label
                        >
                        <div class="input-group">
                          <input
                            class="form-control"
                            placeholder="yyyy-mm-dd"
                            style="max-width: 240px"
                            name="dp"
                            (dateSelect)="
                              onDateSelect($event, 'disQualifierfrom')
                            "
                            ngbDatepicker
                            #d="ngbDatepicker"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary calendar"
                              (click)="d.toggle()"
                              type="button"
                            >
                              <i class="fa fa-calendar" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div>
                        <label for="startDate" class="pb-2 font-weight-bold"
                          >To Date</label
                        >
                        <div class="input-group">
                          <input
                            class="form-control"
                            placeholder="yyyy-mm-dd"
                            style="max-width: 240px"
                            name="dp"
                            (dateSelect)="
                              onDateSelect($event, 'dsiQualifierTo')
                            "
                            ngbDatepicker
                            #c="ngbDatepicker"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary calendar"
                              (click)="c.toggle()"
                              type="button"
                            >
                              <i class="fa fa-calendar" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="add-button-container">
                      <button
                        class="btn btn-warning"
                        (click)="OncreateDisqualifier()"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <div class="card-header flexi-header">
            <h3>DISQUALIFIED USERS TABLE</h3>
          </div>
          <div class="card table-card">
            <div class="card-body flexi-body">
              <div class="flex-column">
                <div class="d-flex justify-content-between mt-2 mb-3">
                  <select
                    class="custom-select"
                    style="width: auto"
                    name="pageSize"
                  >
                    <option [ngValue]="10">10</option>
                    <option [ngValue]="20">20</option>
                    <option [ngValue]="30">30</option>
                    <option [ngValue]="40">40</option>
                    <option [ngValue]="50">50</option>
                  </select>
                </div>
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col" sortable="Tier 1">USER NAME</th>
                        <th scope="col" sortable="Tier 2">FROM DATE</th>
                        <th scope="col" sortable="Tier 3">TO DATE</th>

                        <th scope="col" sortable="Tier 4">ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of disqualiferTable">
                        <td>{{ item.userId.name }}</td>
                        <td>{{ item.fromDate | date }}</td>
                        <td>{{ item.toDate | date }}</td>
                        <td>
                          <button
                            class="btn btn-red-back"
                            (click)="deleteDisqualifyUser(item._id)"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="p-3">
              <div class="d-flex justify-content-between">
                <p>
                  Showing {{ disqualiferTableLength > 1 ? 1 : 0 }} to
                  {{ disqualiferTableLength }} of
                  {{ disqualiferTableLength }} entries
                </p>
                <ngb-pagination> </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #importReward let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Import Reward</h4>
    <div class="d-flex">
      <a
        target='_blank'
        type="button"
        href="./assets/importRewardsFormat.csv"
        class="btn btn-secondary btn-sm"
      >
        <i class="fa fa-download"></i>
        Download Sample
      </a>
      <button
        ngbAutofocus
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <form
          [formGroup]="importRewardsForm"
          class="d-flex flex-wrap"
          (ngSubmit)="uploadImportReward()"
        >
          <div class="bg-info header-buttons p-2 mb-2 rounded w-100">
            <input
              type="file"
              name="csv"
              class="btn-info rounded"
              accept=".csv"
              (change)="importRewardfile($event.target.files)"
            />
          </div>
          <button
            type="button"
            class="btn btn-success mx-auto"
            (click)="uploadImportReward()"
          >
            Upload
          </button>
        </form>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.dismiss('Cross click')"
    >
      Close
    </button>
  </div>
</ng-template>

<ng-template #importLogsReward let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">REWARD LOGS LIST</h4>
    <button
      ngbAutofocus
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="card">
      <div class="card-header flexi-header p-4">
        <h3>REWARD LOGS LIST</h3>
      </div>
      <div class="card-body">
        <div class="card table-card">
          <div class="card-body flexi-body">
            <div class="flex-column">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">
                        STATUS
                      </th>

                      <th
                        scope="col"
                     >
                        UPDATED USERS
                      </th>
                      <th
                        scope="col"
                      >
                        FAILED USERS
                      </th>
                      <th
                        scope="col"
                      >
                        DATE
                      </th>
                      <th
                        scope="col"
                      >
                        ERROR FILE PATH
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of logData">
                      <td>
                        Success
                      </td>

                      <td>{{ item?.success }}</td>
                      <td>{{ item?.fail }}</td>
                      <td>{{ item?.createdAt | date }}</td>
                      <td>{{ item?.failDetails }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <app-skliton-loading-table
                [numberOfCols]="5"
                [isTableLoading]="isTableLoading"
              ></app-skliton-loading-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.dismiss('Cross click')"
    >
      Close
    </button>
  </div>
</ng-template>
