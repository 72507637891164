<!-- <button mat-raised-button (click)="isLinear = !isLinear" id="toggle-linear">
  {{!isLinear ? 'Enable linear mode' : 'Disable linear mode'}}
</button> -->
<mat-horizontal-stepper [linear]="isLinear" #stepper>
  <mat-step [stepControl]="eventFormGroup">
    <form [formGroup]="eventFormGroup">
      <ng-template matStepLabel>EVENT TEASER </ng-template>
      <div class="card">
        <h5 class="card-header bg-theme">EVENT TEASER</h5>
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <label class="font-weight-500">Listing Type<span class="field-mandatory">*</span></label>
              <select
                class="w-100 form-control"
                formControlName="listingType"
                (change)="listingTypeChange()"
                placeholder="Select type"
              >
                <option *ngFor="let cnt of listingType" [ngValue]="cnt.value">
                  {{ cnt.name }}
                </option>
              </select>
              <!-- <span class="text-danger pl-1 f-12" *ngIf="
          count.get('selected_Name').hasError('required')
          ">
          Country Name is required
          </span> -->
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-12">
              <div class="form-group">
                <label class="font-weight-500" for="exampleInputEmail1"
                  >Teaser Title (Limit to 150 Character)<span class="field-mandatory">*</span>
                </label>

                <div [froalaEditor]="options" formControlName="title"></div>

                <!-- <div
                    *ngIf="submitted && f.description.errors"
                    class="invalid-feedback"
                  >
                    <small *ngIf="f.description.errors.required">
                      Role Description is required
                    </small>
                  </div> -->
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <div
              class="col-md-4"
              *ngIf="eventFormGroup.get('listingType').value != 1"
            >
              <p class="font-weight-bold m-0">Teaser Image<span class="field-mandatory">*</span></p>
              <div class="custom-file mt-0 pt-4 pb-4">
                <input
                  class="btn custom-file-input cursor-pointer"
                  [disabled]="isIconLoading"
                  (click)="openImageCropUpload('eventTeaser')"
                  id="c"
                />
                <label class="custom-file-label" for="i">
                  <i
                    class="fa"
                    [ngClass]="{
                      'fa-spin fa-spinner fa-2x': isIconLoading,
                      ' fa-plus-circle fa-2x': !isIconLoading
                    }"
                    aria-hidden="true"
                  ></i>
                </label>
              </div>
            </div>
            <div class="col-md-4" *ngIf="eventFormGroup.get('image').value">
              <div class="image-with-delete-icon" style="width: 200px">
                <i
                  class="fa fa-close"
                  title="Delete"
                  (click)="onDeleteIcon('eventTeaser')"
                ></i>
                <img
                  [src]="validateURLImage(eventFormGroup.get('image').value)"
                  width="200px"
                  height="180px"
                  alt="Image Preview"
                  crossorigin="anonymous"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12" *ngIf="eventFormGroupErr">
        <p class="text-danger font-weight-bold">
          Please fill up all the fields
        </p>
      </div>
      <div class="col-md-2">
        <div class="d-flex justify-content-between">
          <button
            class="btn btn-submit btn-block p-2 m-1"
            (click)="
              eventFormGroup.valid && stepper.next(); eventFormGroupError()
            "
          >
            Next
          </button>
        </div>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="contentFormGroup">
    <form [formGroup]="contentFormGroup">
      <ng-template matStepLabel>CONTENT</ng-template>
      <div class="card mb-2">
        <h5 class="card-header bg-theme">CONTENT</h5>
        <div class="card-body">
          <div class="row mt-4">
            <div class="col-md-12">
              <div class="form-group">
                <label class="font-weight-500" for="exampleInputEmail1"
                  >Title (Limit to 150 Character)<span class="field-mandatory">*</span>
                </label>

                <div [froalaEditor]="options" formControlName="title"></div>

                <!-- <div
                    *ngIf="submitted && f.description.errors"
                    class="invalid-feedback"
                  >
                    <small *ngIf="f.description.errors.required">
                      Role Description is required
                    </small>
                  </div> -->
              </div>
            </div>
          </div>
          <div
            class="row mt-0"
            *ngIf="eventFormGroup.get('listingType').value != 1"
          >
            <div class="col-md-12">
              <div class="row p-3">
                <div class="form-group form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    formControlName="isTeaserImage"
                    (change)="sameTeaserImg()"
                  />
                  <label class="form-check-label font-weight-500"
                    >Same as teaser photo<span class="field-mandatory">*</span></label
                  >
                </div>
              </div>
            </div>
          </div>

          <div
            class="row mt-4"
            *ngIf="contentFormGroup.get('isTeaserImage').value != true"
          >
            <div class="col-md-4">
              <h6>OR Upload another Image<span class="field-mandatory">*</span></h6>
              <div class="custom-file mt-0 pt-4 pb-4">
                <input
                  class="btn custom-file-input cursor-pointer"
                  [disabled]="isIconLoading"
                  (click)="openImageCropUpload('contentTeaser')"
                  id="c"
                />
                <label class="custom-file-label" for="i">
                  <i
                    class="fa"
                    [ngClass]="{
                      'fa-spin fa-spinner fa-2x': isIconLoading,
                      ' fa-plus-circle fa-2x': !isIconLoading
                    }"
                    aria-hidden="true"
                  ></i>
                </label>
              </div>
            </div>
            <div class="col-md-4" *ngIf="contentFormGroup.get('image').value">
              <div class="image-with-delete-icon" style="width: 150px">
                <i
                  class="fa fa-close"
                  title="Delete"
                  (click)="onDeleteIcon('contentTeaser')"
                ></i>
                <img
                  [src]="validateURLImage(contentFormGroup.get('image').value)"
                  width="100px"
                  height="80px"
                  alt="Image Preview"
                  crossorigin="anonymous"
                />
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-md-12">
              <div class="form-group">
                <label class="font-weight-500" for="exampleInputEmail1"
                  >Event Content (Limit to 150 Character)<span class="field-mandatory">*</span>
                </label>

                <div
                  [froalaEditor]="otheroptions"
                  formControlName="content"
                ></div>
                <!-- <div
                    *ngIf="submitted && f.description.errors"
                    class="invalid-feedback"
                  >
                    <small *ngIf="f.description.errors.required">
                      Role Description is required
                    </small>
                  </div> -->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
              <label class="font-weight-500">Organiser Name<span class="field-mandatory">*</span> </label>
              <input
                type="text"
                class="form-control"
                formControlName="organizerName"
              />
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
              <label class="font-weight-500">Place / Address<span class="field-mandatory">*</span></label>
              <input
                type="text"
                class="form-control"
                formControlName="address"
              />
            </div>
            <div
              class="col-xs-6 col-sm-6 col-md-4 col-lg-4 radio-container"
              style="
                display: flex;
                justify-content: space-between;
                margin-top: 30px;
              "
            >
              <div class="radio">
                <input
                  id="sameday"
                  [value]="'single'"
                  type="radio"
                  formControlName="eventType"
                />
                <label for="sameday" class="font-weight-500">
                  Same Day Event<span class="field-mandatory">*</span>
                </label>
              </div>
              <div class="radio radio-danger radio-inline ml-1">
                <input
                  type="radio"
                  [value]="'multi'"
                  formControlName="eventType"
                />
                <label for="multiday" class="font-weight-500">
                  Multi Days Event<span class="field-mandatory">*</span>
                </label>
              </div>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-4">
              <div class="form-group">
                <label class="font-weight-500">Start Date & Time<span class="field-mandatory">*</span> </label>
                <app-date-time-picker
                  formControlName="startDate"
                ></app-date-time-picker>
              </div>
            </div>
            <div
              class="col-md-4"
              *ngIf="contentFormGroup.get('eventType').value == 'multi'"
            >
              <div class="form-group">
                <label class="font-weight-500">End Date & Time <span class="field-mandatory">*</span></label>
                <app-date-time-picker
                  formControlName="endDate"
                ></app-date-time-picker>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="col-md-12" *ngIf="contentFormGroupErr">
          <p class="text-danger font-weight-bold">
            Please fill up all the fields<span class="field-mandatory">*</span>
          </p>
        </div>
        <div class="col-md-4">
          <div class="d-flex justify-content-between">
            <button class="btn btn-clear btn-block p-2 m-1" matStepperPrevious>
              Back
            </button>
            <button
              class="btn btn-submit btn-block p-2 m-1"
              (click)="
                contentFormGroup.valid && stepper.next();
                getformdata();
                contentFormGroupError()
              "
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="publicationFormGroup">
    <form [formGroup]="publicationFormGroup">
      <ng-template matStepLabel>PUBLICATION</ng-template>
      <div class="card mb-2">
        <h5 class="card-header bg-theme">PUBLICATION</h5>
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <label class="font-weight-500"> Channel <span class="field-mandatory">*</span></label>
              <ng-select
                (change)="getCategories($event)"
                [items]="channelArray"
                bindLabel="name"
                bindValue="_id"
                [searchable]="true"
                placeholder="Channel"
                formControlName="channelId"
              >
              </ng-select>
            </div>
            <div class="col-md-4">
              <label class="font-weight-500"> Category<span class="field-mandatory">*</span> </label>
              <ng-select
                (change)="setCategories($event)"
                [items]="categoriesArray"
                bindLabel="name"
                bindValue="_id"
                [searchable]="true"
                placeholder="Category"
                formControlName="categoryId"
              >
              </ng-select>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-4">
              <div class="form-group">
                <label class="font-weight-500"> Publish Date & time<span class="field-mandatory">*</span> </label>
                <app-date-time-picker
                  formControlName="startDate"
                ></app-date-time-picker>
              </div>
            </div>
            <div class="col-md-4 mt-1">
              <div class="form-group">
                <label class="font-weight-500"> Expire Date & time <span class="field-mandatory">*</span></label>
                <app-date-time-picker
                  formControlName="endDate"
                ></app-date-time-picker>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 form-group">
              <label for="name" class="headerStyleNew font-weight-500"
                >Do you require staff to RSVP for event? <span class="field-mandatory">*</span></label
              ><br />
              <div class="radio radio-danger radio-inline">
                <input
                  type="radio"
                  value="true"
                  formControlName="isRSVPRequired"
                />
                <label for="radio1"> Yes </label>
              </div>
              <div class="radio radio-danger radio-inline">
                <input
                  type="radio"
                  value="false"
                  formControlName="isRSVPRequired"
                />
                <label for="radio1"> No </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="col-md-12" *ngIf="publicationFormGroupErr">
          <p class="text-danger font-weight-bold">
            Please fill up all the fields
          </p>
        </div>
        <div class="col-md-4">
          <div class="d-flex justify-content-between">
            <button class="btn btn-clear btn-block p-2 m-1" matStepperPrevious>
              Back
            </button>
            <button
              class="btn btn-submit btn-block p-2 m-1"
              (click)="
                publicationFormGroup.valid  &&  move();publicationFormGroupError();" >
              Next
            </button>
          </div>
        </div>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="sessionsForm" [aria-labelledby]="publicationFormGroup.value.isRSVPRequired == 'false' ? 'step_disable' : '' " >
    <form [formGroup]="sessionsForm">
      <ng-template matStepLabel>SESSIONS </ng-template>
      <div class="card mb-2">
        <h5 class="card-header bg-theme">SESSIONS</h5>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12 form-group">
              <label for="name" class="headerStyleNew font-weight-500"
                >Do you need to limit the number of participants?<span class="field-mandatory">*</span> </label
              ><br />
              <div class="radio radio-danger radio-inline">
                <input
                  type="radio"
                  formControlName="isLimitRequired"
                  [value]="true"
                />
                <label> Yes </label>
                <input
                  formControlName="isLimitRequired"
                  type="radio"
                  class="ml-2"
                  [value]="false"
                />
                <label> No </label>
              </div>
            </div>

            <div class="col-md-12 form-group">
              <label for="name" class="headerStyleNew font-weight-500">
                Do you require attendance taking?<span class="field-mandatory">*</span> </label
              ><br />
              <div class="radio radio-danger radio-inline">
                <input
                  type="radio"
                  id="radio5"
                  formControlName="isAttendanceRequired"
                  [value]="true"
                  (change)="getAttNo()"
                />
                <label for="radio5"> Yes </label>
                <input
                  class="ml-2"
                  type="radio"
                  ng-checked="true"
                  id="radio6"
                  [value]="false"
                  formControlName="isAttendanceRequired"
                  (change)="getAttNo()"
                />
                <label for="radio6"> No </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 form-group">
              <div
                class="row"
                [class.hidden]="
                  this.sessionsForm.controls.isAttendanceRequired.value ==
                    false ||
                  this.sessionsForm.controls.isAttendanceRequired.value == ''
                "
              >
                <div class="col-md-7">
                  <label
                    style="line-height: 2.8"
                    class="headerStyleNew main font-weight-500"
                    >Number of Attendance taking required per session<span class="field-mandatory">*</span></label
                  >
                </div>
                <div class="col-md-2">
                  <input
                    formControlName="totalAttendanceTaking"
                    type="number"
                    min="1"
                    class="form-control"
                    [value]="1"
                    [attr.required]="
                      this.sessionsForm.get('isAttendanceRequired').value ==
                      true
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-2">
        <h5 class="card-header bg-theme">CREATE SESSIONS</h5>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Start Time<span class="field-mandatory">*</span></th>
                  <th>End Time<span class="field-mandatory">*</span></th>

                  <th scope="col">Location<span class="field-mandatory">*</span></th>
                  <th
                    scope="col"
                    *ngIf="sessionsForm.get('isLimitRequired').value"
                  >
                    Participants<span class="field-mandatory">*</span>
                  </th>
                </tr>
              </thead>
              <tbody formArrayName="Rows">
                <tr
                  *ngFor="
                    let itemrow of formArr.controls;
                    let i = index;
                    let l = last
                  "
                  [formGroupName]="i"
                >
                  <td>{{ i + 1 }}</td>

                  <td>
                    <div>
                      <app-date-time-picker
                        formControlName="startDate"
                      ></app-date-time-picker>
                    </div>
                  </td>

                  <td>
                    <app-date-time-picker
                      formControlName="endDate"
                    ></app-date-time-picker>
                  </td>
                  <td>
                    <input
                      type="title"
                      class="form-control"
                      placeholder="Location"
                      formControlName="location"
                    />
                  </td>
                  <td *ngIf="sessionsForm.get('isLimitRequired').value">
                    <input
                      type="title"
                      class="form-control"
                      placeholder="Participant"
                      formControlName="totalParticipantPerSession"
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <button class="btn btn-submit pull-right" (click)="addMoreFelds()">
              Add more
            </button>
            <!-- <button
                class="btn btn-sumbit pull-right"
                (click)="addMoreFelds1()"
              >
                Add 1
              </button> -->
          </div>
          <div class="row">
            <div class="col-md-12 form-group">
              <label class="headerStyleNew font-weight-500">
                Do you want to limit the No. of sessions that staff can RSVP?<span class="field-mandatory">*</span> </label
              ><br />

              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  name="isLimitRsvp"
                  type="radio"
                  [value]="true"
                  formControlName="isLimitRsvp"
                  (change)="validateMaxRsvp($event)"
                />
                <label class="form-check-label">Yes</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  name="isLimitRsvp"
                  type="radio"
                  [value]="false"
                  formControlName="isLimitRsvp"
                  (change)="validateMaxRsvp($event)"
                />
                <label class="form-check-label">No</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 form-group">
              <div
                class="row"
                [class.hidden]="
                  this.sessionsForm.controls.isLimitRsvp.value == false ||
                  this.sessionsForm.controls.isLimitRsvp.value == ''
                "
              >
                <div class="col-md-7">
                  <label
                    style="line-height: 2.8"
                    class="headerStyleNew main font-weight-500"
                    >Max Number of RSVP staff can do? (It can be less than or
                    same as the total number of sessions.)<span class="field-mandatory">*</span></label
                  >
                </div>
                <div class="col-md-2">
                  <input
                    formControlName="maxNoRSVP"
                    type="number"
                    class="form-control"
                    (keyup)="setValid()"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="col-md-12" *ngIf="sessionsFormErr">
          <p class="text-danger font-weight-bold">
            Please fill up all the fields
          </p>
        </div>
        <div class="col-md-4">
          <div class="d-flex justify-content-between">
            <button class="btn btn-clear btn-block p-2 m-1" matStepperPrevious>
              Back
            </button>
            <button
              class="btn btn-submit btn-block p-2 m-1"
              (click)="
                sessionsForm.valid && stepper.next(); sessionsFormError()
              "
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="eventBoardFormGroup">
    <form [formGroup]="eventBoardFormGroup">
      <ng-template matStepLabel>EVENT BOARD </ng-template>
      <div class="card mb-2 w-100">
        <h5 class="card-header bg-theme">EVENT BOARD</h5>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12 form-group">
              <label for="name" class="headerStyleNew font-weight-500">
                Do you require Event Board? <span class="field-mandatory">*</span></label
              ><br />
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  [value]="true"
                  formControlName="isEventWallRequired"
                  (change)="validateEventForm()"
                />
                <label class="form-check-label">Yes</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  [value]="false"
                  formControlName="isEventWallRequired"
                  (change)="validateEventForm()"
                />
                <label class="form-check-label">No</label>
              </div>
            </div>
          </div>
          <ng-template
            [ngIf]="
              eventBoardFormGroup.controls.isEventWallRequired.value == true
            "
          >
            <div class="row">
              <div class="col-md-6 mt-1">
                <div class="form-group">
                  <label class="pb-2 font-weight-500" for="exampleInputEmail1"
                    >Social Wall Group Name (Limit to 100 characters)<span class="field-mandatory">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Wall name"
                    formControlName="wallName"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-md-4">
                <h6>Content Image<span class="field-mandatory">*</span></h6>
                <div class="custom-file mt-0 pt-4 pb-4">
                  <input
                    class="btn custom-file-input cursor-pointer"
                    [disabled]="isIconLoading"
                    (click)="openImageCropUpload('eventBoard')"
                    id="c"
                  />
                  <label class="custom-file-label" for="i">
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-spin fa-spinner fa-2x': isIconLoading,
                        ' fa-plus-circle fa-2x': !isIconLoading
                      }"
                      aria-hidden="true"
                    ></i>
                  </label>
                </div>
              </div>
              <div
                class="col-md-4"
                *ngIf="eventBoardFormGroup.get('eventWallLogoImage').value"
              >
                <div class="image-with-delete-icon" style="width: 150px">
                  <i
                    class="fa fa-close"
                    title="Delete"
                    (click)="onDeleteIcon('eventBoard')"
                  ></i>
                  <img
                    [src]="validateURLImage(eventBoardFormGroup.get('eventWallLogoImage').value)"
                    width="100px"
                    height="80px"
                    alt="Image Preview"
                    crossorigin="anonymous"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="pb-2 font-weight-500">Start Date & Time<span class="field-mandatory">*</span></label>
                  <app-date-time-picker
                    formControlName="startDate"
                  ></app-date-time-picker>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="pb-2 font-weight-500"> End Date & Time<span class="field-mandatory">*</span></label>
                  <app-date-time-picker
                    formControlName="endDate"
                  ></app-date-time-picker>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
      <div>
        <div class="col-md-12" *ngIf="eventBoardFormGroupErr">
          <p class="text-danger font-weight-bold">
            Please fill up all the fields
          </p>
        </div>
        <div class="col-md-4">
          <div class="d-flex justify-content-between">
            <button class="btn btn-clear btn-block p-2 m-1"   (click)="moveBackEvent()">
              Back
            </button>
            <button
              class="btn btn-submit btn-block p-2 m-1"
              (click)="
                eventBoardFormGroup.valid && stepper.next();
                eventBoardFormGroupError()
              "
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="adminstrationFormGroup">
    <form [formGroup]="adminstrationFormGroup">
      <ng-template matStepLabel>ADMINISTRATION </ng-template>
      <div class="card mb-2 w-100">
        <h5 class="card-header bg-theme">ADMINISTRATION</h5>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <form [formGroup]="sessionsForm">
                <table
                  class="table"
                  *ngIf="
                    publicationFormGroup.get('isRSVPRequired').value == 'true'
                  "
                >
                  <thead>
                    <tr>
                      <th scope="col">Location</th>
                      <th scope="col">Session period</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      formArrayName="Rows"
                      *ngFor="let field of formArr.controls; let index = index"
                    >
                      <td>
                        <span class="text-items" [formGroupName]="index">{{
                          field.get("location").value
                        }}</span>
                      </td>
                      <td>
                        <p>
                          {{
                            field.get("startDate").value
                              | date: "d/M/yyyy HH:mm"
                          }}
                          to
                          {{
                            field.get("endDate").value | date: "d/M/yyyy HH:mm"
                          }}
                        </p>
                      </td>
                      <td>
                        <button class="btn btn-clear" (click)="deleteRow(index)">Cancel</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p
                  class="text-danger"
                  *ngIf="
                    publicationFormGroup.get('isRSVPRequired').value != 'true'
                  "
                >
                  Please Note: Since there are NO sessions, there is no need to
                  select Admins.
                </p>
              </form>
            </div>
            <div class="col-md-3">
              <label class="font-weight-500">Admin </label>
              <ng-select
                (change)="addLoginFields($event)"
                class="custom"
                [class.disabled]="
                  publicationFormGroup.get('isRSVPRequired').value != 'true'
                "
                bindLabel="name"
                [virtualScroll]="false"
                placeholder="Select Admin"
                appendTo="body"
                [multiple]="false"
                [searchable]="true"
                [clearable]="true"
                [loading]="CustomFieldDropDownLoading"
                (search)="onSeachCustomField($event)"
                (scrollToEnd)="loadMoreCustomFieldItems($event)"
                (clear)="onClearCustomFieldDropdown($event)"
              >
                <ng-option
                  [value]="item"
                  *ngFor="let item of fieldNeedsToUpdate"
                  [disabled]="
                    publicationFormGroup.get('isRSVPRequired').value != 'true'
                  "
                >
                  {{ item.name }}
                </ng-option>
              </ng-select>
            </div>
            <div class="col-md-3 p-4">
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  flex-wrap: wrap;
                  margin-top: 3px;
                "
              >
                <div
                  formArrayName="admin"
                  *ngFor="
                    let field of LoginFieldsControl.controls;
                    let index = index
                  "
                >
                  <span class="text-items list-ui" [formGroupName]="index"
                    >{{ field.get("string").value }}
                    <img
                      class="ml-3"
                      (click)="deleteSelectedFields(index)"
                      src="./assets/images/cancel.png"
                      width="20px"
                      height="20px"
                      crossorigin="anonymous"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="col-md-12" *ngIf="adminstrationFormGroupErr">
          <p class="text-danger font-weight-bold">
            Please fill up all the fields
          </p>
        </div>
        <div class="col-md-6">
          <div class="d-flex justify-content-between">
            <button class="btn btn-clear btn-block p-2 m-1" matStepperPrevious>
              Back
            </button>
            <button
              class="btn btn-success btn-block p-2 m-1"
              [disabled]="!adminstrationFormGroup.valid"
              (click)="onSubmitEvents(1); adminstrationFormGroupError()"
            >
              {{ eventId ? "Update Event" : " Create Event" }}
              <span
                *ngIf="eventLoader"
                class="spinner-border spinner-border-sm ml-1"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
            <button
              class="btn btn-info btn-block p-2 m-1"
              (click)="saveAsDraft()"
            >
              Save As Draft
            </button>
            <button
              class="btn btn-submit btn-block p-2 m-1"
              (click)="addDepartment()"
            >
              Preview
            </button>
          </div>
        </div>
      </div>
    </form>
  </mat-step>
</mat-horizontal-stepper>
<ng-template #AddDepartment let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Article Preview 3.75x6.66(inch)
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-content mobile-content">
    <div class="modal-body">
      <div class="smartphone">
        <div class="container py-3 container-scroll">
          <div class="row">
            <div class="col">
              <img
                src="{{ editEvent.data.content.image || editEvent.data.teaser.image }}"
                alt="Event Image"
                crossorigin="anonymous"
                class="img-fluid rounded"
              />
              <p class="text-muted my-1">
                {{ channel?.name }}/ {{ category?.name }}
              </p>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-6">
              <div class="form-group">
                <label class="d-flex align-items-center">
                  <span class="material-icons text-theme mr-1">
                    date_range
                  </span>
                  Date & Time
                </label>
                <input
                  type="text"
                  class="form-control"
                  readonly
                  value="{{
                    editEvent.data.publish.startDate | date: 'medium'
                  }}"
                />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label class="d-flex align-items-center"
                  ><span class="material-icons text-theme mr-1">
                    location_on </span
                  >Location</label
                >
                <input
                  type="text"
                  class="form-control"
                  readonly
                  value="{{ editEvent.data.content.address }}"
                />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label class="d-flex align-items-center"
                  ><span class="material-icons text-theme mr-1"> person </span
                  >Organiser</label
                >
                <input
                  type="text"
                  class="form-control"
                  readonly
                  value="{{ editEvent.data.content.organizerName }}"
                />
              </div>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-md-12">
              <div
                [froalaView]="editorContent"
                [innerHTML]="contentFormGroup.get('title').value | safeHtml"
              ></div>

              <p>
                <span class="d-block ng-binding"
                  >Authored By <br />{{ userName }}</span
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #AddImageCropper let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Upload Image
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span style="font-size: 30px;" aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="container">
        <div class="row" style="margin-top: 5%;">
          <div class="row">
            <div class="text-center col-md-12">
              <input type="file" #myInput class="text-primary" accept="image/png, image/gif, image/jpeg" (change)="fileChangeEvent($event)" />
              <button class="btn btn-info" (click)="clear()">Clear</button>
            </div>
          </div>
        </div>
        <hr class="horizontal-line" />
        <div *ngIf="!!imageChangedEvent">
          <div class="row text-center p-2 m-2 border-1 bg-wrapper">
            <div class="col-md-2">
              <button (click)="toggleContainWithinAspectRatio()" mat-mini-fab color="warning">
                <mat-icon>{{containWithinAspectRatio?'aspect_ratio':'image_aspect_ratio'}}</mat-icon>
              </button>
            </div>
            <div class="col-md-2">
              <button (click)="resetImage()" mat-mini-fab color="warning">
                <mat-icon>refresh</mat-icon>
              </button>
            </div>
            <div class="col-md-2">
              <button (click)="flipHorizontal()" mat-mini-fab color="success">
                <mat-icon>swap_horiz</mat-icon>
              </button>
            </div>
            <div class="col-md-2">
              <button (click)="flipVertical()" mat-mini-fab color="success">
                <mat-icon>swap_vert</mat-icon>
              </button>
            </div>
            <div class="col-md-2">
              <button (click)="rotateLeft()" mat-mini-fab color="success">
                <mat-icon>rotate_left</mat-icon>
              </button>
            </div>
            <div class="col-md-2">
              <button (click)="rotateRight()" mat-mini-fab color="success">
                <mat-icon>rotate_right</mat-icon>
              </button>
            </div>
          </div>
          <div class="row" style="margin-top: 5%;">
            <div class="text-center col-5">
              <h5>Crop Image</h5>
              <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="4 / 3"
                [cropperMinWidth]="128" [roundCropper]="false" [canvasRotation]="canvasRotation" [transform]="transform"
                [alignImage]="'left'" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                (cropperReady)="cropperReady($event)" (loadImageFailed)="loadImageFailed()">
              </image-cropper>
            </div>
            <div class="text-center myImg col-7">
              <h5>Preview</h5>
              <img [src]="croppedImage" />
            </div>
          </div>
          <div class="row text-center p-2 m-2  border-1 bg-wrapper">
            <div class="col-md-6">
              <button (click)="zoomOut()" mat-mini-fab color="success">
                <mat-icon>zoom_out</mat-icon>
              </button>
            </div>
            <div class="col-md-6">
              <button (click)="zoomIn()" mat-mini-fab color="success">
                <mat-icon>zoom_in</mat-icon>
              </button>
            </div>
          </div>
          <div class="row text-center justify-content-center pt-4">
            <button class="btn btn-lg btn-success" (click)="iconUpload(imageCategory)">
              Upload
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
