import {
  Component,
  OnInit,
  ViewChild,
  Renderer2,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { CompanySetupService } from 'src/app/users/service/company-setup/company-setup.service';
import { SetupService } from 'src/app/users/service/operations/setup.service';

@Component({
  selector: 'app-plan-shift',
  templateUrl: './plan-shift.component.html',
  styleUrls: ['./plan-shift.component.scss'],
})
export class PlanShiftComponent implements OnInit, AfterViewInit {
  @ViewChild('sidemenu') sidemenu: ElementRef;
  isView: boolean;
  activeId = 1;
  isEdit: boolean;
  isCreate: boolean = true;
  delete = true;
  routeSub;
  buOption: any;
  shiftdata: any;
  selectedBuName: any;
  Bu: any;
  dashboardEvent: any;
  headerData: any;
  tier: any;
  buLoader: boolean = true;
  buForShift: any;
  planBuId: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    private setupService: SetupService,
    private toastrService: ToastrService,
    private companyService: CompanySetupService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.onSameUrlNavigation = 'reload';
    this.routeSub = this.route.data.subscribe((val) => {
      this.isView = val.isView;
    });
  }

  ngOnInit(): void {
    this.getAllBuForShift();
    this.getTier();
  }

  getAllBuForShift() {
    this.buLoader = true;
    const buSubscribe = this.setupService
      .getAllBuShiftModule()
      .pipe(
        finalize(() => {
          this.buLoader = false;
          buSubscribe.unsubscribe();
        })
      )
      .subscribe(
        (res: any) => {
         // if (res.success) {
            this.buForShift = res?.data?.businessUnitList;
         /* } else {
            this.buForShift = [];
          }*/
        },
        (error) => {
          this.buForShift = [];
        }
      );
  }

  ngAfterViewInit() {
    if (!this.isView) {
      setTimeout(() => {
        this.renderer.addClass(this.sidemenu.nativeElement, 'animate');
      }, 50);
    }
  }

  getTier() {
    const tier = this.companyService
      .getcompanySetting()
      .pipe(
        finalize(() => {
          tier.unsubscribe();
        })
      )
      .subscribe((res) => {
        this.tier = res.data.opsGroup.tierType;
        console.log("tier in parent component",this.tier,res);
      });
  }

  ngOnDestroy(): void {
    this.routeSub.unsubscribe();
  }

  viewClicked() {
    if (this.planBuId) {
      this.Bu = this.planBuId;
      this.isView = true;
    } else {
      this.toastrService.warning('Please select Business Unit', 'Warning');
    }
  }

  activeTab(e) {
    this.activeId = e;
  }

  getshiftfor(e) {
    this.shiftdata = e;
  }

  dashboardData(e) {
    this.headerData = e;
  }
}
