import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { CompanyService } from 'src/app/admin/service/companyservice/company.service';
import { getAssignedUsers, getCleanObj } from 'src/app/core/utils/helper';
import { BussinessUnitService } from 'src/app/users/service/bussiness-unit/bussiness-unit.service';
import { CustomFieldService } from 'src/app/users/service/custom-field/custom-field.service';
import { UsersService } from 'src/app/users/service/user-service/users.service';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'assign-users',
  templateUrl: './assign-users.component.html',
  styleUrls: ['./assign-users.component.scss']
})
export class AssignUsersComponent implements OnInit {
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  @Input() toastMessage;
@Input() moduleName = '';
  planBuUnitDropDownLoading: boolean = false;
  apppoinmentDropDownLoading: boolean = false;
  skillSetDropDownLoading: boolean = false;
  adminDropDownLoading: boolean = false;
  showSubSkillSet: boolean = false;
  CustomFieldDropDownLoading: boolean = false;
  isNextBtnActive: boolean = false;
  @Output() isNextBtnActiveEvent = new EventEmitter<boolean>()
  addedCustomfield;
  buDropDownToClear = null;
  apoinemntDropDownToClear = null;
  skillSetDropDownToClear = null;
  subSkillSetDropDownToClear=null;
  customFeildDropDownToClear = null;
  adminDropDownToClear = null;
  authorDropDownToClear = null;
  userDetailsEditIndex: number;
  isAssignUser: boolean = false;
  isUserDetailsEdited: boolean = false;
  userDetailsForm: FormGroup;
  isDisabledFormFields: boolean =  true;
  planBuUnitDropDown: any[];
  @Input() userDetails: any[] = [];
  appoinmentDropDown: any[];
  skillSetDropDown: any[];
  subSkillSetDropDown: any[];
  bussinesUnitplanPTable: { sortWith: string; sortBy: string; data: any[]; page: number; limit: number; search: string; filter: { type: string; }; };
  appoinmentPTable: { sortWith: string; sortBy: string; data: any[]; page: number; total: number; limit: number; search: string; };
  skillSetPTable: { sortWith: string; sortBy: string; data: any[]; page: number; total: number; limit: number; search: string; };
  CustomFieldPTable: { sortWith: string; sortBy: string; data: any[]; page: number; total: number; limit: number; search: string; };
  userPTable: { sortWith: string; sortBy: string; data: any[]; page: number; limit: number; search: string; };
  assignUserDropDown: { sortWith: string; sortBy: string; data: any[]; page: number; limit: number; search: string; };

  adminPTable: { sortWith: string; sortBy: string; data: any[]; page: number; limit: number; search: string; };
  authorLoading: boolean;
  userDropDown: any[];
  adminLoading: boolean;
  adminDropDownPlaceholderText: string;
  adminDropDown: any;
  fieldNeedsToUpdate: any[];
  wallCreateForm: any;
  isBoardEdited: boolean;
  boardId: any;
  activeId: number;
  overViewEvent: any;
  skillSetList: any;
  allBuUnitID: any[];
  @Input() isAdminView: boolean = true
  @Input() isLoading: boolean = true
  @Input() buttonText: string
  @Output() onSubmitAssignUsers = new EventEmitter();
  @Output() showCreateButton = new EventEmitter();
  @Output() hideCreateButton = new EventEmitter();
  @Output() getAssignUsersData = new EventEmitter();
  constructor(private toastService: ToastrService,
    private userService: UsersService,
    private companyService: CompanyService,
    private formBuilder: FormBuilder,
    private buUnit: BussinessUnitService,
    private customFieldService: CustomFieldService,
  ) { }

  ngOnInit() {
    this.inituserDetailsFrom()
    this.bussinesUnitplanPTable = {
      sortWith: 'createdAt',
      sortBy: 'desc',
      data: [],
      page: 1,

      limit: 8,

      search: '',
      filter: {
        type: 'plan',
      },
    };
    this.appoinmentPTable = {

      sortWith: 'createdAt',
      sortBy: 'desc',
      data: [],
      page: 1,
      total: 0,
      limit: 10,

      search: '',

    };

    this.skillSetPTable = {
      sortWith: 'createdAt',
      sortBy: 'desc',
      data: [],
      page: 1,
      total: 0,
      limit: 10,

      search: '',

    };

    this.CustomFieldPTable = {

      sortWith: 'createdAt',
      sortBy: 'desc',
      data: [],
      page: 1,
      total: 0,
      limit: 10,

      search: '',

    };
    this.userPTable = {

      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,

      limit: 10,

      search: '',

    };
    this.assignUserDropDown = {
      sortWith: 'createdAt',
      sortBy: 'desc',
      data: [],
      page: 0,

      limit: 10,

      search: '',
    }
    this.adminPTable = {

      sortWith: 'createdAt',
      sortBy: 'desc',
      data: [],
      page: 1,

      limit: 10,

      search: '',

    };
    // this.onClearAllFrom();
    this.loadBussinessPlanUnit();
    //this.loadAppoinment([], false);
    this.loadSkillSet();
    this.loadCustomField();
    if(this.userDetails && this.userDetails?.length>0){
      this.showBackandNextBtn();
    }

  }

  showBackandNextBtn(){
    this.isNextBtnActive = true;
    this.isNextBtnActiveEvent.emit(this.isNextBtnActive);
    this.showCreateButton.emit();
  }

  inituserDetailsFrom() {
    return (this.userDetailsForm = this.formBuilder.group({
      businessUnits: this.formBuilder.array([], [Validators.required]),
      buFilterType: ['',Validators.required],
      appointments: this.formBuilder.array([]),
      subSkillSets: this.formBuilder.array([]),
      user: this.formBuilder.array([]),
      admin: this.formBuilder.array([]),
      allBuToken: false,
      allBuTokenStaffId: '',
      customField: this.formBuilder.array([]),
    }));
  }

  //getUserS Based on bu unit

  async getUserBasedOnBuUnits(value?: string, concat= false) {
    this.authorLoading = true
    const AutherControl = this.authorControl;
    const AdminControl = this.adminControl;
    value || this.clearFormArray(AutherControl)
    value || this.clearFormArray(AdminControl)
    let array = await this.BussinessUnitControl.value.map((item) => item._id);
    if(array.length === 0){
      this.userDropDown = [];
      this.getAssignUsersData.emit([]);
    }
    const userAllBuIndex = await array.findIndex((x) => x === '1' || x=== 'All Business Unit');

    const { data ,...rest } = this.assignUserDropDown
    const userdata = {
      // filter: {
      //   status: [1],
      ...rest,
      parentBussinessUnitId: userAllBuIndex > -1 ? ['all'] : array,
        // fields: ['name'],
      // },
    };
    const sub = this.userService
      .getUsersBasedOnBussinesUnit(userdata)
      .pipe(finalize(() => sub.unsubscribe()))
      .subscribe((res) => {
        this.authorLoading = false;
        const response = res?.data?.items;
        if(concat){
          this.userDropDown && this.userDropDown.length >0 ? this.userDropDown =  [...this.userDropDown,...response] :this.userDropDown = response;
        }else{
          this.userDropDown = response;
        }
        this.getAssignUsersData.emit(res?.data?.items)
      });
  }
  async getAdminBasedOnBuUnits(value?: string) {
    this.adminLoading = true;
    this.adminDropDownPlaceholderText = 'Loading..........'
    const AutherControl = this.authorControl;
    const AdminControl = this.adminControl;
    value || this.clearFormArray(AutherControl)
    value || this.clearFormArray(AdminControl)
    let array = await this.BussinessUnitControl.value.map((item) => item._id);
    if(array.length === 0){
      this.adminDropDown = [];
      this.adminDropDownPlaceholderText = 'Select admin';
      this.adminLoading = false
      return;
    }
    const userAllBuIndex = await array.findIndex((x) => x === '1' || x=== 'All Business Unit');
    const { data, ...rest } = this.adminPTable
    const userdata = {

      ...rest,
      filter: {
        status: [1],
        parentBussinessUnitId: userAllBuIndex > -1 ? [] : array,
        // fields: ['name'],
      },
    };

    const sub = this.userService
      .getAllUsers(userdata)
      .pipe(finalize(() => sub.unsubscribe()))
      .subscribe((res) => {
        this.adminDropDownPlaceholderText = 'Select admin';
        this.adminLoading = false
        this.adminDropDown = res?.data?.data || [];
      });
  }
  /// bussiness unit
  get BussinessUnitControl(): FormArray {
    return this.userDetailsForm.get('businessUnits') as FormArray;
  }

  loadMoreAuthor(value) {
    this.userPTable.limit = this.userPTable.limit + 10;



    this.getUserBasedOnBuUnits();
  }
  onClearAuthor(value) {
    if (value === undefined) {
      this.loadAssignedUser();
    }
  }

  loadAssignedUser(){
    this.userDropDown = [];
    this.assignUserDropDown.search = '';
    this.getUserBasedOnBuUnits("clear");
  }

  onSeachAdmin(value) {
    this.adminPTable.limit = 10;
    this.adminPTable.search = value.term;
    this.getAdminBasedOnBuUnits('search');
  }
  loadMoreAdmin(value) {
    this.adminPTable.limit = this.adminPTable.limit + 10;



    this.getAdminBasedOnBuUnits("clear");
  }
  onClearAdmin(value) {
    if (value === undefined) {
      // this.createUserForm.patchValue({
      //   appointmentId: null,
      // });
      this.loadAdminData();

    }
  }
  loadAdminData(){
    this.adminPTable.search = '';
    this.adminDropDown = [];
    this.getAdminBasedOnBuUnits("clear");
  }

  selectBusinessUnit(value) {
    if (value === 'all') {
      this.isDisabledFormFields = true;
    }
    else if (value === 'includeSelected' || value === 'excludeSelected') {
      this.isDisabledFormFields = false;
    }
  }

  addbussinesUnitPlan(value) {
    if (value) {
      this.assignUserDropDown.page = 0;
      this.assignUserDropDown.search = '';
      const Control = this.BussinessUnitControl;
      const newArray = [];
      this.userDetails.forEach((element) => {
        newArray.push(...element.businessUnits);
      });
      const anyBuUnitIndex = newArray.findIndex((x) => x._id === value._id);
      const buindex = Control.value.findIndex(
        (x) => x.orgName === 'All Business Unit'
      );

      if (anyBuUnitIndex == -1) {
        if (buindex > -1) {
          this.ngSelectComponent.clearModel();
          this.toastService.error(`All Business Unit is added`);

        } else {
          if (buindex == -1 && value === 'All Business Unit') {
            this.ngSelectComponent.clearModel();
            this.clearFormArray(Control);
            let field = this.formBuilder.group({
              _id: [
                value._id ? value._id : '1',
                Validators.compose([Validators.required]),
              ],
              orgName: [value, Validators.compose([Validators.required])],
            });
            Control.push(field);
            let buID='';
            const buList = this.BussinessUnitControl.value.map((data)=>{
               return  buID = buID+','+data?._id;
            })
            this.userDetailsForm.patchValue({
              allBuToken: true,
              allBuTokenStaffId:localStorage.getItem('flexistaffId')
            })
            this.loadAppoinment([], true)
            this.getUserBasedOnBuUnits("load");
            this.getAdminBasedOnBuUnits("load")
            return;
          }
          this.userDetailsForm.patchValue({
            allBuToken: false,
            allBuTokenStaffId:''
          })
          const index = Control.value.findIndex((x) => x._id === value._id);

          if (index > -1) {
            this.ngSelectComponent.clearModel();
            this.toastService.error(`${value.orgName} is already added`);

          } else {

            let field = this.formBuilder.group({
              orgName: [
                value.orgName,
                Validators.compose([Validators.required]),
              ],
              _id: [value._id, Validators.compose([Validators.required])],
            });
            Control.push(field);
            let buIDArray = [];
            const buList = this.BussinessUnitControl.value.map((data, index)=>{
              buIDArray[index] = data?._id;
            })
            this.loadAppoinment(buIDArray, false)
            this.ngSelectComponent.clearModel();
            this.getUserBasedOnBuUnits("load");
            this.getAdminBasedOnBuUnits("load")
          }
        }
      } else {
        this.toastService.error(`${value.orgName} is already added`);
      }

    }
  }
  deletePlanBuUnitFields(index,id) {
    const AutherControl = this.authorControl;
    const adminControl = this.adminControl;
    const Control = this.BussinessUnitControl;
    this.assignUserDropDown.page = 0;
    this.assignUserDropDown.search = '';
    // dont remove this code as it
    // this.clearFormArray(AutherControl);
    // this.clearFormArray(adminControl);
    if(AutherControl.value?.filter(item=>item?.parentBussinessUnitId === id)){
     AutherControl?.removeAt(AutherControl.value?.findIndex(item=>item?.parentBussinessUnitId === id));
    }
    if(adminControl.value?.filter(item=>item?.parentBussinessUnitId === id)){
     adminControl?.removeAt(adminControl.value?.findIndex(item=>item?.parentBussinessUnitId === id));
    }
    Control.removeAt(index);

    let buIDArray = [];
    const buList = this.BussinessUnitControl.value.map((data, index)=>{
      buIDArray[index] = data?._id;
    })
   buIDArray.length >0 ? this.loadAppoinment(buIDArray, false) : this.setAppointMent() ;
    this.getUserBasedOnBuUnits("load");
    this.adminPTable.search = '';
    this.adminPTable.limit = 10;
    this.getAdminBasedOnBuUnits("load");

  }
  setAppointMent(){
    this.appoinmentDropDown = [] ;
    for(let i = this.AppoinemtControl. length-1; i >= 0; i--) {
      this.AppoinemtControl.removeAt(i)
    }
     //this.AppoinemtControl.setValue([])
  }
  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  };
  onSeachPlanBuUnit(value) {

    this.bussinesUnitplanPTable.limit = 10;
    this.bussinesUnitplanPTable.search = value.term;

    this.loadBussinessPlanUnit();
  }
  loadMorePlasnBuUnitItems(value) {
    this.bussinesUnitplanPTable.limit = this.bussinesUnitplanPTable.limit + 10;

    this.loadBussinessPlanUnit();
  }

  onClearPlanBuUnitDropdown(value) {


    if (value === undefined) {
      this.userDetailsForm.patchValue({
        parentBussinessUnitId: {
          name: null,
          _id: null,
        },
      });
      this.bussinesUnitplanPTable.search = '';
      this.loadBussinessPlanUnit();

      this.planBuUnitDropDown = [];
      this.bussinesUnitplanPTable.data = [];
    }

  }

  loadBussinessPlanUnit() {
    this.planBuUnitDropDownLoading = true;
   // if(this.moduleName=="challenges"){
      const subscribe = this.buUnit
      .getBusinessUnitData()
      .pipe(
        finalize(() => {
          this.planBuUnitDropDownLoading = false;
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          this.bussinesUnitplanPTable.data = response?.data?.planBussinessUnitId;
          this.planBuUnitDropDown = response?.data?.planBussinessUnitId;
        let buUnits =   this.planBuUnitDropDown.map((data) => data?._id);
        this.allBuUnitID = buUnits
        },
        (error) => {
          this.toastService.error("Error Occured",error);
          this.bussinesUnitplanPTable.data = [];
          this.toastService.error('No list found');
        }
      );
    //}
   /* else{
      const { data, ...rest } = this.bussinesUnitplanPTable;
      const subscribe = this.buUnit
        .getBussinesUnit(rest, true)
        .pipe(
          finalize(() => {
            this.planBuUnitDropDownLoading = false;
            subscribe.unsubscribe();
          })
        )
        .subscribe(
          (response: any) => {
            const { businessUnitList } = response;
            this.bussinesUnitplanPTable.data = businessUnitList;
            this.planBuUnitDropDown = businessUnitList;

          },
          (error) => {
            this.bussinesUnitplanPTable.data = [];
            this.toastService.error('No list found');
          }
        );
    }*/


  }

  /// apoinment
  get AppoinemtControl(): FormArray {
    return this.userDetailsForm.get('appointments') as FormArray;
  }

  get SkillSetControl(): FormArray {
    return this.userDetailsForm.get('subSkillSets') as FormArray;
  }

  addAppointment(value) {
    if (value) {
      const Control = this.AppoinemtControl;
      const index = Control.value.findIndex((x) => x._id === value._id);
      this.apoinemntDropDownToClear = []
      if (index > -1) {
        this.toastService.error(`${value.name} is already added`);
        this.apoinemntDropDownToClear = []
      } else {
        let field = this.formBuilder.group({
          name: [value.name, Validators.compose([Validators.required])],
          _id: [value._id, Validators.compose([Validators.required])],
        });
        Control.push(field);
        this.apoinemntDropDownToClear = []
      }
    }
  }

  addSkillSet(value) {
    if (value) {
      this.subSkillSetDropDown = value.subSkillSets;
      this.showSubSkillSet = true;
      // const Control = this.SkillSetControl;
      // const index = Control.value.findIndex((x) => x._id === value._id);
      // this.skillSetDropDownToClear = []
      // if (index > -1) {
      //   this.toastService.error(`${value.name} is already added`);
      //   this.skillSetDropDownToClear = []
      // } else {
      //   let field = this.formBuilder.group({
      //     name: [value.name, Validators.compose([Validators.required])],
      //     _id: [value._id, Validators.compose([Validators.required])],
      //   });
      //   Control.push(field);
      //   this.skillSetDropDownToClear = []
      // }
    }
  }

  addSubSkillSet(value){
    if (value) {
     const Control = this.SkillSetControl;
      const index = Control.value.findIndex((x) => x._id === value._id);
      // this.skillSetDropDownToClear = []
      if (index > -1) {
        this.toastService.error(`${value.name} is already added`);
        // this.skillSetDropDownToClear = []
      } else {
        const name = this.skillSetDropDownToClear.name+' > '+value.name;
        let field = this.formBuilder.group({
          name: [name, Validators.compose([Validators.required])],
          _id: [value._id, Validators.compose([Validators.required])],
        });
        Control.push(field);
        // this.skillSetDropDownToClear = []
      }
    }
    this.showSubSkillSet = false;
  }


  deleteAppoinemnt(index) {
    const Control = this.AppoinemtControl;

    Control.removeAt(index);
  }

  deleteSkillSet(index) {
    const Control = this.SkillSetControl;

    Control.removeAt(index);
  }

  onSearchAppointment(value) {

    this.appoinmentPTable.limit = 10;
    this.appoinmentPTable.search = value.term;

    this.loadAppoinment([], false);
  }

  onSearchSkillSet(value) {

    this.skillSetPTable.limit = 10;
    this.skillSetPTable.search = value.term;

    this.loadSkillSet();
  }

  loadMoreAppoinmentItems(value) {
    this.appoinmentPTable.limit = this.appoinmentPTable.limit + 10;

    this.loadAppoinment([], false);
  }

  loadMoreSkillSetItems(value) {
    this.skillSetPTable.limit = this.skillSetPTable.limit + 10;

    this.loadSkillSet();
  }
  loadMoreAdminItems(value) {
    this.skillSetPTable.limit = this.skillSetPTable.limit + 10;

    this.loadSkillSet();
  }
  onClearAppoinmentDropdown(value) {


    if (value === undefined) {
      // this.createUserForm.patchValue({
      //   appointmentId: null,
      // });
      this.appoinmentPTable.search = '';
      this.loadAppoinment([], false);

      this.appoinmentDropDown = [];
      this.appoinmentPTable.data = [];
    }

  }

  onClearSkillSetDropdown(value) {
    if (value === undefined) {
      // this.createUserForm.patchValue({
      //   appointmentId: null,
      // });
      this.skillSetPTable.search = '';
      this.loadSkillSet();

      this.skillSetDropDown = [];
      this.skillSetPTable.data = [];
    }

  }

  loadAppoinment(buId, buToken) {
    this.apppoinmentDropDownLoading = true;
    const { data, total, ...rest } = this.appoinmentPTable
    const payload =
    {
      "businessUnitId": buId,
      "allBuToken": buToken
    }
    const subscribe = this.userService
      .getAppoinmentsWithoutPagination(payload)
      .pipe(
        finalize(() => {
          this.apppoinmentDropDownLoading = false;
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const { count, data } = response.data;
          this.appoinmentPTable.data = response.data;
          this.appoinmentDropDown = response.data;
          this.appoinmentPTable.total = count;
          this.AppoinemtControl.value.length > 0 ? this.AppoinemtControl.value.map((data,index)=>{
                 const arrayIndex =   this.appoinmentDropDown.findIndex(element => element?._id === data?._id);
                 if(arrayIndex < 0 )
                 {
                  //const indexFound = this.AppoinemtControl.value.findIndex(image => image._id === data?._id);
                  //if(indexFound !== -1)
                  this.AppoinemtControl.removeAt(index);
                 }
          }) : ''
        },
        (error) => {
          this.appoinmentPTable.data = [];
          this.appoinmentPTable.total = 0;
          this.toastService.error('No list found');
        }
      );
  }

  loadSkillSet() {
    this.skillSetDropDownLoading = true;
    const { data, total, ...rest } = this.skillSetPTable
    const subscribe = this.userService
      .getSkillSet(rest)
      .pipe(
        finalize(() => {
          this.skillSetDropDownLoading = false;
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const { count, data } = response.data;
          this.skillSetPTable.data = data;
          this.skillSetDropDown = data;
          this.skillSetPTable.total = count;

        },
        (error) => {
          this.skillSetPTable.data = [];
          this.skillSetPTable.total = 0;
          this.toastService.error('No list found');
        }
      );
  }

  ///custom fields

  get CustomFiledControl(): FormArray {
    return this.userDetailsForm.get('customField') as FormArray;
  }

  addCustomFields(value) {
    this.addedCustomfield = value;
    this.customFeildDropDownToClear = []
  }

  async customFieldADD(value, fieldname, id) {

    if (value) {

      const Control = this.CustomFiledControl;
      const index = await Control.value.findIndex((x) => x.value === value);

      if (index > -1) {
        this.toastService.error(`${value} is already added`);
        this.ngSelectComponent.clearModel();
        this.addedCustomfield = null;
      } else {
        let field = this.formBuilder.group({
          _id: [id, Validators.compose([Validators.required])],
          name: [
            `${fieldname} > ${value}`,
            Validators.compose([Validators.required]),
          ],
          fieldId: [id, Validators.compose([Validators.required])],
          value: [
            value,
            Validators.compose([Validators.required]),
          ],
        });
        Control.push(field);
        this.ngSelectComponent.clearModel();
        this.showBackandNextBtn();
        this.addedCustomfield = null;
      }
    }
  }

  deleteCustomField(index) {
    const Control = this.CustomFiledControl;

    Control.removeAt(index);
  }
  onSeachCustomField(value) {
    this.CustomFieldPTable.limit = 10;
    this.CustomFieldPTable.search = value.term;
    this.loadCustomField();
  }

  onSkillSets(value) {
    this.CustomFieldPTable.limit = 10;
    this.CustomFieldPTable.search = value.term;
    this.loadCustomField();
  }

  loadMoreCustomFieldItems(value) {

    this.CustomFieldPTable.limit = this.CustomFieldPTable.limit + 10;

    this.loadCustomField();
  }

  loadMoreAssignUsers(value){
    this.assignUserDropDown.page++;
    this.getUserBasedOnBuUnits("load", true)
  }

  onSearchAssignUser(value) {
    this.assignUserDropDown.limit = 10;
    this.assignUserDropDown.page = 0;
    this.assignUserDropDown.search = value.term;
    this.getUserBasedOnBuUnits("search");
  }

  onClearCustomFieldDropdown(value) {
    if (value === undefined) {
      // this.setupForm.patchValue({
      //   loginFields: [],
      // });
      this.CustomFieldPTable.search = '';
      this.loadCustomField();

      this.fieldNeedsToUpdate = [];
      this.CustomFieldPTable.data = [];
    }
  }

  loadCustomField() {
    const { data, total, ...rest } = this.CustomFieldPTable
    const subscribe = this.customFieldService
      .getCustomField(rest)
      .pipe(
        finalize(() => {

          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const { count, data } = response.data;
          this.CustomFieldPTable.data = data;
          this.fieldNeedsToUpdate = data;
          this.CustomFieldPTable.total = count;
        },
        (error) => {
          this.CustomFieldPTable.data = [];
          this.CustomFieldPTable.total = 0;
          // this.tostService.error('No list found');
        }
      );
  }

  //author
  get authorControl(): FormArray {
    return this.userDetailsForm.get('user') as FormArray;
  }

  async addAuthor(value) {
    if (value) {
      const Control = this.authorControl;
      const index = await Control.value.findIndex((x) => x._id === value._id);

      if (index > -1) {
        this.toastService.error(`${value.name} is already added`);
        this.authorDropDownToClear = []
      } else {
        let field = this.formBuilder.group({
          name: [value.name, Validators.compose([Validators.required])],
          _id: [value._id, Validators.compose([Validators.required])],
          parentBussinessUnitId:[value?.parentBussinessUnitId]
        });
        Control.push(field);
        this.authorDropDownToClear = []
      }
    }

  }

  deleteAuthor(index) {
    const Control = this.authorControl;
    Control.removeAt(index);
  }
  //admin
  get adminControl(): FormArray {
    return this.userDetailsForm.get('admin') as FormArray;
  }

  async addAdmin(value) {
    if (value) {
      const Control = this.adminControl;
      const index = await Control.value.findIndex((x) => x._id === value._id);

      if (index > -1) {
        this.toastService.error(`${value.name} is already added`);
        this.adminDropDownToClear = []
      } else {
        let field = this.formBuilder.group({
          name: [value.name, Validators.compose([Validators.required])],
          _id: [value._id, Validators.compose([Validators.required])],
          parentBussinessUnitId:[value?.parentBussinessUnitId]

        });
        Control.push(field);
        this.adminDropDownToClear = []
      }
    }
  }

  deleteAdmin(index) {
    const Control = this.adminControl;
    Control.removeAt(index);
  }
  //user details
  get UserDetailsControl(): FormArray {
    return this.wallCreateForm.get('userDetails') as FormArray;
  }

  addUserDetails() {

    if (!this.userDetailsForm.valid) {
      this.toastService.error('Please select all mandatory fields')
      return
    }
    this.userDetails.push(this.userDetailsForm.value);
    this.ClearUserDetailForm();
    this.showBackandNextBtn();
    this.onSubmit()
  }

  onCancelEdit() {
    this.onClearAllFrom();

    this.isBoardEdited = false;
    this.boardId = null;
    this.activeId = 1;
    this.userDetails.length = 0;
    this.overViewEvent.emit(null);
  }

  editUserDetail(userDetail, index) {
    this.showCreateButton.emit();
    const BuControl = this.BussinessUnitControl;
    const apoinemtnControl = this.AppoinemtControl;
    const skillSetControl = this.SkillSetControl;
    const customFieldControl = this.CustomFiledControl;
    const authorcontrol = this.authorControl;
    const admincontrol = this.adminControl;

    this.ClearUserDetailForm();

    this.userDetailsEditIndex = index;
    this.isUserDetailsEdited = true;
    if(userDetail.buFilterType === 1){
      this.isDisabledFormFields = true;
    }else{
      this.isDisabledFormFields = false;
    }

    if(userDetail.allBuToken){
      this.loadAppoinment([], true);
    }else{
      this.loadAppoinment(userDetail.businessUnits.map((bu)=> bu._id), false)
    }
  this.userDetailsForm.patchValue({
      buFilterType: userDetail.buFilterType,

      allBuToken: userDetail.allBuToken,
      allBuTokenStaffId: userDetail.allBuTokenStaffId,
    });
    if( !userDetail?.allBuToken || userDetail?.allBuToken == "" )
    {
      userDetail.businessUnits.forEach((element) => {
        let field = this.formBuilder.group({
          _id: [
            element._id ? element._id : '1',
            Validators.compose([Validators.required]),
          ],
          orgName: [
            element.orgName ? element.orgName : element.name,
            Validators.compose([Validators.required]),
          ],
        });
        BuControl.push(field);
      });
    }
    if( userDetail?.allBuToken )
    {
        let field = this.formBuilder.group({
          _id: [
             '1',
            Validators.compose([Validators.required]),
          ],
          orgName: [
            "All Business Unit",
            Validators.compose([Validators.required]),
          ],
        });
        BuControl.push(field);
    }
    this.getUserBasedOnBuUnits();
    this.getAdminBasedOnBuUnits()
    userDetail.appointments.forEach((element) => {
      let field = this.formBuilder.group({
        name: [element?.name, Validators.compose([Validators.required])],
        _id: [element?._id, Validators.compose([Validators.required])],
      });
      apoinemtnControl.push(field);
    });
    userDetail.subSkillSets.forEach((element) => {
      const name = element?.skillSetId?.name+' > '+element?.name;
      let field = this.formBuilder.group({
        name: [name, Validators.compose([Validators.required])],
        _id: [element._id, Validators.compose([Validators.required])],
      });
      skillSetControl.push(field);
    });
    userDetail.user.forEach((element) => {
      let field = this.formBuilder.group({
        name: [element?.name, Validators.compose([Validators.required])],
        _id: [element?._id, Validators.compose([Validators.required])],
        parentBussinessUnitId:[element?.parentBussinessUnitId]
      });
      authorcontrol.push(field);
    });

    if(userDetail.admin){
    userDetail.admin.forEach((element) => {
      if(!userDetail.firstAdminAddedAsDefault)
      {
        let field = this.formBuilder.group({
          name: [element?.name, Validators.compose([Validators.required])],
          _id: [element?._id, Validators.compose([Validators.required])],
          parentBussinessUnitId:[element?.parentBussinessUnitId]
        });
        admincontrol.push(field);
      }
     else if(userDetail.firstAdminAddedAsDefault && element?.staffId !== 'admin001')
      {
        let field = this.formBuilder.group({
          name: [element?.name, Validators.compose([Validators.required])],
          _id: [element?._id, Validators.compose([Validators.required])],
        });
        admincontrol.push(field);
      }
    });
  }
    userDetail.customField.forEach((element) => {
      let field = this.formBuilder.group({
        _id: [element?._id, Validators.compose([Validators.required])],

        name: [element?.name, Validators.compose([Validators.required])],
      });
      customFieldControl.push(field);
    });
  }

  deleteUserDetail(index) {
    this.userDetails.splice(index, 1)
    this.onSubmit()
    if(this.userDetails.length === 0){
      this.hideCreateButton.emit();
    }
    this.toastService.error('Assigned User Deleted', '', {
      timeOut: 3000,
      positionClass: 'toast-top-right',
    });
  }

  updateUserDetails() {
    if (!this.userDetailsForm.valid) {
      this.toastService.error('Please select all mandatory fields')
      return
    }
    this.toastService.success("Business Unit Updated")
    this.userDetails[this.userDetailsEditIndex] = this.userDetailsForm.value;
    this.ClearUserDetailForm();
    this.onSubmit()
    this.isUserDetailsEdited = false;
    this.userDetailsEditIndex = null;
  }

  onSubmit() {
    const Output = getAssignedUsers(this.userDetails);
    Output.map((data,index)=>{
      if(data?.allBuToken)
      {
      // handled for all bu as it is not required
      // previously Output[index]['businessUnits'] = this.allBuUnitID
       Output[index]['businessUnits'] = [];
      }
    })
    this.onSubmitAssignUsers.emit(Output);
  }
  ClearUserDetailForm() {
    const BuControl = this.BussinessUnitControl;
    const apoinemtnControl = this.AppoinemtControl;
    const skillSetControl = this.SkillSetControl;
    const customFieldControl = this.CustomFiledControl;
    const authorcontrol = this.authorControl;
    const admincontrol = this.adminControl;

    this.clearFormArray(BuControl);
    this.clearFormArray(apoinemtnControl);
    this.clearFormArray(skillSetControl);
    this.clearFormArray(customFieldControl);
    this.clearFormArray(authorcontrol);
    this.clearFormArray(admincontrol);
    this.buDropDownToClear = null;
    this.apoinemntDropDownToClear = null;
    this.skillSetDropDownToClear = null;
    this.customFeildDropDownToClear = null;
    this.adminDropDownToClear = null;
    this.authorDropDownToClear = null;
  }

  onClearAllFrom() {
    const BuControl = this.BussinessUnitControl;
    const apoinemtnControl = this.AppoinemtControl;
    const skillSetControl = this.SkillSetControl;
    const customFieldControl = this.CustomFiledControl;
    const authorcontrol = this.authorControl;
    const admincontrol = this.adminControl;


    this.clearFormArray(BuControl);
    this.clearFormArray(apoinemtnControl);
    this.clearFormArray(skillSetControl);
    this.clearFormArray(skillSetControl);
    this.clearFormArray(customFieldControl);
    this.clearFormArray(authorcontrol);
    this.clearFormArray(admincontrol);

    this.buDropDownToClear = null;
    this.apoinemntDropDownToClear = null;
    this.skillSetDropDownToClear = null;
    this.customFeildDropDownToClear = null;
    this.adminDropDownToClear = null;
    this.authorDropDownToClear = null;
    this.userDetailsEditIndex = null;
    this.isUserDetailsEdited = false;

    this.userDetails.length = 0;
    this.inituserDetailsFrom();
  }

}
