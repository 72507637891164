<div class="card">
  <div class="card-header align-items-center bg-theme">
    <h5 class="m-0">CREATE SHIFTS</h5>
    <p class="m-0">Selected Business Unit : {{ bu.businessUnit }}</p>
  </div>
  <div class="card-body">
    <form>
      <div class="row mb-2 col-md-12 flexDisplay">
        <div class="col-md-4">
          <input type="radio" id="staffDemand" name="staff" />
          <label for="staffDemand" class="p-2"><b>Staff Demand</b></label>
        </div>
        <div class="col-md-4">
          <input type="radio" id="permanentStaff" name="staff" />
          <label for="permanentStaff" class="p-2"
            ><b>Permanent Staffing</b></label
          >
        </div>
      </div>
      <div class="row mb-2 col-md-9">
        <input type="file" class="col-md-12 fileInput" accept=".csv" />
      </div>
    </form>
  </div>
</div>
