<div class="d-flex flex-column">
  <ul
    ngbNav
    #companyNav="ngbNav"
    (navChange)="onNavChange($event)"
    [(activeId)]="activeId"
    class="nav-tabs rounded mb-3"
  >
    <li [ngbNavItem]="1">
      <a ngbNavLink>OVERVIEW</a>
      <ng-template ngbNavContent>
        <app-bu-shift-setup-overview
          (changeActiveBu)="activeTab($event)"
        ></app-bu-shift-setup-overview>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink (click)="resetForm()">BU SHIFT SETUP</a>
      <ng-template ngbNavContent>
        <div class="card">
          <h5 class="card-header bg-theme">
            {{ this.heading }}
          </h5>
          <div class="card-body">
            <form [formGroup]="buShiftSetUpForm">
              <div class="row">
                <div class="col-md-7">
                  <div class="form-group">
                    <label class="font-weight-500">Select Business Unit <span class="field-mandatory required-sign">*</span></label>
                    <ng-select
                      class="custom"
                      bindLabel="name"
                      [virtualScroll]="false"
                      placeholder="Select"
                      appendTo="body"
                      formControlName="businessUnitId"
                      [multiple]="false"
                      [searchable]="true"
                      [clearable]="true"
                      (change)="SchemeList($event)"
                    >
                      <!-- bindValue="_id" -->
                      <ng-option [value]="item" *ngFor="let item of buOption">
                        {{ item.orgName }}
                      </ng-option>
                    </ng-select>
                  </div>
                </div>
              </div>

              <div class="card mb-3">
                <div class="card-body">
                  <h5 class="card-title">Assign Skill Sets to Business Unit</h5>
                  <div class="row">
                    <div class="col-md-3">
                      <label class="font-weight-500"
                        >Select Skill Sets
                        <i
                          class="fa fa-plus-circle"
                          (click)="createAddSkill()"
                          aria-hidden="true"
                        ></i
                      ></label>
                      <ng-select
                        class="custom"
                        bindLabel="name"
                        placeholder="Select Skill Sets"
                        appendTo="body"
                        [multiple]="false"
                        [searchable]="true"
                        [clearable]="true"
                        formControlName="mainSkillSets"
                        (change)="onSkillSetChange($event)"
                      >
                        <ng-option [value]="i" *ngFor="let i of buSkillSet">
                          {{ i.name }}
                        </ng-option>
                      </ng-select>
                    </div>
                    <div class="col-md-3" *ngIf="showSpeciality">
                      <label class="font-weight-500"
                        >Select Speciality
                        <i
                          class="fa fa-plus-circle"
                          (click)="addSpeciality()"
                          aria-hidden="true"
                        ></i
                      ></label>
                      <ng-select
                        class="custom"
                        bindLabel="name"
                        placeholder="Select Speciality"
                        appendTo="body"
                        [multiple]="false"
                        [searchable]="true"
                        [clearable]="true"
                        formControlName="subSkillSets"
                      >
                        <ng-option
                          [value]="item"
                          *ngFor="let item of buSkillspeciality"
                          >{{ item.name }}</ng-option
                        >
                      </ng-select>
                    </div>
                    <div class="col-md-2">
                      <label class="font-weight-500"> &nbsp;</label><br />
                      <button class="btn btn-danger" (click)="clearSkills()">
                        Clear
                      </button>
                      <button
                        class="btn btn-success ml-1"
                        (click)="addSkillbutton()"
                      >
                        Add
                      </button>
                    </div>
                    <div class="col-md-4">
                      <ng-container *ngIf="this.skillsSet != null">
                        <div
                          class="labels mb-1 mr-1"
                          *ngFor="let item of skillLabels; let i = index"
                        >
                          <span>{{ item[0] }} > {{ item[1] }}</span>
                          <span
                            class="material-icons bg-danger rounded btn-close"
                            (click)="clearLabel(i)"
                          >
                            clear
                          </span>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card mb-3">
                <div class="card-body">
                  <h5 class="card-title">
                    Add Reporting Locations to Business Unit
                  </h5>
                  <div class="form-group row">
                    <label class="col-sm-2 font-weight-500"
                      >Add location by</label
                    >
                    <div class="col-sm-10">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="reportingLocationType"
                          formControlName="reportingLocationType"
                          value="map"
                          id="map"
                          (click)="changeLocationType('map')"
                        />
                        <label class="form-check-label">Map</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="reportingLocationType"
                          formControlName="reportingLocationType"
                          value="text"
                          id="text"
                          (click)="changeLocationType('text')"
                        />
                        <label class="form-check-label">Text Format</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="reportingLocationType"
                          formControlName="reportingLocationType"
                          value="both"
                          id="both"
                          (click)="changeLocationType('both')"
                        />
                        <label class="form-check-label">Both</label>
                      </div>
                    </div>
                  </div>
                  <hr />

                  <div class="row mb-3" *ngIf="isMap || isDropdown">
                    <div
                      class="col-md-3"
                      *ngIf="
                        this.buShiftSetUpForm.get('reportingLocationType')
                          .value == 'text' ||
                        this.buShiftSetUpForm.get('reportingLocationType')
                          .value == 'both'
                      "
                    >
                      <label class="font-weigth-500">
                        Add Reporting Locations
                        <i
                          class="fa fa-plus-circle"
                          (click)="addLocations()"
                          aria-hidden="true"
                        ></i>
                      </label>
                      <ng-select
                        [items]="buRepoLocation"
                        bindLabel="name"
                        placeholder="Select Reporting Locations"
                        appendTo="body"
                        [multiple]="false"
                        [searchable]="true"
                        [clearable]="true"
                        formControlName="reportingLocation"
                      >
                      </ng-select>
                    </div>
                    <ng-container *ngIf="isDropdown">
                      <div class="col-md-2 align-self-end mb-1">
                        <button class="btn btn-danger">Clear</button>
                        <button
                          class="btn btn-success ml-1"
                          (click)="addLocateButton()"
                        >
                          Add
                        </button>
                      </div>
                      <div class="col-md-7">
                        <ng-container *ngIf="this.locationslabels != null">
                          <div
                            class="labels my-2 mr-2"
                            *ngFor="let item of locationslabels; let i = index"
                          >
                            <span>{{ item }}</span>
                            <span
                              class="material-icons bg-danger rounded btn-close"
                              (click)="clearLocationLabel(i)"
                            >
                              clear
                            </span>
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>

                  <app-add-map-setup
                    *ngIf="
                      this.buShiftSetUpForm.get('reportingLocationType')
                        .value == 'map' ||
                      this.buShiftSetUpForm.get('reportingLocationType')
                        .value == 'both'
                    "
                    [isMap]="isMap"
                    [isDropdown]="isDropdown"
                    [mapLocations]="geoReportingLocations"
                    (geoLocationEvent)="setGeoLocations($event)"
                    [buSettingObj]="buSettingObj"
                    (handleCheckInEnable)="changeCheckInEnable($event)"
                    (handleProximityEnable)="changeProximityEnable($event)"
                    (handleRepLoc)="changeRepLoc($event)"
                  ></app-add-map-setup>
                </div>
              </div>

              <!--<div class="card mb-3">
                <div class="card-body">
                  <h5 class="card-title">Add Shift Schemes to Business Unit</h5>
                  <div class="row">
                    <div class="col-md-4">
                      <label class="font-weight-500"> Add Shift Schemes</label>
                      <ng-select
                        [items]="schemeTable"
                        class="custom"
                        bindLabel="schemeName"
                        placeholder="Select Shift Schemes"
                        appendTo="body"
                        [multiple]="false"
                        [searchable]="true"
                        [clearable]="true"
                        formControlName="scheme"
                      >
                      </ng-select>
                    </div>
                    <div class="col-md-2">
                      <label class="font-weight-500"> &nbsp;</label><br />
                      <button class="btn btn-danger">Clear</button>
                      <button
                        class="btn btn-success ml-1"
                        (click)="addSchemeButton()"
                      >
                        Add
                      </button>
                    </div>
                    <div class="col-md-6">
                      <p>Shift Schemes</p>
                      <div class="closeAdd" *ngIf="this.schemeLabels != null">
                        <div
                          class="text-item list-ui"
                          *ngFor="let item of schemeLabels; let i = index"
                        >
                          {{ item }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>-->

              <div class="card mb-3">
                <div class="card-body">
                  <h5 class="card-title">
                    Add Weeks for Shift View to Business Unit
                  </h5>
                  <div class="row">
                    <div class="col-md-4">
                      <label class="font-weight-500">
                        Number of Weeks for Shift View to Business Unit
                      </label>
                      <ng-select
                        class="custom"
                        bindLabel="name"
                        placeholder="Select Number of Weeks"
                        appendTo="body"
                        [multiple]="false"
                        [searchable]="true"
                        [clearable]="true"
                        formControlName="noOfWeek"
                      >
                        <ng-option
                          [value]="item"
                          *ngFor="let item of noOfweeks"
                          >{{ item }}</ng-option
                        >
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card mb-3">
                <div class="card-body">
                  <h5 class="card-title">Timesheet Difference Restriction</h5>
                  <div class="row">
                    <div class="col-md-4">
                      <label class="font-weight-500">
                        Max difference between Planned Shift and Approved worked
                        hours (in minutes)
                      </label>
                      <ng-select
                        class="custom"
                        bindLabel="name"
                        placeholder="Select minutes"
                        appendTo="body"
                        [multiple]="false"
                        [searchable]="true"
                        [clearable]="true"
                        formControlName="plannedHours"
                      >
                        <ng-option [value]="15">15 minutes</ng-option>
                        <ng-option [value]="30">30 minutes</ng-option>
                        <ng-option [value]="45">45 minutes</ng-option>
                        <ng-option [value]="60">60 minutes</ng-option>
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card mb-3">
                <div class="card-body">
                  <h5 class="card-title">Add Auto Approval to Shifts</h5>
                  <div class="row">
                    <div class="col-md-4">
                      <label class="font-weight-500">
                        Max time diifference allowed between Shifts & Clocked
                        hours (in minutes)
                      </label>
                      <ng-select
                        class="custom"
                        bindLabel="name"
                        placeholder="Select minutes"
                        appendTo="body"
                        [multiple]="false"
                        [searchable]="true"
                        [clearable]="true"
                        formControlName="shiftTimeInMinutes"
                      >
                        <ng-option [value]="15">15 minutes</ng-option>
                        <ng-option [value]="30">30 minutes</ng-option>
                        <ng-option [value]="45">45 minutes</ng-option>
                        <ng-option [value]="60">60 minutes</ng-option>
                      </ng-select>
                    </div>
                    <div class="col-md-4">
                      <label class="font-weight-500">
                        Configure minimum break time ? (in minutes)
                      </label>
                      <br />
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="isBreakTime"
                          id="isBreakTime1"
                          [value]="true"
                          formControlName="isBreakTime"
                          (change)="changeToggle(true)"
                        />
                        <label class="form-check-label" for="isBreakTime1"
                          >Yes</label
                        >
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="isBreakTime"
                          id="isBreakTime2"
                          [value]="false"
                          formControlName="isBreakTime"
                          (change)="changeToggle(false)"
                        />
                        <label class="form-check-label" for="isBreakTime2"
                          >No</label
                        >
                      </div>
                    </div>
                    <div class="col-md-12" *ngIf="show">
                      <div class="row mt-3">
                        <div class="col-md-3">
                          <label for="" class="font-weight-500"
                            >Shift Duration (in hours)</label
                          >
                          <ng-select
                            [items]="shiftDuration"
                            class="custom"
                            bindLabel="name"
                            placeholder="Select"
                            appendTo="body"
                            [multiple]="false"
                            [searchable]="true"
                            [clearable]="true"
                            [(ngModel)]="shiftDurationSelect"
                            [ngModelOptions]="{ standalone: true }"
                          >
                          </ng-select>
                        </div>
                        <div class="col-md-3">
                          <label for="" class="font-weight-500"
                            >Min break required (in min)</label
                          >
                          <ng-select
                            [items]="minBreak"
                            class="custom"
                            bindLabel="name"
                            placeholder="Select"
                            appendTo="body"
                            [multiple]="false"
                            [searchable]="true"
                            [clearable]="true"
                            [(ngModel)]="minBreakSelect"
                            [ngModelOptions]="{ standalone: true }"
                          >
                          </ng-select>
                        </div>
                        <div class="col-md-3">
                          <label for="" class="font-weight-500">&nbsp;</label>
                          <div class="d-flex">
                            <button class="btn btn-danger">Clear</button>
                            <button
                              class="btn btn-success ml-1"
                              (click)="addBreakTimeButton()"
                            >
                              Add
                            </button>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <ng-container *ngIf="this.shiftBreakArr != null">
                            <div
                              class="labels mr-1 mb-1"
                              *ngFor="let item of shiftBreakArr; let i = index"
                            >
                              For {{ item.shiftHour }} hr :
                              {{ item.breakInMinutes }} min break

                              <span
                                class="material-icons bg-danger rounded btn-close"
                                (click)="clearLabelBreak(i)"
                              >
                                clear
                              </span>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card mb-3">
                <div class="card-body">
                  <h5 class="card-title">Shift</h5>
                  <div class="row">
                    <div class="col-md-3">
                      <label class="font-weight-500">
                        Require Standby Shift?
                      </label>
                      <br />
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="standByShiftPermission"
                          id="standByShiftPermission1"
                          (click)="toggleshift(true)"
                          [value]="true"
                          formControlName="standByShiftPermission"
                        />
                        <label
                          class="form-check-label"
                          for="standByShiftPermission1"
                          >Yes</label
                        >
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="standByShiftPermission"
                          id="standByShiftPermission2"
                          [value]="false"
                          (click)="toggleshift(false)"
                          formControlName="standByShiftPermission"
                        />
                        <label
                          class="form-check-label"
                          for="standByShiftPermission2"
                          >No</label
                        >
                      </div>
                    </div>
                    <div class="col-md-3" *ngIf="showshift">
                      <label class="font-weight-500">
                        Activation Time Limit
                      </label>
                      <ng-select
                        class="custom"
                        bindLabel="name"
                        placeholder="Select Number of Days"
                        appendTo="body"
                        [value]="true"
                        [multiple]="false"
                        [searchable]="true"
                        [clearable]="true"
                        formControlName="shiftCancelHours"
                      >
                        <ng-option [value]="item" *ngFor="let item of timeLimit"
                          >{{ item }}
                        </ng-option>
                      </ng-select>
                    </div>
                    <div class="col-md-3">
                      <label class="font-weight-500">
                        Require Cancel Shift?
                      </label>
                      <br />
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="cancelShiftPermission"
                          id="cancelShiftPermission1"
                          [value]="true"
                          formControlName="cancelShiftPermission"
                        />
                        <label
                          class="form-check-label"
                          for="cancelShiftPermission1"
                          >Yes</label
                        >
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="cancelShiftPermission"
                          id="cancelShiftPermission2"
                          [value]="false"
                          formControlName="cancelShiftPermission"
                        />
                        <label
                          class="form-check-label"
                          for="cancelShiftPermission2"
                          >No</label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-top: 20px;">
                    <div class="col-md-3">
                      <h5 class="card-title" style="white-space: pre">
                        Do you want to set Cut-Off days for Booking &nbsp; Shifts&nbsp;  and &nbsp; for &nbsp; Cancelling &nbsp;  Booked &nbsp; Shifts?</h5>
                      <br />
                      <div class="radioButton">
                        <div class="com-md-3">
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="cutOffDaysForBookingAndCancelling"
                              id="cutOffShiftPermission1" [value]="true" (change)="cutOffDaysForBooking('true')"
                              formControlName="cutOffDaysForBookingAndCancelling" />
                            <label class="form-check-label" for="cutOffShiftPermission1">Yes</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="cutOffDaysForBookingAndCancelling"
                              id="cutOffShiftPermission2" [value]="false" (change)="cutOffDaysForBooking('false')"
                              formControlName="cutOffDaysForBookingAndCancelling" />
                            <label class="form-check-label" for="cutOffShiftPermission2">No</label>
                          </div>
                        </div>
                        <div class="col-md-3" *ngIf="cutOffDays"></div>
                        <div class="col-md-6 numberSelection" *ngIf="cutOffDays">
                          <label class="font-weight-500">Select Number of Days</label>
                          <ng-select [items]="cutOffNumberDays" class="custom" bindLabel="name" placeholder="Select" appendTo="body"
                            [multiple]="false" [searchable]="true" [clearable]="true" [(ngModel)]="cutOffDaysSelect"
                            [ngModelOptions]="{ standalone: true }">
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="card-footer">
            <button
              class="btn btn-lg btn-success"
              type="submit"
              (click)="saveSetupDetails()"
              [disabled]="buSetupSaveLoader"
            >
              {{ saveBtn }}
              <span
                *ngIf="buSetupSaveLoader"
                class="spinner-border ml-1"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="companyNav"></div>
</div>

<ng-template #AddSkill let-c="close" let-d="dismiss">
  <div class="modal-content">
    <div class="modal-body" style="padding: 10px; min-height: 100vh">
      <div>
        <div class="card my-3 w-100">
          <div class="card-header flexi-header">
            <h3>ADD SKILLSET</h3>
          </div>
          <div class="card-body">
            <form [formGroup]="addSkillForm">
              <div class="form-group">
                <label for="exampleInputEmail1">Skillset</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="name"
                  placeholder="Skillset"
                />
                <button
                  *ngIf="!updateBtn"
                  class="btn btn-add mt-4 pull-right"
                  [disabled]="addSkillForm.invalid"
                  type="submit"
                  (click)="addskill()"
                >
                  Add
                </button>

                <button
                  *ngIf="updateBtn"
                  class="btn btn-add mt-4 pull-right"
                  type="submit"
                  (click)="updateSkill()"
                >
                  Update
                </button>
                <!-- <div
                        *ngIf="submitted && f.staffId.errors"
                        class="invalid-feedback"
                      >
                        <small *ngIf="f.staffId.errors.required">
                          StaffId is required
                        </small>
                      </div> -->
                <!-- <small class="text-danger" *ngIf="login.email!==null && !login.email">Enter valid email</small> -->
              </div>
            </form>
          </div>
        </div>
        <div class="card my-3 w-100">
          <div class="card-header flexi-header">
            <h3>SKILLSET LIST</h3>
          </div>
          <div class="card-body">
            <div class="card table-card">
              <div class="card-body flexi-body">
                <div class="flex-column">
                  <div class="d-flex justify-content-between mt-2 mb-3">
                    <select
                      class="custom-select"
                      style="width: auto"
                      name="pageSize"
                    >
                      <option [ngValue]="10">10</option>
                      <option [ngValue]="20">20</option>
                      <option [ngValue]="30">30</option>
                      <option [ngValue]="40">40</option>
                      <option [ngValue]="50">50</option>
                    </select>
                    <div class="form-group form-inline mb-0">
                      <!-- <span
                        >Please wait...</span
                      > -->
                      <input
                        class="form-control ml-2"
                        type="search"
                        name="searchTerm"
                        placeholder="Search here"
                      />
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table table-striped table-body">
                      <thead>
                        <tr>
                          <th scope="col" sortable="Tier 1">S.NO</th>
                          <th scope="col" sortable="Tier 2">
                            <!-- (sort)="onSortDepartment($event)" -->
                            SKILLSET
                          </th>

                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <!-- <pre>{{departmentPTable.data | json}}</pre> -->
                      <tbody >
                        <tr *ngFor="let item of buSkillSet; let i = index">
                          <td>{{ i + 1 }}</td>
                          <td>{{ item.name }}</td>

                          <td>
                            <button
                              class="btn btn-add p-2 m-1"
                              (click)="editSkill(item)"
                            >
                              Edit
                            </button>
                            <button
                              class="btn btn-danger p-sm-1 m-1"
                              (click)="deleteSkill(item)"
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="card-footer">
                <div class="d-flex justify-content-between">
                  <!--<p>Total Records {{ buOption.total }}</p>-->
                  <!-- <ngb-pagination [collectionSize]="" [maxSize]="">
                  </ngb-pagination> -->
                  <!-- <ngb-pagination [collectionSize]="buSkillSet.total" [(page)]="buSkillSet.page" [maxSize]="10"
                  (pageChange)="onPageChangeSkill($event)" [pageSize]="buSkillSet.limit">
                </ngb-pagination> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #AddSpeciality let-c="close" let-d="dismiss">
  <div class="modal-content">
    <div class="modal-body" style="padding: 10px; min-height: 100vh">
      <div>
        <div class="card my-3 w-100">
          <div class="card-header flexi-header">
            <h3>ADD SPECIALITY</h3>
          </div>
          <div class="card-body">
            <form [formGroup]="addSpecialityForm">
              <div class="row">
                <div class="col-md-6">
                  <label> Skillset </label>
                  <ng-select
                    [items]="buSkillSet"
                    class="custom"
                    bindLabel="name"
                    [multiple]="false"
                    [searchable]="true"
                    [clearable]="true"
                    formControlName="skillSetId"
                  >
                    <!-- <ng-option [value]="addSpecialityForm.get('skillSetId').value" *ngFor="let i of buSkillSet">{{ i.name }}</ng-option> -->
                  </ng-select>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="exampleInputEmail1">Speciality</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="name"
                    />
                    <!-- <div
                        *ngIf="submitted && f.staffId.errors"
                        class="invalid-feedback"
                      >
                        <small *ngIf="f.staffId.errors.required">
                          StaffId is required
                        </small>
                      </div> -->
                    <!-- <small class="text-danger" *ngIf="login.email!==null && !login.email">Enter valid email</small> -->
                  </div>
                </div>
              </div>
              <button
                *ngIf="!updateBtnSpe"
                class="btn btn-add mt-4 pull-right"
                type="submit"
                (click)="addSkillSpeciality()"
              >
                Add
              </button>
              <button
                *ngIf="updateBtnSpe"
                class="btn btn-add mt-4 pull-right"
                type="submit"
                (click)="updateSpeciality()"
              >
                Update
              </button>

              <!-- <div
                        *ngIf="submitted && f.staffId.errors"
                        class="invalid-feedback"
                      >
                        <small *ngIf="f.staffId.errors.required">
                          StaffId is required
                        </small>
                      </div> -->
              <!-- <small class="text-danger" *ngIf="login.email!==null && !login.email">Enter valid email</small> -->
            </form>
          </div>
        </div>
        <div class="card my-3 w-100">
          <div class="card-header flexi-header">
            <h3>SPECIALITY LIST</h3>
          </div>
          <div class="card-body">
            <div class="card table-card">
              <div class="card-body flexi-body">
                <div class="flex-column">
                  <div class="d-flex justify-content-between mt-2 mb-3">
                    <select
                      class="custom-select"
                      style="width: auto"
                      name="pageSize"
                    >
                      <option [ngValue]="10">10</option>
                      <option [ngValue]="20">20</option>
                      <option [ngValue]="30">30</option>
                      <option [ngValue]="40">40</option>
                      <option [ngValue]="50">50</option>
                    </select>
                    <div class="form-group form-inline mb-0">
                      <!-- <span
                        >Please wait...</span
                      > -->
                      <input
                        class="form-control ml-2"
                        type="search"
                        name="searchTerm"
                        placeholder="Search here"
                      />
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table table-striped table-body">
                      <thead>
                        <tr>
                          <th scope="col" sortable="Tier 1">
                            <!-- (sort)="onSortDepartment($event)" -->
                            S.NO
                          </th>
                          <th scope="col" sortable="Tier 2">
                            <!-- (sort)="onSortDepartment($event)" -->
                            SKILLSET
                          </th>
                          <th scope="col" sortable="Tier 2">
                            <!-- (sort)="onSortDepartment($event)" -->
                            SPECIALITY
                          </th>

                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <!-- <pre>{{departmentPTable.data | json}}</pre> -->
                      <tbody>
                        <tr
                          *ngFor="let item of buSkillspeciality; let i = index"
                        >
                          <td>{{ i + 1 }}</td>
                          <td>{{ item.skillSetId.name }}</td>
                          <td>{{ item.name }}</td>

                          <td>
                            <button
                              class="btn btn-add p-2 m-1"
                              (click)="editSpeciality(item)"
                            >
                              Edit
                            </button>
                            <button
                              class="btn btn-danger p-sm-1 m-1"
                              (click)="deleteSpeciality(item)"
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="d-flex justify-content-between">
                  <p>Total Records</p>
                  <ngb-pagination [collectionSize]="" [maxSize]="">
                  </ngb-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #AddLocations let-c="close" let-d="dismiss">
  <div class="modal-content">
    <div class="modal-body" style="padding: 10px; min-height: 100vh">
      <div>
        <div class="card my-3 w-100">
          <div class="card-header flexi-header">
            <h3>ADD REPORTING LOCATION</h3>
          </div>
          <div class="card-body">
            <form [formGroup]="addLocationsForm">
              <div class="form-group">
                <label for="exampleInputEmail1">Reporting Location</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Reporting Location"
                  formControlName="name"
                />
                <button
                  class="btn btn-add mt-4 pull-right"
                  type="submit"
                  [disabled]="addLocationsForm.invalid"
                  (click)="updateLocationFlag ?updateRepoLocation() :addRepoLocation()"
                >
                 {{updateLocationFlag ? 'Update': 'Add'}}
                </button>
                <!-- [disabled]="addLocationsForm.invalid" -->
                <!-- <div
                        *ngIf="submitted && f.staffId.errors"
                        class="invalid-feedback"
                      >
                        <small *ngIf="f.staffId.errors.required">
                          StaffId is required
                        </small>
                      </div> -->
                <!-- <small class="text-danger" *ngIf="login.email!==null && !login.email">Enter valid email</small> -->
              </div>
            </form>
          </div>
        </div>
        <div class="card my-3 w-100">
          <div class="card-header flexi-header">
            <h3>REPORTING LOCATION LIST</h3>
          </div>
          <div class="card-body">
            <div class="card table-card">
              <div class="card-body flexi-body">
                <div class="flex-column">
                  <div class="d-flex justify-content-between mt-2 mb-3">
                    <select
                      class="custom-select"
                      style="width: auto"
                      name="pageSize"
                    >
                      <option [ngValue]="10">10</option>
                      <option [ngValue]="20">20</option>
                      <option [ngValue]="30">30</option>
                      <option [ngValue]="40">40</option>
                      <option [ngValue]="50">50</option>
                    </select>
                    <div class="form-group form-inline mb-0">
                      <!-- <span
                        >Please wait...</span
                      > -->
                      <input
                        class="form-control ml-2"
                        type="search"
                        name="searchTerm"
                        placeholder="Search here"
                      />
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table table-striped table-body">
                      <thead>
                        <tr>
                          <th scope="col" sortable="Tier 1">
                            <!-- (sort)="onSortDepartment($event)" -->
                            S.NO
                          </th>
                          <th scope="col" sortable="Tier 2">
                            <!-- (sort)="onSortDepartment($event)" -->
                            REPORTING LOCATION
                          </th>

                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <!-- <pre>{{departmentPTable.data | json}}</pre> -->
                      <tbody>
                        <tr *ngFor="let item of buRepoLocation | slice: (buRepoLocationTable.page-1) * buRepoLocationTable.limit : buRepoLocationTable.page * buRepoLocationTable.limit; let i = index">
                          <td>{{ item.index }}</td>

                          <td>{{ item.name }}</td>
                          <td>
                            <button class="btn btn-add p-2 m-1" (click)="editLocation(item)">Edit</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="card-footer">
                <div class="d-flex justify-content-between">
                  <p>Total Records {{buRepoLocation?.length}}</p>
                  <ngb-pagination [collectionSize]="buRepoLocationTable.total" [(page)]="buRepoLocationTable.page" [maxSize]="5"
                  (pageChange)="onPageChangeUsers($event)" [pageSize]="buRepoLocationTable.limit">
                  </ngb-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
