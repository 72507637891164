import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subject, Subscription, of } from 'rxjs';
import { map, debounceTime, distinctUntilChanged, mergeMap, delay, finalize } from 'rxjs/operators';
import { SortableHeader, SortEvent } from 'src/app/shared/directive/sortable.directive';
import { StorageService } from 'src/app/core/service/storage.service';

import { BoardSetupService } from 'src/app/users/service/board-setup/board-setup.service';
import { NewsEventsService } from 'src/app/users/service/manage-news & events/news-events.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-news-reported-comments',
  templateUrl: './news-reported-comments.component.html',
  styleUrls: ['./news-reported-comments.component.scss']
})
export class NewsReportedCommentsComponent implements OnInit {
  usersPTable
  isTableLoading: boolean = true;
  // isBuUnitEdited:boolean = false
  // sectionId:string
  // subSectionId:string
  // bussinessUnitEditForm:FormGroup
  public userssearchInput = new Subject<KeyboardEvent>();

  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  // companyName: string;
  /// bussiness unit over view table config
  onSortUsers({ column, direction }: SortEvent, field) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    // this.usersPTable.sort = { key: column, order: direction as any };
    this.usersPTable.sortWith = !!direction ? field : 'createdAt';
    this.usersPTable.sortBy = !!direction ? direction : 'desc';
    this.loadUsers();
  }

  onPageChangeUsers(page: number) {
    this.usersPTable.page = page;
    this.loadUsers();
  }
  onLimitChangeUsers() {
    this.loadUsers();
  }

  private usersSearchSubscription: Subscription;

  constructor(
    private boardService: NewsEventsService,
    public toastService: ToastrService,
    private storageService: StorageService,
    private formBuilder: FormBuilder
  ) {
    // this.config.notFoundText = 'Custom not found';
    this.usersPTable = {
      sortBy: 'desc',
      sortWith: 'createdAt',

      data: [],
      page: 1,
      limit: 10,
      search: '',

    };
    // this.companyName = this.storageService.getByKey('companyId');

    this.usersSearchSubscription = this.userssearchInput
      .pipe(
        map((event) => {
          const target = event.target as HTMLTextAreaElement;
          return target.value;
        }),
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap((search) => of(search).pipe(delay(500)))
      )
      .subscribe((input) => {
        this.usersPTable.page = 1;
        this.loadUsers();
      });
  }

  ngOnInit(): void {
    this.loadUsers();
  }

  changeCommentStatus(id:string, status:number){
    const data = {
      status: status === 1 ? 2 : 1,
      postId:id,
    }
      const subscribe = this.boardService
      .updateReportedComment(data)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          this.toastService.success(response?.data);
          this.loadUsers();
        },
        (error) => {
          this.toastService.success(error?.message?.message);
        }
      );
    }

  loadUsers() {
const {data, loading,total ,...rest}  = this.usersPTable
    const subscribe = this.boardService
      .getAllReportedComments(rest,'news')
      .pipe(
        finalize(() => {

          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const { recordsFiltered, data } = response;
          this.usersPTable.data = data;
          this.usersPTable.total = recordsFiltered;
          this.isTableLoading = false;
        },
        (error) => {
          this.usersPTable.data = [];
          this.usersPTable.total = 0;
          this.toastService.error('No list found');
        }
      );
  }

  ngOnDestroy(): void {
    this.usersSearchSubscription && this.usersSearchSubscription.unsubscribe();
  }


}
