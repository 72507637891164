import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'field-builders',
  template: `
  
  <div class="form-group " [formGroup]="form">
    <label class=" form-control-label" [attr.for]="field.question">
      {{field.question  |titlecase}}
      <strong class="text-danger" *ngIf="field.required && form.get(field.question).invalid">*</strong>
    </label>
    <div  [ngSwitch]="field.type">


      <checkboxs  *ngSwitchCase="1"  [field]="field" [form]="form.get(field.question)"></checkboxs>
      <checkboxs  *ngSwitchCase="2"  [field]="field" [form]="form.get(field.question)"></checkboxs>
      <checkboxs  *ngSwitchCase="3"  [field]="field" [form]="form.get(field.question)"></checkboxs>
      <checkboxs  *ngSwitchCase="4"  [field]="field" [form]="form.get(field.question)"></checkboxs>



      <checkboxs  *ngSwitchCase="5" [field]="field" [form]="form.get(field.question)"></checkboxs>
      <checkboxs  *ngSwitchCase="6" [field]="field" [form]="form.get(field.question)"></checkboxs>
      <dropdowns *ngSwitchCase="11"  [field]="field" [form]="form.get(field.question)"></dropdowns>


      <div class="alert alert-danger my-1 p-2 fadeInDown animated " *ngIf="!isValid && isDirty"> <small>{{field.fieldName}} is required</small></div>
</div>
  </div>
  `
})
export class QuestionBuilder implements OnInit {
  @Input() field:any;
  @Input() form:any;
  questionTypes = [
    {
      name: 'Multiple Choices',
      type: 1,
    },
    {
      name: 'Check Box',
      type: 2,
    },
    {
      name: 'Polling',
      type: 3,
    },
    {
      name: 'True or False Options',
      type: 4,
    },
    {
      name: 'Signature',
      type: 5,
    },
    {
      name: 'Profile Fields',
      type: 6,
    },
    {
      name: 'Free Text(Long)',
      type: 7,
    },
    {
      name: 'Free Text(Short)',
      type: 8,
    },
    {
      name: 'Numeric',
      type: 9,
    },
    {
      name: 'Date & Time',
      type: 10,
    },
    {
      name: 'DropDown',
      type: 11,
    },
    {
      name: 'Attachment',
      type: 12,
    },
    {
      name: 'Star Rating',
      type: 13,
    },
    {
      name: 'Nominate User',
      type: 14,
    },
    {
      name: 'Sequence Type',
      type: 15,
    },
  ];
  get isValid() { return this.form.controls[this.field.question].valid; }
  get isDirty() { return this.form.controls[this.field.question].dirty; }

  constructor() { }

  ngOnInit() {
  }

}
