import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, of } from 'rxjs';
import {
  map,
  debounceTime,
  distinctUntilChanged,
  mergeMap,
  delay,
  finalize,
} from 'rxjs/operators';
import {
  NgbdSortableHeader,
  SortEvent,
} from 'src/app/core/directive/sortable.directive';
import { StorageService } from 'src/app/core/service/storage.service';
import { SetupService } from 'src/app/users/service/operations/setup.service';

@Component({
  selector: 'app-bu-shift-setup-overview',
  templateUrl: './bu-shift-setup-overview.component.html',
  styleUrls: ['./bu-shift-setup-overview.component.scss'],
})
export class BuShiftSetupOverviewComponent implements OnInit {
  @Output() changeActiveBu = new EventEmitter<any>();
  buSetup: [];
  @Output() heading: string;
  buSetupCount: any;
  bussinesUnitPTable;
  buShiftSetUpTable;
  isBuShiftSetUpEdited = false;
  sectionId: string;
  subSectionId: string;
  buShiftSetUpForm: FormGroup;
  paginateData:any=[];
  public buShiftSetUpSearchInput = new Subject<KeyboardEvent>();
  @ViewChildren(NgbdSortableHeader)
  headers: QueryList<NgbdSortableHeader>;
  companyName: string;

  private buShiftSetUpSearchSubscription: Subscription;
  loader: boolean = true;
  buShiftOverviewErr: boolean = false;

  constructor(
    private buShiftSetupService: SetupService,
    public toastService: ToastrService,
    private storageService: StorageService,
    private formBuilder: FormBuilder
  ) {
    // this.config.notFoundText = 'Custom not found';
    this.bussinesUnitPTable = {
      sortBy: 'desc',
      sortWith: 'createdBy',
      data: [],
      page: 1,
      limit: 10,
      search: "",
    };
    this.companyName = this.storageService.getByKey('companyId');
    this.buShiftSetUpSearchSubscription = this.buShiftSetUpSearchInput
      .pipe(
        map((event) => {
          const target = event.target as HTMLTextAreaElement;
          return target.value;
        }),
        debounceTime(500),
        distinctUntilChanged()
        //mergeMap((search) => of(search).pipe(delay(500)))
      )
      .subscribe((input) => {
        this.bussinesUnitPTable.page = 1;
        this.loadBuShiftSetUp();
      });
  }

  ngOnInit(): void {
    this.loadBuShiftSetUp();
    // this.buOverviewTable();
    this.buShiftSetUpForm = this.formBuilder.group({
      status: ['active', [Validators.required]],
      name: ['', [Validators.required]],
      buUnit: [''],
    });
  }

  /// bussiness unit over view table config
  onSortBuShiftSetup({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.buShiftSetUpTable.sort = { key: column, order: direction as any };
    this.loadBuShiftSetUp();
  }

  loadBuShiftSetUp() {
    this.loader = true;
    const { data, total, ...rest } = this.bussinesUnitPTable;
    const subscribe = this.buShiftSetupService
      .getBuShiftDetails({search : this.bussinesUnitPTable.search})
      .pipe(
        finalize(() => {
          this.loader = false;
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const businessUnitList = response.data.businessUnitList;
          this.bussinesUnitPTable.data = businessUnitList;
          this.bussinesUnitPTable.total = businessUnitList.length;
          this.getPaginateData();
        },
        (error) => {
          this.bussinesUnitPTable.data = [];
          this.bussinesUnitPTable.total = 0;
          this.toastService.error('No list found');
          this.buShiftOverviewErr = true;
        }
      );
    console.log('response.result', this.bussinesUnitPTable.data);
  }
  getPaginateData(){

    this.paginateData =  this.bussinesUnitPTable.data
     .slice((this.bussinesUnitPTable.page - 1) * this.bussinesUnitPTable.limit, (this.bussinesUnitPTable.page - 1) * this.bussinesUnitPTable.limit + this.bussinesUnitPTable.limit);

   }
  onPageChangeBuShiftSetUp(page: number) {
    this.bussinesUnitPTable.page = page;
    this.getPaginateData();
  }

  onLimitChangeBuShiftSetUp() {
    this.loadBuShiftSetUp();
  }

  // buOverviewTable() {
  //   this.buShiftSetupService.getBuShiftDetails().subscribe((res) => {
  //     this.buSetup = res["result"]["businessUnitList"];
  //     this.buSetupCount = this.buSetup.length;
  //     console.log("BuSetup", this.buSetup);
  //   });
  // }

  onUpdateBuShiftSetup() {
    const data = {
      name: this.buShiftSetUpForm.get('name').value,
      status: this.buShiftSetUpForm.get('status').value,
    };
    this.buShiftSetupService
      .updateSubsection(this.subSectionId, this.sectionId, data)
      .subscribe(
        (res) => {
          console.log(res);
          this.onClearBuUnitEdit();
        },
        (error: HttpErrorResponse) => {
          switch (error.status) {
            case 422:
              for (const key in error.error.error) {
                if (
                  Object.prototype.hasOwnProperty.call(error.error.error, key)
                ) {
                  const element = error.error.error[key];
                  this.toastService.error(element);
                }
              }
              break;
            case 401:
              this.toastService.error(error.error.error);
              break;

            default:
              this.toastService.error(error.error.error);
              break;
          }
        }
      );
  }

  onClearBuUnitEdit() {
    this.isBuShiftSetUpEdited = false;
    this.buShiftSetUpForm.reset();
    this.sectionId = null;
    this.subSectionId = null;
  }

  ngOnDestroy(): void {
    this.buShiftSetUpSearchSubscription &&
      this.buShiftSetUpSearchSubscription.unsubscribe();
  }

  onEditBuShiftSetup(value) {
    console.log('edit value=========', value);

    this.changeActiveBu.emit(value);
  }
}
