<div class="d-flex flex-column">
  <ul ngbNav [(activeId)]="activeId" #companyNav="ngbNav" class="nav-tabs rounded mb-3">
    <li [ngbNavItem]="1">
      <a ngbNavLink (click)="overViewEvent($event)">OVERVIEW</a>
      <ng-template ngbNavContent>
        <users-overview (EditEvent)="userEdit($event)"></users-overview>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink>{{ isUserEdited ? "EDIT" : "ADD" }}</a>
      <ng-template ngbNavContent>
        <app-add-edit-users
          [userId]="userId"
          (overViewEvent)="overViewEvent($event)"
        ></app-add-edit-users>
      </ng-template>
    </li>
    <li [ngbNavItem]="3">
      <a ngbNavLink>BULK IMPORT</a>
      <ng-template ngbNavContent>
        <div class="card">
          <div class="card-header bg-theme">
            <h5 class="m-0">CREATE USER</h5>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <div class="custom-file mt-0 pt-4 pb-4">
                  <input
                    type="file"
                    #myInput
                    accept=".xls,.xlsx,.csv "
                    class="custom-file-input"
                    (change)="iconUpload($event)"
                    id="c"
                  />
                  <label class="custom-file-label" for="i">
                    <i class="fa " [ngClass]="{'fa-spin fa-spinner fa-2x': isLoading, ' fa-plus-circle fa-2x': !isLoading}" aria-hidden="true" style="margin-right: 10px;"></i>
                    <span class="custom-file-label-text">Choose File To Upload </span>
                  </label>
                </div>
              </div>
              <div class="col-md-4" *ngIf="IconUrl">
                <table class="table table-borderless">
                  <tbody>
                    <tr class="image-with-delete-icon">
                      <td class="m-0">
                        <p>{{ IconUrl }}</p>
                      </td>
                      <td>
                        <i
                          class="fa fa-close fa-xs"
                          title="Delete"
                          (click)="onDeleteIcon()"
                        ></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row">
              <div class="col-4 mt-2">
                <button
                  class="btn btn-add"
                  [disabled]="!IconUrl"
                  (click)="createBulkUsers()"
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="companyNav"></div>
</div>
