import { Component, EventEmitter, OnInit, Output, QueryList, ViewChildren, TemplateRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, of } from 'rxjs';
import { map, debounceTime, distinctUntilChanged, mergeMap, delay, finalize } from 'rxjs/operators';
import { SortableHeader, SortEvent } from 'src/app/shared/directive/sortable.directive';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChannelService } from 'src/app/users/service/channel/channel.service';
import { NotificationService } from 'src/app/users/service/notification/notification.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';
@Component({
  selector: 'app-channel-overview',
  templateUrl: './channel-overview.component.html',
  styleUrls: ['./channel-overview.component.scss']
})
export class ChannelOverviewComponent implements OnInit {
  userDetails
  onViewChannelData
  ID: string = ''
  type: string = '';
  startDate: string = '';
  endDate: string = ''
  channelPTable
  isLoading: boolean = false;
  @Output() editChannel = new EventEmitter();
  public channelsearchInput = new Subject<KeyboardEvent>();
  isTableLoading: boolean = true;
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  // companyName: string;
  /// bussiness unit over view table config
  onSortChannel({ column, direction }: SortEvent, field) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.channelPTable.sortWith = field;
    this.channelPTable.sortBy = direction;
    this.loadChannel();
  }

  onPageChangeChannel(page: number) {
    this.channelPTable.page = page;
    this.loadChannel();
  }
  onLimitChangeChannel() {
    this.loadChannel();
  }

  private channelSearchSubscription: Subscription;

  constructor(
    private formbuilder: FormBuilder,
    private channelService: ChannelService,
    public toastService: ToastrService,
    public modelService: NgbModal,
    private notificationService: NotificationService,
  ) {
    // this.config.notFoundText = 'Custom not found';
    this.channelPTable = {
      sortWith: 'name',
      sortBy: 'desc',

      data: [],
      page: 1,
      limit: 10,
      search: '',

    };
    // this.companyName = this.storageService.getByKey('companyId');

    this.channelSearchSubscription = this.channelsearchInput
      .pipe(
        map((event) => {
          const target = event.target as HTMLTextAreaElement;
          return target.value;
        }),
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap((search) => of(search).pipe(delay(500)))
      )
      .subscribe((input) => {
        this.channelPTable.page = 1;
        this.loadChannel();
      });
  }

  ngOnInit(): void {
    this.loadChannel();
    this.editChannel.emit(null)
  }

  exportsSetupForm = new FormGroup({
    type: new FormControl('', Validators.required),
    startDate: new FormControl('', Validators.required),
    endDate: new FormControl('', Validators.required),
  })


  @ViewChild('AddSection', { static: true })
  AddSection: TemplateRef<any>;

  openExportModal(id) {
    this.ID = id;
    this.isLoading = false;
    this.modelService.open(this.AddSection, {
      windowClass: 'modal-ui-fix right-align',
    });
  }

  loadChannel() {
    const { data, loading, total, ...rest } = this.channelPTable
    const subscribe = this.channelService
      .getChannels(rest)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const { recordsTotal, data } = response;
          this.channelPTable.data = data;
          this.channelPTable.total = recordsTotal;
          this.isTableLoading = false;
        },
        (error) => {
          this.channelPTable.data = [];
          this.channelPTable.total = 0;
          this.toastService.error('No list found');
        }
      );
  }

  onEditChannel(id: string) {
    this.editChannel.emit(id)
  }

  onViewChannel(id: string) {
    this.onViewChannelData = true
    this.channelService.getOneChannel(id).subscribe((res) => {
      this.userDetails = res.data
    })
  }

  closeModal(){
    this.modelService.dismissAll()
  }

  onStartDateSelect(event) {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;
    let day = event.day <= 9 ? '0' + event.day : event.day;
    let finalDate = year + '-' + month + '-' + day;
    let date = moment(finalDate, "YYYY-MM-DD HH:mm:ss Z").format("MM-DD-YYYY") + " " + "00:00:00 GMT" + moment().format('ZZ')


    this.startDate = date.toString();
  }

  onEndDateSelect(event) {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;
    let day = event.day <= 9 ? '0' + event.day : event.day;
    let finalDate = year + '-' + month + '-' + day;
    let date = moment(finalDate, "YYYY-MM-DD HH:mm:ss Z").format("MM-DD-YYYY") + " " + "00:00:00 GMT" + moment().format('ZZ')

    this.endDate = date.toString();
  }

  onExportItem() {
    let data = {
      channelId:this.ID,
      type: this.exportsSetupForm.value.type,
      startDate: this.startDate,
      endDate: this.endDate,
    }

    if(!data){
      this.toastService.error('Please Fill these fields');
    }

    this.channelService.exportChannels(this.ID, data).subscribe(
      (res: any) => {
        if(res?.status && res?.filePath){
          this.notificationService.downloadUrl(res?.filePath).subscribe((content) => {
            var a = document.createElement('a');
            a.href = 'data:text/csv,' + content;
            a.target = '_blank';
            a.download = data.type+"_channel_" + new Date() + ".csv";
            document.body.appendChild(a);
            a.click()
          });
          }
        // window.open(res, "Exported Data");
      },
      (error) => {
        this.toastService.error('No data found');
      }
    );
  }


  ngOnDestroy(): void {
    this.channelSearchSubscription && this.channelSearchSubscription.unsubscribe();
  }
}
