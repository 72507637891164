import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'field-builder',
  template: `
  <div class="form-group " [formGroup]="form">
    <label class=" form-control-label" [attr.for]="field.fieldName">
      {{field.fieldName |titlecase}}
      <strong class="text-danger"   *ngIf="field.required && form.get(field.fieldName).invalid && !field.disabled">*</strong>
    </label>
    <div class="" [ngSwitch]="field.type">
      <textbox *ngSwitchCase="'text'" [field]="field" [form]="form.get(field.fieldName)"></textbox>
      <textbox *ngSwitchCase="'date'" [field]="field" [form]="form.get(field.fieldName)"></textbox>
      <dropdown *ngSwitchCase="'dropdown'" [field]="field" [form]="form.get(field.fieldName)"></dropdown>
      <checkbox *ngSwitchCase="'checkbox'" [field]="field" [form]="form.get(field.fieldName)"></checkbox>
      <radio *ngSwitchCase="'radio'" [field]="field" [form]="form.get(field.fieldName)"></radio>
      <file *ngSwitchCase="'file'" [field]="field" [form]="form.get(field.name)"></file>
      <div class="alert alert-danger my-1 p-2 fadeInDown animated " *ngIf="!isValid && isDirty"> <small>{{field.fieldName}} is required</small></div>
    </div>
  </div>
  `
})
export class FieldBuilderComponent implements OnInit {
  @Input() field:any;
  @Input() form:any;

  get isValid() { return this.form.controls[this.field.fieldName].valid; }
  get isDirty() { return this.form.controls[this.field.fieldName].dirty; }

  constructor() { }

  ngOnInit() {
  }

}
