import { Component, Input, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { SetupService } from 'src/app/users/service/operations/setup.service';

@Component({
  selector: 'app-shift-scheme-logs',
  templateUrl: './shift-scheme-logs.component.html',
  styleUrls: ['./shift-scheme-logs.component.scss'],
})
export class ShiftSchemeLogsComponent implements OnInit {
  @Input() BusinessUnit;
  schemeLogTable: any;
  shiftSchemeLoader: boolean = true;

  constructor(private setupService: SetupService) {}

  ngOnInit(): void {
    this.getSchemeLog();
  }

  getSchemeLog() {
    const logPayload = {
      businessUnitId: '5bd723a8c1e35a7a250d562a',
      type: 1,
    };

    const schemeLog = this.setupService
      .schemeUserLog(logPayload)
      .pipe(
        finalize(() => {
          this.shiftSchemeLoader = false;
          schemeLog.unsubscribe();
        })
      )
      .subscribe((res: { data }) => {
        this.schemeLogTable = res.data;
        console.log('schemetable', res);
      });
  }
}
