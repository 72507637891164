import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { ShiftSchedulerService } from 'src/app/users/service/operations/shift-scheduler.service';

@Component({
  selector: 'app-view-booking-overview',
  templateUrl: './view-booking-overview.component.html',
  styleUrls: ['./view-booking-overview.component.scss'],
})
export class ViewBookingOverviewComponent implements OnInit {
  isHour: boolean = true;
  @Input() bu;
  @Input() startDate;
  @Input() graphData;

  startDay: string;
  currWeek: any;
  buWeekArr = [];
  weekCount = 0;
  stafflisting: any;
  endDay: string;
  shiftReadReq: any;
  shiftRead: Object;
  dashboardData: any;
  rawGraph = [];

  graphNeed = [];
  graphBooked = [];

  graphHrNeed = [];
  graphHrBooked = [];
  hrAndShiftChartData: any;
  graphLoader: boolean = true;

  bookShiftArr = [];
  openShiftArr = [];
  selectedDay: any;

  dayArr = [
    { count: 0, d: 'M', day: 'monday' },
    { count: 1, d: 'T', day: 'tuesday' },
    { count: 2, d: 'W', day: 'wednesday' },
    { count: 3, d: 'T', day: 'thursday' },
    { count: 4, d: 'F', day: 'friday' },
    { count: 5, d: 'S', day: 'saturday' },
    { count: 6, d: 'S', day: 'sunday' },
  ];
  yAxesLabel: any;
  index: any = 2;
  constructor(private planShift: ShiftSchedulerService) {}

  ngOnInit(): void {
    this.shiftRead = this.graphData;
    this.graphLoader = false;
    this.getGraphData();
    this.dayViewGraph(this.dayArr[0]);
  }

  getGraphData() {
    this.rawGraph = this.shiftRead['graphDataWeb'];

    for (var i = 0; i < 7; i++) {
      this.buWeekArr.push(
        moment(this.startDate).add(i, 'days').format('DD-MM-YYYY')
      );
    }

    this.buWeekArr.forEach((val) => {
      if (this.rawGraph.hasOwnProperty(val)) {
        this.graphNeed.push(this.rawGraph[val]['numberOfShifts'].need);
        this.graphBooked.push(this.rawGraph[val]['numberOfShifts'].booked);
        this.graphHrNeed.push(this.rawGraph[val]['totalHours'].need);
        this.graphHrBooked.push(this.rawGraph[val]['totalHours'].booked);
      } else {
        this.graphNeed.push(0);
        this.graphBooked.push(0);
        this.graphHrNeed.push(0);
        this.graphHrBooked.push(0);
      }
    });

    this.changeHrAndNumber(true);
  }

  changeHrAndNumber(value) {
    this.isHour = value;
    if (value === true) {
      this.hrAndShiftChartData = [
        {
          data: this.graphHrNeed,
        },
        {
          data: this.graphHrBooked,
        },
      ];
      this.index = 2;
    } else {
      this.hrAndShiftChartData = [
        {
          data: this.graphNeed,
        },
        {
          data: this.graphBooked,
        },
      ];
      this.index = 1;
    }
    this.dayViewGraph(this.dayArr[0]);
  }

  hrAndShiftChartoptions = {
    responsive: true, // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
    scales: {
      xAxes: [
        {
          maxBarThickness: 40,
          gridLines: {
            display: false,
          },
        },
      ],
    },
  };

  hrAndShiftChartLabels = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  // CHART COLOR.
  hrAndShiftChartColors = [
    {
      backgroundColor: '#a42138',
    },
    {
      backgroundColor: '#6BB11E',
    },
  ];

  // Day View Chart
  dayViewGraph(i) {
    this.bookShiftArr = [];
    this.openShiftArr = [];

    this.yAxesLabel = [];

    this.selectedDay = i;
    const weeklyStaffGraphData = this.shiftRead['weeklyStaffGraphData'];

    const bookShift = weeklyStaffGraphData.staffAppliedData[i.day];
    for (let i in bookShift) {
      this.bookShiftArr.push(bookShift[i][this.index]);
      this.yAxesLabel.push(moment(bookShift[i][0]).format('HH:mm'));
    }

    const openShift = weeklyStaffGraphData.staffNeedData[i.day];
    for (let i in openShift) {
      this.openShiftArr.push(openShift[i][this.index]);
    }

    this.dayChartData = [
      {
        label: 'Booked Open Shift',
        data: this.bookShiftArr,
        fill: false,
        borderWidth: 3,
        lineTension: 0,
        pointStyle: 'star',
      },
      {
        label: 'Planned Open Shift',
        data: this.openShiftArr,
        fill: false,
        borderWidth: 3,
        lineTension: 0,
        pointStyle: 'rect',
      },
    ];
  }

  dayChartData = [
    {
      label: 'Booked Open Shift',
      data: this.bookShiftArr,
      fill: false,
      borderWidth: 3,
      lineTension: 0,
      pointStyle: 'star',
    },
    {
      label: 'Planned Open Shift',
      data: this.openShiftArr,
      fill: false,
      borderWidth: 3,
      lineTension: 0,
      pointStyle: 'rect',
    },
  ];

  dayChartOptions = {
    responsive: true,
    borderColor: 'blue',
    scales: {
      xAxes: [
        {
          ticks: {
            callback: function (value, index, values) {
              return index % 4 == 0 ? value : '';
            },
            color: 'red',
            maxRotation: 0,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            borderDash: [8, 4],
          },
        },
      ],
    },
  };

  dayChartColorOption = [
    { borderColor: '#6BB11E', backgroundColor: '#6BB11E' },
    { borderColor: '#a42138', backgroundColor: '#a42138' },
  ];
}
