<div class="card">
  <h5 class="card-header bg-theme">LOCKED ACCOUNTS</h5>
  <div class="card-body">
    <div class="card table-card">
      <div class="card-body flexi-body">
        <div class="flex-column">
          <div class="d-flex justify-content-between mt-2 mb-3">
            <select
              class="custom-select"
              style="width: auto"
              name="pageSize"
              (change)="onLimitChangeUsers()"
              [(ngModel)]="usersPTable.limit"
            >
              <option [ngValue]="10">10</option>
              <option [ngValue]="20">20</option>
              <option [ngValue]="30">30</option>
              <option [ngValue]="40">40</option>
              <option [ngValue]="50">50</option>
            </select>
            <div class="form-group form-inline mb-0">
              <span *ngIf="usersPTable.isLoading">Please wait...</span>
              <input
                class="form-control ml-2"
                type="search"
                name="searchTerm"
                (search)="loadUsers()"
                (keyup)="userssearchInput.next($event)"
                placeholder="Search here"
                [(ngModel)]="usersPTable.search"
              />
            </div>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th
                    scope="col"
                    sortable="1"
                    (sort)="onSortUsers($event, 'name', 1)"
                  >
                    USER NAME
                  </th>
                  <th
                    scope="col"
                    sortable="2"
                    (sort)="onSortUsers($event, 'staffId', 2)"
                  >
                    STAFF ID
                  </th>
                  <th
                    scope="col"
                  >
                    CONTACT NUMBER
                  </th>
                  <th
                    scope="col"
                  >
                    APPOINMENT
                  </th>

                  <th
                    scope="col"
                  >
                    PARENT BUSSINESS UNIT
                  </th>

                  <th scope="col">ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of usersPTable.data">
                  <td>
                    {{ item.name }}
                  </td>

                  <td>{{ item.staffId }}</td>

                  <td>{{item.contactNumber}}</td>

                  <td>{{ item.appointment.name }}</td>
                  <td>{{ item.businessUnit }}</td>
                  <td>
                    <button
                      class="btn btn-add p-2 m-1"
                      (click)="unLockAccount(item._id)"
                    >
                      Unlock
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <app-skliton-loading-table
            [numberOfCols]="6"
            [isTableLoading]="isTableLoading"
          ></app-skliton-loading-table>
        </div>
      </div>
      <div class="card-footer">
        <div class="d-flex justify-content-between">
          <p>Total records {{ usersPTable.total }}</p>
          <ngb-pagination
            [collectionSize]="usersPTable.total"
            [(page)]="usersPTable.page"
            [maxSize]="5"
            (pageChange)="onPageChangeUsers($event)"
            [pageSize]="usersPTable.limit"
          >
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
