<header class="row mx-0 align-items-center shadow-sm">
  <div class="col col-sm-2">
    <div class="brand d-flex align-items-center justify-content-start">
      <figure class="figure">
        <img src="{{companyLogo}}" alt="" srcset="" width="36" height="36" />
      </figure>
      <!-- dont remove this code -->
      <!-- <b class="d-sm-block d-none">Sats 12</b> -->
      <button class="btn d-sm-none d-block" (click)="toggleMenu()">
        <i class="material-icons">menu</i>
      </button>
    </div>
  </div>
  <div class="col col-sm-8 d-none d-sm-block px-md-3">
    <h5 class="m-0">System User</h5>
    <span class="h6 m-0 text-muted">{{ activeUrl }}</span>
  </div>
  <div class="col col-sm-2">
    <div class="user-settings d-flex align-items-center justify-content-end">
      <i class="material-icons mx-3 position-relative link circle rounded-circle"
        >notifications_none
        <span class="badge badge-danger rounded-circle notification-badge">
          {{ notificationCount }}
        </span>
      </i>
      <b>{{ userData }}</b>
      <figure (click)="profile()">
        <img crossorigin="anonymous"
          src="{{
            profilePicture ? validateURLImage(profilePicture) : '../assets/images/user1.png'
          }}"
          alt=""
          class="rounded-circle link"
          onerror="this.src='./assets/images/user1.png';"
        />
      </figure>
      <i class="material-icons link circle rounded-circle" title="Logout" (click)="logout()"
        >exit_to_app</i
      >
    </div>
  </div>
</header>
