import { Component, EventEmitter, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subject, Subscription, of } from 'rxjs';
import { map, debounceTime, distinctUntilChanged, mergeMap, delay, finalize } from 'rxjs/operators';
import { SortableHeader, SortEvent } from 'src/app/shared/directive/sortable.directive';
import { StorageService } from 'src/app/core/service/storage.service';

import { BoardSetupService } from 'src/app/users/service/board-setup/board-setup.service';
import { NewsEventsService } from 'src/app/users/service/manage-news & events/news-events.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
@Component({
  selector: 'news-overview',
  templateUrl: './news-overview.component.html',
  styleUrls: ['./news-overview.component.scss']
})
export class NewsOverviewComponent implements OnInit {

  usersPTable
  // isBuUnitEdited:boolean = false
  // sectionId:string
  // subSectionId:string
  // bussinessUnitEditForm:FormGroup
  public userssearchInput = new Subject<KeyboardEvent>();
  isTableLoading: boolean = true;
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  isNewsView: boolean;
  newsId: string;
  newsData: any;
  todayDate: string;
  // companyName: string;
  /// bussiness unit over view table config
  onSortUsers({ column, direction }: SortEvent, field) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.usersPTable.sortWith = !!direction ? field : 'createdBy';
    this.usersPTable.sortBy = !!direction ? direction : 'desc';
    this.loadUsers();
  }

  onPageChangeUsers(page: number) {
    this.usersPTable.page = page;
    this.loadUsers();
  }
  onLimitChangeUsers() {
    this.loadUsers();
  }
  @Output() EditEvent = new EventEmitter<any>();
  private usersSearchSubscription: Subscription;

  constructor(
    private newsService: NewsEventsService,
    public toastService: ToastrService,
    private storageService: StorageService,
    private formBuilder: FormBuilder
  ) {
    // this.config.notFoundText = 'Custom not found';
    this.usersPTable = {
      sortBy: 'desc',
      sortWith: 'createdBy',
      postType:'news',
      data: [],
      page: 1,
      limit: 10,
      search: '',

    };
    // this.companyName = this.storageService.getByKey('companyId');

    this.usersSearchSubscription = this.userssearchInput
      .pipe(
        map((event) => {
          const target = event.target as HTMLTextAreaElement;
          return target.value;
        }),
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap((search) => of(search).pipe(delay(500)))
      )
      .subscribe((input) => {
        this.usersPTable.page = 1;
        this.loadUsers();
      });
  }

  ngOnInit(): void {
    this.loadUsers();
    this.getCurrentDate()
  }

  getCurrentDate(){
    const currentDate = new Date();
    this.todayDate = moment(currentDate).utc().format();
  }

  loadUsers(type?:string) {
    const {data, loading,total ,...rest}  = this.usersPTable
    const companyId = localStorage.getItem('flexicompanyId');
    const subscribe = this.newsService
      .getAllNewsEventsOverview(companyId,rest)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const {data, count } = response.data;
          this.usersPTable.data = data;
          this.usersPTable.total = count;
          this.isTableLoading = false;
          if (type === 'refresh') {
            this.toastService.success('Data Refreshed Successfully');
          }
        },
        (error) => {
          this.usersPTable.data = [];
          this.usersPTable.total = 0;
          this.toastService.error('No list found');
        }
      );
  }
  onEditWall(newsData: any) {
    this.EditEvent.emit(newsData);
  }

  checkNewsStatus(startDate: string, endDate: string) {
    if(moment() < moment(startDate)) return "Pending Publication";
    if(moment() > moment(endDate)) return "Expired";
    if(moment() < moment(endDate)) return "Published";
   }

   checkNewsColorStatus(startDate: string, endDate: string) {
    if(moment() < moment(startDate)) return "badge-primary";
    if(moment() > moment(endDate)) return "badge-danger";
    if(moment() < moment(endDate)) return "badge-success";
   }

  onViewWall(id: string, item: any){
    this.newsId = id
    this.isNewsView = true
    this.newsData = item;
      }
      ViewEvent(){
        this.isNewsView = false
        this.loadUsers()
      }

  ngOnDestroy(): void {
    this.usersSearchSubscription && this.usersSearchSubscription.unsubscribe();
  }

}
