<div class="card mb-3 w-100">
  <div class="card-header flexi-header">
    <h3>EVENT VIEW</h3>
    <button
      class="btn pull-right btn-outline-light"
      (click)="onCancelView()"
      type="button"
    >
      Back
    </button>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-8">
        <div class="card my-3 w-100 p-3">
          <div>
            <p [innerHTML]="contentTitle | safeHtml"></p>
          </div>
          <div class="card-body">
            <div class="row" style="overflow: hidden">
              <img
                width="100%"
                height="100%"
                [src]="eventObject?.content?.image ? validateURLImage(eventObject.content.image) : null"
                alt=""
                crossorigin="anonymous"
              />
            </div>
          </div>
          <div>
            <p [innerHTML]="eventTitle | safeHtml"></p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card my-3 w-100">
          <div class="custom-header">
            <p>AUTHOR INFO</p>
          </div>
          <hr class="horizontal-line" />
          <div class="card-body">
            <div
              class="col-10"
              style="
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                align-items: center;
              "
            >
              <img
                class="image--cover"
                [src]="eventObject?.authorId?.profilePicture ? validateURLImage(eventObject.authorId.profilePicture) : null"
                alt="Avatar"
                crossorigin="anonymous"
              />

              <p>
                <strong class="ng-binding">{{
                  eventObject?.authorId?.name
                }}</strong>
                <small class="d-block text-muted ng-binding">
                  {{ eventObject?.authorId?.parentBussinessUnitId?.orgName }}
                </small>
              </p>
            </div>
          </div>
        </div>
        <div class="card my-3 w-100">
          <div class="custom-header">
            <p>PUBLICATION</p>
          </div>
          <hr class="horizontal-line" />
          <div class="card-body">
            <div class="space-between">
              <p class="list-heading">Channel</p>
              :
              <span class="list-value ml-1">
                {{ eventObject?.channelId?.name }}</span
              >
            </div>
            <div class="space-between">
              <p class="list-heading">Category</p>
              :
              <span class="list-value ml-1">
                {{ eventObject?.categoryId?.name }}</span
              >
            </div>

            <div class="space-between">
              <p class="list-heading">Published On</p>
              :
              <span class="list-value ml-1">
                {{ eventObject?.publishing?.startDate | date: "medium" }}</span
              >
            </div>

            <div class="space-between">
              <p class="list-heading">Expire On</p>
              :
              <span class="list-value ml-1">
                {{ eventObject?.publishing?.endDate | date: "medium" }}</span
              >
            </div>

            <div class="space-between">
              <p class="list-heading">Organiser Name</p>
              :
              <span class="list-value ml-1">
                {{ eventObject?.eventDetails?.organizerName }}</span
              >
            </div>

            <div class="space-between">
              <p class="list-heading">Event Start Date</p>
              :
              <span class="list-value ml-1">
                {{ eventObject?.eventDetails?.startDate | date: "medium" }}</span
              >
            </div>

            <div class="space-between">
              <p class="list-heading">Location</p>
              :
              <span class="list-value ml-1">
                {{ eventObject?.eventDetails?.address }}</span
              >
            </div>
          </div>
        </div>

        <div class="card my-3 w-100">
          <div class="custom-header">
            <p>EVENT LOGS</p>
          </div>
          <div class="card-body p-0 overflow-auto">
            <table class="table">
              <thead>
                <tr>
                  <th sortable="Tier 1" class="table-col">USER</th>
                  <th sortable="Tier 2" class="table-col">DATE AND TIME</th>
                  <th sortable="Tier 3" class="table-col">DESCRIPTION</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of logPtable.data">
                  <td class="table-col w-25">{{ item.authorId.name }}</td>
                  <td class="table-col">
                    {{ item.createdAt | date: "dd/MM/yyyy HH:mm" }}
                  </td>

                  <td class="table-col">{{ item.logDescription }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
