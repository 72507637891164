import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subject, Subscription, of } from 'rxjs';
import {
  map,
  debounceTime,
  distinctUntilChanged,
  mergeMap,
  delay,
  finalize,
} from 'rxjs/operators';
import {
  SortableHeader,
  SortEvent,
} from 'src/app/shared/directive/sortable.directive';
import { StorageService } from 'src/app/core/service/storage.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NewsEventsService } from 'src/app/users/service/manage-news & events/news-events.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

@Component({
  selector: 'events-overview',
  templateUrl: './events-overview.component.html',
  styleUrls: ['./events-overview.component.scss'],
})
export class EventsOverviewComponent implements OnInit {
  closeResult = '';
  isEventView: boolean = false;
  isManageOpen: boolean = false;
  usersPTable
  isTableLoading: boolean = true;
  eventId: string = null
  eventData: any = null;
  // isBuUnitEdited:boolean = false
  // sectionId:string
  // subSectionId:string
  // bussinessUnitEditForm:FormGroup
  public userssearchInput = new Subject<KeyboardEvent>();
  @Output() isManage = new EventEmitter<string>();
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  // companyName: string;
  /// bussiness unit over view table config
  onSortUsers({ column, direction }: SortEvent, field) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.usersPTable.sortWith = !!direction ? field : 'createdAt';
    this.usersPTable.sortBy = !!direction ? direction : 'desc';
    
    this.loadUsers();
  }

  onPageChangeUsers(page: number) {
    this.usersPTable.page = page;
    this.loadUsers();
  }
  onLimitChangeUsers() {
    this.loadUsers();
  }
  @Output() EditEvent = new EventEmitter<string>();
  @Output() ManageEvent = new EventEmitter<string>();

  private usersSearchSubscription: Subscription;

  constructor(
    private newsService: NewsEventsService,
    public toastService: ToastrService,
    private storageService: StorageService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal
  ) {
    // this.config.notFoundText = 'Custom not found';
    this.usersPTable = {
      sortBy: 'desc',
      sortWith: 'createdAt',
      postType: 'event',
      data: [],
      page: 1,
      limit: 10,
      search: '',
    };
    // this.companyName = this.storageService.getByKey('companyId');

    this.usersSearchSubscription = this.userssearchInput
      .pipe(
        map((event) => {
          const target = event.target as HTMLTextAreaElement;
          return target.value;
        }),
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap((search) => of(search).pipe(delay(500)))
      )
      .subscribe((input) => {
        this.usersPTable.page = 1;
        this.loadUsers();
      });
  }

  ngOnInit(): void {
    this.loadUsers();
  }

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  loadUsers(type?:string) {
    const { data, loading, total, ...rest } = this.usersPTable;
    const companyId = localStorage.getItem('flexicompanyId');
    const subscribe = this.newsService
      .getAllNewsEventsOverview(companyId,rest)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const { data, count } = response.data;
          this.usersPTable.data = data;
          this.usersPTable.total = count;
          this.isTableLoading = false;
          if (type  === 'refresh') {
            this.toastService.success('Data Refreshed Successfully');
          }
        },
        (error) => {
          this.usersPTable.data = [];
          this.usersPTable.total = 0;
          this.toastService.error('No list found');
        }
      );
  }
  onEditWall(id: string) {
    this.EditEvent.emit(id);
  }

  onViewWall(id: string, eventObj: any) {
    this.eventId = id;
    this.eventData = eventObj;
    this.isEventView = true;
  }
  ViewEvent() {
    this.isEventView = false;
    this.loadUsers();
  }

  openManage(id: string) {
    this.ManageEvent.emit(id);
    this.isManageOpen = true;
  }

  checkEventStatus(startDate: string, endDate: string, key: number) {
   if(key == 1) return moment() < moment(startDate);
   if(key == 2) return moment() > moment(endDate);
   if(key == 3) return (moment() > moment(startDate) && moment() < moment(endDate));

  }

  ngOnDestroy(): void {
    this.usersSearchSubscription && this.usersSearchSubscription.unsubscribe();
  }
}
