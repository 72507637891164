<div class="card">
  <div class="card-header bg-theme">USER WITH SHIFT SETUP</div>
  <div class="card-body table-responsive p-0">
    <div class="d-flex justify-content-between m-2">
      <select
        (change)="onLimitChangeBuShiftSetUp()"
        [(ngModel)]="buShiftSetUpTable.limit"
        class="custom-select"
        name="pageSize"
        style="width: auto"
      >
        <option [ngValue]="10">10</option>
        <option [ngValue]="20">20</option>
        <option [ngValue]="30">30</option>
        <option [ngValue]="40">40</option>
        <option [ngValue]="50">50</option>
      </select>
      <div class="form-group form-inline mb-0">
        <span *ngIf="buShiftSetUpTable.isLoading">Please wait...</span>
        <input
          (keyup)="buShiftSetUpSearchInput.next($event)"
          (search)="buShiftSetUpSearchInput.next($event)"
          [(ngModel)]="buShiftSetUpTable.search.value"
          class="form-control ml-2"
          name="searchTerm"
          placeholder="Search here"
          type="search"
        />
      </div>
    </div>

    <div *ngIf="userShiftOverviewErr; then thenBlock; else elseBlock"></div>
    <ng-template #thenBlock>
      <div class="text-md text-black-50 p-4">Not Found!</div>
    </ng-template>
    <ng-template #elseBlock>
      <table class="table table-striped" *ngIf="!loader">
        <thead>
          <tr>
            <th scope="col" sortable="Tier 1">USER NAME</th>
            <!-- (sort)="onSortBuShiftSetup($event) -->
            <th scope="col" sortable="Tier 2">STAFF ID</th>
            <th scope="col" sortable="Tier 3">APPOINTMENT</th>

            <th scope="col" sortable="Tier 4">DATE OF JOINING</th>
            <th scope="col" sortable="Status">CONTACT NUMBER</th>
            <th scope="col">PARENT BUSINESS UNIT</th>
            <th scope="col">ACTION</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let item of buShiftSetUpTable.data; let i = index">
            <td>
              <span class="font-weight-500">{{ item.name }}</span>
            </td>
            <td>{{ item.staffId }}</td>
            <td>
              {{ item.appointment?.name }}
            </td>
            <td>--</td>
            <td>{{ item.contactNumber }}</td>
            <td>
              {{ item.businessUnit }}
            </td>
            <td>
              <div class="d-flex">
                <button
                  (click)="onViewUserShiftSetup(item)"
                  class="btn btn-info btn-sm"
                >
                  View
                </button>
                <button
                  (click)="onEditUserShiftSetup(item)"
                  class="btn btn-success btn-sm ml-1"
                >
                  Edit
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-template>
    <ngx-skeleton-loader
      *ngIf="loader"
      count="8"
      [theme]="{
        width: 'calc(100% - 40px)',
        'border-radius': '6px',
        height: '44px',
        margin: '10px',
        display: 'block'
      }"
    ></ngx-skeleton-loader>
  </div>
  <div class="card-footer">
    <div class="d-flex justify-content-between">
      <p class="align-self-center m-0 text-muted">
        Total Records
        <b class="text-dark">{{ buShiftSetUpTable.total }}</b>
      </p>
      <ngb-pagination
        (pageChange)="onPageChangeBuShiftSetUp($event)"
        [(page)]="buShiftSetUpTable.page"
        [collectionSize]="buShiftSetUpTable.total"
        [maxSize]="5"
        [pageSize]="buShiftSetUpTable.limit"
      >
      </ngb-pagination>
    </div>
  </div>
</div>
<div *ngIf="shouldOpen">
  <app-user-shift-setup-edit [isEdit]="isEdit"></app-user-shift-setup-edit>
</div>
