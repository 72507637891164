<div class="card" *ngIf="isOverview">
  <div
    class="card-header d-flex align-items-center justify-content-between bg-theme"
  >
    <div>
      <h5 class="m-0">CURRENT SHIFTS</h5>
      <p class="m-0">Selected Business Unit : {{ businessUnitId.orgName }}</p>
    </div>

    <button class="btn btn-sm btn-info" (click)="getLogCsv()">
      Export Logs
    </button>
  </div>
  <div class="card-body table-responsive p-0">
    <div *ngIf="timesheetDashboardLoader" class="loader">
      <div class="pageLoading">
        <div class="innerDiv">
          <i class="fa fa-spinner fa-spin" style="float: none;"></i> Please wait, Data is Refreshing ......
        </div>
      </div>
    </div>
    <table class="table" *ngIf="!timesheetDashboardLoader">
      <thead>
        <tr>
          <th scope="col" sortable="Tier 1">DATE</th>
          <!-- (sort)="onSortBuShiftSetup($event) -->
          <th scope="col" sortable="Tier 2">USER NAME</th>
          <th scope="col" sortable="Tier 3">STAFF ID</th>

          <th scope="col" sortable="Tier 4">SHIFT START</th>
          <th scope="col" sortable="Status">SHIFT END</th>
          <th scope="col" sortable="Status">CLOCK IN</th>
          <th scope="col" sortable="Status">CLOCK OUT</th>
          <th scope="col" sortable="Status">CLOCK IN MODE</th>
          <th scope="col" sortable="Status">GO TO</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let data of timesheetDetails">
          <td>
            {{ data?.shiftDetails?.date | date: "dd-MM-yyyy" }}
            <p
            class="m-0 text-primary font-weight-normal"
            *ngIf="data.shiftDetails?.isSplitShift == true && data?.position"
          >
            split shift {{data?.position}}
          </p>
            <p
              *ngIf="data.shiftDetails.isAssignShift == true"
              class="m-0 text-info"
            >
              Assigned Shift
            </p>

          </td>
          <td class="font-weight-500">
            {{ data?.userInfo?.name }}
          </td>
          <td>{{ data?.userInfo?.staffId }}</td>
          <td>{{ data?.shiftDetails?.startTime | date: "HH:mm" }}</td>
          <td>{{ data?.shiftDetails?.endTime | date: "HH:mm" }}</td>
          <td>{{ data?.attendance?.clockInDateTime | date: "HH:mm" }}</td>
          <td>{{ data?.attendance?.clockOutDateTime | date: "HH:mm" }}</td>
          <td>{{ data?.attendance?.attendanceMode }}</td>
          <!-- <td> {{getDurationNew(user.attendance.approval.duration)}} </td> -->
          <!-- <td>
              <button type="button" ng-disabled="user.isDisabled"
                ng-click="markUserAttendance(user, 'no')" class="{{user.btnClass}} btn btn-primary">
                {{setBtnStatus(user,$index, 'dashbaord', 'no')}}
              </button>
            </td> -->
          <td>
            <ng-container *ngIf="data.hasOwnProperty('attendance')">
              <button
                class="btn btn-sm btn-success mr-1"
                (click)="manualCheckout(data)"
                *ngIf="data?.attendance?.attendanceMode == 'Manual'"
              >
                Manual Clock-out
              </button>
              <button
                class="btn btn-sm btn-success mr-1"
                (click)="qrClockOut(data)"
                *ngIf="data?.attendance?.attendanceMode == 'Qr'"
              >
                Qr Clock-out
              </button>
              <button
                *ngIf="data?.attendance?.attendanceMode == 'Facial'"
                class="btn btn-sm btn-success"
                (click)="facialAttendance(data)"
              >
                Facial Clock-out
              </button>
            </ng-container>

            <ng-container *ngIf="!data.hasOwnProperty('attendance')">
              <button
                *ngIf="data?.isFacial"
                class="btn btn-sm btn-danger"
                (click)="facialAttendance(data)"
              >
                Facial Clock-in
              </button>
              <button
                class="btn btn-sm btn-danger mr-1"
                (click)="markUserAttendance(data)"
                *ngIf="!data?.isFacial"
              >
                QR Clock-in
              </button>
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="bg-white text-center p-3 pt-1" *ngIf="!timesheetDashboardLoader && timesheetDetails?.length === 0">
      No Data Available
    </div>
  </div>
</div>
