<div class="d-flex login-main flex-sm-row flex-column">
  <div class="flex-fill min-w50 d-sm-block d-none">
    <div class="mw-100 bg-banner"></div>
  </div>
  <div class="flex-fill bg">
    <div class="d-flex align-items-center h-100 min-h35">
      <div class="d-flex justify-content-center min-w100">
        <div class="login-body min-w50 card">
          <div class="card-body">
            <h3>User Verification</h3>
            <form
              [formGroup]="registrationForm"
              (ngSubmit)="onRequestRestPassword()"
              *ngIf="userVerificationState == 'registration'"
            >
              <div class="form-group">
                <label for="exampleInputPassword1">Mobile Number</label>
                <ngx-intl-tel-input
                  [cssClass]="'custom form-control'"
                  [preferredCountries]="preferredCountries"
                  [ngClass]="{
                    'is-invalid': submitted1 && f.primaryMobileNumber.errors
                  }"
                  [enableAutoCountrySelect]="true"
                  [enablePlaceholder]="true"
                  [searchCountryFlag]="true"
                  [searchCountryField]="[
                    SearchCountryField.Iso2,
                    SearchCountryField.Name
                  ]"
                  [selectFirstCountry]="false"
                  [selectedCountryISO]="CountryISO.Singapore"
                  [maxLength]="15"
                  
                  [phoneValidation]="true"
                  
                  [numberFormat]="PhoneNumberFormat.National"
                  formControlName="primaryMobileNumber"
                >
                </ngx-intl-tel-input>

                <div
                  *ngIf="submitted1 && f.primaryMobileNumber.errors"
                  class="invalid-feedback"
                >
                  <small *ngIf="f.primaryMobileNumber.errors.required">
                    Mobile Number is required
                  </small>
                </div>

              </div>
              <div class="form-group">
                <button type="submit" class="btn btn-sm btn-submit float-right" [disabled]="isLoading">
                  <i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, '': !isLoading}"></i>
                  Submit
                </button>
              </div>
            </form>
            <form
              [formGroup]="otpForm"
              (ngSubmit)="onSubmitOtp()"
              *ngIf="userVerificationState == 'mobileverify'"
            >
              <div class="form-group">
                <label for="exampleInputEmail1">Please Enter the OTP sent to your mobile number</label>
                <input
                  type="number"
                  class="form-control"
                  formControlName="otp"
                  aria-describedby="emailHelp"
                  pattern="/^-?\d+\.?\d*$/" 
                  onKeyPress="if(this.value.length == 20) return false;"
                  placeholder="Enter OTP"
                  [ngClass]="{ 'is-invalid': submitted2 && otp.otp.errors }"
                />
                <div
                  *ngIf="submitted2 && otp.otp.errors"
                  class="invalid-feedback"
                >
                  <small *ngIf="otp.otp.errors.required">
                    OTP is required
                  </small>
                </div>

              </div>

              <div class="form-group">
                <button type="button" [disabled]="isCoundownStarted" class="btn btn-sm btn-back float-right" (click)="resendOtp()">{{isCoundownStarted? counter : 'Resend OTP'}}</button>

                <button type="submit" class="btn btn-sm btn-back" [disabled]="isLoading">
                  <i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, '': !isLoading}"></i>
                  Submit
                </button>
              </div>
            </form>
            <form
              [formGroup]="passwordRestForm"
              (ngSubmit)="onRestPassword()"
              *ngIf="userVerificationState == 'resetpassword' && passwordRestForm"
            >
              <div class="form-group">
                <label class="pb-2 form-bg" for="exampleInputEmail1"
                  >Password
                </label>
                <div class="input-group">
                <input
                [type]="passwordvisible ? 'text' : 'password'"
                class="form-control"
                  placeholder=" Password"
                  [ngClass]="{
                    'is-invalid':
                      submitted3 && passwordRestForm.get('password').errors
                  }"
                  formControlName="password"
                />
                <div class="input-group-append" *ngIf="!passwordvisible">
                  <span class="input-group-text">
                    <i
                    class="fa fa-eye-slash fa-lg"
                    aria-hidden="true"
                    (click)="passwordVisible()"
                  ></i>
                  </span>
                </div>
                <div class="input-group-append" *ngIf="passwordvisible">
                  <span class="input-group-text">
                    <i
                    class="fa fa-eye fa-lg"
                    aria-hidden="true"
                    (click)="passwordVisible()"
                  ></i>
                  </span>
                </div>
                <div
                  *ngIf="passwordRestForm.get('password').errors"
                  class="invalid-feedback"
                >
                  <small
                    *ngIf="passwordRestForm.get('password').errors.required"
                  >
                    Password is required
                  </small>
                  <div
                    *ngIf="passwordRestForm.get('password').errors.hasNumber"
                  >
                    Atleast one numeric letter is required
                </div>
                  <div
                    *ngIf="
                      passwordRestForm.get('password').errors
                        .hasSpecialCharacters
                    "
                  >
                    Atleast one special character is required
              </div>
                  <div
                    *ngIf="
                      passwordRestForm.get('password').errors.hasCapitalCase
                    "
                  >
                    Atleast one Capital character is required
            </div>
                  <div
                    *ngIf="passwordRestForm.get('password').errors.hasSmallCase"
                  >
                    Atleast one Small character is required
          </div>
                  <div
                    *ngIf="passwordRestForm.get('password').errors.minlength"
                  >
                    Minimum Length Should be {{passwordRestForm.get('password').errors.length}} Characters
        </div>
                </div>
              </div>
              </div>
              <div class="form-group">
                <label class="pb-2 form-bg" for="exampleInputEmail1"
                  >Confirm Password
                </label>
                <div class="input-group">
                <input
                [type]="passwordvisibleAlt ? 'text' : 'password'"
                class="form-control"
                  placeholder=" Confirm Password"
                  [ngClass]="{
                    'is-invalid':
                      submitted3 &&
                      passwordRestForm.get('confirmPassword').errors
                  }"
                  formControlName="confirmPassword"
                />
                <div class="input-group-append" *ngIf="!passwordvisibleAlt">
                  <span class="input-group-text">
                    <i
                    class="fa fa-eye-slash fa-lg"
                    aria-hidden="true"
                    (click)="confirmPasswordVisible()"
                  ></i>
                  </span>
                </div>
                <div class="input-group-append" *ngIf="passwordvisibleAlt">
                  <span class="input-group-text">
                    <i
                    class="fa fa-eye fa-lg"
                    aria-hidden="true"
                    (click)="confirmPasswordVisible()"
                  ></i>
                  </span>
                </div>
                <div
                  *ngIf="passwordRestForm.get('confirmPassword').errors"
                  class="invalid-feedback"
                >
                  <small
                    *ngIf="
                      passwordRestForm.get('confirmPassword').errors.required
                    "
                  >
                    Confirm Password is required
                  </small>
                  <small
                    *ngIf="
                      passwordRestForm.get('confirmPassword').errors.mustMatch
                    "
                  >
                    Password doesn't Match
                  </small>
                </div>
              </div>
              </div>

              <div class="form-group">
                <button type="submit" class="btn btn-sm btn-add float-right" [disabled]="isLoading">
                  <i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, '': !isLoading}"></i>
                  Reset Password
                </button>
              </div>
            </form>
            <form
              [formGroup]="form"
              *ngIf="userVerificationState == 'missingfields'"
            >
              <dynamic-form-builder
                [gridView]="'col-xs-12 col-sm-12 col-md-12 col-lg-12 '"
                [formGroup]="form"
                [fields]="getFields()"
              ></dynamic-form-builder>
              <button class="btn btn-submit" [disabled]="form.invalid" (click)="onSubmitCustomField()" [disabled]="isLoading">
                <i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, '': !isLoading}"></i>
                SUBMIT
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
