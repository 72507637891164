import { Component, OnInit } from '@angular/core';
import { CompanySetupService } from '../../../../service/company-setup/company-setup.service';
import { finalize } from 'rxjs/operators';
import { FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-others',
  templateUrl: './others.component.html',
  styleUrls: ['./others.component.scss']
})
export class OthersComponent implements OnInit {

  constructor(
    private companySetupService: CompanySetupService,
    private toastService: ToastrService,
  ) { }
  technicalEmail: ''
  administratorEmail: ''
  remindHours : any;
  remindDays: any;
  skillSet: ''
  companySettingsId: string;
  passwordSetupForm: FormGroup;
  hoursArray = [];

  ngOnInit(): void {
    for (let index = 1; index < 25; index++) {
      this.hoursArray.push(index)
    }
    this.getCompanySetupDetails();
  }

  getCompanySetupDetails() {
    const sub = this.companySetupService
      .getcompanySetting()
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe((res) => {
        this.companySettingsId = res.data._id;
        this.technicalEmail = res.data.techEmail;
        this.administratorEmail = res.data.adminEmail
        this.remindHours = Number(res.data.notificRemindHours)
        this.remindDays = Number(res.data.notificRemindDays)
      }, (error) => {

      });
  }

  onSubmitSetupForm() {
    let data = {
      techEmail: this.technicalEmail,
      adminEmail: this.administratorEmail,
      pageId: this.companySettingsId,
      notificRemindDays: this.remindDays,
      notificRemindHours: this.remindHours
    }
    !this.remindDays ? delete data.notificRemindDays : '';
    !this.remindHours ? delete data.notificRemindHours : '';
    this.companySetupService
      .updateCompanySettings(data)
      .subscribe((res) => {
        this.toastService.success('Updated SuccessFully');
      });
  }
}
