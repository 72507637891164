import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { StorageService } from 'src/app/core/service/storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CompanySetupService {
  private baseUrl: string;
  companyId: any;

  private get Headers(): HttpHeaders {
    return new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }
  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {
    this.baseUrl = environment.baseUrl;
    this.companyId = this.storageService.getByKey('companyId');
  }
  uploadFile(fileToUpload) {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return this.http.post(`${this.baseUrl}/upload/`, formData);
  }

  getcompanySetting() {
    return this.http.get(`${this.baseUrl}/pageSetting/read/`, {
      headers: this.Headers,
    }).pipe(
      map(res => res as any)
    )
  }

  getPasswordSetting() {
    return this.http.get(`${this.baseUrl}/login/getCompany/sats`, {
      headers: this.Headers,
    }).pipe(
      map(res => res as any)
    )
  }

  updateCompanySettings(data) {
    return this.http.post(`${this.baseUrl}/pageSetting/update`, data, { headers: this.Headers })
      .pipe(
        map((res) => res as any)
      )
  }
  updatePasswordSettings(data) {
    return this.http.post(`${this.baseUrl}/pageSetting/updatePwdManage`, data, { headers: this.Headers })
      .pipe(
        map((res) => res as any)
      )
  }

  changePassword(data) {
    return this.http.post(`${this.baseUrl}/user/resetPasswordFromProfile/`, data, {
      headers: this.Headers,
    }).pipe(map((res) => res as any))
  }

  updatePointSettings(id, data) {
    return this.http.put(`${this.baseUrl}/page-setting/company-setting/${id}`, data, {
      headers: this.Headers
    }).pipe(map((res) => res as any))
  }

  getMyPageDropDowns(){
    return this.http.get(`${this.baseUrl}/wall/getCompanyWalls`, {
      headers: this.Headers,
    }).pipe(
      map(res => res as any)
    )
  }

  getPageSettings(companyName){
    return this.http.get(`${this.baseUrl}/login/getCompany/${companyName}`, {
      headers: this.Headers,
    }).pipe(
      map(res => res as any)
    )
  }
}
