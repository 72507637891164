<div class="cancel-ballot" *ngIf="ballotList?.length && ballotList[0]?.isCanceled">
  <p class="blinking-text">THIS BALLOT IS CANCELLED</p>
</div>
<div class="card mb-3">
  <div *ngIf="loader"
    style="position: fixed;top: 0;right: 0;bottom: 0;left: 0;z-index: 1050;background-color: rgba(0, 0, 0, 0.5);overflow: auto;">
    <div class="pageLoading" style="text-align: center;color: white;font-size: 450%;">
      <div class="innerDiv">
        <i class="fa fa-spinner fa-spin" style="float: none;"></i> Please wait Loading Data...
      </div>
    </div>
  </div>
  <h5 class="card-header bg-theme">BALLOTING EXERCISE</h5>
  <div class="card-body p-0">
    <div class="table-responsive">
      <table class="table m-0">
        <thead>
          <tr>
            <th>Ballot Title</th>
            <th>Balloting Round</th>
            <th>Status</th>
            <th>Result Release Date</th>
            <th>Current Ballot Action</th>
            <th>Post Ballot Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of ballotList; let i = index">
            <td>{{ item.ballotName }}</td>
            <td>{{ item.ballotRound }}</td>
            <td>{{ item.ballotStatus }}</td>
            <td>
              {{
              item.isResultRelease
              ? (item.resultDate | date: "mediumDate")
              : "Not Released"
              }}
            </td>
            <td>
              <ng-container *ngIf="i == 0">
                <div class="d-flex">
                  <ng-container *ngIf="item.isAutoAssign">
                    <button class="btn btn-success btn-sm" [disabled]="resultDisabled || item.isCanceled"
                      (click)="resultRelease(item._id)">
                      Result Release
                    </button>
                  </ng-container>
                  <ng-container *ngIf="!item.isAutoAssign">
                    <button class="btn btn-success btn-sm" [disabled]="
                        item.isResultRelease ||
                        ((item.ballotStatus == 'Open' || item.ballotStatus == 'Closed') && !item.isConduct) ||
                        item.isCanceled
                      " (click)="resultRelease(item._id)">
                      Result Release
                    </button>
                  </ng-container>

                  <button class="btn btn-danger btn-sm ml-2" [disabled]="item.isCanceled"
                    (click)="cancelManageBallot()">
                    Cancel
                  </button>
                  <button class="btn btn-info btn-sm ml-2" (click)="extend(content, item)"
                    [disabled]="item.isAutoAssign || item.isCanceled">
                    Extend
                  </button>
                </div>
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="i == 0">
                <div class="d-flex" *ngIf="
                    item.isAutoAssign && !item.isConduct;
                    then thenBlock;
                    else elseBlock
                  "></div>
                <ng-template #thenBlock>
                  <button class="btn btn-info btn-sm" (click)="conductAuto(item)"
                    [disabled]="conductAutoAssignDisabled">
                    Conduct Auto-Assign
                  </button>
                </ng-template>
                <ng-template #elseBlock>
                  <div class="d-flex">
                    <button class="btn btn-info btn-sm" (click)="reBallot()"
                      [disabled]="!item.isResultRelease ||(item.isAutoAssign && item.isConduct)">
                      Re-Ballot
                    </button>
                    <button class="btn btn-warning btn-sm ml-2" (click)="autoAssign()"
                      [disabled]="!item.isResultRelease || (item.isAutoAssign && item.isConduct)">
                      Auto-Assign
                    </button>
                  </div>
                </ng-template>
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="card">
  <h5 class="card-header bg-theme">BALLOTING RESULTS</h5>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="ballotResultForm">
          <div class="form-row">
            <div class="form-group col-md-4" *ngIf="this.ballotList">
              <label class="font-weight-500">Select Balloting Round</label>
              <ng-select [multiple]="false" [closeOnSelect]="true" formControlName="ballotName" (change)="viewDetail()">
                <ng-option *ngFor="let item of ballotRoundDropdown; let i = index" [value]="item._id">
                  {{ item.ballotName }}
                  <span *ngIf="i != 0"> - {{ item.ballotRound }}</span>
                </ng-option>
              </ng-select>
            </div>
            <div class="form-group col-md-3" *ngIf="ballotAll">
              <label class="font-weight-500">Select Ops Group</label>
              <ng-select [items]="ballotAll['data']" bindLabel="opsGroup.value" placeholder="Select"
                formControlName="ballotGrp" (change)="getTeam()">
              </ng-select>
            </div>
            <div class="form-group col-md-3" *ngIf="ballotAll">
              <label class="font-weight-500">Select Ops Team</label>
              <ng-select [items]="this.teamOps" bindLabel="name" placeholder="Select" clearAllText="Clear"
                formControlName="ballotTeam">
              </ng-select>
            </div>
            <div class="form-group col-md-2 align-self-end text-right">
              <button type="button" class="btn btn-danger" (click)="clearFilter()">
                Clear
              </button>
              <button type="submit" class="btn btn-success ml-2" (click)="filterSummary()">
                View
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
          <li [ngbNavItem]="1">
            <a ngbNavLink>Summary View</a>
            <ng-template ngbNavContent>
              <div class="row">
                <div class="col-md-4">
                  <div class="card border-theme" *ngIf="ballotAll">
                    <div class="card-body">
                      <h6 class="card-title mb-3">Ballot Round Result</h6>
                      <div class="d-flex text-center justify-content-between">
                        <p class="text-center m-0 text-muted">
                          Quota<br /><span class="h2 text-dark">{{
                            this.ballotAll.finalTotalQuota
                            }}</span>
                        </p>
                        <p class="text-center m-0 text-muted">
                          Applied<br /><span class="h2 text-dark">{{
                            this.ballotAll.finalTotalApplied
                            }}</span>
                        </p>
                        <p class="text-center m-0 text-muted">
                          Successful<br /><span class="h2 text-dark">{{
                            this.ballotAll.finalTotalSuccessful
                            }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <ngx-skeleton-loader *ngIf="!ballotAll" [theme]="{
                      width: 'calc(100%)',
                      'border-radius': '6px',
                      height: '100px',
                      margin: '10px 0',
                      display: 'block'
                    }"></ngx-skeleton-loader>
                </div>
                <div class="col-md-4 pl-md-0">
                  <div class="card border-theme" *ngIf="ballotAll && consolidate">
                    <div class="card-body">
                      <h6 class="card-title mb-3">
                        Consolidated Ballots Result
                      </h6>
                      <div class="d-flex text-center justify-content-between">
                        <p class="text-center m-0 text-muted">
                          Quota<br /><span class="h2 text-dark">{{
                            consolidate.finalTotalQuota
                            }}</span>
                        </p>
                        <p class="text-center m-0 text-muted">
                          Successful<br /><span class="h2 text-dark">{{
                            consolidate.finalTotalSuccess
                            }}</span>
                        </p>
                        <p class="text-center m-0 text-muted">
                          Unassigned<br /><span class="h2 text-dark">{{
                            consolidate.totalTeamUnassign
                            }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <ngx-skeleton-loader *ngIf="!ballotAll" [theme]="{
                      width: 'calc(100%)',
                      'border-radius': '6px',
                      height: '100px',
                      margin: '10px 0',
                      display: 'block'
                    }"></ngx-skeleton-loader>
                </div>
              </div>

              <div class="row my-3">
                <div class="col-md-12">
                  <div class="border-theme rounded">
                    <ngx-skeleton-loader *ngIf="summaryLoader" count="6" [theme]="{
                        width: 'calc(100% - 20px)',
                        'border-radius': '6px',
                        height: '44px',
                        margin: '10px',
                        display: 'block'
                      }"></ngx-skeleton-loader>
                    <div class="table-responsive table-sticky" *ngIf="!summaryLoader">
                      <table class="table table-striped border-left m-0">
                        <thead>
                          <tr>
                            <th scope="col" class="fix border-bottom-0 border-top-0">
                              OPS GROUP
                            </th>
                            <th scope="col" class="fix border-bottom-0 border-top-0">
                              OPS TEAM
                            </th>
                            <th scope="col" class="fix border-bottom-0 border-top-0">
                              BALLOTING BALANCE
                              <div>
                                <span class="box-label text-muted">Q</span>
                                <span class="box-label text-muted ml-1">U</span>
                              </div>
                            </th>
                            <th scope="col noWrap" *ngFor="let item of slotsArr; let i = index">
                              SLOT {{ i + 1 }}<br />
                              <span class="h6 text-muted">
                                <ng-container *ngIf="!daySlot">
                                  {{ item[0] | date: "dd MMM" }}
                                  - {{ item[1] | date: "dd MMM" }}
                                </ng-container>
                                <ng-container *ngIf="daySlot">
                                  {{ item | date: "dd MMM" }}
                                </ng-container>
                              </span>
                              <div>
                                <span class="box-label text-muted">Q</span>
                                <span class="box-label text-muted ml-1">A</span>
                                <span class="box-label text-muted ml-1">S</span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of allOps; let ind = index">
                            <th class="fix pb-0">{{ item.OpsGrp }}</th>
                            <td class="fix">{{ item.OpsTeam }}</td>
                            <td class="fix">
                              <div class="d-flex">
                                <input type="text" class="form-control box p-1" value="{{ item.quota }}" />
                                <input type="text" class="form-control box ml-1 p-1"
                                  value="{{ item.unassignBalanace }}" />
                              </div>
                            </td>
                            <td *ngFor="let el of item.slots; let i = index">
                              <div class="d-flex">
                                <input type="text" class="form-control box p-1" value="{{ el.teamQuota }}" />
                                <input type="text" class="form-control box ml-1 p-1"
                                  value="{{ el.appliedStaffQuota }}" />
                                <input type="text" class="form-control box ml-1 p-1"
                                  value="{{ el.successStaffQuota }}" />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink (click)="getDetails()">Detail View</a>
            <ng-template ngbNavContent>
              <div class="row my-3">
                <div class="col-md-8">
                  <input type="search" class="form-control mb-3" placeholder="Search by Staff Name or Staff ID" [(ngModel)]="searchQuery" (ngModelChange)="filterDetailView()">
                  <div class="table-responsive border-theme rounded">
                    <ngx-skeleton-loader *ngIf="detailViewLoader" count="5" appearance="line" [theme]="{
                        width: 'calc(100% - 22px)',
                        'border-radius': '6px',
                        height: '40px',
                        margin: '10px',
                        display: 'block'
                      }"></ngx-skeleton-loader>
                    <table class="table table-hover m-0" *ngIf="!detailViewLoader">
                      <thead>
                        <tr>
                          <th>STAFF NAME</th>
                          <th>STAFF ID</th>
                          <th>OPS GROUP</th>
                          <th>BALLOT ROUND</th>
                          <th>BALLOT SUBMITTED</th>
                          <th>SUCCESSFULL BALLOTS</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr (click)="getMobileViewDetail(item)" title="View Details" *ngFor="let item of detailView"
                          [class.bg-grey]="selectedUser == item">
                          <td>
                            <span class="font-weight-500">{{
                              item.user.name
                              }}</span>
                          </td>
                          <td>{{ item.user.staffId }}</td>
                          <td>
                            {{ item.Ops?.opsGroupName }} >
                            <span *ngIf="item.Team?.name">{{
                              item.Team?.name
                              }}</span>
                          </td>
                          <td>{{ item.ballotRound }}</td>
                          <td>{{ item.applied }}</td>
                          <td>{{ item.wonCount }}</td>
                          <td>
                            <button class="btn btn-info btn-sm" (click)="getMobileViewDetail(item)">
                              View
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-md-4 pl-md-0" *ngIf="mobileView">
                  <div class="card border-0">
                    <h6 class="card-header bg-theme">VIEW LEAVE BALLOTING</h6>
                    <div class="card-body border">
                      <ng-template [ngIf]="mobileViewLoader">
                        <ngx-skeleton-loader appearance="line" [theme]="{
                            width: 'calc(40%)',
                            'border-radius': '6px',
                            height: '40px',
                            margin: '10px 0',
                            display: 'block'
                          }"></ngx-skeleton-loader>
                        <ngx-skeleton-loader appearance="line" [theme]="{
                            width: 'calc(70%)',
                            'border-radius': '6px',
                            height: '40px',
                            margin: '10px 0',
                            display: 'block'
                          }"></ngx-skeleton-loader>
                        <ngx-skeleton-loader appearance="line" [theme]="{
                            width: 'calc(50%)',
                            'border-radius': '6px',
                            height: '40px',
                            margin: '10px 0',
                            display: 'block'
                          }"></ngx-skeleton-loader>
                        <ngx-skeleton-loader appearance="line" [theme]="{
                            width: 'calc(90%)',
                            'border-radius': '6px',
                            height: '40px',
                            margin: '10px 0',
                            display: 'block'
                          }"></ngx-skeleton-loader>
                        <ngx-skeleton-loader appearance="line" [theme]="{
                            width: 'calc(60%)',
                            'border-radius': '6px',
                            height: '40px',
                            margin: '10px 0',
                            display: 'block'
                          }"></ngx-skeleton-loader>
                      </ng-template>

                      <ng-template [ngIf]="!mobileViewLoader">
                        <form [formGroup]="viewLeaveBallot">
                          <div class="form-row">
                            <div class="form-group col-md-6">
                              <label class="font-weight-500">Ballot Peirod</label>
                              <input type="text" class="form-control" value="{{
                                  mobileViewDetail.ballotStartDate
                                    | date: 'dd-MM-yyyy'
                                }}" />
                            </div>
                            <div class="form-group col-md-6">
                              <label class="font-weight-500">&nbsp;</label>
                              <input type="text" class="form-control" value="{{
                                  mobileViewDetail.ballotEndDate
                                    | date: 'dd-MM-yyyy'
                                }}" />
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="form-group col-md-12">
                              <label class="font-weight-500">Closing Date For Ballot Application</label>
                              <input type="text" class="form-control" value="{{
                                  mobileViewDetail.applicationCloseDateTime
                                    | date: 'dd-MM-yyyy, HH:mm'
                                }}" />
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="form-group col-md-12">
                              <label class="font-weight-500">Ops Group</label>
                              <input type="text" class="form-control"
                                value="{{ mobileViewDetail.opsGroup + ' > '  }}" />
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="form-group col-md-6">
                              <label class="font-weight-500">Leave Type</label>
                              <input type="text" class="form-control" value="{{
                                  mobileViewDetail.leaveType == 1
                                    ? 'Week Ballot Slot'
                                    : 'Day Ballot Slot'
                                }}" />
                            </div>
                            <div class="form-group col-md-6">
                              <label class="font-weight-500">Max Consecutive</label>
                              <input type="text" class="form-control" value="{{
                                  mobileViewDetail.maxConsecutiveBallot
                                    ? mobileViewDetail.maxConsecutiveBallot
                                    : '-'
                                }}" />
                            </div>
                          </div>
                        </form>

                        <div class="form-group">
                          <label class="font-weight-500">Slot List</label>
                          <div class="alert border font-weight-bold" role="alert" [ngClass]="{
                              'bg-success': item.staffStatus === 'Successful',
                              'bg-info': item.staffStatus === 'autoAssigned'
                            }" *ngFor="let item of mobileViewDetail.won">
                            Slot--{{ item.weekNo + 1 }}&nbsp;&nbsp;
                            {{ getdate2(item.start) }} &nbsp;--&nbsp;
                            {{ getdate2(item.end) }} {{ item.staffStatus }}
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="3">
            <a ngbNavLink (click)="exportBallotData()">Export</a>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header bg-theme">
    <h5 class="modal-title" id="modal-basic-title">Extend Ballot</h5>
    <button type="button" class="close text-light" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="extendBallotForm">
      <div class="form-row">
        <div class="form-group col-md-12">
          <label>Application Start date & time</label>
          <div class="input-group">
            <input class="form-control" [value]="this.applicationStartDate | date: 'medium'" readonly />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label>Closing date</label>
          <input class="form-control" placeholder="Select Date" name="dp7" ngbDatepicker #dp7="ngbDatepicker"
            (click)="dp7.toggle()" formControlName="applicationCloseDate" />
        </div>
        <div class="form-group col-md-6">
          <label>Closing time</label>
          <ng-select [items]="time" formControlName="applicationCloseTime">
          </ng-select>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-12">
          <label class="d-block">Result Release</label>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="resultReleaseIn" value="auto"
              [(ngModel)]="resultReleaseIn" [ngModelOptions]="{ standalone: true }" />
            <label class="form-check-label">Auto</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="resultReleaseIn" value="manual"
              [(ngModel)]="resultReleaseIn" [ngModelOptions]="{ standalone: true }" />
            <label class="form-check-label">Manual</label>
          </div>
        </div>
      </div>
      <div class="form-row" *ngIf="resultReleaseIn == 'auto'">
        <div class="form-group col-md-6">
          <label>Result Release Date</label>
          <input class="form-control" placeholder="Select Date" name="dp9" ngbDatepicker #dp9="ngbDatepicker"
            (click)="dp9.toggle()" formControlName="resultReleaseDate" />
        </div>
        <div class="form-group col-md-6">
          <label>Result Release time</label>
          <ng-select [items]="time" formControlName="resultReleaseTime">
          </ng-select>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="extendSubmit()">
      Extend
    </button>
    <button ngbAutofocus type="button" class="btn btn-dark" (click)="modal.close('Save click')">
      Close
    </button>
  </div>
</ng-template>
