import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'eventPublishstatus',
  pure: false,
})
export class EventPublishstatusPipe implements PipeTransform {
  transform(start: any, end: any): any {
    if (moment(start).isAfter(moment())) {
      return '0'; //Pending
    } else if (
      moment(start).isBefore(moment()) &&
      moment(end).isAfter(moment())
    ) {
      return '1'; //Published
    } else if (moment(end).isBefore(moment())) {
      return '2'; // Expired
    } else {
      return '4';
    }
  }
}
