<form [formGroup]="setupForm">

  <div class="card mb-3">
    <div class="card-header flexi-header p-4">
      <h3>BANNER</h3>
    </div>
    <div class="card-body p-5">
      <div class="row">
        <div class="col-md-4">
          <h6>Upload banner</h6>
          <div class="custom-file mt-0 pt-4 pb-4 mt-2">
            <input
              type="file"
              class="custom-file-input"
              [disabled]="isLoading"
              (change)="BannerUpload($event)"
              required
              id="b"
            />
            <label class="custom-file-label" for="b">
              <i class="fa " [ngClass]="{'fa-spin fa-spinner fa-2x': isLoading, ' fa-plus-circle fa-2x': !isLoading}" aria-hidden="true"></i>
            </label>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="p-3"
          formArrayName="bannerImages"
          *ngFor="let photo of BannersControl.controls; let index = index"
        >
          <div [formGroupName]="index">
            <div class="image-with-delete-icon">
              <i
                class="fa fa-close fa-lg delete-icon"
                title="Delete"
                (click)="onDeleteBannerImage(index)"
              ></i>
              <img
                crossorigin="anonymous"
                class="banner-image"
                [src]="validateURLImage(photo.get('link').value)"
                alt="Image Preview"
              />
            </div>

          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-md-4 mb-2 mt-3">
          <div class="form-group">
            <label class="form-bg" for="exampleInputEmail1">Rotation</label>
            <input placeholder="Rotation" class="form-control w-100"
            min="0" type="number" pattern="/^-?\d+\.?\d*$/"
              formControlName="rotating_banners"
              oninput="this.value = Math.abs(this.value)"
              onKeyPress="if(this.value.length==3) return false;"
              />
          </div>

        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-4 mb-2">
          <label class="font-weight-bold">Fields need to be update on login </label>
          <ng-select
            (change)="addLoginFields($event)"
            class="custom"
            bindLabel="name"
            [virtualScroll]="false"
            placeholder="Custom Fields"
            appendTo="body"
            [multiple]="false"
            [searchable]="true"
            [clearable]="true"
            [loading]="CustomFieldDropDownLoading"
            (search)="onSeachCustomField($event)"
            (scrollToEnd)="loadMoreCustomFieldItems($event)"
            (clear)="onClearCustomFieldDropdown($event)"
          >
            <ng-option [value]="item" *ngFor="let item of fieldNeedsToUpdate">
              {{ item.fieldName }}
            </ng-option>
          </ng-select>
        </div>

        <div class="col-md-6">
          <div style="display: flex;flex-direction: row; flex-wrap: wrap; margin-top: 22px;" >
            <div
            formArrayName="loginFields"
            *ngFor="
              let field of LoginFieldsControl.controls;
              let index = index
            "
          >
            <span class="text-items list-ui"  [formGroupName]="index"
              >{{
                field.get("string").value }}
              <img
                class="ml-3"
                (click)="deleteSelectedFields(index)"
                src="./assets/images/cancel.png"
                width="20px"
                height="20px"
            /></span>
              </div>
           </div>

          <div></div>
        </div>
      </div>
    </div>
  </div>

  <div class="card mb-3">
    <div class="card-header flexi-header p-4">
      <h3>MOBILE HOME PAGE</h3>
    </div>
    <div class="card-body p-5">
      <div class="row">
        <div class="col-md-6">
          <input type="checkbox" id="otp2" formControlName="isTaskViewIncluded" />
          <label for="otp2" class="radio-inline ml-2"> Show tasks view in mobile home page</label>

        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <input type="checkbox"  id="otp3" formControlName="isChallengeIncluded" />
          <label for="otp3" class="radio-inline ml-2">  Show challenges in mobile home page</label>

        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <input type="checkbox" id="12" formControlName="isFormsIncluded" />
          <label for="12" class="radio-inline ml-2">  Show forms in mobile home page</label>

        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <input type="checkbox" id="54" formControlName="isBoardsIncluded" />
          <label for="54" class="radio-inline ml-2">  Show
            boards challenges in mobile home page</label>

        </div>
      </div>
    </div>
  </div>



  <div class="row">
    <div class="col-md-4">
      <button class="btn btn-submit update-button" [disabled]="isUpdateLoading " (click)="onSubmitSetupForm()"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isUpdateLoading, '': !isUpdateLoading}"></i>Update</button>

      <!-- <button
        class="btn btn-add "
        [disabled]="!setupForm.valid"
        (click)="onSubmitSetupForm()"
      >

      </button> -->


    </div>
  </div>
</form>
