import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subject, Subscription, of } from 'rxjs';
import {
  map,
  debounceTime,
  distinctUntilChanged,
  mergeMap,
  delay,
  finalize,
} from 'rxjs/operators';
import {
  SortableHeader,
  SortEvent,
} from 'src/app/shared/directive/sortable.directive';
import { StorageService } from 'src/app/core/service/storage.service';

import {
  deleteAlert,
  infoAlert,
  updateAlert,
} from 'src/app/shared/sweet-alert/sweet-alert';
import { UsersService } from 'src/app/users/service/user-service/users.service';
import { ToastrService } from 'ngx-toastr';
import { tableParam } from 'src/app/core/model/table-param.model';
import { TableService } from 'src/app/core/service/table.service';

@Component({
  selector: 'lock-account-overview',
  templateUrl: './lock-account-overview.component.html',
  styleUrls: ['./lock-account-overview.component.scss'],
})
export class LockAccountOverviewComponent implements OnInit {
  usersPTable;
  // isBuUnitEdited:boolean = false
  // sectionId:string
  // subSectionId:string
  // bussinessUnitEditForm:FormGroup
  public userssearchInput = new Subject<KeyboardEvent>();
  isTableLoading: boolean = false;
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  locAccTableColumn=['name','staffId','doj','contactNumber','appointment.name','businessUnit','_id'];
  tableQueryParamsForLC=tableParam;

  // companyName: string;
  /// bussiness unit over view table config
  onSortUsers({ column, direction }: SortEvent, field, colNum:number) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.usersPTable.sort = { key: column, order: direction as any }; 
    this.tableQueryParamsForLC.order[0].column=colNum;
    this.tableQueryParamsForLC.order[0].dir=direction;
    this.loadUsers();
  }

  onPageChangeUsers(page: number) {
    this.tableQueryParamsForLC.start = +page > 1 ? (page - 1) * this.usersPTable.limit : 0;
    this.loadUsers();
  }
  onLimitChangeUsers() {
    this.tableQueryParamsForLC.start=0;
    this.loadUsers();
  }

  private usersSearchSubscription: Subscription;

  constructor(
    private userService: UsersService,
    public toastService: ToastrService,
    private tableService:TableService,
  ) {
    // this.config.notFoundText = 'Custom not found';
    this.usersPTable = {
      sortBy: 'createdBy',
      sortWith: 'desc',
      data: [],
      page: 1,
      limit: 10,
      total: 0,
      search: '',
      filter: {
        status: ['locked'],
        parentBussinessUnitId: [],
        // fields: ['email', 'staffId', 'appointmentId','parentBussinessUnitId'],
      },
      order:[{
        column:0,
        dir:'desc'
      }]
    };
    // this.companyName = this.storageService.getByKey('companyId');

    this.usersSearchSubscription = this.userssearchInput
      .pipe(
        map((event) => {
          const target = event.target as HTMLTextAreaElement;
          return target.value;
        }),
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap((search) => of(search).pipe(delay(500)))
      )
      .subscribe((input) => {
        this.usersPTable.page = 1;
        this.tableQueryParamsForLC.start = 0;
        this.tableQueryParamsForLC.search.value = input;
        this.loadUsers();
      });
  }

  ngOnInit(): void {
    this.tableQueryParamsForLC.draw = 0;
    this.tableQueryParamsForLC.start = 0;
    this.tableQueryParamsForLC.search.value = '';
    this.loadUsers();
  }

  loadUsers() {
   let tableCol:any = this.tableService.initializeTableParamsColums(this.locAccTableColumn, [0],[0]) || [];
    this.tableQueryParamsForLC.columns=tableCol;
    this.tableQueryParamsForLC.draw++;
    this.tableQueryParamsForLC.length=this.usersPTable.limit;
    this.isTableLoading=true;
    const subscribe = this.userService
      .getLockedUser(this.tableQueryParamsForLC)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          this.usersPTable.data = response?.data;
          this.usersPTable.total = response?.recordsFiltered;
          this.isTableLoading = false;
        },
        (error) => {
          this.usersPTable.data = [];
          this.usersPTable.total = 0;
          this.isTableLoading = false;
          this.toastService.error('No list found');
        }
      );
  }

  unLockAccount(id: string) {
    const data = {
      userId: id,
    };
    updateAlert('Do you want to unlock this user ?', '', 'Unlock').then(
      (resut) => {
        if (resut.value) {
          this.userService.unLockUser(data).subscribe((res) => {
            this.loadUsers();
            this.toastService.success('Unlocked Successfully');
          },(error)=>{
            console.error(error)
            this.toastService.error(error?.error?.message);
          });
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.usersSearchSubscription && this.usersSearchSubscription.unsubscribe();
  }
}
