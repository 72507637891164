import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { of, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  finalize,
  map,
  mergeMap,
} from 'rxjs/operators';
import * as moment from 'moment';
import {
  SortableHeader,
  SortEvent,
} from 'src/app/shared/directive/sortable.directive';
import { StorageService } from 'src/app/core/service/storage.service';

import { NotificationService } from 'src/app/users/service/notification/notification.service';
import { UsersService } from 'src/app/users/service/user-service/users.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-notification-overview',
  templateUrl: './notification-overview.component.html',
  styleUrls: ['./notification-overview.component.scss'],
})
export class NotificationOverviewComponent implements OnInit {
  isNotificationViewEnabled: boolean = false;
  notificationViewData;
  @Output() overViewEvent = new EventEmitter<string>();
  @Output() editEvent = new EventEmitter<string>();
  @Output() overViewNotification = new EventEmitter<boolean>();
  // isBuUnitEdited:boolean = false
  // sectionId:string
  // subSectionId:string
  // bussinessUnitEditForm:FormGroup
  public userssearchInput = new Subject<KeyboardEvent>();
  @Input() notificatioNid: string = null;
  todayDate: string;
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  usersPTable: {
    sortBy: string;
    sortWith: string;
    total: number;
    data: any[];
    page: number;
    limit: number;
    search: string;
  };
  // companyName: string;
  /// bussiness unit over view table config
  onSortUsers({ column, direction }: SortEvent, field) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.usersPTable.sortWith = !!direction ? field : 'createdAt';
    this.usersPTable.sortBy = !!direction ? direction : 'desc';
    this.loadUsers();
  }

  onPageChangeUsers(page: number) {
    this.usersPTable.page = page;
    this.loadUsers();
  }
  onLimitChangeUsers() {
    this.loadUsers();
  }

  private usersSearchSubscription: Subscription;
  constructor(
    private notificationService: NotificationService,
    public toastService: ToastrService,
    private storageService: StorageService,
    private formBuilder: FormBuilder
  ) {
    this.usersPTable = {
      sortBy: 'desc',
      sortWith: 'createdAt',
      total: 0,
      data: [],
      page: 1,
      limit: 10,
      search: '',
    };
    this.usersSearchSubscription = this.userssearchInput
      .pipe(
        map((event) => {
          const target = event.target as HTMLTextAreaElement;
          return target.value;
        }),
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap((search) => of(search).pipe(delay(500)))
      )
      .subscribe((input) => {
        this.usersPTable.page = 1;
        this.loadUsers();
      });
  }

  ngOnInit() {
    this.loadUsers();

    this.getCurrentDate()
  }

  getCurrentDate(){
    const currentDate = new Date();
    this.todayDate = moment(currentDate).utc().format();
  }

  overview() {
    this.overViewEvent.next(null);
  }

  viewEnable(value) {
    this.isNotificationViewEnabled = false;
    this.overViewNotification.next(false);
  }
  onEditNotification(id) {
    this.editEvent.next(id);
  }
  getAcknowledge(notification) {
    let count = isNaN(
      notification.notifyAcknowledgedUsers.length /
        notification.notifyOverAllUsers.length
    )
      ? 0
      : notification.notifyAcknowledgedUsers.length /
        notification.notifyOverAllUsers.length;

    return Math.floor(count * 100);
  }

  onViewNotification(item) {
    this.notificationViewData = item;
    this.isNotificationViewEnabled = true;
    this.overViewNotification.next(true);
  }

  loadUsers() {
    const { data, total, ...rest } = this.usersPTable;
    const subscribe = this.notificationService
      .getAllNotifications(rest, this.notificatioNid)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const { count, data } = response.data;
          this.usersPTable.data = data;
          this.usersPTable.total = count;
        },
        (error) => {
          this.usersPTable.data = [];
          this.usersPTable.total = 0;
          this.toastService.error('No list found');
        }
      );
  }

  getStatus(notification) {
    return notification.status === 0 ? 'Draft' :
      notification.status === 2 ? 'In Active' :
      (!this.checkWithDate(notification.activeTo) && this.checkWithDate(notification.activeFrom) && notification.status == 1) ? 'Active' :
      (!this.checkWithDate(notification.activeFrom) && !this.checkWithDate(notification.activeFrom) && notification.status == 1) ? 'Pending' :
      'Expired';;
  }

  checkWithDate(d, format=null, toDate=null)  {
    if (format) {
      toDate = toDate || moment().format(format);
      return moment(d, format) < moment(toDate, format);
    } else {
      toDate = toDate || moment();
      return moment(d) < moment(toDate);
    }
  }
}
