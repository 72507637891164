<div class="card" *ngIf="isModuleView">
    <div class="card-header flexi-header pt-4">
      <h3 style="display: inline">
        {{ selectedModule?.moduleName | uppercase }}
      </h3>
     <button class="btn pull-right" style="color: ivory" (click)="back()">
        Back
      </button>
    </div>

    <div class="card-body" style="word-break: break-word; padding: 10px">
      <div class="box d-flex flex-column" *ngFor="let item of selectedModule?.questions; let i = index">
        <div class="d-flex align-items-center">
          <strong class="mr-1">{{ i + 1 }}</strong>
          <div class="box-heading" style="word-break: break-word;">
            <p [innerHTML]="item?.question | safeHtml" style="margin: 10px 5px 10px 5px;"></p>
          </div>
        </div>
        
        <div class="box-body" *ngIf="item.type === 1">
          <div class="d-flex flex-wrap answers-options">
            <div class="form-group flex-fill" style="margin: 1rem 1rem; width: 44%">
              <textarea class="form-control notAllowed" rows="5" disabled></textarea>
            </div>
          </div>
        </div>
  <!-- <br/> -->
        <div class="box-body" *ngIf="item.type === 2">
          <div class="d-flex flex-column answers-options">
            <div *ngFor="let option of item.options">
              <input type="checkbox" class="disabled notAllowed" disabled/>
              <label for="consolidated">{{ option.value }}</label>
            </div>
          </div>
          <br/>
        </div>
        <!-- <br/> -->

        <div class="box-body" *ngIf="item.type === 3">
          <div class="d-flex flex-column answers-options">
            <div *ngFor="let item of item.options">
              <input type="radio" class="disabled notAllowed" disabled/>
              <label for="consolidated"> {{ item.value }}</label>
            </div>
          </div>
          <br/>
        </div>
        <!-- <br/> -->

        <div class="box-body" *ngIf="item.type === 4">
            <div class="d-flex flex-column answers-options">
              <div *ngFor="let item of item.options">
                <input type="radio" class="disabled notAllowed" disabled/>
                <label for="consolidated"> {{ item.value }}</label>
              </div>
            </div>
            <br/>
          </div>
          <!-- <br/> -->

        <div class="box-body" *ngIf="item.type === 5">
            <div class="d-flex flex-column answers-options">
              <div *ngFor="let option of item.options">
                <input type="checkbox" class="disabled notAllowed" disabled/>
                <label for="consolidated">{{ option.value }}</label>
              </div>
            </div>
            <br/>
          </div>
          <!-- <br/> -->

          <div class="box-body" *ngIf="item.type === 6">
            <div class="d-flex flex-wrap answers-options">
              <div class="form-group flex-fill" style="margin: 1rem 1rem; width: 44%" >
                <textarea class="form-control notAllowed" rows="3" disabled></textarea>
              </div>
            </div>
            <br/>
          </div>

        <!-- <br/> -->

        <div class="box-body" *ngIf="item.type === 7">
          <div class="d-flex flex-wrap answers-options">
            <div class="form-group flex-fill" style="margin: 1rem 1rem; width: 44%" *ngFor="let item of item.profile">
              <label for="my-input">{{ item.questionName }}</label>
              <input id="my-input" class="form-control notAllowed" type="text" disabled/>
            </div>
          </div>
          <br/>
        </div>

        <!-- <br/> -->

        <div class="box-body" *ngIf="item.type === 8">
          <div class="d-flex flex-wrap answers-options">
            <div class="form-group flex-fill" style="margin: 1rem 1rem; width: 44%">
              <textarea class="form-control notAllowed" rows="3" disabled></textarea>
            </div>
          </div>
          <br/>
        </div>
        <!-- <br/> -->

        <div class="box-body" *ngIf="item.type === 9">
          <div class="d-flex flex-wrap answers-options">
            <div class="form-group flex-fill" style="margin: 1rem 1rem; width: 44%">
              <input type="number" class="form-control notAllowed" disabled/>
            </div>
          </div>
          <br/>
        </div>
        <!-- <br/> -->

        <div class="box-body" *ngIf="item.type === 10">
          <div class="form-group">
            <div class="d-flex flex-wrap answers-options">
              <div class="form-group flex-fill" style="margin: 1rem">
                <label for="my-input">Date</label>
                <input id="my-input" class="form-control notAllowed" type="date" disabled/>
              </div>
              <div class="form-group flex-fill" style="margin: 1rem">
                <label for="my-input">Hours</label>
                <select name="" id="" class="form-control notAllowed" disabled></select>
              </div>
              <div class="form-group flex-fill" style="margin: 1rem">
                <label for="my-input">Miniutes</label>
                <select name="" id="" class="form-control notAllowed" disabled></select>
              </div>
              <div class="form-group flex-fill" style="margin: 1rem">
                <label for="my-input">Seconds</label>
                <select name="" id="" class="form-control notAllowed" disabled></select>
              </div>
            </div>
          </div>
          <br/>
        </div>
        <!-- <br/> -->

        <div class="box-body" *ngIf="item.type === 11">
          <select class="form-control notAllowed" [attr.disabled]="true"></select>
          <br/>
        </div>
        <!-- <br/> -->

        <div class="box-body" *ngIf="item.type === 12">
          <div class="form-group">
            <input id="my-input" class="form-control-file notAllowed" type="file" disabled/>
          </div>
          Preffered formats are -
          <span *ngFor="let item of item?.options">
            {{ item.value }}<span *ngIf="item.length != 0"> </span>
          </span>
          <br/>
        </div>
        <!-- <br/> -->

        <div class="box-body" *ngIf="item.type === 13">
          <div class="d-flex notAllowed">
            <ngb-rating [max]="item.value" [rate]="0" [readonly]="true"></ngb-rating>
          </div>
          <br/>
        </div>
        <!-- <br/> -->

        <div class="box-body" *ngIf="item.type === 14">
          <select class="form-control notAllowed" [attr.disabled]="true"></select>
          <br/>
        </div>
        <!-- <br/> -->

        <div class="box-body" *ngIf="item.type === 15">
          <div class="d-flex flex-wrap" style="gap: 10px;">
            <div *ngFor="let option of item.options">
              <div class="thumbnail">
                <img ng-if="option.imageSrc" [src]="option.imageSrc" style="height: 150px" crossorigin="anonymous" /><br>
                <div class="caption">
                  <p>{{ option.value }}</p>
                </div>
              </div>
            </div>
          </div>
          <br/>
        </div>

        <div class="box-body" *ngIf="item.type === 16">
          <div class="thumbnail">
            <img [src]="item.imageSrc" alt="PhotoPic" height="250px" /><br>
          </div>
          <br />
        </div>

        <div *ngIf="selectedModule?.questions.length == 0" style="text-align: center">
          No questions to show
        </div>
      </div>
    </div>
  </div>
