<div class="d-flex flex-column">
  <ul
    ngbNav
    #companyNav="ngbNav"
    [(activeId)]="activeId"
    class="nav-tabs rounded mb-3"
  >
    <li [ngbNavItem]="1"  (click)="loadRoles()">
      <a ngbNavLink>OVERVIEW</a>
      <ng-template ngbNavContent>
        <div class="card">
          <h5 class="card-header bg-theme">ROLES</h5>
          <div class="card-body table-responsive p-0">
            <div class="d-flex justify-content-between m-2">
              <select
                class="custom-select"
                style="width: auto"
                name="pageSize"
                (change)="onLimitChange()"
                [(ngModel)]="pTable.limit"
              >
                <option [ngValue]="10">10</option>
                <option [ngValue]="20">20</option>
                <option [ngValue]="30">30</option>
                <option [ngValue]="40">40</option>
                <option [ngValue]="50">50</option>
              </select>
              <div class="form-group form-inline mb-0">
                <span *ngIf="pTable.isLoading">Please wait...</span>
                <input
                  class="form-control ml-2"
                  type="text"
                  (keyup)="searchInputValue($event)"
                  placeholder="Search here"
                />
              </div>
            </div>
            <table class="table" *ngIf="!isTableLoading">
              <thead>
                <tr>
                  <th scope="col" sortable="name" (sort)="onSort($event)">
                    ROLE NAME
                  </th>
                  <th
                    scope="col"
                    sortable="description"
                    (sort)="onSort($event)"
                  >
                    DESCRIPTION
                  </th>
                  <th scope="col" sortable="status" (sort)="onSort($event)">
                    STATUS
                  </th>

                  <th scope="col">ACTION</th>
                </tr>
              </thead>
              <tbody *ngIf="privilegeFlags?.privilegeFlags?.roleSetup">
                <tr *ngFor="let item of paginateData">
                  <td>
                    <span class="font-weight-500">{{ item.name }}</span>
                  </td>
                  <td>{{ item.description }}</td>
                  <td>{{ item.status == 1 ? 'Active' : 'In Active'}}</td>
                  <td>
                    <button
                      class="btn btn-success btn-sm"
                      (click)="getEditRoleData(item)"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <ngx-skeleton-loader
              *ngIf="isTableLoading"
              count="6"
              [theme]="{
                width: 'calc(100% - 20px)',
                'border-radius': '6px',
                height: '44px',
                margin: '10px',
                display: 'block'
              }"
            ></ngx-skeleton-loader>
          </div>
          <div class="card-footer">
            <div class="d-flex justify-content-between">
              <p class="align-self-center m-0 text-muted">
                Total Records
                <b class="text-dark">{{ pTable.total }}</b>
              </p>
              <ngb-pagination
                [collectionSize]="pTable.total"
                [(page)]="pTable.page"
                [maxSize]="5"
                (pageChange)="onPageChange($event)"
                [pageSize]="pTable.limit"
              >
              </ngb-pagination>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="2" (click)="initForm()">
      <a ngbNavLink>{{ isEditing ? "EDIT" : "ADD" }}</a>
      <ng-template ngbNavContent>
        <div class="card">
          <div
            class="card-header d-flex align-items-center justify-content-between bg-theme"
          >
            <h5 class="m-0">{{ isEditing ? "EDIT" : "ADD" }} ROLES</h5>
            <button
              *ngIf="isEditing"
              class="btn btn-sm btn-outline-light"
              (click)="onCancelEdit()"
              type="button"
            >
              Back
            </button>
          </div>
          <div class="card-body">
            <form [formGroup]="userRolesFormGroup">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="font-weight-500">Role name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Role Name"
                      formControlName="name"
                      required
                      maxlength="40"
                      [ngClass]="{
                        'is-invalid': submitted && f.name.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && f.name.errors"
                      class="invalid-feedback"
                    >
                      <small *ngIf="f.name.errors.required">
                        Role name is required
                      </small>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="font-weight-500">Role description</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=" Role Description"
                      formControlName="description"
                      required
                      maxlength="50"
                      [ngClass]="{
                        'is-invalid': submitted && f.description.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && f.description.errors"
                      class="invalid-feedback"
                    >
                      <small *ngIf="f.description.errors.required">
                        Role description is required
                      </small>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="font-weight-500 col-md-3">
                      Mobile Access Only
                    </label>
                    <div class="col-md-9">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="isFlexiStaff"
                          id="isFlexiStaff1"
                          [value]="true"
                          formControlName="isFlexiStaff"
                        />
                        <label class="form-check-label" for="isFlexiStaff1"
                          >Yes</label
                        >
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="isFlexiStaff"
                          id="isFlexiStaff2"
                          [value]="false"
                          formControlName="isFlexiStaff"
                        />
                        <label class="form-check-label" for="isFlexiStaff2"
                          >No</label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="font-weight-500 col-md-3">
                      Change status
                    </label>
                    <div class="col-md-9">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="status"
                          id="status1"
                          value="active"
                          formControlName="status"
                        />
                        <label class="form-check-label" for="status1"
                          >Active</label
                        >
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="status"
                          id="status2"
                          value="inactive"
                          formControlName="status"
                        />
                        <label class="form-check-label" for="status2"
                          >Inactive</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <table class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Function</th>
                    <th>Description</th>
                     <th>Enable</th>
                    <th class="wid-15">Additional Access Rights</th>
                  </tr>
                </thead>
                <tbody *ngFor="let item of categaoryDetails;let i=index">


                  <tr>
                    <!-- [attr.rowspan]="item?.privileges ? item?.privileges.length :0" -->
                    <td >{{ item.name }}</td>
                    <td class="pad-0">
                      <ng-container *ngFor="let pre of item?.privileges">
                        <div class="contentStyleAlign">   {{ pre.name }}</div>
                      </ng-container>

                    </td>
                    <td class="pad-0">
                      <ng-container *ngFor="let pre of item?.privileges">
                      <div class="contentStyleAlign">{{ pre.description }}</div>
                      </ng-container>
                    </td>
                    <td class="pad-0">
                      <ng-container *ngFor="let pre of item?.privileges">
                      <div class="contentStyleAlign"> <input class="check-size"
                        type="checkbox"
                        (change)="addPrivileges(pre._id,pre.isSelected)" [(ngModel)]="pre.isSelected" [ngModelOptions]="{standalone: true}"
                      /></div>

                      </ng-container>
                    </td>
                    <td class="pad-0 wid-15">
                      <ng-container *ngFor="let pre of item?.privileges">
                      <div class="contentStyleAlign"></div>
                      </ng-container>
                    </td>


                  </tr>
                </tbody>
              </table>
              <!-- <div class="row">
                  <div class="col-md-3 my-3" *ngFor="let item of this.privilegeMix">
                    <div class="card h-100">
                      <div class="card-body">
                        <div class="d-flex justify-content-between">
                          <div class="mb-2">
                            <h5 class="card-title m-0">{{ item.name }}</h5>
                            <span class="h6 text-muted">{{
                              item.CategoryName
                            }}</span>
                          </div>

                          <input
                            class=""
                            type="checkbox"
                            (click)="addPrivileges(item._id)"
                            [checked]="item.flag === true"
                            [value]="item._id"
                          />
                        </div>

                        <p class="card-text">{{ item.description }}</p>
                      </div>
                    </div>
                  </div>
                </div> -->
            </form>
          </div>
          <div class="card-footer">
            <button
              class="btn btn-success"
              [disabled]="isLoading || !userRolesFormGroup.valid"
              (click)="onUserRoleFormSubmit()"
            >
              {{ isEditing ? "Update" : "Add" }}
              <span
                *ngIf="isLoading"
                class="spinner-border spinner-border-sm ml-1"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
            <button class="btn btn-danger ml-2" (click)="onCancelEdit()">
              Clear
            </button>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="companyNav"></div>
</div>
