import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../service/user-service/users.service';
import { finalize } from 'rxjs/operators';
import { NotificationService } from '../../service/notification/notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  data;
  notificationData = [];
  numberOfRecords: any;
  userData: any;
  profilePictureLink: any;
  notificationTable:any={
    data: [],
    page: 1,
    total: 0,
    limit: 10,
  }
  baseUrl = environment.imageUrl;
  favIcon: HTMLLinkElement = document.querySelector('#favIcon');
  constructor(private users: UsersService,private notificationService:NotificationService) {}

  ngOnInit(): void {
   const faviconLogo =  localStorage.getItem("favicon");
   this.favIcon.href =  !!faviconLogo? faviconLogo : 'favicon.ico';

    this.getUser();
    this.getUserData();
  }

  getUser() {
    const sub = this.notificationService
      .getMyNotification()
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe((res: any) => {
        this.notificationTable.data = res.data || [];
        console.log(this.notificationTable.data );

        this.notificationTable.total = res.data?.length;
      });
  }

  getUserData() {
    const userData = this.users
      .getUserProfile()
      .pipe(
        finalize(() => {
          userData.unsubscribe();
        })
      )
      .subscribe(
        (res: any) => {
          this.userData = res.data;
          if (res.data.profilePicture) {
            this.profilePictureLink = res.data.profilePicture;
          }
        },
        (error) => {}
      );
  }
   //method to pagination
   onPageChangeballotData(page: number) {
    this.notificationTable.page = page;
  }

  validateURLImage(str) {
    var img_Path = str;
    if (img_Path.indexOf("https://") == 0) {
      var new_url = new URL(str);
     var pathName = (new_url.pathname.startsWith('//')) ? `/${new_url.pathname.split("//")[1]}` : new_url.pathname;
      return `${this.baseUrl}${pathName}`;
    }
    if (str.startsWith('/')) return this.baseUrl + str;
    return `${this.baseUrl}/${str}`;
   }
}
