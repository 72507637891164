<div
  #sidemenu
  id="sidemenu"
  class="p-3 d-none d-sm-block pt-5 sidemenu"
  *ngIf="!isView"
>
  <aside>
    <div class="form-group">
      <label class="d-block text-center">BUSINESS UNIT</label>
      <ng-select
        [items]="buForShift"
        [loading]="buLoader"
        loadingText="Please wait data is loading..."
        bindLabel="orgName"
        class="custom"
        placeholder="Select/Search Business unit"
        appendTo="body"
        [searchable]="true"
        [clearable]="true"
        [closeOnSelect]="true"
        [(ngModel)]="planBuId"
      >
      </ng-select>
    </div>

    <div class="row">
      <div class="col pr-md-3">
        <button class="btn btn-danger btn-block">Cancel</button>
      </div>
      <div class="col pl-md-0">
        <button
          class="btn btn-info btn-block"
          (click)="viewClicked(planBuId)"
        >
          View
        </button>
      </div>
    </div>
  </aside>
</div>

<div class="d-flex flex-column" *ngIf="isView">
  <ul
    ngbNav
    #companyNav="ngbNav"
    [(activeId)]="activeId"
    class="nav-tabs rounded mb-3"
  >
    <li [ngbNavItem]="1">
      <a ngbNavLink>OVERVIEW</a>
      <ng-template ngbNavContent>
        <app-plan-shift-header [data]="headerData"></app-plan-shift-header>
        <app-plan-overview
          [isPlan]="true"
          [bu]="Bu"
          [page]="1"
          (overviewHeaderData)="dashboardData($event)"
        ></app-plan-overview>
      </ng-template>
    </li>
    <li [ngbNavItem]="2" class="not-allowed">
      <a ngbNavLink class="no-click">ASSIGN SHIFT</a>
      <ng-template ngbNavContent>
        <app-assign-shift [bu]="Bu" [tier]="tier"></app-assign-shift>
      </ng-template>
    </li>
    <li [ngbNavItem]="3" class="not-allowed">
      <a ngbNavLink class="no-click">OPEN SHIFT</a>
      <ng-template ngbNavContent>
        <app-plan-shift-header [data]="headerData"></app-plan-shift-header>
        <app-open-shift
          [bu]="Bu"
          (changeTab)="activeTab($event)"
          (weekStart)="getshiftfor($event)"
          [tier]="tier"
        ></app-open-shift>
      </ng-template>
    </li>
    <li [ngbNavItem]="4" class="not-allowed">
      <a ngbNavLink class="no-click">LOG (ASSIGN)</a>
      <ng-template ngbNavContent>
        <app-log-assign [bu]="Bu"></app-log-assign>
      </ng-template>
    </li>
    <li [ngbNavItem]="5" class="not-allowed">
      <a ngbNavLink class="no-click">LOG (OPEN)</a>
      <ng-template ngbNavContent>
        <app-log-open [bu]="Bu" [shift]="shiftdata"></app-log-open>
      </ng-template>
    </li>
    <li [ngbNavItem]="6" class="not-allowed">
      <a ngbNavLink class="no-click">STAFFING</a>
      <ng-template ngbNavContent>
        <app-staffing [bu]="Bu"></app-staffing>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="companyNav"></div>
</div>
