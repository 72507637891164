import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { P404Component } from './shared/error/404.component';
import { ExternalLinkComponent } from './external-link/external-link.component';
import { NetworkRocketComponent } from './shared/network-rocket/network-rocket.component';

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () =>
      import('../app/admin/admin.module').then((module) => module.AdminModule),
  },
  {
    path: 'users',
    loadChildren: () =>
      import('../app/users/users.module').then((module) => module.UsersModule),
  },
  {
    path: ':id/external-link/:id',
    component: ExternalLinkComponent
  },
  {
    path: '',
    pathMatch: 'full',
    component: NetworkRocketComponent,
  },
  {
    path: '404',
    component: P404Component
  },
  {
    path: '**', redirectTo: '/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
