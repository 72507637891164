import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SetupService } from 'src/app/users/service/operations/setup.service';
import { UsersService } from 'src/app/users/service/user-service/users.service';
@Component({
  selector: 'app-shift-scheme-edit',
  templateUrl: './shift-scheme-edit.component.html',
  styleUrls: ['./shift-scheme-edit.component.scss'],
})
export class ShiftSchemeEditComponent implements OnInit {
  @Output() ChangeTabId = new EventEmitter<any>();
  @Input() buShiftID: any;
  @Input() schemeShiftID: any;
  @Input() schemeRecieveData: any[];
  @Input() isView: any;
  @Input() resetTab: any;

  limit = false;
  flex = false;
  assgned = false;
  buShiftIdScheme: any;
  shiftSchemeAdmin: any;
  flexiFormData: any;
  FormData: any;
  shiftSchemeBothForm: any;
  schemeSingleview: any;
  flexiData: any;
  assignedData: any;
  flexiAndAssigned: any;
  isRecallHours: boolean;
  isDisable: any;
  isAssignSiftExt: boolean;
  isOpenSiftExt: boolean;
  show = false;
  showshift: boolean = false;
  showAssign: boolean;
  showHours: boolean;
  showRecall: boolean;
  userPTable: {
    sortWith: string;
    sortBy: string;
    data: any[];
    page: number;
    limit: number;
    search: string;
  };
  CustomFieldDropDownLoading: boolean = false;
  loggedInUser: { _id: any; name: any };
  selectedWeekForShiftView:any;
  selectedWeekForShiftViewHours:any;
  selectedWeekForShiftViewMinutes:any;
  weeksShiftsView =[];
  selectedHours = [];
  selectedMinutes = [];
  constructor(
    private buService: SetupService,
    private userService: UsersService,
    public toastService: ToastrService
  ) {
    this.userPTable = {
      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,

      limit: 10,

      search: '',
    };
  }

  ngOnInit(): void {
    this.resetFrom();
    this.buShiftIdScheme = this.buShiftID;
    for(var i=0; i<13; i++)
    {
      if(i <10)
      this.weeksShiftsView[i] = i+1;
      this.selectedHours[i] = i;
    }
    for(var i=0; i<4; i++)
    {
      if(i == 0)
      {
        this.selectedMinutes[i] = '00';

      }
      else
      {
      this.selectedMinutes[i] = i*15;
      }
    }
    if (this.isView == 'view' || this.isView == 'edit') {
      this.schemePrefill();
      this.ShiftSchemeType.disable();
    }
    if (this.isView == 'view') {
      this.schemeInfo.disable();
      this.schemeShiftflexi.disable();
      this.schemeShiftAssign.disable();
      this.limitTimeTable.disable();
      this.actionLimits.disable();
      this.minimumInterVal.disable();
      this.isDisable = true;
    } else {
      this.isDisable = false;
    }
  }

  getAdmins() {
    this.CustomFieldDropDownLoading = true;
    const { data, ...rest } = this.userPTable;
    const subscribe = this.buService
      .getAdminWithfilter()
      .pipe(
        finalize(() => {
          this.CustomFieldDropDownLoading = false;
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (res: any) => {
          const { status, data } = res;
          this.userPTable.data = data;
        },
        (error) => {
          this.userPTable.data = [];
        }
      );
  }

  schemePrefill() {
    if (this.schemeRecieveData['shiftSchemeType'] == 1) {
      this.changeFlexiAndAssgnd(true, false);
    } else if (this.schemeRecieveData['shiftSchemeType'] == 2) {
      this.changeFlexiAndAssgnd(false, true);
    } else {
      this.changeFlexiAndAssgnd(true, true);
    }

    if (this.isView == 'view' || this.isView == 'edit') {
      this.selectedWeekForShiftView = this.schemeRecieveData['noOfWeek'] || null;
      this.showHours = this.schemeRecieveData['isShiftInterval']
      this.selectedWeekForShiftViewHours = this.schemeRecieveData['shiftIntervalHour']
      this.selectedWeekForShiftViewMinutes = this.schemeRecieveData['shiftIntervalMins']
      this.minimumInterVal.patchValue({
        isShiftInterval : this.schemeRecieveData['isShiftInterval']
      })
      this.ShiftSchemeType.patchValue({
        schemeType: this.schemeRecieveData['shiftSchemeType']== '1' ? 'flexi': this.schemeRecieveData['shiftSchemeType']== '2' ? 'assigned':'both',
      });
      this.schemeInfo.patchValue({
        schemeName: this.schemeRecieveData['schemeName'],
        schemeDesc: this.schemeRecieveData['schemeDesc'],
      });

      if (this.schemeRecieveData['shiftSetup']['openShift']['ot'] == true) {
        this.schemeShiftflexi.patchValue({
          basicHours: 'false',
        });
      } else {
        this.schemeShiftflexi.patchValue({
          basicHours: 'true',
        });
      }

      if (
        this.schemeRecieveData['shiftSetup']['openShift'][
          'allowShiftExtension'
        ]['no'] == true
      ) {
        this.schemeShiftflexi.patchValue({
          allowShiftExt: 'false',
        });
      } else {
        this.schemeShiftflexi.patchValue({
          allowShiftExt: 'true',
        });
      }

      if (
        this.schemeRecieveData['shiftSetup']['openShift'][
          'allowShiftExtension'
        ]['ot'] == true
      ) {
        this.schemeShiftflexi.patchValue({
          shiftExtHr: 'false',
        });
      } else {
        this.schemeShiftflexi.patchValue({
          shiftExtHr: 'true',
        });
      }

      if (
        this.schemeRecieveData['shiftSchemeType'] == 2 ||
        this.schemeRecieveData['shiftSchemeType'] == 3
      ) {
        if (this.schemeRecieveData['shiftSetup']['assignShift']['ot'] == true) {
          this.schemeShiftAssign.patchValue({
            assignBasic: 'false',
          });
        } else {
          this.schemeShiftAssign.patchValue({
            assignBasic: 'true',
          });
        }

        if (
          this.schemeRecieveData['shiftSetup']['assignShift'][
            'allowShiftExtension'
          ]['no'] == true
        ) {
          this.schemeShiftAssign.patchValue({
            assignAllowShiftExt: 'false',
          });
        } else {
          this.schemeShiftAssign.patchValue({
            assignAllowShiftExt: 'true',
          });
        }

        if (
          this.schemeRecieveData['shiftSetup']['assignShift'][
            'allowShiftExtension'
          ]['ot'] == true
        ) {
          this.schemeShiftAssign.patchValue({
            assignShiftExt: 'false',
          });
        } else {
          this.schemeShiftAssign.patchValue({
            assignShiftExt: 'true',
          });
        }

        if (
          this.schemeRecieveData['shiftSetup']['assignShift']['allowRecall'][
            'no'
          ] == true
        ) {
          this.schemeShiftAssign.patchValue({
            assignShiftRecall: 'false',
          });
        } else {
          this.schemeShiftAssign.patchValue({
            assignShiftRecall: 'true',
          });
        }

        if (
          this.schemeRecieveData['shiftSetup']['assignShift']['allowRecall'][
            'ot'
          ] == true
        ) {
          this.schemeShiftAssign.patchValue({
            assignShiftRecallHour: 'false',
          });
        } else {
          this.schemeShiftAssign.patchValue({
            assignShiftRecallHour: 'true',
          });
        }

        if (
          this.schemeRecieveData['shiftSetup']['assignShift'][
            'allowShiftExtension'
          ]['yes'] == true
        ) {
          this.showAssign = true;
        }
        if (
          this.schemeRecieveData['shiftSetup']['assignShift']['allowRecall'][
            'yes'
          ] == true
        ) {
          this.showRecall = true;
        }
      }

      this.limitTimeTable.patchValue({
        dayNormal:
          this.schemeRecieveData['shiftSetup']['limits']['normalHr']['day'][
            'value'
          ],
        dayOt:
          this.schemeRecieveData['shiftSetup']['limits']['otHr']['day'][
            'value'
          ],
        dayOverall:
          this.schemeRecieveData['shiftSetup']['limits']['dayOverall'],
        weekNormal:
          this.schemeRecieveData['shiftSetup']['limits']['normalHr']['week'][
            'value'
          ],
        weekOt:
          this.schemeRecieveData['shiftSetup']['limits']['otHr']['week'][
            'value'
          ],
        weekOverall:
          this.schemeRecieveData['shiftSetup']['limits']['weekOverall'],
        monthNormal:
          this.schemeRecieveData['shiftSetup']['limits']['normalHr']['month'][
            'value'
          ],
        monthOt:
          this.schemeRecieveData['shiftSetup']['limits']['otHr']['month'][
            'value'
          ],
        monthOverall:
          this.schemeRecieveData['shiftSetup']['limits']['monthOverall'],
      });

      if (
        this.schemeRecieveData['shiftSetup']['limits']['normalHr']['day'][
          'disallow'
        ] == true
      ) {
        this.actionLimits.patchValue({
          alertDisallow: 'false',
        });
      } else {
        this.actionLimits.patchValue({
          alertDisallow: 'true',
        });
      }

      if (
        this.schemeRecieveData['shiftSetup']['openShift'][
          'allowShiftExtension'
        ]['yes'] == true
      ) {
        this.show = true;
      }
    }
  }

  ShiftSchemeType = new FormGroup({
    schemeType: new FormControl(),
  });

  schemeInfo = new FormGroup({
    schemeName: new FormControl(),
    schemeDesc: new FormControl(),
  });

  schemeShiftflexi = new FormGroup({
    basicHours: new FormControl(),
    allowShiftExt: new FormControl(),
    shiftExtHr: new FormControl(),
  });

  schemeShiftAssign = new FormGroup({
    assignBasic: new FormControl(),
    assignAllowShiftExt: new FormControl(),
    assignShiftExt: new FormControl(),
    assignShiftRecall: new FormControl(),
    assignShiftRecallHour: new FormControl(),
  });
  minimumInterVal = new FormGroup({
    isShiftInterval: new FormControl(),
  });
  limitTimeTable = new FormGroup({
    dayNormal: new FormControl(),
    dayOt: new FormControl(),
    dayOverall: new FormControl(),
    weekNormal: new FormControl(),
    weekOt: new FormControl(),
    weekOverall: new FormControl(),
    monthNormal: new FormControl(),
    monthOt: new FormControl(),
    monthOverall: new FormControl(),
  });

  actionLimits = new FormGroup({
    alertDisallow: new FormControl(''),
  });

  onChangeLimit(val) {
    this.limit = val;
  }

  changeFlexiAndAssgnd(flexVal, assgndVal) {
    this.flex = flexVal;
    this.assgned = assgndVal;
  }

  onSubmit() {
    const companyID = localStorage.getItem('flexicompanyId');
    if (this.ShiftSchemeType.get('schemeType').value == 'flexi') {
      this.flexiData = {
        businessUnitId: '5bd723a8c1e35a7a250d562a',
        "companyID": companyID,
        schemeName: this.schemeInfo.controls.schemeName.value,
        schemeDesc: this.schemeInfo.controls.schemeDesc.value,
        shiftSchemeType: this.ShiftSchemeType.controls.schemeType.value == 'flexi' ? 1:2,
        "noOfWeek":this.selectedWeekForShiftView,
        "isShiftInterval":this.showHours,
        "shiftIntervalHour":this.selectedWeekForShiftViewHours,
        "shiftIntervalMins":this.selectedWeekForShiftViewMinutes == '00' ? 0 : this.selectedWeekForShiftViewMinutes,
        shiftSetup: {
          openShift: {
            enabled: false,
            disabled: true,
            normal: '',
            ot: '',
            allowShiftExtension: {
              yes: '',
              no: '',
              normal: '',
              ot: '',
            },
          },
          limits: {
            dayOverall: this.limitTimeTable.controls.dayOverall.value,
            weekOverall: this.limitTimeTable.controls.weekOverall.value,
            monthOverall: this.limitTimeTable.controls.monthOverall.value,
            normalHr: {
              day: {
                value: this.limitTimeTable.controls.dayNormal.value,
                no: '',
                enable: '',
                action: 'false',
                alert: '',
                disallow: '',
              },
              week: {
                value: this.limitTimeTable.controls.weekNormal.value,
                no: '',
                enable: '',
                action: 'false',
                alert: '',
                disallow: '',
              },
              month: {
                value: this.limitTimeTable.controls.monthNormal.value,
                no: '',
                enable: '',
                action: 'false',
                alert: '',
                disallow: '',
              },
            },
            otHr: {
              day: {
                value: this.limitTimeTable.controls.dayOt.value,
                no: '',
                enable: '',
                action: 'false',
                alert: '',
                disallow: '',
              },
              week: {
                value: this.limitTimeTable.controls.weekOt.value,
                no: '',
                enable: '',
                action: 'false',
                alert: '',
                disallow: '',
              },
              month: {
                value: this.limitTimeTable.controls.monthOt.value,
                no: '',
                enable: '',
                action: 'false',
                alert: '',
                disallow: '',
              },
            },
          },
        },
      };

      if (this.schemeShiftflexi.controls.basicHours.value == 'true') {
        this.flexiData.shiftSetup.openShift.normal = true;
        this.flexiData.shiftSetup.openShift.ot = false;
      } else {
        this.flexiData.shiftSetup.openShift.normal = false;
        this.flexiData.shiftSetup.openShift.ot = true;
      }

      if (this.schemeShiftflexi.controls.allowShiftExt.value == 'true') {
        this.flexiData.shiftSetup.openShift.allowShiftExtension.yes = true;
        this.flexiData.shiftSetup.openShift.allowShiftExtension.no = false;
      } else {
        this.flexiData.shiftSetup.openShift.allowShiftExtension.yes = false;
        this.flexiData.shiftSetup.openShift.allowShiftExtension.no = true;
      }

      if (this.schemeShiftflexi.controls.shiftExtHr.value == 'true') {
        this.flexiData.shiftSetup.openShift.allowShiftExtension.normal = true;
        this.flexiData.shiftSetup.openShift.allowShiftExtension.ot = false;
      } else {
        this.flexiData.shiftSetup.openShift.allowShiftExtension.normal = false;
        this.flexiData.shiftSetup.openShift.allowShiftExtension.ot = true;
      }

      if (this.actionLimits.controls.alertDisallow.value == 'true') {
        this.flexiData.shiftSetup.limits.normalHr.day.alert = true;
        this.flexiData.shiftSetup.limits.normalHr.day.disallow = false;
        this.flexiData.shiftSetup.limits.normalHr.week.alert = true;
        this.flexiData.shiftSetup.limits.normalHr.week.disallow = false;
        this.flexiData.shiftSetup.limits.normalHr.month.alert = true;
        this.flexiData.shiftSetup.limits.normalHr.month.disallow = false;
      } else if (this.actionLimits.controls.alertDisallow.value == 'false') {
        this.flexiData.shiftSetup.limits.normalHr.day.alert = false;
        this.flexiData.shiftSetup.limits.normalHr.day.disallow = true;
        this.flexiData.shiftSetup.limits.normalHr.week.alert = false;
        this.flexiData.shiftSetup.limits.normalHr.week.disallow = true;
        this.flexiData.shiftSetup.limits.normalHr.month.alert = false;
        this.flexiData.shiftSetup.limits.normalHr.month.disallow = true;
      }
      if (this.actionLimits.controls.alertDisallow.value == 'true') {
        this.flexiData.shiftSetup.limits.otHr.day.alert = true;
        this.flexiData.shiftSetup.limits.otHr.day.disallow = false;
        this.flexiData.shiftSetup.limits.otHr.week.alert = true;
        this.flexiData.shiftSetup.limits.otHr.week.disallow = false;
        this.flexiData.shiftSetup.limits.otHr.month.alert = true;
        this.flexiData.shiftSetup.limits.otHr.month.disallow = false;
      } else if (this.actionLimits.controls.alertDisallow.value == 'false') {
        this.flexiData.shiftSetup.limits.otHr.day.alert = false;
        this.flexiData.shiftSetup.limits.otHr.day.disallow = true;
        this.flexiData.shiftSetup.limits.otHr.week.alert = false;
        this.flexiData.shiftSetup.limits.otHr.week.disallow = true;
        this.flexiData.shiftSetup.limits.otHr.month.alert = false;
        this.flexiData.shiftSetup.limits.otHr.month.disallow = true;
      }

      if (this.isView == 'edit') {
        this.flexiData._id = this.schemeRecieveData['_id'];
        this.flexiData.status = true;
      }

      this.FormData = this.flexiData;
    } else if (this.ShiftSchemeType.get('schemeType').value == 'assigned') {
      this.assignedData = {
        businessUnitId: '5bd723a8c1e35a7a250d562a',
        "companyID": companyID,
        schemeName: this.schemeInfo.controls.schemeName.value,
        schemeDesc: this.schemeInfo.controls.schemeDesc.value,
        shiftSchemeType: 2,
        isShiftInterval:this.showHours,
        shiftIntervalHour:this.selectedWeekForShiftViewHours,
        shiftIntervalMins:this.selectedWeekForShiftViewMinutes == '00' ? 0 : this.selectedWeekForShiftViewMinutes,
       "noOfWeek":this.selectedWeekForShiftView,
        shiftSetup: {
          assignShift: {
            enabled: false,
            disabled: true,
            normal: '',
            ot: '',
            allowShiftExtension: {
              yes: '',
              no: '',
              normal: '',
              ot: '',
            },
            allowRecall: {
              no: '',
              normal: '',
              ot: '',
              yes: '',
            },
          },
          openShift: {
            enabled: false,
            disabled: true,
            normal: '',
            ot: '',
            allowShiftExtension: {
              yes: '',
              no: '',
              normal: '',
              ot: '',
            },
          },
          limits: {
            dayOverall: this.limitTimeTable.controls.dayOverall.value,
            weekOverall: this.limitTimeTable.controls.weekOverall.value,
            monthOverall: this.limitTimeTable.controls.monthOverall.value,
            normalHr: {
              day: {
                value: this.limitTimeTable.controls.dayNormal.value,
                no: '',
                enable: '',
                action: 'false',
                alert: '',
                disallow: '',
              },
              week: {
                value: this.limitTimeTable.controls.weekNormal.value,
                no: '',
                enable: '',
                action: 'false',
                alert: '',
                disallow: '',
              },
              month: {
                value: this.limitTimeTable.controls.monthNormal.value,
                no: '',
                enable: '',
                action: 'false',
                alert: '',
                disallow: '',
              },
            },
            otHr: {
              day: {
                value: this.limitTimeTable.controls.dayOt.value,
                no: '',
                enable: '',
                action: 'false',
                alert: '',
                disallow: '',
              },
              week: {
                value: this.limitTimeTable.controls.weekOt.value,
                no: '',
                enable: '',
                action: 'false',
                alert: '',
                disallow: '',
              },
              month: {
                value: this.limitTimeTable.controls.monthOt.value,
                no: '',
                enable: '',
                action: 'false',
                alert: '',
                disallow: '',
              },
            },
          },
        },
      };
      if (this.schemeShiftAssign.controls.assignBasic.value == 'true') {
        this.assignedData.shiftSetup.assignShift.normal = true;
        this.assignedData.shiftSetup.assignShift.ot = false;
      } else {
        this.assignedData.shiftSetup.assignShift.normal = false;
        this.assignedData.shiftSetup.assignShift.ot = true;
      }

      if (this.schemeShiftAssign.controls.assignAllowShiftExt.value == 'true') {
        this.assignedData.shiftSetup.assignShift.allowShiftExtension.yes = true;
        this.assignedData.shiftSetup.assignShift.allowShiftExtension.no = false;
      } else {
        this.assignedData.shiftSetup.assignShift.allowShiftExtension.yes =
          false;
        this.assignedData.shiftSetup.assignShift.allowShiftExtension.no = true;
      }

      if (this.schemeShiftAssign.controls.assignShiftExt.value == 'true') {
        this.assignedData.shiftSetup.assignShift.allowShiftExtension.normal =
          true;
        this.assignedData.shiftSetup.assignShift.allowShiftExtension.ot = false;
      } else {
        this.assignedData.shiftSetup.assignShift.allowShiftExtension.normal =
          false;
        this.assignedData.shiftSetup.assignShift.allowShiftExtension.ot = true;
      }

      if (this.schemeShiftAssign.controls.assignShiftRecall.value == 'true') {
        this.assignedData.shiftSetup.assignShift.allowRecall.yes = true;
        this.assignedData.shiftSetup.assignShift.allowRecall.no = false;
      } else {
        this.assignedData.shiftSetup.assignShift.allowRecall.yes = false;
        this.assignedData.shiftSetup.assignShift.allowRecall.no = true;
      }

      if (
        this.schemeShiftAssign.controls.assignShiftRecallHour.value == 'true'
      ) {
        this.assignedData.shiftSetup.assignShift.allowRecall.normal = true;
        this.assignedData.shiftSetup.assignShift.allowRecall.ot = false;
      } else {
        this.assignedData.shiftSetup.assignShift.allowRecall.normal = false;
        this.assignedData.shiftSetup.assignShift.allowRecall.ot = true;
      }

      if (this.actionLimits.controls.alertDisallow.value == 'true') {
        this.assignedData.shiftSetup.limits.normalHr.day.alert = true;
        this.assignedData.shiftSetup.limits.normalHr.day.disallow = false;
      } else if (this.actionLimits.controls.alertDisallow.value == 'false') {
        this.assignedData.shiftSetup.limits.normalHr.day.alert = false;
        this.assignedData.shiftSetup.limits.normalHr.day.disallow = true;
      }

      if (this.isView == 'edit') {
        this.assignedData._id = this.schemeRecieveData['_id'];
        this.assignedData.status = true;
      }

      this.FormData = this.assignedData;
    } else {
      this.flexiAndAssigned = {
        businessUnitId: '5bd723a8c1e35a7a250d562a',
        "companyID": companyID,
        schemeName: this.schemeInfo.controls.schemeName.value,
        schemeDesc: this.schemeInfo.controls.schemeDesc.value,
        shiftSchemeType: 3,
        isShiftInterval:this.showHours,
        shiftIntervalHour:this.selectedWeekForShiftViewHours,
        shiftIntervalMins:this.selectedWeekForShiftViewMinutes == '00' ? 0 : this.selectedWeekForShiftViewMinutes,
       "noOfWeek":this.selectedWeekForShiftView,
        shiftSetup: {
          assignShift: {
            enabled: false,
            disabled: true,
            normal: '',
            ot: '',
            allowShiftExtension: {
              yes: '',
              no: '',
              normal: '',
              ot: '',
            },
            allowRecall: {
              no: '',
              normal: '',
              ot: '',
              yes: '',
            },
          },
          openShift: {
            enabled: false,
            disabled: true,
            normal: '',
            ot: '',
            allowShiftExtension: {
              yes: '',
              no: '',
              normal: '',
              ot: '',
            },
          },
          limits: {
            dayOverall: this.limitTimeTable.controls.dayOverall.value,
            weekOverall: this.limitTimeTable.controls.weekOverall.value,
            monthOverall: this.limitTimeTable.controls.monthOverall.value,
            normalHr: {
              day: {
                value: this.limitTimeTable.controls.dayNormal.value,
                no: '',
                enable: '',
                action: 'false',
                alert: '',
                disallow: '',
              },
              week: {
                value: this.limitTimeTable.controls.weekNormal.value,
                no: '',
                enable: '',
                action: 'false',
                alert: '',
                disallow: '',
              },
              month: {
                value: this.limitTimeTable.controls.monthNormal.value,
                no: '',
                enable: '',
                action: 'false',
                alert: '',
                disallow: '',
              },
            },
            otHr: {
              day: {
                value: this.limitTimeTable.controls.dayOt.value,
                no: '',
                enable: '',
                action: 'false',
                alert: '',
                disallow: '',
              },
              week: {
                value: this.limitTimeTable.controls.weekOt.value,
                no: '',
                enable: '',
                action: 'false',
                alert: '',
                disallow: '',
              },
              month: {
                value: this.limitTimeTable.controls.monthOt.value,
                no: '',
                enable: '',
                action: 'false',
                alert: '',
                disallow: '',
              },
            },
          },
        },
      };

      if (this.schemeShiftflexi.controls.basicHours.value == 'true') {
        this.flexiAndAssigned.shiftSetup.openShift.normal = true;
        this.flexiAndAssigned.shiftSetup.openShift.ot = false;
      } else {
        this.flexiAndAssigned.shiftSetup.openShift.normal = false;
        this.flexiAndAssigned.shiftSetup.openShift.ot = true;
      }

      if (this.schemeShiftflexi.controls.allowShiftExt.value == 'true') {
        this.flexiAndAssigned.shiftSetup.openShift.allowShiftExtension.yes =
          true;
        this.flexiAndAssigned.shiftSetup.openShift.allowShiftExtension.no =
          false;
      } else {
        this.flexiAndAssigned.shiftSetup.openShift.allowShiftExtension.yes =
          false;
        this.flexiAndAssigned.shiftSetup.openShift.allowShiftExtension.no =
          true;
      }

      if (this.schemeShiftflexi.controls.shiftExtHr.value == 'true') {
        this.flexiAndAssigned.shiftSetup.openShift.allowShiftExtension.normal =
          true;
        this.flexiAndAssigned.shiftSetup.openShift.allowShiftExtension.ot =
          false;
      } else {
        this.flexiAndAssigned.shiftSetup.openShift.allowShiftExtension.normal =
          false;
        this.flexiAndAssigned.shiftSetup.openShift.allowShiftExtension.ot =
          true;
      }

      if (this.schemeShiftAssign.controls.assignBasic.value == 'true') {
        this.flexiAndAssigned.shiftSetup.assignShift.normal = true;
        this.flexiAndAssigned.shiftSetup.assignShift.ot = false;
      } else {
        this.flexiAndAssigned.shiftSetup.assignShift.normal = false;
        this.flexiAndAssigned.shiftSetup.assignShift.ot = true;
      }

      if (this.schemeShiftAssign.controls.assignAllowShiftExt.value == 'true') {
        this.flexiAndAssigned.shiftSetup.assignShift.allowShiftExtension.yes =
          true;
        this.flexiAndAssigned.shiftSetup.assignShift.allowShiftExtension.no =
          false;
      } else {
        this.flexiAndAssigned.shiftSetup.assignShift.allowShiftExtension.yes =
          false;
        this.flexiAndAssigned.shiftSetup.assignShift.allowShiftExtension.no =
          true;
      }

      if (this.schemeShiftAssign.controls.assignShiftExt.value == 'true') {
        this.flexiAndAssigned.shiftSetup.assignShift.allowShiftExtension.normal =
          true;
        this.flexiAndAssigned.shiftSetup.assignShift.allowShiftExtension.ot =
          false;
      } else {
        this.flexiAndAssigned.shiftSetup.assignShift.allowShiftExtension.normal =
          false;
        this.flexiAndAssigned.shiftSetup.assignShift.allowShiftExtension.ot =
          true;
      }

      if (this.schemeShiftAssign.controls.assignShiftRecall.value == 'true') {
        this.flexiAndAssigned.shiftSetup.assignShift.allowRecall.yes = true;
        this.flexiAndAssigned.shiftSetup.assignShift.allowRecall.no = false;
      } else {
        this.flexiAndAssigned.shiftSetup.assignShift.allowRecall.yes = false;
        this.flexiAndAssigned.shiftSetup.assignShift.allowRecall.no = true;
      }

      if (
        this.schemeShiftAssign.controls.assignShiftRecallHour.value == 'true'
      ) {
        this.flexiAndAssigned.shiftSetup.assignShift.allowRecall.normal = true;
        this.flexiAndAssigned.shiftSetup.assignShift.allowRecall.ot = false;
      } else {
        this.flexiAndAssigned.shiftSetup.assignShift.allowRecall.normal = false;
        this.flexiAndAssigned.shiftSetup.assignShift.allowRecall.ot = true;
      }

      if (this.actionLimits.controls.alertDisallow.value == 'true') {
        this.flexiAndAssigned.shiftSetup.limits.normalHr.day.alert = true;
        this.flexiAndAssigned.shiftSetup.limits.normalHr.day.disallow = false;
        this.flexiAndAssigned.shiftSetup.limits.normalHr.week.alert = true;
        this.flexiAndAssigned.shiftSetup.limits.normalHr.week.disallow = false;
        this.flexiAndAssigned.shiftSetup.limits.normalHr.month.alert = true;
        this.flexiAndAssigned.shiftSetup.limits.normalHr.month.disallow = false;
      } else if (this.actionLimits.controls.alertDisallow.value == 'false') {
        this.flexiAndAssigned.shiftSetup.limits.normalHr.day.alert = false;
        this.flexiAndAssigned.shiftSetup.limits.normalHr.day.disallow = true;
        this.flexiAndAssigned.shiftSetup.limits.normalHr.week.alert = false;
        this.flexiAndAssigned.shiftSetup.limits.normalHr.week.disallow = true;
        this.flexiAndAssigned.shiftSetup.limits.normalHr.month.alert = false;
        this.flexiAndAssigned.shiftSetup.limits.normalHr.month.disallow = true;
      }

      if (this.actionLimits.controls.alertDisallow.value == 'true') {
        this.flexiAndAssigned.shiftSetup.limits.otHr.day.alert = true;
        this.flexiAndAssigned.shiftSetup.limits.otHr.day.disallow = false;
        this.flexiAndAssigned.shiftSetup.limits.otHr.week.alert = true;
        this.flexiAndAssigned.shiftSetup.limits.otHr.week.disallow = false;
        this.flexiAndAssigned.shiftSetup.limits.otHr.month.alert = true;
        this.flexiAndAssigned.shiftSetup.limits.otHr.month.disallow = false;
      } else if (this.actionLimits.controls.alertDisallow.value == 'false') {
        this.flexiAndAssigned.shiftSetup.limits.otHr.day.alert = false;
        this.flexiAndAssigned.shiftSetup.limits.otHr.day.disallow = true;
        this.flexiAndAssigned.shiftSetup.limits.otHr.week.alert = false;
        this.flexiAndAssigned.shiftSetup.limits.otHr.week.disallow = true;
        this.flexiAndAssigned.shiftSetup.limits.otHr.month.alert = false;
        this.flexiAndAssigned.shiftSetup.limits.otHr.month.disallow = true;
      }

      if (this.isView == 'edit') {
        this.flexiAndAssigned._id = this.schemeRecieveData['_id'];
        this.flexiAndAssigned.status = true;
      }

      this.FormData = this.flexiAndAssigned;
     }

    if (this.isView! == 'edit') {
      this.buService.updateScheme(this.FormData).subscribe((res) => {
        this.toastService.info('Shift Scheme updated successfully');
        this.ChangeTabId.emit(1);
      });
    } else {
      this.buService.createSchemeShift(this.FormData).subscribe((res) => {
        this.toastService.info('Shift Scheme created successfully');
        this.resetFrom();
        this.ChangeTabId.emit(1);
      });
    }
  }

  resetFrom() {
    this.ShiftSchemeType.reset();
    this.schemeInfo.reset();
    this.schemeShiftflexi.reset();
    this.schemeShiftAssign.reset();
    this.limitTimeTable.reset();
    this.actionLimits.reset();
  }

  changeToggle(e) {
    if (e == true) {
      this.show = true;
    } else {
      this.show = false;
    }
  }

  changeToggleAssign(e) {
    if (e == true) {
      this.showAssign = true;
    } else {
      this.showAssign = false;
    }
  }
  changeSelectedHours(e) {
    if (e == true) {
      this.showHours = true;
    } else {
      this.showHours = false;
    }
  }
  changeToggleRecall(e) {
    if (e == true) {
      this.showRecall = true;
    } else {
      this.showRecall = false;
    }
  }
}
