import { CompanySetupService } from './../../../service/company-setup/company-setup.service';
import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';
import { finalize } from 'rxjs/operators';
import { AuthService } from '../../../service/auth/auth.service';
import { LoginForm } from '../../../../core/model/definition.model';
import { StorageService } from '../../../../core/service/storage.service';
import * as recheck from 'recheck';

import {
  MustMatch,
  CustomValidators,
} from '../../../../shared/custom-validator/custom-validator';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-verify-user',
  templateUrl: './verify-user.component.html',
  styleUrls: ['./verify-user.component.scss'],
})
export class VerifyUserComponent implements OnInit {
  public login: LoginForm;
  public registrationForm: FormGroup;
  public otpForm: FormGroup;
  isLoading = false;
  public passwordRestForm: FormGroup;
  public missingFieldsForm: FormGroup;
  otpCountdown: 60;
  public submitted1: boolean = false;
  public submitted2: boolean = false;
  public submitted3: boolean = false;
  public passwordvisible: boolean = false;
  public passwordvisibleAlt: boolean = false;
  public userVerificationState: string;
  validators = [];
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  otpToken: string;
  public form: FormGroup;
  unsubcribe: any;

  public fields: any[] = [];
  companyId: any;
  counter: any;
  isCoundownStarted: boolean = false

  constructor(
    private router: Router,
    private auth: AuthService,
    public toastService: ToastrService,
    private storageService: StorageService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private pageSetupService: CompanySetupService,

  ) {
    this.companyId = this.storageService.getByKey('companyId');
    this.form = new FormGroup({
      fields: new FormControl(JSON.stringify(this.fields)),
    });
    this.unsubcribe = this.form.valueChanges.subscribe((update) => {

      this.fields = JSON.parse(update.fields);
    });
  }

  ngOnInit(): void {
    this.getPasswordSetup();
    this.getProfileInit();
    this.initForm();
    // this.getLoginFields();
  }
  getPasswordSetup() {
    const sub = this.pageSetupService
      .getPasswordSetting()
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe((res) => {
        this.getValidatorFnArray(res.data.pwdSettings);
      },
        (error: HttpErrorResponse) => {

        });
  }

  getProfileInit() {
    console.log("inside getProfileInit", this.router);
    this.router.navigateByUrl('/users/home')
    console.log("after called getProfileInit", this.router);
    /*const sub = this.auth
      .profileInit()
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe((res) => {
        if (!!res.data.requiredFields) {

          this.getLoginFields(res.data.requiredFields);
        }
        if (!!res.data.issue) {
          this.userVerificationState = res.data.issue;

          // this.toastService.error(res.data.message);
          if (res.data.issue = 'mobileverify') {
            this.otpForm.patchValue({
              token: res.data.token
            })
          }
        } else {
          this.router.navigateByUrl('/users/home');
        }
      },
        (error: HttpErrorResponse) => {

        });*/
  }

  getFields() {
    return this.fields;
  }

  getLoginFields(fields) {
    const data = fields.sort((a, b) => a.indexNum - b.indexNum);

    // const modifiedData = data.map((rawProduct) => {
    //   return {
    //     disabled: true,
    //   ...rawProduct
    //   };
    // });
    this.fields = data;
    this.form = new FormGroup({});
    this.fields.forEach((x) => {
      if (x.type == 'checkbox') {
        this.form.addControl(x.fieldName, new FormGroup({}));
        x.options.forEach((o) => {
          (this.form.get(x.fieldName) as FormGroup).addControl(
            o.key,
            new FormControl(false)
          );
        });
      } else {
        this.form.addControl(
          x.fieldName,
          new FormControl(
            x.value || '',
            x.required ? Validators.required : null
          )
        );
      }
    });
  }

  onSubmitCustomField() {
    this.isLoading = true;
    const modifiedData = this.fields.map((rawProduct) => {
      return {
        fieldId: rawProduct._id,
        editable: rawProduct.editable,
      };
    });

    const values = Object.values(this.form.value);

    let newOjbect = modifiedData.map((item, i) =>
      Object.assign({}, item, { value: values[i] })
    );


    const data = {
      otherFields: newOjbect,
    };

    const sub = this.auth
      .updateProfileInit('missingfields', data)
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe((res) => {
        this.isLoading = false;
        this.getProfileInit();

      },
        (error: HttpErrorResponse) => {
          this.isLoading = false;
        });
  }

  private initForm() {
    this.registrationForm = this.formBuilder.group({
      primaryMobileNumber: ['', [Validators.required]],
    });
    this.otpForm = this.formBuilder.group({
      otp: [null, [Validators.required]],
      token: ['', [Validators.required]],
    });
  }

  resetPasswordForm() {
    this.passwordRestForm = this.formBuilder.group(
      {
        password: ['', [...this.validators, Validators.required]],
        confirmPassword: ['', Validators.required],
        // token: ['', [Validators.required]],
      },
      {
        validator: MustMatch('password', 'confirmPassword'),
      }
    );
  }

  get f() {
    return this.registrationForm.controls;
  }
  get otp() {
    return this.otpForm.controls;
  }
  get restPassword() {
    return this.passwordRestForm.controls;
  }
  passwordVisible() {
    this.passwordvisible = !this.passwordvisible;
  }

  confirmPasswordVisible() {
    this.passwordvisibleAlt = !this.passwordvisibleAlt;
  }

 async getValidatorFnArray(setting?) {
    const validators = [];

    if (setting.charTypes.lowerCase) {
      validators.push(
        CustomValidators.patternValidator(/[a-z]/, {
          hasSmallCase: true,
        })
      );
    }
    if (setting.charTypes.specialChar) {
      validators.push(
        CustomValidators.patternValidator(
          /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
          {
            hasSpecialCharacters: true,
          }
        )
      );
    }

    if (setting.charLength) {
      const len = setting.charLength;
      let test = `^.{${len},22}`;
      let recheckResp = await recheck.check(test, "")
      if(recheckResp.status === 'safe'){
      let regex = new RegExp(test);
      validators.push(
        CustomValidators.patternValidator(
          regex,
          {
            minlength: true,
            length: setting.charLength,
          }
        )
      );
    }
    if (setting.charTypes.upperCase) {
      validators.push(
        CustomValidators.patternValidator(/[A-Z]/, {
          hasCapitalCase: true,
        })
      );
    }

    if (setting.charTypes.numbers) {
      validators.push(
        CustomValidators.patternValidator(/\d/, {
          hasNumber: true,
        })
      );
    }

    this.validators.push(...validators);

    this.resetPasswordForm();
  }
}

  onRequestRestPassword() {
    if (!this.registrationForm.valid) {
      this.submitted1 = true;
      return;
    }
    this.isLoading = true;
    const { dialCode, number } = this.registrationForm.get(
      'primaryMobileNumber'
    ).value;
    const data = {
      ...this.registrationForm.value,
      countryCode: dialCode,
      primaryMobileNumber: number,
    };

    const sub = this.auth
      .updateProfileInit('registration', data)
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe(
        (res) => {
          // this.otpForm.patchValue({
          //   token: res.data.token,
          // });

          this.otpToken = res.data.token
          this.isLoading = false;
          this.getProfileInit();
          // this.registrationForm.reset();
        },
        (error) => {
          this.isLoading = false;
        }
      );
  }

  resendOtp() {
    this.startCountdown(60)
    const sub = this.auth
      .updateProfileInit('resendOtp', {})
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe(
        (res) => {
          this.otpForm.patchValue({
            token: res.data.token,
          });
          this.toastService.success('OTP Sent successfully');
        },
        (error) => {

        }
      );
  }

  startCountdown(seconds) {
    this.counter = seconds;
    this.isCoundownStarted = true

    const interval = setInterval(() => {
      console.log(this.counter);
      this.counter--;

      if (this.counter < 0) {
        this.isCoundownStarted = false
        clearInterval(interval);

      }
    }, 1000);
  }

  onSubmitOtp() {
    // if (!this.otpForm.valid) {
    //   this.submitted2 = true;
    //   this.toastService.error('Please Enter a Valid OTP');
    //   return;
    // }
    this.otpForm.patchValue({
      token: this.otpToken
    });
    console.log(this.otpForm.value)
    this.isLoading = true;
    const sub = this.auth
      .updateProfileInit('verification', this.otpForm.value)
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe(
        (res) => {
          this.isLoading = false;
          this.getProfileInit();
          this.toastService.success('OTP verified successfully');
          // this.getValidatorFnArray(res.data.pwdSettings);
          this.passwordRestForm.patchValue({
            token: res.data.token,
          });
        },
        (error) => {
          this.isLoading = false;
        }
      );
  }
  onRestPassword() {
    if (!this.passwordRestForm.valid) {
      this.submitted3 = true;
      return;
    }
    this.isLoading = true;

    const data = {
      password: this.passwordRestForm.get('confirmPassword').value,
    };
    const sub = this.auth
      .updateProfileInit('resetpassword', data)
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe(
        (res) => {
          this.isLoading = false;
          this.toastService.success('Password Updated successfully');
          this.auth.logout();
        },
        (error) => {
          this.isLoading = false;
          this.toastService.error((error.error.error.message).replace('Couldn\'t','Can\'t'), '', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        }
      );
  }
}
