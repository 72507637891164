import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CompanySetupService } from 'src/app/users/service/company-setup/company-setup.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { StorageService } from 'src/app/core/service/storage.service';
@Component({
  selector: 'app-my-page',
  templateUrl: './my-page.component.html',
  styleUrls: ['./my-page.component.scss']
})
export class MyPageComponent implements OnInit {
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  @ViewChild(NgSelectComponent) ngSelectComponent2: NgSelectComponent;
  compliments: string = ''
  complementsArray = []
  suggestionsArray = []
  suggestionId=[];
  getDataArr:any=null;
  suggestions = null;
  wallDropDowns: [];
  companyId: any;
  companySettingId:any=null;
  constructor(
    private tostService: ToastrService,
    private companyService: CompanySetupService,
    private storageService: StorageService
  ) {
    this.companyId = this.storageService.getByKey('companyId');
  }

  ngOnInit(): void {
    this.getDropDownValues()
    this.getData()
  }

  getData() {
    this.companyService.getcompanySetting()
      .subscribe((res) => {
        this.companySettingId=res.data._id;
        this.getDataArr=res.data
      this.setDropDownData();

        // this.suggestionsArray = res.data.suggestions;
        // this.complementsArray = res.data.compliments;
      },
        (error) => {
          console.log(error)
        });
  }

  setDropDownData(){
    if(this.getDataArr && this.getDataArr.suggestions?.length>0 && this.wallDropDowns?.length>0){
      this.suggestionsArray=this.wallDropDowns.filter((x:any)=>x._id ==this.getDataArr.suggestions[0])
    }
    if(this.getDataArr && this.getDataArr.compliments?.length>0 && this.wallDropDowns?.length>0){
      this.complementsArray=this.wallDropDowns.filter((x:any)=>x._id ==this.getDataArr.compliments[0])
    }
  }
  async addCompliments(value) {
    if (this.complementsArray.includes(value)) {
      this.tostService.error('Value already added')
      this.ngSelectComponent.clearModel();
    }
    else {
      if (typeof value === 'undefined') {
        // console.log('Undefined')
      }
      else {
        this.complementsArray=[];
        this.complementsArray.push(value)
        this.ngSelectComponent.clearModel();
      }
    }
  }

  async addSuggestions(value) {
    if (this.suggestionsArray.includes(value)) {
      this.tostService.error('Value already added')
      this.suggestions = []
      this.ngSelectComponent2.clearModel();
    }
    else {
      if (typeof value === 'undefined') {
        console.log('Undefined')
        this.suggestions = []
      }
      else {
        this.suggestionsArray=[];
        this.suggestionsArray.push(value)
        this.suggestions = []
        this.ngSelectComponent2.clearModel();
      }
    }
  }


  deleteCompliments(index) {
    this.complementsArray.splice(index, 1);
  }

  deleteSuggestionsArray(index) {
    this.suggestionsArray.splice(index, 1);
  }

  getDropDownValues() {
    this.companyService.getMyPageDropDowns().subscribe((res) => {
      this.wallDropDowns = res?.data || [];
      this.setDropDownData();
    });
  }

  updateCompanySettings() {

    let suggestionsString = this.suggestionsArray.map((item) => {
      return item._id
    })

    let complimentsString = this.complementsArray.map((item) => {
      return item._id
    })
    this.companyService.updateCompanySettings({ suggestions: suggestionsString, compliments: complimentsString, pageId:this.companySettingId })
      .subscribe((res) => {
        this.tostService.success('Updated SuccessFully');
      },
        (error) => {
          console.log(error)
        });
  }
}
