<div class="card mb-3">
  <div class="row" *ngIf="isPlan">
    <div class="col-md-12">
      <div class="form-group ml-3 mt-2">
        <label class="font-weight-500" for="">Select Week</label>
        <div class="d-flex" *ngIf="currWeek">
          <div
            class="weekbtn d-flex justify-content-center align-items-center rounded-left"
            (click)="changeWeek('Prev')"
            title="Previous Week"
          >
            <i class="material-icons"> chevron_left </i>
          </div>

          <div class="weekInput d-flex align-items-center">
            {{ currWeek }}
          </div>

          <div
            class="weekbtn d-flex justify-content-center align-items-center rounded-right"
            (click)="changeWeek('Nxt')"
            title="Next Week"
          >
            <i class="material-icons"> chevron_right </i>
          </div>
        </div>

        <ngx-skeleton-loader
          *ngIf="!currWeek"
          count="1"
          [theme]="{
            width: 'calc(20.4%)',
            'border-radius': '6px',
            height: '36px',
            margin: '10px',
            'margin-top': '0',
            'margin-left': '0',
            display: 'block'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
  </div>
  <div class="card-header bg-theme">
    <h5 class="m-0">PLANNED SHIFTS OVERVIEW</h5>
    <p class="m-0">Selected Business Unit : {{ bu.orgName }}</p>
  </div>
  <div class="card-body">
    <div class="col-md-12 flexDisplay">
      <div class="red">{{ isHour ? "Hours" : "Shifts" }}</div>
      <div class="floatButtons">
        <button
          type="button"
          class="btn"
          [ngClass]="isHour ? 'bg-theme' : 'btn-outline-info'"
          (click)="changeHrAndNumber(true)"
        >
          Total Shift Hrs
        </button>
        <button
          type="button"
          class="btn ml-2"
          [ngClass]="isHour ? 'btn-outline-info' : 'bg-theme'"
          (click)="changeHrAndNumber(false)"
        >
          Total Number of Shifts
        </button>
      </div>
    </div>

    <div
      class="d-flex align-items-baseline justify-content-between"
      *ngIf="graphLoader"
    >
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          'border-radius': '6px',
          height: '300px',
          width: '45px',
          margin: '10px',
          display: 'block'
        }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          'border-radius': '6px',
          height: '500px',
          width: '45px',
          margin: '10px',
          display: 'block'
        }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          'border-radius': '6px',
          height: '400px',
          width: '45px',
          margin: '10px',
          display: 'block'
        }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          'border-radius': '6px',
          height: '300px',
          width: '45px',
          margin: '10px',
          display: 'block'
        }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          'border-radius': '6px',
          height: '450px',
          width: '45px',
          margin: '10px',
          display: 'block'
        }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          'border-radius': '6px',
          height: '330px',
          width: '45px',
          margin: '10px',
          display: 'block'
        }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          'border-radius': '6px',
          height: '280px',
          width: '45px',
          margin: '10px',
          display: 'block'
        }"
      ></ngx-skeleton-loader>
    </div>

    <div class="hrAndShiftChart" *ngIf="!graphLoader">
      <canvas
        baseChart
        [chartType]="'bar'"
        [datasets]="hrAndShiftChartData"
        [labels]="hrAndShiftChartLabels"
        [options]="hrAndShiftChartoptions"
        [legend]="false"
        [colors]="hrAndShiftChartColors"
      >
      </canvas>
    </div>
  </div>
</div>

<div class="card">
  <div class="card-header flexi-header header">
    <h3>CHOOSE FOR DAY VIEW</h3>
  </div>
  <div class="card-body">
    <div class="col-md-12 mb-2 no-padding">
      <button
        *ngFor="let i of dayArr"
        class="dayButton"
        [class.bg-theme]="i === selectedDay"
        (click)="dayViewGraph(i)"
      >
        {{ i.d }}
      </button>
    </div>
    <div class="dayChart" *ngIf="graphLoader">
      <ngx-skeleton-loader
        count="5"
        [theme]="{
          'border-radius': '6px',
          height: '2px',
          margin: '100px 0',
          display: 'block'
        }"
      ></ngx-skeleton-loader>
    </div>
    <div class="dayChart" *ngIf="!graphLoader">
      <canvas
        baseChart
        [chartType]="'line'"
        [labels]="yAxesLabel"
        [datasets]="dayChartData"
        [options]="dayChartOptions"
        [colors]="dayChartColorOption"
        [legend]="true"
      >
      </canvas>
    </div>
  </div>
</div>
