<div
  #sidemenu
  id="sidemenu"
  class="p-3 d-none d-sm-block pt-5 sidemenu"
  *ngIf="!isNotificationEndabled"
>
  <aside>
    <label class="d-block mb-2 text-center">BUSINESS UNIT</label>
    <form action="" [formGroup]="viewNotification">
      <ng-select
        class="custom"
        bindLabel="businessUnit"
        placeholder="Bussiness unit (Plan)"
        appendTo="body"
        [multiple]="false"
        [searchable]="true"
        [closeOnSelect]="true"
        [clearable]="true"
        formControlName="bussinessUnit"
        [loading]="planBuUnitDropDownLoading"
        (search)="onSeachPlanBuUnit($event)"
        (scrollToEnd)="loadMorePlasnBuUnitItems($event)"
        (clear)="onClearPlanBuUnitDropdown($event)"
      >
        <ng-option [value]="item" *ngFor="let item of planBuUnitDropDown">
          {{ item.orgName }}
        </ng-option>
      </ng-select>
    </form>
    <div class="margin buttons">
      <button class="btn btn-clear p-2 m-1" (click)="onCloseDrawer()">Cancel</button>
      <button class="btn btn-primary p-2 m-1" (click)="onClickView()">
        View
      </button>
    </div>
  </aside>
</div>
<div *ngIf="isNotificationEndabled">
  <div class="d-flex flex-column">
    <div class="my-4">
      <ul ngbNav class="nav-tabs" [(activeId)]="active">
        <li
          [ngbNavItem]="1"
          (click)="changeOption('create');"
        >
          <a ngbNavLink>{{navBar}}</a>
        </li>
        <li
          [ngbNavItem]="2"
          (click)="changeOption('schedule');"
        >
          <a ngbNavLink>SCHEDULED</a>
        </li>
        <li [ngbNavItem]="3" (click)="changeOption('push');">
          <a ngbNavLink>PUSHED</a>
          <!-- </li>
        <li style="margin-left: 70%;">
      <button style="background-color: #a42138" class="btn pull-right btn-outline-light " (click)="overview()"><i class="fa fa-chevron-left" aria-hidden="true"></i> Back</button>
      </li> -->
        </li>
      </ul>

      <!-- <div class="mt-2"></div>  -->
    </div>
  </div>

  <div *ngIf="selectedOption === 'schedule'">
    <app-schedule
      [buId]="buId"
      (scheduleView)="viewSchedule($event)"
    ></app-schedule>
  </div>
  <div *ngIf="selectedOption === 'push'">
    <app-pushed
      [buId]="buId"
      (pushView)="viewSchedule($event)"
    ></app-pushed>
  </div>
  <div *ngIf="selectedOption === 'create'">
    <app-create
      [buId]="buId"
      [notiData] = "notiData"
      ($selectedOption)="changeOption($event, 'api')"
    ></app-create>
  </div>
</div>
