<div class="d-flex login-main flex-sm-row flex-column">
    <div class="flex-fill min-w50 d-sm-block d-none">
      <div class="mw-100 bg-banner"></div>
    </div>
    <div class="flex-fill bg">
      <div class="d-flex align-items-center h-100 min-h35">
        <div class="d-flex justify-content-center min-w100">
          <div class="login-body min-w50 card">
            <div class="forgot-password-header">
              <h3 class="text-center">Welcome  {{loggedInUserName}} </h3>
              <p>
                The last step is to complete your registration</p>
            </div>
            <div class="card-body">
              <form
                [formGroup]="forgetPasswordForm"
                (ngSubmit)="onRequestRestPassword()"
                *ngIf="restPasswordState == 'initRest'"
              >
                <div class="form-group" *ngIf="otpSentFor === 2">
                  <label for="exampleInputPassword1">Enter mobile number</label>
                  <ngx-intl-tel-input
                    [cssClass]="'custom form-control'"
                    [preferredCountries]="preferredCountries"
                    [ngClass]="{
                      'is-invalid': submitted1 && f.mobile.errors
                    }"
                    [enableAutoCountrySelect]="true"
                    [enablePlaceholder]="true"
                    [searchCountryFlag]="true"
                    [searchCountryField]="[
                      SearchCountryField.Iso2,
                      SearchCountryField.Name
                    ]"
                    [selectFirstCountry]="false"
                    [selectedCountryISO]="CountryISO.Singapore"
                    [maxLength]="15"
                    [phoneValidation]="true"
                    [separateDialCode]="separateDialCode"
                    [numberFormat]="PhoneNumberFormat.National"
                    formControlName="mobile"
                    customPlaceholder="Please Enter your mobile number"
                  >
                  </ngx-intl-tel-input>
                  <div class="error-container">
                    <span *ngIf="phone.invalid && phone.touched"
                      >Please Enter a Valid Phone number</span
                    >
                  </div>
                  <div
                    *ngIf="submitted1 && f.mobile.errors"
                    class="invalid-feedback mobile-error"
                  >
                    <small *ngIf="f.mobile.errors.required">
                      Please Enter Your Mobile Number
                    </small>
                  </div>
                </div>
                <div class="form-group">
                  <button
                    type="submit"
                    class="btn btn-sm btn-add float-right ml-2"
                    [disabled]="isLoadingOnRequestRestPassword"
                  >
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-spin fa-spinner': isLoadingOnRequestRestPassword,
                        '': !isLoadingOnRequestRestPassword
                      }"
                    ></i>
                    Submit
                  </button>
                  <button
                    type="submit"
                    (click)="navigateToLogin()"
                    class="btn btn-sm btn-red-back pl-3 pr-3 float-right"
                  >
                    Back
                  </button>
                </div>
              </form>
              <form
                [formGroup]="otpVerifyForm"
                *ngIf="restPasswordState == 'otpRest'"
              >
                <div class="form-group">
                  <label for="exampleInputEmail1">Please enter OTP sent to your mobile number</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="otp"
                    maxlength="6"
                    aria-describedby="emailHelp"
                    placeholder="Enter OTP"
                    [ngClass]="{ 'is-invalid': submitted2 && otp.otp.errors }"
                  />
                  <div
                    *ngIf="submitted2 && otp.otp.errors"
                    class="invalid-feedback"
                  >
                    <small *ngIf="otp.otp.errors.required">
                      Please enter OTP sent to your mobile number
                    </small>
                  </div>
                </div>
                <div class="form-group">
                  <button
                    type="submit"
                    (click)="onSubmitOtp()"
                    class="btn btn-sm btn-add float-right ml-3"
                    [disabled]="isLoadingOtpSubmit"
                  >
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-spin fa-spinner': isLoadingOtpSubmit,
                        '': !isLoadingOtpSubmit
                      }"
                    ></i
                    >Submit
                  </button>
                  <button
                    type="submit"
                    class="btn btn-sm btn-back float-right"
                    (click)="goBack()"
                  >
                    Back
                  </button>
                </div>
              </form>
              <form
                [formGroup]="passwordRestForm"
                (ngSubmit)="onRestPassword()"
                *ngIf="restPasswordState == 'passwordRest' && passwordRestForm"
              >
                <div class="form-group">
                  <label class="pb-2 form-bg" for="exampleInputEmail1"
                    >Password
                  </label>
                  <input
                    [type]="isPasswordVisible ? 'text' : 'password'"
                    class="form-control"
                    placeholder=" Password"
                    [ngClass]="{
                      'is-invalid':
                        submitted3 && passwordRestForm.get('password').errors
                    }"
                    formControlName="password"
                  />
                  <div class="eye-slash" *ngIf="!isPasswordVisible">
                    <i
                      class="fa fa-eye-slash fa-lg"
                      aria-hidden="true"
                      (click)="passwordVisible()"
                    ></i>
                  </div>
                  <div class="eye-slash" *ngIf="isPasswordVisible">
                    <i
                      class="fa fa-eye fa-lg"
                      aria-hidden="true"
                      (click)="passwordVisible()"
                    ></i>
                  </div>
                  <div
                    *ngIf="passwordRestForm.get('password').errors"
                    class="invalid-feedback"
                  >
                    <small
                      *ngIf="passwordRestForm.get('password').errors.required"
                    >
                    Please enter your Password, it's a mandatory field
                    </small>
                    <div
                      *ngIf="passwordRestForm.get('password').errors.hasNumber"
                    >
                      Atleast one numeric letter is required
                  </div>
                    <div
                      *ngIf="
                        passwordRestForm.get('password').errors
                          .hasSpecialCharacters
                      "
                    >
                      Atleast one special character is required
                </div>
                    <div
                      *ngIf="
                        passwordRestForm.get('password').errors.hasCapitalCase
                      "
                    >
                      Atleast one Capital character is required
              </div>
                    <div
                      *ngIf="passwordRestForm.get('password').errors.hasSmallCase"
                    >
                      Atleast one Small character is required
            </div>
                    <div
                      *ngIf="passwordRestForm.get('password').errors.minlength"
                    >
                      Minimum Length Should be {{passwordRestForm.get('password').errors.length}} Characters
          </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="pb-2 form-bg">Confirm Password </label>
                    <input
                      [type]="isPasswordVisibleAlt ? 'text' : 'password'"
                      class="form-control"
                      placeholder=" Confirm Password"
                      [ngClass]="{
                        'is-invalid':
                          submitted3 && passwordRestForm.get('confirmPassword').errors
                      }"
                      formControlName="confirmPassword"
                    />
                    <div class="eye-slash-alt" *ngIf="!isPasswordVisibleAlt">
                      <i
                        class="fa fa-eye-slash fa-lg"
                        aria-hidden="true"
                        (click)="confirmPasswordVisible()"
                      ></i>
                    </div>
                    <div class="eye-slash-alt" *ngIf="isPasswordVisibleAlt">
                      <i
                        class="fa fa-eye fa-lg"
                        aria-hidden="true"
                        (click)="confirmPasswordVisible()"
                      ></i>
                    </div>
                  <div
                    *ngIf="passwordRestForm.get('confirmPassword').errors"
                    class="invalid-feedback"
                  >
                      <small
                        *ngIf="
                        passwordRestForm.get('confirmPassword').errors.required
                        "
                      >
                        Confirm Password is required
                    </small>
                    <small
                      *ngIf="
                      passwordRestForm.get('confirmPassword').errors.mustMatch
                      "
                    >
                      Password doesn't Match
                  </small>
                </div>
                </div>
                <div class="form-group">
                  <button type="submit" class="btn btn-sm btn-add float-right" [disabled]="isLoadingResetPassword" >
                    <i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, '': !isLoadingResetPassword}"></i>
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
