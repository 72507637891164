<div class="d-flex flex-column">
  <ul ngbNav [(activeId)]="activeId" #companyNav="ngbNav" class="nav-tabs mb-3 rounded">
    <li [ngbNavItem]="1">
      <a ngbNavLink (click)="overViewEvent($event)">OVERVIEW</a>
      <ng-template ngbNavContent>
        <news-overview (EditEvent)="formEdit($event)"></news-overview>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink>{{ isBoardEdited ? "EDIT" : "CREATE" }}</a>
      <ng-template ngbNavContent>
        <app-create-edit-managenews
          [newsId]="boardId"
          (overViewEvent)="overViewEvent($event)"
        >
        </app-create-edit-managenews>
      </ng-template>
    </li>

    <li [ngbNavItem]="3">
      <a ngbNavLink>REPORTED POSTS</a>
      <ng-template ngbNavContent>
        <app-news-reported-posts></app-news-reported-posts>
      </ng-template>
    </li>
    <li [ngbNavItem]="4">
      <a ngbNavLink>REPORTED COMMENTS</a>
      <ng-template ngbNavContent>
        <app-news-reported-comments></app-news-reported-comments>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="companyNav"></div>
</div>
