<div class="card my-3 w-100" *ngIf="!onViewChannelData">
  <div class="card-header flexi-header p-4">
    <h3>ALL CHANNELS</h3>
  </div>
  <div class="card-body">
    <div class="card table-card">
      <div class="card-body flexi-body">
        <div class="flex-column">
          <div class="d-flex justify-content-between mt-2 mb-3">
            <select class="custom-select" style="width: auto" name="pageSize" (change)="onLimitChangeChannel()"
              [(ngModel)]="channelPTable.limit">
              <option [ngValue]="10">10</option>
              <option [ngValue]="20">20</option>
              <option [ngValue]="30">30</option>
              <option [ngValue]="40">40</option>
              <option [ngValue]="50">50</option>
            </select>
            <div class="form-group form-inline mb-0">
              <span *ngIf="channelPTable.isLoading">Please wait...</span>
              <input class="form-control ml-2" type="search" name="searchTerm" (search)="loadUsers()"
                (keyup)="channelsearchInput.next($event)" placeholder="Search here"
                [(ngModel)]="channelPTable.search" />
            </div>
          </div>
          <div class="table-responsive">
            <table class="table" *ngIf="!isTableLoading">
              <thead>
                <tr>
                  <th scope="col" sortable="Tier 1" (sort)="onSortChannel($event, 'name')">
                    GROUP NAME
                  </th>
                  <th scope="col" sortable="Tier 2" (sort)="onSortChannel($event, 'status')">
                    STATUS
                  </th>

                  <th scope="col">
                    ACTION
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of channelPTable.data">
                  <td>
                    {{ item.name }}
                  </td>

                  <td class="active-status">{{ item.status===1?'Active':'Inactive' }}</td>

                  <td>
                    <button class="btn btn-submit p-2 m-1 pl-3 pr-3" (click)="onViewChannel(item._id)">
                      View
                    </button>
                    <button class="btn btn-add p-2 m-1 pl-3 pr-3" (click)="onEditChannel(item._id)">
                      Edit
                    </button>

                    <button class="btn btn-clear p-2 m-1 pl-3 pr-3" (click)="openExportModal(item._id)">
                      Export
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <app-skliton-loading-table [numberOfCols]="3" [isTableLoading]="isTableLoading"></app-skliton-loading-table>
        </div>
      </div>
      <div class="card-footer">
        <div class="d-flex justify-content-between">
          <p>Total records {{ channelPTable.total }}</p>
          <ngb-pagination [collectionSize]="channelPTable.total" [(page)]="channelPTable.page" [maxSize]="5"
            (pageChange)="onPageChangeChannel($event)" [pageSize]="channelPTable.limit">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="onViewChannelData">
  <div class="card my-3 w-100">
    <div class="card-header flexi-header p-4">
      <h3>CHANNEL SETUP</h3>
      <button class="btn pull-right btn-outline-light" (click)="onViewChannelData = false">
        <i class="fa fa-angle-left fa-lg" aria-hidden="true"></i> BACK
      </button>
    </div>

    <div class="card-body">

      <div class="row">
        <div class="col-md-6 mt-1">
          <div class="form-group">
            <label class="pb-2 font-weight-bold" for="exampleInputEmail1">Channel Name</label>

            <input type="text" class="form-control" [value]="userDetails?.name" readonly />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="pb-2 font-weight-bold"> Category </label>
          <select [attr.disabled]="true" class="dropdown-select w-100 form-control">
            <option value="" [attr.selected]="true" disabled>Category</option>
          </select>
        </div>
        <div class="col-md-6">
          <div style="display: flex;flex-direction: row; flex-wrap: wrap; margin-top: 25px;">
            <div *ngFor="
                let field of userDetails?.category;;

              ">
              <span class="text-items list-ui">{{
                field.name }}
              </span>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="card my-3 w-100">
    <div class="card-header flexi-header p-4">
      <h3>SELECTED INFORMATION</h3>
    </div>

    <div class="card-body">
      <div class="table-responsive">
        <table class="table" *ngIf="!isTableLoading">
          <thead>
            <tr>
              <th>BUSINESS UNIT</th>
              <th>APPOINTMENTS</th>
              <th>SELECTED USERS</th>
              <th>SELECTED ADMINISTRATOR</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of userDetails?.assignUsers; let index = index">
              <td>
                <ul *ngIf="item.allBuToken == true">
                  <li>All Business Units Selected ({{item.allBuTokenStaffId}})</li>
                </ul>
                <ul *ngFor="let buUnit of item.businessUnits; let len = count">
                  <li *ngIf="item.allBuToken == false">{{buUnit.name}}</li>
                </ul>
              </td>

              <td>
                <ul *ngFor="let item of item.appointments">
                  <li>{{ item.name }}</li>
                </ul>
              </td>
              <td>
                <ul *ngFor="let item of item.user">
                  <li>{{ item.name }}</li>
                </ul>
              </td>
              <td>
                <ul *ngFor="let current of item.admin">
                  <li *ngIf="item.firstAdminAddedAsDefault == true">
                    <span *ngIf="current.staffId !== 'admin001'">{{current.name}}</span> 
                    </li>
                  <li *ngIf="item?.firstAdminAddedAsDefault == false || !item?.firstAdminAddedAsDefault"> {{current.name}}</li>
                </ul>
                <!--<ul *ngFor="let item of item.admin">
                  <li>{{ item.name }}</li>
                </ul>-->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <app-skliton-loading-table [numberOfCols]="5" [isTableLoading]="isTableLoading"></app-skliton-loading-table>
    </div>
  </div>
</div>

<ng-template #AddSection>
  <div>
    <div class="modal-content">
      <div class="modal-body" style="min-height: auto">
        <div class="card my-3" style="margin: 0 auto">
          <div class="card-header flexi-header p-4">
            <h3>EXPORT CHANNEL</h3>
            <button type="button" class="btn close-button" aria-label="Close" (click)="closeModal()">
              <i class="fa fa-times-circle fa-2x" aria-hidden="true"></i>
            </button>
          </div>

          <div>
            <div class="card table-card">
              <form [formGroup]="exportsSetupForm">
                <div class="manage-filter">
                  <div class="form-group w-100 p-3">
                    <div class="form-group">
                      <p class="criteria-source mt-3">Select Report</p>
                      <select class="form-control" formControlName="type">
                        <option value="readership">Readership</option>
                        <option value="comments">Comments</option>
                        <option value="like">Likes</option>
                      </select>
                    </div>
                    <div class="form-group date-container">
                      <div>
                        <label class="pb-2 font-weight-bold" for="fromDate">From Date</label>
                        <div class="input-group">
                          <input class="form-control" placeholder="yyyy-mm-dd" style="max-width: 240px;" name="dp"
                          (dateSelect)="onStartDateSelect($event)" ngbDatepicker #d="ngbDatepicker">
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                              <i class="fa fa-calendar" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div>
                        <label for="startDate" class="pb-2 font-weight-bold">To Date</label>
                        <div class="input-group">
                          <input class="form-control" placeholder="yyyy-mm-dd" style="max-width: 240px;" name="dp"
                          (dateSelect)="onEndDateSelect($event)" ngbDatepicker #c="ngbDatepicker">
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="c.toggle()" type="button">
                              <i class="fa fa-calendar" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button class="btn btn-success" (click)="onExportItem()">Export</button>
                  </div>
                </div>
              </form>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</ng-template>
