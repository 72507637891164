import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { LeavePlannerService } from 'src/app/users/service/human-resources/leave-planner.service';

@Component({
  selector: 'view-ballot',
  templateUrl: './view-ballot.component.html',
  styleUrls: ['./view-ballot.component.scss'],
})
export class ViewBallotComponent implements OnInit {
  @Input() ballot;
  @Output() back = new EventEmitter<Number>();
  slotWeeks: any[];
  slot = [];
  allSlotArr = [];
  daySlot: boolean;
  autoBallotStaff: any;
  constructor(private leaveService: LeavePlannerService) {}

  ngOnInit(): void {

    this.getSlotWeeks();
    this.getSlotCreation();
    if (this.ballot.isAutoAssign) {
      this.getAutoAssignStaff(this.ballot.parentBallot);
    }
    this.calculateTotalBalanace();
  }

  calculateTotalBalanace(){
    if(this.ballot?.slotCreation?.length){
      this.ballot.totUN=0;
      this.ballot.TotBQ=0;
      this.ballot.assignRatio=0;
      this.ballot.slotCreation?.forEach(item=>{
        this.ballot.totUN += item.totalUnassignedIs;
        this.ballot.TotBQ += item.totalBallotBalance;
      });
      this.ballot.assignRatio= (+this.ballot.totUN /  +this.ballot.TotBQ)?.toFixed(2);
    }
  }

  getSlotCreation() {
    this.ballot.slotCreation.forEach((v, slotCreationIndex) => {
      let team = {};
      team['opsGrp'] = v.opsGroup.value;
      team['team'] = 'Tier 1';
      team['totalBallotBalance'] = v.totalBallotBalance;
      team['totalUnassignedIs'] = v.totalUnassignedIs;
      const slotGrpVal = [];

      for (let prop in v.weekRangeSlot) {
        if (prop.includes('A')) {
          slotGrpVal.push(v.weekRangeSlot[prop]);
        }
      }

      // if (this.ballot.isAutoAssign) {
      //   team['BallotBalance'] = v.opsGroup.BallotBalance;
      //   team['unassignBalanace'] = v.opsGroup.unassignBalanace;
      // }

      team['slots'] = slotGrpVal;
      this.allSlotArr.push(team);
      let ballotSlot;
      ballotSlot = this.ballot.slotCreation[slotCreationIndex].weekRangeSlot;
      let keyy = Object.keys(ballotSlot);
      let slotSortArr = [];
      keyy.forEach((weekSlotKey) => {
        slotSortArr.push([weekSlotKey, ballotSlot[weekSlotKey]]);
      });
      v.opsTeam?.forEach((opsTeam, opsTeamIndex) => {
        let team2 = {};
        team2['opsGrp'] = '';
        team2['team'] = opsTeam.name;



        let slotTeam = [];
        let pTeamm = [];
        // console.log(this.slotWeeks);

        this.slotWeeks?.map((vs, slotsIndex) => {

          let matchKeyValue = slotSortArr?.find((slotValue) => {


            if (
              slotValue[0] ==
              'OG' + slotsIndex + 'OT' + opsTeamIndex.toString()
            ) {
              return true;
            }
          });

          if(matchKeyValue){
            pTeamm.push(matchKeyValue);
          }


        });

        pTeamm?.map((sv) =>{
         if(sv){
            slotTeam.push(sv[1]);
          }

        })

        // if (!this.ballot?.isAutoAssign) {
        //   pTeamm.forEach((sv) => slotTeam.push(sv[1]));
        // } else {
        //   pTeamm.forEach((sv) => slotTeam.push(sv[1]));
        // }
        let sum = 0;
        for (const item of slotTeam) {
            sum += item.value;
        }
        team2['slots'] = slotTeam;
        team2['totalBallotBalance'] = sum;
        team2['totalUnassignedIs'] = opsTeam?.unassignBalanace;
        this.allSlotArr.push(team2);
      });
    });
  }

  backbtn() {
    this.back.emit(1);
  }

  getSlotWeeks() {
    this.slotWeeks = [];

    if (this.ballot.leaveType == '1') {
      const ballotStart = moment(this.ballot.ballotStartDate).startOf(
        'isoWeek'
      );
      const ballotEnd = moment(this.ballot.ballotEndDate).endOf('isoWeek');
      const weekdiff = ballotEnd.diff(ballotStart, 'weeks');

      for (var i = 0; i <= weekdiff; i++) {
        this.slotWeeks.push([
          moment(ballotStart).add(i, 'week'),
          moment(ballotStart).add(i, 'week').endOf('isoWeek'),
        ]);
      }
    } else {
      let ballotStartDate = moment(this.ballot.ballotStartDate);
      let ballotEndDate = moment(this.ballot.ballotEndDate);
      const daydiff = ballotEndDate.diff(ballotStartDate, 'days');
      for (let i = 0; i <= daydiff; i++) {
        this.slotWeeks.push([moment(ballotStartDate).add(i, 'days')]);
      }
    }
  }

  getAutoAssignStaff(parentBallotId) {
    this.leaveService.getAutoAssignStaff(parentBallotId).subscribe((res) => {
      this.autoBallotStaff = res;
    });
  }
}
