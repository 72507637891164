<div class="row">
  <div class="col-md-6 col-sm-6">
    <div class="card mb-3 w-100">
      <div class="card-header flexi-header p-4">
        <h3>NOTIFICATION</h3>
      </div>
      <div class="card-body">
        <div class="card table-card">
          <div class="card-body flexi-body">
            <div class="flex-column">
              <div class="d-flex justify-content-between mt-2 mb-3">
                <select
                  class="custom-select"
                  style="width: auto"
                  name="pageSize"
                  (change)="onLimitChangeUsers()"
                  [(ngModel)]="usersPTable.limit"
                >
                  <option [ngValue]="10">10</option>
                  <option [ngValue]="20">20</option>
                  <option [ngValue]="30">30</option>
                  <option [ngValue]="40">40</option>
                  <option [ngValue]="50">50</option>
                </select>
                <div class="form-group form-inline mb-0">
                  <span *ngIf="usersPTable.isLoading">Please wait...</span>
                  <input
                    class="form-control ml-2"
                    type="search"
                    name="searchTerm"
                    (keyup)="userssearchInput.next($event)"
                    placeholder="Search here"
                    [(ngModel)]="usersPTable.search"
                  />
                </div>
              </div>
              <div class="table-responsive">
                <table class="table" *ngIf="!isTableLoading">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        sortable="Tier 1"
                        (sort)="onSortUsers($event, 'title')"
                      >
                        TITLE
                      </th>

                      <th scope="col"
                      sortable="Tier 2"
                      (sort)="onSortUsers($event, 'activeFrom')">DATE & TIME</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let item of usersPTable.data; let index = index"
                      (click)="onSelectNotification(item, index)"
                      [ngClass]="
                        selectedIndex === index
                          ? 'active-item'
                          : 'inactive-item'
                      "
                    >
                      <td>
                        <b>
                          {{ item.title | uppercase }}
                        </b>
                        <p>
                          {{ item.subTitle }}
                        </p>
                      </td>

                      <td>{{ item.activeFrom | date: "dd/MM/yyyy HH:mm" }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <app-skliton-loading-table [numberOfCols]="2" [isTableLoading]="isTableLoading"></app-skliton-loading-table>
            </div>
          </div>
          <div class="card-footer">
            <div class="d-flex justify-content-between">
              <p>Total Records {{ usersPTable.total }}</p>
              <ngb-pagination
                [collectionSize]="usersPTable.total"
                [(page)]="usersPTable.page"
                [maxSize]="5"
                (pageChange)="onPageChangeUsers($event)"
                [pageSize]="usersPTable.limit"
              >
              </ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 col-sm-6" *ngIf="notificationViewData; else elseBlock">
    <div class="card mb-3 w-100">
      <div class="card-header flexi-header">
        <h3>NOTIFICATION DETAIL</h3>
      </div>

      <div class="card-body">
        <div class="panel-body">
          <div class="row m-0">
            <div class="col-md-12">
              <p>
                <small>
                  {{ notificationViewData?.activeTo | date: "short" }}
                </small>
              </p>
              <p>
                <strong>
                  {{ notificationViewData?.title }}
                </strong>
                <small class="d-block">
                  {{ notificationViewData?.subTitle }}
                </small>
              </p>
              <p class="ng-binding">
                {{ notificationViewData?.description }}
              </p>
              <hr />
            </div>
          </div>
          <div class="row m-0">
            <div class="col-md-6">
              <div class="form-group">
                <label>Effective From</label>
                <div class="form-text-conteol ng-binding">
                  {{ notificationViewData?.effectiveFrom | date}}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Effective Till</label>
                <div class="form-text-conteol ng-binding">
                  {{ notificationViewData?.effectiveTo | date}}
                </div>
              </div>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-md-6">
              <div class="form-group">
                <label>Main Category</label>
                <div class="form-text-conteol ng-binding">
                  {{ notificationViewData?.subCategory?.categoryId?.name }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Sub Category </label>
                <div class="form-text-conteol ng-binding">
                  {{ notificationViewData?.subCategory?.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #elseBlock>
    <div class="col-md-6 col-sm-6">
      <div class="card my-3 w-100">
        <div class="card-header flexi-header p-4">
          <h3>NOTIFICATION DETAIL</h3>
        </div>

        <div class="card-body">
          <div class="panel-body">
            <h6>No Data Availabale</h6>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
