import { Component, Input, OnInit } from "@angular/core";
import { NgSelectModule, NgOption } from "@ng-select/ng-select";

@Component({
  selector: "app-plan-shift-header",
  templateUrl: "./plan-shift-header.component.html",
  styleUrls: ["./plan-shift-header.component.scss"],
})
export class PlanShiftHeaderComponent implements OnInit {
  @Input() data;
  constructor() {}

  ngOnInit(): void {
    if (this.data != null) {
      console.log("header Data", this.data);
    }
  }
}
