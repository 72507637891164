import {
  Component,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription, of } from 'rxjs';
import {
  map,
  debounceTime,
  distinctUntilChanged,
  mergeMap,
  delay,
  finalize,
  pairwise,
  startWith,
} from 'rxjs/operators';
import {
  SortableHeader,
  SortEvent,
} from 'src/app/shared/directive/sortable.directive';
import { PaginatedTable } from 'src/app/core/model/pagination.model';
import { StorageService } from 'src/app/core/service/storage.service';

import { BussinessUnitService } from '../../../service/bussiness-unit/bussiness-unit.service';
import { NgbModal, NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { deleteAlert } from 'src/app/shared/sweet-alert/sweet-alert';
import { tableParam } from 'src/app/core/model/table-param.model';
import { TableService } from 'src/app/core/service/table.service';

@Component({
  selector: 'app-bussiness-unit',
  templateUrl: './bussiness-unit.component.html',
  styleUrls: ['./bussiness-unit.component.scss'],
})
export class BussinessUnitComponent implements OnInit {
  createBussinessUnitForm: FormGroup;
  createDepartmentForm: FormGroup;
  createSectionForm: FormGroup;
  createSubsectionForm: FormGroup;
  customMessage: string = '';
  companyId="";
  public BussinessunitsearchInput = new Subject<KeyboardEvent>();
  public DepartmentsearchInput = new Subject<KeyboardEvent>();
  public SectionsearchInput = new Subject<KeyboardEvent>();
  public SubSectionsearchInput = new Subject<KeyboardEvent>();
  activeId: number = 1;
  companyName: string;
  showDropDown: boolean;
  tableParams=tableParam;
  tableCol={
    deptCols:['sno','companyId.name','name',""]
  }
  private DepartmentsearchSubscription: Subscription;
  private SectionsearchSubscription: Subscription;
  private SubsectionsearchSubscription: Subscription;
  departmentId: string;
  isDepartmentEdited: boolean = false;
  editedDepartmentID: string;
  sectionId: string;
  isSectionEdited: boolean = false;
  editedSectionID: string;
  isSubSectionEdited: boolean = false;
  editedSubSectionID: string;
  departmentDropDown = [];
  sectionDropDown = [];
  successMessage = '';
  errorMessage = '';
  loader:boolean = false;
  subSectionDropDown = [];
  deparmentDropDownLoading: boolean = false;
  sectionDropDownLoading: boolean = false;
  departmentLoading: boolean = false
  sectionLoading: boolean = false
  subsectionLoading: boolean = false
  subSectionPTable: { sortWith: string; sortBy: string; data: any[]; page: number; total: number; limit: number; search: string; };
  sectionPTable: { sortWith: string; sortBy: string; data: any[]; page: number; total: number; limit: number; search: string; };
  departmentPTable: { sortWith: string; sortBy: string; data: any[]; page: number; total: number; limit: number; search: string; };



  /// department popup table configuration
  onSortDepartment({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }

    });
    this.departmentPTable.sortBy = direction;
    this.departmentPTable.sortWith = column;
    direction && this.loaddepartment();

  }

  onPageChangeDepartment(page: number) {
    this.departmentPTable.page = page;
    this.tableParams.start=this.departmentPTable.data?.length;

    this.loaddepartment();
  }
  onLimitChangeDepartment() {
    this.tableParams.start=0;
    this.loaddepartment();
  }

  // section popup table configuration
  onSortSection({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.sectionPTable.sortBy = direction;
    this.sectionPTable.sortWith = column;
    direction && this.loadSection(this.departmentId);

  }

  onPageChangeSection(page: number) {
    this.sectionPTable.page = page;
    this.loadSection(this.departmentId);
  }
  onLimitChangeSection() {
    this.loadSection(this.departmentId);
  }

  /// subsection popup table configuration
  onSortSubSection({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.subSectionPTable.sortBy = direction;
    this.subSectionPTable.sortWith = column;
    direction && this.loadSubSection(this.sectionId);

  }

  onPageChangeSubSection(page: number) {
    this.subSectionPTable.page = page;
    this.loadSubSection(this.sectionId);
  }
  onLimitChangeSubSection() {
    this.loadSubSection(this.sectionId);
  }
  @ViewChild('AddDepartment', { static: true })
  AddDepartment: TemplateRef<any>;
  @ViewChild('AddSection', { static: true })
  AddSection: TemplateRef<any>;
  @ViewChild('AddSubSection', { static: true })
  AddSubSection: TemplateRef<any>;
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;

  constructor(
    public modelService: NgbModal,
    private buUnit: BussinessUnitService,
    public toastService: ToastrService,
    private storageService: StorageService,
    private formBuilder: FormBuilder,
    public tableService:TableService
  ) {
    this.companyId = localStorage.getItem("flexicompanyId");
    this.departmentPTable = {
      sortWith: 'createdAt',
      sortBy: 'desc',
      data: [],
      page: 1,
      total: 0,
      limit: 10,
      search: '',
    };
    this.sectionPTable = {
      sortWith: 'createdAt',
      sortBy: 'desc',
      data: [],
      page: 1,
      total: 0,
      limit: 10,
      search: '',
    };
    this.subSectionPTable = {
      sortWith: 'createdAt',
      sortBy: 'desc',
      data: [],
      page: 1,
      total: 0,
      limit: 10,
      search: '',
    };

    this.companyName = this.storageService.getByKey('companyName');
    // this.clearFields();

    this.DepartmentsearchSubscription = this.DepartmentsearchInput.pipe(
      map((event) => {
        const target = event.target as HTMLTextAreaElement;
        return target.value;
      }),
      debounceTime(500),
      distinctUntilChanged(),
      mergeMap((search) => of(search).pipe(delay(500)))
    ).subscribe((input) => {
      this.departmentPTable.page = 1;
      this.loaddepartment();
    });
    this.SectionsearchSubscription = this.SectionsearchInput.pipe(
      map((event) => {
        const target = event.target as HTMLTextAreaElement;
        return target.value;
      }),
      debounceTime(500),
      distinctUntilChanged(),
      mergeMap((search) => of(search).pipe(delay(500)))
    ).subscribe((input) => {
      this.sectionPTable.page = 1;
      this.loadSection(this.departmentId);
    });
    this.SubsectionsearchSubscription = this.SubSectionsearchInput.pipe(
      map((event) => {
        const target = event.target as HTMLTextAreaElement;
        return target.value;
      }),
      debounceTime(500),
      distinctUntilChanged(),
      mergeMap((search) => of(search).pipe(delay(500)))
    ).subscribe((input) => {
      this.subSectionPTable.page = 1;
      this.loadSubSection(this.sectionId);
    });
  }

  ngOnInit(): void {
    const { data, total, ...rest } = this.departmentPTable;
    this.tableParams.draw=0;
    this.loaddepartment(rest);
    this.initForm();
    this.getSection();
    this.getSubsection();




  }

  onClearDepartmentDropdown(value) {
    if (value === undefined) {
      this.createBussinessUnitForm.patchValue({
        sectionName: null,
      });
      this.departmentPTable.search = '';
      this.departmentId = null;
      this.tableParams.search.value = '';
      this.loaddepartment();
      this.subSectionDropDown = [];
      this.sectionDropDown = [];
      this.sectionPTable.data = [];
      this.subSectionPTable.data = [];
    }

  }
  onClearSectionDropdown(value) {


    if (value === undefined) {
      this.createBussinessUnitForm.patchValue({
        subSectionName: null,
      });
      this.sectionPTable.search = '';
      this.loadSection(this.departmentId);
      this.subSectionDropDown = [];
      this.sectionDropDown = [];
      this.subSectionPTable.data = [];
    }
  }

  getSection() {
    this.createBussinessUnitForm
      .get('departmentName')
      .valueChanges.pipe(startWith(null), pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        if (next != null) {
          this.createBussinessUnitForm.patchValue({
            sectionName: null,
          });
          const { _id } = next;
          this.sectionDropDownLoading = true;
          this.subSectionDropDown = [];
          this.departmentId = _id;
          // this.createBussinessUnitForm.patchValue({
          //   sectionName: next,
          // });
          this.loadSection(this.departmentId);
        }
      });
  }
  getSubsection() {
    this.createBussinessUnitForm
      .get('sectionName')
      .valueChanges.pipe(startWith(null), pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        if (next != null) {
          const { _id } = next;
          this.subSectionDropDown = [];
          this.createDepartmentForm.patchValue({
            subSectionName: '',
          });


          this.sectionId = _id;
          this.loadSubSection(this.sectionId);

        }
      });
  }

  initForm() {
    this.createBussinessUnitForm = this.formBuilder.group({
      companyName: this.companyName,
      departmentName: [null, [Validators.required]],
      sectionName: [null, [Validators.required]],
      subSectionName: [null, [Validators.required]],
    });
    this.departmentInit()
    this.sectionInit()
    this.subSectionInit()

  }

  departmentInit() {
    this.createDepartmentForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9_]+.*$')]],
      status: 1,
      companyId:[localStorage.getItem('flexicompanyId') || '']
    });
  }

  sectionInit() {
    this.createSectionForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9_]+.*$')]],
      status: 1,
    });
  }

  subSectionInit() {
    this.createSubsectionForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9_]+.*$')]],
      status: 1,
    });
  }

  get f() {
    return this.createBussinessUnitForm.controls;
  }

  // createBussinessUnit() {
  //   const {
  //     companyName,
  //     departmentName,
  //     sectionName,
  //     subSectionName,
  //   } = this.createBussinessUnitForm.value;
  //   const titile = 'Create Bussiness Unit';
  //   const text = `${companyName} > ${departmentName} > ${sectionName} > ${subSectionName}`;
  //   const buttonText = 'Create';
  //   updateAlert(titile, text, buttonText).then((result) => {
  //     if (result.value) {
  //
  //     }
  //   });
  // }

  // createDepartment() {
  //   if (!this.createDepartmentForm.valid) {
  //     return;
  //   }

  //   this.buUnit
  //     .createDepartment(this.createDepartmentForm.value)
  //     .subscribe((res) => {
  //       this.createDepartmentForm.patchValue({
  //         name: '',
  //       });
  //
  //       this.loaddepartment();
  //     });
  // }
  loaddepartment(value?: any) {
        this.deparmentDropDownLoading = true;
    const { data, total, ...rest } = this.departmentPTable;
    let tableCol:any = this.tableService.initializeTableParamsColums(this.tableCol.deptCols, [0,1,2,3],[0,1,2]) || [];
    this.tableParams.columns=tableCol;
    this.tableParams.draw++;
    this.tableParams.start=this.departmentPTable.limit - 10;
    this.tableParams.length=this.departmentPTable.limit;
    //this.tableParams.search.value = value ? value : ''
    // this.tableParams=this.buUnit.tableParams;

    const subscribe = this.buUnit
      .getDepartments(this.tableParams)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const { recordsTotal, departments } = response;
          this.departmentPTable.data = [ ...departments];
          this.departmentDropDown = [...departments];
          this.deparmentDropDownLoading = false;
          this.departmentPTable.total = recordsTotal;
        },
        (error) => {
          this.deparmentDropDownLoading = false;
          this.departmentPTable.data = [];
          this.departmentPTable.total = 0;
          // this.toastService.error('No list found');
        }
      );
  }

  loadSection(departmentId) {
    this.sectionDropDownLoading = true;
    // const { data, total, ...rest } = this.sectionPTable
    const payload = { "departmentId": departmentId }
    const subscribe = this.buUnit
      .getSection(payload)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          // const { count, data } = response.data;
          this.sectionPTable.data = response?.data?.sections || [];
          this.sectionDropDown = response?.data?.sections || [];
          this.sectionDropDownLoading = false;
          this.sectionPTable.total = response?.data?.sections?.length || 0;
        },
        (error) => {
          this.sectionDropDownLoading = false;

          this.sectionPTable.data = [];
          this.sectionPTable.total = 0;
          // this.toastService.error('No list found');
        }
      );
  }

  loadSubSection(sectionId) {
    const { data, total, ...rest } = this.subSectionPTable
    const payload = { sectionId: sectionId }
    const subscribe = this.buUnit
      .getSubsection(payload)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          // const { count, data } = response.data;
          this.subSectionPTable.data = response?.data?.subSections || [];
          this.subSectionDropDown = response?.data?.subSections || [];
          this.subSectionPTable.total = response?.data?.subSections?.length || 0;
        },
        (error) => {
          this.subSectionPTable.data = [];
          this.subSectionPTable.total = 0;
          // this.toastService.error('No list found');
        }
      );
  }

  ///  bu unit deparment drop down and popup of department section and subsectionb

  onSeachDepartment(value) {
    this.departmentPTable.limit = 10;
    this.tableParams.search.value = value.term;

    this.departmentPTable.page = 1;
    const { data, total, ...rest } = this.departmentPTable

    this.loaddepartment(rest);
  }
  onSeachSection(value) {

    this.sectionPTable.limit = 10;
    this.sectionPTable.search = value.term;
    this.sectionPTable.page = 1;
  }

  loadMoreDepartmentItems(value) {
    this.departmentPTable.limit = this.departmentPTable.limit + 10;
    this.departmentPTable.page = this.departmentPTable.page + 1;
    const { data, total, ...rest } = this.departmentPTable
    this.loaddepartment(rest);
  }

  loadMoreSectionItems(value) {
    this.sectionPTable.limit = this.sectionPTable.limit + 10;
    this.loadSection(this.sectionPTable);
  }

  DepartmentCreate() {
    if (!this.createDepartmentForm.valid) {
      return;
    }
    this.departmentLoading = true
    if (!this.isDepartmentEdited) {
        this.buUnit
        .createDepartment(this.createDepartmentForm.value)
        .subscribe(() => {
          this.departmentInit()
          this.departmentPTable.search = '';
          this.loaddepartment();
          this.departmentLoading = false
          this.toastService.success('Department Created Successfully', '', {
            timeOut: 3000,
            positionClass: 'toast-top-left',
          });
        }, (error) => {
          this.departmentLoading = false;
          this.toastService.error(error?.error?.message);
        });
    } else {
      const payload={
        "departmentId":this.editedDepartmentID,
        "name":this.createDepartmentForm.get('name').value,
        "status":this.createDepartmentForm.get('status').value,
        "companyId":this.createDepartmentForm.get('companyId').value
      }
      this.buUnit
        .updateDepartment(
          payload
        )
        .subscribe((res) => {
          this.isDepartmentEdited = false;
          this.editedDepartmentID = null;
          this.departmentInit()
          this.departmentPTable.search = '';
          this.loaddepartment();
          this.departmentLoading = false;
          this.toastService.success('Department Updated Successfully', '', {
            timeOut: 3000,
            positionClass: 'toast-top-left',
          });

        }, (error) => {
          this.departmentLoading = false;
          this.toastService.error(error?.error?.message);
        });
    }
  }

  closeModal() {
    this.modelService.dismissAll()
    this.departmentPTable.search = ''
    this.sectionPTable.search = ''
    this.subSectionPTable.search = ''
  }
  onEditDepartment(department) {
    this.isDepartmentEdited = true;
    this.editedDepartmentID = department._id;
    this.createDepartmentForm.patchValue({
      name: department.name,
      status: department.status,
    });
  }

  onDeleteDepartment(id) {

    deleteAlert().then((result) => {
      if (result.value) {
       const payload= {"departmentId":id}
        this.buUnit.deleteDepartment(payload).subscribe((res) => {
          this.loaddepartment()
          this.toastService.success('Department Deleted Successfully', '', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        });
      }
    });

  }
  SectionCreate() {
    if (!this.createSectionForm.valid) {
      return;
    }
    this.sectionLoading = true
    if (!this.isSectionEdited) {
      const payload = {
        name: this.createSectionForm.get('name').value,
        departmentId: this.departmentId,
        status: this.createSectionForm.get('status').value
      }
      this.buUnit
        .createSection(payload)
        .subscribe(() => {
          this.sectionInit()
          this.sectionPTable.search = ''
          this.loadSection(this.departmentId);
          this.sectionLoading = false;
          this.toastService.success('Section Successfully Added', '', {
            timeOut: 3000,
            positionClass: 'toast-top-left',
          });
        },
          (error) => {
            this.toastService.error(error?.error?.message);
          }
        );
    } else {
      const payload = {
        sectionId: this.editedSectionID,
        name: this.createSectionForm.get('name').value,
        status: this.createSectionForm.get('status').value
      }
      this.buUnit
        .updateSection(payload)
        .subscribe(
          (res) => {
            this.isSectionEdited = false;
            this.editedSectionID = null;
            this.sectionPTable.search = ''
            this.sectionInit()
            this.loadSection(this.departmentId);
            this.sectionLoading = false;
            this.toastService.success('Section Updated Successfully', '', {
              timeOut: 3000,
              positionClass: 'toast-top-left',
            });
          },
          (error) => {
            this.sectionLoading = false
            this.toastService.error(error?.error?.message);
          }
        );
    }
  }

  editSection(section) {
    this.isSectionEdited = true;
    this.editedSectionID = section._id;
    this.createSectionForm.patchValue({
      name: section.name,
      status: section.status,
    });
  }

  onDeleteSection(id) {
    deleteAlert().then((result) => {
      if (result.value) {
        const payload = {
          sectionId: id
        }
        this.buUnit.deleteSection(payload).subscribe((res) => {
          this.loadSection(this.departmentId)
          this.toastService.success('Section Deleted Successfully', '', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        })
      }
    });

  }
  bussinessunitCreate() {
    if (!this.createSubsectionForm.valid) {
      return;
    }
    this.subsectionLoading = true;
    this.loader = true;
    if (!this.isSubSectionEdited) {
      const payload = {
        name: this.createSubsectionForm.get('name').value,
        sectionId: this.sectionId,
        status: this.createSubsectionForm.get('status').value
      }
      this.buUnit
        .createSubsection(payload)
        .subscribe(
          (res) => {
            this.ngOnInit();
            this.modelService.dismissAll();
            this.departmentDropDown = [];
            this.sectionDropDown = [];
            this.subSectionDropDown = [];
            this.toastService.success('Business unit Created Successfully', '', {
              timeOut: 3000,
              positionClass: 'toast-top-left',
            });
            this.activeId = 1;
            this.subsectionLoading = false;
            this.loader = false;
          },
          (error) => {
            this.subsectionLoading = false;
            this.loader = false;
            this.toastService.error(error?.error?.message);

          }
        );
    } else {
      const payload =
      {
        name: this.createSubsectionForm.get('name').value,
        subSectionId: this.editedSubSectionID,
        sectionId: this.sectionId
      }
      this.buUnit
        .updateSubsection(payload)
        .subscribe(
          (res) => {
            this.subSectionInit()
            this.isSubSectionEdited = false;
            this.loader = false;
            this.editedSubSectionID = null;

            this.loadSubSection(this.sectionId)
            this.toastService.success('Business unit Updated Successfully', '', {
              timeOut: 3000,
              positionClass: 'toast-top-left',
            });
            this.subsectionLoading = false

          },
          (error) => {
            this.subsectionLoading = false;
             this.loader = false;
            this.toastService.error(error?.error?.message);

          }
        );
    }
  }

  editSubSection(Subsection) {
    this.isSubSectionEdited = true;
    this.editedSubSectionID = Subsection._id;
    this.createSubsectionForm.patchValue({
      name: Subsection.name,
      status: Subsection.status,
    });
  }

  onDeleteSubSection(id) {
    deleteAlert().then((result) => {
      if (result.value) {
        const payload = {
          subSectionId: id
        }
        this.buUnit.deleteSubsection(payload).subscribe((res) => {
          this.loadSubSection(this.sectionId)
          this.toastService.success('Subsection Deleted Successfully', '', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        })
      }
    });

  }

  addDepartment() {
    this.departmentPTable.limit = 10;

    this.loaddepartment();
    this.modelService.open(this.AddDepartment, {
      windowClass: 'modal-ui-fix right-align',
    }).result.then((result) => {
      this.departmentPTable.limit = 10;
      this.departmentPTable.page = 1;
      this.loaddepartment();

    }, (reason) => {
      this.departmentPTable.limit = 10;
      this.departmentPTable.page = 1;
      this.loaddepartment();

    });
  }
  addSection() {
    this.sectionPTable.limit = 10;

    this.modelService.open(this.AddSection, {
      windowClass: 'modal-ui-fix right-align',
    }).result.then((result) => {
      this.subSectionPTable.limit = 10;
      this.subSectionPTable.page = 1;

    }, (reason) => {
      this.subSectionPTable.limit = 10;
      this.subSectionPTable.page = 1;
    });
  }

  addSubSection() {
    this.subSectionPTable.limit = 10;
    this.modelService.open(this.AddSubSection, {
      windowClass: 'modal-ui-fix right-align',
    }).result.then((result) => {
      this.subSectionPTable.limit = 10;
      this.subSectionPTable.page = 1;

    }, (reason) => {
      this.subSectionPTable.limit = 10;
      this.subSectionPTable.page = 1;
    });
  }

  ngOnDestroy(): void {
    this.DepartmentsearchSubscription &&
      this.DepartmentsearchSubscription.unsubscribe();
    this.SectionsearchSubscription &&
      this.SectionsearchSubscription.unsubscribe();
    this.SubsectionsearchSubscription &&
      this.SubsectionsearchSubscription.unsubscribe();
  }
}
