import { HttpHeaders, HttpClient,HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StorageService } from 'src/app/core/service/storage.service';
import { queryParam } from 'src/app/core/utils/helper';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CustomFormService {
  private baseUrl: string;
  private get Headers(): HttpHeaders {
    return new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }
  private get Header(): HttpHeaders {
    return new HttpHeaders({
      Accept: 'text/csv',
    });
  }
  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {
    this.baseUrl = environment.baseUrl;
  }
  getAllForms(companyId,data): Observable<any> {
    return this.http.get(`${this.baseUrl}/customform/readFormsList/${companyId}?${queryParam(data)}`, {
      headers: this.Headers,
    }).pipe(map((res) => res as any))
  }
  getWorkFlowLogs(data,workFlowID): Observable<any> {
    return this.http.get(`${this.baseUrl}/customform/readManageFormLog/${workFlowID}?${queryParam(data)}`, {
      headers: this.Headers,
    }).pipe(map((res) => res as any))
  }
  getAllMyForms(data): Observable<any> {
    return this.http.get(`${this.baseUrl}/customForm/myForms?${queryParam(data)}`, {
      headers: this.Headers,
    }).pipe(map((res) => res as any))
  }
  getAllFormLogs(data): Observable<any> {
    return this.http.get(`${this.baseUrl}/customForm/readManageFormLog?${queryParam(data)}`, {
      headers: this.Headers,
    }).pipe(map((res) => res as any))
  }
  getAllManageFormLogs(data): Observable<any> {
    return this.http.get(`${this.baseUrl}/custom-form/readManageFormLog?${queryParam(data)}`, {
      headers: this.Headers,
    }).pipe(map((res) => res as any))
  }

  updateForm(data, id: string): Observable<any> {
    return this.http
      .put(`${this.baseUrl}/custom-form/${id}`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }
  createForm(data): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/customForm/createCustomForm`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }
  exportForm(data): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('timeZone', '-330');
    return this.http
      .post(`${this.baseUrl}/customForm/exportFormCsv`, data, {
        headers: this.Headers,
        params: queryParams,
      })
      .pipe(map((res) => res as any));
  }
  exportWorkFlowPdf(data): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/customForm/exportCustomForm`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  downloadCustomFormPdf(url): Observable<any> {
    const httpOptions = {
      responseType: ('blob' as 'json')
    };
    return this.http
      .get(`${this.baseUrl}${url}`, {
        headers: this.Headers,
        observe: 'body', responseType: 'blob' as 'json'
      })
      .pipe(map((res) => res as any));
  }

  getOneForm(id: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/custom-form/${id}`)
    .pipe(map((res) => res as any))
  }
  getFormSettings(id: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/customform/getFormSettings/${id}`)
    .pipe(map((res) => res as any))
  }
  setFormSettings(data): Observable<any> {
    return this.http
    .post(`${this.baseUrl}/customform/setFormSettings`, data, {
      headers: this.Headers,
    })
    .pipe(map((res) => res as any));
  }
  updateWorkflowStatus(data): Observable<any> {
    return this.http
    .post(`${this.baseUrl}/customForm/updateWorkflowStatus`, data, {
      headers: this.Headers,
    })
    .pipe(map((res) => res as any));
  }
  getOneFormManage(data,payload): Observable<any> {
    return this.http.post(`${this.baseUrl}/customform/readManageforms/${data.customFormId}?${queryParam(data)}` ,payload)
    .pipe(map((res) => res as any))
  }

  submitAnswers(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/custom-form/readManageforms/` ,data)
    .pipe(map((res) => res as any))
  }
  getManageFormUser(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/customForm/getManageFormUsers` ,data)
    .pipe(map((res) => res as any))
  }
  getExportCSV(data): Observable<any> {
    return this.http.get(`${this.baseUrl}${data}`, {responseType: 'text'})
    .pipe(map((res) => res as any))
  }
  updateFormStatus(data): Observable<any> {
    return this.http.put(`${this.baseUrl}/customForm/updateForm`, data)
    .pipe(map((res) => res as any))
  }
  uploadFiles(data) {
    return this.http.post(`${this.baseUrl}/external/uploadFiles` ,data)
    .pipe(map((res) => res as any))
  }

  exportManageForm(data){
    return this.http.post(`${this.baseUrl}/customForm/exportManageForms?timeZone=-330` ,data)
    .pipe(map((res) => res as any))
  }
}
