import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subject, Subscription, of } from 'rxjs';
import {
  map,
  debounceTime,
  distinctUntilChanged,
  mergeMap,
  delay,
  finalize,
} from 'rxjs/operators';
import {
  SortableHeader,
  SortEvent,
} from 'src/app/shared/directive/sortable.directive';
import { StorageService } from 'src/app/core/service/storage.service';

import { deleteAlert } from 'src/app/shared/sweet-alert/sweet-alert';
import { CentralBuilderService } from 'src/app/users/service/central-builder/central-builder.service';
import { UsersService } from 'src/app/users/service/user-service/users.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-question-builder-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit {
  isTableLoading: boolean = true;
  public isModuleView: boolean = false
  public modulesearchInput = new Subject<KeyboardEvent>();
  selectedModule
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  moduleTable: { sortWith: string; sortBy: string; data: any[]; page: number; limit: number; search: any; total: number; isLoading: boolean; start: number;order:any };

  onSortModule({ column, direction }: SortEvent, field,colNum:number) {
    if (direction) {
      this.headers.forEach((header) => {
        if (header.sortable !== column) {
          header.direction = '';
        }
      });
     this.moduleTable.order[0].column=colNum;
      this.moduleTable.order[0].dir=direction;
      this.loadModules();
    }
    // resetting other headers
  }

  onPageChangeModule(page: number) {
    this.moduleTable.page = page;
    this.moduleTable.start = +page > 1 ? (page - 1) * this.moduleTable.limit : 0;
    this.loadModules();
  }
  onLimitChangeModule() {
    this.loadModules();
  }

  private moduleSearchSubscription: Subscription;

  @Output() overViewEvent = new EventEmitter<string>();
  @Output() editModuleEvent = new EventEmitter<string>();

  constructor(
    private centralBuilderService: CentralBuilderService,
    public toastService: ToastrService,
    private storageService: StorageService,
    private formBuilder: FormBuilder
  ) {
    this.moduleTable = {
      sortWith: 'createdAt',
      sortBy: 'desc',
      data: [],
      page: 1,
      limit: 10,
      search:{
        value:''
      },
      total: 0,
      start: 0,
      isLoading: true,
      order:[{
        column:1,
        dir:'desc'
      }]
    };

    this.moduleSearchSubscription = this.modulesearchInput
      .pipe(
        map((event) => {
          const target = event.target as HTMLTextAreaElement;
          return target.value;
        }),
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap((search) => of(search).pipe(delay(500)))
      )
      .subscribe((input) => {
        this.moduleTable.page = 1;
        this.moduleTable.start = 0;
        this.moduleTable.search.value = input;
        this.loadModules();
      });
  }

  ngOnInit(): void {
    this.loadModules();
    this.onOverViewEvent(null);
  }

  loadModules() {
    const { data, total, isLoading, ...moduleTbale } = this.moduleTable
    const subscribe = this.centralBuilderService
      .getAllModules(moduleTbale)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const { recordsTotal, data } = response;
          this.moduleTable.data = data;
          this.moduleTable.total = recordsTotal;
          this.moduleTable.isLoading = false
          this.isTableLoading = false;
        },
        (error) => {
          this.moduleTable.data = [];
          this.moduleTable.total = 0;
          this.toastService.error('No list found');
        }
      );
  }

  getQuestions(moduleId: string) {
    this.centralBuilderService
      .getSingleQuestions(moduleId)
      .subscribe((res) => {
        this.isModuleView = true
        this.selectedModule = res.data.data;

      });
  }
  moduleDelete(id: string) {
    deleteAlert().then((resut) => {
      if (resut.value) {
        this.centralBuilderService.deleteModule(id).subscribe((res) => {
          this.toastService.success('Module Deleted Successfully');
          this.loadModules()
        })
      }
    })

  }

  onOverViewEvent(value: string) {
    this.overViewEvent.emit(value);
  }
  onEditModule(id: string) {

    this.editModuleEvent.emit(id)
  }
  ngOnDestroy(): void {
    this.moduleSearchSubscription &&
      this.moduleSearchSubscription.unsubscribe();
  }

}
