<div class="card">
  <div class="card-header flexi-header">
    <h3>Log Details</h3>
  </div>
  <div class="card-body table-responsive p-0">
    <ngx-skeleton-loader
      *ngIf="shiftSchemeLoader"
      count="6"
      [theme]="{
        width: 'calc(100% - 20px)',
        'border-radius': '6px',
        height: '44px',
        margin: '10px',
        display: 'block'
      }"
    ></ngx-skeleton-loader>
    <table class="table table-striped" *ngIf="!shiftSchemeLoader">
      <thead>
        <tr>
          <th scope="col" sortable="Tier 1">DATE</th>
          <th scope="col" sortable="Tier 2">TIME</th>
          <th scope="col" sortable="Tier 3">SCHEME NAME</th>

          <th scope="col" sortable="Tier 4">ASSIGNED TO ME(USER NAME)</th>
          <th scope="col" sortable="Status">DESCRIPTION</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let i of schemeLogTable">
          <td>{{ i.createdAt | date: "MM/dd/yyyy" }}</td>
          <td>{{ i.createdAt | date: "HH:mm" }}</td>
          <td>{{ i.newSchemeId?.schemeName }}</td>
          <td>{{ i.userId?.name }}</td>
          <td>
            {{ i.oldSchemeId?.schemeName }} Changed to
            {{ i.newSchemeId?.schemeName }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
