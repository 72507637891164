import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SetupService } from 'src/app/users/service/operations/setup.service';
import { ShiftSchemeEditComponent } from './shift-scheme-edit/shift-scheme-edit.component';

@Component({
  selector: 'app-shift-scheme',
  templateUrl: './shift-scheme.component.html',
  styleUrls: ['./shift-scheme.component.scss'],
})
export class ShiftSchemeComponent implements OnInit {
  @Output() BuShiftId = new EventEmitter<any>();
  @ViewChild('sidemenu') sidemenu: ElementRef;
  buShiftSelectedID: any;
  schemeRecieveData: any;
  schemeView: any;
  buLoader: boolean = true;
  isView: boolean;
  activeId = 1;
  isEdit: boolean;
  isCreate: boolean = true;
  buParameters: {
    sortWith: string;
    sortBy: string;
    data: any[];
    page: number;
    limit: number;
    search: string;
  };

  constructor(private buService: SetupService, private renderer: Renderer2) {
    this.buParameters = {
      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,
      limit: 10,
      search: '',
    };
  }

  ngOnInit(): void {
    this.viewClicked();
  }

  ngAfterViewInit() {
    if (!this.isView) {
      setTimeout(() => {
        this.renderer.addClass(this.sidemenu.nativeElement, 'animate');
      }, 50);
    }
  }

  changeActiveTab($event) {
    this.activeId = $event;
    this.schemeView = null;
    this.schemeRecieveData = null;
  }

  activeViewTab($event) {
    this.activeId = 2;
    this.isCreate = false;
    this.schemeRecieveData = $event;
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    if (changeEvent.nextId === 1) {
      this.schemeView = null;
      this.schemeRecieveData = null;
    }
  }

  viewOrEdit($event) {
    console.log('opertion', $event);
    if ($event == 'view') {
      this.schemeView = 'view';
    } else {
      this.schemeView = 'edit';
    }
  }

  viewClicked() {
    //this.BuShiftId.emit(i);
    //this.buShiftSelectedID = i;
    //console.log('View Bu', i);
    this.isView = true;
  }
}
