<div class="card-with-border mt-3">
    <div class="card-header flexi-header p-4 flex-justify-sb">
        <h3>MY NEWS AND EVENTS</h3>
    </div>
    <div *ngIf="newsDetailsData === []">
        <h3 class="text-center p-3">No Data Available</h3>
    </div>
    <div class="row p-3">
        <div class="col-4" *ngIf="newsDetailsData !== []">
            <div class="card my-3 w-100">
                <div class="card-header flexi-header p-4 flex-justify-sb">
                    <h3 style="display: inline-block;">ALL CHANNELS</h3>
                    <div class="form-group form-inline mb-0">
                      <input class="form-control ml-2" type="search" name="searchTerm" (search)="onLoadNewsandEvents()"
                      (keyup)="newsAndEventssearchInput.next($event)" placeholder="Search here"
                      [(ngModel)]="newsAndEventsPTable.search" />
                    </div>
                </div>
                <div class="card-body">
                    <div *ngFor="let newsData of newsAndEventsPTable.data; let index = index"
                        [ngClass]="selectedIndex === index ? 'card-selected' : ''" class="card-content p-0"
                        (click)="getCardData(newsData, index)">
                        <div class="newsList p-0">
                          <div class="small_img" *ngIf="newsData.teaser.listingType == 2">
                            <div class="img">
                                <img [src]="validateURLImage(getBannerImage(newsData.teaser.image))" class="img-responsive" crossorigin="anonymous" alt="" />
                            </div>
                            <div class="content">
                                <p class="categoryName">
                                   {{newsData.categoryId.name}}
                                </p>
                                <p class="title" [innerHTML]="newsData.content.title | safeHtml"></p>
                                <p class="eventDate"  *ngIf="newsData.postType == 'event'">
                                       <i class="fa fa-calendar"></i> {{newsData.eventDetails.startDate | date}}  to {{newsData.eventDetails.endDate | date}}
                                  </p>
                                <p class="text-muted channelName">
                                  {{newsData.channelId.name}}
                                </p>
                            </div>
                        </div>
                        <div class="big_img" *ngIf="newsData.teaser.listingType == 3">
                          <div class="img">
                              <img [src]="validateURLImage(getBannerImage(newsData.teaser.image))" class="img-responsive" crossorigin="anonymous" alt="" />
                              <div class="content">
                                  <p class="categoryName">
                                      {{newsData.categoryId.name}}
                                  </p>
                                  <p class="title" [innerHTML]="newsData.content.title | safeHtml"></p>
                                  <p class="eventDate"  *ngIf="newsData.postType == 'event'">
                                          <i class="fa fa-calendar"></i> {{newsData.eventDetails.startDate | date}}  to {{newsData.eventDetails.endDate | date}}
                                   </p>
                                  <p class="text-muted channelName">
                                        {{newsData.channelId.name}}
   </p>
                              </div>
                          </div>
                      </div>
                      <div class="no_img" *ngIf="newsData.teaser.listingType == 1">
                        <div class="content">
                            <p class="categoryName">
                                {{newsData.categoryId.name}}
                            </p>
                            <p class="title" [innerHTML]="newsData.content.title | safeHtml"></p>
                            <p class="eventDate" *ngIf="newsData.postType == 'event'">

                                    <i class="fa fa-calendar"></i> {{newsData.eventDetails.startDate | date}}  to {{newsData.eventDetails.endDate | date}}
                            </p>
                            <p class="text-muted channelName">
                                {{newsData.channelId.name}}
                            </p>
                        </div>
                    </div>
                        </div>

                    </div>
                    <div class="card-footer">
                        <div class="d-flex justify-content-between">
                            <p>{{ newsAndEventsPTable.total }} Records</p>
                            <ngb-pagination [collectionSize]="newsAndEventsPTable.total" (pageChange)="onPageChange($event)"
                                [(page)]="newsAndEventsPTable.page" [maxSize]="1" [pageSize]="newsAndEventsPTable.limit">
                            </ngb-pagination>
                        </div>
                    </div>
                    <div *ngIf="newsAndEventsPTable.data.length <= 0">
                      <h3 class="text-center p-3">No Data Available</h3>
                  </div>
                </div>
            </div>
        </div>
        <div class="col-8" *ngIf="newsAndEventsPTable.data !== []">
            <div class="card my-3 w-100">
                <div class="card-header flexi-header p-4">
                    <h3 style="display: inline-block;">{{type | uppercase}} DETAILS</h3>
                    <button class="btn pull-right btn-outline-light" (click)="addSection()"><i class="fa fa-filter"
                            aria-hidden="true"></i> Filter</button>
                </div>
                <div class="card-body" *ngIf="newsAndEventsPTable.data.length !== 0">
                    <img class="main-image" [src]="validateURLImage(imageLink)"  crossorigin="anonymous"/>
                    <div class="flex-justify-sb" *ngIf="newsAndEventsPTable.data.length !== 0">
                        <p class="text-capital">{{channelName}} / {{categoryName}}</p>
                        <p>Authored By</p>
                    </div>
                    <div class="flex-justify-sb stick-to-top" *ngIf="newsAndEventsPTable.data.length !== 0">
                        <p>{{updatedAt | date}}</p>
                        <p class="font-weight-bold">{{authorName}}</p>
                    </div>

                    <div class="p-3">
                        <h4>{{organizerName}}</h4>
                        <h5>{{organizerAddress}}</h5>
                    </div>
                    <div>
                        <h4 [innerHTML]="title | safeHtml" class="font-weight-bold heading"></h4>
                        <p [innerHTML]="eventContent | safeHtml"></p>
                      </div>
                </div>
                <div class="col-8"  *ngIf="newsAndEventsPTable.data.length <= 0">
                    <h3 class="text-center p-3">No Data Available</h3>
                </div>
            </div>
        </div>
    </div>

    <ng-template #AddSection let-c="close" let-d="dismiss">
        <div>
            <div class="modal-content">
                <div class="modal-body" style="min-height: auto">
                    <div class="card my-3" style="margin: 0 auto">
                        <div class="card-header flexi-header text-center p-4">
                            <h3>MY NEWS AND EVENTS</h3>
                            <button type="button" class="btn btn-large close-icon" aria-label="Close"
                                (click)="cancelPopup()">
                                <i class="fa fa-times-circle fa-2x" aria-hidden="true"></i>
                            </button>
                        </div>

                            <div class="card-body">
                                <div class="row mb-2">
                                    <div class="col-md-12">
                                        <label> CHANNELS</label>
                                        <ng-select (change)="getCategories($event)" [items]="channelDropDown"
                                            bindLabel="name" bindValue="_id" [searchable]="true" placeholder="Channel"
                                            [(ngModel)]="newsAndEventsPTable.channelId" >
                                        </ng-select>
                                    </div>
                                </div>

                                <div class="row mb-2">
                                    <div class="col-md-12">
                                        <label> CATEGORY</label>
                                        <ng-select  [items]="categoriesDropDown"
                                            bindLabel="name" bindValue="_id" [searchable]="true" placeholder="Category"
                                             [(ngModel)]="newsAndEventsPTable.categoryId">
                                        </ng-select>
                                    </div>
                                </div>
                            </div>

                            <div class="card-footer">
                                <button (click)="onLoadNewsandEvents(); cancelPopup();" type="submit" class="btn btn-submit btn-block"
                                    >Submit</button>
                            </div>

                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>
