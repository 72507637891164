import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { SetupService } from 'src/app/users/service/operations/setup.service';
import { ShiftSchedulerService } from 'src/app/users/service/operations/shift-scheduler.service';
import { getGmtOffSet } from 'src/app/core/utils/helper';

@Component({
  selector: 'app-open-shift',
  templateUrl: './open-shift.component.html',
  styleUrls: ['./open-shift.component.scss'],
})
export class OpenShiftComponent implements OnInit {
  isSplitShift: boolean = false;
  @Input() bu: any;
  @Input() tier;
  @Output() changeTab = new EventEmitter<any>();
  @Output() weekStart = new EventEmitter<any>();
  time = [];
  buSkillSet = [];
  buSkillspeciality: any;
  buRepoLocation: any;
  planOpenshiftForm: FormGroup;
  geoReportinglocation: any;
  week: any;
  openPlanshift: {};
  skillsSet = [];
  skillLabels = [];
  skillSetArry = [];
  skillTableLabels = [];
  skillTableArry = [];
  timeSlotTable = [];
  weekArr = [];
  myWeekRange = [];
  addFirstDate: Date;
  addLastDate: Date;
  locationlabel: any;
  dayDate = [];
  mockDayDate = [];
  shiftsArr = [];
  shiftDay = [];
  radius: any;
  isCheckInEnabled: any;
  isProximityEnabled: any;
  delete = false;
  deleteShiftTemplate = false;

  @ViewChild('btn1', { static: true })
  btn1: ElementRef;
  @ViewChild('btn2', { static: true })
  btn2: ElementRef;
  @ViewChild('btn3', { static: true })
  btn3: ElementRef;
  @ViewChild('btn4', { static: true })
  btn4: ElementRef;
  @ViewChild('btn5', { static: true })
  btn5: ElementRef;
  @ViewChild('btn6', { static: true })
  btn6: ElementRef;
  @ViewChild('btn7', { static: true })
  btn7: ElementRef;

  shiftTemplate: any;
  shiftTemplateArr = [];
  template: {};
  selectShiftTemp: any;
  showShift: boolean;
  showTemplate:boolean;
  ShiftTemplateTable: any;
  temDay: number;
  preTemDay: number;
  shiftTempArr = [];
  skillSetArry2 = [];
  skillLabels2 = [];
  weekArr2: any;
  weekArr1 = [];
  reportLocation: any;
  isSplit: boolean = false;
  splitArr: any[];
  chkday: any[];
  timeSlot = [];
  selectedWeek: any;
  timeSlotArr: any[];
  ShiftTempSt: Date;
  ShiftTempEt: Date;
  weekStartDate: moment.Moment;
  weekEndDate: moment.Moment;
  disableDayArr = [];
  shiftUniqueID:any;
  reportingLocationRadius = [
    { name: 50, id: 50 },
    { name: 100, id: 100 },
    { name: 150, id: 150 },
    { name: 200, id: 200 },
    { name: 250, id: 250 },
    { name: 300, id: 300 },
    { name: 350, id: 350 },
    { name: 400, id: 400 },
    { name: 450, id: 450 },
    { name: 500, id: 500 },
  ];
  locations: any;
  buShiftDetails: any;
  loaderShiftCreate: boolean = false;
  deleteShiftIndex:number=null;
  gmtOffset: any;
  constructor(
    private buService: SetupService,
    private planService: ShiftSchedulerService,
    protected formBuilder: FormBuilder,
    private el: ElementRef,
    public toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.gmtOffset = getGmtOffSet();
    this.deleteShiftIndex=null;
    this.planService.getPageSettingSkill().subscribe((res)=>{
      this.tier = res['tierType'];
    })
    this.getBuShift(this.bu);
    this.initForm();
    this.planService.getWeeks(this.bu._id).subscribe((res) => {
      this.weekArr2 = res['data'];
      let getDate = moment().startOf('isoWeek').format("MM-DD-YYYY");
      if (this.weekArr2.noOfWeek > 0) {
        for (var i = -this.weekArr2.noOfWeek; i <= this.weekArr2.noOfWeek; i++) {
          let dummyData:any = this.getWeekStartEndDate(getDate, 7 * (i))
         const res =  moment().diff(dummyData.lastDate,'days')
         if(res === 0 || res < 0){
            this.weekArr1.push(dummyData);
          }
         }
        this.planOpenshiftForm.patchValue({
            selectWeek: this.weekArr1[0]?.label,
          });
        this.selectedWeek=this.weekArr1[0]?.label;
      }
      this.getStartEndDate();
      this.templateDisplay();
    });

    this.emptyDayArr();
    this.selectTime();
  }
  getBuShift(bu) {
    this.buService.getBuShiftEdit(bu._id).subscribe((res: { result }) => {
      this.buShiftDetails = res['data']['businessUnitList'];
    });
  }

  getWeekStartEndDate = function (date, count) {
    let datesToReturn = {};
    let label = moment().startOf('isoWeek').add(count, 'days').format("MMM DD") + ' to ' + moment().startOf('isoWeek').add(count + 6, 'days').format("MMM DD")
    let value = moment().startOf('isoWeek').add(count, 'days').format("MM-DD-YYYY");

    datesToReturn = { label: label, value: value, key: count, lastDate: moment().startOf('isoWeek').add(count+6, 'days').format("MM-DD-YYYY") };
   /* if (count == 0 && $scope.workWeeksDate == undefined) {

      $scope.workWeeksDate = value;

    }*/
    return datesToReturn;
  }
  getStartEndDate() {
    const d = this.planOpenshiftForm.controls.selectWeek.value;
    this.selectedWeek=d;
    this.weekStartDate = moment()
      .date(d?.slice(4, 6))
      .month(d?.slice(0, 3))
      .hour(0);
    this.weekEndDate = moment(this.weekStartDate).isoWeekday(7);
    this.disableDayArr = [];
    for (var i = 1; i < 7; i++) {
      const days = this.weekStartDate.isoWeekday(i);
      if (moment().isAfter(days)) {
        this.disableDayArr.push(this.weekStartDate.format('ddd'));
      }
    }
     this.disableDayArr.pop();


  }

  selectTime() {
    this.time = [];
    for (let hour = 0; hour < 24; hour++) {
      this.time.push(moment({ hour }).format('HH:mm'));
      this.time.push(moment({ hour, minute: 15 }).format('HH:mm'));
      this.time.push(moment({ hour, minute: 30 }).format('HH:mm'));
      this.time.push(moment({ hour, minute: 45 }).format('HH:mm'));
    }
  }

  getSelectedWeek1() {
    var date = this.changeToDate(
      this.planOpenshiftForm.controls.selectWeek.value,
      0
    );

    if (this.btn1.nativeElement.classList.contains('selected')) {
      this.dayDate.push({
        date: this.dateFormat(date),
        day: 'Monday',
        startTime: '',
        endTime: '',
        splitStartTime:'',
        splitEndTime:'',
        isSplitShift:false,

      });
    } else {
      var index = this.dayDate.findIndex((x) => x.day === 'Monday');
      for (var i = 0; i < this.dayDate.length; i++) {
        if (this.dayDate[i].day.indexOf('Monday') != -1) {
          this.dayDate = this.removeDay(this.dayDate, index);
        }
      }
    }

  }

  getSelectedWeek2() {
    var date = this.changeToDate(
      this.planOpenshiftForm.controls.selectWeek.value,
      0
    );

    if (this.btn2.nativeElement.classList.contains('selected')) {
      this.dayDate.push({
        date: this.dateFormat(new Date(date.setDate(date.getDate() + 1))),
        day: 'Tuesday',
        startTime: '',
        endTime: '',
        splitStartTime:'',
        splitEndTime:'',
        isSplitShift:false,

      });
    } else {
      var index = this.dayDate.findIndex((x) => x.day === 'Tuesday');
      for (var i = 0; i < this.dayDate.length; i++) {
        if (this.dayDate[i].day.indexOf('Tuesday') != -1) {
          this.dayDate = this.removeDay(this.dayDate, index);
        }
      }
    }

    var date = this.changeToDate(
      this.planOpenshiftForm.controls.selectWeek.value,
      0
    );
  }

  getSelectedWeek3() {
    var date = this.changeToDate(
      this.planOpenshiftForm.controls.selectWeek.value,
      0
    );

    if (this.btn3.nativeElement.classList.contains('selected')) {
      this.dayDate.push({
        date: this.dateFormat(new Date(date.setDate(date.getDate() + 2))),
        day: 'Wednesday',
        startTime: '',
        endTime: '',
        splitStartTime:'',
        splitEndTime:'',
        isSplitShift:false,

      });
    } else {
      var index = this.dayDate.findIndex((x) => x.day === 'Wednesday');

      for (var i = 0; i < this.dayDate.length; i++) {
        if (this.dayDate[i].day.indexOf('Wednesday') != -1) {
          this.dayDate = this.removeDay(this.dayDate, index);
        }
      }
    }

    var date = this.changeToDate(
      this.planOpenshiftForm.controls.selectWeek.value,
      0
    );
  }

  getSelectedWeek4() {
    var date = this.changeToDate(
      this.planOpenshiftForm.controls.selectWeek.value,
      0
    );
    if (this.btn4.nativeElement.classList.contains('selected')) {
      this.dayDate.push({
        date: this.dateFormat(new Date(date.setDate(date.getDate() + 3))),
        day: 'Thursday',
        startTime: '',
        endTime: '',
        splitStartTime:'',
        splitEndTime:'',
        isSplitShift:false,

      });
    } else {
      var index = this.dayDate.findIndex((x) => x.day === 'Thursday');

      for (var i = 0; i < this.dayDate.length; i++) {
        if (this.dayDate[i].day.indexOf('Thursday') != -1) {
          this.dayDate = this.removeDay(this.dayDate, index);
        }
      }
    }
    var date = this.changeToDate(
      this.planOpenshiftForm.controls.selectWeek.value,
      0
    );
  }

  getSelectedWeek5() {
    var date = this.changeToDate(
      this.planOpenshiftForm.controls.selectWeek.value,
      0
    );
    if (this.btn5.nativeElement.classList.contains('selected')) {
      this.dayDate.push({
        date: this.dateFormat(new Date(date.setDate(date.getDate() + 4))),
        day: 'Friday',
        startTime: '',
        endTime: '',
        splitStartTime:'',
        splitEndTime:'',
        isSplitShift:false,

      });
    } else {
      var index = this.dayDate.findIndex((x) => x.day === 'Friday');

      for (var i = 0; i < this.dayDate.length; i++) {
        if (this.dayDate[i].day.indexOf('Friday') != -1) {
          this.dayDate = this.removeDay(this.dayDate, index);
        }
      }
    }
    var date = this.changeToDate(
      this.planOpenshiftForm.controls.selectWeek.value,
      0
    );
  }

  getSelectedWeek6() {
    var date = this.changeToDate(
      this.planOpenshiftForm.controls.selectWeek.value,
      0
    );
    if (this.btn6.nativeElement.classList.contains('selected')) {
      this.dayDate.push({
        date: this.dateFormat(new Date(date.setDate(date.getDate() + 5))),
        day: 'Saturday',
        startTime: '',
        endTime: '',
        splitStartTime:'',
        splitEndTime:'',
        isSplitShift:false,

      });
    } else {
      var index = this.dayDate.findIndex((x) => x.day === 'Saturday');

      for (var i = 0; i < this.dayDate.length; i++) {
        if (this.dayDate[i].day.indexOf('Saturday') != -1) {
          this.dayDate = this.removeDay(this.dayDate, index);
        }
      }
    }
    var date = this.changeToDate(
      this.planOpenshiftForm.controls.selectWeek.value,
      0
    );
  }

  getSelectedWeek7() {
    var date = this.changeToDate(
      this.planOpenshiftForm.controls.selectWeek.value,
      0
    );
    if (this.btn7.nativeElement.classList.contains('selected')) {
      this.dayDate.push({
        date: this.dateFormat(new Date(date.setDate(date.getDate() + 6))),
        day: 'Sunday',
        startTime: '',
        endTime: '',
        splitStartTime:'',
        splitEndTime:'',
        isSplitShift:false,
      });
    } else {
      var index = this.dayDate.findIndex((x) => x.day === 'Sunday');

      for (var i = 0; i < this.dayDate.length; i++) {
        if (this.dayDate[i].day.indexOf('Sunday') != -1) {
          this.dayDate = this.removeDay(this.dayDate, index);
        }
      }
    }
    var date = this.changeToDate(
      this.planOpenshiftForm.controls.selectWeek.value,
      0
    );
  }

  removeDay(a, index) {
    let newDayDate = [...a];
    newDayDate.splice(index, 1);
    return newDayDate;
  }

  dateFormat(d) {
    var dd = d.getDate().toString();
    var mm = (d.getMonth() + 1).toString();
    var yy = d.getFullYear().toString();

    var date =
      mm.padStart(2, 0) +
      '-' +
      dd.padStart(2, 0) +
      '-' +
      yy +
      ` 00:00:00 ${this.gmtOffset}`;
    return date;
  }

  addStartTime() {
    for (var i = 0; i < this.dayDate.length; i++) {
      var d = this.dayDate[i].date.toString();
      var t = this.planOpenshiftForm.controls.setTimingStart.value + ':00';
      var tstr = t.toString();
      d = d.replace('00:00:00', tstr);
      var tz = moment().format('[GMT]ZZ');
      d = d.replace(this.gmtOffset, tz);
      this.dayDate[i].startTime = d;
    }
  }

  areTimingsOnOtherDay(startTime, endTime) {
    const startDateTime = startTime.split(':');
    const endDateTime = endTime.split(':');

    const startHour = parseInt(startDateTime[0]);
    const endHour = parseInt(endDateTime[0]);

    return startHour > endHour
  }


  addEndTime() {
    for (var i = 0; i < this.dayDate.length; i++) {
      const nextDay = moment(this.dayDate[i].date).add(1, 'days').format('MM-DD-YYYY');

      var d = this.dayDate[i].date.toString();
      var t = this.planOpenshiftForm.controls.setTimingEnd.value + ':00';
      var tstr = t.toString();
      d = d.replace('00:00:00', tstr);
      var tz = moment().format('[GMT]Z');
      d = d.replace(this.gmtOffset, tz);
      this.dayDate[i].endTime = d;
      if(this.areTimingsOnOtherDay(moment(this.dayDate[i].startTime).format("HH:mm"), moment(this.dayDate[i].endTime).format("HH:mm")))
      {
       this.dayDate[i].endTime = `${nextDay} ${tstr} ${tz}`;

      }
    }
  }

  addSplitStartTime() {
    for (var i = 0; i < this.dayDate.length; i++) {
      const nextDay = moment(this.dayDate[i].date).add(1, 'days').format('MM-DD-YYYY');
      var d = this.dayDate[i].date.toString();
      var t = this.planOpenshiftForm.controls.splitShiftStart.value + ':00';
      var tstr = t.toString();
      d = d.replace('00:00:00', tstr);
      var tz = moment().format('[GMT]ZZ');
      d = d.replace(this.gmtOffset, tz);
      this.dayDate[i].splitStartTime = d;
      this.dayDate[i].isSplitShift = true;
      if(this.areTimingsOnOtherDay(moment(this.dayDate[i].startTime).format("HH:mm"), moment(this.dayDate[i].splitStartTime).format("HH:mm")))
      {
       this.dayDate[i].splitStartTime = `${nextDay} ${tstr} ${tz}`;

      }
    }
  }

  addSplitEndTime() {
    for (var i = 0; i < this.dayDate.length; i++) {
      const nextDay = moment(this.dayDate[i].date).add(1, 'days').format('MM-DD-YYYY');
      var d = this.dayDate[i].date.toString();
      var t = this.planOpenshiftForm.controls.splitShiftEnd.value + ':00';
      var tstr = t.toString();
      d = d.replace('00:00:00', tstr);
      var tz = moment().format('[GMT]Z');
      d = d.replace(this.gmtOffset, tz);
      this.dayDate[i].splitEndTime = d;
      if(this.areTimingsOnOtherDay(moment(this.dayDate[i].startTime).format("HH:mm"), moment(this.dayDate[i].splitEndTime).format("HH:mm")))
      {
       this.dayDate[i].splitEndTime = `${nextDay} ${tstr} ${tz}`;

      }
    }
  }

  addSplitTime() {
    this.splitArr = [];

    const splitst = this.planOpenshiftForm.controls.splitShiftStart.value;
    const splitet = this.planOpenshiftForm.controls.splitShiftEnd.value;

    for (var s = 0; s < this.dayDate.length; s++) {
      const sdstr = this.dayDate[s].startTime.slice(11, 16);
      const edstr = this.dayDate[s].endTime.slice(11, 16);

      var splitArrSt = this.dayDate[s].startTime
        .toString()
        .replace(sdstr, splitst);
      var splitArrEt = this.dayDate[s].endTime
        .toString()
        .replace(edstr, splitet);

      this.splitArr.push({
        date: this.dayDate[s].date,
        day: this.dayDate[s].day,
        startTime: splitArrSt,
        endTime: splitArrEt,
      });
    }
  }

  emptyDayArr() {
    this.dayDate = [];
    this.btn1.nativeElement.classList.remove('selected');
    this.btn2.nativeElement.classList.remove('selected');
    this.btn3.nativeElement.classList.remove('selected');
    this.btn4.nativeElement.classList.remove('selected');
    this.btn5.nativeElement.classList.remove('selected');
    this.btn6.nativeElement.classList.remove('selected');
    this.btn7.nativeElement.classList.remove('selected');
  }

  initForm() {
    this.planOpenshiftForm = this.formBuilder.group({
      businessUnitId: [null],
      selectWeek: [null],
      confirmStaffNeed: [null, [Validators.required]],
      standByStaffNeed: [null, [Validators.required]],
      setTimingStart: [null, [Validators.required]],
      setTimingEnd: [null, [Validators.required]],
      splitShift: [null],
      splitShiftStart: [null],
      splitShiftEnd: [null],
      mainSkillSets: [null],
      subSkillSets: [null],
      reportingLocation: [null, [Validators.required]],
      selectDay: [null],
      shiftTemplate: [null],
      proximity: [null],
      isCheckInEnabled: [null],
      isProximityEnabled: [null],
    });

    this.displaySkilltable();

    this.buService.getRepoLocations(this.bu._id).subscribe((res) => {
      this.buRepoLocation = res['data'].reportingLocation;
      this.buService.getGeoRepoLocations(this.bu._id).subscribe((res) => {
        this.locations = res['result'].geoReportingLocation;
        this.buRepoLocation = this.buRepoLocation.concat(this.locations);
      });
    });

    this.buService.getBuShiftGeoLocation(this.bu._id).subscribe((res) => {
      const data = res['result'];
      this.radius = data.proximity;
      this.isCheckInEnabled = data.isCheckInEnabled;
      this.isProximityEnabled = data.isProximityEnabled;
      this.planOpenshiftForm.patchValue({
        proximity: this.radius,
        isCheckInEnabled: this.isCheckInEnabled.toString(),
        isProximityEnabled: this.isProximityEnabled.toString(),
      });
    });
  }

  templateDisplay() {
    var d = new Date();
    var dISO = d.toISOString();
    this.template = {
      businessUnitId: this.bu._id,
      weekRangeStartsAt: this.changeToDate(
        this.planOpenshiftForm.controls.selectWeek.value,
        0
      ),
      date: dISO,
    };

    this.planService.getTemplate(this.template).subscribe((res) => {
      this.selectShiftTemp = res['data']['templates'];
    });
  }
  deleteTemplateModal(){
    this.delete = true;
  }
  setTemplateDelete(){
    this.delete = false;
    const payload = {
      "templateId": this.ShiftTemplateTable?._id
  }
  let success = false;
    this.planService.deleteTemplate(payload).subscribe((res)=>{
      const result = res['data'];
      this.ShiftTemplateTable = {};
      this.openPlanshift = {};
     this.selectShiftTemp = this.selectShiftTemp.filter(t => t._id != payload.templateId);
     this.planOpenshiftForm.controls.shiftTemplate.setValue(null);
      this.toastr.success(result, 'Successfully');
      success = true;
    }, error => {
      this.toastr.error(error?.error?.message);
      success = false;
    })
  }
  deleteShiftInTemplateModal(id,index){
    this.shiftUniqueID = id.uniqueId;
    this.deleteShiftTemplate = true;
    this.deleteShiftIndex=index;
  }
  removeShiftInTemplate(id, index){
     this.openPlanshift?.['shifts']?.splice(index,1);
    this.timeSlot?.splice(index,1);
    this.skillTableLabels?.splice(index,1);
    this.shiftDay?.splice(index,1);
  }
  deleteShiftInTemplate(){
    this.deleteShiftTemplate = false;
  const payload =  {
      "templateId": this.ShiftTemplateTable._id,
      "planShiftToDelete": this.shiftUniqueID
  }
    this.planService.deleteShiftTemplate(payload).subscribe((res)=>{
      const result = res['data'];
   this.openPlanshift['shifts'] =  this.openPlanshift['shifts'].filter((val)=>{
     return val.uniqueId != this.shiftUniqueID;
     })
      this.toastr.success(result, 'Successfully');
      this.timeSlot?.splice(this.deleteShiftIndex,1);
      this.skillTableLabels?.splice(this.deleteShiftIndex,1);
      this.shiftDay?.splice(this.deleteShiftIndex,1);
      this.ngOnInit();
    }, error => {
      this.toastr.error(error);
    })
  }
  changeToDate(d, n) {
    if(!!d){

      var months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
      var sd = d.slice(4, 6).toString();
      var dd = parseInt(sd);
      var smm = d.slice(0, 3);
      var mm = months.indexOf(smm);
      var sy = new Date();
      var yy = sy.getFullYear();
      var startDate = new Date(yy, mm, dd);

      if (n == 0) {
        var startDate = new Date(yy, mm, dd);
        return startDate;
      } else if (n == 1) {
        var endDate = new Date(startDate.setDate(startDate.getDate() + 6));
        return endDate;
      }
    }
  }

  prefillShiftTemplate() {
   this.showShift = true;
    this.showTemplate = true;
    this.ShiftTemplateTable =
      this.planOpenshiftForm.controls.shiftTemplate.value;
    this.dayDate = [];
    this.shiftTempArr = [];
    this.skillSetArry2 = [];
    this.skillLabels2 = [];
    this.skillTableArry = [];
    this.skillTableLabels = [];
    this.timeSlot = [];

    /*for (var i = 1; i < 8; i++) {
      const days = this.weekStartDate.isoWeekday(i);
      this.disableDayArr.push(this.weekStartDate.format('ddd'));
    }*/
    this.disableDayArr = [];
    for (var i = 1; i < 7; i++) {
      const days = this.weekStartDate.isoWeekday(i);
      if (moment().isAfter(days)) {
        this.disableDayArr.push(this.weekStartDate.format('ddd'));
      }
    }
     this.disableDayArr.pop();

    this.ShiftTempSt = this.changeToDate(
      this.planOpenshiftForm.controls.selectWeek.value,
      0
    );

    this.ShiftTempEt = this.changeToDate(
      this.planOpenshiftForm.controls.selectWeek.value,
      1
    );
    const ids = this.ShiftTemplateTable?.shifts?.map(item => item?.uniqueId);
    const uniqueIds = [...new Set(ids)];
    const shiftArray = uniqueIds?.map(data => {
    const sameShiftArray = this.ShiftTemplateTable?.shifts?.filter(item => item.uniqueId === data);
      return sameShiftArray;
    });
   
    shiftArray.map((item) => {
      let shifts = {};
      item?.forEach((val: any) => {


        const startTime = moment(val.startTime).format("HH:mm");
        const endTime = moment(val.endTime).format("HH:mm");
        const selectedDate = moment(this.ShiftTempSt, 'MM-DD-YYYY').isoWeekday(val.day).format('MM-DD-YYYY');
        const otherDayFlag = this.areTimingsOnOtherDay(startTime, endTime);
        const nextDay = moment(selectedDate).add(1, 'days').format('MM-DD-YYYY');
        let shiftEndDateTime = this.calculateDateTimeString(selectedDate,val.endTime);
          let splitStartTime = val.isSplitShift ? (val.splitStartTime ?  this.calculateDateTimeString(selectedDate,val.splitStartTime):""):"";
         let splitEndTime = val.isSplitShift ? (val.splitEndTime ? this.calculateDateTimeString(selectedDate,val.splitEndTime):""):"";
         let isSplitStartOtherDay = false;
        let isSplitEndOtherDay = false;
        if (otherDayFlag) {
          shiftEndDateTime = nextDay + " " + endTime + ":00 GMT" + moment().format('ZZ');
        }
        if (val.isSplitShift) {
          isSplitStartOtherDay = this.areTimingsOnOtherDay(startTime, moment(val.splitStartTime).format("HH:mm"));
          isSplitEndOtherDay = this.areTimingsOnOtherDay(startTime, moment(val.splitEndTime).format("HH:mm"));
          if (isSplitStartOtherDay) {
            splitStartTime = this.calculateDateTimeString(nextDay,val.splitStartTime);
          }
          if (isSplitEndOtherDay) {
            splitEndTime = this.calculateDateTimeString(nextDay,val.splitEndTime);

          }
        }

        this.skillSetArry2 = [];
        this.skillLabels2 = [];

        if (this.tier == 2) {
          val.subSkillSets.forEach((el) => {
            this.skillSetArry2.push(el._id);
            this.skillLabels2.push([el.skillSetId.name, el.name]);
          });
        } else {
          val.mainSkillSets.forEach((el) => {
            this.skillSetArry2.push(el._id);
            this.skillLabels2.push([el.name, '_']);
          });
        }


        let dayObj = {
          date: moment(this.ShiftTempSt, 'MM-DD-YYYY')
            .isoWeekday(val.day)
            .format('MM-DD-YYYY 00:00:00 [GMT]ZZ'),
          day: val.day,
          isSplitShift: val?.isSplitShift,
          uniqueId: val.uniqueId,
          startTime: this.calculateDateTimeString(selectedDate,val.startTime),
          endTime: shiftEndDateTime,
          splitStartTime: splitStartTime,
          splitEndTime: splitEndTime,
        }
        this.dayDate.push(dayObj);

        shifts = {
          staffNeedCount: val.staffNeedCount,
          backUpStaffNeedCount: val.backUpStaffNeedCount,
          dayDate: this.dayDate,
          reportLocationId: val.reportLocationId?._id,
          status: '1',
          isSplitShift: val?.isSplitShift,
          uniqueId: val.uniqueId,
        };

        if (this.tier == 2) {
          shifts['subSkillSets'] = this.skillSetArry2;
        } else {
          shifts['mainSkillSets'] = this.skillSetArry2;
          shifts['skillSetTierType'] = this.tier;
          shifts['subSkillSets'] = [];
        }
      });


      this.skillTableArry.push(this.skillSetArry2);
      this.skillTableLabels.push(this.skillLabels2);

      this.shiftTempArr.push(shifts);

      this.chkday = [];
      this.dayDate.forEach((val) => {
        this.chkday.push(val.day);
      });
      this.shiftDay.push([...new Set(this.chkday)]);
      this.dayDate = [];




    });
    this.chkday = [];
    this.dayDate.forEach((val) => {
      this.chkday.push(val.day);
    });
    this.chkday = [...this.chkday];
    this.openPlanshift = {
      businessUnitId: this.bu._id,
      businessUnitName: '',
      weekRangeStartsAt: this.dateFormat(
        this.changeToDate(this.planOpenshiftForm?.controls?.selectWeek?.value, 0)
      ),
      weekRangeEndsAt: this.dateFormat(
        this.changeToDate(this.planOpenshiftForm?.controls?.selectWeek?.value, 1)
      ),
      shifts: this.shiftTempArr,
      status: '1',
      isTemplate: 1,
      templateId: this.ShiftTemplateTable?._id,
      platform: 'web',
      skillSetTierType: this.tier,
    };
    
    this.shiftTempArr.forEach((val, index, arr) => {
      if (arr[index].isSplitShift) {
        this.timeSlot.push([
          [arr[index].dayDate[0].startTime, arr[index].dayDate[0].endTime],
          [arr[index].dayDate[0].splitStartTime, arr[index].dayDate[0].splitEndTime],
        ]);
      } else {
        this.timeSlot.push([
          [arr[index].dayDate[0].startTime, arr[index].dayDate[0].endTime],
        ]);
      }
      
    });
this.dayDate = [];
  }

  // Define a function to calculate the date and time string
 calculateDateTimeString(date, time) {
  return date + " " + moment(time).format("HH:mm") + ":00 GMT" + moment().format('ZZ');
}

  displaySkilltable() {
    this.buService.getSkillset(this.bu._id).subscribe((res) => {
      this.buSkillSet = res['data'].skillSets;
      if (res['data'].mainSkillSets.length > 0 ) {
        this.buSkillSet = res['data'].mainSkillSets;
      }
    });
  }

  onPlanSkillSetChange($event) {
    const index = this.buSkillSet.findIndex((val,index)=>val._id === $event._id);
    this.buSkillspeciality = this.buSkillSet[index].subSkillSets;
    this.planOpenshiftForm.patchValue({
      subSkillSets: null,
    });
 
  }

  addUserSkillbutton() {
    if (this.tier == 2) {
      if (
        this.skillSetArry.indexOf(
          this.planOpenshiftForm.get('subSkillSets').value?._id
        ) > -1
      ) {
        this.toastr.warning('Already Exists', 'Warning');
      } else {
        this.skillSetArry.push(
          this.planOpenshiftForm.get('subSkillSets').value?._id
        );
        this.skillLabels.push([
          this.planOpenshiftForm.get('mainSkillSets').value?.name,
          this.planOpenshiftForm.get('subSkillSets').value?.name,
        ]);
      }
    } else {
      if (
        this.skillSetArry.indexOf(
          this.planOpenshiftForm.get('mainSkillSets').value._id
        ) > -1
      ) {
        this.toastr.warning('Already Exists', 'Warning');
      } else {
        this.skillSetArry.push(
          this.planOpenshiftForm.get('mainSkillSets').value._id
        );
        this.skillLabels.push([
          this.planOpenshiftForm.get('mainSkillSets').value.name,
          '_',
        ]);
      }
    }
    this.planOpenshiftForm.get('mainSkillSets').setValue(null);
    this.planOpenshiftForm.get('subSkillSets').setValue(null);
  }

  clearLabel(i) {
    this.skillSetArry.splice(i, 1);
    this.skillLabels.splice(i, 1);
  }

  addShiftTemplate() {
    if (this.planOpenshiftForm.invalid || this.skillLabels.length == 0) {
      return;
    }

    this.addStartTime();
    this.addEndTime();

    if (this.isSplit) {
      const firstSplitEndTime =
        this.planOpenshiftForm.controls.setTimingEnd.value;
      const secondSplitStartTime =
        this.planOpenshiftForm.controls.splitShiftStart.value;

      var startTime = moment(firstSplitEndTime, 'HH:mm');
      var endTime = moment(secondSplitStartTime, 'HH:mm');
      var duration = endTime.diff(startTime);
      if (duration < 0) {
        this.toastr.error(
          'Second split timing is overlapping with the first split timing',
          'Failed',
          { timeOut: 3000 }
        );
        return;
      }

      this.timeSlotTable.push([
        [
          this.planOpenshiftForm.controls.setTimingStart.value,
          firstSplitEndTime,
        ],
        [
          secondSplitStartTime,
          this.planOpenshiftForm.controls.splitShiftEnd.value,
        ],
      ]);

      this.addSplitTime();
      this.addSplitStartTime();
      this.addSplitEndTime();
      this.mockDayDate = this.dayDate;
      const concatSplitArr = this.mockDayDate.concat(this.splitArr);
      this.mockDayDate = concatSplitArr;
    } else {
      this.timeSlotTable.push([
        [
          this.planOpenshiftForm.controls.setTimingStart.value,
          this.planOpenshiftForm.controls.setTimingEnd.value,
        ],
      ]);
    }

    this.showShift = true;

    this.chkday = [];
    this.dayDate.forEach((val) => {
      this.chkday.push(val.day);
    });

    this.shiftDay.push([...new Set(this.chkday)]);

    this.skillTableArry = [...this.skillSetArry];
    this.skillTableLabels.push([...this.skillLabels]);

    const shifts = {
      staffNeedCount: parseInt(
        this.planOpenshiftForm.controls.confirmStaffNeed.value
      ),
      dayDate: this.dayDate,
      reportLocationId:
        this.planOpenshiftForm.controls.reportingLocation.value._id,
      status: '1',
      isSplitShift: this.isSplit,
    };

  const index =   this.locations.find((data)=>{
     return data._id == this.planOpenshiftForm.controls.reportingLocation.value._id
    })
    // Add/Show Standby staff if bushift standby has it true
    if (this.buShiftDetails?.standByShiftPermission) {
      shifts['backUpStaffNeedCount'] = parseInt(
        this.planOpenshiftForm.controls.standByStaffNeed.value
      );
    }

    // 'createdAt' property is not in geoloaction obj and used to identify location is geo or text.
    if (!index) {
      shifts['geoReportingLocation'] = null;
      shifts['reportLocationId'] =
        this.planOpenshiftForm.controls.reportingLocation.value._id;
    } else {
      shifts['reportLocationId'] = null;
      shifts['geoReportingLocation'] =
        this.planOpenshiftForm.controls.reportingLocation.value._id;

    }

    if (this.tier == 2) {
      shifts['subSkillSets'] = this.skillTableArry;
    } else {
      shifts['mainSkillSets'] = this.skillTableArry;
      shifts['subSkillSets'] = [];
      shifts['skillSetTierType'] = this.tier;
    }

    this.shiftsArr.push(shifts);

    if(this.showTemplate){
      const tempVar = [];
      tempVar.push(shifts);
      const previousShift = this.openPlanshift?.['shifts'];
      const finalShift = [...previousShift,...tempVar]
      this.openPlanshift = {
        businessUnitId: this.bu._id,
        businessUnitName: '',
        weekRangeStartsAt: this.dateFormat(
          this.changeToDate(this.planOpenshiftForm.controls.selectWeek.value, 0)
        ),
        weekRangeEndsAt: this.dateFormat(
          this.changeToDate(this.planOpenshiftForm.controls.selectWeek.value, 1)
        ),
        shifts: finalShift,
        status: '1',
        isTemplate: 1,
        templateId: this.ShiftTemplateTable._id,
        platform: 'web',
        skillSetTierType: this.tier,
        proximity: this.radius,
        isCheckInEnabled: this.isCheckInEnabled,
        isProximityEnabled: this.isProximityEnabled,
      };
    }
   else{
    this.openPlanshift = {
      businessUnitId: this.bu._id,
      businessUnitName: '',
      weekRangeStartsAt: this.dateFormat(
        this.changeToDate(this.planOpenshiftForm.controls.selectWeek.value, 0)
      ),
      weekRangeEndsAt: this.dateFormat(
        this.changeToDate(this.planOpenshiftForm.controls.selectWeek.value, 1)
      ),
      shifts: this.shiftsArr,
      status: '1',
      isTemplate: 0,
      platform: 'web',
      skillSetTierType: this.tier,
      proximity: this.radius,
      isCheckInEnabled: this.isCheckInEnabled,
      isProximityEnabled: this.isProximityEnabled,
    };
   }

    if (this.isSplit) {
      this.mockDayDate.forEach((val, indx) => {
        if (val.day == this.mockDayDate[0].day && indx != 0) {
          this.timeSlot.push([
            [this.mockDayDate[0].startTime, this.mockDayDate[0].endTime],
            [val.startTime, val.endTime],
          ]);
        }
      });
    } else {
      this.timeSlot.push([
        [this.dayDate[0]?.startTime, this.dayDate[0]?.endTime],
      ]);
    }
    this.resetForm();
    this.toastr.info('Shift added below', 'Added below');
  }

  clearShifts() {
    this.dayDate = [];
    this.shiftTempArr = [];
    this.shiftsArr = [];
    this.skillSetArry2 = [];
    this.skillLabels2 = [];
    this.skillTableArry = [];
    this.skillTableLabels = [];
    this.timeSlot = [];
    this.chkday = [];
    this.shiftDay = [];
    this.showShift = false;
    this.openPlanshift = {};
  }

  resetForm() {
    this.planOpenshiftForm.reset();
    this.planOpenshiftForm.patchValue({
      selectWeek: this.selectedWeek,
      shiftTemplate: this.ShiftTemplateTable,
      isCheckInEnabled:this.isCheckInEnabled?.toString()
    });
    this.skillSetArry = [];
    this.skillLabels = [];

    this.isSplit = false;
    this.isSplitShift = false;

    this.dayDate = [];
    this.btn1.nativeElement.classList.remove('selected');
    this.btn2.nativeElement.classList.remove('selected');
    this.btn3.nativeElement.classList.remove('selected');
    this.btn4.nativeElement.classList.remove('selected');
    this.btn5.nativeElement.classList.remove('selected');
    this.btn6.nativeElement.classList.remove('selected');
    this.btn7.nativeElement.classList.remove('selected');
  }

  clearSkills() {
    this.skillSetArry = [];
    this.skillLabels = [];
    this.planOpenshiftForm.patchValue({
      mainSkillSets: null,
    });

    this.tier == 2 &&
      this.planOpenshiftForm.patchValue({
        subSkillSets: null,
      });
  }

  onShiftSubmit() {
    if (this.openPlanshift) {
      this.loaderShiftCreate = true;
      const openShiftCreate = this.planService
        .createShift(this.openPlanshift)
        .pipe(
          finalize(() => {
            this.loaderShiftCreate = false;
            openShiftCreate.unsubscribe();
          })
        )
        .subscribe((res:any) => {
         if(res?.data == 'Shift created sucessfully')
          this.toastr.success('Shift Created', 'Successfully');
          else
          this.toastr.warning(res?.data, 'Warning');
          this.changeTab.emit(5);
          this.weekStart.emit(this.openPlanshift);
        });
    } else {
      this.toastr.warning(
        'Please add shift first by filling the above form',
        'Warning'
      );
    }
  }

  clearshift() {
    this.openPlanshift = '';
    this.shiftsArr = [];
  }

  savePlanTemplate() {
    const saveShiftTemp = this.planService
      .savePlanShift(this.openPlanshift)
      .pipe(
        finalize(() => {
          saveShiftTemp.unsubscribe();
        })
      )
      .subscribe((res) => {
        this.toastr.success('Saved Shift Template', 'Successfully');
        this.changeTab.emit(5);
      });
  }

  setSplitShift(e) {
    if (e.target.checked) {
      this.isSplit = true;
      this.isSplitShift = true;
    } else {
      this.isSplit = false;
      this.isSplitShift = false;
    }
  }

  changeReportingLocationRadius(reportingLoc: any) {
    this.radius = reportingLoc.id;
  }

  changeCheckinEnable(value) {
    this.isCheckInEnabled = value;
    if (value == 'false') {
      this.planOpenshiftForm.patchValue({
        isProximityEnabled: 'false',
      });
      this.isProximityEnabled = 'false';
    }
  }

  changeProximityEnable(value) {
    this.isProximityEnabled = value;
    if (value == 'false') {
      this.planOpenshiftForm.patchValue({
        isProximityEnabled: 'false',
        proximity: null,
      });
    }
  }
}
