import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-staffing',
  templateUrl: './staffing.component.html',
  styleUrls: ['./staffing.component.scss']
})
export class StaffingComponent implements OnInit {
  @Input() bu;
  constructor() { }

  ngOnInit(): void {
  }

}
