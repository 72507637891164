import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Company } from 'src/app/core/model/definition.model';
import { StorageService } from 'src/app/core/service/storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  private baseUrl: string;
  private get Headers(): HttpHeaders{
    return new HttpHeaders({ Accept: 'application/json', 'Content-Type': 'application/json' })
  }
  constructor(private http: HttpClient, private storageService: StorageService) {

    this.baseUrl = environment.baseUrl
  }
  getCompanies({page, limit, search, sort}): Observable<any>{
    return this.http.get(`${this.baseUrl}/company/`, {headers: this.Headers, params:{page, limit, search, sortWith:sort.key, sortBy:sort.order}})
    .pipe(map((res) => res as any))
  }

  uploadFile(fileToUpload, isOldAPI=false){
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    if(isOldAPI === true){
      return this.http.post(`${this.baseUrl}/notification/uploadContentFiles/`, formData);
    }
    return this.http.post(`${this.baseUrl}/upload/`, formData);
  }

  createCompany(company: Company): Observable<any> {

    return this.http.post(`${this.baseUrl}/company/`, {...company}, {headers: this.Headers})
    .pipe(map((res) => res as any))
  }

  // getLogs(): Observable<any>{
  //   return this.http.get(`http://doodlebluelive.com:2223/docs/#/News%20and%20Events/getPostLog`);
  // }
}
