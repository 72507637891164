<div class="card mb-3">
    <div class="card-header flexi-header">
        <h3>OTHERS</h3>
    </div>
    <div class="card-body notificationSection">
        <p class="notif-reminder"> NOTIFICATION REMINDER SETUP </p>
        <div class="justify-content-SB">
            <div class="col-3">
                <div class="form-group">
                    <label class="font-weight-bold">Remind Days</label>
                    <input type="number" class="form-control" [(ngModel)]="remindDays" />
                </div>
            </div>
            <div class="col-3">
                <div class="form-group">
                    <label class="font-weight-bold">Remind Hours</label>
                    <ng-select class="custom" [searchable]="true" [clearable]="true" [(ngModel)]="remindHours">
                        <ng-option [value]="item" *ngFor="let item of hoursArray">
                            {{ item }}
                        </ng-option>
                    </ng-select>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body notificationSection">
        <p class="notif-reminder">FEEDBACK EMAIL</p>
        <div class="justify-content-SB">
            <div class="col-3">
                <div class="form-group">
                    <label class="font-weight-bold" for="exampleInputEmail1">Technical email</label>
                    <input type="email" class="form-control" placeholder="" maxlength="50"
                        [(ngModel)]="technicalEmail" />
                </div>
            </div>
            <div class="col-3 mb-2">
                <div class="form-group">
                    <label class="font-weight-bold" for="exampleInputEmail1">Adminstrative email</label>
                    <input type="title" class="form-control" placeholder="" maxlength="50"
                        [(ngModel)]="administratorEmail" />
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="card my-3 w-100">
    <div class="card-header flexi-header p-4">
        <h3>SKILLSET SETUP</h3>
    </div>
    <div class="card-body-inline ml-4">
        <input type="radio" id="tier1" name="skillSet" value="tier-1" [(ngModel)]="skillSet">
        <label class="mt-2 mr-2">Tier 1</label><br>

        <input type="radio" class="ml-3" id="tier2" name="skillSet" value="tier-2" [(ngModel)]="skillSet">
        <label class="mt-2">Tier 2</label><br>
    </div>
</div> -->
<div class="row">
    <div class="col-md-4">
        <button class="btn btn-submit update-button" (click)="onSubmitSetupForm()"><i class="fa"></i>Update</button>
    </div>
</div>
