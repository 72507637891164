import { DatePipe } from '@angular/common';
import {
  Component,
  Injectable,
  Input,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  NgbDate,
  NgbDateParserFormatter,
  NgbModal,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription, of } from 'rxjs';
import {
  map,
  debounceTime,
  distinctUntilChanged,
  mergeMap,
  delay,
  finalize,
} from 'rxjs/operators';
import {
  SortableHeader,
  SortEvent,
} from 'src/app/shared/directive/sortable.directive';
import * as moment from 'moment';
import { BussinessUnitService } from 'src/app/users/service/bussiness-unit/bussiness-unit.service';
import { CustomFormService } from 'src/app/users/service/custom-form/custom-form.service';
import { ToastrService } from 'ngx-toastr';
import { tableParam } from 'src/app/core/model/table-param.model';
import { TableService } from 'src/app/core/service/table.service';
import { environment } from 'src/environments/environment';
import { FileDownloadService } from 'src/app/users/service/common/file-download.service';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '-';
/* logic for convert date into dd/mm/yyyy format*/
  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? date.day +
          this.DELIMITER +
         date.month +
          this.DELIMITER +
          date.year
      : '';
  }
}

@Component({
  selector: 'app-form-manage',
  templateUrl: './form-manage.component.html',
  styleUrls: ['./form-manage.component.scss'],
  providers:[
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ]
})
export class FormManageComponent implements OnInit {
  manageFormLogTable;
  @Input() manageId: string = null;
  @Input() editFormData: any = null;
  @Input() overViewData: any = null;
  hoveredDate: NgbDate | null = null;
  selectedStatusID: any = null;
  selectedWorkFlowID: any = null;
  selectedWorkFlowData: any = null;
  baseUrl = environment.baseUrl;
  isAdminTrue:boolean;
  maxDate;
  columns = ['createdAt', 'formId', 'user'];
  fromDate: NgbDate = null;
  manageFormId:any;
  toDate: NgbDate = null;
  workFlowBuDropDownToClear;
  public manageFromLogSearchInput = new Subject<KeyboardEvent>();
  parentBuUnitDropDown = [];
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  questions = [];
  workflow = [];
  workflowStatusArray = [];
  tableData = [];
  readManageForm: FormGroup;
  updateFormSettings: FormGroup;
  workFlowLogForm: FormGroup;
  form: FormGroup;
  selectedCheckBoxIndex: number;
  tableListArr = [];
  selectedWorkFlowStatus: any[];
  selectedFilterStatus: any[];
  clickedWorkflowButtonData:any;
  defaultStatusArray: any[];
  defaultStatusFilterArray: any[];
  editClicked: boolean;
  showWorkFlowContent:boolean = false;
  isAdditionalModuleId:boolean=false;
  submittedWorkflowOnAdminPage:any;
  isAdminAdditionalFormSubmitted:boolean;
  adminQuestionare:boolean;
  additionalModuleIdData:any;
  customFormId: any;
  questionId: any;
  clickedWorkFlowTitle:any;
  workflowId:any;
  businessunitPlaceholder:string='Select Business unit';
  formStatusValue:any=null;
  parentBuUnitDropDownLoading: boolean = false;
  parentbussinesUnitPTable: {
    sortBy: string;
    sortWith: string;
    data: any[];
    page: number;
    limit: number;
    search: string;
    filter: { type: string };
  };
  workFlowLogTableData: {
    sortBy: string;
    sortWith: string;
    data: any[];
    page: number;
    total: number;
    limit: number;
    search: string;
    filter: { type: string };
  };
  workflowStatus = [];
  userTableColumn = ['createdAt', 'formId', 'user'];
  tableQueryParams = tableParam;
  logTableQueryParams = tableParam;
  tableDataObj: any;
  /// bussiness unit over view table config
  onSortUsers({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.manageFormLogTable.sort = { key: column, order: direction as any };
    this.loadManageFormLogs();
  }

  onPageChangeManageFromLogs(page: number) {
    this.manageFormLogTable.page = page;
    this.loadManageFormLogs();
  }
  onLimitChangeManageFormLogs() {
    this.loadManageFormLogs();
  }

  private manageFormLogSearchSubscription: Subscription;
  @ViewChild('updateSettings', { static: true })
  updateSettings: TemplateRef<any>;
  workFlowModalReference:any;
  @ViewChild('updateStatusSettings', { static: true })
  updateStatusSettings: TemplateRef<any>;
  @ViewChild('adminQuestion', { static: true })
  adminQuestion: TemplateRef<any>;
  @ViewChild('updateStatusFilter', { static: true })
  updateStatusFilter: TemplateRef<any>;
  constructor(
   public toastService: ToastrService,
    public formBuilder: FormBuilder,
    public modelService: NgbModal,
    private datePipe: DatePipe,
    private buUnit: BussinessUnitService,
   private customFromService: CustomFormService,
    private tableService: TableService,
    private fileDownloadService: FileDownloadService,
    fb: FormBuilder
  ) {
    this.form = fb.group({
      selectedCountries: new FormArray([])
    });
    this.tableDataObj = {
      sortBy: 'desc',
      sortWith: 'createdBy',
      total: 0,
      data: [],
      page: 1,
      limit: 10,
      search: '',
    };
    this.maxDate = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate(),
    };

    // this.config.notFoundText = 'Custom not found';
    this.manageFormLogTable = {
      sortBy: 'createdBy',
      sortWith: 'desc',

      data: [],
      page: 1,
      limit: 10,
      search: '',
    };

    this.parentbussinesUnitPTable = {
      sortBy: 'createdAt',
      sortWith: 'desc',
      data: [],
      page: 1,

      limit: 8,

      search: '',
      filter: {
        type: 'plan',
      },
    };
    this.workFlowLogTableData = {
      sortBy: 'createdBy',
      sortWith: 'desc',

      data: [],
      page: 1,
      limit: 10,
      search: '',
      total: 0,
      filter: {
        type: 'plan',
      },
    };

    this.manageFormLogSearchSubscription = this.manageFromLogSearchInput
      .pipe(
        map((event) => {
          const target = event.target as HTMLTextAreaElement;
          return target.value;
        }),
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap((search) => of(search).pipe(delay(500)))
      )
      .subscribe((input) => {
        this.manageFormLogTable.page = 1;
        this.loadManageFormLogs();
      });
  }

  ngOnInit(): void {
    this.isAdminAdditionalFormSubmitted = false;
    this.manageId = this.editFormData?._id;
    this.workflowId = this.editFormData?.workflow?.length ?  this.editFormData?.workflow[0]?._id :null;
    if(this.editFormData?.workflow?.length > 0 && this.editFormData?.workflow[0]?.additionalModuleId?._id)
    {
      this.isAdditionalModuleId = true;
      this.additionalModuleIdData = this.editFormData?.workflow?.length  ? this.editFormData?.workflow[0]?.additionalModuleId?.questions:null;
    }
    else{
      this.isAdditionalModuleId = false;
    }
    this.tableQueryParams.draw = 0;
    this.tableQueryParams.start = 0;
    this.tableQueryParams.search.value = "";
    this.logTableQueryParams.draw = 1;
    this.logTableQueryParams.start = 0;
    this.logTableQueryParams.search.value = "";
    this.initForm();
    this.setFormSettings();
    this.loadParentBussinessUnit();

    if (this.manageId) {
      let qusIds = this.editFormData?.moduleId?.questions?.map(item => {
        return item._id
      });
      let workFlowQusId = this.editFormData?.workflow?.map(item => {
        return item._id
      });
      if (qusIds?.length) {
        this.userTableColumn.push(...qusIds)
      }
      if (workFlowQusId?.length) {
        this.userTableColumn.push(...workFlowQusId)
      }
      this.userTableColumn.push('_id');


      this.getManageForm(false);
      this.patchFormData();
    }


  }

  onShowWorkFlowContent (workflow){
    this.defaultStatusArray = workflow?.workflowStatus
    this.clickedWorkFlowTitle = workflow;
    this.isAdminTrue = workflow?.isAdmin;
    this.showWorkFlowContent = true;
  }

  private initForm() {
    this.readManageForm = this.formBuilder.group({
      customFormId: [this.manageId, [Validators.required]],
      parentBussinessUnitId: [null],
      statusFilter: this.formBuilder.array([]),
      fromDate: [{}, [Validators.required]],
      toDate: [{}, [Validators.required]],
    });
  }
  private setFormSettings() {
    this.updateFormSettings = this.formBuilder.group({
      formId: [this.manageId, [Validators.required]],
      fieldStatus: this.formBuilder.array([]),
      statusFilter: this.formBuilder.array([]),
    });
  }

  get fieldStatusArray(): FormArray {
    return this.updateFormSettings.get('fieldStatus') as FormArray;
  }

  async addFieldStatus(value) {
    if (value) {
      const Control = this.fieldStatusArray;
      const index = await Control.value.findIndex((x) => x._id === value._id);

      if (index > -1) {
        Control.at(index).patchValue({ status: value.status, name: value.question ? value.question : value.name, _id: value._id })
      } else {
        let field = this.formBuilder.group({
          _id: [value._id, Validators.compose([Validators.required])],
          name: [
            value.question ? value.question : value.title,
            Validators.compose([Validators.required]),
          ],
          status: value.status ? value.status : false,
        });
        Control.push(field);

      }
    }

  }
  get statusFilterArray(): FormArray {
    return this.updateFormSettings.get('statusFilter') as FormArray;
  }

  async addStatusFilter(value, loadApi?: boolean) {
    if (value) {
      const Control = this.statusFilterArray;
      const index = await Control.value.findIndex((x) => x._id === value._id);

      if (index > -1) {
        Control.at(index).patchValue({ status: value.status, _id: value._id })

      } else {
        let field = this.formBuilder.group({
          _id: [value._id, Validators.compose([Validators.required])],
          status: value.status ? value.status : false,
        });
        Control.push(field);
      }
      if (loadApi) {
        this.getManageForm(false);
      }

    }
  }
  onChangeFilterCheckBox(event, item) {
    const index = this.selectedFilterStatus.findIndex((x) => x._id === item._id);

    if (index > -1) {
      this.selectedFilterStatus[index] = { status: item.status, _id: item._id, field: item.field }

    }

  }

  isAllSelected(event, item, selectedIndex) {
    this.selectedCheckBoxIndex = event.target.checked ? selectedIndex : undefined;
    const Control = this.statusFilterArray;
    const index = this.workflowStatus.findIndex((x) => x._id === item._id);

    if (index > -1) {
      this.workflowStatus[index] = { ...this.workflowStatus[index], status: event.target.checked }

    }
    this.workflowStatus.forEach((data, ind) => {
      if (ind != index) {
        this.workflowStatus[ind] = { ...this.workflowStatus[ind], status: false }
      }
    })
    this.selectedWorkFlowStatus = this.workflowStatus;
  }

  isColVisible(title) {
    let qusObj = this.workflow?.find(item => item.title == title) ? this.workflow?.find(item => item.title == title) : this.questions?.find(item => this.stripHtml(item.question) == title);
    let fieldList = this.fieldStatusArray.getRawValue();
    return fieldList?.find(f => f._id == qusObj?._id)?.status;
  }

  onSearchParentBuUnit(value) {

    this.parentbussinesUnitPTable.limit = 10;
    this.parentbussinesUnitPTable.search = value.term;

    this.loadParentBussinessUnit();
  }

  loadMoreParentBuUnitItems(value) {
    this.parentbussinesUnitPTable.limit =
      this.parentbussinesUnitPTable.limit + 10;

    this.loadParentBussinessUnit();
  }

  onClearParentBuUnitDropdown(value) {
    if (value === undefined) {
      this.readManageForm.patchValue({
        parentBussinessUnitId: null,
      });
      this.parentbussinesUnitPTable.search = '';
      this.loadParentBussinessUnit();

      this.parentBuUnitDropDown = [];
      this.parentbussinesUnitPTable.data = [];
    }
  }
  setHeader(title) {
    switch (title) {
      case 'createdAt':
        return 'Date / Time submitted';
        break;
      case 'staffId':
        return 'Full name';
        break;
      default:
        return title;
    }
  }

  setData(data, title) {


    if (!title) return 'Hi';
    switch (title) {
      case 'createdAt':
        return this.datePipe.transform(data, 'd/M/yy, h:mm a');

      default:
        return data;
    }
  }

  formatTime(time) {
    if (time.includes('-')) {
      return time
    } else {
      return moment(time, 'HH:mm:ss').format('hh-mm-A')
    }
  }

  setAnswer(item, title) {
    let answer = '';
    let qusObj = this.workflow?.find(qus => qus?.title == title);
    if (qusObj) {

      let workFlowArr = qusObj?.workflowStatus || [];
      let status = workFlowArr?.find(data => data._id == item[qusObj?._id])?.field || ''
      answer = status;
      // }
    } else {
      qusObj = this.questions?.find(qus => this.stripHtml(qus?.question) == title);
      if (qusObj) {
        let data = item[qusObj?._id];
        switch (qusObj.type) {
          case 1: case 8: case 9: case 13: answer = data || '--'; break;
          case 2: case 3: case 11: answer = typeof data === 'object' ? data.value : data || '--'; break;
          case 4: case 5: case 15: answer = Array.isArray(data) ? data.map(a => a.value).join(', ') : data?.value; break;
          case 10: answer = (data?.date || '') + ' ' + (data?.time ? this.formatTime(data?.time) : ''); break;
          case 12: answer = !!data ? `<a target="_blank" (click)="downloadAttachments('${data.url}', '${(!!data?.filename ? data?.filename : data?.name)}')">${(!!data?.filename ? data?.filename || '--' : data?.name || '--')}</a>` : '--'; break;
          case 14: answer = data.map(v => v.name || v.text).join(', '); break;
          case 6:
            data = `${data || ''}`.startsWith(`data:image/png;base64,`) ? data : `data:image/png;base64,${data}`;
            answer = `<img src="${data}" width="100" height="auto" />`;
            answer = answer ? answer : '--';
            break;
          case 16:
            // answer = `<span ng-click="ppFullScreen('${curr._id}', '${row._id}')">show PP</span>`;
            break;
          default:
            break;
        }
      }
      // })
    }

    return answer;

  }

  settingsOpenPopup() {
    this.modelService.open(this.updateSettings, {
      windowClass: 'modal-ui-fix right-align',
    });

  }
  adminFormSubmitted(event){
    this.submittedWorkflowOnAdminPage = event;
    this.isAdminAdditionalFormSubmitted = true;
    this.WorkflowstatusOpenPopup(this.clickedWorkflowButtonData, this.clickedWorkflowButtonData?._id)
  }
  statusOpenPopup() {
  this.modelService.open(this.updateStatusFilter, {
      windowClass: 'modal-ui-fix right-align',
    });
    const Control = this.statusFilterArray;
    this.defaultStatusFilterArray = this.workflowStatus.flatMap((data, index) => {
      return data && { ...data, status: false }
    })
    this.selectedFilterStatus = this.defaultStatusFilterArray;
  }
  WorkflowstatusOpenPopup(data, id: string) {
    this.clickedWorkflowButtonData = data;
   if(this.isAdditionalModuleId && !this.isAdminAdditionalFormSubmitted){
      this.adminQuestionare = true;
      this.selectedWorkFlowID = id;
      this.customFormId = id;
      this.manageFormId = id;
      this.questionId = this.editFormData?.moduleId?._id;
    }
    else if(this.isAdditionalModuleId && this.isAdminAdditionalFormSubmitted){
      this.selectedCheckBoxIndex = null;
      this.selectedWorkFlowID = id;
      this.selectedWorkFlowData = data;
        this.workFlowModalReference = this.modelService.open(this.updateStatusSettings, {
          windowClass: 'modal-ui-fix right-align',
        });
      this.selectedWorkFlowStatus = this.workflowStatus;
      const Control = this.statusFilterArray;
      this.defaultStatusArray = this.workflowStatus.flatMap((data, index) => {
        const arrindex = Control.value.findIndex((x) => x._id === data._id);
       return data && { ...data, status: false }
      })
      this.onShowWorkFlowContent(this.submittedWorkflowOnAdminPage);
      this.loadWorkFlowLog();
      this.workFlowModalReference.result.then((data) => {
        },
      (error) => {
        this.showWorkFlowContent = false;
        this.isAdminAdditionalFormSubmitted=false;
      })
    }
    else if(!this.isAdditionalModuleId || this.isAdminAdditionalFormSubmitted){

      this.selectedCheckBoxIndex = null;
this.selectedWorkFlowID = id;
this.selectedWorkFlowData = data;

  this.workFlowModalReference = this.modelService.open(this.updateStatusSettings, {
    windowClass: 'modal-ui-fix right-align',
  });
this.selectedWorkFlowStatus = this.workflowStatus;
const Control = this.statusFilterArray;
this.defaultStatusArray = this.workflowStatus.flatMap((data, index) => {
  const arrindex = Control.value.findIndex((x) => x._id === data._id);
  return data && { ...data, status: false }
})
this.loadWorkFlowLog();
this.workFlowModalReference.result.then((data) => {
  // on close
},
(error) => {
  this.showWorkFlowContent = false;
  this.isAdminAdditionalFormSubmitted=false;
})
}
  }

  loadWorkFlowLog() {
    this.selectedWorkFlowStatus = this.defaultStatusArray;
    this.logTableQueryParams.draw++;
    this.logTableQueryParams.length = this.workFlowLogTableData.limit;
    this.customFromService.getWorkFlowLogs(this.logTableQueryParams, this.selectedWorkFlowID).subscribe((data) => {
      this.workFlowLogTableData.data = data?.data;
      this.workFlowLogTableData.total = data?.recordsTotal;
    })
  }

  loadParentBussinessUnit() {
    this.parentBuUnitDropDownLoading = true;
    const { data, ...rest } = this.parentbussinesUnitPTable;
    const subscribe = this.buUnit
      .getBussinesUnit(rest, true)
      .pipe(
        finalize(() => {
          this.parentBuUnitDropDownLoading = false;
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          // const { count, data } = response.data;
          this.parentbussinesUnitPTable.data = response?.businessUnitList || [];
          this.parentBuUnitDropDown = response?.businessUnitList || [];
        },
        (error) => {
          this.parentbussinesUnitPTable.data = [];
          this.toastService.error('No list found');
        }
      );
  }

  stripHtml(html) {
    var tmp = document.createElement("DIV");
    tmp.textContent = html;
    const str = tmp?.textContent || tmp?.innerText || "";
    return str?.substring(0, 50);
  }

  getFormSettings(moduleId: string) {
    const subscribe = this.customFromService
      .getFormSettings(moduleId)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(({ data }) => {
        let formArr = this.fieldStatusArray;
        formArr?.clear();
        this.columns = ['createdAt', 'formId', 'user'];
        let statusArr = this.statusFilterArray;
        statusArr?.clear();
        if (data.fieldStatus.length !== 0) {
          data.fieldStatus?.map((element) => {
            this.questions?.map((question) => {
              if (element._id == question._id) {
                question.status = element.status;
                this.columns.push(this.stripHtml(question?.question));
              }
            });
          });
          data.fieldStatus?.map((element) => {
            this.workflow?.map((work) => {
              if (element._id == work._id) {
                work.status = element.status;
                this.columns.push(work?.title);
              }
            });
          });

          data.fieldStatus?.forEach((element) => {
            this.addFieldStatus(element);
          });
        }
        if (data.statusFilter?.length !== 0) {
          data.statusFilter?.forEach((element) => {
            this.workflowStatus?.map((workflow) => {
              if (element._id === workflow._id) {
                workflow.status = element.status;
              }
            });
          });
          data.statusFilter?.forEach((element) => {
            this.addStatusFilter(element);
          });
        }
      });
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  };
  onEdit(data) {
    this.selectedWorkFlowID = data;
    this.editClicked = true;
    this.customFormId = data;
    this.questionId = this.editFormData?.moduleId?._id;
  }
  backToManage(event) {
    this.editClicked = false;
    if (event == true) {
      this.tableQueryParams.draw = 0;
      this.tableQueryParams.start = 0;
      this.tableQueryParams.search.value = "";
      this.tableDataObj.page = 1;
      this.getManageForm(false);
    }

  }
  adminModalClosed(event){
    this.adminQuestionare = false;
  }

  onSubmitColumnSettings(value) {
    let checkedID = '';
    const filterStatusId = this.selectedWorkFlowStatus?.map((data) => {
      if (data?.status)
        checkedID = data?._id
    })
    const data = {
      workflowStatus: {
        "fieldId": this.clickedWorkFlowTitle?._id,
        "fieldStatusId": checkedID ? checkedID : this.selectFormStatus[this.selectedCheckBoxIndex]?._id
      },
      _id: this.selectedWorkFlowID
    }
    const subscribe = this.customFromService
      .updateWorkflowStatus(data)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(({ data }) => {
        this.toastService.success(data?.message);
        this.getManageForm(false);
        this.modelService.dismissAll();
        // this.getFormSettings(this.manageId);
        this.patchFormData();
      });
  }
  onSubmitStatusFilter(type) {
    let filter: any = [];
    const { customFormId } = this.readManageForm.value;
    let payload: any = {};
    payload.customFormId = customFormId;
    if (type == 'status') {
      filter = this.selectedFilterStatus?.filter((data) => {
        return data?.status
      });
      payload.statusFilter = filter;
      this.showWorkFlowContent = false;
    }
    if (type == 'workflow') {
      let columns = this.questions?.map((data) => {
        return { _id: data._id, status: data.status }
      });
      filter.push(...columns);
      let workflow = this.workflow?.map((data) => {
        return { _id: data._id, status: data.status }
      });
      filter.push(...workflow)
      payload.fieldStatus = filter;
    }


    const subscribe = this.customFromService
      .setFormSettings(payload)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(({ data }) => {
        // this.clearFormArray(this.statusFilterArray)
        this.toastService.success(data);
        this.modelService.dismissAll();
        this.getManageForm(true);
        this.getFormSettings(this.manageId);
        this.patchFormData();
      });
  }

  onSubmitStatusSettings() {
    const subscribe = this.customFromService
      .setFormSettings(this.updateFormSettings.value)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(({ data }) => {
        // this.clearFormArray(this.statusFilterArray)
        this.toastService.success(data.message);
        this.modelService.dismissAll();
        this.getFormSettings(this.manageId);
        this.patchFormData();
      });
  }
  onCloseStatusFrom() {
    // this.clearFormArray(this.statusFilterArray);
    this.modelService.dismissAll();
    this.showWorkFlowContent = false;
  }
  onCloseColumnForm() {
    // this.clearFormArray(this.fieldStatusArray);
    this.modelService.dismissAll();
  }
  onExportForm() {
    const payload={
      "fromDate":moment()
      .set({
        y: this.readManageForm.get('fromDate').value?.year,
        M: this.readManageForm.get('fromDate').value?.month - 1,
        D: this.readManageForm.get('fromDate').value?.day,
      }).toISOString(),
      "toDate":moment()
      .set({
        y: this.readManageForm.get('toDate').value?.year,
        M: this.readManageForm.get('toDate').value?.month - 1,
        D: this.readManageForm.get('toDate').value?.day,
      }).toISOString(),
      "parentBussinessUnitId":this.readManageForm.get('parentBussinessUnitId').value,
      "customFormId":this.readManageForm.get('customFormId').value
    }
    this.customFromService.exportManageForm(payload).subscribe((data:any)=>{
      if(data?.data?.csvLink){
        this.downloadAttachments(data?.data?.csvLink, "form-manage.csv");
      }else{
        this.toastService.error(data?.message);
      }
    },error=>{
      this.toastService.error('Something went wrong. Please try again later..');
    });
   }

  onViewBasedOnBussinessUnit() {
    this.getManageForm(false);
  }
  onclearBussinessUnit() {
    this.readManageForm.patchValue({
      parentBussinessUnitId: '',
      statusFilter: [],
      fromDate: {},
      toDate: {},
    });

    this.getManageForm(false);
  }

  ngbToMoment(d) {
    return moment().set({
      year: d.year,
      month: d.month - 1, //ngbdatepicker month starts from 1
      date: d.day,
    });
  }

  getManageForm(caller) {
    const { customFormId } = this.readManageForm.value;
    let queryObj: any = {};
    if (Object.keys(this.readManageForm.get('fromDate').value).length != 0) {
      queryObj.fromDate = this.ngbToMoment(this.readManageForm.get('fromDate').value)?.format('YYYY-MM-DD');
    }
    if (Object.keys(this.readManageForm.get('toDate').value).length != 0) {
      queryObj.toDate = this.ngbToMoment(this.readManageForm.get('toDate').value)?.format('YYYY-MM-DD');
    }
    if (this.readManageForm.get('parentBussinessUnitId').value) {
      queryObj.parentBussinessUnitId = this.readManageForm.get('parentBussinessUnitId').value;
    }

    queryObj.customFormId = customFormId;
    queryObj.timeZone = -330;
    queryObj.order = [{ column: 0, dir: 'desc' }];
    const statusFilterArray = [];
    caller && this.selectedFilterStatus.map((data, ind) => {
      if (data.status)
        statusFilterArray[ind] = data?._id
    })
    caller ? queryObj.statusFilter = statusFilterArray.toString() : this.readManageForm.get('statusFilter').value || null
    //this.readManageForm.get('statusFilter').value || null;
    let orderSearchIndex = this.userTableColumn?.map((item, index) => {
      return index;
    });
    let tableCol: any = this.tableService.initializeTableParamsColums(this.userTableColumn, orderSearchIndex, orderSearchIndex) || [];
    this.tableQueryParams.columns = tableCol;
    caller ? this.tableQueryParams.draw = 1 : this.tableQueryParams.draw++;;
    this.tableQueryParams.length = 10;
    this.tableQueryParams.order = queryObj.order;
    this.customFromService
      .getOneFormManage(queryObj, this.tableQueryParams)
      .subscribe((res) => {
        this.tableData = res.data.result;
        this.workflowStatusArray = res.data.groupByWFStatus;
        this.tableDataObj.total = res?.recordsFiltered || 0;
       });
  }
  onPageChangeUsers(page: number) {
    this.tableQueryParams.start = +page > 1 ? (page - 1) * this.tableDataObj.limit : 0;
    this.getManageForm(false);
  }
  onPageChangeWorkFlow(page: number) {
    this.logTableQueryParams.start = +page > 1 ? (page - 1) * this.workFlowLogTableData.limit : 0;
    this.loadWorkFlowLog();
  }
  onLimitChangeWorkFlow() {
    this.logTableQueryParams.start = 0;
    this.loadWorkFlowLog();
  }
  patchFormData() {
    const newArray = [];
    this.editFormData?.workflow?.forEach((element) => {
      newArray.push(...element.workflowStatus);
    });
    const modifiedData = newArray.map((rawProduct) => {
      return { ...rawProduct, status: rawProduct.status == 1 ? true : false };
    });
    this.workflowStatus = modifiedData;
    this.workflowStatus?.map(item => {
      item.status = false;
    });
    this.questions = this.editFormData?.moduleId?.questions || [];
    this.questions?.map(item => {
      item.status =  item.status == 1 ? true : false;
    });
    this.formStatusValue = this.editFormData?.status;
    this.workflow = this.editFormData?.workflow?.map(({ _id, title, status, workflowStatus, isAdmin ,additionalModuleId}) => ({
      _id,
      title,
      status: status ? true : false,
      workflowStatus,
      isAdmin,
      additionalModuleId,
    }));
    this.getFormSettings(this.manageId);

  }

  loadManageFormLogs() {
    const { data, total, loading, ...rest } = this.manageFormLogTable;
    const subscribe = this.customFromService
      .getAllManageFormLogs(rest)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const { count, data } = response.data;
          this.manageFormLogTable.data = data;
          this.manageFormLogTable.total = count;
        },
        (error) => {
          this.manageFormLogTable.data = [];
          this.manageFormLogTable.total = 0;
          this.toastService.error('No list found');
        }
      );
  }

  onPrintWorkFlowPdf(id: string) {
    const data = {
      timeZone: new Date().getTimezoneOffset(),
      _id: id
    };
    const subscribe = this.customFromService
      .exportWorkFlowPdf(data)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          if (response?.data?.csvLink) {
            this.downloadAttachments(response?.data?.csvLink, "print.pdf");
          }
        },
        (error) => {
          this.toastService.error('Something went wrong. Please try again later.');
        }
      );
  }

  downloadAttachments(url, fileName) {
    this.fileDownloadService.downloadFile(url, fileName)

  }

  selectFormStatus(event) {
    const payload = {
      "customFormId": this.readManageForm.get('customFormId').value,
      "status": this.formStatusValue || null
    }
    this.customFromService.updateFormStatus(payload).subscribe(res => {
      this.toastService.success(res?.data?.message);
    }, (err) => {
      this.toastService.error('Something went wrong. Please try again later.');
    })

  }

  getWorkflowStatusCount(fieldName) {
    return this.workflowStatusArray?.find(item => item._id == fieldName)?.count || 0;
  }

  ngOnDestroy(): void {
    this.manageFormLogSearchSubscription &&
      this.manageFormLogSearchSubscription.unsubscribe();
  }
}
