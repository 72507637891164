<div class="card">
  <div class="card-header flexi-header p-4">
    <h3>EDIT QUESTION</h3>
  </div>

  <div class="card-body">
    <div class="row">
      <div class="col-md-3">
        <div class="card">
          <div class="card-header font-weight-bold">QUESTION TYPES</div>
          <div class="card-body" style="padding: 5px">
            <a class="list-group-item" (click)="openPopup(item)" *ngFor="let item of questionTypes">{{ item.name }}</a>
          </div>
        </div>
      </div>
      <div class="col-md-9">
        <div class="card">
          <div class="card-header-flexi">
            <div class="d-flex justify-content-between">
              <span>{{ selectedModule?.moduleName }}</span>
              <div>
                <button class="btn btn-submit" type="button" *ngIf="selectedModule?.status != 'active'"
                  [disabled]="selectedModule?.status == 1" (click)="saveAsDraft()">
                  Save as Draft
                </button>
                <button class="btn btn-add" type="button" (click)="openPublishPopup()"
                  [disabled]="selectedModule?.questions.length == 0">
                  Publish
                </button>
              </div>
            </div>
          </div>
          <div class="card-body" style="word-break: break-word; padding: 5px">
            <div class="box d-flex flex-column" *ngFor="let item of selectedModule?.questions; let i = index">
              <div class="d-flex">
                <strong class="mr-1">{{ i + 1 }} </strong>
                <div class="box-heading ml-2" style="word-break: break-word">
                  &emsp; <p [innerHTML]="item.question | safeHtml"></p>
                </div>
                <span class="pull-right" ngbDropdown style="margin-left: auto">
                  <button class="btn dropdown-toggle" ngbDropdownToggle>
                    <i class="fa fa-ellipsis-v"></i>
                  </button>
                  <ul class="dropdown-menu" ngbDropdownMenu>
                    <li (click)="onEditPopup(item)" ngbDropdownItem style="cursor: pointer">
                      <a>Edit</a>
                    </li>
                    <li ngbDropdownItem style="cursor: pointer" (click)="onDeleteQuestion(item._id, item.moduleId)">
                      <a>Delete</a>
                    </li>
                  </ul>
                </span>
              </div>
              <div class="box-body" *ngIf="item.type === 2">
                <div class="d-flex flex-column answers-options">
                  <div class="radio radio-danger radio-inline" *ngFor="let option of item.options">
                    <input type="radio" disabled class="disabled mt-2" />
                    <label for="consolidated">{{ option.value }}</label>
                  </div>
                </div>
              </div>

              <div class="box-body" *ngIf="item.type === 5">
                <div class="d-flex flex-column answers-options">
                  <div class="checkbox checkbox-danger checkbox-inline" *ngFor="let option of item.options">
                    <input type="checkbox" class="disabled mt-2" />
                    <label for="consolidated">{{ option.value }}</label>
                  </div>
                </div>
              </div>

              <div class="box-body" *ngIf="item.type === 4">
                <div class="d-flex flex-column answers-options">
                  <div class="radio radio-danger radio-inline" *ngFor="let item of item.options">
                    <input type="radio" disabled class="disabled mt-2" />
                    <label for="consolidated"> {{ item.value }}</label>
                  </div>
                </div>
              </div>
              <div class="box-body" *ngIf="item.type === 3">
                <div class="d-flex flex-column answers-options">
                  <div class="radio radio-danger radio-inline">
                    <input type="radio" disabled class="disabled" />
                    <label for="consolidated"> True</label>
                  </div>
                  <div class="radio radio-danger radio-inline">
                    <input type="radio" disabled class="disabled mt-2" />
                    <label for="consolidated"> False</label>
                  </div>
                </div>
              </div>

              <div class="box-body" *ngIf="item.type === 6">
                <div class="d-flex flex-row answers-options">
                  <textarea disabled cols="30" class="disabled form-control flex-fill" rows="5"></textarea>
                  <div class="flex-fill" style="width: 100%"></div>
                </div>
              </div>

              <div class="box-body" *ngIf="item.type === 7">
                <div class="d-flex flex-wrap answers-options">
                  <div class="form-group flex-fill" style="margin: 1rem 1rem; width: 44%"
                    *ngFor="let item of item.profile">
                    <label for="my-input">{{ item.questionName }}</label>
                    <input id="my-input" class="form-control" disabled type="text" />
                  </div>
                </div>
              </div>
              <div class="box-body" *ngIf="item.type === 1">
                <div class="d-flex flex-wrap answers-options">
                  <div class="form-group flex-fill" style="margin: 1rem 1rem; width: 44%">
                    <textarea disabled class="form-control" rows="3"></textarea>
                  </div>
                </div>
              </div>
              <div class="box-body" *ngIf="item.type === 8">
                <div class="d-flex flex-wrap answers-options">
                  <div class="form-group flex-fill" style="margin: 1rem 1rem; width: 44%">
                    <textarea disabled class="form-control" rows="5"></textarea>
                  </div>
                </div>
              </div>
              <div class="box-body" *ngIf="item.type === 9">
                <div class="d-flex flex-wrap answers-options">
                  <div class="form-group flex-fill" style="margin: 1rem 1rem; width: 44%">
                    <input type="number" disabled class="form-control" />
                  </div>
                </div>
              </div>
              <div class="box-body" *ngIf="item.type === 10">
                <div *ngFor="let option of item.dateTime">
                    <div class="d-flex flex-wrap answers-options"*ngIf="item.dateTime?.length === 2 && option === 2">
                      <div class="form-group flex-fill" style="margin: 1rem">
                        <label for="my-input">Date</label>
                        <input id="my-input" class="form-control" type="date" disabled />
                      </div>
                      <div class="form-group flex-fill" style="margin: 1rem">
                        <label for="my-input">Hours</label>
                        <select name="" id="" class="form-control" disabled></select>
                      </div>
                      <div class="form-group flex-fill" style="margin: 1rem">
                        <label for="my-input">Miniutes</label>
                        <select name="" id="" class="form-control" disabled></select>
                      </div>
                      <div class="form-group flex-fill" style="margin: 1rem">
                        <label for="my-input">Seconds</label>
                        <select name="" id="" class="form-control" disabled></select>
                      </div>
                    </div>
                    <div class="d-flex flex-wrap answers-options w-35"*ngIf="item.dateTime?.length === 1 && option ===1">
                      <div class="form-group flex-fill" style="margin: 1rem">
                        <label for="my-input">Date</label>
                        <input id="my-input" class="form-control" type="date" disabled />
                      </div>
                    </div>
                    <div class="d-flex flex-wrap answers-options"*ngIf="option === 2 && item.dateTime?.length === 1">
                      <div class="form-group flex-fill" style="margin: 1rem">
                        <label for="my-input">Hours</label>
                        <select name="" id="" class="form-control" disabled></select>
                      </div>
                      <div class="form-group flex-fill" style="margin: 1rem">
                        <label for="my-input">Miniutes</label>
                        <select name="" id="" class="form-control" disabled></select>
                      </div>
                      <div class="form-group flex-fill" style="margin: 1rem">
                        <label for="my-input">Seconds</label>
                        <select name="" id="" class="form-control" disabled></select>
                      </div>
                    </div>
                </div>
              </div>
              <div class="box-body w-35" *ngIf="item.type === 11">
                <select class="form-control" [attr.disabled]="true"></select>
              </div>
              <div class="box-body" *ngIf="item.type === 12">
                <div class="form-group">
                  <input id="my-input" disabled class="form-control-file" type="file" />
                </div>
                Preffered formats are -
                <span *ngFor="let item of item?.options">
                  {{ item.value + ',' }}<span *ngIf="item.length != 0"> </span>
                </span>
              </div>
              <div class="box-body" *ngIf="item.type === 13">
                <div class="d-flex">
                  <ngb-rating [max]="item.value" [rate]="0"></ngb-rating>
                  <!-- <i class="fa fa-star-o fa-2x" data-start="{{val}}"
                        ng-repeat="val in getStars(item)"></i> -->
                </div>
              </div>
              <div class="box-body w-35" *ngIf="item.type === 14">
                <select class="form-control" [attr.disabled]="true"></select>
              </div>
              <div class="box-body" *ngIf="item.type === 15">
                <div class="row" ng-model="item.options">
                  <div *ngFor="let option of item.options" class="sequenceQuestion-container text-center">
                    <img ng-if="option.imageSrc" [src]="option.imageSrc" style="height: 150px" crossorigin="anonymous" />
                    <div class="thumbnail flex-SB mt-3">
                      <div class="caption">
                        <p>Answer Option: {{ option.value }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="box-body" *ngIf="item.type === 16">
                <div style="text-align: center">
                  <img [src]="item.imageSrc" alt="Photo Pick Image" class="w-50">
                </div>
              </div>
              <div *ngIf="!selectedModule.questions.length" style="text-align: center">
                No questions to show
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #updateModule let-c="close" let-d="dismiss">
  <div>
    <div class="modal-content">
      <div class="modal-body" style="padding: 0px; min-height: 100vh">
        <div>
          <div class="card w-100">
            <div class="card-header flexi-header">
              <h3>UPDATE MODULE</h3>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <form [formGroup]="moduleUpdateForm">

                    <div class="form-group">
                      <label class="font-weight-bold" for="exampleInputEmail1">Module name</label>
                      <input type="text" class="form-control" formControlName="moduleName" placeholder="Module Name"
                        disabled />
                    </div>
                    <div class="form-group">
                      <div class="lableMandatory">
                        <label class="font-weight-bold" for="exampleInputEmail1">Welcome Message </label>
                        <span class="field-mandatory mandatory_Star">*</span>
                      </div>
                      <input type="text" class="form-control" formControlName="welComeMessage"
                        placeholder="welcome Message" />
                    </div>
                    <div class="custom-file mt-0 w-100 pt-4 pb-4" style="width: 67px"
                      *ngIf="!moduleUpdateForm.get('welComeAttachement').value">
                      <input
                        accept="image/png, image/gif, image/jpeg"
                        type="file"
                        style="
                            position: absolute;
                            z-index: 9;
                            visibility: visible;
                            left: 0;
                            top: 0;
                            width: 100%;
                            opacity: 0;"
                          (change)="welcomeBannerUpload($event)"
                          required
                          id="b"
                        />
                      <div class="lableMandatory">
                        <label class="font-weight-bold" class="custom-file-label" for="b">
                          Upload Welcome Attachement
                          <span class="field-mandatory mandatory_Star">*</span>
                        </label>
                      </div>
                    </div>
                    <div *ngIf="moduleUpdateForm.get('welComeAttachement').value">
                      <div class="image-with-delete-icon width-180 imgContent">
                        <i class="fa fa-close" title="Delete" for="4" (click)="onDeleteWelcomeImage()"></i>
                        <img [src]="
                            moduleUpdateForm.get('welComeAttachement').value
                          " id="4" class="centered-image" alt="Image Preview" crossorigin="anonymous"/>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="lableMandatory">
                        <label class="font-weight-bold" for="exampleInputEmail1">Closing Message</label>
                        <span class="field-mandatory mandatory_Star">*</span>
                      </div>
                      <input type="text" class="form-control" formControlName="closingMessage"
                        placeholder="closing Message" />
                    </div>
                    <div class="form-group">
                      <div class="lableMandatory">
                        <label for="" class="font-weight-bold">Post Submission message required?</label>
                        <span class="field-mandatory mandatory_Star">*</span>
                      </div>
                      <div class="d-flex">
                        <div class="radio radio-danger radio-inline p-1">
                          <input (click)="onPostSubmistionChange(true)" type="radio"
                            formControlName="postSubmissionRequired" id="postSubReqTrue" [value]="true" />
                          <label for="postSubReqTrue"> Yes</label>
                        </div>
                        <div class="radio radio-danger radio-inline p-1">
                          <input (click)="onPostSubmistionChange(false)" type="radio" id="postSubReqFalse"
                            formControlName="postSubmissionRequired" [value]="false" />
                          <label for="postSubReqFalse"> No</label>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="
                        moduleUpdateForm.get('postSubmissionRequired').value
                      ">
                      <div class="form-group">
                        <div class="lableMandatory">
                          <label class="font-weight-bold" for="exampleInputEmail1">Post Submission message</label>
                          <span class="field-mandatory mandatory_Star">*</span>
                        </div>
                        <input type="text" class="form-control" formControlName="postSubmissionMessage"
                          placeholder="Post Submission Message" />
                        <!-- <div
                      *ngIf="submitted && f.staffId.errors"
                      class="invalid-feedback"
                    >
                      <small *ngIf="f.staffId.errors.required">
                        StaffId is required
                      </small>
                    </div> -->
                      </div>

                      <div class="custom-file mt-0 w-100 pt-4 pb-4" style="width: 67px" *ngIf="
                          !moduleUpdateForm.get('postSubmissionImage').value
                        ">
                        <input
                          accept="image/png, image/gif, image/jpeg"
                          type="file"
                          style="
                              position: absolute;
                              z-index: 9;
                              visibility: visible;
                              left: 0;
                              top: 0;
                              width: 100%;
                              opacity: 0;"
                            (change)="postSubmissionImageUpload($event)"
                            required
                            id="b"
                            />
                        <label class="font-weight-bold" class="custom-file-label" for="b">
                          Upload Post Submission message Image
                        </label>
                      </div>
                      <div *ngIf="
                          moduleUpdateForm.get('postSubmissionImage').value
                        ">
                        <div class="image-with-delete-icon width-180">
                          <i class="fa fa-close" title="Delete" for="4" (click)="onDeletePostSumissionImage()"></i>
                          <img [src]="
                              moduleUpdateForm.get('postSubmissionImage').value
                            " class="centered-image" id="4" alt="Image Preview" crossorigin="anonymous"/>
                        </div>
                      </div>
                      <div class="mt-2">
                        <label class="font-weight-bold">Post Submission display submitted responses</label>
                      </div>
                      <div class="ml-2">
                        <label class="font-weight-bold">Question Title</label>
                        <ng-select (change)="getSubmissionQuestions($event)" class="custom" bindLabel="name"
                          placeholder="Select Questions" appendTo="body" [multiple]="false" [searchable]="true"
                          [clearable]="true">
                          <ng-option [value]="item" *ngFor="let item of selectedModule?.questions">
                            <div>
                              <p [innerHTML]="item.question | safeHtml"></p>
                            </div>
                          </ng-option>
                        </ng-select>
                      </div>
                      <table class="table table-borderless mt-3">
                        <tbody>
                          <tr formArrayName="postSubmissionResponse" *ngFor="
                              let field of postSumissionArray.controls;
                              let index = index
                            ">
                            <td>
                              <span class="text-items" [formGroupName]="index">
                                <p [innerHTML]="field.get('name').value |safeHtml"></p>
                              </span>
                            </td>
                            <td>
                              <img class="ml-1 cancel-button" alt="" (click)="deletePostSubmission(index)"
                                src="../../../../../../assets/images/cancel.png" width="20px" height="20px" crossorigin="anonymous" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col" sortable="Tier 1">QUESTIONS</th>
                            <th scope="col" sortable="Tier 2">
                              Make this question mandatory
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of selectedModule?.questions">
                            <td style="word-break: break-all">
                              <p [innerHTML]="item.question | safeHtml"></p>
                            </td>

                            <td class="text-center">
                              <input class="text-center" type="checkbox" [checked]="item.required" [value]="item._id"
                                (change)="
                                  addQuestionToMandatory($event)
                                " />
                            </td>

                            <td class="text-center" *ngIf="isScoring">
                              <input class="text-center" type="checkbox" checked disabled/>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="form-group">
                      <div class="lableMandatory">
                        <label for="" class="font-weight-bold">Sequence Type</label>
                        <span class="field-mandatory mandatory_Star">*</span>
                      </div>
                      <div class="d-flex">
                        <div class="radio radio-danger radio-inline p-1">
                          <input type="radio" (click)="onSequenceChange(false)" formControlName="randomOrder"
                            id="postSubReqTrue1" [value]="false" />
                          <label for="postSubReqTrue1">
                            All questions, by order given
                          </label>
                        </div>
                        <div class="radio radio-danger radio-inline p-1">
                          <input type="radio" id="postSubReqFalse2" (click)="onSequenceChange(true)"
                            formControlName="randomOrder" [value]="true" />
                          <label for="postSubReqFalse2">
                            Randomise questions
                          </label>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="sequenceType === true">
                      <div class="form-group">
                        <label class="font-weight-bold" for="exampleInputEmail1">Mandatory Questions</label>
                        <input type="text" class="form-control" placeholder="" [value]="madatoryQuestionsArray.length"
                          disabled />
                      </div>
                      <div class="form-group">
                        <div class="lableMandatory">
                          <label class="font-weight-bold" for="exampleInputEmail1">No of Questions to be answered</label>
                          <span class="field-mandatory mandatory_Star">*</span>
                        </div>
                        <input type="text" class="form-control" formControlName="viewCount" placeholder=""
                          [ngClass]="{ 'is-invalid': f.viewCount.errors }" />

                        <div *ngIf="f.viewCount.errors" class="invalid-feedback">
                          <small *ngIf="f.viewCount.errors.pattern">
                            Value should be not more than {{ questionsLength }} and greater than 0
                          </small>
                        </div>

                        <div *ngIf="f.viewCount.errors" class="invalid-feedback">
                          <small *ngIf="f.viewCount.errors.required">
                            Value should not be empty
                          </small>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <div class="p-1">
                          <input type="checkbox" formControlName="scoringEnabled" id="postSubReqTrue12"
                            [value]="true" (click)="enableScoring()"/>
                          <label for="postSubReqTrue12" class="ml-3">
                            Enable Scoring
                          </label>
                        </div>
                        <div *ngIf="moduleUpdateForm.get('scoringEnabled').value">
                          <select class="dropdown-select w-100 form-control" formControlName="scorePerQuestion">
                            <option [value]="null" [selected]="true">
                              Select Score
                            </option>
                            <option *ngFor="let cnt of scores" [ngValue]="cnt">
                              {{ cnt }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="p-3">
              <button class="btn btn-clear" (click)="d('dismiss')">
                Close
              </button>
              <button class="btn btn-add ml-2" [disabled]="!moduleUpdateForm.valid"
                (click)="moduleUpdate()">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
