import { Component, OnInit, Injectable } from '@angular/core';
import {
  NgbDateStruct,
  NgbModal,
  NgbCalendar,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDate,
} from '@ng-bootstrap/ng-bootstrap';
import { isSameDay, isSameMonth } from 'date-fns';
import {
  CalendarEvent,
  CalendarMonthViewBeforeRenderEvent,
  CalendarMonthViewDay,
  CalendarView,
} from 'angular-calendar';
import * as moment from 'moment';
import Swal from 'sweetalert2';

import { ToastrService } from 'ngx-toastr';
import { LeavePlannerService } from 'src/app/users/service/human-resources/leave-planner.service';
import { UsersService } from 'src/app/users/service/user-service/users.service';
import { finalize, map } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { RoleService } from 'src/app/users/service/user-roles/role.service';
import { CompanySetupService } from 'src/app/users/service/company-setup/company-setup.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SetupService } from 'src/app/users/service/operations/setup.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
  readonly DELIMITER = '-';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date
      ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year
      : null;
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year
      : '';
  }
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class DashboardComponent implements OnInit {
  setup;
  logsPTable;
  fromDate: any;
  changefromDate: any;
  changetoDate: any;
  fromDateExport: NgbDateStruct;
  toDateExport: NgbDateStruct;
  toDate: any;
  staffList: any[];
  staffLeaveType: any[];
  isLeaveSwap: boolean = false;
  leaveTypeId: any;
  leaveTypeGroupId: any;
  startDate: string;
  endDate: string;
  leaveGroupId: any;
  leaveHistory: any;
  activeDayIsOpen: boolean;
  leaveType: any;
  leaveTypeData: any[];
  leavesCal: any[];
  selectedDate: string;
  quotaData: { leaveApplied: any };
  quotaUsedData: any;
  quotaBalanceData: any;
  appliedLeaveType: any = [];
  viewUserDet: any;
  clickedDate: Date;
  calendarData: any;
  selectedMonthViewDay: CalendarMonthViewDay;
  selectedDays: any = [];
  showLog: boolean;
  changeAllocateData: any;
  displayDateEnd: any;
  displayDate: any;
  swapValue: any;
  submittedFromValue: any;
  seeLogsData: Object;
  highlightIndex: any;
  swapLogData: any;
  events$: Observable<any>;
  firstActive: boolean;
  noLeave = [];
  viewAccess: boolean = false;
  manageAccess: boolean = false;
  leaveCalenderLoader: boolean;
  viewclicked: boolean = false;
  allocateLoader: boolean;
  leaveHistoryLoader: boolean;
  logLoader: boolean;
  timeZone: string;
  teamListExport: any;
  exportLoader: boolean;
  buLoader: boolean;
  annualLeave: any;
  imageURL:string= environment.imageUrl;
  get today() {
    return this.dateAdapter.toModel(this.ngbCalendar.getToday())!;
  }
  opsGrpSelect;
  opsTeamSelect;
  opsStaffSelect;
  minDate: NgbDate | null;
  date: { year: number; month: number };
  opsTeam: any;
  opsList: any;
  yearRadio = new Date().getFullYear();
  loggedInUser: any;
  yearArr: number[] = [
    parseInt(moment().subtract(1, 'year').format('YYYY')),
    parseInt(moment().format('YYYY')),
    parseInt(moment().add(1, 'year').format('YYYY')),
  ];
  swapRadio = 3;
  swapRadioChange = '3';
  buList: any;
  leaves: Object;
  leaveData: any;
  calendarDate: any;
  refresh: Subject<any> = new Subject();
  dashParaForm: FormGroup;
  allocationMinDate= {year:new Date().getFullYear(), month: 1, day: 1};
  constructor(
    private modalService: NgbModal,
    private usersService: UsersService,
    private leaveService: LeavePlannerService,
    private ngbCalendar: NgbCalendar,
    private dateAdapter: NgbDateAdapter<string>,
    public toastrService: ToastrService,
    private roleService: RoleService,
    private companyService: CompanySetupService,
    private fb: FormBuilder,
    private setupService: SetupService
  ) {
    this.logsPTable = {
      sortBy: 'desc',
      sortWith: 'createdBy',
      data: [],
      page: 1,
      limit: 10,
    };

    this.dashParaForm = this.fb.group({
      buOps: new FormControl('true', [Validators.required]),
      buSelect: new FormControl(null, [Validators.required]),
      opsGrpSelect: new FormControl(null),
      opsTeamSelect: new FormControl(null),
      yearRadio: new FormControl(this.yearArr[1], [Validators.required]),
    });

    // switch validators on BU and Ops Grp Selection
    this.dashParaForm.controls.buOps.valueChanges.subscribe(() =>
      this.switchValidators()
    );

    this.timeZone = moment().format('[GMT]Z');
  }

  view: CalendarView = CalendarView.Month;
  viewDate: Date = new Date(this.yearRadio, 0, 1);
  viewDate2: Date = moment().add(1, 'month').toDate();
  locale: string = 'en';
  events: CalendarEvent[] = [];

  ngOnInit(): void {
    this.getBuShift();
    const loggedInUserID = localStorage.getItem("loggedInUserId");
    const bu = this.setupService
      .getOPSGroupAndTeam(loggedInUserID)
      //.pipe(concatMap((res: { data }) => this.getLoggedUser(res)))
      .subscribe((data) => {
        this.leaveData = data['data']['leavedata'];
        this.opsList = data['data']['opsids'];
      });
    const privileges = this.roleService
      .getNewUserPrivilege()
      .pipe(
        finalize(() => {
          privileges.unsubscribe();
        })
      )
      .subscribe((res) => {
        this.manageAccess = res.privileges.hasOwnProperty(
          'leavePlannerDashboardManage'
        )
          ? res.privileges.leavePlannerDashboardManage
          : false;
      });

    this.getSetup();
  }
  setYearRadio(event){
    this.yearRadio = event;

  }

  getLoggedUser(data) {
    this.loggedInUser = data.data;
    return this.leaveService.getSwapOpsGroup(data.data._id);
  }

  switchValidators() {
    this.viewclicked = false;
    this.viewUserDet = null;
    this.leaveTypeData = [];
    if (this.dashParaForm.controls.buOps.value == 'true') {
      this.dashParaForm.controls.buSelect.setValidators(Validators.required);
      this.dashParaForm.controls.opsGrpSelect.clearValidators();
    } else {
      this.dashParaForm.controls.opsGrpSelect.setValidators(
        Validators.required
      );
      this.dashParaForm.controls.buSelect.clearValidators();
    }
    this.dashParaForm.get('buSelect').updateValueAndValidity();
    this.dashParaForm.get('opsGrpSelect').updateValueAndValidity();
  }

  getSetup() {
    this.companyService.getcompanySetting().subscribe((res) => {
      this.setup = res.data.opsGroup;
    });
  }

  getOpsTeam() {
    this.dashParaForm.patchValue({
      opsTeamSelect: null, //reset team after grp change
    });
    this.opsTeam = this.dashParaForm.controls.opsGrpSelect.value.team;
  }

  getExportTeam() {
    this.opsTeamSelect = null; //reset team after grp change
    this.teamListExport = this.opsGrpSelect.team;
  }

  viewLeaveAndUser() {
    if (this.dashParaForm.controls.buOps.value == 'true') {
      this.calendarData = null;
      this.viewclicked = true;
    }

    this.firstActive = true;
    this.selectedDays = this.appliedLeaveType = [];

    const date = '01-01-' + this.yearRadio;
    this.selectedDate = moment(date, 'MM-DD-YYYY').format('DD MMM, yyyy');
    this.viewDate = moment(date, 'MM-DD-YYYY').toDate();
    this.leaveCalenderLoader = true;
    this.refresh.next();
    this.getLeaveAndUser(moment(date, 'MM-DD-YYYY').toDate());
     this.allocationMinDate= { year: this.yearRadio, month: 1, day: 1 };
  }

  getstaffUsers() {
    const form = this.dashParaForm.controls;
    let staffObj = {
      isOpsGroup: form.buOps.value == 'false',
    };

    staffObj.isOpsGroup
      ? (staffObj['opsGroupId'] = form.opsGrpSelect.value?.id)
      : (staffObj['buId'] = form.buSelect.value?._id);

    const staffUsers = this.leaveService
      .getBallotUser(staffObj)
      .pipe(
        finalize(() => {
          staffUsers.unsubscribe();
        })
      )
      .subscribe((res: { data: any; success: boolean }) => {
        this.staffList = res.success ? res.data : [];
        this.staffList = this.staffList.map((i) => { i.staffName = `${i.name} ( ${i.staffId} )`; return i; }) 
      });
  }

  //getting staffLeaveType and LeaveHistory in 1 api
  handleStaffSelect() {
    this.annualLeave = null;
    const staffObj = {
      userId: this.opsStaffSelect?._id,
      year: this.yearRadio,
    };
    this.leaveHistory =[];
    this.leaveHistoryLoader = true;
    const leaveHistoryObj = this.leaveService
      .newLeavePlanner(staffObj)
      .pipe(
        finalize(() => {
          this.leaveHistoryLoader = false;
          leaveHistoryObj.unsubscribe();
        })
      )
      .subscribe(
        (res: {
          success: boolean;
          leaveTypeData: any;
          leaveApplied: any;
          leaveGroupId: any;
        }) => {
          if (res.success) {
            this.staffLeaveType = res.leaveTypeData
              ? res.leaveTypeData.filter((val) => {
                  return val.isAdminAllocate;
                })
              : [];

            this.isLeaveSwap = false;
            this.leaveGroupId = res.leaveGroupId ?? '';

            //get annual leave: Quota balance & taken
            this.annualLeave = res.leaveTypeData.find(
              (val) => val.leaveTypeName == 'Annual Leave'
            );

            if (res.leaveApplied.length) {
              //sort by date ascending
              this.leaveHistory = res.leaveApplied.sort(
                (a, b) =>
                  parseInt(moment(a.startDate).format('X')) -
                  parseInt(moment(b.startDate).format('X'))
              );
            }

            //change and cancel leave history btn
            if (res.leaveApplied?.length) {
              this.leaveHistory?.forEach((v) => {
                const start = v.startDate;
                var lastweek = moment(start).subtract(
                  this.setup.adminCutOffTime,
                  'w'
                );
                if (
                  v.status != 1 &&
                  v.status != 5 &&
                  this.setup.isAdminCanChangePlan
                ) {
                  v['canChangePlanShow'] = true;
                  if (moment().isAfter(lastweek))
                    v['canChangePlanDisable'] = true;
                  else v['canChangePlanDisable'] = false;
                } else v['canChangePlanShow'] = false;

                if (
                  v.status == 1 &&
                  v.status != 5 &&
                  this.setup.isadminCanChange
                ) {
                  v['canChangeActualShow'] = true;
                  if (moment().isAfter(lastweek))
                    v['canChangeActualDisable'] = true;
                  else v['canChangeActualDisable'] = false;
                } else v['canChangeActualShow'] = false;

                //canCancel
                if (
                  v.status != 1 &&
                  v.status != 5 &&
                  this.setup.isAdminCanCancelPlan
                ) {
                  v['canCancelPlanShow'] = true;
                  if (moment().isAfter(lastweek))
                    v['canCancelPlanDisable'] = true;
                  else v['canCancelPlanDisable'] = false;
                } else v['canCancelPlanShow'] = false;

                if (
                  v.status == 1 &&
                  v.status != 5 &&
                  this.setup.isAdminCanCancel
                ) {
                  v['canCancelActualShow'] = true;
                  if (moment().isAfter(lastweek))
                    v['canCancelActualDisable'] = true;
                  else v['canCancelActualDisable'] = false;
                } else v['canCancelActualShow'] = false;
              });
            }
          } else this.toastrService.error('Somthing went wrong', 'Failed');
        },
        () => {
          this.annualLeave = null;
        }
      );
  }

  handleLeaveType(staffLeavetype) {
    this.leaveTypeId = staffLeavetype.leaveTypeId;
    this.leaveTypeGroupId = staffLeavetype._id;
    this.isLeaveSwap =
      staffLeavetype.leaveTypeName === 'Annual Leave' ? true : false;
  }

  getBuShift() {
    this.buLoader = true;
    const buplan = this.setupService
      .getBusinessUnitBUShift()
      .pipe(
        finalize(() => {
          this.buLoader = false;
          buplan.unsubscribe();
        })
      )
      .subscribe((res: { data: any }) => {
        this.buList = res.data.planBussinessUnitId;
      });
  }

  open(content) {
    this.modalService
      .open(content, { windowClass: 'modal-ui-fix right-align' })
      .result.then(
        () => this.resetManageLeave(),
        () => this.resetManageLeave()
      );
  }

  resetManageLeave() {
    this.leaveHistory = this.staffLeaveType = [];
    this.opsStaffSelect = null;
    this.resetAllocateLeave();
    this.resetExport();
  }

  resetAllocateLeave() {
    this.fromDate = null;
    this.toDate = null;
    this.swapRadio = 3; //reset to swap value 3 means nor Yes neither No
    this.isLeaveSwap = false; //reset swap hide
    this.showLog = false; //reset log hidden
  }

  getLeavecalender() {
    // get ops calendar data for pre color dates on basis of quota
    if (this.dashParaForm.controls.buOps.value == 'false') {
      const payload = {
        opsGroupId: this.dashParaForm.get('opsGrpSelect').value.id,
        year: this.yearRadio,
      };
      if (this.dashParaForm.get('opsTeamSelect').value) {
        payload['opsTeamId'] = this.dashParaForm.get('opsTeamSelect').value.id;
      }

      this.viewDate = moment()
        .set({ y: this.yearRadio, month: 0, date: 1 })
        .toDate();

      this.events$ = this.leaveService.getcalenderforyear(payload);
      this.leaveCalenderLoader = true;
      this.events$.subscribe(
        (res: { data }) => {
          if (res.data != null) {
            this.calendarData = res.data[this.yearRadio.toString()];
          } else {
            this.toastrService.info(
              'Ops Group/Team quota is not added.',
              'Note'
            );
            this.calendarData = null;
          }
          this.viewclicked = true;
        },
        () => {},
        () => (this.leaveCalenderLoader = false)
      );
    }
  }

  leavePlannerUserByDate() {
    if (this.dashParaForm.controls.buOps.value == 'false') {
      this.selectedDate = moment()
        .set({ y: this.yearRadio, month: 0, date: 1 })
        .format('DD MMM, YYYY');

      const tz = 'GMT+0530';
      let usersData = {
        opsGroupId: this.dashParaForm.controls.opsGrpSelect.value.id,
        year: this.yearRadio,
        date: '01-01-' + this.yearRadio,
        timeZone: tz,
      };
      if (this.dashParaForm.controls.opsTeamSelect.value)
        usersData['opsTeamId'] =
          this.dashParaForm.controls.opsTeamSelect.value.id;

          this.leaveService
        .leavePlanner(usersData)
        .pipe(
          map((val) => {
            const la = val['leaveApplied'].filter((lv) => {
              if (lv.status != 2) {
                return true;
              }
            });

            val['leaveApplied'] = la;
            return val;
          })
        )
        .subscribe((res) => {
          this.quotaData = res['data'].date?.value;
          this.quotaUsedData = res['data'].totalLeaveApplied;
          this.quotaBalanceData = res['data'].balance;
        });
    }
  }

  handleAllocate() {
    const overLapObj = {
      leaveTypeId: this.leaveTypeId,
      leaveGroupId: this.leaveGroupId,
      userId: this.opsStaffSelect?._id,
      businessUnitId: '',
      timeZone: '+0530',
    };

    const allocateObj = {
      ...new Object(overLapObj),
      startDate: this.startDate,
      endDate: this.endDate,
      type: this.leaveTypeId,
      isSwappable: this.swapRadio,
      remark: '',
      submittedFrom: 3,
      startAt: 'AM',
      endAt: 'AM',
    };
    const swd = moment().add(this.setup.adminCutOffTime, 'weeks');
    this.allocateLoader = true;
    if (moment(this.fromDate, 'DD-MM-YYYY').isAfter(swd)) {
      overLapObj['startDate'] = this.startDate + ' 00:00:00 GMT+0530';
      overLapObj['endDate'] = this.endDate + ' 00:00:00 GMT+0530';
      const checkOverlap = this.leaveService
        .checkOverLap(overLapObj)
        .pipe(
          finalize(() => {
            checkOverlap.unsubscribe();
          })
        )
        .subscribe((res: { success: boolean; message: string }) => {
          if (res.success) {
            Swal.fire({
              title: 'Are you sure?',
              text: 'You want to Allocte this?',
              icon: 'question',
              showCancelButton: true,
              cancelButtonText: 'No',
              confirmButtonText: 'Yes',
            }).then((result) => {
              if (result.isConfirmed) {
                if (this.dashParaForm.controls.buOps.value == 'false') {
                  //buOps false is Ops Group is selected
                  allocateObj['opsGroupId'] =
                    this.dashParaForm.controls.opsGrpSelect.value.id;
                  if (this.dashParaForm.controls.opsTeamSelect.value) {
                    allocateObj['opsTeamId'] =
                      this.dashParaForm.controls.opsTeamSelect.value.id;
                  }
                }
                const allocate = this.leaveService
                  .getLeaveAllocate(allocateObj)
                  .pipe(
                    finalize(() => {
                      this.allocateLoader = false;
                      allocate.unsubscribe();
                    })
                  )
                  .subscribe((res: { success: boolean; message: string }) => {
                    if (res.success) {
                      this.toastrService.success(res.message, 'Successful');
                      this.handleStaffSelect();
                      this.getLeaveAndUser(this.clickedDate); //refresh Leave and User on dashboard
                      this.seeLogsButton();
                      this.resetAllocateLeave(); //allocate form reset
                    } else {
                      res.message
                        ? this.toastrService.warning(res.message, 'Warning')
                        : this.toastrService.error(
                            'Somthing went wrong',
                            'Failed'
                          );
                    }
                  });
              } else {
                this.allocateLoader = false;
              }
            });
          } else {
            this.allocateLoader = false;
            res.message
              ? this.toastrService.warning(res.message, 'Warning')
              : this.toastrService.error('Somthing went wrong', 'Failed');
          }
        });
    } else {
      this.allocateLoader = false;
      this.toastrService.warning(
        'Cut-off time is set for allocation of leaves, please check in the company setup',
        'Warning'
      );
    }
  }

  getLeaveAndUser(date) {
    const leaveTypePara = {
      date: moment(date).format('MM-DD-yyyy'),
      timeZone: 'GMT+0530',
      year: this.yearRadio
    };
    this.leavesCal = this.leaveTypeData = this.appliedLeaveType = [];
    if (this.dashParaForm.controls.buOps.value == 'true') {
      leaveTypePara['buId'] = this.dashParaForm.controls.buSelect.value._id;
      this.buLeaveType(leaveTypePara);
      this.buUser(leaveTypePara);
    } else {
      leaveTypePara['opsGroupId'] =
        this.dashParaForm.controls.opsGrpSelect.value?.id;
      if (this.dashParaForm.controls.opsTeamSelect.value) {
        leaveTypePara['opsTeamId'] =
          this.dashParaForm.controls.opsTeamSelect.value?.id;
      }
      this.opsLeaveType(leaveTypePara);
      this.opsUser(leaveTypePara);
    }
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      )
        this.activeDayIsOpen = false;
      else this.activeDayIsOpen = true;
      this.viewDate = date;
    }

    this.clickedDate = date;
    this.viewUserDet = null; //reset user view

    this.selectedDate = moment(date).format('DD MMM, yyyy');
    this.getLeaveAndUser(this.clickedDate);
  }

  opsLeaveType(data: Object) {
    this.leaveService
      .getLeaveType(data)
      .subscribe((res: { leaveType: any }) => {
        this.leaveTypeData = res.leaveType;
        this.noLeave = [];
        this.noLeave = this.leaveTypeData.filter((nl) => {
          if (nl.total != 0) return true;
        });
      });
  }

  buLeaveType(data: Object) {
    this.events$ = this.leaveService.getLeaveTypeBu(data);
    this.events$.subscribe((res: { leaveType: any[] }) => {
      this.leaveTypeData = res.leaveType;
      this.noLeave = [];
      this.noLeave = this.leaveTypeData.filter((nl) => {
        if (nl.total != 0) return true;
      });
    });
  }

  opsUser(data: Object) {
    this.leaveService
      .leavePlanner(data)
      .subscribe((res: { leaveApplied: any; data: any }) => {
        this.leavesCal = res.leaveApplied;
        this.quotaData = res.data.date?.value;
        this.quotaUsedData = res.data.totalLeaveApplied;
        this.quotaBalanceData = res.data.balance;
        this.leavesCal.forEach((item) => {
          this.appliedLeaveType.push(item.leaveTypeId);
        });
      });
  }

  buUser(data: Object) {
    this.leaveService
      .leavePlannerBu(data)
      .pipe(
        map((val) => {
          const la = val['leaveApplied'].filter((lv) => {
            if (lv.status != 2) return true;
          });
          val['leaveApplied'] = la;
          return val;
        }),
        finalize(() => {
          this.leaveCalenderLoader = false;
        })
      )
      .subscribe((res: { leaveApplied: any }) => {
        this.appliedLeaveType = [];
        this.leaves = res;
        this.leavesCal = res.leaveApplied;
        this.leavesCal.forEach((item) => {
          this.appliedLeaveType.push(item.leaveTypeId);
        });
        this.leaveTypeData = this.appliedLeaveCount(this.appliedLeaveType);
        this.noLeave = [];
        this.noLeave = this.leaveTypeData.filter((nl) => {
          if (nl.total != 0) return true;
        });
      });
  }

  selectedD(day: CalendarMonthViewDay): void {
    this.selectedDays = [];

    if (
      day.date.getTime() !=
      moment()
        .set({
          year: this.yearRadio,
          month: 0,
          date: 1,
          h: 0,
          minute: 0,
          second: 0,
        })
        .toDate()
        .getTime()
    ) {
      this.selectedDays = [];
    }
    this.selectedMonthViewDay = day;
    delete this.selectedMonthViewDay.cssClass;
    this.selectedMonthViewDay.cssClass = 'cal-day-selected';
    this.selectedDays.push(this.selectedMonthViewDay);
    day.cssClass = 'cal-day-selected';
    this.selectedMonthViewDay = day;
    this.firstActive = false;
  }

  beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
    renderEvent.body.forEach((day) => {
      const dayOfMonth = moment(day.date).format('DD-MM-YYYY');

      if (this.calendarData) {
        const dv = this.calendarData.findIndex((v) => {
          if (dayOfMonth == v.date) return true;
        });

        if (dv != -1) {
          if (
            this.calendarData[dv].value > 0 &&
            this.calendarData[dv].value != 0
          ) {
            day.cssClass = 'cal-balance-notzero';
          } else if (this.calendarData[dv].value < 1) {
            day.cssClass = 'cal-balance-zero';
          }
        }
      }

      if (
        dayOfMonth ==
          moment()
            .set({ year: this.yearRadio, month: 0, date: 1 })
            .format('DD-MM-YYYY') &&
        this.firstActive
      )
        this.selectedDays.push(day);

      if (
        this.selectedDays.some(
          (selectedDay) => selectedDay.date.getTime() == day.date.getTime()
        )
      )
        day.cssClass = 'cal-day-selected';
    });
  }

  appliedLeaveCount(arr) {
    let objArr = [
      ...arr.map((item) => {
        return item.name;
      }),
    ];
    const uniqueArr = [...new Set(objArr)];
    const arrObj3 = [];
    for (let index = 0; index < uniqueArr.length; index++) {
      const count = this.getOccurence(objArr, uniqueArr[index]);
      arrObj3.push({ name: uniqueArr[index], total: count });
    }
    return arrObj3;
  }

  getOccurence(arr, val) {
    return arr.filter((value) => value === val).length;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  viewUser(history, index) {
    this.viewUserDet = history;
    this.highlightIndex = index;
  }

  remove(item) {
    const cancelObj = { leaveAppliedId: item._id };
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to cancel this leave',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        this.leaveService.cancel(cancelObj).subscribe((res) => {
          this.toastrService.success('Leave successfully cancelled');
          this.handleStaffSelect();
        });
      }
    });
  }

  changeAllocateModal(changeModal, item) {
    this.changeAllocateData = item;
    this.displayDate = moment(item?.startDate).format('DD-MM-YYYY');
    this.displayDateEnd = moment(item?.endDate).format('DD-MM-YYYY');
    this.swapValue = item.isSwappable;
    this.swapRadioChange = item.isSwappable.toString();
    this.submittedFromValue = item.submittedFrom;
    this.changefromDate = moment(item.startDate).format('D-M-YYYY');
    this.changetoDate = moment(item.endDate).format('D-M-YYYY');
    this.modalService.open(changeModal);
  }

  changeAllocate() {
    const changeObj = {
      userId: this.changeAllocateData.userId,
      startDate: moment(this.changefromDate, 'D-M-YYYY').format('MM-DD-YYYY'),
      endDate: moment(this.changetoDate, 'D-M-YYYY').format('MM-DD-YYYY'),
      remark: 'medical',
      timeZone: this.timeZone,
      leaveAppliedId: this.changeAllocateData._id,
      startAt: 'AM',
      endAt: 'AM',
      isSwappable: this.swapRadioChange,
    };
    this.leaveService
      .change(changeObj)
      .subscribe((res: { success: boolean; message: string }) => {
        if (res.success) {
          this.toastrService.success(res.message, 'Successful');
          this.resetChange();
          this.handleStaffSelect();
        } else {
          res.message
            ? this.toastrService.error(res.message, 'Failed')
            : this.toastrService.error('Something went wrong', 'Failed');
        }
      });
  }

  resetChange() {
    this.changefromDate = this.changetoDate = null;
    this.swapRadioChange = '3';
  }

  exportButton() {
    const exportObj = {
      startDate: moment(this.fromDateExport, 'D-M-YYYY').format('MM-DD-YYYY'),
      endDate: moment(this.toDateExport, 'D-M-YYYY').format('MM-DD-YYYY'),
      timeZone: this.timeZone,
    };

    if (this.opsGrpSelect && !this.opsTeamSelect) {
      exportObj['opsGroupId'] = this.opsGrpSelect.id;
      exportObj['opsTeamId'] = null;
    } else if (this.opsGrpSelect && this.opsTeamSelect) {
      exportObj['opsGroupId'] = this.opsGrpSelect.id;
      exportObj['opsTeamId'] = this.opsTeamSelect.id;
    }
    this.exportLoader = true;
    this.leaveService.export(exportObj).subscribe((res) => {
      this.exportLoader = false;
      var a = document.createElement('a');
      a.href = 'data:attachment/csv,' + encodeURI(res['csv']);
      a.target = '_blank';
      a.download = "Leaves.csv";
      document.body.appendChild(a);
      a.click();
      this.toastrService.success(
        "success",
        "Leaves exported succesfully"
      );
    }, () => {
      this.toastrService.error('Something went wrong', 'Failed');
    });
  }

  resetExport() {
    this.opsGrpSelect = this.opsTeamSelect = null;
    this.fromDateExport = this.toDateExport = null;
  }

  seeLogsButton() {
    this.showLog = true;
    const logObj = {
      userId: this.opsStaffSelect._id,
      year: this.yearRadio,
    };
    this.logLoader = true;
    const log = this.leaveService
      .seeLogs(logObj)
      .pipe(
        finalize(() => {
          this.logLoader = false;
          log.unsubscribe();
        })
      )
      .subscribe((res: { data: any }) => {
        this.seeLogsData = res.data;
        this.logsPTable.page = 1;
      });
  }

  gotToSwapLog(data, swapLogsModal) {
    this.swapLogData = data;
    this.modalService.open(swapLogsModal);
  }

  getStartDate(e) {
    this.startDate = this.momFormat(e);
    this.allocationMinDate = {year:e.year,month:e.month,day:e.day};
  }

  getEndDate(e) {
    this.endDate = this.momFormat(e);
  }

  onFromDateChange(event){
    this.toDate=null;
    if(!event){
      this.allocationMinDate={year:this.yearRadio,month:1,day:1};

    }
  }
  //common method from above methods MM-DD-YYYY date format
  momFormat(e) {
    return moment()
      .set({ year: e.year, month: e.month - 1, date: e.day })
      .format('MM-DD-YYYY');
  }

  validateURLImage(str) {
    var img_Path = str;
    if (img_Path.indexOf("https://") == 0) {
      var new_url = new URL(str);
     var pathName = (new_url.pathname.startsWith('//')) ? `/${new_url.pathname.split("//")[1]}` : new_url.pathname;
      return `${this.imageURL}${pathName}`;
    }
    if (str.startsWith('/')) return this.imageURL + str;
    return `${this.imageURL}/${str}`;
   }
}
