import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

// text,email,tel,textarea,password,
@Component({
    selector: 'textarea',
    template: `

        <textarea [attr.disabled]="field.disabled? field.disabled :null" [class.is-invalid]="isDirty && !isValid" [formControl]="form" [id]="field.name"
        rows="9" class="form-control" [placeholder]="field.placeholder"></textarea>
    `
})
export class TextAreaComponent {
    @Input() field:any = {};
    @Input() form:FormControl;
    get isValid() { return this.form.valid; }
    get isDirty() { return this.form.dirty; }

    constructor() {

    }
}
