import { Component, EventEmitter, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, of } from 'rxjs';
import { map, debounceTime, distinctUntilChanged, mergeMap, delay, finalize } from 'rxjs/operators';
import { tableParam } from 'src/app/core/model/table-param.model';
import { TableService } from 'src/app/core/service/table.service';
import { SortableHeader, SortEvent } from 'src/app/shared/directive/sortable.directive';
import { GamificationService } from 'src/app/users/service/gamification/gamification.service';
import { UsersService } from 'src/app/users/service/user-service/users.service';

@Component({
  selector: 'challenges-log',
  templateUrl: './challenges-log.component.html',
  styleUrls: ['./challenges-log.component.scss']
})
export class ChallengesLogComponent implements OnInit {
  @Output() ViewEvent = new EventEmitter<string>();
  logTableColumn = ['user.name', 'createdAt', 'title', 'logDescription', 'challenge.criteriaSourceType'];
  tableQueryParams = tableParam;
  // isBuUnitEdited:boolean = false
  // sectionId:string
  // subSectionId:string
  // bussinessUnitEditForm:FormGroup
  public userssearchInput = new Subject<KeyboardEvent>();
  isTableLoading: boolean = false;
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  usersPTable: { sortWith: string; sortBy: string; data: any[]; page: number; total: number; limit: number; search: string; };
  // companyName: string;
  /// bussiness unit over view table config
  onSortUsers({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.usersPTable.sortBy = direction;
    this.usersPTable.sortWith = column;
    direction && this.loadUsers();
  }

  onPageChangeUsers(page: number) {
    this.usersPTable.page = page;
    this.tableQueryParams.start=this.usersPTable.data?.length;
    this.loadUsers();
  }
  onLimitChangeUsers() {
    this.tableQueryParams.start=0;
    this.loadUsers();
  }

  private usersSearchSubscription: Subscription;
  constructor(
    private gamificationService: GamificationService,
    public toastService: ToastrService,
    public tableService: TableService

  ) {
    // this.config.notFoundText = 'Custom not found';
    this.usersPTable = {
      sortWith: 'createdAt',
      sortBy: 'desc',
      data: [],
      page: 1,
      total: 0,
      limit: 10,
      search: '',

    };
    // this.companyName = this.storageService.getByKey('companyId');

    this.usersSearchSubscription = this.userssearchInput
      .pipe(
        map((event) => {
          const target = event.target as HTMLTextAreaElement;
          return target.value;
        }),
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap((search) => of(search).pipe(delay(500)))
      )
      .subscribe((input) => {
        this.usersPTable.page = 1;
        this.loadUsers();
      });
  }

  ngOnInit(): void {
    this.tableQueryParams.draw=0;
    this.loadUsers();
  }

  onViewUser(data: any) {
    this.ViewEvent.emit(data);
  }


  loadUsers() {
    // const { data, total, ...rest } = this.usersPTable
    let tableCol: any = this.tableService.initializeTableParamsColums(this.logTableColumn, [0, 1, 2, 3, 4], [0, 1, 2, 3, 4]) || [];
    this.tableQueryParams['userId'] = "";
    this.tableQueryParams.columns = tableCol;
    this.tableQueryParams.draw++;
    this.tableQueryParams.length = this.usersPTable.limit;
    this.isTableLoading = true;
    const subscribe = this.gamificationService
      .getLogs(this.tableQueryParams)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          // const { count, data } = response.data;
          this.usersPTable.data = response?.data;
          this.usersPTable.total = response?.recordsTotal;
          this.isTableLoading = false;
        },
        (error) => {
          this.usersPTable.data = [];
          this.usersPTable.total = 0;
          this.toastService.error('No list found');
        }
      );
  }

  ngOnDestroy(): void {
    this.usersSearchSubscription && this.usersSearchSubscription.unsubscribe();
  }

}
