<div class="card mb-3" *ngIf="!isEventView">
  <ng-container *ngIf="!isManageOpen">
    <div class="align-items-center bg-theme card-header d-flex justify-content-between">
      <span class="h5 m-0">OVERVIEW</span>
      <button class="btn btn-primary" (click)="loadUsers('refresh')">
        REFRESH
      </button>
    </div>
    <div class="card-body p-0">
      <div class="card table-card">
        <div class="card-body flexi-body">
          <div class="flex-column">
            <div class="d-flex justify-content-between mt-2 mb-3">
              <select
                class="custom-select"
                style="width: auto"
                name="pageSize"
                (change)="onLimitChangeUsers()"
                [(ngModel)]="usersPTable.limit"
              >
                <option [ngValue]="10">10</option>
                <option [ngValue]="20">20</option>
                <option [ngValue]="30">30</option>
                <option [ngValue]="40">40</option>
                <option [ngValue]="50">50</option>
              </select>
              <div class="form-group form-inline mb-0">
                <span *ngIf="usersPTable.isLoading">Please wait...</span>
                <input
                  class="form-control ml-2"
                  type="search"
                  name="searchTerm"
                  (search)="loadUsers()"
                  (keyup)="userssearchInput.next($event)"
                  placeholder="Search here"
                  [(ngModel)]="usersPTable.search"
                />
              </div>
            </div>
            <div class="table-responsive">
              <table class="table" *ngIf="!isTableLoading">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      sortable="Tier 1"
                      (sort)="onSortUsers($event, 'createdAt')"
                    >
                      DATE
                    </th>
                    <th
                      scope="col"
                      sortable="Tier 2"
                      (sort)="onSortUsers($event, 'title')"
                    >
                      EVENTS TITLE
                    </th>
                    <th scope="col">AUTHOR</th>
                    <th scope="col">STATUS</th>
                    <th scope="col">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of usersPTable.data">
                    <td>
                      {{ item.createdAt | date: "medium" }}
                    </td>
                    <td>
                      <p [innerHtml]="item?.teaser?.title | safeHtml"></p>
                    </td>
                    <td>{{ item.authorId.name }}</td>
                    <td>
                      <div *ngIf="item.status != 2">
                        <span
                          class="badge badge-info"
                          *ngIf="checkEventStatus(item.publishing.startDate, item.publishing.endDate, 1)"
                        >
                          Pending Publication
                        </span>
                        <span
                        class="badge badge-danger"
                        *ngIf="checkEventStatus(item.publishing.startDate, item.publishing.endDate, 2)"
                      >
                        Expired
                      </span>
                        <span
                          class="badge badge-success"
                          *ngIf="checkEventStatus(item.publishing.startDate, item.publishing.endDate, 3)"
                        >
                          Published
                        </span>
                      </div>
                      <ng-template [ngIf]="item.status == 2">
                        <span class="badge badge-warning"> Draft </span>
                      </ng-template>
                    </td>

                    <td>
                      <button
                        class="btn btn-info btn-sm"
                        (click)="onViewWall(item._id, item)"
                      >
                        View
                      </button>
                      <button
                        class="btn btn-success btn-sm ml-1"
                        (click)="onEditWall(item._id)"
                      >
                        Edit
                      </button>
                      <button
                        class="btn btn-warning btn-sm ml-1"
                        (click)="openManage(item._id)"
                        *ngIf="item.eventDetails.isRSVPRequired === true"
                      >
                        Manage
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <app-skliton-loading-table
              [numberOfCols]="5"
              [isTableLoading]="isTableLoading"
            ></app-skliton-loading-table>
          </div>
        </div>
        <div class="card-footer">
          <div class="d-flex justify-content-between">
            <p>Total records {{ usersPTable.total }}</p>
            <ngb-pagination
              [collectionSize]="usersPTable.total"
              [(page)]="usersPTable.page"
              [maxSize]="5"
              (pageChange)="onPageChangeUsers($event)"
              [pageSize]="usersPTable.limit"
            >
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<app-events-view
  *ngIf="isEventView"
  [eventId]="eventId"
  [eventData]="eventData"
  (ViewEvent)="ViewEvent($event)"
></app-events-view>
