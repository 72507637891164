import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  TemplateRef,
  OnChanges,
} from '@angular/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ShiftSchedulerService } from 'src/app/users/service/operations/shift-scheduler.service';
import { finalize } from 'rxjs/operators';
import { getGmtOffSet } from 'src/app/core/utils/helper';

@Component({
  selector: 'app-timesheet-data',
  templateUrl: './timesheet-data.component.html',
  styleUrls: ['./timesheet-data.component.scss'],
})
export class TimesheetDataComponent implements OnInit, OnChanges {
  @Input('businessUnitId') public businessUnitId: any;
  @Input() refresh: any;
  @Output('onBtnCloseClick') onBtnCloseClick = new EventEmitter<number>();
  public timeSheetData: any;
  public user: any;
  shiftStartTime: any;
  shiftEndTime: any;
  singleTimeSheet: any;
  singleData: any;
  approvalObj: any;
  approvalObjTwo: any;
  checked: any;
  disabled: boolean;
  checkedArray = [];
  isShow = true;
  isApproveShowDet: string;
  breakTimeObj: {};
  shiftsForm: any;
  neitherShiftsForm: any;
  lockObj: any;
  approvalTimeIn: any;
  approvalTimeOut: any;
  dura = 0;
  dayHrs: any;
  uniqueIdClocked: any;
  uniqueIdShift: any;
  appovePath: any;
  neitTime: any;
  dateForNeither: any;
  exportdata: any;
  break: any;
  checkShift: any;
  allShiftArray = {};
  allShift = [];
  isBreakShowHisDet: any;
  showBtn = true;
  searchString: any = null;
  LockChecked: boolean;
  timeSheetDataLoader: boolean = true;
  enableLockAll: boolean = true;
  gmtOffset: any;
  constructor(
    protected formBuilder: FormBuilder,
    public toastService: ToastrService,
    private timesheetService: ShiftSchedulerService,
    public modelService: NgbModal
  ) {}

  ngOnInit(): void {
    this.gmtOffset = getGmtOffSet();
  }

  ngOnChanges() {
    this.getTimesheetData();
    this.initForm();
  }

  @ViewChild('AddShiftHours', { static: true })
  AddShiftHours: TemplateRef<any>;

  createAddShiftHours(data) {
    this.singleTimeSheet = data;
    this.shiftStartTime = data.shiftDetails.startTime;
    this.shiftEndTime = data.shiftDetails.endTime;
    if (this.singleTimeSheet.attendance) {
      this.shiftsForm.patchValue({
        clockedStartTime: moment(data.attendance.clockInDateTime).format(
          'HH:mm'
        ),
        clockedEndTime: moment(data.attendance.clockOutDateTime).format(
          'HH:mm'
        ),
      });
    }

    //For Neither and Remarks and Break
    this.shiftsForm.patchValue({
      neitherstartTime: moment(data.shiftDetails.startTime).format('HH:mm'),
      neitherendTime: moment(data.shiftDetails.endTime).format('HH:mm'),
      neitherRemarks: '',
      inputstartTime: null,
      inputendTime: null,
    });

    if (this.singleTimeSheet.attendance?.approval) {
      this.shiftsForm.patchValue({
        neitherstartTime: moment(
          data.attendance.approval.approveClockInTime || data.shiftDetails.startTime
        ).format('HH:mm'),
        neitherendTime: moment(
          data.attendance.approval.approveClockOutTime || data.shiftDetails.endTime
        ).format('HH:mm'),
        neitherRemarks: data.attendance.approval.neitherMessage,
      });
    }

    this.shiftsForm.patchValue({
      startDate: moment(data.shiftDetails.startTime).format('DD-MM-YYYY'),
      endDate: moment(data.shiftDetails.endTime).format('DD-MM-YYYY'),
      startTime:
      data.shiftDetails.isExtendedShift &&
      data.shiftDetails.extendedStaff.length > 0
        ? moment(data.shiftDetails.extendedStaff[0].startDateTime).format(
            'HH:mm'
          )
        : moment(data.shiftDetails.startTime).format('HH:mm'),
    endTime:
      data.shiftDetails.isExtendedShift &&
      data.shiftDetails.extendedStaff.length > 0
        ? moment(data.shiftDetails.extendedStaff[0].endDateTime).format(
            'HH:mm'
          )
        : moment(data.shiftDetails.endTime).format('HH:mm'),
    });

    const breakTimes = this.singleTimeSheet.attendance?.breakTime || [];

    if (breakTimes.length > 0) {
      const startTime = this.parseTimeString(moment(breakTimes[0].startTime).format('HH:mm'));
      const endTime = this.parseTimeString(moment(breakTimes[0].endTime).format('HH:mm'));

      this.shiftsForm.patchValue({
        inputstartTime:startTime,
        inputendTime: endTime,
      });
    }

    this.modelService.open(this.AddShiftHours, {
      windowClass: 'modal-ui-fix right-align',
    });
  }

  initForm() {
    this.shiftsForm = this.formBuilder.group({
      startDate: [false, [Validators.required]],
      endDate: [null, [Validators.required]],
      startTime: [null, [Validators.required]],
      endTime: [null, [Validators.required]],
      clockedStartTime: [null, [Validators.required]],
      clockedEndTime: [null, [Validators.required]],
      inputstartTime: [null, [Validators.required]],
      inputendTime: [null, [Validators.required]],
      neitherstartTime: [null, [Validators.required]],
      neitherendTime: [null, [Validators.required]],
      neitherRemarks: [null, [Validators.required]],
    });
  }

  handleApprove($event) {
    console.log('handleShift---', $event);
    this.uniqueIdShift = $event;
  }

  checkAllShiftLock(i) {
    this.checkShift = i;
  }

  lockAllShiftBtn() {
    this.allShift = [];
    this.timeSheetData.forEach((val) => {
      if (Object.keys(val).includes('attendance')) {
        if (
          (val.attendance.approval.shift == true ||
            val.attendance.approval.clocked == true ||
            val.attendance.approval.neither == true ||
            val.attendance.isAutoApprove == true) &&
          val.attendance.IsLock == false
        ) {
          this.allShift.push(val.attendance._id);
        }
      }
    });

    this.allShiftArray = {
      ids: this.allShift,
    };

    this.timesheetService.lockShift(this.allShiftArray).subscribe((res) => {
      this.timeSheetData = res['data'];
      this.toastService.success(res['message']);
      this.LockChecked = false;
      this.getTimesheetData();
      this.enableLockAll = true;
    });
  }

  approvalBtn() {
    this.timeSheetDataLoader = true;
    this.singleTimeSheet = this.singleTimeSheet;
    this.dateForNeither = moment(
      this.singleTimeSheet.shiftDetails.startTime
    ).format('YYYY-MM-DD');

    this.neitTime = this.shiftsForm.get('neitherstartTime').value;

    this.break = [];
    const inputStartTime = this.shiftsForm.get('inputstartTime').value;
    const inputEndTime = this.shiftsForm.get('inputendTime').value;

    if (inputStartTime && inputEndTime && this.singleTimeSheet.attendance?.clockInDateTime) {
      const formatDateTime = (date, time) =>
        new Date(
          moment(date).format('MM-DD-YYYY ') + time + ':00 ' + this.gmtOffset
        ).toISOString();

      this.break = [
        {
          startTime: formatDateTime(
            this.singleTimeSheet.shiftDetails.startTime,
            this.formatTimeObject(inputStartTime)
          ),
          endTime: formatDateTime(
            this.singleTimeSheet.shiftDetails.startTime,
            this.formatTimeObject(inputEndTime)
          ),
          shiftDetailId: this.singleTimeSheet.shiftDetails._id,
          userId: this.singleTimeSheet.userInfo._id,
        },
      ];
    }

    if (this.uniqueIdShift === 1) {
      if (this.singleTimeSheet.attendance) {
        this.approvalObj = {
          businessUnitId: this.singleTimeSheet.businessUnitId,
          clocked: false,
          neither: false,
          shift: true,
          breakTime: this.break,
          approveClockInTime:
          this.singleTimeSheet.shiftDetails.isExtendedShift &&
          this.singleTimeSheet.shiftDetails.extendedStaff.length > 0
            ? this.singleTimeSheet.shiftDetails.extendedStaff[0].startDateTime
            : this.singleTimeSheet.shiftDetails.startTime,
          approveClockOutTime:
          this.singleTimeSheet.shiftDetails.isExtendedShift &&
          this.singleTimeSheet.shiftDetails.extendedStaff.length > 0
            ? this.singleTimeSheet.shiftDetails.extendedStaff[0].endDateTime
            : this.singleTimeSheet.shiftDetails.endTime,
          shiftDetailId: this.singleTimeSheet.shiftDetails._id,
          shiftId: this.singleTimeSheet.shiftDetails.shiftId,
          userId: this.singleTimeSheet.userInfo._id,
          neitherMessage: this.shiftsForm.get('neitherRemarks').value,
          _id: this.singleTimeSheet.attendance._id,
        };
      } else if (!this.singleTimeSheet.attendance) {
        this.approvalObj = {
          businessUnitId: this.singleTimeSheet.businessUnitId,
          clocked: false,
          neither: false,
          shift: true,
          breakTime: this.break,
          neitherMessage: this.shiftsForm.get('neitherRemarks').value,
          approveClockInTime:
          this.singleTimeSheet.shiftDetails.isExtendedShift &&
          this.singleTimeSheet.shiftDetails.extendedStaff.length > 0
            ? this.singleTimeSheet.shiftDetails.extendedStaff[0].startDateTime
            : this.singleTimeSheet.shiftDetails.startTime,
          approveClockOutTime:
          this.singleTimeSheet.shiftDetails.isExtendedShift &&
          this.singleTimeSheet.shiftDetails.extendedStaff.length > 0
            ? this.singleTimeSheet.shiftDetails.extendedStaff[0].endDateTime
            : this.singleTimeSheet.shiftDetails.endTime,
          shiftDetailId: this.singleTimeSheet.shiftDetails._id,
          shiftId: this.singleTimeSheet.shiftDetails.shiftId,
          userId: this.singleTimeSheet.userInfo._id,
        };
      }
    } else if (this.uniqueIdShift === 2) {
      this.approvalObj = {
        businessUnitId: this.singleTimeSheet.businessUnitId,
        clocked: true,
        neither: false,
        shift: false,
        breakTime: this.break,
        approveClockInTime: this.singleTimeSheet.attendance.clockInDateTime,
        approveClockOutTime: this.singleTimeSheet.attendance.clockOutDateTime,
        shiftDetailId: this.singleTimeSheet.shiftDetails._id,
        shiftId: this.singleTimeSheet.shiftDetails.shiftId,
        userId: this.singleTimeSheet.userInfo._id,
        neitherMessage: this.shiftsForm.get('neitherRemarks').value,
        _id: this.singleTimeSheet.attendance._id,
      };
    } else if (this.uniqueIdShift === 3) {
      if (!this.shiftsForm.get('neitherRemarks').value) {
        this.toastService.error("Please enter Remarks to approve Neither Shift hours.");
        this.timeSheetDataLoader = false;
        return;
      }
      this.approvalObj = {
        businessUnitId: this.singleTimeSheet.businessUnitId,
        clocked: false,
        neither: true,
        shift: false,
        breakTime: this.break,
        approveClockInTime: new Date(
          moment(this.singleTimeSheet.shiftDetails.startTime).format(
            'MM-DD-YYYY '
          ) +
            this.shiftsForm.get('neitherstartTime').value +
            ':00 ' + this.gmtOffset
        ).toISOString(),
        approveClockOutTime: new Date(
          moment(this.singleTimeSheet.shiftDetails.endTime).format(
            'MM-DD-YYYY '
          ) +
            this.shiftsForm.get('neitherendTime').value +
            ':00 ' + this.gmtOffset
        ).toISOString(),
        shiftDetailId: this.singleTimeSheet.shiftDetails._id,
        shiftId: this.singleTimeSheet.shiftDetails.shiftId,
        userId: this.singleTimeSheet.userInfo._id,
        neitherMessage: this.shiftsForm.get('neitherRemarks').value,
        _id: this.singleTimeSheet.attendance?._id,
      };
    }
    this.modelClose();

    this.timesheetService
      .approvalTimeSheet(this.approvalObj)
      .subscribe((res) => {
        this.timeSheetData = res['data'];
        this.getTimesheetData();
        this.timeSheetDataLoader = false;
        this.toastService.success('Shift approved successfully');
      },
      (error: any) => {
        this.toastService.error("Something went wrong. Please try again.");
        this.timeSheetDataLoader = false;
      });
  }

  changeTab() {
    this.onBtnCloseClick.emit(2);
  }

  addBreakTime() {
    this.singleTimeSheet = this.singleTimeSheet;

    this.breakTimeObj = {
      endTime:
        moment(this.singleTimeSheet.shiftDetails.startTime).format(
          'MM-DD-YYYY '
        ) +
        this.formatTimeObject(this.shiftsForm.get('inputendTime').value) +
        ':00 ' + this.gmtOffset,

      shiftDetailId: this.singleTimeSheet.shiftDetails._id,
      startTime:
        moment(this.singleTimeSheet.shiftDetails.startTime).format(
          'MM-DD-YYYY '
        ) +
        this.formatTimeObject(this.shiftsForm.get('inputstartTime').value) +
        ':00 ' + this.gmtOffset,
      userId: this.singleTimeSheet.userInfo._id,
    };

    this.timesheetService.breakTimeAdd(this.breakTimeObj).subscribe((res) => {
      this.timeSheetData = res['data'];

      this.toastService.success(res['message']);
      this.getTimesheetData();
    });
  }

  lockFunction(d) {
    this.singleData = d;

    this.lockObj = { _id: this.singleData.attendance._id };

    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'Back',
    }).then((result) => {
      if (result.isConfirmed) {
        this.timesheetService.lock(this.lockObj).subscribe((res) => {
          this.toastService.success(res['message']);
          this.getTimesheetData();
        });
      }
    });
  }

  getTimesheetData(): void {
    const timesheet = this.timesheetService
      .getTimesheetData(this.businessUnitId._id)
      .pipe(
        finalize(() => {
          this.timeSheetDataLoader = false;
          timesheet.unsubscribe();
        })
      )
      .subscribe((res) => {
        this.timeSheetData = res['data'] || [];
      });
  }
  isKeyAvailable = (item, key) => {
    if (item && key) {
      return Object.keys(item).includes(key);
    }
  };
  toggleDisplay() {
    this.isShow = !this.isShow;
  }
  getDuration(time) {
    if (time) {
      time = parseFloat(time);
      time = time * 1000 * 3600;
      const date = new Date(time);
      let hh = date.getUTCHours();
      let min = 0;
      if (date.getUTCMinutes() === 59) {
        min = 0;
        hh = hh + 1;
      } else {
        min = date.getUTCMinutes();
      }
      return '' + hh + 'h:' + min + 'min';
    } else {
      return '0';
    }
  }
  exportTimesheet() {
    this.timesheetService
      .exportTimesheetData(this.businessUnitId._id)
      .subscribe((res:any) => {
        this.exportdata = res;
        var a = document.createElement('a');
        a.href = 'data:attachment/csv,' +encodeURI(res.csv);
        a.target = '_blank';
        a.download = "current_timesheet.csv";
        document.body.appendChild(a);
        a.click();
       this.toastService.success(
          "success",
          "timesheet Details exported succesfully"
        );
      });
  }
  toggleApprovalDetails(data, i) {
    if (this.isApproveShowDet === i) {
      return (this.isApproveShowDet = '');
    }
    return (this.isApproveShowDet = i);
  }

  toggleBreakDetails(data, i) {
    if (this.isBreakShowHisDet === i) {
      this.showBtn = true;
      return (this.isBreakShowHisDet = '');
    }
    this.showBtn = false;
    return (this.isBreakShowHisDet = i);
  }

  modelClose() {
    this.getTimesheetData();
    this.modelService.dismissAll();
  }

  parseTimeString(timeString) {
    const [hourString, minuteString] = timeString.split(':');
    const hour = parseInt(hourString, 10);
    const minute = parseInt(minuteString, 10);
    return { hour, minute };
  }

  formatTimeObject(timeObject){
    return `${timeObject.hour}:${timeObject.minute}`
  }

  enableLockAllBtn(value){
    this.enableLockAll = value;
  }
}
