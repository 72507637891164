<ul
  ngbNav
  #nav="ngbNav"
  [(activeId)]="activeSubId"
  class="nav-tabs rounded mb-3" [ngStyle]="{'display':viewValue ? 'none':'flex'}"
>
  <li [ngbNavItem]="1" *ngIf="this.isView" [disabled]="true">
    <a ngbNavLink>ENTER OPS GROUP DATA</a>
    <ng-template ngbNavContent>
      <div class="card">
        <h5 class="card-header bg-theme">ENTER OPS GROUP DATA</h5>
        <div class="card-body">
          <form [formGroup]="opsGroupForm">
            <div class="row">
              <div class="col-md-3">
                <label class="font-weight-500">ENTER OPS GROUP DATA </label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="groupname"
                />
              </div>
              <div class="col-md-6 angular2-multiselect-dropdown">
                <label class="font-weight-500">Source BU's </label>
                <angular2-multiselect [data]="buParameters.data" formControlName="buShift" [(ngModel)]="selectedBuShift" [settings]="dropdownSettingsSourceBU"
                [ngModelOptions]="{ standalone: true }"
               >
                <c-badge>
                  <ng-template let-item="item">
                    {{selectedBuShift?.length}} selected
                  </ng-template>
                </c-badge>
              </angular2-multiselect>
              <div class="row">
                <div

                *ngFor="
                  let field of selectedBuShift;
                  let index = index
                "
                class="row red-container mt-3 col-md-5 ml-4"
              >
                <div class="col-10 selected-text">
                  {{ field.orgName }}
                </div>
                <div class="col-2 cancel-btn-container">
                  <img
                    (click)="deleteSourceBU(field._id)"
                    src="./assets/images/cancel.png"
                    width="20px"
                    height="20px"
                  />
                </div>
              </div>
              </div>

              </div>
              <div class="col-md-3 angular2-multiselect-dropdown">
                <label class="font-weight-500">Adminstration </label>
                <angular2-multiselect [data]="shiftSchemeAdmin"  [(ngModel)]="selectedAdmin" [settings]="dropdownSettingsAdmin"
                [ngModelOptions]="{ standalone: true }"
               >
                <c-badge>
                  <ng-template let-item="item">
                    {{selectedAdmin?.length}} selected
                  </ng-template>
                </c-badge>
              </angular2-multiselect>
              <div
              *ngFor="
                let field of selectedAdmin;
                let index = index
              "
              class="row red-container mt-3 ml-0"
            >
              <div class="col-10 selected-text">
                {{ field.name }}
              </div>
              <div class="col-2 cancel-btn-container">
                <img
                  (click)="deleteAdmin(field._id)"
                  src="./assets/images/cancel.png"
                  width="20px"
                  height="20px"
                />
              </div>
            </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-3">
                <label class="font-weight-500">Number of Teams </label>
                <ng-select
                  [items]="teamNo"
                  class="custom"
                  appendTo="body"
                  [multiple]="false"
                  [clearable]="false"
                  [closeOnSelect]="true"
                  (change)="teamNoCount()"
                  [(ngModel)]="teamCountNo"
                  [ngModelOptions]="{ standalone: true }"
                  class="custom"
                  >
                </ng-select>

              </div>
            </div>
            <div class="my-3" *ngIf="this.showRow">
              <div
                class="border d-inline-flex mb-3 p-2 rounded mr-2"
                *ngFor="let team of teamNameArray; let i = index"
              >
                <div class="form-inline">
                  <label class="font-weight-500">Team name</label>
                  <input
                    type="text"
                    class="form-control mx-2"
                    id="{{ 'teamIdName' + i }}"
                    value="{{ team.teamName }}" [disabled]="team.isAdded" [(ngModel)]="team.teamName"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
                <button class="btn btn-danger" (click)="removeTeam(i)">Remove Team</button>
               <button
                  *ngIf="!team.isAdded; else editBtn"
                  class="btn btn-success ml-2"
                  (click)="addTeambutton(i)" [disabled]="!team.teamName"
                >
                  Add Team
                </button>
                <ng-template #editBtn>
                  <button class="btn btn-warning ml-2" (click)="editTeam(i)">Edit Team Name</button>
                </ng-template>
              </div>
            </div>
            <hr />
            <div class="form-inline">
              <label class="font-weight-500 mr-2">Swap Setting</label>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  formControlName="swap"
                  id="swapNo"
                  value="0"
                />
                <label class="form-check-label" for="swapNo">No swap</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  formControlName="swap"
                  id="swapGroup1"
                  value="1"
                />
                <label class="form-check-label" for="swapGroup1"
                  >Swap at Ops Group level</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  formControlName="swap"
                  id="swapTeam2"
                  value="2"
                />
                <label class="form-check-label" for="swapTeam2"
                  >Swap at Ops Team level</label
                >
              </div>
            </div>
          </form>
        </div>
        <div class="card-footer">
          <button
            type="button"
            class="bg-theme border-0 btn d-flex align-items-center"
            (click)="changeTabId()"
          >
            <span>Next</span
            ><span class="material-icons ml-1"> arrow_forward </span>
          </button>
        </div>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="2" *ngIf="this.isViewtab2" [disabled]="true">
    <a ngbNavLink>ADD STAFF</a>
    <ng-template ngbNavContent>
      <div class="card">
        <h5 class="card-header bg-theme">ADD/REMOVE STAFF</h5>
        <div class="card-body">
          <form class="mb-3" [formGroup]="opsGroupForm">
            <div class="row">
              <div class="col-md-3">
                <label class="font-weight-500">Ops Group </label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="groupname"
                />
              </div>
              <div class="col-md-9">
                <div class="form-group">
                  <label class="font-weight-500"> Ops Team </label>
                  <br />
                  <div
                    class="form-check form-check-inline"
                    *ngFor="let team of teamNameArray; let i = index"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      name="opsteam"
                      (change)="selectedteam(i)"
                      value="{{ team.teamName }}"
                      [checked]="i == 0"
                    />
                    <label class="form-check-label">{{ team.teamName }}</label>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="row mb-3">
              <div class="col-md-3">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="opsAction1"
                    [value]="1"
                    [(ngModel)]="addFromSource"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="handleApprove()"
                  />
                  <label
                    class="form-check-label font-weight-500"
                    for="opsAction1"
                  >
                    Add Staff</label
                  >
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    [value]="2"
                    formControlName="removeOpsGroup"
                    [checked]="true"
                  />
                  <label
                    class="form-check-label font-weight-500"
                    for="opsAction2"
                  >
                    Remove Staff</label
                  >
                </div>
              </div>
              <div class="col-md-9" *ngIf="addFromBu">
                <label class="font-weight-500"> Selected Source BU's </label>
                <br />
                <div
                  class="labels label-light mr-1 mb-1"
                  *ngFor="let buShift of buShiftNewArr; let i = index"
                >
                  {{ buShift.name }}
                </div>
              </div>
            </div>
            <div class="row" *ngIf="addFromBu">
              <div class="col-md-3">
                <label class="font-weight-500">Add Staff </label>
                <ng-select
                  [items]="staffUser"
                  class="custom"
                  bindLabel="name"
                  appendTo="body"
                  [multiple]="true"
                  [clearable]="true"
                  [closeOnSelect]="true"
                  [(ngModel)]="staffDetail"
                  clearAllText="Clear"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <ng-template
                    ng-option-tmp
                    let-item="item"
                    let-item$="item$"
                    let-index="index"
                  >
                    {{ item?.name }} ({{item?.staffId}})
                  </ng-template>
                </ng-select>
              </div>
              <div
                class="col-md-2 d-flex justify-content-start align-items-end"
              >
                <button class="btn btn-add" (click)="addStaffUser()">
                  Add Staff
                </button>
              </div>
            </div>
          </form>
          <div class="row">
            <div class="col-md-12">
              <label class="font-weight-500">Staff Table</label>
              <div class="table-responsive border-theme rounded">
                <table class="table m-0">
                  <thead>
                    <tr>
                      <th scope="col">USER NAME</th>
                      <th scope="col">STAFF ID</th>
                      <th scope="col">DATE OF JOINNING</th>
                      <th scope="col">CONTACT NUMBER</th>
                      <th scope="col">APPOINMENT</th>
                      <th scope="col">BUSINESS UNIT</th>
                      <th scope="col">ACTIONS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let staffData of staffUserDet;let i=index">
                      <td>{{ staffData.name }}</td>
                      <td>{{ staffData.staffId }}</td>
                      <td></td>
                      <td>{{ staffData.contactNumber }}</td>
                      <td>{{ staffData.appointmentId.name }}</td>
                      <td>
                        {{ staffData.parentBussinessUnitId.orgName }}
                      </td>

                      <td>
                        <button class="btn btn-danger" (click)="removeStaff(i)">Remove Staff</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="d-flex">
            <button
              type="button"
              class="align-items-center btn btn-outline-dark d-flex"
              (click)="changeTabIdPrevious()"
            >
              <span class="material-icons mr-1"> arrow_back </span>
              <span>Previous</span>
            </button>
            <button
              class="bg-theme border-0 btn d-flex align-items-center ml-2"
              (click)="changeTabIdNextBTn()"
            >
              <span>Next</span>
              <span class="material-icons ml-1"> arrow_forward </span>
            </button>
          </div>
        </div>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="3" *ngIf="this.isViewtab3" [disabled]="true">
    <a ngbNavLink>CREATE OPS GROUP</a>
     <ng-template ngbNavContent>
      <div class="card">
        <h5 class="card-header bg-theme">VIEW DETAILS </h5>
        <div class="card-body">
          <form [formGroup]="opsGroupForm">
            <div class="row">
              <div class="col-md-3">
                <label class="font-weight-500">Ops group name </label>
                <input
                  disabled
                  type="text"
                  class="form-control"
                  placeholder="Field Name"
                  formControlName="groupname"
                />
              </div>
              <div class="col-md-6">
                <label class="font-weight-500">Source BU's </label><br />
                <div
                  class="labels mb-1 mr-1"
                  *ngFor="let buShiftView of selectedBuShift"
                >
                  <span class="text">{{
                    buShiftView.businessUnit
                      ? buShiftView.businessUnit
                      : buShiftView.orgName
                  }}</span>
                </div>
              </div>
              <div class="col-md-6" *ngIf="!this.isViewtab3">
                <label class="font-weight-500">Source BU's </label>
                 <ng-select
                  [items]="buOption"
                  [multiple]="true"
                  bindLabel="orgName"
                  groupBy="type"
                  placeholder="Search"
                  [selectableGroup]="true"
                  [selectableGroupAsModel]="true"
                  [closeOnSelect]="false"
                  [searchable]="false"
                  [(ngModel)]="selectedBuShift"
                  [ngModelOptions]="{ standalone: true }"
                  [readonly]="true"
                >
                  <ng-template
                    ng-option-tmp
                    let-item="item"
                    let-item$="item$"
                    let-index="index"
                  >
                    {{ item.orgName }}
                  </ng-template>
                </ng-select>
              </div>
              <div class="col-md-3">
                <label class="font-weight-500">Adminstration </label><br />
                <div
                  class="labels my-1"
                  *ngFor="let adminView of selectedAdmin"
                >
                  <span class="text">{{ adminView.name }}</span>&nbsp;
                </div>
              </div>
            </div>
            <hr />
            <div class="row mb-3">
              <div class="col-md-3">
                <label class="font-weight-500">Number of Teams </label>
                <ng-select
                  [items]="teamNo"
                  class="custom"
                  appendTo="body"
                  [readonly]="true"
                  formControlName="numberteam"
                  [multiple]="false"
                  [clearable]="true"
                  [closeOnSelect]="true"
                  (change)="teamNoCount()"
                  [(ngModel)]="teamCountNo"
                  [ngModelOptions]="{ standalone: true }"
                >
                </ng-select>
              </div>
            </div>
            <div class="row" *ngIf="teamCountNo >0">
              <div class="col-md-12">
                <div
                  class="border d-inline-flex mb-3 p-2 rounded mr-2"
                  *ngFor="let team of teamNameArray"
                >
                  <div class="form-inline">
                    <label class="font-weight-500 mr-2">Team name</label>
                    <input
                      type="text"
                      class="form-control"
                      value="{{ team.teamName }}"
                      readonly
                    />
                  </div>
                  <ng-container *ngIf="!this.isViewtab3">
                    <button class="btn btn-danger">Remove Team</button>
                    <button
                      class="btn btn-success ml-2"
                      (click)="addTeambutton(i)"
                    >
                      Add Team
                    </button>
                  </ng-container>
                  <hr />
                </div>
              </div>
            </div>

            <div class="row" *ngIf="this.isViewtab3 && this.removedTeam?.length>0">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header bg-theme">Deleted Teams</div>
                  <div class="card-body p-0">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Team name</th>
                          <th scope="col">Date, Time</th>
                          <th scope="col">Deleted By</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of removedTeam">
                          <td>{{ item.teamId.name }}</td>
                          <td>
                            {{ item.deletedDateTime | date: "MMM d, y, h:mm" }}
                          </td>
                          <td>{{ item.userId.name }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="form-inline">
              <label class="font-weight-500 mr-2">Swap Setting</label>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  formControlName="swap"
                  id="swapNo"
                  value="0"
                  disabled
                />
                <label class="form-check-label" for="swapNo">No swap</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  formControlName="swap"
                  id="swapGroup1"
                  value="1"
                  disabled
                />
                <label class="form-check-label" for="swapGroup1"
                  >Swap at Ops Group level</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  formControlName="swap"
                  id="swapTeam2"
                  value="2"
                  disabled
                />
                <label class="form-check-label" for="swapTeam2"
                  >Swap at Ops Team level</label
                >
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label class="font-weight-500">Ops Group</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="groupname" disabled
                  />
                </div>
              </div>
              <div class="col-md-9" *ngIf="!ifNoTeam">
                <div class="form-group">
                  <label class="font-weight-500"> Ops Team </label><br />
                  <div
                    class="form-check form-check-inline"
                    *ngFor="let team of teamNameArray; let i = index"
                  >
                    <input
                      type="radio"
                      class="form-check-input"
                      [checked]="selectedteam"
                      [value]="i"
                      [(ngModel)]="selectedTeamIndex"
                      [ngModelOptions]="{ standalone: true }"
                      formControlName="opsTeamName"
                      (change)="selectedteam(i, 'o', team._id)"
                    />
                    <!-- {{team |json}} -->
                    <label for="channel" class="form-check-label">{{
                      team.teamName
                    }}</label>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <hr />
          <div class="row">
            <div class="col-md-12">
              <label class="font-weight-500">Staff Table</label>
              <div class="table-responsive border-theme rounded">
                <table class="table m-0">
                  <thead>
                    <tr>
                      <th scope="col">USER NAME</th>
                      <th scope="col">STAFF ID</th>
                      <th scope="col">DATE OF JOINNING</th>
                      <th scope="col">CONTACT NUMBER</th>
                      <th scope="col">APPOINMENT</th>
                      <th scope="col">BUSINESS UNIT</th>
                      <!-- <th scope="col">ACTIONS</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let staffData of staffUserDet">
                      <td>{{ staffData.name }}</td>
                      <td>{{ staffData.staffId }}</td>
                      <td></td>
                      <td>{{ staffData.contactNumber }}</td>
                      <td>{{ staffData.appointmentId.name }}</td>
                      <td>
                        {{ staffData.parentBussinessUnitId.orgName }}
                      </td>

                      <!-- <td>
                          <button class="btn btn-danger" *ngIf="!this.isViewtab3">
                            Remove Staff
                          </button>
                        </td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="d-flex">
            <button
              *ngIf="disable"
              class="align-items-center btn btn-outline-dark d-flex"
              (click)="backToOverview()"
            >
              <span class="material-icons mr-1"> arrow_back </span>
              <span>Back</span>
            </button>
            <ng-container *ngIf="!disable">
              <button
                type="button"
                class="align-items-center btn btn-outline-dark d-flex"
                (click)="changeTabIdPreviouBtn()"
              >
                <span class="material-icons mr-1"> arrow_back </span>
                <span>Previous</span>
              </button>
              <button class="btn btn-warning ml-2 d-flex align-items-center" (click)="saveAsDraft()"  [disabled]="draftOpsGrpLoader">
                <span>Save as Draft</span>
                <span class="material-icons mr-1" *ngIf="!draftOpsGrpLoader">
                  arrow_upward
                </span>
                <span
                  *ngIf="draftOpsGrpLoader"
                  class="spinner-border spinner-border-sm ml-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
              <button
                class="btn border-0 d-flex align-items-center ml-2 btn-success"
                (click)="onCreateForm()"
                [disabled]="createOpsGrpLoader"
              >
                <span>Create OPS Group</span>
                <span class="material-icons mr-1" *ngIf="!createOpsGrpLoader">
                  arrow_upward
                </span>
                <span
                  *ngIf="createOpsGrpLoader"
                  class="spinner-border spinner-border-sm ml-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-template>
  </li>
</ul>


<div [ngbNavOutlet]="nav"></div>


