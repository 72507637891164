<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="inputEmail4">Select Ops Group</label>
            <ng-select
              [items]="opsList"
              class="custom margin"
              bindLabel="name"
              placeholder="Select Ops Group"
              appendTo="body"
              [multiple]="false"
              [searchable]="true"
              [clearable]="true"
              [closeOnSelect]="true"
              (change)="getOpsTeam()"
              [(ngModel)]="opsGrpSelect"
            >
            </ng-select>
          </div>
          <div class="form-group col-md-4">
            <label>Select Ops Team</label>
            <ng-select
              [items]="opsTeam"
              class="custom margin"
              bindLabel="name"
              placeholder="Select Ops Team"
              appendTo="body"
              [multiple]="false"
              [searchable]="true"
              [clearable]="true"
              [closeOnSelect]="true"
              (change)="getOpsTeamUser()"
              [(ngModel)]="opsTeamSelect"
            >
            </ng-select>
          </div>
          <div class="form-group col-md-2 align-self-end">
            <button type="submit" class="btn btn-success" (click)="swapList()">
              View
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row mt-3" *ngIf="swapListData?.userId">
  <div class="col-md-12">
    <div class="table-responsive">
      <table class="table table-hover border">
        <thead class="bg-theme">
          <tr>
            <th scope="col">STAFF NAME</th>
            <th scope="col">STAFF ID</th>
            <th scope="col">RESTRICT SWAP</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of swapListData.userId; let i = index">
            <td>{{ item.name }}</td>
            <td>{{ item.staffId }}</td>
            <td>
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                  [(ngModel)]="swapCheck[i]"
                  (change)="swapChange(item)"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
