import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { NewsEventsService } from 'src/app/users/service/manage-news & events/news-events.service';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'app-events-manage',
  templateUrl: './events-manage.component.html',
  styleUrls: ['./events-manage.component.scss'],
})
export class EventsManageComponent implements OnInit {
  @Input() manageId;
  sessionArr = [];
  selectedSession;
  sessionTitle: string;
  sessionDetail: any;
  attendanceCountArr = [];
  manualForm: FormGroup;
  eventTitle: any;
  constructor(
    private modalService: NgbModal,
    public newsService: NewsEventsService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {}
  closeResult = '';
  ngOnInit(): void {
    if (this.manageId) {
      this.getEventsEdit(this.manageId);
    }
  }

  getEventsEdit(id: string) {
    const sub = this.newsService
      .getSingleNewsEvents(id)
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe(({ data: { session, teaser } }) => {
        this.eventTitle = teaser?.title;
        session.Rows.forEach((val) => {
          this.sessionArr.push(val);
        });
        this.selectedSession = session.Rows[0];
        this.getSessionDetail();
      });
  }

  getSessionDetail() {
    this.sessionTitle = 'Session @ ' + this.selectedSession.location;
    const sessionDetailPayload = {
      session_id: this.selectedSession._id,
      event_id: this.manageId,
    };

    this.newsService
      .getSessionDetail(sessionDetailPayload)
      .subscribe((res: { data }) => {
        this.attendanceCountArr = [];
        // need to understand this logic how status showing
        const attendanceCount = res.data.eventSession.totalAttendanceTaking;
        if (attendanceCount != 0) {
          for (var i = 1; i <= attendanceCount; i++) {
            this.attendanceCountArr.push(i);
          }
        }
      });
    
    this.getRSVPAttendanceStatus();
  }

  getRSVPAttendanceStatus() {
    const payload = {
      session_id: this.selectedSession._id,
      event_id: this.manageId,
      appointmentSlotNumber: 1,
      apptStatus: '',
    };

    this.newsService
      .getRSVPAttendanceStatus(payload)
      .subscribe((res: { data }) => {
        this.sessionDetail = res.data;
      });
  }

  open(content) {
    this.manualForm = this.fb.group({
      session_id: '',
      event_id: '',
      staff_ids: '',
      appointmentSlotNumber: '',
    });

    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  submitManualAtt() {
    const payload = {
      staff_ids: this.manualForm.controls.staff_ids.value,
      event_id: this.manageId,
      session_id: this.manualForm.controls.session_id.value._id,
      appointmentSlotNumber: this.manualForm.controls.appointmentSlotNumber.value.toString(),
    };

    this.newsService.manualStaffAttendance(payload).subscribe(
      (res) => {
      this.toastr.success('Attendance marked');
      this.modalService.dismissAll();
      this.getSessionDetail()
      this.getRSVPAttendanceStatus();
      this.getSessions();
    },
    (error) => {
      this.toastr.error(error?.message?.message);
    }
    );
  }

  getAttendeneceStatus(item, index) {
    let status = ''
    item.attendanceStaff?.filter(id => id.appointmentSlotNumber === index).length > 0 ? status = "Present" : status = ""
    return status
  }

  getSessions() {
    const payload = {
      event_id: this.manageId,
    };

    this.newsService
      .getSessions(payload)
      .subscribe((res: { data }) => {
      });
  }

  getAttendeneceCount(count) {
    return [].concat.apply([], this.sessionDetail?.map(id => id.attendanceStaff)).filter(ele => ele.appointmentSlotNumber
      === count).length;
  }

  exportAttd() {
    const payload = {
      session_id: this.selectedSession._id,
      event_id: this.manageId,
      appointmentSlotNumber: 0,
      timeZone: 330,
    };

    this.newsService.exportAttendance(payload).subscribe((res) => { 
      var a = document.createElement('a');
      a.href = 'data:attachment/csv,' + encodeURI(res['csv']);
      a.target = '_blank';
      a.download = "Attendence.csv";
      document.body.appendChild(a);
      a.click();
      this.toastr.success(
        "success",
        "Attendences exported succesfully"
      );
    });
  }

}
