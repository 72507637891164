<div class="d-flex flex-column" *ngIf="notificatioNid">
  <div class="card mb-3 w-100">
    <div class="card-header flexi-header p-4">
      <h3 style="display: inline-block">
        {{ notificationEditedId ? "EDIT" : "CREATE" }} NOTIFICATION
      </h3>
      <!-- <button class="btn pull-right btn-outline-light" *ngIf="isNotificationEdited" (click)="onCancelEdit()">
        <i class="fa fa-chevron-left" aria-hidden="true"></i> Back
      </button> -->
    </div>


    <div class="card-body" *ngIf="!isNotificationEdited">
      <form [formGroup]="notificationCreateForm">
        <div class="row">
          <div class="col-md-6 mt-1">
            <div class="form-group">
              <label class="pb-2 font-weight-bold" for="exampleInputEmail1">Title<span class="field-mandatory">*</span></label>

              <input type="text" class="form-control" placeholder="Title" formControlName="title" required
                maxlength="40" />
            </div>
          </div>
          <div class="col-md-6 mt-1">
            <div class="form-group">
              <label class="pb-2 font-weight-bold" for="exampleInputEmail1">Sub Title<span class="field-mandatory">*</span></label>

              <input type="text" class="form-control" placeholder="Sub Title" formControlName="subTitle" required
                maxlength="40" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-1">
            <div class="form-group">
              <label class="pb-2 font-weight-bold" for="exampleInputEmail1">Description<span class="field-mandatory">*</span></label>

              <textarea name="" class="form-control" id="" cols="5" rows="5" maxlength="100"
                formControlName="description"></textarea>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mt-1">
            <div class="form-group">
              <label class="pb-2 font-weight-bold" for="exampleInputEmail1">Effective from</label>

              <div class="input-group">
                <input class="form-control" placeholder="yyyy-mm-dd" readonly name="dp" formControlName="effectiveFrom"
                  ngbDatepicker #d="ngbDatepicker" (dateSelect)="onDateSelect($event,'Effective from')" />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                    <i class="fa fa-calendar" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mt-1">
            <div class="form-group">
              <label class="pb-2 font-weight-bold" for="exampleInputEmail1">Effective to</label>

              <div class="input-group">
                <input class="form-control" placeholder="yyyy-mm-dd" readonly name="dp" ngbDatepicker
                  formControlName="effectiveTo" #c="ngbDatepicker" (dateSelect)="onDateSelect($event,'Effective to')" />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="c.toggle()" type="button">
                    <i class="fa fa-calendar" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label class="pb-2 font-weight-bold">
              Main category <span class="field-mandatory">*</span>
              <button class="btn p-1 ml-1" style="color: #9f1d39;" (click)="addCategory()">
                <i class="fa fa-plus-circle fa-lg" aria-hidden="true"></i></button></label>
            <ng-select (change)="addSeletedCategory($event)" class="custom" bindLabel="name" placeholder="Main category"
              appendTo="body" [multiple]="false" [searchable]="true" [clearable]="true" [(ngModel)]="mainCategory"
              [loading]="categoryDropDownLoading" (search)="onSeachCategory($event)" [ngModelOptions]="{standalone: true}"
              (scrollToEnd)="loadMoreCategoryItems($event)" (clear)="onClearCategoryDropdown($event)">
              <ng-option [value]="item" *ngFor="let item of categoryDropDown">
                {{ item.name }}
              </ng-option>
            </ng-select>

            <div *ngIf="notificationCreateForm.get('CategoryId.string').value" class="display-flex">
              <div>
                <span class="text-items list-ui">
                  {{ notificationCreateForm.get("CategoryId.string").value}}
                </span>
              </div>
              <div style="margin-top: 10px;">
                <i (click)="deleteMainCategory()" class="fa fa-times-circle fa-2x" style="color: #9f1d39;"
                  aria-hidden="true">
                </i>
              </div>
            </div>

          </div>
          <div class="col-md-6">
            <label class="pb-2 font-weight-bold">
              Sub category <span class="field-mandatory">*</span>
              <button class="btn p-1 ml-1" style="color: #9f1d39;;" (click)="addSubCategory()">
                <i class="fa fa-plus-circle fa-lg" aria-hidden="true"></i>
              </button>
            </label>
            <ng-select (change)="addSeletedSubCategory($event)" class="custom" bindLabel="name"
              placeholder=" Sub Category" [(ngModel)]="subCategory" appendTo="body" [multiple]="false" [searchable]="true" [clearable]="true"
              [loading]="subCategoryDropDownLoading" (search)="onSortSubCategory($event)" [ngModelOptions]="{standalone: true}"
              (scrollToEnd)="loadMoreSubCategoryItems($event)" (clear)="onClearSubCategoryDropdown($event)">
              <ng-option [value]="item" *ngFor="let item of subCategoryDropDown">
                {{ item.name }}
              </ng-option>
            </ng-select>
            <div *ngIf="notificationCreateForm.get('subCategoryId.string').value" class="display-flex">
              <div>
                <span class="text-items list-ui">
                  {{ notificationCreateForm.get("subCategoryId.string").value }}</span>
              </div>
              <div style="margin-top: 10px;">
                <i (click)="deleteSelectedSubCategory()" class="fa fa-times-circle fa-2x" style="color: #9f1d39;"
                  aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-6">
            <div class="form-group">
              <label for="activeEndDate" class="pb-2 font-weight-bold">Active from<span class="field-mandatory">*</span></label>
              <app-date-time-picker (sendDate)="getDate($event)" formControlName="activeFrom"></app-date-time-picker>
            </div>
          </div>
          <div class="col-md-6 mt-1">
            <div class="form-group">
              <label for="activeEndDate" class="pb-2 font-weight-bold">Active to<span class="field-mandatory">*</span></label>
              <app-date-time-picker [minDate]="efectiveMinDate" formControlName="activeTo"></app-date-time-picker>
            </div>
          </div>
        </div>

        <div class="row">
          <label class="font-weight-bold col-md-3">Method<span class="field-mandatory">*</span></label>
        </div>
        <div class="row">
          <div class="form-group col-md-2 pr-0">
            <input type="radio" value="static" formControlName="isDynamic" [value]="'0'" />
            <label for="otp2" class="radio-inline ml-2">Static</label>
          </div>
          <div class="form-group col-md-2 pr-0">
            <input type="radio" value="dynamic" formControlName="isDynamic" [value]="'1'" />
            <label for="otp1" class="radio-inline ml-2">Dynamic</label>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 mt-4">
            <div class="col-md-12">

              <div class="custom-file mt-0 pt-4 pb-4">
                <input type="file" #myInputVariable class="custom-file-input" (change)="iconUpload($event)" id="c" />
                <label class="custom-file-label" for="i">
                  Choose file to upload <span class="field-mandatory">*</span>
                </label>
              </div>
            </div>
            <div class="col-md-12" *ngIf="notificationCreateForm.get('notificationAttachment').value">
              <div class="image-with-delete-icon width-180">
                <i class="fa fa-close" title="Delete" (click)="onDeleteIcon()"></i>
                <img *ngIf="showImageTag(this.notificationCreateForm.value.notificationAttachment)" src={{this.notificationCreateForm.value.notificationAttachment}} alt="Image Preview" />
                <div class="w-100">{{ this.attachmentFilePath }}</div>
              </div>
            </div>
          </div>
          <div class="col-md-6" style="margin-top: -5px;">
            <label class="font-weight-bold"> Module </label>
            <ng-select (change)="addSeletedModule($event)" class="custom" bindLabel="name" placeholder="Select Section"
              appendTo="body" [multiple]="false" [searchable]="true" [clearable]="true" [(ngModel)]="moduleList"
              [ngModelOptions]="{standalone: true}"
              [loading]="moduleDropDownLoading" (search)="onSeachModule($event)"
              (scrollToEnd)="loadMoreModuleItems($event)" (clear)="onClearModuleDropdown($event)">
              <ng-option [value]="item" *ngFor="let item of moduleDropDown">
                {{ item.moduleName }}
              </ng-option>
            </ng-select>
            <ul class="mt-3" *ngIf="notificationCreateForm.get('moduleId.string').value">
              <div class="red-container d-flex justify-content-between" style="width: 200px;">
                <span class="text-items">{{
                  notificationCreateForm.get("moduleId.string").value
                  }}
                </span>
                <img class="ml-1" (click)="deleteSelectedModule()"
                   src="./assets/images/cancel.png"
                  width="20px" height="20px" />
              </div>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 p-4">
            <div class="form-group">
              <div>
                <input id="view" formControlName="viewOnly" type="checkbox" />
                <label class="ml-3" for="view">Document view only permission</label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="card-body" *ngIf="isNotificationEdited">
      <form [formGroup]="notificationCreateForm">

        <div class="row">
          <div class="col-md-6 mt-1">
            <div class="form-group">
              <label class="pb-2 font-weight-bold" for="exampleInputEmail1">Title<span class="field-mandatory">*</span></label>

              <input type="text" class="form-control" [pattern]="noSpacesRegex" placeholder="Title" formControlName="title" required disabled
                maxlength="40" />
            </div>
          </div>
          <div class="col-md-6 mt-1">
            <div class="form-group">
              <label class="pb-2 font-weight-bold"  for="exampleInputEmail1">Sub Title<span class="field-mandatory">*</span></label>

              <input type="text" class="form-control" [pattern]="noSpacesRegex" placeholder="Sub Title" formControlName="subTitle" required
                disabled maxlength="40" />

            </div>

          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-1">
            <div class="form-group">
              <label class="pb-2 font-weight-bold" for="exampleInputEmail1">Description<span class="field-mandatory">*</span></label>

              <textarea name="" class="form-control" id="" cols="5" [pattern]="noSpacesRegex" rows="5" maxlength="100" disabled
                formControlName="description"></textarea>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mt-1">
            <div class="form-group">
              <label class="pb-2 font-weight-bold" for="exampleInputEmail1">Effective from</label>

              <div class="input-group">
                <input class="form-control" placeholder="yyyy-mm-dd" readonly name="dp" [disabled]="true"
                  formControlName="effectiveFrom" ngbDatepicker #d="ngbDatepicker"
                  (dateSelect)="onDateSelect($event,'Effective from')" />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                    <i class="fa fa-calendar" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mt-1">
            <div class="form-group">
              <label class="pb-2 font-weight-bold" for="exampleInputEmail1">Effective to</label>

              <div class="input-group">
                <input class="form-control" placeholder="yyyy-mm-dd" readonly name="dp" ngbDatepicker [disabled]="true"
                  formControlName="effectiveTo" #c="ngbDatepicker" (dateSelect)="onDateSelect($event,'Effective to')" />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="c.toggle()" type="button">
                    <i class="fa fa-calendar" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label class="pb-2 font-weight-bold">
              Main category <span class="field-mandatory">*</span>
            </label>
            <div *ngIf="notificationCreateForm.get('CategoryId.string').value" class="display-flex">
              <div>
                <span class="text-items list-ui">
                  {{ notificationCreateForm.get("CategoryId.string").value}}</span>
              </div>
              <div style="margin-top: 10px;">
                <!-- <i (click)="deleteMainCategory()" class="fa fa-times-circle fa-2x" style="color: #9f1d39;"
                  aria-hidden="true"></i> -->
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <label class="pb-2 font-weight-bold">
              Sub category<span class="field-mandatory">*</span>
            </label>

            <div *ngIf="notificationCreateForm.get('subCategoryId.string').value" class="display-flex">
              <div>
                <span class="text-items list-ui">
                  {{ notificationCreateForm.get("subCategoryId.string").value}}</span>
              </div>
              <div style="margin-top: 10px;">
                <!-- <i (click)="deleteSelectedSubCategory()" class="fa fa-times-circle fa-2x" style="color: #9f1d39;"
                  aria-hidden="true"></i> -->
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-6">
            <div class="form-group">
              <label for="activeEndDate" class="pb-2 font-weight-bold">Active from<span class="field-mandatory">*</span></label>
              <app-date-time-picker (sendDate)="getDate($event)" formControlName="activeFrom"></app-date-time-picker>
            </div>
          </div>
          <div class="col-md-6 mt-1">
            <div class="form-group">
              <label for="activeEndDate" class="pb-2 font-weight-bold">Active to<span class="field-mandatory">*</span></label>
              <app-date-time-picker [minDate]="efectiveMinDate" formControlName="activeTo"></app-date-time-picker>
            </div>
          </div>
        </div>

        <div class="row">
          <label class="font-weight-bold col-md-3">Method<span class="field-mandatory">*</span></label>
        </div>
        <div class="row">
          <div class="form-group col-md-2 pr-0">
            <input type="radio" value="static" formControlName="isDynamic" disabled [value]="0" />
            <label for="otp2" class="radio-inline ml-2">Static</label>
          </div>
          <div class="form-group col-md-2 pr-0">
            <input type="radio" value="dynamic" formControlName="isDynamic" disabled [value]="1" />
            <label for="otp1" class="radio-inline ml-2">Dynamic</label>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 mt-5">
            <div class="col-md-12">
              <div class="custom-file mt-0 pt-4 pb-4">
                <input type="file" disabled #myInputVariable class="custom-file-input" (change)="iconUpload($event)"
                  id="c" />
                <label class="custom-file-label" for="i">
                  Choose file to upload<span class="field-mandatory">*</span>
                </label>
              </div>
            </div>
            <div class="col-md-12" *ngIf="notificationCreateForm.get('notificationAttachment').value">
              <div class="image-with-delete-icon width-180">
                <!-- <i class="fa fa-close" title="Delete" (click)="onDeleteIcon()"></i> -->
                <img  *ngIf="showImageTag(notificationCreateForm.get('notificationAttachment').value)" [src]="
                    notificationCreateForm.get('notificationAttachment').value
                  " alt="Image Preview" />
                  <div class="w-100">{{ this.attachmentFilePath }}</div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mt-1">
            <label class="font-weight-bold"> Module </label>
            <ul *ngIf="notificationCreateForm.get('moduleId.string').value">
              <div class="red-container col d-flex justify-content-between">
                <span class="text-items">{{
                  notificationCreateForm.get("moduleId.string").value
                  }}
                </span>
                <img class="ml-1" (click)="deleteSelectedModule()"
                   src="./assets/images/cancel.png"
                  width="20px" height="20px" />
              </div>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <div>
                <input id="view" disabled formControlName="viewOnly" type="checkbox" />
                <label class="ml-3" for="view">Document view only permission</label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <assign-users [isAdminView]="false" [userDetails]="userDetails" (showCreateButton)="showCreateButton()"
    (hideCreateButton)="hideCreateButton()" (onSubmitAssignUsers)="updateUserDetails($event)">
  </assign-users>
  <div class="row">
    <div class="col-md-4">
      <button *ngIf="isAddButtonActive" class="btn btn-submit update-button" (click)="onCreateNotification()"><i class="fa"
          [ngClass]="{'fa-spin fa-spinner': isLoading, '': !isLoading}"></i>{{isNotificationEdited? 'Update'
        : 'Create'}}</button>
    </div>
  </div>
</div>

<ng-template #AddCategory let-c="close" let-d="dismiss">
  <div>
    <div class="modal-content">
      <div class="modal-body" style="padding: 10px; min-height: 100vh">
        <div>
          <div class="card my-3 w-100">
            <div class="card-header flexi-header">
              <h3>ADD CATEGORY</h3>
              <button type="button" class="btn" aria-label="Close" (click)="c('Cross click')">
                <i class="fa fa-times-circle fa-2x" aria-hidden="true"></i>
              </button>
            </div>
            <div class="card-body">
              <form [formGroup]="createCategoryForm">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="exampleInputEmail1">CATEGORY</label>
                      <input type="text" class="form-control" formControlName="name" placeholder="Category"
                        maxlength="40" />
                    </div>
                  </div>
                </div>
                <button class="btn btn-add pull-right" *ngIf="!isCategoryEdited" [disabled]="!createCategoryForm.valid"
                  (click)="createCategory()">
                  Add
                </button>
                <button class="btn btn-add pull-right" *ngIf="isCategoryEdited" [disabled]="!createCategoryForm.valid"
                  (click)="UpdateCatagory()">
                  UPDATE
                </button>
              </form>
            </div>
          </div>
          <div class="card my-3 w-100">
            <div class="card-header flexi-header">
              <h3>CATEGORY LIST</h3>
            </div>
            <div class="card-body">
              <div class="card table-card">
                <div class="card-body flexi-body">
                  <div class="flex-column">
                    <div class="d-flex justify-content-between mt-2 mb-3">
                      <select name="plan" id="plan" style="width: 100px" class="custom-select"
                        (change)="onLimitChangeCategory()" [(ngModel)]="categoryPTable.limit">
                        <option [ngValue]="10">10</option>
                        <option [ngValue]="20">20</option>
                        <option [ngValue]="30">30</option>
                        <option [ngValue]="40">40</option>
                        <option [ngValue]="50">50</option>
                      </select>
                      <div class="form-group form-inline mb-0">
                        <span *ngIf="categoryPTable.isLoading">Please wait...</span>
                        <input class="form-control ml-2" type="search" name="searchTerm"
                          (keyup)="categorySearchInput.next($event)" placeholder="Search here"
                          [(ngModel)]="categoryPTable.search" />
                      </div>
                    </div>
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col" sortable="Tier 1" (sort)="onSortCategory($event)">
                              S.NO
                            </th>
                            <th scope="col" sortable="Tier 2" (sort)="onSortCategory($event)">
                              CATEGORY
                            </th>

                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="
                              let item of categoryPTable.data;
                              let index = index
                            ">
                            <td>{{ index + 1 }}</td>

                            <td>{{ item.name }}</td>

                            <td>

                              <button class="btn btn-add p-2 m-1" (click)="oneditCategory(item)">
                                Edit
                              </button>
                              <button class="btn btn-clear p -2 m-1" (click)="onDeleteCategory(item._id)">
                                Delete
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="d-flex justify-content-between">
                    <p>Total Records {{ categoryPTable.total }}</p>
                    <ngb-pagination [collectionSize]="categoryPTable.total" [(page)]="categoryPTable.page" [maxSize]="5"
                      (pageChange)="onPageChangeCtegory($event)" [pageSize]="categoryPTable.limit">
                    </ngb-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #AddSubCategory let-c="close" let-d="dismiss">
  <div>
    <div class="modal-content">
      <div class="modal-body" style="padding: 10px; min-height: 100vh">
        <div>
          <div class="card my-3 w-100">
            <div class="card-header flexi-header">
              <h3>ADD SUB CATEGORY</h3>
              <button type="button" class="btn" aria-label="Close" (click)="closeModal()">
                <i class="fa fa-times-circle fa-2x" aria-hidden="true"></i>
              </button>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <form [formGroup]="notificationCreateForm">
                    <label class="pb-2 font-weight-bold"> Main Category </label>
                    <ng-select (change)="addSeletedCategory($event)" class="custom" bindLabel="name"
                      placeholder="Select Section" appendTo="body" [multiple]="false" [searchable]="true"
                      [(ngModel)]="mainCategory" [ngModelOptions]="{standalone: true}"
                      [clearable]="true" [loading]="categoryDropDownLoading" (search)="onSeachCategory($event)"
                      (scrollToEnd)="loadMoreCategoryItems($event)" (clear)="onClearCategoryDropdown($event)">
                      <ng-option [value]="item" *ngFor="let item of categoryDropDown">
                        {{ item.name }}
                      </ng-option>
                    </ng-select>

                    <div *ngIf="notificationCreateForm.get('CategoryId.string').value" class="display-flex">
                      <div>
                        <span class="text-items list-ui">
                          {{ notificationCreateForm.get("CategoryId.string").value}}</span>
                      </div>
                      <div style="margin-top: 10px;">
                        <i (click)="deleteSeletedCategory()" class="fa fa-times-circle fa-2x" style="color: #9f1d39;"
                          aria-hidden="true"></i>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="col-md-6">
                  <form [formGroup]="createSubCategoryForm">
                    <div class="form-group">
                      <label for="exampleInputEmail1" class="pb-2 font-weight-bold">Sub Category</label>
                      <input type="text" class="form-control" formControlName="name" maxlength="40"
                        placeholder="Category" />
                    </div>
                  </form>
                </div>
              </div>
              <button class="btn btn-add pull-right" *ngIf="!isSubCategoryEdited"
                [disabled]="!createSubCategoryForm.valid" (click)="createSubCategory()">
                Add
              </button>
              <button class="btn btn-add pull-right" *ngIf="isSubCategoryEdited"
                [disabled]="!createSubCategoryForm.valid" (click)="UpdateSubCatagory()">
                UPDATE
              </button>
            </div>
          </div>
          <div class="card my-3 w-100">
            <div class="card-header flexi-header">
              <h3>CATEGORY LIST</h3>
            </div>
            <div class="card-body">
              <div class="card table-card">
                <div class="card-body flexi-body">
                  <div class="flex-column">
                    <div class="d-flex justify-content-between mt-2 mb-3">
                      <select class="custom-select" style="width: 100px" name="pageSize"
                        (change)="onLimitChangeSubCategory()" [(ngModel)]="categoryPTable.limit">
                        <option [ngValue]="10">10</option>
                        <option [ngValue]="20">20</option>
                        <option [ngValue]="30">30</option>
                        <option [ngValue]="40">40</option>
                        <option [ngValue]="50">50</option>
                      </select>
                      <div class="form-group form-inline mb-0">
                        <span *ngIf="categoryPTable.isLoading">Please wait...</span>
                        <input class="form-control ml-2" type="search" name="searchTerm"
                          (keyup)="subcategorySearchInput.next($event)" placeholder="Search here"
                          [(ngModel)]="categoryPTable.search" />
                      </div>
                    </div>
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col" sortable="Tier 1" (sort)="onSortSubCategory($event)">
                              S.NO
                            </th>
                            <th scope="col" sortable="Tier 2" (sort)="onSortSubCategory($event)">
                              CATEGORY
                            </th>

                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="
                              let item of subCategoryPTable.data;
                              let index = index
                            ">
                            <td>{{ index + 1 }}</td>

                            <td>{{ item.name }}</td>

                            <td>

                              <button class="btn btn-add p-2 m-1" (click)="oneditSubCategory(item)">
                                Edit
                              </button>
                              <button class="btn btn-clear p -2 m-1" (click)="onDeleteSubCategory(item._id)">
                                Delete
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="d-flex justify-content-between">
                    <p>Total Records {{ subCategoryPTable.total }}</p>
                    <ngb-pagination [collectionSize]="subCategoryPTable.total" [(page)]="subCategoryPTable.page"
                      [maxSize]="5" (pageChange)="onPageChangeSubCtegory($event)" [pageSize]="subCategoryPTable.limit">
                    </ngb-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
