<ul ngbNav #nav="ngbNav" [(activeId)]="activeId" class="nav-tabs rounded mb-3">
  <li [ngbNavItem]="1" [disabled]="true">
    <a ngbNavLink>ENTER OPS GROUP DATA</a>
    <ng-template ngbNavContent>
      <div class="card">
        <h5 class="card-header bg-theme">EDIT OPS GROUP DATA</h5>
        <div class="card-body">
          <form [formGroup]="opsGroupForm">
            <div class="row">
              <div class="col-md-3">
                <label class="font-weight-500">Ops Group name </label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="groupname"
                />
              </div>
              <div class="col-md-6 angular2-multiselect-dropdown" >
                <label class="font-weight-500">Source Bu's </label>
                <angular2-multiselect [data]="buOption"  [(ngModel)]="selectedBuShift" [settings]="dropdownSettingsSourceBU"
                [ngModelOptions]="{ standalone: true }"
               >
                <c-badge>
                  <ng-template let-item="item">
                    {{selectedBuShift?.length}} selected
                  </ng-template>
                </c-badge>
              </angular2-multiselect>
              <div class="row">
                <div
                *ngFor="
                  let field of selectedBuShift;
                  let index = index
                "
                class="row red-container mt-3 col-md-5 ml-4"
              >
                <div class="col-10 selected-text">
                  {{ field.orgName }}
                </div>
                <div class="col-2 cancel-btn-container">
                  <img
                    (click)="deleteSourceBU(field._id)"
                    src="./assets/images/cancel.png"
                    width="20px"
                    height="20px"
                  />
                </div>
              </div>
              </div>
              </div>
              <div class="col-md-3 angular2-multiselect-dropdown">
                <label class="font-weight-500">Adminstration </label>
                <angular2-multiselect [data]="shiftSchemeAdmin"  [(ngModel)]="selectedAdmin" [settings]="dropdownSettingsAdmin"
                [ngModelOptions]="{ standalone: true }"
               >
                <c-badge>
                  <ng-template let-item="item">
                    {{selectedAdmin?.length}} selected
                  </ng-template>
                </c-badge>
              </angular2-multiselect>
              <div
              *ngFor="
                let field of selectedAdmin;
                let index = index
              "
              class="row red-container mt-3 ml-0"
            >
              <div class="col-10 selected-text">
                {{ field.name }}
              </div>
              <div class="col-2 cancel-btn-container">
                <img
                  (click)="deleteAdmin(field._id)"
                  src="./assets/images/cancel.png"
                  width="20px"
                  height="20px"
                />
              </div>
            </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-3">
                <label class="font-weight-500">Number of Teams </label>
                <ng-select
                  [items]="teamNo"
                  class="custom"
                  appendTo="body"
                  [multiple]="false"
                  [clearable]="true"
                  [closeOnSelect]="true"
                  formControlName="numberteam"
                  [(ngModel)]="teamCountInt"
                  [ngModelOptions]="{ standalone: true }"
                >
                </ng-select>
              </div>
              <div class="col-md-3 align-self-end">
                <button class="btn btn-success" (click)="addAnotherTeam()">
                  Add Another Team
                </button>
              </div>
            </div>

            <div class="my-3" *ngIf="!ifNoTeam">
              <div
                class="border d-inline-flex mb-3 p-2 rounded mr-2"
                *ngFor="let team of teamNameArray; let i = index"
              >
                <div class="form-inline">
                  <label class="font-weight-500 mr-2">Team name</label>
                  <input
                    type="text"
                    class="form-control mx-2"
                    id="{{ 'teamIdName' + i }}"
                    value="{{ team.name }}"
                    [disabled]="team.isInputDisabled"
                  />
                </div>
                <button
                  class="btn btn-danger"
                  id="{{ i }}"
                  (click)="removeTeambutton(team)"
                >
                  Remove Team
                </button>
                <button
                  *ngIf="team.isInputDisabled"
                  class="btn btn-warning ml-2"
                  (click)="enableInput(i)"
                >
                  Edit Team
                </button>
                <button
                  *ngIf="!team.isInputDisabled"
                  class="btn btn-success ml-2"
                  (click)="addTeambutton(i)"
                >
                  Update Name
                </button>
              </div>
            </div>
            <hr />
            <div class="form-inline">
              <label class="font-weight-500 mr-2">Swap Setting</label>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  formControlName="swap"
                  id="swapNo"
                  value="0"
                />
                <label class="form-check-label" for="swapNo">No swap</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  formControlName="swap"
                  id="swapGroup1"
                  value="1"
                />
                <label class="form-check-label" for="swapGroup1"
                  >Swap at Ops Group level</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  formControlName="swap"
                  id="swapTeam2"
                  value="2"
                />
                <label class="form-check-label" for="swapTeam2"
                  >Swap at Ops Team level</label
                >
              </div>
            </div>
          </form>
        </div>
        <div class="card-footer">
          <button
            type="button"
            class="bg-theme border-0 btn d-flex align-items-center"
            (click)="changeTabId()"
          >
            <span>Next</span
            ><span class="material-icons ml-1"> arrow_forward </span>
          </button>
        </div>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="2" [disabled]="true">
    <a ngbNavLink>ADD STAFF</a>
    <ng-template ngbNavContent>
      <div class="card">
        <h5 class="card-header bg-theme">ADD/REMOVE STAFF</h5>
        <div class="card-body">
          <form class="mb-3" [formGroup]="opsGroupForm">
            <div class="row">
              <div class="col-md-3">
                <label class="font-weight-500">Ops Group </label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="groupname"
                />
              </div>
              <div class="col-md-9" *ngIf="!ifNoTeam">
                <div class="form-group">
                  <label class="font-weight-500"> Ops Team </label>
                  <br />
                  <div
                    class="form-check form-check-inline"
                    *ngFor="let team of teamNameArray; let i = index"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      name="opsTeamName"
                      formControlName="opsTeamName"
                      [value]="i"
                      [(ngModel)]="selectedTeamIndex"
                      [ngModelOptions]="{ standalone: true }"
                      (change)="selectedteam(i, 'o', team._id)"
                    />
                    <label class="form-check-label">
                      {{ team.name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="row mb-3">
              <div class="col-md-3">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    [value]="1"
                    (change)="handleApprove(1)"
                    formControlName="opsAction"
                    id="opsAction1"
                  />
                  <label
                    class="form-check-label font-weight-500"
                    for="opsAction1"
                  >
                    Add Staff
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    [value]="2"
                    (change)="handleApprove(2)"
                    formControlName="opsAction"
                    id="opsAction2"
                  />
                  <label
                    class="form-check-label font-weight-500"
                    for="opsAction2"
                  >
                    Remove Staff
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    [value]="3"
                    (change)="handleApprove(3)"
                    formControlName="opsAction"
                    id="opsAction3"
                  />
                  <label
                    class="form-check-label font-weight-500"
                    for="opsAction3"
                  >
                    Transfer within this ops group
                  </label>
                </div>
              </div>
              <div class="col-md-9" *ngIf="addFromBu">
                <label class="font-weight-500">
                  <ng-container *ngIf="addFromBu && !transferFromBu">
                    Selected Source BU's
                  </ng-container>
                  <ng-container *ngIf="transferFromBu">
                    Transfer to
                  </ng-container>
                </label>
                <br />
                <ng-container *ngIf="!transferFromBu">
                  <div
                    class="labels label-light mr-1 mb-1"
                    *ngFor="let buShift of groupBuShift; let i = index"
                  >
                    <span>{{ buShift.orgName }} </span>
                  </div>
                </ng-container>
                <ng-container *ngIf="transferFromBu">
                  <div
                    class="form-check form-check-inline"
                    *ngFor="let team of transferTeamArr; let i = index"
                  >
                    <input
                      type="radio"
                      class="form-check-input"
                      formControlName="opsTeamTransfer"
                      (change)="selectedteam(i, 't', team._id)"
                      [value]="i"
                      [(ngModel)]="selectedTransferTeamIndex"
                      [ngModelOptions]="{ standalone: true }"
                    />
                    <label class="form-check-label">
                      {{ team.name }}
                    </label>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="row" *ngIf="addFromBu">
              <div class="col-md-3">
                <label class="font-weight-500">Add Staff</label>
                <ng-select class="custom" [(ngModel)]="staffDetail" [ngModelOptions]="{ standalone: true }" [closeOnSelect]="true"
                  [clearable]="staffDetail ? true : false">
                  <ng-option *ngFor="let item of staffUser" [value]="item">{{item?.name}} ({{item?.staffId}})</ng-option>
                </ng-select>
              </div>
              <div
                class="col-md-2 d-flex justify-content-start align-items-end"
              >
                <button class="btn btn-add" (click)="addStaffUser()">
                  Add Staff
                </button>
              </div>
            </div>
          </form>
          <div class="row">
            <div class="col-md-12">
              <label class="font-weight-500">Staff Table</label>
              <div class="table-responsive border-theme rounded">
                <table class="table m-0">
                  <thead>
                    <tr>
                      <th scope="col">USER NAME</th>
                      <th scope="col">STAFF ID</th>
                      <th scope="col">DATE OF JOINNING</th>
                      <th scope="col">CONTACT NUMBER</th>
                      <th scope="col">APPOINMENT</th>
                      <th scope="col">BUSINESS UNIT</th>
                      <th scope="col">ACTIONS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let staffData of staffUserDet; let i = index">
                      <td>{{ staffData.name }}</td>
                      <td>{{ staffData.staffId }}</td>
                      <td></td>
                      <td>{{ staffData.contactNumber }}</td>
                      <td>{{ staffData.appointmentId?.name }}</td>
                      <td>
                        {{ staffData.parentBussinessUnitId?.orgName ? staffData.parentBussinessUnitId?.orgName : staffData?.parentBussinessUnitId?.sectionId?.departmentId?.companyId?.name + '>' +
                        staffData?.parentBussinessUnitId?.sectionId?.departmentId?.name + '>' + staffData?.parentBussinessUnitId?.sectionId?.name +
                        '>' + staffData?.parentBussinessUnitId?.name }}
                      </td>
                      <td>
                        <button
                          class="btn"
                          *ngIf="removeFromBu"
                          (click)="removeStaffbutton(staffData, i)"
                          [disabled]="staffData.removed"
                          [class.btn-danger]="!staffData.removed"
                        >
                          {{ staffData.removed ? "Removed" : "Remove Staff" }}
                        </button>
                        <button
                          class="btn btn-danger"
                          *ngIf="transferFromBu"
                          (click)="transferUsers(staffData)"
                        >
                          Transfer Staff
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="d-flex">
            <button
              type="button"
              class="align-items-center btn btn-outline-dark d-flex"
              (click)="changeTabIdPrevious()"
            >
              <span class="material-icons mr-1"> arrow_back </span>
              <span>Previous</span>
            </button>
            <button
              class="bg-theme border-0 btn d-flex align-items-center ml-2"
              (click)="changeTabIdNextBTn()"
            >
              <span>Next</span>
              <span class="material-icons ml-1"> arrow_forward </span>
            </button>
          </div>
        </div>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="3" [disabled]="true">
    <a ngbNavLink>UPDATE OPS GROUP</a>
    <ng-template ngbNavContent>
      <div class="card">
        <h5 class="card-header bg-theme">VIEW DETAILS</h5>
        <div class="card-body">
          <form [formGroup]="opsGroupForm">
            <div class="row">
              <div class="col-md-3">
                <label class="font-weight-500">Ops group name </label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="groupname"
                  readonly
                />
              </div>
              <div class="col-md-6">
                <label class="font-weight-500">Source BU's </label><br />
                <div
                  class="labels mb-1 mr-1"
                  *ngFor="let buShiftView of selectedBuShift"
                >
                  <span class="text">{{ buShiftView.orgName }}</span>
                </div>
              </div>
              <div class="col-md-3">
                <label class="font-weight-500">Adminstration </label><br />
                <div
                  class="labels mr-1 mb-1"
                  *ngFor="let adminView of this.selectedAdmin"
                >
                  <span class="text">{{ adminView.name }}</span>
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label class="font-weight-500">Number of Teams </label>
                  <ng-select
                    [items]="teamNo"
                   appendTo="body"
                    [multiple]="false"
                    [clearable]="true"
                    [closeOnSelect]="true"
                    formControlName="numberteam"
                    [(ngModel)]="teamCountInt"
                    [ngModelOptions]="{ standalone: true }"
                    [readonly]="true"
                   >
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="!ifNoTeam">
              <div class="col-md-12">
                <div
                  class="border d-inline-flex mb-3 p-2 rounded mr-2"
                  *ngFor="let team of teamNameArray"
                >
                  <div class="form-inline">
                    <label class="font-weight-500 mr-2">Team name</label>
                    <input
                      type="text"
                      class="form-control"
                      value="{{ team.name }}"
                      readonly
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="form-inline">
              <label class="font-weight-500 mr-2">Swap Setting</label>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  formControlName="swap"
                  id="swapNo"
                  value="0"
                  disabled
                />
                <label class="form-check-label" for="swapNo">No swap</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  formControlName="swap"
                  id="swapGroup1"
                  value="1"
                  disabled
                />
                <label class="form-check-label" for="swapGroup1"
                  >Swap at Ops Group level</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  formControlName="swap"
                  id="swapTeam2"
                  value="2"
                  disabled
                />
                <label class="form-check-label" for="swapTeam2"
                  >Swap at Ops Team level</label
                >
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label class="font-weight-500">Ops Group </label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="groupname"
                    readonly
                  />
                </div>
              </div>
              <div class="col-md-9" *ngIf="!ifNoTeam">
                <div class="form-group">
                  <label class="font-weight-500"> Ops Team </label>
                  <br />
                  <div
                    class="form-check form-check-inline"
                    *ngFor="let team of teamNameArray; let i = index"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      [value]="i"
                      [(ngModel)]="selectedTeamIndex"
                      [ngModelOptions]="{ standalone: true }"
                      formControlName="opsTeamName"
                      (change)="selectedteam(i, 'o', team._id)"
                      readonly
                    />
                    <label class="form-check-label">{{ team.name }}</label>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <hr />
          <div class="row">
            <div class="col-md-12">
              <label class="font-weight-500">Staff Table</label>
              <div class="table-responsive border-theme rounded">
                <table class="table m-0">
                  <thead>
                    <tr>
                      <th scope="col">USER NAME</th>
                      <th scope="col">STAFF ID</th>
                      <th scope="col">DATE OF JOINNING</th>
                      <th scope="col">CONTACT NUMBER</th>
                      <th scope="col">APPOINMENT</th>
                      <th scope="col">BUSINESS UNIT</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let staffData of staffUserDet">
                      <td>{{ staffData.name }}</td>
                      <td>{{ staffData.staffId }}</td>
                      <td></td>
                      <td>{{ staffData.contactNumber }}</td>
                      <td>{{ staffData.appointmentId.name }}</td>
                      <td>
                        {{ staffData.parentBussinessUnitId?.orgName ? staffData.parentBussinessUnitId?.orgName : staffData?.parentBussinessUnitId?.sectionId?.departmentId?.companyId?.name + '>' +
                        staffData?.parentBussinessUnitId?.sectionId?.departmentId?.name + '>' + staffData?.parentBussinessUnitId?.sectionId?.name +
                        '>' + staffData?.parentBussinessUnitId?.name }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="d-flex">
            <button
              type="button"
              class="align-items-center btn btn-outline-dark d-flex"
              (click)="changeTabIdPreviouBtn()"
            >
              <span class="material-icons mr-1"> arrow_back </span>
              <span>Previous</span>
            </button>
            <button
              class="btn border-0 d-flex align-items-center ml-2 btn-success"
              (click)="onEditForm()"
              [disabled]="updateOpsGrpLoader"
            >
              <span>Update OPS Group</span>
              <span class="material-icons mr-1" *ngIf="!updateOpsGrpLoader">
                arrow_upward
              </span>
              <span
                *ngIf="updateOpsGrpLoader"
                class="spinner-border spinner-border-sm ml-2"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          </div>
        </div>
      </div>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav"></div>
