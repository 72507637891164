import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { Subject, Subscription, of } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { map, debounceTime, distinctUntilChanged, mergeMap, delay, finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { SortableHeader, SortEvent } from 'src/app/shared/directive/sortable.directive';
import { NotificationService } from 'src/app/users/service/notification/notification.service';
// import {environment} fr

class DataTablesResponse {
  data: any[] = [];
  draw: number;
  recordsFiltered: number;
  totalRecords: number;
  recordsTotal: Number;
}

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {



  schedulePTable;
  isLoading: boolean = false;
  isTableLoading: boolean = true;
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  public scheduleSearchInput = new Subject<KeyboardEvent>();
  @Output() scheduleView = new EventEmitter<any>();

  @Input() buId: string = null;
  private scheduleSearchSubscription: Subscription;
  constructor(private route: ActivatedRoute,
    public toastService: ToastrService,
    private notificationService: NotificationService,) {
      this.schedulePTable = {
        sortWith: 'createdBy',
        sortBy: 'desc',

        data: [],
        page: 1,
        limit: 10,
        search: '',

      };
      // this.companyName = this.storageService.getByKey('companyId');

      this.scheduleSearchSubscription = this.scheduleSearchInput
        .pipe(
          map((event) => {
            const target = event.target as HTMLTextAreaElement;
            return target.value;
          }),
          debounceTime(500),
          distinctUntilChanged(),
          mergeMap((search) => of(search).pipe(delay(500)))
        )
        .subscribe((input) => {
          this.schedulePTable.page = 1;
          this.loadSchedule();
        });
    // this.route.params.subscribe(params => {
    //   this.ngOnInit(); // reset and set based on new parameter this time
    // });
  }

  ngOnInit(): void {
    this.loadSchedule();
  }

  onLimitChangeSchedule() {
    this.loadSchedule();
  }

  loadSchedule() {
    const { data, loading, total, ...rest } = this.schedulePTable
    const body = {
      timeZone : "GMT+0530",
      buId : this.buId
    }
    const subscribe = this.notificationService
      .getScheduleNotification(body, rest)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const { totalRecords, data } = response;
          this.schedulePTable.data = data;
          this.schedulePTable.total = totalRecords;
          this.isTableLoading = false;
        },
        (error) => {
          this.schedulePTable.data = [];
          this.schedulePTable.total = 0;
          this.toastService.error('No list found');
        }
      );
  }

  onSortSchedule({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.schedulePTable.sortWith = !!direction ? column : 'activeTo';
    this.schedulePTable.sortBy = !!direction ? direction : 'desc';
    this.loadSchedule();
  }

  onPageChangeSchedule(page: number) {
    this.schedulePTable.page = page;
    this.loadSchedule();
  }

  onViewSchedule(notificationId){
    this.scheduleView.emit({ notificationId, isView: true });
  }

  onEditSchedule(notificationId){
    this.scheduleView.emit({ notificationId, isView: false });
  }

  ngOnDestroy(): void {
    this.scheduleSearchSubscription && this.scheduleSearchSubscription.unsubscribe();
  }

}
