import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectDropdownComponent {
  @Input() list: any[];
  showDropDown = false
  @Output() shareCheckedList = new EventEmitter();
  @Output() shareIndividualCheckedList = new EventEmitter();

 @Input() checkedList: any[];
  currentSelected: {};
  options = [
    { value: "Small", selected: false },
    { value: "Medium", selected: false },
    { value: "Large", selected: false }
  ];

  searchvalue: any;
  filterdOptions = [];

  constructor() {
    this.checkedList = [];
  }

  getSelectedValue(status: Boolean, value) {
    if (status) {
      this.checkedList.push(value);
    } else {
      var index = this.checkedList.indexOf(value.id);
      this.checkedList.splice(index, 1);
    }

    this.currentSelected = { checked: status, name: value };

    //share checked list
    this.shareCheckedlist();

    //share individual selected item
    this.shareIndividualStatus();
  }
  shareCheckedlist() {
    this.shareCheckedList.emit(this.checkedList);
  }
  shareIndividualStatus() {
    this.shareIndividualCheckedList.emit(this.currentSelected);
  }
}
