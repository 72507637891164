import {  Component, OnInit } from '@angular/core';
import { CompanySetupService } from 'src/app/users/service/company-setup/company-setup.service';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { GamificationService } from 'src/app/users/service/gamification/gamification.service';

@Component({
  selector: 'app-point-system',
  templateUrl: './point-system.component.html',
  styleUrls: ['./point-system.component.scss']
})
export class PointSystemComponent implements OnInit {
  iconsList:any=[];
  constructor(
    private companyService: CompanySetupService,
    private tostService: ToastrService,
    private gamificationService: GamificationService
  ) { }

  pointObj={
    title: "",
    description: "" ,
    iconURL: "",
    ID: "",
    arrayIndex:"",
    status:false,
    isFromEdit: false,
  }
  updatedRecord=[];
  emptyPointObj=JSON.parse(JSON.stringify(this.pointObj));
  companySettingsId: string;
  pointSystems = [];
  selectedRecord:any=null;
  expandIcons=false;
  ngOnInit(): void {
    this.iconsList = [11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69]
    this.getPointSystem()
  }


  public trackItem (index: number, item: any) {
    return item.trackId;
  }

  selectIcon(icon){
    const index = this.iconsList.findIndex(i => i.toString() === icon.toString());
    this.iconsList.splice(index, 1);
    if(this.pointObj.iconURL){
      this.iconsList.push(+this.pointObj.iconURL);
      this.iconsList = this.uniqueIconList(this.iconsList);
    }

    this.pointObj.iconURL = icon;
  }

  getPointSystem() {
    this.companyService.getcompanySetting().subscribe((res) => {
      this.companySettingsId = res.data._id;
      this.pointSystems = [...res.data.pointSystems]
      this.pointSystems?.map((item,index)=>{
        item.isCheckedBox=false;
        item.index=index;
      })

    },
      (error) => {

      });
  }

  onCheckBoxChanges(item, index, e) {
  this.pointSystems?.map((data:any)=>{
        data.isCheckedBox = (index == data.index)?true:false;
        });
    if(e.target.checked === false){
     this.pointObj.title = '',
     this.pointObj.description = '',
     this.pointObj.iconURL = '';
     this.selectedRecord=null;
   }
   else{
     this.selectedRecord=item;
     this.selectedRecord['index']=index;
   }
 }

 cancelData(){
  this.pointObj=JSON.parse(JSON.stringify(this.emptyPointObj));
 }

  iconUpload(event) {
    if (event.target.files && event.target.files.length) {
      const file: File = event.target.files[0];
      const fileUpload = this.gamificationService
        .uploadFile(file)
        .pipe(
          finalize(() => {
            fileUpload.unsubscribe();
          })
        )
        .subscribe(
          (data: any) => {
            this.pointObj.iconURL = `${environment.imageUrl}${data?.filePath}`;
            this.tostService.success('Icon Uploaded Successfully')
          }
        );
    }
  }

  updatePointSettings() {
    let pointSystems =this.pointSystems?.filter(item=>item.isEnabled==true);
    let data={
      pointSystems: pointSystems ,
      pageId:this.companySettingsId
    }
    this.companyService.updateCompanySettings(data).subscribe((res) => {
      this.getPointSystem();
      this.tostService.success('Updated SuccessFully');
    },
      (error) => {
        console.error(error)
      });
  }

  editRecord(){
    this.pointObj.arrayIndex = this.selectedRecord.index;
    this.pointObj.ID = this.selectedRecord._id
    this.pointObj.title = this.selectedRecord.title;
    this.pointObj.description = this.selectedRecord.description;
    this.pointObj.iconURL = this.selectedRecord.icon;
    this.pointObj.status=this.selectedRecord.isEnabled;
    this.pointSystems[this.selectedRecord.index].isCheckedBox =false;
    this.pointObj.isFromEdit = true;
    this.selectedRecord=null;
    if(this.pointObj.iconURL){
      let index = this.iconsList?.findIndex(item=>item ==+this.pointObj.iconURL)
        this.iconsList.splice(index,1);
      this.iconsList = this.uniqueIconList(this.iconsList);
    }

  }

  uniqueIconList(arr){
    return arr.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
  }

  updateRecord(data){
    this.pointSystems[this.pointObj.arrayIndex].title=data.title;
    this.pointSystems[this.pointObj.arrayIndex].description=data.description;
    this.pointSystems[this.pointObj.arrayIndex].icon=data.iconURL;
    this.pointSystems[this.pointObj.arrayIndex].isEnabled= true;
   this.updatedRecord.push(this.pointSystems[this.pointObj.arrayIndex]);
    this.pointObj=JSON.parse(JSON.stringify(this.emptyPointObj));
  }
  activateRecord(data){
    this.pointSystems.push({title:data.title, icon:data.iconURL,description:data.description,status: true, isEnabled: true})
    // this.pointSystems[this.pointObj.arrayIndex].title=data.title;
    // this.pointSystems[this.pointObj.arrayIndex].description=data.description;
    // this.pointSystems[this.pointObj.arrayIndex].icon=data.iconURL;
    // this.pointSystems[this.pointObj.arrayIndex].status=true;
    this.pointObj=JSON.parse(JSON.stringify(this.emptyPointObj));
  }
}
