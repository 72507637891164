import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { StorageService } from 'src/app/core/service/storage.service';
import { queryParam } from 'src/app/core/utils/helper';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogsService {
  private baseUrl: string;
  private get Headers(): HttpHeaders {
    return new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {
    this.baseUrl = environment.baseUrl;
  }

  getMasterIntegratinLogs(data){
    return this.http.get(`${this.baseUrl}/integration/readMasterData/?${queryParam(data)}`, {
      headers: this.Headers,

    })
    .pipe(map((res) => res as any))
  }

  getUsersIntegratinLogs(data){
    return this.http.get(`${this.baseUrl}/integration/read?${queryParam(data)}`, {
      headers: this.Headers,

    })
    .pipe(map((res) => res as any))
  }

  getLbsQuotaIntegratinLogs(data){
    return this.http.get(`${this.baseUrl}/integration/readQuota?${queryParam(data)}`, {
      headers: this.Headers,

    })
    .pipe(map((res) => res as any))
  }
  getLbsApprovedIntegratinLogs(data){
    return this.http.get(`${this.baseUrl}/integration/readApprove?${queryParam(data)}`, {
      headers: this.Headers,

    })
    .pipe(map((res) => res as any))
  }

  downloadFile(url: any){
    const fileUrl = url.startsWith('/') ? `${this.baseUrl}${url}` : `${this.baseUrl}/${url}`;
    return this.http.get(fileUrl, { responseType: 'blob' });
  }
}
