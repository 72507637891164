import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { UsersService } from 'src/app/users/service/user-service/users.service';

@Component({
  selector: 'app-facial-overview',
  templateUrl: './facial-overview.component.html',
  styleUrls: ['./facial-overview.component.scss'],
})
export class FacialOverviewComponent implements OnInit {
  @Input() bu;
  @Output() edit = new EventEmitter<any>();
  facialList: any;
  facialIdLoader: boolean = true;
  constructor(private userService: UsersService) {}

  ngOnInit() {
    const facialID = this.userService
      .getFacialList(this.bu._id)
      .pipe(
        finalize(() => {
          this.facialIdLoader = false;
          facialID.unsubscribe();
        })
      )
      .subscribe((res) => {
       // if (res?.success) {
          this.facialList = res?.message.facialOverviewData;
       // }
      });
  }

  editFacialId(item) {
    this.edit.emit(item);
  }
}
