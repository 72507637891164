import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  NgbDate,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, of } from 'rxjs';
import {
  map,
  debounceTime,
  distinctUntilChanged,
  mergeMap,
  delay,
  finalize,
} from 'rxjs/operators';
import {
  SortableHeader,
  SortEvent,
} from 'src/app/shared/directive/sortable.directive';
import { CentralBuilderService } from 'src/app/users/service/central-builder/central-builder.service';
import { CustomFormService } from 'src/app/users/service/custom-form/custom-form.service';
import { tableParam } from 'src/app/core/model/table-param.model';
import { TableService } from 'src/app/core/service/table.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-forms-overview',
  templateUrl: './forms-overview.component.html',
  styleUrls: ['./forms-overview.component.scss'],
})
export class FormsOverviewComponent implements OnInit {
  isTableLoading: boolean = false;
  usersPTable;
  public isModuleView: boolean = false;
  selectedModule;
  hoveredDate: NgbDate | null = null;
  maxDate;
  columns = ['name', 'email', 'company'];
  @Output() isManage = new EventEmitter<string>();
  FormExport: FormGroup;
  workFlow = [];
  @Output() EditEvent = new EventEmitter<string>();
  @Output() ViewEvent = new EventEmitter<string>();
  csvData: any[] = [];
  formTableColumn = ['createdAt', 'title', 'isDeployed', 'createdBy.name', 'status', 'status'];
  tableQueryParams = tableParam;
  public userssearchInput = new Subject<KeyboardEvent>();

  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  // companyName: string;
  /// bussiness unit over view table config
  onSortUsers({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.usersPTable.sort = { key: column, order: direction as any };
    this.loadUsers();
  }

  onPageChangeUsers(page: number) {
    // this.usersPTable.page = page;
    this.tableQueryParams.start = +page > 1 ? (page - 1) * this.usersPTable.limit : 0;
    this.loadUsers();
  }
  onLimitChangeUsers() {
    this.tableQueryParams.start = 0;
    this.loadUsers();
  }

  private usersSearchSubscription: Subscription;
  @ViewChild('exportForm', { static: true })
  exportForm: TemplateRef<any>;
  parentBUUnit = null;
  formDate: any
  toDate: string
  constructor(
    private customFormService: CustomFormService,
    public toastService: ToastrService,
    public modelService: NgbModal,
    private formBuilder: FormBuilder,
    private centralBuilderService: CentralBuilderService,
    private tableService: TableService,
    private datepipe:DatePipe
  ) {
    // this.config.notFoundText = 'Custom not found';
    this.usersPTable = {
      sortBy: 'desc',
      sortWith: 'createdBy',
      total: 0,
      data: [],
      page: 1,
      limit: 10,
      search: '',
    };
    // this.companyName = this.storageService.getByKey('companyId');

    this.usersSearchSubscription = this.userssearchInput
      .pipe(
        map((event) => {
          const target = event.target as HTMLTextAreaElement;
          return target.value;
        }),
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap((search) => of(search).pipe(delay(500)))
      )
      .subscribe((input) => {
        // this.usersPTable.page = 1;
         this.tableQueryParams.start = 0;
        this.tableQueryParams.search.value = input;
        this.loadUsers();
      });
  }

  ngOnInit(): void {
    this.tableQueryParams.draw = 0;
    this.tableQueryParams.start = 0;
    this.tableQueryParams.search.value = "";
    this.loadUsers();
  }

  initiExportForm() {
    this.FormExport = this.formBuilder.group({
      customFormId: ['', Validators.required],
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
      workflow: this.formBuilder.array([]),
      formStatus:[]
    });
  }

  getQuestions(moduleId: string) {
    this.centralBuilderService.getSingleQuestions(moduleId).subscribe((res) => {

      this.isModuleView = true;
      this.selectedModule = res.data.moduleData;
    });
  }
  loadUsers() {
    // const { data, total, isLoading, ...rest } = this.usersPTable
    let tableCol: any = this.tableService.initializeTableParamsColums(this.formTableColumn, [0, 1, 2, 3, 4], [0, 1, 2, 3, 4, 5]) || [];
    this.tableQueryParams.columns = tableCol;
    this.tableQueryParams.draw++;
    this.tableQueryParams.length = this.usersPTable.limit;
    this.isTableLoading = true;
    const companyId = localStorage.getItem('flexicompanyId');
    const subscribe = this.customFormService
      .getAllForms(companyId,this.tableQueryParams)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          this.usersPTable.data = response?.data || [];
          this.usersPTable.total = response?.recordsFiltered ||0;
          this.isTableLoading = false;
        },
        (error) => {
          this.usersPTable.data = [];
          this.usersPTable.total = 0;
          this.isTableLoading = false;
          this.toastService.error('No list found');
        }
      );
  }
  onEditForm(data: any) {
    this.EditEvent.emit(data);
  }
  onViewForm(data: any) {
    this.ViewEvent.emit(data);
  }

  onExternalURL(data) {
    const URL = data.formUrl.replace('#', '')
    window.open(`/${URL}`);
  }

  qrCodeUrl(item) {
    // const url = location.protocol + '//' + window.location.hostname + '/' + item;
    const baseUrl = item.replace('#', '')
    const url = window.location.origin + '/' + baseUrl
    return url
  }

  get workFlowStatusControl(): FormArray {
    return this.FormExport.get('workflow') as FormArray;
  }

  addParentBussinentUnit(value) {
    this.parentBUUnit = []
  }

  getParentElementWithValue(parentArray, selectedValue) {
    let parentElement = null;
    parentArray.find((parent) => {
      if (parent.workflowStatus.some((nested) => nested === selectedValue)) {
        parentElement = parent;
      }
    });
    return parentElement;
  }

  async addWorkFlowStatus(value) {
    const workFlowDetails = this.getParentElementWithValue(this.workFlow, value)
    if (value) {
      const Control = this.workFlowStatusControl;
      const index = await Control.value.findIndex((x) => x.workflowId === value.workflowId);
      if (index > -1) {
        Control.removeAt(index)
        let field = this.formBuilder.group({
          workflowId: [workFlowDetails._id, Validators.compose([Validators.required])],
          workflowStatusId: [value._id, Validators.compose([Validators.required])],
        });
        Control.push(field);
      } else {
        let field = this.formBuilder.group({
          workflowId: [workFlowDetails._id, Validators.compose([Validators.required])],
          workflowStatusId: [value._id, Validators.compose([Validators.required])],
        });
        Control.push(field);
      }
    }
  }


  onExportForm(item) {
    item && this.initiExportForm();
    this.workFlow = item.workflow;

    this.FormExport.patchValue({
      customFormId: item._id,
      formStatus:item.formStatus
    });
    this.modelService.open(this.exportForm, {
      windowClass: 'modal-ui-fix right-align',
    });
  }

  modalClose() {
    this.modelService.dismissAll();
  }


  onManage(data) {
    this.isManage.emit(data);
  }
  formatDateString(data) {
    const day = data.day + "-" + data.month + "-" + data.year;
    return day;
  }
  onSubmit() {
    const data = {
      customFormId: this.FormExport.value?.customFormId,
      fromDate:  moment()
      .set({
        y: this.FormExport.value?.fromDate?.year,
        M: this.FormExport.value?.fromDate?.month - 1,
        D: this.FormExport.value?.fromDate?.day,
      }).toISOString(),
      toDate:  moment()
      .set({
        y: this.FormExport.value?.toDate?.year,
        M: this.FormExport.value?.toDate?.month - 1,
        D: this.FormExport.value?.toDate?.day,
      }).toISOString(),
     workflow : this.FormExport.value?.workflow
    }
    const sub = this.customFormService
      .exportForm(data)
      .pipe(finalize(() => sub.unSubscribe()))
      .subscribe(
        (res: any) => {
          if (res?.data?.csvLink) {
            this.downloadCSV(res.data.csvLink);
            this.FormExport.reset();
            this.modelService.dismissAll();
          } else {
            this.toastService.error(res.message);
          }
        },
        (error) => {
          this.toastService.error(
            'Something went wrong. Please try again later.'
          );
        }
      );
  }
  downloadCSV(data) {
    const sub = this.customFormService.getExportCSV(data)
      .subscribe((response) => {
        const list = data.split('\n');

        list.forEach(e => {

          this.csvData.push(e);

        });
        const a = document.createElement("a");
        a.href = "data:text/csv," + response;
        let filename = "users_workflowStatus_data" + this.datepipe.transform(new Date(), 'dd-MMM-yyyy HH:mm:ss')
        a.setAttribute("download", filename + ".csv");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }, (error) => {
        console.log(error);
      })

  }
  ngOnDestroy(): void {
    this.usersSearchSubscription && this.usersSearchSubscription.unsubscribe();
  }
}
