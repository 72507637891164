import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Company } from 'src/app/core/model/definition.model';
import { StorageService } from 'src/app/core/service/storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
private token
public readonly JWT_TOKEN = 'adminToken';
  private baseUrl: string;
  private get Headers(): HttpHeaders{
    return new HttpHeaders({ Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization':   `${this.token}`, })
  }
  constructor(private http: HttpClient, private storageService: StorageService) {
this.loadToken()
    this.baseUrl = environment.baseUrl
  }

  async loadToken() {
    this.token = await this.storageService.getByKey(this.JWT_TOKEN);

  }
  getCompanies({page, limit, search, sort}): Observable<any>{
    return this.http.get(`${this.baseUrl}/company/`, {headers: this.Headers, params:{page, limit, search, sortWith:sort.key, sortBy:sort.order}});
  }

  uploadFile(fileToUpload){
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return this.http.post(`${this.baseUrl}/upload/`, formData)
    .pipe(map((res) => res as any))
  }
  uploadAnswerImage(fileToUpload){
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return this.http.post(`${this.baseUrl}/wall/uploadFiles`, formData)
    .pipe(map((res) => res as any))
  }

  uploadWallFile(fileToUpload){
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return this.http.post(`${this.baseUrl}/wall/uploadFiles`, formData)
    .pipe(map((res) => res as any))
  }

  createCompany(company: Company): Observable<any> {
    return this.http.post(`${this.baseUrl}/company/`, {...company}, {headers: this.Headers});
  }

  // updateWall(data, id): Observable<any> {
  //   return this.http
  //     .put(`${this.baseUrl}/wall/updateWall/${id}`, data, {
  //       headers: this.Headers,
  //     })
  //     .pipe(map((res) => res as any));
  // }

  updateCompany(company: Company,companyId:string): Observable<any> {
    // http://doodlebluelive.com:2223/api/company/60379e1d9792158c58568484  
    return this.http.put(`${this.baseUrl}/company/${companyId}`, {...company}, {headers: this.Headers}).pipe(map((res) => res as any));
  }

  getCompanyById(id: String): Observable<any> {
    return this.http.get(`https://admin.newstaging.gq/api/company/getActiveCompanyByInput/?input=${id}`, {headers: this.Headers});
  }

  deleteCompanyById(companyId: String):Observable<any> {
    return this.http.delete(`https://admin.newstaging.gq/api/company/deleteCompany/${companyId}`, {headers: this.Headers});
  }

  checkImagePath(path) {
    if(path.startsWith('/')) return `${environment.imageUrl}${path}`
    return `${environment.imageUrl}/${path}`
}
}
