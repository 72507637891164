<div class="card mb-3">
  <div class="card-header flexi-header">
    <h3>LEAVE MANAGEMENT SETTINGS</h3>
  </div>
  <div class="card-body" [formGroup]="leaveMngForm">
    <div class="justify-content-SB">
      <div class="col-6">
        <div class="row">
          <label class="font-weight-bold">Block Leave Configuration</label>
        </div>
        <div class="row">
          <div class="col-3">
            <input
              type="radio"
              id="active"
              [value]="1"
              formControlName="blockLeave"
            />5 + 2
          </div>
          <div class="col-3">
            <input
              type="radio"
              id="active"
              [value]="2"
              formControlName="blockLeave"
            />6 + 1
          </div>
          <div class="col-3">
            <input
              type="radio"
              id="active"
              [value]="3"
              formControlName="blockLeave"
            />7 + 0
          </div>
        </div>
      </div>
      <div class="col-3">
        <label class="font-weight-bold"
          >Leave Application Dates Adjustment</label
        >
        <select class="dropdown-select w-100 form-control">
          <option [value]="null" selected>Select</option>
        </select>
      </div>
      <div class="col-3 mb-2">
        <label class="font-weight-bold"
          >Select Minimum Weeks before Swapping Allowed</label
        >
        <ng-select
          [items]="weekSwap"
          formControlName="weekSwaping"
          class="custom"
          appendTo="body"
          [multiple]="false"
          [clearable]="true"
          [closeOnSelect]="true"
        >
        </ng-select>
      </div>
    </div>
    <div class="justify-content-SB mt-5">
      <div class="col-4">
        <div class="row">
          <label class="font-weight-bold">STAFF CANCEL</label>
        </div>
        <div class="row">
          <label>Plan Leave Allow CANCEL</label>
        </div>
        <div class="row">
          <div class="col-3">
            <input
              type="radio"
              id="active"
              [value]="true"
              formControlName="staffPlan"
              (change)="changeToggle(true)"
            />
            <label class="font-weight-bold">Yes</label>
          </div>
          <div class="col-3">
            <input
              type="radio"
              id="active"
              [value]="false"
              formControlName="staffPlan"
              (change)="changeToggle(false)"
            />
            <label class="font-weight-bold">No</label>
          </div>
        </div>
        <div class="row">
          <label>Actual Leave Allow CANCEL</label>
        </div>
        <div class="row">
          <div class="col-3">
            <input
              type="radio"
              id="active"
              [value]="true"
              formControlName="staffActual"
              (change)="changeToggle(true)"
            />
            <label class="font-weight-bold">Yes</label>
          </div>
          <div class="col-3">
            <input
              type="radio"
              id="active"
              [value]="false"
              formControlName="staffActual"
              (change)="changeToggle(false)"
            />
            <label class="font-weight-bold">No</label>
          </div>
        </div>
        <div class="row" *ngIf="show">
          <div class="col-8 p-0">
            <label>Cut-off Time (Weeks before leave start date)</label>
            <ng-select
              [items]="weekSwap"
              formControlName="staffCutOff"
              class="custom"
              appendTo="body"
              [multiple]="false"
              [clearable]="true"
              [closeOnSelect]="true"
            >
            </ng-select>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="row">
          <label class="font-weight-bold">ADMIN CANCEL</label>
        </div>
        <div class="row">
          <label>Plan Leave Allow CANCEL</label>
        </div>
        <div class="row">
          <div class="col-3">
            <input
              type="radio"
              id="active"
              [value]="true"
              formControlName="adminPlan"
            />
            <label class="font-weight-bold">Yes</label>
          </div>
          <div class="col-3">
            <input
              type="radio"
              id="active"
              [value]="false"
              formControlName="adminPlan"
            />
            <label class="font-weight-bold">No</label>
          </div>
        </div>
        <div class="row">
          <label>Actual Leave Allow CANCEL</label>
        </div>
        <div class="row">
          <div class="col-3">
            <input
              type="radio"
              id="active"
              [value]="true"
              formControlName="adminActual"
            />
            <label class="font-weight-bold">Yes</label>
          </div>
          <div class="col-3">
            <input
              type="radio"
              id="active"
              [value]="false"
              formControlName="adminActual"
            />
            <label class="font-weight-bold">No</label>
          </div>
        </div>
        <div class="row">
          <div class="col-8 p-0">
            <label>Cut-off Time (Weeks before leave start date)</label>
            <ng-select
              [items]="adminCutOff"
              formControlName="adminCutOff"
              class="custom"
              appendTo="body"
              [multiple]="false"
              [clearable]="true"
              [closeOnSelect]="true"
            >
            </ng-select>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="row">
          <label class="font-weight-bold">ADMIN CHANGE</label>
        </div>
        <div class="row">
          <label>Plan Leave Allow CHANGE</label>
        </div>
        <div class="row">
          <div class="col-3">
            <input
              type="radio"
              id="active"
              [value]="true"
              formControlName="adminPlanChange"
            />
            <label class="font-weight-bold">Yes</label>
          </div>
          <div class="col-3">
            <input
              type="radio"
              id="active"
              [value]="false"
              formControlName="adminPlanChange"
            />
            <label class="font-weight-bold">No</label>
          </div>
        </div>
        <div class="row">
          <label>Actual Leave Allow CHANGE</label>
        </div>
        <div class="row">
          <div class="col-3">
            <input
              type="radio"
              id="active"
              [value]="true"
              formControlName="adminActualChange"
            />
            <label class="font-weight-bold">Yes</label>
          </div>
          <div class="col-3">
            <input
              type="radio"
              id="active"
              [value]="false"
              formControlName="adminActualChange"
            />
            <label class="font-weight-bold">No</label>
          </div>
        </div>
        <p class="text-danger">
          * Please note - This Cut-off time setup will be applied for all 3
          functions (Allocation, Change and Cancel)
        </p>
      </div>
    </div>
  </div>
</div>
<div class="card mb-3">
  <div class="card-header flexi-header">
    <h3>SKILLSET SETUP</h3>
  </div>
  <div class="card-body" [formGroup]="setupSkillSetForm">
    <div class="justify-content-SB">
      <div class="col-6">
        <div class="row">
          <label class="font-weight-bold">Tier 1</label>
          <div class="col-3">
            <input
              type="radio"
              id="active"
              [value]="1"
              formControlName="tier"
               (change)="changeTierToggle()"
            />
          </div>
          <label class="font-weight-bold">Tier 2</label>
          <div class="col-3">
            <input
              type="radio"
              id="active"
              [value]="2"
              formControlName="tier"
               (change)="changeTierToggle()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-md-2 buttons">
  <button class="btn btn-clear p-2 m-1">Clear</button>
  <button class="btn btn-add p-2 m-1" (click)="handleUpdate()">Update</button>
</div>
