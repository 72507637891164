<div class="card">
  <div class="card-header flexi-header">
    <h3>CHALLENGE</h3>
  </div>
  <div class="card-body">
    <mat-horizontal-stepper [linear]="isLinear" #stepper>
      <mat-step [stepControl]="discriptioGroup">
        <form [formGroup]="discriptioGroup">
          <ng-template matStepLabel>DESCRIPTION </ng-template>
          <div class="card my-3 w-100">
            <div class="card-header flexi-header">
              <h3>DESCRIPTION</h3>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 mt-1">
                  <div class="form-group">
                    <label
                      class="pb-2 font-weight-bold"
                      for="exampleInputEmail1"
                      >Title</label
                    >

                    <input
                      type="text"
                      class="form-control"
                      placeholder="Title"
                      formControlName="title"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mt-1">
                  <div class="form-group">
                    <label
                      class="pb-2 font-weight-bold"
                      for="exampleInputEmail1"
                      >Description</label
                    >

                    <textarea
                      name=""
                      class="form-control"
                      id=""
                      cols="5"
                      rows="5"
                      formControlName="description"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <div class="custom-file mt-0 pt-4 pb-4">
                    <input
                      type="file"
                      #myInputVariable
                      class="custom-file-input"
                      disabled
                      id="c"
                    />
                    <label class="custom-file-label" for="i">
                      Choose file to upload
                    </label>
                  </div>
                </div>
                <div class="col-md-3" *ngIf="discriptioGroup.get('icon').value">
                  <div class="image-with-delete-icon width-180">
                    <img
                    crossorigin="anonymous"
                      [src]="validateURLImage(discriptioGroup.get('icon').value)"
                      alt="Image Preview"
                    />
                  </div>
                </div>
              </div>
              <div class="form-check m-3">
                <input
                  class="form-check-input"
                  type="checkbox"
                  formControlName="leaderBoard"
                  value=""
                  disabled
                  id="flexCheckChecked"
                />
                <label
                  class="form-check-label ml-2 checkbox-label"
                  for="flexCheckChecked"
                >
                  Leader board required
                </label>
              </div>
              <div>
                <label class="pb-2 font-weight-bold" for="challengeParticipant">Challenge participants selection</label>
                <div class="criteria-radio col-6">
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="individual" [value]="false"
                      formControlName="isTeam" [attr.disabled]="true" />
                    <label for="individual">Individual challenge</label><br />
                  </div>
                  <div>
                    <input type="radio" class="mt-1 ml-2" id="team" [value]="true" formControlName="isTeam" [attr.disabled]="true" />
                    <label for="team">Team challenge</label><br />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="card my-3 w-100">
                    <div class="card-header flexi-header">
                      <h3>PUBLISH PERIOD</h3>
                    </div>
                    <div class="card-body">
                      <div class="row mt-2">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label
                              for="activeEndDate"
                              class="pb-2 font-weight-bold"
                              >Start Date and Time
                            </label>
                            <app-date-time-picker
                              formControlName="publishStart"
                            ></app-date-time-picker>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label
                              for="activeEndDate"
                              class="pb-2 font-weight-bold"
                              >End Date and Time
                            </label>
                            <app-date-time-picker
                              formControlName="publishEnd"
                            ></app-date-time-picker>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card my-3 w-100">
                    <div class="card-header flexi-header">
                      <h3>CHALLENGE PERIOD</h3>
                    </div>
                    <div class="card-body">
                      <div class="row mt-2">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label
                              for="activeEndDate"
                              class="pb-2 font-weight-bold"
                              >Start Date and Time
                            </label>
                            <app-date-time-picker
                              formControlName="challengeStart"
                            ></app-date-time-picker>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label
                              for="activeEndDate"
                              class="pb-2 font-weight-bold"
                              >End Date and Time
                            </label>
                            <app-date-time-picker
                              formControlName="challengeEnd"
                            ></app-date-time-picker>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button [disabled]='dataLoaded' class="btn btn-success mt-2" (click)="stepper.next()">
                Next
              </button>
            </div>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="teamsGroup" *ngIf="discriptioGroup.get('isTeam').value === true">
        <form [formGroup]="teamsGroup">
          <ng-template matStepLabel>TEAMS</ng-template>
          <div class="card my-3 w-100">
            <div class="card-header flexi-header">
              <h3>TEAMS</h3>
            </div>
            <div class="card-body">
              <div class="card my-3 w-100" *ngIf="teamDetails.length !== 0">
                <div>
                  <div class="card-header flexi-header">
                    <h3>TEAM DETAILS </h3>
                  </div>
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th class="p-3">
                              TEAM NAME
                            </th>
                            <th class="p-3">
                              TEAM LOGO
                            </th>
                            <th class="p-3">
                              SELECTED USERS
                            </th>
                            <th class="p-3" scope="col">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of teamDetails; let index = index">
                            <td>
                              <div class="p-3">{{item.title}}</div>
                            </td>
                            <td>
                              <div class="p-3"><img crossorigin="anonymous" [src]="validateURLImage(item.icon)" style="height: 50px;
                                    width: 50px;
                                    border-radius: 50%;" /></div>
                            </td>
                            <td>
                              <div class="p-3"> {{item?.teamUserDetails?.length || "-"}} items Added</div>
                            </td>
                            <td>
                              <div class="p-3">
                                <button class="btn btn-add p-2 m-1" disabled
                                  (click)="editTeamDetail(item, index)">
                                  <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                </button>
                                <button class="btn btn-clear p-2 m-1" disabled
                                  (click)="deleteTeamEntry(index)">
                                  <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <button class="btn btn-warning mt-4" (click)="stepper.previous()">
              Prev
            </button>
            <button class="btn btn-danger ml-1 mt-4" (click)="stepper.next()">
              Next
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="rulesGroup">
        <form [formGroup]="rulesGroup">
          <ng-template matStepLabel>RULES</ng-template>
          <div class="card my-3 w-100">
            <div class="card-header flexi-header">
              <h3>RULES</h3>
            </div>
            <div class="card-body">
              <div class="ml-3">
                <p class="criteria-source">Criteria Source</p>
              </div>
              <div class="criteria-source-radio col-10">
                <div>
                  <input
                    type="radio"
                    formControlName="criteriaType"
                    class="mt-1 ml-2"
                    id="1"
                    disabled
                    name="criteriaType"
                    [value]="1"
                  />
                  <label for="channel">Channel</label>
                </div>
                <br />
                <div>
                  <input
                    type="radio"
                    formControlName="criteriaType"
                    class="mt-1 ml-2"
                    id="2"
                    name="criteriaType"
                    disabled
                    [value]="2"
                  />
                  <label for="boards">Boards</label>
                </div>
                <br />

                <div>
                  <input
                    type="radio"
                    formControlName="criteriaType"
                    class="mt-1 ml-2"
                    id="3"
                    name="criteriaType"
                    disabled
                    [value]="3"
                  />
                  <label for="system">System</label>
                </div>
                <br />

                <div>
                  <input
                    type="radio"
                    formControlName="criteriaType"
                    class="mt-1 ml-2"
                    id="4"
                    name="criteriaType"
                    disabled
                    [value]="4"
                  />
                  <label for="direct-rewards">Direct Rewards</label>
                </div>
                <br />

                <div>
                  <input
                    type="radio"
                    formControlName="criteriaType"
                    class="mt-1 ml-2"
                    id="5"
                    name="criteriaType"
                    disabled
                    [value]="5"
                  />
                  <label for="custom-form">Custom Form</label>
                </div>
                <br />

                <div>
                  <input
                    type="radio"
                    formControlName="criteriaType"
                    class="mt-1 ml-2"
                    id="6"
                    name="criteriaType"
                    disabled
                    [value]="6"
                  />
                  <label for="custom-form">Shift Form</label>
                </div>
                <br />
              </div>
              <hr />

              <div *ngIf="rulesGroup.get('criteriaType').value === 1">
                <div class="channels-section col-12 flex-direction mt-3">
                  <div class="col-md-4">
                    <div class="form-group">
                      <select
                        class="form-control"
                        id="exampleFormControlSelect1"
                        disabled
                      >
                        <option>Select Channel</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <ul *ngIf="rulesGroup.get('selectedChannelConst.name').value">
                      <li>
                        <span class="text-items list-ui selected-list"
                          >{{ rulesGroup.get("selectedChannelConst.name").value }}
                          <img
                            class="ml-3"
                            *ngIf="rulesGroup.get('selectedChannelConst.name').value"
                            src="./assets/images/cancel.png"
                            width="20px"
                            height="20px"
                        /></span>
                      </li>
                    </ul>
                  </div>

                  <!-- <button class="btn btn-danger btn-sm">Clear</button>
                  <button class="btn btn-success btn-sm ml-1">Next</button> -->
                </div>

                <p class="criteria-source mt-3">Criteria Selection</p>

                <div class="channels-section col-12 flex-direction">
                  <div class="col-md-4">
                    <div class="form-group">
                      <select
                        class="form-control"
                        id="exampleFormControlSelect1"
                        disabled
                      >
                        <option>Select Criteria</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <ul *ngIf="creatiaView">
                      <li>
                        <span class="text-items list-ui selected-list"
                          >{{ creatiaView }}
                          <img
                            class="ml-3"
                            *ngIf="creatiaView"
                            src="./assets/images/cancel.png"
                            width="20px"
                            height="20px"
                          />
                        </span>
                      </li>
                    </ul>
                  </div>
                  <!-- <button class="btn btn-danger btn-sm">Clear</button>
                  <button class="btn btn-success btn-sm ml-1">Next</button> -->
                </div>
                <p class="criteria-source mt-3">Criteria Counter</p>
                <div class="criteria-radio col-4">
                  <div>
                    <input
                      type="radio"
                      class="mt-1 ml-2"
                      id="single"
                      [value]="1"
                      disabled
                      formControlName="criteriaCountType"
                    />
                    <label for="channel">Single</label><br />

                    <div class="form-group ml-1">
                      <label class="criteria-subtext" for="criteria-count"
                        >Criteria Count</label
                      >
                      <input
                        type="number"
                        min="1"
                        [value]="1"
                        disabled
                        [attr.disabled]="
                          rulesGroup.get('criteriaCountType').value === 1
                            ? true
                            : null
                        "
                        class="form-control"
                        placeholder="Criteria Count"
                        formControlName="criteriaCount"
                      />
                    </div>
                  </div>
                  <div>
                    <input
                      type="radio"
                      class="mt-1 ml-2"
                      id="bundled"
                      disabled
                      [value]="2"
                      formControlName="criteriaCountType"
                    />
                    <label for="boards">Bundled</label><br />

                    <div class="form-group ml-1">
                      <label class="criteria-subtext" for="reward-points"
                        >Reward Points</label
                      >
                      <input
                        type="number"
                        min="0"
                        class="form-control"
                        disabled
                        placeholder="Reward Points"
                        formControlName="rewardPoints"
                      />
                    </div>
                  </div>
                </div>

                <p class="criteria-source mt-3">Non-reward Point System</p>
                <br />
                <p class="criteria-subtext mt-0">Do we need point system</p>
                <div class="criteria-radio col-3">
                  <div>
                    <input
                      type="radio"
                      class="mt-1 ml-2"
                      id="1"
                      disabled
                      [value]="true"
                      formControlName="nonRewardPointSystemEnabled"
                    />
                    <label for="yes">Yes</label><br />
                  </div>
                  <div>
                    <input
                      type="radio"
                      class="mt-1 ml-2"
                      id="2"
                      [value]="false"
                      disabled
                      formControlName="nonRewardPointSystemEnabled"
                    />
                    <label for="no">No</label><br />
                  </div>
                </div>

                <div
                  class="row"
                  *ngIf="rulesGroup.get('nonRewardPointSystemEnabled').value"
                >
                  <div class="col-md-4">
                    <select name="cars" id="cars" disabled></select>
                  </div>
                  <div class="col-md-4">
                    <ul *ngIf="pointSytemView">
                      <li>
                        <span class="text-items list-ui mt-3"
                          >{{ pointSytemView }}
                          <img
                            class="ml-3"
                            *ngIf="pointSytemView"
                            src="./assets/images/cancel.png"
                            width="20px"
                            height="20px"
                        /></span>
                      </li>
                    </ul>
                  </div>
                </div>
                <button
                  *ngIf="!rulesGroup.get('nonRewardPointSystemEnabled').value"
                  class="reward-points-button"
                >
                  Reward Points</button
                ><br />

                <p class="criteria-source mt-0">Reward Counter</p>
                <p class="criteria-subtext mt-0">
                  Will rewards count stop after 1st achievement
                </p>
                <div class="criteria-radio col-3">
                  <div>
                    <input
                      type="radio"
                      class="mt-1 ml-2"
                      id="1"
                      disabled
                      [value]="true"
                      formControlName="stopAfterAchievement"
                    />
                    <label for="yes">Once-off</label><br />
                  </div>
                  <div>
                    <input
                      type="radio"
                      class="mt-1 ml-2"
                      id="2"
                      [value]="false"
                      disabled
                      formControlName="stopAfterAchievement"
                    />
                    <label for="no">Recurring</label><br />
                  </div>
                </div>

                <p class="criteria-subtext mt-0">
                  Do we need to limit the maximum rewards per staff
                </p>
                <div class="criteria-radio col-3">
                  <div>
                    <input
                      type="radio"
                      class="mt-1 ml-2"
                      id="1"
                      [value]="true"
                      disabled
                      formControlName="setLimitToMaxRewards"
                    />
                    <label for="yes">Yes</label><br />
                  </div>
                  <div>
                    <input
                      type="radio"
                      class="mt-1 ml-2"
                      id="2"
                      [value]="false"
                      disabled
                      formControlName="setLimitToMaxRewards"
                    />
                    <label for="no">No</label><br />
                  </div>
                </div>
                <div
                  class="row"
                  *ngIf="rulesGroup.get('setLimitToMaxRewards').value"
                >
                  <div class="col-md-4">
                    <input
                      type="number"
                      min="0"
                      disabled
                      class="form-control"
                      placeholder="setLimitToMaxRewards"
                      formControlName="maximumRewards"
                      required
                    />
                  </div>
                </div>

                <p class="criteria-subtext mt-0">Badge Tiering required</p>
                <div class="criteria-radio col-3">
                  <div>
                    <input
                      type="radio"
                      class="mt-1 ml-2"
                      id="1"
                      disabled
                      [value]="true"
                      formControlName="badgeTiering"
                    />
                    <label for="yes">Yes</label><br />
                  </div>
                  <div>
                    <input
                      type="radio"
                      class="mt-1 ml-2"
                      id="2"
                      [value]="false"
                      disabled
                      formControlName="badgeTiering"
                    />
                    <label for="no">No</label><br />
                  </div>
                </div>
                <div class="row" *ngIf="rulesGroup.get('badgeTiering').value">
                  <div class="col-md-6">
                    <form [formGroup]="createRanks">
                      <table class="table borderless">
                        <tbody>
                          <tr>
                            <td>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Title"
                                formControlName="name"
                                disabled
                                required
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                min="0"
                                class="form-control"
                                disabled
                                placeholder="startRange"
                                formControlName="startRange"
                                required
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                min="0"
                                class="form-control"
                                disabled
                                placeholder="endRange"
                                formControlName="endRange"
                                required
                              />
                            </td>
                            <td>
                              <div
                                class="custom-file mt-0 pt-4 pb-4"
                                style="width: 67px"
                                *ngIf="!createRanks.get('icon').value"
                              >
                                <input
                                  type="file"
                                  disabled
                                  style="
                                    position: absolute;
                                    z-index: 9;
                                    visibility: visible;
                                    left: 0;
                                    top: 0;
                                    width: 80%;
                                    opacity: 0;
                                  "
                                  required
                                  id="b"
                                />
                                <label class="custom-file-label" for="b">
                                  Upload Image
                                </label>
                              </div>
                              <div *ngIf="createRanks.get('icon').value">
                                <div
                                  class="image-with-delete-icon"
                                  style="width: 100px"
                                >
                                  <i
                                    class="fa fa-close"
                                    title="Delete"
                                    for="4"
                                    (click)="onDeleteBadgeImage()"
                                  ></i>
                                  <img
                                    [src]="validateURLImage(createRanks.get('icon').value)"
                                    id="4"
                                    alt="Image Preview"
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                class="btn btn-submit btn-lg ml-1"
                                (click)="addRank()"
                              >
                                +
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                  </div>
                </div>
                <div class="row" *ngIf="rulesGroup.get('badgeTiering').value">
                  <div class="col-md-6" *ngIf="ranksControl.length != 0">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">TIER TITLE</th>
                            <th scope="col">FROM</th>

                            <th scope="col">TO</th>
                            <th scope="col">BADGE ICON</th>

                            <th scope="col">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            formArrayName="ranks"
                            *ngFor="
                              let field of ranksControl.controls;
                              let index = index
                            "
                          >
                            <ng-container>
                              <td>
                                {{ field.get("name").value }}
                              </td>

                              <td>{{ field.get("startRange").value }}</td>
                              <td>{{ field.get("endRange").value }}</td>

                              <td>
                                <img
                                  [src]="validateURLImage(field.get('icon').value)"
                                  width="50px"
                                  height="50px"
                                  alt="icon Preview"
                                />
                              </td>

                              <td></td>
                            </ng-container>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="rulesGroup.get('criteriaType').value === 2">
                <div class="channels-section col-12 flex-direction mt-3">
                  <div class="form-group">
                    <select
                      class="form-control"
                      id="exampleFormControlSelect1"
                      disabled
                    >
                      <option>Select Board</option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <ul *ngIf="rulesGroup.get('selectedWallConst.wallName').value">
                      <li>
                        <span class="text-items list-ui selected-list"
                          >{{ rulesGroup.get("selectedWallConst.wallName").value }}
                          <img
                            class="ml-3"
                            *ngIf="rulesGroup.get('selectedWallConst.wallName').value"
                            src="./assets/images/cancel.png"
                            width="20px"
                            height="20px"
                        /></span>
                      </li>
                    </ul>
                  </div>
                  <!-- <button class="btn btn-danger btn-sm">Clear</button>
                  <button class="btn btn-success btn-sm ml-1">Next</button> -->
                </div>

                <p class="criteria-source mt-3">Criteria Selection</p>

                <div class="channels-section col-12 flex-direction">
                  <div class="col-md-4">
                    <div class="form-group">
                      <select
                        class="form-control"
                        id="exampleFormControlSelect1"
                        disabled
                      >
                        <option>Select Criteria</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <ul *ngIf="boardcreatiaView">
                      <li>
                        <span class="text-items list-ui selected-list"
                          >{{ boardcreatiaView }}
                          <img
                            class="ml-3"
                            *ngIf="boardcreatiaView"
                            src="./assets/images/cancel.png"
                            width="20px"
                            height="20px"
                        /></span>
                      </li>
                    </ul>
                  </div>
                  <!-- <button class="btn btn-danger btn-sm">Clear</button>
                  <button class="btn btn-success btn-sm ml-1">Next</button> -->
                </div>

                <p class="criteria-source mt-3">Criteria Counter</p>

                <div class="criteria-radio col-4">
                  <div>
                    <input
                      type="radio"
                      class="mt-1 ml-2"
                      id="single"
                      [value]="1"
                      disabled
                      formControlName="criteriaCountType"
                    />
                    <label for="channel">Single</label><br />

                    <div class="form-group ml-1">
                      <label class="criteria-subtext" for="criteria-count"
                        >Criteria Count</label
                      >
                      <input
                        type="number"
                        min="1"
                        [value]="1"
                        disabled
                        [attr.disabled]="
                          rulesGroup.get('criteriaCountType').value === 1
                            ? true
                            : null
                        "
                        class="form-control"
                        placeholder="Criteria Count"
                        formControlName="criteriaCount"
                      />
                    </div>
                  </div>
                  <div>
                    <input
                      type="radio"
                      class="mt-1 ml-2"
                      id="bundled"
                      [value]="2"
                      disabled
                      formControlName="criteriaCountType"
                    />
                    <label for="boards">Bundled</label><br />

                    <div class="form-group ml-1">
                      <label class="criteria-subtext" for="reward-points"
                        >Reward Points</label
                      >
                      <input
                        type="number"
                        min="0"
                        class="form-control"
                        disabled
                        placeholder="Reward Points"
                        formControlName="rewardPoints"
                      />
                    </div>
                  </div>
                </div>

                <p class="criteria-source mt-3">Non-reward Point System</p>
                <br />
                <p class="criteria-subtext mt-0">Do we need point system</p>
                <div class="criteria-radio col-3">
                  <div>
                    <input
                      type="radio"
                      class="mt-1 ml-2"
                      id="1"
                      [value]="true"
                      disabled
                      formControlName="nonRewardPointSystemEnabled"
                    />
                    <label for="yes">Yes</label><br />
                  </div>
                  <div>
                    <input
                      type="radio"
                      class="mt-1 ml-2"
                      id="2"
                      [value]="false"
                      disabled
                      formControlName="nonRewardPointSystemEnabled"
                    />
                    <label for="no">No</label><br />
                  </div>
                </div>

                <div
                  class="row"
                  *ngIf="rulesGroup.get('nonRewardPointSystemEnabled').value"
                >
                  <div class="col-md-4">
                    <select name="cars" id="cars" disabled></select>
                  </div>
                  <div class="col-md-4">
                    <ul *ngIf="pointSytemView">
                      <li>
                        <span class="text-items list-ui mt-3"
                          >{{ pointSytemView }}
                          <img
                            class="ml-3"
                            *ngIf="pointSytemView"
                            src="./assets/images/cancel.png"
                            width="20px"
                            height="20px"
                        /></span>
                      </li>
                    </ul>
                  </div>
                </div>
                <button
                  *ngIf="!rulesGroup.get('nonRewardPointSystemEnabled').value"
                  class="reward-points-button"
                >
                  Reward Points</button
                ><br />

                <p class="criteria-source mt-0">Reward Counter</p>
                <p class="criteria-subtext mt-0">
                  Will rewards count stop after 1st achievement
                </p>
                <div class="criteria-radio col-3">
                  <div>
                    <input
                      type="radio"
                      class="mt-1 ml-2"
                      id="1"
                      [value]="true"
                      disabled
                      formControlName="stopAfterAchievement"
                    />
                    <label for="yes">Once-off</label><br />
                  </div>
                  <div>
                    <input
                      type="radio"
                      class="mt-1 ml-2"
                      id="2"
                      [value]="false"
                      disabled
                      formControlName="stopAfterAchievement"
                    />
                    <label for="no">Recurring</label><br />
                  </div>
                </div>

                <p class="criteria-subtext mt-0">
                  Do we need to limit the maximum rewards per staff
                </p>
                <div class="criteria-radio col-3">
                  <div>
                    <input
                      type="radio"
                      class="mt-1 ml-2"
                      id="1"
                      [value]="true"
                      disabled
                      formControlName="setLimitToMaxRewards"
                    />
                    <label for="yes">Yes</label><br />
                  </div>
                  <div>
                    <input
                      type="radio"
                      class="mt-1 ml-2"
                      id="2"
                      [value]="false"
                      disabled
                      formControlName="setLimitToMaxRewards"
                    />
                    <label for="no">No</label><br />
                  </div>
                </div>
                <div
                  class="row"
                  *ngIf="rulesGroup.get('setLimitToMaxRewards').value"
                >
                  <div class="col-md-4">
                    <input
                      type="number"
                      min="0"
                      class="form-control"
                      disabled
                      placeholder="setLimitToMaxRewards"
                      formControlName="maximumRewards"
                      required
                    />
                  </div>
                </div>

                <p class="criteria-subtext mt-0">Badge Tiering required</p>
                <div class="criteria-radio col-3">
                  <div>
                    <input
                      type="radio"
                      class="mt-1 ml-2"
                      id="1"
                      [value]="true"
                      disabled
                      formControlName="badgeTiering"
                    />
                    <label for="yes">Yes</label><br />
                  </div>
                  <div>
                    <input
                      type="radio"
                      class="mt-1 ml-2"
                      id="2"
                      [value]="false"
                      disabled
                      formControlName="badgeTiering"
                    />
                    <label for="no">No</label><br />
                  </div>
                </div>
                <div class="row" *ngIf="rulesGroup.get('badgeTiering').value">
                  <div class="col-md-6">
                    <form [formGroup]="createRanks">
                      <table class="table borderless">
                        <tbody>
                          <tr>
                            <td>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Title"
                                disabled
                                formControlName="name"
                                required
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                min="0"
                                class="form-control"
                                placeholder="startRange"
                                disabled
                                formControlName="startRange"
                                required
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                min="0"
                                class="form-control"
                                placeholder="endRange"
                                disabled
                                formControlName="endRange"
                                required
                              />
                            </td>
                            <td>
                              <div
                                class="custom-file mt-0 pt-4 pb-4"
                                style="width: 67px"
                                *ngIf="!createRanks.get('icon').value"
                              >
                                <input
                                  type="file"
                                  disabled
                                  style="
                                    position: absolute;
                                    z-index: 9;
                                    visibility: visible;
                                    left: 0;
                                    top: 0;
                                    width: 80%;
                                    opacity: 0;
                                  "
                                  required
                                  id="b"
                                />
                                <label class="custom-file-label" for="b">
                                  Upload Image
                                </label>
                              </div>
                              <div *ngIf="createRanks.get('icon').value">
                                <div
                                  class="image-with-delete-icon"
                                  style="width: 100px"
                                >
                                  <i
                                    class="fa fa-close"
                                    title="Delete"
                                    for="4"
                                    (click)="onDeleteBadgeImage()"
                                  ></i>
                                  <img
                                    [src]="(createRanks.get('icon').value)"
                                    id="4"
                                    alt="Image Preview"
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                class="btn btn-submit btn-lg ml-1"
                                (click)="addRank()"
                              >
                                +
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                  </div>
                </div>
                <div class="row" *ngIf="rulesGroup.get('badgeTiering').value">
                  <div class="col-md-6" *ngIf="ranksControl.length != 0">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">TIER TITLE</th>
                            <th scope="col">FROM</th>

                            <th scope="col">TO</th>
                            <th scope="col">BADGE ICON</th>

                            <th scope="col">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            formArrayName="ranks"
                            *ngFor="
                              let field of ranksControl.controls;
                              let index = index
                            "
                          >
                            <ng-container>
                              <td>
                                {{ field.get("name").value }}
                              </td>

                              <td>{{ field.get("startRange").value }}</td>
                              <td>{{ field.get("endRange").value }}</td>

                              <td>
                                <img
                                  [src]="validateURLImage(field.get('icon').value)"
                                  width="50px"
                                  height="50px"
                                  alt="icon Preview"
                                />
                              </td>

                              <td></td>
                            </ng-container>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="rulesGroup.get('criteriaType').value === 3">
                <div class="ml-3">
                  <p class="criteria-source">Criteria Selection</p>
                </div>
                <div class="channels-section col-12 flex-direction mt-3 ml-4">
                  <div class="form-group">
                    <select
                      class="form-control"
                      id="exampleFormControlSelect1"
                      disabled
                    >
                      <option>Select Criteria</option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <ul *ngIf="systemCretiaView">
                      <li>
                        <span class="text-items list-ui selected-list"
                          >{{ systemCretiaView }}
                          <img
                            class="ml-3"
                            *ngIf="systemCretiaView"
                            src="./assets/images/cancel.png"
                            width="20px"
                            height="20px"
                        /></span>
                      </li>
                    </ul>
                  </div>
                  <!-- <button class="btn btn-danger btn-sm">Clear</button>
                  <button class="btn btn-success btn-sm ml-1">Next</button> -->
                </div>
                <div class="row ml-0">
                  <div class="col-3 mt-2">
                    <label class="criteria-subtext" for="reward-points"
                      >Reward Points</label
                    >
                    <input
                      type="text"
                      formControlName="rewardPoints"
                      class="form-control"
                      disabled
                      required
                    />
                  </div>
                  <div class="col-4" style="margin-top: 35px">
                    <ul *ngIf="rewardPoints">
                      <li>
                        <span class="text-items list-ui selected-list"
                          >{{ rewardPoints }}
                          <img
                            class="ml-3"
                            *ngIf="rewardPoints"
                            src="./assets/images/cancel.png"
                            width="20px"
                            height="20px"
                        /></span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div *ngIf="rulesGroup.get('criteriaType').value === 4">
                <div class="card my-3 w-100" *ngIf="userDetails.length != 0">
                  <div class="card-header flexi-header">
                    <h3>SELECTED INFORMATION</h3>
                  </div>

                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>BUSINESS UNIT</th>
                            <th>APPOINTMENTS</th>
                            <th>SELECTED USERS</th>

                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="let item of userDetails; let index = index"
                          >
                            <td *ngIf='!item.allBuToken'>
                              <ul *ngFor="let item of item.businessUnits">
                                <li>{{ item?.sectionId.departmentId.companyId.name + ' > ' +
                                  item?.sectionId.departmentId.name +
                                  ' > ' +
                                  item?.sectionId.name +
                                  '> ' +
                                  item?.name }}</li>
                              </ul>

                            </td>
                            <td *ngIf = 'item.allBuToken'> <ul>
                              <li>All Business Units Selected</li>
                            </ul></td>
                            <td>
                              <ul *ngFor="let item of item.appointments">
                                <li>{{ item.name }}</li>
                              </ul>
                            </td>
                            <td>
                              <ul *ngFor="let item of item.user">
                                <li>{{ item.name }}</li>
                              </ul>
                            </td>

                            <td>
                              <button
                                class="btn btn-add p-2 m-1"
                                [disabled]="true"
                                (click)="editUserDetail(item, index)"
                              >
                                <i
                                  class="fa fa-pencil-square-o"
                                  aria-hidden="true"
                                ></i>
                              </button>
                              <button
                                class="btn btn-clear p-2 m-1"
                                [disabled]="true"
                                (click)="deleteUserDetail(index)"
                              >
                                <i class="fa fa-trash" aria-hidden="true"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <button
                      class="btn btn-clear"
                      *ngIf="userDetails.length != 0"
                      (click)="onClearAllFrom()"
                    >
                      Clear All
                    </button>
                  </div>
                  <!-- <div class="card-footer">
                    <div class="d-flex pull-right">
                      <button class="btn btn-clear" (click)="onClickSteps()">
                        Back
                      </button>
                      <button
                        class="btn btn-add"
                        [disabled]="userDetails.length === 0"
                        (click)="onClickSteps(3)"
                      >
                        Proceed to next step
                      </button>
                    </div>
                  </div> -->
                </div>
              </div>

              <div *ngIf="rulesGroup.get('criteriaType').value === 5">
                <div class="channels-section col-12 flex-direction mt-3">
                  <div class="form-group">
                    <select
                      class="form-control"
                      id="exampleFormControlSelect1"
                      disabled
                    >
                      <option>Select Custom Form</option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <ul *ngIf="rulesGroup.get('selectedCustomFormConst.title').value">
                      <li>
                        <span class="text-items list-ui selected-list"
                          >{{
                            rulesGroup.get("selectedCustomFormConst.title").value
                          }}
                          <img
                            class="ml-3"
                            *ngIf="
                              rulesGroup.get('selectedCustomFormConst.title').value
                            "
                            src="./assets/images/cancel.png"
                            width="20px"
                            height="20px"
                        /></span>
                      </li>
                    </ul>
                  </div>
                  <!-- <button class="btn btn-danger btn-sm">Clear</button>
                  <button class="btn btn-success btn-sm ml-1">Next</button> -->
                </div>

                <p class="criteria-source mt-3">Criteria Selection</p>

                <div class="channels-section col-12 flex-direction">
                  <div class="col-md-4">
                    <div class="form-group">
                      <select
                        class="form-control"
                        id="exampleFormControlSelect1"
                        disabled
                      >
                        <option>Select Criteria</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <ul *ngIf="customFormcreatiaView">
                      <li>
                        <span class="text-items list-ui selected-list"
                          >{{ customFormcreatiaView }}
                          <img
                            class="ml-3"
                            *ngIf="customFormcreatiaView"
                            src="./assets/images/cancel.png"
                            width="20px"
                            height="20px"
                        /></span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div>
                  <div class="flex-direction">
                    <p class="criteria-source mt-3">Criteria Categories</p>
                    <label class="switch criteria-source mt-3 ml-4">
                      <input type="checkbox"
                        [checked]="rulesGroup.get('isCriteriaCategory').value === true ? 'checked' : ''"
                        formControlName="isCriteriaCategory" disabled />
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <div class="channels-section col-12 flex-direction"
                    *ngIf="rulesGroup.get('isCriteriaCategory').value === true">
                    <div class="col-md-4">
                      <ng-select class="custom" bindLabel="name" (change)="addItemsInSelector($event, 'Criteria_Category')"
                        placeholder="Select Criteria Category" appendTo="body" [multiple]="false" [clearable]="true" aria-disabled="true"
                        [(ngModel)]="criteriaCategorySelect" [ngModelOptions]="{ standalone: true }">
                        <ng-option [value]="item" *ngFor="let item of criteriaCategory">
                          {{ item.key }}
                        </ng-option>
                      </ng-select>
                    </div>
                    <div class="col-md-4">
                      <ul *ngIf="criteriaCategoryView">
                        <li>
                          <span class="text-items list-ui selected-list">{{ criteriaCategoryView }}
                            <img class="ml-3" (click)="criteraCategoryView = null" *ngIf="criteriaCategoryView"
                              src="./assets/images/cancel.png" width="20px" height="20px" aria-disabled="true"
                              crossorigin="anonymous" /></span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div *ngIf="rulesGroup.get('criteriaSourceType').value === 7">
                <p class="criteria-source mt-3">Nominate question</p>
                <div>
                  <span
                    class="text-items list-ui mt-2">
                   {{nomineeQuestion}}
                  </span>

                </div>
              </div>

                <p class="criteria-source mt-3">Select workflow status</p>
                <div>
                  <div
                  *ngFor="
                    let field of workFlowStatusInfo;
                    let index = index
                  "
                >
                  <span
                    class="text-items list-ui mt-2"
                  >
                   {{field}}
                  </span>
                </div>
                </div>
                <p class="criteria-source mt-3">Criteria Counter</p>

                <div class="criteria-radio col-4">
                  <div>
                    <input
                      type="radio"
                      class="mt-1 ml-2"
                      id="single"
                      [value]="1"
                      disabled
                      formControlName="criteriaCountType"
                    />
                    <label for="channel">Single</label><br />

                    <div class="form-group ml-1">
                      <label class="criteria-subtext" for="criteria-count"
                        >Criteria Count</label
                      >
                      <input
                        type="number"
                        min="1"
                        [value]="1"
                        disabled
                        [attr.disabled]="
                          rulesGroup.get('criteriaCountType').value === 1
                            ? true
                            : null
                        "
                        class="form-control"
                        placeholder="Criteria Count"
                        formControlName="criteriaCount"
                      />
                    </div>
                  </div>
                  <div>
                    <input
                      type="radio"
                      class="mt-1 ml-2"
                      id="bundled"
                      [value]="2"
                      disabled
                      formControlName="criteriaCountType"
                    />
                    <label for="boards">Bundled</label><br />
                  </div>
                </div>
                <div>
                  <div class="flex-direction">
                    <p class="criteria-source mt-3">Challenge Reward Type</p>
                    <label class="switch criteria-source mt-3 ml-4">
                      <input type="checkbox"
                        [checked]="rulesGroup.get('isRewardChallenge').value === true ? 'checked' : ''"
                        formControlName="isRewardChallenge" disabled />
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <div *ngIf="rulesGroup.get('isRewardChallenge').value === true">
                    <div class="criteria-radio col-8">
                      <div>
                        <input type="radio" class="mt-1 ml-2" id="2" [value]="false"
                          formControlName="nonRewardPointSystemEnabled" disabled />
                        <label for="no">Reward Point System</label><br />
                      </div>
                      <div>
                        <input type="radio" class="mt-1 ml-2" id="1" [value]="true"
                          formControlName="nonRewardPointSystemEnabled" disabled />
                        <label for="yes">Non-Reward Point System</label><br />
                      </div>
                    </div>
                    <div class="row pt-4 pl-4 pb-2" *ngIf="rulesGroup.get('nonRewardPointSystemEnabled').value">
                      <div class="col-md-3">
                        <ng-select (change)="addPointSystem($event)" class="custom" bindLabel="name" placeholder=""
                          appendTo="body" [multiple]="false" [(ngModel)]="criteriaCategorySelect" aria-disabled="true" [clearable]="true">
                          <ng-option [value]="item" *ngFor="let item of poinstSystemData">
                            {{ item.title }}
                          </ng-option>
                        </ng-select>
                      </div>
                      <div class="col-md-4">
                        <ul *ngIf="pointSytemView">
                          <li>
                            <span class="text-items list-ui">{{ pointSytemView }}
                              <img class="ml-3" (click)="pointSytemView = null" *ngIf="pointSytemView"
                                src="./assets/images/cancel.png" width="20px" height="20px" aria-disabled="true"
                                crossorigin="anonymous" /></span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="form-group col-md-3">
                      <label class="criteria-subtext" for="reward-points">Reward Points</label>
                      <input type="number" min="0" class="form-control" placeholder="Reward Points"
                        onKeyPress="if(this.value.length==10) return false;" formControlName="rewardPoints" disabled />
                    </div>
                  </div>
                </div>
                <br />
                <p class="criteria-source mt-0">Points Counter</p>
                <p class="criteria-subtext mt-0">
                  Will rewards count stop after 1st achievement
                </p>
                <div class="criteria-radio col-3">
                  <div>
                    <input
                      type="radio"
                      class="mt-1 ml-2"
                      id="1"
                      [value]="true"
                      disabled
                      formControlName="stopAfterAchievement"
                    />
                    <label for="yes">Once-off</label><br />
                  </div>
                  <div>
                    <input
                      type="radio"
                      class="mt-1 ml-2"
                      id="2"
                      [value]="false"
                      disabled
                      formControlName="stopAfterAchievement"
                    />
                    <label for="no">Recurring</label><br />
                  </div>
                </div>

                <p class="criteria-subtext mt-0">
                  Do we need to limit the maximum rewards per staff
                </p>
                <div class="criteria-radio col-3">
                  <div>
                    <input
                      type="radio"
                      class="mt-1 ml-2"
                      id="1"
                      [value]="true"
                      disabled
                      formControlName="setLimitToMaxRewards"
                    />
                    <label for="yes">Yes</label><br />
                  </div>
                  <div>
                    <input
                      type="radio"
                      class="mt-1 ml-2"
                      id="2"
                      [value]="false"
                      disabled
                      formControlName="setLimitToMaxRewards"
                    />
                    <label for="no">No</label><br />
                  </div>
                </div>
                <div
                  class="row"
                  *ngIf="rulesGroup.get('setLimitToMaxRewards').value"
                >
                  <div class="col-md-4">
                    <input
                      type="number"
                      min="0"
                      class="form-control"
                      disabled
                      placeholder="setLimitToMaxRewards"
                      formControlName="maximumRewards"
                      required
                    />
                  </div>
                </div>

                <p class="criteria-subtext mt-0">Badge Tiering required</p>
                <div class="criteria-radio col-3">
                  <div>
                    <input
                      type="radio"
                      class="mt-1 ml-2"
                      id="1"
                      [value]="true"
                      disabled
                      formControlName="badgeTiering"
                    />
                    <label for="yes">Yes</label><br />
                  </div>
                  <div>
                    <input
                      type="radio"
                      class="mt-1 ml-2"
                      id="2"
                      [value]="false"
                      disabled
                      formControlName="badgeTiering"
                    />
                    <label for="no">No</label><br />
                  </div>
                </div>
                <div class="row" *ngIf="rulesGroup.get('badgeTiering').value">
                  <div class="col-md-6">
                    <form [formGroup]="createRanks">
                      <table class="table borderless">
                        <tbody>
                          <tr>
                            <td>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Title"
                                disabled
                                formControlName="name"
                                required
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                min="0"
                                class="form-control"
                                placeholder="startRange"
                                disabled
                                formControlName="startRange"
                                required
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                min="0"
                                class="form-control"
                                placeholder="endRange"
                                disabled
                                formControlName="endRange"
                                required
                              />
                            </td>
                            <td>
                              <div
                                class="custom-file mt-0 pt-4 pb-4"
                                style="width: 67px"
                                *ngIf="!createRanks.get('icon').value"
                              >
                                <input
                                  type="file"
                                  disabled
                                  style="
                                    position: absolute;
                                    z-index: 9;
                                    visibility: visible;
                                    left: 0;
                                    top: 0;
                                    width: 80%;
                                    opacity: 0;
                                  "
                                  required
                                  id="b"
                                />
                                <label class="custom-file-label" for="b">
                                  Upload Image
                                </label>
                              </div>
                              <div *ngIf="createRanks.get('icon').value">
                                <div
                                  class="image-with-delete-icon"
                                  style="width: 100px"
                                >
                                  <i
                                    class="fa fa-close"
                                    title="Delete"
                                    for="4"
                                    (click)="onDeleteBadgeImage()"
                                  ></i>
                                  <img
                                    [src]="validateURLImage(createRanks.get('icon').value)"
                                    id="4"
                                    alt="Image Preview"
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                class="btn btn-submit btn-lg ml-1"
                                (click)="addRank()"
                              >
                                +
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                  </div>
                </div>
                <div class="row" *ngIf="rulesGroup.get('badgeTiering').value">
                  <div class="col-md-6" *ngIf="ranksControl.length != 0">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">TIER TITLE</th>
                            <th scope="col">FROM</th>

                            <th scope="col">TO</th>
                            <th scope="col">BADGE ICON</th>

                            <th scope="col">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            formArrayName="ranks"
                            *ngFor="
                              let field of ranksControl.controls;
                              let index = index
                            "
                          >
                            <ng-container>
                              <td>
                                {{ field.get("name").value }}
                              </td>

                              <td>{{ field.get("startRange").value }}</td>
                              <td>{{ field.get("endRange").value }}</td>

                              <td>
                                <img
                                  [src]="validateURLImage(field.get('icon').value)"
                                  width="50px"
                                  height="50px"
                                  alt="icon Preview"
                                />
                              </td>

                              <td></td>
                            </ng-container>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="flex-direction">
                    <p class="criteria-source mt-3">Progress Status Setup</p>
                    <label class="switch criteria-source mt-3 ml-4">
                      <input type="checkbox"
                        [checked]="rulesGroup.get('isProgressSetup').value === true ? 'checked' : ''"
                        formControlName="isProgressSetup" disabled />
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <div *ngIf="rulesGroup.get('isProgressSetup').value === true">
                    <div class="my-3">
                      <p class="criteria-subtext mt-0">Progress Type</p>
                      <div class="channels-section col-12 flex-direction">
                        <div class="col-md-4">
                          <ng-select class="custom" (change)="addItemsInSelector($event, 'Progress_Type')"
                            bindLabel="name" placeholder="Select Progress Type" appendTo="body" [multiple]="false"
                            [clearable]="true" [(ngModel)]="criteriaCategorySelect" [ngModelOptions]="{ standalone: true }" aria-disabled="true">
                            <ng-option [value]="item" *ngFor="let item of progressTypes">
                              {{ item.key }}
                            </ng-option>
                          </ng-select>
                        </div>
                        <div class="col-md-4">
                          <ul *ngIf="progressTypesView">
                            <li>
                              <span class="text-items list-ui selected-list">{{ progressTypesView }}
                                <img class="ml-3" *ngIf="progressTypesView"
                                  src="./assets/images/cancel.png" width="20px" height="20px"
                                  crossorigin="anonymous" aria-disabled="true" /></span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="my-3">
                      <p class="criteria-subtext mt-0">Display Selection</p>
                      <div class="channels-section col-12 flex-direction">
                        <div class="col-md-4">
                          <ng-select class="custom" (change)="addItemsInSelector($event, 'Display_Selection')"
                            bindLabel="name" placeholder="Select Display Selection" appendTo="body" [multiple]="false"
                            [clearable]="true" [(ngModel)]="criteriaCategorySelect" [ngModelOptions]="{ standalone: true }" aria-disabled="true">
                            <ng-option [value]="item" *ngFor="let item of displaySelectionData">
                              {{ item.key }}
                            </ng-option>
                          </ng-select>
                        </div>
                        <div class="col-md-4">
                          <ul *ngIf="displaySelectionView">
                            <li>
                              <span class="text-items list-ui selected-list">{{ displaySelectionView }}
                                <img class="ml-3"  *ngIf="displaySelectionView" aria-disabled="true"
                                  src="./assets/images/cancel.png" width="20px" height="20px"
                                  crossorigin="anonymous" /></span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="my-3">
                      <p class="criteria-subtext mt-0">Display Description</p>
                      <div class="channels-section col-12 flex-direction">
                        <div class="col-md-4">
                          <ng-select class="custom" (change)="addItemsInSelector($event, 'Display_Description')"
                            bindLabel="name" placeholder="Select Display Description" appendTo="body" [multiple]="false"
                            [clearable]="true" [(ngModel)]="criteriaCategorySelect" aria-disabled="true" [ngModelOptions]="{ standalone: true }">
                            <ng-option [value]="item" *ngFor="let item of displayDescriptions">
                              {{ item.key }}
                            </ng-option>
                          </ng-select>
                        </div>
                        <div class="col-md-4">
                          <ul *ngIf="displayDescriptionsView">
                            <li>
                              <span class="text-items list-ui selected-list">{{ displayDescriptionsView }}
                                <img class="ml-3" *ngIf="displayDescriptionsView"
                                  src="./assets/images/cancel.png" width="20px" height="20px" aria-disabled="true" 
                                  crossorigin="anonymous" /></span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="my-3">
                      <p class="criteria-subtext mt-0">Display Fields</p>
                      <div class="channels-section col-12 flex-direction">
                        <div class="col-md-4">
                          <ng-select class="custom" (change)="addItemsInSelector($event, 'Display_Fields')"
                            bindLabel="name" placeholder="Select Display Fields" appendTo="body" [multiple]="false"
                            [clearable]="true" [(ngModel)]="criteriaCategorySelect" [ngModelOptions]="{ standalone: true }" aria-disabled="true">
                            <ng-option [value]="item" *ngFor="let item of displayFieldsData">
                              {{ item.key }}
                            </ng-option>
                          </ng-select>
                        </div>
                        <div class="col-md-4">
                          <ul *ngIf="criteriaCategorySelectView">
                            <li>
                              <span class="text-items list-ui selected-list">{{ criteriaCategorySelectView }}
                                <img class="ml-3"  *ngIf="customFormcreatiaView"
                                  src="./assets/images/cancel.png" width="20px" height="20px" aria-disabled="true"
                                  crossorigin="anonymous" /></span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="flex-direction">
                        <p class="criteria-source mt-3">Target Criteria Display</p>
                        <label class="switch criteria-source mt-3 ml-4">
                          <input type="checkbox"
                            [checked]="rulesGroup.get('isTargetCriteria').value === true ? 'checked' : ''"
                            formControlName="isTargetCriteria" disabled />
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <div *ngIf="rulesGroup.get('isTargetCriteria').value === true">
                        <div class="my-3">
                          <p class="criteria-subtext mt-0">Number of criteria count​</p>
                          <div class="channels-section col-12 flex-direction">
                            <div class="col-md-4">
                              <input type="number" min="0" class="form-control" placeholder="Criteria Count"
                                onKeyPress="if(this.value.length==10) return false;" formControlName="targetCount" disabled />
                            </div>
                          </div>
                        </div>
                        <div class="my-3">
                          <p class="criteria-subtext mt-0">Target description​</p>
                          <div class="channels-section col-12 flex-direction">
                            <div class="col-md-4">
                              <input type="text" class="form-control" placeholder="Description" onKeyPress=""
                                formControlName="targetCountDescription"  disabled/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <ng-container *ngIf="rulesGroup.get('criteriaType').value === 6">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="text-theme font-weight-500">
                        Selected Scheme
                      </label>
                      <br />
                      <ng-select
                        [items]="schemeDataArr"
                        class="custom"
                        bindLabel="schemeName"
                        [virtualScroll]="false"
                        appendTo="body"
                        [clearable]="true"
                        [multiple]="true"
                        [disabled]="true"
                        [closeOnSelect]="false"
                        [searchable]="true"
                        [(ngModel)]="schemeData"
                        [ngModelOptions]="{ standalone: true }"
                      >
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="d-flex" *ngIf="schemeData != null">
                      <div
                        class="labels mb-2 mr-2"
                        *ngFor="let item of schemeData; let i = index"
                      >
                        <span class="text">{{ item?.schemeName }} </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="text-theme font-weight-500">
                        Criteria Selection
                      </label>
                      <br />
                      <ng-select
                        [items]="allCriteria"
                        class="custom"
                        bindLabel="key"
                        appendTo="body"
                        [(ngModel)]="shiftcriteriaSelect"
                        [ngModelOptions]="{ standalone: true }"
                      >
                      </ng-select>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="" class="text-theme font-weight-bold"
                        >Criteria Counter</label
                      ><br />
                      <div class="form-check form-check-inline">
                        <input
                          type="radio"
                          class="form-check-input"
                          id="single"
                          [value]="1"
                          formControlName="criteriaCountType"
                        />
                        <label for="channel" class="form-check-label"
                          >Single</label
                        >
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          type="radio"
                          class="form-check-input"
                          id="bundled"
                          [value]="2"
                          formControlName="criteriaCountType"
                        />
                        <label for="boards" class="form-check-label"
                          >Bundled</label
                        ><br />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="" for="criteria-count"
                        >Criteria Count</label
                      >
                      <input
                        type="number"
                        min="1"
                        [value]="1"
                        [attr.disabled]="
                          rulesGroup.get('criteriaCountType').value === 1
                            ? true
                            : null
                        "
                        class="form-control"
                        placeholder="Criteria Count"
                        formControlName="criteriaCount"
                        pattern="/^-?\d+\.?\d*$/"
                        onKeyPress="if(this.value.length == 10) return false;"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Reward Pointss</label>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Reward Points"
                        name="rewardPoints"
                        formControlName="rewardPoints"
                      />
                    </div>
                  </div>
                </div>

                <p class="criteria-source mt-3">Non-reward Point System</p>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="">Do we need point system</label><br />
                      <div class="form-check form-check-inline">
                        <input
                          type="radio"
                          class="form-check-input"
                          id="1"
                          [value]="true"
                          formControlName="nonRewardPointSystemEnabled"
                        />
                        <label for="yes" class="form-check-label">Yes</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          type="radio"
                          class="form-check-input"
                          id="2"
                          [value]="false"
                          formControlName="nonRewardPointSystemEnabled"
                        />
                        <label for="no" class="form-check-label">No</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="row"
                  *ngIf="rulesGroup.get('nonRewardPointSystemEnabled').value"
                >
                  <div class="col-md-4">
                    <select name="cars" id="cars" disabled></select>
                  </div>
                  <div class="col-md-4">
                    <ul *ngIf="pointSytemView">
                      <li>
                        <span class="text-items list-ui mt-3"
                          >{{ pointSytemView }}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <button
                  *ngIf="!rulesGroup.get('nonRewardPointSystemEnabled').value"
                  class="btn btn-danger"
                >
                  Reward Points</button
                ><br />

                <p class="criteria-source mt-3">Reward Counter</p>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for=""
                        >Will rewards count stop after 1st achievement</label
                      ><br />
                      <div class="form-check form-check-inline">
                        <input
                          type="radio"
                          class="form-check-input"
                          id="1"
                          [value]="true"
                          formControlName="stopAfterAchievement"
                        />
                        <label for="yes" class="form-check-label"
                          >Once-off</label
                        >
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          type="radio"
                          class="form-check-input"
                          id="2"
                          [value]="false"
                          formControlName="stopAfterAchievement"
                        />
                        <label for="no" class="form-check-label"
                          >Recurring</label
                        ><br />
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <label for=""
                        >Do we need to limit the maximum rewards per
                        staff</label
                      ><br />
                      <div class="form-check form-check-inline">
                        <input
                          type="radio"
                          class="form-check-input"
                          id="1"
                          [value]="true"
                          formControlName="setLimitToMaxRewards"
                        />
                        <label for="yes" class="form-check-label">Yes</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          type="radio"
                          class="form-check-input"
                          id="2"
                          [value]="false"
                          formControlName="setLimitToMaxRewards"
                        />
                        <label for="no" class="form-check-label">No</label>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-4"
                    *ngIf="rulesGroup.get('setLimitToMaxRewards').value"
                  >
                    <input
                      type="number"
                      pattern="/^-?\d+\.?\d*$/"
                      onKeyPress="if(this.value.length==10) return false;"
                      min="0"
                      class="form-control"
                      placeholder="setLimitToMaxRewards"
                      formControlName="maximumRewards"
                      required
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="">Badge Tiering required</label><br />
                      <div class="form-check form-check-inline">
                        <input
                          type="radio"
                          class="form-check-input"
                          id="1"
                          [value]="true"
                          formControlName="badgeTiering"
                        />
                        <label for="yes" class="form-check-label">Yes</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          type="radio"
                          class="form-check-input"
                          id="2"
                          [value]="false"
                          formControlName="badgeTiering"
                        />
                        <label for="no" class="form-check-label">No</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row" *ngIf="rulesGroup.get('badgeTiering').value">
                  <div class="col-md-6">
                    <form [formGroup]="createRanks">
                      <table class="table borderless">
                        <tbody>
                          <tr>
                            <td>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Badge"
                                formControlName="name"
                                required
                                pattern="/^-?\d+\.?\d*$/"
                                onKeyPress="if(this.value.length==50) return false;"
                              />
                            </td>
                            <div class="ml-3">
                              <input
                                type="number"
                                min="0"
                                class="form-control"
                                placeholder="From"
                                formControlName="startRange"
                                required
                                pattern="/^-?\d+\.?\d*$/"
                                onKeyPress="if(this.value.length==10) return false;"
                              />
                            </div>
                            <td class="columns">
                              <input
                                type="number"
                                min="0"
                                class="form-control columns"
                                placeholder="To"
                                formControlName="endRange"
                                required
                                pattern="/^-?\d+\.?\d*$/"
                                onKeyPress="if(this.value.length==10) return false;"
                              />
                            </td>
                            <td>
                              <div
                                class="custom-file"
                                style="
                                  width: 130px;
                                  height: 30px;
                                  margin-left: 10px;
                                "
                                *ngIf="!createRanks.get('icon').value"
                              >
                                <input
                                  type="file"
                                  disabled
                                  style="
                                    position: absolute;
                                    z-index: 9;
                                    visibility: visible;
                                    left: 0;
                                    top: 0;
                                    width: 80%;
                                    opacity: 0;
                                  "
                                  required
                                  id="b"
                                />
                                <label class="custom-file-label" for="b">
                                  Upload Image
                                </label>
                              </div>
                              <div *ngIf="createRanks.get('icon').value">
                                <div
                                  class="
                                    image-with-delete-icon
                                    ml-5 ml-0
                                    badge-image
                                  "
                                  style="width: 100px"
                                >
                                  <i
                                    class="fa fa-close"
                                    title="Delete"
                                    for="4"
                                    (click)="onDeleteBadgeImage()"
                                  ></i>
                                  <img
                                    [src]="validateURLImage(createRanks.get('icon').value)"
                                    id="4"
                                    alt="Image Preview"
                                  />
                                </div>
                              </div>
                            </td>
                            <div class="add-button columns">
                              <button
                                class="btn btn-submit btn-lg ml-1 pl-3 pr-3"
                                (click)="addRank()"
                              >
                                +
                              </button>
                            </div>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                  </div>
                </div>

                <div class="row">
                  <div class="col-8" *ngIf="badgeTieringArray.length != 0">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">TIER TITLE</th>
                            <th scope="col">FROM</th>

                            <th scope="col">TO</th>
                            <th scope="col">BADGE ICON</th>

                            <th scope="col">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let field of badgeTieringArray;
                              let index = index
                            "
                          >
                            <td>
                              <div class="mt-2">{{ field.name }}</div>
                            </td>

                            <td>
                              <div class="mt-2">{{ field.startRange }}</div>
                            </td>
                            <td>
                              <div class="mt-2">{{ field.endRange }}</div>
                            </td>

                            <td>
                              <img
                                [src]="validateURLImage(field.icon)"
                                width="50px"
                                height="50px"
                                class="mt-2"
                                alt="icon Preview"
                              />
                            </td>

                            <td>
                              <img
                                class="ml-3 mt-2"
                                (click)="deleteBadge(index)"
                                src="./assets/images/cancel.png"
                                width="20px"
                                height="20px"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </ng-container>

              <div>
                <button
                  class="btn btn-warning mt-4"
                  (click)="stepper.previous()"
                >
                  Prev
                </button>
                <button
                  class="btn btn-danger ml-1 mt-4"
                  (click)="stepper.next()"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="adminGroup">
        <form [formGroup]="adminGroup">
          <ng-template matStepLabel>ADMINSTRATOR</ng-template>
          <div class="card my-3 w-100">
            <div class="card-header flexi-header">
              <h3>ADMINSTRATOR</h3>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <label class="font-weight-bold ml-3">Admin </label>
                  <div class="flex-direction col-12">
                    <div class="w-100">
                      <select
                        class="form-control"
                        name="cars"
                        id="cars"
                        disabled
                      >
                        <option></option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 p-4">
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      flex-wrap: wrap;
                      margin-top: 2px;
                    "
                  >
                    <div
                      formArrayName="administrators"
                      *ngFor="
                        let field of AdminFieldsControl.controls;
                        let index = index
                      "
                    >
                      <span
                        class="text-items list-ui mt-2"
                        [formGroupName]="index"
                        >{{ field.get("string").value }}
                        <img
                          class="ml-3"
                          (click)="deleteAdmin(index)"
                          src="./assets/images/cancel.png"
                          width="20px"
                          height="20px"
                      /></span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex-direction col-1">
                <div>
                  <button
                    class="btn btn-danger ml-1"
                    (click)="stepper.previous()"
                  >
                    Back
                  </button>
                </div>
                <div>
                  <!-- <button
                    class="btn btn-success ml-2"
                    (click)="
                      onSubmitEvents(this.rulesGroup.get('criteriaType').value)
                    "
                  >
                    Submit
                  </button> -->
                </div>
                <!-- <div>
                  <button class="btn btn-save ml-2">
                    Save as Draft
                  </button>
                </div> -->
              </div>
            </div>
          </div>
          <div></div>
        </form>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div>
