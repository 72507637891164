import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { COMMON_MESSAGES } from 'src/app/core/utils/helper';
import { CompanySetupService } from 'src/app/users/service/company-setup/company-setup.service';
import { CustomFieldService } from 'src/app/users/service/custom-field/custom-field.service';
import { GamificationService } from 'src/app/users/service/gamification/gamification.service';
import { environment } from 'src/environments/environment';
import { URL_REGEX_PATTERN } from 'src/app/core/utils/helper';

@Component({
  selector: 'quick-links',
  templateUrl: './quick-links.component.html',
  styleUrls: ['./quick-links.component.scss']
})
export class QuickLinksComponent implements OnInit {
  userFullLinksForm: FormGroup;
  userLinksubmitted:boolean = false
  useFullLinkOrders = [];
  isIconLoading: boolean;
  IconUrl: string;
  usefullinkEdit: boolean;
  usefullinkIndex: number;
  useFullLinksArray = []
  isUpdateLoading: boolean;
  companySettingsId: string;
  baseUrl = environment.imageUrl;
  constructor(
    private companyService: CompanySetupService,
    private gamificationService: GamificationService,
    private formBuilder: FormBuilder,
    private tostService: ToastrService,

    private customFieldService: CustomFieldService
  ) { }

  ngOnInit() {
    this.initiUsefullLinkForm()
    this.getCompanySettings()

  }
  initiUsefullLinkForm() {
    return (this.userFullLinksForm = this.formBuilder.group({
      indexNum: [null, [Validators.required]],
      icon: ['', [Validators.required]],
      link: ['', [Validators.required, Validators.pattern(URL_REGEX_PATTERN)]],
      title: ['', [Validators.required]],
    }));
  }

  get userLinkControl() {
    return this.userFullLinksForm.controls;
  }
  async addUserLinks(userLink: any) {
    if(!this.userFullLinksForm.valid){
      this.userLinksubmitted = true
      this.tostService.error('Please fill the quick links form')
      return
    }
    const index = this.useFullLinksArray.findIndex(
      (x) => x.title === userLink.title
    );

    if (index > -1) {
      this.userFullLinksForm.reset();
      return;
      // errorAlert(`${value} is already added`);
    } else {
      this.useFullLinksArray.push(userLink);
      this.useFullLinkOrders.push(this.useFullLinksArray.length + 1)
      const data = this.useFullLinksArray.sort((a, b) => a.indexNum - b.indexNum);

      this.useFullLinksArray = data;
      this.tostService.success('User Link added')
      this.userFullLinksForm.reset();
      this.userLinksubmitted = false
    }
  }
  onClearUserLink(){
    this.userFullLinksForm.reset();
    this.userLinksubmitted = false
  }


  iconUpload(event) {
    this.isIconLoading = true;
    if (event.target.files && event.target.files.length) {
      let file: File = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        const fileUpload = this.gamificationService.uploadFile(file)
          .pipe(
            finalize(() => {
              fileUpload.unsubscribe();
            })
          )
          .subscribe(
            (data: any) => {
              this.IconUrl = `${data.data.link}`;
              this.userFullLinksForm.patchValue({
                icon: `${data.data.link}`,
              });
              this.isIconLoading = false;
              this.tostService.success('Icon Uploaded Successfully')
            },
            (error: HttpErrorResponse) => {
              this.isIconLoading = false;
              console.info(error)
              this.tostService.error('The image is not uploaded, please upload again');
            }
          );
      } else {
        this.tostService.error(COMMON_MESSAGES.UPLOAD_ONLY_IMAGE);
        event.target.value = '';
        this.isIconLoading = false;
        file = null;
      }
    }
  }

  onDeleteIcon() {
    this.IconUrl = null;
    this.userFullLinksForm.patchValue({
      icon: '',
    });
  }
  editUsefullLinks(data: any, index: number) {
    this.useFullLinkOrders.pop()

    this.usefullinkEdit = true;
    this.usefullinkIndex = index;
    this.IconUrl = data.icon;
    this.userFullLinksForm.patchValue({
      indexNum: data.indexNum,
      icon: data.icon,
      link: data.link,
      title: data.title,
    });
  }

  updateUserLinks() {
    if (!this.userFullLinksForm.valid) {
      this.userLinksubmitted = true;
      this.tostService.error('Please fill the quick links form');
      return;
    }
  this.useFullLinkOrders.push(this.useFullLinksArray.length + 1)
    this.tostService.success('User Link updated')
    this.usefullinkEdit = false;
    this.IconUrl = null;
    this.useFullLinksArray[this.usefullinkIndex] = this.userFullLinksForm.value;
    const data = this.useFullLinksArray.sort((a, b) => a.indexNum - b.indexNum);

    this.useFullLinksArray = data;
    this.userFullLinksForm.reset();
  }

  deteleUseFullLinnk(index) {
    this.tostService.success('User link deleted')
    this.useFullLinksArray.splice(index, 1);
  }

  getCompanySettings() {
    this.companyService.getcompanySetting().subscribe((res) => {
      this.companySettingsId = res.data._id;
      const data = res.data.externalLinks.sort((a, b) => a.indexNum - b.indexNum);

      this.useFullLinksArray = data;
      this.useFullLinksArray.forEach((e, i) => this.useFullLinkOrders.push(i +1))
      this.useFullLinkOrders.push(this.useFullLinksArray.length + 1)
      // this.useFullLinkOrders.push(res.data.externalLinks.length + 1)

    },
    (error:HttpErrorResponse) => {

    });
  }
  async onSubmitSetupForm() {
    this.isUpdateLoading = true;
      const data = {
        externalLinks: this.useFullLinksArray,
        pageId:this.companySettingsId
      };
      const sub = this.companyService
      .updateCompanySettings(data)
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe(
        (res) => {
          this.useFullLinkOrders= []
          this.tostService.success('Updated SuccessFully');
          this.isUpdateLoading = false
          this.ngOnInit();
        },
        (error) => {
          this.isUpdateLoading = false
        }
      );

  }

  validateURLImage(str) {
    var img_Path = str;
    if (img_Path.indexOf("https://") == 0) {
      var new_url = new URL(str);
     var pathName = (new_url.pathname.startsWith('//')) ? `/${new_url.pathname.split("//")[1]}` : new_url.pathname;
      return `${this.baseUrl}${pathName}`;
    }
    if (str.startsWith('/')) return this.baseUrl + str;
    return `${this.baseUrl}/${str}`;
   }
}
