<div class="card mb-3">
  <div class="card-header flexi-header header">
    <h3>SELECT SHIFT SCHEME TYPE</h3>
  </div>
  <div class="card-body">
    <div class="paddingDiv">
      <form [formGroup]="ShiftSchemeType">
        <div class="row">
          <div class="lableMandatory">
            <label class="red-heading">Choose Shift Scheme Type</label><span class="field-mandatory mandatory_Star heading">*</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-md-6 schemeType">
            <div class="radioDiv">
              <input
                type="radio"
                formControlName="schemeType"
                name="schemeType"
                value="flexi"
                [disabled]="isDisable"
                id="flexi"
                (click)="changeFlexiAndAssgnd(true, false)"
              />
              <label class="radioLabel" for="flexi">Flexi</label>
            </div>
            <div class="radioDiv">
              <input
                type="radio"
                formControlName="schemeType"
                name="schemeType"
                value="assigned"
                [disabled]="isDisable"
                id="assigned"
                (click)="changeFlexiAndAssgnd(false, true)"
              />
              <label class="radioLabel" for="assigned">Assigned</label>
            </div>
            <div class="radioDiv">
              <input
                type="radio"
                formControlName="schemeType"
                name="schemeType"
                value="both"
                [disabled]="isDisable"
                id="assnflex"
                (click)="changeFlexiAndAssgnd(true, true)"
              />
              <label class="radioLabel" for="assnflex">Assigned + Flexi</label>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="card mb-3">
  <div class="card-header flexi-header header">
    <h3>SELECT SHIFT SCHEME TYPE</h3>
  </div>
  <div class="card-body">
    <div class="paddingDiv">
      <form [formGroup]="schemeInfo">
        <div class="row mb-2">
          <label class="red-heading">Shift Scheme Information</label>
        </div>
        <div class="row mb-2">
          <div class="col-md-9 schemeType">
            <div class="radioDiv info">
              <div class="lableMandatory">
                <label>Shift Scheme Name</label><span class="field-mandatory mandatory_Star">*</span>
              </div>
              <input
                [disabled]="isDisable"
                class="form-control"
                formControlName="schemeName"
                type="text"
              />
            </div>
            <div class="radioDiv info col-md-6">
              <div class="lableMandatory">
                <label>Description</label><span class="field-mandatory mandatory_Star">*</span>
              </div>
              <textarea
                [disabled]="isDisable"
                class="form-control"
                formControlName="schemeDesc"
                cols="5"
              ></textarea>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="card mb-3">
  <div class="card-body">
    <div class="">
      <div class="row mb-2 paddingDiv">
        <button
          class="btn"
          (click)="onChangeLimit(false)"
          [ngClass]="limit ? 'btn-primary' : 'btn-danger'"
        >
          Shift Setup
        </button>
        <button
          class="btn"
          style="margin-left: 1rem"
          [ngClass]="limit ? 'btn-danger' : 'btn-primary'"
          (click)="onChangeLimit(true)"
        >
          Limit
        </button>
      </div>
      <div class="row mb-2">
        <div class="col-md-12" *ngIf="!limit && flex">
          <form [formGroup]="schemeShiftflexi">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th class="col1Header">OPEN SHIFT</th>
                  <th class="col2Header">ENABLED</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Basic Hours Type</td>
                  <td>
                    <div class="radioDiv">
                      <input
                        [disabled]="isDisable"
                        type="radio"
                        name="basicHours"
                        value="true"
                        formControlName="basicHours"
                        id="opennormalHrType"
                      />
                      <label class="radioLabel" for="opennormalHrType"
                        >Normal</label
                      >
                    </div>
                    <div class="radioDiv">
                      <input
                        [disabled]="!isEdit"
                        type="radio"
                        name="basicHours"
                        value="false"
                        formControlName="basicHours"
                        id="openotHrType"
                      />
                      <label class="radioLabel" for="openotHrType">OT</label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Allow Shift Extension</td>
                  <td class="radioDiv">
                    <div class="col-md-3 no-padding">
                      <div class="radioDiv">
                        <input
                          [disabled]="!isEdit"
                          type="radio"
                          name="allowShiftExt"
                          (change)="changeToggle(true)"
                          value="true"
                          formControlName="allowShiftExt"
                          id="openyesExt"
                        />
                        <!-- (change)="showOpenAllowShift()" -->
                        <label
                          class="radioLabel"
                          class="radioLabel"
                          for="openyesExt"
                          >Yes</label
                        >
                      </div>
                      <div class="radioDiv">
                        <input
                          [disabled]="!isEdit"
                          type="radio"
                          name="allowShiftExt"
                          (change)="changeToggle(false)"
                          value="false"
                          formControlName="allowShiftExt"
                          id="opennoExt"
                        />
                        <!-- (change)="showOpenAllowShift()" -->
                        <label class="radioLabel" for="opennoExt">No</label>
                      </div>
                    </div>

                    <div class="col-md-7 no-padding radioDiv space-around">
                      <div *ngIf="show">
                        <label>Shift Extension Hours Type</label>
                        <div class="radioDiv">
                          <input
                            [disabled]="!isEdit"
                            type="radio"
                            name="shiftExtHr"
                            value="true"
                            formControlName="shiftExtHr"
                            id="opennormalExt"
                          />
                          <label class="radioLabel" for="opennormalExt"
                            >Normal</label
                          >
                        </div>
                        <div class="radioDiv">
                          <input
                            [disabled]="!isEdit"
                            type="radio"
                            name="shiftExtHr"
                            value="false"
                            formControlName="shiftExtHr"
                            id="openotExt"
                          />
                          <label class="radioLabel" for="openotExt">OT</label>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>

        <div class="col-md-12" *ngIf="!limit && assgned">
          <form [formGroup]="schemeShiftAssign">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th class="col1Header">ASSIGN SHIFT</th>
                  <th class="col2Header">ENABLED</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Basic Hours Type</td>
                  <td>
                    <div class="radioDiv">
                      <input
                        [disabled]="!isEdit"
                        type="radio"
                        name="assignBasic"
                        value="true"
                        formControlName="assignBasic"
                        id="normalHrType"
                      />
                      <label class="radioLabel" for="normalHrType"
                        >Normal</label
                      >
                    </div>
                    <div class="radioDiv">
                      <input
                        [disabled]="!isEdit"
                        type="radio"
                        name="assignBasic"
                        value="false"
                        formControlName="assignBasic"
                        id="otHrType"
                      />
                      <label class="radioLabel" for="otHrType">OT</label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Allow Shift Extension</td>
                  <td class="radioDiv">
                    <div class="col-md-3 no-padding">
                      <div class="radioDiv">
                        <input
                          [disabled]="!isEdit"
                          type="radio"
                          name="assignAllowShiftExt"
                          (change)="changeToggleAssign(true)"
                          value="true"
                          formControlName="assignAllowShiftExt"
                          id="yesExt"
                        />
                        <label
                          class="radioLabel"
                          class="radioLabel"
                          for="yesExt"
                          >Yes</label
                        >
                      </div>
                      <div class="radioDiv">
                        <input
                          [disabled]="!isEdit"
                          type="radio"
                          name="assignAllowShiftExt"
                          (change)="changeToggleAssign(false)"
                          value="false"
                          formControlName="assignAllowShiftExt"
                          id="noExt"
                        />
                        <label class="radioLabel" for="noExt">No</label>
                      </div>
                    </div>

                    <div class="col-md-7 no-padding radioDiv space-around">
                      <div *ngIf="showAssign">
                        <label>Shift Extension Hours Type</label>
                        <div class="radioDiv">
                          <input
                            [disabled]="!isEdit"
                            type="radio"
                            name="assignShiftExt"
                            value="true"
                            formControlName="assignShiftExt"
                            id="normalExt"
                          />
                          <label class="radioLabel" for="normalExt"
                            >Normal</label
                          >
                        </div>
                        <div class="radioDiv">
                          <input
                            [disabled]="!isEdit"
                            type="radio"
                            name="assignShiftExt"
                            value="false"
                            formControlName="assignShiftExt"
                            id="otExt"
                          />
                          <label class="radioLabel" for="otExt">OT</label>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Allow Shift Recall</td>
                  <td class="radioDiv">
                    <div class="col-md-3 no-padding">
                      <div class="radioDiv">
                        <input
                          [disabled]="!isEdit"
                          type="radio"
                          name="assignShiftRecall"
                          (change)="changeToggleRecall(true)"
                          value="true"
                          formControlName="assignShiftRecall"
                          id="yesRecall"
                        />
                        <label for="yesRecall" class="radioLabel">Yes</label>
                      </div>
                      <div class="radioDiv">
                        <input
                          [disabled]="!isEdit"
                          type="radio"
                          name="assignShiftRecall"
                          (change)="changeToggleRecall(false)"
                          value="false"
                          formControlName="assignShiftRecall"
                          id="noRecall"
                        />
                        <label for="noRecall" class="radioLabel">No</label>
                      </div>
                    </div>
                    <div class="col-md-7 no-padding radioDiv space-around">
                      <div *ngIf="showRecall">
                        <label>Shift Recall Hours Type</label>

                        <div class="radioDiv">
                          <input
                            [disabled]="!isEdit"
                            type="radio"
                            name="assignShiftRecallHour"
                            value="true"
                            formControlName="assignShiftRecallHour"
                            id="normalRecall"
                          />
                          <label class="radioLabel" for="normalRecall"
                            >Normal</label
                          >
                        </div>
                        <div class="radioDiv">
                          <input
                            [disabled]="!isEdit"
                            type="radio"
                            name="assignShiftRecallHour"
                            value="false"
                            formControlName="assignShiftRecallHour"
                            id="otRecall"
                          />
                          <label class="radioLabel" for="otRecall">OT</label>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>
      <div>
        <form [formGroup]="minimumInterVal">
      <div class="row" *ngIf="!limit">
        <div class="col-md-4">
          <div class="form-group">
            <div class="lableMandatory">
              <label style="margin-top: 5px;">Select Weeks for Shift View</label><span class="field-mandatory mandatory_Star">*</span>
            </div>
           <ng-select
              [items]="weeksShiftsView"
              class="custom"
              [disabled]="isDisable"
              [virtualScroll]="false"
              placeholder="Select Number of Weeks"
              appendTo="body"
              [multiple]="false"
              [searchable]="true"
              [clearable]="true"
              [(ngModel)]="selectedWeekForShiftView"
              [ngModelOptions]="{ standalone: true }"
            >
            </ng-select>
          </div>
        </div>
      </div>
        <div class="row" *ngIf="!limit">
          <div class="col-md-4">
            <div class="lableMandatory">
              <label style="margin-top: 5px;">Require Minimum Interval between Shifts?</label>
              <span class="field-mandatory mandatory_Star">*</span>
            </div>
            <div class="radioDiv">
              <input
                [disabled]="isDisable"
                type="radio"
                name="isShiftInterval"
                formControlName="isShiftInterval"
                (change)="changeSelectedHours(true)"
                [value]="true"
                id="yesExt"
              />
              <label
                class="radioLabel"
                class="radioLabel"
                for="yesExt"
                >Yes</label
              >
            </div>
            <div class="radioDiv">
              <input
                [disabled]="isDisable"
                type="radio"
                name="isShiftInterval"
                formControlName="isShiftInterval"
                (change)="changeSelectedHours(false)"
                [value]="false"
                id="noExt"
              />
              <label class="radioLabel" for="noExt">No</label>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="showHours && !limit">
          <div class="col-md-4">
            <div class="form-group">
              <label>Select Hours </label>
              <ng-select
                [items]="selectedHours"
                class="custom"
                [disabled]="isDisable"
                [virtualScroll]="false"
                placeholder="Hours"
                appendTo="body"
                [multiple]="false"
                [searchable]="true"
                [clearable]="true"
                [(ngModel)]="selectedWeekForShiftViewHours"
                [ngModelOptions]="{ standalone: true }"
              >
              </ng-select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Select Minutes </label>
              <ng-select
                [items]="selectedMinutes"
                [disabled]="isDisable"
                class="custom"
                [virtualScroll]="false"
                placeholder="Minutes"
                appendTo="body"
                [multiple]="false"
                [searchable]="true"
                [clearable]="true"
                [(ngModel)]="selectedWeekForShiftViewMinutes"
                [ngModelOptions]="{ standalone: true }"
              >
              </ng-select>
            </div>
          </div>
        </div>
        </form>
      </div>
      <div class="row mb-2">
        <div class="col-md-12" *ngIf="limit">
          <form [formGroup]="limitTimeTable">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th class="col1Header">&nbsp;</th>
                  <th>NORMAL</th>
                  <th>OT</th>
                  <th>OVERALL</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>DAY</td>
                  <td>
                    <input
                      [disabled]="!isEdit"
                      class="form-control inputText"
                      type="text"
                      formControlName="dayNormal"
                    />
                  </td>
                  <td>
                    <input
                      [disabled]="!isEdit"
                      class="form-control inputText"
                      type="text"
                      formControlName="dayOt"
                    />
                  </td>
                  <td>
                    <input
                      [disabled]="!isEdit"
                      class="form-control inputText"
                      type="text"
                      formControlName="dayOverall"
                    />
                  </td>
                </tr>
                <tr>
                  <td>WEEK</td>
                  <td>
                    <input
                      [disabled]="!isEdit"
                      class="form-control inputText"
                      type="text"
                      formControlName="weekNormal"
                    />
                  </td>
                  <td>
                    <input
                      [disabled]="!isEdit"
                      class="form-control inputText"
                      type="text"
                      formControlName="weekOt"
                    />
                  </td>
                  <td>
                    <input
                      [disabled]="!isEdit"
                      class="form-control inputText"
                      type="text"
                      formControlName="weekOverall"
                    />
                  </td>
                </tr>
                <tr>
                  <td>MONTH</td>
                  <td>
                    <input
                      [disabled]="!isEdit"
                      class="form-control inputText"
                      type="text"
                      formControlName="monthNormal"
                    />
                  </td>
                  <td>
                    <input
                      [disabled]="!isEdit"
                      class="form-control inputText"
                      type="text"
                      formControlName="monthOt"
                    />
                  </td>
                  <td>
                    <input
                      [disabled]="!isEdit"
                      class="form-control inputText"
                      type="text"
                      formControlName="monthOverall"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>

        <div class="col-md-12" *ngIf="limit">
          <form [formGroup]="actionLimits">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>ACTION ABOVE LIMIT</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="radioDiv">
                    <input
                      [disabled]="!isEdit"
                      type="radio"
                      name="alertDisallow"
                      value="true"
                      formControlName="alertDisallow"
                      id="alert"
                    />
                    <label class="radioLabel pl-4" for="alert">Alert</label>
                  </td>
                </tr>
                <tr>
                  <td class="radioDiv">
                    <input
                      [disabled]="!isEdit"
                      type="radio"
                      name="alertDisallow"
                      value="false"
                      formControlName="alertDisallow"
                      id="disallow"
                    />
                    <label class="radioLabel pl-4" for="disallow"
                      >Disallow</label
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="buttons">
  <button
    class="btn btn-add p-2 m-1"
    (click)="onSubmit()"
    [disabled]="isDisable"
    *ngIf="isView != 'view' && isView != 'edit'"
  >
    Save scheme
  </button>
  <button
    class="btn btn-add p-2 m-1"
    (click)="onSubmit()"
    *ngIf="isView == 'edit'"
  >
    Update scheme
  </button>
</div>
