import {
  Component,
  OnInit,
  Input,
  TemplateRef,
  ViewChild,
  ElementRef,
  NgZone,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { SetupService } from 'src/app/users/service/operations/setup.service';
import { finalize } from 'rxjs/operators';
import { CompanySetupService } from 'src/app/users/service/company-setup/company-setup.service';
import { ShiftSchedulerService } from 'src/app/users/service/operations/shift-scheduler.service';

@Component({
  selector: 'app-bu-shift-setup',
  templateUrl: './bu-shift-setup.component.html',
  styleUrls: ['./bu-shift-setup.component.scss'],
})
export class BuShiftSetupComponent implements OnInit {
  @Input() isEditBu: boolean;

  activeId: number = 1;
  buShiftSetUpForm: FormGroup;
  addSkillForm: FormGroup;
  addSpecialityForm: FormGroup;
  addLocationsForm: FormGroup;
  buOption: any;
  buSkillSet: any;
  // buSkillSet:any = {
  //   sortBy: 'desc',
  //   sortWith: 'createdBy',
  //   data: [],
  //   page: 1,
  //   limit: 10,
  //   search: '',
  //   total:0

  // };
  buRepoLocation: any;
  buSkillspeciality: any=[];
  noOfweeks = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  shiftDuration = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  cutOffNumberDays = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,13,14];
  minBreak = [15, 30, 45, 60];
  shiftDurationSelect: any;
  minBreakSelect: any;
  cutOffDaysSelect:any;
  timeLimit = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24,
  ];
  mainSkillLabel: any;
  subSkillLabel: any;
  skillsSet = [];
  skillLabels = [];
  locationlabel = [];
  locationslabels = [];
  buRepoLocationArr = [];
  show = false;
  showshift: boolean = false;
  public buttonName: any = 'Show';
  public shiftButtonName: any = 'Show';
  shiftDurationLabel: any;
  minBreakLabel: any;
  preFillBu: any;
  editData: any;
  isVisible: boolean;
  saveBtn: string;
  heading: string;
  breakTime: [];
  isBreakTime: any;
  shiftBreak = [];
  shiftDurationHr: any;
  shiftBreakArr = [];
  userSkillSetArray = [];
  userSkillSetLbl: any;
  schemeTable: any;
  selectedBuOption: any;
  schemeLabel = [];
  schemeLabels = [];
  schemeArr = [];
  singleItem: any;
  singleSpecialityItem: any;
  editSkillObj: any;
  delDataObj: any;
  updateSkillData: any;
  editSpecialityObj: any;
  delSpecialityDataObj: any;
  updateBtn: boolean = false;
  updateBtnSpe: boolean = false;
  buSetupSaveLoader: boolean;
  Id: any;
  cutOffRdion:any;
  cutOffDays:boolean
  tierTypeData: any;
  showSpeciality: boolean;
  isDropdown = false;
  isMap = false;
  map = false;
  text = false;
  both = false;
  updateLocationFlag=false;
  geoReportingLocations = [];
  reportingLocationRadius = 0;
  buSettingObj = {
    isCheckInEnabled: null,
    isProximityEnabled: null,
    reportingLocationRadius: null,
  };
  buRepoLocationTable:any={
    page: 1,
    limit: 10,
    total: 0,
    start: 0,
  }

  constructor(
    protected formBuilder: FormBuilder,
    private buService: SetupService,
    public modelService: NgbModal,
    public toastService: ToastrService,
    private companySetupService: CompanySetupService,
    private shiftSchedularService: ShiftSchedulerService,
  ) {}
  ngOnInit(): void {
    // this.loaddepartment(this.departmentPTable);
    this.initForm();

    //   this.getSection();
    //  this.getSubsection();
    this.buShiftSetUpForm.reset();
  }

  initForm() {
    this.buShiftSetUpForm = this.formBuilder.group({
      businessUnitId: [null, [Validators.required]],
      mainSkillSets: [null, [Validators.required]],
      subSkillSets: [null, [Validators.required]],
      skillSetTierType: 2,
      reportingLocation: [null, [Validators.required]],
      // location: [null, [Validators.required]],
      scheme: [null, [Validators.required]],
      noOfWeek: [null, [Validators.required]],
      plannedHours: [null, [Validators.required]],
      shiftTimeInMinutes: [null, [Validators.required]],
      shiftBreak: [null, [Validators.required]],
      isBreakTime: [false, [Validators.required]],
      standByShiftPermission: [null, [Validators.required]],
      shiftCancelHours: [null, [Validators.required]],
      cancelShiftPermission: [null, [Validators.required]],
      cutOffDaysForBookingAndCancelling: [null, [Validators.required]],
      geoReportingLocation: [null],
      reportingLocationType: [null, [Validators.required]],
      isCheckInEnabled: [null],
      isProximityEnabled: [null],
      reportingLocationRadius: [null],
    });

    this.heading = 'BU SHIFT WORK SETUP';
    this.saveBtn = 'Save Shift Setup Details';

    this.displaySkilltable();
    this.displayLocationTable();
    this.tierTypeHandle();

    this.buService.getBusinessUnitBUShift().subscribe((res) => {
      this.buOption = res['data']['planBussinessUnitId'];
    });
  }
  //getPageSettingSkill
  tierTypeHandle() {
    const sub = this.shiftSchedularService
      .getPageSettingSkill()
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe(
        (res) => {
         // this.Id = res.data._id;
          this.tierTypeData = res;
          if (this.tierTypeData.tierType === 1) {
            this.showSpeciality = false;
            // this.skillLabels.push([
            //   this.buShiftSetUpForm.get('mainSkillSets').value.name,
            // ]);
          } else if (this.tierTypeData.tierType === 2) {
            this.showSpeciality = true;
          }
          // this.buService
          //   .getTierType(this.Id)
          //   .subscribe((res: { data: any }) => {
          //   });
        },
        (error) => {}
      );
  }

  SchemeList(event) {
    /*this.buService.getScheme(event._id, '').subscribe((res) => {
      this.schemeTable = res['data'];
    });*/
  }

  displaySkilltable() {
    this.buService.getSkillSet().subscribe((res) => {
      this.buSkillSet = res['data']['skillsets'] || [];
      // console.log(this.buSkillSet.data);

      // this.buSkillSet.total = res['data']['skillsets']?.length || 0;
    });
  }

  displayLocationTable() {
    this.buService.getReportingLocation().subscribe((res) => {
      this.buRepoLocation = res['data']['reportingLocations'] || [];
      this.buRepoLocation?.map((locationObj:any,index:number)=>{
        locationObj['index']=index + 1;
      });
      this.buRepoLocationTable.total= this.buRepoLocation?.length;
    });
  }

  onPageChangeUsers(page){
    this.buRepoLocationTable.page = page;
  }

  onPageChangeSkill(page){
    this.buSkillSet.page = page;
  }

  resetForm() {
    this.skillLabels = [];
    this.skillsSet = [];
    this.locationslabels = [];
    this.buRepoLocationArr = [];
    this.shiftBreakArr = [];
    this.schemeArr = [];
    this.schemeLabels = [];
    this.buShiftSetUpForm.reset();
    this.heading = 'BU SHIFT WORK SETUP';
    this.saveBtn = 'Save Shift Setup Details';
    this.buShiftSetUpForm.patchValue({
      isBreakTime: null,
    });
    this.show = false;
  }

  addskill() {
    if (this.addSkillForm.valid) {
      this.buService
        .createSkillSet(this.addSkillForm.value)
        .subscribe((res) => {
          this.addSkillForm.reset();
          this.displaySkilltable();
          this.toastService.success('Skillset added successfully.');
        });
    }

    // console.log("addskill", this.addSkillForm.value);
  }

  editSkill(data) {
    this.singleItem = data;
    this.updateBtn = true;
    this.addSkillForm.patchValue({
      name: data.name,
    });

  }

  updateSkill() {
    this.updateSkillData = this.singleItem;
    this.editSkillObj = {
      name: this.addSkillForm.get('name').value,
      skillSetId: this.updateSkillData._id,
      status: "1"
    };

    this.buService.updateSkillSet(this.editSkillObj).subscribe((res) => {
      this.addSkillForm.reset();
      this.displaySkilltable();
      this.toastService.success('Skillset updated successfully.');
    });
  }

  deleteSkill(delData) {
    this.delDataObj = {
      skillSetId: delData._id,
    };

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.buService.deleteSkillSet(this.delDataObj).subscribe((res) => {
          this.displaySkilltable();
          this.toastService.success('Skillset deleted successfully.');
          this.addSkillForm.reset();
        });
      }
    });
  }

  activeTab(event) {
    this.skillLabels = [];
    this.skillsSet = [];
    this.locationslabels = [];
    this.buRepoLocationArr = [];
    this.shiftBreakArr = [];
    this.schemeArr = [];
    this.schemeLabels = [];
    this.activeId = 2;
    this.heading = 'EDIT BUSINESS UNIT DETAILS ';
    this.saveBtn = 'Update Shift Setup Details';

    this.buSetting(event._id);
    this.buService.getBuShiftEdit(event._id).subscribe((res) => {
      this.editData = res['data']['businessUnitList'];

      // this.selectedBuOption = "";

      this.geoReportingLocations = [];
      this.isMap = false;
      this.isDropdown = false;
     /* this.buService.getScheme(event._id, '').subscribe((res) => {
        this.schemeTable = res['data'];
      });*/
     // this.editData.reportingLocationType = 'both';
      this.reportingLocationRadius = this.editData.reportingLocationRadius;
      this.geoReportingLocations = !!this.editData?.geoReportingLocation && this.editData.geoReportingLocation.map(
        (val) => val.name
      );
     // this.editData?.reportingLocationType && this.editData?.reportingLocationType
      this.isMap =
        this.editData?.reportingLocationType == 'map' ||
        this.editData?.reportingLocationType == 'both';
      this.isDropdown =
        this.editData?.reportingLocationType == 'text' ||
        this.editData?.reportingLocationType == 'both';


      for (var i = 0; this.buOption.length > i; i++) {
        if (this.buOption[i]._id == event._id) {
          this.buShiftSetUpForm.patchValue({
            businessUnitId: this.buOption[i],
          });
        }
      }

      this.skillLabels = [];

      if (this.showSpeciality) {
        if (this.editData.subSkillSets != null) {
          for (let k = 0; this.editData.subSkillSets.length > k; k++) {
            this.skillLabels.push([
              this.editData.subSkillSets[k].skillSetId?.name,
              this.editData.subSkillSets[k]?.name,
            ]);
            this.skillsSet.push(this.editData.subSkillSets[k]?._id);
          }
        }
      } else {
        if (this.editData.mainSkillSets != null) {
          for (let k = 0; this.editData.mainSkillSets.length > k; k++) {
            this.skillLabels.push([this.editData.mainSkillSets[k]?.name]);
            this.skillsSet.push(this.editData.mainSkillSets[k]?._id);
          }
        }
      }

      if (this.editData.reportingLocation != null) {
        for (let j = 0; this.editData.reportingLocation.length > j; j++) {
          this.locationslabels.push(this.editData.reportingLocation[j]?.name);
          this.buRepoLocationArr.push(this.editData.reportingLocation[j]?._id);
        }
      }

      if (this.editData.scheme != null) {
        for (let k = 0; this.editData.scheme.length > k; k++) {
          this.schemeLabels.push(this.editData.scheme[k]?.schemeName);
          this.schemeArr.push(this.editData.scheme[k]?._id);
        }
      }
      // console.log("locationslabels", this.locationslabels);
      // console.log("buRepoLocationArr", this.buRepoLocationArr);

      if (this.editData.standByShiftPermission == true) {
        this.showshift = true;
      }

      if (this.editData.shiftBreak && this.editData.shiftBreak?.length>0 ) {
        this.buShiftSetUpForm.get('isBreakTime').setValue(true);
        this.show = true;
      }else{
        this.buShiftSetUpForm.get('isBreakTime').setValue(false);
        this.show = false;
      }

      // for (let i = 0; this.editData.subSkillSets.length > i; i++)
      //   this.skillLabels.push([
      //     this.editData.subSkillSets[i].skillSetId.name,
      //     this.editData.subSkillSets[i].name,
      //   ]);

      // this.locationslabels = [this.editData.reportingLocation.name];
      this.locationslabels = [];
      for (let i = 0; this.editData?.reportingLocation.length > i; i++)
        this.locationslabels.push([this.editData.reportingLocation[i].name]);

       if( this.editData?.shiftBreak && this.editData?.shiftBreak.length >0)
       {
        for (let k = 0; this.editData?.shiftBreak.length > k; k++) {
          this.shiftBreakArr.push({
            shiftHour: this.editData.shiftBreak[k].shiftHour,
            breakInMinutes: this.editData.shiftBreak[k].breakInMinutes,
          });
        }
       }
       this.cutOffDays =  !this.editData?.cutOffDaysForBookingAndCancelling ? false:true,
       this.cutOffDaysSelect = this.editData?.cutOffDaysForBookingAndCancelling;
       this.cutOffRdion = !this.editData?.cutOffDaysForBookingAndCancelling ? false :true;
      this.buShiftSetUpForm.patchValue({
        noOfWeek: this.editData?.noOfWeek,
        plannedHours: this.editData?.plannedHours,
        shiftTimeInMinutes: this.editData?.shiftTimeInMinutes,
        isBreakTime: this.editData.shiftBreak && this.editData.shiftBreak?.length>0 ? true: false,
        shiftBreak: this.editData?.shiftBreak,
        standByShiftPermission: this.editData?.standByShiftPermission,
        shiftCancelHours: this.editData?.shiftCancelHours,
        cancelShiftPermission: this.editData?.cancelShiftPermission,
        cutOffDaysForBookingAndCancelling: !this.editData?.cutOffDaysForBookingAndCancelling ? false:true,
        reportingLocationType: this.editData?.reportingLocationType,
      });
    });
  }
  cutOffDaysForBooking(e){
    this.cutOffDays = e == 'true' ? true : false;
  }

  buSetting(id) {
    this.buService.getBuShiftGeoLocation(id).subscribe((res: { result }) => {
      this.buSettingObj = res.result;
      this.buSettingObj.isCheckInEnabled = res.result.isCheckInEnabled;
      this.buSettingObj.isProximityEnabled = res.result.isProximityEnabled;
      this.buSettingObj.reportingLocationRadius = res.result.proximity;

    });
  }

  getSkillSpeciality(){
    let payload= {"skillSetId": this.addSpecialityForm.get('skillSetId').value?._id}
    this.buService
      .getSkillSpeciality(
        payload
      )
      .subscribe((res) => {
        this.buSkillspeciality = res['data']?.subSkillSets || [];
      });
  }

  addSkillSpeciality() {
    if (this.addSpecialityForm.valid) {
      this.buService
        .createSpeciality(this.addSpecialityForm.value)
        .subscribe((res) => {
          this.addSpecialityForm.patchValue({
            skillSetId: this.addSpecialityForm.get('skillSetId').value,
          });
          this.toastService.success('Skill speciality added successfully.');
          this.getSkillSpeciality();
        });
    }
  }

  editSpeciality(data) {
    this.singleSpecialityItem = data;
    this.updateBtnSpe = true;
    this.addSpecialityForm.patchValue({
      name: data.name,
    });

  }

  updateSpeciality() {
    this.updateSkillData = this.singleSpecialityItem;

    this.editSpecialityObj = {
      name: this.addSpecialityForm.get('name').value,
      skillSetId: this.updateSkillData.skillSetId._id,
      status: 1,
      subSkillSetId: this.updateSkillData._id,
    };

    this.buService.updateSpeciality(this.editSpecialityObj).subscribe((res) => {
      this.toastService.success('Skill speciality updated successfully.');
      this.getSkillSpeciality();
      this.addSpecialityForm.reset();
      // this.buSkillspeciality;
    });
  }

  deleteSpeciality(delData) {
    this.delSpecialityDataObj = {
      subSkillSetId: delData._id,
    };


    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.buService
          .deleteSpeciality(this.delSpecialityDataObj)
          .subscribe((res) => {
            this.toastService.success('Speciality deleted successfully.');
            //  this.buSkillspeciality();
            this.addSpecialityForm.get('name').reset();
            this.getSkillSpeciality();
          });
      }
    });
  }

  addRepoLocation() {
    if (this.addLocationsForm.valid) {
      this.buService
        .createLocations(this.addLocationsForm.value)
        .subscribe((res) => {
          this.addLocationsForm.reset();
          this.displayLocationTable();
          this.toastService.success('Reporting location added successfully.');
        });
    }
    //console.log("loction", this.addLocationsForm.value);
  }

  saveSetupDetails() {
    this.buSetupSaveLoader = true;
    this.buShiftSetUpForm.patchValue({
      businessUnitId: this.buShiftSetUpForm.get('businessUnitId').value?._id,
      reportingLocation: this.buRepoLocationArr,
      // location: this.buRepoLocationArr,
      scheme: this.schemeArr,
      geoReportingLocation: this.geoReportingLocations,
      isCheckInEnabled: this.buSettingObj?.isCheckInEnabled,
      isProximityEnabled: this.buSettingObj?.isProximityEnabled,
      reportingLocationRadius: this.buSettingObj?.reportingLocationRadius,
    });
     if (this.cutOffDays) {
      this.buShiftSetUpForm.patchValue({ cutOffDaysForBookingAndCancelling: this.cutOffDaysSelect });
    }
    else{
      this.buShiftSetUpForm.patchValue({ cutOffDaysForBookingAndCancelling: '' });
    }

    if (this.isMap && this.isDropdown) {
      this.buShiftSetUpForm.patchValue({ reportingLocationType: 'both' });
    } else if (this.isMap) {
      this.buShiftSetUpForm.patchValue({ reportingLocationType: 'map' });
    } else if (this.isDropdown) {
      this.buShiftSetUpForm.patchValue({ reportingLocationType: 'text' });
    }

    if (this.showSpeciality) {
      this.buShiftSetUpForm.patchValue({
        subSkillSets: this.skillsSet,
        mainSkillSets: [],
        skillSetTierType: 2,
      });
    } else {
      this.buShiftSetUpForm.patchValue({
        mainSkillSets: this.skillsSet,
        subSkillSets: [],
        skillSetTierType: 1,
      });
    }

    if (this.buShiftSetUpForm.get('isBreakTime').value == false) {
      this.buShiftSetUpForm.patchValue({
        shiftBreak: [],
      });
    }

    this.buShiftSetUpForm.value['locations'] = this.buShiftSetUpForm.value.reportingLocation
    delete this.buShiftSetUpForm.value.reportingLocation
    this.buService
      .saveBuSetup(this.buShiftSetUpForm.value)
      .pipe(
        finalize(() => {
          this.buSetupSaveLoader = false;
        })
      )
      .subscribe((res) => {
        this.activeId = 1;
        this.buShiftSetUpForm.reset();
        // this.toastService.info(res['data'].message);
        if (res['data']) {
          this.toastService.success(
            'BU Shift Setup saved successfully',
            'Successfull'
          );
        }
      },
      (err)=>{
        this.toastService.error(err.error.error);
      });
  }

  @ViewChild('AddSkill', { static: true })
  AddSkill: TemplateRef<any>;
  @ViewChild('AddSpeciality', { static: true })
  AddSpeciality: TemplateRef<any>;
  @ViewChild('AddLocations', { static: true })
  AddLocations: TemplateRef<any>;

  createAddSkill() {
    this.modelService.open(this.AddSkill, {
      windowClass: 'modal-ui-fix right-align',
    });
    this.addSkillForm = this.formBuilder.group({
      name: [null, [Validators.required]],
      status:["1"]
    });
    this.displaySkilltable();
  }

  onSkillSetChange($event) {
    this.buSkillspeciality = $event.subSkillSets;
    this.buShiftSetUpForm.patchValue({
      subSkillSets: null,
    });
   /* this.buService.getSkillSpeciality($event._id).subscribe((res) => {
      this.buSkillspeciality = res['data'];
      this.buShiftSetUpForm.patchValue({
        subSkillSets: null,
      });
    });*/
  }

  addSkillbutton() {
    if (this.showSpeciality) {
      this.subSkillLabel = this.buShiftSetUpForm.get('subSkillSets').value._id;

      if (this.skillsSet.indexOf(this.subSkillLabel) > -1) {
        this.toastService.warning('Already Exists');
      } else {
        this.skillLabels.push([
          this.buShiftSetUpForm.get('mainSkillSets').value.name,
          this.buShiftSetUpForm.get('subSkillSets').value.name,
        ]);
        this.skillsSet.push(this.subSkillLabel);
      }

      this.buShiftSetUpForm.patchValue({
        mainSkillSets: null,
        subSkillSets: null,
      });
    } else {
      this.subSkillLabel = this.buShiftSetUpForm.get('mainSkillSets').value._id;

      if (this.skillsSet.indexOf(this.subSkillLabel) > -1) {
        this.toastService.warning('Already Exists');
      } else {
        this.skillLabels.push([
          this.buShiftSetUpForm.get('mainSkillSets').value.name,
        ]);
        this.skillsSet.push(this.subSkillLabel);
      }
    }
  }

  clearLabel(i) {
    this.skillsSet.splice(i, 1);
    this.skillLabels.splice(i, 1);
    // console.log("SkillSetArry2", this.skillsSet);
    // console.log("SkillSetLabel2", this.skillLabels);
  }

  clearSkills() {
    this.skillsSet = [];
    this.skillLabels = [];
  }

  addLocateButton() {
    //this.locationlabel = this.buShiftSetUpForm.get('reportingLocation').value.name;
    this.locationlabel =
      this.buShiftSetUpForm.get('reportingLocation').value._id;
    // console.log("locationlables", this.locationlabel);

    if (this.buRepoLocationArr.indexOf(this.locationlabel) > -1) {
      this.toastService.warning('Already Exist');
    } else {
      this.locationslabels.push(
        this.buShiftSetUpForm.get('reportingLocation').value.name
      );
      this.buRepoLocationArr.push(this.locationlabel);
    }

    this.buShiftSetUpForm.patchValue({
      reportingLocation: null,
    });
    // console.log("locate add button", this.locationlabel);
  }

  addSchemeButton() {
    this.schemeLabel = this.buShiftSetUpForm.get('scheme').value._id;
    this.schemeLabels.push(
      this.buShiftSetUpForm.get('scheme').value.schemeName
    );
    this.schemeArr.push(this.schemeLabel);
  }

  clearLocationLabel(i) {
    this.buRepoLocationArr.splice(i, 1);
    this.locationslabels.splice(i, 1);
    // console.log("buRepoLocationArr", this.buRepoLocationArr);
    // console.log("locationslabels", this.locationslabels);
  }
  clearSchemeLabel(i) {
    this.schemeArr.splice(i, 1);
    this.schemeLabels.splice(i, 1);
  }

  addBreakTimeButton() {
    this.shiftBreakArr.push({
      breakInMinutes: this.minBreakSelect,
      shiftHour: this.shiftDurationSelect,
      id: this.shiftDurationSelect,
    });
    this.buShiftSetUpForm.patchValue({
      shiftBreak: this.shiftBreakArr,
    });
    this.minBreakSelect = null;
    this.shiftDurationSelect = null;
  }

  clearLabelBreak(i) {
    this.shiftBreakArr.splice(i, 1);
  }

  addSpeciality() {
    if (this.buShiftSetUpForm.get('mainSkillSets').value != null) {
      this.modelService.open(this.AddSpeciality, {
        windowClass: 'modal-ui-fix right-align',
      });
    } else {
      this.toastService.info('Please select skillset');
    }
    this.addSpecialityForm = this.formBuilder.group({
      skillSetId: [null, [Validators.required]],
      name: [null, [Validators.required]],
    });
    this.addSpecialityForm.patchValue({
      skillSetId: this.buShiftSetUpForm.get('mainSkillSets').value,
    });
  }

  addLocations() {
    this.modelService.open(this.AddLocations, {
      windowClass: 'modal-ui-fix right-align',
    });
    this.addLocationsForm = this.formBuilder.group({
      name: [null, [Validators.required]],
      status: "1",
      reportingLocationId:[null]
    });
  }

  changeToggle(e) {
    if (e == true) {
      this.show = true;
    } else {
      this.show = false;
    }
  }

  toggleshift(val) {
    this.showshift = val;
    if (this.showshift) this.shiftButtonName = 'Hide';
    else this.shiftButtonName = 'Show';
  }

  changeLocationType(type) {
    if (type === 'text') {
      this.isMap = false;
      this.isDropdown = true;
    } else if (type === 'map') {
      this.isMap = true;
      this.isDropdown = false;
    } else {
      this.isMap = true;
      this.isDropdown = true;
    }
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    if (changeEvent.nextId === 2) {
      // changeEvent.preventDefault();
      this.buSettingObj = {
        isCheckInEnabled: null,
        isProximityEnabled: null,
        reportingLocationRadius: null,
      };
    }

    if (changeEvent.nextId === 1) {
      this.geoReportingLocations = [];
    }
  }

  setGeoLocations(locations: any) {
    this.geoReportingLocations = locations;
  }

  changeCheckInEnable(enablCheckIn: any) {
    this.buSettingObj['isCheckInEnabled'] = enablCheckIn;
  }

  changeProximityEnable(enableProximity: any) {
    this.buSettingObj['isProximityEnabled'] = enableProximity;
  }

  changeRepLoc(repLocRadius: any) {
    this.buSettingObj['reportingLocationRadius'] = repLocRadius;
    // this.reportingLocationRadius = repLocRadius;
  }
  editLocation(data){
    this.addLocationsForm.get('name').setValue(data?.name);
     this.addLocationsForm.get('reportingLocationId').setValue(data._id);
     this.updateLocationFlag=true;
  }
  updateRepoLocation() {
    if (this.addLocationsForm.valid) {
      this.buService
        .updateLocations(this.addLocationsForm.value)
        .subscribe((res) => {
          this.addLocationsForm.reset();
          this.displayLocationTable();
          this.toastService.success('Reporting location updated successfully.');
          this.updateLocationFlag=false;
        });
    }
    //console.log("loction", this.addLocationsForm.value);
  }



}
