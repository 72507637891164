<div class="d-flex flex-column">
  <ul ngbNav [(activeId)]="activeId" #companyNav="ngbNav" class="nav-tabs rounded mb-3">
    <li [ngbNavItem]="1">
      <a ngbNavLink (click)="overViewEvent($event)">OVERVIEW</a>
      <ng-template ngbNavContent>
        <challenges-overview
          (ViewEvent)="userView($event)"
          (EditEvent)="userEdit($event)"
          (isManage)="manageForm($event)"
        ></challenges-overview>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink (click)="!isUserView ? viewType = 'edit' : false">{{
        isUserEdited
          ? "EDIT"
          : isFormManaged
          ? "MANAGE"
          : isUserView
          ? "VIEW"
          : "ADD"
      }}</a>
      <ng-template ngbNavContent>
        <manage-challenges
          *ngIf="viewType === 'manage'"
          [manageId]="manageId"
        ></manage-challenges>
        <view-challanges
          *ngIf="viewType === 'view'"
          [challangesId]="userId"
          [challangesData]="userViewData"
          (overViewEvent)="overViewEvent($event)"
        >
        </view-challanges>
        <add-edit-challenges
          *ngIf="viewType === 'edit'"
          [isUserEdited]="isUserEdited"
          [challangesId]="userId"
          [challangesData]="userEditData"
          (overViewEvent)="overViewEvent($event)"
        ></add-edit-challenges>
      </ng-template>
    </li>
    <li [ngbNavItem]="3">
      <a ngbNavLink (click)="overViewLogEvent($event)">LOG</a>
      <ng-template ngbNavContent>
        <challenges-log (ViewEvent)="userView($event)"></challenges-log>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="companyNav"></div>
</div>
