<div
  #sidemenu
  id="sidemenu"
  class="p-3 d-none d-sm-block pt-5 sidemenu"
  *ngIf="!isView"
>
  <aside>
    <div class="form-group">
      <label class="d-block text-center">BUSINESS UNIT</label>
      <ng-select
        [items]="buOption"
        [loading]="buLoader"
        class="custom"
        bindLabel="orgName"
        placeholder="Select/Search Business unit"
        appendTo="body"
        [searchable]="true"
        [clearable]="true"
        [closeOnSelect]="true"
        [(ngModel)]="buId"
      >
        <ng-option [value]="item" *ngFor="let item of buOption">
          <span title="{{ item.businessUnit }}">{{ item.businessUnit }}</span>
        </ng-option>
      </ng-select>
    </div>

    <div class="row">
      <div class="col pr-md-3">
        <button class="btn btn-danger btn-block">Cancel</button>
      </div>
      <div class="col pl-md-0">
        <button class="btn btn-info btn-block" (click)="viewClicked()">
          View
        </button>
      </div>
    </div>
  </aside>
</div>

<div class="d-flex flex-column" *ngIf="isView">
  <ul
    ngbNav
    #companyNav="ngbNav"
    [(activeId)]="activeId"
    (navChange)="onNavChange($event)"
    class="nav-tabs rounded mb-3"
  >
    <li [ngbNavItem]="1">
      <a ngbNavLink>OVERVIEW</a>
      <ng-template ngbNavContent>
        <app-facial-overview
          [bu]="buId"
          (edit)="editFacialId($event)"
        ></app-facial-overview>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink>{{ this.tabView == "edit" ? "EDIT" : "ADD" }}</a>
      <ng-template ngbNavContent>
        <div *ngIf="addPhotoIdLoader" style="position: fixed;top: 0;right: 0;bottom: 0;left: 0;z-index: 1050;background-color: rgba(0, 0, 0, 0.5);overflow: auto;">
          <div class="pageLoading" style="text-align: center;color: white;font-size: 450%;">
              <div class="innerDiv">
                  <i class="fa fa-spinner fa-spin" style="float: none;"></i> Please wait, data is updating....
              </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header bg-theme">
            <h5 class="m-0">ADD FACIAL ID</h5>
            <p class="m-0">Selected Business Unit : {{ buId.orgName }}</p>
          </div>
          <div class="card-body">
            <ng-container *ngIf="this.tabView != 'edit'">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="font-weight-500"> Select Staff ID/Name</label>
                    <ng-select
                      [items]="userPTable.data"
                      class="custom"
                      bindLabel="name"
                      [virtualScroll]="false"
                      placeholder="Select User"
                      appendTo="body"
                      [multiple]="false"
                      [searchable]="true"
                      [clearable]="true"
                      (scrollToEnd)="loadMoreAdminItems($event)"
                      (change)="selectedStaff($event)"
                      [(ngModel)]="adminSelect"
                      [ngModelOptions]="{ standalone: true }"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row" *ngIf="this.adminSelect">
                <div class="col-md-4">
                  <h5 class="font-weight-500 m-0">
                    {{ staffDetails?.name }}
                  </h5>
                  <p class="text-muted">{{ staffDetails?.role?.name }}</p>
                </div>
                <div class="col-md-8">
                  <button class="btn btn-info btn-lg" (click)="toggleCamera()">
                    {{ showWebcam ? "Switch OFF Camera" : "Switch ON Camera" }}
                  </button>
                </div>
                <div class="col-md-4">
                  <img
                    *ngIf="userFacialImgUrl"
                    [src]="userFacialImgUrl"
                    class="img-fluid border rounded mb-2 img-shot"
                    alt=""
                  />
                  <div class="form-group">
                    <label class="font-weight-500">Staff ID</label>
                    <input
                      type="text"
                      class="form-control"
                      [(ngModel)]="staffName"
                      readonly
                    />
                  </div>
                  <div class="form-group">
                    <label class="font-weight-500">Phone no.</label>
                    <input
                      type="text"
                      class="form-control"
                      [(ngModel)]="staffPhone"
                      readonly
                    />
                  </div>
                </div>
                <div class="col-md-8">
                  <webcam
                    [trigger]="triggerObservable"
                    (imageCapture)="handleImage($event)"
                    *ngIf="showWebcam"
                    [allowCameraSwitch]="allowCameraSwitch"
                    [imageQuality]="0.9"
                    (initError)="handleInitError($event)"
                    [width]="500"
                    [height]="340"
                  >
                  </webcam>
                  <img
                    #snap
                    *ngIf="!showWebcam"
                    [src]="capturedImg"
                    class="img-fluid border rounded mb-2 img-shot"
                    alt=""
                    width="500"
                    height="320"
                  />
                  <div class="" *ngIf="actionbtn">
                    <button class="btn btn-info" (click)="triggerSnapshot()">
                      Capture
                    </button>
                    <button class="btn btn-success ml-2" (click)="addPhotoId()">
                      Add Photo ID
                    </button>
                    <button
                      class="btn btn-danger ml-2"
                      (click)="navigateToOverview()"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="this.tabView == 'edit'">
              <app-facial-edit
                [userFacial]="userFacialDetail"
                (changeTab)="navigateToOverview()"
              ></app-facial-edit>
            </ng-container>
          </div>
          <div class="card-footer">
            <button class="btn btn-warning" (click)="navigateToOverview()">
              Back
            </button>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="companyNav"></div>
</div>
