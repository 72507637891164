import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { generateImageUrl } from 'src/app/core/utils/helper';
import { GamificationService } from 'src/app/users/service/gamification/gamification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'view-challanges',
  templateUrl: './view-challanges.component.html',
  styleUrls: ['./view-challanges.component.scss'],
})
export class ViewChallangesComponent implements OnInit {
  discriptioGroup: FormGroup;
  rulesGroup: FormGroup;
  createRanks: FormGroup;
  isLinear = false;
  teamDetails = [];
  workFlowStatusInfo = []
  disabledTrue: boolean = true;
  criteriaCategoryView: any;
  progressTypesView: any;
  displaySelectionView: any;
  displayDescriptionsView: any;
  criteriaCategorySelectView: any;
  allCriteria = [
    {
      value: 1,
      key: 'Reading articles',
    },
    {
      value: 2,
      key: 'Questionnaire attempted',
    },
    {
      value: 3,
      key: ' Event attended',
    },
    {
      value: 4,
      key: 'Posting Done',
    },
    {
      value: 5,
      key: 'First Login',
    },
    {
      value: 6,
      key: 'Visible to submitted User',
    },
    {
      value: 7,
      key: 'Visible to Nominated User',
    },
    {
      value: 8,
      key: 'Nominated User',
    },
    {
      value: 9,
      key: 'Questionnaire attempted',
    },
    {
      value: 10,
      key: 'On-time report',
    },
    {
      value: 11,
      key: 'No. of Shift worked (By Hour)',
    },
    {
      value: 12,
      key: 'No. of Shift worked (By Shift Count)',
    },
  ];
  poinstSystemData: any;
  pointSytemView: any;
  customFormcreatiaView: any;
  boardcreatiaView: any;
  systemCretiaView: any;
  rewardPoints;
  @Input() challangesId: string = null;
  @Input() challangesData: any = null;
  adminGroup: FormGroup;
  creatiaView: any;
  userDetails: any;
  badgeTieringArray: any[] = [];
  schemeDataArr = [];
  schemeData = null;
  shiftcriteriaSelect = null;
  imagePath = environment.imageUrl;
  dataLoaded = true;
  nomineeQuestion
  baseUrl = environment.imageUrl;
  constructor(
    private formBuilder: FormBuilder,
    private gamificationService: GamificationService,
    private toastService: ToastrService
  ) { }

  ngOnInit() {
    this.initRulesFrom();
    this.initDiscriptionFrom();
    this.adminGroup = this.formBuilder.group({
      administrators: this.formBuilder.array([]),
    });
    this.challangesId && this.getSingleChallenges(this.challangesId);
    this.patchData();
    this.createRanks = this.formBuilder.group({
      name: ['', Validators.required],
      startRange: [0, Validators.required],
      endRange: [0, Validators.required],
      icon: ['', Validators.required],
    })

  }


  patchData() {
    if (this.challangesData) {
      const {
        title,
        description,
        challengeStart,
        challengeEnd,
        publishStart,
        publishEnd,
        icon,
        status,
        leaderBoard,
      } = this.challangesData || {};
      this.discriptioGroup.patchValue({
        title,
        description,
        challengeStart,
        challengeEnd,
        publishStart,
        publishEnd,
        icon,
        status,
        leaderBoard,
      });
      const subscribe = this.gamificationService
        .getChallengesNewSingle(this.challangesData._id)
        .pipe(
          finalize(() => {
            subscribe.unsubscribe();
          })
        )
        .subscribe(
          (response: any) => {
            const data = response.data;
            this.challangesData = data;
            this.dataLoaded = false;
            this.patchDataAll();
          },
          (error) => {
            this.toastService.error('Something went wrong');
          }
        );
    }
  }

  patchDataAll() {
    if (this.challangesData) {
      const {
        title,
        description,
        challengeStart,
        challengeEnd,
        publishStart,
        publishEnd,
        icon,
        status,
        leaderBoard,
        assignUsers,
        criteriaType,
        criteriaSourceType,
        criteriaCountType,
        maximumRewards,
        criteriaCount,
        selectedChannel,
        selectedWall,
        selectedScheme,
        selectedCustomForm,
        rewardPoints,
        nonRewardPointSystem,
        nonRewardPointSystemEnabled,
        stopAfterAchievement,
        setLimitToMaxRewards,
        badgeTiering,
        ranks,
        administrators,
        nomineeQuestion
      } = this.challangesData || {};
      this.workFlowStatusInfo = this.challangesData.workFlowStatusInfo
      this.schemeData = selectedScheme;
      const adminControl = this.AdminFieldsControl;
      const rankControl = this.ranksControl;
      ranks?.forEach(({ name, startRange, endRange, icon }) => {
        let field = this.formBuilder.group({
          name,
          startRange,
          endRange,
          icon,
        });
        rankControl.push(field);
      });


      const creteriav = this.allCriteria.filter(
        (data) => { return data.value == criteriaSourceType }
      );
      const creteria = this.allCriteria.find(
        (cretia) => { return cretia.value == criteriaSourceType }
      );
      this.populateCriteria(creteria);
      this.userDetails = assignUsers ? assignUsers : [];
      this.discriptioGroup.patchValue({
        title,
        description,
        challengeStart,
        challengeEnd,
        publishStart,
        publishEnd,
        icon,
        status,
        leaderBoard,
      });
      if (nonRewardPointSystemEnabled) {
        this.pointSytemView = nonRewardPointSystem?.title;
      }
      this.nomineeQuestion = nomineeQuestion?.question;
      const nonRewardPointSystemConst = nonRewardPointSystem ?? false;
      const nonRewardPointSystemEnabledConst = nonRewardPointSystemEnabled ?? false;
      const selectedChannelConst = selectedChannel ?? '';
      const selectedWallConst = selectedWall ?? '';
      const selectedCustomFormConst = selectedCustomForm ?? '';
      this.rulesGroup.patchValue({
        criteriaType,
        criteriaSourceType,
        criteriaCountType,
        maximumRewards,
        criteriaCount,
        selectedChannelConst,
        selectedWallConst,
        selectedCustomFormConst,
        rewardPoints,
        nonRewardPointSystemConst,
        nonRewardPointSystemEnabledConst,
        stopAfterAchievement,
        setLimitToMaxRewards,
        badgeTiering,
      });
    }
  }
  getSingleChallenges(id: string) {
    const sub = this.gamificationService
      .getChallengesNewSingle(id)
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe(
        ({
          res, data: {
            title,
            description,
            challengeStart,
            challengeEnd,
            publishStart,
            publishEnd,
            icon,
            status,
            leaderBoard,
            assignUsers,
            criteriaType,
            criteriaSourceType,
            criteriaCountType,
            maximumRewards,
            criteriaCount,
            selectedChannel,
            selectedWall,
            selectedScheme,
            selectedCustomForm,
            rewardPoints,
            nonRewardPointSystem,
            nonRewardPointSystemEnabled,
            stopAfterAchievement,
            setLimitToMaxRewards,
            badgeTiering,
            ranks,
            administrators,
            isTeam,
            teams,
            isCriteriaCategories,
            criteriaCategories,
            isRewardChallenge,
            rewardType,
            isProgressStatusSetup,
            progressType,
            displaySelection,
            displayDescription,
            displayField,
            isTargetCriteria,
            targetCriteriaCount,
            targetDescription,
          },
        }: any) => {
          // this.rewardPoints = rewardPoints;
          this.schemeData = selectedScheme;
          const adminControl = this.AdminFieldsControl;
          this.teamDetails = this.getModifiedTeams(teams);
          const rankControl = this.ranksControl;
          ranks.forEach(({ name, startRange, endRange, icon }) => {
            let field = this.formBuilder.group({
              name,
              startRange,
              endRange,
              icon,
            });
            rankControl.push(field);
          });
          administrators?.filter((item, index, self) => self.indexOf(item) === index)?.forEach((element) => {
            let field = this.formBuilder.group({
              string: [element.name, Validators.compose([Validators.required])],
              _id: [element._id, Validators.compose([Validators.required])],
            });
            adminControl.push(field);
          });

          const creteria = this.allCriteria.find(
            (cretia) => cretia.value === criteriaSourceType
          );

          this.populateCriteria(creteria);
          this.userDetails = assignUsers ? assignUsers : [];
          this.discriptioGroup.patchValue({
            title,
            description,
            challengeStart,
            challengeEnd,
            publishStart,
            publishEnd,
            icon,
            status,
            leaderBoard,
            isTeam,
          });

          this.rulesGroup.patchValue({
            criteriaType: criteriaType,
            criteriaSourceType: criteriaSourceType,
            criteriaCountType: criteriaCountType,
            maximumRewards: maximumRewards,
            criteriaCount: criteriaCount,
            selectedChannel: {
              name: selectedChannel ? selectedChannel.name : '',
              _id: selectedChannel ? selectedChannel._id : '',
            },
            selectedWall: {
              name: selectedWall ? selectedWall.wallName : null,
              _id: selectedWall ? selectedWall._id : '',
            },
            selectedCustomForm: {
              title: selectedCustomForm
                ? selectedCustomForm.title
                : '',
              _id: selectedCustomForm ? selectedCustomForm._id : '',
            },
            rewardPoints: rewardPoints,
            nonRewardPointSystem: nonRewardPointSystem,
            nonRewardPointSystemEnabled: nonRewardPointSystemEnabled,
            stopAfterAchievement: stopAfterAchievement,
            setLimitToMaxRewards: setLimitToMaxRewards,
            badgeTiering: badgeTiering,
            isCriteriaCategory: isCriteriaCategories,
            isRewardChallenge: isRewardChallenge,
            isProgressSetup: isProgressStatusSetup,
            isTargetCriteria: isTargetCriteria,
            targetCount: targetCriteriaCount,
            targetCountDescription: targetDescription,
          });
          this.criteriaCategoryView = criteriaCategories?.question,
          this.progressTypesView = this.getViewNameForProgressType(progressType),
          this.displaySelectionView = this.getViewNameForDisplaySelection(displaySelection),
          this.displayDescriptionsView = displayDescription?.question,
          this.criteriaCategorySelectView = displayField?.question
        },
        (error) => {
          this.toastService.error('No data found');
        }
      );
  }
  initDiscriptionFrom() {
    return (this.discriptioGroup = this.formBuilder.group({
      title: [{ value: '', disabled: true }, Validators.required],
      description: [{ value: '', disabled: true }, Validators.required],
      challengeStart: [{ value: '', disabled: true }, Validators.required],
      challengeEnd: [{ value: '', disabled: true }, Validators.required],
      publishStart: [{ value: '', disabled: true }, Validators.required],
      publishEnd: [{ value: '', disabled: true }, Validators.required],
      icon: [{ value: '', disabled: true }, Validators.required],
      status: 'Draft',
      leaderBoard: false,
      isTeam: false,
    }));
  }

  getModifiedTeams(data) {
    const newArrayOfObj = data?.map(({
       name: title,
      logo: icon,
      assignUsers: teamUserDetails,
    }) => ({
      title,
      icon,
      teamUserDetails,
    }));
    return newArrayOfObj;
  }

  getViewNameForProgressType(key) {
    switch (key) {
      case 1:
        return 'Leader Board';
      case 2:
        return 'Digital Stamp';
    }
  }

  getViewNameForDisplaySelection(key) {
    switch (key) {
      case 1:
        return 'See own only';
      case 2:
        return 'See own or other';
        case 3:
          return 'See own + others'      
          case 4:
          return 'See own + all combined';
    }
  }

  get AdminFieldsControl(): FormArray {
    return this.adminGroup.get('administrators') as FormArray;
  }
  initRulesFrom() {
    return (this.rulesGroup = this.formBuilder.group({
      criteriaType: [{ value: 1, disabled: true }, Validators.required],
      criteriaSourceType: [{ value: '', disabled: true }, Validators.required],
      criteriaCountType: [{ value: 1, disabled: true }, Validators.required],
      maximumRewards: [{ value: 0, disabled: true }, Validators.required],
      criteriaCount: [{ value: 1, disabled: true }, Validators.required],
      selectedChannelConst: this.formBuilder.group({
        name: [{ value: '', disabled: true }, Validators.compose([Validators.required])],
        _id: [{ value: '', disabled: true }, Validators.compose([Validators.required])],
      }),
      selectedWallConst: this.formBuilder.group({
        wallName: [{ value: '', disabled: true }, Validators.compose([Validators.required])],
        _id: [{ value: '', disabled: true }, Validators.compose([Validators.required])],
      }),
      selectedCustomFormConst: this.formBuilder.group({
        title: [{ value: '', disabled: true }, Validators.compose([Validators.required])],
        _id: [{ value: '', disabled: true }, Validators.compose([Validators.required])],
      }),

      rewardPoints: [{ value: null, disabled: true }, Validators.required],
      nonRewardPointSystem: [{ value: '', disabled: true }, Validators.required],
      nonRewardPointSystemEnabled: [{ value: false, disabled: true }, Validators.required],
      stopAfterAchievement: [{ value: false, disabled: true }, Validators.required],
      setLimitToMaxRewards: [{ value: false, disabled: true }, Validators.required],
      badgeTiering: [{ value: false, disabled: true }, Validators.required],
      ranks: this.formBuilder.array([]),
      isCriteriaCategory:  false,
      isRewardChallenge: false,
      isProgressSetup: false,
      isTargetCriteria: false,
      targetCount: [{ value: '', disabled: true }, Validators.required],
      targetCountDescription: [{ value: '', disabled: true }, Validators.required],
    }));
  }

  get ranksControl(): FormArray {
    return this.rulesGroup.get('ranks') as FormArray;
  }

  populateCriteria({ value, key }) {
    if ([1, 2, 3].includes(value)) {
      this.creatiaView = key;
    }
    if ([4, 8, 9].includes(value)) {
      this.boardcreatiaView = key;
    }
    if ([6, 7].includes(value)) {
      this.customFormcreatiaView = key;
    }
    if ([5].includes(value)) {
      this.systemCretiaView = key;
    }
    if ([10, 11, 12].includes(value)) {
      this.shiftcriteriaSelect = key;
    }
  }

  validateURLImage(str) {
    console.log('ssss', str)
    return generateImageUrl(str)
  }
}
