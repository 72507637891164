
<div>
    <div>
        <button class="drop-toggle btn flat" (click)="showDropDown=!showDropDown" >
            <span *ngIf="checkedList.length<=0">Select</span>
            <span *ngIf="checkedList.length>0">{{checkedList[0].value}} +{{checkedList.length -1}}</span>
            <i class="fa fa-angle-down"></i>
        </button>
        <div class="drop-show" *ngIf="showDropDown">
          <input type = "search" [(ngModel)]="searchvalue">
            <label *ngFor="let a of options|filter:{ value: searchvalue }">
                <input type="checkbox" [(ngModel)]="a.selected"
                    (change)="getSelectedValue(a.selected,a)" />
                <span>{{a.value}}</span>
            </label>
        </div>
    </div>
</div>
