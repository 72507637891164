import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompanySetupService } from 'src/app/users/service/company-setup/company-setup.service';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-leavemng-skillset',
  templateUrl: './leavemng-skillset.component.html',
  styleUrls: ['./leavemng-skillset.component.scss'],
})
export class LeavemngSkillsetComponent implements OnInit {
  leaveMngForm: FormGroup;
  setupSkillSetForm: FormGroup;
  weekSwap = [];
  adminCutOff = [];
  updateObj: {};
  Id: any;
  show: boolean;

  constructor(
    private formbuilder: FormBuilder,
    private companySetupService: CompanySetupService,
    public toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.getLeaveAndSetupDetails();

    for (let i = 0; i <= 20; i++) {
      this.weekSwap.push(i.toString());
    }
    for (let i = -10; i <= 10; i++) {
      this.adminCutOff.push(i.toString());
    }
  }
  getLeaveAndSetupDetails() {
    const sub = this.companySetupService
      .getcompanySetting()
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe(
        (res) => {
          this.Id = res.data._id;
          console.log('sdfjn', res);
          this.leaveMngForm.patchValue({
            blockLeave: res.data.opsGroup.blockLeaveConfiguration,
            leaveApp: res.data.opsGroup.leaveAdjust,
            weekSwaping: res.data.opsGroup.swapMinimumWeek,
            staffActual: res.data.opsGroup.isStaffCanCancel,
            staffPlan: res.data.opsGroup.isStaffCanCancelPlan,
            staffCutOff: res.data.opsGroup.staffCutOffTime,
            adminActual: res.data.opsGroup.isAdminCanCancel,
            adminActualChange: res.data.opsGroup.isadminCanChange,
            adminCutOff: res.data.opsGroup.adminCutOffTime,
            adminPlan: res.data.opsGroup.isAdminCanCancelPlan,
            adminPlanChange: res.data.opsGroup.isAdminCanChangePlan,
          });
          this.setupSkillSetForm.patchValue({
            tier: res.data.opsGroup.tierType,
          });

          if(res.data.opsGroup.isStaffCanCancelPlan == true || res.data.opsGroup.isStaffCanCancel == true ){
            this.show = true;
          }else if(res.data.opsGroup.isStaffCanCancelPlan == false && res.data.opsGroup.isStaffCanCancel == false ){
            this.show = false;
          }
        },
        (error) => {}
      );
  }
  initForm() {
    this.leaveMngForm = this.formbuilder.group({
      blockLeave: [false, Validators.required],
      leaveApp: [false, Validators.required],
      weekSwaping: [false, Validators.required],
      staffPlan: [false, Validators.required],
      staffActual: [false, Validators.required],
      staffCutOff: [false, Validators.required],
      adminPlan: [false, Validators.required],
      adminActual: [false, Validators.required],
      adminCutOff: [false, Validators.required],
      adminPlanChange: [false, Validators.required],
      adminActualChange: [false, Validators.required],
    });

    this.setupSkillSetForm = this.formbuilder.group({
      tier: ['', Validators.required],
    });
  }

  handleUpdate() {
    this.updateObj =  {
      opsGroup:{
        adminCutOffTime: parseInt(this.leaveMngForm.get('adminCutOff').value),
        blockLeaveConfiguration: this.leaveMngForm.get('blockLeave').value,
        isAdminCanCancel: this.leaveMngForm.get('adminActual').value,
        isAdminCanCancelPlan: this.leaveMngForm.get('adminPlan').value,
        isAdminCanChangePlan: this.leaveMngForm.get('adminPlanChange').value,
        isStaffCanCancel: this.leaveMngForm.get('staffActual').value,
        isStaffCanCancelPlan: this.leaveMngForm.get('staffPlan').value,
        isadminCanChange: this.leaveMngForm.get('adminActualChange').value,
        leaveAdjust: false,
        staffCutOffTime: parseInt(this.leaveMngForm.get('staffCutOff').value),
        swapMinimumWeek: parseInt(this.leaveMngForm.get('weekSwaping').value),
        tierType: this.setupSkillSetForm.get('tier').value,

      },
      pageId:this.Id

    };

    console.log('object update', this.updateObj);

    this.companySetupService
      .updateCompanySettings(this.updateObj)
      .subscribe(
        (res) => {
          this.toastr.success('Updated Successfully');
        },
        (error) => {
          console.log(error);
        }
      );
  }

  changeToggle(e) {
    if (e == true) {
      this.show = true;
      console.log('showvalue', this.show);
    } else {
      this.show = false;
      console.log('showvalue', this.show);
    }
  }
  changeTierToggle(){
    Swal.fire('Change of skillset tier setup will automatically remove all existing skillsets assigned to staff.')

  }
}
