import { MapsAPILoader } from '@agm/core';
import { MouseEvent } from '@agm/core';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-map-setup',
  templateUrl: './add-map-setup.component.html',
  styleUrls: ['./add-map-setup.component.scss'],
})
export class AddMapSetupComponent implements OnInit {
  @Input() isMap: boolean;
  @Input() isDropdown: boolean;
  @Input() mapLocations: Array<string>;
  @Input() buSettingObj: any;
  @Output() geoLocationEvent = new EventEmitter<any>();
  @Output() handleCheckInEnable = new EventEmitter<any>();
  @Output() handleProximityEnable = new EventEmitter<any>();
  @Output() handleRepLoc = new EventEmitter<any>();
  addRepLoc: FormGroup;
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;
  @ViewChild('search', { static: true }) searchElementRef: ElementRef;

  reportingLocationRadius = [
    { name: 50, id: 50 },
    { name: 100, id: 100 },
    { name: 150, id: 150 },
    { name: 200, id: 200 },
    { name: 250, id: 250 },
    { name: 300, id: 300 },
    { name: 350, id: 350 },
    { name: 400, id: 400 },
    { name: 450, id: 450 },
    { name: 500, id: 500 },
  ];
  constructor(
    protected formBuilder: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private toastService: ToastrService
  ) {}

  passGeoLocation(): void {
    this.geoLocationEvent.emit(this.mapLocations);
  }

  ngOnInit(): void {
    this.setCurrentLocation();

    this.initForm();
    this.updateCheckIn();

    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();

      let autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement
      );
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          this.address = place.formatted_address;

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 15;
        });
      });
    });
  }

  initForm() {
    this.addRepLoc = this.formBuilder.group({
      geoReportingLocation: [null],
      isCheckInEnabled: [null],
      isProximityEnabled: [null],
      reportingLocationRadius: [null],
    });
    this.addRepLoc.patchValue({
      reportingLocationRadius: this.buSettingObj?.reportingLocationRadius ?? 0,
      isCheckInEnabled: this.buSettingObj?.isCheckInEnabled ?? false,
      isProximityEnabled: this.buSettingObj?.isProximityEnabled,
    });
  }

  ngOnChanges(changes) {
    this.buSettingObj.isProximityEnabled =
      changes.buSettingObj?.isProximityEnabled?.currentValue
        ?.isProximityEnabled ?? this.buSettingObj?.isProximityEnabled;
    this.buSettingObj.isCheckInEnabled =
      changes.buSettingObj?.isCheckInEnabled?.currentValue?.isCheckInEnabled ??
      this.buSettingObj?.isCheckInEnabled;
    this.mapLocations = changes.mapLocations?.currentValue ?? this.mapLocations;
    this.isMap = changes.isMap?.currentValue ?? this.isMap;
    this.buSettingObj.reportingLocationRadius =
      changes.buSettingObj?.reportingLocationRadius?.currentValue
        ?.reportingLocationRadius ?? this.buSettingObj?.reportingLocationRadius;
    this.isDropdown = changes.isDropdown?.currentValue ?? this.isDropdown;
    this.initForm();

    this.addRepLoc.patchValue({
      reportingLocationRadius: this.buSettingObj?.reportingLocationRadius ?? 0,
      isCheckInEnabled: this.buSettingObj?.isCheckInEnabled ?? 'false',
      isProximityEnabled: this.buSettingObj?.isProximityEnabled ?? 'false',
    });
  }

  changeLocationType(type) {
    if (type === 'dropdown') {
      this.isMap = false;
      this.isDropdown = true;
    } else if (type === 'map') {
      this.isMap = true;
      this.isDropdown = false;
    } else {
      this.isMap = true;
      this.isDropdown = true;
    }
  }

  updateCheckIn() {
    this.addRepLoc.patchValue({
      isCheckInEnabled: this.buSettingObj.isCheckInEnabled,
    });
  }

  changeCheckinEnable(value) {
    this.buSettingObj.isCheckInEnabled = value;
    this.handleCheckInEnable.emit(this.buSettingObj.isCheckInEnabled);
  }

  changeProximityEnable(value) {
    this.buSettingObj.isProximityEnabled = value;
    this.handleProximityEnable.emit(this.buSettingObj.isProximityEnabled);
  }

  changeReportingLocationRadius(reportingLoc: any) {
    this.buSettingObj.reportingLocationRadius = reportingLoc.id;
    this.handleRepLoc.emit(this.buSettingObj.reportingLocationRadius);
  }

  markerDragEnd($event: MouseEvent) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 15;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.zoom = 15;
            this.address = results[0].formatted_address;
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }
      }
    );
  }

  addAddress() {
    if (this.mapLocations == undefined || !this.mapLocations) {
      this.mapLocations = [];
    }

    if (this.mapLocations.findIndex((i) => i == this.address) === -1) {
      this.mapLocations.push(this.address);
      this.passGeoLocation();
    } else {
      this.toastService.warning('Already Exists');
    }
  }

  onChooseLocation(event) {
    this.longitude = event.coords.lng;
    this.latitude = event.coords.lat;
  }

  clearMapLocationLabel(i) {
    //this.buRepoLocationArr.splice(i, 1);
    this.mapLocations.splice(i, 1);
    // console.log("buRepoLocationArr", this.buRepoLocationArr);
    // console.log("locationslabels", this.locationslabels);
  }
}
