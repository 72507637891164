import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, Subscription, of } from 'rxjs';
import {
  map,
  debounceTime,
  distinctUntilChanged,
  mergeMap,
  delay,
  finalize,
} from 'rxjs/operators';
import {
  SortableHeader,
  SortEvent,
} from 'src/app/shared/directive/sortable.directive';
import { PaginatedTable } from 'src/app/core/model/pagination.model';
import { StorageService } from 'src/app/core/service/storage.service';

import { BussinessUnitService } from 'src/app/users/service/bussiness-unit/bussiness-unit.service';
import { ToastrService } from 'ngx-toastr';
import { tableParam } from 'src/app/core/model/table-param.model';
import { TableService } from 'src/app/core/service/table.service';

@Component({
  selector: 'bussiness-unit-overview',
  templateUrl: './bussiness-unit-overview.component.html',
  styleUrls: ['./bussiness-unit-overview.component.scss'],
})
export class BussinessUnitOverviewComponent implements OnInit {

  isBuUnitEdited: boolean = false;
  sectionId: string;
  subSectionId: string;
  isTableLoading: boolean = false;
  locAccTableColumn=['sectionId.departmentId.companyId.name','sectionId.departmentId.name','sectionId.name','name','status','_id'];
  tableQueryParams=tableParam;
  numberOfSkeletonRows: number = 4;
  skeletonData = [{
    tier1: '',
    tier2: '',
    tier3: '',
    status: '',
    action: '',
  }]
  bussinessUnitEditForm: FormGroup;
  public BussinessunitsearchInput = new Subject<KeyboardEvent>();
  public DepartmentsearchInput = new Subject<KeyboardEvent>();
  public SectionsearchInput = new Subject<KeyboardEvent>();
  public SubSectionsearchInput = new Subject<KeyboardEvent>();
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  companyName: string;
  bussinesUnitPTable: { sortWith: string; sortBy: string; data: any[]; page: number; total: number; limit: number; search: string; };
  /// bussiness unit over view table config
  onSortBussinessUnit({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.bussinesUnitPTable.sortBy = direction;
    this.bussinesUnitPTable.sortWith = column;
    direction && this.loadBussinessUnit();


  }

  onPageChangeBussinessUnit(page: number) {
    this.bussinesUnitPTable.page = page;
    this.tableQueryParams.start= +page > 1 ? (page-1) * this.bussinesUnitPTable.limit: 0;
    this.loadBussinessUnit();
  }
  onLimitChangeBussinessUnit() {
    this.tableQueryParams.start=0;
    this.loadBussinessUnit();
  }

  private BussinessunitSearchSubscription: Subscription;

  constructor(
    private buUnit: BussinessUnitService,
    public toastService: ToastrService,
    private storageService: StorageService,
    private formBuilder: FormBuilder,
    private tableService:TableService
  ) {
    // this.config.notFoundText = 'Custom not found';
    this.bussinesUnitPTable = {
      sortWith: 'createdAt',
      sortBy: 'desc',
      data: [],
      page: 1,
      total: 0,
      limit: 10,
      search: '',
    };
    this.companyName = this.storageService.getByKey('companyId');

    this.BussinessunitSearchSubscription = this.BussinessunitsearchInput.pipe(
      map((event) => {
        const target = event.target as HTMLTextAreaElement;
        return target.value;
      }),
      debounceTime(500),
      distinctUntilChanged(),
      mergeMap((search) => of(search).pipe(delay(500)))
    ).subscribe((input) => {
      this.bussinesUnitPTable.page = 1;
      this.tableQueryParams.search.value = input;
      this.loadBussinessUnit();
    });
  }

  ngOnInit(): void {
    this.tableQueryParams.draw=0;
    this.loadBussinessUnit();
    this.bussinessUnitEditForm = this.formBuilder.group({
      status: [1, [Validators.required]],
      name: ['', [Validators.required]],
      buUnit: [''],
      sectionDeptComIdName:[],
      sectionDeptName:[],
      sectionIdName:[]
    });
  }


  loadBussinessUnit() {
    // this.bussinesUnitPTable.isLoading = true;
    // const { data, total, ...rest } = this.bussinesUnitPTable;
    let tableCol:any = this.tableService.initializeTableParamsColums(this.locAccTableColumn, [0],[0]) || [];
    this.tableQueryParams.columns=tableCol;
    this.tableQueryParams.draw++;
    this.tableQueryParams.length=this.bussinesUnitPTable.limit;
    this.isTableLoading = true;
    const subscribe = this.buUnit
      .getBussinesUnit(this.tableQueryParams)
      .pipe(
        finalize(() => {
          // this.bussinesUnitPTable.isLoading = false;
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const { recordsTotal, businessUnitList } = response;
          this.bussinesUnitPTable.data = businessUnitList;
          this.bussinesUnitPTable.total = recordsTotal;
          this.isTableLoading = false;
        },
        (error) => {
          this.bussinesUnitPTable.data = [];
          this.bussinesUnitPTable.total = 0;
          this.isTableLoading = false;
          this.toastService.error('No list found');
        }
      );
  }

  onEditBuUnit(item) {
    this.isBuUnitEdited = true;
    this.bussinessUnitEditForm.patchValue({
      status: item.status,
      buUnit:   item.sectionId.departmentId.companyId.name + ">" +
      item.sectionId.departmentId.name + ">" + item.sectionId.name +
      ">" + item.name,
      name: item.name,
      sectionDeptComIdName:item.sectionId.departmentId.companyId.name,
      sectionDeptName:item.sectionId.departmentId.name,
      sectionIdName:item.sectionId.name

    });
    this.sectionId = item?.sectionId?._id;
    this.subSectionId = item?._id;
  }

  onUpdateBuUnit() {
    const data = {

        "businessUnitId":this.subSectionId,
      "sectionId_departmentId_companyId_name":this.bussinessUnitEditForm.get('sectionDeptComIdName').value,
      "sectionId_departmentId_name":this.bussinessUnitEditForm.get('sectionDeptName').value,
      "sectionId_name":this.bussinessUnitEditForm.get('sectionIdName').value,
      "name":this.bussinessUnitEditForm.get('name').value
      ,"status":this.bussinessUnitEditForm.get('status').value

    };
    this.buUnit
      .updateBusinessUnitDetails(data)
      .subscribe(
        (res) => {
          this.toastService.success('Business Unit Updated');
          this.loadBussinessUnit();
          this.onClearBuUnitEdit();
         },
        (error: HttpErrorResponse) => {
          this.toastService.success('Something is wrong');
        }
      );
  }
  onClearBuUnitEdit() {
    this.isBuUnitEdited = false;
    this.bussinessUnitEditForm.reset();
    this.sectionId = null;
    this.subSectionId = null;
  }
  ngOnDestroy(): void {
    this.BussinessunitSearchSubscription &&
      this.BussinessunitSearchSubscription.unsubscribe();
  }
}
