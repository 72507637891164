<div class="row">
  <div class="col-md-12">
    <div class="card">
      <h5 class="card-header bg-theme">DASHBOARD PARAMETERS</h5>
      <div class="card-body">
        <form [formGroup]="dashParaForm">
          <fieldset class="form-group">
            <div class="row">
              <legend class="col-form-label col-sm-2 pt-0 font-weight-500">
                Select
              </legend>
              <div class="col-sm-10">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="buOps"
                    value="true"
                    formControlName="buOps"
                  />
                  <label class="form-check-label"> Business Unit </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="buOps"
                    value="false"
                    formControlName="buOps"
                  />
                  <label class="form-check-label"> Ops Group </label>
                </div>
              </div>
            </div>
          </fieldset>

          <div
            *ngIf="
              dashParaForm.get('buOps').value == 'false';
              then thenBlock;
              else elseBlock
            "
          ></div>
          <ng-template #thenBlock
            ><div class="form-row">
              <div class="form-group col-md-6">
                <label>Select Ops Group</label>
                <ng-select
                  [items]="opsList"
                  class="custom margin"
                  bindLabel="name"
                  placeholder="Select Ops Group"
                  appendTo="body"
                  [multiple]="false"
                  [searchable]="true"
                  [clearable]="true"
                  [closeOnSelect]="true"
                  (change)="getOpsTeam()"
                  formControlName="opsGrpSelect"
                >
                </ng-select>
              </div>
              <div class="form-group col-md-4">
                <label class="font-weight-500">Select Ops Team</label>
                <ng-select
                  [items]="this.dashParaForm.controls.opsGrpSelect.value?.team"
                  class="custom margin"
                  bindLabel="name"
                  placeholder="Select Ops Team"
                  appendTo="body"
                  [multiple]="false"
                  [searchable]="true"
                  [clearable]="true"
                  [closeOnSelect]="true"
                  formControlName="opsTeamSelect"
                >
                </ng-select>
              </div>
            </div>
          </ng-template>
          <ng-template #elseBlock
            ><div class="form-group row">
              <label class="col-sm-2 col-form-label font-weight-500"
                >Select Business Unit</label
              >
              <div class="col-8">
                <ng-select
                  [items]="buList"
                  class="custom margin"
                  [loader]="buLoader"
                  bindLabel="orgName"
                  placeholder="Select/Search Business Unit"
                  appendTo="body"
                  [multiple]="false"
                  [searchable]="true"
                  [clearable]="true"
                  [closeOnSelect]="true"
                  formControlName="buSelect"
                >
                </ng-select>
                <div *ngIf="buLoader" class="text-danger">
                  Please wait data is loading...
                </div>
              </div>
            </div>
          </ng-template>

          <fieldset class="form-group">
            <div class="row">
              <legend class="col-form-label col-sm-2 pt-0 font-weight-500">
                Select Year
              </legend>
              <div class="col-sm-10">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="yearRadio"
                    (click)="setYearRadio(yearArr[0])"
                    [value]="yearArr[0]"
                    formControlName="yearRadio"
                  />
                  <label class="form-check-label"> {{ yearArr[0] }} </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="yearRadio"
                    (click)="setYearRadio(yearArr[1])"
                    [value]="yearArr[1]"
                    formControlName="yearRadio"
                  />
                  <label class="form-check-label"> {{ yearArr[1] }} </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="yearRadio"
                    (click)="setYearRadio(yearArr[2])"
                    [value]="yearArr[2]"
                    formControlName="yearRadio"
                  />
                  <label class="form-check-label"> {{ yearArr[2] }} </label>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
      <div class="card-footer">
        <div class="d-flex">
          <button type="button" class="btn btn-danger">Clear</button>
          <button
            type="submit"
            class="btn btn-success ml-2"
            [disabled]="dashParaForm.invalid || leaveCalenderLoader"
            (click)="
              leavePlannerUserByDate();
              getLeavecalender();
              viewLeaveAndUser();
              getstaffUsers()
            "
          >
            View
            <span
              *ngIf="leaveCalenderLoader"
              class="spinner-border spinner-border-sm ml-1"
              role="status"
              aria-hidden="true"
            ></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="viewclicked">
  <div class="row">
    <div class="col-md-12">
      <div class="card my-2">
        <div class="card-header bg-theme">LEAVE OVERVIEW</div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-3">
              <div class="btn-group">
                <div
                  class="btn btn-info"
                  mwlCalendarPreviousView
                  [view]="view"
                  [(viewDate)]="viewDate"
                  (viewDateChange)="closeOpenMonthViewDay()"
                >
                  Previous
                </div>
                <div
                  class="btn btn-outline-info"
                  mwlCalendarToday
                  [(viewDate)]="viewDate"
                >
                  Today
                </div>
                <div
                  class="btn btn-info"
                  mwlCalendarNextView
                  [view]="view"
                  [(viewDate)]="viewDate"
                  (viewDateChange)="closeOpenMonthViewDay()"
                >
                  Next
                </div>
              </div>
            </div>
            <div class="col-md-6 text-center">
              <h5 class="bg-theme p-2 text-center">
                {{ viewDate | calendarDate: view + "ViewTitle":locale }}
              </h5>
              <mwl-calendar-month-view
                [viewDate]="viewDate"
                [events]="events"
                [refresh]="refresh"
                (dayClicked)="dayClicked($event.day); selectedD($event.day)"
                (beforeViewRender)="beforeMonthViewRender($event)"
              >
              </mwl-calendar-month-view>
            </div>

            <div class="col-md-3 text-right">
              <button
                type="button"
                class="btn btn-info"
                (click)="open(content)"
                *ngIf="viewAccess || manageAccess"
              >
                Manage Leave
              </button>
              <button class="btn btn-info ml-1" (click)="open(export)">
                Export Leave
              </button>
            </div>
          </div>
        </div>
        <!-- <div class="card-footer">

      </div> -->
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="card my-2">
        <div class="card-header bg-theme">LEAVE DETAILS</div>
        <div class="card-body">
          <div class="row">
            <div class="col-2">
              <div class="card h-100 border-theme">
                <div class="card-body">
                  <h6 class="card-title mb-3">Selected Date</h6>
                  <div class="d-flex text-center">
                    <p class="text-center m-0">
                      <span class="h4">{{ selectedDate }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4 pl-md-0">
              <div class="card h-100 border-theme">
                <div class="card-body">
                  <h6 class="card-title mb-3">Quota</h6>
                  <div class="d-flex text-center justify-content-between">
                    <p class="text-center m-0 text-muted">
                      Quota <br />
                      <span class="text-dark h4">{{ this.quotaData }}</span>
                    </p>
                    <p class="text-center m-0 text-muted">
                      Used Quota <br />
                      <span class="text-dark h4">{{ quotaUsedData }}</span>
                    </p>
                    <p class="text-center m-0 text-muted">
                      Balance Quota <br />
                      <span class="text-dark h4">{{ quotaBalanceData }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 pl-md-0">
              <div class="card h-100 border-theme">
                <div class="card-body">
                  <h6 class="card-title mb-3">Leaves</h6>
                  <div class="d-flex text-center">
                    <ng-container *ngFor="let leave of leaveTypeData">
                      <p
                        class="text-center my-0 mx-2 text-muted"
                        *ngIf="leave.total != 0"
                      >
                        {{ leave.name }}<br />
                        <span class="text-dark h4">{{ leave.total }}</span>
                      </p>
                    </ng-container>
                    <ng-container>
                      <p
                        class="text-center m-0 text-muted"
                        *ngIf="this.noLeave.length == 0"
                      >
                        No Leave for this day
                      </p>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-8">
              <div class="table-responsive border-theme rounded">
                <table class="table table-hover m-0">
                  <thead class="theme">
                    <tr>
                      <th scope="col">STAFF NAME</th>
                      <th scope="col">STAFF ID</th>
                      <th scope="col">LEAVE START</th>
                      <th scope="col">LEAVE END</th>
                      <th scope="col">DAYS</th>
                      <th scope="col">LEAVE TYPE</th>
                      <th scope="col">LEAVE PLAN STATUS</th>
                      <th scope="col">LEAVE APPLICATION STATUS</th>
                      <th scope="col">SWAP LOG</th>
                      <th scope="col">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let history of leavesCal; let i = index"
                      [class.selected]="i === highlightIndex"
                    >
                      <td>{{ history.userId.name }}</td>
                      <td>{{ history.userId.staffId }}</td>
                      <td>{{ history.startDate | date: "dd-MM-yyyy" }}</td>
                      <td>{{ history.endDate | date: "dd-MM-yyyy" }}</td>
                      <td>{{ history.totalDay }}</td>
                      <td>{{ history.leaveTypeId.name }}</td>
                      <td>
                        {{
                          history.submittedFrom == 1
                            ? "Apply Leave"
                            : history.submittedFrom == 2
                            ? "Leave Request"
                            : history.submittedFrom == 3
                            ? "Allocated"
                            : "Bid Successful"
                        }}
                      </td>
                      <td>
                        {{
                          history.status == 0
                            ? "Pending Approval"
                            : history.status == 1
                            ? "Approved"
                            : history.status == 2
                            ? "Rejected"
                            : history.status == 5
                            ? "Cancelled"
                            : history.status == 3
                            ? "-"
                            : "-"
                        }}
                      </td>
                      <td>
                        <li
                          class="tooltips"
                          (click)="
                            gotToSwapLog(history.swapLogId, swapLogsModal)
                          "
                          *ngIf="history?.swapLogId.length > 0"
                        >
                          <i
                            class="fa fa-info-circle"
                            style="float: right; margin-top: 5px"
                          >
                          </i>
                          <span class="tooltiptext">Swap Logs</span>
                        </li>
                      </td>
                      <td>
                        <button
                          class="btn btn-info btn-sm"
                          (click)="viewUser(history, i)"
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-md-4 pl-md-0" *ngIf="viewUserDet">
              <div class="d-flex flex-column">
                <div class="card">
                  <div class="card-header bg-theme text-center">
                    USER DETAILS
                  </div>
                  <div class="card-body">
                    <div class="d-flex flex-column">
                      <div class="d-flex align-items-center">
                        <div class="profile-img mr-3">

                          <ng-container  *ngIf="viewUserDet">
                            <img crossorigin="anonymous"
                            src="{{
                              viewUserDet?.userId?.profilePicture
                              ? viewUserDet?.userId?.profilePicture
                                : './assets/images/user1.png'
                            }}"
                            alt="{{
                              viewUserDet?.userId?.profilePicture
                                ? 'Profile Picture'
                                : 'No Profile Picture'
                            }}"
                            class="img-fluid rounded"
                          />
                          </ng-container>

                        </div>
                        <div class="flex-fill">
                          <h5 class="card-title m-0">
                            {{ viewUserDet?.userId?.name }}
                          </h5>
                          <p class="card-text">
                            {{ viewUserDet?.userId?.staffId }}
                          </p>
                        </div>
                      </div>
                      <div class="my-4">
                        <ul class="list-group">
                          <li
                            class="list-group-item d-flex justify-content-between align-items-center"
                          >
                            <span class="font-weight-500">Phone</span>
                            <span class="ml-3 text-right">{{
                              viewUserDet?.userId?.contactNumber
                            }}</span>
                          </li>
                          <li
                            class="list-group-item d-flex justify-content-between align-items-center"
                          >
                            <span class="font-weight-500">Email</span>
                            <span class="ml-3 text-right">{{
                              viewUserDet?.userId?.email
                            }}</span>
                          </li>
                          <li
                            class="list-group-item d-flex justify-content-between align-items-center"
                          >
                            <span class="font-weight-500">Business Unit</span>
                            <span class="ml-3 text-right">{{
                              viewUserDet?.userId?.parentBussinessUnitId?.orgName
                            }}</span>
                          </li>
                          <li
                            class="list-group-item d-flex justify-content-between align-items-center"
                          >
                            <span class="font-weight-500">Ops Group</span>
                            <span class="ml-3 text-right">
                              <!-- {{dashParaForm.controls.buOps.value}}{{viewUserDet?.opsGroupName}} -->
                               {{ dashParaForm.controls.buOps.value == 'true' ?
                                viewUserDet?.opsGroup?.name +
                                  " > " +
                                  viewUserDet?.opsTeam
                              : viewUserDet?.opsGroupName + " > "+ viewUserDet?.opsTeam }}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card mt-3">
                  <div class="card-header bg-theme text-center">
                    LEAVE DETAILS
                  </div>
                  <div class="card-body">
                    <form>
                      <div class="form-group">
                        <label class="font-weight-500">Leave Type</label>
                        <input
                          type="text"
                          class="form-control"
                          value="{{ viewUserDet?.leaveTypeId.name }}"
                        />
                      </div>
                      <div class="form-group">
                        <label class="font-weight-500">Leave Start Date</label>
                        <input
                          type="text"
                          class="form-control"
                          value="{{
                            viewUserDet?.startDate | date: 'dd-MM-yyyy'
                          }}"
                        />
                      </div>
                      <div class="form-group">
                        <label class="font-weight-500">Leave End Date</label>
                        <input
                          type="text"
                          class="form-control"
                          value="{{
                            viewUserDet?.endDate | date: 'dd-MM-yyyy'
                          }}"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #content let-modal>
  <div *ngIf="!showLog">
    <div class="bg-theme modal-header rounded-0">
      <h5 class="modal-title text-uppercase">Manage Leave</h5>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="mb-2">
        <label class="font-weight-500">Select Staff ID / Name </label>
        <ng-select
          [items]="staffList"
          class="custom margin"
          bindLabel="staffName"
          placeholder="Select Staff"
          appendTo="body"
          [multiple]="false"
          [searchable]="true"
          [clearable]="true"
          [closeOnSelect]="true"
          (change)="handleStaffSelect()"
          [(ngModel)]="opsStaffSelect"
          [ngModelOptions]="{ standalone: true }"
        >
        </ng-select>
      </div>
      <button
        type="submit"
        class="btn btn-success mb-2"
        (click)="seeLogsButton()"
        [disabled]="opsStaffSelect == null"
      >
        See Logs
      </button>

      <ng-container *ngIf="opsStaffSelect">
        <div class="card my-3">
          <h6 class="card-header bg-theme">USER DETAILS</h6>
          <div class="card-body">
            <div class="d-flex flex-column" *ngIf="this.opsStaffSelect">
              <div class="align-items-center d-flex mb-3">
                <div class="mr-3 profile-img rounded">
                  <img crossorigin="anonymous" src="{{opsStaffSelect?.profilePicture ? validateURLImage(opsStaffSelect.profilePicture) :'./assets/images/user1.png' }}"
                  class="img-fluid rounded">
                </div>
                <div class="flex-fill">
                  <h5 class="card-title m-0">
                    {{ this.opsStaffSelect?.name }}
                  </h5>
                  <p class="card-text">
                    {{ this.opsStaffSelect?.staffId }}
                  </p>
                </div>
              </div>
              <ul class="list-group">
                <li
                  class="list-group-item d-flex justify-content-between align-items-center"
                >
                  <span class="font-weight-500">Phone</span>
                  <span class="ml-3 text-right">{{
                    this.opsStaffSelect?.contactNumber
                  }}</span>
                </li>
                <li
                  class="list-group-item d-flex justify-content-between align-items-center"
                >
                  <span class="font-weight-500">Email</span>
                  <span class="ml-3 text-right">{{
                    this.opsStaffSelect?.email
                  }}</span>
                </li>
                <li
                  class="list-group-item d-flex justify-content-between align-items-center"
                >
                  <span class="font-weight-500">Business Unit</span>
                  <span class="ml-3 text-right">{{
                    this.opsStaffSelect.parentBussinessUnitId?.orgName
                  }}</span>
                </li>
                <li
                  class="list-group-item d-flex justify-content-between align-items-center"
                >
                  <span class="font-weight-500">Ops Group</span>
                  <span class="ml-3 text-right">
                    {{ this.opsStaffSelect.opsGroupName }}
                    {{ " > " + this.opsStaffSelect.opsTeam }}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="card my-3">
          <div class="card-header bg-theme d-flex justify-content-between">
            <h6 class="align-self-center m-0 text-uppercase">Leave History</h6>
            <div *ngIf="annualLeave">
              <span>Annual Leave: </span>
              <span class="ml-2">Quota {{annualLeave?.total}}</span>
              <span class="ml-2">Balance {{annualLeave?.planQuota}}</span>
              <span class="ml-2">Taken {{annualLeave?.total - annualLeave?.quota}}</span>
            </div>
          </div>
          <div class="card-body table-responsive rounded p-0">
            <ngx-skeleton-loader
              *ngIf="leaveHistoryLoader"
              count="5"
              [theme]="{
                width: 'calc(100% - 20px)',
                'border-radius': '6px',
                height: '44px',
                margin: '10px',
                display: 'block'
              }"
            ></ngx-skeleton-loader>
            <table class="table table-hover m-0" *ngIf="!leaveHistoryLoader">
              <thead class="fontBold">
                <tr>
                  <th scope="col">LEAVE START</th>
                  <th scope="col">LEAVE END</th>
                  <th scope="col">DAYS</th>
                  <th scope="col">LEAVE TYPE</th>
                  <th scope="col">LEAVE PLAN STATUS</th>
                  <th scope="col">LEAVE APPLICATION STATUS</th>
                  <th scope="col" *ngIf="!viewAccess">ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of leaveHistory">
                  <td>{{ data.startDate | date: "dd-MM-yyyy" }}</td>
                  <td>{{ data.endDate | date: "dd-MM-yyyy" }}</td>
                  <td>{{ data.totalDay }}</td>
                  <td>{{ data.leaveTypeId?.name }}</td>
                  <td>
                    {{
                      data.submittedFrom == 1
                        ? "Apply Leave"
                        : data.submittedFrom == 2
                        ? "Leave Request"
                        : data.submittedFrom == 3
                        ? "Allocated"
                        : "Bid Successful"
                    }}
                  </td>
                  <td>
                    {{
                      data.status == 0
                        ? "Pending Approval"
                        : data.status == 1
                        ? "Approved"
                        : data.status == 2
                        ? "Rejected"
                        : data.status == 5
                        ? "Cancelled"
                        : data.status == 3
                        ? "-"
                        : "-"
                    }}
                  </td>
                  <td *ngIf="!viewAccess">
                    <div class="d-flex" *ngIf="setup">
                      <button
                        class="btn btn-info btn-sm"
                        *ngIf="data.canChangePlanShow"
                        [disabled]="data.canChangePlanDisable"
                        (click)="changeAllocateModal(changeModal, data)"
                      >
                        Change
                      </button>
                      <button
                        class="btn btn-info btn-sm"
                        *ngIf="data.canChangeActualShow"
                        [disabled]="data.canChangeActualDisable"
                        (click)="changeAllocateModal(changeModal, data)"
                      >
                        Change
                      </button>

                      <button
                        class="btn btn-danger btn-sm ml-1"
                        *ngIf="data.canCancelPlanShow"
                        [disabled]="data.canCancelPlanDisable"
                        (click)="remove(data)"
                      >
                        Cancel
                      </button>
                      <button
                        class="btn btn-danger btn-sm ml-1"
                        *ngIf="data.canCancelActualShow"
                        [disabled]="data.canCancelActualDisable"
                        (click)="remove(data)"
                      >
                        Cancel
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="card my-3" *ngIf="!viewAccess">
          <h6 class="card-header bg-theme text-uppercase">
            Allocation of Leave
          </h6>
          <div class="card-body">
            <form>
              <fieldset class="form-group">
                <div class="row" *ngIf="staffLeaveType">
                  <legend class="col-form-label col-sm-4 pt-0 font-weight-500">
                    Select Leave Type
                  </legend>
                  <div class="col-sm-8">
                    <div
                      class="form-check"
                      *ngFor="let staff of staffLeaveType; let index = i"
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        name="gridRadios"
                        value="{{ i }}"
                        id="{{ i }}"
                        (change)="handleLeaveType(staff)"
                      />
                      <label class="form-check-label" for="{{ i }}">
                        {{ staff.leaveTypeName }}
                      </label>
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset class="form-group" *ngIf="isLeaveSwap">
                <div class="row d-flex">
                  <legend class="col-form-label col-sm-4 pt-0 font-weight-500">
                    Is Leave Swappable?
                  </legend>
                  <div class="col-sm-8">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="gridRadios1"
                        id="swap1"
                        [value]="1"
                        [(ngModel)]="swapRadio"
                      />
                      <label class="form-check-label" for="swap1"> Yes </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="gridRadios1"
                        id="swap2"
                        [value]="2"
                        [(ngModel)]="swapRadio"
                      />
                      <label class="form-check-label" for="swap2"> No </label>
                    </div>
                  </div>
                </div>
              </fieldset>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="inputEmail4" class="font-weight-500">From</label>
                  <input
                    type="text"
                    class="form-control"
                    id="inputEmail4"
                    placeholder="Select date"
                    [(ngModel)]="fromDate"
                    [minDate]="{ year: yearRadio, month: 1, day: 1 }"
                    ngbDatepicker
                    #d="ngbDatepicker"
                    (click)="d.toggle()"
                    (ngModelChange)="onFromDateChange($event)"
                    (dateSelect)="getStartDate($event)"
                    [ngModelOptions]="{ standalone: true }"
                    autocomplete="off"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label for="inputPassword4" class="font-weight-500">To</label>
                  <input
                    type="text"
                    class="form-control"
                    id="inputPassword4"
                    placeholder="Select date"
                    [(ngModel)]="toDate"
                    [minDate]="allocationMinDate"
                    ngbDatepicker
                    #d1="ngbDatepicker"
                    (click)="d1.toggle()"
                    (dateSelect)="getEndDate($event)"
                    [ngModelOptions]="{ standalone: true }"
                    autocomplete="off"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="card-footer">
            <button
              type="button"
              class="btn btn-success"
              [disabled]="allocateLoader"
              (click)="handleAllocate()"
            >
              Allocate
              <span
                *ngIf="allocateLoader"
                class="spinner-border spinner-border-sm ml-1"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-dark"
        (click)="modal.close('Save click')"
      >
        Close
      </button>
    </div>
  </div>
  <div class="modal-body" *ngIf="showLog">
    <div class="d-flex justify-content-end mt-2 mb-3">
      <button
        type="button"
        class="btn btn-success"
        (click)="this.showLog = false"
      >
        Back
      </button>
    </div>
    <div class="d-flex justify-content-between mt-2 mb-3">
      <!-- (change)="onLimitChangeBuShiftSetUp()"
      [(ngModel)]="buShiftSetUpTable.limit" -->
      <select class="custom-select" name="pageSize" style="width: auto">
        <option [ngValue]="10">10</option>
        <option [ngValue]="20">20</option>
        <option [ngValue]="30">30</option>
        <option [ngValue]="40">40</option>
        <option [ngValue]="50">50</option>
      </select>
      <div class="form-group form-inline mb-0">
        <!-- <span >Please wait...</span> -->
        <!--
          *ngIf="buShiftSetUpTable.isLoading"
          (keyup)="buShiftSetUpSearchInput.next($event)"
        [(ngModel)]="buShiftSetUpTable.search" -->
        <input
          class="form-control ml-2"
          name="searchTerm"
          placeholder="Search here"
          type="search"
        />
      </div>
    </div>
    <div class="card my-3">
      <div class="card-header bg-theme text-uppercase">Leave Planner Logs</div>
      <div class="card-body table-responsive p-0">
        <ngx-skeleton-loader
          *ngIf="logLoader"
          count="5"
          [theme]="{
            width: 'calc(100% - 20px)',
            'border-radius': '6px',
            height: '44px',
            margin: '10px 0',
            display: 'block'
          }"
        ></ngx-skeleton-loader>
        <table class="table table-striped" *ngIf="!logLoader">
          <thead>
            <tr>
              <th scope="col">DATE</th>
              <th scope="col">Action By User</th>
              <th scope="col">Description</th>
              <th scope="col">Old Leave Date</th>
              <th scope="col">Leave Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let logs of seeLogsData">
              <td>{{ logs.createdAt | date: "dd-MM-yyyy hh:mm" }}</td>
              <td>
                {{
                  logs.isChangeDate
                    ? logs.changedBy?.changeBy?.name
                    : logs.status == 5
                    ? logs.cancelledBy?.name
                    : logs.status == 3
                    ? logs.allocatedBy?.name
                    : "-"
                }}
              </td>
              <td>
                Leave
                {{
                  logs.status == 0
                    ? "Pending"
                    : logs.status == 1
                    ? "Approved to"
                    : logs.status == 2
                    ? "Rejected to"
                    : logs.status == 3 && !logs.isChangeDate
                    ? "Allocated to"
                    : logs.isChangeDate
                    ? "Changed to"
                    : logs.status == 5
                    ? "Cancelled to"
                    : "Balloted"
                }}
                {{ logs.startDate | date: "dd-MM-yyyy" }} to
                {{ logs.endDate | date: "dd-MM-yyyy" }}
              </td>
              <td>
                {{ logs.changedBy?.oldData?.startDate | date: "dd-MM-yyyy" }}
                {{ logs.changedBy ? "To" : "--" }}
                {{ logs.changedBy?.oldData?.endDate | date: "dd-MM-yyyy" }}
              </td>
              <td>
                {{
                  logs.status == 0
                    ? "Pending"
                    : logs.status == 1
                    ? "Approved"
                    : logs.status == 2
                    ? "Rejected"
                    : logs.status == 3 && !logs.isChangeDate
                    ? "Allocated"
                    : logs.isChangeDate
                    ? "Changed"
                    : logs.status == 5
                    ? "Cancelled"
                    : "Balloted"
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-footer">
        <div class="d-flex justify-content-between">
          <p class="align-self-center m-0 text-muted">
            Total Records
            <b class="text-dark">{{ seeLogsData?.length }}</b>
          </p>
          <!-- <ngb-pagination> </ngb-pagination> -->
          <!-- (pageChange)=""
          [(page)]=""
          [collectionSize]=""
          [maxSize]=""
          [pageSize]="" -->
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #export let-modal>
  <div class="bg-theme modal-header rounded-0">
    <h5 class="modal-title text-uppercase">EXPORT LEAVES</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-row">
      <div class="form-group col-md-6">
        <label class="font-weight-500">Select Ops Group</label>
        <ng-select
          [items]="opsList"
          class="custom margin"
          bindLabel="name"
          placeholder="Select Ops Group"
          appendTo="body"
          [multiple]="false"
          [searchable]="true"
          [clearable]="true"
          [closeOnSelect]="true"
          (change)="getExportTeam()"
          [(ngModel)]="opsGrpSelect"
        >
        </ng-select>
      </div>
      <div class="form-group col-md-6">
        <label class="font-weight-500">Select Ops Team</label>
        <ng-select
          [items]="teamListExport"
          class="custom margin"
          bindLabel="name"
          placeholder="Select Ops Team"
          appendTo="body"
          [multiple]="false"
          [searchable]="true"
          [clearable]="true"
          [closeOnSelect]="true"
          [(ngModel)]="opsTeamSelect"
        >
        </ng-select>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-6">
        <label class="font-weight-500">Select start date</label>
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            id="inputEmail4"
            placeholder="Select date"
            [(ngModel)]="fromDateExport"
            [minDate]="{ year: yearRadio, month: 1, day: 1 }"
            ngbDatepicker
            #d5="ngbDatepicker"
            (click)="d5.toggle()"
            autocomplete="off"
          />
        </div>
      </div>
      <div class="col-md-6">
        <label class="font-weight-500">Select end date</label>
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            id="inputEmail4"
            placeholder="Select date"
            [(ngModel)]="toDateExport"
            [minDate]="{ year: yearRadio, month: 1, day: 1 }"
            ngbDatepicker
            #d6="ngbDatepicker"
            (click)="d6.toggle()"
            autocomplete="off"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('Closed')"
    >
      Close
    </button>
    <button
      type="button"
      class="btn btn-success"
      (click)="exportButton()"
      [disabled]="exportLoader"
    >
      Export
      <span
        *ngIf="exportLoader"
        class="spinner-border spinner-border-sm ml-1"
        role="status"
        aria-hidden="true"
      ></span>
    </button>
  </div>
</ng-template>
<ng-template #changeModal let-modal>
  <div class="modal-header bg-theme">
    <h5 class="modal-title text-uppercase">Change Leave</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h4 class="h4 d-flex justify-content-between">
      <span
        >{{ this.displayDate }} <span class="text-muted"> to </span>
        {{ this.displayDateEnd }}</span
      >
      <div>
        <span class="badge badge-pill badge-primary mr-2 py-2">
          {{
            this.submittedFromValue == 1
              ? "Apply Leave"
              : this.submittedFromValue == 2
              ? "Leave Request"
              : this.submittedFromValue == 3
              ? "Allocated"
              : "Bid Successful"
          }}</span
        >
        <span class="badge badge-pill badge-warning py-2">{{
          this.swapValue == 1
            ? "Swappable"
            : this.swapValue == 2
            ? "Not Swappable"
            : this.swapValue == 3
            ? ""
            : ""
        }}</span>
      </div>
    </h4>
    <!-- <h5 class=""></h5> -->

    <div class="row mb-3">
      <div class="col-md-12">
        <div class="form-group">
          <label class="font-weight-500">Period From</label>
          <div class="input-group">
           <input
              type="text"
              class="form-control"
              id="inputEmail4"
              placeholder="Select date"
              [(ngModel)]="changefromDate"
              [minDate]="{ year: yearRadio, month: 1, day: 1 }"
              ngbDatepicker
              #d3="ngbDatepicker"
              (click)="d3.toggle()"
              (dateSelect)="getChangeStartDate($event)"
              autocomplete="off"
            />
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label class="font-weight-500">Period To</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              id="inputPassword4"
              placeholder="Select date"
              [(ngModel)]="changetoDate"
              [minDate]="{ year: yearRadio, month: 1, day: 1 }"
              ngbDatepicker
              #d4="ngbDatepicker"
              (click)="d4.toggle()"
              (dateSelect)="getChangeEndDate($event)"
              autocomplete="off"
            />
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label class="font-weight-500"> Is Leave Swappable? </label><br />
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="gridRadios1"
              value="1"
              [(ngModel)]="swapRadioChange"
            />
            <label class="form-check-label" for="gridRadios1"> Yes </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="gridRadios1"
              value="2"
              [(ngModel)]="swapRadioChange"
            />
            <label class="form-check-label" for="gridRadios1"> No </label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.dismiss('Cross click')"
    >
      Close
    </button>
    <button
      type="button"
      class="btn btn-success"
      (click)="changeAllocate(); modal.dismiss('Cross click')"
    >
      Change
    </button>
  </div>
</ng-template>
<ng-template #swapLogsModal let-modal>
  <div class="card-body">
    <div class="modal-body">
      <div class="d-flex justify-content-end mt-2 mb-3">
        <button
          type="button"
          class="btn btn-success"
          (click)="modal.close('Save click')"
        >
          Back
        </button>
      </div>
      <div class="d-flex justify-content-between mt-2 mb-3">
        <!-- (change)="onLimitChangeBuShiftSetUp()"
        [(ngModel)]="buShiftSetUpTable.limit" -->
        <select class="custom-select" name="pageSize" style="width: auto">
          <option [ngValue]="10">10</option>
          <option [ngValue]="20">20</option>
          <option [ngValue]="30">30</option>
          <option [ngValue]="40">40</option>
          <option [ngValue]="50">50</option>
        </select>
        <div class="form-group form-inline mb-0">
          <!-- <span >Please wait...</span> -->
          <!--
            *ngIf="buShiftSetUpTable.isLoading"
            (keyup)="buShiftSetUpSearchInput.next($event)"
          [(ngModel)]="buShiftSetUpTable.search" -->
          <input
            class="form-control ml-2"
            name="searchTerm"
            placeholder="Search here"
            type="search"
          />
        </div>
      </div>
      <div class="card my-3 border-0">
        <div class="card-header bg-theme text-uppercase">Swap Logs</div>

        <div class="table-responsive">
          <table class="table table-hover border">
            <thead class="fontBold">
              <tr>
                <th scope="col">Request Submitted date</th>
                <th scope="col">Sender</th>
                <th scope="col">Receiver</th>
                <th scope="col">Description</th>
                <th scope="col">Swap Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let Swaplogs of swapLogData">
                <td>{{ Swaplogs.createdAt | date: "dd-MM-yyyy HH:mm" }}</td>
                <td>
                  {{ Swaplogs.fromUserId.name }} -
                  {{ Swaplogs.fromUserId.staffId }}
                </td>
                <td>
                  {{ Swaplogs.toUserId.name }} - {{ Swaplogs.toUserId.staffId }}
                </td>
                <td>
                  {{ Swaplogs.fromUserId.name }} wants to swap (
                  {{ Swaplogs.from.startDate | date: "dd-MM-yyyy" }} -
                  {{ Swaplogs.from.endDate | date: "dd-MM-yyyy" }} ) leave dates
                  with ( {{ Swaplogs.to.startDate | date: "dd-MM-yyyy" }} -
                  {{ Swaplogs.to.endDate | date: "dd-MM-yyyy" }} ) leave dates .
                </td>
                <td>
                  {{
                    Swaplogs.status == 0
                      ? "Pending"
                      : Swaplogs.status == 1
                      ? "Accepted"
                      : Swaplogs.status == 2
                      ? "Rejected"
                      : ""
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="d-flex justify-content-between">
          <p>Total Records</p>
          <ngb-pagination> </ngb-pagination>
          <!-- (pageChange)=""
          [(page)]=""
          [collectionSize]=""
          [maxSize]=""
          [pageSize]="" -->
        </div>
      </div>
    </div>
  </div>
</ng-template>
