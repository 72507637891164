<div class="card my-3 w-100">
  <div class="card-header flexi-header p-4">
    <h3 style="margin-top: 10px">CREATE USER CUSTOM FIELDS</h3>
    <button type="button" class="btn btn-success" aria-label="Close" (click)="addDepartment()">
      Create custom field
    </button>
  </div>
  <div class="card-body">
    <div class="card table-card">
      <div class="card-body flexi-body">
        <div class="flex-column">
          <div class="d-flex justify-content-between mt-2 mb-3">
            <select class="custom-select" style="width: auto" name="pageSize" (change)="onLimitChangeCustomFieldt()"
              [(ngModel)]="CustomFieldPTable.limit">
              <option [ngValue]="10">10</option>
              <option [ngValue]="20">20</option>
              <option [ngValue]="30">30</option>
              <option [ngValue]="40">40</option>
              <option [ngValue]="50">50</option>
            </select>
            <div class="form-group form-inline mb-0">
              <input class="form-control ml-2" type="search" name="searchTerm"
                (keyup)="CustomFieldsearchInput.next($event)" placeholder="Search here"
                [(ngModel)]="CustomFieldPTable.search" />
            </div>
          </div>
          <div class="table-responsive">
            <table class="table" *ngIf="!isTableLoading">
              <thead>
                <tr class="p-4">
                  <th scope="col" sortable="Tier 2" (sort)="onSortCustomField($event)">
                    FIELD NAME
                  </th>
                  <th scope="col" sortable="Tier 3" (sort)="onSortCustomField($event)">
                    FIELD TYPE
                  </th>

                  <th scope="col" sortable="Tier 4" (sort)="onSortCustomField($event)">
                    FIELD VALUE
                  </th>
                  <th scope="col" sortable="Status" (sort)="onSortCustomField($event)">
                    EDITABLE
                  </th>
                  <th scope="col">ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="hideDefaultFields">
                  <td>
                    <div class="pb-4">Name</div>
                  </td>

                  <td>Free Text</td>

                  <td></td>

                  <td></td>

                  <td></td>
                </tr>
                <tr *ngIf="hideDefaultFields">
                  <td>
                    <div class="pb-4">Staff Id</div>
                  </td>

                  <td>Free Text</td>

                  <td></td>

                  <td></td>

                  <td></td>
                </tr>
                <tr *ngIf="hideDefaultFields">
                  <td>
                    <div class="pb-4">Appointment</div>
                  </td>
                  <td>Dropdown</td>

                  <td></td>

                  <td></td>

                  <td></td>
                </tr>
                <tr *ngIf="hideDefaultFields">
                  <td>
                    <div class="pb-4">Email</div>
                  </td>
                  <td>Free Text</td>

                  <td></td>

                  <td></td>

                  <td></td>
                </tr>

                <tr *ngFor="let item of CustomFieldPTable.data; let index = index">
                  <td>
                    {{ item.fieldName }}
                  </td>

                  <td>{{ item.type }}</td>

                  <td>
                    <div *ngIf="item.options.length > 0; else templateName">
                      <ul *ngFor="let item of item.options">
                        <li>{{ item.value }}</li>
                      </ul>
                    </div>
                    <ng-template #templateName>
                      <ul>
                        <li>-</li>
                      </ul>
                    </ng-template>
                  </td>

                  <td>{{ item.editable ? "Yes" : "No" }}</td>

                  <td>
                    <button class="btn btn-add p-2 m-1" style="width: 40px" (click)="editCustomField(item,index)">
                      <i class="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-clear p-2 m-1" style="width: 40px" (click)="deleteCustomField(item._id)">
                      <i class="fa fa-trash fa-lg" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <app-skliton-loading-table [numberOfCols]="5" [isTableLoading]="isTableLoading"></app-skliton-loading-table>
        </div>
      </div>
      <div class="card-footer">
        <div class="d-flex justify-content-between">
          <p>Total records {{ CustomFieldPTable.total }}</p>
          <!-- <ngb-pagination [collectionSize]="CustomFieldPTable.total" [(page)]="CustomFieldPTable.page" [maxSize]="5"
            (pageChange)="onPageChangeCustomField($event)" [pageSize]="CustomFieldPTable.limit">
          </ngb-pagination> -->
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <form [formGroup]="form">
  <dynamic-form-builder [formGroup]="form" [fields]="getFields() " (onSubmit)="getValues($event)"></dynamic-form-builder>
  </form>
  {{form?.value|json}} -->

<ng-template #AddDepartment let-c="close" let-d="dismiss">
  <div>
    <div class="modal-content">
      <div class="modal-body" style="padding: 10px; min-height: 100vh">
        <div>
          <div class="card my-3 w-100">
            <div class="card-header flexi-header pt-4">
              <h3>{{ isCustomFieldEdited ? "UPDATE FIELD" : "ADD FIELD" }}</h3>
              <button type="button" class="btn no-border" aria-label="Close" (click)="c('Cross click')">
                <i class="fa fa-times-circle fa-2x" aria-hidden="true"></i>
              </button>
            </div>
            <div class="card-body">
              <form [formGroup]="customFieldForm">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="font-weight-bold red-heading" for="exampleInputEmail1">Field name</label>

                      <input type="text" class="form-control" placeholder="Field Name" formControlName="fieldName"
                        maxlength="40" />
                      <!-- <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                        <small *ngIf="f.name.errors.required">
                          Role Name is required
                        </small>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="isCustomFieldEdited">
                  <div class="col-md-12">
                    <label class="font-weight-bold red-heading">Order</label>
                    <select class="dropdown-select w-100 form-control" formControlName="order">
                      <option [value]="" selected>Select Order</option>
                      <option *ngFor="let cnt of orderList" [ngValue]="cnt">
                        {{ cnt }}
                      </option>
                    </select>

                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-12">
                    <label class="font-weight-bold red-heading">Type</label>
                    <select class="dropdown-select w-100 form-control" formControlName="type">
                      <option value="" [attr.selected]="true" disabled>
                        Select Type
                      </option>
                      <option *ngFor="let cnt of type" [ngValue]="cnt">
                        {{ cnt }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row mt-3" *ngIf="customFieldForm.get('type').value === 'dropdown'">
                  <div class="col-md-8">
                    <form [formGroup]="dropDownForm">
                      <div class="form-group">
                        <label class="font-weight-bold" for="exampleInputEmail1">Dropdown values</label>

                        <input type="text" class="form-control" placeholder="DropDown values" formControlName="value" />
                        <button class="btn btn-add mt-2" (click)="addOptions()" [disabled]="!dropDownForm.valid">
                          {{ isDropDownEdited ? "Update" : "Add" }}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="row" *ngIf="customFieldForm.get('type').value === 'dropdown'">
                  <div class="col-md-8" *ngIf="OptionieldsControl.controls.length != 0">
                    <table class="table table-borderless">
                      <tbody>
                         <tr formArrayName="options" *ngFor="
                            let field of OptionieldsControl.controls;
                            let index = index
                          ">
                          <td>

                             <span class="text-items" [formGroupName]="index">{{
                              field.get("value").value
                              }}</span>
                          </td>
                          <td>
                            <button class="btn btn-pri p-2" (click)="onEditDropDown(index, field.value)">
                              <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                            </button>
                            <button class="btn btn-clear p-2" (click)="deleteOption(index)">
                              <i class="fa fa-trash" aria-hidden="true"></i>
                            </button>
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="row p-3">
                  <div class="form-group pr-0">
                    <input type="checkbox" formControlName="editable" /> &emsp;
                    Editable by user
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <button class="btn btn-add btn-block p-1 m-2" [disabled]="isLoading"
                      [disabled]="!customFieldForm.valid" (click)="onSubmitCustomField()">
                      <i class="fa" [ngClass]="{
                          'fa-spin fa-spinner': isLoading,
                          '': !isLoading
                        }"></i>
                      {{ isCustomFieldEdited ? "Update" : "Add Field" }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
