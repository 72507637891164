import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NewsEventsService } from '../../../service/manage-news & events/news-events.service'
import { debounceTime, delay, distinctUntilChanged, finalize, map, mergeMap } from 'rxjs/operators';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, Subject, of } from 'rxjs';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-my-news-and-events',
  templateUrl: './my-news-and-events.component.html',
  styleUrls: ['./my-news-and-events.component.scss'],
  styles: [`
    .left-modal {
      position : 'relative',
      left : '0px'
    }
  `]
})
export class MyNewsAndEventsComponent implements OnInit {
  isLoading: boolean = false;
  viewMyNotification: FormGroup;
  isMyNewsAndEventsEnabled: boolean = false;
  myNewsAndNotificationID: string;
  channelDropDown: any[] = [];
  categoriesDropDown: any[] = [];
  newsDetailsData: any[] = [];
  singleNewsData: any[] = [];
  channel;
  category;
  query;
  data;
  postAuthor: any;
  imageLink: any;
  channelID: string;
  channelName: string;
  categoryName: string;
  updatedAt: string;
  authorName: string;
  organizerName: string;
  title: any;
  type: string;
  organizerAddress: string;
  numberOfPosts: number = 5;
  categoryID: string;
  postType: string = '';
  eventContent: any;
  // cardID: string;
  selectedIndex: number;
  onLoad: boolean = false;
  searchInput: string = ''
  baseUrl = environment.imageUrl;
  @ViewChild('AddSection', { static: true })
  AddSection: TemplateRef<any>;
  private newsSearchSubscription: Subscription;
  public newsAndEventssearchInput = new Subject<KeyboardEvent>();

  newsAndEventsPTable: { skip:number; sortWith: string; sortBy: string; data: any[]; page: number; total: number; limit: number; search: string; channelId: string; categoryId: string; };
  constructor(
    public modelService: NgbModal,
    private formBuilder: FormBuilder,
    private toastService: ToastrService,
    private myNewsAndEvents: NewsEventsService,
  ) { }

  ngOnInit(): void {
    this.newsAndEventsPTable = {
      sortWith: 'createdAt',
      sortBy: 'desc',
      data: [],
      page: 1,
      total: 0,
      limit: 10,
      search: '',
      channelId: '',
      categoryId:'',
      skip:0,
    };
    this.getChannels();
  this.onLoadNewsandEvents()

    this.newsSearchSubscription = this.newsAndEventssearchInput.pipe(
      map((event) => {
        const target = event.target as HTMLTextAreaElement;
        return target.value;
      }),
      debounceTime(500),
      distinctUntilChanged(),
      mergeMap((search) => of(search).pipe(delay(500)))
    ).subscribe((input) => {
      this.newsAndEventsPTable.page = 1;
      this.onLoadNewsandEvents()
    });
  }
  
  onPageChange(page: number): void {
    this.newsAndEventsPTable.page = page;
    this.newsAndEventsPTable.skip = (page - 1) * this.newsAndEventsPTable.limit;
    this.onLoadNewsandEvents();
  }
  // searchFunction(event) {
  //   this.searchInput = event;
  //   this.onClickView();
  // }

  loadMore() {
    this.newsAndEventsPTable.page =  this.newsAndEventsPTable.page + 1;
    this.newsAndEventsPTable.skip =  this.newsAndEventsPTable.skip + 10;
    this.onLoadNewsandEvents();
  }

  getBannerImage(imageLink) {
    return `${imageLink}`
  }

  getChannels() {
    const sub = this.myNewsAndEvents
      .getChannelForNewsAndEvents()
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe((res: any) => {
        this.channelDropDown = res.data;
      },
        (error) => {

        })
  }

  cancelPopup(){
    this.modelService.dismissAll();
  }

  addSection() {
    this.modelService.open(this.AddSection, {
      windowClass: 'modal-ui-fix right-align',
    });
  }

  getCategories(channel) {
    this.channelID = channel._id
    this.newsAndEventsPTable.channelId = channel._id
    const sub = this.myNewsAndEvents
      .getAllCategories(channel._id)

      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe((res: any) => {
        this.categoriesDropDown = res.data;
      });
      this.newsAndEventsPTable.categoryId = null;
  }

  onLoadNewsandEvents() {
    this.isLoading = true

    const { data, total, ...rest } = this.newsAndEventsPTable;

    const sub = this.myNewsAndEvents
      .getAllNewsEvents(rest)
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe((response: any) => {
        // this.newsAndEventsPTable.data = response.data.postList;
        let oldData = []
        if(response.data.postList.length > 0){
          oldData = this.newsAndEventsPTable.data;
          this.newsAndEventsPTable.data = [...response.data.postList]
        }
        else{
          this.newsAndEventsPTable.data = []
        }
        this.newsAndEventsPTable.total = response?.data?.total;
        this.getCardData(response.data.postList[0], 0)
        this.channelDropDown = response.data.channelList;
        this.isLoading = false
      },
        (error) => {
          this.isLoading = false

        }
      );
  }

  getCardData(channel, index) {
    this.selectedIndex = index;
    // this.cardID = channel._id;
    if(channel){
    this.imageLink = `${channel.content.image}`;
    this.channelName = channel.channelId.name;
    this.categoryName = channel.categoryId.name;
    this.updatedAt = channel.updatedAt;
    this.authorName = channel.authorId.name;
    this.organizerName = channel.eventDetails.organizerName;
    this.organizerAddress = channel.eventDetails.address;
    this.eventContent = channel.content.content;
    this.title = channel.teaser.title;
    this.type = channel.postType;
    }
  }

  goBack() {
    this.isLoading = false;
    this.addSection();
    setTimeout(() => {
      this.isMyNewsAndEventsEnabled = false
      const { _id } = this.viewMyNotification.get('bussinessUnit').value
      this.myNewsAndNotificationID = _id
    }, 0);
  }

  validateURLImage(str) {
    var img_Path = str;
    if (img_Path.indexOf("https://") == 0) {
      var new_url = new URL(str);
     var pathName = (new_url.pathname.startsWith('//')) ? `/${new_url.pathname.split("//")[1]}` : new_url.pathname;
      return `${this.baseUrl}${pathName}`;
    }
    if (str.startsWith('/')) return this.baseUrl + str;
    return `${this.baseUrl}/${str}`;
   }
}
