import { Component, Input, OnInit } from "@angular/core";
import * as moment from "moment";
import { finalize } from "rxjs/operators";
import { ShiftSchedulerService } from "src/app/users/service/operations/shift-scheduler.service";
import { getGmtOffSet } from 'src/app/core/utils/helper';

@Component({
  selector: "app-log-assign",
  templateUrl: "./log-assign.component.html",
  styleUrls: ["./log-assign.component.scss"],
})
export class LogAssignComponent implements OnInit {
  @Input() bu;
  isOverview: boolean = true;
  showShiftDetails: boolean = false;
  logAssign: any;
  logDetail: any;
  shiftDetail: any;
  BuName: any;
  assignLogLoader: boolean = true;
  currWeek: any = null;
  buWeekArr = [];
  weekCount = 0;
  currentWeekCount: any;
  weekRangeStartsAt: any;
  weekRangeEndsAt: any;
  gmtOffset: any;
  constructor(private logService: ShiftSchedulerService, private shiftService: ShiftSchedulerService) {}

  ngOnInit(): void {
    this.gmtOffset = getGmtOffSet();
    this.getWeeks();
  }

  changeOverview(value, item) {
    this.isOverview = value;
    this.logDetail = item;
    this.showShiftDetails = false;
  }

  changeShiftDetailsView(value, item) {
    this.showShiftDetails = value;
    this.shiftDetail = item;
  }

  logTableData() {
    this.assignLogLoader = true;
    const logPayload = {
      businessUnitId: this.bu._id,
      weekRangeStartsAt: this.weekRangeStartsAt,
      weekRangeEndsAt: this.weekRangeEndsAt,
    };

    const logTableSub = this.logService
      .getLogAssign(logPayload)
      .pipe(
        finalize(() => {
          this.assignLogLoader = false;
          logTableSub.unsubscribe();
        })
      )
      .subscribe((res) => {
        this.logAssign = res["data"];
        this.BuName = res['orgName']
      });
  }

  getWeeks() {
    const currDate = moment();
    this.weekRangeStartsAt = currDate.clone().startOf("isoWeek").format("MM-DD-YYYY") + ` 00:00:00 ${this.gmtOffset}`;
    this.weekRangeEndsAt = currDate.clone().endOf("isoWeek").format("MM-DD-YYYY") + ` 00:00:00 ${this.gmtOffset}`;
    this.shiftService.getWeeks(this.bu._id).subscribe((res) => {
      let getDate = moment().startOf('isoWeek').format("MM-DD-YYYY");
      const count = res['data'].noOfWeek
      this.currentWeekCount = count;
      if (count > 0) {
        for (var i = -count; i <= count; i++) {
          let dummyData = this.getWeekStartEndDate(getDate, 7 * (i))
          this.buWeekArr.push(dummyData);
        }
      }
      this.currWeek = this.buWeekArr[this.currentWeekCount];
    });
    this.logTableData();
  }

  getWeekStartEndDate = function (date, count) {
    let datesToReturn = {};
    let label = moment().startOf('isoWeek').add(count, 'days').format("MMM DD") + ' to ' + moment().startOf('isoWeek').add(count + 6, 'days').format("MMM DD")
    let value = moment().startOf('isoWeek').add(count, 'days').format("MM-DD-YYYY");
    datesToReturn = { label: label, value: value, key: count };
    return label;
  }

  changeWeek(str) {
    if (str == 'Nxt') {
      this.weekCount =
        this.weekCount < this.currentWeekCount
          ? this.weekCount + 1
          : this.currentWeekCount;
    } else if (str == 'Prev') {
      this.weekCount =
        this.weekCount == -Math.abs(this.currentWeekCount)
          ? this.weekCount
          : this.weekCount - 1;
    } else {
      this.weekCount = 0;
    }

    this.currWeek = this.buWeekArr[this.currentWeekCount + this.weekCount];

    let startDaystr = this.currWeek.slice(0, 6);
    let date = moment(startDaystr).year(moment().year()).format('MM-DD-YYYY');
    this.weekRangeStartsAt = date + ` 00:00:00 ${this.gmtOffset}`;
    let endDayString = moment(startDaystr)
      .year(moment().year())
      .add(6, 'days')
      .format('MM-DD-YYYY');
    this.weekRangeEndsAt = endDayString + ` 00:00:00 ${this.gmtOffset}`;
    this.logTableData();
  }
}
