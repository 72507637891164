<div class="terms-body">
    <div>
        <a class="back-button" (click)="backToLogin()">Back to Login</a>
        <div class="mt-5">
            <h3 class="main-header">Netroc Web and Mobile Application Terms of Use</h3>
            <h4 class="sub-header mt-3">1. Acceptance of Terms of Use</h4>
            <p class="terms-para">The following terms and conditions (these “Terms of Use”) govern your access to and
                use of our online and / <br> or mobile services, website, and software provided in connection with the
                Services.</p>

            <p class="terms-para">Please read these Terms of Use carefully before you start using the Service. By using
                our services, you accept <br> and agree to be bound by and abide to these Terms of Use.</p>
        </div>

        <div class="mt-3">
            <h4 class="sub-header mt-3">2. Ownership and Intellectual Property Rights</h4>
            <p class="terms-para">This Services is owned and operated by Netroc Pte Ltd. If you have any questions
                regarding this Terms of <br> Use, please contact us at info@net-roc.com.</p>

            <p class="terms-para">The Netroc name and all related product and service names, logos, designs, and slogans
                used within this <br> Services are trademarks of Netroc or its licensors that you may not use without
                Netroc
                prior written <br> permission. No right, title or interest in or to the Services is transferred to you.
                All
                rights not expressly granted <br> are reserved by Netroc. Any use of the Services not expressly
                permitted by
                these Terms of Use is a breach of <br> these Terms of Use and may violate copyright, trademark and other
                laws.</p>
        </div>

        <div class="mt-3">
            <h4 class="sub-header mt-3">3. Use of our Service</h4>
            <p class="terms-para">Subject to the terms of this Agreement, you are hereby granted a non-exclusive,
                non-transferable license to <br> use the Services solely as permitted by the features of the Services,
                which
                may vary by User. Netroc can <br> revoke the licenses granted or deny you future access of the Service
                or
                impose conditions on your future <br> access, including but not limited to requiring payment of
                subscription
                or license fees as a prerequisite for <br> future access.</p>
        </div>

        <div class="mt-3">
            <h4 class="sub-header mt-3">4. User Accounts</h4>
            <p class="terms-para">Your User account gives you access to the services and functionality that Netroc may
                establish and maintain <br> from time to time and in Netroc's sole discretion. Netroc may maintain
                different
                types of accounts for <br> different types of Users. If you open a Netroc account on behalf of a
                company,
                organization, or other entity,<br> then (a) "you" includes you and that entity, and (b) you represent
                and
                warrant that you are an authorized<br> representative of the entity with the authority to bind the
                entity to
                this Agreement, and that you agree to<br> this Agreement on the entity’s behalf. By connecting to Netroc
                with a third-party service, you give us<br> permission to access and use your information from that
                service
                as permitted by that service, and to store<br>your log-in credentials for that service.</p>

            <p class="terms-para">You acknowledge and agree that if you are opening a User account or otherwise using
                the Services as or on<br> behalf of an employer company, organization or other entity, your employees
                who
                open employee accounts<br> must and do separately agree to be bound by this Agreement.</p>

            <p class="terms-para">You may never use another User’s account without permission. When creating your
                account, you must<br> provide accurate and complete information, and you must keep this information up
                to
                date. You are solely<br> responsible for the activity that occurs on your account, and you must keep
                your
                account password secure.<br> We encourage you to use "strong" passwords (passwords that use a
                combination of
                upper and lower case<br> letters, numbers and symbols) with your account. You must notify Netroc
                immediately
                of any breach of<br> security or unauthorized use of your account. Netroc will not be liable for any<br>
                losses
                caused by any unauthorized use of your account.</p>
        </div>

        <div class="mt-3">
            <h4 class="sub-header mt-3">5. Restrictions on Use</h4>
            <p class="terms-para">You are not permitted to do any of the following while using the Services: (a) conduct
                or promote any illegal<br> activities; (b) upload, download, distribute or print anything that violates
                the
                Terms of Use or that may be<br> harmful to others; (c) attempt to reverse engineer or jeopardize the
                correct
                functioning of the Services; (d)<br> attempt to gain access to secured portions of the Services to which
                you
                do not possess access rights; (e)<br> upload, submit, or transmit any form of virus, worm, Trojan horse,
                or
                other malicious code; (f) use the<br> Services to generate unsolicited email advertisements or spam; (g)
                use
                any automatic or manual process to<br> harvest information from the Services; (h) impersonate another
                user;
                or (i) copy or distribute or attempt to<br> copy or distribute User Content not belonging to you.</p>

            <p class="terms-para">Links to Third-Party Sites. This Services may allow you to access other websites that
                are not under Netroc’s<br> control (“Third-Party Sites”). Any links to Third-Party Sites are provided as
                a
                convenience only. Netroc does<br> not endorse any Third-Party Sites and assumes no responsibility or
                liability for any material that may be<br> accessed on Third-Party Sites.</p>

            <p class="terms-para">Links from Third-Party Sites. Netroc prohibits unauthorized links to its Services and
                the framing of any<br> information contained on the Services or any portion of the Services and reserves
                the
                right to disable<br> unauthorized links at any time. Netroc has no responsibility or liability for any
                material that may contain links<br> to the Services.</p>
        </div>

        <div class="mt-3">
            <h4 class="sub-header mt-3">6. User Content</h4>
            <p class="terms-para">Some areas of the Service allow Users to post or provide content such as profile
                information, images,<br> comments, questions, and other content or information (any such materials a
                User
                submits, posts, displays,<br> or otherwise makes available on the Service is referred to as “User
                Content”).
                Netroc claims no ownership<br> rights over User Content created by you. The User Content you create
                remains
                yours; however, by providing<br> or sharing User Content through the Service, you agree to allow others
                to
                view, edit, and/or share your User<br> Content in accordance with your settings and this Agreement.</p>

            <p class="terms-para">You agree not to post User Content that: (i) may create a risk of harm, loss, physical
                or mental injury,<br> emotional distress, death, disability, disfigurement, or physical or mental
                illness to
                you, to any other person,<br> or to any animal; (ii) may create a risk of any other loss or damage to
                any
                person or property; (iii) seeks to<br> harm or exploit children by exposing them to inappropriate
                content,
                asking for personally identifiable details<br> or otherwise; (iv) may constitute or contribute to a
                crime or
                tort; (v) contains any information or content that<br> we deem to be unlawful, harmful, abusive,
                racially or
                ethnically offensive, defamatory, infringing, invasive of<br> personal privacy or publicity rights,
                harassing, humiliating to other people (publicly or otherwise), libellous,<br> threatening, profane,
                obscene, or otherwise objectionable; (vi) contains any information or content that is<br> illegal
                (including, without limitation, the disclosure of insider information under securities law or of
                another<br>
                party’s trade secrets); (vii) contains any information or content that you do not have a right to make
                available<br> under any law or under contractual or fiduciary relationships; (viii) contains any
                information
                or content that<br> you know is not correct and current or (ix) violates any company, employer or other
                applicable policy.</p>

            <p class="terms-para">You agree that any User Content that you post does not and will not violate
                third-party rights of any kind,<br> including without limitation any Intellectual Property Rights (as
                defined below), rights of privacy or other<br> rights of Netroc or any third party.</p>

            <p class="terms-para">Netroc reserves the right, but is not obligated, to reject and/or remove any User
                Content that Netroc<br> believes, in its sole discretion, violates any of these provisions or is
                otherwise
                unlawful, offensive, threatening,<br> libellous, defamatory, pornographic, obscene or otherwise
                objectionable.</p>

            <p class="terms-para">For the purposes of this Agreement, “Intellectual Property Rights” means all patent
                rights, copyright rights,<br> mask work rights, moral rights, rights of publicity, trademark, trade
                dress
                and service mark rights, goodwill,<br> trade secret rights and other intellectual property rights as may
                now
                exist or hereafter come into existence,<br> and all applications therefore and registrations, renewals
                and
                extensions thereof, under the laws of any state,<br> country, territory or other jurisdiction.</p>

            <p class="terms-para">Netroc takes no responsibility and assumes no liability for any User Content that you
                or any other User or<br> third party posts, sends, or otherwise makes available over the Service. You
                shall
                be solely responsible for<br> your User Content and the consequences of posting, publishing it, sharing
                it,
                or otherwise making it<br> available on the Service, and you agree that we are only acting as a passive
                conduit for your online<br> distribution and publication of your User Content. You understand and agree
                that
                you may be exposed to<br> User Content that is inaccurate, objectionable, inappropriate for children, or
                otherwise unsuited to your<br> purpose, and you agree that Netroc shall not be liable for any damages
                you
                allege to incur as a result of or<br> relating to any User Content.</p>

            <p class="terms-para">In addition to the foregoing, if you are an employer, as between you and Netroc, you
                are solely responsible<br> for any and all User Content you provide and/or cause to be provided to the
                Service, and the consequences<br> of providing, posting or transmitting such User Content, including
                responsibility for compliance with breach<br> notification laws. You agree that you will not use Netroc
                to
                collect, store, or process bank account<br> information, credit or debit card information or other
                similarly-sensitive personal information, and that the<br> User assumes all risk arising from use of any
                such sensitive information with the Service, including the risk of<br> any inadvertent disclosure or
                unauthorised access thereto.</p>
        </div>

        <div class="mt-3">
            <h4 class="sub-header mt-3">7. Security</h4>
            <p class="terms-para">Netroc utilizes physical, managerial, and technical safeguards to preserve the
                integrity and security of your<br> personal information and implement your privacy settings. However, we
                cannot guarantee that<br> unauthorized third parties will never be able to defeat our security measures
                or
                use your personal<br> information for improper purposes.</p>
        </div>

        <div class="mt-3">
            <h4 class="sub-header mt-3">8. Indemnification</h4>
            <p class="terms-para">You agree to defend, indemnify, and hold harmless Netroc, and our respective
                employees, contractors,<br> officers, directors, and agents from all liabilities, claims and expenses,
                including attorney’s fees that arise<br> from: (a) your use or misuse of the Service; (b) any violation
                of
                these Terms of Use; and (c) any of your User<br> Contributions.</p>
        </div>

        <div class="mt-3">
            <h4 class="sub-header mt-3">9. Disclaimer of Warranties</h4>
            <p class="terms-para">You understand that we cannot and do not guarantee or warrant that the Services or any
                Content available<br> for view or download (including User Contents) will be free of viruses or other
                destructive code. You are<br> responsible for implementing sufficient procedures and checkpoints to
                satisfy
                your particular requirements<br> for anti-virus protection and accuracy of data input and output, and
                for
                maintaining a means external to our<br> Service for any reconstruction of any lost data. Netroc will not
                be
                liable for any loss or damage caused by a<br> distributed denial-of-service attack, viruses or other
                technologically harmful material that may infect your<br> computer equipment, computer programs, data or
                other proprietary material due to your use of the services<br> or items obtained through the services or
                to
                your downloading of any material posted on it, or on any<br> website linked to it. Your use of the
                services,
                its content and any items obtained through the services is at<br> your own risk. The services, its
                content
                and any items obtained through the services are provided on an "As<br> Is" and "As Available" basis,
                without
                any warranties of any kind, either express or implied. Neither Netroc nor<br> anyone associated with
                Netroc
                represents or warrants that the services, its content or any items obtained<br> through the services
                will be
                accurate, reliable, error-free or uninterrupted, that defects will be corrected, that<br> our site or
                the
                server that makes it available are free of viruses or other harmful components or that the<br> service
                or
                any services or items obtained through the service will otherwise meet your needs or<br> expectations.
                Netroc hereby disclaims all warranties of any kind, whether express or implied, statutory or<br>
                otherwise,
                including but not limited to any warranties of merchantability, non-infringement and fitness for<br>
                particular purpose. The foregoing does not affect any warranties which cannot be excluded or limited
                under<br> applicable law.</p>

            <p class="terms-para">Subject to the terms of this agreement, Netroc shall use commercially reasonable
                efforts to make the<br> Services available to the Company.</p>
        </div>

        <div class="mt-3">
            <h4 class="sub-header mt-3">10. Limitation of Liability</h4>
            <p class="terms-para">In no event will Netroc, its affiliates or their licensors, service providers,
                employees, agents, officers or<br> directors be liable for damages of any kind, under any legal theory,
                arising out of or in connection with your<br> use, or inability to use, the service, any services linked
                to
                it, any content on the service or such other websites<br> or any services or items obtained through the
                services or such other websites, including any direct, indirect,<br> special, incidental, consequential
                or
                punitive damages, including but not limited to, personal injury, pain<br> and suffering, emotional
                distress,
                loss of revenue, loss of profits, loss of business or anticipated savings, loss of<br> use, loss of
                goodwill, loss of data, and whether caused by tort (including negligence), breach of contract or<br>
                otherwise, even if foreseeable. Any cause of action or claim you may have arising out of or relating to
                these<br> terms of use or the service must be commenced within 30 days after the cause of action
                accrues,
                otherwise,<br> such cause of action or claim is permanently barred. The foregoing does not affect any
                liability which cannot<br> be excluded or limited under applicable law.</p>
        </div>

        <div class="mt-3">
            <h4 class="sub-header mt-3">11. Right to Modify</h4>
            <p class="terms-para">Netroc reserves the right to modify these Terms of Use at any time, in its sole
                discretion. All changes are<br> effective immediately when Netroc posts them. Your continued use of the
                Service following the posting of<br> revised Terms of Use means you accept and agree to the changes. It
                is
                your responsibility to check this page<br> from time-to-time so you are aware of any changes, as they
                are
                binding on you.</p>
        </div>

        <div class="mt-3">
            <h4 class="sub-header mt-3">12. No Agency</h4>
            <p class="terms-para">You agree that no joint venture, partnership, employment, or agency relationship
                exists between you<br> and Netroc as a result of these Terms of Use or your use of or access to the
                Services.</p>
        </div>

        <div class="mt-3">
            <h4 class="sub-header mt-3">13. Enforceability</h4>
            <p class="terms-para">If any provisions of these Terms of Use are found to be invalid or unenforceable for
                any reason, such provision<br> must be construed to have been adjusted to the minimum extent necessary
                to
                cure such invalidity or<br> unenforceability, and the remainder will continue in full force and effect.
        </div>

        <div class="mt-3">
            <h4 class="sub-header mt-3">14. No Waiver</h4>
            <p class="terms-para">No waiver by Netroc under these Terms of Use will be valid or binding unless set forth
                in writing and duly<br> executed by Netroc. Any such waiver constitutes a waiver only with respect to
                the
                specific matter described<br> therein and in no way impairs the rights of Netroc in any other respect or
                at
                any other time.
        </div>

        <div class="mt-3">
            <h4 class="sub-header mt-3">15. Governing Law and Jurisdiction</h4>
            <p class="terms-para">This Agreement shall be governed and construed in accordance with the laws of
                Singapore.

            <p class="terms-para">Any dispute arising out of or in connection with this Agreement, including any
                question regarding its<br> existence, validity or termination, shall be referred to and finally resolved
                by
                arbitration in Singapore in<br> accordance with the Arbitration Rules of the Singapore International
                Arbitration Centre ("SIAC Rules") for the<br> time being in force, which rules are deemed to be
                incorporated
                by reference in this clause. The Tribunal shall<br> consist of one (1) arbitrator. The language of the
                arbitration shall be English.
        </div>
    </div>
</div>