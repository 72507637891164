<div class="card mb-3">
  <div class="card-header align-items-center bg-theme">
    <h5 class="m-0">PLANNED SHIFTS OVERVIEW</h5>
    <p class="m-0">Selected Business Unit : {{ bu?.orgName }}</p>
  </div>
  <div class="card-body">
    <div class="col-md-12 flexDisplay">
      <div class="red font-weight-500">{{ isHour ? "Hours" : "Shifts" }}</div>
      <div class="floatButtons">
        <button
          type="button"
          class="btn"
          [ngClass]="isHour ? 'bg-theme' : 'btn-outline-info'"
          (click)="changeHrAndNumber(true)"
        >
          Total Shift Hrs
        </button>
        <button
          type="button"
          class="btn ml-2"
          [ngClass]="isHour ? 'btn-outline-info' : 'bg-theme'"
          (click)="changeHrAndNumber(false)"
        >
          Total Number of Shifts
        </button>
      </div>
    </div>

    <div
      class="d-flex align-items-baseline justify-content-between"
      *ngIf="!hrAndShiftChartData"
    >
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          'border-radius': '6px',
          height: '300px',
          width: '45px',
          margin: '10px',
          display: 'block'
        }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          'border-radius': '6px',
          height: '500px',
          width: '45px',
          margin: '10px',
          display: 'block'
        }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          'border-radius': '6px',
          height: '400px',
          width: '45px',
          margin: '10px',
          display: 'block'
        }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          'border-radius': '6px',
          height: '300px',
          width: '45px',
          margin: '10px',
          display: 'block'
        }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          'border-radius': '6px',
          height: '450px',
          width: '45px',
          margin: '10px',
          display: 'block'
        }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          'border-radius': '6px',
          height: '330px',
          width: '45px',
          margin: '10px',
          display: 'block'
        }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          'border-radius': '6px',
          height: '280px',
          width: '45px',
          margin: '10px',
          display: 'block'
        }"
      ></ngx-skeleton-loader>
    </div>

    <div class="hrAndShiftChart" *ngIf="hrAndShiftChartData">
      <canvas
        baseChart
        [chartType]="'bar'"
        [datasets]="hrAndShiftChartData"
        [labels]="hrAndShiftChartLabels"
        [options]="hrAndShiftChartoptions"
        [legend]="false"
        [colors]="hrAndShiftChartColors"
      >
      </canvas>
    </div>
  </div>
</div>

<div class="card">
  <div class="card-header flexi-header header">
    <h3>CHOOSE FOR DAY VIEW</h3>
  </div>
  <div class="card-body">
    <div class="col-md-12 mb-2 p-0">
      <button
        *ngFor="let i of dayArr"
        class="dayButton"
        [class.bg-theme]="i === selectedDay"
        (click)="dayViewGraph(i)"
      >
        {{ i.d }}
      </button>
    </div>

    <div class="dayChart" *ngIf="graphLoader">
      <ngx-skeleton-loader
        count="5"
        [theme]="{
          'border-radius': '6px',
          height: '2px',
          margin: '100px 0',
          display: 'block'
        }"
      ></ngx-skeleton-loader>
    </div>

    <div class="dayChart" *ngIf="dayChartData">
      <canvas
        baseChart
        [chartType]="'line'"
        [datasets]="dayChartData"
        [labels]="yAxesLabel"
        [options]="dayChartOptions"
        [colors]="dayChartColorOption"
        [legend]="true"
      >
      </canvas>
    </div>
  </div>
</div>
