import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, of } from 'rxjs';
import { map, debounceTime, distinctUntilChanged, mergeMap, delay, finalize } from 'rxjs/operators';
import { SortableHeader, SortEvent } from 'src/app/shared/directive/sortable.directive';
import { GamificationService } from 'src/app/users/service/gamification/gamification.service';
import { BussinessUnitService } from 'src/app/users/service/bussiness-unit/bussiness-unit.service';
import * as fileSaver from 'file-saver';
import { tableParam } from 'src/app/core/model/table-param.model';
import { TableService } from 'src/app/core/service/table.service';

@Component({
  selector: 'app-Redemption-List',
  templateUrl: './Redemption-List.component.html',
  styleUrls: ['./Redemption-List.component.scss']
})
export class RedemptionListComponent implements OnInit {
  exportRedemption: FormGroup
  usersPTable;
  selectedUser:any=null;
  locAccTableColumn=['createdAt','title','publishEnd','status','_id'];
  viewtableQueryParams={...tableParam, start: 0};
  public userssearchInput = new Subject<KeyboardEvent>();
  isTableLoading: boolean = true;
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  bussinesUnitplanPTable: { sortBy: string; sortWith: string; data: any[]; page: number; limit: number; search: string; filter: { type: string; }; };
  planBuUnitDropDownLoading: boolean = false
  isLoading: boolean = false
  showRedemptionList: boolean = false;
  redemptionListID;
  // companyName: string;
  /// bussiness unit over view table config
  onSortUsers({ column, direction }: SortEvent, field) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.usersPTable.sortWith = !!direction ? field : 'createdAt';
    this.usersPTable.sortBy = !!direction ? direction : 'desc';
    this.loadUsers(this.usersPTable.page);
  }

  onPageChangeUsers(page: number) {
    this.usersPTable.page = page;
    this.loadUsers(this.usersPTable.page);
  }
  onLimitChangeUsers() {
    // this.loadUsers();
  }

  private usersSearchSubscription: Subscription;
  @Output() EditEvent = new EventEmitter<string>();
  constructor(
    private gamificationService: GamificationService,
    public toastService: ToastrService,
    private buUnit: BussinessUnitService,
    private formBuilder: FormBuilder

  ) {
    // this.config.notFoundText = 'Custom not found';
    this.usersPTable = {
      sortBy: 'desc',
      sortWith: 'createdAt',
      data: [],
      page: 1,
      limit: 10,
      search: '',

    };
    this.bussinesUnitplanPTable = {
      sortBy: 'desc',
      sortWith: 'createdBy',
      data: [],
      page: 1,
      limit: 8,
      search: '',
      filter: {
        type: 'plan',
      },
    };

    this.usersSearchSubscription = this.userssearchInput
      .pipe(
        map((event) => {
          const target = event.target as HTMLTextAreaElement;
          return target.value;
        }),
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap((search) => of(search).pipe(delay(500)))
      )
      .subscribe((input) => {
        this.usersPTable.page = 0;
        this.usersPTable.search.value = input;
        this.loadUsers(this.usersPTable.page);
      });
  }

  ngOnInit(): void {
    this.exportRedemption = this.formBuilder.group({
      bussinessUnit: [null, [Validators.required]],
    })
    this.loadBussinessPlanUnit()
    this.loadUsers( this.usersPTable.page);
  }
  onEditUser(data: any) {
    this.EditEvent.emit(data?._id);
    this.showRedemptionList = true;
    this.redemptionListID = data?._id;
    this.selectedUser=data;
  }

  goBack(){
    this.showRedemptionList = false;
  }

  addParentBussinentUnit(value) {
    this.exportRedemption.patchValue({
      bussinessUnit: {
        string: value.businessUnit,
        _id: value._id,
      },
    });
  }
  deleteparentBussinesUnit() {
    this.exportRedemption.patchValue({
      bussinessUnit: null
    });
  }

  onSeachPlanBuUnit(value) {

    this.bussinesUnitplanPTable.limit = 10;
    this.bussinesUnitplanPTable.search = value.term;

    this.loadBussinessPlanUnit();
  }

  loadMorePlasnBuUnitItems(value) {
    this.bussinesUnitplanPTable.limit = this.bussinesUnitplanPTable.limit + 10;

    this.loadBussinessPlanUnit();
  }

  onClearPlanBuUnitDropdown(value) {


    if (value === undefined) {
      this.exportRedemption.patchValue({
        bussinessUnit: null
      });
      // this.bussinesUnitplanPTable.search = '';
      this.bussinesUnitplanPTable.data = [];
      this.loadBussinessPlanUnit();


      // this.bussinesUnitplanPTable.data = [];
    }

  }
  loadBussinessPlanUnit() {
    this.planBuUnitDropDownLoading = true;

    // const { data, ...rest } = this.bussinesUnitplanPTable;
    const subscribe = this.buUnit
      .getBusinessUnitData()
      .pipe(
        finalize(() => {
          this.planBuUnitDropDownLoading = false;
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          // const { count, data } = response.data;
          this.bussinesUnitplanPTable.data = response?.data?.planBussinessUnitId;


        },
        (error) => {
          this.bussinesUnitplanPTable.data = [];

          this.toastService.error('No list found');
        }
      );
  }



  loadUsers(pageNumber) {
    this.isTableLoading = true;
    // const { data, total, isLoading, ...rest } = this.usersPTable
    const id=this.exportRedemption.get('bussinessUnit').value? this.exportRedemption.get('bussinessUnit').value: "";
    const subscribe = this.gamificationService
      .getRedemptionList(id, (pageNumber - 1) || 0, this.usersPTable)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          // const { count, data } = response.data;
          this.usersPTable.data = response?.data.rewards || [];
          this.usersPTable.total = response?.data?.recordsTotal || 0;
          this.isTableLoading = false;
        },
        (error) => {
          this.usersPTable.data = [];
          this.usersPTable.total = 0;
          this.isTableLoading = false;
          this.toastService.error('No list found');
        }
      );
  }

  onClickExport() {
    if (!this.exportRedemption.valid) {
      this.toastService.error('Please select Business unit');
      return
    }

    this.isLoading = true
    let d = new Date();
    const timeZone = d.getTimezoneOffset();
   this.gamificationService.getRedemptionExport(this.exportRedemption.get('bussinessUnit').value, timeZone).subscribe(({ data }) => {
      this.isLoading = false
      const filename = data['csvLink']
        .split('\\')
        .pop()
        .split('/')
        .pop();

      this.downloadCsv(filename);

    }, (error) => {
      this.isLoading = false
    })

  }
 // Converting json response to CSV file with third party npm fileSaver
 downloadCsv(n) {
  this.gamificationService.downloadRedemptionFile(n).subscribe((res) => {
    if (res) {
      fileSaver.saveAs(new Blob([res], { type: 'text/csv' }), n);
    }
  });
}

  ngOnDestroy(): void {
    this.usersSearchSubscription && this.usersSearchSubscription.unsubscribe();
  }

  changeBuUnit(event){
    this.usersPTable.page = 1
    this.loadUsers(this.usersPTable.page);
  }


}
