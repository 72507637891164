<div class="card mb-3">
  <div class="card-header align-items-center bg-theme">
    <h5 class="m-0">CREATE SHIFT</h5>
    <p class="m-0">Selected Business Unit : {{ bu.orgName }}</p>
  </div>
  <div class="card-body">
    <form [formGroup]="planOpenshiftForm">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label class="font-weight-500">Select Week<span class="field-mandatory mandatory_Star">*</span></label><br />
            <ng-select
              class="custom"
              formControlName="selectWeek"
              placeholder="Select Week"
              appendTo="body"
              [multiple]="false"
              [searchable]="true"
              [clearable]="true"
              [closeOnSelect]="true"
              (change)="emptyDayArr(); getStartEndDate()"

            >
            <ng-container *ngFor="let i of weekArr1">
              <ng-option [value]="i.label" >
                {{ i.label }}
              </ng-option>
            </ng-container>

            </ng-select>
          </div>
        </div>

        <div class="col">
          <div class="form-group row">
            <label class="align-self-end col-sm-4 font-weight-500"
              >Number of Shift<span class="field-mandatory mandatory_Star">*</span></label
            >
            <div class="col-sm-4">
              <label class="">Confirmed<span class="field-mandatory mandatory_Star">*</span></label><br />
              <input
                class="form-control"
                type="text"
                formControlName="confirmStaffNeed"
              />
            </div>
            <div
              class="col-sm-4"
              *ngIf="this.buShiftDetails?.standByShiftPermission"
            >
              <label class="">Standby<span class="field-mandatory mandatory_Star">*</span></label><br />
              <input
                class="form-control"
                type="text"
                formControlName="standByStaffNeed"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="form-group">
            <label class="font-weight-500">Shift Template</label><br />
            <ng-select
              class="custom"
              [items]="selectShiftTemp"
              bindLabel="name"
              formControlName="shiftTemplate"
              appendTo="body"
              [multiple]="false"
              [searchable]="true"
              [clearable]="true"
              [closeOnSelect]="true"
              (change)="prefillShiftTemplate()"
            >
            </ng-select>
          </div>
        </div>

        <div class="col">
          <div class="border rounded p-3">
            <div class="form-group row">
              <label class="align-self-end col-sm-4 font-weight-500"
                >Set Timing<span class="field-mandatory mandatory_Star">*</span></label
              >
              <div class="col-sm-4">
                <label class="">Start Time<span class="field-mandatory mandatory_Star">*</span></label><br />
                <ng-select
                  [items]="time"
                  class="custom"
                  bindLabel="name"
                  formControlName="setTimingStart"
                  appendTo="body"
                  [multiple]="false"
                  [searchable]="true"
                  [clearable]="true"
                  [closeOnSelect]="true"
                  (change)="addStartTime()"
                >
                </ng-select>
              </div>
              <div class="col-sm-4">
                <label class="">End Time<span class="field-mandatory mandatory_Star">*</span></label><br />
                <ng-select
                  [items]="time"
                  class="custom"
                  bindLabel="name"
                  formControlName="setTimingEnd"
                  appendTo="body"
                  [multiple]="false"
                  [searchable]="true"
                  [clearable]="true"
                  [closeOnSelect]="true"
                  (change)="addEndTime()"
                >
                </ng-select>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-4">
                <div class="form-check">
                  <input
                    (change)="setSplitShift($event)"
                    type="checkbox"
                    class="form-check-input"
                    formControlName="splitShift"
                    id="splitShift"
                    name="splitShift"
                  />
                  <label class="form-check-label">Split Shift</label>
                </div>
              </div>
              <ng-container *ngIf="isSplitShift">
                <div class="col-md-4">
                  <label class="">Start Time<span class="field-mandatory mandatory_Star">*</span></label><br />
                  <ng-select
                    [items]="time"
                    class="custom"
                    bindLabel="name"
                    formControlName="splitShiftStart"
                    appendTo="body"
                    [multiple]="false"
                    [searchable]="true"
                    [clearable]="true"
                    [closeOnSelect]="true"
                  >
                  </ng-select>
                </div>
                <div class="col-md-4">
                  <label class="">End Time<span class="field-mandatory mandatory_Star">*</span></label><br />
                  <ng-select
                    [items]="time"
                    class="custom"
                    bindLabel="name"
                    formControlName="splitShiftEnd"
                    appendTo="body"
                    [multiple]="false"
                    [searchable]="true"
                    [clearable]="true"
                    [closeOnSelect]="true"
                  >
                  </ng-select>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col">
          <div class="form-group border rounded p-3">
            <label class="font-weight-500">Select Day<span class="field-mandatory mandatory_Star">*</span></label>
            <div class="mb-2 mt-1">
              <button
                class="dayButton m-0"
                #btn1
                [disabled]="disableDayArr.includes('Mon')"
                (click)="btn1.classList.toggle('selected'); getSelectedWeek1()"
              >
                M
              </button>
              <button
                class="dayButton"
                #btn2
                [disabled]="disableDayArr.includes('Tue')"
                (click)="btn2.classList.toggle('selected'); getSelectedWeek2()"
              >
                T
              </button>
              <button
                class="dayButton"
                #btn3
                [disabled]="disableDayArr.includes('Wed')"
                (click)="btn3.classList.toggle('selected'); getSelectedWeek3()"
              >
                W
              </button>
              <button
                class="dayButton"
                #btn4
                [disabled]="disableDayArr.includes('Thu')"
                (click)="btn4.classList.toggle('selected'); getSelectedWeek4()"
              >
                T
              </button>
              <button
                class="dayButton"
                #btn5
                [disabled]="disableDayArr.includes('Fri')"
                (click)="btn5.classList.toggle('selected'); getSelectedWeek5()"
              >
                F
              </button>
              <button
                class="dayButton"
                #btn6
                [disabled]="disableDayArr.includes('Sat')"
                (click)="btn6.classList.toggle('selected'); getSelectedWeek6()"
              >
                S
              </button>
              <button
                class="dayButton"
                #btn7
                [disabled]="disableDayArr.includes('Sun')"
                (click)="btn7.classList.toggle('selected'); getSelectedWeek7()"
              >
                S
              </button>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="border rounded p-3">
            <div class="form-group row">
              <label class="align-self-end col-sm-4 font-weight-500"
                >Report to<span class="field-mandatory mandatory_Star">*</span></label
              >
              <div class="col-sm-8">
                <ng-select
                  [items]="buRepoLocation"
                  class="custom"
                  bindLabel="name"
                  formControlName="reportingLocation"
                  appendTo="body"
                  [multiple]="false"
                  [searchable]="true"
                  [clearable]="true"
                  [closeOnSelect]="true"
                >
                </ng-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="align-self-end col-sm-4 font-weight-500"
                >Enable Check-in<span class="field-mandatory mandatory_Star">*</span></label
              >
              <div class="col-sm-8">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="true"
                    id="yesCheckin"
                    formControlName="isCheckInEnabled"
                    name="isCheckInEnabled"
                    (click)="changeCheckinEnable(true)"
                  />
                  <label class="form-check-label">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="false"
                    id="noCheckin"
                    formControlName="isCheckInEnabled"
                    name="isCheckInEnabled"
                    (click)="changeCheckinEnable(false)"
                  />
                  <label class="form-check-label">No</label>
                </div>
              </div>
            </div>
            <div
              class="form-group row"
              *ngIf="
                this.planOpenshiftForm.get('isCheckInEnabled').value == 'true'
              "
            >
              <label class="align-self-end col-sm-4 font-weight-500"
                >Proximity Enabled</label
              >
              <div class="col-sm-8">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="true"
                    id="yesProx"
                    formControlName="isProximityEnabled"
                    name="isProximityEnabled"
                    (click)="changeProximityEnable(true)"
                  />
                  <label class="form-check-label">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="false"
                    id="noProx"
                    formControlName="isProximityEnabled"
                    name="isProximityEnabled"
                    (click)="changeProximityEnable(false)"
                  />
                  <label class="form-check-label">No</label>
                </div>
              </div>
            </div>
            <div
              class="form-group row"
              *ngIf="
                this.planOpenshiftForm.get('isProximityEnabled').value ==
                  'true' &&
                this.planOpenshiftForm.get('isCheckInEnabled').value == 'true'
              "
            >
              <label class="align-self-end col-sm-4 font-weight-500"
                >Proximity</label
              >
              <div class="col-sm-8">
                <ng-select
                  class="custom"
                  bindLabel="name"
                  formControlName="proximity"
                  name="proximity"
                  appendTo="body"
                  [multiple]="false"
                  [searchable]="true"
                  [clearable]="true"
                  [closeOnSelect]="true"
                  (change)="changeReportingLocationRadius($event)"
                >
                  <ng-option
                    [value]="item"
                    *ngFor="let item of reportingLocationRadius"
                    >{{ item.name }}
                  </ng-option>
                </ng-select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-3 align-self-end">
          <label>Select Skillsets <span class="field-mandatory mandatory_Star">*</span> </label>
          <ng-select
            [attr.disabled]="true"
            [items]="buSkillSet"
            class="custom"
            bindLabel="name"
            formControlName="mainSkillSets"
            placeholder="Skillsets"
            appendTo="body"
            [multiple]="false"
            [searchable]="true"
            [clearable]="true"
            [closeOnSelect]="true"
            (change)="onPlanSkillSetChange($event)"
          >
          </ng-select>
        </div>
        <div class="col-md-3 align-self-end" *ngIf="tier == 2">
          <label> Select Speciality <span class="field-mandatory mandatory_Star mandatory_Star">*</span></label>
          <ng-select
            [items]="buSkillspeciality"
            class="custom"
            bindLabel="name"
            formControlName="subSkillSets"
            placeholder="Speciality"
            appendTo="body"
            [multiple]="false"
            [searchable]="true"
            [closeOnSelect]="true"
            [clearable]="true"
          >
          </ng-select>
        </div>
        <div class="col-md-2 align-self-end">
          <button class="btn btn-danger btn-sm" (click)="clearSkills()">
            Clear
          </button>
          <button
            class="btn btn-success btn-sm ml-1"
            (click)="addUserSkillbutton()" [disabled]="tier == 2 ? !(planOpenshiftForm.get('mainSkillSets').value?.name && planOpenshiftForm.get('subSkillSets').value?.name) : !planOpenshiftForm.get('mainSkillSets').value?.name "
          >
            Add
          </button>
        </div>
        <div class="col-md-4 align-self-end">
          <ng-container *ngIf="this.skillLabels != null">
            <div
              class="labels mr-1 mb-1"
              *ngFor="let item of skillLabels; let i = index"
            >
              <span class="text"
                >{{ item[0] }} >
                <ng-container *ngIf="tier == 2"> {{ item[1] }}</ng-container>
              </span>
              <span
                class="material-icons bg-danger rounded btn-close"
                (click)="clearLabel(i)"
              >
                clear
              </span>
            </div>
          </ng-container>
        </div>
      </div>
    </form>
  </div>
  <div class="card-footer d-flex justify-content-center">
    <button
    [disabled]="planOpenshiftForm.invalid || !(!!skillLabels && skillLabels?.length) || !(!!dayDate && dayDate?.length)"
      class="btn btn-success"
      (click)="addShiftTemplate()"
    >
      Add Shifts As Draft
    </button>
    <button class="btn btn-danger ml-1" (click)="resetForm()">Clear</button>
  </div>
</div>

<div class="card">
  <h5 class="card-header bg-theme">PLAN SHIFT</h5>
  <div class="card-body p-0">
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col" sortable="Tier 1">Timing/Slot</th>
            <th scope="col" sortable="Tier 2">Skillsets</th>
            <th scope="col">Mon</th>
            <th scope="col">Tue</th>
            <th scope="col">Wed</th>
            <th scope="col">Thu</th>
            <th scope="col">Fri</th>
            <th scope="col">Sat</th>
            <th scope="col">Sun</th>
            <th scope="col">Action</th>
          </tr>
        </thead>

        <tbody>
          <ng-container *ngIf="showShift">
            <tr *ngFor="let i of openPlanshift?.shifts; let index = index" >
              <td>
                <p class="m-0" *ngFor="let k of timeSlot[index]">
                  {{ k[0] | date: "HH:mm" }} to {{ k[1] | date: "HH:mm" }}
                </p>
                <p class="m-0">
                  {{ i.staffNeedCount }} Confirmed
                  <ng-container *ngIf="i.backUpStaffNeedCount">
                    /
                    {{ i.backUpStaffNeedCount }} Standby
                  </ng-container>
                </p>
              </td>
              <td>
                <p *ngFor="let j of skillTableLabels[index]">
                  {{ j[0] }} >
                  <ng-container *ngIf="tier == 2"> {{ j[1] }} </ng-container>
                </p>
              </td>
              <td>
                <i
                  class="material-icons"
                  *ngIf="shiftDay[index].includes('Monday')"
                >
                  check_box
                </i>
              </td>
              <td>
                <i
                  class="material-icons"
                  *ngIf="shiftDay[index].includes('Tuesday')"
                >
                  check_box
                </i>
              </td>
              <td>
                <i
                  class="material-icons"
                  *ngIf="shiftDay[index].includes('Wednesday')"
                >
                  check_box
                </i>
              </td>
              <td>
                <i
                  class="material-icons"
                  *ngIf="shiftDay[index].includes('Thursday')"
                >
                  check_box
                </i>
              </td>
              <td>
                <i
                  class="material-icons"
                  *ngIf="shiftDay[index].includes('Friday')"
                >
                  check_box
                </i>
              </td>
              <td>
                <i
                  class="material-icons"
                  *ngIf="shiftDay[index].includes('Saturday')"
                >
                  check_box
                </i>
              </td>
              <td>
                <i
                  class="material-icons"
                  *ngIf="shiftDay[index].includes('Sunday')"
                >
                  check_box
                </i>
              </td>
              <td *ngIf="i.uniqueId">
                <button class="btn btn-danger ml-1" style="background-color: #a42138;width:6rem;" (click)="deleteShiftInTemplateModal(i,index)">
                  Delete
                </button>
              </td>
              <td *ngIf="!i.uniqueId">
                <button class="btn btn-warning ml-1" style="background-color: #f5ca3c; width:6rem;" (click)="removeShiftInTemplate(i,index)">
                  Remove
                </button>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  <div class="card-footer d-flex justify-content-center">
    <button
      class="btn btn-success"
      [disabled]="loaderShiftCreate"
      (click)="onShiftSubmit()"
    >
      Publish Shifts
      <span
        *ngIf="loaderShiftCreate"
        class="spinner-border spinner-border-sm ml-1"
        role="status"
        aria-hidden="true"
      ></span>
    </button>
    <button class="btn btn-danger ml-1" (click)="clearShifts()">
      Clear Plan
    </button>
    <button class="btn btn-info ml-1" (click)="savePlanTemplate()">
      Save Template
    </button>
    <button class="btn btn-danger ml-1" style="background-color: #a42138" (click)="deleteTemplateModal()" [disabled]="!ShiftTemplateTable?._id">
      Delete Template
    </button>
  </div>
  <div [ngClass]="delete ? 'show-modal' : ''" class="modal" id="mmmModal">
    <div
      class="modal-dialog"
      style="margin-top: 80px; width: 311px; height: 200px"
    >
      <div class="modal-content modalContentBoxGrey">
        <div class="modal-body">
          <div>
            <h4 style="font-size: 17px">
              Are you sure you want to delete this Template?
            </h4>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn footer-button btn-warning" (click)="setTemplateDelete()">
            Yes
          </button>
          <button
            class="btn footer-button btn-primary"
            (click)="this.delete = false"
            style="margin-right: 80px"
          >
            No
          </button>
        </div>
      </div>
    </div>
  </div>


  <div [ngClass]="deleteShiftTemplate ? 'show-modal' : ''" class="modal" id="mmmModal">
    <div
      class="modal-dialog"
      style="margin-top: 80px; width: 311px; height: 200px"
    >
      <div class="modal-content modalContentBoxGrey">
        <div class="modal-body">
          <div>
            <h4 style="font-size: 17px">
              Are you sure you want to delete this shift from this template?
            </h4>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn footer-button btn-warning" (click)="deleteShiftInTemplate()">
            Yes
          </button>
          <button
            class="btn footer-button btn-primary"
            (click)="this.deleteShiftTemplate = false"
            style="margin-right: 80px"
          >
            No
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
