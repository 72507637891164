import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import * as moment from "moment";

import { UsersService } from '../../../../service/user-service/users.service'
import { SetupService } from '../../../../service/operations/setup.service';

@Component({
  selector: 'leave-grp-overview',
  templateUrl: './leave-grp-overview.component.html',
  styleUrls: ['./leave-grp-overview.component.scss']
})
export class LeaveGrpOverviewComponent implements OnInit {
  active = 1;
  proList = [{ val: 0 }, { val: 1 }, { val: 2 }, { val: 3 }, { val: 4 }, { val: 5 }, { val: 6 }, { val: 7 }, { val: 8 }, { val: 9 }, { val: 10 }, { val: 11 }, { val: 12 }];
  proRateSlab = [{
    fromMonth: 0,
    toMonth: 0,
    quota: 0
  }, {
    fromMonth: 0,
    toMonth: 0,
    quota: 0
  }];
  senioritySlab = [{
    year: 0,
    quota: 0
  },
  {
    year: 0,
    quota: 0
  }];

  staticLeaveGroupName = [
    'Tick to include Leave Type in this Leave Group',
    'Quota',
    'Display quota in mobile - "My Leave"',
    'Tick If there is pro rate table for staff not completed 1 year',
    'Tick If there is seniority table for staff with long service',
    'Planning tools to use',
    'Apply types to use'
  ];

  loader: boolean = false;
  isProrateModelOpen: boolean = false;
  isSeniorityModelOpen: boolean = false;
  onlyView: boolean = false;
  isEdit: boolean = false;
  isDelete: boolean = false;
  quota: number = 0;
  groupName: string = '';
  updateGroupName: string = '';
  selectedColumn: {};
  adminList = [];
  userData: {};
  loginAdminId: any = [];
  loginAdminData: {};
  selectedAdmin = [];
  leaveTypeList = [];
  leaveGroupList = [];
  leaveSchemeList = [];
  viewLeaveTypeList = [];
  viewLeaveGroupType = [];

  constructor(
    private _usersService: UsersService,
    private _setupService: SetupService,
    public toastService: ToastrService,
    private userService: UsersService) { }

  ngOnInit(): void {
    this.onlyView = false;
    this.getInitialData();
    this.getAdminList();

    this.userService.getUserProfile().subscribe((res) => {
      this.userData = res["data"];
      this.loginAdminId.push(this.userData);
      this.selectedAdmin = this.loginAdminId;
  });
  }

  getInitialData() {
    this.leaveTypeList = [];
    this.leaveGroupList = [];
    this.selectedAdmin = [];
    this.groupName = '';
    this.getLeaveGroupData();
    this.getLeaveTypeData();
  }

  getLeaveTypeData() {
    this.loader = true;
    const subscribe = this._usersService.getLeaveType()
      .pipe(finalize(() => subscribe.unsubscribe()))
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.leaveSchemeList = response.data;
            response.data.forEach(leave => {
              this.leaveTypeList.push({
                name: leave.name,
                quota: 0,
                id: leave._id,
                displayQuota: false,
                displayInMyLeave: false,
                proRate: [],
                proRateDisplay: false,
                seniority: [],
                seniorityDisplay: false,
                isLeaveRequest: false,
                isAdminAllocate: false,
                isApplyLeavePlan: false,
                isApplyLeave: false
              });
            });
          } else {
            this.toastService.error(response.message);
          }
          this.loader = false;
        },
        (error) => {
          this.loader = false;
          this.toastService.error('No list found');
        }
      );
  }

  getLeaveGroupData() {
    this.loader = true;
    const subscribe = this._usersService.getLeaveGroup()
      .pipe(finalize(() => subscribe.unsubscribe()))
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.leaveGroupList = response.data;
            this.leaveGroupList.forEach(leave => {
              leave.createdAt = moment(leave.createdAt).format("DD MMM YYYY");
              leave.updatedAt = moment(leave.updatedAt).format("DD MMM YYYY");
            });
          } else {
            this.toastService.error(response.message);
          }
          this.loader = false;
        },
        (error) => {
          this.loader = false;
          this.toastService.error('No list found');
        }
      );
  }

  createLeaveGroup() {
    if (!this.selectedAdmin || this.selectedAdmin.length === 0) {
      this.toastService.error('Please select the admin!');
      return;
    }
    if (!this.groupName) {
      this.toastService.error('Please enter the group name!');
      return;
    }

    const isLeaveTypeSelected = this.leaveTypeList.find(leave => leave.displayQuota === true);
    if (!isLeaveTypeSelected) {
      this.toastService.error('Please select the leave type!');
      return;
    }
    this.loader = true;
    const selectedLeaveType = [];
    this.leaveTypeList.forEach(selectedLeave => {
      if (selectedLeave.displayQuota) {
        selectedLeaveType.push({
          leaveTypeId: selectedLeave.id,
          displayQuota: selectedLeave.displayQuota,
          quota: selectedLeave.quota,
          specialType: false,
          displayInMyLeave: selectedLeave.displayInMyLeave,
          proRate: selectedLeave.proRate,
          seniority: selectedLeave.seniority,
          leavePlanning: {
            isLeaveRequest: selectedLeave.isLeaveRequest,
            isAdminAllocate: selectedLeave.isAdminAllocate
          },
          leaveApplication: {
            isApplyLeavePlan: selectedLeave.isApplyLeavePlan,
            isApplyLeave: selectedLeave.isApplyLeave
          }
        })
      }
    });

    const requestPayload = {
      name: this.groupName,
      leaveType: selectedLeaveType,
      adminId: this.selectedAdmin.map(admin => admin._id)
    }

    this._usersService.createLeaveGroup(requestPayload).subscribe(response => {
      if (response.success) {
        this.toastService.success(response.message);
        this.getInitialData()
        this.active = 1;
        this.selectedAdmin = [];
        this.groupName = '';
      } else {
        this.toastService.error(response.message);
      }
      this.loader = false;
    },
      (error) => {
        this.loader = false;
        this.toastService.error(error.message);
      }
    );
  }

  getAdminList() {
    this._setupService.getAdminWithfilter().subscribe((response) => {
      this.adminList = response['data'];
     
    });
  }

  addNewProSlab() {
    this.proRateSlab.push({
      fromMonth: 0,
      toMonth: 0,
      quota: 0
    });
  }

  addNewSeniority() {
    this.senioritySlab.push({
      year: 0,
      quota: 0
    });
  }

  removeproRateSlab(index: number): void {
    this.proRateSlab.splice(index, 1);
  }

  removesenioritySlab(index: number): void {
    this.senioritySlab.splice(index, 1);
  }

  proRateModal(selectedColumn) {
    if (!selectedColumn.quota) {
      this.toastService.error('Please enter the quoat!');
      return;
    }
    this.quota = 0;
    this.selectedColumn = {};
    this.quota = selectedColumn.quota;
    this.selectedColumn = selectedColumn;
    this.isProrateModelOpen = !this.isProrateModelOpen;
  }

  seniorityModal(selectedColumn) {
    if (!selectedColumn.quota) {
      this.toastService.error('Please enter the quoat!');
      return;
    }
    this.quota = 0;
    this.selectedColumn = {};
    this.selectedColumn = selectedColumn;
    this.quota = selectedColumn.quota;
    this.isSeniorityModelOpen = !this.isSeniorityModelOpen;
  }

  submitProRate(proRate) {
    const isQuotaExceed =  proRate.find(element => Number(element.quota) > this.quota);
    if (isQuotaExceed) {
      this.toastService.error('Please enter valid quoat!');
      return;
    }
    this.isProrateModelOpen = false;
    const isExist = this.leaveTypeList.find(leave => leave.id === this.selectedColumn['id']);
    isExist.proRate = proRate;
  }

  submitseniority(seniority) {
    this.isSeniorityModelOpen = false;
    const isExist = this.leaveTypeList.find(leave => leave.id === this.selectedColumn['id']);
    isExist.seniority = seniority;
  }

  editLeaveGroup(leaveGroup, isDelete) {
    this.leaveTypeList = [];
    this.selectedAdmin = [];
    this.isDelete = isDelete;
    this.isEdit = true;
    this.active = 2;
    this.groupName = leaveGroup.name;
    this.selectedAdmin = leaveGroup.adminId;
    this.updateGroupName = leaveGroup._id;
    this.leaveSchemeList.forEach(leave => {
      this.leaveTypeList.push({
        name: leave.name,
        quota: 0,
        id: leave._id,
        displayQuota: false,
        displayInMyLeave: false,
        proRate: [],
        proRateDisplay: false,
        seniority: [],
        seniorityDisplay: false,
        isLeaveRequest: false,
        isAdminAllocate: false,
        isApplyLeavePlan: false,
        isApplyLeave: false
      });
    });

    this.leaveTypeList.map(leaveScheme => {
      leaveGroup.leaveType.forEach(type => {
        if (leaveScheme.name === type.leaveTypeId.name) {
          leaveScheme.name = type.leaveTypeId.name;
          leaveScheme.id = type.leaveTypeId._id;
          leaveScheme.quota = type.quota;
          leaveScheme.displayQuota = type.displayQuota;
          leaveScheme.displayInMyLeave = type.displayInMyLeave;
          leaveScheme.proRate = type.proRate;
          leaveScheme.proRateDisplay = false;
          leaveScheme.seniority = type.seniority;
          leaveScheme.seniorityDisplay = false;
          leaveScheme.isLeaveRequest = type.leavePlanning.isLeaveRequest;
          leaveScheme.isAdminAllocate = type.leavePlanning.isAdminAllocate;
          leaveScheme.isApplyLeavePlan = type.leaveApplication.isApplyLeavePlan;
          leaveScheme.isApplyLeave = type.leaveApplication.isApplyLeave;
        }
      });
    });
  }

  updateLeaveGroup() {
    if (!this.selectedAdmin || this.selectedAdmin.length === 0) {
      this.toastService.error('Please select the admin!');
      return;
    }
    if (!this.groupName) {
      this.toastService.error('Please enter the group name!');
      return;
    }

    const selectedLeaveType = [];
    this.leaveTypeList.forEach(selectedLeave => {
      if (selectedLeave.displayQuota) {
        selectedLeaveType.push({
          leaveTypeId: selectedLeave.id,
          displayQuota: selectedLeave.displayQuota,
          quota: selectedLeave.quota,
          specialType: false,
          displayInMyLeave: selectedLeave.displayInMyLeave,
          proRate: selectedLeave.proRate,
          seniority: selectedLeave.seniority,
          leavePlanning: {
            isLeaveRequest: selectedLeave.isLeaveRequest,
            isAdminAllocate: selectedLeave.isAdminAllocate
          },
          leaveApplication: {
            isApplyLeavePlan: selectedLeave.isApplyLeavePlan,
            isApplyLeave: selectedLeave.isApplyLeave
          }
        });
      }
    });

    this.loader = true;

    const requestPayload = {
      name: this.groupName,
      leaveType: selectedLeaveType,
      adminId: this.selectedAdmin.map(admin => admin._id),
      leaveGroupId: this.updateGroupName,
    };

    this._usersService.updateLeaveGroup(requestPayload).subscribe(response => {
      if (response.success) {
        this.toastService.success(response.message);
        this.getInitialData();
        this.active = 1;
      } else {
        this.toastService.error(response.message);
      }
      this.loader = false;
    },
      (error) => {
        this.loader = false;
        this.toastService.error(error.message);
      }
    );
  }

  deleteLeaveGroup() {
    this.isEdit = true;
    this.loader = true;
    const requestPayload = {
      leaveGroupId: this.updateGroupName
    }

    this._usersService.deleteLeaveGroup(requestPayload).subscribe(response => {
      if (response.success) {
        this.loader = false;
        this.toastService.success(response.message);
        this.getInitialData();
        this.active = 1;
      } else {
        this.loader = false;
        this.toastService.error(response.message);
      }
    },
      (error) => {
        this.loader = false;
        this.toastService.error(error.message);
      }
    );
  }

}
