import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { ItemsList } from '@ng-select/ng-select/lib/items-list';
import { ToastrService } from 'ngx-toastr';
import { finalize, map } from 'rxjs/operators';
import { PaginatedTable } from 'src/app/core/model/pagination.model';

import { CompanySetupService } from 'src/app/users/service/company-setup/company-setup.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-usefull-link',
  templateUrl: './usefull-link.component.html',
  styleUrls: ['./usefull-link.component.scss']
})
export class UsefullLinkComponent implements OnInit {
  iconFileName: any;
  companySettingsId: string;
  IconUrl: string;
  isUpdateLoading: boolean = false
  CustomFieldPTable: PaginatedTable;
  usefullinkIndex: number;
  usefullinkEdit: boolean = false;
  setupForm: FormGroup;
  userFullLinksForm: FormGroup;
  useFullLinksArray = [];
  fieldNeedsToUpdate = [];
  useFullLinkOrders = [];
  constructor(
    private companyService: CompanySetupService,
    private formBuilder: FormBuilder,
    private tostService: ToastrService,
  ) {


  }

  ngOnInit(): void {


    this.initForm();
    this.getCompanySettings();

  }


  getCompanySettings() {
    this.companyService.getcompanySetting().subscribe((res) => {
      this.companySettingsId = res.data._id;

      this.useFullLinksArray = res.data.quickLinks.map((link) => {
        return {
          ...link,
          isChecked: link?.status === 'active' ? true : false
        }
      })


    },
      (error: HttpErrorResponse) => {
        this.tostService.error("Error"+error)
      });
  }

  initForm() {
    this.setupForm = this.formBuilder.group({

      quickLinks: this.formBuilder.array([]),

    });
  }





  get quickLinksControl(): FormArray {
    return this.setupForm.get('quickLinks') as FormArray;
  }

  onCheckBoxChanges(event: HTMLInputElement, title: string) {
    // get current position of the changes element by ID
    const index = this.useFullLinksArray.findIndex(_ => _.title === title);
    if (!(index > -1)) return;

    // const isChecked = this.checkBoxes[index].isChecked;
    this.useFullLinksArray[index].isChecked = event.checked;
  }



  async onSubmitSetupForm() {
    this.isUpdateLoading = true
    let fields = this.useFullLinksArray.map(({ isChecked, ...rest }) => {
      return {
        ...rest,
        status: isChecked ? 'active' : 'inactive'
      }
    }
    )
    const data = {
      quickLinks: fields,
      pageId:this.companySettingsId
    };
    const sub = this.companyService
      .updateCompanySettings(data)
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe(
        (res) => {
          this.useFullLinkOrders = []
          this.tostService.success('Updated SuccessFully');
          this.isUpdateLoading = false
          this.ngOnInit();
        },
        (error) => {
          this.tostService.error("Error"+error)
          this.isUpdateLoading = false
        }
      );
  }
}
