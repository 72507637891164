<button mat-button color="link"(click)="close()" style="float: right;">X</button>
<h1 mat-dialog-title>Signature</h1>
<div class="container" mat-dialog-content>
  <div class="content">
    <canvas  #canvasel (touchstart)="startDrawing($event)" (touchmove)="moved($event)" width="450" height="300"></canvas>

  </div>
</div>
<p>Please sign above</p>
<div class="child">
    <button mat-button color="warn" (click)="clearPad()" style="margin-left: 5px;">clear</button>
   <button mat-button color="success" (click)="savePad()" style="margin-left: 5px;">save</button>

</div>
  




