<div class="row" *ngIf="!showRedemptionList">
  <div class="col-md-12">
    <div class="card my-3">
      <div class="card-header flexi-header p-4">
        <h3>REDEMPTION EXPORT
        </h3>
      </div>
      <div class="card-body">
        <form action="" [formGroup]="exportRedemption">

          <div class="row mb-2">
            <div class="col-md-4">
              <label> Bussiness unit (Plan) </label>
              <ng-select class="custom" bindLabel="businessUnit" placeholder="Bussiness unit (Plan)" appendTo="body"
                [multiple]="false" [searchable]="true" [closeOnSelect]="true" [clearable]="true"  [virtualScroll]="true"
                formControlName="bussinessUnit" [loading]="planBuUnitDropDownLoading"
                (change)="changeBuUnit($event)"
                (clear)="onClearPlanBuUnitDropdown($event)">
                <ng-option [value]="item?._id" *ngFor="let item of bussinesUnitplanPTable.data">
                  {{ item.orgName }}
                </ng-option>
              </ng-select>
              <!-- (search)="onSeachPlanBuUnit($event)" (scrollToEnd)="loadMorePlasnBuUnitItems($event)" -->
            </div>
            <div class="col-md-2">
              <button style="margin-top:25px;height: 35px;" class="btn btn-submit pl-3 pr-3" [disabled]="isLoading"
                (click)="onClickExport()">
                <i class="fa" [ngClass]="{ 'fa-spin fa-spinner': isLoading, '': !isLoading }"></i>
                Export
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="card my-3 w-100" *ngIf="!showRedemptionList">
  <div class="card-header flexi-header p-4">
    <h3>REDEMPTION LIST
    </h3>
  </div>
  <div class="card-body">
    <div class="card table-card">
      <div class="card-body flexi-body">
        <div class="flex-column">
          <div class="d-flex justify-content-between mt-2 mb-3">
            <select class="custom-select" style="width: auto" name="pageSize" (change)="onLimitChangeUsers()"
              [(ngModel)]="usersPTable.limit">
              <option [ngValue]="10">10</option>
              <option [ngValue]="20">20</option>
              <option [ngValue]="30">30</option>
              <option [ngValue]="40">40</option>
              <option [ngValue]="50">50</option>
            </select>
            <div class="form-group form-inline mb-0">
              <span *ngIf="usersPTable.isLoading">Please wait...</span>
              <input class="form-control ml-2" type="search" name="searchTerm" (search)="loadUsers()"
                (keyup)="userssearchInput.next($event)" placeholder="Search here" [(ngModel)]="usersPTable.search" />
            </div>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">
                    USERNAME
                  </th>
                  <th scope="col">
                    STAFF ID
                  </th>
                  <th scope="col" sortable="0" (sort)="onSortUsers($event, 'name')">
                    PRODUCT
                  </th>

                  <th scope="col" sortable="1" (sort)="onSortUsers($event, 'code')">
                    COUPON CODE
                  </th>
                  <th scope="col" sortable="2" (sort)="onSortUsers($event, 'redemption_type')">
                    COUPON CATEGORY
                  </th>
                  <th scope="col" sortable="3" (sort)="onSortUsers($event, 'createdAt')">
                    REDEMEED ON
                  </th>
                  <th scope="col">ACTION</th>
                </tr>
              </thead>
              <tbody *ngIf="!isTableLoading">
                <tr *ngFor="let item of usersPTable.data">
                  <td>
                    {{ item?.user.name }}
                  </td>

                  <td>{{ item?.user.staffId }}</td>

                  <td> {{ item?.name }}</td>
                  <td>
                    {{ item?.code }}
                  </td>
                  <td>
                    {{ item?.redemption_type }}
                  </td>
                  <td>
                    {{ item?.createdAt | date }}
                  </td>
                  <td>
                    <button class="btn btn-submit p-2 pr-3 pl-3 m-1" (click)="onEditUser(item)">
                      VIEW
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <app-skliton-loading-table [numberOfCols]="7" [isTableLoading]="isTableLoading"></app-skliton-loading-table>
        </div>
      </div>
      <div class="card-footer">
        <div class="d-flex justify-content-between">
          <p>Total records {{ usersPTable.total }}</p>
          <ngb-pagination [collectionSize]="usersPTable.total" [(page)]="usersPTable.page" [maxSize]="5"
            (pageChange)="onPageChangeUsers($event)" [pageSize]="usersPTable.limit">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showRedemptionList">
  <div class="card-header flexi-header mt-4">
    <span style="color: white;" class="pl-3 pr-3">
      <i class="fa fa-angle-left fa-2x back-button" aria-hidden="true" (click)="goBack()"></i>
    </span>
    <h3>REDEMPTION LIST</h3>
  </div>
  <div class="card-body">
    <div class="flex-SB">
      <p class="card-title">Username</p>
      <p class="card-subtitle">{{selectedUser?.user?.name}}</p>
    </div>
    <div class="flex-SB mt-4">
      <p class="card-title">Staff ID</p>
      <p class="card-subtitle">{{selectedUser?.user?.staffId}}</p>
    </div>
    <div class="flex-SB mt-4">
      <p class="card-title">Product Name</p>
      <p class="card-subtitle">{{selectedUser?.name}}</p>
    </div>
    <div class="flex-SB mt-4">
      <p class="card-title">Total Points before Redeem</p>
      <p class="card-subtitle">{{ (selectedUser?.totalRewardPoints  ? selectedUser?.totalRewardPoints : 0 ) + (selectedUser?.points ? selectedUser?.points : 0 )}}</p>
    </div>
    <div class="flex-SB mt-4">
      <p class="card-title">Total Points after Redeem</p>
      <p class="card-subtitle">{{selectedUser?.totalRewardPoints}}</p>
    </div>
    <div class="flex-SB mt-4">
      <p class="card-title">Coupon Category</p>
      <p class="card-subtitle">{{selectedUser?.redemption_type}}</p>
    </div>
    <div class="flex-SB mt-4">
      <p class="card-title">Redeemed on </p>
      <p class="card-subtitle">{{selectedUser?.createdAt ? (selectedUser?.createdAt | date:'MM/dd/yyyy'): ''}}</p>
    </div>
    <div class="flex-SB mt-4">
      <p class="card-title">Redeemed type</p>
      <p class="card-subtitle">Points</p>
    </div>
  </div>
</div>
