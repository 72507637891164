<div class="d-flex flex-column">
    <ul ngbNav [(activeId)]="activeId" #companyNav="ngbNav" class="nav-tabs rounded">
      <li [ngbNavItem]="1">
        <a ngbNavLink>OVERVIEW</a>
        <ng-template ngbNavContent>
          <app-channel-overview (editChannel)="editChannel($event)"></app-channel-overview>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink (click)="userDetails.length = 0">{{isChannelEdited? 'EDIT' : 'ADD'}}</a>
        <ng-template ngbNavContent>
          <div class="card my-3 w-100">
            <div class="card-header flexi-header p-4">
              <h3>CHANNEL SETUP </h3>
              <button *ngIf="isChannelEdited" class="btn pull-right btn-outline-light" (click)="onCancelEdit()"
                type="button">
                <i class="fa fa-angle-left fa-lg" aria-hidden="true"></i> Back
              </button>
            </div>
            <div class="card-body">
              <form [formGroup]="channelCreateForm">
                <div class="channel-setup" *ngIf="isChannelEdited == true">
                  <div class="d-flex">
                    <div class="radio radio-danger radio-inline p-1">
                      <input type="radio" id="postSubReqTru" [value]="1" formControlName="status"/>
                      <label for="postSubReqTru"> Active</label>
                    </div>
                    <div class="radio radio-danger radio-inline p-1">
                      <input type="radio" id="postSubReqFals" [value]="2" formControlName="status"/>
                      <label for="postSubReqFals">Inactive</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 mt-1">
                    <div class="form-group">
                      <label class="pb-2 font-weight-bold red-label" for="exampleInputEmail1">Channel Name</label>

                      <input type="text" maxlength="60" class="form-control"
                        formControlName="name" required />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <label class="pb-2 font-weight-bold red-label">
                      Category
                      <button class="btn p-1 ml-1" style="color: #a51f38;" (click)="addCategory()">
                        <i class="fa fa-plus-circle fa-lg" aria-hidden="true"></i></button></label>
                    <ng-select (change)="addCategories($event)" class="custom" bindLabel="name"
                      placeholder="Select Category" appendTo="body" [multiple]="false" [searchable]="true"
                      [clearable]="true" [loading]="categoryDropDownLoading" (search)="onSeachCategory($event)"
                      (scrollToEnd)="loadMoreCategoryItems($event)" (clear)="onClearCategoryDropdown($event)" [hideSelected]="true">
                      <ng-option [value]="item" *ngFor="let item of categoryDropDown">
                        {{ item.name }}
                      </ng-option>
                    </ng-select>
                  </div>
                  <div class="col-md-6">
                    <div style="display: flex;flex-direction: row; flex-wrap: wrap; margin-top: 35px;">
                      <div formArrayName="category" *ngFor="
                        let field of CategorytControl.controls;
                        let index = index
                      ">
                        <span class="text-items list-ui" [formGroupName]="index">{{
                          field.get("name").value }}
                          <img class="ml-3" (click)="deleteCategories(index)" src="./assets/images/cancel.png"
                            width="20px" height="20px" /></span>
                      </div>
                    </div>

                  </div>
                </div>
              </form>
            </div>
          </div>
          <assign-users
          [userDetails]="userDetails"
          (onSubmitAssignUsers)="updateUserDetails($event)"
          (showCreateButton)="showCreateButton()"
          (hideCreateButton)="hideCreateButton()"
          toastMessage="Channel Updated"></assign-users>

          <div class="row">
            <div class="col-md-4">
              <button *ngIf="isAddButtonActive" class="btn btn-red-back" (click)="clearForm()" ><i class="fa"></i>Clear</button>
              <button *ngIf="isAddButtonActive" class="btn btn-add update-button ml-2" [disabled]="isUpdateLoading" (click)="onSubmit()"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isUpdateLoading, '': !isUpdateLoading}"></i>{{isChannelEdited? 'Update' : 'Create'}}</button>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="companyNav"></div>
</div>

<ng-template #AddSubSection let-c="close" let-d="dismiss">
  <div>
    <div class="modal-content">
      <div class="modal-body" style="padding: 10px; min-height: 100vh">
        <div>
          <div class="card my-3 w-100">
            <div class="card-header flexi-header">
              <h3 class="mt-2">ADD CATEGORY</h3>
              <button type="button" class="btn close-button" aria-label="Close" (click)="c('Cross click')">
                <i class="fa fa-times-circle fa-2x" aria-hidden="true"></i>
              </button>
            </div>
            <div class="card-body">
              <form [formGroup]="createCategoryForm">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="exampleInputEmail1" class="font-weight-bold">Category</label>
                      <input type="text" maxlength="60" class="form-control" formControlName="name"/>
                    </div>
                  </div>
                </div>

                <button class="btn btn-add pull-right" *ngIf="!isCategoryEdited" [disabled]="!createCategoryForm.valid"
                  (click)="createCategory()">Add</button>
                <button class="btn btn-add pull-right" *ngIf="isCategoryEdited" [disabled]="!createCategoryForm.valid"
                  (click)="UpdateCatagory()">UPDATE</button>
              </form>

            </div>
          </div>
          <div class="card my-3 w-100">
            <div class="card-header flexi-header">
              <h3>CATEGORY LIST</h3>
            </div>
            <div class="card-body">
              <div class="card table-card">
                <div class="card-body flexi-body">
                  <div class="flex-column">
                    <div class="d-flex justify-content-between mt-2 mb-3">
                      <select class="custom-select" style="width: auto" name="pageSize"
                        (change)="onLimitChangeCategory()" [(ngModel)]="categoryPTable.limit">
                        <option [ngValue]="10">10</option>
                        <option [ngValue]="20">20</option>
                        <option [ngValue]="30">30</option>
                        <option [ngValue]="40">40</option>
                        <option [ngValue]="50">50</option>
                      </select>
                      <div class="form-group form-inline mb-0">
                        <span *ngIf="categoryPTable.isLoading">Please wait...</span>
                        <input class="form-control ml-2" type="search" name="searchTerm"
                          (keyup)="categorySearchInput.next($event)" placeholder="Search here"
                          [(ngModel)]="categoryPTable.search" />
                      </div>
                    </div>
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col" sortable="Tier 1" (sort)="onSortCategory($event)">
                              S.NO
                            </th>
                            <th scope="col" sortable="Tier 2" (sort)="onSortCategory($event)">
                              CATEGORY
                            </th>

                            <th scope="col" class="text-center action-header">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="
                              let item of categoryPTable.data;
                              let index = index
                            ">
                            <td>{{ index + 1 }}</td>

                            <td>{{ item.name }}</td>

                            <td>
                              <div class="modal-buttons">
                                <button class="btn btn-add p-2 m-1"
                                  (click)="oneditCategory(item)">Edit</button>
                                <button class="btn btn-clear p -2 m-1"
                                  (click)="onDeleteCategory(item._id)">Delete</button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="d-flex justify-content-between">
                    <p>Total records {{ categoryPTable.total }}</p>
                    <ngb-pagination [collectionSize]="categoryPTable.total" [(page)]="categoryPTable.page" [maxSize]="5"
                      (pageChange)="onPageChangeCtegory($event)" [pageSize]="categoryPTable.limit">
                    </ngb-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
