import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Toast, ToastrService } from 'ngx-toastr';
import { Subject, Subscription, of } from 'rxjs';
import {
  map,
  debounceTime,
  distinctUntilChanged,
  mergeMap,
  delay,
  finalize,
} from 'rxjs/operators';
import {
  NgbdSortableHeader,
  SortEvent,
} from 'src/app/core/directive/sortable.directive';
import { StorageService } from 'src/app/core/service/storage.service';
import { SetupService } from 'src/app/users/service/operations/setup.service';
import { RoleService } from 'src/app/users/service/user-roles/role.service';

@Component({
  selector: 'app-user-shift-setup-overview',
  templateUrl: './user-shift-setup-overview.component.html',
  styleUrls: ['./user-shift-setup-overview.component.scss'],
})
export class UserShiftSetupOverviewComponent implements OnInit {
  @Output() changeActiveUserBu = new EventEmitter<any>();
  @Output() changeActiveViewBu = new EventEmitter<any>();
  userShitfdata: any;
  editUserData: any;
  viewUserData: any;
  parentbuId: any;
  buName: any;
  userRoles = [];
  buShiftSetUpTable;
  isBuShiftSetUpEdited = false;
  shouldOpen = false;
  sectionId: string;
  subSectionId: string;
  buShiftSetUpForm: FormGroup;
  public buShiftSetUpSearchInput = new Subject<KeyboardEvent>();
  @ViewChildren(NgbdSortableHeader)
  headers: QueryList<NgbdSortableHeader>;
  companyName: string;

  private buShiftSetUpSearchSubscription: Subscription;
  loader: boolean = true;
  userShiftOverviewErr: boolean = false;

  constructor(
    private buShiftSetupService: SetupService,
    public toastService: ToastrService,
    private storageService: StorageService,
    private formBuilder: FormBuilder,
    private roleService: RoleService
  ) {
    this.buShiftSetUpTable = {
      sortBy: 'desc',
      sortWith: 'desc',
      data: [],
      page: 1,
      draw: 1,
      start:0,
      length:10,
      limit: 10,
      search:{
        value:'',
        regex:false
      }
     // searchBvalue: 'cargo',
      //searchBregex: false,
    };
    // search: '',
    this.companyName = this.storageService.getByKey('companyId');
    this.buShiftSetUpSearchSubscription = this.buShiftSetUpSearchInput
      .pipe(
        map((event) => {
          const target = event.target as HTMLTextAreaElement;
          return target.value;
        }),
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap((search) => of(search).pipe(delay(500)))
      )
      .subscribe((input) => {
        this.buShiftSetUpTable.page = 1;
        this.buShiftSetUpTable.start=0;
        this.loadBuShiftSetUp();
      });
  }

  ngOnInit(): void {
    this.loadBuShiftSetUp();
    this.buShiftSetUpForm = this.formBuilder.group({
      status: ['active', [Validators.required]],
      name: ['', [Validators.required]],
      buUnit: [''],
    });
  }

  onSortBuShiftSetup({ column, direction }: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.buShiftSetUpTable.sort = { key: column, order: direction as any };
    this.loadBuShiftSetUp();
  }

  onPageChangeBuShiftSetUp(page: number) {
    this.buShiftSetUpTable.page = page;
    this.buShiftSetUpTable.draw = page;
    this.buShiftSetUpTable.start = +page > 1 ? (page - 1) * this.buShiftSetUpTable.limit : 0;;
    this.loadBuShiftSetUp();
  }

  onLimitChangeBuShiftSetUp() {
    this.loadBuShiftSetUp();
  }

  loadBuShiftSetUp() {
    this.loader = true;
    // this.buShiftSetUpTable?.search ? this.buShiftSetUpTable.search.value:''
    const { data, total, ...rest } = this.buShiftSetUpTable;
    console.log(this.buShiftSetUpTable);
   // companyuser/editList
    const subscribe = this.buShiftSetupService
      .getUserShift(rest)
      .pipe(
        finalize(() => {
          this.loader = false;
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const { recordsFiltered, data, ...rest } = response;
          this.buShiftSetUpTable.data = data;
          this.buShiftSetUpTable.total = recordsFiltered;
        },
        (error) => {
          this.buShiftSetUpTable.data = [];
          this.buShiftSetUpTable.total = 0;
          this.toastService.error('No list found');
          this.userShiftOverviewErr = true;
        }
      );
  }

  onUpdateBuShiftSetup() {
    const data = {
      name: this.buShiftSetUpForm.get('name').value,
      status: this.buShiftSetUpForm.get('status').value,
    };
    this.buShiftSetupService
      .updateSubsection(this.subSectionId, this.sectionId, data)
      .subscribe(
        (res) => {
          console.log(res);
          this.onClearBuUnitEdit();
        },
        (error: HttpErrorResponse) => {
          switch (error.status) {
            case 422:
              for (const key in error.error.error) {
                if (
                  Object.prototype.hasOwnProperty.call(error.error.error, key)
                ) {
                  const element = error.error.error[key];
                  this.toastService.error(element);
                }
              }
              break;
            case 401:
              this.toastService.error(error.error.error);
              break;

            default:
              this.toastService.error(error.error.error);
              break;
          }
        }
      );
  }

  onClearBuUnitEdit() {
    this.isBuShiftSetUpEdited = false;
    this.buShiftSetUpForm.reset();
    this.sectionId = null;
    this.subSectionId = null;
  }

  ngOnDestroy(): void {
    this.buShiftSetUpSearchSubscription &&
      this.buShiftSetUpSearchSubscription.unsubscribe();
  }

  onEditUserShiftSetup(value) {
    this.changeActiveUserBu.emit(value);
  }

  onViewUserShiftSetup(value) {
    this.changeActiveViewBu.emit(value);
  }
}
