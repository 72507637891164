import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'manage-news',
  templateUrl: './manage-news.component.html',
  styleUrls: ['./manage-news.component.scss']
})
export class ManageNewsComponent implements OnInit {
  isBoardEdited:boolean = false

  activeId = 1

  boardId:string
  constructor() { }

  ngOnInit() {
  }
  overViewEvent(value){

    this.isBoardEdited = false
    this.boardId = null
        this.activeId = 1

}
formEdit(id:string){
  this.activeId = 2
  this.isBoardEdited = true
this.boardId = id
}

}
