import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LeavePlannerService {
  private baseUrl: string;

  private get Headers(): HttpHeaders {
    return new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }
  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }

  getMobileView(data) {
    return this.http.post(`${this.baseUrl}/ballot/getMobileViewAtWeb`, data, {
      headers: this.Headers,
    });
  }

  getballotdetailsforautoassigned(id) {
    return this.http.get(
      `${this.baseUrl}/ballot/ballotdetailsforautoassigned/${id}`,
      {
        headers: this.Headers,
      }
    );
  }

  getballotdetailsByUsers(id) {
    return this.http.get(`${this.baseUrl}/ballot/ballotdetailsbyusers/${id}`, {
      headers: this.Headers,
    });
  }

  getOpsGrp() {
    const payload= {privilage:true,search:''};
    return this.http.post(`${this.baseUrl}/opsGroup/get`,payload, {
      headers: this.Headers,
    });
  }

  getbuPlanBu(data) {
    return this.http.post(`${this.baseUrl}/business-unit/read/planbu`, data, {
      headers: this.Headers,
    });
  }

  getQuotaByGrp(payload) {
    return this.http.post(
      `${this.baseUrl}/ballot/getquotabyopsgroups`,
      payload,
      {
        headers: this.Headers,
      }
    );
  }

  getleaveQuota(userId) {
    return this.http.get(
      `${this.baseUrl}/ballot/getballotcalender1/${userId}`,
      {
        headers: this.Headers,
      }
    );
  }

  getSwapOpsGroup(userId) {
    return this.http.get(`${this.baseUrl}/ballot/getswapopsgroup/${userId}`, {
      headers: this.Headers,
    });
  }

  addOpsquota(payload) {
    return this.http.post(`${this.baseUrl}/ballot/addperdayopsquota`, payload, {
      headers: this.Headers,
    });
  }

  getcalenderforyear(payload) {
    return this.http.post(
      `${this.baseUrl}/ballot/getcalenderforyear`,
      payload,
      {
        headers: this.Headers,
      }
    );
  }

  leavePlanner(payload) {
    return this.http.post(
      `${this.baseUrl}/newleaveplanner/usersbydate`,
      payload,
      {
        headers: this.Headers,
      }
    );
  }
  leavePlannerBu(payload) {
    return this.http.post(
      `${this.baseUrl}/newleaveplanner/usersbydate/bu`,
      payload,
      {
        headers: this.Headers,
      }
    );
  }
  leavemanagement(payload) {
    return this.http.post(
      `${this.baseUrl}/leavemanagement/type/leaveAllocation`,
      payload,
      {
        headers: this.Headers,
      }
    );
  }
  newLeavePlanner(payload) {
    return this.http.post(
      `${this.baseUrl}/newleaveplanner/staffleavetype`,
      payload,
      {
        headers: this.Headers,
      }
    );
  }
  getLeaveType(payload) {
    return this.http.post(
      `${this.baseUrl}/newleaveplanner/leavetype`,
      payload,
      {
        headers: this.Headers,
      }
    );
  }
  getstaffleavetype(payload) {
    return this.http.post(
      `${this.baseUrl}/newleaveplanner/staffleavetype`,
      payload,
      {
        headers: this.Headers,
      }
    );
  }
  getLeaveTypeBu(payload) {
    return this.http.post(
      `${this.baseUrl}/newleaveplanner/leavetype/bu`,
      payload,
      {
        headers: this.Headers,
      }
    );
  }
  getLeaveUserData(payload) {
    return this.http.post(
      `${this.baseUrl}/newleaveplanner/mobilescreenforleaves`,
      payload,
      {
        headers: this.Headers,
      }
    );
  }
  getLeaveAllocate(payload) {
    return this.http.post(
      `${this.baseUrl}/leavemanagement/allocatteleave`,
      payload,
      {
        headers: this.Headers,
      }
    );
  }

  getSwapList(data) {
    return this.http.post(`${this.baseUrl}/ballot/getuserslistwithswap`, data, {
      headers: this.Headers,
    });
  }

  swapRestrict(id) {
    return this.http.post(`${this.baseUrl}/ballot/swaprestricttouser/${id}`, {
      headers: this.Headers,
    });
  }

  getBallotUser(payload) {
    return this.http.post(`${this.baseUrl}/ballot/user`, payload, {
      headers: this.Headers,
    });
  }

  addBallot(payload) {
    return this.http.post(`${this.baseUrl}/ballot`, payload, {
      headers: this.Headers,
    });
  }

  getBallot(pageNum:number) {
    return this.http.get(`${this.baseUrl}/ballot?page=${pageNum}`, {
      headers: this.Headers,
    });
  }

  updataBallot(payload) {
    return this.http.post(`${this.baseUrl}/ballot/update`, payload, {
      headers: this.Headers,
    });
  }

  extendBallot(id, payload) {
    return this.http.post(
      `${this.baseUrl}/ballot/extendballot/${id}`,
      payload,
      {
        headers: this.Headers,
      }
    );
  }

  conductAutoBallot(data) {
    return this.http.post(`${this.baseUrl}/ballot/auto`, data, {
      headers: this.Headers,
    });
  }

  getConsolidatedResult(data) {
    return this.http.post(`${this.baseUrl}/ballot/consolidatedresult`, data, {
      headers: this.Headers,
    });
  }

  viewBallotRoundDetail(payload) {
    return this.http.post(`${this.baseUrl}/ballot/detail`, payload, {
      headers: this.Headers,
    });
  }

  allBallotDetail(payload) {
    return this.http.post(`${this.baseUrl}/ballot/detail/all`, payload, {
      headers: this.Headers,
    });
  }

  resultRelease(payload) {
    return this.http.post(`${this.baseUrl}/ballot/resultrelease`, payload, {
      headers: this.Headers,
    });
  }

  manageBallot(id) {
    return this.http.get(`${this.baseUrl}/ballot/manageballot/${id}`, {
      headers: this.Headers,
    });
  }

  getReballot(id) {
    return this.http.get(`${this.baseUrl}/ballot/reballot/${id}`, {
      headers: this.Headers,
    });
  }

  getAutoAssign(id) {
    return this.http.get(`${this.baseUrl}/ballot/autoAssignballot/${id}`, {
      headers: this.Headers,
    });
  }

  getAutoAssignStaff(id) {
    return this.http.get(
      `${this.baseUrl}/ballot/datatoautoassignbystaff/${id}`,
      {
        headers: this.Headers,
      }
    );
  }

  getBallotDetailAll(id) {
    return this.http.post(`${this.baseUrl}/ballot/detail/all`, id, {
      headers: this.Headers,
    });
  }

  updateCancelBallot(id) {
    return this.http.post(`${this.baseUrl}/ballot/cancelballot/${id}`, {
      headers: this.Headers,
    });
  }

  getExportBallotData(id) {
    return this.http.get(`${this.baseUrl}/ballot/exportballotdata/${id}`, {
      headers: this.Headers,
    });
  }

  checkOverLap(payload) {
    return this.http.post(
      `${this.baseUrl}/leavemanagement/checkoverlap`,
      payload,
      {
        headers: this.Headers,
      }
    );
  }
  approveAppliedLeave() {
    return this.http.get(`${this.baseUrl}/leavemanagement/appliedLeave/bu`, {
      headers: this.Headers,
    });
  }
  leaveApproval(payload) {
    return this.http.post(
      `${this.baseUrl}/leavemanagement/leave/status`,
      payload,
      {
        headers: this.Headers,
      }
    );
  }
  cancel(payload) {
    return this.http.post(`${this.baseUrl}/newleaveplanner/cancel`, payload, {
      headers: this.Headers,
    });
  }
  change(payload) {
    return this.http.post(
      `${this.baseUrl}/leavemanagement/allocateleavechangedate`,
      payload,
      {
        headers: this.Headers,
      }
    );
  }
  export(payload) {
    return this.http.post(`${this.baseUrl}/newleaveplanner/export`, payload, {
      headers: this.Headers,
    });
  }
  seeLogs(payload) {
    return this.http.post(`${this.baseUrl}/ballot/userleavelogs`, payload, {
      headers: this.Headers,
    });
  }

  getBallotAdminList(){
    return this.http.get(`${this.baseUrl}/ballot/getballotadmins/`, {
      headers: this.Headers,
    });
  }
}
