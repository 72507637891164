import { IMonthCalendarConfig } from 'ng2-date-picker';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
  Input,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, delay, distinctUntilChanged, finalize, map, mergeMap } from 'rxjs/operators';
import { Subject, Subscription, of } from 'rxjs';
import { GamificationService } from 'src/app/users/service/gamification/gamification.service';
import { getCleanObj } from 'src/app/core/utils/helper';
import * as moment from 'moment';
import { BussinessUnitService } from 'src/app/users/service/bussiness-unit/bussiness-unit.service';
import { SortableHeader, SortEvent } from 'src/app/shared/directive/sortable.directive';
import { tableParam } from 'src/app/core/model/table-param.model';
import { TableService } from 'src/app/core/service/table.service';
import { NotificationService } from 'src/app/users/service/notification/notification.service';
@Component({
  selector: 'manage-challenges',
  templateUrl: './manage-challenges.component.html',
  styleUrls: ['./manage-challenges.component.scss'],
})
export class ManageChallengesComponent implements OnInit {
  @Output() overViewEvent = new EventEmitter<string>();
  @Output() action = new EventEmitter<any>();
  pTable;
  fromDate: string;
  toDate: string;
  selectUser = null;
  notificationCreateForm: FormGroup;
  disqualifierCreateForm: FormGroup;
  directRewardCreateForm: FormGroup;
  @Input() manageId = null;
  userDropDownLoading = false;
  challengeName: string;
  directRewardSelecteduser = null;
  disqualifySelectUser = null;
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  public userssearchInput = new Subject<KeyboardEvent>();
  private usersSearchSubscription: Subscription;

  userTableColumn = [
    '',
    'name',
    'staffId',
    'businessUnit',
    'count',
    'createdAt',
    'directRewardBy',
  ];
  tableQueryParams = tableParam;
  usersPTable: {
    sortBy: string;
    sortWith: string;
    data: any[];
    page: number;
    limit: number;
    search: string;
  };
  selectUserTable: {
    page: number;
    challengeId: string;
    q: string;
    // limit:number;
  };
  manageChallengesTable = [];
  managePTable: {
    sortBy: any;
    sortWith: string;
    startDate: string;
    endDate: string;
    businessUnit: string;
    individual: boolean;
    isTeam: boolean;
    challengeId: string;
    data: any[];
    page: number;
    total: number;
    limit: number;
    search: string;
  };
  challangeUsers: any;
  bussinesUnitplanPTable: {
    sortWith: string;
    sortBy: string;
    data: any[];
    page: number;
    limit: number;
    search: string;
    filter: { type: string };
  };
  countPTable: {
    startDate: string;
    endDate: string;
    businessUnit: string;
    challengeId: string;
    nonRewardPointSystemEnabled: boolean;
  };
  disqualiferTable: any;
  disqualiferTableLength: any;
  totalAmount: any;
  totalUsers: any;
  parentBuUnitDropDown: any;
  disqualifierUsersPTable: {
    challengeId: string;
    data: any[];
    page: number;
    limit: number;
  };
  closeResult: string;
  fileToUpload: File | null = null;
  importRewardsForm: FormGroup;
  stringArray: string;
  public userArray = [];
  @Output() LogEvent = new EventEmitter<string>();
  errorLogPresent = true;
  logData = [];

  constructor(
    public modelService: NgbModal,
    private formBuilder: FormBuilder,
    private gamificationService: GamificationService,
    private toastService: ToastrService,
    private buUnit: BussinessUnitService,
    private tableService: TableService,
    private notificationService: NotificationService,
    private fb: FormBuilder,
    private http: HttpClient
  ) {

    this.usersSearchSubscription = this.userssearchInput
      .pipe(
        map((event) => {
          this.modelService.dismissAll();
          const target = event.target as HTMLTextAreaElement;
          return target.value;
        }),
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap((search) => of(search).pipe(delay(500)))
      )
      .subscribe((input) => {
        this.modelService.dismissAll();
        this.managePTable.page = 1;
        this.tableQueryParams.start = 0;
        this.tableQueryParams.search.value = input;
        this.getManageChallange();
      });
  }

  ngOnInit(): void {
    this.challengeName = this.manageId.title || '';
    this.tableQueryParams.draw = 0;
    this.usersPTable = {
      sortBy: 'createdBy',
      sortWith: 'desc',
      data: [],
      page: 1,
      limit: 10,
      search: '',
    };
    this.disqualifierUsersPTable = {
      challengeId: '',
      data: [],
      page: 1,
      limit: 10,
    };
    this.managePTable = {
      sortBy: 5,
      sortWith: 'desc',
      startDate: '',
      endDate: '',
      businessUnit: '',
      individual: false,
      isTeam: false,
      challengeId: '',
      data: [],
      page: 1,
      total: 0,
      limit: 10,
      search: '',
    };
    this.selectUserTable = {
      page: 1,
      challengeId: this.manageId.id,
      q: '',
      // limit:10
    };
    this.countPTable = {
      startDate: '',
      endDate: '',
      businessUnit: '',
      nonRewardPointSystemEnabled: false,
      challengeId: '',
    };
    this.bussinesUnitplanPTable = {
      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,

      limit: 8,

      search: '',
      filter: {
        type: 'plan',
      },
    };

    this.directinitForm();
    this.managePTable.challengeId = this.manageId.id;
    this.countPTable.challengeId = this.manageId.id;
    this.countPTable.nonRewardPointSystemEnabled = this.manageId.pointSystem;
    this.disqualifierUsersPTable.challengeId = this.manageId.id;
    this.getManageChallange();
    this.getCountOfChallanges();
    this.getManageUsers();
    this.loadParentBussinessUnit();
    this.importRewardsForm = this.fb.group({
      csv: [''],
    });
    this.importLogsPresent();
  }
  importLogsPresent() {
    const subscribe = this.gamificationService
      .importLogsPresent(this.managePTable.challengeId)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          this.errorLogPresent = response.data
        },
        (error) => {
          this.errorLogPresent = false
        }
      );
  }
  onCancelView() {
    this.overViewEvent.emit(null);
  }

  @ViewChild('OpenQualifier', { static: true })
  OpenQualifier: TemplateRef<any>;

  openDisqualier() {
    this.modelService.open(this.OpenQualifier, {
      windowClass: 'modal-ui-fix right-align',
    });
    this.initForm();
    this.getDisQualifierUsers();
  }

  closeModal() {
    this.modelService.dismissAll();
  }

  onSeachUsers(value) {
    // this.usersPTable.limit = 10;
    this.selectUserTable.page = 0;
    this.selectUserTable.q = value.term;
    this.challangeUsers = [];
    this.getManageUsers();
  }
  loadMoreUsers(value) {
    //  this.selectUserTable.limit = this.selectUserTable.limit + 10;
    this.selectUserTable.page++;
    this.getManageUsers();
  }
  onClearUsers(value) {
    this.usersPTable.search = '';
    this.getManageUsers();
    this.challangeUsers = [];
    this.usersPTable.data = [];
  }

  onSeachBu(value) {
    this.bussinesUnitplanPTable.limit = 10;
    this.bussinesUnitplanPTable.search = value.term;
    this.loadParentBussinessUnit();
  }
  loadMoreBu(value) {
    this.bussinesUnitplanPTable.limit = this.bussinesUnitplanPTable.limit + 10;
    this.loadParentBussinessUnit();
  }
  onClearBu(value) {
    if (value === undefined) {
      // this.createUserForm.patchValue({
      //   appointmentId: null,
      // });
      this.bussinesUnitplanPTable.search = '';
      this.getManageUsers();

      this.parentBuUnitDropDown = [];
      this.bussinesUnitplanPTable.data = [];
    }
  }

  onSortData({ column, direction }: SortEvent, field) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.managePTable.sortBy = column;
    this.managePTable.sortWith = direction;
    this.getManageChallange();
  }

  onClickCheckBox(key, event) {
    if (key == "Team") {
      this.managePTable.isTeam = event?.target?.checked;
      this.getManageChallange(event?.target?.checked, this.managePTable.individual);
    }
    else {
      this.managePTable.individual = event?.target?.checked;
      this.getManageChallange(this.managePTable.isTeam, event?.target?.checked);
    }
  }

  getManageChallange(isTeam = false, isIndividual = false) {
    // const { data, ...rest } = this.managePTable;
    this.tableQueryParams['challengeId'] = this.manageId.id;
    this.tableQueryParams['startDate'] = this.managePTable.startDate;
    this.tableQueryParams['endDate'] = this.managePTable.endDate;
    this.tableQueryParams['businessUnit'] = this.managePTable.businessUnit;
    this.tableQueryParams['nonRewardPointSystemEnabled'] =
      this.manageId.pointSystem;
    this.tableQueryParams['individual'] = this.managePTable.individual;
    this.tableQueryParams['isTeam'] = this.managePTable.isTeam;

    let tableCol: any =
      this.tableService.initializeTableParamsColums(
        this.userTableColumn,
        [0, 1, 2, 3, 4, 5, 6],
        [0, 1, 2, 3, 4, 5, 6]
      ) || [];
    this.tableQueryParams.columns = tableCol;
    this.tableQueryParams.draw = 1;
    this.tableQueryParams.length = this.managePTable.limit;
    this.tableQueryParams.order = [{column: this.managePTable.sortBy, dir:this.managePTable.sortWith}]

    const subscribe = this.gamificationService
      .getManageChallenge(getCleanObj(this.tableQueryParams))
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe((response: any) => {
        this.manageChallengesTable = response.data || [];
        this.managePTable.total = response?.recordsFiltered || 0;
      });
  }
  onPageChangeUsers(page: number) {
    this.managePTable.page = page;
    this.tableQueryParams.start =
      +page > 1 ? (page - 1) * this.managePTable.limit : 0;
    this.getManageChallange();
  }
  onLimitChangeUsers() {
    this.managePTable.page = 1;
    this.tableQueryParams.start = 0;
    this.tableQueryParams.length = 0;
    this.getManageChallange();
  }
  loadParentBussinessUnit() {
    const { data, ...rest } = this.bussinesUnitplanPTable;
    const subscribe = this.buUnit
      .getBusinessUnitData()
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          // const { count, data } = response.data;
          this.parentBuUnitDropDown = response?.data?.planBussinessUnitId || [];
        },
        (error) => {
          this.parentBuUnitDropDown = [];
          this.toastService.error('No list found');
        }
      );
  }

  onViewLogs() {
    this.LogEvent.emit("");
  }

  getManageUsers() {
    // const { data, ...rest } = this.usersPTable;
    this.userDropDownLoading = true;
    const subscribe = this.gamificationService
      .getChallangesUsers(this.selectUserTable)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          this.userDropDownLoading = false;
          this.challangeUsers =
            this.challangeUsers?.length > 0
              ? [...this.challangeUsers, ...response.data?.items]
              : response.data?.items;
        },
        (error) => {
          this.userDropDownLoading = false;
          this.toastService.error('No list found');
        }
      );
  }

  getCountOfChallanges() {
    const subscribe = this.gamificationService
      .getCountOfChallenges(getCleanObj(this.countPTable))
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        ({ data: { totalUsers, totalAmount } }) => {
          this.totalAmount = totalAmount;
          this.totalUsers = totalUsers;
        },
        (error) => {
          this.toastService.error('No list found');
        }
      );
  }

  initForm() {
    this.disqualifierCreateForm = this.formBuilder.group({
      challengeId: [this.manageId.id, Validators.required],
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
      // userId: ['', Validators.required]
    });
  }

  addDisqualifier(value) {
    this.disqualifySelectUser = value;
    // this.disqualifierCreateForm.patchValue({
    //   userId: value._id
    // });
  }

  directinitForm() {
    this.directRewardCreateForm = this.formBuilder.group({
      challengeId: [this.manageId.id, Validators.required],

      comment: ['', Validators.required],
      points: ['', Validators.required],
      // userId: ['', Validators.required],
    });
  }

  onDateSelect(event, value) {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;
    let day = event.day <= 9 ? '0' + event.day : event.day;
    let finalDate = year + '-' + month + '-' + day;
    let date = moment(finalDate).utc().format();

    if (value === 'to') {
      this.managePTable.endDate = finalDate;
      this.countPTable.endDate = date;
    }
    if (value === 'from') {
      this.managePTable.startDate = finalDate;
      this.countPTable.startDate = date;
    }
    if (value === 'disQualifierfrom') {
      this.disqualifierCreateForm.patchValue({
        fromDate: date,
      });
    }
    if (value === 'dsiQualifierTo') {
      this.disqualifierCreateForm.patchValue({
        toDate: date,
      });
    }
  }

  OncreateDisqualifier() {
    if (
      this.disqualifierCreateForm.value.fromDate &&
      this.disqualifierCreateForm.value.toDate &&
      this.disqualifySelectUser?._id
    ) {
      let payload = {
        challengeId: this.disqualifierCreateForm.value.challengeId,
        fromDate: this.disqualifierCreateForm.value.fromDate,
        toDate: this.disqualifierCreateForm.value.toDate,
        userId: this.disqualifySelectUser?._id,
      };
      const subscribe = this.gamificationService
        .createDisqualifyUser(payload)
        .pipe(
          finalize(() => {
            subscribe.unsubscribe();
          })
        )
        .subscribe(
          (response) => {
            this.toastService.success('User added successfully.');
            this.disqualifierCreateForm.patchValue({
              fromDate: '',
              toDate: '',
            });
            this.disqualifySelectUser = null;
            this.getDisQualifierUsers();
          },
          (error) => {
            this.toastService.error(error?.error?.error || error?.error?.message);
          }
        );
    }
    else {
      this.toastService.error("Please select all the fields")
    }
  }
  addReward() {
    let data = {
      challengeId: this.directRewardCreateForm.value.challengeId,
      comment: this.directRewardCreateForm.value.comment,
      points: this.directRewardCreateForm.value.points,
      userId: this.directRewardSelecteduser._id,
    };
    const subscribe = this.gamificationService
      .createDirectRewards(data)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response) => {
          this.toastService.success('Reward Successfully Added');
          this.directRewardCreateForm.patchValue({
            comment: '',
            points: '',
            // userId: '',
          });
          this.directRewardSelecteduser = null;
          this.getCountOfChallanges();
          this.tableQueryParams.draw = 0;
          this.getManageChallange();
        },
        (error) => {
          if (error?.error?.message) {
            this.toastService.error(error.error.message);
          }
          if (error?.error?.error?.comment) {
            this.toastService.error(error.error.error.comment);
          }
          if (error?.error?.error?.points) {
            this.toastService.error(error.error.error.points);
          }
          if (error?.error?.error?.message) {
            this.toastService.error(error.error.error.message);
          }

        }
      );
  }
  getDisQualifierUsers() {
    const { data, ...rest } = this.disqualifierUsersPTable;

    const subscribe = this.gamificationService
      .getDisqualifierUserData(rest)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          this.disqualiferTable = response.data;
          this.disqualiferTableLength = this.disqualiferTable.length;
        },
        (error) => {
          this.toastService.error('No list found');
        }
      );
  }
  addBussinessUnit(id: string) {
    this.managePTable.businessUnit = id;
    this.countPTable.businessUnit = id;
  }
  onExport() {
    let d = new Date();
    const timeZone = d.getTimezoneOffset();
    const { sortBy, sortWith, individual, isTeam, data, page, limit, search, ...rest } =
      this.managePTable;
    const exportData = {
      ...rest,
      timeZone,
    };

    if (
      this.managePTable.startDate &&
      this.managePTable.endDate &&
      this.managePTable.businessUnit
    ) {
      const subscribe = this.gamificationService
        .exportManageChallenge(exportData)
        .pipe(
          finalize(() => {
            subscribe.unsubscribe();
          })
        )
        .subscribe(
          ({ data }) => {
            this.notificationService
              .downloadUrl(data.csvLink)
              .subscribe((content) => {
                var a = document.createElement('a');
                a.href = 'data:text/csv,' + content;
                a.target = '_blank';
                a.download = 'challenge_' + new Date() + '.csv';
                document.body.appendChild(a);
                a.click();
              });
          },
          (error) => {
            this.toastService.error('Error while exporting the file. Please Try again!');
          }
        );
    } else {
      this.toastService.error('Please select all the fields');
    }
  }
  onView() {
    if (
      this.managePTable.startDate &&
      this.managePTable.endDate &&
      this.managePTable.businessUnit
    ) {
      this.tableQueryParams.draw = 0;
      this.getManageChallange();
    }
    else {
      this.toastService.error('Please select all the fields');
    }
  }

  //method to delete disqualify user

  deleteDisqualifyUser(id) {
    if (id) {
      const subscribe = this.gamificationService
        .deleteDisqualifier(id)
        .pipe(
          finalize(() => {
            subscribe.unsubscribe();
          })
        )
        .subscribe(
          (response) => {
            this.toastService.success('User deleted successfully.');
            this.getDisQualifierUsers();
          },
          (error) => {
            this.toastService.error(
              error?.error?.error || 'Something went wrong'
            );
          }
        );
    }
  }

  importDataFromCSV(csvText: string) {
    const lines = csvText.split('\n');
    let headers = lines[0].split(',');
    headers = headers.map((header) => header.trim())
    const result = [];
    lines.forEach((line, index) => {
      if (index !== 0 && line.trim() !== '') {
        let data = {};
        let cells = line.split(',');
        cells.forEach((cell, index) => {
          let header = headers[index];
          data[header] = cell;
        });
        result.push(data);
      }
    });
    return result;
  }

  importRewardfile(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  async uploadImportReward() {
    const csvtoJSON = this.importDataFromCSV(await this.fileToUpload.text());
    let isErrorRow = false;
    for (let i = 0; i < csvtoJSON.length; i++) {
      const row = csvtoJSON[i];

      if (!row['Staff Id'] || !row['Points'] || !row['comment']) {
        isErrorRow = true;
        break;
      }
      row['Staff Id'] = row['Staff Id'].trim();
      row['Points'] = row['Points'].trim();
      row['comment'] = row['comment'].trim();
    }
    if (isErrorRow) {
      this.toastService.error('Import file is not having proper records, please verify again');
      return;
    }
    const payload = {
      challengeId: this.manageId.id,
      updateChallenge: csvtoJSON,
    };
    this.gamificationService
      .bulkUpdateDirectReward(payload)
      .subscribe((res: { data }) => {
        this.toastService.success(res.data);
        this.modelService.dismissAll();
      });
  }

  openModal(importQuota, from) {
    if (from === 'errorLog') {
      this.getLogData();
    }
    this.modelService
      .open(importQuota, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed`;
        }
      );
  }

  getLogData() {
    const subscribe = this.gamificationService
      .getLogData(this.managePTable.challengeId)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          this.logData = response.data
        },
        (error) => {
          this.errorLogPresent = false
        }
      );
  }
}
