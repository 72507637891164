import { async } from '@angular/core/testing';
import { CompanySetupService } from 'src/app/users/service/company-setup/company-setup.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  ValidatorFn,
} from '@angular/forms';

import {
  CustomValidators,
  MustMatch,
} from '../../../../shared/custom-validator/custom-validator';
import { StorageService } from 'src/app/core/service/storage.service';
import { AfterContentChecked } from '@angular/core';
import { AfterViewChecked } from '@angular/core';
import { UsersService } from '../../../service/user-service/users.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, AfterViewChecked {
  activeId;
  public passwordvisible: boolean = false;
  public confirmPasswordvisible: boolean = false;
  isUserDetected: boolean = false
  resetPasswordForm: FormGroup;
  getUserForm: FormGroup;
  submitted = false;
  @Output() overViewEvent = new EventEmitter<string>();
  passwordSetting;
  resePassword
  validators = [];
  selectedUserId: string = null
  pwdSettings:any=null;
  constructor(
    private storageService: StorageService,
    private toastService: ToastrService,
    public formBuilder: FormBuilder,
    private passwordSettings: CompanySetupService,
    private userService: UsersService
  ) {
     this.initForm();
    this.getPageSettings();
    // this.passwordSettings
    //   .getPasswordSetting()
    //   .subscribe(async (Res) => {
    //     this.passwordSetting = await Res.data.pwdSettings.charTypes;
    //     console.log(Res.data);

    //     this.getValidatorFnArray(Res.data.pwdSettings);

    //   },
    //     (error: HttpErrorResponse) => {

    //     });
  }
  ngOnInit() {
    this.resePassword = {
      usernameType: 'staffId',

      fields: ['email', 'staffId', 'name'],

    };

  }

  getPageSettings(){
    let companyName=localStorage.getItem('flexiurlCompanyName');
    this.passwordSettings.getPageSettings(companyName).subscribe(result => {
      this.pwdSettings = result?.data?.pageSettingData?.pwdSettings || null;
      if(this.pwdSettings){
        this.getValidatorFnArray();
      }

  },(error) => {
      console.log(error);
  })
  }

  passwordMatchValidator(g: FormGroup) {
    return g.controls.password.value === g.controls.confirmPassword.value ? null : {mismatch: true};
  }

  initForm() {
    this.resetPasswordForm = this.formBuilder.group(
      {
        name: [''],
        staffId: [''],
        businessUnit: [''],
        password: ['', [Validators.required]],
        confirmPassword: ['', Validators.required]
      }, { validator: this.passwordMatchValidator }
    );
    this.getUserForm = this.formBuilder.group(
      {

        staffId: ['', [Validators.required]],

      },

    );
  }

  passwordVisible() {
    this.passwordvisible = !this.passwordvisible;
  }

  confirmPasswordVisible() {
    this.confirmPasswordvisible = !this.confirmPasswordvisible;
  }

  overview() {
    // this.overViewEvent.next(null)
    this.isUserDetected = false
    this.resetPasswordForm.reset()
  }

  ngAfterViewChecked(): void { }

  getValidatorFnArray() {
    const validators = [];
    if (this.pwdSettings.charTypes.lowerCase) {
      validators.push(
        CustomValidators.patternValidator(/[a-z]/, {
          hasSmallCase: true,
        })
      );
    }
    if (this.pwdSettings.charTypes.specialChar) {
      validators.push(
        CustomValidators.patternValidator(
          /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
          {
            hasSpecialCharacters: true,
          }
        )
      );
    }

    if (this.pwdSettings.charTypes.upperCase) {
      validators.push(
        CustomValidators.patternValidator(/[A-Z]/, {
          hasCapitalCase: true,
        })
      );
    }

    if (this.pwdSettings.charTypes.numbers) {
      validators.push(
        CustomValidators.patternValidator(/\d/, {
          hasNumber: true,
        })
      );
    }

    if (this.pwdSettings.charLength) {
      const len = this.pwdSettings.charLength;
      let test = `^.{${len},22}`;
      let regex = new RegExp(test);
      validators.push(
        CustomValidators.patternValidator(
          regex,
          {
            minlength: true,
            length: this.pwdSettings.charLength,
          }
        )
      );
    }

    this.validators.push(...validators);
    this.resetPasswordForm.controls.password.setValidators([...this.validators, Validators.required]);
    this.resetPasswordForm.controls.password.updateValueAndValidity();
  }

  get f() {
    return this.resetPasswordForm.controls;
  }
  getUserBYStaffId() {
    const { staffId } = this.getUserForm.value;
    this.userService.getUserbasedOnUserType(staffId).subscribe((res) => {
      this.getUserForm.reset()
      this.isUserDetected = true
      this.selectedUserId = res.data._id
      this.resetPasswordForm.patchValue({
        name: res.data.name,
        staffId: res.data.staffId,
        businessUnit: res.data?.parentBussinessUnitId?.sectionId.departmentId.companyId.name + '>' + res.data?.parentBussinessUnitId?.sectionId.departmentId.name + '>' + res.data.parentBussinessUnitId?.sectionId.name + '>' + res.data?.parentBussinessUnitId?.name,
      })
    },
      (error) => {
        let toastMessage = error?.error?.message;
        this.toastService.error(toastMessage, 'failed', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
      })
  }
  onPasswordRest() {
    if (!this.resetPasswordForm.valid) {
      this.submitted = true
      return
    }
   const payload = { "staffId": this.resetPasswordForm.controls.staffId.value, "password": this.resetPasswordForm.controls.password.value };
   this.userService.updateResetPassword(payload).subscribe(
      (res) => {
        this.toastService.success('Password reset successfully')
        this.resetPasswordForm.reset()
        this.isUserDetected = false

      },
      (error) => {
        let toastMessage = (error?.error?.message).replace('Couldn\'t', 'Can\'t');
        this.toastService.error(toastMessage, 'failed', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
      })
  }
  onClearResetForm() {
    this.resetPasswordForm.reset()
    this.selectedUserId = null
    this.isUserDetected = false
  }
  onClear() {
    this.getUserForm.reset()
  }
}
