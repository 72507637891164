import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../service/auth/auth.service';
import {
  CustomValidators,
  MustMatch,
} from '../../../../shared/custom-validator/custom-validator';
import { CompanySetupService } from 'src/app/users/service/company-setup/company-setup.service';
import { take, tap, map, switchMap, finalize } from 'rxjs/operators';
import { companyNameResponse } from '../../../../core/model/users.model';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';

import * as recheck from 'recheck';
@Component({
  selector: 'app-welcomepage',
  templateUrl: './welcomepage.component.html',
  styleUrls: ['./welcomepage.component.scss']
})
export class WelcomepageComponent implements OnInit {
  isPasswordVisible:boolean=false;
  isPasswordVisibleAlt:boolean=false;
  public restPasswordState: string = 'initRest';
  public forgetPasswordForm: FormGroup;
  otpSentFor: number;
  isLoadingOnRequestRestPassword: Boolean = false;
  public otpVerifyForm: FormGroup;
  public otpForm: FormGroup;
  public passwordRestForm: FormGroup;
  public companyName;
  otpToken: string;
  validators = [];
  xforceCompanyId: string;
  CountryISO = CountryISO;
  loggedInUserName:string;
  loggedInUserID:string;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  SearchCountryField = SearchCountryField;
  isLoadingOtpSubmit: Boolean = false;
  isLoadingResetPassword: Boolean = false;
  public submitted3: boolean = false;

  constructor(
    private router: Router,
    private auth: AuthService,
    public toastService: ToastrService,
    private companyService: CompanySetupService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,

  ) { }

  ngOnInit(): void {
   this.loggedInUserName = localStorage.getItem("loggedInUserName");
   this.loggedInUserID = localStorage.getItem("loggedInUserId");
   const otpVerified = localStorage.getItem("otpVerified");
    if (otpVerified === "true") {
      this.otpToken = localStorage.getItem("otpToken");
      this.restPasswordState = 'passwordRest';
    }
    this.initForm();
    this.getPasswordSetting();
    this.route.params
    .pipe(
      take(1),
      tap((value) => (this.companyName = value.name)),
      map((v) => v.name),
      switchMap((name) => this.auth.fetchCompanyId(name))
    )
    .subscribe((res: companyNameResponse) => {
      this.forgetPasswordForm.patchValue({
        companyId: res.data._id,
      });
    });

  const urlParam = this.route.params
    .pipe(
      take(1),
      map((v) => v.name)
    )
    .subscribe((val) => {
      this.companyName = val;
      this.getCompanyId();
    });

  }
  getCompanyId() {
    if (this.companyName != 'xforce' && this.companyName != undefined) {
      const urlCompanyName = this.auth
        .fetchCompanyId(this.companyName)
        .pipe(
          finalize(() => {
            urlCompanyName.unsubscribe();
          })
        )
        .subscribe(
          (res: companyNameResponse) => {
            this.forgetPasswordForm.patchValue({
              companyId: res.data._id,
            });
          },
          (error) => {}
        );
    } else {
      if (this.f.companyId.value) {
        const urlxforce = this.auth
          .fetchCompanyId(this.forgetPasswordForm.controls.companyId.value)
          .pipe(
            finalize(() => {
              urlxforce.unsubscribe();
            })
          )
          .subscribe(
            (res: companyNameResponse) => {
              this.xforceCompanyId = res.data._id;
            },
            (error) => {}
          );
      }
    }
  }
  get phone() {
    return this.forgetPasswordForm.get('mobile');
  }

  get email() {
    return this.forgetPasswordForm.get('email');
  }

  get f() {
    return this.forgetPasswordForm.controls;
  }
  get otp() {
    return this.otpForm.controls;
  }
  get restPassword() {
    return this.passwordRestForm.controls;
  }
  private initForm() {
    this.forgetPasswordForm = this.formBuilder.group({
      companyId: ['', [Validators.required]],
      mobile: [null, [Validators.required]],
    });
    this.otpForm = this.formBuilder.group({
      token: ['', [Validators.required]],
      companyId: ['', [Validators.required]],
      staffId: ['', [Validators.required]],
    });
    this.otpVerifyForm = this.formBuilder.group({
      token: ['', [Validators.required]],
      otp: ['', [Validators.required]],
    });
  }

  onRequestRestPassword() {
    if (this.otpSentFor === 1) {
      const data = {
        staffId: this.forgetPasswordForm.value.staffId,
        email: this.forgetPasswordForm.value.email,
        countryCode: '',
        pathName:"sats"
      };
      const sub = this.auth
        .requestForgetPassword(data)
        .pipe(
          finalize(() => {
            sub.unsubscribe();
          })
        )
        .subscribe(
          (res) => {
            this.isLoadingOnRequestRestPassword = false;
            this.toastService.success(res.data.message);
            this.otpVerifyForm.patchValue({
              token: res?.data?.token,
            });
            this.otpToken=res?.data?.token;
            localStorage.setItem("otpVerified", JSON.stringify(res?.data?.firstLogin));
            this.restPasswordState = 'otpRest';
          },
          (error) => {
            console.log(error)
            this.isLoadingOnRequestRestPassword = false;
          }
        );
    } else if (this.otpSentFor === 2) {
      this.isLoadingOnRequestRestPassword = true;
      const { number } = this.forgetPasswordForm.get('mobile').value;

      const data = {
        userId: this.loggedInUserID,
        countryCode: this.forgetPasswordForm.value.mobile.dialCode,
        primaryMobileNumber: parseInt(
          number.replace(/[^0-9]/g, ''),
          10
        ).toString(),
        pathName:"sats"
      };

      const sub = this.auth
        .requestForgetPassword(data)
        .pipe(
          finalize(() => {
            sub.unsubscribe();
          })
        )
        .subscribe(
          (res) => {
            this.isLoadingOnRequestRestPassword = false;
            this.toastService.success(res.data.message);
            this.otpToken=res?.data?.token;
            localStorage.setItem("otpVerified", JSON.stringify(res?.data?.firstLogin));
            this.otpVerifyForm.patchValue({
              token: res?.data?.token,
            });
            this.restPasswordState = 'otpRest';
          },
          (error) => {
            this.toastService.error(error?.error?.message)
            this.isLoadingOnRequestRestPassword = false;
          }
        );
    }
  }

  resetPasswordForm() {
    this.passwordRestForm = this.formBuilder.group(
      {
        password: ['', [...this.validators, Validators.required]],
        confirmPassword: ['', Validators.required],
        token: ['', Validators.required],
      },
      {
        validator: MustMatch('password', 'confirmPassword'),
      }
    );
 }
 onSubmitOtp() {
  if (!this.otpVerifyForm.valid) {
    this.toastService.error('Please Enter the OTP');
    return;
  }
  this.isLoadingOtpSubmit = true;
  let data={token:this.otpVerifyForm.get('token').value, otp:this.otpVerifyForm.get('otp').value}
  const sub = this.auth
    .verifyOTP(data)
    .pipe(
      finalize(() => {
        sub.unsubscribe();
      })
    )
    .subscribe(
      (res) => {
        this.isLoadingOtpSubmit = false;
        this.restPasswordState = 'passwordRest';
        this.toastService.success('OTP verified successfully');
      },
      (error) => {
        this.isLoadingOtpSubmit = false;
        this.toastService.error("OTP is either expired or incorrect, Please enter the correct OTP");

      }
    );
}
 goBack() {
  this.restPasswordState = 'initRest';
}
navigateToLogin() {
  this.router.navigate(['/users/login/', this.companyName]);
}
async getValidatorFnArray(setting?) {
  const validators = [];

  if (setting?.charTypes?.lowerCase) {
    validators.push(
      CustomValidators.patternValidator(/[a-z]/, {
        hasSmallCase: true,
      })
    );
  }
  if (setting?.charTypes?.specialChar) {
    validators.push(
      CustomValidators.patternValidator(
        /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
        {
          hasSpecialCharacters: true,
        }
      )
    );
  }

  if (setting?.charTypes?.upperCase) {
    validators.push(
      CustomValidators.patternValidator(/[A-Z]/, {
        hasCapitalCase: true,
      })
    );
  }

  if (setting?.charTypes?.numbers) {
    validators.push(
      CustomValidators.patternValidator(/\d/, {
        hasNumber: true,
      })
    );
  }

  if (setting?.charLength) {
    const len = setting.charLength;
    let test = `^.{${len},22}`;
    let recheckResp = await recheck.check(test, "")
    if(recheckResp.status === 'safe'){
    let regex = new RegExp(test);
    validators.push(
      CustomValidators.patternValidator(
        regex,
        {
          minlength: true,
          length: setting.charLength,
        }
      )
    );
      }
  }

  this.validators.push(...validators);
  this.resetPasswordForm();
}
getPasswordSetting() {
  const sub = this.companyService
    .getPasswordSetting()
    .pipe(
      finalize(() => {
        sub.unsubscribe();
      })
    )
    .subscribe(
      (res) => {
        this.getValidatorFnArray(res.data?.pageSettingData?.pwdSettings);
        this.otpSentFor = res?.data?.pageSettingData?.pwdSettings?.otpSentFor;
      },
      (error) => {
        console.log(error);
      }
    );
}
onRestPassword() {
  this.passwordRestForm.patchValue({
    token: this.otpToken,
  });
  if (!this.passwordRestForm.valid) {
    this.submitted3 = true;
    return;
  }

  let data = {
    password: this.passwordRestForm.get('confirmPassword').value,
    token: this.passwordRestForm.value.token,
  };
  this.isLoadingResetPassword = true;
  const sub = this.auth
    .restPassword(data)
    .pipe(
      finalize(() => {
        sub.unsubscribe();
      })
    )
    .subscribe(
      (res) => {
        this.isLoadingResetPassword = false;
        this.toastService.success('Password Updated successfully');
        this.passwordRestForm.patchValue({
          token: res.data.token,
        });
        this.auth.logout();
      },
      (error) => {
        this.isLoadingResetPassword = false;
      }
    );
}

  passwordVisible() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  confirmPasswordVisible() {
    this.isPasswordVisibleAlt = !this.isPasswordVisibleAlt;
  }
}
