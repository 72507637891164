import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'checkboxs',
    template: `
        <div [formGroup]="form" >
          
          <div *ngFor="let opt of field.options" class="form-check form-check">
          <label class="form-check-label">
             <input [formControlName]="opt._id" class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
             {{opt.value}}</label>
          </div>
      </div>
    `
})
export class CheckBoxsComponent {
    @Input() field:any = {};
    @Input() form:FormGroup;
    get isValid() { return this.form.controls[this.field.question].valid; }
    get isDirty() { return this.form.controls[this.field.question].dirty; }
}
