<div class="card">
  <div
    class="card-header d-flex align-items-center justify-content-between bg-theme"
  >
    <h5 class="m-0">CAPTURE ATTENDANCE</h5>
    <div>
      <button class="btn btn-sm btn-info">Export Timesheet</button>
      <button
        class="btn btn-sm btn-outline-light ml-1"
        (click)="redirectToOverview()"
      >
        Back
      </button>
    </div>
  </div>

  <div class="card-body">
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label class="font-weight-500"> Staff ID/Name</label>
          <input
            type="text"
            readonly
            class="form-control"
            value="{{ dataAttendance?.userInfo.name }}"
          />
        </div>
      </div>
    </div>

    <hr />

    <div class="row">
      <div class="col-md-12">
        <h5 class="font-weight-500 m-0">
          {{ dataAttendance?.userInfo.name }}
        </h5>
        <p class="text-muted">{{ dataAttendance?.appointmentInfo?.name }}</p>
      </div>
      <div class="col-md-4">
        <img
          #originalImg
          *ngIf="userImage"
          [src]="userImage"
          class="img-fluid border rounded mb-2 shadow"
          alt=""
        />
        <div class="form-group">
          <label class="font-weight-500">Staff ID</label>
          <input
            type="text"
            class="form-control"
            [value]="dataAttendance?.userInfo.staffId"
            readonly
          />
        </div>
        <div class="form-group">
          <label class="font-weight-500">Phone no.</label>
          <input
            type="text"
            class="form-control"
            readonly
            value="{{
              dataAttendance?.userInfo.contactNumber
                ? dataAttendance?.userInfo.contactNumber
                : '--'
            }}"
          />
        </div>
      </div>
      <div class="col-md-2">
        <div class="shiftTime">
          <div class="ShiftStart rounded">
            <label for="name" class="font-weight-bold">Shift Start</label>
            <label for="name" class="font-weight-bold">{{
              dataAttendance?.shiftDetails.startTime | date: "HH:mm"
            }}</label>
          </div>
          <div class="ShiftStart bg-success mt-2 rounded">
            <label for="name" class="font-weight-bold">Shift End</label>
            <label for="name" class="font-weight-bold">{{
              dataAttendance?.shiftDetails.endTime | date: "HH:mm"
            }}</label>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div *ngIf="isCameraExist; then openCamera; else noCameraExist"></div>
        <ng-template #openCamera>
          <webcam
            [height]="400"
            [width]="800"
            [trigger]="triggerObservable"
            (imageCapture)="handleImage($event)"
            *ngIf="clockInCam == 'facial'"
            [switchCamera]="nextWebcamObservable"
          >
          </webcam>
          <zxing-scanner
            *ngIf="clockInCam == 'QR'"
            #scanner
            [formats]="['QR_CODE', 'EAN_13']"
            (camerasFound)="cameraFound($event)"
            (camerasNotFound)="camerasNotFound($event)"
            (scanSuccess)="scanSuccessHandler($event)"
            class="d-flex"
          >
          </zxing-scanner>
          <img
            #snapImg
            *ngIf="webcamImage"
            [src]="webcamImage.imageAsDataUrl"
            class="img-fluid border rounded mb-2 shadow"
            alt=""
          />
        </ng-template>

        <ng-template #noCameraExist> Camera device not available </ng-template>

        <div class="d-flex justify-content-between align-items-center mt-2">
          <div>
            <ng-container *ngIf="facialActionBtn">
              <button class="btn btn-info mr-2" (click)="triggerSnapshot()">
                Capture Photo
              </button>
              <button class="btn btn-success mr-2" (click)="verifyPhoto()">
                {{ loadingText }}
              </button>
            </ng-container>

            <button class="btn btn-dark" (click)="qrScan()" [hidden]="!dataAttendance?.isFacial || dataAttendance?.attendance?.attendanceMode == 'Qr'">QR scan</button>
            <button
              class="btn btn-warning ml-2"
              (click)="takeManualAttendance()"
            >
              Manual
            </button>

            <button class="btn btn-danger ml-2" (click)="redirectToOverview()">
              Cancel/Back
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
