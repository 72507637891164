<div class="card mb-3" *ngIf="createForm.get('isDeployed').value === null && !isformEdited"
  style="width: 36.5em; margin: 0 auto">
  <div class="card-header flexi-header p-4">
    <h3>PLEASE SELECT FORM TYPE</h3>
  </div>
  <div class="card-body">
    <div class="d-flex justify-content-between m-1">
      <button class="btn btn-clear p-2 btn-block" (click)="chooseFormType(1)">
        INTERNAL
      </button>
      <button style="margin: 2px" class="btn btn-submit p-2 btn-block" (click)="chooseFormType(2)">
        EXTERNAL
      </button>
    </div>
  </div>
</div>

<div *ngIf="createForm.get('isDeployed').value === 1">
  <mat-horizontal-stepper [linear]="isLinear" #stepper>
    <mat-step [stepControl]="firstFormGroup">
      <form [formGroup]="createForm">
        <ng-template matStepLabel> STEP 1 </ng-template>
        <div class="card">
          <div class="card-header flexi-header p-4">
            <h3>FORM</h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold" for="exampleInputEmail1">Form Title<span class="field-mandatory">*</span></label>
                  <input type="text" class="form-control" formControlName="title" placeholder="Please enter Form Title here" />
                </div>
                <div class="custom-logo">
                  <div class="custom-file mt-0 w-50 pt-4 pb-4" style="width: 67px" *ngIf="!createForm.get('formLogo').value">
                    <input type="file" style="position: absolute; z-index: 9; visibility: visible; left: 0; top: 0;
                                                     width: 100%; opacity: 0;" (change)="iconUpload($event)" required id="b" />
                    <label class="font-weight-bold" class="custom-file-label" for="b">
                      Upload Form Logo
                    </label>
                  </div>
                </div>
                <div class="field-mandatory_custom-logo">
                  <span class="field-mandatory custom-logo-mandatory">*</span>
                </div>
                <div *ngIf="createForm.get('formLogo').value">
                  <div class="image-with-delete-icon" style="width: 300px">
                    <i class="fa fa-close" title="Delete" for="4" (click)="ondeleteImage()"></i>
                    <img [src]="validateURLImage(createForm.get('formLogo').value)" width="300px" height="300px" id="4" alt="Image Preview"
                      crossorigin="anonymous" />
                  </div>
                </div>

                <div class="form-group mt-2">
                  <label class="font-weight-bold" for="exampleInputEmail1">Form Description<span class="field-mandatory">*</span></label>
                  <input type="text" class="form-control" formControlName="description" placeholder="Please enter Form Description here" />
                </div>
                <div class="">
                  <div class="form-group pr-0">
                    <input type="checkbox" formControlName="quickNavEnabled" /> Allow
                    this form to be placed in quick navigation
                  </div>
                </div>
                <div class="">
                  <div class="form-group pr-0">
                    <input type="checkbox" formControlName="viewOnly" />
                    Allow Form Values to be Edited By Administrator after submission
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-12">
                    <label class="font-weight-bold">Select Questionnaire<span class="field-mandatory">*</span>
                    </label>
                    <ng-select (change)="addQuestionaari($event)" class="custom" bindLabel="name" placeholder="Select Questionnaire"
                      appendTo="body" [multiple]="false" [searchable]="true" [clearable]="true" [(ngModel)]="questionDropDownToClear"
                      [ngModelOptions]="{ standalone: true }">
                      <ng-option [value]="item" *ngFor="let item of questionaarie">
                        {{ item.moduleName }}
                      </ng-option>
                    </ng-select>
                  </div>
                  <div class="col-3 mt-3" *ngIf="createForm.get('moduleId.string').value">
                    <div class="flex-SB">
                      <span class="text-items list-ui">
                        {{ createForm.get("moduleId.string").value }}
                      </span>
                      <div style="margin-top: 10px">
                        <i class="fa fa-times-circle fa-2x" (click)="deleteQuestionaari()" aria-hidden="true"
                          style="color: #a42138"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <button class="btn btn-danger" (click)="goForward(stepper)">Proceed to Next Step</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup">
      <!-- [formGroup]="createNewsForm" -->
      <form>
        <ng-template matStepLabel>STEP 2</ng-template>
        <assign-users [isAdminView]="false" [userDetails]="userDetails"
          (onSubmitAssignUsers)="updateDUserDetails($event)" (getAssignUsersData)="getUserBasedOnBuUnits($event)"
          (showCreateButton)="showCreateButton()" (hideCreateButton)="hideCreateButton()">
        </assign-users>
        <div class="ml-1" *ngIf="isNextBtnActive">
          <button class="btn btn-warning mt-4" (click)="stepper.previous()">
            Back
          </button>
          <button class="btn btn-danger ml-1 mt-4" (click)="stepper.next()">
            Next Step
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="thirdFormGroup">
      <ng-template matStepLabel> STEP 3</ng-template>
      <div class="card mb-3">
        <div class="card-header flexi-header p-4">
          <h3>WORKFLOW</h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <form [formGroup]="workFlowForm">
                <div class="form-group">
                  <label class="font-weight-bold" for="exampleInputEmail1">Workflow Title<span class="field-mandatory">*</span></label>
                  <input type="text" class="form-control" formControlName="title" placeholder="Please enter Workflow Title here" />
                </div>
              </form>
              <form [formGroup]="workflowstatusForm">
                <div class="form-group">
                  <label class="font-weight-bold" for="exampleInputEmail1">Workflow Values<span class="field-mandatory">*</span></label>
                  <div class="row">
                    <div class="col-md-6">
                      <input type="text" class="form-control" formControlName="field" placeholder="Please enter Workflow Value here" />
                    </div>
                    <div class="col-md-2">
                      <input type="color" class="form-control" formControlName="color" placeholder="Please enter Workflow Value here" />
                    </div>
                    <div class="col-md-3">
                      <button class="btn btn-add" (click)="adddWorkFlowStatus()">
                        Add
                      </button>
                    </div>
                  </div>
                </div>
                <div class="">
                  <div class="form-group pr-0">
                    <input type="checkbox" id="ter" formControlName="isDefault" />
                    <label for="ter" class="ml-2">
                      Select as default value
                    </label>
                  </div>
                </div>
              </form>

              <form [formGroup]="workFlowForm">
                <div class="row" *ngIf="workFlowStatusControl.length != 0">
                  <div class="col-md-12">
                    <table class="table table-borderless mt-3">
                      <tbody>
                        <tr formArrayName="workflowStatus" *ngFor="
                      let fieldObj of workFlowStatusControl.controls;
                      let index = index
                    ">
                          <td>
                            <div *ngIf="fieldObj.get('isDefault').value === true">
                              <span class="badge badge-warning" [formGroupName]="index">
                                Default
                              </span>
                            </div>
                          </td>
                          <td>
                            <span class="text-items list-workflow" style="word-break: break-all" [formGroupName]="index"
                              [style.backgroundColor]="fieldObj.get('color').value">{{ fieldObj.get("field").value }}
                              <img class="ml-2" alt="" (click)="deleteQuestions(index)" src="./assets/images/cancel.png"
                                width="20px" height="20px" crossorigin="anonymous" /></span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="form-group">
                  <label class="font-weight-bold" for="exampleInputEmail1">Administrator<span class="field-mandatory">*</span></label>
                  <ng-select (change)="addWorkFlowAdmin($event)" class="custom" bindLabel="name"
                    placeholder="Select admin" appendTo="body" [multiple]="false" [searchable]="true" [clearable]="true"
                    [virtualScroll]="true" [(ngModel)]="workFlowAdminDropDownToClear"
                    [ngModelOptions]="{ standalone: true }">
                    <ng-option [value]="item" *ngFor="let item of userDropDown">
                      {{ item.name }}
                    </ng-option>
                  </ng-select>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div style="
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                      ">
                      <div formArrayName="admin" *ngFor="
                          let field of workFlowAdminControl.controls;
                          let index = index
                        ">
                        <span class="text-items list-ui" [formGroupName]="index">{{ field.get("name").value }}
                          <img class="ml-3" (click)="deleteWorkFlowAdmin(index)" src="./assets/images/cancel.png"
                            width="20px" height="20px" crossorigin="anonymous" /></span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-md-6">
              <form [formGroup]="workFlowForm">
                <div class="form-group">
                  <label for="" class="font-weight-bold">Workflow Type<span class="field-mandatory">*</span></label>
                  <div class="d-flex flex-column">
                    <div class="radio radio-danger radio-inline p-1">
                      <input type="radio" formControlName="type" id="postSubReqTrue" [value]="1" />
                      <label for="postSubReqTrue"> Common Workflow</label>
                    </div>
                    <div class="radio radio-danger radio-inline mt-2 p-1">
                      <input type="radio" id="postSubReqFalse" formControlName="type" [value]="2" (click)="
                          getQuestions(createForm.get('moduleId._id').value)
                        " />
                      <label for="postSubReqFalse">
                        Conditional workflow based on specific question
                        response</label>
                    </div>

                    <div class="row mt-2" *ngIf="workFlowForm.get('type').value === 2">
                      <div class="col-md-12">
                        <label class="font-weight-bold">Question Title</label>
                        <ng-select (change)="getContionalAnswers($event)" class="custom" bindLabel="name"
                          placeholder=" Questionnaire" appendTo="body" [multiple]="false" [searchable]="false"
                          [clearable]="true" formControlName="question">
                          <ng-option [value]="ques" *ngFor="let ques of questions">
                            {{ ques.question }}
                          </ng-option>
                        </ng-select>
                      </div>

                      <div class="col-md-12 mt-3" *ngIf="answers.length != 0">
                        <label class="font-weight-bold">Answers</label>

                        <ng-select class="custom" bindLabel="name" placeholder=" Answers" appendTo="body"
                          [multiple]="false" [searchable]="true" [clearable]="true" formControlName="answer">
                          <ng-option [value]="item" *ngFor="let item of answers">
                            {{ item.value }}
                          </ng-option>
                        </ng-select>
                        <button class="btn btn-add p-2 m-2 pull-right" (click)="addQuestionAndAnswer()">
                          Add
                        </button>
                      </div>
                    </div>

                    <div class="col-md-12" *ngIf="workFlowForm.get('type').value === 2">
                      <div class="questions-container" formArrayName="questionResponse" *ngFor="
                        let field of questionResponseControl.controls;
                        let index = index
                      ">
                        <td>
                          <div>
                            Q -
                            <p class="questions" [formGroupName]="index" innerHtml="{{field.get('question').value}}">
                            </p>
                          </div>
                          <span class="text-items mt-2" style="word-break: break-all" [formGroupName]="index">
                            <p> A - {{field.get('answer').value}}</p>
                          </span>
                        </td>
                        <td class="cancel-button">
                          <img class="ml-1 cancel-icon" alt="" (click)="formsQuestionDelete(index)"
                            src="./assets/images/cancel.png" width="20px" height="20px" crossorigin="anonymous" />
                          <button class="btn btn-red-back ml-2"
                            (click)="editQuestions(field.value, index)">Edit</button>
                      </div>
                    </div>

                    <div class="radio radio-danger radio-inline p-1 mt-2">
                      <input type="radio" id="postSubReqFalse1" formControlName="type" [value]="3" />
                      <label for="postSubReqFalse1">
                        Conditional workflow based on specific workflow status
                      </label>
                    </div>
                    <div class="row mt-2" *ngIf="workFlowForm.get('type').value === 3">
                      <div class="col-md-12">
                        <label class="font-weight-bold">Select a workflow form</label>
                        <ng-select (change)="getContionalWorkflowID($event)" class="custom" bindLabel="name"
                          placeholder="" appendTo="body" [ngModelOptions]="{standalone: true}" [multiple]="false"
                          [searchable]="false" [clearable]="true" formControlName="workflow">
                          <ng-option [value]="item" *ngFor="let item of workFlow">
                            {{ item.title }}
                          </ng-option>
                        </ng-select>
                      </div>

                      <div class="col-md-12 mt-3" *ngIf="workFlowDropDown.length != 0">
                        <ng-select (change)="getContionalWorkflowAnswer($event)" class="custom" bindLabel="name"
                          placeholder="" appendTo="body" [multiple]="false" [searchable]="true" [clearable]="true"
                          formControlName="workFlowAnswer" [ngModelOptions]="{standalone: true}">
                          <ng-option [value]="item" *ngFor="let item of workFlowDropDown">
                            {{ item.field }}
                          </ng-option>
                        </ng-select>
                        <button class="btn btn-add p-2 m-2 pull-right" (click)="showworkflowResponse()">
                          Add
                        </button>
                      </div>
                    </div>
                    <div class="col-md-12 workFlow_item" *ngIf="workFlowForm.get('type').value === 3">
                      <div formArrayName="workflowResponse" *ngFor="
                        let field of workflowResponseControl.controls;
                        let index = index
                      ">
                        <td class="workFlow">
                          <div class="Selected">
                            <b>Selected workflow:</b> {{field.get('question').value}} => {{field.get('workFlowAnswer').value}}
                          </div>
                          <div class="cancel">
                            <img class="ml-1 cancel-icon" alt="" (click)="formsWorkflowDelete(index)" src="./assets/images/cancel.png"
                              width="20px" height="20px" crossorigin="anonymous" />
                          </div>
                        </td>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="">
                  <div class="form-group pr-0">
                    <input type="checkbox" id="ter1" formControlName="userStatusVisibility" />
                    <label for="ter1"> &nbsp; Show workflow to staff </label>
                  </div>
                </div>
                <div class="form-group">
                  <label class="font-weight-bold" for="exampleInputEmail1">Additional questionnaire for the admin of
                    this workflow:
                  </label>
                  <ng-select class="custom" bindLabel="name" placeholder="Additional questionnaire" appendTo="body"
                    [multiple]="false" [searchable]="true" (change)="addAdditionalModuleId($event)" [clearable]="true"
                    [(ngModel)]="WorkFlowAddFieldDropDownToClear" [ngModelOptions]="{standalone: true}">
                    <ng-option [value]="item" *ngFor="let item of questionaarie">
                      {{ item.moduleName }}
                    </ng-option>
                  </ng-select>
                  <div class="col-md-6 mt-3" *ngIf="workFlowForm.get('additionalModuleId.string').value">
                    <div class="row">
                      <div class="col-md-12">
                        <div style="
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                          ">
                          <div >
                            <span class="text-items list-ui pr-1"> {{ workFlowForm.get("additionalModuleId.string").value}}
                              <img class="ml-3" (click)="deleteAditionalModule()" src="./assets/images/cancel.png"
                                width="20px" height="20px" crossorigin="anonymous" /></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="d-flex pull-right">
            <button class="btn btn-add" *ngIf="!isWorkflowEdited" [disabled]="!this.workFlowForm.valid"
              (click)="addWorkFlow()">
              Add WorkFlow
            </button>
            <button class="btn btn-submit" *ngIf="isWorkflowEdited" (click)="updateWorkFlow()">
              Update
            </button>
            <button class="btn btn-danger ml-3" (click)="stepper.previous()">Back</button>
          </div>
        </div>
      </div>
      <div class="card mb-3" *ngIf="workFlow.length != 0">
        <div class="card-header flexi-header">
          <h3>SELECTED WORKFLOWS INFORMATION</h3>
        </div>

        <div class="card-body">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>WORKFLOW TITLE</th>
                  <th>WORKFLOW TYPE</th>
                  <th>WORKFLOW VALUES</th>
                  <th>ADDITIONAL QUESTIONNAIRE</th>

                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of workFlow; let index = index">
                  <td>
                    {{ item.title }}
                  </td>

                  <td>
                    {{ item.type }}
                  </td>
                  <td>
                    <ul *ngFor="let item of item.workflowStatus">
                      <li>{{ item.field }}</li>
                    </ul>
                  </td>
                  <td>
                    {{ item?.additionalModuleId?.string === "undefined" || item?.additionalModuleId?.string === null ?
                    "No" : "Yes" }}
                  </td>

                  <td>
                    <button class="btn btn-add p-2 m-1" [disabled]="isWorkflowEdited"
                      (click)="editWorkflow(item, index)">
                      <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-danger p-2 m-1" [disabled]="isWorkflowEdited"
                      (click)="deleteWorkFlow(index)">
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- <button class="btn btn-clear" *ngIf="workFlow.length != 0" (click)="ClearWorkFlowForm()">
            Clear All
          </button> -->
        </div>
      </div>
      <div class="flex-direction p-3">
        <button class="btn btn-submit float-right" *ngIf="!formId"
          [disabled]=" isLoadingSubmit || workFlow.length === 0" (click)="onSubmitWorkFlow()">
          <i class="fa" [ngClass]="{
              'fa-spin fa-spinner': isLoadingSubmit,
              '': !isLoadingSubmit
            }"></i>
          Publish
        </button>
        <button class="btn btn-submit float-right" *ngIf="formId" [disabled]="isLoadingUpdate && workFlow.length !== 0"
          (click)="onUpdateWorkFlow()">
          <i class="fa" [ngClass]="{
              'fa-spin fa-spinner': isLoadingUpdate,
              '': !isLoadingUpdate
            }"></i>
          Update
        </button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>

<div *ngIf="createForm.get('isDeployed').value === 2">
  <mat-horizontal-stepper [linear]="isLinear" #stepper>
    <mat-step [stepControl]="fourFormGroup">
      <ng-template matStepLabel> STEP 1 </ng-template>
      <div class="card mb-3 w-100">
        <div class="card-header flexi-header">
          <h3>FORM</h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <form [formGroup]="createForm">
                <div class="form-group">
                  <label class="font-weight-bold" for="exampleInputEmail1">Form Title</label>
                  <input type="text" class="form-control" formControlName="title" placeholder="Please enter Form Title here" />
                </div>
                <div class="custom-file mt-0 w-50 pt-4 pb-4" style="width: 67px"
                  *ngIf="!createForm.get('formLogo').value">
                  <input type="file" style="
                      position: absolute;
                      z-index: 9;
                      visibility: visible;
                      left: 0;
                      top: 0;
                      width: 100%;
                      opacity: 0;
                    " (change)="iconUpload($event)" required id="b" />
                  <label class="font-weight-bold" class="custom-file-label" for="b">
                    Upload Form Logo
                  </label>
                </div>
                <div *ngIf="createForm.get('formLogo').value">
                  <div class="image-with-delete-icon" style="width: 300px">
                    <i class="fa fa-close" title="Delete" for="4" (click)="ondeleteImage()"></i>
                    <img [src]="validateURLImage(createForm.get('formLogo').value)" width="300px" height="300px" id="4"
                      alt="Image Preview" crossorigin="anonymous" />
                  </div>
                </div>
                <div class="form-group mt-3">
                  <label class="font-weight-bold" for="exampleInputEmail1">Form Description</label>
                  <input type="text" class="form-control" formControlName="description" placeholder="Please enter Form Description here" />
                </div>
                <div class="">
                  <div class="form-group pr-0">
                    <input type="checkbox" formControlName="quickNavEnabled" /> &nbsp;
                    Allow this form to be placed in quick navigation
                  </div>
                </div>
                <div class="">
                  <div class="form-group pr-0">
                    <input type="checkbox" formControlName="viewOnly" /> &nbsp;
                    Allow Form Values to be Edited By Administrator after submission
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-12">
                    <label class="font-weight-bold">Select Questionnaire
                    </label>
                    <ng-select (change)="addQuestionaari($event)" class="custom" bindLabel="name"
                      placeholder="Select Questionnaire" appendTo="body" [multiple]="false" [searchable]="true"
                      [clearable]="true" [(ngModel)]="questionDropDownToClear" [ngModelOptions]="{ standalone: true }">
                      <ng-option [value]="item" *ngFor="let item of questionaarie">
                        {{ item.moduleName }}
                      </ng-option>
                    </ng-select>
                  </div>
                  <div class="col-md-6 mt-3" *ngIf="createForm.get('moduleId.string').value">
                    <div class="flex-SB">
                      <span class="text-items list-ui">
                        {{ createForm.get("moduleId.string").value }}
                      </span>
                      <div style="margin-top: 10px">
                        <i class="fa fa-times-circle fa-2x" (click)="deleteQuestionaari()" aria-hidden="true"
                          style="color: #a42138"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button class="btn btn-danger mt-4" (click)="stepper.next()">
          Next
        </button>
      </div>
    </mat-step>

    <mat-step [stepControl]="fiveFormGroup">
      <ng-template matStepLabel> STEP 2</ng-template>
      <div class="card mb-3 w-100">
        <div class="card-header flexi-header">
          <h3>WORKFLOW</h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <form [formGroup]="workFlowForm">
                <div class="form-group">
                  <label class="font-weight-bold" for="exampleInputEmail1">Workflow Title</label>
                  <input type="text" class="form-control" formControlName="title" placeholder="Please enter Workflow Title here" />
                </div>
              </form>
              <form [formGroup]="workflowstatusForm">
                <div class="form-group">
                  <label class="font-weight-bold" for="exampleInputEmail1">Workflow Values</label>
                  <div class="row">
                    <div class="col-md-6">
                      <input type="text" class="form-control" formControlName="field" placeholder="Please enter Workflow Value here" />
                    </div>
                    <div class="col-md-2">
                      <input type="color" class="form-control" formControlName="color" placeholder="Please enter Workflow Value here" />
                    </div>
                    <div class="col-md-3">
                      <button class="btn btn-add" [disabled]="!workflowstatusForm.valid" (click)="adddWorkFlowStatus()">
                        Add
                      </button>
                    </div>
                  </div>
                </div>
                <div class="">
                  <div class="form-group pr-0">
                    <input type="checkbox" id="ter" formControlName="isDefault" />
                    <label for="ter"> &nbsp; Select as default value </label>
                  </div>
                </div>
              </form>

              <form [formGroup]="workFlowForm">
                <div class="row" *ngIf="workFlowStatusControl.length != 0">
                  <div class="col-md-12">
                    <table class="table table-borderless mt-3">
                      <tbody>
                        <tr formArrayName="workflowStatus" *ngFor="
                            let field of workFlowStatusControl.controls;
                            let index = index
                          ">
                          <td>
                            <div *ngIf="field.get('isDefault').value === true">
                              <span class="badge badge-warning" [formGroupName]="index">
                                Default
                              </span>
                            </div>
                          </td>
                          <td>
                            <span class="text-items list-workflow" style="word-break: break-all" [formGroupName]="index"
                              [style.background]="field.get('color').value">{{ field.get("field").value }}
                              <img class="ml-2" alt="" (click)="deleteQuestions (index)"
                                src="./assets/images/cancel.png" width="20px" height="20px"
                                crossorigin="anonymous" /></span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="form-group">
                  <label class="font-weight-bold" for="exampleInputEmail1">Administrator</label>
                  <ng-select (change)="addWorkFlowAdmin($event)" class="custom" bindLabel="name"
                    placeholder="Select Admin" appendTo="body" [multiple]="false" [searchable]="true" [clearable]="true"
                    [virtualScroll]="true" [(ngModel)]="workFlowAdminDropDownToClear"
                    [ngModelOptions]="{ standalone: true }">
                    <ng-option [value]="item" *ngFor="let item of userDropDown">
                      {{ item.name }}
                    </ng-option>
                  </ng-select>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div style="
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                      ">
                      <div formArrayName="admin" *ngFor="
                          let field of workFlowAdminControl.controls;
                          let index = index
                        ">
                        <span class="text-items list-ui" [formGroupName]="index">{{ field.get("name").value }}
                          <img class="ml-3" (click)="deleteWorkFlowAdmin(index)"
                            src="./assets/images/cancel.png" width="20px" height="20px"
                            crossorigin="anonymous" /></span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-md-6">
              <form [formGroup]="workFlowForm">
                <div class="form-group">
                  <label for="" class="font-weight-bold">Workflow Type</label>
                  <div class="d-flex flex-column">
                    <div class="radio radio-danger radio-inline p-1">
                      <input type="radio" formControlName="type" id="postSubReqTrue" [value]="1" />
                      <label for="postSubReqTrue"> Common Workflow</label>
                    </div>
                    <div class="radio radio-danger radio-inline p-1">
                      <input type="radio" id="postSubReqFalse" formControlName="type" [value]="2" (click)="
                          getQuestions(createForm.get('moduleId._id').value)
                        " />
                      <label for="postSubReqFalse">
                        Conditional workflow based on specific question
                        response</label>
                    </div>

                    <div class="row mt-2" *ngIf="workFlowForm.get('type').value === 2">
                      <div class="col-md-12">
                        <label class="font-weight-bold">Question Title</label>
                        <ng-select (change)="getContionalAnswers($event)" class="custom" bindLabel="name"
                          placeholder="Questionnaire" appendTo="body" [multiple]="false" [searchable]="false"
                          [clearable]="true" formControlName="question">
                          <ng-option [value]="ques" *ngFor="let ques of questions">
                            {{ ques.question }}
                          </ng-option>
                        </ng-select>
                      </div>

                      <div class="col-md-12 mt-3" *ngIf="answers.length != 0">
                        <label class="font-weight-bold">Answers</label>

                        <ng-select class="custom" bindLabel="name" placeholder=" Answers" appendTo="body"
                          [multiple]="false" [searchable]="true" [clearable]="true" formControlName="answer">
                          <ng-option [value]="item" *ngFor="let item of answers">
                            {{ item.value }}
                          </ng-option>
                        </ng-select>
                        <button class="btn btn-add p-2 m-2 pull-right" (click)="addQuestionAndAnswer()">
                          Add
                        </button>
                      </div>
                    </div>

                    <div class="col-md-12" *ngIf="workFlowForm.get('type').value === 2">
                      <div class="questions-container" formArrayName="questionResponse" *ngFor="
                        let field of questionResponseControl.controls;
                        let index = index">
                        <div>
                          <div>
                            Q -
                            <p class="questions" [formGroupName]="index" innerHtml="{{field.get('question').value}}">
                            </p>
                          </div>
                          <span class="text-items" style="word-break: break-all" [formGroupName]="index">
                            <p> A - {{field.get('answer').value}}</p>
                          </span>
                        </div>
                        <div class="cancel-button">
                          <img class="ml-1 cancel-icon" alt="" (click)="formsQuestionDelete(index)"
                            src="./assets/images/cancel.png" width="20px" height="20px" crossorigin="anonymous" />
                        </div>
                      </div>
                    </div>

                    <div class="radio radio-danger radio-inline p-1 mt-2">
                      <input type="radio" id="postSubReqFalse1" formControlName="type" [value]="3" />
                      <label for="postSubReqFalse1">
                        Conditional workflow based on specific workflow status
                      </label>
                    </div>
                    <div class="row mt-2" *ngIf="workFlowForm.get('type').value === 3">
                      <div class="col-md-12">
                        <label class="font-weight-bold">Select a workflow form</label>
                        <ng-select (change)="getContionalWorkflowID($event)" class="custom" bindLabel="name"
                          placeholder="" appendTo="body" [multiple]="false" [searchable]="false" [clearable]="true"
                          formControlName="workflow">
                          <ng-option [value]="item" *ngFor="let item of workFlow">
                            {{ item.title }}
                          </ng-option>
                        </ng-select>
                      </div>

                      <div class="col-md-12 mt-3" *ngIf="workFlowDropDown.length != 0">
                        <ng-select (change)="getContionalWorkflowAnswer($event)" class="custom" bindLabel="name"
                          placeholder="" appendTo="body" [multiple]="false" [searchable]="true" [clearable]="true"
                          formControlName="workFlowAnswer">
                          <ng-option [value]="item" *ngFor="let item of workFlowDropDown">
                            {{ item.field }}
                          </ng-option>
                        </ng-select>
                        <button class="btn btn-add p-2 m-2 pull-right" (click)="showworkflowResponse()">
                          Add
                        </button>
                      </div>
                    </div>
                    <div class="col-md-12" *ngIf="workFlowForm.get('type').value === 3">
                      <div class="questions-container" formArrayName="workflowResponse" *ngFor="
                        let field of workflowResponseControl.controls;
                        let index = index
                      ">
                        <td>
                          <div>
                            Q -
                            <p class="questions" innerHtml="{{field.get('question').value}}"></p>
                          </div>
                          <div>
                            <p> A - {{field.get('workFlowAnswer').value}}</p>
                          </div>
                        </td>
                        <td class="cancel-button">
                          <img class="ml-1 cancel-icon" alt="" (click)="formsWorkflowDelete(index)"
                            src="./assets/images/cancel.png" width="20px" height="20px" crossorigin="anonymous" />
                        </td>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="">
                  <div class="form-group pr-0">
                    <input type="checkbox" id="ter1" formControlName="userStatusVisibility" />
                    <label for="ter1"> &nbsp; Show workflow to staff </label>
                  </div>
                </div>

                <div class="form-group">
                  <label class="font-weight-bold" for="exampleInputEmail1">Additional questionnaire for the admin of
                    this workflow:
                  </label>
                  <ng-select class="custom" bindLabel="name" placeholder="Additional questionnaire" appendTo="body"
                    [multiple]="false" [searchable]="true" (change)="addAdditionalModuleId($event)" [clearable]="true"
                    [(ngModel)]="WorkFlowAddFieldDropDownToClear" [ngModelOptions]="{standalone: true}">
                    <ng-option [value]="item" *ngFor="let item of questionaarie">
                      {{ item.moduleName }}
                    </ng-option>
                  </ng-select>
                  <div class="col-md-6 mt-3" *ngIf="workFlowForm.get('additionalModuleId.string').value">
                    <div class="row">
                      <div class="col-md-12">
                        <div style="
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                          ">
                          <div >
                            <span class="text-items list-ui pr-1"> {{  workFlowForm.get("additionalModuleId.string").value}}
                              <img class="ml-3" (click)="deleteAditionalModule()" src="./assets/images/cancel.png"
                                width="20px" height="20px" crossorigin="anonymous" /></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="d-flex pull-right">
            <button class="btn btn-add" *ngIf="!isWorkflowEdited" [disabled]="!this.workFlowForm.valid"
              (click)="addWorkFlow()">
              Add WorkFlow
            </button>
            <button class="btn btn-submit" *ngIf="isWorkflowEdited" (click)="updateWorkFlow()">
              Update
            </button>
          </div>
        </div>
      </div>
      <div class="card mb-3 w-100" *ngIf="workFlow.length != 0">
        <div class="card-header flexi-header">
          <h3>SELECTED WORKFLOWS INFORMATION</h3>
        </div>

        <div class="card-body">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>WORKFLOW TITLE</th>
                  <th>WORKFLOW TYPE</th>
                  <th>WORKFLOW VALUES</th>
                  <th>ADDITIONAL QUESTIONNAIRE</th>

                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of workFlow; let index = index">
                  <td>
                    {{ item.title }}
                  </td>

                  <td>
                    {{ item.type }}
                  </td>
                  <td>
                    <ul *ngFor="let item of item.workflowStatus">
                      <li>{{ item.field }}</li>
                    </ul>
                  </td>
                  <td>
                    {{ item?.additionalModuleId?.string === "undefined" || item?.additionalModuleId?.string === null ?
                    "No" : "Yes" }}
                  </td>

                  <td>
                    <button class="btn btn-add p-2 m-1" [disabled]="isWorkflowEdited"
                      (click)="editWorkflow(item, index)">
                      <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-add p-2 m-1" [disabled]="isWorkflowEdited" (click)="deleteWorkFlow(index)">
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <button class="btn btn-clear" *ngIf="workFlow.length != 0" (click)="ClearWorkFlowForm()">
            Clear All
          </button>
        </div>
      </div>
      <div class="flex-direction mt-2">
        <button class="btn btn-red-back" (click)="stepper.previous()">
          Back
        </button>

        <button class="btn btn-add float-right" *ngIf="!formId" [disabled]="isLoadingSubmit && workFlow.length === 0"
          (click)="onSubmitWorkFlow()">
          <i class="fa" [ngClass]="{
              'fa-spin fa-spinner': isLoadingSubmit,
              '': !isLoadingSubmit
            }"></i>
          Submit
        </button>
        <button class="btn btn-submit float-right" *ngIf="formId" [disabled]="isLoadingUpdate && workFlow.length !== 0"
          (click)="onUpdateWorkFlow()">
          <i class="fa" [ngClass]="{
              'fa-spin fa-spinner': isLoadingUpdate,
              '': !isLoadingUpdate
            }"></i>
          Update
        </button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>
