<div class="d-flex flex-column">
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs rounded mb-3">
    <li [ngbNavItem]="1">
      <a ngbNavLink>SETUP LEAVE TYPES</a>
      <ng-template ngbNavContent>
        <leave-overview></leave-overview>
      </ng-template>
    </li>
    <li [ngbNavItem]="2" *ngIf="leaveSchemeGroup">
      <a ngbNavLink>SETUP LEAVE GROUPINGS</a>
      <ng-template ngbNavContent>
        <leave-grp-overview></leave-grp-overview>
      </ng-template>
    </li>
  </ul>
</div>

<div [ngbNavOutlet]="nav"></div>
