import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import * as moment from 'moment';
import { ViewPeriod } from 'calendar-utils';
import { LeavePlannerService } from 'src/app/users/service/human-resources/leave-planner.service';
import Swal from 'sweetalert2';
import { UsersService } from 'src/app/users/service/user-service/users.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'ops-leave-quota',
  templateUrl: './ops-leave-quota.component.html',
  styleUrls: ['./ops-leave-quota.component.scss'],
})
export class OpsLeaveQuotaComponent implements OnInit {
  opsList: any;
  opsLeaveQuota: FormGroup;
  yearArr: string[] = [
    moment().subtract(1, 'year').format('YYYY'),
    moment().format('YYYY'),
    moment().add(1, 'year').format('YYYY'),
  ];


  monthArr: string[] = moment.monthsShort();
  opsTeam: any;
  selectMonthArr: any[];
  saveLeavePayload: any;
  teamLeave: boolean = true;
  viewLeaveQuota: boolean;
  getQuotaPayload: any;
  teamLeaveQuotaSun: any = 0;
  tierLeaveQuotaSun: any = 0;
  teamLeaveQuotaMon: any = 0;
  tierLeaveQuotaMon: any = 0;
  teamLeaveQuotaTue: any = 0;
  tierLeaveQuotaTue: any = 0;
  teamLeaveQuotaWed: any = 0;
  tierLeaveQuotaWed: any = 0;
  teamLeaveQuotaThu: any = 0;
  tierLeaveQuotaThu: any = 0;
  teamLeaveQuotaFri: any = 0;
  tierLeaveQuotaFri: any = 0;
  teamLeaveQuotaSat: any = 0;
  tierLeaveQuotaSat: any = 0;
  tierInputValue: any;
  teamInputValue: any;
  loggedInUser: any;
  viewDayQuota: any;
  constructor(
    private leaveService: LeavePlannerService,
    private usersService: UsersService,
    private fb: FormBuilder
  ) {}

  view: CalendarView = CalendarView.Month;

  locale: string = 'en';
  events: CalendarEvent[] = [];
  viewPeriod: ViewPeriod;

  ngOnInit(): void {
    this.getOpsLeave();

    this.opsLeaveQuota = this.fb.group({
      opsGrpSelect: [null, Validators.required],
      opsTeamSelect: null,
      year: this.yearArr[1],
      fromMonth: [null, Validators.required],
      toMonth: [null, Validators.required],
    });

    this.usersService.getUserProfile().subscribe((res) => {
      this.loggedInUser = res.data;
    });
  }

  getOpsLeave() {
    this.leaveService.getOpsGrp().subscribe((res: { data: any }) => {
      this.opsList = res.data;
    });
  }

  getOpsTeam() {
    this.opsTeam = this.opsLeaveQuota.controls.opsGrpSelect.value.opsTeamId;
  }

  viewQuota() {
    this.viewLeaveQuota = true;

    this.getMonths();

    if (this.opsLeaveQuota.controls.opsTeamSelect.value == null) {
      this.teamLeave = false;

      this.getQuotaPayload = {
        opsGroupId: this.opsLeaveQuota.controls.opsGrpSelect.value._id,
        year: this.opsLeaveQuota.controls.year.value,
      };
    } else {

      this.getQuotaPayload = {
        opsGroupId: this.opsLeaveQuota.controls.opsGrpSelect.value._id,
        year: this.opsLeaveQuota.controls.year.value,
        opsTeamId: this.opsLeaveQuota.controls.opsTeamSelect.value._id,
      };
    }

    this.leaveService
      .getQuotaByGrp(this.getQuotaPayload)
      .subscribe((res: { data }) => {
        this.viewDayQuota = res.data.perDayQuota;
        const selectedYear = this.opsLeaveQuota.controls.year.value;

        this.viewDayQuota.quota[selectedYear].forEach((val) => {
          if (
            <HTMLInputElement>(
              document.getElementById(
                'tier' + moment(val.date, 'DD-MM-YYYY').toDate().toString()
              )
            ) != null
          ) {
            (<HTMLInputElement>(
              document.getElementById(
                'tier' + moment(val.date, 'DD-MM-YYYY').toDate().toString()
              )
            )).value = val.value;
          }
        });

        if (this.opsTeam != null) {
          this.viewDayQuota.opsTeams.quota[selectedYear].forEach((val) => {
            if (
              <HTMLInputElement>(
                document.getElementById(
                  'team' + moment(val.date, 'DD-MM-YYYY').toDate().toString()
                )
              ) != null
            ) {
              (<HTMLInputElement>(
                document.getElementById(
                  'team' + moment(val.date, 'DD-MM-YYYY').toDate().toString()
                )
              )).value = val.value;
            }
          });
        }
      });
  }

  getMonths() {
    const sm = moment().set({
      year: this.opsLeaveQuota.controls.year.value,
      month: this.opsLeaveQuota.controls.fromMonth.value,
    });
    const em = moment().set({
      year: this.opsLeaveQuota.controls.year.value,
      month: this.opsLeaveQuota.controls.toMonth.value,
    });

    this.selectMonthArr = [];
    for (var i = sm.get('month'); i <= em.get('month'); i++) {
      this.selectMonthArr.push(
        moment()
          .set({
            year: this.opsLeaveQuota.controls.year.value,
            month: i,
          })
          .toDate()
      );
    }
  }

  saveLeaveQuota() {
    const selectedYear = this.opsLeaveQuota.controls.year.value;

    let startDate = moment().year(selectedYear).startOf('year');
    let endDate = moment().year(selectedYear).endOf('year');
    let months = {};
    months[selectedYear] = [];
    let month = startDate;
    while (month <= endDate) {
      for (var i = 0; i < 7; i++) {
        if (month.day() == i) {
          if (
            <HTMLInputElement>(
              document.getElementById('tier' + month.toDate().toString())
            ) == null
          ) {
            this.tierInputValue = '0';
          } else {
            this.tierInputValue = (<HTMLInputElement>(
              document.getElementById('tier' + month.toDate().toString())
            )).value;
          }

          console.log(
            'inputBox',
            month.toDate().toString(),
            '-----',
            <HTMLInputElement>(
              document.getElementById('tier' + month.toDate().toString())
            )
          );

          months[selectedYear].push({
            date: month.format('DD-MM-YYYY'),
            value: this.tierInputValue,
          });
        }
      }

      month = month.clone().add(1, 'day');
    }


    this.saveLeavePayload = {
      opsGroup: {
        id: this.opsLeaveQuota.controls.opsGrpSelect.value._id,
        name: this.opsLeaveQuota.controls.opsGrpSelect.value.opsGroupName,
        quota: [],
      },
    };
    this.saveLeavePayload.opsGroup.quota = [months];

    if (this.teamLeave) {
      let months = {};
      months[selectedYear] = [];
      let month = startDate;
      while (month <= endDate) {
        for (var i = 0; i < 7; i++) {
          if (month.day() == i) {
            if (
              <HTMLInputElement>(
                document.getElementById('team' + month.toDate().toString())
              ) == null
            ) {
              this.teamInputValue = '0';
            } else {
              this.teamInputValue = (<HTMLInputElement>(
                document.getElementById('team' + month.toDate().toString())
              )).value;
            }
            months[selectedYear].push({
              date: month.format('DD-MM-YYYY'),
              value: this.teamInputValue,
            });
          }
        }

        month = month.clone().add(1, 'day');
      }
      this.saveLeavePayload.opsTeam = {
        id: this.opsLeaveQuota.controls.opsTeamSelect.value._id,
        name: this.opsLeaveQuota.controls.opsTeamSelect.value.name,
        quota: [months],
      };
    }

    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to update the leave quota?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {

        this.leaveService
          .getSwapOpsGroup(this.loggedInUser._id)
          .pipe(
            finalize(() => {
              this.leaveService
                .addOpsquota(this.saveLeavePayload)
                .subscribe((res) => {
                  Swal.fire(
                    'Updated!',
                    'Leave Quota has been updated Successfully.',
                    'success'
                  );
                });
            })
          )
          .subscribe((data) => {
            console.log('swapData', data);
          });
      }
    });
  }

  tierCheck(e, value, day) {
    if (e.target.checked) {
      switch (day) {
        case 'Sun':
          this.tierLeaveQuotaSun = value;
          break;
        case 'Mon':
          this.tierLeaveQuotaMon = value;
          break;
        case 'Tue':
          this.tierLeaveQuotaTue = value;
          break;
        case 'Wed':
          this.tierLeaveQuotaWed = value;
          break;
        case 'Thu':
          this.tierLeaveQuotaThu = value;
          break;
        case 'Fri':
          this.tierLeaveQuotaFri = value;
          break;
        case 'Sat':
          this.tierLeaveQuotaSat = value;
          break;
      }
    }
  }

  teamCheck(e, value, day) {
    if (e.target.checked) {
      switch (day) {
        case 'Sun':
          this.teamLeaveQuotaSun = value;
          break;
        case 'Mon':
          this.teamLeaveQuotaMon = value;
          break;
        case 'Tue':
          this.teamLeaveQuotaTue = value;
          break;
        case 'Wed':
          this.teamLeaveQuotaWed = value;
          break;
        case 'Thu':
          this.teamLeaveQuotaThu = value;
          break;
        case 'Fri':
          this.teamLeaveQuotaFri = value;
          break;
        case 'Sat':
          this.teamLeaveQuotaSat = value;
          break;
      }
    }
  }
}
