<div class="row">
  <div class="col-md-4">
    <div class="card">
      <div class="card-body">
        <div class="profile-details text-left">
          <div class="profile-userpic">
            <input type="file" disabled />
            <img
              *ngIf="!IconUrl"
              [src]="
                userData?.profilePicture
                  ? userData?.profilePicture
                  : '../assets/images/user1.png'
              "
              class="img-responsive"
              alt=""
            />
            <img
              *ngIf="IconUrl"
              [src]="IconUrl"
              class="img-responsive"
              alt=""
            />
            <!-- <div class="editProfile">Edit</div> -->
          </div>

          <div class="profile-usertitle">
            <div class="profile-usertitle-name">
              {{ userData?.name }}
            </div>
            <div class="profile-usertitle-job">
              {{ userData?.role?.name }}
            </div>
          </div>

          <ul class="list-group text-dark">
            <li
              class="list-group-item d-flex justify-content-between align-items-center"
            >
              <div class="text-muted"><i class="fa fa-user"></i> Staff ID</div>
              <span class="text-right">{{ userData?.staffId }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center"
            >
              <div class="text-muted"><i class="fa fa-phone"></i> Phone</div>
              <span class="text-right">{{
                userData?.primaryMobileNumber
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center"
            >
              <div class="text-muted"><i class="fa fa-envelope"></i> Email</div>
              <span class="text-right">{{ userData?.email }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center"
            >
              <div class="text-muted">
                <i class="fa fa-flag"></i> Parent Business Unit
              </div>
              <span class="text-right">{{
                userData?.parentBussinessUnitId?.orgName
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center"
            >
              <div class="text-muted">
                <i class="fa fa-users"></i> Ops Group Name
              </div>
              <span class="text-right">{{ userData?.opsGroupName }}</span>
            </li>
          </ul>
        </div>
        <hr />
        <div class="back-btn-container">
          <button class="btn btn-clear pl-4 pr-4" (click)="goBack()">
            Back
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-8 pl-md-0">
    <div class="d-flex flex-column">
      <div>
        <ul
          ngbNav
          [(activeId)]="activeId"
          #companyNav="ngbNav"
          class="nav-tabs rounded mb-3"
        >
          <li [ngbNavItem]="1">
            <a ngbNavLink>GENERAL INFO</a>
            <ng-template ngbNavContent>
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6 col-sm-12">
                      <div class="form-group">
                        <label for="exampleInputEmail1">User Name</label>
                        <input
                          type="text"
                          class="form-control"
                          [value]="userData?.name"
                          aria-describedby="emailHelp"
                          placeholder="Enter User Name"
                          readonly
                        />
                      </div>
                      <div class="form-group">
                        <label for="exampleInputEmail1">Staff Id</label>
                        <input
                          type="text"
                          class="form-control"
                          [value]="userData?.staffId"
                          aria-describedby="emailHelp"
                          placeholder="Enter Staff Id"
                          readonly
                        />
                      </div>
                      <div class="form-group">
                        <label class="pb-2 form-bg" for="exampleInputEmail1"
                          >Recovery Mobile Number</label
                        >
                        <div class="input-group">
                          <!-- "
                              userData?.countryCode.concat(
                                userData?.primaryMobileNumber
                              )
                            " -->
                          <input
                            class="form-control"
                            [value]="userData?.primaryMobileNumber"
                            type="text"
                            readonly
                          />
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="exampleInputEmail1">Appoinment</label>
                        <input
                          type="text"
                          class="form-control"
                          [value]="userData?.appointmentId?.name"
                          aria-describedby="emailHelp"
                          placeholder="Enter Apoinment"
                          readonly
                          [ngClass]="{
                            'is-invalid': submitted1 && f.staffId.errors
                          }"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink>CONTACT INFO</a>
            <ng-template ngbNavContent>
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Contact Number</label>
                        <input
                          type="text"
                          class="form-control"
                          [value]="userData?.contactNumber"
                          aria-describedby="emailHelp"
                          placeholder="Enter Staff Id"
                          readonly
                          [ngClass]="{
                            'is-invalid': submitted1 && f.staffId.errors
                          }"
                        />
                      </div>
                      <div class="form-group">
                        <label for="exampleInputPassword1">Email</label>
                        <input
                          type="text"
                          class="form-control"
                          [value]="userData?.email"
                          aria-describedby="emailHelp"
                          placeholder="Enter Staff Id"
                          readonly
                          [ngClass]="{
                            'is-invalid': submitted1 && f.staffId.errors
                          }"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="3">
            <a ngbNavLink>OTHER INFO</a>
            <ng-template ngbNavContent>
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Roles</label>
                        <input
                          type="text"
                          class="form-control"
                          [value]="userData.role?.name"
                          aria-describedby="emailHelp"
                          placeholder="Enter Staff Id"
                          readonly
                        />
                      </div>
                      <div class="form-group">
                        <label for="exampleInputPassword1"
                          >Bussiness Unit</label
                        >
                        <input
                          type="email"
                          class="form-control"
                          placeholder="Enter BussinnesUnit"
                          readonly
                          [value]="userData.parentBussinessUnitId?.orgName"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="4">
            <a ngbNavLink>CUSTOM FIELDS</a>
            <ng-template ngbNavContent>
              <div class="card">
                <div class="card-body">
                  <form [formGroup]="form">
                    <dynamic-form-builder
                      [gridView]="'col-xs-12 col-sm-12 col-md-4 col-lg-4 '"
                      [formGroup]="form"
                      [fields]="getFields()"
                    ></dynamic-form-builder>
                  </form>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="5">
            <a ngbNavLink>ASSIGNED BUSSINESS UNIT</a>
            <ng-template ngbNavContent>
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="exampleInputEmail1"
                          >Business Unit (Plan)</label
                        >
                        <ng-container
                          *ngFor="let item of userData?.planBussinessUnitId"
                        >
                          <input
                            type="text"
                            class="form-control mb-2"
                            [value]="item?.orgName"
                            aria-describedby="emailHelp"
                            placeholder="Enter Staff Id"
                            readonly
                          />
                        </ng-container>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="exampleInputEmail1"
                          >Business Unit (View)
                        </label>
                        <ng-container
                          *ngFor="let item of userData?.viewBussinessUnitId"
                        >
                          <input
                            type="text"
                            class="form-control mb-2"
                            [value]="item.orgName"
                            aria-describedby="emailHelp"
                            placeholder="Enter Staff Id"
                            readonly
                          />
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="companyNav"></div>
      </div>
    </div>
  </div>
</div>

<ng-template #changePassword let-c="close" let-d="dismiss">
  <div>
    <div class="modal-content">
      <div class="modal-body" style="padding: 10px; min-height: 0vh">
        <div>
          <div class="card">
            <div class="card-header flexi-header">
              <h3>CHANGE PASSWORD</h3>
            </div>
            <div class="card-body">
              <form [formGroup]="passwordRestForm">
                <div class="form-group">
                  <label class="pb-2 form-bg" for="exampleInputEmail1"
                    >Password
                  </label>

                  <input
                    type="password"
                    class="form-control"
                    placeholder=" Password"
                    [ngClass]="{
                      'is-invalid':
                        submitted3 && passwordRestForm.get('password').errors
                    }"
                    formControlName="password"
                  />
                  <div
                    *ngIf="passwordRestForm.get('password').errors"
                    class="invalid-feedback"
                  >
                    <small
                      *ngIf="passwordRestForm.get('password').errors.required"
                    >
                      Password is required
                    </small>
                    <br />
                    <small
                      *ngIf="passwordRestForm.get('password').errors.hasNumber"
                    >
                      Atleast one numeric letter is required
                    </small>
                    <br />
                    <small
                      *ngIf="
                        passwordRestForm.get('password').errors
                          .hasSpecialCharacters
                      "
                    >
                      Atleast one special character is required
                    </small>
                    <br />
                    <small
                      *ngIf="
                        passwordRestForm.get('password').errors.hasCapitalCase
                      "
                    >
                      Atleast one Capital character is required
                    </small>
                    <br />
                    <small
                      *ngIf="
                        passwordRestForm.get('password').errors.hasSmallCase
                      "
                    >
                      Atleast one Small character is required
                    </small>
                    <br />
                    <small
                      *ngIf="passwordRestForm.get('password').errors.minlength"
                    >
                      Min Length Should 8 Charecter
                    </small>
                  </div>
                </div>
                <div class="form-group">
                  <label class="pb-2 form-bg" for="exampleInputEmail1"
                    >Confirm Password
                  </label>

                  <input
                    type="password"
                    class="form-control"
                    placeholder=" Confirm Password"
                    [ngClass]="{
                      'is-invalid':
                        submitted3 &&
                        passwordRestForm.get('confirmPassword').errors
                    }"
                    formControlName="confirmPassword"
                  />
                  <div
                    *ngIf="passwordRestForm.get('confirmPassword').errors"
                    class="invalid-feedback"
                  >
                    <small
                      *ngIf="
                        passwordRestForm.get('confirmPassword').errors.required
                      "
                    >
                      Confirm Password is required
                    </small>
                    <small
                      *ngIf="
                        passwordRestForm.get('confirmPassword').errors.mustMatch
                      "
                    >
                      Password doesn't Match
                    </small>
                  </div>
                </div>
              </form>
              <div class="form-group">
                <button
                  type="submit"
                  (click)="onRestPassword()"
                  class="btn btn-sm btn-submit float-right m-1 p-1"
                >
                  Reset Password
                </button>
                <button
                  type="submit"
                  (click)="closeChangePasswordPopUp()"
                  class="btn btn-sm btn-clear float-right m-1 p-1"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #changeMobileNumber let-c="close" let-d="dismiss">
  <div class="modal-content">
    <div class="modal-body" style="padding: 10px; min-height: 50vh">
      <div>
        <div class="card">
          <div class="card-header flexi-header">
            <h3>CHANGE MOBILE NUMBER</h3>
          </div>
          <div class="card-body">
            <form [formGroup]="forgetPasswordForm">
              <div class="form-group">
                <label for="exampleInputPassword1">Mobile Number</label>
                <ngx-intl-tel-input
                  [preferredCountries]="preferredCountries"
                  [cssClass]="{
                    'form-control': 1,
                    'is-invalid': submitted1 && f.mobile.errors
                  }"
                  [enableAutoCountrySelect]="true"
                  [enablePlaceholder]="true"
                  [searchCountryFlag]="true"
                  [searchCountryField]="[
                    SearchCountryField.Iso2,
                    SearchCountryField.Name
                  ]"
                  [selectFirstCountry]="false"
                  [selectedCountryISO]="CountryISO.India"
                  [maxLength]="15"
                  [tooltipField]="TooltipLabel.Name"
                  [phoneValidation]="false"
                  [separateDialCode]="separateDialCode"
                  [numberFormat]="PhoneNumberFormat.National"
                  formControlName="primaryMobileNumber"
                >
                </ngx-intl-tel-input>

                <div
                  *ngIf="submitted1 && f.primaryMobileNumber.errors"
                  class="invalid-feedback"
                >
                  <small *ngIf="f.primaryMobileNumber.errors.required">
                    Mobile Number is required
                  </small>
                </div>
              </div>
              <div class="form-group">
                <button
                  type="submit"
                  (click)="onRestPassword()"
                  class="btn btn-sm btn-submit float-right m-1 p-1"
                >
                  Update
                </button>
                <button
                  type="submit"
                  (click)="closeChangePasswordPopUp()"
                  class="btn btn-sm btn-clear float-right m-1 p-1"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
